(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('noJourneysView', {
            templateUrl: '/screen/views/components/timetable-hafas/no-journeys-view/no-journeys-view.component.tpl.html',
            controller: NoJourneysViewController,
            bindings: {
                noJourneysConfig: '<',
            }
        });

    /**
     * @constructor
     * 
     * @property {{
     *   text: string,
     *   image: string,
     *   textColor: string,
     *   bgColor: string,
     * }} noJourneysConfig
     *
     * @param {$interval} $interval
     * @param {object} NO_JOURNEYS_VIEW_MODE
     */
    function NoJourneysViewController(
        $interval,
        NO_JOURNEYS_VIEW_MODE
    ) {
        const $ctrl = this;
        const STEP_DURATION_MS = 5000;

        let interval = null;

        this.$onChanges = onChanges;

        $ctrl.NO_JOURNEYS_VIEW_MODE = NO_JOURNEYS_VIEW_MODE;
        $ctrl.viewMessage = '';
        $ctrl.viewIndex = 0;
        $ctrl.viewMessages = [''];

        function onChanges(changes) {
            $ctrl.viewIndex = 0;

            const { text: message } = changes.noJourneysConfig.currentValue;

            if (message) {
                $ctrl.viewMessages = message.split('\n');
                $ctrl.viewMessage = $ctrl.viewMessages[0];
            }

            if (interval) {
                $interval.cancel(interval);
            }

            $interval(() => {
                _showNextMessage();
            }, STEP_DURATION_MS);
        }

        function _showNextMessage() {
            $ctrl.viewIndex++;
            $ctrl.viewMessage = $ctrl.viewMessages[$ctrl.viewIndex % $ctrl.viewMessages.length];
        }
    }

})();

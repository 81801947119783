(function() {
    'use strict';

    angular.module('beacon.app')
        .component('delayedTimeHighlightCheckbox', {
            templateUrl:
                '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-elements/delayed-time-highlight-checkbox/delayed-time-highlight-checkbox.tpl.html',
            controller: DelayedTimeHighlightCheckbox,
            bindings: {
                element: '=',
            }
        });

        /**
         * @property {TimetableHafasElement} element
         *
         * @constructor
         */
        function DelayedTimeHighlightCheckbox() {
            const $ctrl = this;

            $ctrl.value = typeof $ctrl.element.isDelayedTimeHighlight !== 'boolean'
                ? true
                : $ctrl.element.isDelayedTimeHighlight;

            $ctrl.$onInit = () => {
                $ctrl.onToggle();
            }

            $ctrl.onToggle = () => {
                $ctrl.element.isDelayedTimeHighlight = $ctrl.value;
            };
        }
})();

(function() {
    'use strict';

    angular.module('trmTimetableHafas').factory('JourneyFactory',
        /**
         * @param {MomentService} $moment
         * @param {JourneyModel} JourneyModel
         */
        function(
            $moment,
            JourneyModel,
        ) {
            /**
             * @param {MgateStationBoardResponseCore} mgateResponse
             * @param {MctConfig} mctConfig
             * @param {Object<string, string>[]} stationNameReplacementMap
             * @param {ProductParams} productParams
             * @param {number} viaStopsAmount
             *
             * @return {{journeys: Journey[], hims: MgateHim[]}}
             */
            function generateJourneys(
                mgateResponse,
                mctConfig,
                stationNameReplacementMap,
                productParams,
                viaStopsAmount,
            ) {
                if (mgateResponse.err === 'PARSE') {
                    throw new MgateExpectedError('Invalid server request');
                }

                if (mgateResponse.err !== 'OK') {
                    throw new MgateUnexpectedError(`Response CORE error: ${mgateResponse.err}`);
                }

                const objects = mgateResponse.svcResL.map(responseItem => {
                    if (responseItem.err === 'LOCATION') {
                        throw new MgateExpectedError('Invalid station identifier');
                    }

                    if (responseItem.err !== 'OK') {
                        throw new MgateUnexpectedError(`Response ITEM error: ${responseItem.err}`);
                    }

                    const responseBody = responseItem.res;
                    const serverTime = $moment(responseBody.sD + responseBody.sT, 'YYYYMMDDHHmmss');
                    const serverTimeDiff = $moment().diff(serverTime, 'seconds');
                    const products = responseBody.common.prodL;
                    const locations = responseBody.common.locL;
                    const icons = responseBody.common.icoL;

                    /**
                     * @type {MgateHim[]}
                     */
                    const hims = (responseBody.common.himL || [])
                        .filter(
                            /**
                             * @param {MgateHim} value
                             * @param {number} index
                             * @param {MgateHim[]} self
                             */
                            (value, index, self) => {
                                return self.findIndex(v => v.hid === value.hid) === index;
                            }
                        );

                    if (!angular.isArray(responseBody.jnyL)) {
                        responseBody.jnyL = [];
                    }

                    const journeys = responseBody.jnyL.map(item => {
                        const journey = new JourneyModel.Journey();
                        const product = products[item.prodX];
                        const icon = icons[product.icoX];
                        const location = locations[item.stbStop.locX];

                        // Original MgateJourney and related objects
                        journey.origin = {
                            journey: item,
                            product,
                            icon,
                            location,
                            him: (item.msgL || [])
                                .filter(msg => msg.type === 'HIM')
                                .map(item => hims[item.himX])
                        };

                        // Direction
                        journey.direction = _makeStationName(stationNameReplacementMap, item.dirTxt);

                        // Time
                        journey.time = _generateTime(
                            item.date,
                            item.stbStop.dTimeS || item.stbStop.aTimeS,
                            serverTimeDiff
                        );

                        journey.timeReal = _generateTime(
                            item.date,
                            item.stbStop.dTimeR || item.stbStop.aTimeR,
                            serverTimeDiff
                        );

                        journey.serverTime = serverTime;

                        let productConfig = productParams.productConfig
                            && productParams.productConfig.find(config => config.containedCls.includes(product.cls));

                        if (!productConfig) {
                            productConfig = {
                                index: 9999,
                                timetableDisplayFullName: `No config (product.cls: ${product.cls})`,
                                cls: 'missing_config__cls_' + product.cls,
                            };
                        }

                        // Product
                        journey.product = {
                            cls: product.cls,
                            clsOverride: productConfig.cls,
                            number: product.number,
                            category: (product.prodCtx.catOutL || product.prodCtx.catOutS).trim(),
                            productConfig,
                            icon: _makeProductIcon(productConfig, icon.res),
                        };

                        journey.product.iconFull = _makeProductIconFull(productConfig, journey.product);

                        // Poles arrow
                        const poleConfig = mctConfig.poleFilter[location.extId];
                        const arrowId = poleConfig && poleConfig.arrow || null;

                        journey.pole = {
                            id: location.extId,
                            arrow: arrowId,
                            arrowImage: _arrowImage(arrowId),
                        };

                        // Track
                        journey.track = {
                            name: item.stbStop.dPltfR && item.stbStop.dPltfR.txt || item.stbStop.dPltfS && item.stbStop.dPltfS.txt,
                            prefix: poleConfig && poleConfig.filterName || null,
                        };

                        // Via stops
                        if (viaStopsAmount && item.stopL) {
                            journey.viaStops = item.stopL
                                .filter(stopL => stopL.locX)
                                .map(stopL => _makeStationName(stationNameReplacementMap, locations[stopL.locX].name))
                                .slice(1, -1) // 1st station is the current station, last is the "direction"
                                .slice(0, viaStopsAmount);
                        }

                        // Cancelled
                        journey.isCancelled = item.isCncl;
                        journey.isPartlyCancelled = item.isPartCncl;

                        return journey;
                    });

                    return {
                        journeys,
                        hims,
                    }
                });

                return {
                    journeys: objects
                        .map(station => station.journeys)
                        .flat()
                        .sort((a, b) => {
                            const timeA = a.getRealTime() || a.getScheduledTime();
                            const timeB = b.getRealTime() || b.getScheduledTime();

                            return timeA.unix() - timeB.unix();
                        })
                    ,
                    hims: objects.map(station => station.hims).flat(),
                }
            }

            function _hasPersonalIcon(product) {
                const name = product.category.toLowerCase();
                const number = product.number;
                return name === 's' || name === 're' || name === 'u'
                    || ( name === 'rb' && typeof number === 'string' && number.startsWith('RB') );
            }

            /**
             * @param {string} date
             * @param {string} time
             * @param {number} serverTimeDiffSeconds - hours
             * @return {moment.Moment|null}
             * @private
             */
            function _generateTime(date, time, serverTimeDiffSeconds) {
                if (!time) {
                    return null;
                }

                const isOvernight = time.length > 6;

                // overnight journey has "01" prefix in the beginning
                if (isOvernight) {
                    time = time.slice(2);
                }

                const serverTimeZone = ($moment().utcOffset() - Math.round(serverTimeDiffSeconds / 60)) / 60;
                const datetime = $moment(date + time, 'YYYYMMDDHHmmss').utcOffset(serverTimeZone, true);

                if (isOvernight) {
                    datetime.add(1, 'day');
                }

                return datetime;
            }

            /**
             * @return {string}
             */
            function _arrowImage(arrowId) {
                const arrows = {
                    0: null,
                    1: 'Geradeaus.svg',
                    2: 'rechts oben.svg',
                    3: 'rechts.svg',
                    4: 'rechts unten.svg',
                    5: 'zurueck.svg',
                    6: 'links unten.svg',
                    7: 'links.svg',
                    8: 'links oben.svg',
                    9: 'geradeaus links um Eck.svg',
                    10: 'links um Eck.svg',
                    11: 'geradeaus rechts um Eck.svg',
                    12: 'Geschlossen.svg',
                    13: 'rechts um Eck.svg',
                    14: 'umdrehen.svg',
                };

                return arrows[arrowId] || null;
            }

            /**
             * Returns "icon" URL
             * 
             * @param {ProductConfig} productConfig
             * @param {string} defaultIconRes
             * @returns {string}
             */
            function _makeProductIcon({ iconRef, iconRes }, defaultIconRes) {
                const defaultIconPath = `/screen/images/products/product-icons/haf_${defaultIconRes}.svg`;

                return iconRef || iconRes || defaultIconPath;
            }

            /**
             * Returns "iconFull" URL
             * 
             * @param {ProductConfig} productConfig
             * @param {JourneyProduct} journeyProduct
             * @returns {string}
             */
            function _makeProductIconFull({ iconRef, iconRes }, journeyProduct) {
                const defaultIconPath = '/screen/images/products/line-icons/';

                return iconRef ||
                       iconRes ||
                       _hasPersonalIcon(journeyProduct) && (defaultIconPath + journeyProduct.number.toLowerCase() + '.svg');
            }

            /**
             * Replace sourceString parts by replacement map
             * 
             * @param {Object<string, string>} replacementMap
             * @param {string} sourceString
             * @returns {string}
             */
            function _makeStationName(replacementMap, sourceString = '') {
                let result = sourceString.trim();

                if (!angular.isArray(replacementMap)) {
                    return result;
                }

                replacementMap.forEach((pairs) => {
                    Object.keys(pairs).forEach(pattern => {
                        const replacement = pairs[pattern];
                        result = result.replaceAll(pattern, replacement);
                    });
                });

                return result.replace(/\s+/g, ' ');
            }

            class MgateExpectedError extends Error {
                constructor(message) {
                    super(message);
                    this.name = 'MgateExpectedError';
                }
            }

            class MgateUnexpectedError extends Error {
                constructor(message) {
                    super(message);
                    this.name = 'MgateUnexpectedError';
                }
            }

            return {
                generateJourneys,
            };
        });
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('dynamicContentDetails', {
            controller: dynamicContentDetails,
            templateUrl: '/assets/views/dynamic-content-dashboard/details/details.tpl.html',
            bindings: {
                languages: '<',
                content: '<',
                campaigns: '<',
                setCampaign: '<',
                callbackDeleteContent: '<',
            }
        });

    function dynamicContentDetails(
        $q,
        $scope,
        $state,
        PopupService,
        ContentDataService,
        DynamicContentPopup,
        HTTP_STATUSES,
        CampaignHelper,
        $translate,
    ) {
        const vm = this;

        const DATE_FORMAT = 'DD MMM YYYY HH:mm';

        vm.$onInit = init;
        vm.setLanguage = setLanguage;
        vm.selectCampaign = selectCampaign;
        vm.editContent = editContent;
        vm.copyContent = copyContent;
        vm.deleteContent = deleteContent;
        vm.showPreview = showPreview;

        /**
         * Controller initialization
         */
        function init() {
            resetTabs();
            setContentValues();
            setCampaignValues();
        }

        /**
         * Set content values to the view
         */
        function setContentValues() {
            if (!vm.content) {
                return;
            }
            vm.name = vm.content.data.name;
            vm.title = vm.content.title[vm.langId];
            vm.text = vm.content.message[vm.langId];
        }

        /**
         * Set campaign values to the view
         */
        function setCampaignValues() {
            vm.campaignsCount = vm.campaigns && vm.campaigns.length;
            vm.campaign = vm.campaignsCount && vm.campaigns[vm.activeCampaignIndex];

            if (!vm.campaign) {
                return;
            }

            vm.campaignName = vm.campaign.name;
            generatePeriod(vm.campaign.started_at, vm.campaign.finished_at)
                .then(period => {
                    vm.campaignPeriod = period;
                });
            vm.campaignTime = generateTime(vm.campaign.send_time);
            generateDays(vm.campaign.show_days).then(result => {
                vm.campaignDays = result;
            });
        }

        /**
         * Set active campaign
         *
         * @param {int} index
         */
        function selectCampaign(index) {
            const campaign = vm.campaigns[index];

            if (!campaign) {
                return;
            }

            resetLanguage();
            vm.activeCampaignIndex = index;

            vm.campaign = campaign;
            setCampaignValues();
            vm.setCampaign(campaign);
        }

        /**
         * Set active language
         *
         * @param {int} langId
         */
        function setLanguage(langId) {
            vm.langId = langId;
        }

        /**
         * Reset active language (set default)
         */
        function resetLanguage() {
            vm.langId = vm.languages && vm.languages[0].id;
        }

        /**
         * Reset active language and active campaign
         */
        function resetTabs() {
            resetLanguage();
            vm.activeCampaignIndex = 0;
        }

        /**
         * Makes a date range string (start - end)
         *
         * @param {string|date} start
         * @param {string|date} end
         * @returns {string}
         */
        function generatePeriod(start, end) {
            return $translate('INFINITE').then(infiniteLabel => {
                const dateStart = moment.utc(start).local().format(DATE_FORMAT);
                const momentEnd = moment.utc(end).local();
                const dateEnd = CampaignHelper.isCampaignDefault(momentEnd)
                    ? infiniteLabel
                    : momentEnd.format(DATE_FORMAT);
                return `${dateStart} &#8212; ${dateEnd}`;
            });

        }

        /**
         * Make a time string for campaign
         *
         * @param object
         * @returns {string}
         */
        function generateTime(object) {
            object = angular.isString(object) ? JSON.parse(object) : object;
            const wholeDay = object.start === '00:00' && object.end === '24:00';
            const start = wholeDay ? object.start : moment.utc(object.start, 'HH:mm').local().format('HH:mm');
            const end = wholeDay ? object.end : moment.utc(object.end, 'HH:mm').local().format('HH:mm');
            return `${start} - ${end}`;
        }

        /**
         * Make a days string for campaign
         *
         * @param {array|json} days
         * @returns {string}
         */
        function generateDays(days) {
            const ALL_DAYS = [9];
            const week = {
                1: 'MON',
                2: 'TUE',
                3: 'WED',
                4: 'THU',
                5: 'FRI',
                6: 'SAT',
                7: 'SUN',
            };

            days = angular.isString(days)
                ? JSON.parse(days)
                : days;

            const arrDays = _.isEqual(days, ALL_DAYS)
                ? ['ALL']
                : days.map(day => week[day])

            const promises = arrDays.map(value => $translate(value));
            return $q.all(promises).then(arrDays => arrDays.join(', '));
        }

        /**
         * Opens the dynamic content preview inside a popup
         */
        function showPreview() {
            DynamicContentPopup.show({
                content: vm.content,
                languages: vm.languages,
                aspectRatioControl: true,
            });
        }

        /**
         * Edit content handler
         */
        function editContent() {
            $state.go('app.addContent', {
                data: vm.content,
                type: 'edit',
                redirect: 'app.dynamicContentDashboard',
            });
        }

        /**
         * Copy content handler
         */
        function copyContent() {
            $state.go('app.addContent', {
                data: vm.content,
                type: 'new',
                redirect: 'app.dynamicContentDashboard',
                copy: true,
            });
        }

        /**
         * Delete content handler
         */
        function deleteContent() {
            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CONTENT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                ContentDataService.deleteContent(vm.content.id)
                    .then(() => {
                        vm.callbackDeleteContent(vm.content.id);
                    })
                    .catch(response => {
                        if (response.status === HTTP_STATUSES.BAD_REQUEST) {
                            PopupService.showAlertPopup({
                                text: 'CAMPAIGN_CONTENT_CANT_BE_DELETED',
                                okButtonText: 'OK'
                            });
                        }
                    });
            });
        }

        /**
         * Watchers
         */
        $scope.$watch(
            () => [vm.content, vm.languages, vm.campaigns],
            () => {
                resetTabs();
                setContentValues();
                setCampaignValues();
            },
            true,
        );

        $scope.$watch(
            () => vm.langId,
            () => setContentValues()
        );

        $scope.$watch(
            () => vm.activeCampaignIndex,
            () => setContentValues()
        );
    }
})();
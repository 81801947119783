(function() {
    'use strict';

    angular.module('beacon.app')
        .service('CsvService', CsvService);

    function CsvService(

    ) {
        return {
            generateCsv,
            downloadCsv,
            generateCsvContent,
        }

        /**
         * @param {[]} array
         * @param {string} fileName (without extension)
         * @return {File}
         */
        function generateCsv(array, fileName = 'table') {
            const content = generateCsvContent(array);
            return new File(["\ufeff" + content], fileName + '.csv', { type: 'text/csv' });
        }

        /**
         * @param {[]} array
         * @return {string}
         */
        function generateCsvContent(array) {
            return array.map(row => row.join(';')).join('\n');
        }

        /**
         *
         * @param {File} file
         */
        function downloadCsv(file) {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(file);
            a.download = file.name;
            a.click();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PoiImportDataService', poiImportDataService);

    function poiImportDataService(
        Restangular,
        StorageFactory,
    ) {
        return {
            importPOIs,
            getPoiDoublets,
            addToBlacklist,
            addToWhitelist,
            getImportedPOIs,
            createPoisFromImported,
        };

        function getImportedPOIs() {
            return new Promise((resolve, reject) => {
                let STORAGE = StorageFactory.Storage('POIImport');
                let importedPOIs = STORAGE.get('importedPOIs') || [];
                let importedPOIsTypes = STORAGE.get('importedPOIsTypes') || [];
                resolve({
                    importedPOIs,
                    importedPOIsTypes,
                });
            });
        }

        function importPOIs() {
            return Restangular.all('poi/import').getList();
        }

        function getPoiDoublets(maxDist, minSim) {
            return Restangular.all('poi/doublets').getList({maxDist, minSim});
        }

        function addToBlacklist(id) {
            return Restangular.one('poi/toBlacklist').get({id: id});
        }

        function addToWhitelist(id) {
            return Restangular.one('poi/toWhitelist').get({id: id});
        }

        function createPoisFromImported(data) {
            return Restangular.all('poi/contentsFromImported')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('IotEntityDataService', IotEntityDataService);

    function IotEntityDataService(
        Restangular,
    ) {
        return {
            getIotEntities,
            getIotEntityTypes,
            createIotEntity,
            updateIotEntity,
            deleteIotEntity,
        };

        function getIotEntities(data) {
            return Restangular.all('sharePark/iotEntity').getList(data);
        }

        function getIotEntityTypes() {
            return Restangular.all('sharePark/iotEntityTypes').getList();
        }

        function createIotEntity(data) {
            return Restangular.all('sharePark/iotEntity')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteIotEntity(id) {
            return Restangular.one('sharePark/iotEntity', id).remove();
        }

        function updateIotEntity(iotEntity, data) {
            data.append('_method', 'PUT');
            return iotEntity.customPOST(data, '', {}, { 'Content-Type': undefined });
        }
    }
})();
(function () {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkReports', {
            templateUrl: '/assets/views/share-park/operations/reports/reports.tpl.html',
            controller: ReportsController
        });

    function ReportsController(
        UtilitiesService,
        ReportsService,
        DATETIME_FORMAT,
        ShareParkDataService,
    ) {
        const vm = this;

        const { getCarParks } = ShareParkDataService;
        vm.carParks = [];
        const ACTIVE_STATUS = 1;
        const INACTIVE_STATUS = 2;
        const DELETED_STATUS = 3;

        vm.reports = [];
        vm.visibleReports = [];
        vm.updateCounter = 0;
        vm.getSimulate = getSimulate;
        vm.getReportsList = getReportsList;
        vm.runSimulate = runSimulate;
        vm.runPreviewSimulate = runPreviewSimulate;
        vm.$onInit = init;

        vm.reportsList = {
            columns: [
                {
                    name: 'NAME',
                    width: '15',
                    translate: true,
                    title: 'name'
                },
                {
                    name: 'RESULT_FORMAT',
                    width: '15',
                    translate: true,
                    title: 'resultFormat'
                },
                {
                    name: 'LAST_RESULT',
                    width: '20',
                    translate: true,
                    title: listItem =>
                        listItem.lastResultDate ? moment(listItem.lastResultDate).format(DATETIME_FORMAT) : '-',
                },
                {
                    name: 'LAST_PROCESSING_DURATION',
                    width: '15',
                    translate: true,
                    title: listItem => {
                        return listItem.lastResultDuration ? Math.ceil(listItem.lastResultDuration / 1000) + 's' : '-'
                    }
                },
            ],
            buttons: {
                width: '20',
                minWidth: '150px',
                items: [
                    {
                        class: 'downloadBtn',
                        callback: getSimulate,
                        tooltip: 'DOWNLOAD_FULL_REPORT',
                        permissionAction: 'can_download_full_report',
                        isVisible,
                    },
                    {
                        class: 'playBtn',
                        callback: runSimulate,
                        tooltip: 'CREATE_FULL_REPORT',
                        permissionAction: 'can_create_full_preview',
                        isVisible,
                    },
                    {
                        class: 'previewBtn',
                        callback: runPreviewSimulate,
                        tooltip: 'CREATE_PREVIEW',
                        permissionAction: 'can_create_preview',
                        isVisible,
                    },
                ]
            },
            updateCallback: updateList,
            generatePermissionName
        }


        function init() {
            getCarParks().then(carParks => {
                vm.carParks = carParks.plain();
                getReportsList();
            })
        }

        function getReportsList() {
            ReportsService.getReportList().then(reports => {
                vm.reports = splitResultToArray(reports.data);
                vm.updateCounter++;
            });
        }

        function splitResultToArray(reports) {
            return reports.map(report => {
                if (report.lastPreviewResult) {
                    report.formattedResult = report.lastPreviewResult.split('\n');
                    report.formattedResult = report.formattedResult.filter(row => row);
                    report.formattedResult = report.formattedResult.map(row => row.split(';'));
                }

                if (typeof report.parameterTemplate === 'string') {
                    report.parameterTemplate = JSON.parse(report.parameterTemplate);
                }

                if (typeof report.lastUsedParameters === 'string') {
                    report.lastUsedParameters = JSON.parse(report.lastUsedParameters);
                }

                if (vm.carParks) {
                    report.carParks = vm.carParks;
                }

                for (let key in report.lastUsedParameters) {
                    report.parameterTemplate = report.parameterTemplate.map(item => {
                        if (item.parameterName === key) {
                            item.value = report.lastUsedParameters[key];
                            if (item.parameterName === 'carParkRefs') {
                                item.value = item.value.split(',');
                            }
                        }
                        return item;
                    })
                }

                return report;
            })

        }

        function getSimulate($event, report) {
            $event.preventDefault();
            $event.stopPropagation();

            ReportsService.downloadReport(report.id, true).then(csv => {
                const blob = new Blob(["\ufeff" + csv.data.lastResult], { type: 'text/csv' });
                const fileName = csv.data.fileName;
                downloadCSV(fileName, blob);
            });
        }

        function runSimulate($event, report) {
            $event.preventDefault();
            $event.stopPropagation();
            runReport(report, false)
        }

        function runPreviewSimulate($event, report) {
            $event.preventDefault();
            $event.stopPropagation();
            runReport(report, true)
        }

        function runReport(selectedReport, isPreview) {
            let reportId = selectedReport.id;
            let data = {};

            if (selectedReport.parameterTemplate) {
                selectedReport.parameterTemplate.forEach(item => {
                    if (item.value && isDate(item.value)) {
                        item.value = moment(item.value).toISOString();
                    }

                    if (item.parameterName === 'carParkRefs') {
                        item.value = item.value.join(',');
                    }

                    data[item.parameterName] = item.value ? item.value : '';
                })
            }

            ReportsService.runReport(reportId, isPreview, data).then(simulate => {
                let newReport = simulate.data;

                vm.reports = vm.reports.map((report) => {
                    if (report.id === newReport.id) {
                        report = newReport;
                    }
                    return report;
                })

                vm.reports = splitResultToArray(vm.reports);
                vm.updateCounter++;
            });
        }

        function isDate(variable) {
            return variable.constructor.toString().indexOf("Date") > -1;
        }

        function downloadCSV(fileName, blob) {
            const a = document.createElement('a');
            a.download = fileName;
            a.href = URL.createObjectURL(blob);
            a.click();
            URL.revokeObjectURL(a.href);
        }

        function isVisible(btn, item) {
            return item.status !== DELETED_STATUS;
        }

        /**
         * Update data on pagination
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.visibleReports = UtilitiesService.getArrayPage(vm.reports, page, count);
        }

        function generatePermissionName(button) {
            return button.permissionAction;
        }

    }
})();
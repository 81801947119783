(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('imageProperties', imageProperties);

    function imageProperties() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/elements/image.tpl.html',
            replace: true,
            controller: imagePropertiesController,
            controllerAs: 'imagePropertiesCtrl',
            bindToController: true,
            scope: {
                item: '=',
                pages: '=pages'
            }
        };
    }

    function imagePropertiesController($scope, Cropper, ContentDataService) {
        const vm = this;
        $scope.cropper = {};

        vm.encodeImageFileAsURL = encodeImageFileAsURL;

        function encodeImageFileAsURL(imageFile) {

            Cropper.encode(imageFile).then(function(url) {
                var data = new FormData();
                data.append('image', imageFile);
                ContentDataService.upload(data).then(function(res) {
                    vm.item.src = res;
                    vm.item.croppedData.src = res;
                });

                var img = new Image();
                img.src = url;
            });
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('GeofenceDataService', GeofenceDataService);

    function GeofenceDataService(
        Restangular,
    ) {
        return {
            getGeofences,
            deleteGeofence,
            createGeofence,
            getGeofenceGroups,
            deleteGeofenceGroup,
            createGeofenceGroup,
            upload,
        };


        // Public methods

        /**
         * Returns geofences list
         */
        function getGeofences(data) {
            return Restangular.all('geofence').getList(data);
        }

        /**
         * Deletes geofence by it`s id
         *
         * @param {object} id
         */
        function deleteGeofence(id) {
            return Restangular.one('geofence', id).remove();
        }

        /**
         * Creates new geofence
         *
         * @param {object} data
         */
        function createGeofence(data) {
            return Restangular.all('geofence').post(data);
        }

        /**
         * Returns geofence groups list
         */
        function getGeofenceGroups(data) {
            return Restangular.all('geofence/group').getList(data);
        }

        /**
         * Deletes geofence group by it`s id
         *
         * @param {object} id
         */
        function deleteGeofenceGroup(id) {
            return Restangular.one('geofence/group', id).remove();
        }

        /**
         * Creates new geofence group
         *
         * @param {object} data
         */
        function createGeofenceGroup(data) {
            return Restangular.all('geofence/group').post(data);
        }

        /**
         * Uploads new geofence group
         *
         * @param {object} data
         */
        function upload(data) {
            return new Restangular.one('geofence/upload')
                .customPOST(data, '', undefined, {'Content-Type': undefined});
        }
    }
})();
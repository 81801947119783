(function() {
    'use strict';

    angular.module('beacon.app')
        .component('doubletsChecker', {
            templateUrl: '/assets/views/poi/doublets-check/doublets-checker.tpl.html',
            controller: DoubletsCheckerController
        });

    function DoubletsCheckerController(
        $stateParams,
        $translate,
        ListPaginationService,
        PoiImportDataService,
        DEFAULT_POI_FILTERS
    ) {
        const vm = this;

        let nameTranslation = 'Name';
        let kmShortcutTranslation = 'km';
        let mShortcutTranslation = 'm';
        let distanceTranslation = 'Distance';

        vm.$onInit = init;

        vm.loadDuplicationData = loadDuplicationData;
        vm.poiDismissCallback = poiDismissCallback;
        vm.typeFiltersChanged = typeFiltersChanged;

        vm.updateCounter = 0;
        vm.doubletsUpdateCounter = 0;
        vm.sameTypeOnly = false;
        vm.FILTER_ALL = -1;
        vm.selectedTypeFilter = vm.FILTER_ALL;
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '80',
                    title: item =>  {
                        return item.firstElementData.name;
                    }
                },
                {
                    name: 'SIMILARITY',
                    class: 'similarity-type-col',
                    width: '20',
                    title: checkSimilarity
                }
            ],
            itemClickCallback: selectPOI,
            updateCallback: updateDuplicatingPoisList,
            needRadiobutton: true,
        };

        vm.similarListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '70',
                    title: function (item) {
                        return item.doubletData.name;
                    }
                },
                {
                    name: 'DISTANCE',
                    class: 'similarity-column',
                    width: '15',
                    title: item => {
                        if (item.locationDistance < 1000) {
                            return `${Math.round(item.locationDistance)}${mShortcutTranslation}`;
                        } else {
                            return `${Math.round(item.locationDistance / 1000)}${kmShortcutTranslation}`;
                        }
                    }
                },
                {
                    name: 'NAME',
                    class: 'similarity-column',
                    width: '15',
                    title: item => {
                        return `${Math.round(item.nameSimilarity * 100)}%`;
                    }
                }
            ],
            itemClickCallback: selectDoublet,
            updateCallback: updatePoiDuplicatesList,
            needRadiobutton: true,
        };

        /**
         * Initialization method
         */
        function init() {
            vm.maxDistance = DEFAULT_POI_FILTERS.MAX_DISTANCE;
            vm.minNameSimilarity = DEFAULT_POI_FILTERS.MIN_NAME_SIMILARITY;

            $translate('NAME').then(translation => nameTranslation = translation);
            $translate('METERS_SHORTCUT').then(translation => mShortcutTranslation = translation);
            $translate('KILOMETERS_SHORTCUT').then(translation => kmShortcutTranslation = translation);
            $translate('DISTANCE').then(translation => distanceTranslation = translation);
        }

        /**
         * Check which of similarity filters works for this item
         * @param poiItem
         * @return {string}
         */
        function  checkSimilarity(poiItem) {
            let minDistance = Infinity;
            let maxNameSimilarity = 0;
            let result = '';
            poiItem.doublets.forEach(doublet => {
                if (doublet.locationDistance < minDistance) {
                    minDistance = doublet.locationDistance;
                }
                if (doublet.nameSimilarity > maxNameSimilarity) {
                    maxNameSimilarity = doublet.nameSimilarity;
                }
            });
            if (minDistance < vm.maxDistance) {
                result += (minDistance < 1000) ? `${distanceTranslation}: ${Math.round(minDistance)}${mShortcutTranslation} \n ` :
                    `${distanceTranslation}: ${Math.round(minDistance / 1000)}${kmShortcutTranslation} \n `;
            }
            if (maxNameSimilarity > (vm.minNameSimilarity / 100)) {
                result += `${nameTranslation}: ${Math.round(maxNameSimilarity * 100)}%`;
            }
            return result;
        }

        /**
         * Imports all POIs from the server
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function loadPOIs(page, itemsPerPage) {
            PoiImportDataService.importPOIs()
                .then(response => {
                    const result = response.plain();
                    vm.types = [];
                    result.forEach(poi => {
                        poi.lat = Number(poi.lat);
                        poi.lng = Number(poi.lng);
                        if (!vm.types.includes(poi.type)) {
                            vm.types.push(poi.type);
                        }
                    });
                    vm.poiItems = result;
                    loadDuplicationData(page, itemsPerPage);
                })
        }

        /**
         * Loads data about POI duplications
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function loadDuplicationData(page, itemsPerPage) {
            PoiImportDataService.getPoiDoublets(vm.maxDistance, vm.minNameSimilarity / 100)
                .then(response => {
                    vm.poiDoubletsData = response.plain();
                    vm.filteredDoubletsData = _.uniqBy(vm.poiDoubletsData, doublet => {
                        return doublet.firstElement;
                    });
                    fillDuplicatedPoisData();
                });
        }

        /**
         * Binding imported POIs data with duplications data, applies filters
         */
        function fillDuplicatedPoisData() {
            vm.duplicatedPois = [];
            vm.filteredDoubletsData.forEach(doublet => {
                const firstElementData = vm.poiItems.find(poi => {
                    if (vm.selectedTypeFilter !== vm.FILTER_ALL) {
                        return poi.id === doublet.firstElement && poi.type === vm.selectedTypeFilter;
                    } else {
                        return poi.id === doublet.firstElement;
                    }
                });
                if (firstElementData) {
                    const doublets = [];
                    vm.poiDoubletsData.forEach(doubletCase => {
                        if (doubletCase.firstElement === doublet.firstElement) {
                            const doubletData = vm.poiItems.find(poi => {
                                return poi.id === doubletCase.secondElement;
                            });
                            if (!checkFilters(firstElementData, doubletData)) {
                                return;
                            }
                            doublets.push({
                                locationDistance: doubletCase.locationDistance,
                                nameSimilarity: doubletCase.nameSimilarity,
                                doubletData
                            })
                        }
                    });
                    vm.duplicatedPois.push({
                        firstElement: doublet.firstElement,
                        firstElementData,
                        doublets
                    });
                }
            });

            if (vm.sameTypeOnly || vm.selectedTypeFilter !== vm.FILTER_ALL) {
                vm.duplicatedPois = vm.duplicatedPois.filter(poi => poi.doublets.length);
            }
            vm.duplicatedPoisCount = vm.duplicatedPois.length;
            vm.updateCounter++;
        }

        /**
         * Handler on type filters change
         */
        function typeFiltersChanged() {
            vm.selectedPoi = null;
            vm.selectedPoiDoublets = null;
            vm.selectedDoublet = null;
            fillDuplicatedPoisData();
        }

        /**
         * Checks if doublet case fits filters
         * @param firstElementData
         * @param secondElementData
         * @return {boolean}
         */
        function checkFilters(firstElementData, secondElementData) {
            const sameTypes = firstElementData.type === secondElementData.type;
            if (vm.selectedTypeFilter !== vm.FILTER_ALL) {
                return sameTypes && firstElementData.type === vm.selectedTypeFilter;
            } else if (vm.sameTypeOnly) {
                return sameTypes;
            } else {
                return true;
            }
        }

        /**
         * Updates duplications list
         * @param page
         * @param itemsPerPage
         * @param forceApi
         */
        function updateDuplicatingPoisList(page, itemsPerPage, forceApi = false) {
            if (!forceApi && vm.duplicatedPois !== undefined) {
                showDuplicatedPois(page, itemsPerPage);
            } else {
                loadPOIs(page, itemsPerPage);
            }
        }

        /**
         * Updates duplicates list
         * @param page
         * @param itemsPerPage
         */
        function updatePoiDuplicatesList(page, itemsPerPage) {
            const result = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.selectedPoiDoublets);
            vm.visiblePoiDuplicates = result.visibleItems;
        }

        /**
         * Check which duplicated POIs should be shown
         * @param page
         * @param itemsPerPage
         */
        function showDuplicatedPois(page, itemsPerPage) {
            const result = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.duplicatedPois);
            vm.visibleItems = result.visibleItems;
        }

        /**
         * Select POI handler
         * @param selectedPoi
         */
        function selectPOI(selectedPoi) {
            vm.selectedPoi = selectedPoi.firstElementData;
            vm.selectedPoiDoublets = selectedPoi.doublets;
            vm.selectedDoubletsCount = selectedPoi.doublets.length;
            vm.selectedDoublet = null;
            vm.doubletsUpdateCounter++;
            vm.poiMarkers = [];
            vm.poiMarkers.push({
                id: vm.selectedPoi.id,
                label: vm.selectedPoi.name,
                position: {
                    lat: vm.selectedPoi.lat,
                    lng: vm.selectedPoi.lng
                },
                selected: true,
            });
            vm.selectedPoiDoublets.forEach(doublet => {
                vm.poiMarkers.push({
                    id: doublet.doubletData.id,
                    label: doublet.doubletData.name,
                    position: {
                        lat: doublet.doubletData.lat,
                        lng: doublet.doubletData.lng
                    },
                    selected: false,
                });
            });
        }

        /**
         * Select POI doublet handler
         * @param selectedDoublet
         */
        function selectDoublet(selectedDoublet) {
            vm.selectedDoublet = selectedDoublet.doubletData;
            vm.poiMarkers = vm.poiMarkers.map(marker => {
                return {
                    id: marker.id,
                    label: marker.label,
                    position: {
                        lat: marker.position.lat,
                        lng: marker.position.lng
                    },
                    selected: marker.id === selectedDoublet.doubletData.id || marker.id === vm.selectedPoi.id,
                };
            });

        }

        /**
         * Handler on POI dismiss
         * @param id
         */
        function poiDismissCallback(id) {
            if (id === vm.selectedPoi.id) {
                vm.selectedPoi = null;
                vm.selectedPoiDoublets = null;
                vm.selectedDoublet = null;
            } else {
                vm.selectedPoiDoublets = vm.selectedPoiDoublets.filter(doublet => {
                    return doublet.doubletData.id !== id;
                });
                vm.selectedDoubletsCount = vm.selectedPoiDoublets.length;
            }
            loadDuplicationData();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ExternalCompaniesDataService', ExternalCompaniesDataService);

    function ExternalCompaniesDataService(
        Restangular,
    ) {
        return {
            getExternalCompanies,
            createExternalCompany,
            updateExternalCompany,
            deleteExternalCompany,
            getExternalCompanyById,
        };

        function getExternalCompanies(data) {
            return Restangular.all('sharePark/external-company').getList(data);
        }

        function getExternalCompanyById(id) {
            return Restangular.one(`sharePark/external-company/${id}`).get();
        }

        function createExternalCompany(data) {
            return Restangular.all('sharePark/external-company')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteExternalCompany(id) {
            return Restangular.one('sharePark/external-company', id).remove();
        }

        function updateExternalCompany(billingConfiguration, data) {
            data.append('_method', 'PUT');
            return billingConfiguration.customPOST(data, '', {}, { 'Content-Type': undefined });
        }
    }
})();
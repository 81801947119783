(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('rangesPicker', rangesPicker);

    function rangesPicker() {
        return {
            restrict: 'AE',
            templateUrl: '/assets/views/common/directives/ranges-picker/ranges-picker.tpl.html',
            replace: true,
            controller: RangesPickerController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                ranges: '=',
                dynamicRangeLimits: '=',
                editable: '<',
                onChangeCallback: '=',
            }
        };
    }

    function RangesPickerController() {
        const vm = this;

        vm.DYNAMIC_RANGE_TYPE = 0;
        vm.PREDEFINED_RANGE_TYPE = 1;

        vm.DEFAULT_LIMIT_TYPE = 0;
        vm.ABSOLUTE_LIMIT_TYPE = 1;

        vm.showAddRangeForm = false;

        vm.newRange = {
            minValue: 0,
            maxValue: 0
        };

        vm.rangeTypes = [
            {
                name: 'DYNAMIC_RANGE',
                id: vm.DYNAMIC_RANGE_TYPE
            },
            {
                name: 'PREDEFINED_RANGES',
                id: vm.PREDEFINED_RANGE_TYPE
            }
        ];

        vm.dynamicLimitsTypes = [
            {
                name: 'DEFAULT_LIMITS',
                id: vm.DEFAULT_LIMIT_TYPE
            },
            {
                name: 'ABSOLUTE_LIMITS',
                id: vm.ABSOLUTE_LIMIT_TYPE
            }
        ];

        // public methods
        vm.isPredefinedRangeSelected = isPredefinedRangeSelected;
        vm.formRangeText = formRangeText;
        vm.onRangeTypeChange = onRangeTypeChange;
        vm.saveRangeCallback = saveRangeCallback;
        vm.deleteRangeCallback = deleteRangeCallback;
        vm.dynamicRangeLimitsChange = dynamicRangeLimitsChange;

        init();

        /**
         * Forms string which describes range
         * @param range
         */
        function formRangeText(range) {
            if (!_.isNil(range.maxValue) && !_.isNil(range.minValue)) {
                return `${range.minValue}-${range.maxValue}`;
            } else {
                if (_.isNil(range.maxValue)) {
                    return `>${range.minValue}`;
                } else {
                    return `<${range.maxValue}`;
                }
            }
        }

        /**
         * Deletes range
         * @param index
         */
        function deleteRangeCallback(index) {
            vm.ranges.splice(index, 1);
            vm.onChangeCallback(vm.rangeType);
        }

        /**
         * Checks if predefined range type is selected
         * @return {boolean}
         */
        function isPredefinedRangeSelected() {
            return parseInt(vm.rangeType) === vm.PREDEFINED_RANGE_TYPE;
        }

        /**
         * Handles dynamic range limits change
         */
        function dynamicRangeLimitsChange() {
            if(vm.editable) {
                vm.onChangeCallback(vm.rangeType, vm.dynamicRangeForm.$valid);
            }
        }

        /**
         * Handles range type change
         */
        function onRangeTypeChange() {
            if (parseInt(vm.rangeType) === vm.PREDEFINED_RANGE_TYPE) {
                vm.onChangeCallback(vm.rangeType, true);
            } else {
                vm.onChangeCallback(vm.rangeType, vm.dynamicRangeForm.$valid);
            }
        }

        /**
         * Save range button handler
         * Adds range to the list
         */
        function saveRangeCallback() {
            const oneSideRange = angular.isNumber(vm.newRange.maxValue) !== angular.isNumber(vm.newRange.minValue);
            if (vm.addRangeForm.$valid) {
                if (((vm.newRange.maxValue !== null) || (vm.newRange.minValue !== null)) &&
                    (!oneSideRange ? (vm.newRange.maxValue > vm.newRange.minValue) : true)) {

                    let newRange = {};
                    if (oneSideRange) {
                        for (let key in vm.newRange) {
                            if (angular.isNumber(vm.newRange[key])) {
                                newRange[key] = vm.newRange[key];
                            }
                        }
                    } else {
                        newRange = angular.copy(vm.newRange)
                    }
                    vm.ranges.push(newRange);

                    vm.newRange.maxValue = vm.newRange.minValue = 0;
                    vm.showAddRangeForm = false;
                    vm.onChangeCallback(vm.rangeType);
                }
            }
        }

        /**
         * Initialization method
         */
        function init() {
            if (!!vm.ranges && !!vm.ranges.length) {
                vm.rangeType = vm.PREDEFINED_RANGE_TYPE;
            } else {
                vm.rangeType = vm.DYNAMIC_RANGE_TYPE;
            }
        }
    }

})();

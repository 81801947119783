(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newShareParkGuests', {
            templateUrl: '/assets/views/share-park/customers/guests/new/new-guests.tpl.html',
            controller: NewGuestsController
        });

    function NewGuestsController(
        $scope,
        $state,
        $stateParams,
        $translate,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
        PopupService,
        TenantHelper,
        UserUtilitiesService,
        DATETIME_FORMAT,
        TEXT_ANGULAR_OPTIONS_EXTENDED,
    ) {
        const vm = this;

        const { getCurrentTenant } = TenantHelper;
        const { createInvitation, updateInvitation, sendInvitations } = ShareParkDataService;
        const timeZoneId = UserUtilitiesService.timeZoneId();

        vm.state = StatesFactory.ShareParkGuestsStates.refresh();
        vm.state.type = $stateParams.type;

        vm.$onInit = init;

        vm.emailsString = '';

        vm.TEXT_ANGULAR_OPTIONS_EXTENDED = TEXT_ANGULAR_OPTIONS_EXTENDED;
        vm.carParksListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'car-park-title',
                    width: '100',
                    title: 'name',
                }
            ],
            updateCallback: () => {},
            needRadiobutton: true,
            itemClickCallback: selectCarPark,
            additionalData: {
                previewLayout: 'column'
            },
            initOnOpen: true
        };

        vm.showInputError = showInputError;
        vm.readEmailAddresses = readEmailAddresses;
        vm.onEmailTextChange = onEmailTextChange;
        vm.getDefaultTranslation = getDefaultTranslation;
        vm.onSubAreaSelect = onSubAreaSelect;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.invitationData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.CarParkInvitation();

            if (vm.isEditMode) {
                delete vm.carParksListData.itemClickCallback;
            }

            if (!vm.invitationData.email) {
                $translate('GUESTS_INVITATION_EMAIL_MOCKUP', {
                    carParkName: '{{carParkName}}',
                    startDate: '{{startDate}}',
                    endDate: '{{endDate}}',
                    link: '{{link}}',
                    tenantName: getCurrentTenant().name
                }, undefined, undefined, false, 'sanitize').then(translation => {
                    vm.invitationData.email = translation;
                });
            }

            loadCarParks();
        }

        function loadCarParks() {
            ShareParkDataService.getCarParks()
                .then(response => {
                    vm.carParks = response.plain();

                    if (vm.carParks.length) {
                        selectCarPark(vm.invitationData.carParkId
                            ? vm.carParks.find(cap => cap.id === vm.invitationData.carParkId)
                            : vm.carParks[0]);
                        canFinish();
                    }
                });
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.newGuestsForm
                && vm.newGuestsForm[name].$dirty
                && vm.newGuestsForm[name].$invalid;
        }

        function readEmailAddresses() {
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const emailAddresses = vm.emailsString.split(';')
                .map(str => str.trim())
                .filter(str => emailRegex.test(str));

            vm.invitationData.guests = emailAddresses.map(emailAddress => {
                const user = vm.invitationData.guests.find(
                    user => user.email === emailAddress
                );

                return user ? user : { email: emailAddress };
            });
        }

        function onSubAreaSelect() {
            canFinish();
        }

        function selectCarPark(carPark) {
            if (vm.selectedCarPark && vm.selectedCarPark.id !== carPark.id) {
                vm.invitationData.subAreaId = null;
            }
            vm.selectedCarPark = carPark;
            vm.invitationData.carParkId = carPark ? carPark.id : null;
            vm.selectedCarPark.sub_areas.sort((a, b) => getDefaultTranslation(a.label).localeCompare(getDefaultTranslation(b.label)));

            canFinish();
        }

        function getDefaultTranslation(field) {
            return Object.values(field)[0];
        }

        function onEmailTextChange() {
            const errorField = [
                'link',
                'carParkName',
                'startDate',
                'endDate',
            ].find(field =>
                !vm.invitationData.email.includes(`{{${field}}}`)
            );

            if (!errorField) {
                vm.newGuestsForm.emailContent.$setValidity('templateValidity', true);
                return;
            }

            vm.newGuestsForm.emailContent.$setValidity('templateValidity', false);

            $translate('THERE_SHOULD_BE_PLACEHOLDER_IN_EMAIL', { placeholder: `{{${errorField}}`})
                .then(translation => {
                    vm.templateValidityErrorMessage = translation;
                }).catch(() => {
                vm.templateValidityErrorMessage = '';
            });
        }

        /**
         * On finish handler
         * @param {boolean} sendInvitations
         */
        function onFinish(sendInvitations) {
            const successHandler = sendInvitations ? sendGuestsInvitations : onSuccess;
            const formData = new FormData();

            const invitationData = angular.copy(vm.invitationData);

            // for startDate and endDate inputs tenant timezone should be used
            // moment from datepicker has current user timezone as default
            // so it is converted to string and then new moment created with correct timezone value
            const startDateString = moment(invitationData.startDate).format(DATETIME_FORMAT);
            const endDateString = moment(invitationData.endDate).format(DATETIME_FORMAT);

            // but for storing these values in db they should be converted to UTC
            invitationData.startDate = moment.tz(startDateString, timeZoneId).utc().format(DATETIME_FORMAT);
            invitationData.endDate = moment.tz(endDateString, timeZoneId).utc().format(DATETIME_FORMAT);

            formData.append('invitation', angular.toJson(invitationData));

            if (!vm.isEditMode) {
                createInvitation(formData)
                    .then(successHandler);
            } else {
                updateInvitation(vm.invitationData, formData)
                    .then(successHandler)
            }
        }

        function sendGuestsInvitations(invitationId) {
            $translate('DO_YOU_REALLY_WANT_TO_SEND_INVITATIONS', { count: vm.invitationData.guests.length })
                .then(text => {
                    PopupService.showConfirmationPopup({
                        text,
                        okButtonText: 'OK',
                        cancelButtonText: 'CANCEL'
                    }, function() {
                        ShareParkDataService.sendInvitations(invitationId)
                            .then(() => {
                                PopupService.showAlertPopup({
                                    text: 'INVITATIONS_SENT_SUCCESSFULLY',
                                    okButtonText: 'OK'
                                }).then(onSuccess);
                            })
                            .catch((err) => {
                                PopupService.showAlertPopup({
                                    text: 'ERROR_WHILE_SENDING_INVITATIONS',
                                    okButtonText: 'OK'
                                }).then(onSuccess);
                                throw err;
                            });
                    });
                });
        }

        function onSuccess() {
            $state.go('app.shareParkGuests', {
                paginationData: $stateParams.paginationData
            });
        }

        function canFinish() {
            vm.state.canFinish = vm.newGuestsForm
                && vm.newGuestsForm.$valid
                && vm.invitationData.carParkId
                && vm.invitationData.subAreaId
                && vm.invitationData.guests.length;
        }

        $scope.$watch(function() {
            return vm.newGuestsForm && vm.newGuestsForm.$valid;
        }, canFinish);

        $scope.$watch(function() {
            return vm.invitationData.guests && vm.invitationData.guests.length;
        }, canFinish);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );

        $scope.$watch(
            () => vm.state.send,
            newValue => newValue && onFinish(!!newValue)
        );
    }
})();
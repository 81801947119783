(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenCampaign', {
                templateUrl: '/assets/views/campaign/screen/screen.tpl.html',
                controller: ScreenCampaignController,
            });


    function ScreenCampaignController(
        $q,
        $scope,
        $state,
        $stateParams,
        PopupService,
        CampaignDataService,
        ContentDataService,
        ShareDataService,
        UtilitiesService,
        PermissionsService,
        LocationDataService,
        ScreenHelper,
        SCREEN_CAMP_SORTING_PARAMS
    ) {
        const vm = this;
        const DATE_FORMAT = 'MMMM D, YYYY (HH:mm)';
        const CONTROLLER_STATUS_DELETED = 2;

        const {
            generateScreenCampaignPermission,
            actions
        } = PermissionsService;

        // public methods
        vm.quickCampaignUpdate = quickCampaignUpdate;
        vm.addScreenCampaign = addScreenCampaign;
        vm.updateAllCampaigns = updateAllCampaigns;
        vm.onOrderChange = onOrderChange;

        vm.createScreenCampaignPermission = generateScreenCampaignPermission(actions.create);

        vm.$onInit = onInit();

        // public properties
        vm.SORTING_PARAMS = Object.values(SCREEN_CAMP_SORTING_PARAMS);
        vm.orderBy = SCREEN_CAMP_SORTING_PARAMS.PRIORITY.field;
        vm.reverseOrder = false;
        vm.updateCounter = 0;
        vm.showAllUpdateBtn = false;
        vm.campaigns = [];
        vm.controllers = [];
        vm.totalItems = $stateParams.paginationData ? $stateParams.paginationData.count : 0;
        vm.searchQuery = '';
        vm.updateCampaigns = updateCampaigns;

        vm.listData = {
            columns: [
                {
                    name: 'Name',
                    class: 'campaignTitle',
                    width: '30',
                    title: 'name'
                },
                {
                    name: 'CONTENTS',
                    class: 'contents',
                    width: '10',
                    title: item => item.contents.length,
                },
                {
                    name: 'SCREENS',
                    class: 'screens',
                    width: '10',
                    title: item => item.locations.length,
                },
                {
                    name: 'START_DATE',
                    class: 'startDate',
                    width: '20',
                    title: item => moment.utc(item.started_at).local().format(DATE_FORMAT)
                },
                {
                    name: 'END_DATE',
                    class: 'endDate',
                    width: '20',
                    title: item => moment(item.started_at).isAfter(item.finished_at)
                        ? '-' : moment.utc(item.finished_at).local().format(DATE_FORMAT)
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'quickUpBtn',
                        screensOnly: true,
                        callback: quickCampaignUpdate,
                        permissionAction: actions.modify
                    },
                    {
                        class: 'deleteBtn',
                        callback: deleteCampaign,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'copyBtn',
                        callback: copyCampaign,
                        permissionAction: actions.create
                    },
                    {
                        class: 'editBtn',
                        callback: updateCampaign,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateCampaigns,
            generatePermissionName: generatePermissionName
        };

        // private methods
        function quickCampaignUpdate($event, campaignItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            ShareDataService.updateSharedCampaign(campaignItem.id).then(() => {
                let page = Number(angular.element('.pagination-page.active a')[0].innerHTML);
                let itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));
                updateCampaigns(page, itemsPerPage);
            });
        }

        function updateAllCampaigns() {
            ShareDataService.updateAllSharedCampaigns()
                .then(() => {
                    let page = Number(angular.element('.pagination-page.active a')[0].innerHTML);
                    let itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));
                    updateCampaigns(page, itemsPerPage);
                });
        }

        /**
         * Updates list of campaigns
         */
        function updateCampaigns(page, itemsPerPage) {
            const requestParams = {
                page,
                itemsPerPage,
                type: 1,
                searchQuery: vm.searchQuery
            };
            if (vm.reverseOrder) {
                requestParams.reverseOrder = vm.reverseOrder
            }
            if (vm.orderBy === SCREEN_CAMP_SORTING_PARAMS.PRIORITY.field) {
                requestParams.sortByPriority = true;
            } else {
                requestParams.orderBy = vm.orderBy;
            }

            $q.all([
                CampaignDataService.getCampaigns(requestParams),
                LocationDataService.getControllerDetails(),
            ])
            .then(([responseCampaigns, responseControllers]) => {
                vm.controllers = responseControllers.plain().list;
                vm.campaigns = responseCampaigns;
                vm.totalItems = responseCampaigns.count;
                vm.showAllUpdateBtn = false;
                vm.campaigns.forEach((item) => {
                    if (Number(item.new_content) === 1) {
                        vm.showAllUpdateBtn = true;
                    }

                    item.contents.forEach((content) => {
                        content.title = JSON.parse(content.title);
                        content.message = JSON.parse(content.message);
                        content.data = JSON.parse(content.data);
                    });

                    item.locations = item.locations.filter(location => {
                        const controller = vm.controllers.find(controller => controller.id === location.controllerRef);
                        location.statusName = ScreenHelper.getStatusName(controller.status);
                        return controller.status !== CONTROLLER_STATUS_DELETED;
                    });
                });
            })
            .catch(console.error.bind(console));
        }

        /**
         * Removes campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function deleteCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CAMPAIGN',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, () => {
                CampaignDataService.deleteCampaign(item.id)
                    .then(() => {
                        vm.totalItems -= 1;
                    })
                    .catch(console.error.bind(console));
            });

        }

        function addScreenCampaign() {
            $state.go('app.addScreenCampaign', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Edit campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function updateCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            let page = Number(angular.element('.pagination-page.active a')[0].innerHTML);
            let itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));

            $state.go('app.editScreenCampaign', {
                data: item,
                paginationData: {
                    page,
                    itemsPerPage,
                    count: vm.totalItems
                }
            });
        }

        /**
         * Copy campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function copyCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_CAMPAIGN',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }, () => {
                CampaignDataService.getLocations({ campaignID: item.id })
                    .then(response => {
                        const data =  _.omit(item, ['id']);
                        data.locationsIDs = response;
                        $state.go('app.addScreenCampaign', {
                            data,
                            paginationData: {
                                page: 1,
                                itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                                count: vm.totalItems
                            }
                        });
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Initialization method
         */
        function onInit() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Generates permission name for each button on campaign screen accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateScreenCampaignPermission(button.permissionAction);
        }

        function onOrderChange() {
            vm.updateCounter++;
        }
    }
}());

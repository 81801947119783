(function() {
    'use strict';

    angular.module('beacon.app')
        .component('quizContentType', {
            templateUrl: '/assets/views/content/elements/types/quiz/quiz.tpl.html',
            controller: QuizContentType,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<'
            }
        });

    function QuizContentType($scope,
                             ModelsFactory, StatesFactory, StorageFactory,
                             ContentDataProcessingService, ContentHelper,
                             ContentDataService, CONTENT_TYPES, QUIZ_TYPE) {
        const vm = this;

        const {processDefaultContent, processFeedbackContent} = ContentDataProcessingService;
        const { isFixedInfoType } = ContentHelper;

        vm.state = StatesFactory.QuizStates.refresh();
        vm.allUserAttributeTypes = new Set();

        function init() {
            vm.state.type = StatesFactory.ContentStates.type;

            vm.state.haveNext = true;
            vm.STORAGE = StorageFactory.Storage('Content');
            vm.quizInfo = vm.STORAGE.get('quizInfo');

            if (CONTENT_TYPES.QUIZ === vm.contentData.content_type_id) {
                vm.contentData.message.quiz.type = QUIZ_TYPE.QUIZ;
            } else {
                vm.contentData.message.quiz.type = QUIZ_TYPE.FEEDBACK;
            }

            if (vm.quizInfo) {
                processQuizInfo(vm.quizInfo);
            } else {
                ContentDataService.contents({contentTypeId: CONTENT_TYPES.QUIZ})
                    .then(function(contents) {
                        vm.quizInfo = vm.STORAGE.set('quizInfo', contents);

                        processQuizInfo(contents);
                    })
                    .catch(console.error.bind(console));
            }

        }

        function processQuizInfo(contents) {
            contents.forEach(function(content) {
                const message = JSON.parse(content.message);
                message.quiz.questions.forEach(function(question) {
                    vm.allUserAttributeTypes.add(question.userAttributeType);
                });
            });
        }

        /**
         * Checks if quiz data is valid and can be sent to the server
         */
        function canFinish() {
            const questions = vm.contentData.message.quiz.questions;
            const defaultLangId = vm.langArray[0].id;
            const canFinish = validQuestionsCount(questions) && questions.every(question => {
                const title = question.title;
                const titleValid = !!title[defaultLangId];
                const votingOptions = question.votingOptions;
                const votingOptionsValid = votingOptions.length && votingOptions.every(option => {
                    const answer = option.answer;
                    return !!answer[defaultLangId];
                });
                return titleValid && votingOptionsValid;
            });

            Object.assign(vm.state, {
                canFinish: canFinish
            });
        }

        /**
         * Checks if questions count is valid
         * if feedback type is fixed there can be only one question
         *
         * @param {array} questions
         * @return {boolean}
         */
        function validQuestionsCount(questions) {
            return isFixedInfoType(vm.contentData) ? (questions.length === 1) : questions.length;
        }


        function setListeners() {
            $scope.$on('content-finish', onFinish);
        }

        function setWatchers() {
            $scope.$watch(angular.bind(vm, function() {
                return vm.state.finish;
            }), function(newValue) {
                if (newValue) {
                    StatesFactory.ContentStates.finish++;
                }
            });

            $scope.$watch(angular.bind(vm, function() {
                return vm.contentData.message.quiz.questions;
            }), canFinish, true);

            $scope.$watch(angular.bind(vm, function() {
                return vm.contentData.data.infotainmentType;
            }), canFinish, true);
        }

        /**
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            const contentData = (CONTENT_TYPES.QUIZ === vm.contentData.content_type_id) ?
                angular.copy(vm.contentData) : processFeedbackContent(vm.contentData);

            Object.keys(contentData.message.message).forEach(langId => {
                if (!contentData.title[langId]) {
                    contentData.title[langId] = '';
                }
            })

            let formData = new FormData();
            let contentFormData = processDefaultContent(
                formData,
                contentData,
                vm.contentForm
            );

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    formData,
                    contentData: contentFormData
                });
            }
        }

        init();
        setListeners();
        setWatchers();
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app').service('MsOAuthService', MsOAuthService);

    function MsOAuthService($http) {
        return {
            getEntraClientId,
            loginWithMicrosoft,
        };

        /**
         * Get "Entra ID" application's "client id" parameter
         * 
         * @returns {string}
         */
        function getEntraClientId() {
            return $http.get('/api/oauth/ms/client-id').then(({ data }) => data);
        }

        /**
         * Authorize with Microsoft Graph API
         * 
         * @param {string} accessToken
         * @returns {token: string} token
         */
        function loginWithMicrosoft(accessToken) {
            return $http.post('/api/oauth/ms/login', { accessToken }).then(({ data }) => data);
        }
    }
})();

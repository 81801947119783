(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedbackForms', {
            templateUrl: '/assets/views/feedback/feedback-forms/feedback-forms.tpl.html',
            controller: FeedbackFormsController,
        });

    function FeedbackFormsController(
        $state,
        $translate,
        PopupService,
        UtilitiesService,
        FeedbackConfigService,
    ) {
        const vm = this;

        vm.$onInit = onInit;
        vm.onCreate = onCreate;

        vm.itemsAll = [];
        vm.items = [];

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'name',
                    width: '25',
                    title: item => item.name,
                },
                {
                    name: 'ALIAS',
                    class: 'alias',
                    width: '25',
                    title: item => item.alias,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: onDelete,
                    },
                    {
                        class: 'editBtn',
                        callback: onEdit,
                    },
                    {
                        class: 'campaignBtn',
                        callback: onPreview,
                    },
                ]
            },
            updateCallback: updateList,
        };

        function onInit() {
            FeedbackConfigService.getAll()
                .then(response => {
                    vm.itemsAll = response.data;
                });
        }

        function onDelete($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $translate('ARE_YOU_SURE')
                .then(text => {
                    PopupService.showConfirmationPopup({
                        text,
                        okButtonText: 'DELETE',
                        cancelButtonText: 'CANCEL'
                    }).then(() => {
                        FeedbackConfigService.remove(item.id).then(onInit);
                    });
                });
        }

        function onEdit($event, item) {
            $event.preventDefault();
            $event.stopPropagation();
            $state.go('app.feedbackFormsEdit', {
                feedbackFormConfig: item,
            });
        }

        function onPreview($event, item) {
            $event.preventDefault();
            $event.stopPropagation();
            $state.go('app.feedbackForm', {
                type: item.alias
            });
        }

        function onCreate() {
            $state.go('app.feedbackFormsEdit');
        }

        function updateList(page = 1, itemsPerPage = 25) {
            vm.items = UtilitiesService.getArrayPage(vm.itemsAll, page, itemsPerPage);
        }
    }
}());
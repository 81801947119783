(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ContentImageProcessingService', contentImageProcessingService);

    function contentImageProcessingService() {
        return {
            onImage,
            onImageDelete
        };
    }

    /**
     * Image file input handler
     * @param {object} contentData
     * @param {object} imageFile
     * @param {boolean} uploadToS3 Need upload to S3
     * @returns {*}
     */
    function onImage(contentData, imageFile, uploadToS3) {
        const imageSrc = contentData.data.darkMode ? 'image_src_dark' : 'image_src';
        const fileName = contentData.data.darkMode ? 'image_dark' : 'image';
        const urlCreator = window.URL || window.webkitURL;
        contentData[fileName] = uploadToS3 ? imageFile : null;
        contentData[imageSrc] = uploadToS3
            ? urlCreator.createObjectURL(imageFile)
            : imageFile.name;
    }

    /**
     * Image delete click handler
     * @param {object} contentData
     */
    function onImageDelete(contentData) {
        const imageSrc = contentData.data.darkMode ? 'image_src_dark' : 'image_src';
        const fileName = contentData.data.darkMode ? 'image_dark' : 'image';
        delete contentData[fileName];
        contentData[imageSrc] = null;
    }
})();

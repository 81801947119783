(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('segmentationCategoriesList', segmentationCategoriesList);

    function segmentationCategoriesList() {
        return {
            restrict: 'AE',
            templateUrl: '/assets/views/common/directives/audience-picker/segmentation-categories-list/segmentation-categories-list.tpl.html',
            replace: true,
            controller: SegmentationCategoriesListController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                allAudienceOptions: '=',
                audienceOptions: '=',
                audiencesData: '=',
                updateRecipientsCount: '=',
                segmentationGroups: '<',
                disabled: '=',
            }
        };
    }

    function SegmentationCategoriesListController(
        $scope,
        $rootScope,
        $timeout,
        AudiencesService,
        PermissionsService,
        PopupService,
        SEGMENT_TYPES,
        SEGMENT_TYPES_ICONS,
        SEGMENT_FILTERS_TITLES,
    ) {
        const vm = this;

        const COMPLICATED_TYPES = {
            [SEGMENT_TYPES.LOYALTIES]: [
                SEGMENT_TYPES.LOYALTY_SUBSCRIPTION,
                SEGMENT_TYPES.LOYALTY_COUNTER_RANGE,
                SEGMENT_TYPES.LOYALTY_ACCOUNT_RANGE,
                SEGMENT_TYPES.LOYALTY_TIER_STATUS
            ],
            [SEGMENT_TYPES.FEEDBACK_ATTRIBUTES]: [
                SEGMENT_TYPES.NUMBER_RANGES,
                SEGMENT_TYPES.CALCULATED,
            ],
            [SEGMENT_TYPES.PREDEFINED]: [
                SEGMENT_TYPES.PREDEFINED_RANGES,
            ]
        };
        const {
            getLimits,
            isGroupSegmentationDataSelected,
            isRandomAudienceTypeSelected,
            mergeAudiences,
            updateSlider,
        } = AudiencesService;

        // public properties
        vm.segmentFilters = {};
        vm.selectedSegmentationGroups = [];
        vm.SEGMENT_FILTERS_TITLES = SEGMENT_FILTERS_TITLES;
        vm.SEGMENT_TYPES_ICONS = SEGMENT_TYPES_ICONS;

        // public methods
        vm.toggleFilter = toggleFilter;
        vm.toggleCategoryVisible = toggleCategoryVisible;
        vm.toggleGroupVisible = toggleGroupVisible;
        vm.isSegmentsGroupSelected = isSegmentsGroupSelected;
        vm.isDynamicRangeType = AudiencesService.isDynamicRangeType;
        vm.segmentTypePermission = PermissionsService.generateSegmentTypeFilterPermission;

        /**
         * Filters change handler
         * @param filter
         */
        function toggleFilter(filter) {
            if (Object.keys(COMPLICATED_TYPES).some(typeId => parseInt(typeId) === filter.value)) {
                Object.keys(COMPLICATED_TYPES).forEach(typeId => {
                    COMPLICATED_TYPES[typeId].forEach(nestedType => {
                        vm.segmentFilters[nestedType] = vm.segmentFilters[typeId];
                    });
                });
            }
            const filtersSelected = Object.keys(SEGMENT_TYPES).some(key => {
                return vm.segmentFilters[SEGMENT_TYPES[key]];
            });
            if (filtersSelected) {
                vm.audienceOptions = vm.allAudienceOptions.filter(audience => vm.segmentFilters[audience.categoryType]);
                applyFiltersToGroups();
            } else {
                vm.audienceOptions = vm.allAudienceOptions;
                vm.visibleSegmentationGroups = vm.segmentationGroups;
            }
            updateSlider();
        }

        /**
         * Toggles audience filter category visibility
         *
         * @param {number} audience
         */
        function toggleCategoryVisible(audience) {
            if (audience.categoryType === SEGMENT_TYPES.RANDOM) {
                processRandomAudienceType(audience);
            } else {
                processSimpleAudienceType(audience);
            }
        }

        /**
         * Processes audience when selected (except random audience type)
         * @param audience
         */
        function processSimpleAudienceType(audience) {
            let audiencesData = vm.audiencesData;
            if (audiencesData[audience.id]) {
                delete audiencesData[audience.id];
            } else {
                audiencesData[audience.id] = {
                    audienceId: audience.id,
                    audienceValues: [],
                    audienceStars: {},
                    audienceLocationTypes: {},
                    audienceLocationWhen: false,
                    preferenceData: {},
                };

                if (audience.categoryType === SEGMENT_TYPES.GEOFENCES) {
                    audience.filterValues.forEach(filterValue => {
                        audiencesData[audience.id].audienceValues.push(filterValue.id);
                    })
                }
                if (vm.isDynamicRangeType(audience)) {
                    let limits = getLimits(audience);
                    if (!audiencesData[audience.id].rangesData) {
                        audiencesData[audience.id].rangesData = {
                            minValue: limits.minValue,
                            maxValue: limits.maxValue,
                            outOfRange: false,
                        };
                    }
                    updateSlider();
                }
            }
            vm.updateRecipientsCount();
            checkGroupsSelected();
        }

        /**
         * Process random audience type when clicked
         * @param audience
         */
        function processRandomAudienceType(audience) {
            if (audience.id === vm.selectedRandomSegmentId) {
                vm.audiencesData = {};
                vm.selectedRandomSegmentId = null;
                vm.disabled = false;
                vm.updateRecipientsCount();
            } else {
                PopupService.showConfirmationPopup({
                    text: 'If you select random audience all other audiences data will be cleared and predefined segments data can\'t be changed.',
                    okButtonText: 'Yes',
                    cancelButtonText: 'No'
                }, function() {
                    const filterValueParams = audience.filterValues[0].filterValueParams;
                    vm.audiencesData = {};
                    vm.selectedRandomSegmentId = audience.id;
                    vm.audiencesData[audience.id] = {
                        audienceId: audience.id,
                        audienceValues: [],
                        audienceStars: {},
                        audienceLocationTypes: {},
                        audienceLocationWhen: false,
                        preselectedAudiencesData: filterValueParams.segmentationData,
                    };
                    if (filterValueParams.randomizationTime) {
                        vm.audiencesData[audience.id].randomizationTime = moment(moment.utc(filterValueParams.randomizationTime).toDate()).local();
                    }
                    vm.disabled = true;
                    updateSlider();
                    vm.updateRecipientsCount();
                });
            }
        }

        /**
         * On group select handler
         * @param clickedSegmentsGroup
         */
        function toggleGroupVisible(clickedSegmentsGroup) {
            const index = _.findIndex(vm.selectedSegmentationGroups, group => {
                return group.id === clickedSegmentsGroup.id
            });
            if (index !== -1) {
                vm.selectedSegmentationGroups.splice(index, 1);
                deselectSegmentationGroup(clickedSegmentsGroup);
            } else {
                vm.selectedSegmentationGroups.push(angular.copy(clickedSegmentsGroup));
                Object.keys(clickedSegmentsGroup.message.segmentationData).forEach(key => {
                    if (vm.audiencesData[key]) {
                        vm.audiencesData[key] = _.mergeWith(vm.audiencesData[key],
                            clickedSegmentsGroup.message.segmentationData[key], mergeAudiences);
                    } else {
                        vm.audiencesData[key] = angular.copy(clickedSegmentsGroup.message.segmentationData[key]);
                    }
                    if (vm.isDynamicRangeType(vm.audienceOptions.find((audience) => audience.id === parseInt(key)))) {
                        updateSlider();
                    }
                })
            }
            checkGroupsSelected();
            vm.updateRecipientsCount();
        }

        /**
         * Filters segments groups list
         * Groups all segments of those are allowed by the filters should be visible only
         */
        function applyFiltersToGroups() {
            vm.visibleSegmentationGroups = vm.segmentationGroups.filter(group => {
                return Object.keys(group.message.segmentationData).every(key => {
                    const audienceData = vm.allAudienceOptions.find(audience => parseInt(audience.id) === parseInt(key));
                    return vm.segmentFilters[audienceData.categoryType];
                });
            })
        }

        /**
         * Clear audience data set by this group
         * @param segmentationGroup
         */
        function deselectSegmentationGroup(segmentationGroup) {
            const segmentationData = segmentationGroup.message.segmentationData;
            Object.keys(segmentationData).forEach(key => {
                const audienceData = vm.audiencesData[key];
                if (!_.isEmpty(audienceData.rangesData)) {
                    delete vm.audiencesData[key];
                    return;
                }
                audienceData.audienceValues = _.difference(audienceData.audienceValues, segmentationData[key].audienceValues);

                if (!_.isEmpty(audienceData.audienceValues) && !_.isEmpty(audienceData.audienceLocationTypes)) {
                    Object.keys(segmentationData[key].audienceLocationTypes).forEach(locationTypeKey => {
                        delete audienceData.audienceLocationTypes[locationTypeKey];
                    });
                }

                if (!_.isEmpty(audienceData.audienceStars)) {
                    Object.keys(audienceData.audienceStars).forEach((filterKey) => {
                        audienceData.audienceStars[filterKey] = _.difference(audienceData.audienceStars[filterKey],
                            segmentationData[key].audienceStars[filterKey]);
                        if (_.isEmpty(audienceData.audienceStars[filterKey])) {
                            delete audienceData.audienceStars[filterKey];
                        }
                    });
                }

                if (_.isEmpty(audienceData.audienceValues) && _.isEmpty(audienceData.audienceStars)) {
                    delete vm.audiencesData[key];
                }
            });
        }

        /**
         * Checks if segments group is selected
         * @param segmentsGroup
         * @return {boolean}
         */
        function isSegmentsGroupSelected(segmentsGroup) {
            return vm.selectedSegmentationGroups.some(group => {
                return group.id === segmentsGroup.id;
            });
        }

        /**
         * Updates selected groups list when audiences data is changed
         */
        function checkGroupsSelected() {
            vm.segmentationGroups.forEach(segmentationGroup => {
                const segmentationData = segmentationGroup.message.segmentationData;
                const index = _.findIndex(vm.selectedSegmentationGroups, group => {
                    return group.id === segmentationGroup.id
                });
                const isGroupSelected = Object.keys(segmentationData).every(key => {
                    if (segmentationData[key] && vm.audiencesData[key]) {
                        return isGroupSegmentationDataSelected(segmentationData[key], vm.audiencesData[key])
                    } else {
                        return false;
                    }
                });
                if (isGroupSelected && index === -1) {
                    vm.selectedSegmentationGroups.push(angular.copy(segmentationGroup));
                } else if (!isGroupSelected && index !== -1) {
                    vm.selectedSegmentationGroups.splice(index, 1);
                }
            })
        }

        /**
         * Initialization method
         */
        function init() {
            Object.keys(SEGMENT_TYPES).forEach(key => {
                vm.segmentFilters[SEGMENT_TYPES[key]] = false;
            });
            if (isRandomAudienceTypeSelected(vm.audiencesData)) {
                vm.selectedRandomSegmentId = parseInt(Object.keys(vm.audiencesData)[0]);
            }
        }

        init();

        $scope.$watch(angular.bind(vm, function() {
            return vm.audiencesData;
        }), function() {
            if (vm.segmentationGroups) {
                checkGroupsSelected();
            }
        }, true);

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentationGroups;
        }), function() {
            if (vm.segmentationGroups) {
                vm.visibleSegmentationGroups = vm.segmentationGroups;
            }
            if (!_.isEmpty(vm.audiencesData) && !_.isEmpty(vm.segmentationGroups)) {
                checkGroupsSelected();
            }
        });
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('DomainsListController', DomainsListController);

    function DomainsListController(TenantDataService, LoginService, PopupService, UserDataService) {
        let currentUserInfo = null;

        const vm = this;

        vm.tenants = [];
        vm.totalItems = 0;
        vm.currentTenants = [];
        vm.listData = {
            columns: [
                {
                    name: 'DOMAIN_NAME',
                    class: 'domain-name',
                    title: function(item) {
                        return item.name;
                    }
                }
            ],
            updateCallback: updateTenantsList,
            additionalData: {
                onSignInBtnClick: onSubdomainSignInBtnClick
            }
        };
        vm.paginationData = {
            itemsPerPage: 100
        };

        /**
         * Initialization method
         */
        function init() {
            UserDataService.loadUserData().then(function(userData){
                currentUserInfo = userData;
            });

            reloadTenantsList();
        }

        /**
         * Updates pagination
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateTenantsList(page, itemsPerPage) {
            vm.currentTenants = vm.tenants.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        /**
         * Reloads tenants list
         */
        function reloadTenantsList() {
            TenantDataService.getTenants({"include[]": ["subdomains"]}).then(function(response) {
                vm.tenants = response.plain();
                vm.totalItems = response.count;

                vm.listData.updateCallback(1, vm.paginationData.itemsPerPage);
            });
        }

        /**
         * Subdomain sign in button click handler
         * @param {number} subdomainId
         */
        function onSubdomainSignInBtnClick(subdomainId) {
            if (subdomainId === currentUserInfo.subdomain_id) {
                PopupService.showAlertPopup({
                    text: 'YOU_ARE_ALREADY_LOGGED_INTO_THIS_SUBDOMAIN',
                    okButtonText: 'OK'
                });
                return;
            }

            LoginService.authorize(currentUserInfo.id, subdomainId, function() {
                location.reload();
            });
        }

        init();
    }
}());

/**
 * @var SunCalc https://github.com/mourner/suncalc
 */
(function() {
    'use strict';

    angular.module('trmServices').service('DayNightService', function (
        $interval,
        $rootScope,
        LogService,
    ) {
        const log = (...args) => {
            LogService.colored('orange', '#FFF', 'Day Night Service |', ...args);
        };

        const EVERY_MINUTE = 60 * 1000;

        let _initialized = false;
        let _scheme = null;

        // Berlin
        let _lat = 52.52;
        let _lng = 13.40;

        /**
         * @param {number} lat
         * @param {number} lng
         */
        function init(lat, lng) {
            if (_initialized) {
                throw new Error('DayNightService already initialized');
            }

            _lat = lat;
            _lng = lng;

            _checkForChangesAndBroadcast();
            $interval(_checkForChangesAndBroadcast, EVERY_MINUTE);
        }

        /**
         * @return {'default'|'night'}
         */
        function currentColorScheme() {
            const altitude = _getSunAltitude(_lat, _lng);

            log('Sun altitude', altitude, 'degrees', _lat, _lng);

            return altitude > 0 ? 'default' : 'night';
        }

        /**
         * @private
         */
        function _checkForChangesAndBroadcast() {
            const newScheme = currentColorScheme();

            if (newScheme !== _scheme) {
                _scheme = newScheme;

                log('Scheme changed to', newScheme);

                $rootScope.$broadcast('DayNightService@change', newScheme);
            }
        }

        /**
         * @return {Number} - Sun altitude in degrees
         * @private
         */
        function _getSunAltitude(lat, lng) {
            const { altitude } = SunCalc.getPosition(new Date(), lat, lng);
            return Math.trunc(altitude * (180 /Math.PI) * 100) / 100;
        }

        return {
            currentColorScheme,
            init,
        }
    });
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('GoogleMapsRequestService', GoogleMapsRequestService);

    function GoogleMapsRequestService(
        GOOGLE_MAPS_API_KEY,
        ) {

        function getTimezoneByCoordinates(data) {
            const date = new Date();
            const timestamp = date.getTime() / 1000;

            const url = `https://maps.googleapis.com/maps/api/timezone/json` +
                `?location=${data.location}&timestamp=${timestamp}&key=${GOOGLE_MAPS_API_KEY}`;

            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                xhr.onload = function(){
                    if (this.status !== 200) {
                        return reject({
                            status: this.status,
                            statusText: xhr.statusText
                        })
                    }
                    const response = JSON.parse(xhr.responseText);
                    if (!response.timeZoneId){
                        return reject('Timezone id missing');
                    }

                    resolve(response.timeZoneId);
                };
                xhr.send();
            });
        }

        return {
            getTimezoneByCoordinates,
        };
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LocationGroupAddController', LocationGroupAddController);

    function LocationGroupAddController(
        $scope,
        $stateParams,
        $state,
        $timeout,
        $q,
        $translate,
        LocationDataService,
        GeofenceDataService,
        UtilitiesService,
        ModelsFactory,
        locationGroupAddState,
        locationTabState,
        GeofenceLocationPickerPopup,
        ScreensFilterPopup,
        LOCATION_GROUPS_TYPES,
        ScreenHelper,
        ACTIVATION_STATUS,
    ) {
        const vm = this;

        // public methods
        vm.onNameChangeCallback = onNameChangeCallback;
        vm.allowSelectByGeofence = allowSelectByGeofence;
        vm.updatePolylineCallback = updatePolylineCallback;
        vm.updateSelectedLocations = updateSelectedLocations;
        vm.markerClickCallback = markerClickCallback;
        vm.openFilter = openFilter;
        vm.locationItemsList = locationItemsList;
        vm.openFullscreenLocationPicker = openFullscreenLocationPicker;
        vm.invertSelection = invertSelection;
        vm.unselectAll = unselectAll;
        vm.onSearchFilterChange = onSearchFilterChange;

        // public properties
        vm.state = locationGroupAddState;
        vm.onlySelected = false;
        vm.searchName = '';
        const NO_REF = -1;

        init();

        vm.checkboxListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'checkbox-list-item-title',
                    width: '',
                    title: item => item.name,
                },
                {
                    name: 'STATUS',
                    class: 'checkbox-list-item-title',
                    width: '20',
                    translate: true,
                    title: item => item.details
                        ? ScreenHelper.getStatusName(item.details.status)
                        : ''
                }
            ],
            header: false,
            itemClickCallback: showLocationDetails,
            checkboxClickCallback: toggleLocation
        };

        vm.locationGroupsCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'checkbox-list-item-title',
                    width: '100',
                    title: function (item) {
                        return `Group - ${item.name}`;
                    }
                },
            ],
            header: false,
            itemClickCallback: showLocationGroupDetails,
            checkboxClickCallback: toggleLocationGroup
        };

        vm.geofenceGroupingListData = {
            itemsList: {
                columns: [
                    {
                        name: 'NAME',
                        class: 'checkbox-list-item-title',
                        width: '',
                        title: 'name',
                    },
                ],
                trackBy: 'id',
                orderBy: 'name',
            },
            groupsList: {
                columns: [
                    {
                        name: 'TITLE',
                        class: 'checkbox-list-item-title',
                        width: '100',
                        translate: true,
                        getTranslationParams: ({name}) => ({name}),
                        title: () => 'GROUP_NAME',
                    },
                ],
                trackBy: 'id',
            },
            updateSelectedItemsListCallback: updateSelectedGeofences
        };

        vm.selectedLocationGroups = [];
        vm.visibleLocations = [];
        vm.markers = [];
        vm.showScreenDetails = false;
        vm.allowMapEditing = false;
        vm.polyline = '';
        vm.geofences = [];
        vm.selectedGeofences = [];

        function init() {
            const promisesArray = [
                {
                    requestPromise: LocationDataService.getSections(),
                    responseHandler: responseSectionsHandler
                }];
            if ($stateParams.group_type === LOCATION_GROUPS_TYPES.SCREEN) {
                promisesArray.push({
                    requestPromise: LocationDataService.getControllerDetails(),
                    responseHandler: responseControllerDetailsHandler
                });
            } else {
                promisesArray.push({
                    requestPromise: LocationDataService.getBeaconDetails(),
                    responseHandler: responseControllerDetailsHandler
                });
            }
            $q.all(promisesArray.map(promise => promise.requestPromise)).then(responses => {
                const isIncorrectResponse = !responses || !_.isArray(responses)
                    || !responses.length || responses.length !== promisesArray.length;
                if (isIncorrectResponse) {
                    return;
                }
                responses.forEach((response,key) => {
                    promisesArray[key].responseHandler(response);
                });
                assignCoordinatesToLocation();
                allowSelectByGeofence();

                if ($stateParams.group_type === LOCATION_GROUPS_TYPES.SCREEN) {
                    vm.locationItems = vm.locationItems.filter(item =>
                        item.details && [
                            ACTIVATION_STATUS.ACTIVE.value,
                            ACTIVATION_STATUS.INACTIVE.value
                        ].includes(item.details.status));
                }

                vm.locationItems = UtilitiesService.sortByStringProperty(vm.locationItems, 'name');

                vm.visibleLocations = vm.locationItems;

            }).catch(console.error.bind(console));

            vm.state.type = $stateParams.type;
            vm.locationGroupData = (vm.state.type === "new")
                ? new ModelsFactory.LocationGroup(): $stateParams.data;
            vm.locationGroupData.type = $stateParams.group_type;
            vm.state.canCreateGroup = !!vm.locationGroupData.name;
            setLabels();
            loadGeofences();
        }

        function locationItemsList() {
            return vm.onlySelected
                ? vm.locationGroupData.locations
                : vm.locationItems;
        }

        function assignCoordinatesToLocation() {
            if (vm.locationGroupData.type === LOCATION_GROUPS_TYPES.SCREEN) {
                vm.locationItems.forEach(item => {
                    const currentLocationDetails = vm.locationDetails.find(
                        detailsItem => item.controllerRef === parseInt(detailsItem.id)
                    );
                    if (!!currentLocationDetails) {
                        item.lat = parseFloat(currentLocationDetails.screen.lat);
                        item.lng = parseFloat(currentLocationDetails.screen.lng);
                        item.details = currentLocationDetails;
                        item.name = currentLocationDetails.name;
                    }
                });
            } else {
                vm.locationItems.forEach(item => {
                    const currentLocationDetails = vm.locationDetails.find(
                        detailsItem => item.beaconRef === parseInt(detailsItem.beaconReferenceId)
                    );
                    if (!!currentLocationDetails) {
                        const geoLocation = currentLocationDetails.geoLocation.split(',');
                        item.lat = parseFloat(geoLocation[0]);
                        item.lng = parseFloat(geoLocation[1]);
                    }
                });
            }
        }

        /**
         * Locations loading handler
         * @param response
         */
        function responseSectionsHandler(response) {
            vm.allLocationItems = response.list;
            vm.allLocationItems = vm.allLocationItems.filter(item => {
                if ($stateParams.group_type === LOCATION_GROUPS_TYPES.SCREEN) {
                    return item.controllerRef > NO_REF;
                } else {
                    return item.beaconRef > NO_REF;
                }
            });
            vm.locationItems = UtilitiesService.distinctByProp(vm.allLocationItems, 'locationId');

            vm.locationGroups =
                _.chain(vm.locationItems)
                    .map('groups')
                    .flatten()
                    .uniqBy('id')
                    .sortBy('name')
                    .value();
            if (vm.state.type !== "new") {
                vm.locationGroups = vm.locationGroups.filter(group => group.id !== vm.locationGroupData.id);
                vm.locationGroupData.locations = vm.locationItems.filter(location => {
                    return vm.locationGroupData.locations.some(selectedLocation => selectedLocation.id === location.id);
                });
                toggleLocation();
            }
        }

        /**
         * Controller Details loading handler
         * @param response
         */
        function responseControllerDetailsHandler(response) {
            vm.locationDetails = response.list;
        }

        /**
         * Allow geofence drawing to select locations
         */
        function allowSelectByGeofence() {
            vm.allowMapEditing = true;
        }

        /**
         * Handles location group creation
         */
        function locationGroupResponseHandler() {
            let data;
            if (vm.locationGroupData.type === LOCATION_GROUPS_TYPES.SCREEN) {
                data = {
                    selectedTabIndex: locationTabState.views.LOCATIONS.tabs.SCREENS.index,
                    selectedSubtabIndex: locationTabState.views.LOCATIONS.tabs.SCREENS
                        .subtabs.SCREEN_GROUPS.index,
                };
            } else {
                data = {
                    selectedTabIndex: locationTabState.views.LOCATIONS.tabs.BEACONS.index,
                    selectedSubtabIndex: locationTabState.views.LOCATIONS.tabs.BEACONS
                        .subtabs.BEACON_GROUPS.index,
                };
            }
            $state.go('app.location', {
                paginationData: $stateParams.paginationData,
                data
            });
        }

        /**
         * Set page title and group name label according to group type
         */
        function setLabels() {
            if (vm.locationGroupData.type === LOCATION_GROUPS_TYPES.SCREEN) {
                vm.pageTitle = 'SCREEN_GROUP_INFO';
                vm.groupNameLabel = 'SCREEN_GROUP_NAME';
                vm.detailsColumnTitle = 'SCREEN_DETAILS';
            } else {
                vm.pageTitle = 'BEACON_GROUP_INFO';
                vm.groupNameLabel = 'BEACON_GROUP_NAME';
                vm.detailsColumnTitle = 'BEACON_DETAILS';
            }
        }

        /**
         * Handler on location select/deselect
         */
        function toggleLocation(item) {
            updateSelectedGroups();
            if (!!item) {
                showLocationDetails(item);
            }
        }

        /**
         * Updates selected groups list according to selected locations
         */
        function updateSelectedGroups() {

            // creates new selectedLocationsGroupsIds array according to selected locations list changes
            let selectedLocationsGroupsIds =
                _.chain(vm.locationItems)
                    .map(location => _.map(location.groups, 'id'))
                    .flatten()
                    .countBy()
                    .pickBy((count, key) => {
                        return count === _.filter(vm.locationGroupData.locations, location => {
                            return !!_.find(location.groups, ['id', parseInt(key)])
                        }).length;
                    })
                    .keys()
                    .map(_.parseInt)
                    .value();
            vm.selectedLocationGroups = _.filter(vm.locationGroups,
                group => selectedLocationsGroupsIds.indexOf(group.id) !== -1);
        }

        /**
         * Show location details and set markers on the map
         * @param {object} item selected location item
         */
        function showLocationDetails(item) {
            vm.allowMapEditing = false;
            if (vm.locationGroupData.type === LOCATION_GROUPS_TYPES.SCREEN) {
                vm.showScreenDetails = true;
            }
            vm.selectedLocation = angular.copy(item);
            if (item.lat) {
                vm.selectedLocation.markers = [{
                        label: item.name,
                        position: {
                            lat: item.lat,
                            lng: item.lng
                        },
                        selected: !!vm.locationGroupData.locations.find(location => location.id === item.id),
                        locationId: item.id
                }];
            }
        }

        /**
         * Handler on location group select/deselect
         * @param {object} groupItem Selected group item
         */
        function toggleLocationGroup(groupItem) {
            vm.allowMapEditing = false;
            vm.showScreenDetails = false;
            let groupLocations = _.filter(vm.locationItems,
                location => !!_.find(location.groups, ["id", groupItem.id]));
            if (_.find(vm.selectedLocationGroups, ["id", groupItem.id])){
                vm.locationGroupData.locations = _.unionWith(vm.locationGroupData.locations, groupLocations, _.isEqual);
            } else {
                vm.locationGroupData.locations = _.pullAllBy(vm.locationGroupData.locations, groupLocations, "id");
            }
            toggleLocation();
            showLocationGroupDetails(groupItem, groupLocations);
        }

        /**
         * Show details of location group and set markers for group locations on the map
         * @param {object} groupItem
         * @param {array} groupLocations
         */
        function showLocationGroupDetails(groupItem, groupLocations) {
            vm.allowMapEditing = false;
            if (!angular.isDefined(groupLocations)) {
                groupLocations = _.filter(vm.locationItems,
                    location => !!_.find(location.groups, ["id", groupItem.id]));
            }
            vm.selectedLocation = angular.copy(groupItem);
            vm.selectedLocation.markers = [];
            vm.selectedLocation.name = `Group - ${vm.selectedLocation.name}`;
            groupLocations.forEach(groupLocation => {
                if (angular.isDefined(groupLocation.lat)) {
                    vm.selectedLocation.markers.push({
                        label: groupLocation.name,
                        position: {
                            lat: groupLocation.lat,
                            lng: groupLocation.lng
                        },
                        selected: !!vm.locationGroupData.locations.find(location => location.id === groupLocation.id),
                        locationId: groupLocation.id
                    });
                }
            })
        }

        /**
         * Handles marker click on the map
         * @param {object} locationMarker
         */
        function markerClickCallback(locationMarker) {
            const currentLocation = vm.locationItems.find(location => location.id === locationMarker.locationId);
            if (locationMarker.selected) {
                vm.locationGroupData.locations.push(currentLocation);
            } else {
                let locationIndex = vm.locationGroupData.locations.findIndex(location => location.id === currentLocation.id);
                if (locationIndex !== -1) {
                    vm.locationGroupData.locations.splice(locationIndex, 1);
                }
            }
            updateSelectedGroups();
            $scope.$apply();
        }


        /**
         * Updates polyline
         * @param {string} newPolyline
         * @return {void}
         */
        function updatePolylineCallback(newPolyline) {
            $timeout(() => {
                vm.polyline = newPolyline;
                const selectedGeofencePolylines = newPolyline ? newPolyline.split('|') : [];
                vm.selectedGeofences = vm.geofences.filter(geofence => {
                    return geofence.polyline.split('|')
                        .every(polyline => selectedGeofencePolylines.includes(polyline))
                });
            });
        }

        function updateSelectedLocations(selectedLocations) {
            $timeout(() => {
                vm.locationGroupData.locations = selectedLocations;
            });
        }

        /**
         * Save/finish action callback
         */
        function onFinish() {
            const locationGroupData = angular.copy(vm.locationGroupData);
            locationGroupData.locations = vm.allLocationItems.filter(item => {
                return vm.locationGroupData.locations.some(selectedItem => {
                    return selectedItem.locationId === item.locationId;
                })
            });
            let promise;
            if (vm.state.type === 'new') {
                promise = LocationDataService.createLocationsGroup(locationGroupData);
            } else {
                promise = locationGroupData.put();
            }
            promise.then(locationGroupResponseHandler).catch(console.error.bind(console));
        }

        /**
         * Handles group name change
         */
        function onNameChangeCallback() {
            vm.state.canCreateGroup = !!vm.locationGroupData.name;
        }

        /**
         * Open popup with screens filter
         */
        function openFilter() {
            ScreensFilterPopup.show(vm.locationItems)
                .then(response => {
                    if (response) {
                        vm.locationGroupData.locations = response;
                        updateSelectedGroups();
                    }
                });
        }

        function loadGeofences() {
            GeofenceDataService.getGeofences().then(response => {
                vm.geofences = response;
            });
        }

        function updateSelectedGeofences(selectedGeofences, updateSelectedLocations = true) {
            vm.selectedGeofences = selectedGeofences;
            vm.polyline = selectedGeofences.map(geofence => geofence.polyline)
                .join('|') || '';
            $scope.$broadcast('redraw-polyline', { polyline: vm.polyline, updateSelectedLocations });
        }

        function openFullscreenLocationPicker() {
            const selectedGeofenceIds = vm.selectedGeofences.map(({ id }) => id);
            GeofenceLocationPickerPopup.show(vm.locationItems, vm.locationGroupData.locations, selectedGeofenceIds).then(result => {
                $timeout(() => {
                    vm.locationGroupData.locations = result.locations;
                    const selectedGeofences = vm.geofences.filter(geofence => {
                        return result.selectedGeofenceIds.includes(geofence.id);
                    });
                    updateSelectedGroups();

                    vm.selectedGeofences = selectedGeofences;
                    vm.polyline = selectedGeofences.map(geofence => geofence.polyline)
                        .join('|') || '';
                });
            })
        }

        function invertSelection() {
            vm.locationGroupData.locations = vm.locationItems.filter(location => {
                return vm.locationGroupData.locations.every(selectedLocation => {
                    return selectedLocation.id !== location.id;
                })
            });
            updateSelectedGroups();
            vm.selectedLocation && !vm.allowMapEditing && showLocationDetails(vm.selectedLocation);
        }

        function unselectAll() {
            $timeout(() => {
                vm.locationGroupData.locations = [];
                vm.selectedLocationGroups = [];
                vm.selectedLocation && !vm.allowMapEditing && showLocationDetails(vm.selectedLocation);
            });
        }

        function onSearchFilterChange() {
            vm.visibleLocations = vm.searchName
                ? vm.locationItems.filter(location =>
                    location.name.toUpperCase().includes(vm.searchName.toUpperCase())
                ) : vm.locationItems;
        }

        $scope.$watch(() => vm.state.finish, (newValue) => {
            if (newValue) {
                onFinish();
            }
        });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('pageEditorElementProperties', pageEditorElementProperties);

    function pageEditorElementProperties() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/elements/elements.tpl.html',
            replace: true,
            controller: pageEditorElementPropertiesController,
            controllerAs: 'pageEditorElementPropertiesCtrl',
            bindToController: true,
            scope: {
                pages: '=pages'
            }
        };
    }

    function pageEditorElementPropertiesController($scope) {
        const vm = this;
        $scope.$on('item-click', function(event, args) {
            vm.item = args.item;
        });
    }
}());

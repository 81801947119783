(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ScreenCampaignHelper', ScreenCampaignHelper);

    function ScreenCampaignHelper() {

        const ALL_DAYS_FLAG = 9;
        const ENDLESS_DATE_STRING = '1980-01-01 00:00:00';
        const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

        function createCampaignFormData(campaign) {

            const {
                finished_at,
                name,
                description,
                contents,
            } = campaign;

            const data = {
                started_at: moment(campaign.started_at).utc().format(DATE_TIME_FORMAT),
                finished_at,
                name,
                description,
                audiences: [],
                contents,
                locations: campaign.sections.map(section => Number(section.locationId)),
                send_time: JSON.stringify(campaign.send_time),
                show_days: JSON.stringify(campaign.show_days),
                campaign_type: 1,
                default: campaign.default
            };

            let formData = new FormData();

            for (let prop in data) {
                if (data.hasOwnProperty(prop)) {
                    formData.append(prop, data[prop]);
                }
            }

            formData.append('id', campaign.id);

            return formData;
        }

        return {
            ALL_DAYS_FLAG,
            ENDLESS_DATE_STRING,
            DATE_TIME_FORMAT,
            createCampaignFormData,
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewIotDeviceToolbarController', NewIotDeviceToolbarController);

    function NewIotDeviceToolbarController($state, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.IotDeviceStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.iotDevices', { iotEntitiesMode: false});
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltyCampaignPreview', {
            templateUrl: '/assets/views/content/elements/preview/loyalty-campaign/loyalty-campaign.tpl.html',
            controller: LoyaltyCampaignPreview,
            bindings: {
                contentData: '='
            }
        });

    function LoyaltyCampaignPreview() {
        const vm = this;

        const langId = vm.contentData.language_id;
        const loyaltyCampaign = vm.contentData.selectedInfotainment;

        vm.title = loyaltyCampaign.campaignLabel[langId];
        vm.description = loyaltyCampaign.description[langId];
    }
})();
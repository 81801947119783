(function() {
    'use strict';

    angular.module('beacon.app')
        .component('durationDateTimePicker', {
            templateUrl: '/assets/views/common/components/duration-date-time-picker/duration-date-time-picker.tpl.html',
            controller: DurationDateTimePickerController,
            bindings: {
                form: '<?',
                dateTime: '<',
                onDurationChange: '=',                  // callback, takes duration parameter
                onTimeChange: '=',                      // callback, takes new time value as parameter
                disabled: '<',
                parentController: '<',
            }
        });

    function DurationDateTimePickerController($scope, DateHelper, UtilitiesService) {
        const vm = this;

        const { getTimeDiff } = DateHelper;

        vm.$onInit = init;

        // public methods
        vm.onTimeDiffChange = onTimeDiffChange;
        vm.showInputError = showInputError;

        /**
         * Initialization method
         */
        function init() {

            // default values
            vm.days = vm.minutes = vm.hours = 0;

            findDifferences();
            onTimeDiffChange();
        }

        /**
         * Get differences between current date and vm.dateTime
         */
        function findDifferences() {
            if (vm.dateTime) {
                const diff = getTimeDiff(vm.dateTime);
                vm.days = diff.days;
                vm.hours = diff.hours;
                vm.minutes = diff.minutes;
            }
        }

        /**
         * Handler on time change
         */
        function onTimeDiffChange() {
            const duration = _.pick(vm, ['days', 'hours', 'minutes']);

            if (angular.isFunction(vm.onDurationChange)) {
                vm.onDurationChange(duration);
            }

            if (angular.isFunction(vm.onTimeChange)) {
                vm.parentController ?
                    vm.onTimeChange.call(vm.parentController, UtilitiesService.getTimeByDiff(duration)) :
                    vm.onTimeChange(UtilitiesService.getTimeByDiff(duration));
            }
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.form[name].$dirty && vm.form[name].$invalid;
        }

        /**
         * Set watchers
         */
        $scope.$watch(() => vm.dateTime, findDifferences);
    }
})();
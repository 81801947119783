(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedbackTickets', {
            templateUrl: '/assets/views/feedback/feedback-tickets/feedback-tickets.tpl.html',
            controller: FeedbackTicketsController,
        });

    function FeedbackTicketsController(
        $scope,
        $http,
        $state,
        $timeout,
        UtilitiesService,
        FeedbackHelper,
        FeedbackTicketService,
    ) {
        const vm = this;
        const STATUS = FeedbackHelper.STATUS;
        const PRIORITY = FeedbackHelper.PRIORITY;
        let searchQueryTimeout = null;

        vm.$onInit = onInit;
        vm.onSearchQueryChange = onSearchQueryChange;
        vm.onCreate = onCreate;

        vm.itemsAll = [];
        vm.items = [];
        vm.activeStatuses = [STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.RESOLVED];
        vm.activePriorities = [PRIORITY.LOW, PRIORITY.MEDIUM, PRIORITY.HIGH, PRIORITY.CRITICAL];
        vm.searchQuery = '';
        vm.statuses = FeedbackHelper.getStatusesList();
        vm.priorities = FeedbackHelper.getPrioritiesList();

        vm.listConfig = {
            columns: [
                {
                    name: 'ID',
                    class: 'id feedback-tickets__list-col',
                    width: '5',
                    title: item => '#' + item.id,
                },
                {
                    name: 'SUBJECT',
                    class: 'name feedback-tickets__list-col',
                    width: '25',
                    title: item => item.subject,
                },
                {
                    name: 'FORM',
                    class: 'feedback-tickets__list-col',
                    width: '15',
                    title: item => item.feedback_form_config && item.feedback_form_config.name,
                },
                {
                    name: 'AUTHOR',
                    class: 'feedback-tickets__list-col',
                    width: '10',
                    title: item => item.user.first_name + ' ' + item.user.last_name,
                },
                {
                    name: 'STATUS',
                    class: 'status feedback-tickets__list-col',
                    width: '10',
                    translate: true,
                    title: item => FeedbackHelper.statusName(item),
                    after: item => isNew(item) ? `<span class="label label-success">NEW</span>` : '',
                },
                {
                    name: 'PRIORITY',
                    class: 'feedback-tickets__list-col',
                    width: '10',
                    translate: true,
                    colorIcon: item => FeedbackHelper.priorityColor(item),
                    title: item => FeedbackHelper.priorityName(item),
                },
                {
                    name: 'CREATED',
                    class: 'feedback-tickets__list-col',
                    width: '15',
                    translate: true,
                    title: item => moment.utc(item.created_at).local().format('MMMM D, YYYY (HH:mm)'),
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'openBtn',
                        callback: onOpen,
                    },
                ]
            },
            updateCallback: updateList,
        };

        function onInit() {
            $scope.$watch(
                () => vm.activeStatuses.length + vm.activePriorities.length,
                () => loadItems(),
            );
        }

        function onSearchQueryChange() {
            if (searchQueryTimeout) {
                $timeout.cancel(searchQueryTimeout);
            }
            searchQueryTimeout = $timeout(loadItems, 500);
        }

        function onOpen(event, feedbackTicket) {
            event.stopPropagation();
            event.preventDefault();

            $state.go('app.feedbackTicket', {
                id: feedbackTicket.id
            });
        }

        function onCreate() {
            $state.go('app.feedbackCreate');
        }

        function loadItems() {
            FeedbackTicketService.getAll(vm.activeStatuses.join(','), vm.activePriorities.join(','), vm.searchQuery)
                .then(response => {
                    vm.itemsAll = response.data;
                });
        }

        function updateList(page = 1, itemsPerPage = 25) {
            vm.items = UtilitiesService.getArrayPage(vm.itemsAll, page, itemsPerPage);
        }

        function isNew(ticket) {
            const hoursAgo = moment().diff(moment(ticket.created_at), 'hours');
            return hoursAgo < 12 && ticket.status === STATUS.OPEN;
        }
    }
}());
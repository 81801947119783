(function() {
    'use strict';

    angular.module('beacon.app')
        .component('audioPicker', {
            templateUrl: '/assets/views/common/components/audio-picker/audio-picker.tpl.html',
            controller: AudioPickerController,
            bindings: {
                contentForm: '<',
                lang: '<',
                audio: '<',
                audioSrc: '<',
                isLanguageSensitive: '<',
                onChangeCallback: '&',
                state: '<',
            }
        });

    function AudioPickerController($scope, $timeout) {
        const vm = this;

        // public properties
        vm.onAudio = onAudio;
        vm.clearAudio = clearAudio;
        vm.deleteAudio = deleteAudio;
        vm.onAudioModeChange = onAudioModeChange;

        /**
         * Audio file input handler
         * @param {object} blob
         */
        function onAudio(blob) {
            if (blob) {
                if (vm.isLanguageSensitive) {
                    if (vm.audio) {
                        vm.audio[vm.lang] = blob;
                    } else {
                        vm.audio = {
                            [vm.lang]: blob,
                        }
                    }
                    if (vm.audioSrc) {
                        delete vm.audioSrc[vm.lang];
                    }
                } else {
                    vm.audio = blob;
                    vm.audioSrc = null;
                }

                setCurrentAudio();
                toggleAudioPreview();
            } else {
                angular.element('input[type="file"].audio').val(null);
            }
            onDataChanged();
        }

        function deleteAudio() {
            vm.currentAudio = '';
            if (vm.isLanguageSensitive && vm.audioSrc[vm.lang]) {
                delete vm.audioSrc[vm.lang];
            } else if (!vm.isLanguageSensitive && vm.audioSrc) {
                vm.audioSrc = null;
            }
            onDataChanged();
        }

        /**
         * Audio mode change handler
         */
        function onAudioModeChange() {
            let audio;

            if (vm.isLanguageSensitive) {
                audio = vm.audio;
                vm.audio = {};

                if (audio) {
                    vm.audio[vm.lang] = audio;
                }
            } else {
                audio = vm.audio ? vm.audio[vm.lang] : null;
                vm.audio = audio || null;
            }
            vm.audioSrc = null;

            setCurrentAudio();
            toggleAudioPreview();
            onDataChanged();
        }

        function clearAudio() {
            angular.element('input[type="file"].audio').val(null);
            angular.element('input[type="file"].fileName_audio').val(null);
            vm.currentAudio = null;

            if (vm.isLanguageSensitive) {
                delete vm.audio[vm.lang];
            } else {
                vm.audio = null;
            }
            onDataChanged();
        }

        /**
         * Sets current audio file name
         */
        function setCurrentAudio() {
            if (vm.audio) {
                $timeout(function() { // workaround to force view re-render
                    vm.currentAudio = vm.isLanguageSensitive
                        ? vm.audio[vm.lang]
                            ? vm.audio[vm.lang].name : ''
                        : vm.audio.name;
                });
            }
        }

        /**
         * Toggles audio preview element state
         */
        function toggleAudioPreview() {
            let audioElement,
                src = null;

            if (vm.state.type === 'edit') {
                audioElement = $('audio');

                if (audioElement.length) {
                    if (vm.audioSrc && vm.isLanguageSensitive) {
                        src = typeof vm.audioSrc === 'object' ? vm.audioSrc[vm.lang] : null;
                    } else {
                        src = typeof vm.audioSrc === 'string' ? vm.audioSrc : null;
                    }

                    if (src) {
                        audioElement.show();
                        audioElement.find('source').attr('src', src);
                        audioElement[0].load();
                    } else {
                        audioElement.hide();
                    }
                }
            }
        }

        /**
         * Handles audio data change
         */
        function onDataChanged() {
            const data = {
                audio: vm.audio,
                audio_src: vm.audioSrc,
                language_sensitive_audio: vm.isLanguageSensitive
            };
            vm.onChangeCallback({data});
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.lang;
        }), function() {
            setCurrentAudio();
            toggleAudioPreview();
        });
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('totalPurchases', {
            templateUrl: '/assets/views/share-park/dashboard/widgets/total-purchases/total-purchases.tpl.html',
            controller: TotalPurchasesController,
            bindings: {
                purchases: '<',
                timeRange: '<',
            }
        });

    function TotalPurchasesController(
        $scope,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.totalPurchases = [];

        vm.onTimeRangeChange = updateTotalPurchases;

        function init() {
            updateTotalPurchases();
        }

        function updateTotalPurchases() {
            const purchasesInRange = getPurchasesInRange();
            const totalPurchases = {};

            purchasesInRange.forEach(purchase => {
                if (!purchase.currencyCode) {
                    return;
                }

                if (!angular.isNumber(totalPurchases[purchase.currencyCode])) {
                    totalPurchases[purchase.currencyCode] = 0;
                }
                totalPurchases[purchase.currencyCode] += angular.isNumber(purchase.discountedPrice)
                    ? purchase.discountedPrice
                    : purchase.originalPrice;
            });

            vm.totalPurchases = Object.keys(totalPurchases).map(currencyCode => ({
                currencyCode,
                sum: totalPurchases[currencyCode] / 100,
            })).sort((a, b) => b.sum - a.sum);
        }

        /**
         * Return array of purchases in range
         * @return {Array}
         */
        function getPurchasesInRange() {
            if (vm.timeRange.diffValue) {
                const minDate = moment().subtract(vm.timeRange.diffValue, vm.timeRange.diffUnit);

                return vm.purchases.filter(purchase => purchase.purchaseDate.isAfter(minDate));
            }

            return vm.purchases;
        }

        $scope.$watch(
            () => vm.timeRange,
            () => updateTotalPurchases()
        )
    }
})();
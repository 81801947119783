(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poiContentPreview', {
            templateUrl: '/assets/views/poi/elements/preview/poi-preview.tpl.html',
            controller: PoiContentPreview,
            bindings: {
                contentData: '=',
                openingHours: '<',
            }
        });

    function PoiContentPreview(UtilitiesService) {
        const vm = this;

        const { prearrangeText } = UtilitiesService;

        vm.getMessageText = getMessageText;
        vm.prearrangeText = prearrangeText;

        /**
         * Returns message text
         * @returns {string}
         */
        function getMessageText() {
            return vm.contentData.message[vm.contentData.language_id] || '';
        }
    }
})();

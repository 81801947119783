(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newScenario', {
            templateUrl: '/assets/views/campaign/scenario/new/new-scenario.tpl.html',
            controller: NewScenarioController
        });

    function NewScenarioController(
        $q,
        $scope,
        $state,
        $stateParams,
        LocationDataService,
        DigitalDisplayHelper,
        Restangular,
        ScenarioDataService,
        ModelsFactory,
        StatesFactory,
        UtilitiesService,
        DATETIME_FORMAT,
        ACTIVATION_STATUS,
        ScreenHelper,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.state = StatesFactory.ScenarioStates.refresh();
        vm.currentDate = new Date().setSeconds(0, 0);
        vm.searchName = '';

        // public methods
        vm.addAssignment = addAssignment;
        vm.onDurationChange = onDurationChange;
        vm.isScreensColumnVisible = isScreensColumnVisible;
        vm.filterScreens = updateAvailableLocationsList;

        vm.assignmentListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'assignment-name-column',
                    width: '70',
                    title: 'name',
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteAssignment,
                    },
                ]
            },
            itemClickCallback: onAssignmentSelect,
            isSelected: isAssignmentSelected,
            template: 'scenario-assignment-edit/scenario-assignment-edit',
            needRadiobutton: true,
            hideHeader: true,
        };

        vm.contentListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'content-name-column',
                    width: '100',
                    title: item =>  {
                        return item.title[item.language_id];
                    }
                }
            ],
            additionalData: {
                generatePreview: DigitalDisplayHelper.generatePreview
            },
            itemClickCallback: onContentSelect,
            isSelected: isContentSelected,
            template: 'digital-display-info',
            needRadiobutton: true,
            hideHeader: true,
        };

        vm.locationGroupingListData = {
            itemsList: {
                columns: [
                    {
                        name: 'NAME',
                        class: 'checkbox-list-item-title',
                        width: '',
                        title: 'name',
                    },
                    {
                        name: 'STATUS',
                        class: '',
                        width: '20',
                        translate: true,
                        title: item => item.details ? ScreenHelper.getStatusName(item.details.status) : 'N/A'
                    }
                ],
                trackBy: 'locationId',
                orderBy: 'name',
            },
            groupsList: {
                columns: [
                    {
                        name: 'TITLE',
                        class: 'checkbox-list-item-title',
                        width: '100',
                        translate: true,
                        getTranslationParams: ({name}) => ({name}),
                        title: () => 'GROUP_NAME',
                    },
                ],
                trackBy: 'id',
            },
            updateSelectedItemsListCallback: updateSelectedLocationsList
        };

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.scenarioData = (vm.state.type === "new" && !$stateParams.data)
                ? new ModelsFactory.Scenario(): $stateParams.data;
            vm.activateScenario = vm.scenarioData.active_till && moment().isBefore(vm.scenarioData.active_till);
            if (!vm.activateScenario) {
                vm.scenarioData.active_till = null;
            }
            vm.selectedLocationGroups = [];
            vm.selectedLocations = [];
            getScenarioContents();
            getLocationsList();
        }

        /**
         * Get list of contents which can be attached to scenario
         */
        function getScenarioContents() {
            ScenarioDataService.getScenarioContents()
                .then((response) => {
                    vm.interactiveContents = response.map(content => {
                        const contentData = content.plain();
                        contentData.title = angular.fromJson(content.title);
                        contentData.message = angular.fromJson(content.message);
                        return contentData;
                    });
                });
        }

        /**
         * Get list of locations (screens)
         * @return {*}
         */
        function getLocationsList() {
            $q.all([
                LocationDataService.getScreens(),
                LocationDataService.getControllerDetails(),
            ]).then(response => {
                const screens = response[0];
                const details = response[1];

                vm.locations = screens.list.map(screen => {
                    screen.details = details.list.find(controller => screen.controllerRef === controller.id);
                    return screen;
                }).filter(screen => {
                    const status = screen.details ? screen.details.status : null;
                    const validStatuses = [ACTIVATION_STATUS.ACTIVE.value, ACTIVATION_STATUS.INACTIVE.value];
                    return validStatuses.includes(status);
                });
            });
        }

        /**
         * Handler on scenario active_till time update
         * @param duration
         */
        function onDurationChange(duration) {
            vm.scenarioDuration = duration;
        }

        /**
         * Add new assignment to the list
         */
        function addAssignment() {
            vm.scenarioData.assignments.push(new ModelsFactory.ScenarioAssignment());
        }

        /**
         * Handler on assignment select
         * @param assignment
         * @param index
         */
        function onAssignmentSelect(assignment, index) {
            vm.selectedAssignmentIndex = index;
            vm.interactiveContents.forEach(content => {
                content.isOpen = content.id === vm.scenarioData.assignments[vm.selectedAssignmentIndex].content_id;
            });
            updateAvailableLocationsList();
        }

        /**
         * Check if assignment is selected
         * @param {object} assignment
         * @param {number} index
         * @return {boolean}
         */
        function isAssignmentSelected(assignment, index) {
            return index === vm.selectedAssignmentIndex;
        }

        /**
         * Deletes assignment from assignments list
         * @param $event
         * @param {object} assignment
         * @param {number} index
         */
        function deleteAssignment($event, assignment, index) {
            $event.stopPropagation();
            $event.preventDefault();
            vm.scenarioData.assignments.splice(index, 1);
            updateSelectedAssignmentIndex(index);
        }

        /**
         * Updates selected assignment index
         * @param index
         */
        function updateSelectedAssignmentIndex(index) {
            if (angular.isDefined(vm.selectedAssignmentIndex)) {

                if (vm.selectedAssignmentIndex === index) {
                    vm.selectedAssignmentIndex = null;
                } else {
                    vm.selectedAssignmentIndex = vm.selectedAssignmentIndex > index ?
                        (vm.selectedAssignmentIndex - 1) : vm.selectedAssignmentIndex;
                }
            }
        }

        /**
         * Handler on content select
         * @param content
         */
        function onContentSelect(content) {
            vm.scenarioData.assignments[vm.selectedAssignmentIndex].content_id = content.id;
        }

        /**
         * Checks if content is selected
         * @param content
         * @return {boolean}
         */
        function isContentSelected(content) {
            return vm.scenarioData.assignments[vm.selectedAssignmentIndex].content_id === content.id;
        }

        /**
         * Updates selected locations list
         * @param selectedLocations
         */
        function updateSelectedLocationsList(selectedLocations) {
            vm.scenarioData.assignments[vm.selectedAssignmentIndex].locations = selectedLocations;
        }

        /**
         * Updates available locations list
         */
        function updateAvailableLocationsList() {
            if (!_.isNil(vm.selectedAssignmentIndex)) {
                vm.visibleLocations = vm.locations.filter((location => {
                    const notUsedInOtherAssignments =  vm.scenarioData.assignments.every((assignment, index) => {
                        return vm.selectedAssignmentIndex === index ||
                            !assignment.locations.some(assignedLocation => assignedLocation.id === location.id);
                    });
                    
                    const matchesSearchValue = !vm.searchName 
                        || location.name.toUpperCase().includes(vm.searchName.toUpperCase());
                    
                    return notUsedInOtherAssignments && matchesSearchValue;
                }));
            }
        }

        /**
         * Returns scenario expiration time
         * @return string
         */
        function getExpirationTime() {
            const expirationTime = vm.useDurationTimePicker ?
                UtilitiesService.getTimeByDiff(vm.scenarioDuration) : vm.scenarioData.active_till;
            return moment(expirationTime).utc().format(DATETIME_FORMAT)
        }

        function isScreensColumnVisible() {
            const selectedAssignment = vm.scenarioData.assignments[vm.selectedAssignmentIndex];
            return selectedAssignment && selectedAssignment.content_id
        }

        /**
         * Checks if scenario data is filled and can be finished
         */
        function canFinish() {
            vm.state.canFinish = vm.scenarioForm.$valid &&
                vm.scenarioData.assignments.length &&
                vm.scenarioData.assignments.every(assignment => {
                    return assignment.content_id && !_.isEmpty(assignment.locations);
                });
        }

        /**
         * Handler on finish button click
         */
        function onFinish() {
            const scenarioData = Restangular.copy(vm.scenarioData);
            scenarioData.active_till = vm.activateScenario ?
                getExpirationTime() : null;
            scenarioData.assignments.forEach(assignment => {
                assignment.locationIds = assignment.locations.map(location => location.id);
                delete assignment.locations;
            });

            let promise;

            promise = (vm.state.type === "new") ?
                ScenarioDataService.createScenario(scenarioData) : scenarioData.put();

            promise.then(() => $state.go('app.scenario'))
                .catch(console.error.bind(console));

        }

        $scope.$watch(
            () => vm.scenarioForm.$valid,
            canFinish);

        $scope.$watch(
            () => vm.scenarioData.assignments,
            canFinish, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish());
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('SmartCampusApiService', SmartCampusApiService);

    function SmartCampusApiService(
        $http,
    ) {
        return {
            getCampuses,
        };

        /**
         *
         * @return {Promise<object[]>}
         */
        function getCampuses(allTenants = false) {
            let url = '/api/smart-campus/campus';

            if (allTenants) {
                url += '?allTenants=true';
            }

            return $http.get(url)
                .then(response => response.data);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('SegmentHelper', SegmentHelper);

    function SegmentHelper(SEGMENT_STATUS) {

        return {
            getPreferenceName,
            getPropertyDescription,
            changeSegmentStatus,
        };

        /**
         * Handles segment status change
         */
        function changeSegmentStatus(segmentData, activateSegment) {
            segmentData.status = activateSegment ? SEGMENT_STATUS.ACTIVE : SEGMENT_STATUS.INACTIVE;
        }

        /**
         * Getting the name of preference
         *
         * @param {object} option
         * @param {object} segmentMessage
         * @return {string}
         */
        function getPreferenceName(option, segmentMessage) {
            if(option.propertyName === "") {
                return segmentMessage[Object.keys(segmentMessage)[0]].preferences.reduce((name, el) => {
                    return option.code === el.code ? el.propertyName : name;
                }, "");
            } else {
                return option.propertyName;
            }
        }

        /**
         * Getting the description of property
         *
         * @param {object} option
         * @param {string} key
         * @param {object} segmentMessage
         * @return {string}
         */
        function getPropertyDescription(option, key, segmentMessage) {
            if(option.description1 === "") {
                return segmentMessage[Object.keys(segmentMessage)[0]].preferences.reduce((name, el) => {
                    return option.code === el.code ? el[key] : name;
                }, "");
            } else {
                return option[key];
            }
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tariffTypeTab', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/new/tabs/tariff-type-tab/tariff-type-tab.tpl.html',
            controller: TariffTypeTabController,
            bindings: {
                tariffData: '<',
            }
        });

    function TariffTypeTabController(
        ModelsFactory,
        StatesFactory,
        RATE_TYPES,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.state = StatesFactory.TariffStates;
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'tariff-type-name',
                    width: '80',
                    translate: true,
                    title: item =>  item.label
                },
            ],
            itemClickCallback: onTypeSelect,
            updateCallback: () => {},
            needRadiobutton: true,
        };
        vm.tariffTypes = Object.values(RATE_TYPES);

        function init() {
            vm.selectedItem = vm.tariffTypes.find(type => type.id === vm.tariffData.rateType);
            vm.state.canNext = !!vm.selectedItem;
        }

        function onTypeSelect(type) {
            vm.state.canNext = true;
            vm.tariffData.rateType = type.id;
            vm.tariffData.quota.rateType = type.id;
            vm.tariffData.quota.capTariffRef = type.id;

            switch (type.id) {
                case RATE_TYPES.FIXED_PRODUCT.id:
                case RATE_TYPES.SUBSCRIPTION.id:
                    vm.tariffData.quota.params = new ModelsFactory.FixedProductOrSubscriptionQuotaParamsModel();
                    break;
                case RATE_TYPES.PAY_AS_YOU_PARK.id:
                    vm.tariffData.quota.params = new ModelsFactory.PayAsYouParkQuotaParamsModel();
                    break;
                case RATE_TYPES.DAILY_PARKING.id:
                case RATE_TYPES.FREE_PARKING.id:
                    vm.tariffData.quota.params = new ModelsFactory.ExtendedQuotaParamsModel();
                    break;
                case RATE_TYPES.TIME_SLOT_TARIFF.id:
                    vm.tariffData.quota.params = new ModelsFactory.TimeSlotTariffQuotaParamsModel();
                    break;
                default:
                    vm.tariffData.quota.params = new ModelsFactory.BaseQuotaParamsModel();
            }

            vm.tariffData.quota.params.quotaType = type.id;
        }
    }
})();
(function(){
    'use strict';

    angular.module('beacon.app')
        .component('textScaleInput', {
            templateUrl:
                '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-elements/text-scale-input/text-scale-input.tpl.html',
            controller: TextScaleInputController,
            bindings: {
                element: '<',
            }
        });

        /**
         * @property {TimetableHafasElement} element
         * 
         * @constructor
         */
        function TextScaleInputController() {
            const $ctrl = this;

            $ctrl.step = 0.05;
            $ctrl.value = $ctrl.element.textScale || 1;

            $ctrl.onInput = () => {
                $ctrl.element.textScale = $ctrl.value;
            };
        }

}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('VouchersFormController', VouchersFormController);

    function VouchersFormController(
        $scope,
        $state,
        $stateParams,
        StatesFactory,
        LoyaltyDataService,
        VoucherService,
        FileService,
        SponsorDataService,
        ImageUploadFactory,
        $q,
        PopupService,
    ) {
        const vm = this;
        const LOYALTY_CONFIG_TYPE_VALIDITY_DURATION = 9;
        const LOYALTY_CONFIG_TYPE_VOUCHER = 10;
        vm.voucherLayouts = [
            {
                id: 1,
                title: 'WITH_QR_CODE',
            },
            {
                id: 2,
                title: 'WITHOUT_QR_CODE',
            },
        ]

        const VOUCHERS_UPLOAD_MESSAGES = {
            error: 'OOPS_THERE_IS_SOME_ERROR',
            upload: 'PLEASE_UPLOAD_CSV_FILE',
            saveFirst: 'SAVE_VOUCHER_BEFORE_IMPORTING_CODES',
        };

        vm.state = StatesFactory.VoucherStates.refresh();

        vm.data = {};
        vm.sponsors = [];

        vm.loaderConfig = {
            minWidth: 200,
            minHeight: 200,
            center: true,
            fullInfo: true,
        };

        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.onCsvAttached = onCsvAttached;
        vm.onIcon = onIcon;
        vm.onIconDelete = onIconDelete;
        let iconUploader = null;
        let imageUploader = null;

        _init();

        /**
         * Image field change handler
         *
         * @param imageFile
         */
        function onImage(imageFile, uploadToS3) {
            imageUploader.setImage(uploadToS3 ? imageFile.blob : imageFile.blob.name);
            vm.data.image_ref = imageUploader.getImagePreview();
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            imageUploader.setImage(null);
            vm.data.image_ref = '';
        }

        /**
         * Image field change handler
         *
         * @param imageFile
         */
        function onIcon(imageFile, uploadToS3) {
            iconUploader.setImage(uploadToS3 ? imageFile.blob : imageFile.blob.name);
            vm.data.icon_ref = iconUploader.getImagePreview();
        }

        /**
         * Image delete click handler
         */
        function onIconDelete() {
            iconUploader.setImage(null);
            vm.data.icon_ref = '';
        }

        /**
         * Handler for attaching vouchers file to input
         *
         * @param {object} csvFile
         */
        function onCsvAttached(csvFile) {
            const formData = new FormData();
            formData.append('csv', csvFile);
            VoucherService.uploadCodes(formData, vm.voucherType.id)
                .then(response => {
                    vm.vouchersImportMessage = response.plain()[0];
                    showMessage('THE_VOUCHERS_HAVE_BEEN_SUCCESSFULLY_IMPORTED');
                }).catch(() => {
                    vm.vouchersImportMessage = VOUCHERS_UPLOAD_MESSAGES.error;
                    console.error.bind(console);
                    showMessage('THE_VOUCHERS_HAVE_NOT_BEEN_IMPORTED');
                });
        }

        /**
         * Controller initialization
         *
         * @private
         */
        function _init() {
            const INTERNAL_VOUCHER = 1;

            SponsorDataService.getSponsors().then(response => {
              vm.sponsors = response;
            })

            if ($stateParams.type === 'edit') {
                vm.data = $stateParams.data;
                vm.data.voucher_value = vm.data.voucher_value && parseFloat(vm.data.voucher_value);
            } else {
                vm.data.voucher_source = INTERNAL_VOUCHER;
            }

            if (!vm.data.redeem_layout) {
                vm.data.redeem_layout = vm.voucherLayouts[0].id;
            }
            vm.vouchersImportMessage = vm.data.id ? VOUCHERS_UPLOAD_MESSAGES.upload
                : VOUCHERS_UPLOAD_MESSAGES.saveFirst;
            
            VoucherService.getVoucherType(vm.data.external_id).then(response => {
                vm.issuedCount = response.data.issuedCount;
                vm.redeemCount = response.data.redeemCount
                vm.voucherType = response.data;
            })

            imageUploader = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.data.image_ref,
                onImageUploaded: (imageUrl) => {
                    vm.data.image_ref = imageUrl;
                }
            });

            iconUploader = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.data.icon_ref,
                onImageUploaded: (imageUrl) => {
                    vm.data.icon_ref = imageUrl;
                }
            });
            
            _loadConfigs();
        }

        function showMessage(test) {
            PopupService.showAlertPopup({
                text: test,
                okButtonText: 'OK',
            });
        }

        /**
         * Loads voucher configs
         *
         * @private
         */
        function _loadConfigs() {
            LoyaltyDataService.getLoyaltyConfigs().then(response => {
                const paramsData = _decodeLoyaltyConfigs(response.plain());

                vm.voucherTypes = paramsData.filter(el => parseInt(el.configType) === LOYALTY_CONFIG_TYPE_VOUCHER);
                if (!vm.data.voucher_type && vm.voucherTypes.length) {
                    vm.data.voucher_type = vm.voucherTypes[0];
                }

                vm.validityDurationTypes = paramsData.filter(
                    el => parseInt(el.configType) === LOYALTY_CONFIG_TYPE_VALIDITY_DURATION);
                _setDefaultValue(vm.data, 'validity_duration_type', vm.validityDurationTypes);
            }).catch(console.error.bind(console));

            LoyaltyDataService.getLoyaltyProducts().then(response => {
                vm.loyaltyProducts = response.plain();
            }).catch(console.error.bind(console));
        }

        /**
         * Setting the first config value if model property is empty
         *
         * @param {object} object
         * @param {string} property
         * @param {array} configList
         * @private
         */
        function _setDefaultValue(object, property, configList) {
            if (!object[property] && configList.length) {
                object[property] = configList[0].parameters.value;
            }
        }

        /**
         * Finish handler
         *
         * @param {boolean} saveAction - saving voucher and leave user on the page
         * @private
         */
        function _onFinish(saveAction = false) {
            vm.state.finish = false;
            vm.state.save = false;

            const serviceAction = $stateParams.type === 'edit' ? 'update' : 'create';

            $q.all([
                imageUploader.run(),
                iconUploader.run(),
            ])
            .then(() => {
                const data = angular.copy(vm.data);
                const formData = new FormData();
                formData.append('json', JSON.stringify(data));

                return VoucherService[serviceAction](formData)
            })
            .then(response => {
                if (saveAction) {
                    vm.data.id = response.plain().id;
                    vm.vouchersImportMessage = VOUCHERS_UPLOAD_MESSAGES.upload;
                } else {
                    $state.go('app.loyaltyVouchersList', {
                        paginationData: $stateParams.paginationData
                    });
                }
            })
            .catch(err => console.log(err));
        }

        /**
         * Decoding dropdown parameters
         * eg. params = "foo=bar" is becoming
         *     params = {foo: bar}
         *
         * @param {array} loyaltyConfigs
         * @returns {array}
         * @private
         */
        function _decodeLoyaltyConfigs(loyaltyConfigs) {
            return loyaltyConfigs.map(loyaltyConfig => {
                const paramsEncodedArray = loyaltyConfig.parameters.split(';');
                const paramsDecoded = {};
                paramsEncodedArray.map(paramEncoded => {
                    const paramArray = paramEncoded.split('==');
                    if (paramArray[0] && paramArray[1]) {
                        paramsDecoded[paramArray[0]] = paramArray[1];
                    }
                });
                loyaltyConfig.parameters = paramsDecoded;
                return loyaltyConfig;
            });
        }

        /**
         * Finish button click watcher
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Finish button click watcher
         */
        $scope.$watch(
            () => vm.state.save,
            allowSave => {
                allowSave && _onFinish(true);
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.dataForm.$valid,
            formValid => {
                vm.state.canFinish = formValid;
            });
    }
}());

(function() {
    'use strict';

    angular.module('dynamicContent')
        .component('dynamicVideo', {
            templateUrl: '/assets/views/dynamic-content/dynamic-video/dynamic-video.tpl.html',
            controller: DynamicVideoController,
            bindings: {
                source: '<',
                resizeType: '<',
                onReady: '&',
                onEnded: '&',
            },
        });

        function DynamicVideoController() {
            const vm = this;

            vm.videoEl;

            vm.onCanPlay = onCanPlay;
            vm.listenForVideoEvents = listenForVideoEvents;

            vm.$onInit = function () {
                initPlayer();
            }

            function initPlayer() {
                if (!vm.videoEl) { return; }

                if (typeof vm.onReady === 'function') {
                    vm.onReady({ duration: vm.videoEl.duration });
                }

                vm.listenForVideoEvents(vm.videoEl);
                vm.videoEl.play();
            }

            function onCanPlay(event, _index) {
                vm.videoEl = event.target;
                initPlayer();
            }

            function listenForVideoEvents(video) {
                if (typeof vm.onEnded !== 'function') { return; }

                video.onended = () => {
                    vm.onEnded();
                    vm.videoEl.play();
                };
            }
        }
})();

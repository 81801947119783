(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newProfile', {
            templateUrl: '/assets/views/share-park/customers/profiles/new/new-profile.tpl.html',
            controller: NewProfileController
        });

    function NewProfileController(
        $scope,
        $state,
        $stateParams,
        $translate,
        LanguageService,
        ModelsFactory,
        PricingDataService,
        ProfilesHelper,
        StatesFactory,
        ShareParkDataService,
        ShareParkHelperService,
        PROFILE_PERMISSION_LEVELS,
        PROFILE_CATEGORIES,
    ) {
        const vm = this;

        const { decodeTariff } = ShareParkHelperService;
        const {
            getCapWithSubAreasMapping,
            encodeCustomerProfile,
            encodeRatesData,
            encodeCarParksData,
            encodeOverrideProfiles,
            decodeOverrideProfilesData,
        } = ProfilesHelper;
        const currentLocale = $translate.use();

        vm.$onInit = init;

        vm.state = StatesFactory.ShareParkProfileStates.refresh();
        vm.state.type = $stateParams.type;
        vm.PROFILE_PERMISSION_LEVELS = PROFILE_PERMISSION_LEVELS;
        vm.profileCategoriesArr = Object.values(PROFILE_CATEGORIES);
        vm.langArray = [];
        vm.carParks = [];
        vm.capRates = [];
        vm.selectedCarParks = [];
        vm.selectedCapRates = [];
        vm.overrideSettingsCarPark = null;
        vm.overrideSettingsCarParkId = null;
        vm.overrideCapName = '';
        vm.capFeaturePermissions = null;
        vm.carParksListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'car-park-title',
                    width: '55',
                    title: 'name',
                },
                {
                    name: 'SUB_AREA',
                    class: 'sub-areas-column',
                    width: '45',
                    title: item => {
                        if (!item.related_sub_areas) {
                            return '';
                        }

                        return item.related_sub_areas.join(', ')
                    },
                },
                {
                    width: '5',
                    title: item => `<md-icon class="material-icons" role="img">${item.isOpen ? 'expand_less' : 'expand_more'}</md-icon>`,
                }
            ],
            buttons: {
                width: '5',
                items: [
                    {
                        class: 'editBtn',
                        callback: ($event, carPark) => {
                            $event.preventDefault();
                            $event.stopPropagation();

                            vm.overrideSettingsCarParkId = carPark.external_id;
                            vm.overrideSettingsCarPark = carPark;
                            onOverrideSettingsCapChange();
                        },
                        isVisible: (btn, item) => {
                            return vm.selectedCarParks.some(({external_id}) => external_id === item.external_id)
                                && (item.external_id !== vm.overrideSettingsCarParkId);
                        },
                    }
                ]
            },
            updateCallback: () => {},
            needCheckbox: true,
            checkboxClickCallback: toggleCarPark,
            additionalData: {
                previewLayout: 'column',
                getDefaultTranslation,
                multipleSelection: true,
                smallPreview: true,
            },
            initOnOpen: true,
            hideHeader: true,
        };

        vm.ratesListData = {
            columns: [
                {
                    name: 'NAME',
                    width: '45',
                    title: tariff => tariff.name[currentLocale] || Object.values(tariff.name)[0],
                },
                {
                    name: 'PRICE',
                    width: '30',
                    title: tariff => tariff.priceLabel[currentLocale] || Object.values(tariff.priceLabel)[0],
                },
                {
                    name: 'ADHOC',
                    width: '15',
                    title: tariff => `<div class="adhoc-checkbox ${isSelectedAdhocRate(tariff) ? 'selected' : ''}">Adhoc</div>`,
                },
            ],
            header: false,
            itemClickCallback: rateClickCallback,
            checkboxClickCallback: () => {},
        };

        vm.isActive = isActive;
        vm.changeProfileStatus = changeProfileStatus;
        vm.toggleAllRates = toggleAllRates;
        vm.allRatesSelected = allRatesSelected;
        vm.toggleAllCarParks = toggleAllCarParks;
        vm.allCarParksSelected = allCarParksSelected;
        vm.showError = showError;
        vm.toDefaultSettings = toDefaultSettings;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';
            const newProfileData = new ModelsFactory.Profile(vm.isEditMode);

            vm.profileData = vm.isEditMode
                ? _.merge(newProfileData, $stateParams.data)
                : newProfileData;

            LanguageService.getLanguages().then((response) => {
                vm.langArray = response.plain();

                if (!vm.language_id) {
                    vm.language_code = vm.langArray[0].code;
                }
            });

            loadCarParks()
                .then(() => loadRates());
        }

        function loadCarParks() {
            return ShareParkDataService.getCarParks()
                .then(response => {
                    vm.carParks = response.plain().map(carPark => {
                        carPark.related_sub_areas = [];
                        return carPark;
                    }).sort((cap1, cap2) => {
                        return (cap1.name > cap2.name) ? 1 : -1;
                    });

                    let capSubAreasMapping = {};
                    const capPermissions = vm.profileData.capPermissions;

                    if (!angular.isNumber(capPermissions.profileCategory)) {
                        capPermissions.profileCategory = PROFILE_CATEGORIES.DEFAULT.id;
                    }

                    if (!angular.isDefined(capPermissions.caps)) {
                        toggleAllCarParks();
                        vm.selectedCarParks.forEach(cap => { capSubAreasMapping[cap.external_id] = [] });
                    } else {
                        capSubAreasMapping = getCapWithSubAreasMapping(capPermissions.caps);
                        const capIds = Object.keys(capSubAreasMapping).map(key => Number(key));

                        vm.selectedCarParks = vm.carParks.filter(({external_id}) => {
                            return capIds.includes(external_id);
                        });
                    }

                    vm.selectedCarParks.forEach(carPark => {
                        carPark.related_sub_areas = capSubAreasMapping[carPark.external_id];

                        const overrideSettings = vm.profileData.profilePermissions.overrideProfiles.find(override =>
                            override.carParkRef === carPark.external_id
                        );

                        if (!overrideSettings) {
                            const defaultProfilePermissions = vm.profileData.profilePermissions.defaultProfile;
                            vm.profileData.profilePermissions.overrideProfiles.push({
                                carParkRef: carPark.external_id,
                                ratePermissions: angular.copy(defaultProfilePermissions.ratePermissions),
                                featurePermissions: angular.copy(defaultProfilePermissions.featurePermissions)
                            })
                        } else {
                            carPark.adhocParking = !!overrideSettings.ratePermissions.adhocParking;
                            carPark.bookingPermission = !!overrideSettings.ratePermissions.bookingPermission;
                        }
                    });

                    vm.capFeaturePermissions = vm.profileData.profilePermissions.defaultProfile.featurePermissions;
                });
        }

        function loadRates() {
            PricingDataService.getAllTariffs()
                .then(response => {
                    vm.capRates = response.plain().map(decodeTariff);
                    const ratePermissions = vm.profileData.ratePermissions;

                    if (!angular.isDefined(ratePermissions.rates)) {
                        toggleAllRates();
                    } else {
                        const defaultRateIds = ratePermissions.rates.split(';')
                            .map(rateId => Number(rateId));
                        const defaultAdhocRateIds = (ratePermissions.adhocRates || '').split(';')
                            .map(rateId => Number(rateId))
                        ratePermissions.ratesArr = vm.capRates.filter(rate => defaultRateIds.includes(rate.id));
                        ratePermissions.adhocRatesArr = vm.capRates.filter(rate => defaultAdhocRateIds.includes(rate.id));

                        vm.selectedCapRates = ratePermissions.ratesArr;
                    }

                    if (vm.profileData.profilePermissions.overrideProfiles) {
                        vm.profileData.profilePermissions.overrideProfiles =
                            decodeOverrideProfilesData(vm.profileData.profilePermissions.overrideProfiles, vm.capRates);
                    }
                })
        }

        function onOverrideSettingsCapChange() {
            if (vm.overrideSettingsCarParkId) {
                vm.overrideCapName = vm.overrideSettingsCarPark.name;
            } else {
                vm.overrideCapName = '';
            }
            onFeaturePermissionsCapChange();
            onRatePermissionCapChange();
        }
        
        function onFeaturePermissionsCapChange() {
            if (!vm.overrideSettingsCarParkId) {
                vm.capFeaturePermissions = vm.profileData.profilePermissions.defaultProfile.featurePermissions;
                return;
            }

            const overrideProfiles = vm.profileData.profilePermissions.overrideProfiles;
            const capOverrideSettings = overrideProfiles.find(({carParkRef}) =>
                carParkRef === vm.overrideSettingsCarParkId
            );

            vm.capFeaturePermissions = capOverrideSettings ? capOverrideSettings.featurePermissions : null;
        }

        function onRatePermissionCapChange() {
            vm.selectedCapRates = getSelectedCapRates();
        }

        function isActive() {
            const ACTIVE_STATUSES = [0, 1];
            return ACTIVE_STATUSES.includes(vm.profileData.status);
        }

        function changeProfileStatus() {
            const ACTIVE = 1;
            const INACTIVE = 2;

            vm.profileData.status = isActive() ? INACTIVE : ACTIVE;
        }

        /**
         * On all rates checkbox click handler
         */
        function toggleAllRates() {
            if (allRatesSelected()) {
                setSelectedCapRates([]);
            } else {
                setSelectedCapRates(vm.capRates);
            }
        }

        function allRatesSelected() {
            return vm.selectedCapRates.length === vm.capRates.length;
        }

        /**
         * Returns general rate permissions or rate permissions for selected cap
         * @returns {ProfileRatePermissions|*}
         */
        function getCurrentRatePermissions() {
            if (vm.overrideSettingsCarParkId === null) {
                return vm.profileData.ratePermissions;
            }

            const overrideProfiles = vm.profileData.profilePermissions.overrideProfiles;
            const capOverrideSettings = overrideProfiles.find(({carParkRef}) =>
                carParkRef === vm.overrideSettingsCarParkId
            );

            return capOverrideSettings.ratePermissions;
        }

        function getSelectedCapRates() {
            return getCurrentRatePermissions().ratesArr;
        }

        function rateClickCallback(rate, $event) {
            if ([...$event.target.classList].includes('adhoc-checkbox')) {
                const selectedAdHocRates = getSelectedAdhocRates();
                const adhocRateIds = selectedAdHocRates.map(rate => rate.id);
                const rateIndex = adhocRateIds.indexOf(rate.id)
                if (rateIndex !== -1) {
                    selectedAdHocRates.splice(rateIndex, 1);
                } else {
                    selectedAdHocRates.push(rate);
                }
            }
        }

        function isSelectedAdhocRate(rate) {
            const selectedAdhocRates = getSelectedAdhocRates().map(rate => rate.id);
            return selectedAdhocRates.includes(rate.id);
        }

        function getSelectedAdhocRates() {
            return getCurrentRatePermissions().adhocRatesArr;
        }

        function setSelectedCapRates(selectedCapRates) {
            const newSelectedRates = angular.copy(selectedCapRates);
            const selectedRates = getSelectedCapRates();
            selectedRates.splice(0, selectedRates.length, ...newSelectedRates);
            vm.selectedCapRates = selectedRates;
        }

        function toggleAllCarParks() {
            if (allCarParksSelected()) {
                vm.selectedCarParks = [];
                vm.profileData.profilePermissions.overrideProfiles = [];
                toDefaultSettings();
            } else {
                vm.selectedCarParks = [...vm.carParks];
                vm.selectedCarParks.forEach(toggleCarPark);
            }
        }

        function allCarParksSelected() {
            return vm.selectedCarParks.length === vm.carParks.length;
        }

        /**
         * On car park click callback
         * @param carPark
         */
        function toggleCarPark(carPark) {
            const overrideProfiles = vm.profileData.profilePermissions.overrideProfiles;

            if (vm.selectedCarParks.some(({id}) => id === carPark.id)) {
                const defaultProfilePermissions = vm.profileData.profilePermissions.defaultProfile;

                if (!overrideProfiles.some(override => override.carParkRef === carPark.external_id)) {
                    overrideProfiles.push({
                        carParkRef: carPark.external_id,
                        ratePermissions: angular.copy(vm.profileData.ratePermissions)
                            || new ModelsFactory.ProfileRatePermissions(vm.isEditMode),
                        featurePermissions: angular.copy(defaultProfilePermissions.featurePermissions)
                            || new ModelsFactory.ProfileFeaturePermissions(),
                    });
                }
            } else {
                vm.profileData.profilePermissions.overrideProfiles = overrideProfiles.filter(({carParkRef}) =>
                    carParkRef !== carPark.external_id
                );

                if (vm.overrideSettingsCarParkId === carPark.external_id) {
                    toDefaultSettings();
                }
            }
        }

        function toDefaultSettings() {
            vm.overrideSettingsCarParkId = null;
            vm.overrideSettingsCarPark = null;
            onOverrideSettingsCapChange();
        }

        function getDefaultTranslation(field) {
            return Object.values(field)[0];
        }

        /**
         * Checks if field error should be shown
         * @param name
         * @return {*|boolean}
         */
        function showError(name) {
            const field = vm.newProfileForm && vm.newProfileForm[name];
            return field && field.$dirty && field.$invalid;
        }

        function onFinish() {
            const formData = new FormData();
            let profileData = angular.copy(vm.profileData);

            profileData = encodeRatesData(profileData, vm.capRates);
            profileData = encodeCarParksData(profileData, vm.selectedCarParks, allCarParksSelected());
            profileData = encodeOverrideProfiles(profileData, vm.capRates);

            formData.append('profile', angular.toJson(encodeCustomerProfile(profileData)));

            ShareParkDataService.setProfile(formData).then(() => {
                $state.go('app.shareParkProfiles');
            });
        }

        $scope.$watch(
            () => vm.newProfileForm && vm.newProfileForm.$valid,
            formValid => {
                vm.state.canFinish = formValid;
            }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewShareParkCompanyToolbarController', NewShareParkCompanyToolbarController);

    function NewShareParkCompanyToolbarController(
        $state,
        StatesFactory
    ) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ShareParkCompaniesStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;
        vm.state.finishAndClose = 0;
        vm.state.send = 0;
        vm.state.canFinish = true;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;
        vm.finishAndClose = finishAndClose;

        function cancel() {
            $state.go('app.shareParkCompanies');
        }

        function finish() {
            vm.state.finish++;
        }

        function finishAndClose() {
            vm.state.finishAndClose++;
        }
    }
})();
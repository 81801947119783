(function() {
    'use strict';

    angular.module('beacon.app')
        .component('bannerThirdTab', {
            templateUrl: '/assets/views/content/elements/types/banner/tabs/banner-third-tab/banner-third-tab.tpl.html',
            controller: BannerThirdTab,
            bindings: {
                contentForm: '=',
                contentData: '=',
                clickActionsScreens: '=',
                clickActionsActions: '=',
                clickActionsExternalApp: '=',
            }
        });



    function BannerThirdTab(
        $scope,
        BANNER_PRESENTATION_OPTIONS,
        BANNER_CLICK_OPTIONS,
        BANNER_COMEBACK_OPTIONS,
        BANNER_EVERY_STATIC_TIME_SEGMENTS,
        ContentDataService,
        VoucherService,
        TrmPoiContentDataService,
    ) {
        const vm = this;

        vm.BANNER_PRESENTATION_OPTIONS = BANNER_PRESENTATION_OPTIONS;
        // "Open infotainment" type is disabled for standalone banner type
        vm.BANNER_CLICK_OPTIONS = BANNER_CLICK_OPTIONS.filter(clickOption => {
            const OPEN_INFOTAINMENT_TYPE = 1;
            return !vm.contentData.data.standaloneBanner || clickOption.value !== OPEN_INFOTAINMENT_TYPE
        });
        vm.BANNER_COMEBACK_OPTIONS = BANNER_COMEBACK_OPTIONS;
        vm.BANNER_EVERY_TIME_SEGMENTS = BANNER_EVERY_STATIC_TIME_SEGMENTS;

        vm.showActions = showActions;
        vm.getSelectedInfotainmentTitle = getSelectedInfotainmentTitle;
        vm.searchQueryForPoi = '';
        vm.selectPOI = selectPOI;
        vm.selectedItem = null;
        vm.getTrmPoiElements = getTrmPoiElements;

        const REWARD_TYPE_NONE = {
            id: 0,
            description: '',
            rewardCode: 0,
            rewardLabel: 'NONE',
            rewardUnitLabel: '',
            rewardValueFormat: ''
        };

        _init();

        function _init() {
            vm.langId = vm.contentData.language_id;
            vm.selectedInfotainment = vm.contentData.selectedInfotainment;

            ContentDataService.getRewardTypes().then(response => {
                const types = response.plain();
                
                vm.rewardTypes = types.length 
                    ? [REWARD_TYPE_NONE, ...types]
                    : types;

            }).catch(console.error.bind(console));

            VoucherService.getList().then(response => {
                const STATUS_ACTIVE = 1;
                vm.vouchers = angular.copy(response.plain()).filter(voucher => voucher.status === STATUS_ACTIVE);
            })
            .catch(console.error.bind(console));

            if (vm.contentData.message.directPoiRef) {
                TrmPoiContentDataService.getTrmPoiById(vm.contentData.message.directPoiRef).then(response => {
                    vm.selectedItem = response.data;
                });
            }
        }

        function selectPOI(id) {
            vm.contentData.message.directPoiRef = id;
        }

        function getTrmPoiElements() {
            return !vm.searchQueryForPoi 
                ? TrmPoiContentDataService.getTrmPoiContents().then(response => getUniquePois(response.data))
                : TrmPoiContentDataService.findTrmPoiContents({ searchQuery: vm.searchQueryForPoi }).then(response => getUniquePois(response.data))

            function getUniquePois(pois){
                const poiNames = [];
                return pois.filter(poi => {
                    if (!poiNames.includes(poi.name)) {
                        poiNames.push(poi.name);
                        return poi;
                    }
                })  
            }
        }

        function showActions() {
            return vm.showActionsScreensSelect
                || vm.showActionsActionsSelect
                || vm.showActionsExternalLinkInput
                || vm.showActionsExternalApp;
        }

        function getSelectedInfotainmentTitle() {
            return vm.selectedInfotainment ? `(${vm.selectedInfotainment.title[vm.langId]})` : '';
        }

        function bindWatchers() {
            $scope.$watch(angular.bind(vm, function () {
                return vm.contentData.message.presentationOptions.case;
            }), function () {
                vm.showPresentationOptionsInput = Number(vm.contentData.message.presentationOptions.case) !== 0;
                vm.showPresentationEveryTimeSegmentsSelect = Number(vm.contentData.message.presentationOptions.case) === 4;
            });

            $scope.$watch(angular.bind(vm, function () {
                return vm.contentData.message.clickOptions.case;
            }), function () {
                vm.showActionsActionsSelect = Number(vm.contentData.message.clickOptions.case) === 2;
                vm.showActionsScreensSelect = Number(vm.contentData.message.clickOptions.case) === 3;
                vm.showActionsExternalLinkInput = Number(vm.contentData.message.clickOptions.case) === 4;
                vm.showActionsExternalApp = Number(vm.contentData.message.clickOptions.case) === 5;
            });

            $scope.$watch(angular.bind(vm, function () {
                return vm.contentData.message.comebackOptions.case;
            }), function () {
                vm.showComebackOptionsInput = Number(vm.contentData.message.comebackOptions.case) >= 2;
                vm.showComebackEveryTimeSegmentsSelect = Number(vm.contentData.message.comebackOptions.case) === 3;
            });
        }

        bindWatchers();
    }
})();

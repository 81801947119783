(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newPoiTour', {
            templateUrl: '/assets/views/poi/tours/new/new-tour/new-tour.tpl.html',
            controller: NewTourController
        });

    function NewTourController(
        $q,
        $scope,
        $state,
        $stateParams,
        ContentDataService,
        LanguageService,
        PoiContentDataService,
        ModelsFactory,
        StatesFactory,
        CONTENT_TYPES,
    ) {
        const vm = this;

        vm.$onInit = onInit;

        // public properties
        vm.state = StatesFactory.PoiTourStates.refresh();
        vm.state.canNext = true;

        vm.tourPoints = [];
        vm.poiCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'checkbox-list-item-title',
                    width: '100',
                    title: function(item) {
                        return item.title[vm.tourData.language_id] || item.title[item.language_id];
                    },
                }
            ],
            header: false,
            itemClickCallback: updatePointsDescription
        };

        vm.contentsCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'checkbox-list-item-title',
                    width: '100',
                    title: function(item) {
                        return item.title[vm.tourData.language_id] || item.title[item.language_id];
                    },
                }
            ],
            header: false,
            itemClickCallback: updatePointsDescription
        };

        vm.selectedContentsList = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'list-item-title',
                    width: '100',
                    title: function(item) {
                        return item.title[vm.tourData.language_id] || item.title[item.language_id];
                    },
                }
            ],
            header: false,
            itemClickCallback: updateTourPoints
        };

        function updatePointsDescription(pointData) {
            if (vm.tourData.tourContents.some(content => {
                return content.id === pointData.id && content.content_type_id === pointData.content_type_id;
            })) {
                pointData.description = {
                        [vm.tourData.language_id]: pointData.title[vm.tourData.language_id] || '',
                }
            } else {
                delete pointData.description;
            }
            updateTourPoints();
        }

        function updateTourPoints() {
            vm.tourPoints = [];
            vm.tourData.tourContents.forEach(element => {
                vm.tourPoints = vm.tourPoints.concat(element.map_info.map(point => {
                    return {
                        coords: {
                            lat: point.latitude,
                            lng: point.longitude
                        },
                        label: element.title[vm.tourData.language_id] || element.title[element.language_id],

                    }
                }));
            });
        }

        /**
         * Load POI contents which can be attached into tour
         * @return {*}
         */
        function loadPossibleTourPoiContents() {
            return PoiContentDataService.getPossibleTourPoints()
                .then(response => {
                    vm.poiContents = _.isArray(response) ? response.plain() : [];
                    vm.poiContents = vm.poiContents.map(element => {
                        element.title = angular.fromJson(element.title);
                        element.message = angular.fromJson(element.message);
                        element.map_info = angular.fromJson(element.map_info);
                        return element;
                    });
                })
        }

        /**
         * Load infotainments, quizes of feedbacks which can be attached into tour
         */
        function loadSimpleContentsForTour() {
            const requestData = {
                contentTypeId: `${CONTENT_TYPES.INFOTAINMENT},${CONTENT_TYPES.QUIZ},${CONTENT_TYPES.FEEDBACK}`
            };
            return ContentDataService.getContentsForTour(requestData)
                .then(response => {
                    vm.elements =  _.isArray(response) ? response.plain() : [];
                    vm.elements = vm.elements.map(element => {
                        element.title = angular.fromJson(element.title);
                        element.map_info = angular.fromJson(element.map_info);
                        return element;
                    });
                });
        }

        function getlanguages() {
            return LanguageService.getLanguages().then((response) => {
                vm.langArray = response.plain();
                if (!!vm.langArray.length) {
                    vm.tourData.language_id = vm.langArray[0].id;
                }
            })
        }

        function processTourData() {
            updateTourPoints();
        }

        /**
         * Language change handler
         * @param {string} oldValue
         * @returns {undefined}
         */
        function onLanguageChange(oldValue) {
            let valid = vm.tourData.title[oldValue] && vm.tourData.description[oldValue];

            if (!valid) {
                delete vm.tourData.title[oldValue];
                delete vm.tourData.description[oldValue];
            }
        }

        /**
         * Initialization method
         */
        function onInit() {
            const promises = [getlanguages(), loadPossibleTourPoiContents(), loadSimpleContentsForTour()];
            vm.state.type = $stateParams.type;
            if (vm.state.type === "new" && !$stateParams.data) {
                vm.tourData = new ModelsFactory.PoiTour();
            } else {
                vm.tourData = $stateParams.data;
            }

            $q.all(promises).then(processTourData);
        }

        function _onFinish() {
            const formData = new FormData();
            const jsonFields = ['title', 'description', 'poiContents', 'contents'];

            const tourData = angular.copy(vm.tourData);

            tourData.poiContents = [];
            tourData.contents = [];
            vm.tourData.tourContents.forEach((tourContent, index) => {
                if (tourContent.content_type_id) {
                    tourData.contents.push({
                        id: tourContent.id,
                        index: index,
                        description: angular.toJson(tourContent.description),
                    });
                } else {
                    tourData.poiContents.push({
                        id: tourContent.id,
                        index: index,
                        description: angular.toJson(tourContent.description),
                    });
                }
            });
            delete tourData.tourContents;

            for (let prop in tourData) {
                let value = tourData[prop];

                if (jsonFields.includes(prop)) {
                    value = angular.toJson(value);
                }

                if (tourData.hasOwnProperty(prop) && value !== undefined) {
                    formData.append(prop, value);
                }
            }

            if (vm.state.type === 'new') {
                PoiContentDataService.createTour(formData)
                    .then(() => {
                        $state.go('app.poiTours', {
                            paginationData: $stateParams.paginationData
                        });
                    });
            } else {
                PoiContentDataService.poiTour(vm.tourData.id)
                    .then((poiTour) => {
                        formData.append('_method', 'PUT');

                        return PoiContentDataService.updateTour(poiTour, formData)
                            .then(() => {
                                $state.go('app.poiTours', {
                                    paginationData: $stateParams.paginationData
                                });
                            });
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Watcher for finish button click event
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.tourForm.$valid,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('loyaltyCampaignLanguage', loyaltyCampaignLanguage);

    function loyaltyCampaignLanguage() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/loyalty/campaigns/form/directives/language/language.tpl.html',
            controller: loyaltyCampaignLanguageController,
            controllerAs: '$ctrl',
            scope: {
                ngModel: '='
            },
        };
    }

    function loyaltyCampaignLanguageController(LanguageService, StorageFactory) {
        const vm = this;

        vm.STORAGE = StorageFactory.Storage('LoyaltyLanguages');
        if (!vm.STORAGE) {
            vm.STORAGE = new StorageFactory.Storage('LoyaltyLanguages', true);
        }

        vm.langArray = vm.STORAGE.get('list');

        if (!vm.langArray) {
            LanguageService.getLanguages().then((response) => {
                vm.STORAGE.set('list', vm.langArray = response.plain());
            }).catch(console.error.bind(console));
        }
    }
}());

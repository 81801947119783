(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedbackFormsEdit', {
            templateUrl: '/assets/views/feedback/feedback-forms/edit/feedback-forms-edit.tpl.html',
            controller: FeedbackFormsEditController,
        });

    function FeedbackFormsEditController(
        $state,
        $scope,
        $timeout,
        $stateParams,
        StatesFactory,
        FeedbackConfigService,
    ) {
        const vm = this;

        vm.$onInit = onInit;
        vm.fillExample = fillExample;
        vm.isConfigValid = isConfigValid;

        vm.isEditMode = false;
        vm.state = StatesFactory.FeedbackFormEditStates.refresh();


        function onInit() {
            vm.isEditMode = !!$stateParams.feedbackFormConfig;
            vm.data = vm.isEditMode ? $stateParams.feedbackFormConfig : {};
        }

        function onFinish() {
            if (vm.isEditMode) {
                FeedbackConfigService.modify(vm.data).then(afterFinish);
            } else {
                FeedbackConfigService.create(vm.data).then(afterFinish);
            }
        }

        function afterFinish() {
            $state.go('app.feedbackForms');
        }

        function fillExample() {
            FeedbackConfigService.getExample()
                .then(response => {
                    vm.data.config = JSON.stringify(response.data, null, 2);
                });
        }

        function isFormValid() {
            const d = vm.data;
            return !!d.name && !!d.alias && isConfigValid();
        }

        function isConfigValid() {
            if (!vm.data.config) {
                return false;
            }
            try {
                return !!JSON.parse(vm.data.config);
            } catch (error) {
                return false;
            }
        }

        function generateFormPreview() {
            return {
                name: 'Test',
                config: vm.data.config
            };
        }

        $scope.$watch(
            () => vm.state.finish,
            _ => vm.state.finish > 0 && onFinish(),
        );

        $scope.$watch(
            () => vm.data,
            _ => {
                vm.state.canFinish = isFormValid();
                vm.formPreview = false;
                $timeout(_ => {
                    vm.formPreview = isConfigValid() && generateFormPreview();
                });
            },
            true,
        );
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newLegalText', {
            templateUrl: '/assets/views/share-park/operations/legal/new/new-legal-text.tpl.html',
            controller: NewLegalTextController
        });

    function NewLegalTextController(
        $scope,
        $state,
        $stateParams,
        LanguageService,
        ModelsFactory,
        PopupService,
        StatesFactory,
        LegalTextDataService,
        LEGAL_TEXT_TYPES,
        TEXT_ANGULAR_OPTIONS_EXTENDED,
    ) {
        const vm = this;

        const {
            createLegalText,
            updateLegalText
        } = LegalTextDataService;

        vm.$onInit = init;

        vm.state = StatesFactory.LegalTextStates.refresh();
        vm.LEGAL_TEXT_TYPES = Object.values(LEGAL_TEXT_TYPES);
        const textAngularOptionsExtended = angular.copy(TEXT_ANGULAR_OPTIONS_EXTENDED);
        textAngularOptionsExtended[1].push('fontName');
        vm.TEXT_ANGULAR_OPTIONS_EXTENDED = textAngularOptionsExtended;

        vm.showInputError = showInputError;
        vm.onFilenameSelectChange = onFilenameSelectChange;
        vm.onFilenameInputChange = onFilenameInputChange;
        vm.onHtmlFileUpload = onHtmlFileUpload;
        vm.onEditorModeChange = onEditorModeChange;

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.isEditMode = vm.state.type === 'edit';

            vm.legalTextData = vm.isEditMode
                ? $stateParams.data
                : new ModelsFactory.LegalText();

            vm.predefinedFilename = vm.LEGAL_TEXT_TYPES.some(type => type.filename === vm.legalTextData.filename)
                ? vm.legalTextData.filename
                : null;

            LanguageService.getLanguages().then((response) => {
                vm.langsArray = response.plain();
                if (!vm.legalTextData.language_id) {
                    vm.legalTextData.language_id = vm.langsArray[0].id;
                }
            })
        }

        /**
         * Ob finish handler
         */
        function onFinish() {
            const formData = new FormData();

            const data = angular.copy(vm.legalTextData);
            data.publishing_date = moment(data.publishing_date).utc().format('YYYY-MM-DD HH:mm:ss');
            // legalTextData.publishing_date
            formData.append('legalText', angular.toJson(vm.legalTextData));

            if (!vm.isEditMode) {
                createLegalText(formData)
                    .then(onSuccess)
                    .catch(errorHandler);
            } else {
                updateLegalText(vm.legalTextData, formData)
                    .then(onSuccess)
                    .catch(errorHandler);
            }
        }

        function onSuccess() {
            $state.go('app.operationsLegal', {
                paginationData: $stateParams.paginationData
            });
        }

        function errorHandler(err) {
            if (angular.isString(err.data.error)) {
                PopupService.showAlertPopup({
                    text: err.data.error,
                    okButtonText: 'OK'
                });
            }
            console.log(err)
        }

        /**
         * Checks is error must be shown
         * @param { String } name
         * @return {boolean}
         */
        function showInputError(name) {
            return vm.newLegalTextForm
                && vm.newLegalTextForm[name].$dirty
                && vm.newLegalTextForm[name].$invalid;
        }

        function onFilenameSelectChange() {
            vm.legalTextData.filename = vm.predefinedFilename
                ? vm.predefinedFilename
                : null;
        }

        function onFilenameInputChange() {
            if (vm.LEGAL_TEXT_TYPES.some(type => type.filename === vm.legalTextData.filename)) {
                vm.predefinedFilename = vm.legalTextData.filename;
            } else {
                vm.predefinedFilename = null;
            }
        }

        function onHtmlFileUpload(htmlFile) {
            const reader = new FileReader();
            reader.onload = function(event) {
                vm.legalTextData.text[vm.legalTextData.language_id] = event.target.result;
                $scope.$digest();
            };
            reader.readAsText(htmlFile);
        }

        function onEditorModeChange() {
            if (vm.useHtmlEditor) {
                vm.legalTextData.text[vm.legalTextData.language_id] = window.html_beautify(vm.legalTextData.text[vm.legalTextData.language_id]);
            }
        }

        $scope.$watch(() => {
            return vm.newLegalTextForm && vm.newLegalTextForm.$valid;
        }, formValid => {
            vm.state.canFinish = !!formValid;
        }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
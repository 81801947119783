(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('GlobalPageSettingsController', GlobalPageSettingsController);

    function GlobalPageSettingsController($scope, $uibModalInstance, data, PopupService, StorageFactory, ModelsFactory) {
        const vm = this;

        vm.settings = angular.copy(data.globalPagesSettings || {});
        vm.acceptTypes = [
            'image/jpeg',
            'image/png'
        ];

        vm.okHandler = okHandler;
        vm.cancelHandler = cancelHandler;
        vm.onImage = onImage;
        vm.deleteImage = deleteImage;
        vm.resetHandler = resetHandler;

        function okHandler() {
            $uibModalInstance.close({
                data: {globalPagesSettings: vm.settings}
            });
        }

        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        function onImage(imageFile, uploadToS3) {
            const urlCreator = window.URL || window.webkitURL;
            if (!imageFile || vm.acceptTypes.indexOf(imageFile.type) === -1) {
                return angular.element('input[type="file"].image').val(null);
            }

            vm.settings.backgroundImage = uploadToS3 ? imageFile : null;
            vm.settings.backgroundImageSource = uploadToS3
                ? urlCreator.createObjectURL(imageFile)
                : imageFile.name;
        }

        function deleteImage() {
            vm.settings.backgroundImage = null;
            vm.settings.backgroundImageSource = null;
        }

        function resetHandler() {
            let digitalDisplay = new ModelsFactory.DigitalDisplay;
            vm.settings = angular.copy(digitalDisplay.globalPagesSettings);
        }
    }

}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tariffQuotaDetailsTab', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/new/tabs/tariff-quota-details-tab/tariff-quota-details-tab.tpl.html',
            controller: TariffQuotaDetailsTabController,
            bindings: {
                tariffData: '<',
                newTariffForm: '<',
            }
        });

    function TariffQuotaDetailsTabController(
        $scope,
        StatesFactory,
        UtilitiesService,
        QUOTA_UNIT_TYPES,
        RATE_TYPES,
        WEEK_DAYS,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.state = StatesFactory.TariffStates;
        vm.QUOTA_UNIT_TYPES = QUOTA_UNIT_TYPES;
        vm.RATE_TYPES = RATE_TYPES;
        vm.WEEK_DAYS_ARR = Object.values(WEEK_DAYS);

        vm.addTier = addTier;
        vm.removeTier = removeTier;
        vm.updateTierIndexes = updateTierIndexes;
        vm.addRate = addRate;
        vm.removeRate = removeRate;
        vm.addTimeWindow = addTimeWindow;
        vm.deleteTimeWindow = deleteTimeWindow;
        vm.openMenu = openMenu;
        vm.copyTimeSlots = copyTimeSlots;
        vm.areTimeWindowsValid = areTimeWindowsValid;
        vm.validateQuotaParams = validateQuotaParams;
        vm.formattingQuotaPriceTaxForDB = formattingQuotaPriceTaxForDB;

        function init() {
            vm.showError = UtilitiesService.inputValidator(vm.newTariffForm);
            vm.state.canNext = true;

            if (vm.tariffData.quota.params.tiers) {
                vm.tariffData.quota.params.tiers.sort((a, b) => a.index - b.index);
            }
            vm.currentQuotaPriceTax = vm.tariffData.quota.params.quotaPriceTax / 100;
        }

        function addTier() {
            vm.tariffData.quota.params.tiers.push({
                index: vm.tariffData.quota.params.tiers.length,
                tierSize: 1,
                quotaPrice: 1,
            });
        }

        function formattingQuotaPriceTaxForDB() {
            vm.tariffData.quota.params.quotaPriceTax = vm.currentQuotaPriceTax * 100;
        }

        /**
         * Removes tier from the list
         * @param $event
         * @param index
         */
        function removeTier($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.tariffData.quota.params.tiers.splice(index, 1);
            updateTierIndexes();
        }

        function updateTierIndexes() {
            vm.tariffData.quota.params.tiers.forEach((tier, index) => {
                tier.index = index;
            });
        }

        function addRate() {
            if (!vm.tariffData.quota.params.rates) {
                vm.tariffData.quota.params.rates = [];
            }

            vm.tariffData.quota.params.rates.push({
                name: '',
                tierSize: 1,
                quotaPrice: 1,
            });

            validateQuotaParams();
        }

        /**
         * Removes rate from the list
         * @param $event
         * @param index
         */
        function removeRate($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.tariffData.quota.params.rates.splice(index, 1);
            validateQuotaParams();
        }

        function addTimeWindow(dayId) {
            vm.tariffData.quota.params.tariffDays[dayId].push({
                startTime: moment('00:00', 'HH:mm'),
                endTime: moment('23:59', 'HH:mm'),
                tariff: 0,
            });

            validateQuotaParams();
        }

        function deleteTimeWindow($event, dayId, index) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.tariffData.quota.params.tariffDays[dayId].splice(index, 1);
            validateQuotaParams();
        }

        function areTimeWindowsValid(dayId) {
            const timeWindows = vm.tariffData.quota.params.tariffDays[dayId];
            const rates = vm.tariffData.quota.params.rates;

            return timeWindows.every(({startTime, endTime, tariff}, index) => {
                const validTime =  timeWindows.every((timeWindow, comparedIndex) => {
                    if (index === comparedIndex) {
                        return true;
                    }

                    return (startTime.isBefore(timeWindow.startTime) && endTime.isBefore(timeWindow.startTime))
                        || (startTime.isAfter(timeWindow.endTime) && endTime.isAfter(timeWindow.endTime));
                });

                return validTime
                    && angular.isDefined(tariff)
                    && rates.some((rate, index) => index === tariff);
            });
        }

        /**
         * Opens dropdown menu
         *
         * @param $mdMenu
         * @param ev
         */
        function openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }

        function copyTimeSlots(targetDayId, sourceDayId) {
            vm.tariffData.quota.params.tariffDays[targetDayId] =
                angular.copy(vm.tariffData.quota.params.tariffDays[sourceDayId] || []);

            validateQuotaParams();
        }

        function validateQuotaParams() {
            canNext();
        }

        function canNext() {
            let validTimeSlots = true;
            if (RATE_TYPES.TIME_SLOT_TARIFF.id === vm.tariffData.rateType) {
                const rates = vm.tariffData.quota.params.rates;

                validTimeSlots = rates
                    && rates.length
                    && vm.WEEK_DAYS_ARR.every(day => areTimeWindowsValid(day.id));
            }

            vm.state.canNext = vm.newTariffForm
                && vm.newTariffForm.$valid
                && validTimeSlots;
        }

        $scope.$watch(function() {
            return vm.newTariffForm && vm.newTariffForm.$valid;
        }, canNext);
    }
})();
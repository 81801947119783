(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('CopyFromWebSourcePopupController', CopyFromWebSourcePopupController);

    function CopyFromWebSourcePopupController(
        $uibModalInstance,
        ContentDataService,
        UtilitiesService
    ) {
        const vm = this;
        const { generateGUID } = UtilitiesService;
        vm.FULL_DESCRIPTION = 'fullDescription';
        vm.DESCRIPTION = 'description';

        vm.selectedItem = null;
        vm.selectedItemId = null;
        vm.showLoading = true;
        vm.descriptionType = vm.DESCRIPTION;
        vm.uploadImage = true;

        vm.okHandler = okHandler;
        vm.cancelHandler = cancelHandler;
        vm.selectItem = selectItem;


        /**
         * Ok handler
         * @returns {undefined}
         */
        function okHandler() {
            const description = vm.descriptionType === vm.FULL_DESCRIPTION
                ? vm.selectedItem.descriptionFull
                : vm.selectedItem.description;
            const item = {
                title: vm.selectedItem.title,
                description,
                imageRef: vm.selectedItem.imageRef,
                linkRef: vm.selectedItem.linkRef,
                uploadImage: vm.uploadImage && !!vm.selectedItem.imageRef,
            }
            $uibModalInstance.close(item);
        }

        /**
         * Cancel handler
         * @returns {undefined}
         */
        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Radio onchange handler
         * @param {object} item Selected item
         * @returns {undefined}
         */
        function selectItem(item) {
            vm.descriptionType = vm.DESCRIPTION;
            vm.selectedItem = item;
            vm.uploadImage = !!vm.selectedItem.imageRef;
        }

        /**
         * Init
         * Loads data required for popup
         * @returns {undefined}
         */
        function init() {
            ContentDataService.getRssWebSources().then(data => {

                vm.rssWebSources = data.map(source => {
                    if (!source.rssWebItems) {
                        return;
                    }
                    const items = source.rssWebItems.map(item => ({
                            id: generateGUID(),
                            title: item.title,
                            description: item.description || null,
                            descriptionFull: item.descriptionFull || null,
                            imageRef: typeof item.imageRef === 'string' ? item.imageRef : null,
                            linkRef: item.linkRef,
                    }));

                    return {
                        title: source.name,
                        description: source.description,
                        items
                    }
                }).filter(source => source);

                vm.showLoading = false;
            });
        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('SubdomainPopupController', SubdomainPopupController);

    function SubdomainPopupController($uibModalInstance, data, TenantDataService, LOCATION_TYPES) {
        const vm = this;

        const locationTypes = LOCATION_TYPES || [];

        // public methods
        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.data = data;


        vm.TABS = {
            PERMISSIONS: {
                id: 1,
                name: 'PERMISSIONS',
            },
            LOCATIONS: {
                id: 2,
                name: 'LOCATIONS',
            }
        };

        vm.TABS_ARR = Object.values(vm.TABS);
        vm.selectedTabId = vm.TABS.PERMISSIONS.id;

        vm.isCheckedPermission = isCheckedPermission;
        vm.togglePermission = togglePermission;

        vm.permissions = getPermissions();

        // public properties
        vm.item = {
            subdomainLocations: {
                isChecked: isCheckedLocation,
                toggleCheckbox: toggleLocation,
                items: getLocations(),
                titleProp: 'name',
                groupByValue : 'region',
                orderByValue: 'name',
                groups: [
                    {
                        name: "LOCATION_TYPES",
                        items: locationTypes,
                        idProp: "id",
                        titleProp: "name",
                        filterFunction: (checkboxItem, selectedGroupItems)=> {
                            var isVisible = false;
                            _.forEach(selectedGroupItems, (groupItem)=>{
                                if (groupItem && checkboxItem[groupItem] && checkboxItem[groupItem] > 0) {
                                    isVisible = true;
                                    return false;
                                }
                            });
                            return isVisible;
                        }
                    },
                    {
                        name: "Location groups",
                        items: _.uniqBy(getLocations(), 'region'),
                        idProp: "region",
                        titleProp: "region",
                       filterFunction: (checkboxItem, selectedGroupItems)=>
                            _.indexOf(selectedGroupItems, checkboxItem.region)!==-1
                    }
                ]
            }
        };

        // private methods
        function isCheckedPermission(permission) {
            return _.findIndex(vm.data.subdomainObj.permission_tenant, {'permission_id': permission.id})>-1;
        }

        function getPermissions() {
            var permissionTeantIds = _.map(vm.data.tenant.permission_tenant, 'permission_id');
            
            return _.filter(vm.data.permissions, (permission)=>{return permissionTeantIds.indexOf(permission.id)>-1;});
        }

        function togglePermission(permission) {
            var permissionIndex = _.findIndex(vm.data.subdomainObj.permission_tenant, {'permission_id': permission.id});
            if(permissionIndex > -1){
                vm.data.subdomainObj.permission_tenant.splice(permissionIndex, 1);
            } else {
                vm.data.subdomainObj.permission_tenant.push(_.find(vm.data.tenant.permission_tenant,['permission_id', permission.id]));
            }
        }

        function isCheckedLocation(location) {
            return _.findIndex(vm.data.subdomainObj.location_tenant, {'location_id': location.id})>-1;
        }

        function getLocations() {
            var locationTeantIds = _.map(vm.data.tenant.location_tenant, 'location_id');
            
            return _.filter(vm.data.locationsAll, (location)=>{return locationTeantIds.indexOf(location.id)>-1;});
        }

        function toggleLocation(location) {
            var locationIndex = _.findIndex(vm.data.subdomainObj.location_tenant, {'location_id': location.id});
            if(locationIndex > -1){
                vm.data.subdomainObj.location_tenant.splice(locationIndex, 1);
            } else {
                vm.data.subdomainObj.location_tenant.push(_.find(vm.data.tenant.location_tenant,['location_id', location.id]));
            }
        }

        function okHandler() {
            if(vm.data && vm.data.subdomainObj && vm.data.subdomainObj.id && vm.data.subdomainObj.put){
                vm.data.subdomainObj.put().then(()=>{
                    vm.data.reloadSubdomainsList();
                    cancelHandler();
                });
            } else {
                TenantDataService.createSubdomain(vm.data.tenant.id, vm.data.subdomainObj).then((response)=>{
                    vm.data.reloadSubdomainsList();
                    cancelHandler();
                });
            }
        }

        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }
    }
}());

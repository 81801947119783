(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screensFilterGroup', {
            controller: screensFilterGroupController,
            templateUrl: '/assets/views/common/components/screens-filter/filter-group/filter-group.tpl.html',
            bindings: {
                filterTypes: '<',
                filterComparisons: '<',
                filterGroup: '=',
                index: '<',
                disableRemove: '<',
                onChange: '<?',
                onDelete: '<?',
            },
        });

    function screensFilterGroupController($scope, $interval, $timeout, SCREEN_FILTER_GROUP_TYPE) {
        const vm = this;

        vm.GROUP_TYPE = SCREEN_FILTER_GROUP_TYPE;

        vm.addFilter = addFilter;
        vm.deleteItem = deleteItem;
        vm.addGroup = addGroup;
        vm.setFilterItem = setFilterItem;
        vm.removeGroup = vm.onDelete;

        vm.$onInit = init;

        /**
         * Controller initialization
         */
        function init() {
            vm.filterGroup = vm.filterGroup || defaultGroup();
        }

        /**
         * Adds new filter item
         */
        function addFilter() {
            vm.filterGroup.items.push(defaultFilterItem());
            vm.onChange(vm.filterGroup, vm.index);
        }

        /**
         * Adds new filter item
         */
        function addGroup() {
            vm.filterGroup.items.push(defaultGroup());
            vm.onChange(vm.filterGroup, vm.index);
        }

        /**
         * Returns default filter item
         *
         * @return {object}
         */
        function defaultFilterItem() {
            return angular.copy({
                filterType: vm.filterTypes[0] || null,
                filterComparison: vm.filterComparisons[0] || null,
                filterValue: null,
            });
        }

        /**
         * Returns default filter item
         *
         * @return {object}
         */
        function defaultGroup() {
            return angular.copy({
                group: vm.GROUP_TYPE.AND,
                items: [],
            });
        }

        /**
         * Sets filter item into current group
         *
         * @param filterItem
         * @param index
         */
        function setFilterItem(filterItem, index) {
            vm.filterGroup.items[index] = filterItem;
            vm.onChange(vm.filterGroup, vm.index);
        }

        /**
         * Deleting item from current group
         *
         * @param index
         */
        function deleteItem(index) {
            vm.filterGroup.items.splice(index, 1);
            vm.onChange(vm.filterGroup, vm.index);
        }
    }
})();
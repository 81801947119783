(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('locationValidator', function(UtilitiesService) {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.location = function (modelValue, viewValue) {
                        const coords = UtilitiesService.getCoordinates(viewValue);
                        const isValid = val => !!val && val >=-90 && val <= 90;
                        return coords && isValid(coords.latitude) && isValid(coords.longitude);
                    };
                }
            };
        });
})();
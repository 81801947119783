(function() {
    'use strict';

    angular.module('beacon.app')
        .component('accordionRbList', {
            templateUrl: '/assets/views/common/components/accordion-radio-button-list/accordion-rb-list.tpl.html',
            controller: AccordionRadioButtonListController,
            bindings: {
                listData: '<',
                listItems: '<',
            }
        });

    function AccordionRadioButtonListController() {
        const vm = this;

        // public methods
        vm.getText = getText;

        /**
         * Returns text for column
         * @param {object} item
         * @param {object} column
         * @return {string}
         */
        function getText(item, column) {
            return angular.isString(column.title) ? item[column.title] : column.title(item);
        }
    }
})();
(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timeSlotPreview', {
            templateUrl: '/assets/views/trm-poi/elements/new/new-trm-poi-element/time-slot-preview/time-slot-preview.tpl.html',
            controller: TimeSlotPreviewController,
            bindings: {
                timeSlotData: '<',
            }
        });
    
    function TimeSlotPreviewController() {
        const vm = this;

        vm.onChange = function () {
            vm.timeSlotData.scheduleFrom = moment(vm.timeSlotData.scheduleFrom).toISOString();
            vm.timeSlotData.scheduleTill = moment(vm.timeSlotData.scheduleTill).toISOString();
            vm.timeSlotData.scheduleWeekday = moment(vm.timeSlotData.scheduleWeekday).weekday();
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentScreenCampaignsToolbarController', ContentScreenCampaignsToolbarController);

    function ContentScreenCampaignsToolbarController(
        $rootScope,
        $state,
        $stateParams
    ) {
        const vm = this;

        vm.next = function () {
            $rootScope.$broadcast('nextClick');
        };

        vm.cancel = function () {
            $stateParams.redirect
                ? $state.go($stateParams.redirect)
                : $state.go('app.content');
        };
    }
}());
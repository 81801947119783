(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LineRoutePopup', LineRoutePopup);

    function LineRoutePopup(PopupService) {

        return {
            showPreview
        };

        function showPreview(previewType, lineRouteSettings, departuresDetails) {
            return PopupService.show({
                templateUrl: '/assets/views/content/elements/types/line-route/preview-popup/preview-popup.tpl.html',
                controller: 'LineRoutePreviewPopupController',
                controllerAs: '$ctrl',
                windowClass: `line-route-preview-wrapper ${previewType}-line-route-preview-wrapper`,
                backdrop: true,
                resolve: {
                    data: {
                        previewType,
                        lineRouteSettings,
                        departuresDetails
                    },
                }
            });
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ScreensFilterPopup', ScreensFilterPopup);

    function ScreensFilterPopup(PopupService) {

        return {
            show
        };

        function show(screens) {
            return PopupService.show({
                templateUrl: '/assets/views/common/components/screens-filter/popup/popup.tpl.html',
                controller: 'ScreensFilterPopupController',
                controllerAs: '$ctrl',
                windowClass: 'screens-filter-popup',
                backdrop: true,
                resolve: {
                    // send data to the component
                    data: {screens},
                }
            });
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('concierge', {
            templateUrl: '/assets/views/share-park/concierge/concierge.tpl.html',
            controller: ConciergeController
        });

    function ConciergeController(
        $q,
        ConciergeDataService,
        ConciergePopupService,
        LanguageService,
        $interval,
        StorageFactory,
        $window,
    ) {
        const vm = this;
        const EVENTS_UPDATE_INTERVAL = 10000;
        const STORAGE = StorageFactory.Storage('Main');

        vm.carParks = [];
        vm.events = [];
        vm.updateInterval = null;

        vm.$onInit = onInit;
        vm.$onDestroy = onDestroy;
        vm.openPopup = openPopup;
        vm.isRecentEvent = isRecentEvent;
        vm.getEntranceImageUrl = getEntranceImageUrl;

        function onInit() {
            ConciergeDataService.carParks().then(carParks => {
                vm.carParks = carParks;
                updateEvents();
                vm.updateInterval = $interval(() => {
                    if ($window.document.hasFocus()) {
                        updateEvents();
                    }
                }, EVENTS_UPDATE_INTERVAL);
            })

            LanguageService.getLanguages()
                .then(response => {
                    vm.langs = response.plain();
                });
        }

        function onDestroy() {
            if (vm.updateInterval) {
                $interval.cancel(vm.updateInterval);
            }
        }

        function updateEvents() {
            ConciergeDataService.events().then(events => {
                const getEntranceEvent = entranceExternalId => events.find(event => event.entranceRef === entranceExternalId)
                vm.carParks.forEach(carPark => {
                    carPark.car_park_gateways && carPark.car_park_gateways.forEach(gateway => {
                        gateway.entrances && gateway.entrances.forEach(entrance => {
                            entrance.event = getEntranceEvent(entrance.external_id);
                            if (entrance.event) {
                                entrance.event.timeAgo = generateTimeDiff(entrance.event.createdAt);
                                entrance.event.created = moment(entrance.event.createdAt).format('D MMM YYYY, HH:mm');
                            }
                        });
                    });
                });
            })
        }

        function isRecentEvent(event) {
            const created = moment(event.createdAt);
            const minutesAgo = moment().diff(created, 'minutes');
            return minutesAgo < 1;
        }

        function openPopup(entrance, plateNumber, carPark) {
            return ConciergePopupService.open(entrance, plateNumber, carPark.sub_areas, vm.langs, vm.carParks, carPark.external_id)
                .then(openingData => {
                    if (openingData) {
                        ConciergeDataService.openGate(Object.assign(openingData,
                            {
                                entranceId: entrance.id,
                                conciergeEmail: STORAGE.currentUserInfo.email,
                            }
                        )).then(_ => updateEvents());
                    }
                });
        }

        function generateTimeDiff(date) {
            const mDate = moment(date);

            const seconds = moment().diff(mDate, 'seconds');
            const minutes = moment().diff(mDate, 'minutes');
            const hours = moment().diff(mDate, 'hours');
            const days = moment().diff(mDate, 'days');

            switch (true) {
                case seconds < 60:
                    return seconds + 's';
                case minutes < 60:
                    return minutes + 'm';
                case hours < 60:
                    return hours + 'h';
                default:
                    return days + 'd';
            }
        }

        /**
         * Returns entrance image url
         * @param entrance
         * @param gateway
         * @return {string}
         */
        function getEntranceImageUrl(entrance, gateway) {
            if (entrance.image_ref) {
                return `url(${entrance.image_ref})`;
            }

            return gateway.image_ref ? `url(${gateway.image_ref})` : 'none';
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PasswordGeneratorService', function(PASSWORD_VALIDITY_PATTERN) {
            return {
                generate,
            };

            function generate(length = 12) {
                let password;

                do {
                    password = _generate(length);
                } while (!password.match(PASSWORD_VALIDITY_PATTERN));

                return password;
            }

            /**
             * @param {number} length
             * @return {string}
             */
            function _generate(length) {
                const getRandomChar = () => {
                    const charSets = [
                        [48, 57],  // Numbers (0-9)
                        [65, 90],  // Uppercase letters (A-Z)
                        [97, 122], // Lowercase letters (a-z)
                        [33, 47],  // Special characters (!"#$%&'()*+,-./)
                    ];

                    // Select a random character set
                    const charSet = charSets[Math.floor(Math.random() * charSets.length)];
                    // Generate a random character code from the selected set
                    const charCode = Math.floor(Math.random() *
                        (charSet[1] - charSet[0] + 1)) + charSet[0];
                    // Convert the character code to a character
                    return String.fromCharCode(charCode);
                };

                let password = '';
                for (let i = 0; i < length; i++) {
                    password += getRandomChar();
                }

                return password;
            }
        });
}());

(function() {

    // 'use strict';
    angular.module('beacon.app')
        .config(function($stateProvider, $translateProvider) {
            $stateProvider
                .state('weather', {
                    url: '/weather?lang&lat&lng&units',
                    templateUrl: 'app/weather/weather.html'
                });
        })
        .component('weatherPage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/weather/weather.tpl.html',
            controller: WeatherPageCtrl,
            bindings: {
                contentData: '=',
                pageData: '<'
            }
        });

    function WeatherPageCtrl($scope,
                             ContentDataService, StorageFactory,
                             CONTENT_TYPES) {
        const vm = this;

        // public properties
        vm.selected = null;

        // private methods

        init();
        /**
         * Initialization method
         */
        function init() {
            vm.STORAGE = StorageFactory.Storage('Content-DigitalDisplay');
            vm.weatherList = vm.STORAGE.get('weatherList');

            if (!vm.weatherList) {
                ContentDataService.contents({ contentTypeId: CONTENT_TYPES.WEATHER })
                    .then((response) => processWeathers(response.plain()))
                    .catch(console.error.bind(console));
            } else {
                vm.selected = findSelectedItem();
            }
        }

        /**
         * onFinish handler
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            if (!vm.selected) {
                return;
            }

            vm.pageData.selectedContentId = vm.selected.id;
            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        function processWeathers(weathers) {
            vm.weatherList = weathers.map((item) => {
                item.message = JSON.parse(item.message);
                item.title = JSON.parse(item.title);
                return item;
            });
            vm.weatherList = vm.STORAGE.set('weatherList', vm.weatherList);

            vm.selected = findSelectedItem();
        }

        function findSelectedItem() {
            let selectedItem = vm.weatherList.find((app) => app.id === vm.pageData.selectedContentId);
            return selectedItem || vm.weatherList[0];
        }

        /**
         * Save page listener
         */
        $scope.$on('digital-display-savepage', onFinish);
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('legalTextVersions', {
            templateUrl: '/assets/views/share-park/operations/legal/versions/legal-text-versions.tpl.html',
            controller: LegalTextVersionsController
        });

    function LegalTextVersionsController(
        $stateParams,
        DateHelper,
        LanguageService,
    ) {
        const vm = this;

        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

        vm.$onInit = init;

        vm.updateCounter = 0;
        vm.legalTextVersionsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'legal-text-name',
                    width: '30',
                    title: 'name',
                },
                {
                    name: 'PUBLISHING_DATE',
                    width: '30',
                    title: item => moment.utc(item.published_at).local().format(DATE_TIME_FORMAT),
                },
            ],
            updateCallback: updateElementsList,
            additionalData: {
                langsArray: []
            }
        };

        function init() {
            vm.legalText = $stateParams.legalText;
            return LanguageService.getLanguages().then((response) => {
                vm.legalTextVersionsListData.additionalData.langsArray = response.plain();
            });
        }

        function updateElementsList() {}
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('customCheckboxList', {
            controller: CustomCheckboxListController,
            templateUrl: '/assets/views/common/components/custom-checkbox-list/custom-checkbox-list.tpl.html',
            bindings: {
                listItems: '<',
                filterName: '<',
                isSelected: '<',
                toggleItem: '<',
                getCheckboxLabel: '<'
            },
        });

    function CustomCheckboxListController() {
        const vm = this;
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pushPreviewIos', {
            templateUrl: '/assets/views/common/components/push-preview/ios/ios.tpl.html',
            bindings: {
                title: '<',
                text: '<',
                icon: '<',
                image: '<',
            }
        });
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TierService', TierService);

    function TierService(Restangular) {

        /**
         * Getting the tiers list
         *
         * @returns {array}
         */
        function getList() {
            return Restangular.all('tiers').getList();
        }

        /**
         * Creating tier
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function create(data) {
            return Restangular.all('tiers')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updating the tier
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function save(data) {
            data.append('_method', 'PUT');
            return Restangular.all('tiers')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Delete tier (changing status to 3)
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function remove(data) {
            data.append('_method', 'DELETE');
            return Restangular.all('tiers')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        return {
            getList,
            remove,
            save,
            create
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FeedbackConfigService', FeedbackConfigService);

    function FeedbackConfigService(
        $http,
    ) {
        return {
            getAll,
            getList,
            remove,
            modify,
            create,
            getExample,
            getByAlias,
        }

        function getAll() {
            return $http.get('/api/feedback-forms');
        }

        function getList() {
            return $http.get('/api/feedback-forms-list');
        }

        function remove(id) {
            return $http.delete('/api/feedback-forms/' + id);
        }

        function modify(data) {
            return $http.post('/api/feedback-forms/' + data.id, data);
        }

        function create(data) {
            return $http.post('/api/feedback-forms', data);
        }

        function getByAlias(alias) {
            return $http.get('/api/feedback-forms/' + alias)
        }

        function getExample() {
            return $http.get('/json/feedback-form-example.json');
        }
    }
})();

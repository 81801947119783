(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ElementsController', ElementsController);

    function ElementsController(
        $q,
        $state,
        $stateParams,
        $translate,
        PopupService,
        LanguageProvider,
        UtilitiesService,
        ContentDataService,
        CampaignDataService,
        PermissionsService,
        CONTENT_TYPES,
        CONTENT_TYPES_WHICH_HAVE_STATISTICS,
        CONTENT_TYPES_WHICH_CAN_RELATE_TO_MOBILE_CAMPAIGN,
        HTTP_STATUSES,
        CAMPAIGN_TYPES,
        BANNER_REFERENCE_TYPE,
        ContentHelper,
        GoogleMapsUtilitiesService,
        LoyaltyCampaignService,
    ) {
        const vm = this;
        const { compareAsNumber } = UtilitiesService;
        const { isPermissionAvailable } = PermissionsService;

        // public methods
        vm.createContent = createContent;
        vm.filterContentCallback = filterContentCallback;
        vm.getDefaultTitle = getDefaultTitle;
        vm.updateFilter = updateFilter;
        vm.toggleAllFilter = toggleAllFilter;
        vm.isContentGroupSelectBoxVisible = PermissionsService.isContentGroupMenuItemVisible;

        // public properties
        vm.FILTER_ALL = -1;
        vm.content = [];
        vm.contentFiltered = [];
        vm.contentLength = 0;
        vm.contentFilteredLength = $stateParams.paginationData ? $stateParams.paginationData.count : 0;
        vm.langArray = [];
        vm.contentGroupsArray = [];
        vm.contentGroupId = vm.FILTER_ALL;
        vm.updateCounter = 0;
        vm.selectedTypes = {};
        vm.contentTypes = [];
        vm.chunkedTypes = [];
        vm.searchValue = '';
        vm.updateContentList = updateContentList;
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'content-element-title',
                    width: '35',
                    title: ContentHelper.getContentNameForList
                },
                {
                    name: 'ICON',
                    class: 'contentIcon no-padding',
                    width: '20',
                    title: function(item) {
                        let group = vm.contentGroupsArray.filter((group) => {
                            return group.icon_id.toString() === item.content_group.icon_id.toString();
                        })[0];

                        return group && group.icon ? '<img src="' + group.icon.iconUrl + '">' : 'Icon not available';
                    }
                },
                {
                    name: 'TYPE',
                    class: 'contentType',
                    width: '20',
                    translate: true,
                    title: function(item) {
                        return ContentHelper.isStandaloneBanner(item)
                            ? 'BANNER_STANDALONE'
                            : item.content_type.name;
                    }
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteContent,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: copyContent,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: editContent,
                        permissionAction: 'modify'
                    },
                    {
                        class: 'campaignBtn',
                        callback: editCampaigns,
                        permissionAction: 'modify',
                        isVisible: _canRelateToMobileCampaign,
                    },
                    {
                        class: 'statsBtn',
                        callback: getStats,
                        isVisible: isStatisticsBtnVisible,
                    }
                ]
            },
            updateCallback: updateContentList,
            additionalData: {
                itemMapCallback: GoogleMapsUtilitiesService.showMap,
                getMessageText: ContentHelper.getMessageText,
                updateCampaign,
                updateLoyaltyCampaign,
                updateRelatedContent,
            },
            generatePermissionName: generatePermissionName,
            itemClickCallback,
        };

        //private methods
        let lookupDataLoaded = false;

        init();
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        // Lookup data loading methods

       /**
        *
        * @param {type} response
        * @return {undefined}
        */
        function loadLookupData(callback) {
            if (lookupDataLoaded) {
                callback();
                return;
            }

            var promisesArray = [
                { requestPromise: ContentDataService.contentTypes(), responseHandler: responseContentTypesHandler },
                { requestPromise: LanguageProvider.getTenantLanguages(), responseHandler: responseLanguagesHandler }
            ];

            if (PermissionsService.isContentGroupMenuItemVisible()) {
                promisesArray.push({
                    requestPromise: ContentDataService.contentGroups(),
                    responseHandler: responseContentGroupsHandler
                });
            }

            $q.all(_.map(promisesArray, 'requestPromise')).then((responses) => {
                var isIncorrectResponse = !responses || !_.isArray(responses)
                        || !responses.length || responses.length !== promisesArray.length;
                if (isIncorrectResponse) {
                    return;
                }
                _.forEach(responses, (response,key)=>{
                    promisesArray[key].responseHandler(response);
                });
                lookupDataLoaded = true;
                callback();
            }).catch(console.error.bind(console));
        }

        /**
         * Languages loading handler
         *
         * @param {object} $event
         *
         */
        function responseLanguagesHandler(response) {
            if (_.isArray(response)) {
                vm.langArray = response;
            }
        }

        /**
         * Content types loading handler
         *
         * @param {object} $event
         *
         */
        function responseContentTypesHandler(response) {
            vm.contentTypes = response;

            const selectedTypes = angular.fromJson(localStorage.getItem('elementFilter'));
            vm.selectedTypes = {};
            for (let contentType of vm.contentTypes) {
                vm.selectedTypes[contentType.id] = selectedTypes && selectedTypes[contentType.id];
            }
            localStorage.setItem('elementFilter', JSON.stringify(vm.selectedTypes));

            function chunk(arr, size) {
                var newArr = [];
                for (var i = 0; i < arr.length; i += size) {
                    newArr.push(arr.slice(i, i + size));
                }
                return newArr;
            }

                vm.chunkedTypes = chunk(vm.contentTypes, 3);
        }

        /**
         * Content groups loading handler
         *
         * @param {object} $event
         *
         */
        function responseContentGroupsHandler(response) {
            vm.contentGroupsArray = response;
            vm.contentGroupsArray.forEach((item) => {
                item.title = angular.fromJson(item.title);
            });
        }

        // CRUD content methods

        /**
         * Create new content
         */
        function createContent() {
            $state.go('app.addContent', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                    count: vm.contentFilteredLength
                }
            });
        }

        /**
         * Edit content
         *
         * @param {MouseEvent} $event
         * @param {Object} contentItem
         */
        function editContent($event, contentItem) {
            if ($event) {
                $event.preventDefault(); // to prevent accordion expand/collapse
                $event.stopPropagation();
            }

            $state.go('app.editContent', {
                data: contentItem,
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    count: vm.contentFilteredLength,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Copy content
         *
         * @param {MouseEvent} $event
         * @param {Object} contentItem
         */
        function copyContent($event, contentItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_CONTENT',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then(() => {
                contentItem.copy = true;
                $state.go('app.addContent', {
                    data: contentItem,
                    paginationData: {
                        page: 1,
                        count: vm.contentFilteredLength,
                        itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                    }
                });
            });
        }

        /**
         * Removes content by id
         *
         * @param {MouseEvent} $event
         * @param {object} contentItem
         */
        function deleteContent($event, contentItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CONTENT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                ContentDataService.deleteContent(contentItem.id)
                    .then((response) => {
                        vm.contentLength--;
                        vm.contentFilteredLength--;
                    })
                    .catch((response) => {
                        if (response.status === 400) {
                            _generateRelatedCampaignsMessage(response.data)
                                .then(msg => {
                                    PopupService.showAlertPopup({
                                        text: msg,
                                        okButtonText: 'OK',
                                        html: true,
                                    });
                                });
                        } else if (response.status === HTTP_STATUSES.LOCKED) {
                            Promise.all([
                                _generateRelatedContentMessage(response.data),
                                $translate('OK')
                            ]).then(([text, okButtonText]) => {
                                PopupService.showAlertPopup({
                                    text,
                                    okButtonText,
                                    html: true,
                                });
                            });
                        }
                    });
            });
        }

        function editCampaigns($event, contentItem) {
            $event.preventDefault();
            $event.stopPropagation();

            const isStandaloneBanner = ContentHelper.isStandaloneBanner(contentItem);
            const contentTypeId = isStandaloneBanner
                ? CONTENT_TYPES.INFOTAINMENT
                : contentItem.content_type_id;

            const state = CONTENT_TYPES_WHICH_CAN_RELATE_TO_MOBILE_CAMPAIGN.includes(contentTypeId) ?
                'app.contentCampaigns' : 'app.contentScreenCampaigns';

            if (isStandaloneBanner) {
                ContentDataService.content(contentItem.message.infoId)
                    .then(content => {

                        const contentElement = ContentHelper.processContentItem(vm.langArray, content);
                        $state.go(state, {
                            contentElement
                        });
                    })

            } else {
                $state.go(state, {
                    contentElement: contentItem
                });
            }
        }

        /**
         * Updates list of contents
         *
         * @param {number} page
         * @param {number} itemsPerPage
         * @param {boolean} forceReload
         */
        function updateContentList(page, itemsPerPage = null, forceReload) {
            if (itemsPerPage === null) {
                itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));
            }

            if (forceReload || vm.contentLength !== vm.content.length) {
                loadLookupData(() => {
                    const processContentItem = ContentHelper.processContentItem.bind(null, vm.langArray);
                    ContentDataService.contents().then(data => {
                        const content = Array.isArray(data) ? data : [];
                        vm.contentLength = content.length;
                        vm.contentFiltered = [];
                        vm.content = content.map(processContentItem);

                        filterContentList(page, itemsPerPage);
                }).catch(console.error.bind(console));
            });
            } else {
                filterContentList(page, itemsPerPage);
            }
        }

        /**
         * Filter list of contents
         */
        function filterContentList(page, itemsPerPage) {
            const selectedTypes = getSelectedTypes();
            const itemsPageRangeFrom = (page - 1) * itemsPerPage;
            const itemsPageRangeTo = page * itemsPerPage;

            vm.contentFilteredLength = 0;

            vm.contentFiltered = vm.content
                .filter(item => {
                    const name = ContentHelper.getContentNameForList(item);
                    const searchMatch = !vm.searchValue || name.toUpperCase().includes( vm.searchValue.toUpperCase() );

                    // Checking whether the array of the selected content types contains item's content type.
                    return selectedTypes.indexOf(item.content_type_id) > -1
                        // Checking whether the item's content group is the same as a selected.
                        && [-1, item.content_group_id].indexOf(vm.contentGroupId) > -1
                        // Search query
                        && searchMatch
                        // Checking whether the item is in the page range.
                        && _.inRange(vm.contentFilteredLength++, itemsPageRangeFrom, itemsPageRangeTo);
                });
        }

        /**
         * Get selected types
         * @return {Array} :: types ids
         */
        function getSelectedTypes() {
            let selectedTypes = [];

            for (let key in vm.selectedTypes) {
                if (vm.selectedTypes.hasOwnProperty(key) && vm.selectedTypes[key] === true) {
                    selectedTypes.push(Number.parseInt(key));
                }
            }

            if (!selectedTypes.length) {
                selectedTypes = angular.copy(vm.contentTypes).map((type) => type.id);
            }

            return selectedTypes;
        }

        function updateFilter(type) {
            for (var key in vm.selectedTypes) {
                if (compareAsNumber(key, type.id)) {
                    vm.selectedTypes[key] = !!vm.selectedTypes[key];
                }
            }
            // save to local storage
            localStorage.setItem('elementFilter', JSON.stringify(vm.selectedTypes));

            updateContentList(1);
        }

        function toggleAllFilter(value) {
            for (var key in vm.selectedTypes) {
                vm.selectedTypes[key] = value;
            }
            localStorage.setItem('elementFilter', JSON.stringify(vm.selectedTypes));
            updateContentList(1);
        }

        /**
         * Filter content callback
         */
        function filterContentCallback() {
            vm.updateCounter++;
        }

        // Additional
        function getStats($event, contentItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            $state.go('app.statistics', {data: contentItem});
        }

        /**
         * Returns default translation
         *
         * @param {object} item
         * @returns {string}
         */
        function getDefaultTitle(item) {
            var keys = Object.keys(item),
                title = '',
                language;

            if (keys.length) {
                language = vm.langArray.filter(function(language) {
                    return language.id.toString() === keys[0];
                })[0];

                title = language ? (item[keys[0]] + ' [' + language.code + ']') : '';
            }

            return title;
        }

        /**
         * Generates permission name for each button on content accordion-list
         * @param {object} button Button from accordion-list
         * @param {object} item
         * @return {string} Permission name
         */
        function generatePermissionName(button, item) {
            return PermissionsService.generateContentPermission(button.permissionAction, item.content_type_id);
        }

        /**
         * Determines if the statistics btn is visible
         *
         * @param {object} btn
         * @param {object} contentItem
         * @return {boolean} True if visible, false otherwise
         */
        function isStatisticsBtnVisible(btn, contentItem) {
            if (!PermissionsService.isStatisticsVisible()) {
                return false;
            }

            return CONTENT_TYPES_WHICH_HAVE_STATISTICS.some(v => v === contentItem.content_type_id);
        }

        function _generateRelatedCampaignsMessage(campaigns) {
            return $q.all([
                $translate('CAMPAIGN_CONTENT_CANT_BE_DELETED'),
                $translate('CAMPAIGN'),
            ]).then(([msg, campaignLabel]) => {
                return msg + ':<br><br>' + campaigns
                    .map(campaign => `${campaign.name} (${campaignLabel})`)
                    .join('<br>');
            });
        }

        /**
         * Generate alert message with related contents
         *
         * @param {object} data
         * @return {string}
         * @private
         */
        function _generateRelatedContentMessage(data) {
            const related = [];

            return Promise.all([
                $translate('THIS_CONTENT_ELEMENT_IS_RELATED_TO'),
                $translate('BANNER_NAME'),
                $translate('ONBOARDING_NAME'),
                $translate('DIGITAL_DISPLAY_NAME'),
            ]).then(([message, bannerName, onboardingName, digitalDisplayName]) => {
                data[CONTENT_TYPES.BANNER] && data[CONTENT_TYPES.BANNER].forEach(item => {
                    item.title = JSON.parse(item.title);
                    related.push(`${item.title[item.language_id]} (${bannerName})`);
                });

                data[CONTENT_TYPES.ONBOARDING] && data[CONTENT_TYPES.ONBOARDING].forEach(item => {
                    item.title = JSON.parse(item.title);
                    related.push(`${item.title[item.language_id]} (${onboardingName})`);
                });

                data[CONTENT_TYPES.DIGITAL_DISPLAY] && data[CONTENT_TYPES.DIGITAL_DISPLAY].forEach(item => {
                    item.title = JSON.parse(item.title);
                    related.push(`${item.title[item.language_id]} (${digitalDisplayName})`);
                });

                return `${message}:<br><br>${related.join('<br>')}`;
            });
        }

        /**
         * Handler on list item click
         * @param item
         */
        function itemClickCallback(item) {
            if (!item.isOpen && item.content_type_id === CONTENT_TYPES.BANNER) {
                onBannerOpen(item);
            }
        }

        /**
         * Handler on banner accordion list item open
         * @param banner
         */
        function onBannerOpen(banner) {
            switch (banner.message.referenceType) {
                case BANNER_REFERENCE_TYPE.INFOTAINMENT:
                    getRelatedContent(banner);
                    break;
                case BANNER_REFERENCE_TYPE.LOYALTY_CAMPAIGN:
                    LoyaltyCampaignService.campaign(banner.message.infoId).then(campain =>
                        banner.loyaltyCampaign = campain
                    );
            }
        }

        /**
         * Get banner related content
         * @param banner
         */
        function getRelatedContent(banner) {
            let content = vm.content.find(content => content.id === banner.message.infoId);

            if (!!content) {
                banner.relatedContent = content;
                return;
            }

            ContentDataService.content(banner.message.infoId).then(relatedContent => {
                banner.relatedContent = ContentHelper.processContentItem(vm.langArray, relatedContent);
            });
        }

        /**
         * Updates campaign
         * @param campaign
         */
        function updateCampaign(campaign) {
            const { generateCampaignPermission, actions } = PermissionsService;
            let targetState, campaignTypeName, campaignTypePermission;
            switch (campaign.campaign_type) {
                case CAMPAIGN_TYPES.MOBILE:
                    targetState = 'app.editCampaign';
                    campaignTypeName = 'MOBILE_CAMPAIGN';
                    campaignTypePermission = 'mobile';
                    break;
                case CAMPAIGN_TYPES.PUSH:
                    targetState = 'app.editPushCampaign';
                    campaignTypeName = 'PUSH_CAMPAIGN';
                    campaignTypePermission = 'push';
                    break;
                case CAMPAIGN_TYPES.SCREEN:
                    targetState = 'app.editScreenCampaign';
                    campaignTypeName = 'SCREEN_CAMPAIGN';
                    campaignTypePermission = 'screen';
            }

            const permissionName = generateCampaignPermission(actions.modify, campaignTypePermission);

            if (!isPermissionAvailable(permissionName)) {
                return Promise.all([
                    $translate('YOU_DONT_HAVE_PERMISSION_TO_EDIT'),
                    $translate(campaignTypeName),
                    $translate('OK'),
                ]).then(([message, campType, okButtonText]) => {
                    PopupService.showAlertPopup({
                        text: `${message} ${campType.toLowerCase()}`,
                        okButtonText
                    });
                });
            }

            CampaignDataService.campaign(campaign.id).then(campaignData => {
                campaignData.additional_data = campaignData.additional_data
                    ? angular.fromJson(campaignData.additional_data)
                    : {};
                $state.go(targetState, { data: campaignData });
            });
        }

        /**
         * Update related content
         * @param content
         */
        function updateRelatedContent(content) {
            const { generateContentPermission, actions } = PermissionsService;
            const permissionName = generateContentPermission(actions.modify, content.content_type_id);

            if (!isPermissionAvailable(permissionName)) {
                return Promise.all([
                    $translate('YOU_DONT_HAVE_PERMISSION_TO_EDIT'),
                    $translate('THIS_CONTENT'),
                    $translate('OK'),
                ]).then(([message, thisContent, okButtonText]) => {
                    PopupService.showAlertPopup({
                        text: `${message} ${thisContent}`,
                        okButtonText
                    });
                });
            }

            editContent(null, content);
        }

        /**
         * Update loyalty campaign
         * @param campaign
         */
        function updateLoyaltyCampaign(campaign) {
            if (!isPermissionAvailable('can_modify_loyalty_campaign')) {
                return Promise.all([
                    $translate('YOU_DONT_HAVE_PERMISSION_TO_EDIT'),
                    $translate('LOYALTY_CAMPAIGN'),
                    $translate('OK'),
                ]).then(([message, loyaltyCamp, okButtonText]) => {
                    PopupService.showAlertPopup({
                        text: `${message} ${loyaltyCamp.toLowerCase()}`,
                        okButtonText
                    });
                });
            }

            $state.go('app.loyaltyCampaignsEdit', {
                data: campaign,
                type: 'edit'
            });
        }

        /**
         * Check is content can relate to mobile campaign
         *
         * @param btn
         * @param contentItem
         * @return {*|boolean}
         * @private
         */
        function _canRelateToMobileCampaign(btn, contentItem) {

            const contentTypeId = ContentHelper.isStandaloneBanner(contentItem)
                ? CONTENT_TYPES.INFOTAINMENT
                : contentItem.content_type_id;
            return ContentHelper.canRelateToCampaign(contentTypeId);
        }
    }
}());

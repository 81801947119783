(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkOpeningTimePicker', {
            templateUrl: '/assets/views/share-park/car-parks/new/car-park-opening-time-picker/car-park-opening-time-picker.tpl.html',
            controller: CarParkOpeningTimePickerController,
            bindings: {
                openingHoursData: '<',
                openingHoursRef: '<',
                onOpeningParamsChange: '<',
                languages: '<',
                langId: '<',
                form: '<',
            }
        });

    function CarParkOpeningTimePickerController(
        ShareParkDataService,
        WEEK_DAYS,
    ) {
        const vm = this;

        const HOLIDAYS_DEFAULT_ID = 8;
        vm.definedOpeningHours = [];
        vm.openingHour = {};

        vm.$onInit = init;

        vm.onCheckboxClick = onCheckboxClick;
        vm.isCheckboxChecked = isCheckboxChecked;
        vm.onOpeningHoursSourceChange = onOpeningHoursSourceChange;
        vm.onOpeningHoursSelect = onOpeningHoursSelect;
        vm.onOpeningTimeChange = onOpeningTimeChange;
        vm.onOpeningHoursChange = onOpeningHoursChange;

        vm.WEEK_DAYS = [ ...WEEK_DAYS, {
            name: 'HOLIDAYS',
            id: HOLIDAYS_DEFAULT_ID
        }];

        /**
         * Initialization method
         */
        function init() {
            vm.openingHour = decodeOpeningHours(vm.openingHoursData);

            if (angular.isNumber(vm.openingHoursRef)) {
                vm.useExistingOpeningHours = true;
                vm.sourceOpeningHoursRef = vm.openingHoursRef;
            }

            ShareParkDataService.getOpeningHours()
                .then(response => {
                    vm.definedOpeningHours = response.plain().map(decodeOpeningHours);
                    vm.useExistingOpeningHours && onOpeningHoursSelect();
                })
        }

        function onOpeningHoursSourceChange() {
            if (!vm.useExistingOpeningHours) {
                return vm.onOpeningParamsChange(encodeOpeningHours(vm.openingHour));
            }

            onOpeningHoursSelect();
        }

        function onOpeningHoursSelect() {
            const selectedOpeningHours = vm.definedOpeningHours.find(openingHours =>
                openingHours.id === vm.sourceOpeningHoursRef
            );

            if (!selectedOpeningHours) {
                return vm.onOpeningParamsChange();
            }

            vm.openingHour = angular.copy(selectedOpeningHours);
            vm.onOpeningParamsChange(encodeOpeningHours(vm.openingHour), vm.sourceOpeningHoursRef);
        }

        function onOpeningHoursChange() {
            vm.onOpeningParamsChange(
                encodeOpeningHours(vm.openingHour),
                vm.useExistingOpeningHours ? vm.sourceOpeningHoursRef : null
            );
        }

        /**
         * Checks if checkbox is checked
         * @param dayId
         * @return {boolean}
         */
        function isCheckboxChecked(dayId) {
            return !!vm.openingHour.parameters[dayId];
        }

        /**
         * Handler on checkbox click
         * @param dayId
         */
        function onCheckboxClick(dayId) {
            if (!vm.openingHour.parameters[dayId]) {
                vm.openingHour.parameters[dayId] = {};
            } else {
                delete vm.openingHour.parameters[dayId];
            }

            vm.onOpeningParamsChange(encodeOpeningHours(vm.openingHour));
        }

        function onOpeningTimeChange() {
            vm.onOpeningParamsChange(encodeOpeningHours(vm.openingHour));
        }

        function decodeOpeningHours(openingHour) {
            if (!openingHour) {
                return {
                    name: '',
                    parameters: {}
                }
            }


            const params = angular.isObject(openingHour.parameters)
                ? openingHour.parameters
                : angular.fromJson(openingHour.parameters);
            const decodedParams = {};

            Object.keys(params).forEach(key => {
                const [endStr, startSrt] = params[key].split('-');

                decodedParams[key] = {
                    timeClose: moment(endStr, 'HH:mm'),
                    timeOpen: moment(startSrt, 'HH:mm'),
                };
            });

            return {
                name: openingHour.name,
                id: openingHour.id,
                parameters: decodedParams,
                description: angular.isObject(openingHour.description)
                    ? openingHour.description
                    : decodeOpeningHourDescription(openingHour)
            }
        }

        /**
         * Decodes opening hours description field
         * @param openingHour
         * @return {{}}
         */
        function decodeOpeningHourDescription(openingHour) {
            if (!openingHour.description) {
                return {};
            }

            const descriptionObj = angular.fromJson(openingHour.description);
            const decodedFieldValue = {};

            Object.keys(descriptionObj).forEach(langCode => {
                const lang = vm.languages.find(lang =>
                    lang.code === langCode
                );

                if (!lang) {
                    return;
                }

                decodedFieldValue[lang.id] = descriptionObj[langCode].replace(/<br\s*\/?>/g, "\n");
            });

            return decodedFieldValue;
        }

        function encodeOpeningHours(openingHour) {
            const encodedParameters = {};
            Object.keys(openingHour.parameters).forEach(key => {
                const params = openingHour.parameters[key];

                if (params.timeClose && params.timeOpen) {
                    const timeClose = moment.isMoment(params.timeClose) ? params.timeClose : moment(params.timeClose);
                    const timeOpen = moment.isMoment(params.timeOpen) ? params.timeOpen : moment(params.timeOpen);
                    encodedParameters[key] = `${timeClose.format('HH:mm')}-${timeOpen.format('HH:mm')}`;
                }
            });

            return {
                id: openingHour.id || null,
                name: openingHour.name,
                parameters: encodedParameters,
                description: openingHour.description
            }
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LineRoutePreviewPopupController', LineRoutePreviewPopupController);

    function LineRoutePreviewPopupController(data, LINE_ROUTE_ORIENTATIONS) {
        const vm = this;

        vm.lineRouteSettings = data.lineRouteSettings;
        vm.previewType = data.previewType || LINE_ROUTE_ORIENTATIONS.HORIZONTAL;
        vm.departuresDetails = data.departuresDetails;
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewProductToolbarController', NewProductToolbarController);

    function NewProductToolbarController(
        $state,
        StatesFactory
    ) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ProductStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.shareParkProducts');
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
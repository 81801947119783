(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userInformationTab', {
            templateUrl: '/assets/views/users/new/tabs/user-information/user-information.tpl.html',
            controller: NewUserInformationTab,
            bindings: {
                data: '=',
                userForm: '<',
            }
        });

    /**
     * @param $scope
     * @param $stateParams
     * @param newUserState
     * @param UtilitiesService
     * @param PopupService
     * @param UserDataService
     * @param {PasswordGeneratorService} PasswordGeneratorService
     * @constructor
     */
    function NewUserInformationTab(
        $scope,
        $stateParams,
        newUserState,
        UtilitiesService,
        PopupService,
        UserDataService,
        PasswordGeneratorService,
    ) {
        const vm = this;
        const { sortByStringProperty, sortByDateProperty } = UtilitiesService;

        // public properties
        vm.state = newUserState;

        // public methods
        vm.resetUsernameValidity = resetUsernameValidity;
        vm.resetFirstnameValidity = resetFirstnameValidity;
        vm.resetLastnameValidity = resetLastnameValidity;
        vm.resetEmailValidity = resetEmailValidity;
        vm.resetTenantValidity = resetTenantValidity;
        // vm.onResetPassword = onResetPassword;

        vm.generatePassword = () => {
            const password = PasswordGeneratorService.generate();

            vm.data.userData.password = password;
            vm.data.userData.confirmPassword = password;
        }

        init();

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            $scope.$watch(
                () => vm.userForm && vm.userForm.$valid,
                newValue => vm.state.canProcessNext = newValue
            );

        }

        // REMOVED DUE TO SECURITY REPORT
        // function onResetPassword() {
        //     PopupService.showConfirmationPopup({
        //         text: 'ARE_YOU_SURE',
        //         okButtonText: 'YES',
        //         cancelButtonText: 'NO',
        //     }, _ => {
        //         UserDataService.resetPassword(vm.data.userData.id).then(response => {
        //             PopupService.showAlertPopup({
        //                 text: 'PASSWORD_IS_CHANGED_AND_SENT',
        //                 okButtonText: 'OK',
        //             });
        //         });
        //     });
        // }

        /**
         * Resets username validation state
         */
        function resetUsernameValidity() {
            vm.userForm && vm.userForm.username.$setValidity('serverValidation', true);
        }

        /**
         * Resets firstname validation state
         */
        function resetFirstnameValidity() {
            vm.userForm &&  vm.userForm.firstname.$setValidity('serverValidation', true);
        }

        /**
         * Resets lastname validation state
         */
        function resetLastnameValidity() {
            vm.userForm && vm.userForm.lastname.$setValidity('serverValidation', true);
        }

        /**
         * Resets email validation state
         */
        function resetEmailValidity() {
            vm.userForm && vm.userForm.email.$setValidity('serverValidation', true);
        }

        /**
         * Resets tenant id validation state
         */
        function resetTenantValidity() {
            vm.userForm && vm.userForm.tenant.$setValidity('serverValidation', true);
        }
    }
}());

(function () {
    'use strict';

    angular.module('beacon.app')
        .service('SponsorDataService', SponsorDataService);

    function SponsorDataService($http) {
        return {
            getSponsors,
            setSponsor,
        };

        /**
        * Get list
        *
        * @returns {SponsorConfig}}
        */
        function getSponsors(data) {
            return $http.get('/api/sponsors').then(response => response.data.list);
        }

        /**
        * Create, Update
        *
        * @param {object} data
        * @returns {SponsorConfig}
        */
        function setSponsor(data) {
            return $http.post(`/api/sponsors`, data);
        }
    }

})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FindStationPopupService', (PopupService) => {
            return {
                show
            };

            /**
             * @param {{latitude: number, longitude: number}} location
             * @return {Promise<StationV2>}
             */
            function show(location = undefined) {
                return PopupService.show({
                    templateUrl: '/assets/views/location/new-screen/find-station-popup/find-station-popup.tpl.html',
                    controller: 'FindStationPopupController',
                    controllerAs: '$ctrl',
                    backdrop: true,
                    resolve: {
                        data: () => (
                            {
                                location
                            }
                        )
                    }
                });
            }
        });
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('EnvFactory', EnvFactory);

    function EnvFactory($q, $http) {
        let env = null;

        return {
            getVariables,
        }

        /**
         * @typedef Env
         * @property {string} appTitle
         * @property {string} appDescription
         */

        /**
         * @returns {Promise<Env>}
         */
        function getVariables() {
            return $q((resolve, reject) => {
                if (env) {
                    resolve(env);
                } else {
                    $http.get('/env/global')
                        .then(response => {
                            env = response.data;
                            resolve(env);
                        });
                }
            })
        }
    }
})();

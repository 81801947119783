(function() {
    'use strict';

    angular.module('beacon.app')
        .component('groupingCheckboxList', {
            templateUrl: '/assets/views/common/components/grouping-checkbox-list/grouping-checkbox-list.tpl.html',
            controller: GroupingCheckboxListController,
            bindings: {
                listData: '<',
                items: '<',
                selectedItems: '<',
                disabled: '<?',
            }
        });

    function GroupingCheckboxListController($scope) {
        const vm = this;

        vm.$onInit = init;

        const defaultItemsListData = {
            itemClickCallback: toggleItem,
        };

        const defaultGroupsListData = {
            itemClickCallback: toggleGroup,
        };

        // public properties
        vm.selectedGroups = [];

        function init() {
            if (!vm.selectedItems) {
                vm.selectedItems = [];
            }
            vm.groupsListData = Object.assign(vm.listData.groupsList, defaultGroupsListData);
            vm.itemsListData = Object.assign(vm.listData.itemsList, defaultItemsListData);
            getGroupsFromItemsList();
        }

        function getGroupsFromItemsList() {
            vm.groups =
                _.chain(vm.items)
                    .map('groups')
                    .flatten()
                    .uniqBy('id')
                    .value();
        }

        function toggleItem(item) {
            updateSelectedGroups();

            if (angular.isFunction(vm.listData.updateSelectedItemsListCallback)) {
                vm.listData.updateSelectedItemsListCallback(vm.selectedItems);
            }
        }

        function toggleGroup(group) {

            let groupItems = getGroupItems(group);
            if (_.find(vm.selectedGroups, ["id", group.id])){
                vm.selectedItems = _.unionWith(vm.selectedItems, groupItems, _.isEqual);
            } else {
                vm.selectedItems = _.pullAllBy(vm.selectedItems, groupItems, "id");
            }

            if (angular.isFunction(vm.listData.updateSelectedItemsListCallback)) {
                vm.listData.updateSelectedItemsListCallback(vm.selectedItems);
            }
        }

        /**
         * Returns items which are inside this group
         * @param {object} group
         * @return {Array}
         */
        function getGroupItems(group) {
            return _.filter(vm.items,
                item => !!_.find(item.groups, ["id", group.id]));
        }

        /**
         * Updates selected groups list according to selected locations
         */
        function updateSelectedGroups() {

            // creates new selectedGroupsIds array according to selected list changes
            const selectedGroupsIds = vm.groups.filter(group => {
                const groupItems = getGroupItems(group);
                return groupItems.every(item => {
                    return vm.selectedItems.some(selectedItem => selectedItem.id === item.id);
                })
            }).map(group => group.id);

            vm.selectedGroups = _.filter(vm.groups,
                group => selectedGroupsIds.indexOf(group.id) !== -1);
        }

        function onListChange() {
            getGroupsFromItemsList();
            updateSelectedGroups();
        }

        $scope.$watchCollection(() => vm.selectedItems, onListChange);

        $scope.$watchCollection(() => vm.items, onListChange);
    }
})();
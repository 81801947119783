(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LoyaltyCampaignsFormToolbarController', LoyaltyCampaignsFormToolbarController);

    function LoyaltyCampaignsFormToolbarController($scope, StatesFactory, $state, $stateParams, PermissionsService) {
        const vm = this;
        vm.stateParams = $stateParams;
        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.LoyaltyCampaignStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = false;

        const { actions, isPermissionAvailable, generateSegmentPermission } = PermissionsService;

        vm.getTabClass = getTabClass;
        vm.next = next;
        vm.back = back;
        vm.cancel = cancel;
        vm.finish = finish;
        vm.isLastTab = isLastTab;

        /**
         * Initialization method
         */
        function init() {

            vm.showAudiences = isPermissionAvailable(generateSegmentPermission(actions.view));
            if (!vm.showAudiences) {
                delete vm.stateAss.state.views.TARGETING;
            }
            vm.tabs = Object.keys(vm.stateAss.state.views);
        }

        /**
         * Getting class name for tabs, eg. "active", "disabled" or "done"
         *
         * @param {string} tab
         * @returns {string}
         */
        function getTabClass(tab) {
            if (!(tab in vm.stateAss.state.views)) {
                return "";
            }

            return vm.stateAss.state.is(tab) ? 'active' : vm.stateAss.state.view < vm.stateAss.state.views[tab][0] ? 'disabled' : 'done';
        }

        /**
         * "Next" button handler
         */
        function next() {
            vm.stateAss.state.next();
        }

        /**
         * "Back" button handler
         */
        function back() {
            vm.stateAss.state.back();
        }

        /**
         * "Cancel" button handler
         */
        function cancel() {
            $state.go('app.loyaltyCampaigns');
        }

        function finish() {
            vm.state.finish = true;
        }

        /**
         * Check if current tab is last one
         */
        function isLastTab() {
            let lastTabIndex = 0;
            Object.keys(vm.state.views).forEach(key => {
                if (vm.state.views[key][0] > lastTabIndex) {
                    lastTabIndex = vm.state.views[key][0];
                }
            });
            return vm.state.view === lastTabIndex;
        }

        init();
    }
}());
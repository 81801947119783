(function() {
    'use strict';

    angular.module('beacon.app')
        .component('chargingStations', {
            templateUrl: '/assets/views/share-park/charging-stations/charging-stations.tpl.html',
            controller: ChargingStationsController
        });

    function ChargingStationsController(
        $q,
        $state,
        $stateParams,
        ChargingStationDataService,
        UtilitiesService,
        FileService,
        PopupService,
        ShareParkDataService,
        ChargingStationHelper,
    ) {
        const vm = this;
        let carParks = [];

        vm.chargingStations = [];
        vm.chargingStationsCount = 0;
        vm.dataLoaded = false;

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'device-title',
                    width: '30',
                    title: chargingDevice => ChargingStationHelper.defaultTitle(chargingDevice),
                },
                {
                    name: 'CAR_PARK',
                    width: '30',
                    title: chargingDevice => deviceCarPark(chargingDevice),
                    translate: true,
                },
                {
                    name: 'STATUS',
                    width: '10',
                    title: chargingDevice => ChargingStationHelper.status(chargingDevice),
                    translate: true,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: onDelete,
                        permissionAction: 'delete',
                    },
                    {
                        class: 'editBtn',
                        callback: onEdit,
                        permissionAction: 'modify',
                    },
                    {
                        class: 'copyBtn',
                        callback: onCopy,
                        permissionAction: 'create',
                    }
                ]
            },
            updateCallback: onUpdate,
            generatePermissionName,
        };

        vm.onCreate = onCreate;
        vm.$onInit = init;

        function init() {
            $q.all([
                ShareParkDataService.getCarParksList(),
                loadStations(),
            ]).then(response => {
                carParks = response[0].plain();
                vm.dataLoaded = true;
            });
        }

        function onUpdate(page, itemsPerPage) {
            vm.chargingStationsList = UtilitiesService.getArrayPage(vm.chargingStations, page, itemsPerPage);
        }

        function onDelete($event, chargingStation) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'You are about to delete this station. Are you sure you want to continue?',
                okButtonText: 'Delete',
                cancelButtonText: 'Cancel'
            }, function() {
                FileService.deleteFromS3(chargingStation.image);
                ChargingStationDataService.remove(chargingStation).then(() => loadStations());
            });
        }

        function onEdit($event, chargingStation) {
            $event.preventDefault();
            $event.stopPropagation();
            $state.go('app.editChargingStation', {
                data: chargingStation,
            });
        }

        function onCopy($event, chargingStation) {
            $event.preventDefault();
            $event.stopPropagation();

            const copy = angular.copy(chargingStation);
            delete copy.id;
            delete copy.external_id;

            $state.go('app.addChargingStation', {
                data: copy,
                copy: true,
            });
        }

        function onCreate() {
            $state.go('app.addChargingStation');
        }

        function deviceCarPark(chargingStation) {
            const carPark = carParks.find(item => item.id === chargingStation.car_park_id);
            return carPark ? carPark.name : '-';

        }

        function loadStations() {
            return ChargingStationDataService.getAll().then(response => {
                vm.chargingStations = response.plain();
                vm.chargingStationsCount = vm.chargingStations.length;
            });
        }

        function generatePermissionName(button) {
            return 'can_' + button.permissionAction + '_charging_stations';
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenUrl', {
            controller: ScreenUrlController,
            templateUrl: '/assets/views/common/components/screen-url/screen-url.tpl.html',
            bindings: {
                url: '<',
            },
        });

    function ScreenUrlController(
        $translate,
        UtilitiesService
    ) {
        const vm = this;

        vm.$onInit = init;
        vm.copyUrl = copyUrl;

        const { generateGUID } = UtilitiesService;

        /**
         * Copy screen URL to clipboard
         */
        function copyUrl() {
            const selector = `#${vm.id}`;
            const input = document.querySelector(selector);
            input.select();
            document.execCommand('copy');
        }

        /**
         * Constructor
         */
        function init() {
            vm.id = `screen-url-${generateGUID()}`;
        }
    }
})();

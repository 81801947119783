(function() {
    'use strict';

    angular.module('trmDigitalDisplayStaticHeader')
        .component('date', {
            templateUrl: '/screen/views/components/digital-display-static-header/date/date.tpl.html',
            controller: DateController,
        });

    /**
     * @constructor
     *
     * @param $interval
     */
    function DateController(
        $interval,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            _update();
            $interval(_update, 60 * 1000);
        }

        function _update() {
            $ctrl.time = new Date();
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('MapPopupController', MapPopupController);

    function MapPopupController($scope, $uibModalInstance, data) {
        const vm = this,
            DEFAULT_LAT = 50,
            DEFAULT_LNG = 10,
            DEFAULT_ZOOM = 4,
            DEFAULT_MAX_ZOOM = false,
            initialZoom = data.mapDefaults.zoom || DEFAULT_ZOOM;

        // public properties
        vm.data = data;
        vm.markers = [];
        vm.fit = true;
        vm.markerCount = 0;
        vm.mapEvents = {
            click: addMarker,
        };

        vm.singleMarker = false;

        vm.map = {
            center: {
                latitude: data.mapDefaults && data.mapDefaults.latitude || DEFAULT_LAT,
                longitude: data.mapDefaults && data.mapDefaults.longitude || DEFAULT_LNG
            },
            zoom: initialZoom,
            options: {
                maxZoom: data.mapDefaults.maxZoom || DEFAULT_MAX_ZOOM
            }
        };

        // public methods
        vm.ok = okHandler;
        vm.okDisabled = okDisabled;
        vm.cancel = cancelHandler;
        vm.removeMarker = removeMarker;

        init();

        /**
         * OK button handler
         */
        function okHandler() {
            let mapInfo = vm.markers.map(elem => elem.coordinates);

            if(vm.singleMarker) {
                const coordinates = mapInfo.pop();
                mapInfo = { coordinates };
                mapInfo.zoom = vm.map.zoom;
            }

            $uibModalInstance.close(mapInfo);
        }

        /**
         * Cancel button handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        // private methods

        /**
         * Adds new maker
         *
         *@param {object} GoogleMap object
         *@param {string} event name
         *@param {object} arguments provided by Google Maps fot click
         */
        function addMarker(mapModel, eventName, eventArgs) {
            if (vm.data.canEdit) {
                if (vm.singleMarker && vm.markers.length) {
                    vm.markers = [];
                }

                vm.fit = false; // to not fit map to new markers
                $scope.$apply(function() {
                    vm.markers.push({
                        id: vm.markerCount++,
                        coordinates: {
                            latitude: eventArgs[0].latLng.lat(),
                            longitude: eventArgs[0].latLng.lng()
                        }
                    });
                });
            }
        }

        /**
         * Removes maker
         *
         *@param {object} instance of the google.maps.Marker
         *@param {string} event name
         *@param {object} marker object
         */
        function removeMarker(marker, eventName, markerElem) {
            if (vm.data.canEdit && !(vm.singleMarker && !vm.markers.length)) {
                vm.fit = false; // to not fit map to markers that remains
                marker.setMap(null);
                vm.markers.splice(vm.markers.indexOf(markerElem), 1);
            }
        }

        /**
         * Initialization method
         */
        function init() {
            if (vm.data.mapInfo) {
                vm.markers = vm.data.mapInfo.map(function(elem, index) {
                    return {
                        id: index,
                        coordinates: elem
                    };
                });
            }

            vm.singleMarker = vm.data.singleMarker || false;
            vm.markerCount = vm.markers.length;
            setSingleMarkerZoom();
        }

        function okDisabled() {
            return vm.singleMarker && !vm.markers.length;
        }

        /**
         * Avoid max zoom fitting to a single marker
         * Works only if property "vm.fit" is "true"
         */
        function setSingleMarkerZoom() {
            if (vm.markerCount !== 1 || !vm.fit) {
                return;
            }

            const watcher = $scope.$watch(
                () => vm.map.zoom,
                zoom => {
                    if (zoom > initialZoom) {
                        vm.map.zoom = initialZoom;
                        watcher();
                    }
                },
            );
        }
    }
}());

(function() {
    'use strict';

    angular.module('trmTimetableHafas').service('LocationsProviderService', function(
        $q,
        MgateService,
        LogService,
        MultiStationsHelper,
        MgateLocationValidator,
    ) {
        return {
            getStationsWithPoles,
        }

        /**
         * @typedef {{
         *   subdomainId: number,
         *   screenController: ScreenController,
         *   stationsFilter: number[],
         *   mctConfig: MctConfig,
         * }} LocationProviderOptions
         */

        /**
         * @typedef {{
         *   id: string,
         *   name: string,
         *   poles: MgateLocation[]
         * }} LocationProviderStation
         */

        /**
         * @param {LocationProviderOptions} options
         * @return {Promise<LocationProviderStation[]>}
         */
        function getStationsWithPoles(options) {
            const subdomainId = options.subdomainId;
            const stations = _generateStationsWithPoles(options);

            /* @type string[][] */
            const poleIds = Object
                .values(stations)
                .map(item => Object.keys(item.poles))
                .filter(ids => ids.length);

            if (!poleIds.length) {
                const result = stations.map(station => {
                    return {
                        id: station.id,
                        name: station.name,
                        poles: [],
                    }
                });

                _logStations(result);

                return $q.resolve(result);
            }

            return MgateService
                .locations({
                    subdomainId,
                    poleIds,
                })
                .then(response => {
                    MgateLocationValidator.validate(response, poleIds);

                    const result = stations.map(station => {
                        const somePolesSelected = Boolean(Object.keys(station.poles).length);

                        if (somePolesSelected) {
                            const responsePart = response.svcResL.shift();
                            const poleLocations = responsePart.res.locL;

                            return {
                                id: station.id,
                                name: station.name,
                                poles: poleLocations,
                            }
                        } else {
                            return {
                                id: station.id,
                                name: station.name,
                                poles: [],
                            }
                        }
                    });

                    _logStations(result);

                    return result;
                });
        }

        function _log(...args) {
            LogService.colored('#00b003', '#FFF', 'Location Provider |', ...args);
        }

        /**
         * @param {LocationProviderStation[]} stations
         * @private
         */
        function _logStations(stations) {
            _log(
                'Stations:',
                stations.map(item => ({
                    ...item,
                    poles: item.poles.map(pole => pole.extId).join(', ') || 'ALL',
                }))
            );
        }

        /**
         * @param {LocationProviderOptions} options
         * @return {{id: string, name: string, poles: MctConfigPoleFilter}[]}
         * @private
         */
        function _generateStationsWithPoles(options) {
            const controller = options.screenController;
            const stationsFilter = options.stationsFilter;
            const mctConfig = options.mctConfig;

            try {
                const stations = MultiStationsHelper.getMultiStations(controller);
                const poleFilters = mctConfig.poleFilters;

                // Multi stations format
                return Object
                    .keys(stations)
                    .filter(index => {
                        return !angular.isArray(stationsFilter)
                            || !stationsFilter.length
                            || stationsFilter.includes(parseInt(index));
                    })
                    .map(index => {
                        return {
                            id: stations[index].id,
                            name: stations[index].name,
                            poles: poleFilters && poleFilters[index] || {}, // do not replace with ?:
                        }
                    })
            } catch (error) {
                // Backward compatibility with single station format
                return [
                    {
                        id: controller.station.stopId,
                        name: controller.station.stopName,
                        poles: mctConfig.poleFilter,
                    }
                ]
            }
        }
    });
})();


(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capServiceProviders', {
            templateUrl: '/assets/views/share-park/customers/service-providers/service-providers.tpl.html',
            controller: ServiceProvidersController
        });

    function ServiceProvidersController(
        $state,
        $translate,
        ShareParkDataService,
        PermissionsService,
        PopupService,
        PdfPopupService,
        UtilitiesService,
    ) {
        const vm = this;

        const { actions, generateCapServiceProviderPermission } = PermissionsService;

        vm.$onInit = init;

        vm.serviceProvidersAll = [];
        vm.count = 0;

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'service-providers-list-name',
                    width: '20',
                    title: listItem => listItem.provider.fullCompanyName,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteServiceProvider,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editServiceProvider,
                        permissionAction: actions.modify
                    },
                    {
                        class: 'receiptBtn',
                        callback: createServiceProviderBill,
                        permissionAction: 'bill'
                    },

                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        vm.addServiceProvider = addServiceProvider;
        vm.editServiceProvider = editServiceProvider;
        vm.billAllProviders = billAllProviders;

        /**
         * Controller initialization
         */
        function init() {
            ShareParkDataService.getCapServiceProviders()
                .then(response => {
                    vm.serviceProvidersAll = response.plain().map(provider => ({provider}));
                    vm.count = vm.serviceProvidersAll.length;
                    updateList();
                });
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.serviceProviders = UtilitiesService.getArrayPage(vm.serviceProvidersAll, page, count);
        }

        /**
         * Create new serviceProvider
         */
        function addServiceProvider() {
            $state.go('app.addShareParkServiceProvider');
        }

        /**
         * Edit serviceProvider
         * @param $event
         * @param item
         */
        function editServiceProvider($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkServiceProvider', { data: item.provider });
        }

        function deleteServiceProvider($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            ShareParkDataService.deleteCapServiceProvider(item.provider.id)
                .then(init)
        }

        function createServiceProviderBill($event, item) {
            $event.preventDefault();
            $event.stopPropagation();
            openPopup(item.provider);
        }

        function openPopup(provider = null) {
            return PopupService.show({
                templateUrl: '/assets/views/share-park/customers/service-providers/bills-popup/bills-popup.tpl.html',
                controller: 'ServiceProviderBillsPopupController',
                controllerAs: '$ctrl',
                backdrop: true,
                resolve: {
                    serviceProviderExternalId: provider && provider.id || null
                }
            });
        }

        function billAllProviders() {
            openPopup();
        }

        function generatePermissionName(button) {
            return generateCapServiceProviderPermission(button.permissionAction);
        }
    }
})();
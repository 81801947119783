(function() {
    'use strict';

    angular.module('beacon.app')
        .service('BillingLayoutPreviewPopupService', BillingLayoutPreviewPopupService);

    function BillingLayoutPreviewPopupService(PopupService) {

        return {
            showPreview
        };

        function showPreview(layoutFilename) {
            return PopupService.show({
                templateUrl: '/assets/views/share-park/operations/billing/new/layout-preview/layout-preview-popup.tpl.html',
                controller: 'BillingLayoutPreviewPopupController',
                controllerAs: '$ctrl',
                windowClass: `billing-layout-preview-wrapper`,
                backdrop: true,
                resolve: {
                    data: {
                        layoutFilename
                    },
                }
            });
        }
    }
}());
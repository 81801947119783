(function() {
    'use strict';

    angular.module('beacon.app')
        .component('randomSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/random/random.tpl.html',
            controller: RandomSegmentController,
            bindings: {
                segmentData: '=',
            }
        });

    function RandomSegmentController(
        $scope,
        $timeout,
        segmentAddState,
        StorageFactory,
        SegmentHelper,
        SEGMENT_STATUS,
    ) {
        const vm = this;

        vm.PERCENT_RANDOMIZATION = 1;

        // public methods
        vm.changeSegmentStatus = SegmentHelper.changeSegmentStatus;
        vm.randomizeNow = randomizeNow;

        // Definition
        vm.state = segmentAddState;
        vm.currentDate = new Date();
        vm.randomizationOptions = [
            {
                label: 'USERS',
                value: 0,
            },
            {
                label: '%',
                value: 1,
            }
        ];


        init();

        /**
         * Handles push immediately checkbox state change
         */
        function randomizeNow() {
            if (vm.segmentData.message.randomizeNow) {
                vm.segmentData.message.randomizationTime = new Date();
            }
        }

        /**
         * Checks id segment can be edited
         * @return boolean
         */
        function isSegmentEditable() {
            const wasActivated = vm.segmentData.message.wasActivated;

            if (angular.isDefined(vm.segmentData.message.randomizationTime)) {
                return moment(vm.segmentData.message.randomizationTime).isBefore(vm.currentDate) || wasActivated;
            } else {
                return wasActivated;
            }
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {
            vm.disabled = isSegmentEditable();
            vm.STORAGE = new StorageFactory.Storage('RandomSegment', true);

            if (!vm.segmentData.message.segmentationData) {
                vm.segmentData.message.segmentationData = {};
            }
            vm.activateSegment = vm.segmentData.status === SEGMENT_STATUS.ACTIVE;
            if (!vm.segmentData.message.randomAmount) {
                vm.segmentData.message.randomAmount = 1;
                vm.segmentData.message.randomUnitType = vm.PERCENT_RANDOMIZATION;
            }
        }

        function onFinish(event, option) {
            const segmentData = angular.copy(vm.segmentData);
            if (segmentData === SEGMENT_STATUS.ACTIVE) {
                segmentData.message.wasActivated = true;
            }
            if (segmentData.message.randomizationTime) {
                segmentData.message.randomizationTime = moment(segmentData.message.randomizationTime).utc().format('YYYY-MM-DD HH:mm:ss');
            }
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: segmentData
                });
            }
        }

        function canFinish() {
            return !!vm.segmentData.title
                && !!vm.segmentData.description
                && vm.segmentDataForm.$valid;
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.title;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.description;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentDataForm.$valid;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofencesSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/geofences/geofences.tpl.html',
            controller: GeofencesSegmentType,
            bindings: {
                langArray: '<',
                segmentData: '=',
            }
        });

    function GeofencesSegmentType(
        $scope,
        $stateParams,
        $window,
        segmentAddState,
        GeofenceDataService,
        UtilitiesService,
        SEGMENT_STATUS,
    ) {
        const vm = this;
        const { plainResponse } = UtilitiesService;


        // Definition
        vm.state = segmentAddState;

        vm.errorMsg = null;

        vm.selectedGeofences = [];
        vm.selectedGeofenceGroups = [];

        vm.geofencesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'checkbox-list-item-title',
                    width: '65',
                    title: 'name',
                },
            ],
            header: false,
            itemClickCallback: geofenceItemCLickCallback,
        };

        vm.geofenceGroupsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'checkbox-list-item-title',
                    width: '65',
                    translate: true,
                    getTranslationParams: (item) => ({
                        name: item.name || ''
                    }),
                    title: () => 'GROUP_NAME',
                },
            ],
            header: false,
            itemClickCallback: geofenceGroupItemCLickCallback,
        };

        init();

        function geofenceItemCLickCallback() {
            updateSelectedGeofenceGroups();
            updatePolyline();
            updateSegmentData();
        }

        function geofenceGroupItemCLickCallback(groupItem) {
            updateSelectedGeofences(groupItem.id);
            updatePolyline();
            updateSegmentData();
        }

        function updatePolyline() {
            vm.polyline = vm.selectedGeofences
                    .map(geofence => geofence.polyline).join("|");
        }

        function updateSegmentData() {
            vm.segmentData.definitionRef = _.map(vm.selectedGeofences, 'externalId').join();
        }

        function updateSelectedGeofences (groupId) {
            let groupGeofences = _.filter(vm.geofences,
                        geofence => !!_.find(geofence.groups, ["id", groupId]));
            if (_.find(vm.selectedGeofenceGroups, ["id", groupId])){
                vm.selectedGeofences = _.unionWith(vm.selectedGeofences, groupGeofences, _.isEqual);
            } else {
                vm.selectedGeofences = _.pullAllBy(vm.selectedGeofences, groupGeofences, "id");
            }
            updateSelectedGeofenceGroups();
        }

        function updateSelectedGeofenceGroups () {
            let selectedGeofenceGroupIds =
                    _.chain(vm.geofences)
                     .map(geofence => _.map(geofence.groups, 'id'))
                     .flatten()
                     .countBy()
                     .pickBy((count, key) => count === _.filter(vm.selectedGeofences, geofence => !!_.find(geofence.groups, ['id', parseInt(key)])).length)
                     .keys()
                     .map(_.parseInt)
                     .value();

            vm.selectedGeofenceGroups = _.filter(vm.geofenceGroups,
                    group => selectedGeofenceGroupIds.indexOf(group.id) !== -1);
        }

        function canFinish() {
            return !!vm.segmentData.title
                && !!vm.segmentData.description
                && !!vm.segmentData.definitionRef;
        }

        function bindWatchers() {
            $scope.$watch(angular.bind(vm, function() {
                return vm.segmentData.title;
            }), function() {
                vm.state.canFinish = canFinish();
            });

            $scope.$watch(angular.bind(vm, function() {
                return vm.segmentData.description;
            }), function() {
                vm.state.canFinish = canFinish();
            });

            $scope.$watch(angular.bind(vm, function() {
                return vm.segmentData.definitionRef;
            }), function() {
                vm.state.canFinish = canFinish();
            });
        }

        function init() {
            if ($stateParams.type === 'new') {
                vm.segmentData.status = SEGMENT_STATUS.ACTIVE;
            }
            GeofenceDataService.getGeofences().then(response => {
                vm.geofences = _.sortBy(plainResponse(response), 'name');
                vm.geofenceGroups =
                        _.chain(vm.geofences)
                         .map('groups')
                         .flatten()
                         .uniqBy('id')
                         .sortBy('name')
                         .value();
                if (vm.segmentData.definitionRef.length) {
                    vm.selectedGeofences = _.filter(vm.geofences, geofence => vm.segmentData.definitionRef.indexOf(geofence.externalId) !== -1);
                    updateSelectedGeofenceGroups();
                    updatePolyline();
                }
            });
            bindWatchers();
        }

        function onFinish(event, option) {

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: angular.copy(vm.segmentData)
                });
            }
        }

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
    }
})();

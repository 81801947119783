(function() {
    'use strict';

    angular.module('beacon.app')
        .component('legalTextPreview', {
            templateUrl: '/assets/views/share-park/operations/legal/preview/legal-text-preview.tpl.html',
            controller: LegalTextPreviewController,
            bindings: {
                text: '<'
            }
        });

    function LegalTextPreviewController() {
        const vm = this;
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('himPage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/him/him-page.tpl.html',
            controller: HimPageController,
            bindings: {
                contentData: '<',
                pageData: '<',
                languages: '<',
            }
        });

    /**
     * @param {$rootScope.Scope} $scope
     * @param {ContentDataService} ContentDataService
     * @param {CONTENT_TYPES} CONTENT_TYPES
     *
     * @property {DigitalDisplayContent} contentData
     * @property {HimPageType} pageData
     * @property {Language[]} languages
     *
     * @constructor
     */
    function HimPageController(
        $scope,
        ContentDataService,
        CONTENT_TYPES,
    ) {
        const vm = this;

        /**
         * @type {HimContent[]}
         */
        vm.contents = [];

        /**
         * @type {HimContent}
         */
        vm.selected = null;

        vm.$onInit = onInit;

        /**
         * Initialization method
         */
        function onInit() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.HIM})
                .then(response => {
                    vm.contents = response.plain().map(content => {
                        content.title = angular.fromJson(content.title);
                        content.message = angular.fromJson(content.message);
                        content.data = angular.fromJson(content.data);
                        return content;
                    });
                    vm.selected = vm.contents.find(content => content.id === vm.pageData.selectedContentId);
                });
        }

        /**
         * @callback FinishCallback
         * @param  {{ pageData: HimPageType }} data
         * @return void
         */

        /**
         * @param event
         * @param {{ callback: FinishCallback }} data
         */
        function onFinish(event, data) {
            if (!vm.selected) {
                return;
            }

            vm.pageData.selectedContentId = vm.selected.id;

            if (typeof data.callback === 'function') {
                data.callback({
                    pageData: vm.pageData
                });
            }
        }

        $scope.$on('digital-display-savepage', onFinish);
    }
})();

(() => {
    'use strict';
    angular.module('beacon.app')
        .directive('autofocusDirective', ['$timeout',
                $timeout => ({
                    restrict: 'A',
                    link: ($scope, $element) => {
                        $timeout(() => {
                            $element[0].focus();
                        });
                    }
                })
            ]);
})();

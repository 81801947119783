(function () {
    'use strict';

    angular
        .module('beacon.app')
        .service('ProfileRulesService', ProfileRulesService);

    function ProfileRulesService($http, $mdDialog) {
        return {
            getProfileRulesList,
            saveProfileRulesList,
            runSimulateAutoConfig,
            getSimulateAutoConfig,
        };


        function getProfileRulesList() {
            return $http.get('/api/sharePark/customers/profile-rules');
        }

        function saveProfileRulesList(data) {
            return $http.post('/api/sharePark/customers/profile-rules', data);
        }

        function runSimulateAutoConfig(version) {
            return $http.get(`/api/sharePark/customers/profile-rules/run-simulate-auto-config?version=${version}`);
        }

        /**
         * @return {Blob}
         */
         function getSimulateAutoConfig(version) {
            return $http.get(`/api/sharePark/customers/profile-rules/simulate-auto-config?version=${version}`);
        }

    }
})();
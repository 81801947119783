(function() {
    'use strict';

    angular.module('beacon.app')
        .component('externalAppContentType', {
            templateUrl: '/assets/views/content/elements/types/external-app/external-app.tpl.html',
            controller: ExternalAppContentType,
            bindings: {
                langArray: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
                popup: '=?',
                previewmode: '<?'
            }
        });

    function ExternalAppContentType($scope,
                                    StatesFactory,
                                    PopupService,
                                    UtilitiesService,
                                    ContentImageProcessingService,
                                    ContentDataService,
                                    ContentDataProcessingService,
                                    ) {
        const vm = this;
        const { plainResponse } = UtilitiesService;
        const { isPdfURL } = ContentDataService;
        const { processDefaultContent } = ContentDataProcessingService;


        // public properties
        vm.state = StatesFactory.ContentStates;
        vm.imageUploadMessages = [{
            error: 'required',
            text: 'Uploading a picture is required'
        }];
        vm.archive = {};
        vm.useHtmlFile = {};

        // public methods
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.urlOnChange = urlOnChange;
        vm.onArchiveLoad = onArchiveLoad;

        vm.$onInit = init;

        // private methods

        /**
         * Image file input handler
         * @param {object} imageFile
         * @param {boolean} uploadToS3
         */
        function onImage(imageFile, uploadToS3) {
            ContentImageProcessingService.onImage(vm.contentData, imageFile, uploadToS3);
            angular.element('#imagePreview').attr('src', vm.contentData.image_src);
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            ContentImageProcessingService.onImageDelete(vm.contentData);
        }

        /**
         * On archive load handler
         * @param archive
         */
        function onArchiveLoad(archive) {
            vm.archive[vm.contentData.language_id] = archive;
            $scope.$digest();
        }

        /**
         * Initialization method
         */
        function init() {
            if (vm.previewmode) {
                $scope.$watch(() => vm.contentData.id, updateComponentModel);
            }

            updateComponentModel();
        }

        /**
         * @param {Event} event
         * @param {Function} option
         */
        function onFinish(event, option) {
            if (typeof option.callback === 'function') {
                let formData = new FormData();

                for (let prop in vm.archive) {
                    vm.contentData.title[prop] &&
                        vm.useHtmlFile[prop] &&
                        formData.append(`archive[${prop}]`, vm.archive[prop]);
                }

                let contentData = processDefaultContent(formData, vm.contentData, vm.contentForm);

                option.callback({
                    type: vm.state.type,
                    formData,
                    contentData
                });
            }
        }

        /**
         * URL On Change
         */
        function urlOnChange() {
            const url = vm.model.externalLink;
            url && isPdfURL(url).then(res => {
                vm.model.isPdf = res;
            });
        }

        /**
         * On language change handler
         */
        function onLanguageChange() {
            updateComponentModel();
        }

        /**
         * Updates component model according to selected language
         */
        function updateComponentModel() {
            vm.model = vm.contentData.message[vm.contentData.language_id];
        }

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newTariffModel', {
            templateUrl: '/assets/views/share-park/pricing/tariff-models/new/new-tariff-model.tpl.html',
            controller: NewTariffModelController
        });

    function NewTariffModelController(
        $scope,
        $state,
        $stateParams,
        $translate,
        ModelsFactory,
        PricingDataService,
        StatesFactory,
        ShareParkHelperService,
    ) {
        const vm = this;

        const currentLocale = $translate.use();
        const { decodeTariff } = ShareParkHelperService;

        vm.state = StatesFactory.TariffModelStates.refresh();
        vm.state.type = $stateParams.type;
        vm.tariffAssignmentsListItems = [];
        vm.selectedTariffAssignments = [];
        vm.tariffAssignmentsListData = {
            columns: [
                {
                    name: 'NAME',
                    width: '50',
                    title: item => item.capRate.name[currentLocale] || Object.values(item.capRate.name)[0],
                },
                {
                    name: 'PRICE',
                    width: '45',
                    title: item => item.capRate.priceLabel[currentLocale] || Object.values(item.capRate.priceLabel)[0],
                },
                {
                    width: '5',
                    title: item => `<md-icon class="material-icons" role="img">${item.isOpen ? 'expand_less' : 'expand_more'}</md-icon>`,
                }
            ],
            header: false,
            needCheckbox: true,
            updateCallback: () => {},
        };

        vm.$onInit = init;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.tariffModel = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.TariffModel();

            if (vm.tariffModel.capRateAssignment) {
                vm.tariffModel.capRateAssignment.forEach(assignment => {
                    assignment.capRate = decodeTariff(assignment.capRate);
                });
            }

            vm.selectedTariffAssignments = vm.tariffModel.capRateAssignment || [];

            PricingDataService.getAllTariffs()
                .then(tariffsResponse => {
                const tariffs = tariffsResponse.plain()
                    .map(decodeTariff);

                vm.tariffAssignmentsListItems = tariffs.map(tariff => {
                    const relatedAssignment = vm.tariffModel.capRateAssignment
                        && vm.tariffModel.capRateAssignment.find(assignment =>
                            assignment.capRate.id === tariff.id
                        );

                    return relatedAssignment || {
                        capRateGroup: 0,
                        capRate: tariff,
                        startDate: null,
                        endDate: null
                    };
                });
            });

        }

        /**
         * On finish handler
         */
        function onFinish() {
            const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
            const DEFAULT_CAP_RATE_GROUP = 0;
            const formData = new FormData();

            const tariffModel = angular.copy(vm.tariffModel);

            const tariffAssignments = vm.selectedTariffAssignments.map(assignment => ({
                capRateGroup: DEFAULT_CAP_RATE_GROUP,
                capRateRef: assignment.capRate.id,
                id: assignment.id || undefined,
                startDate: assignment.startDate
                    ? moment(assignment.startDate).utc().format(API_DATETIME_FORMAT) : undefined,
                endDate: assignment.endDate
                    ? moment(assignment.endDate).utc().format(API_DATETIME_FORMAT) : undefined,
            }));

            const deletedAssignments = vm.tariffAssignmentsListItems.filter(assignment =>
                assignment.id && !tariffAssignments.some(({id}) => id === assignment.id)
            ).map(({id}) => id);

            delete tariffModel.capRateAssignment;

            formData.append('tariffModel', angular.toJson(tariffModel));
            formData.append('tariffAssignments', angular.toJson(tariffAssignments));
            formData.append('deletedAssignments', angular.toJson(deletedAssignments));

            PricingDataService.setTariffModel(formData)
                .then(onSuccess)

        }

        function onSuccess() {
            $state.go('app.shareParkTariffModels', {
                paginationData: $stateParams.paginationData
            });
        }

        $scope.$watch(function() {
            return vm.newTariffModelForm && vm.newTariffModelForm.$valid;
        }, formValid => vm.state.canFinish = formValid);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('scMeetings')
        .component('scMeetingsApp', {
            templateUrl: '/assets/views/components/sc-meetings-app/sc-meetings-app.tpl.html',
            controller: ScMeetingsAppController,
        });

    function ScMeetingsAppController(
        $scope,
        $location,
    ) {
        const $ctrl = this;

        $scope.header = true;

        $ctrl.$onInit = () => {
            _validateVars();
        }

        function _validateVars() {
            const {
                tenant,
                campuses,
                header,
            } = $location.search();

            if (header) {
                $scope.header = header !== 'false';
            }

            switch (true) {
                case !tenant:
                    $scope.error = 'Missing GET param tenant';
                    break;
                case !campuses:
                    $scope.error = 'Missing GET param campuses';
                    break;
            }
        }
    }
})();

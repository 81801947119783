(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PdfPopupService', pdfPopupService);

    function pdfPopupService(
        $sce,
        PopupService,
    ) {
        return {
            showBlob
        };

        /**
         * Shows popup with pdf
         * @param { Blob } pdfBlob pdf file to be displayed
         */
        function showBlob(pdfBlob) {
            const fileURL = URL.createObjectURL(pdfBlob);

            PopupService.show({
                templateUrl: '/assets/views/common/popups/pdf-popup/pdf-popup.tpl.html',
                controller: 'PdfPopupController',
                controllerAs: '$ctrl',
                windowClass: 'pdf-popup',
                resolve: {
                    data: () => ({
                        url: $sce.trustAsResourceUrl(fileURL),
                    })
                }
            });
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('preferencesPreview', {
            templateUrl: '/assets/views/campaign/segment/new/types/preferences/preferences-preview/preferences-preview.tpl.html',
            controller: PreferencesPreview,
            bindings: {
                segmentData: '='
            }
        });

    function PreferencesPreview(SegmentHelper) {
        const vm = this;

        vm.getPreferenceName = SegmentHelper.getPreferenceName;
        vm.getPropertyDescription = SegmentHelper.getPropertyDescription;
    }
})();

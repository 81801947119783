(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('DynamicContentPopupController', DynamicContentPopupController);

    function DynamicContentPopupController(
        $uibModalInstance,
        data,
        $window,
        $timeout,
        PopupService,
    ) {
        const vm = this;

        vm.cancel = cancelHandler;
        vm.ok = okHandler;
        vm.setAspectRatio = setAspectRatio;
        vm.onVerticalChange = onVerticalChange;
        vm.canShowImage = canShowImage;
        vm.onShowImageClick = onShowImageClick;

        vm.$onInit = init;

        vm.aspectRatios = [
            {w: 4,  h: 3},
            {w: 16, h: 9},
        ];
        vm.vertical = false;
        vm.aspectRatio = vm.aspectRatios[0];
        vm.showDynamicContent = true;

        /**
         * Init
         */
        function init() {
            vm.content = data.content;
            vm.languages = data.languages;
            vm.aspectRatioControl = data.aspectRatioControl;
            onModalLoaded().then(() => setAspectRatio(vm.aspectRatio));
        }

        function canShowImage() {
            return vm.content.data && vm.content.data.imageOnly && vm.content.image_src;
        }

        function onShowImageClick() {
            if (vm.content.image_src) {
                PopupService.showImagePopup(vm.content.image_src);
            }
        }

        function onVerticalChange() {
            setAspectRatio(vm.aspectRatio);
        }

        /**
         *
         * @param aspectRatio
         * @param aspectRatio.w
         * @param aspectRatio.h
         */
        function setAspectRatio(aspectRatio) {
            vm.aspectRatio = aspectRatio;

            const divDialog = $window.document.querySelector('.dynamic-content-popup .modal-dialog');
            const divContent = $window.document.querySelector('.dynamic-content-popup .modal-content');

            const ratio = aspectRatio.w / aspectRatio.h;

            setPopupSize(divDialog, vm.vertical ? 1 / ratio : ratio);
            setPopupSize(divContent, vm.vertical ? 1 / ratio : ratio);

            reloadDynamicContent();

            function setPopupSize(element, ratio) {
                const maxWidth = $window.innerWidth * 0.8;
                const maxHeight = $window.innerHeight * 0.8;
                let height, width;

                width = maxHeight * ratio;
                height = maxHeight;

                element.style.width = width + 'px';
                element.style.height = height + 'px';
            }
        }

        /**
         *
         * @return {Promise<{dialog: HTMLDivElement, content: HTMLDivElement}>}
         */
        function onModalLoaded() {
            return new Promise(resolve => {
                setInterval(() => {
                    const modalContent = $window.document.querySelector('.dynamic-content-popup .modal-content');

                    if (modalContent) {
                        resolve();
                    }
                }, 50);
            })
        }

        function reloadDynamicContent() {
            vm.showDynamicContent = false;
            $timeout(
                () => vm.showDynamicContent = true,
            );
        }

        /**
         * "OK" click handler
         */
        function okHandler() {
            $uibModalInstance.close();
        }

        /**
         * "Cancel" click handler
         */
        function cancelHandler() {
            $uibModalInstance.close();
        }
    }
}());

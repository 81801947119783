(function() {
    'use strict';

    angular.module('beacon.app')
        .component('bannerSecondTab', {
            templateUrl: '/assets/views/content/elements/types/banner/tabs/banner-second-tab/banner-second-tab.tpl.html',
            controller: BannerSecondTab,
            bindings: {
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
                positionConfig: '=',
            }
        });



    function BannerSecondTab(
            $scope,
            StatesFactory,
            UtilitiesService,
            ContentImageProcessingService,
            PermissionsService,
            BANNER_FORMAT_OPTIONS,
            BANNER_TYPE_OPTIONS,
            BANNER_REFERENCE_TYPE,
            LanguageService,
            ) {
        const vm = this;

        vm.ready = false;

        vm.$onInit = init;
        vm.copyBtnCallback = copyBtnCallback;

        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.bannerTypeOnChange = bannerTypeOnChange;
        vm.bannerFormatOnChange = bannerFormatOnChange;
        vm.imageBackgroundOnChange = imageBackgroundOnChange;
        vm.getInfotainmentImageUrl = getInfotainmentImageUrl;
        vm.refreshBannerPreview = refreshBannerPreview;
        vm.getMaxTextLength = getMaxTextLength;
        vm.isConfigPositionParameterVisible = isConfigPositionParameterVisible;
        vm.onLanguageChange = onLanguageChange;
        vm.setColorContrastRatioViewerInfo = setColorContrastRatioViewerInfo;
        vm.stripHtml = UtilitiesService.stripHtml;

        vm.BANNER_TYPE_OPTIONS_ARR = Object.values(BANNER_TYPE_OPTIONS).filter(option => {
            if (option.permission) {
                return PermissionsService.isPermissionAvailable(option.permission);
            }
            return true;
        });
        vm.BANNER_REFERENCE_TYPE = BANNER_REFERENCE_TYPE;

        vm.backgroundImageUrl = getBackgroundImageUrl();

        vm.imageLoaderConfig = {
            minWidth: 0,
            minHeight: 0,
        };

        vm.state = StatesFactory.BannerStates;
        vm.state.canBack = true;

        vm.BANNER_TYPES_FORMATS = {
            1: [1,2,3],
            2: [1,2,3],
            3: [4],
            4: [5],
        };
        vm.colorPickerApi = {
            text: {
                onChange: (_api, color) => {
                    setColorContrastRatioViewerInfo(color, vm.contentData.message.backgroundColor);
                },
            },
            background: {
                onChange: (_api, color) => {
                    setColorContrastRatioViewerInfo(vm.contentData.message.textColor, color);
                },
            },
        };
        vm.colorContrastRatioViewerInfo = {
            foreground: '',
            background: '',
        };

        function init() {
            LanguageService.getLanguages()
                .then(response => {
                    vm.langs = response.plain();
                    vm.lang = vm.langs.find(item => item.id === vm.contentData.language_id);
                    vm.defaultLang = vm.langs[0];

                    vm.selectedInfotainment = vm.contentData.selectedInfotainment;
                    setDefaults();
                    bannerTypeOnChange(vm.state.type === 'new');
                    bannerFormatOnChange();
                    refreshBannerPreview();
                    setColorContrastRatioViewerInfo(vm.contentData.message.textColor, vm.contentData.message.backgroundColor);

                    vm.ready = true;
                });
        }

        function onLanguageChange() {
            vm.lang = vm.langs.find(item => item.id === vm.contentData.language_id);
            vm.contentData.title[vm.lang.id] = vm.contentData.title[vm.defaultLang.id];
            setDefaults();
            refreshBannerPreview();
        }

        function bannerTypeOnChange(updateDefaultSettings = true) {
            const bannerType = vm.contentData.message.bannerType;
            vm.filteredBannerFormatOptions = BANNER_FORMAT_OPTIONS.filter(option => vm.BANNER_TYPES_FORMATS[bannerType].indexOf(option.value) !== -1);
            const itemExists = vm.filteredBannerFormatOptions.some(el => el.value === vm.contentData.message.formatType);
            if (!itemExists) {
                vm.contentData.message.formatType = vm.filteredBannerFormatOptions[0].value;
            }
            vm.bannerType = vm.BANNER_TYPE_OPTIONS_ARR.reduce((type, option) => (option.value === bannerType ? option.type : type), "");
            vm.backgroundImageUrl = getBackgroundImageUrl();
            bannerFormatOnChange();

            if (updateDefaultSettings) {
                setDefaultOptions();
            }
        }

        function isConfigPositionParameterVisible(param) {
            if (param === 'pop') {
                return vm.contentData.message.formatType === 4;         // 4 is pop over format type
            } else {
                return vm.contentData.message.formatType !== 4;
            }
        }

        /**
         * Set default options for banner behavior
         */
        function setDefaultOptions() {
            const TYPE_POPOVER = 3;
            const bannerType = vm.contentData.message.bannerType;

            if (bannerType === TYPE_POPOVER) {
                const ONLY_N_TIMES = 1;
                const NEWER = 1;

                const message = vm.contentData.message;
                message.presentationOptions.case = ONLY_N_TIMES;
                message.presentationOptions.ntimes = 1;
                message.comebackOptions.case = NEWER;
            }
        }

        function bannerFormatOnChange() {
            const bannerFormat = vm.contentData.message.formatType;
            vm.bannerFormat = BANNER_FORMAT_OPTIONS.reduce((format, option) => (option.value === bannerFormat ? option.format : format), "");
            vm.backgroundImageUrl = getBackgroundImageUrl();
        }

        function imageBackgroundOnChange() {
            vm.backgroundImageUrl = getBackgroundImageUrl();
        }

        function copyBtnCallback() {
            const info = vm.contentData.selectedInfotainment;

            vm.contentData.title = angular.copy(info.title);
            vm.contentData.message.title = angular.copy(info.title);

            const message = angular.copy(info.message.message || info.message);
            Object.keys(message).forEach(langId => {
                message[langId] = UtilitiesService.stripHtml(message[langId]);
            });
            vm.contentData.message.message = message;
            refreshBannerPreview();
        }

        function getBackgroundImageUrl() {
            if (vm.contentData.message.imageOnly
                || vm.bannerType === 'infotainment' && vm.bannerFormat === 'simple') {
                    
                const myImage = new Image();
                myImage.src = vm.contentData.image_src || getInfotainmentImageUrl();
                setTimeout(()=>{
                    vm.isSmallBanner = (myImage.width / myImage.height) > 2 ? true : false;
                }, 100)    

                return `url(${vm.contentData.image_src || getInfotainmentImageUrl()})`;
            }
            return 'none';
        }

        /**
         * Get infotainment title
         *
         * @return {string}
         */
        function getInfotainmentTitle() {
            return vm.contentData.data.standaloneBanner
                ? vm.contentData.message.title[vm.lang.id]
                : vm.contentData.selectedInfotainment.title[vm.lang.id];
        }

        /**
         * Get infotainment message
         *
         * @return {string}
         */
        function getInfotainmentMessage() {
            if (vm.contentData.data.standaloneBanner) {
                return vm.contentData.message.message[vm.lang.id];
            }

            const msg = vm.contentData.selectedInfotainment.message;
            return (msg.message ? msg.message[vm.lang.id] : msg[vm.lang.id]) || '';
        }

        function getInfotainmentImageUrl() {
            return (vm.contentData.selectedInfotainment && vm.contentData.selectedInfotainment.image_src) || '';
        }

        /**
         * Returns max text length according to selected banner type
         * @return {number}
         */
        function getMaxTextLength() {
            const POP_OVER_MAX_TEXT_LENGTH = 500;
            const USUAL_MAX_TEXT_LENGTH = 250;

            return BANNER_TYPE_OPTIONS.POP_OVER.value === vm.contentData.message.bannerType
                ? POP_OVER_MAX_TEXT_LENGTH
                : USUAL_MAX_TEXT_LENGTH;
        }

        /**
         * Image load callback
         * @param imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @returns {*}
         */
        function onImage(imageFile, uploadToS3) {
            ContentImageProcessingService.onImage(vm.contentData, imageFile, uploadToS3);
            refreshBannerPreview();
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            ContentImageProcessingService.onImageDelete(vm.contentData);
            refreshBannerPreview();
        }

        /**
         * Sets default values for message and title
         */
        function setDefaults() {
            if (!vm.contentData.message.title[vm.lang.id]) {
                vm.contentData.message.title[vm.lang.id] = '';
            }

            if (!vm.contentData.message.message[vm.lang.id]) {
                vm.contentData.message.message[vm.lang.id] = '';
            }
        }

        /**
         * Refreshing banner preview data
         */
        function refreshBannerPreview() {
            vm.bannerPreview = {
                title: vm.contentData.message.title[vm.lang.id] || getInfotainmentTitle(),
                text:  vm.contentData.message.message[vm.lang.id] || getInfotainmentMessage(),
                image: vm.contentData.image_src || vm.getInfotainmentImageUrl()
            };
            vm.bannerPreview.imageCSS = vm.bannerPreview.image && `url(${vm.bannerPreview.image})`;
            vm.backgroundImageUrl = getBackgroundImageUrl();            
        }

        /**
         * @param {string} foregroundColor 
         * @param {string} backgroundColor 
         */
        function setColorContrastRatioViewerInfo(foregroundColor, backgroundColor) {
            vm.colorContrastRatioViewerInfo = { foregroundColor, backgroundColor };
        }

        /**
         * Form validation
         */
        $scope.$watch(
            () => vm.contentForm && vm.contentForm.$valid,
            isFormValid => {
                vm.state.canNext = isFormValid;
            },
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('listItemDetails', {
            controller: listItemDetailsController,
            templateUrl: '/assets/views/common/components/list-item-details/list-item-details.tpl.html',
            bindings: {
                config: '<',
                object: '<',
            },
        });

    /**
     * @typedef {{
     *     isVisible: function,
     *     label: string,
     *     value: function,
     *     stripHtml?: boolean,
     *     languageEncoded?: boolean,
     *     image?: boolean,
     *     maxWidth?: string,
     * }} ListItemDetailsField
     */

    /**
     * @typedef {{
     *     languagePicker: boolean,
     *     columns: {
     *         title: string,
     *         fields: ListItemDetailsField[],
     *     }[]
     * }} ListItemDetailsConfig
     */

    /**
     * @property {object} object
     * @property {ListItemDetailsConfig} config
     *
     * @param $moment
     * @param $timeout
     * @param StorageFactory
     * @param {LanguageProvider} LanguageProvider
     * @param UtilitiesService
     */
    function listItemDetailsController(
        $moment,
        $timeout,
        StorageFactory,
        LanguageProvider,
        UtilitiesService,
    ) {
        const $ctrl = this;

        $ctrl.ready = false;

        $ctrl.$onInit = () => {
            $ctrl.languagePickerEnabled = $ctrl.config.languagePicker;

            LanguageProvider.getTenantLanguages()
                .then(languages => {
                    $ctrl.languages = languages;
                    $ctrl.currentLng = $ctrl.languages[0];
                    $ctrl.ready = true;
                });
        }

        /**
         * @param {ListItemDetailsField} field
         * @return {string}
         */
        $ctrl.valueFormatted = (field) => {
            let val = $ctrl.valueRaw(field);

            return _formatValue(field, val);
        }

        /**
         * @param {ListItemDetailsField} field
         * @return {string}
         */
        $ctrl.valueRaw = field => {
            const val = angular.isFunction(field.value)
                ? field.value($ctrl.object)
                : field.value;

            return field.languageEncoded && angular.isObject(val)
                ? val[$ctrl.currentLng.code] || val[$ctrl.currentLng.id]
                : val;
        }

        /**
         * @param {Language} lng
         */
        $ctrl.changeLng = lng => {
            $ctrl.currentLng = lng;
        }

        /**
         * @param {ListItemDetailsField} field
         * @return {boolean}
         */
        $ctrl.isFieldVisible = field => {
            return angular.isUndefined(field.isVisible)
                || angular.isFunction(field.isVisible) && field.isVisible($ctrl.object);
        }

        function _formatValue(field, val) {
            if (field.stripHtml && angular.isString(val)) {
                val = UtilitiesService.stripHtml(val);
            }

            if (_isEmpty(val)) {
                return '-';
            }

            return val;
        }

        function _isEmpty(val) {
            return [null, undefined, ''].includes(val);
        }
    }
})();

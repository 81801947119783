(function() {
    'use strict';

    angular.module('beacon.app')
        .component('defaultContentType', {
            templateUrl: '/assets/views/content/elements/types/default/default.tpl.html',
            controller: DefaultContentType,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<'
            }
        });

    function DefaultContentType($timeout,
                                $scope,
                                StatesFactory,
                                StorageFactory,
                                PopupService,
                                UtilitiesService,
                                FileService,
                                ContentDataProcessingService,
                                ContentDataService,
                                ContentImageProcessingService,
                                ContentHelper,
                                UserDataService,
                                PermissionsService,
                                QUIZ_TYPE) {
        const vm = this;
        const { checkContentType, compareAsNumber, oneOfContentTypes, base64ImageToBlob } = UtilitiesService;
        const { processDefaultContent, processContentData} = ContentDataProcessingService;
        const { getRemoteImage } = FileService;
        const { isFixedInfoType } = ContentHelper;

        /**
         * Exports
         */
        vm.onMap = onMap;
        vm.onImage = onImage;
        vm.onAudioDataChange = onAudioDataChange;
        vm.showImagePopup = showImagePopup;
        vm.copyFromWebSourceCallback = copyFromWebSourceCallback;
        vm.onImageDelete = onImageDelete;
        vm.checkContentType = checkContentType;
        vm.compareAsNumber = compareAsNumber;
        vm.oneOfContentTypes = oneOfContentTypes;
        vm.isFixedInfoType = isFixedInfoType;
        vm.onLanguageSensitiveLinkChange = onLanguageSensitiveLinkChange;
        vm.QUIZ_TYPE = QUIZ_TYPE;
        vm.generateShowMapPermissionName = PermissionsService.generateShowMapPermissionName;

        vm.imageCropPopup = null;

        init();

        vm.getImageCropPopup = getImageCropPopup;

        /**
         * Get image crop popup
         * @param {function} imageCropPopup Image crop popup
         * @returns {undefined}
         */
        function getImageCropPopup(imageCropPopup) {
            vm.imageCropPopup = imageCropPopup;
        }

        /**
         * Init
         * @returns {undefined}
         */
        function init() {
            vm.state = StatesFactory.ContentStates;
            // Image
            vm.imageUploadMessages = [{
                error: 'required',
                text: 'Uploading a picture is required'
            }];
            // Audio
            vm.prevAudioUrl = null;
            vm.currentAudio = null;
            vm.audioUploadMessages = [{
                error: 'all-languages-filled',
                text: 'Audio for all language options should be selected'
            }];

            UserDataService.loadUserData().then(function(data){
                vm.userData = data;
                processUserData();
            });

            vm.contentDescription = vm.oneOfContentTypes(['QUIZ', 'FEEDBACK'], vm.contentData.content_type_id)
                ? vm.contentData.message.message
                : vm.contentData.message;
        }

        function onFinish(event, option) {
            if (checkContentType('TICKER', vm.contentData.content_type_id) &&
                Number(vm.contentData.message[vm.contentData.language_id].dropzones.page.marquee.type) === 1) {
                // vm.pageForm.title.$setValidity('uniqueness', true);
                vm.contentForm.marqueeform.rssurl.$setValidity('url', true);
                vm.contentForm.marqueeform.htmlurl.$setValidity('url', true);
            }

            if (checkContentType('INFOTAINMENT', vm.contentData.content_type_id)) {
                const langs = Object.keys(vm.contentData.message);
                langs.forEach(langId => {
                    if (!vm.contentData.title[langId]) {
                        vm.contentData.title[langId] = '';
                    }
                })
            }

            processContentData(vm.contentData);
            let formData = new FormData();
            let contentFormData = processDefaultContent(formData, vm.contentData, vm.contentForm);

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    formData,
                    contentData: contentFormData
                });
            }
        }

        /**
         * Image file input handler
         * @param {object} imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @returns {*}
         */
        function onImage(imageFile, uploadToS3) {
            ContentImageProcessingService.onImage(vm.contentData, imageFile, uploadToS3);
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            ContentImageProcessingService.onImageDelete(vm.contentData);
        }

        /**
         * Copy from web source callback
         * @param {object} data Web source data
         * @returns {undefined}
         */
        function copyFromWebSourceCallback(data) {
            const languageId = vm.contentData.language_id;

            vm.contentData.title[languageId] = data.title;

            if (oneOfContentTypes(['QUIZ', 'FEEDBACK'], vm.contentData.content_type_id)) {
                vm.contentData.message.message[languageId] = data.description;
            } else {
                vm.contentData.message[languageId] = data.description;
            }

            if (vm.contentData.language_sensitive_link) {
                vm.contentData.web_link[languageId] = data.linkRef;
            } else {
                vm.contentData.web_link = data.linkRef;
            }

            vm.contentData.image_src = data.imageRef;

            if(data.uploadImage && data.imageRef){
                loadFromUrl(data.imageRef);
            }
        }

        /**
         * Load image from url
         * @param {string} url URL
         * @returns {undefined}
         */
        function loadFromUrl(url) {
            getRemoteImage(url).then(response => {
                if (response.image) {
                    const blob = base64ImageToBlob(response.image);
                    blob.name = url;
                    vm.imageCropPopup(blob, true);
                }
            })
        }

        /**
         * Shows image preview popup
         */
        function showImagePopup() {
            PopupService.show({
                templateUrl: '/assets/views/common/popups/image-preview/image-preview.tpl.html',
                controller: 'ImagePreviewPopupController',
                controllerAs: 'imagePreviewPopupController',
                windowClass: 'imagePreviewPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return {
                            okButtonText: 'Ok',
                            imageUrl: vm.contentData.image_src
                        };
                    }
                }
            });
        }

        function onAudioDataChange(data) {
            Object.assign(vm.contentData, data);
        }

        function processUserData() {
            vm.mapDefaults = {};

            if (vm.userData.subdomain.tenant.homeLocation) {
                let mapData = vm.userData.subdomain.tenant.homeLocation.split(',');
                if (mapData.length === 3) {
                    vm.mapDefaults.latitude = parseFloat(mapData[0]);
                    vm.mapDefaults.longitude = parseFloat(mapData[1]);
                    vm.mapDefaults.zoom = Number(mapData[2]);
                }
            }

            vm.isShopTenant = Number(vm.userData.subdomain.tenant.storeRef) > 0;
        }

        function onLanguageSensitiveLinkChange() {
            if (vm.contentData.language_sensitive_link) {
                vm.contentData.web_link = {
                    [vm.contentData.language_id]: vm.contentData.web_link || ''
                };
            } else {
                vm.contentData.web_link = vm.contentData.web_link[vm.contentData.language_id] || '';
            }
        }

        /**
         * Map icon click hendler
         */
        function onMap() {
            var mapPopup;
            mapPopup = PopupService.show({
                templateUrl: '/assets/views/common/popups/map/map.tpl.html',
                controller: 'MapPopupController',
                controllerAs: 'mapPopupController',
                windowClass: 'mapPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return {
                            okButtonText: 'OK',
                            cancelButtonText: 'CANCEL',
                            mapInfo: vm.contentData.map_info,
                            mapDefaults: vm.mapDefaults,
                            canEdit: true
                        };
                    }
                }
            });

            mapPopup.then(function(mapInfo) {
                vm.contentData.map_info = mapInfo;
            });
        }

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('MainController', MainController);

    function MainController(
        $auth,
        $document,
        $interval,
        $scope,
        $state,
        $timeout,
        $mdSidenav,
        $uibModalStack,
        $window,
        StorageFactory,
        LoginService,
        PermissionsService,
        PopupService,
        UserDataService,
        UserUtilitiesService,
        APP_MODES,
        SESSION_SETTINGS,
        AppVersionService,
        $translate,
        LanguageProvider,
    ) {
        let vm = this;

        let logoutTimer;
        let popupTimer;
        let checkTokenInterval;
        const MINUTE = 60000;

        const {
            trmModeEnabled,
            shareParkModeEnabled,
            isSmartCampusModeEnabled,
            hasRoleUser,
            hasRoleSysAdmin,
            loginTimeout,
        } = UserUtilitiesService;

        const { isPermissionAvailable } = PermissionsService;

        const LOGOUT_TIMEOUT = loginTimeout() || SESSION_SETTINGS.FORCE_LOGOUT_TIMEOUT;

        const isContentModeVisible = trmModeEnabled();
        const isUserManagementModeVisible = !hasRoleUser();
        const isShareParkModeVisible = shareParkModeEnabled();
        const isSmartCampusModeVisible = isSmartCampusModeEnabled();

        // public properties
        vm.sidebarIsOpen = false;
        vm.logoUrl = null;
        vm.currentTenantName = '';
        vm.currentSubdomainName = '';

        vm.appModes = {
            SHARE_PARK: {
                id: 3,
                label: 'SHARE_PARK',
                isVisible: isShareParkModeVisible,
                targetState: getDefaultShareParkState(),
            },
            CONTENT: {
                id: 1,
                label: 'TRM',
                isVisible: isContentModeVisible,
                targetState: 'app.content'
            },
            SMART_CAMPUS: {
                id: 4,
                label: 'SMART_CAMPUS',
                isVisible: isSmartCampusModeVisible,
                targetUrl: '/smart-campus',
            },
            MANAGEMENT: {
                id: 2,
                label: 'MANAGEMENT',
                isVisible: isUserManagementModeVisible,
                targetState: 'app.tenants'
            },
        };
        vm.appModesMap = Object.values(vm.appModes);
        vm.currentMode = vm.appModes.CONTENT.id;

        vm.appVersion = null;


        // public methods
        vm.toggleMenu = toggleMenu;
        vm.logout = logout;
        vm.switchMode = switchMode;

        init();

        /**
         * Toggles menu open state
         */
        function toggleMenu() {
            $mdSidenav('left').toggle();
            vm.sidebarIsOpen = !vm.sidebarIsOpen;
        }

        /**
         * Switch main menu mode
         *
         * @param newAppMode
         */
        function switchMode(newAppMode) {
            if (newAppMode.targetUrl) {
                $window.location.href = newAppMode.targetUrl;
            } else {
                vm.currentMode = newAppMode.id;
                $state.go(newAppMode.targetState);
            }
        }

        function getDefaultShareParkState() {
            if (isPermissionAvailable('can_view_cap_customer')) {
                return 'app.shareParkCustomers';
            }

            if (isPermissionAvailable('can_view_concierge') && !isPermissionAvailable('can_view_car_park')) {
                return 'app.capConcierge';
            }

            if (isPermissionAvailable('can_view_share_park_dashboard')) {
                return 'app.shareParkDashboard';
            }

            if (!isPermissionAvailable('can_view_car_park')) {
                return 'app.profile';
            }

            return 'app.carParks';
        }

        /**
         * Logs user out
         */
        function logout() {
            if (localStorage.getItem('elementFilter') && localStorage.getItem('elementFilter') !== null) {
                localStorage.setItem('elementFilter', null);
            }
            LoginService.logout();
        }

        // private methods

        /**
         * Checks if color bright enough
         *
         * @param string hex
         */
        function isColorBright(hex) {
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                red = 0,
                green = 0,
                blue = 0,
                brightness;

            hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                return '#' + r + r + g + g + b + b;
            });

            if (hex.length === 7) {
                red = parseInt(hex.slice(1, 3), 16);
                green = parseInt(hex.slice(3, 5), 16);
                blue = parseInt(hex.slice(5, 7), 16);
            }

            brightness = Math.round((red * 299 + green * 587 + blue * 114) / 1000);

            return (brightness > 155);
        }

        /**
         * Initialization method
         */
        function init() {
            let STORAGE = StorageFactory.Storage('Main');

            $timeout(function() {
                vm.toggleMenu();
            }, 0);

            AppVersionService.getCurrentVersion()
                .then(version => vm.appVersion = version)
                .catch(error => vm.appVersion = 'N/A');

            LanguageProvider.init();

            UserDataService.loadUserData().then((response) => {
                let tenant = response && response.subdomain && response.subdomain.tenant;

                if (!tenant) {
                    return;
                }

                if (tenant.corporateColours) {
                    let corporateColours = tenant.corporateColours.split(',');
                    const COLOR = {
                        PRIMARY: 0,
                        DARK: 1,
                        ACCENT: 2,
                        PRIMARY_TEXT: 3,
                    }
                    corporateColours.push(isColorBright(corporateColours[COLOR.PRIMARY]) ? '#000' : '#fff');

                    if (corporateColours.length === 4) {
                        less.modifyVars({
                            '@beacon-primary': corporateColours[COLOR.PRIMARY],
                            '@beacon-dark': corporateColours[COLOR.DARK],
                            '@beacon-accent': corporateColours[COLOR.ACCENT],
                            '@beacon-text-colour': corporateColours[COLOR.PRIMARY_TEXT],
                            '@beacon-dark-text': isColorBright(corporateColours[COLOR.DARK]) ? '#000' : '#fff',
                            '@beacon-accent-text': isColorBright(corporateColours[COLOR.ACCENT]) ? '#000' : '#fff',
                        });
                        STORAGE.set('corporateColours', corporateColours);
                    }
                }

                if (tenant.tenantLogoUrl) {
                    vm.logoUrl = tenant.tenantLogoUrl;
                    vm.currentTenantName = tenant.name;
                    vm.currentSubdomainName = response.subdomain.name;
                }

                // Used for OpenID SSO redirect callback page
                if ($state.params.appMode && $state.params.appMode === 'smart-campus') {
                    return switchMode(vm.appModes.SMART_CAMPUS);
                }

                const getParam = findGetParameter('app');
                switch (getParam) {
                    case 'profile':
                        return $state.go('app.profile');
                    case 'trm':
                        return switchMode(vm.appModes.CONTENT);
                    case 'share-park':
                        return switchMode(vm.appModes.SHARE_PARK);
                    case 'management':
                        return switchMode(vm.appModes.MANAGEMENT);
                }

                const primeMode = getUsersPrimeMode(tenant, getParam);
                switchMode(primeMode);

                // *********************************************************************************
                // A place where you can put the homepage like $state.go('app.operationsDashboard');
                // *********************************************************************************

                // $state.go('app.tenants');

                // *********************************************************************************
                // A place where you can put the homepage like $state.go('app.operationsDashboard');
                // *********************************************************************************
            });

            checkTokenInterval = $interval(checkToken, MINUTE);
            setClickListeners();
            updateLogoutTimer();
            processPasswordExpiry();
        }

        function getUsersPrimeMode(tenant, getParam = null) {
            if (getParam) {
                return getPrimeModeByName(getParam);
            }

            const tenantAppMode = getPrimeModeByName(tenant.primeMode);
            if (tenantAppMode && tenantAppMode.isVisible) {
                return tenantAppMode;
            } else {
                const availableAppModeKey = Object.keys(vm.appModes).find(key => vm.appModes[key].isVisible);
                return vm.appModes[availableAppModeKey];
            }
        }

        function getPrimeModeByName(primeModeName) {
            switch(true) {
                case primeModeName === APP_MODES.SHARE_PARK.id:
                    return vm.appModes.SHARE_PARK;
                case primeModeName === APP_MODES.TRM.id:
                    return vm.appModes.CONTENT;
                case primeModeName === APP_MODES.SMART_CAMPUS.id:
                    return vm.appModes.SMART_CAMPUS;
                default:
                    throw new Error('Went through all conditions for choosing the default app');
            }
        }

        /**
         * Return the value of get param
         *
         * @param {string} parameterName
         * @return {string}
         */
        function findGetParameter(parameterName) {
            let result = null,
                tmp = [];
            const items = location.search.substr(1).split('&');
            for (let index = 0; index < items.length; index++) {
                tmp = items[index].split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            }
            return result;
        }

        function processPasswordExpiry() {
            const daysToExpire = UserUtilitiesService.daysToPasswordExpiry();
            if (daysToExpire <= 5 && daysToExpire > 0) {
                const days = Math.round(daysToExpire);
                const message = days === 0 ? 'YOUR_PASSWORD_WILL_EXPIRE_TODAY' : 'YOUR_PASSWORD_WILL_EXPIRE';
                $translate(message, {days})
                    .then(text => {
                        PopupService.showAlertPopup({
                            text,
                            okButtonText: 'OK'
                        });
                    });
            }
        }

        function checkToken() {
            const token = $auth.getToken();
            if (token) {
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace('-', '+').replace('_', '/');
                const parsedToken = JSON.parse(window.atob(base64));
                const timeDiff = parsedToken.exp - new Date().getTime() / 1000;
                if (timeDiff < SESSION_SETTINGS.MIN_TOKEN_LIFETIME * 60) {
                    LoginService.refreshToken().then(response => {
                        if (response.token) {
                            $auth.setToken(response.token);
                        }
                    });
                }
            }
        }

        function setClickListeners() {
            document.addEventListener('click', updateLogoutTimer);
            document.addEventListener('keydown', updateLogoutTimer);
        }

        function updateLogoutTimer() {
            if (logoutTimer) {
                $timeout.cancel(logoutTimer);
                $timeout.cancel(popupTimer);
            }
            if (LoginService.isLoggedIn()) {
                popupTimer = $timeout(() => {
                    PopupService.show({
                        templateUrl: '/assets/views/common/popups/info/info.tpl.html',
                        controller: 'InfoPopupController',
                        controllerAs: 'infoPopupController',
                        windowClass: 'infoPopup',
                        keyboard: false,
                        backdrop: true,
                        resolve: {
                            data: function() {
                                return {
                                    text: "You will be logged out in a minute because you don't interact with the page",
                                    okButtonText: 'Keep signed in'
                                };
                            }
                        }
                    }).then(updateLogoutTimer)
                        .catch(updateLogoutTimer);
                }, (LOGOUT_TIMEOUT - SESSION_SETTINGS.SHOW_POPUP_BEFORE) * MINUTE);
                logoutTimer = $timeout(() => {
                    $uibModalStack.dismissAll();
                    $auth.logout();
                    $state.go('login', { message: "YOU_HAVE_BEEN_LOGGED_OUT_SUCCESSFULLY" });
                    setTimeout(function() {
                        less.modifyVars();
                    }, 0);
                }, LOGOUT_TIMEOUT * MINUTE);
            }
        }

        $scope.$on('$destroy', function(){
            if (logoutTimer) {
                $timeout.cancel(logoutTimer);
                $timeout.cancel(popupTimer);
                $interval.cancel(checkTokenInterval);
                document.removeEventListener('click', updateLogoutTimer);
                document.removeEventListener('keydown', updateLogoutTimer);
            }
        });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userRolesTab', {
            templateUrl: '/assets/views/users/new/tabs/user-roles/user-roles.tpl.html',
            controller: NewUserRolesTab,
            bindings: {
                data: '=',
                userForm: '<'
            }
        });

    function NewUserRolesTab(
        $scope,
        newUserState,
        ROLES,
        APP_MODES,
        SESSION_SETTINGS,
        UserUtilitiesService,
        ) {
        const vm = this;

        const { allowedModes } = UserUtilitiesService;

        const currentUserRoles = vm.data.currentUserData && vm.data.currentUserData.roles
                ? vm.data.currentUserData.roles : [];

        const hasRoleSysAdmin = _.findIndex(currentUserRoles,
                                ['role_id', ROLES.SYS_ADMIN]) > -1;

        // public properties
        vm.state = newUserState;

        //private properties
        var rolesMap = vm.data.rolesMap || {};
        var permissionsMap = vm.data.permissionsMap || {};
        var tenantsMap = getTenantsMap();
        var permissionsGroupMap = vm.data.permissionsGroupMap || {};
        var userGroups = vm.data.userGroups || [];

        vm.userAccessRulesData = {
            columns: [
                {
                    name: 'TENANT',
                    class: 'user-column',
                    width: 15,
                    title: function (item) {
                        return getNameValue(item, 'tenant_id', tenantsMap);
                    }
                },
                {
                    name: 'SUBDOMAIN',
                    class: 'user-column',
                    width: 15,
                    title: function (item) {
                        if (item && item.tenant_id && tenantsMap && tenantsMap[item.tenant_id]) {
                            return getNameValue(item, 'subdomain_id', tenantsMap[item.tenant_id].subdomainsMap);
                        } else {
                            return '';
                        }
                    }
                },
                {
                    name: 'ROLE',
                    class: 'user-column',
                    width: 10,
                    title: function (item) {
                        return getNameValue(item, 'role_id', rolesMap);
                    }
                },
                {
                    name: 'USER_GROUP',
                    class: 'user-group-column',
                    width: 20,
                    title: (item) => getUserGroupName(item.user_groups_allowed), // #ListOfAllowedUserGroups
                },
                {
                    name: 'LOGIN_TIMEOUT',
                    class: 'timeout-column',
                    width: 15,
                    title: (item) => {
                        return item.loginTimeout + ' min' || '-';
                    }
                },
                {
                    name: 'APPS',
                    class: 'apps-column',
                    width: 15,
                    title: (item) => {
                        return item.allowedModes.join(', ');
                    }
                }
            ],
            buttons: {
                width: 10,
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteAccessRuleItem
                    },
                    {
                        class: 'editBtn',
                        callback: updateAccessRuleItem
                    }
                ]
            },
            doNotCloseOthers: true,
            updateCallback : function () { },
            additionalData: {
                ROLES,
                tenantsMap,
                getSubdomains,
                getRoles,
                getAppModes,
                toggleMode,
                isModeEnabled,
                onTenantChange,
                onSubdomainChange,
                onRoleChange,
                updatePermissionsList,
                getFilteredPermissions,
                isCheckedPermission,
                togglePermission,
                permissionGroups: _.values(permissionsGroupMap),
                userGroups,
            }
        };

        // public methods
        vm.addNewAccessRule = addNewAccessRule;


        init();

        // private methods

        /**
         * Returns array of filtered permissions

         * @param {array} newValues
         * @return {array}
         */
        function getFilteredPermissions (newValues) {
                var tenantId = newValues && newValues[0] ? newValues[0] : null;
                var subdomainId = newValues && newValues[1] ? newValues[1] : null;
                var roleId = newValues && newValues[2] ? newValues[2] : null;

                var userPermissionsIds = getUserPermissionsIds(tenantId, subdomainId);
                var domainPermissionsIds = getDomainPermissionsIds(tenantId, subdomainId, roleId);

                var availablePermissionsIds =
                        _.chain(_.concat(userPermissionsIds, domainPermissionsIds))
                         .countBy()
                         .pickBy((count) => count > 1)
                         .keys()
                         .value();

                return _.values(_.pick(permissionsMap, availablePermissionsIds));
            }

        /**
         * Returns ids array of available permissions for domain
         *
         * @param {number} tenantId
         * @param {number} subdomainId
         * @param {number} roleId
         * @return {array}
         */
        function getDomainPermissionsIds(tenantId, subdomainId, roleId) {
            var domainPermissionsIds = [];

            if (tenantId && roleId && tenantsMap && tenantsMap[tenantId]) {
                var domainPermissions = [];

                if (roleId === ROLES.SYS_ADMIN) {
                    domainPermissionsIds = _.map(permissionsMap, 'id');
                } else {
                    if (roleId === ROLES.TENANT_ADMIN) {
                        domainPermissions = tenantsMap[tenantId].permission_tenant || [];
                    } else if (subdomainId) {
                        domainPermissions = tenantsMap[tenantId].subdomainsMap
                                && tenantsMap[tenantId].subdomainsMap[subdomainId]
                                ? tenantsMap[tenantId].subdomainsMap[subdomainId].permission_tenant : [];
                    }
                    domainPermissionsIds = _.map(domainPermissions, 'permission_id');
                }
            }

            return domainPermissionsIds;
        }

        /**
         * Returns ids array of available permissions for user
         *
         * @param {number} tenantId
         * @param {number} subdomainId
         * @return {array}
         */
        function getUserPermissionsIds(tenantId, subdomainId) {

            if (hasRoleSysAdmin) {
                return _.map(permissionsMap, 'id');
            }

            var userPermissionsIds = [];

            var userAccess = _.find(currentUserRoles,{role_id:ROLES.SYS_ADMIN})
                    || (tenantId && _.find(currentUserRoles,{role_id:ROLES.TENANT_ADMIN, tenant_id: tenantId}))
                    || (tenantId && subdomainId &&
                            _.find(currentUserRoles,{role_id:ROLES.ADMIN, tenant_id: tenantId, subdomain_id: subdomainId}));

            if (userAccess) {
                    userPermissionsIds = _.pullAll(
                        _.union(
                            _.map(rolesMap[userAccess.role_id].permissions, 'id'),
                            _.map(_.filter(userAccess.additional_permissions, {available: 1}), 'permission_id')
                        ),
                        _.map(_.filter(userAccess.additional_permissions, {available: 0}), 'permission_id')
                    );
            }

            return userPermissionsIds;
        }

        /**
         * Returns map of available tenants
         *
         * @return {user-roles.ctrlL#1.NewUserRolesTab.isCheckedPermission.isChecked}
         */
        function getTenantsMap() {
            var tenantsMap = {};
            if (vm.data.tenantsMap) {
                if (hasRoleSysAdmin) {
                    tenantsMap = vm.data.tenantsMap;
                } else {
                    var availableTenantIds = _.chain(currentUserRoles)
                            .filter((item) => item.role_id > ROLES.USER).map('tenant_id').uniq().value();
                    tenantsMap = _.pick(vm.data.tenantsMap, availableTenantIds);
                }
            }
            return tenantsMap;
        }

        /**
         * Returns map of available subdomains
         *
         * @param {number} tenant_id
         * @return {object} subdomainsMap
         */
        function getSubdomains(tenantId) {
            var subdomainsMap = null;
            if (tenantsMap && tenantsMap[tenantId]) {
                if (hasRoleSysAdmin || isTenantAdmin(tenantId)) {
                    subdomainsMap = tenantsMap[tenantId].subdomainsMap;
                } else {
                    var availableSubdomainsIds = _.chain(currentUserRoles)
                            .filter((item) => {
                                return item.role_id > ROLES.USER &&
                                    item.tenant_id === tenantId;
                        }).map('subdomain_id').uniq().value();
                    subdomainsMap = _.pick(tenantsMap[tenantId].subdomainsMap, availableSubdomainsIds);
                }
            }
            return subdomainsMap || {};
        }

        /**
         * Returns list of available roles
         *
         * @return {user-roles.ctrlL#1.NewUserRolesTab.isCheckedPermission.isChecked}
         */
        function getRoles(tenantId, subdomainId) {
            var highestRoleId = 0;
            var lowestRoleId = subdomainId ? ROLES.USER : ROLES.TENANT_ADMIN;
            if (hasRoleSysAdmin) {
                highestRoleId = ROLES.SYS_ADMIN;
            } else if (isTenantAdmin(tenantId)) {
                highestRoleId = ROLES.ADMIN;
            } else if (isAdmin(tenantId, subdomainId)) {
                highestRoleId = ROLES.USER;
            }
            var filteredRoles = _.filter(rolesMap, (role)=>
                role.id <= highestRoleId && role.id >= lowestRoleId);
            return filteredRoles;
        }

        /**
         * Returns available app modes for user role
         * @param userRole
         * @return {array}
         */
        function getAppModes(userRole) {
            if (ROLES.SYS_ADMIN === userRole.role_id ) {
                return Object.values(APP_MODES);
            }

            const tenantId = userRole.tenant_id;

            const tenant = Object.values(vm.data.tenantsMap)
                .find(tenant => tenant.id === tenantId);
            if (!tenant) {
                return [];
            }
            const tenantModesIds = tenant.allowedModes;

            if (ROLES.TENANT_ADMIN === userRole.role_id ) {
                return Object.values(APP_MODES).filter(mode => tenantModesIds.includes(mode.id));
            }

            const userAllowedModesIds = allowedModes();
            const allowedModeIds = tenantModesIds.filter(modeId => userAllowedModesIds.includes(modeId));

            return Object.values(APP_MODES).filter(mode => allowedModeIds.includes(mode.id));
        }

        /**
         * Updates app modes for user role
         * @param userRole
         */
        function updateAllowedAppModes(userRole) {
            const availableAppModes = getAppModes(userRole);
            userRole.allowedModes = [ROLES.SYS_ADMIN, ROLES.TENANT_ADMIN].includes(userRole.role_id)
                ? availableAppModes.map(mode => mode.id)
                : userRole.allowedModes.filter(mode =>
                    availableAppModes.some(availableMode => availableMode.id === mode)
            );
        }

        /**
         * Reset allowedCampuses, allowedServiceProviders and allowedProfiles on tenant change
         * @param {number} roleId role id
         */
        function resetAllowedCampusesProfilesAndServiceProviders(roleId) {
            vm.data.userData.roles = vm.data.userData.roles.map((role) => {
                if (roleId == role.role_id) {
                    role.allowedCampuses = [];
                    role.allowedServiceProviders = [];
                    role.allowedProfiles = [];
                }

                return role;
            });
        }

        /**
         * On mode checkbox click handler
         * @param userRole
         * @param modeId
         */
        function toggleMode(userRole, modeId) {
            const index = userRole.allowedModes.indexOf(modeId);
            if (index === -1) {
                userRole.allowedModes.push(modeId);
                return;
            }

            userRole.allowedModes.splice(index, 1);

        }

        /**
         * Checks if mode checkbox is selected
         * @param userRole
         * @param modeId
         * @return {boolean}
         */
        function isModeEnabled(userRole, modeId) {
            return userRole.allowedModes.includes(modeId);
        }

        function onTenantChange(userRole) {
            resetAllowedCampusesProfilesAndServiceProviders(userRole.role_id);

            userRole.subdomain_id = userRole.role_id = null;
            userRole.user_groups = [];
            updateAllowedAppModes(userRole);
        }

        function onSubdomainChange(userRole) {
            userRole.role_id = null;
            updateAllowedAppModes(userRole);
        }

        function onRoleChange(userRole) {
            updatePermissionsList(userRole);
            updateAllowedAppModes(userRole);
        }

        /**
         * Checks is user has role TENANT_ADMIN
         *
         * @param {number} tenantId
         * @return {boolean}
         */
        function isTenantAdmin(tenantId) {
            return _.findIndex(currentUserRoles,
                    {
                        'tenant_id': tenantId,
                        'role_id':ROLES.TENANT_ADMIN
                    }) > -1;
        }

        /**
         * Checks is user has role ADMIN
         *
         * @param {number} tenantId
         * @return {boolean}
         */
        function isAdmin(tenantId, subdomainId) {
            return _.findIndex(currentUserRoles,
                    {
                        'tenant_id': tenantId,
                        'subdomain_id': subdomainId,
                        'role_id':ROLES.ADMIN
                    }) > -1;
        }


        /**
         * Checks is permission enabled
         *
         * @param {object} permission
         * @param {object} accessRuleItem
         * @return {boolean} isChecked
         */
        function isCheckedPermission(permission, accessRuleItem) {
            let isChecked = false;

            if (accessRuleItem.user_groups.length) {
                const relatedUserGroups = userGroups.filter(
                    ({ id }) => accessRuleItem.user_groups.find(item => item.id === id)
                );

                return relatedUserGroups.some(userGroup =>
                    userGroup.permissions.some(({ id }) => id === permission.id)
                );
            }

            if (accessRuleItem.role_id && permission.id && _.isObject(accessRuleItem)
                    && _.isArray(accessRuleItem.additional_permissions)) {
                var rolesPermissions = rolesMap[accessRuleItem.role_id].permissions;
                isChecked = (_.findIndex(rolesPermissions, ['id', permission.id]) !== -1
                        && _.findIndex(accessRuleItem.additional_permissions,
                                {'permission_id': permission.id, 'available': 0}) === -1)
                        || _.findIndex(accessRuleItem.additional_permissions,
                                {'permission_id': permission.id, 'available': 1}) !== -1;
            }
            return isChecked;
        }

        /**
         * Enables / disables permission
         *
         * @param {object} permission
         * @param {object} accessRuleItem
         */
        function togglePermission(permission, accessRuleItem) {
            var isChecked = isCheckedPermission(permission, accessRuleItem);
            _.remove(accessRuleItem.additional_permissions, {'permission_id': permission.id});
            if (accessRuleItem.role_id && _.findIndex(rolesMap[accessRuleItem.role_id].permissions, ['id', permission.id])===-1
                    || isChecked) {
                accessRuleItem.additional_permissions.push( {
                    'permission_id': permission.id,
                    'available': isChecked ? 0 : 1
                });
            }
        }

        /**
         * Deletes access rule item
         *
         */
        function deleteAccessRuleItem ($event, accessRuleItem){
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            _.remove(vm.data.userData.roles, (role)=> { return role.$$hashKey === accessRuleItem.$$hashKey;});
        }

        /**
         * Updates access rule item
         *
         */
        function updateAccessRuleItem ($event, accessRuleItem){
        }

        /**
         * Extracts name value from role item
         *
         * @param {object} item
         * @param {string} value_id
         * @param {OBJECT} collection
         * @return {string}
         */
        function getNameValue (item, value_id, collection) {
            return _.isObject(item) && value_id && _.isObject(collection)
                && item[value_id] && collection[item[value_id]]
                && collection[item[value_id]].name ? collection[item[value_id]].name : "";
        }

        /**
         * @param {array} userGroups
         */
        function getUserGroupName(userGroups) {
            if (!userGroups || !userGroups.length) {
                return '-';
            }

            return userGroups.map(item => item.name).join(', ');
        }

        /**
         * Go to access rule add page
         */
        function addNewAccessRule() {
            vm.data.userData.roles.push({
                additional_permissions: [],
                allowedModes: [],
                allowedCampuses: [],
                allowedServiceProviders: [],
                allowedProfiles: [],
                loginTimeout: SESSION_SETTINGS.FORCE_LOGOUT_TIMEOUT,
                user_groups: [],
            });
        }

        /**
         * Updates permissions list for the role item
         */
        function updatePermissionsList (item) {
            item.additional_permissions = [];
        }

        /**
         * Initialization method
         */
        function init() {
            if (vm.data.userData && !vm.data.userData.roles) {
                vm.data.userData.roles = [];
            }
        }


        function canFinish() {
            return vm.data
                && vm.data.userData.roles.length
                && vm.data.userData.roles.every(role => {
                    return role.tenant_id
                        && role.subdomain_id
                        && role.role_id;
                })
                && vm.userForm
                && vm.userForm.$valid;
        }

        $scope.$watch(
            () => vm.data.userData.roles,
            () => vm.state.canFinish = canFinish(),
            true
        );

        $scope.$watch(
            () => vm.userForm && vm.userForm.$valid,
            () => vm.state.canFinish = canFinish()
        );
    }
}());

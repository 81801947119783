(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('RefundPopupController', RefundPopupController);

    const STATUS_ACTIVE = 1;

    function RefundPopupController(
        $sce,
        $uibModalInstance,
        data,
        ShareParkDataService,
        PermissionsService,
        lodash,
    ) {
        const vm = this;

        vm.isPermissionAvailable = PermissionsService.isPermissionAvailable;
        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.isFormInvalid = isFormInvalid;
        vm.onRefundAmountChange = onRefundAmountChange;
        vm.$onInit = init;

        vm.maxAmmount = data.maxAmount;
        vm.currencyCode = data.currencyCode;
        vm.paymentName = data.paymentName;
        vm.transaction = data.transaction;
        vm.redeemAccountTypes = [];
        vm.redeemAccountTypeSelected = null;
        vm.comment = '';
        vm.REFUND_TO_PAYMENT_MEAN = null;

        vm.refundAmount = vm.maxAmmount;

        function init() {
            ShareParkDataService.redeemAccountTypes()
                .then(response => {
                    vm.redeemAccountTypes = response.plain()
                      .filter(item => item.status === STATUS_ACTIVE);

                    vm.redeemAccountTypeSelected = vm.redeemAccountTypes.length
                        ? vm.redeemAccountTypes[0]
                        : vm.REFUND_TO_PAYMENT_MEAN;
                });
        }

        /**
         * "OK" click handler
         */
        function okHandler() {
            const result = {
                amount: vm.refundAmount,
                comment: vm.comment,
            };

            if (vm.redeemAccountTypeSelected) {
                result.redeemAccount = vm.redeemAccountTypeSelected;
            }

            $uibModalInstance.close(result);
        }

        function isFormInvalid() {
            return vm.refundPopupForm && vm.refundPopupForm.$invalid;
        }

        function onRefundAmountChange() {
            if (vm.refundAmount) {
                vm.refundAmount = lodash.floor(vm.refundAmount, 2);
            }
        }

        /**
         * "Cancel" click handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss();
        }
    }
}());

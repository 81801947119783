(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenGroupsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/screens-tab/screen-groups-tab/screen-groups-tab.tpl.html',
            controller: ScreenGroupsTab,
            bindings: {
                screens: '<',
                onSelect: '=',
                searchName: '<',
            }
        });

    function ScreenGroupsTab(
        $state,
        $scope,
        $translate,
        ListPaginationService,
        LocationDataService,
        PermissionsService,
        PopupService,
        LOCATION_GROUPS_TYPES,
    ) {
        const vm = this;

        vm.screenGroups = [];
        vm.filteredGroups = [];
        vm.screenGroupsCount = 0;

        vm.updateCounter = 0;
        vm.paginationData = {
            page: null,
            itemsPerPage: null,
        };
        vm.screenGroupsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'locationTitle',
                    width: '100',
                    title: item => item.name
                }
            ],
            buttons: {
                width: '10',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteScreenGroup,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'editBtn',
                        callback: updateScreenGroup,
                        permissionAction: 'modify'
                    },
                ]
            },
            itemClickCallback: selectScreenGroup,
            updateCallback: updateScreenGroups,
            generatePermissionName: generateScreenGroupPermission,
            needRadiobutton: true,
        };

        vm.$onInit = init;
        vm.createScreenGroup = createScreenGroup;
        vm.reloadScreenGroups = reloadScreenGroups;
        vm.getGroupMarkers = getGroupMarkers;

        function init() {
            vm.reloadScreenGroups().then(
                () => updateScreenGroups(vm.paginationData.page, vm.paginationData.itemsPerPage)
            );
        }

        /**
         * Updates screen group
         *
         * @param {MouseEvent} $event
         * @param {Object} screenGroupItem
         */
        function updateScreenGroup($event, screenGroupItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editScreenGroup', {
                data: screenGroupItem,
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                },
                group_type: LOCATION_GROUPS_TYPES.SCREEN
            });
        }

        function reloadScreenGroups() {
            return LocationDataService.getLocationGroups({type: LOCATION_GROUPS_TYPES.SCREEN})
                .then(response => {
                    vm.screenGroups = response; 
                    vm.filteredGroups = vm.screenGroups;
                    vm.screenGroupsCount = vm.screenGroups.length;
                });
        }

        /**
         * Gets markers of specific screen group
         * @param screenGroup
         * @return {Array}
         */
        function getGroupMarkers(screenGroup) {
            let markers = [];
            screenGroup.locations.forEach(location => {
                const currentLocation = vm.screens.find(screen => screen.locationId === location.locationId);
                if (currentLocation && currentLocation.markers) {
                    markers = markers.concat(currentLocation.markers);
                }
            });

            return markers;
        }

        /**
         * Deletes location group
         *
         * @param {MouseEvent} $event
         * @param {Object} screenGroup
         */
        function deleteScreenGroup($event, screenGroup) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DELETE_LOCATION_GROUP_CONFIRMATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                LocationDataService.deleteLocationGroup(screenGroup.id)
                    .then(() => {
                        angular.isFunction(vm.reloadScreenGroups) && vm.reloadScreenGroups();
                    });
            });
        }

        /**
         * Creates screen group
         *
         */
        function createScreenGroup() {
            $state.go('app.addScreenGroup', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                },
                group_type: LOCATION_GROUPS_TYPES.SCREEN
            });
        }

        /**
         * Handle location group item click
         *
         * @param {object} screenGroup
         */
        function selectScreenGroup(screenGroup) {
            const selectedGroupName = `group - ${screenGroup.name}`;
            const selectedScreenIds = screenGroup && screenGroup.locations
                ? [... new Set(screenGroup.locations.map(location => location.id))]
                : null;

            vm.onSelect(selectedGroupName, selectedScreenIds);
            vm.selectedGroup = screenGroup;
            if (!vm.selectedGroup.markers) {
                vm.selectedGroup.markers = vm.getGroupMarkers(screenGroup);
            }
        }

        /**
         * Generates permission name for each button on screenGroup list
         * @param {object} button Button from list
         * @return {string} Permission name
         */
        function generateScreenGroupPermission(button) {
            return PermissionsService.generateScreenGroupPermission(button.permissionAction);
        }

        /**
         * Updates screen groups list
         * @param page
         * @param itemsPerPage
         */
        function updateScreenGroups(page, itemsPerPage) {
            vm.paginationData = {
                page,
                itemsPerPage,
            }
            vm.visibleScreenGroups = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredGroups).visibleItems;
        }

         /**
         *
         * @param name
         * @return {T[]}
         */
         function filterScreenGroupsByName(name) {
            return vm.screenGroups.filter(beacon => beacon.name.toUpperCase().includes(name.toUpperCase()));
        }

        $scope.$watch(
            () => vm.searchName,
            searchName => {
                vm.filteredGroups = vm.searchName
                    ? filterScreenGroupsByName(searchName)
                    : vm.screenGroups;
                vm.updateCounter++;
            }
        );

        $scope.$watch(
            () => vm.screenGroups,
            () => {
                if (vm.screenGroups) {
                    vm.filteredGroups = vm.searchName ? filterScreenGroupsByName(vm.searchName) : vm.screenGroups;
                    vm.updateCounter++;
                }
            },
            true
        );
    }
})();

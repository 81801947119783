(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userGroupPermissionsTab', {
            templateUrl: '/assets/views/user-groups/new/tabs/user-group-permissions/user-group-permissions.tpl.html',
            controller: UserGroupPermissionsController,
            bindings: {
                userGroupData: '<',
            }
        });

    function UserGroupPermissionsController(
        $scope,
        LookupDataService,
        StatesFactory,
    ) {
        const vm = this;

        vm.state = StatesFactory.UserGroupStates;

        vm.state.canBack = true;

        vm.$onInit = init;

        vm.permissions = [];
        vm.permissionGroups = [];

        vm.isCheckedPermission = isCheckedPermission;
        vm.togglePermission = togglePermission;

        function init() {
            vm.state.canFinish = true;
            LookupDataService.getAccessData()
                .then(response => {
                    vm.permissions = Object.values(response.permissionsMap);
                    vm.permissionGroups = Object.values(response.permissions_groupMap);

                    $scope.$digest();
                });
        }

        function isCheckedPermission(permission) {
            return vm.userGroupData.group_permissions.includes(permission.id);
        }

        /**
         * Toggle Permission
         * @param permission
         */
        function togglePermission(permission) {
            const groupPermissions = vm.userGroupData.group_permissions;
            const permissionIndex = groupPermissions.findIndex(permissionId =>
                permissionId === permission.id
            );

            permissionIndex > -1
                ? groupPermissions.splice(permissionIndex, 1)
                : groupPermissions.push(permission.id);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('bannerPositionsConfig', {
            controller: BannerPositionsConfigController,
            templateUrl: '/assets/views/tenants/tabs/tenants-tab/new/banner-positions-config/banner-positions-config.tpl.html',
            bindings: {
                onComplete: '<',
                tenantId: '<',
            }
        });

    function BannerPositionsConfigController(
        $q,
        ContentDataService,
    ) {
        const vm = this;

        const STATUS_ACTIVE = 1;
        const STATUS_INACTIVE = 0;

        vm.$onInit = init;
        vm.isSelectedApp = isSelectedApp;
        vm.isSelectedPage = isSelectedPage;
        vm.isSelectedPosition = isSelectedPosition;
        vm.onAppCheckboxClick = onAppCheckboxClick;
        vm.onPageCheckboxClick = onPageCheckboxClick;
        vm.onPositionCheckboxClick = onPositionCheckboxClick;
        vm.onSubmit = onSubmit;

        function init() {
            $q.all([
                ContentDataService.getAllBannerConfigs(),
                ContentDataService.getBannerConfigsWithInactive(vm.tenantId),
            ]).then(([configsAll, configsCurrent]) => {
                vm.bannerPositionsCurrent = configsCurrent.plain();
                vm.bannerPositionsAll = _groupConfigs(
                    configsAll.plain().filter(item => item.useCase === 'position')
                );
            });
        }

        /**
         * @param {BannerConfigGroup} app
         * @returns {boolean}
         */
        function isSelectedApp(app) {
            return !!vm.bannerPositionsCurrent.find(
                item => item.appName === app.appName && item.status === STATUS_ACTIVE
            );
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @returns {boolean}
         */
        function isSelectedPage(app, page) {
            return !!vm.bannerPositionsCurrent.find(item => {
                return item.appName === app.appName
                    && item.configText === page.configText
                    && item.status === STATUS_ACTIVE;
            });
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @param {object} pos
         * @returns {boolean}
         */
        function isSelectedPosition(app, page, pos) {
            return !!vm.bannerPositionsCurrent.find(item => {
                return item.appName === app.appName
                    && item.configText === page.configText
                    && item.parameters.split('|').includes(pos)
                    && item.status === STATUS_ACTIVE;
            });
        }

        /**
         * @param {BannerConfigGroup} app
         */
        function onAppCheckboxClick(app) {
            const isSelected = isSelectedApp(app);
            if (isSelected) {
                _deselectApp(app);
            } else {
                _selectApp(app)
            }
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         */
        function onPageCheckboxClick(app, page) {
            const isSelected = isSelectedPage(app, page);
            if (isSelected) {
                _deselectPage(app, page);
            } else {
                _selectPage(app, page);
            }
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @param position
         */
        function onPositionCheckboxClick(app, page, position) {
            const isSelected = isSelectedPosition(app, page, position);
            if (isSelected) {
                _deselectPosition(app, page, position);
            } else {
                _selectPosition(app, page, position);
            }
        }

        function onSubmit() {
            ContentDataService.setBannerConfigs(vm.bannerPositionsCurrent, vm.tenantId)
                .then(response => {
                    if (vm.onComplete) {
                        vm.onComplete();
                    }
                });
        }

        /**
         * @param {BannerConfigGroup} app
         * @private
         */
        function _selectApp(app) {
            vm.bannerPositionsAll.forEach(group => {
                if (group.appName === app.appName) {
                    group.configs.forEach(page => {
                        _selectPage(app, page);
                    })
                }
            })
        }

        /**
         * @param {BannerConfigGroup} app
         * @private
         */
        function _deselectApp(app) {
            vm.bannerPositionsCurrent.forEach(
                item => {
                    if (item.appName === app.appName) {
                        item.status = STATUS_INACTIVE;
                    }
                }
            );
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @private
         */
        function _selectPage(app, page) {
            const found = vm.bannerPositionsCurrent.find(
                item => item.appName === app.appName && item.configText === page.configText
            );

            if (found) {
                found.status = STATUS_ACTIVE;
            } else {
                vm.bannerPositionsAll.forEach(group => {
                    if (group.appName === app.appName) {
                        group.configs.forEach(config => {
                            if (config.configText === page.configText) {
                                const copy = angular.copy(config);
                                delete copy.id;
                                vm.bannerPositionsCurrent.push(copy);
                            }
                        })
                    }
                })
            }
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @private
         */
        function _deselectPage(app, page) {
            vm.bannerPositionsCurrent.forEach(
                item => {
                    if (item.appName === app.appName && item.configText === page.configText) {
                        item.status = STATUS_INACTIVE;
                    }
                }
            );
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @param {string} position
         * @private
         */
        function _deselectPosition(app, page, position) {
            const item = vm.bannerPositionsCurrent.find(
                item => item.appName === app.appName && item.configText === page.configText
            );
            item.parameters = item.parameters.split('|').filter(pos => pos !== position).join('|');

            if (!item.parameters) {
                _deselectPage(app, page);
            }
        }

        /**
         * @param {BannerConfigGroup} app
         * @param {BannerConfig} page
         * @param {string} position
         * @private
         */
        function _selectPosition(app, page, position) {
            const getPage = () => {
                return vm.bannerPositionsCurrent.find(
                    item => item.appName === app.appName
                        && item.configText === page.configText
                        && item.status === STATUS_ACTIVE
                );
            }
            let item = getPage();

            if (!item) {
                _selectPage(app, page);
                item = getPage();
                item.parameters = "";
            }

            const params = angular.copy(item).parameters.split('|');
            params.push(position);
            item.parameters = params.filter(notEmpty => notEmpty).join('|');
        }

        /**
         * @param configArray
         * @returns {BannerConfigGroup[]}
         * @private
         */
        function _groupConfigs(configArray) {
            const configsGrouped = [];
            configArray
                .forEach(item => {
                    const app = configsGrouped.find(config => config.appName === item.appName);
                    if (app) {
                        app.configs.push(item);
                    } else {
                        configsGrouped.push({
                            appName: item.appName,
                            configs: [item],
                        });
                    }
                });
            return configsGrouped;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('TierTabsController', TierTabsController);

    function TierTabsController($stateParams)
    {
        const vm = this;

        vm.TAB_TIER_TYPES = 0;
        vm.TAB_TIER_GROUPS = 1;

        vm.currentTab = $stateParams.tab;

        vm.changeTab = changeTab;

        /**
         * Tab title click handler
         *
         * @param number
         */
        function changeTab(number) {
            vm.currentTab = number;
        }
    }
}());
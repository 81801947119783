(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('buttonProperties', buttonProperties);

    function buttonProperties() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/elements/button.tpl.html',
            replace: true,
            controller: buttonPropertiesController,
            controllerAs: 'buttonPropertiesCtrl',
            bindToController: true,
            scope: {
                item: '=',
                pages: '=pages'
            }
        };
    }

    function buttonPropertiesController($scope) {}
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('DownloadScreensUrlPopupController', DownloadScreensUrlPopupController);

    function DownloadScreensUrlPopupController(
        $scope,
        $timeout,
        $uibModalInstance,
        $translate,
        LocationDataService
    ) {
        const vm = this;

        const {downloadScreensUrl} = LocationDataService;

        // public methods
        vm.download = downloadHandler;
        vm.cancel = cancelHandler;

        vm.columns = [
            {
                title: 'NAME',
                checked: true,
                disabled: true,
            },
            {
                title: 'DESCRIPTION',
                checked: true,
            },
            {
                title: 'CREATED',
                checked: true,
            },
            {
                title: 'LAST_CALL',
                checked: true,
            },
            {
                title: 'Url',
                checked: true,
                disabled: true,
            },
            {
                title: 'STATUS',
                checked: true,
                disabled: false,
            },
            {
                title: 'SCREEN_TYPE',
                checked: true,
                disabled: false,
            },
            {
                title: 'POLE_FILTERS',
                checked: true,
                disabled: false,
            },
            {
                title: 'GROUPS',
                checked: true,
                disabled: false,
            },
            {
                title: 'CAMPAIGNS',
                checked: true,
                disabled: false,
            },
            {
                title: 'SCREEN_DATA_FILTERING',
                checked: false,
                disabled: false,
            },
        ];


        /**
         * Download button handler
         */
        function downloadHandler() {
            const columns = vm.columns
                .map(column => column.checked && column.title.toLowerCase())
                .filter(column => column);

            const fileName = 'screens-url.csv';
            const a = document.createElement('a');
            document.body.appendChild(a);
            downloadScreensUrl({columns}).then(result => {
                const csv = "\ufeff" + result; // UTF-8 fix
                const file = new Blob([csv], {
                    type: 'text/csv'
                });
                a.href = window.URL.createObjectURL(file);
                a.download = fileName;
                a.click();
                $uibModalInstance.close();
            });
        }

        /**
         * Cancel button handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }
    }

}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tenantAppSelector', {
            controller: tenantAppSelectorController,
            templateUrl: '/assets/views/common/components/tenant-app-selector/tenant-app-selector.tpl.html',
            bindings: {
                ngModel: '=',
                ngDisabled: '<',
                disabledApps: '<', // eg. ['com.mobility.flow', 'de.hafas.android.trm.local']
                ngRequired: '<',
                ngMultiple: '<',
                ngChange: '&',
            },
        });

    function tenantAppSelectorController($timeout, TenantDataService) {
        const vm = this;

        vm.$onInit = init;
        vm.isDisabledApp = isDisabledApp;
        vm.onAppsChange = onAppsChange;

        /**
         * Controller initialization
         */
        function init() {
            TenantDataService.getAvailableApps()
                .then(apps => {
                    vm.apps = apps;

                    if (!vm.ngModel && vm.ngRequired) {
                        vm.ngModel = _getDefaultValue(apps);
                        onAppsChange();
                    }
                });
        }

        /**
         * Return the default value for apps selector
         *
         * @param {array} apps
         * @return {array}
         * @private
         */
        function _getDefaultValue(apps) {
            return vm.ngMultiple
                ? apps.map(app => app.id)
                : vm.apps[0] && apps[0].id || null;
        }

        /**
         * Checks if app is disabled by app id
         *
         * @param {string} appId
         * @return {boolean}
         */
        function isDisabledApp(appId) {
            return Array.isArray(vm.disabledApps) && vm.disabledApps.includes(appId);
        }

        function onAppsChange() {

            // timeout is needed to call function after digest cycle will finish updating ngModel
            $timeout(vm.ngChange);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('segmentTypeTab', {
            templateUrl: '/assets/views/campaign/segment/new/tabs/segment-type/segment-type.tpl.html',
            controller: SegmentTypeTab
        });

    function SegmentTypeTab(
        StorageFactory,
        SegmentDataService,
        segmentAddState,
    ) {

        const vm = this;
        vm.state = segmentAddState;
        vm.setSegmentType = setSegmentType;

        init();

        function init() {
            vm.STORAGE = StorageFactory.Storage('Segment');
            vm.STORAGE.on('segmentData', (value) => (vm.segmentData = value));
            vm.segmnentTypes = vm.STORAGE.get('segmnetTypes');

            if (!vm.segmnentTypes) {
                loadSegmentTypes();
            }
        }

        function loadSegmentTypes() {
            return SegmentDataService.segmentTypes().then(function(response) {
                let segmentTypes = response.plain();

                vm.STORAGE.set('segmentTypes', segmentTypes);
                vm.segmentTypes = segmentTypes;
            });
        }

        /**
         * Sets segment type
         * @param {number} type
         */
        function setSegmentType(type) {
            let typeId =_.isObject(type) && type.id ? Number(type.id) : null;
            if (typeId && vm.segmentData.segment_type_id !== typeId) {
                vm.segmentData.segment_type_id = typeId;
                vm.segmentData.segment_type = type;
            } else {
                vm.segmentData.segment_type_id = false;
                vm.segmentData.segment_type = null;
            }
            vm.state.canProcessNext = !!vm.segmentData.segment_type_id;
        }
    }
})();

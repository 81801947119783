(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tierGroups', {
            templateUrl: '/assets/views/loyalty/tiers/tier-groups/tier-groups.tpl.html',
            controller: TierGroupsController
        });

    function TierGroupsController($state, $stateParams, StorageFactory, PermissionsService, TierGroupService,
                                  UtilitiesService) {
        const vm = this;

        vm.STORAGE = new StorageFactory.Storage('TierGroups', true);

        vm.TIER_GROUP_STATUS_INACTIVE = 0;
        vm.TIER_GROUP_STATUS_ACTIVE = 1;
        vm.TIER_GROUP_STATUS_ACTIVE_DEFAULT = 2;
        vm.TIER_GROUP_STATUS_DELETED = 3;

        vm.filter = {
            status: {}
        };

        vm.newTierGroup = newTierGroup;
        vm.updateTierGroupsList = updateTierGroupsList;
        vm.isPermissionAvailable = PermissionsService.isPermissionAvailable;

        _init();

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '40',
                    title: 'name'
                },
                {
                    name: 'STATUS',
                    class: 'campaignStatus',
                    width: '15',
                    translate: true,
                    title: item => {
                        switch(item.status) {
                            case vm.TIER_GROUP_STATUS_INACTIVE:
                                return 'INACTIVE';
                            case vm.TIER_GROUP_STATUS_ACTIVE:
                                return 'ACTIVE';
                            case vm.TIER_GROUP_STATUS_ACTIVE_DEFAULT:
                                return 'ACTIVE_DEFAULT';
                            case vm.TIER_GROUP_STATUS_DELETED:
                                return 'DELETED';
                            default:
                                return 'UNDEFINED';
                        }
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: _deleteTierGroup,
                        isVisible: (btn, item) => item.status !== vm.TIER_GROUP_STATUS_DELETED,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: _copyTierGroup,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: _editTierGroup,
                        isVisible: (btn, item) => item.status !== vm.TIER_GROUP_STATUS_DELETED,
                        permissionAction: 'modify'
                    },
                ]
            },
            updateCallback: updateTierGroupsList,
            generatePermissionName: _generatePermissionName
        };

        /**
         * Create tier group button handler
         */
        function newTierGroup() {
            $state.go('app.loyaltyTierGroupsAdd');
        }

        /**
         * Updates tier groups list
         *
         * @param {number} page
         * @param {number} itemsPerPage
         * @param {boolean} forceApi - force update from DB
         */
        function updateTierGroupsList(page = vm.paginationParams.page,
                                        itemsPerPage = vm.paginationParams.itemsPerPage,
                                        forceApi = false) {
            const tierGroupsAll = vm.STORAGE.get('tierGroups');
            if (!forceApi && tierGroupsAll !== undefined) {
                _applyTierGroupsToScope(tierGroupsAll, page, itemsPerPage);
            } else {
                TierGroupService.getList()
                    .then(response => {
                        vm.STORAGE.set('tierGroups', response);
                        _applyTierGroupsToScope(response, page, itemsPerPage);
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Copy tier group button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function _copyTierGroup($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const objCopy = angular.copy(item.plain());
            UtilitiesService.removeProperties(objCopy, ['id', 'external_id']);

            objCopy.label = JSON.parse(objCopy.label);
            objCopy.description = JSON.parse(objCopy.description);
            objCopy.status = 0;

            const formData = new FormData();
            formData.append('json', JSON.stringify(objCopy));

            TierGroupService.create(formData)
                .then(function() {
                    updateTierGroupsList(vm.paginationParams.page, vm.paginationParams.itemsPerPage, true);
                }).catch(console.error.bind(console));
        }

        /**
         * Edit tier group button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function _editTierGroup($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            const data = item.plain();

            $state.go('app.loyaltyTierGroupsEdit', {
                data,
                paginationData: {
                    page: vm.paginationParams.page,
                    itemsPerPage: vm.paginationParams.itemsPerPage,
                    count: vm.totalItems
                },
                type: 'edit'
            });
        }

        /**
         * Delete campaign button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function _deleteTierGroup($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = item.plain();
            data.label = JSON.parse(data.label);
            data.description = JSON.parse(data.description);
            data.status = vm.TIER_GROUP_STATUS_DELETED;

            const formData = new FormData();
            formData.append('json', JSON.stringify(data));

            TierGroupService.remove(formData)
                .then(function() {
                    item.status = data.status;
                }).catch(console.error.bind(console));
        }

        /**
         * Applying filtering and pagination parameters to tier groups, and adding tier groups list to scope
         *
         * @param {array} tierGroups
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function _applyTierGroupsToScope(tierGroups, page, itemsPerPage) {
            const tierGroupsFiltered = _getFiltered(tierGroups);
            vm.totalItems = tierGroupsFiltered.length;
            vm.tierGroups = _getPage(tierGroupsFiltered, page, itemsPerPage);
            vm.paginationParams = {page, itemsPerPage};
        }

        /**
         * Getting filtered tier groups from all tier groups list
         *
         * @param {array} tierGroups
         * @returns {array}
         */
        function _getFiltered(tierGroups) {
            const statuses = Object.keys(vm.filter.status).filter(statusId => vm.filter.status[statusId] === true);

            if (!statuses.length) {
                return tierGroups;
            }

            return tierGroups.filter(item => {
                return statuses.includes(item.status.toString());
            });
        }

        /**
         * Getting part (page) of array
         *
         * @param {array} array
         * @param {number} page
         * @param {number} perPage
         * @returns {array}
         */
        function _getPage(array, page, perPage) {
            --page; // because pages logically start with 1, but technically with 0
            const firstElement = page * perPage;
            return array.slice(firstElement, firstElement + perPage);
        }

        /**
         * From short name to permission full name
         *
         * @param {object} button
         * @returns {string}
         */
        function _generatePermissionName(button) {
            return 'can_' + button.permissionAction + '_tiers';
        }

        /**
         * Controller initialization
         */
        function _init() {
            vm.paginationData = $stateParams.paginationData;
        }
    }
}());
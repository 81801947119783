(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasPage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/timetable-hafas/timetable-hafas-page.tpl.html',
            controller: TimetableHafasPageController,
            bindings: {
                contentData: '<',
                pageData: '<',
                languages: '<',
            }
        });

    /**
     * @param {$rootScope.Scope} $scope
     * @param ContentDataService
     * @param CONTENT_TYPES
     * @property {DigitalDisplayContent} contentData
     * @property {TimetableHafasPageType} pageData
     * @property {Language[]} languages
     * @constructor
     */
    function TimetableHafasPageController(
        $scope,
        ContentDataService,
        CONTENT_TYPES,
    ) {
        const vm = this;

        /**
         * @type {TimetableHafasContent[]}
         */
        vm.contents = [];

        /**
         * @type {TimetableHafasContent}
         */
        vm.selected = null;

        vm.$onInit = () => {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.TIMETABLE_HAFAS})
                .then(response => {
                    vm.contents = response.plain().map(content => {
                        content.title = angular.fromJson(content.title);
                        content.message = angular.fromJson(content.message);
                        content.data = angular.fromJson(content.data);
                        return content;
                    });
                    vm.selected = vm.contents.find(content => content.id === vm.pageData.selectedContentId);
                });
        };

        /**
         * @callback FinishCallback
         * @param  {{ pageData: TimetableHafasPageType }} data
         * @return void
         */

        /**
         * @param event
         * @param {{ callback: FinishCallback }} data
         */
        function onFinish(event, data) {
            if (!vm.selected) {
                return;
            }

            vm.pageData.selectedContentId = vm.selected.id;

            if (typeof data.callback === 'function') {
                data.callback({
                    pageData: vm.pageData
                });
            }
        }

        $scope.$on('digital-display-savepage', onFinish);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewUserController', NewUserController);

    function NewUserController(
        $scope,
        $state,
        newUserState,
        UserDataService,
        $stateParams,
        PopupService,
        Restangular,
        CREATE_USER_ERROR_CODES,
    ) {
        const vm = this;

        // public properties
        vm.state = newUserState;
        vm.data = null;

        // public methods


        init();

        /**
         * View change callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onViewChange(newValue, oldValue) {
            
        }

        /**
         * Finishing of creation/edition user callback
         *
         * @param {object} response
         */
        function usersResponseHandler(response) {
            $state.go('app.users', {
                paginationData: $stateParams.paginationData
            });
        }

        /**
         * Finishing of creation/edition user callback
         */
        function onFinish() {
            let promise;

            const userData = Restangular.copy(vm.data.userData);
            if (vm.state.type === 'new') {
                promise = UserDataService.createUser(userData)
            } else {
                if (userData && userData.password && userData.password === "dummyPassword3$") {
                    delete userData.password;
                }

                promise = userData.put();
            }

            promise.then(usersResponseHandler).catch(err => {
                console.error(err);

                if (err.data) {
                    let text;
                    switch (err.data.errorCode) {
                        case CREATE_USER_ERROR_CODES.INVALID_EMAIL:
                            text = 'USER_WITH_THIS_EMAIL_EXISTS_ALREADY';
                            break;
                        case CREATE_USER_ERROR_CODES.INVALID_NAME:
                            text = 'USER_WITH_THIS_USERNAME_EXISTS_ALREADY';
                            break;
                    }

                    if (!text) {
                        text = err.data.message || 'UNEXPECTED_ERROR';
                    }

                    PopupService.showAlertPopup({
                        text,
                        okButtonText: 'OK'
                    });
                }
            });
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.data = $stateParams.data || {};

            if (vm.state.type === "edit") {
                vm.data.userData.password = vm.data.userData.confirmPassword = "dummyPassword3$";
            }
        }

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.state.view;
        }), onViewChange);

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.state.finish;
        }), (newValue) => {
                if (newValue) {
                    onFinish();
                }
            });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentAddController', ContentAddController);

    function ContentAddController($scope, $rootScope, $stateParams, $timeout, $state,
                                  UtilitiesService, ContentDataService,
                                  StatesFactory, StorageFactory, ModelsFactory, PopupService,
                                  SCREEN_CAMPAIGN_CONTENT_TYPES) {
        const vm = this;
        const { checkContentType, oneOfContentTypes } = UtilitiesService;

        // public properties
        vm.state = StatesFactory.ContentStates.refresh();

        // public methods
        vm.checkContentType = checkContentType;
        vm.oneOfContentTypes = oneOfContentTypes;

        init();

        // private methods

        function init() {
            vm.state.type = $stateParams.type;
            vm.STORAGE = new StorageFactory.Storage('Content', true);

            switch (vm.state.type) {
                case 'new':
                    // NOTE: when content copy
                    if ($stateParams.data) {
                        refreshContentData($stateParams.data);
                        vm.state.go('CONTENT_DETAILS', true);
                        vm.state.canBack = true;
                    } else {
                        refreshContentData();

                        if ($stateParams.initialData && $stateParams.initialData.contentType) {
                            vm.contentData.content_type_id = $stateParams.initialData.contentType;
                            vm.state.go('CONTENT_DETAILS', true);
                            vm.state.canBack = true;
                        }
                    }
                    break;

                case 'edit':
                    refreshContentData($stateParams.data);
                    vm.state.go('CONTENT_DETAILS', true);
                    vm.state.canBack = true;
                    break;
            }
        }

        function refreshContentData(data = {}) {
            // NOTE: use plain when data loading!!!
            if (typeof data.plain === 'function') {
                data = data.plain();
            }

            // TODO: Test it! Maybe we can remove this!!!
            let contentData = new ModelsFactory.BaseContentModel(data);

            if (!contentData.language_id) {
                let langArray = vm.STORAGE.get('langArray');

                if (langArray && langArray[0]) {
                    contentData.language_id = langArray[0].id;
                }
            }

            if (!contentData.content_group_id) {
                let contentGroupsArray = vm.STORAGE.get('contentGroupsArray');

                if (contentGroupsArray && contentGroupsArray[0]) {
                    contentData.language_id = contentGroupsArray[0].id;
                }
            }

            vm.contentData = vm.STORAGE.set('contentData', contentData);
        }

        /**
         * View change callback
         */
        function onStateChange() {
            switch (Number(vm.state.view)) {
                case vm.state.views.CONTENT_TYPE[0]:
                    refreshContentData({
                        content_type_id: vm.contentData.content_type_id
                    });
                    break;
                case vm.state.views.CONTENT_DETAILS[0]:
                    Object.assign(vm.state, {
                        canFinish: true
                    });
                    break;
            }
        }

        /**
         * Save/finish action callback
         *
         * @param {function} callback - callback function on content save
         */
        function onFinish(callback) {

            let contentForm = vm.STORAGE.get('contentForm');
            if (!contentForm) {
                return;
            }

            contentForm.$submitted = true;
            if (!contentForm.$valid) {
                return;
            }

            $timeout(function() {
                $rootScope.$broadcast('content-finish', {
                    callback: (obj) => {
                        const jsonFields = ['title', 'message', 'data', 'audio_src'];
                        // todo: move to some method
                        if (!obj.hasOwnProperty('contentData') || obj.contentData === undefined) {
                            throw new ReferenceError('The \'contentData\' property is not found!');
                        } else if (typeof obj.contentData !== 'object' && obj.contentData !== null) {
                            throw new TypeError('The \'contentData\' should be an object, not be a Null!');
                        }

                        if (!obj.hasOwnProperty('formData') || obj.contentData === undefined) {
                            obj.formData = new FormData();
                        } else if (typeof obj.formData !== 'object' && obj.formData !== null) {
                            throw new TypeError('The \'formData\' should be an object, not be a Null!');
                        }

                        for (let prop in obj.contentData) {
                            let value = obj.contentData[prop];

                            if (jsonFields.includes(prop) && angular.isObject(value)) {
                                value = angular.toJson(value);
                            }

                            // jump to next loop iteration, because we don't need to rewrite 'audio' property
                            if (prop === 'audio' && obj.formData.get('audio')) {
                                continue;
                            }

                            if (obj.contentData.hasOwnProperty(prop) && value !== undefined && value !== null) {
                                obj.formData.append(prop, value);
                            }
                        }

                        if (obj.type === 'new') {
                            ContentDataService.create(obj.formData)
                                .then(contentRecord => callback(contentRecord))
                                .catch(_handleApiException);
                        } else {
                            ContentDataService.content(obj.contentData.id)
                                .then((content) => {
                                    obj.formData.append('_method', 'PUT');

                                    return ContentDataService.update(content, obj.formData)
                                        .then(contentRecord => callback(contentRecord));
                                })
                                .catch(_handleApiException);
                        }
                    }
                });
            }, 0);
        }

        /**
         * Handles API errors
         *
         * @param response
         * @private
         */
        function _handleApiException(response) {
            console.error.bind(console);
            if (response.data && response.data.message) {
                PopupService.showAlertPopup({
                    text: response.data.message,
                    okButtonText: 'OK'
                });
            } else {
                console.error(response);
            }
        }

        /**
         * On finish handler
         */
        function finishCallback() {
            if ($stateParams.redirect) {
                $state.go($stateParams.redirect);
                return;
            }

            $state.go('app.content', {
                paginationData: $stateParams.paginationData
            });
        }

        /**
         * Finish and manage callback
         * @param content
         */
        function finishAndManageCallback(content) {
            const state = SCREEN_CAMPAIGN_CONTENT_TYPES.includes(content.content_type_id) ?
                'app.contentScreenCampaigns' : 'app.contentCampaigns';
            $state.go(state, {
                contentElement: content,
                redirect: $stateParams.redirect,
            });
        }

        /**
         * Finish and add banner callback
         * @param content
         * @return {*}
         */
        function finishAndAddBanner(content) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/content-banners-popup/content-banners.tpl.html',
                controller: 'ContentBannersPopupController',
                controllerAs: '$ctrl',
                resolve: {
                    data: () => (
                        {
                            content
                        }
                    )
                }
            }).catch(finishCallback);
        }

        // Watchers
        $scope.$watch(() => vm.state.view, onStateChange);
        $scope.$watch(() => vm.state.finish, (newValue) => {
            if (newValue) {
                onFinish(finishCallback);
            }
        });
        $scope.$watch(() => vm.state.finishAndManage, newValue => newValue && onFinish(finishAndManageCallback));
        $scope.$watch(() => vm.state.finishAndAddBanner, newValue => newValue && onFinish(finishAndAddBanner));
    }
}());

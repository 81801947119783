(function() {
    'use strict';

    angular.module('beacon.app')
        .component('campaignDetailsTab', {
            templateUrl: '/assets/views/campaign/mobile/tabs/details/details.tpl.html',
            controller: CampaignMobileDetailsTab,
            bindings: {
                campaignModel: '='
            }
        });

    function CampaignMobileDetailsTab(
        $scope,
        $stateParams,
        campaignMobileState,
        DateHelper,
        WEEK_DAYS,
        MOBILE_CAMPAIGN_INFINITE_DATE
    ) {
        const vm = this;

        const { getDefaultDateFormat } = DateHelper;

        // public properties
        vm.state = campaignMobileState;
        vm.isTitleEntered = false;
        vm.isDescriptionEntered = false;
        vm.infinite = MOBILE_CAMPAIGN_INFINITE_DATE;
        vm.days = WEEK_DAYS;
        vm.ALL_DAYS_FLAG = 9;
        vm.zeroDate = new Date(0);
        vm.today = new Date(); // need for form validation
        vm.today.setHours(0, 0, 0, 0);
        vm.minDate = vm.today;
        vm.maxDate = new Date();
        vm.maxDate.setFullYear(vm.maxDate.getFullYear() + 10);
        vm.dateFormat = getDefaultDateFormat();

        vm.$onInit = init;

        // public methods
        vm.onTitleBlur = onTitleBlur;
        vm.onDescriptionBlur = onDescriptionBlur;
        vm.toggleDay = toggleDay;
        vm.onChangeInfinite = onChangeInfinite;
        vm.isTestMode = isTestMode;
        vm.setTestMode = setTestMode;

        // private methods

        /**
         *  Title blur handler
         */
        function onTitleBlur() {
            if (vm.campaignModel.name) {
                vm.isTitleEntered = true;
            }
        }

        /**
         *  Title change handler
         */
        function onTitleChange() {
            if ($stateParams.type === 'new' && !$stateParams.data && !vm.isTitleEntered && !vm.isDescriptionEntered) {
                vm.campaignModel.description = vm.campaignModel.name;
            }
        }

        /**
         *  Description blur handler
         */
        function onDescriptionBlur() {
            if (vm.campaignModel.description) {
                vm.isDescriptionEntered = true;
            }
        }

        /**
         * Toggles day selection
         * @param {number} id
         */
        function toggleDay(id) {
            const { selectedDays } = vm.campaignModel;
            let index = selectedDays.indexOf(id);

            if (index === -1) {
                selectedDays.push(id);
            } else {
                selectedDays.splice(index, 1);
            }

            vm.state.canProcessNext = !!(vm.campaignDetailsForm.$valid && selectedDays.length);
        }

        /**
         * Validate certain days option
         *
         * @return {Boolean}
         */
        function validateCertainDayOption() {
            const { onlyCertainDays, selectedDays } = vm.campaignModel;
            return !onlyCertainDays || selectedDays.length;
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.isEditMode = $stateParams.type === 'edit';
            vm.minDate = moment(vm.today).isBefore(vm.campaignModel.started_at)
                ? vm.today
                : vm.campaignModel.started_at;

            if (!angular.isDefined(vm.campaignModel.additional_data.testCampaign)) {
                vm.campaignModel.additional_data.testCampaign = 1;      // testCampaign default value
            }

            if ($stateParams.data) {
                vm.campaignModel = Object.assign({}, $stateParams.data, vm.campaignModel);

                try {
                    let days = JSON.parse(vm.campaignModel.show_days);
                    let time = JSON.parse(vm.campaignModel.send_time);

                    vm.campaignModel.onlyCertainDays = !((days.length === 1) && (days[0] === vm.ALL_DAYS_FLAG));
                    vm.campaignModel.selectedDays = vm.campaignModel.onlyCertainDays ? days : [];
                    vm.campaignModel.wholeDay = time.end === '24:00';

                    if (!vm.campaignModel.wholeDay) {
                        let timeArray = time.start.split(':');
                        vm.campaignModel.start_time = moment();
                        vm.campaignModel.start_time.hour(timeArray[0]);
                        vm.campaignModel.start_time.minute(timeArray[1]);

                        timeArray = time.end.split(':');
                        vm.campaignModel.end_time = moment();
                        vm.campaignModel.end_time.hour(timeArray[0]);
                        vm.campaignModel.end_time.minute(timeArray[1]);
                    } else {
                        vm.campaignModel.start_time = null;
                        vm.campaignModel.end_time = null;
                    }

                    if (vm.state.type === 'edit') {
                        const isInfinite = moment(vm.campaignModel.finished_at).isSame(vm.infinite);
                        vm.campaignModel.started_at = moment.utc(vm.campaignModel.started_at).local();
                        vm.campaignModel.finished_at = isInfinite
                            ? moment(vm.campaignModel.finished_at)
                            : moment.utc(vm.campaignModel.finished_at).local();
                        vm.campaignModel.infinite = isInfinite;
                        vm.campaignModel.sections = vm.campaignModel.locations;
                    }

                    delete vm.campaignModel.locations;
                } catch (err) { console.error(err); }
            }

            if (!vm.campaignModel.started_at) {
                vm.campaignModel.started_at = angular.copy(vm.today);
            }

            if (!vm.campaignModel.finished_at) {
                vm.campaignModel.finished_at = angular.copy(vm.infinite);
                vm.campaignModel.infinite = true;
            }
        }

        function isTestMode() {
            return !!vm.campaignModel.additional_data.testCampaign;
        }

        function setTestMode(val) {
            vm.campaignModel.additional_data.testCampaign = val;
        }

        /**
         * Infinite checkbox handler
         */
        function onChangeInfinite() {
            if (vm.campaignModel.infinite) {
                // Cache old finish value (if not infinite)
                vm.oldFinishValue =
                    !moment(vm.campaignModel.finished_at).isSame(vm.infinite)
                    && angular.copy(vm.campaignModel.finished_at);

                vm.campaignModel.finished_at = angular.copy(vm.infinite);
            } else {
                // Set default date if cache is empty or invalid, else - set cached
                vm.campaignModel.finished_at =
                    vm.oldFinishValue && moment(vm.oldFinishValue).isAfter(vm.campaignModel.started_at)
                        ? angular.copy(vm.oldFinishValue)
                        : _getDefaultFinishDate();
            }
        }

        /**
         * Getting the default finish date (start_at + 1 day)
         *
         * @return {Date}
         * @private
         */
        function _getDefaultFinishDate() {
            const startedAt = vm.campaignModel.started_at;
            const startMoment = moment(startedAt);
            return startMoment.date(startMoment.date() + 1);
        }

        /**
         * Change name watcher
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.campaignModel.name;
        }), onTitleChange);

        $scope.$watch(
            () => vm.campaignDetailsForm && vm.campaignDetailsForm.$valid,
            newValue => vm.state.canProcessNext = newValue && validateCertainDayOption()
        );
    }
}());

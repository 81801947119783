(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenDashboardDetails', {
            controller: ScreenDashboardDetailsController,
            templateUrl: '/assets/views/common/components/screen-dashboard-details/screen-dashboard-details.tpl.html',
            bindings: {
                screen: '<',
                langs: '<',
            }
        });

    function ScreenDashboardDetailsController(
        $state,
        ContentHelper,
        LocationDataService,
        Restangular,
    ) {
        const vm = this;

        const {
            getScreensAssignedContent,
        } = LocationDataService;

        const SCREEN_ASSIGNMENT_TYPES = {
            CAMPAIGNS: 'campaigns',
            CONTENT: 'content',
        };

        vm.$onInit = init;

        vm.editAssignedItem = editAssignedItem;
        vm.prepareAssignedItem = prepareAssignedItem;

        function init() {
            getAssignmentsData();
        }

        function getAssignmentsData() {
            getScreensAssignedContent(vm.screen.controllerRef)
                .then(response => {
                    const processContentItem = ContentHelper.processContentItem.bind(null, vm.langs);
                    const processCampaignItem = campaign => Restangular.restangularizeElement(null, campaign, 'campaigns');
                    const assignments = response.plain();
                    vm.screen.screenAssignments = Object.keys(assignments).reduce((processedAssignments, assignmentType) => {
                        switch (assignmentType) {
                            case SCREEN_ASSIGNMENT_TYPES.CAMPAIGNS:
                                processedAssignments[assignmentType] = assignments[assignmentType].map(processCampaignItem);
                                break;
                            case SCREEN_ASSIGNMENT_TYPES.CONTENT:
                                processedAssignments[assignmentType] = assignments[assignmentType].map(processContentItem);
                                break;
                        }
                        return processedAssignments;
                    }, {});
                })
        }

        /**
         * Edit assigned item
         * @param item
         * @param type
         */
        function editAssignedItem(item, type) {
            switch (type) {
                case SCREEN_ASSIGNMENT_TYPES.CAMPAIGNS:
                    $state.go('app.editScreenCampaign', {
                        data: item
                    });
                    break;
                case SCREEN_ASSIGNMENT_TYPES.CONTENT:
                    $state.go('app.editContent', {
                        data: item,
                    });
                    break;
            }
        }

        /**
         * Prepare assigned item
         */
        function prepareAssignedItem(item, type) {
            let title;
            switch (type) {
                case SCREEN_ASSIGNMENT_TYPES.CAMPAIGNS:
                    title = item.name;
                    break;
                case SCREEN_ASSIGNMENT_TYPES.CONTENT:
                    title = item.title[item.language_id];
                    break;
            }
            return title;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tickerContentType', {
            templateUrl: '/assets/views/content/elements/types/ticker/ticker.tpl.html',
            controller: TickerContentType,
            bindings: {
                langArray: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
                referenced: '<?' // included from page
            }
        });

    function TickerContentType($scope, $timeout, StatesFactory, MARQUEE_TYPE, TICKER_BOUNDS, PLAYLIST_TRANSITION_TYPES) {
        const vm = this;

        const MAX_TICKER_ITEM_LENGTH = 250;

        // Definition
        vm.MARQUEE_TYPE = MARQUEE_TYPE;
        vm.TICKER_BOUNDS = TICKER_BOUNDS;
        vm.TRANSITION_TYPES = PLAYLIST_TRANSITION_TYPES;
        vm.state = StatesFactory.ContentStates;
        vm.sizes = ['SMALL', 'BIG', 'HUGE'];
        vm.positions = ['TOP', 'BOTTOM'];
        vm.sources = [
            {id: 1, name: 'STATIC_TEXT', inputName: ''},
            {id: 2, name: 'RSS_FEED', inputName: 'rssurl'},
            {id: 3, name: 'HTML_TEXT_FROM_EXTERNAL_RESOURCE', inputName: 'htmlurl'}
        ];

        vm.errorMsg = null;
        vm.previousContent = {};

        vm.stripHTML = stripHTML;
        vm.setMarqueeType = setMarqueeType;

        vm.$onInit = init;

        function init() {
            setModel();

            $timeout(() => {
                validateFields();
            }, 50);
            vm.model.tickerType = 'tape';
        }

        function setModel() {
            vm.model = vm.referenced ? vm.contentData : vm.contentData.message[vm.contentData.language_id];
        }

        function onFinish(event, option) {
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    contentData: angular.copy(vm.contentData)
                });
            }
        }

        function validateFields() {
            if (!vm.contentForm.rssurl) {
                return;
            }

            if (Number(vm.model.type) === MARQUEE_TYPE.STATIC) {
                vm.contentForm.rssurl.$setValidity('url', true);
                vm.contentForm.htmlurl.$setValidity('url', true);
                validateTickersLength();
            }
        }

        function validateTickersLength() {
            if (!vm.model.fixed) {
                return;
            }
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(vm.model.source, 'text/html');
            vm.contentForm.message.$setValidity('itemLength', true);
            Array.from(htmlDoc.getElementsByTagName('p')).forEach(item => {
                if (item.innerText.length > MAX_TICKER_ITEM_LENGTH) {
                    vm.contentForm.message.$setValidity('itemLength', false);
                }
            });
        }

        /**
         * Strips tags in HTML string
         *
         * @param {string} $html
         * @returns {string}
         */
        function stripHTML($html) {
            return $html ? String($html).replace(/<[^>]+>/gm, '') : '';
        }

        function setMarqueeType(type) {
            vm.previousContent[vm.model.type] = vm.model.source;
            vm.model.type = type;
            if (vm.previousContent[type]) {
                vm.model.source = vm.previousContent[type];
            } else {
                vm.previousContent[type] = null;
                vm.model.source = null;
            }

            vm.sources.forEach(function(source) {
                if (source.id !== vm.model.type && source.id !== MARQUEE_TYPE.STATIC) {
                    vm.contentForm[source.inputName].$setValidity('url', false);
                }
            });

            validateFields();
        }

        function onLanguageChange() {
            setModel();
        }

        $scope.$watch(() => vm.model.source,
            newValue => newValue && validateFields()
        );

        $scope.$watch(() => vm.model.fixed, validateFields);

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('PoiElementToolbarController', PoiElementToolbarController);

    function PoiElementToolbarController($state, $stateParams, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.PoiContentStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = false;

        vm.cancel = cancel;
        vm.finish = finish;


        /**
         * Cancel button handler
         */
        function cancel() {
            $state.go('app.poiElements', {
                paginationData: $stateParams.paginationData
            });
        }

        /**
         * Finish button handler
         */
        function finish() {
            vm.state.finish = true;
        }
    }
})();
(function() {
    'use strict';

    angular.module('trmServices').service('ExternalLogService',
        /**
         * @param {$http} $http
         * @param {ScreenStorage} ScreenStorage
         * @param {$window} $window
         * @constructor
         */
        function(
            $http,
            ScreenStorage,
            $window,
        ) {
            return {
                log,
                logScreenReload,
            }

            /**
             * @param {string} channel
             * @param {object} data
             * @return Promise<void>
             */
            function log(channel, data) {
                data.clientTime = _getTimeFormatted();

                const uri = _generateUri(channel, data);

                return $http.get($window.LOGS_ENDPOINT + '?' + uri)
                    .catch(error => {
                        console.error('CAN NOT SEND LOGS');
                        console.error(error);
                    });
            }

            /**
             * @param {string} reason
             * @param {string} message
             * @return {Promise<void>}
             */
            function logScreenReload(reason, message = '') {
                return log('SCREEN_RELOAD', {
                    reason,
                    message,
                    ...ScreenStorage.controllerDetails(),
                    title: $window.document.title,
                });
            }

            /**
             * @param {string} channel
             * @param {Object} data
             * @return {string}
             * @private
             */
            function _generateUri(channel, data) {
                return new URLSearchParams({
                    channel,
                    ...data,
                }).toString();
            }

            /**
             * @return {string}
             * @private
             */
            function _getTimeFormatted() {
                return new Date()
                    .toISOString()
                    .replace(/:/g, '-')
            }
        });
})();

(function() {
    'use strict';

    angular.module('trmTimetableHafas').factory('TimetableHafasOptionsModel',
        function() {
            /**
             * @name TimetableHafasOptionsModel
             *
             * @extends {TimetableHafasOptions}
             */
            class TimetableHafasOptionsModel {
                /**
                 * @type {TimetableHafasElement}
                 * @private
                 */
                _timeElement;

                /**
                 * @type {TimetableHafasElement}
                 * @private
                 */
                _realTimeElement;

                /**
                 * @param {TimetableHafasOptions} options
                 */
                constructor(options) {
                    Object.assign(this, options);

                    this._setShortcuts();
                    this._setDefaultsForElements();
                }

                /**
                 * @private
                 */
                _setDefaultsForElements() {
                    // Realtime indicator
                    this._timeElement.realtimeIndicator = Boolean(this._timeElement.realtimeIndicator);
                    this._realTimeElement.realtimeIndicator = [undefined, true]
                        .includes(this._realTimeElement.realtimeIndicator)

                    // Realtime indicator type
                    if (!this._timeElement.realtimeIndicatorType) {
                        this._timeElement.realtimeIndicatorType = 'icon';
                    }

                    if (!this._realTimeElement.realtimeIndicatorType) {
                        this._realTimeElement.realtimeIndicatorType = 'icon';
                    }

                    // Delayed colors
                    if (!this._timeElement.delayedHighlightColors) {
                        this._timeElement.delayedHighlightColors = {
                            default: 'red',
                            night: 'red',
                        }
                    }

                    if (!this._realTimeElement.delayedHighlightColors) {
                        this._realTimeElement.delayedHighlightColors = {
                            default: 'red',
                            night: 'red',
                        }
                    }

                    if (!this._destinationElement.textPlacement) {
                        this._destinationElement.textPlacement = 'center';
                    }
                }

                _setShortcuts() {
                    this.elements.forEach(item => {
                        switch (item.type) {
                            case 'time':
                                this._timeElement = item;
                                break;
                            case 'direction':
                                this._destinationElement = item;
                                break;
                            case 'realTime':
                                this._realTimeElement = item;
                                break;
                        }
                    })
                }
            }

            return TimetableHafasOptionsModel;
        });
})();

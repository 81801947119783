(function() {
    'use strict';

    angular.module('beacon.app')
        .component('editHabit', {
            templateUrl: '/assets/views/campaign/habit/edit/edit-habit.tpl.html',
            controller: EditHabitController,
        });

    function EditHabitController(
        $scope,
        $rootScope,
        $stateParams,
        $timeout,
        $state,
        Restangular,
        HabitService,
        StorageFactory,
        habitEditState,
        ModelsFactory,
        HABIT_TYPES
    ) {
        const vm = this;
        const { Habit } = ModelsFactory;

        // public properties
        vm.state = habitEditState;
        vm.habitModel = null;


        vm.$onInit = init;

        // private methods

        function init() {
            vm.state.type = $stateParams.type;


            switch (vm.state.type) {
                case 'new':
                    // NOTE: when content copy
                    if ($stateParams.data) {
                        vm.habitModel = $stateParams.data;
                        vm.state.view++;
                        vm.state.copy = true;
                    } else {
                        vm.habitModel = new Habit;
                    }
                    break;

                case 'edit':
                    vm.habitModel = $stateParams.data;
                    vm.state.view++;
                    break;
            }

            bindWatchers();
        }


        /**
         * Save/finish action callback
         */
        function onFinish() {
            const habit = vm.habitModel;
            const { timeParams } = habit;
            const ALL_DAYS_FLAG = 9;

            switch (habit.type) {
                case HABIT_TYPES.AREAS:
                    habit.typeRef = habit.selectedGeofences
                        .map(geofence => geofence.externalId)
                        .join(',');
                    break;
                case HABIT_TYPES.PLACES:
                    habit.typeRef = habit.markers
                        .map(marker => marker.stringify())
                        .join(';');
                    break;
            }

            timeParams.hourOfDayStart = moment(timeParams.startTime).format('HH:mm');
            timeParams.hourOfDayEnd = moment(timeParams.endTime).format('HH:mm');

            delete timeParams.startTime;
            delete timeParams.endTime;


            timeParams.daysOfWeek = timeParams.onlyCertainDays
                ? timeParams.selectedDays
                : [ALL_DAYS_FLAG];

            delete timeParams.selectedDays;
            delete timeParams.onlyCertainDays;

            const formData = new FormData();
            formData.append('habit', JSON.stringify(habit));

            const success = () => $state.go('app.habit');
            const error = console.error.bind(console);

            (vm.state.type === 'new'
                ? HabitService.create(formData)
                : HabitService.update(habit, formData)
            ).then(success).catch(error);
        }

        /**
         * Bind watchers
         */
        function bindWatchers() {
            $scope.$watch(
                () => vm.state.finish,
                newValue => newValue && onFinish()
            );
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkProfileConfigs', {
            templateUrl: '/assets/views/share-park/customers/profile-configs/profile-configs.tpl.html',
            controller: ProfileConfigsController
        });

    function ProfileConfigsController(
        $state,
        PermissionsService,
        PopupService,
        ShareParkDataService,
        ShareParkHelperService,
        UtilitiesService,
        ProfilesHelper,
    ) {
        const vm = this;

        const ACTIVE_STATUS = 1;
        const INACTIVE_STATUS = 2;
        const DELETED_STATUS = 3;

        const { actions, generateProfileConfigsPermission } = PermissionsService;
        const { getProfileConfigs } = ShareParkDataService;
        const { getUserProfiles } = ShareParkDataService;
        const { decodeCustomerProfile } = ProfilesHelper;

        vm.$onInit = init;

        vm.profileConfigs = [];
        vm.visibleProfileConfigs = [];
        vm.updateCounter = 0;
        vm.profiles = [];
        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'config-title',
                    width: '20',
                    title: listItem => listItem.profileConfig.name,
                },
                {
                    name: 'PROFILE_EMAIL',
                    class: '',
                    width: '30',
                    title: listItem => listItem.profileConfig.profileEmail,
                },
                {
                    name: 'RELATED_PROFILE',
                    class: '',
                    width: '30',
                    title: listItem => getProfileName(listItem.profileConfig.profileRef),
                },
                {
                    name: 'STATUS',
                    class: '',
                    width: '10',
                    translate: true,
                    title: listItem => {
                        switch (listItem.profileConfig.status) {
                            case ACTIVE_STATUS:
                                return 'ACTIVE';
                            case INACTIVE_STATUS:
                                return 'INACTIVE';
                            case DELETED_STATUS:
                                return 'DELETED';
                            default: 
                                return 'INACTIVE';
                        }
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteProfileConfig,
                        permissionAction: actions.delete,
                        isVisible,
                    },
                    {
                        class: 'editBtn',
                        callback: editProfileConfig,
                        permissionAction: actions.modify,
                        isVisible,
                    },
                    {
                        class: item => {
                            switch (item.profileConfig.status) {
                                case ACTIVE_STATUS:
                                    return 'block-btn';
                                case INACTIVE_STATUS:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeProfileConfigStatus,
                        isVisible,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
            additionalData: {
                getProfileName
            },
        }

        vm.addProfileConfig = addProfileConfig;

        function init() {
            getProfileConfigs()
                .then(response => {
                    vm.profileConfigs = response.plain()
                        .map(profileConfig => ({profileConfig}));

                    vm.updateCounter++;
                })

            getUserProfiles()
                .then(response => {
                    vm.profiles = response.plain()
                        .map(profile => decodeCustomerProfile(profile));
                })
        }

        function isVisible(btn, item) {
            return item.profileConfig.status !== DELETED_STATUS;
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.visibleProfileConfigs = UtilitiesService.getArrayPage(vm.profileConfigs, page, count);
        }

        function addProfileConfig() {
            $state.go('app.addShareParkProfileConfig');
        }

        function getProfileName(id) {
            if (vm.profiles) {
                return vm.profiles.find(profile => profile.id === id) && vm.profiles.find(profile => profile.id === id).nameLabel;
            }
        }

        /**
         * Edit profile
         * @param $event
         * @param item
         */
        function editProfileConfig($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkProfileConfig', { data: item.profileConfig });
        }

        /**
         * Delete profile
         * @param $event
         * @param item
         */
        function deleteProfileConfig($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_PROFILE_CONFIGURATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                const formData = new FormData();
                const profileConfig = angular.copy(item.profileConfig);

                profileConfig.status = DELETED_STATUS;

                formData.append('profileConfig', angular.toJson(profileConfig));
                ShareParkDataService.setProfileConfig(formData).then(() => {
                    init();
                })
            });
        }

        function changeProfileConfigStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const ACTIVE_STATUS = 1;

            const formData = new FormData();
            const profileConfig = angular.copy(item.profileConfig);

            profileConfig.status = ACTIVE_STATUS === profileConfig.status
                ? INACTIVE_STATUS
                : ACTIVE_STATUS;

            formData.append('profileConfig', angular.toJson(profileConfig));

            ShareParkDataService.setProfileConfig(formData).then(() => {
                init();
            });
        }

        function generatePermissionName(button) {
            return generateProfileConfigsPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('ratingStars', ratingStars);

    function ratingStars() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/rating-stars/rating-stars.tpl.html',
            replace: true,
            controller: RatingStarsController,
            controllerAs: 'ratingStarsController',
            bindToController: true,
            scope: {
                rating: '=',
                maxRating: '<?'
            }
        };
    }

    function RatingStarsController($scope) {
        const vm = this;

        // public methods
        vm.getRatingArray = getRatingArray;
        vm.changeRating = changeRating;

        /**
         * Forms dummy array to display rating stars
         *
         * @returns {Array}
         */
        function getRatingArray() {
            return new Array(vm.maxRating || vm.rating || 0);
        }

        function changeRating(value) {
            if (vm.maxRating) {
                vm.rating = value;
            }
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('quizDescriptionTab', {
            templateUrl: '/assets/views/content/elements/types/quiz/tabs/description/description.tpl.html',
            controller: QuizDesriptionTab,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<'
            }
        });

    function QuizDesriptionTab($scope, StatesFactory) {
        const vm = this;
        vm.state = StatesFactory.QuizStates;

        function bindWatchers() {
            $scope.$watch(angular.bind(vm, function() {
                return vm.contentData.message.quiz.type;
            }), function() {
                vm.state.canNext = !!vm.contentData.message.quiz.type && vm.contentForm.$valid;
            });

            $scope.$watch(angular.bind(vm, function() {
                return vm.contentForm.$valid;
            }), function() {
                vm.state.canNext = !!vm.contentData.message.quiz.type && vm.contentForm.$valid;
            });
        }

        bindWatchers();

    }
}());

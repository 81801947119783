(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewMobileCampaignController', NewMobileCampaignController);

    function NewMobileCampaignController(
            $scope,
            $state,
            $stateParams,
            campaignMobileState,
            ModelsFactory,
            CampaignDataService,
            StorageFactory,
            CAMPAIGN_TYPES,
            MOBILE_CAMPAIGN_INFINITE_DATE
        ) {
        const vm = this;

        // public properties
        vm.state = campaignMobileState;
        vm.campaignModel = new ModelsFactory.NewCampaign();
        vm.ALL_DAYS_FLAG = 9;

        init();

        /**
         * View change callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onViewChange(newValue, oldValue) {
            switch (newValue) {
                case vm.state.views.CONTENT:
                    vm.state.canProcessNext = !!vm.campaignModel.contents.length;
                    break;

                case vm.state.views.SECTIONS:
                    vm.state.canProcessNext = !!vm.campaignModel.sections.length || !vm.campaignModel.useBeacons;
                    break;

                default:
                    break;
            }
        }

        /**
         * Returns audiences string
         *
         * @param {object} audiencesObject
         *
         * @return {string}
         */
        function getAudiencesString(audiencesObject) {
            if (!_.isObject(audiencesObject)) {
                return "";
            }

            return _(audiencesObject)
                    .filter(audienceGroup => _.isArray(audienceGroup) && audienceGroup.length)
                    .map(audienceGroup => _.filter(audienceGroup, value => value).join(','))
                    .value().join("|");
        }

        /**
         * Finishing of creation/edition campaign callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onFinish(newValue, oldValue) {
            if (newValue) {
                const {
                    name,
                    description,
                    start_time,
                    end_time,
                    started_at,
                    finished_at,
                    selectedDays,
                    wholeDay,
                    onlyCertainDays,
                    additional_data
                } = vm.campaignModel;

                let formData = new FormData();
                let visibleDays = onlyCertainDays ? selectedDays : [ vm.ALL_DAYS_FLAG ];
                let sendTime = {
                    start: !wholeDay ? moment(start_time).format('HH:mm') : '00:00',
                    end: !wholeDay ? moment(end_time).format('HH:mm') : '24:00'
                };

                const finishedAtValue = moment(finished_at).isSame(MOBILE_CAMPAIGN_INFINITE_DATE)
                    ? moment(finished_at)
                    : moment(finished_at).utc();

                let campaignData = {
                    name,
                    description,
                    started_at: moment(started_at).utc().format('YYYY-MM-DD HH:mm:ss'),
                    finished_at: finishedAtValue.format('YYYY-MM-DD HH:mm:ss'),
                    audiences: angular.isObject(vm.campaignModel.audiencesData) ?
                        JSON.stringify(vm.campaignModel.audiencesData) : (vm.campaignModel.audiencesData || ''),
                    contents: vm.campaignModel.contents.map((content) => content.id),
                    locations: vm.campaignModel.useBeacons
                        ? vm.campaignModel.sections.map(section => section.locationId)
                        : [],
                    campaign_type: CAMPAIGN_TYPES.MOBILE,
                    send_time: JSON.stringify(sendTime),
                    show_days: JSON.stringify(visibleDays),
                    additional_data: JSON.stringify(additional_data)
                };

                for (let prop in campaignData) {
                    if (campaignData.hasOwnProperty(prop)) {
                        formData.append(prop, campaignData[prop]);
                    }
                }

                if (vm.state.type === 'new') {
                    CampaignDataService.create(formData)
                        .then(() => {
                            $state.go('app.campaign', {
                                paginationData: $stateParams.paginationData
                            });
                        })
                        .catch((err) => { throw err; });
                } else if (vm.state.type === 'edit') {
                    CampaignDataService.campaign(vm.campaignModel.id)
                        .then((campaign) => {
                            CampaignDataService.update(campaign, formData)
                                .then((campaign) => {
                                    $state.go('app.campaign', {
                                        paginationData: $stateParams.paginationData
                                    });
                                })
                                .catch((err) => { throw err; });
                        });

                }
            }
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.STORAGE = new StorageFactory.Storage('MobileCampaign', true);

            if ($stateParams.data) {
                vm.campaignModel = Object.assign({}, vm.campaignModel, $stateParams.data);
            }
        }

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.state.view;
        }), onViewChange);

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.state.finish;
        }), onFinish);
    }
}());

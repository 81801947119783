(function() {
    'use strict';

    angular.module('dynamicContent')
        .component('dynamicContent', {
            templateUrl: '/assets/views/dynamic-content/dynamic-content.tpl.html',
            controller: DynamicContent,
            bindings: {
                content: '<',
                isStopped: '<',
                languages: '<',
                preview: '<',
            }
        });

    function DynamicContent(
        $scope,
        $element,
        $timeout,
        DayNightService,
        DYNAMIC_CONTENT_TEMPLATES,
        DYNAMIC_CONTENT_IMAGE_POSITION,
        DYNAMIC_CONTENT_IMAGE_SCALING,
        DYNAMIC_CONTENT_IMAGE_REPEAT,
    ) {
        const vm = this;
        const element = $element[0].children[0].children[0];

        const STOPPED_INTERVAL = -1;
        const MAX_FONT_SIZE = 70;
        const MIN_FONT_SIZE = 10;
        let zoom;

        vm.colorScheme = 'default';
        vm.fontColor = {};

        vm.class = templateClass;
        vm.style = style;
        vm.updateFontSize = updateFontSize;
        vm.onVideoReady = onVideoReady;
        vm.onVideoEnded = onVideoEnded;
        vm.$onInit = init;

        function init() {
            zoom = getZoom();
            setValues();
            setWatchers();
            updateFontSize();
        }

        function setValues() {
            vm.model = parseJsonFields(vm.content);
            vm.data = vm.model.data;
            vm.interval = vm.isStopped ? STOPPED_INTERVAL : slideInterval();
            vm.flags = generateFlags();
            setLanguages();
            _initColorScheme();
            _setFontColors();
        }

        /**
         * Parse JSON fields for content object (if they are string type)
         *
         * @param content
         * @returns {*}
         */
        function parseJsonFields(content) {
            const jsonProps = ['title', 'message', 'data'];
            jsonProps.forEach(prop => {
                const val = content[prop];
                if (angular.isString(val)) {
                    content[prop] = angular.fromJson(val)
                }
            });
            return content;
        }

        /**
         * Generates an object with language flags
         *
         * @returns {object}
         */
        function generateFlags() {
            const flags = {};

            vm.languages.forEach(lang => {
                flags[lang.id] = `https://flagsapi.com/${lang.countryflags_code.toUpperCase()}/shiny/64.png`;
            });

            return flags;
        }

        /**
         * Returns template class
         *
         * @param id
         * @returns {*|string}
         */
        function templateClass(id) {
            const classes = [];
            vm.model.image_src && classes.push('--image');

            const template = DYNAMIC_CONTENT_TEMPLATES.find(template => template.id === id);
            template && classes.push(template.class);

            vm.model.data.slidingLanguage && classes.push('--carousel');
            vm.model.data.fullPageSliding && classes.push('--full-page-sliding');
            isVertical() && classes.push('--vertical');

            return classes.join(' ');
        }

        /**
         * Get scale factor
         */
        function getZoom() {
            const size = element.clientHeight + element.clientWidth;
            const originSize = 1920 + 1080;
            return size / originSize || 1;
        }

        function isVertical() {
            return element.clientHeight / element.clientWidth > 1;
        }

        /**
         * Get sliding interval
         *
         * @returns {number}
         */
        function slideInterval() {
            const SECOND = 1000;
            return vm.data.slidingTimeout * SECOND;
        }

        /**
         * Returns a style object for element
         *
         * @returns {object}
         */
        function style() {
            const style = {
                'transform': `scale(${zoom})`,
                'transform-origin': '0 0',
                'width': 100 / zoom + '%',
                'height': 100 / zoom + '%',
                'background-color': vm.colorScheme === 'default'
                    ? vm.data.backgroundColor
                    : vm.data.backgroundColorNight,
            };

            // The customer says: "if you have any image -> show image instead of no image"
            const dayImage = vm.model.image_src || vm.data.imageNightSrc;
            const nightImage = vm.data.imageNightSrc || vm.model.image_src;

            if (vm.colorScheme === 'night' && nightImage) {
                style['background-image'] = `url("${nightImage}")`;
                style['background-color'] = 'unset';
            }

            if (vm.colorScheme === 'default' && dayImage) {
                style['background-image'] = `url("${dayImage}")`;
                style['background-color'] = 'unset';
            }

            if (vm.data.imagePosition) {
                style['background-position'] = DYNAMIC_CONTENT_IMAGE_POSITION.find(
                    item => item.id === vm.data.imagePosition
                ).style;
            }

            if (vm.data.imageScaling) {
                style['background-size'] = DYNAMIC_CONTENT_IMAGE_SCALING.find(
                    item => item.id === vm.data.imageScaling
                ).style;
            }

            if (vm.data.imageRepeat) {
                style['background-repeat'] = DYNAMIC_CONTENT_IMAGE_REPEAT.find(
                    item => item.id === vm.data.imageRepeat
                ).style;
            }
            return style;
        }

        /**
         * Update font size (scaling to fit the screen)
         */
        function updateFontSize() {
            if (vm.preview) {
                return;
            }

            $timeout(() => {
                if (vm.isStopped) {
                    return;
                }

                const wrapper = element.querySelector('.dynamic-content__wrap');
                if (!wrapper) {
                    return;
                }
                const needsScaleDown = () => {
                    const elementHeight = getInnerHeight(element);
                    const wrapperHeight = parseInt(getComputedStyle(wrapper).height);
                    return wrapperHeight > elementHeight;
                };

                let currentFontSize = MAX_FONT_SIZE;
                do {
                    wrapper.style.fontSize = currentFontSize-- + 'px';
                } while (needsScaleDown() || currentFontSize === MIN_FONT_SIZE);
            }, 50);
        }

        /**
         * Get inner height of an element
         *
         * @param element
         * @returns {number}
         */
        function getInnerHeight(element) {
            const style = getComputedStyle(element);
            return parseInt(style.height) - parseInt(style.paddingTop) - parseInt(style.paddingBottom);
        }

        /**
         * Set language values to the view
         */
        function setLanguages() {
            const availableLanguages = vm.languages.filter(lng =>
                Object.keys(vm.model.title).indexOf(lng.id.toString()) !== -1
            );

            if (availableLanguages.length) {
                vm.lngFirst = availableLanguages[0].id;
                const lngList = availableLanguages.map(lng => lng.id);
                vm.otherLanguages = vm.data.fullPageSliding
                    ? lngList
                    : lngList.slice(1);
            }
        }

        /**
         * Set font colors for title and message
         */
        function _setFontColors() {
            vm.fontColor = {
                title: vm.colorScheme === 'default'
                    ? vm.data.titleColor
                    : vm.data.titleColorNight,
                message: vm.colorScheme === 'default'
                    ? vm.data.messageColor
                    : vm.data.messageColorNight,
                background: vm.colorScheme === 'default'
                    ? vm.data.backgroundColor
                    : vm.data.backgroundColorNight,
            };
        }

        function _initColorScheme() {
            const isAuto = vm.data.colorScheme === 'auto';

            vm.colorScheme = isAuto
                ? DayNightService.currentColorScheme()
                : vm.data.colorScheme;

            if (isAuto) {
                $scope.$on(
                    'DayNightService@change',
                    /**
                     * @param event
                     * @param {'default'|'night'} colorsScheme
                     */
                    (event, colorsScheme) => {
                        vm.colorScheme = colorsScheme;
                        _setFontColors();
                    });
            }
        }

        function onVideoReady(duration) {}

        function onVideoEnded() {}

        /**
         * Set watchers
         */
        function setWatchers() {
            $scope.$watch(
                () => vm.isStopped,
                isStopped => {
                    if (isStopped) {
                        vm.activeSlide = 0;
                        vm.interval = STOPPED_INTERVAL;
                    } else {
                        vm.interval = slideInterval();
                        updateFontSize();
                    }
                }
            );

            $scope.$watch(
                () => vm.activeSlide,
                () => updateFontSize(),
            );

            $scope.$watch(
                () => vm.content,
                () => setValues() && updateFontSize(),
                true
            );

            $scope.$watch(
                () => vm.model.title,
                () => setLanguages(),
                true
            );
        }
    }
})();

(function() {
    'use strict';

    angular.module('lineRoute')
        .service('Canvas', () => {

            class Canvas {
                constructor(element) {
                    if (!element) {
                        throw new Error('No element');
                    }

                    this.element = element;
                    this.context = this.element.getContext('2d');
                };

                /**
                 * Draw object on canvas
                 *
                 * @param object
                 */
                add(object) {
                    object.draw(this.context);
                }
            }

            return Canvas;
        });
})();
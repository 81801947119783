(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('OnboardingContentAddController', OnboardingContentAddController);

    function OnboardingContentAddController($q,
                                            $uibModalInstance,
                                            ContentDataService,
                                            LanguageService,
                                            SegmentDataService,
                                            OnboardingHelper,
                                            ContentHelper,
                                            PopupService,
                                            CONTENT_TYPES,
                                            SEGMENT_TYPES,
                                            data,
                                            GoogleMapsUtilitiesService,
                                            ) {
        const vm = this;

        vm.CONTENT_TYPE_SEGMENT = OnboardingHelper.CONTENT_TYPE_SEGMENT;

        vm.listConfig = {
            columns: [
                {
                    name: 'Title',
                    class: 'onboarding-add__content-title',
                    width: '50',
                    title: item => OnboardingHelper.getTitle(item)
                },
                {
                    name: 'Content type',
                    class: 'onboarding-add__content-type',
                    width: '40',
                    title: item => item.content_type.name
                }
            ],
            additionalData: {
                itemMapCallback: GoogleMapsUtilitiesService.showMap,
                getMessageText: ContentHelper.getMessageText,
            },
        };

        vm.filter = {
            contentTypes: {},
        };

        vm.$onInit = init;
        vm.selectContent = selectContent;
        vm.isSelected = isSelected;
        vm.setContents = setContents;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.getAccordionTemplateName = OnboardingHelper.getAccordionTemplateName;

        /**
         * Controller initialization (load and process contents)
         */
        function init() {
            const contentTypes = [
                CONTENT_TYPES.INFOTAINMENT,
                CONTENT_TYPES.FEEDBACK,
                CONTENT_TYPES.QUIZ,
            ];

            const PROMISE_INDEX_CONTENTS = 0;
            const PROMISE_INDEX_LANGUAGES = 1;
            const PROMISE_INDEX_CONTENT_TYPES = 2;
            const PROMISE_INDEX_PREFERENCES_SEGMENTS = 3;

            vm.dataLoading = true;

            $q.all([
                ContentDataService
                    .contents({contentTypeId: contentTypes.join(',')})
                    .catch(console.error.bind(console)),
                LanguageService
                    .getLanguages()
                    .catch(console.error.bind(console)),
                ContentDataService
                    .contentTypes()
                    .catch(console.error.bind(console)),
                SegmentDataService
                    .getSegments({
                        filter: {
                            types: [SEGMENT_TYPES.PREFERENCES]
                        }
                    })
                    .catch(console.error.bind(console)),
            ]).then(response => {
                vm.langArrayAll = response[PROMISE_INDEX_LANGUAGES].plain();
                vm.contentTypesAll = _processContentTypesResponse(response[PROMISE_INDEX_CONTENT_TYPES]);

                const segments = _processSegmentsResponse(response[PROMISE_INDEX_PREFERENCES_SEGMENTS]);
                const contents = _processContentElementsResponse(response[PROMISE_INDEX_CONTENTS]);

                vm.contentElementsAll = [].concat(segments, contents);

                setContents();
                vm.dataLoading = false;
            });
        }

        /**
         * Returns array of contents
         *
         * @param response
         * @return {*}
         * @private
         */
        function _processContentElementsResponse(response) {
            if (!response) {
                return [];
            }

            return response.plain().map(
                item => OnboardingHelper.prepareContentElement(item, vm.langArrayAll)
            );
        }

        /**
         * Returns array of prepared segments
         *
         * @param response
         * @return {array}
         * @private
         */
        function _processSegmentsResponse(response) {
            if (!response) {
                return [];
            }

            return response.plain().map(item => {
                const segment = OnboardingHelper.prepareSegment(item, vm.langArrayAll);
                segment.message[segment.language_id].preferences.forEach(item => {
                    item.active = !!item.code;
                });
                return segment;
            });
        }

        /**
         * Returns array of content types with segment type
         *
         * @param response
         * @return {array}
         * @private
         */
        function _processContentTypesResponse(response) {
            if (!response) {
                return [];
            }
            const contentTypes = response.plain();
            contentTypes.push(OnboardingHelper.CONTENT_TYPE_SEGMENT);
            return contentTypes;
        }

        /**
         * Submit content selection
         */
        function submit() {
            if (!vm.selectedContent) {
                PopupService.showAlertPopup({
                    text: 'PLEASE_SELECT_CONTENT_ELEMENT',
                    okButtonText: 'OK'
                });
            } else {
                data.addContentCallback(vm.selectedContent);
                $uibModalInstance.dismiss('cancel');
            }
        }

        /**
         * Cancel button handler
         */
        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Filter contents and set to scope
         */
        function setContents() {
            // Show content types with at least one content element
            vm.contentTypes = vm.contentTypesAll.filter(contentType =>
                vm.contentElementsAll.find(
                    content => content.content_type_id === contentType.id
                ));

            const contentTypesChecked = Object.keys(vm.filter.contentTypes)
                .map(key => Number(key))
                .filter(key => vm.filter.contentTypes[key] === true);

            vm.items = vm.contentElementsAll.filter(content =>
                !contentTypesChecked.length || contentTypesChecked.includes(content.content_type_id));
        }

        /**
         * Make content selected to show the preview and make available submitting
         *
         * @param contentElement
         */
        function selectContent(contentElement) {
            vm.selectedContent = contentElement;
        }

        /**
         * Check if content element is selected
         *
         * @param contentElement
         * @return {boolean}
         */
        function isSelected(contentElement) {
            return vm.selectedContent && vm.selectedContent.id === contentElement.id;
        }
    }
})();

(function() {
    'use strict';

    angular.module('trmDigitalDisplayStaticHeader')
        .constant('HEADER_FOOTER_DEFAULTS', {
            PADDING_LEFT: '20px',
            PADDING_RIGHT: '20px'
        })
    ;
}());

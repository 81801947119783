(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.factory('locationTabState', function() {
        return {
            views: {
                LOCATIONS: {index: 0,
                    tabs: {
                        GEOFENCES: {index: 0,
                            subtabs: {
                                GEOFENCE_ITEMS: {index: 0},
                                GEOFENCE_GROUPS: {index: 1},
                            },
                        },
                        SCREENS: {index: 1,
                            subtabs: {
                                SCREEN_ITEMS: {index: 0},
                                SCREEN_GROUPS: {index: 1},
                            },
                        },
                        BEACONS: {index: 2,
                            subtabs: {
                                BEACON_ITEMS: {index: 0},
                                BEACON_GROUPS: {index: 1},
                            },},
                    },
                },
                RELATED_CAMPAIGNS: {index: 1},
            },
        };
    });

    app.controller('LocationToolbarController', LocationToolbarController);

    function LocationToolbarController(
        $translate,
        locationTabState
    ) {
        const vm = this;

        // public methods
        vm.next = next;
        vm.back = back;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;

        // public properties
        vm.state = locationTabState;
        vm.state.view = vm.state.views.LOCATIONS.index;
        vm.state.canProcessNext = false;
        vm.tabsText = ['LOCATION', 'RELATED_CAMPAIGNS'];
        vm.getTabsText = viewIndex => {
            return vm.tabsText[viewIndex];
        };

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view++;
                vm.state.canProcessNext = false;
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.view--;
            vm.state.canProcessNext = false;
        }

        /**
         * Sets selected tab
         *
         * @param {number} tab - tab number
         */
        function goToTab(tab) {
            if (vm.state.view > tab) {
                vm.state.view = tab;
                vm.state.canProcessNext = false;
            }
        }

        /**
         * Return css class depending of tab state
         *
         * @param {number} tab - tab number
         * @returns {string} - css class for tab
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }
    }
}());

(function() {
    'use strict';

    angular.module('scMeetings')
        .filter('html', function ($sce) {
            return function (value) {
                return $sce.trustAsHtml('' + value);
            };
        });
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TagHelper', TagHelperController);

    function TagHelperController(TagService) {

        return {
            loadTags,
        };

        /**
         * Loads all tags from database with adding lowercase name to each object
         *
         * @returns {Promise}
         */
        function loadTags() {
            return TagService.get()
                .then(response => {
                    return response.plain().map(tag => {
                        tag._lowername = tag.name.toLowerCase();
                        return tag;
                    });
                });
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('BillingConfigurationDataService', BillingConfigurationDataService);

    function BillingConfigurationDataService(
        Restangular,
    ) {
        return {
            getBillingConfigurations,
            createBillingConfiguration,
            updateBillingConfiguration,
            deleteBillingConfiguration,
            exportBillnigConfigurations,
            getBillingTemplates,
        };

        function getBillingConfigurations(data) {
            return Restangular.all('sharePark/billing-configuration').getList(data);
        }

        function createBillingConfiguration(data) {
            return Restangular.all('sharePark/billing-configuration')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteBillingConfiguration(id) {
            return Restangular.one('sharePark/billing-configuration', id).remove();
        }

        function updateBillingConfiguration(billingConfiguration, data) {
            data.append('_method', 'PUT');
            return billingConfiguration.customPOST(data, '', {}, { 'Content-Type': undefined });
        }
        
        function exportBillnigConfigurations() {
            return Restangular.one('sharePark/export-billing-configurations').get()
        }

        function getBillingTemplates() {
            return Restangular.one('sharePark/billing-configuration/templates').get()
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timeWindowsPicker', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/new/time-windows-picker/time-windows-picker.tpl.html',
            controller: TimeWindowsPickerController,
            bindings: {
                timeWindowsData: '<',
                timeWindowsRef: '<',
                onTimeWindowChange: '<',
                langCode: '<',
                form: '<',
            }
        });

    function TimeWindowsPickerController(
        PricingDataService,
        WEEK_DAYS,
    ) {
        const vm = this;

        const HOLIDAYS_DEFAULT_ID = 8;
        const DEFAULT_TIME_WINDOW_STATUS = 1;
        vm.definedTimeWindows = [];
        vm.openingHour = {};

        vm.$onInit = init;

        vm.onCheckboxClick = onCheckboxClick;
        vm.isCheckboxChecked = isCheckboxChecked;
        vm.onTimeWindowsSourceChange = onTimeWindowsSourceChange;
        vm.onTimeWindowsSelect = onTimeWindowsSelect;
        vm.onOpeningTimeChange = onOpeningTimeChange;
        vm.onTimeWindowsChange = onTimeWindowsChange;

        vm.WEEK_DAYS = [ ...WEEK_DAYS, {
            name: 'HOLIDAYS',
            id: HOLIDAYS_DEFAULT_ID
        }];

        /**
         * Initialization method
         */
        function init() {
            vm.timeWindow = decodeTimeWindows(vm.timeWindowsData);

            if (angular.isNumber(vm.timeWindowsRef)) {
                vm.useExistingTimeWindows = true;
                vm.sourceTimeWindowsRef = vm.timeWindowsRef;
            }

            PricingDataService.getTimeWindows().then(response => {
                vm.definedTimeWindows = response.plain().map(decodeTimeWindows);
                vm.useExistingTimeWindows && onTimeWindowsSelect();
            });
        }

        function onTimeWindowsSourceChange() {
            if (!vm.useExistingTimeWindows) {
                return vm.onTimeWindowChange(encodeTimeWindows(vm.timeWindow));
            }

            onTimeWindowsSelect();
        }

        function onTimeWindowsSelect() {
            const selectedTimeWindows = vm.definedTimeWindows.find(timeWindows =>
                timeWindows.id === vm.sourceTimeWindowsRef
            );

            if (!selectedTimeWindows) {
                return vm.onTimeWindowChange();
            }

            vm.timeWindow = angular.copy(selectedTimeWindows);
            vm.onTimeWindowChange(encodeTimeWindows(vm.timeWindow), vm.sourceTimeWindowsRef);
        }

        function onTimeWindowsChange() {
            vm.onTimeWindowChange(
                encodeTimeWindows(vm.timeWindow),
                vm.useExistingTimeWindows ? vm.sourceTimeWindowsRef : null
            );
        }

        /**
         * Checks if checkbox is checked
         * @param dayId
         * @return {boolean}
         */
        function isCheckboxChecked(dayId) {
            return !!vm.timeWindow.parameters[dayId];
        }

        /**
         * Handler on checkbox click
         * @param dayId
         */
        function onCheckboxClick(dayId) {
            if (!vm.timeWindow.parameters[dayId]) {
                vm.timeWindow.parameters[dayId] = {};
            } else {
                delete vm.timeWindow.parameters[dayId];
            }

            vm.onTimeWindowChange(encodeTimeWindows(vm.timeWindow));
        }

        function onOpeningTimeChange() {
            vm.onTimeWindowChange(encodeTimeWindows(vm.timeWindow));
        }

        function decodeTimeWindows(timeWindow) {
            if (!timeWindow) {
                return {
                    name: '',
                    parameters: {},
                    status: DEFAULT_TIME_WINDOW_STATUS
                }
            }


            const params = angular.isObject(timeWindow.parameters)
                ? timeWindow.parameters
                : angular.fromJson(timeWindow.parameters);
            const decodedParams = {};

            Object.keys(params).forEach(key => {
                const [endStr, startSrt] = params[key].split('-');

                decodedParams[key] = {
                    timeClose: moment(endStr, 'HH:mm'),
                    timeOpen: moment(startSrt, 'HH:mm'),
                };
            });

            return {
                name: timeWindow.name,
                id: timeWindow.id,
                parameters: decodedParams,
                description: angular.isObject(timeWindow.description)
                    ? timeWindow.description
                    : angular.fromJson(timeWindow.description),
                status: timeWindow.status || DEFAULT_TIME_WINDOW_STATUS
            }
        }

        function encodeTimeWindows(timeWindow) {
            const encodedParameters = {};
            Object.keys(timeWindow.parameters).forEach(key => {
                const params = timeWindow.parameters[key];

                if (params.timeClose && params.timeOpen) {
                    const timeClose = moment.isMoment(params.timeClose) ? params.timeClose : moment(params.timeClose);
                    const timeOpen = moment.isMoment(params.timeOpen) ? params.timeOpen : moment(params.timeOpen);
                    encodedParameters[key] = `${timeClose.format('HH:mm')}-${timeOpen.format('HH:mm')}`;
                }
            });

            return {
                id: timeWindow.id || null,
                name: timeWindow.name,
                parameters: encodedParameters,
                description: timeWindow.description,
                status: timeWindow.status || DEFAULT_TIME_WINDOW_STATUS
            }
        }
    }
})();
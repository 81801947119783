(function () {
    'use strict';

    angular.module('beacon.app').component('newSponsor', {
        templateUrl: '/assets/views/loyalty/sponsor/new/new-sponsor.tpl.html',
        controller: SponsorFormController
    });

    /**
     * @property {form.FormController} newSponsorForm
     *
     * @param $scope
     * @param $state
     * @param $stateParams
     * @param StatesFactory
     * @param ModelsFactory
     * @param SponsorDataService
     * @param ImageUploadFactory
     *
     * @constructor
     */
    function SponsorFormController(
        $scope,
        $state,
        $stateParams,
        StatesFactory,
        ModelsFactory,
        SponsorDataService,
        ImageUploadFactory,
        $q,
    ) {
        const vm = this;

        vm.state = StatesFactory.SponsorStates.refresh();
        vm.state.type = $stateParams.type;
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.addNewShop = addNewShop;
        vm.onEditorModeChange = onEditorModeChange;
        vm.onIcon = onIcon;
        vm.onIconDelete = onIconDelete;

        vm.loaderConfig = {
            minWidth: 200,
            minHeight: 200,
            center: true,
            fullInfo: true,
        };

        vm.shopListItems = [];

        vm.shopsConfig = {
            columns: [
                {
                    name: 'POSTAL_CODE',
                    width: '25',
                    title: listItem => listItem.shop.postalCode,
                },
                {
                    name: 'CITY',
                    width: '25',
                    title: listItem => listItem.shop.city,
                },
                {
                    name: 'STREET',
                    width: '25',
                    title: listItem => listItem.shop.street,
                },
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteShop,
                    },
                ],
            },
            updateCallback: () => { },
        };

        vm.$onInit = init;

        /**
         * @type {ImageUploader}
         */
        let imageUploader = null;
        let iconUploader = null;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.sponsorData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.SponsorModel();

            if (vm.sponsorData.branchInfo) {
                vm.shopListItems = vm.sponsorData.branchInfo.shops.map(shop => ({shop}));
            }

            if (!vm.sponsorData.legalAddress) {
                vm.sponsorData.legalAddress = {
                    city: '',
                    houseNumber: '',
                    postalCode: '',
                    street: ''
                }
            }

            imageUploader = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.sponsorData.logoRef,
                onImageUploaded: (imageUrl) => {
                    vm.sponsorData.logoRef = imageUrl;
                }
            });

            iconUploader = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.sponsorData.iconRef,
                onImageUploaded: (imageUrl) => {
                    vm.sponsorData.iconRef = imageUrl;
                }
            });
        }

        /**
        * Adds new shop
        */
        function addNewShop() {
            if(!vm.sponsorData.branchInfo) {
                vm.sponsorData.branchInfo = {shops: []};
            }
            vm.shopListItems.push({shop: new ModelsFactory.ShopModel()});
        }

        /**
         * @param $event
         * @param {ShopConfig} shop
         * @param {number} index
         */
        function deleteShop($event, shop, index) {
            $event.stopPropagation();
            $event.preventDefault();
            vm.shopListItems.splice(index, 1);
        }

        /**
        * Image field change handler
        *
        * @param {{blob: File, cropData: Object}} imageFile
        * @param {boolean} uploadToS3
        */
        function onImage(imageFile, uploadToS3) {
            imageUploader.setImage(uploadToS3 ? imageFile.blob : imageFile.blob.name);
            vm.sponsorData.logoRef = imageUploader.getImagePreview();
        }

        /**
        * Image delete click handler
        */
        function onImageDelete() {
            imageUploader.setImage(null);
            vm.sponsorData.logoRef = '';
        }

        /**
         * Image field change handler
         *
         * @param imageFile
         */
        function onIcon(imageFile, uploadToS3) {
            iconUploader.setImage(uploadToS3 ? imageFile.blob : imageFile.blob.name);
            vm.sponsorData.iconRef = iconUploader.getImagePreview();
        }

        /**
         * Image delete click handler
         */
        function onIconDelete() {
            iconUploader.setImage(null);
            vm.sponsorData.iconRef = '';
        }

        function onFinish() {
            if (vm.sponsorData.branchInfo) {
                vm.sponsorData.branchInfo.shops = vm.shopListItems.map(item => item.shop);
            }

            vm.sponsorData.branchInfo = vm.sponsorData.branchInfo
                ? JSON.stringify(vm.sponsorData.branchInfo)
                : '';
            vm.sponsorData.legalAddress = vm.sponsorData.legalAddress
                ? JSON.stringify(vm.sponsorData.legalAddress)
                : '';

            $q.all([
                imageUploader.run(),
                iconUploader.run(),
            ]).then(() => {
                SponsorDataService.setSponsor(vm.sponsorData).then(() => {
                    $state.go('app.loyaltySponsorList');
                });
            });

        }

        function onEditorModeChange() {
            if (vm.useHtmlEditor) {
                vm.sponsorData.description = window.html_beautify(vm.sponsorData.description);
            }
        }

        $scope.$watch(
            () => vm.newSponsorForm && vm.newSponsorForm.$valid,
            formValid => {
                vm.state.canFinish = formValid;
            }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );

        $scope.$watch(
            () => vm.state.save,
            newValue => newValue && onFinish()
        );
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('defineScreenGroupsTab', {
            controller: ScreenGroupsTabController,
            templateUrl: '/assets/views/location/new-screen/tabs/screen-groups/screen-groups.tpl.html',
            bindings: {
                controllerData: '<',
                onScreenGroupsChange: '<'
            }
        });

    function ScreenGroupsTabController(
        LocationDataService,
        StatesFactory,
        LOCATION_GROUPS_TYPES
    ) {
        const vm = this;

        vm.state = StatesFactory.ScreenStates;

        vm.$onInit = init;

        vm.screenGroups = [];
        vm.selectedScreenGroups = [];
        vm.checkboxListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'group-title',
                    width: '65',
                    title: 'name',
                },
            ],
            header: false,
            itemClickCallback: itemClickCallback,
        };

        function init() {
            LocationDataService.getLocationGroups({
                type: LOCATION_GROUPS_TYPES.SCREEN,
                withLocations: true,
            }).then(response => {
                vm.screenGroups = response;

                if (vm.controllerData.id) {
                    vm.selectedScreenGroups = vm.screenGroups.filter(group =>
                        group.locations.some(location => location.controllerRef === vm.controllerData.id)
                    );
                    itemClickCallback();
                }
            });

            vm.state.canFinish = true;
        }

        function itemClickCallback() {
            if (angular.isFunction(vm.onScreenGroupsChange)) {
                vm.onScreenGroupsChange(vm.selectedScreenGroups);
            }
        }
    }
})();
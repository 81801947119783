(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FeedbackCommentService', FeedbackCommentService);

    function FeedbackCommentService(
        $http,
    ) {
        return {
            create,
        }

        function create(data) {
            return $http.post('/api/feedback-comment', data);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentManagementEditToolbarController', ContentManagementEditToolbarController);

    function ContentManagementEditToolbarController($state, $stateParams,
                                                    StatesFactory) {
        const vm = this;

        // Update state
        vm.state = StatesFactory.ManagementStates;
        vm.state.finish = 0;

        // public methods
        vm.back = back;
        vm.finish = finish;

        function back() {
            $state.go('app.contentManagement', { paginationData: $stateParams.paginationData });
        }

        function finish() {
            vm.state.finish++;
        }
    }
}());

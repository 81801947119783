(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LoyaltyCampaignsController', LoyaltyCampaignsController);

    /**
     * @param $q
     * @param $scope
     * @param $state
     * @param $stateParams
     * @param $translate
     * @param $moment
     * @param LoyaltyCampaignService
     * @param PermissionsService
     * @param StorageFactory
     * @param StatesFactory
     * @param TierGroupService
     * @param {PopupService} PopupService
     * @param CONTENT_TYPES
     * @param HTTP_STATUSES
     * @param TierService
     * @param VoucherService
     * @param LoyaltyDataService
     * @constructor
     */
    function LoyaltyCampaignsController(
        $q,
        $scope,
        $state,
        $stateParams,
        $translate,
        $moment,
        LoyaltyCampaignService,
        PermissionsService,
        StorageFactory,
        StatesFactory,
        TierGroupService,
        PopupService,
        CONTENT_TYPES,
        HTTP_STATUSES,
        TierService,
        VoucherService,
        LoyaltyDataService,
    ) {
        const vm = this;
        const CONFIG_TYPE_REDEEM_LIST = 5;
        const CONFIG_TYPE_REDEEM_TYPES = 6;

        const REWARD_TYPE_DISCOUNT = 1;
        const REWARD_TYPE_VOUCHER = 2;
        const REWARD_TYPE_TIER = 3;

        const redeemsMap = {};
        const redeemTypesMap = {};
        const vouchersMap = {};
        const tierGroupsMap = {};

        vm.state = StatesFactory.LoyaltyCampaignListStates.refresh();

        vm.filter = {
            status: {}
        };

        vm.STORAGE = new StorageFactory.Storage('LoyaltyCampaigns', true);

        vm.CAMPAIGN_STATUS_INACTIVE = 0;
        vm.CAMPAIGN_STATUS_ACTIVE   = 1;
        vm.CAMPAIGN_STATUS_DELETED  = 3;

        vm.REWARD_TYPE_TIER = 3;

        vm.newCampaign = newCampaign;
        vm.updateCampaignsList = updateCampaignsList;
        vm.showAll = showAll;
        vm.isPermissionAvailable = PermissionsService.isPermissionAvailable;

        init();

        /**
         * @type {ListItemDetailsConfig}
         */
        const detailsConfig = {
            languagePicker: true,
            columns: [
                {
                    title: 'DETAILS',
                    fields: [
                        {
                            label: 'NAME',
                            value: item => item.name,
                        },
                        {
                            label: 'START_DATE',
                            value: item => item.campaignStart ? $moment(item.campaignStart).format('LL HH:mm') : '-',
                        },
                        {
                            label: 'END_DATE',
                            value: item => item.campaignEnd ? $moment(item.campaignEnd).format('LL HH:mm') : '-',
                        },
                        {
                            label: 'LABEL',
                            value: item => item.campaignLabel,
                            languageEncoded: true,
                        },
                        {
                            label: 'DESCRIPTION',
                            value: item => item.description,
                            languageEncoded: true,
                            stripHtml: true,
                        },
                        {
                            label: 'PRECONDITIONS',
                            value: item => item.preconditionsLabel,
                            languageEncoded: true,
                        },
                        {
                            label: 'IMAGE',
                            value: item => item.imageRef,
                            image: true,
                            maxWidth: '200px',
                        },
                    ],
                },
                {
                    title: 'EVENTS',
                    fields: [
                        {
                            label: 'NAME',
                            value: item => item.loyaltyEventType.name,
                        },
                        {
                            label: 'DESCRIPTION',
                            value: item => item.loyaltyEventType.description,
                        },
                        {
                            label: 'CONVERSION_RATIO',
                            value: item => item.loyaltyEventType.conversionRatio,
                        },
                        {
                            label: 'COUNTING_METHOD',
                            value: item => $translate.instant(
                                item.loyaltyEventType.countingMethod ? 'EVENT_VALUE' : 'EVENT'
                            ),
                        },
                    ]
                },
                {
                    title: 'COUNTERS',
                    fields: [
                        {
                            label: 'COUNTER_LABEL',
                            value: item => item.loyaltyCounterType.label,
                            languageEncoded: true,
                        },
                        {
                            label: 'COUNTER_UNITS',
                            value: item => item.loyaltyCounterType.unitsLabel,
                            languageEncoded: true,
                        },
                        {
                            label: 'REWARD_THRESHOLD',
                            value: item => item.loyaltyCounterType.conversionThreshold,
                        },
                        {
                            label: 'CONVERSION_METHOD',
                            value: item => $translate.instant(
                                item.loyaltyCounterType.rewardMethod ? 'EVENT_VALUE' : 'EVENT'
                            ),
                        },
                        {
                            label: 'MAXIMUM_VALUE',
                            value: item => item.loyaltyCounterType.maximumAccumulated,
                        }
                    ]
                },
                {
                    title: 'ACCOUNT',
                    fields: [
                        {
                            label: 'ACCOUNT_LABEL',
                            value: item => item.loyaltyAccountType.label,
                            languageEncoded: true,
                        },
                        {
                            label: 'ACCOUNT_UNITS',
                            value: item => item.loyaltyAccountType.unitsLabel,
                            languageEncoded: true,
                        },
                        {
                            label: 'VISIBLE_TO_USER',
                            value: item => $translate.instant(
                                item.loyaltyAccountType.visibility ? 'YES' : 'NO'
                            ),
                        },
                        {
                            label: 'REDEEM_THRESHOLD',
                            value: item => item.loyaltyAccountType.conversionThreshold,
                        },
                        {
                            label: 'REDEEM_METHOD',
                            value: item => $translate.instant(
                                item.loyaltyAccountType.rewardMethod ? 'EVENT_VALUE' : 'EVENT'
                            ),
                        },
                        {
                            label: 'MAXIMUM_VALUE',
                            value: item => item.loyaltyAccountType.maximumAccumulated,
                        }
                    ]
                },
                {
                    title: 'REWARD',
                    fields: [
                        {
                            label: 'NAME',
                            value: item => item.loyaltyRewardType.name,
                        },
                        {
                            label: 'DESCRIPTION',
                            value: item => item.loyaltyRewardType.description,
                        },
                        {
                            label: 'REDEEM_TYPE',
                            value: item => {
                                const rewardType = redeemTypesMap[item.loyaltyRewardType.rewardType];

                                return rewardType && rewardType.configTitle;
                            },
                        },
                        {
                            label: 'REDEEM',
                            isVisible: item => item.loyaltyRewardType.rewardType === REWARD_TYPE_DISCOUNT,
                            value: item => {
                                const reward = redeemsMap[item.loyaltyRewardType.rewardTargetRef];

                                return reward && reward.configTitle;
                            },
                        },
                        {
                            label: 'VOUCHER',
                            isVisible: item => item.loyaltyRewardType.rewardType === REWARD_TYPE_VOUCHER,
                            value: item => {
                                const voucher = vouchersMap[item.loyaltyRewardType.rewardVoucherType];

                                return voucher && voucher.name;
                            },
                            languageEncoded: true,
                        },
                        {
                            label: 'TIER_GROUP',
                            isVisible: item => item.loyaltyRewardType.rewardType === REWARD_TYPE_TIER,
                            value: item => {
                                const tierGroup = tierGroupsMap[item.loyaltyRewardType.rewardTierGroup];

                                return tierGroup && tierGroup.name;
                            },
                        },
                    ]
                },
            ],
        };

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '30',
                    title: item => item.isGroup ? `${item.name} (Group)` : item.name
                },
                {
                    name: 'STATUS',
                    class: 'campaignStatus',
                    width: '15',
                    translate: true,
                    title: item => {
                        if (item.isGroup) {
                            return '-';
                        }

                        switch(item.status) {
                            case vm.CAMPAIGN_STATUS_INACTIVE:
                                return 'INACTIVE';
                            case vm.CAMPAIGN_STATUS_ACTIVE:
                                return 'ACTIVE';
                            case vm.CAMPAIGN_STATUS_DELETED:
                                return 'DELETED';
                            default:
                                return 'UNDEFINED';
                        }
                    }
                },
                {
                    name: 'START_DATE',
                    width: '15',
                    title: item => item.isGroup ? '-' : moment(item.campaignStart).format('LL HH:mm')
                },
                {
                    name: 'END_DATE',
                    width: '15',
                    title: item => item.isGroup ? '-' : moment(item.campaignEnd).format('LL HH:mm')
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'listBtn',
                        callback: _showGroupedCampaigns,
                        isVisible: (btn, item) => item.isGroup,
                        permissionAction: 'view'
                    },
                    {
                        class: 'deleteBtn',
                        callback: deleteCampaign,
                        isVisible: (btn, item) => !item.isGroup && item.status !== vm.CAMPAIGN_STATUS_DELETED,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: copyCampaign,
                        isVisible: (btn, item) => !item.isGroup,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: editCampaign,
                        isVisible: (btn, item) => !item.isGroup && item.status !== vm.CAMPAIGN_STATUS_DELETED,
                        permissionAction: 'modify'
                    },
                    {
                        class: item => {
                            switch (item.status) {
                                case vm.CAMPAIGN_STATUS_ACTIVE:
                                    return 'block-btn';
                                case vm.CAMPAIGN_STATUS_INACTIVE:
                                    return 'check-btn';
                                default:
                                    break;
                            }
                        },
                        callback: changeStatus,
                        isVisible: (btn, item) => !item.isGroup && item.status !== vm.CAMPAIGN_STATUS_DELETED,
                        permissionAction: 'modify'
                    },
                    {
                        class: 'statsBtn',
                        callback: () => {
                            $state.go('app.loyaltyDashboard');
                        },
                        permissionAction: 'view'
                    }
                ]
            },
            updateCallback: vm.updateCampaignsList,
            generatePermissionName: generatePermissionName,
            additionalData: {
                detailsConfig,
            }
        };
        
        vm.$onInit = () => {
            void _loadLoyaltyRelatedEntities();
        }

        // Buttons handling

        /**
         * Create campaign button handler
         */
        function newCampaign() {
            $state.go('app.loyaltyCampaignsAdd');
        }

        /**
         * Edit campaign button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function editCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            const data = item.plain();

            $state.go('app.loyaltyCampaignsEdit', {
                data,
                paginationData: {
                    page: vm.paginationParams.page,
                    itemsPerPage: vm.paginationParams.itemsPerPage,
                    count: vm.totalItems
                },
                type: 'edit'
            });
        }

        /**
         * Change status button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function changeStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = item.plain();
            data.status = data.status === vm.CAMPAIGN_STATUS_INACTIVE ? vm.CAMPAIGN_STATUS_ACTIVE
                : vm.CAMPAIGN_STATUS_INACTIVE;

            const formData = new FormData();
            formData.append('json', JSON.stringify(data));

            LoyaltyCampaignService.save(formData)
                .then(function() {
                    item.status = data.status;
                }).catch(console.error.bind(console));
        }

        /**
         * Delete campaign button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function deleteCampaign($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = item.plain();
            data.status = vm.CAMPAIGN_STATUS_DELETED;

            const formData = new FormData();
            formData.append('json', JSON.stringify(data));

            LoyaltyCampaignService.remove(formData)
                .then(function() {
                    item.status = data.status;
                }).catch((response) => {
                    if (response.status === HTTP_STATUSES.LOCKED) {
                        _generateRelatedContentMessage(response.data)
                            .then(({text, okButtonText}) => {
                                PopupService.showAlertPopup({
                                    text,
                                    okButtonText
                                });
                            });
                    } else {
                        console.error.bind(console)
                    }
                });
        }

        /**
         * Copy campaign button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function copyCampaign($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const objCopy = angular.copy(item.plain());
            removeKeys(objCopy, ['id', 'externalId']);
            objCopy.status = 0;

            const formData = new FormData();
            formData.append('json', JSON.stringify(objCopy));

            LoyaltyCampaignService.create(formData)
                .then(function() {
                    updateCampaignsList(vm.paginationParams.page, vm.paginationParams.itemsPerPage, true);
                }).catch(console.error.bind(console));
        }

        // Private methods

        /**
         * Expand grouped campaigns
         *
         * @param $event
         * @param item
         * @private
         */
        function _showGroupedCampaigns($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.groupIsShowing = true;
            vm.campaigns = item.campaigns;
            vm.campaignsInGroup = item.campaigns;
            vm.groupName = item.name;
            vm.state.canBack = true;
        }

        /**
         * Applying filtering and pagination parameters to campaigns, and adding campaigns list to scope
         *
         * @param {array} campaigns
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function applyCampaignsToScope(campaigns, page, itemsPerPage) {
            const campaignsList = vm.groupIsShowing ? vm.campaignsInGroup : _groupCampaigns(campaigns);
            const campaignsFiltered = getFiltered(campaignsList);
            vm.totalItems = campaignsFiltered.length;
            vm.campaigns = getPage(campaignsFiltered, page, itemsPerPage);
            vm.paginationParams = {page, itemsPerPage};
        }

        /**
         * Getting filtered campaigns from all campaigns list
         *
         * @param {array} campaigns
         * @returns {array}
         */
        function getFiltered(campaigns) {
            const statuses = Object.keys(vm.filter.status).filter(statusId => vm.filter.status[statusId] === true);

            if (!statuses.length) {
                return campaigns;
            }

            return campaigns.filter(item => {
                return item.isGroup || statuses.includes(item.status.toString());
            });
        }

        /**
         * Getting part (page) of array
         *
         * @param {array} array
         * @param {number} page
         * @param {number} perPage
         * @returns {array}
         */
        function getPage(array, page, perPage) {
            --page; // because pages logically start with 1, but technically with 0
            const firstElement = page * perPage;
            return array.slice(firstElement, firstElement + perPage);
        }

        /**
         * Removing specific keys from object
         *
         * @param {object} obj - target object
         * @param {array} keys - array of property names
         */
        function removeKeys(obj, keys) {
            let index;
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    index = keys.indexOf(prop);
                    if (index > -1) {
                        delete obj[prop];
                    }
                    if (typeof obj[prop] === 'object') {
                        removeKeys(obj[prop], keys);
                    }
                }
            }
        }

        /**
         * Updates campaign list
         *
         * @param {number} page
         * @param {number} itemsPerPage
         * @param {boolean} forceApi - force update from DB
         */
        function updateCampaignsList(page = vm.paginationParams.page,
                                     itemsPerPage = vm.paginationParams.itemsPerPage,
                                     forceApi = false
        ) {
            const campaignsAll = vm.STORAGE.get('campaigns');
            if (!forceApi && campaignsAll !== undefined) {
                applyCampaignsToScope(campaignsAll, page, itemsPerPage);
            } else {
                LoyaltyCampaignService.getList()
                    .then(response => {
                        vm.STORAGE.set('campaigns', response);
                        TierGroupService.getList()
                            .then(response => {
                                vm.tierGroups = angular.copy(response.plain());
                                applyCampaignsToScope(vm.STORAGE.get('campaigns'), page, itemsPerPage);
                            })
                            .catch(console.error.bind(console));
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Show all campaigns (handler for "back to all campaigns link")
         */
        function showAll() {
            vm.groupName = false;
            vm.groupIsShowing = false;
            updateCampaignsList(vm.paginationParams.page, vm.paginationParams.itemsPerPage);
        }

        /**
         * Grouping campaigns by tier groups
         *
         * @param {array} campaigns
         * @private
         */
        function _groupCampaigns(campaigns) {
            const campaignsGrouped = [];
            campaigns.forEach(item => {
                if (item.loyaltyRewardType.rewardType === vm.REWARD_TYPE_TIER
                    && item.loyaltyRewardType.rewardTargetRef) {
                    const tierGroupExternalId = item.loyaltyRewardType.rewardTierGroup;
                    const groupFoundIndex = _getGroupIndex(campaignsGrouped, tierGroupExternalId);
                    if (groupFoundIndex === false) {
                        const tierGroup = _getTierGroup(tierGroupExternalId);
                        campaignsGrouped.push({
                            id: tierGroup.id,
                            external_id: tierGroup.external_id,
                            name: tierGroup.name,
                            description: tierGroup.description,
                            isGroup: true,
                            campaigns: [item],
                            plain: function() {
                                return this;
                            }
                        });
                    } else {
                        campaignsGrouped[groupFoundIndex].campaigns.push(item);
                    }
                } else {
                    campaignsGrouped.push(item);
                }
            });
            return campaignsGrouped;
        }

        /**
         * If campaign group is already created - return is's index
         *
         * @param {array} campaignsGrouped
         * @param {number|boolean} groupExternalId
         */
        function _getGroupIndex(campaignsGrouped, groupExternalId) {
            const groupIndex = campaignsGrouped.findIndex(item => {
                return item.isGroup && item.external_id === groupExternalId;
            });
            return groupIndex !== -1 && groupIndex;
        }

        /**
         * Get any property value of tier group by external id
         *
         * @param {number} tierGroupExternalId
         * @returns {number|string|object|null}
         * @private
         */
        function _getTierGroup(tierGroupExternalId) {
            return vm.tierGroups.find(item => {
                return item.external_id === tierGroupExternalId;
            });
        }

        /**
         * From short name to permission full name
         *
         * @param {object} button
         * @returns {string}
         */
        function generatePermissionName(button) {
            return 'can_' + button.permissionAction + '_loyalty_campaign';
        }

        /**
         * Controller initialization
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Generate alert message with related contents
         *
         * @param {object} data
         * @return {string}
         * @private
         */
        function _generateRelatedContentMessage(data) {
            return Promise.all([
                $translate('THIS_CONTENT_ELEMENT_IS_RELATED_TO'),
                $translate('BANNER'),
                $translate('OK'),
            ]).then(([message, bannerTranslation, okTranslation]) => {
                const related = [];
                if (data[CONTENT_TYPES.BANNER]) {
                    data[CONTENT_TYPES.BANNER].map(item => {
                        item.title = JSON.parse(item.title);
                        related.push(`${item.title[item.language_id]} (${bannerTranslation})`);
                    })
                }
                return {
                    text: `${message}: ${related.join(', ')}`,
                    okButtonText: okTranslation,
                };
            });
        }

        /**
         * @return {Promise<void>}
         * @private
         */
        function _loadLoyaltyRelatedEntities() {
            return $q.all([
                TierGroupService.getList(),
                VoucherService.getList(),
                LoyaltyDataService.getLoyaltyConfigs(),
            ])
                .then(response => response.map(item => item.plain()))
                .then(([tierGroups, vouchers, loyaltyConfigs]) => {
                    loyaltyConfigs
                        .filter(item => parseInt(item.configType) === CONFIG_TYPE_REDEEM_TYPES)
                        .forEach(item => {
                            const value = item.parameters.split('==')[1];

                            redeemsMap[value] = item;
                        });

                    loyaltyConfigs
                        .filter(item => parseInt(item.configType) === CONFIG_TYPE_REDEEM_LIST)
                        .forEach(item => {
                            const value = item.parameters.split('==')[1];

                            redeemTypesMap[value] = item;
                        });

                    vouchers.forEach(item => vouchersMap[item.external_id] = item);
                    tierGroups.forEach(item => tierGroupsMap[item.external_id] = item);
                });
        }

        /**
         * Back button handler
         */
        $scope.$watch(
            () => vm.state.onBack,
            onBack => {
                onBack && showAll();
                vm.state.onBack = false;
                vm.state.canBack = false;
            });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkFaqConfigurations', {
            templateUrl: '/assets/views/share-park/operations/faq/faq.tpl.html',
            controller: ShareParkFaqController
        });

    function ShareParkFaqController(
        $state,
        PermissionsService,
        FAQConfigurationDataService,
        PopupService,
    ) {
        const vm = this;

        const { actions, generateFAQConfigurationPermission } = PermissionsService;

        vm.updateCounter = 0;
        vm.faqConfigurationsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'faq-configuration-name',
                    width: '30',
                    title: 'name',
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteFAQConfiguration,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editFAQConfiguration,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName,
            additionalData: {
                isJsonFile: url => url.includes('.json'),
            }
        };

        vm.createFAQConfiguration = createFAQConfiguration;

        /**
         * Create new FAQ configuration text
         */
        function createFAQConfiguration() {
            $state.go('app.addShareParkFAQ');
        }

        function deleteFAQConfiguration($event, faqConfiguration) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_FAQ_CONFIGURATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                FAQConfigurationDataService.deleteFAQConfiguration(faqConfiguration.id)
                    .then(() => {
                        vm.updateCounter++;
                    })
            });
        }

        function editFAQConfiguration($event, faqConfiguration) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkFAQ', { data: faqConfiguration });
        }

        function updateElementsList(page, itemsPerPage) {
            let data = { page, itemsPerPage };

            FAQConfigurationDataService.getFAQConfigurations(data)
                .then(response => {
                    vm.faqConfigurations = response;
                    vm.faqConfigurationsCount = response.count;
                })
        }

        function generatePermissionName(button) {
            return generateFAQConfigurationPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('checkboxList', checkboxList);

    function checkboxList() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/checkbox-list/checkbox-list.tpl.html',
            replace: true,
            controller: CheckboxListController,
            controllerAs: 'checkboxList',
            bindToController: true,
            scope: {
                data: '=checkboxList',
                items: '=',
                selectedItems: '=',
                prop: '<',
                disabled: '<?',
                order: '<?',
                showOnlySelected: '<',
            }
        };
    }

    function CheckboxListController(UtilitiesService) {
        const vm = this;
        const { compareAsNumber } = UtilitiesService;
        vm.NO_REF = -1;

        // public methods
        vm.itemClickHandler = itemClickHandler;
        vm.checkboxClickHandler = checkboxClickHandler;
        vm.isSelected = isSelected;

        if (!vm.order) {
            vm.order = '';
        }

        /**
         * Handles click on item
         */
        function itemClickHandler(item, $event) {
            if (!vm.disabled) {
                if (!angular.isFunction(vm.data.checkboxClickCallback)) {
                    selectItem(item);
                }
                if (typeof vm.data.itemClickCallback === 'function') {
                    vm.data.itemClickCallback(item, $event);
                }
            }
        }

        /**
         * Handles click on checkbox
         * @param {object} item
         * @param {object} event
         */
        function checkboxClickHandler(item, event) {
            if (angular.isFunction(vm.data.checkboxClickCallback)) {
                event.stopPropagation();
                selectItem(item);
                vm.data.checkboxClickCallback(item);
            }
        }

        // private methods

        /**
         * Implements checkbox behaviour
         *
         * @param {object} item
         */
        function selectItem(item) {
            let itemIndex = vm.selectedItems.findIndex((selectedItem) => {
                if (angular.isArray(vm.prop)) {
                    return vm.prop.every(propName => {
                        if (angular.isUndefined(selectedItem[propName]) && angular.isUndefined(item[propName])) {
                            return true;
                        } else {
                            return compareAsNumber(selectedItem[propName], item[propName])
                        }
                    });
                } else {
                    return compareAsNumber(selectedItem[vm.prop], item[vm.prop])
                }
            });

            if (itemIndex === -1) {
                vm.selectedItems.push(item);
            } else {
                vm.selectedItems.splice(itemIndex, 1);
            }
        }

        /**
         * Checks if item selected
         *
         * @param {object} item
         * @returns {boolean}
         */
        function isSelected(item) {
            return vm.selectedItems.some((selectedItem) => {
                if (angular.isArray(vm.prop)) {
                    return vm.prop.every(propName => {
                        if (angular.isUndefined(selectedItem[propName]) && angular.isUndefined(item[propName])) {
                            return true;
                        } else {
                            return compareAsNumber(selectedItem[propName], item[propName])
                        }
                    });
                } else {
                    return compareAsNumber(selectedItem[vm.prop], item[vm.prop])
                }
            });
        }
    }
}());

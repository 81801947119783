(function() {
    'use strict';

    angular.module('lineRoute')
        .service('RouteLine', () => {

            class Line {
                constructor(config) {
                    this.config = config;

                    this.color = '#1b9748';
                    this.lineWidth = 4;

                    this.from = config.from;
                    this.to = config.to;
                }

                /**
                 * Draw the line
                 *
                 * @param context
                 */
                draw(context) {
                    context.save();

                    context.beginPath();
                    context.moveTo(this.from.x, this.from.y);
                    context.lineTo(this.to.x, this.to.y);

                    context.strokeStyle = this.color;
                    context.lineWidth = this.lineWidth;
                    context.stroke();

                    context.restore();
                }
            }

            return Line;
        });
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ImagePreviewPopupController', ImagePreviewPopupController);

    function ImagePreviewPopupController($uibModalInstance, data) {
        const vm = this;

        // public properties
        vm.data = data;

        // public methods
        vm.ok = okHandler;

        /**
         * OK button handler
         */
        function okHandler() {
            $uibModalInstance.close();
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkDetailsTab', {
            templateUrl: '/assets/views/share-park/car-parks/new/tabs/car-park-details/car-park-details-tab.tpl.html',
            controller: CarParkDetailsTabController,
            bindings: {
                carParkData: '<',
            }
        });

    function CarParkDetailsTabController(
        $scope,
        $state,
        $stateParams,
        DateHelper,
        LanguageService,
        ModelsFactory,
        StatesFactory,
        GoogleMapsUtilitiesService,
        GoogleMapsRequestService,
        UtilitiesService,
        CAR_PARK_TYPE,
        ShareParkDataService,
    ) {
        const vm = this;

        vm.$onInit = init;

        const { getLocationDataByCoordinates } = GoogleMapsUtilitiesService;
        const { getTimezoneByCoordinates } = GoogleMapsRequestService;
        const { toPrecision } = UtilitiesService;
        const { generateTimeZones } = DateHelper;

        vm.state = StatesFactory.CarParkStates;
        vm.carParkTypes = Object.values(CAR_PARK_TYPE);
        vm.markers = [];
        vm.carParks = [];
        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };

        vm.serviceProviders = [];

        vm.onMapClickCallback = onMapClickCallback;
        vm.onMarkerClick = onMarkerClick;
        vm.onOpeningHoursChange = onOpeningHoursChange;
        vm.onCarParkImage = onCarParkImage;
        vm.onCarParkImageDelete = onCarParkImageDelete;
        vm.onCoordinatesChange = onCoordinatesChange;
        vm.onPolylineUpdate = onPolylineUpdate;
        vm.getCarParkTypeIcon = getCarParkTypeIcon;

        /**
         * Initialization method
         */
        function init() {
            vm.isEditMode = vm.state.type === "edit";
            vm.timeZones = generateTimeZones();

            getLanguages();

            ShareParkDataService.getCarParks()
                .then(response => {
                    vm.carParks = response.plain()
                        .filter(cap => cap.id !== vm.carParkData.id)
                        .sort((a, b) => a.name.localeCompare(b.name));
                });

            ShareParkDataService.getCapServiceProviders().then(response => {
                vm.serviceProviders = response.plain();
            });

            if (vm.carParkData.latlng) {
                const coords = vm.carParkData.latlng.split(',').map(coord =>
                    parseFloat(coord)
                );
                const [lat, lng] = coords;
                vm.markers = [{
                    position: {
                        lat,
                        lng
                    },
                    locationId: `${lat},${lng}`,
                }]
            }

            ShareParkDataService.getAccessSystemConfigs().then(response => {
                vm.accessSystemConfigs = response;
            });
        }

        function getLanguages() {
            LanguageService.getLanguages()
                .then(response => {
                    const firstSelected = Object.keys(vm.carParkData.description)[0];
                    vm.langArray = response.plain();

                    vm.carParkData.langId = vm.isEditMode && firstSelected
                        ? firstSelected
                        : vm.langArray[0].id;
                })
        }

        function onOpeningHoursChange(openingHours, openingHoursRef) {
            if (openingHoursRef) {
                vm.carParkData.opening_hours_ref = openingHoursRef;
                vm.carParkData.opening_hour = openingHours;
                vm.carParkData.opening_hours = openingHours.description;

                return;
            }


            if (openingHours && openingHours.id) {
                delete openingHours.id;
            }
            vm.carParkData.opening_hour = openingHours;
            vm.carParkData.opening_hours = openingHours.description;
            delete vm.carParkData.opening_hours_ref;
        }

        /**
         * On map click handler
         * adds new marker and sets
         * @param event
         */
        function onMapClickCallback(event) {
            if (vm.markers.length) {
                return;
            }

            const precision = 6;
            const lat = toPrecision(event.latLng.lat(), precision);
            const lng = toPrecision(event.latLng.lng(), precision);
            vm.markers = [{
                locationId: `${lat},${lng}`,
                position: {
                    lat,
                    lng
                }
            }];
            vm.carParkData.latlng = `${lat},${lng}`;
            updateTimeZone();
            getLocationDataByCoordinates({
                latitude: lat,
                longitude: lng
            }).then(({
                 locationLocality,
                 locationCountryCode,
                 locationStreetName,
                 locationStreetNumber,
                 locationCountryName,
            }) => {
                const { address_city, address_street } = vm.carParkData;

                if (address_city || address_street) {
                    return;
                }

                vm.carParkData.address_city = locationLocality;
                vm.carParkData.country_code = locationCountryCode;
                vm.carParkData.country = locationCountryName;

                if (locationStreetName) {
                    vm.carParkData.address_street = `${locationStreetName}` + (locationStreetNumber
                    ? ` ${locationStreetNumber}`
                    : '');
                }

                $scope.$digest();
            });
            $scope.$digest();
        }

        function onMarkerClick(marker) {
            vm.markers = [];
            $scope.$apply();
        }

        function onCarParkImage(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            vm.carParkData.imageFile = imageFile;
            vm.carParkData.image_ref = urlCreator.createObjectURL(imageFile);
        }

        function onCarParkImageDelete() {
            delete vm.carParkData.imageFile;
            vm.carParkData.image_ref = '';
        }

        /**
         * On coordinates change handler
         */
        function onCoordinatesChange() {
            if (!vm.carParkData.latlng) {
                vm.markers = [];
                return;
            }

            updateTimeZone();
            const [lat, lng] = vm.carParkData.latlng.split(',').map(coord => parseFloat(coord));

            if (isNaN(lat) || isNaN(lng)) {
                vm.markers = [];
                return;
            }

            vm.markers = [{
                locationId: `${lat},${lng}`,
                position: {
                    lat,
                    lng
                }
            }];
        }

        function onPolylineUpdate(newPolyline, newPolylineV2) {
            vm.carParkData.area_polygon = newPolyline;
            vm.carParkData.area_polygon_v2 = newPolylineV2;
        }

        function updateTimeZone() {
            getTimezoneByCoordinates({ location: vm.carParkData.latlng })
                .then((time_zone_id) => {
                    vm.carParkData.time_zone_id = time_zone_id;
                    $scope.$digest();
                });
        }

        function getCarParkTypeIcon(type) {
            return `/assets/images/icon-circle-car-park-${type.icon}.png`;
        }

        /**
         * Form validation watcher
         */
        $scope.$watch(() =>
            vm.newCarParkForm && vm.newCarParkForm.$valid
        , formValid => {
            vm.state.canNext = !!formValid;
        }, true);
    }
})();
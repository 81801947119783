(function() {
    'use strict';

    angular.module('beacon.app')
        .component('locationLocationTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/location-tab.tpl.html',
            controller: LocationLocationTab,
            bindings: {
                item: '<',
                selectedLocations: '=',
                selectedGeofences: '=',
                selectedLocationName: '='
            }
        });

    function LocationLocationTab(
            $state,
            $stateParams,
            $translate,
            locationTabState,
            PermissionsService,
            PopupService,
    ) {
        const vm = this;

        const state = locationTabState;

        vm.TABS = state.views.LOCATIONS.tabs;
        vm.searchName = '';

        vm.selectedLocations = false;
        vm.selectedGeofences = false;
        vm.selectedTabIndex = $stateParams.data && $stateParams.data.selectedTabIndex
            ? $stateParams.data.selectedTabIndex : 0;
        vm.selectedSubTabIndex = $stateParams.data && $stateParams.data.selectedSubtabIndex
            ? $stateParams.data.selectedSubtabIndex : 0;
        vm.availableTabs = [];

        vm.changeTab = changeTab;
        vm.onSubTabChange = onSubTabChange;
        vm.showSearchField = showSearchField;
        vm.openDownloadScreensURLPopup = openDownloadScreensURLPopup;
        vm.onManageScreensClick = onManageScreensClick;
        vm.selectGeofenceCallback = selectGeofenceCallback;
        vm.selectLocationCallback = selectLocationCallback;

        function changeTab(newTab) {
            vm.searchName = '';
            vm.selectedTabIndex = newTab;
            vm.selectedSubTabIndex = 0;
        }

        function onSubTabChange(newSubTab) {
            vm.selectedSubTabIndex = newSubTab;
        }

        function showSearchField() {
            const tabs = locationTabState.views.LOCATIONS.tabs;
            switch (vm.selectedTabIndex) {
                case tabs.GEOFENCES.index:
                    return vm.selectedSubTabIndex === tabs.GEOFENCES.subtabs.GEOFENCE_ITEMS.index;
                case tabs.SCREENS.index:
                    return vm.selectedSubTabIndex === tabs.SCREENS.subtabs.SCREEN_ITEMS.index 
                        || vm.selectedSubTabIndex === tabs.SCREENS.subtabs.SCREEN_GROUPS.index;
                case tabs.BEACONS.index:
                    return vm.selectedSubTabIndex === tabs.BEACONS.subtabs.BEACON_ITEMS.index;
                default:
                    return false;
            }
        }

        /**
         * Init available tabs, according to users permissions
         */
        function initTabs () {
            if(PermissionsService.isPermissionAvailable('can_view_location_geofence')) {
                vm.availableTabs.push({ name: 'GEOFENCES', index: vm.TABS.GEOFENCES.index });
            }
            if(PermissionsService.isPermissionAvailable('can_view_location_screen')) {
                vm.availableTabs.push({ name: 'SCREENS', index: vm.TABS.SCREENS.index });
            }
            if(PermissionsService.isPermissionAvailable('can_view_location_beacon')) {
                vm.availableTabs.push({ name: 'BEACONS', index: vm.TABS.BEACONS.index });
            }
            vm.selectedTabIndex = $stateParams.data && $stateParams.data.selectedTabIndex
                ? $stateParams.data.selectedTabIndex : vm.availableTabs[0].index;
        }

        function openDownloadScreensURLPopup() {
            PopupService.show({
                templateUrl: '/assets/views/common/popups/download-screens-url/download-screens-url.tpl.html',
                controller: 'DownloadScreensUrlPopupController',
                controllerAs: '$ctrl',
                windowClass: 'download-screens-url-popup',
                keyboard: false,
            });
        }

        function onManageScreensClick() {
            $state.go('app.manageScreens');
        }

        /**
         * On geofence select callback
         * @param {string} name
         * @param {array} ids
         */
        function selectGeofenceCallback(name, ids) {
            vm.selectedLocations = null;
            vm.selectedLocationName = name;
            vm.selectedGeofences = ids;
            canProcessNext();
        }

        /**
         * On location select callback
         * @param {string} name
         * @param {array} ids
         */
        function selectLocationCallback(name, ids) {
            vm.selectedGeofences = null;
            vm.selectedLocationName = name;
            vm.selectedLocations = ids;
            canProcessNext();
        }

        /**
         * Checks if next button is enabled
         */
        function canProcessNext() {
            state.canProcessNext = !!vm.selectedLocations || vm.selectedGeofences && vm.selectedGeofences.length;
        }

        /**
         * Initialization method
         */
        function onInit() {
            initTabs();
        }

        vm.$onInit = onInit;
    }
}());

(function() {
    'use strict';

    angular.module('lineRoute')
        .service('LineRouteDateTimeService', LineRouteDateTimeService);

    function LineRouteDateTimeService() {

        const SHORT_TIME_FORMAT = 'HH:mm';
        const MILISECONDS_IN_SECOND = 1000;
        const SECONDS_IN_MINUTE = 60;
        const MINUTES_IN_HOUR = 60;
        const HOURS_IN_DAY = 24;

        return {
            toTimeString,
            getTimeDiff,
            getMinutesToDeparture,
            incrementCurrentDate,
            toLocalTime,
            compareDates,
        };

        /**
         * Returns time string
         * @param date
         * @return {*}
         */
        function toTimeString(date) {
            return moment(date).format(SHORT_TIME_FORMAT);
        }

        /**
         * Get difference between current date and checkDate in minutes
         * @param departureTime
         * @return {number}
         */
        function getMinutesToDeparture(departureTime) {
            const diff = getTimeDiff(departureTime);

            return Math.ceil(diff / MILISECONDS_IN_SECOND / SECONDS_IN_MINUTE);
        }

        /**
         * Returns diff between departure time and current time in miliseconds
         * @param departureTime
         * @return {number}
         */
        function getTimeDiff(departureTime) {
            const currentDate = new Date();
            const dateTime = angular.isDate(departureTime) ? departureTime : moment(departureTime).toDate();
            return dateTime.getTime() - currentDate.getTime();
        }

        /**
         * Incrementing current date by minutes
         * @param {number} increment Increment value
         * @return {Date} Date object
         */
        function incrementCurrentDate(increment) {
            const d = new Date();
            d.setMinutes(d.getMinutes() + increment);
            return moment(d);
        }

        /**
         * Converts datetime to local time
         * @param dateTime
         * @param timezoneId
         * @return {*}
         */
        function toLocalTime(dateTime, timezoneId) {
            const date = moment.tz(dateTime, timezoneId).toDate();
            return moment(date);
        }

        /**
         * Comparator function for sorting by date
         * @param date1
         * @param date2
         * @return {number}
         */
        function compareDates(date1, date2) {
            return moment(date1).isBefore(date2) ? -1 : 1;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('trmPoiElements', {
            templateUrl: '/assets/views/trm-poi/elements/trm-poi-elements.tpl.html',
            controller: TrmPoiElementsController
        });

    function TrmPoiElementsController(
        $state,
        PermissionsService,
        TrmPoiContentDataService,
        PopupService,
        UtilitiesService,
    ) {
        const vm = this;

        vm.searchQuery = '';
        vm.addElement = addElement;
        vm.updateElementsList = updateElementsList;
        vm.updateCounter = 0;
        vm.filteredElements = [];

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '35',
                    title: listItem => listItem.name,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deletePoiContent,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: copyPoiContent,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: editPoiContent,
                        permissionAction: 'modify'
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName: generatePermissionName,
        };

        /**
         * Create new element
         */
        function addElement() {
            $state.go('app.addTrmPoiElement');
        }

        /**
         * Updates list of elements
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateElementsList(page = 1, count = 25) {
            if (!vm.searchQuery) {
                TrmPoiContentDataService.getTrmPoiContents()
                .then(response => {
                    vm.elements = response.data;
                    vm.filteredElements = UtilitiesService.getArrayPage(vm.elements, page, count);
                });
            } else {
                TrmPoiContentDataService.findTrmPoiContents({searchQuery: vm.searchQuery})
                .then(response => {
                    vm.elements = response.data;
                    vm.filteredElements = UtilitiesService.getArrayPage(vm.elements, page, count);
                });
            }
            
        }

        /**
         * Removes poi content by id
         *
         * @param {MouseEvent} $event
         * @param {object} poiContent
         */
        function deletePoiContent($event, poiContent) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_POI_CONTENT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                TrmPoiContentDataService.deleteTrmPoiContent(poiContent.id)
                    .then(() => {
                        vm.updateCounter++;
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Edit poi content
         *
         * @param {MouseEvent} $event
         * @param {Object} poiContent
         */
        function editPoiContent($event, poiContent) {
            const page = angular.element('.pagination-page.active a')[0].innerHTML;
            const itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            const data = poiContent;

            $state.go('app.editTrmPoiElement', {data});
        }

        /**
         * Copy poi content
         *
         * @param {MouseEvent} $event
         * @param {Object} poiContent
         */
        function copyPoiContent($event, poiContent) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_CONTENT',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then(() => {
                poiContent.copy = true;
                $state.go('app.addTrmPoiElement', {data: poiContent});
            });
        }

        /**
         * Generates permission name for each button on POI elements accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return PermissionsService.generateTrmPoiElementPermission(button.permissionAction);
        }
    }
})();

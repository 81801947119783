(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timetablePage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/timetable/timetable.tpl.html',
            controller: TimetablePageCtrl,
            bindings: {
                contentData: '=',
                pageData: '<'
            }
        });

    function TimetablePageCtrl(
        $q,
        $scope,
        StorageFactory,
        UtilitiesService,
        ContentDataService,
        PermissionsService,
        CONTENT_TYPES,
        TIMETABLE_TYPE,
    ) {
        const vm = this;
        const { plainResponse } = UtilitiesService;
        const { isPermissionAvailable } = PermissionsService;
        const STORAGE = StorageFactory.Storage('Content-DigitalDisplay');

        vm.timetableTypes = TIMETABLE_TYPE;
        vm.selectedTimetable = null;
        vm.internalTimetables = STORAGE.get('internal-timetables') || [];
        vm.externalTimetables = STORAGE.get('external-timetables') || [];

        init();

        /**
         * Initialization method
         */
        function init() {
            let promises = [];

            if (!vm.internalTimetables.length && isPermissionAvailable('can_view_timetable')) {
                promises.push(
                        ContentDataService.contents({contentTypeId: CONTENT_TYPES.TIMETABLE})
                            .then((response)=>processInternalTimetables(plainResponse(response))));
            }

            if (!vm.externalTimetables.length && isPermissionAvailable('can_use_external_timetable')) {
                promises.push(
                        ContentDataService.getExternalTimetables()
                            .then((response)=>processExternalTimetables(plainResponse(response))));
            }

            $q.all(promises).then(()=>{
                vm.selectedTimetable = findSelectedItem();
            }).catch(console.error.bind(console));
        }

        /**
         * TODO: implement logic for external-timetable
         */
        function onFinish(event, option) {
            if (!vm.selectedTimetable && !vm.selectedTimetable.data) {
                return;
            }
            let timetable = vm.selectedTimetable.data;
            if (vm.selectedTimetable.type === TIMETABLE_TYPE.INTERNAL) {
                vm.pageData.selectedContentId = timetable.id;
                vm.pageData.selectedExternalLink = null;
                vm.pageData.page = Object.assign(vm.pageData.page, timetable.message[timetable.language_id]);
            } else if (vm.selectedTimetable.type === TIMETABLE_TYPE.EXTERNAL) {
                vm.pageData.selectedExternalLink = timetable.url;
                vm.pageData.selectedContentId = null;
                vm.pageData.page.externalLink = timetable.url;
            }
            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        function processInternalTimetables(timetables) {
            vm.internalTimetables = [];
            timetables = timetables.map((item) => {
                item.message = angular.fromJson(item.message);
                item.title = angular.fromJson(item.title);
                return item;
            });

            for (let t of timetables) {
                vm.internalTimetables.push({
                    type: TIMETABLE_TYPE.INTERNAL,
                    title: t.title,
                    language_id: t.language_id,
                    data: t
                })
            }

            STORAGE.set('internal-timetables', vm.internalTimetables);
        }

        function processExternalTimetables(timetables) {
            vm.externalTimetables = [];
            for (let t of timetables) {
                vm.externalTimetables.push({
                    type: TIMETABLE_TYPE.EXTERNAL,
                    title: t.name,
                    data: t
                })
            }

            STORAGE.set('external-timetables', vm.externalTimetables);
        }

        function findSelectedItem() {
            let selectedTimetable = null;
            if (vm.pageData.selectedContentId) {
                selectedTimetable = vm.internalTimetables.find(
                        (item) => vm.pageData.selectedContentId === item.data.id);
            } else if (vm.pageData.selectedExternalLink) {
                selectedTimetable = vm.externalTimetables.find(
                        (item) => vm.pageData.selectedExternalLink === item.data.url);
            }
            return selectedTimetable
                    || vm.internalTimetables[0] || vm.externalTimetables[0];
        }

        $scope.$on('digital-display-savepage', onFinish);
    }
}());

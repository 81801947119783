(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ShowScreenshotPopupController', ShowScreenshotPopupController);

    function ShowScreenshotPopupController(
        $uibModalInstance,
        $translate,
        LocationDataService,
        data
    ) {
        const vm = this;
        const {requestScreenshot, getScreenshot} = LocationDataService;

        vm.data = data;
        vm.timeout = null;
        vm.image = null;
        vm.endTime = null;
        vm.timestamp = null;
        vm.showLoading = true;

        vm.okHandler = okHandler;
        vm.refreshHandler = refreshHandler;

        /**
         * Ok handler
         * @returns {undefined}
         */
        function okHandler() {
            vm.timeout && clearTimeout(vm.timeout);
            $uibModalInstance.close();
        }

        /**
         * Cancel handler
         * @returns {undefined}
         */
        function refreshHandler() {
            const hash = vm.data.hash;
            const WAITING_TIME = 60000; //1 min
            vm.endTime = Number(new Date()) + WAITING_TIME;
            vm.image = null;
            vm.showLoading = true;
            requestScreenshot(hash).then(hash => {
                    getScreenshotPolling(hash);
                }
            );
        }

        /**
         * Get screenshot polling
         * @param hash
         */
        function getScreenshotPolling(hash) {
            vm.timeout && clearTimeout(vm.timeout);
            getScreenshot(hash).then(image => {
                if (image) {
                    processImage(image);
                } else {
                    if(Number(new Date()) < vm.endTime) {
                        vm.timeout = setTimeout(() => getScreenshotPolling(hash), 2500);
                    } else {
                        vm.showLoading = false;
                    }
                }
            })
        }

        /**
         * Process image
         * @param image
         */
        function processImage(image) {
            vm.image = image;
            vm.showLoading = false;
            const start = (image.indexOf('_') + 1);
            const end = image.indexOf('.png');
            const timestamp = image.substring(start, end);
            vm.timestamp = moment.utc(timestamp * 1000).local().format('DD MMMM YYYY HH:mm:ss');
        }

        /**
         * Init
         * Loads data required for popup
         * @returns {undefined}
         */
        function init() {
            const hash = vm.data.hash;
            getScreenshot(hash).then(image =>
                image ? processImage(image) : refreshHandler()
            );
        }

        vm.$onInit = init;
    }
}());

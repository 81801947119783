(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LegalTextDataService', LegalTextDataService);

    function LegalTextDataService(
        Restangular,
    ) {
        return {
            getLegalTexts,
            createLegalText,
            updateLegalText,
            deleteLegalText,
        };

        function getLegalTexts(data) {
            return Restangular.all('sharePark/legal-text').getList(data);
        }

        function createLegalText(data) {
            return Restangular.all('sharePark/legal-text')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteLegalText(id) {
            return Restangular.one('sharePark/legal-text', id).remove();
        }

        function updateLegalText(legalText, data) {
            data.append('_method', 'PUT');
            return legalText.customPOST(data, '', {}, { 'Content-Type': undefined });
        }
    }
})();
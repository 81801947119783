(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('pagesEditorToolbar', pagesEditorToolbar);

    function pagesEditorToolbar() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/toolbar/toolbar.tpl.html',
            replace: true,
            controller: PagesEditorToolbarController,
            controllerAs: 'pagesEditorToolbar',
            bindToController: true,
            scope: {
                templates: '=templates',
                selected: '=selected',
                pages: '=pages'
            }
        };
    }

    function PagesEditorToolbarController($scope) {}
}());

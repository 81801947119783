(function() {
    'use strict';

    angular.module('beacon.app')
        .component('multiGuide', {
            templateUrl: '/assets/views/share-park/operations/multi-guide/multi-guide.tpl.html',
            controller: ShareParkMultiGuideController
        });

    function ShareParkMultiGuideController(
        $scope,
        MultiGuideApiService,
        $q,
        ShareParkHelperService,
    ) {
        const vm = this;
        vm.selectedCapacityProvider = null;
        vm.onCarParkChange = onCarParkChange;

        const ACTIONS = {
            SET_NORMAL: 'MULTI_GUIDE_ACTION_SET_NORMAL',
            SET_ELECTRIC: 'MULTI_GUIDE_ACTION_SET_ELECTRIC',
            SET_HANDICAPPED: 'MULTI_GUIDE_ACTION_SET_HANDICAPPED',
            SET_RESERVED: 'MULTI_GUIDE_ACTION_RESERVE',
            SET_NOT_RESERVED: 'MULTI_GUIDE_ACTION_REMOVE_RESERVATION',
            ACTIVATE: 'MULTI_GUIDE_ACTION_ACTIVATE',
            DEACTIVATE: 'MULTI_GUIDE_ACTION_DEACTIVATE',
        };

        vm.multiGuideItems = [];
        vm.multiGuideItemsGrouped = [];
        vm.selected = [];

        vm.$onInit = init;
        vm.onItemClick = selectItem;
        vm.onSelectedClick = removeItem;
        vm.isSelected = isSelected;
        vm.onSendClick = onSendClick;
        vm.onClearClick = onClearClick;
        vm.onRefreshClick = onRefreshClick;

        /**
         * @type {MultiGuideLot[]}
         */

        vm.action = ACTIONS.SET_NORMAL;
        vm.actions = ACTIONS;

        function init() {
            loadData();
        }

        function loadData() {
            MultiGuideApiService.getCapacityProviders()
                .then(capacityProviders => {
                    vm.capacityProviders = getUniqueProvidersByCarPark(capacityProviders);
                })
        }

        /**
         * Ignore every next provider with the same carParkRef
         * 
         * @param {CapacityProvider[]} capacityProviders 
         * @returns {CapacityProvider[]}
         */
        function getUniqueProvidersByCarPark(capacityProviders) {
            return capacityProviders.filter((provider, index, self) => {
                const isUniqueByCarPark = self.findIndex(item => item.carParkRef === provider.carParkRef) === index;
                return provider.serviceProvider === 'MULTI_GUIDE' && isUniqueByCarPark;
            }).sort((a, b) => a.name.localeCompare(b.name));
        }

        function onCarParkChange() {
            MultiGuideApiService.getLotsInfo(vm.selectedCapacityProvider)
                .then(result => {
                    if (result.length) {
                        vm.multiGuideItems = result;
                        vm.multiGuideItemsGrouped = groupItems(result);
                        vm.selected = vm.selected.map(selectedItem => {
                            return vm.multiGuideItems.find(item => item.id === selectedItem.id);
                        })
                    } else {
                        vm.multiGuideItemsGrouped = [];
                        vm.selected = [];
                    }
                }).catch((err) => {
                    console.error(err);
                    vm.multiGuideItemsGrouped = [];
                    vm.selected = [];
                })
        }

        function selectItem(lot) {
            if (isSelected(lot)) {
                removeItem(lot);
                return;
            }
            vm.selected.push(lot);
        }

        function removeItem(lot) {
            vm.selected = vm.selected.filter(item => item.id !== lot.id);
        }

        function isSelected(lot) {
            return vm.selected.find(item => item.id === lot.id);
        }

        function onSendClick() {
            switch (vm.action) {
                case ACTIONS.SET_NORMAL:
                    $q.all(generateChangeTypePromises(vm.selected, 1)).then(onActionDone);
                    break;
                case ACTIONS.SET_ELECTRIC:
                    $q.all(generateChangeTypePromises(vm.selected, 4)).then(onActionDone);
                    break;
                case ACTIONS.SET_RESERVED:
                    $q.all(generateChangeReservationPromises(vm.selected, true)).then(onActionDone);
                    break;
                case ACTIONS.SET_NOT_RESERVED:
                    $q.all(generateChangeReservationPromises(vm.selected, false)).then(onActionDone);
                    break;
            }

            function onActionDone() {
                loadData();
            }

            function generateChangeTypePromises(lots, lotTypeId) {
                return lots.map(lot => MultiGuideApiService.setLotType(lot.id, lotTypeId));
            }

            function generateChangeReservationPromises(lots, reserved = true) {
                return lots.map(lot => MultiGuideApiService.setReserved(lot.id, reserved));
            }
        }

        function onClearClick() {
            vm.selected = [];
        }

        function onRefreshClick() {
            loadData();
        }

        /**
         * @param {MultiGuideLot[]} items
         * @return {{name: string, free: number, items: MultiGuideLot[]}[]}
         */
        function groupItems(items) {
            const groupsMap = {};
            items.forEach(item => {
                const groupName = item.levelAreaName;
                if (groupsMap[groupName]) {
                    groupsMap[groupName].items.push(item);
                } else {
                    groupsMap[groupName] = {
                        name: groupName,
                        items: [item]
                    };
                }
            });
            const groups = orderByName(groupsMap);

            groups.forEach(group => {
                group.items = orderByName(group.items);
                group.free = group.items.filter(item => item.available).length;
                group.total = group.items.length;
                /**
                 * Simulation for testing
                 */
                // group.items.forEach((item, index) => {
                //     if (index % 5 === 1) {
                //         item.reserved = true;
                //     }
                //
                //     if (index % 7 === 1) {
                //         item.alarming = true;
                //     }
                // })
            })
            return groups;
        }

        function orderByName(arr) {
            return Object.values(arr).sort((a, b) => {
                return Number(a.name) - Number(b.name);
            });
        }
    }
})();
(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.controller('ContentToolbarController', ContentToolbarController);

    function ContentToolbarController(
        $scope,
        $state,
        $stateParams,
        $translate,
        ContentHelper,
        StatesFactory,
        StorageFactory,
        PermissionsService,
        CONTENT_TYPES,
        CONTENT_TYPES_RELATED_TO_BANNERS,
    ) {
        const vm = this;

        const {
            generateContentToolbarPermission,
            generateContentPermission,
            isPermissionAvailable,
            actions,
        } = PermissionsService;

        // public properties
        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ContentStates);
        vm.stateAss.state.finish = 0;
        vm.stateAss.state.finishAndManage = 0;
        vm.stateAss.state.finishAndAddBanner = 0;

        vm.contentTypeId = null;

        // public methods
        vm.next = next;
        vm.back = back;
        vm.cancel = cancel;
        vm.finish = finish;
        vm.showFinishAndManage = showFinishAndManage;
        vm.canRelateToBanner = canRelateToBanner;
        vm.finishAndManage = finishAndManage;
        vm.finishAndAddBanner = finishAndAddBanner;

        vm.goToTab = goToTab;
        vm.getTabClass = getTabClass;

        vm.generatePermissionName = generateContentToolbarPermission;

        /**
         * Update tabs:
         * 1. Get views
         * 2. Slice 3 tab:
         *      2.1. Previous, Active, Next
         *      2.2. Active, Next, Next
         */
        function updateTabs() {
            let tabs = Object.keys(vm.stateAss.state.views);
            let first = Math.max(vm.stateAss.state.view - 2, 0);
            let last = Math.min(vm.stateAss.state.last + 1, first + 3);

            vm.tabs = tabs.slice(first, last);
        }

        /**
         * Switches to selected tab
         *
         * @param {number} tab
         */
        function goToTab(tab) {
            vm.stateAss.state.go(tab);
            updateTabs();
        }

        /**
         * Returns tab class
         * @param {Number} tab
         * @return {String}
         */
        function getTabClass(tab) {
            if (!(tab in vm.stateAss.state.views)) {
                return;
            }

            return vm.stateAss.state.is(tab)
                ? 'active'
                : vm.stateAss.state.view < vm.stateAss.state.views[tab][0]
                    ? 'disabled'
                    : 'done';
        }

        /**
         * Set or delete child state
         */
        function updateChild() {
            let contentData = StorageFactory.Storage('Content').get('contentData');
            if (contentData) {
                vm.contentTypeId = contentData.content_type_id;

                switch (contentData.content_type_id) {
                    case CONTENT_TYPES.DIGITAL_DISPLAY:
                        vm.stateAss.child = StatesFactory.DigitalDisplayStates;
                        break;
                    case CONTENT_TYPES.QUIZ:
                    case CONTENT_TYPES.FEEDBACK:
                        vm.stateAss.child = StatesFactory.QuizStates;
                        break;
                    case CONTENT_TYPES.BANNER:
                        vm.stateAss.child = StatesFactory.BannerStates;
                        break;
                    default:
                        delete vm.stateAss.child;
                }
            }
        }

        /**
         * Sets next view
         */
        function next() {
            updateChild();

            vm.stateAss.state.next();
            updateTabs();
        }

        /**
         * Sets previous view
         */
        function back() {
            if (vm.stateAss.state.page && vm.stateAss.state.page.type === 'edit') {
                vm.stateAss.state.go('PAGES_LIST', true);
            } else {
                vm.stateAss.state.back();
            }

            updateTabs();
        }

        /**
         * Sets previous view
         */
        function cancel() {
            if ($stateParams.redirect) {
                $state.go($stateParams.redirect);
                return;
            }

            if (vm.stateAss.isParent() || vm.stateAss.state.type === 'edit') {
                $state.go('app.content', { paginationData: $stateParams.paginationData });
            } else {
                vm.stateAss.back();
            }
            updateTabs();
        }

        /**
         * Saves content
         */
        function finish() {
            vm.stateAss.parent.finish++;
        }

        /**
         * Can show the "Finish & Manage" button
         *
         * @return {boolean}
         */
        function showFinishAndManage() {
            return vm.stateAss.state.showFinish() &&
                ContentHelper.canRelateToCampaign(vm.contentTypeId);
        }

        /**
         * Checks is content can relate to banner
         */
        function canRelateToBanner() {
            const permissions = [actions.view, actions.modify].map(action =>
                generateContentPermission(action, CONTENT_TYPES.BANNER)
            );

            return permissions.some(permission => isPermissionAvailable(permission)) &&
                vm.stateAss.state.showFinish() &&
                CONTENT_TYPES_RELATED_TO_BANNERS.includes(vm.contentTypeId);
        }

        /**
         * Saves content
         */
        function finishAndManage() {
            vm.stateAss.parent.finishAndManage++;
        }

        /**
         * Saves content
         */
        function finishAndAddBanner() {
            vm.stateAss.parent.finishAndAddBanner++;
        }

        $scope.$watch(angular.bind(vm, function() {
            return typeof StorageFactory.Storage('Content') === 'object';
        }), function(newValue) {
            if (newValue) {
                updateChild();
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return typeof this.stateAss.state.views === 'object';
        }), function(newValue) {
            if (newValue) {
                updateTabs();
            }
        });
    }
}());

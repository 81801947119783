(function() {
    'use strict';

    angular.module('beacon.app')
        .component('infotainmentEditor', {
            controller: infotainmentEditorController,
            templateUrl: '/assets/views/common/components/infotainment-editor/infotainment-editor.tpl.html',
            bindings: {
                formObject: '<',
                label: '@',
                name: '@',
                required: '<',
                model: '=',
                infotainmentType: '=',          // eg. 1 - basic view, 2 - extended view
                editorType: '=',                // eg. 1 - WYSIWYG editor, 2 - HTML editor
                maxLength: '<',
                showErrors: '<',
                contentTypeId: '<',
                infotainmentUseCase: '=',
                useCaseMedia: '=',
            },
        });

    function infotainmentEditorController(INFOTAINMENT_TYPES, INFOTAINMENT_TYPE_EXTENDED_EDITORS, CONTENT_TYPES,
                                          TEXT_ANGULAR_OPTIONS_EXTENDED,
                                          ContentDataService, PermissionsService, UtilitiesService) {
        const vm = this;

        const { decodeConfigString } = UtilitiesService;

        vm.INFOTAINMENT_TYPES = INFOTAINMENT_TYPES;
        vm.EXTENDED_EDITOR_TYPES = INFOTAINMENT_TYPE_EXTENDED_EDITORS;

        vm.$onInit = init;

        vm.setInfoType = setInfoType;
        vm.isInfoType = isInfoType;
        vm.isEditorType = isEditorType;
        vm.isAvailableType = isAvailableType;
        vm.isReadonlyType = isReadonlyType;
        vm.isVisibleTabPanel = isVisibleTabPanel;
        vm.onUseCaseChange = onUseCaseChange;

        vm.TEXT_ANGULAR_OPTIONS_EXTENDED = TEXT_ANGULAR_OPTIONS_EXTENDED;

        vm.TYPES = _makeInfoTypeMap();

        const PERMISSIONS_MAP = {
            [vm.TYPES.EXTENDED]: {
                [CONTENT_TYPES.INFOTAINMENT]: 'html_infotainment',
                [CONTENT_TYPES.FEEDBACK]: 'html_feedback',
                [CONTENT_TYPES.QUIZ]: 'html_quiz',
            },
            [vm.TYPES.FIXED]: {
                [CONTENT_TYPES.FEEDBACK]: 'smart_feedback',
            }
        };

        function init() {
            if (!vm.infotainmentType) {
                vm.infotainmentType = vm.INFOTAINMENT_TYPES.DEFAULT.id;
            }

            vm.infoTypeBeforeEdit = vm.infotainmentType;

            if (!vm.editorType) {
                vm.editorType = vm.EXTENDED_EDITOR_TYPES.WYSIWYG.id;
            }
            if (vm.contentTypeId === CONTENT_TYPES.FEEDBACK) {
                loadConfigs();
            }
        }

        /**
         * Load and set content configs to scope
         *
         * @private
         */
        function loadConfigs() {

            const FEEDBACK_CONFIG_TYPE = 2;
            ContentDataService.getContentConfigs()
                .then(response => {
                    vm.contentUseCases = response.plain()
                        .filter(item => item.configType === FEEDBACK_CONFIG_TYPE)
                        .map(item => {
                            item.parameters = decodeConfigString(item.parameters);
                            return item;
                        });
                    setDefaultUseCase();
                });
        }

        /**
         * If useCase missing - set first as default
         */
        function setDefaultUseCase() {
            if (vm.infotainmentUseCase) { return; }

            const defaultUseCase = vm.contentUseCases[0];

            if (!defaultUseCase) { return; }
            
            vm.infotainmentUseCase = defaultUseCase.parameters.location;
            vm.useCaseMedia = defaultUseCase.mediaRef;
        }

        /**
         * Sets infotainment type
         *
         * @param value
         */
        function setInfoType(value) {
            vm.infotainmentType = value;
        }

        /**
         * Handles use case change
         */
        function onUseCaseChange() {
            const selectedUseCase = vm.contentUseCases.find(useCase =>
                useCase.parameters.location === vm.infotainmentUseCase
            );
            vm.useCaseMedia = selectedUseCase.mediaRef;
        }

        /**
         * Checks if current infotainment is specific type
         *
         * @param infoTypeId
         * @return {boolean}
         */
        function isInfoType(infoTypeId) {
            return vm.infotainmentType === infoTypeId;
        }

        /**
         * Checks if current editor is specific type
         *
         * @param editorTypeId
         * @return {boolean}
         */
        function isEditorType(editorTypeId) {
            return vm.editorType === editorTypeId;
        }

        /**
         * Checks if content type is available for user
         * (available if permitted or message is filled)
         *
         * @param {number} infotainmentType
         * @return {boolean}
         */
        function isAvailableType(infotainmentType) {
            return vm.model && vm.infoTypeBeforeEdit === infotainmentType || _isPermittedType(infotainmentType);
        }

        /**
         * Checks if this type of infotainment is readonly for user
         *
         * @param {number} infotainmentType
         * @return {boolean}
         */
        function isReadonlyType(infotainmentType) {
            return isAvailableType(infotainmentType) && !_isPermittedType(infotainmentType);
        }

        /**
         * Tabs is visible when any of infotainment types is available
         *
         * @return {boolean}
         */
        function isVisibleTabPanel() {
            return Object.keys(PERMISSIONS_MAP).some(infoType => isAvailableType(Number(infoType)));
        }

        /**
         * Makes simple infotainmentTypes constant map
         *
         * @return {object}
         * @private
         */
        function _makeInfoTypeMap() {
            const types = angular.copy(INFOTAINMENT_TYPES);
            Object.keys(types).forEach(typeName => {
                types[typeName] = types[typeName].id;
            });
            return types;
        }

        /**
         * Checks if content type is
         *
         * @param {number} infotainmentType
         * @return {boolean}
         * @private
         */
        function _isPermittedType(infotainmentType) {
            const permission = PERMISSIONS_MAP[infotainmentType][vm.contentTypeId];
            return !!PermissionsService.isPermissionAvailable(permission);
        }
    }
})();
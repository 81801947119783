(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tagsEditor', {
            controller: tagsEditorController,
            templateUrl: '/assets/views/common/components/tags-editor/tags-editor.tpl.html',
            bindings: {
                callback: '<',
                selectedTags: '<',
            },
        });

    function tagsEditorController($scope, $timeout, TagService, TagHelper) {
        const vm = this;

        vm.editMode = false;
        vm.searchText = null;
        vm.querySearch = querySearch;
        vm.transformChip = transformChip;
        vm.save = save;
        vm.edit = edit;
        vm.generateTagsList = generateTagsList;

        vm.$onInit = init;

        /**
         * Constructor
         */
        function init() {
            TagHelper.loadTags()
                .then(tags => {
                    vm.tagsAll = tags;
                });
        }

        /**
         * Handler for save button:
         * 1) save new tags
         * 2) send selected tags into component callback
         */
        function save() {
            const oldTags = vm.selectedTags.filter(tag => tag.id);
            const newTags = vm.selectedTags.filter(tag => !tag.id);

            if (newTags.length) {
                TagService.create(newTags)
                    .then(response => {
                        vm.selectedTags = response.plain().concat(oldTags);
                        vm.callback(vm.selectedTags);
                    });
            } else {
                vm.callback(vm.selectedTags);
            }
            vm.editMode = !vm.editMode;
        }

        /**
         * Handler for edit button
         */
        function edit() {
            vm.editMode = !vm.editMode;
        }

        /**
         * Generates sting from tags list
         *
         * @returns {string}
         */
        function generateTagsList() {
            const tagNames = vm.selectedTags.map(tag => '#' + tag.name);
            return tagNames.join(', ');
        }

        /**
         * Return the proper object when the append is called.
         *
         * @param {object|string} chip
         * @returns {object}
         */
        function transformChip(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
            if (vm.selectedTags.find(tag => tag.name === chip)) {
                return null;
            }
            return {
                name: chip
            };
        }

        /**
         * Search for tags.
         */
        function querySearch(query) {
            const lowercaseQuery = query.toLowerCase();
            return query
                ? vm.tagsAll.filter(
                    item => item._lowername.indexOf(lowercaseQuery) !== -1
                ) : [];
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentCampaignsToolbarController', ContentCampaignsToolbarController);

    function ContentCampaignsToolbarController($rootScope, $state) {
        const vm = this;

        vm.save = function () {
            $rootScope.$broadcast('saveClick');
        };

        vm.cancel = function () {
            $state.go('app.content');
        };
    }
}());
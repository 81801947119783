(function () {
    'use strict';

    angular.module('beacon.app')
        .component('doubleColorPicker', {
            templateUrl: '/assets/views/common/components/double-color-picker/double-color-picker.tpl.html',
            controller: DoubleColorPickerController,
            bindings: {
                label: '@',
                colorFirst: '=',
                colorSecond: '=',
            }
        });

    /**
     * @property {string} label
     * @property {string} colorFirst
     * @property {string} colorSecond
     *
     * @constructor
     */
    function DoubleColorPickerController() { }
})();

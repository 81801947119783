(function () {
    'use strict';

    angular
        .module('beacon.app')
        .service('ProfileRulesDialogService', ProfileRulesDialogService);

    function ProfileRulesDialogService($http, $mdDialog) {
        return {
            showServiceSelection,
        };

        function showServiceSelection(event, profileRulesListData) {
            let selectedVersion = profileRulesListData[0].version;

            return $mdDialog.show({
                controller: DialogController,
                locals: { "profileRulesList": profileRulesListData, 'selectedVersion': selectedVersion },
                templateUrl: '/assets/views/share-park/customers/profile-rules/dialog/dialog.tpl.html',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: false,
            })

            function DialogController($scope, $mdDialog, profileRulesList, selectedVersion) {
                $scope.profileRulesList = profileRulesList;
                $scope.selectedVersion = selectedVersion;
                $scope.hide = function () {
                    $mdDialog.hide();
                };
                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
                $scope.version = function (version) {
                    $mdDialog.hide(version);
                };
            }
        }

    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('chargingStationsForm', {
            templateUrl: '/assets/views/share-park/charging-stations/form/charging-stations-form.tpl.html',
            controller: ChargingStationsFormController
        });

    function ChargingStationsFormController(
        $q,
        $scope,
        $timeout,
        $state,
        $stateParams,
        ChargingStationDataService,
        LanguageService,
        ModelsFactory,
        UtilitiesService,
        ImageHelper,
        FileService,
        ShareParkDataService,
        StatesFactory,
    ) {
        const vm = this;
        const isEditMode = $stateParams.type === 'edit';

        vm.state = StatesFactory.ShareParkChargingStationStates.refresh();
        vm.imageBlob = null;
        vm.lang = null;
        vm.form = null;
        vm.langArray = [];
        vm.plugTypes = [];
        vm.chargingStation = new ModelsFactory.ChargingStation();
        vm.statuses = [
            {
                id: 0,
                name: 'INACTIVE',
            },
            {
                id: 1,
                name: 'ACTIVE',
            }
        ];

        vm.paymentTypes = [
            {
                id: 1,
                name: 'CARD',
            },
            {
                id: 2,
                name: 'APP',
            }
        ];

        vm.$onInit = init;
        vm.onImageChange = onImageChange;
        vm.onImageDelete = onImageDelete;

        function init() {
            $timeout(setErrorChecker);

            if ($stateParams.data) {
                vm.chargingStation = $stateParams.data;
            }

            if ($stateParams.copy) {
                FileService.isFromS3(vm.chargingStation.image).then(isFromS3 => {
                    isFromS3 && ImageHelper.remoteImageToBlob(vm.chargingStation.image)
                        .then(blob => onImageChange(blob, true));
                });
            }

            LanguageService.getLanguages()
                .then(response => {
                    vm.langArray = response.plain();
                    vm.lang = vm.langArray[0];
                });

            ChargingStationDataService.plugTypes()
                .then(response => {
                    vm.plugTypes = response.plain().sort((a, b) => a.plugLabel.localeCompare(b.plugLabel));
                });

            ShareParkDataService.getCarParksList().then(response => {
                vm.carParks = response.plain().sort((a, b) => a.name.localeCompare(b.name));
            });
        }

        function setErrorChecker() {
            vm.showError = UtilitiesService.inputValidator(vm.form);
        }

        /**
         * Image file input handler
         *
         * @param {Blob} imageFile
         * @param {boolean} uploadToS3
         */
        function onImageChange(imageFile, uploadToS3) {
            vm.imageBlob = imageFile;
            vm.chargingStation.image = uploadToS3
                ? ImageHelper.blobToUrl(vm.imageBlob)
                : vm.imageBlob.name;
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            vm.chargingStation.image = null;
        }

        function onFinish() {
            if (vm.imageBlob) {
                ImageHelper.uploadImage(vm.imageBlob)
                    .then(imageUrl => {
                        vm.chargingStation.image = imageUrl;
                        saveChargingStation();
                    });
            } else {
                saveChargingStation();
            }
        }

        function saveChargingStation() {
            const method = isEditMode ? 'edit' : 'create';
            ChargingStationDataService[method](vm.chargingStation)
                .then(() => {
                    $state.go('app.chargingStations');
                });
        }

        $scope.$watch(
            () => vm.state.finish,
            finish => finish && onFinish(),
        );

        $scope.$watch(
            () => vm.form && vm.form.$valid,
            isFormValid => {
                vm.state.canFinish = isFormValid;
            },
        )
    }
})();
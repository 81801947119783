(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ContentDataProcessingService', contentDataProcessingService);

    function contentDataProcessingService(ContentHelper) {

        const { isFixedInfoType } = ContentHelper;

        return {
            processDefaultContent,
            processContentData,
            processFeedbackContent
        };

        /**
         *
         * @param formData
         * @param originalContentData
         * @param contentForm
         */
        function processDefaultContent(formData, originalContentData, contentForm) {
            let contentData = angular.copy(originalContentData);

            if (contentData.language_sensitive_audio) {
                delete contentData.audio;

                for (let prop in originalContentData.audio) {
                    if (originalContentData.title[prop]) {
                        formData.append(`audio[${prop}]`, originalContentData.audio[prop]);
                    }
                }
            } else if (originalContentData.audio) {
                formData.append('audio', originalContentData.audio);
            }

            contentData.title = JSON.stringify(originalContentData.title);
            contentData.message = JSON.stringify(originalContentData.message);

            if (originalContentData.map_info && originalContentData.map_info.length) {
                contentData.map_info = JSON.stringify(originalContentData.map_info);
            }

            if (originalContentData.language_sensitive_link) {
                contentData.web_link = JSON.stringify(originalContentData.web_link);
            }

            return contentData;
        }

        /**
         * Processes content data for default content & POI content
         */
        function processContentData(contentData) {
            const emptyAudioData = _.isEmpty(contentData.audio) && !(contentData.audio instanceof File);
            const emptyAudioSources = _.isEmpty(contentData.audio_src);
            if (emptyAudioData) {
                delete contentData.audio;
            }
            if (emptyAudioSources) {
                delete contentData.audio_src;
            }
            // language_sensitive_audio can be true only if we have audio set for some of languages
            if (emptyAudioData && emptyAudioSources) {
                contentData.language_sensitive_audio = 0;
            }

            if (contentData.language_sensitive_link && _.isEmpty(contentData.web_link)) {
                contentData.language_sensitive_link = 0;
                contentData.web_link = '';
            }
        }

        /**
         * Processes content data for feedback
         * @param {object} originalContentData
         * @return {object}
         */
        function processFeedbackContent(originalContentData) {
            const contentData = angular.copy(originalContentData);

            if (isFixedInfoType(contentData)) {
                contentData.message.message = {};
                contentData.image_src = null;
                delete contentData.image;
            } else {

                // Title can be hidden for fixed feedback only
                contentData.message.quiz.questions.forEach(question => delete question.hideTitle);
                delete contentData.data.useCase;
                delete contentData.data.useCaseMedia;
            }
            return contentData;
        }

    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('contentPagesList', contentPagesList);

    function contentPagesList() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/content-pages-list/content-pages-list.tpl.html',
            replace: true,
            controller: ContentPagesListController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                pages: '=',
                canEdit: '<?',
                minified: '<?',
                showCheckboxes: '<?'
            }
        };
    }

    function ContentPagesListController($scope, $rootScope, $timeout,
                                        PopupService, UtilitiesService,
                                        PAGE_TYPES, PAGE_TYPE_TITLES, SPEEDDIAL_MAX_PAGE_COUNT, VIEW_TYPES) {
        const vm = this;
        const { oneOfPageTypes, getKeyByValue } = UtilitiesService;

        vm.oneOfPageTypes = oneOfPageTypes;

        vm.VIEW_TYPES = VIEW_TYPES;
        vm.editPage = editPage;
        vm.deletePage = deletePage;
        vm.orderChanged = orderChanged;
        vm.itemClickHandler = itemClickHandler;
        vm.getPageTypeTitle = getPageTypeTitle;

        function itemClickHandler(item) {
            if (!vm.showCheckboxes) {
                return;
            }

            let filteredPages = vm.pages.filter((item) => item.selected);
            if (filteredPages.length < SPEEDDIAL_MAX_PAGE_COUNT || item.selected) {
                item.selected = !item.selected;
            }
        }

        /**
         * Get page title by page type
         * @param  {Number} type
         * @return {String}
         */
        function getPageTypeTitle(type) {
            return PAGE_TYPE_TITLES[getKeyByValue(PAGE_TYPES, type)];
        }

        // Buttons events
        function editPage($event, index) {
            if (!vm.canEdit) {
                return;
            }

            $timeout(function() {
                $rootScope.$broadcast('page-edit', { index: index });
            }, 0);
        }

        function deletePage($event, index) {
            if (!vm.canEdit) {
                return;
            }

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_PAGE',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then(() => {
                $timeout(function() {
                    $rootScope.$broadcast('page-delete', { index: index });
                }, 0);
            });
        }

        function orderChanged() {
            $timeout(function() {
                $rootScope.$broadcast('page-order', {});
            }, 0);
        }
    }
}());

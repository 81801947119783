(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newProduct', {
            templateUrl: '/assets/views/share-park/pricing/products/new/new-product.tpl.html',
            controller: NewProductController
        });

    function NewProductController(
        $scope,
        $state,
        $stateParams,
        $translate,
        LanguageService,
        ModelsFactory,
        PricingDataService,
        StatesFactory,
        QUOTA_UNIT_TYPES,
    ) {
        const vm = this;

        const currentLocale = $translate.use();
        vm.language_code = currentLocale;

        vm.QUOTA_UNIT_TYPES = QUOTA_UNIT_TYPES;
        vm.state = StatesFactory.ProductStates.refresh();
        vm.state.type = $stateParams.type;
        vm.rates = [];
        vm.selectedRates = [];
        vm.isSelectedTime = {
            start: false,
            end: false
        }
        vm.ratesListData = {
            columns: [
                {
                    name: 'NAME',
                    width: '55',
                    title: item => item.name[currentLocale] || Object.values(item.name)[0],
                },
                {
                    name: 'PRICE',
                    width: '45',
                    title: item => item.priceLabel[currentLocale] || Object.values(item.priceLabel)[0],
                },
            ],
            header: false,
        };

        vm.$onInit = init;

        vm.showInputError = showInputError;
        vm.isActive = isActive;
        vm.changeProductStatus = changeProductStatus;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.productData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.Product();

            vm.isSelectedTime.start = vm.productData.params.dayStart ? true : false;
            vm.isSelectedTime.end = vm.productData.params.dayBreak ? true : false;
            LanguageService.getLanguages().then((response) => {
                const filledLanguages = Object.keys(vm.productData.name);
                vm.langArray = response.plain();

                if (filledLanguages.length && !filledLanguages.includes(vm.language_code)) {
                    vm.language_code = filledLanguages[0];
                }
            });

            PricingDataService.getAllTariffs()
                .then(response => {
                    vm.rates = response.plain().map(rate => {
                        rate.name = angular.fromJson(rate.name);
                        rate.priceLabel = angular.fromJson(rate.priceLabel);

                        return rate;
                    });

                    if (vm.isEditMode) {
                        vm.selectedRates = vm.rates.filter(rate =>
                            rate.capProductAssignment.some(assignment =>
                                assignment.capProductRef.id === vm.productData.id
                            )
                        )
                    }
                });
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.newProductForm
                && vm.newProductForm[name].$dirty
                && vm.newProductForm[name].$invalid;
        }

        function isActive() {
            const ACTIVE = 1;
            return vm.productData.status === ACTIVE;
        }

        function changeProductStatus() {
            const ACTIVE = 1;
            const INACTIVE = 2;

            vm.productData.status = isActive() ? INACTIVE : ACTIVE;
        }

        /**
         * On finish handler
         */
        function onFinish() {
            const formData = new FormData();

            const removedAssignments = [];
            if (!vm.isSelectedTime.start || !vm.productData.params.dayStart) delete vm.productData.params.dayStart;
            if (!vm.isSelectedTime.end || !vm.productData.params.dayBreak) delete vm.productData.params.dayBreak;

            const productData = angular.copy(vm.productData);

            productData.name = angular.toJson(productData.name);
            productData.description = angular.toJson(productData.description);
            productData.params = angular.toJson(productData.params);

            const rateAssignments = vm.selectedRates.map(rate => {
                const assignmentData = {
                    capRateRef: rate.id
                };

                if (vm.isEditMode) {
                    const relatedAssignment = rate.capProductAssignment.find(assignment =>
                        assignment.capProductRef.id === vm.productData.id
                    );

                    if (relatedAssignment) {
                        assignmentData.id = relatedAssignment.id;
                    }
                }

                return assignmentData;
            });

            if (vm.isEditMode) {
                vm.rates.forEach(rate => {
                    rate.capProductAssignment.forEach(assignment => {
                        if (assignment.capProductRef.id === vm.productData.id
                            && !rateAssignments.some(({id}) => id === assignment.id)) {
                            removedAssignments.push({
                                id: assignment.id
                            })
                        }
                    })
                })
            }

            formData.append('productData', angular.toJson(productData));
            formData.append('rateAssignments', angular.toJson(rateAssignments));
            formData.append('removedAssignments', angular.toJson(removedAssignments));

            PricingDataService.createProduct(formData)
                .then(onSuccess)
        }

        function onSuccess() {
            $state.go('app.shareParkProducts', {
                paginationData: $stateParams.paginationData
            });
        }

        $scope.$watch(function() {
            return vm.newProductForm && vm.newProductForm.$valid;
        }, formValid => vm.state.canFinish = formValid);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('elementProduct', {
            templateUrl: '/screen/views/components/timetable-hafas/elements/element-product/element-product.tpl.html',
            controller: ElementProductController,
            bindings: {
                journey: '<',
                element: '<',
                height: '<',
            }
        });

    /**
     * @property {Journey} journey
     * @property {TimetableHafasElement} element
     * @property {string} height - i.e. "100px"
     * @constructor
     */
    function ElementProductController() {
        const $ctrl = this;

        $ctrl.TYPE = {
            ICON_SET_1: 1,
            ICON_SET_2: 2,
            TEXT_LONG: 3,
            TEXT_SHORT: 4,
        };

        $ctrl.$onInit = () => {
            $ctrl.gap = (angular.isNumber($ctrl.element.gap) ? $ctrl.element.gap : 0.15) + 'em';
        }
    }
})();

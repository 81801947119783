(function() {
    'use strict';

    angular.module('beacon.app')
        .component('habitAreasDetails', {
            templateUrl: '/assets/views/campaign/habit/tabs/details/areas/areas.tpl.html',
            controller: HabitAreasDetails,
            bindings: {
                model: '='
            }
        });

    function HabitAreasDetails(
        $scope,
        $stateParams,
        habitEditState,
        GeofenceDataService
    ) {
        const vm = this;

        vm.geofences = [];

        vm.state = habitEditState;

        vm.$onInit = init;

        vm.polyline = '';

        vm.checkboxListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'checkbox-list-item-title',
                    width: '65',
                    title: 'name',
                },
            ],
            header: false,
            itemClickCallback: updatePolyline
        };

        /**
         * Update Polyline
         */
        function updatePolyline() {
            vm.polyline = vm.model.selectedGeofences
                .map(geofence => geofence.polyline)
                .join("|");
        }


        /**
         * Initialization method
         */
        function init() {

            vm.state.type = $stateParams.type;

            vm.model.selectedGeofences = [];

            GeofenceDataService.getGeofences().then(response => {
                vm.geofences = response;
                vm.model.selectedGeofences = vm.model.typeRef.split(',').map(id =>
                    vm.geofences.find(geofence =>
                        geofence.externalId === Number(id)
                    )
                ).filter(e => e);
                updatePolyline();
            });

            bindWatchers();
        }

        /**
         * Can finish
         */
        function canFinish() {
            vm.state.canFinish = vm.model.name
                && vm.model.description
                && !!vm.model.selectedGeofences.length;

        }

        /**
         * Bind watchers
         */
        function bindWatchers() {
            $scope.$watch(
                () => vm.model.selectedGeofences.length,
                () => canFinish()
            );

            $scope.$watch(
                () => vm.model.name,
                () => canFinish()
            );

            $scope.$watch(
                () => vm.model.description,
                () => canFinish()
            );
        }
    }

}());

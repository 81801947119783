(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LoyaltyCampaignsFormController', LoyaltyCampaignsFormController);

    function LoyaltyCampaignsFormController(
        $scope, 
        $state, 
        StatesFactory, 
        StorageFactory,
        ModelsFactory, 
        LoyaltyCampaignService, 
        $stateParams,
        ImageProcessorService,
    ) {
        const vm = this;
        vm.state = StatesFactory.LoyaltyCampaignStates.refresh();

        init();

        /**
         * Finish handler
         */
        function onFinish() {
            let data, formData, serviceAction = null;
            ImageProcessorService.run().then(imageUrl => {
                vm.data.imageRef = imageUrl || vm.data.imageRef;
                data = angular.copy(vm.data);
                formData = new FormData();
                formData.append('json', JSON.stringify(data));
    
                serviceAction = $stateParams.type === 'edit' ? 'save' : 'create';
            }).then(() => {
                LoyaltyCampaignService[serviceAction](formData)
                .then(function() {
                    $state.go('app.loyaltyCampaigns', {
                        paginationData: $stateParams.paginationData
                    });
                }).catch((err) => {
                    vm.state.finish = false;
                    throw err;
                });
            });
        }

        /**
         * Controller initialization
         */
        function init() {
            if ($stateParams.type === 'new') {
                vm.data = new ModelsFactory.LoyaltyCampaign;
            } else if ($stateParams.type === 'edit') {
                vm.data = $stateParams.data;
            }

            vm.STORAGE = new StorageFactory.Storage('LoyaltyCampaign', true);
            vm.STORAGE.set('data', vm.data);
        }

        /**
         * Listener of finish event
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && onFinish();
            });
    }
}());
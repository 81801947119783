(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('segmentAddState', function() {
            return {
                views: {
                    SEGMENT_TYPE: 0,
                    SEGMENT_DETAILS: 1,
                },
                finish: 0,
                type: 'new',
                canFinish: false,
                canGoBack: function () {
                    return this.view === this.views['SEGMENT_DETAILS'] && this.type !== 'edit';
                }
            };
        })
        .controller('SegmentController', SegmentController);

    function SegmentController(
            $state,
            $stateParams,
            PopupService,
            PermissionsService,
            SegmentDataService,
            SEGMENT_TYPES,
            SEGMENT_FILTERS_TITLES,
            STATUS_FILTERS,
            SEGMENT_STATUS,
    ) {
        const vm = this;

        const ONE_LANGUAGE_TYPES = [
            SEGMENT_TYPES.GEOFENCES,
            SEGMENT_TYPES.LOYALTIES,
            SEGMENT_TYPES.PREDEFINED,
            SEGMENT_TYPES.SALES,
            SEGMENT_TYPES.SEGMENTATION_GROUP,
            SEGMENT_TYPES.RANDOM,
        ];

        // Segment type that should be freezed when activated
        const FREEZE_WHEN_ACTIVATED = [
            SEGMENT_TYPES.PREFERENCES,
            SEGMENT_TYPES.LOYALTIES,
            SEGMENT_TYPES.PREDEFINED,
            SEGMENT_TYPES.SALES,
            SEGMENT_TYPES.SEGMENTATION_GROUP,
            SEGMENT_TYPES.RANDOM
        ];

        const {
            actions,
            generateSegmentPermission,
            generateSegmentTypeFilterPermission,

        } = PermissionsService;

        // public methods
        vm.deleteSegment = deleteSegment;
        vm.updateSegments = updateSegments;
        vm.newSegment = newSegment;
        vm.updateSegment = updateSegment;
        vm.copySegment = copySegment;
        vm.generateSegmentTypeFilterPermission = generateSegmentTypeFilterPermission;


        init();

        // public properties
        vm.createSegmentPermission = generateSegmentPermission(actions.create);
        vm.SEGMENT_STATUS_INACTIVE = SEGMENT_STATUS.INACTIVE;
        vm.SEGMENT_STATUS_ACTIVE = SEGMENT_STATUS.ACTIVE;
        vm.SEGMENT_STATUS_DELETED = SEGMENT_STATUS.DELETED;
        vm.STATUS_FILTERS = STATUS_FILTERS;
        vm.SEGMENT_FILTERS_TITLES = SEGMENT_FILTERS_TITLES;
        vm.filter = {
            [SEGMENT_STATUS.ACTIVE]: true,
            [SEGMENT_STATUS.INACTIVE]: true,
        };
        vm.typeFilters = {};
        vm.campaigns = [];
        vm.totalItems = $stateParams.paginationData ? $stateParams.paginationData.count : 0;
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'segmentTitle',
                    width: '25',
                    title: function(item) {
                        if (isSegmentItem(null, item)) {
                            if (typeof item.title === 'string') {
                                return item.title;
                            }
                            return item.title[item.currentLanguage] || '';
                        } else {
                            return item.label;
                        }
                    },
                },
                {
                    name: 'TYPE',
                    class: 'segmentType',
                    width: '25',
                    translate: true,
                    title: function(item) {
                        if (isSegmentItem(null, item)) {
                            return item.segment_type.name;
                        } else {
                            const filterCategoryTitle = vm.SEGMENT_FILTERS_TITLES.find(filter => {
                                return filter.value === parseInt(item.categoryType);
                            });
                            return filterCategoryTitle ? filterCategoryTitle.title : 'FEEDBACK';
                        }
                    }
                },
                {
                    name: 'Status',
                    class: 'segmentStatus',
                    width: '25',
                    translate: true,
                    title: function(item) {
                        switch (parseInt(item.status)) {
                            case vm.SEGMENT_STATUS_ACTIVE:
                                return 'ACTIVE';
                            case vm.SEGMENT_STATUS_INACTIVE:
                                return 'INACTIVE';
                            case vm.SEGMENT_STATUS_DELETED:
                                return 'DELETED';
                            default:
                                return '';
                        }
                    }
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteListItem,
                        permissionAction: actions.delete,
                        isVisible: (btn, item) => {
                            return parseInt(item.status) !== vm.SEGMENT_STATUS_DELETED;
                        }
                    },
                    {
                        class: 'copyBtn',
                        callback: vm.copySegment,
                        permissionAction: actions.create,
                        isVisible: isSegmentItem
                    },
                    {
                        class: 'editBtn',
                        callback: vm.updateSegment,
                        permissionAction: actions.modify,
                        isVisible: (btn, item) => {
                            return isSegmentItem(btn, item) && parseInt(item.status) !== vm.SEGMENT_STATUS_DELETED;
                        }
                    },
                    {
                        class: item => {
                            switch (parseInt(item.status)) {
                                case vm.SEGMENT_STATUS_ACTIVE:
                                    return 'block-btn';
                                case vm.SEGMENT_STATUS_INACTIVE:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeSegmentStatus,
                        isVisible: (btn, item) => parseInt(item.status) !== vm.SEGMENT_STATUS_DELETED,
                        permissionAction: actions.modify
                    },
                ]
            },
            updateCallback: vm.updateSegments,
            generatePermissionName: generatePermissionName,
        };

        // private methods

        /**
         * Updates list of segments
         *
         * @param {Number} page
         * @param {Number} itemsPerPage
         */
        function updateSegments(page, itemsPerPage) {
            vm.typeFilters[SEGMENT_TYPES.NUMBER_RANGES] = vm.typeFilters[SEGMENT_TYPES.FEEDBACK_ATTRIBUTES];
            vm.typeFilters[SEGMENT_TYPES.CALCULATED] = vm.typeFilters[SEGMENT_TYPES.FEEDBACK_ATTRIBUTES];
            const data = { page, itemsPerPage };
            const filter = {};
            const statuses = Object.keys(vm.filter).filter(key => vm.filter[key]);
            const neededTypes = Object.keys(vm.typeFilters).filter(key => vm.typeFilters[key]);

            if (!!neededTypes.length) {
                filter.types = neededTypes;
            }
            if (!!statuses.length) {
                filter.statuses = statuses;
            }
            if (!_.isEmpty(filter)) {
                data.filter = JSON.stringify(filter);
            }
            SegmentDataService.getSegments(data)
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    vm.segments = response;
                    vm.totalItems = response.count;
                    vm.segments.forEach(segment => {
                        if (isSegmentItem(null, segment)) {
                            processSegmentItem(segment);
                        }
                    });
                })
                .catch(console.error.bind(console));
        }

        function isMultiLanguages (item) {
            return ONE_LANGUAGE_TYPES.indexOf(Number(item.segment_type_id)) === -1;
        }

        /**
         * Check if item is Segment
         * @param {object} button Button which was clicked
         * @param {object} item List item on which button was clicked
         * @return {boolean}
         */
        function isSegmentItem(button, item) {
            return angular.isDefined(item.segment_type_id);
        }

        /**
         * Handle segmentation category activate/deactivate button click
         * @param {object} $event
         * @param {object} item Clicked segmentation category item
         */
        function changeSegmentStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const newStatus = parseInt(item.status) === vm.SEGMENT_STATUS_ACTIVE ?
                vm.SEGMENT_STATUS_INACTIVE : vm.SEGMENT_STATUS_ACTIVE;
            if (!isSegmentItem(null, item)) {
                SegmentDataService.updateFilterCategoryStatus(item.id, newStatus).then(() => {
                    item.status = newStatus;
                })
                    .catch(console.error.bind(console));
            } else {
                const data = item.plain();
                data.status = newStatus;
                for (let prop in data) {
                    let value = data[prop];

                    if (prop === 'title' && typeof value === 'object') {
                        data[prop] = angular.toJson(value);
                    } else if (prop === 'message' && typeof value === 'object') {
                        if (FREEZE_WHEN_ACTIVATED.includes(data.segment_type_id) && newStatus === vm.SEGMENT_STATUS_ACTIVE) {
                            if (data.segment_type_id === SEGMENT_TYPES.PREFERENCES) {
                                _.forEach(value, preferenceLanguageData => {
                                    if (!preferenceLanguageData.wasActivated) {
                                        preferenceLanguageData.wasActivated = !!vm.SEGMENT_STATUS_ACTIVE;
                                    }
                                })
                            } else if (!value.wasActivated){
                                value.wasActivated = true;
                            }
                        }
                        data[prop] = angular.toJson(value);
                    }
                }
                const formData = new FormData();
                for (let prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        formData.append(prop, data[prop]);
                    }
                }
                formData.append('_method', 'PUT');
                SegmentDataService.updateSegment(item, formData).then((response) => {
                    item.status = newStatus;
                })
            }
        }

        /**
         * Process content item
         *
         * @param {object} item
         */
        function processSegmentItem(item) {
            if (angular.isDefined(item.categoryRef)) {
                item.categoryRef = parseInt(item.categoryRef);
            }
            if (isMultiLanguages(item)) {
                try {
                    item.title = angular.fromJson(item.title);
                } catch (e) {
                    console.error.bind(console);
                }
                item.currentLanguage = Number(item.language_id);
            }
            try {
                item.message = angular.fromJson(item.message);
            } catch (e) {
                console.error.bind(console);
            }
            if (item.segment_type_id === SEGMENT_TYPES.RANDOM && item.message.randomizationTime) {
                item.message.randomizationTime = moment(moment.utc(item.message.randomizationTime).toDate()).local();
            }
        }

        function deleteListItem($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            isSegmentItem(null, item) ? deleteSegment(item) : deleteSegmentationCategory(item);
        }

        /**
         * Removes segment
         *
         * @param {object} segmentItem
         */
        function deleteSegment(segmentItem) {
            if(segmentItem.segment_type_id === 5) {
                PopupService.showAlertPopup({
                    text: 'YOU_ARE_NOT_ABLE_TO_DELETE_THIS_TYPE_OF_SEGMENT',
                    okButtonText: 'OK'
                }, function() {
                });
            } else {
                PopupService.showConfirmationPopup({
                    text: 'YOU_ARE_ABOUT_TO_DELETE_SEGMENT',
                    okButtonText: 'DELETE',
                    cancelButtonText: 'CANCEL'
                }, function() {
                    const data = segmentItem.plain();
                    data.status = vm.SEGMENT_STATUS_DELETED;
                    for (let prop in data) {
                        let value = data[prop];

                        if (prop === 'title' && typeof value === 'object') {
                            data[prop] = angular.toJson(value);
                        } else if (prop === 'message' && typeof value === 'object') {
                            data[prop] = angular.toJson(value);
                        }
                    }
                    const formData = new FormData();
                    for (let prop in data) {
                        if (data.hasOwnProperty(prop)) {
                            formData.append(prop, data[prop]);
                        }
                    }
                    formData.append('_method', 'PUT');
                    SegmentDataService.updateSegment(segmentItem, formData).then((response) => {
                        segmentItem.status = vm.SEGMENT_STATUS_DELETED;
                    })
                });
            }
        }

        /**
         * Changes segmentation category status to deleted
         * @param {object} item
         */
        function deleteSegmentationCategory(item) {
            SegmentDataService.updateFilterCategoryStatus(item.id, vm.SEGMENT_STATUS_DELETED).then(() => {
                item.status = vm.SEGMENT_STATUS_DELETED;
            })
                .catch(console.error.bind(console));
        }

        /**
         * Create new segment button handler
         */
        function newSegment() {
            let itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            $state.go('app.addSegment', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Edit segment
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function updateSegment($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            let page = angular.element('.pagination-page.active a')[0].innerHTML;
            let itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            let data = item;

            $state.go('app.editSegment', {
                data,
                paginationData: {
                    page: Number(page),
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Copy segment
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function copySegment($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            if(item.segment_type_id === 5) {
                PopupService.showAlertPopup({
                    text: 'YOU_ARE_NOT_ABLE_TO_COPY_THIS_TYPE_OF_SEGMENT',
                    okButtonText: 'OK'
                }, function() {
                });
            } else {
                PopupService.showConfirmationPopup({
                    text: 'DO_YOU_WANT_TO_COPY_THIS_SEGMENT',
                    okButtonText: 'YES',
                    cancelButtonText: 'NO'
                }, function() {
                    let itemsPerPage = angular.element('.paginationControls select').val()
                        .replace('number:', '');

                    let data = _.omit(item, ['id', 'externalId', 'categoryRef']);
                    data.status = vm.SEGMENT_STATUS_INACTIVE;
                    if (data.segment_type_id === SEGMENT_TYPES.RANDOM) {
                        data.message.wasActivated = false;
                        data.message.randomizationTime = null;
                    }
                    $state.go('app.addSegment', {
                        data,
                        paginationData: {
                            page: 1,
                            itemsPerPage: Number(itemsPerPage),
                            count: vm.totalItems
                        }
                    });
                });
            }

        }

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Generates permission name for each button on segment accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateSegmentPermission(button.permissionAction);
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('Unity3dModelFormToolbarController', Unity3dModelFormToolbarController)

    function Unity3dModelFormToolbarController($state, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.Unity3dModelStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;
        vm.state.canFinish = false;

        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.unity3dModels');
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
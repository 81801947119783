(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkGuests', {
            templateUrl: '/assets/views/share-park/customers/guests/guests.tpl.html',
            controller: GuestsController
        });

    function GuestsController(
        $state,
        $translate,
        ShareParkDataService,
        PopupService,
        DATETIME_FORMAT,
        PermissionsService,
        UserUtilitiesService,
        UtilitiesService,
    ) {
        const vm = this;

        const { actions, generateCapInvitationPermission } = PermissionsService;
        const timeZoneId = UserUtilitiesService.timeZoneId();
        vm.$onInit = function () {
            getInvitations();
        };
        

        vm.invitations = [];
        vm.invitationsCount = 0;
        vm.searchValue = '';
        vm.filteredInvitations = [];
        vm.filterInvitations = filterInvitations;

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'guests-list-name',
                    width: '25',
                    title: listItem => listItem.invitation.name,
                },
                {
                    name: 'VALID_FROM',
                    width: '20',
                    title: listItem =>
                        moment(listItem.invitation.startDate).format(DATETIME_FORMAT),
                },
                {
                    name: 'VALID_TILL',
                    width: '20',
                    title: listItem =>
                        moment(listItem.invitation.endDate).format(DATETIME_FORMAT),
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteInvitation,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editInvitation,
                        permissionAction: actions.modify
                    },
                    {
                        class: 'sendBtn',
                        callback: sendInvitations,
                        permissionAction: 'send'
                    },
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        vm.createInvitation = createInvitation;

        function getInvitations() {
            ShareParkDataService.getInvitations()
            .then(response => {
                vm.invitations = response.plain().map(invitation => {
                    invitation.startDate = moment.utc(invitation.startDate).tz(timeZoneId);
                    invitation.endDate = moment.utc(invitation.endDate).tz(timeZoneId);
                    return { invitation };
                });

                filterInvitations();
                vm.invitationsCount++;
            })
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param itemsPerPage
         */
        function updateList(page = 1, itemsPerPage = 25) {
            vm.visibleInvitations = UtilitiesService.getArrayPage(vm.filteredInvitations, page, itemsPerPage);
        }

        /**
         * Filters invitation list
         */
        function filterInvitations() {
            vm.filteredInvitations = vm.searchValue
                ? vm.invitations.filter(listItem => (
                    listItem.invitation.name.toUpperCase().includes(vm.searchValue.toUpperCase())
                ))
                : vm.invitations;
            
            vm.invitationsCount++;
        }

        function createInvitation() {
            $state.go('app.addShareParkGuests');
        }

        function sendInvitations($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            const invitation = listItem.invitation;
            $translate('DO_YOU_REALLY_WANT_TO_SEND_INVITATIONS', {
                count: invitation.guests.length
            }).then(text => {
                PopupService.showConfirmationPopup({
                    text: text,
                    okButtonText: 'OK',
                    cancelButtonText: 'CANCEL'
                }, function() {
                    ShareParkDataService.sendInvitations(invitation.id)
                        .then(() => {
                            PopupService.showAlertPopup({
                                text: 'INVITATIONS_SENT_SUCCESSFULLY',
                                okButtonText: 'OK'
                            });
                        })
                        .catch((err) => {
                            PopupService.showAlertPopup({
                                text: 'ERROR_WHILE_SENDING_INVITATIONS',
                                okButtonText: 'OK'
                            });

                            throw err;
                        });
                });
            });
        }

        function deleteInvitation($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_INVITATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                ShareParkDataService.deleteInvitation(listItem.invitation.id)
                    .then(() => {
                        getInvitations();
                    })
                    .catch(console.error.bind(console));
            });
        }

        function editInvitation($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkGuests', { data: listItem.invitation });
        }

        function generatePermissionName(button) {
            return generateCapInvitationPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userRoleItemOptions', {
            templateUrl: '/assets/views/users/new/tabs/user-roles/user-role-item-options/user-role-item-options.tpl.html',
            controller: UserRoleItemOptionsController,
            bindings: {
                selectedUserGroups: '<',
                tenantId: '<',
                userRole: '<',
                allowedCampuses: '=',
                allowedServiceProviders: '=',
                allowedProfiles: '=',
            }
        });

    /**
     * @property {Number[]} allowedCampuses
     * @property {Number[]} allowedServiceProviders
     * @property {Number} tenantId
     * @property {object[]} selectedUserGroups
     * @property {Number} userRole
     *
     * @param {$rootScope.Scope} $scope
     * @param {$q} $q
     * @param $translate
     * @param $timeout
     * @param ShareParkDataService
     * @param SmartCampusApiService
     * @param UserGroupsDataService
     * @param TenantDataService
     * @param {ROLES} ROLES
     * @param TranslateService
     * @constructor
     */
    function UserRoleItemOptionsController(
        $scope,
        $q,
        $translate,
        $timeout,
        ShareParkDataService,
        SmartCampusApiService,
        UserGroupsDataService,
        TenantDataService,
        ROLES,
        TranslateService,
    ) {
        const $ctrl = this;

        let serviceProvidersAll;
        let profilesAll;
        let campusesAll;

        $ctrl.serviceProviders = [];
        $ctrl.campuses = [];
        $ctrl.profiles = [];
        $ctrl.tenant = null;
        $ctrl.ready = false;

        /**
         * @type {UserGroup[]}
         */
        $ctrl.userGroups = [];

        $ctrl.$onInit = () => {
            $q.all([
                UserGroupsDataService.getUserGroups(),
                ShareParkDataService.getCapServiceProvidersAll(),
                SmartCampusApiService.getCampuses(true),
                TenantDataService.getTenants({}),
                ShareParkDataService.getUserProfiles({
                    allProfiles: true
                }),
            ]).then(([userGroups, serviceProviders, campuses, tenants, profiles]) => {
                serviceProvidersAll = serviceProviders.plain();
                profilesAll = profiles.plain();
                campusesAll = campuses;

                $ctrl.userGroups = userGroups.plain();
                $ctrl.tenants = tenants.plain();

                _setTenant();
                _setDropdownElements();
                $timeout(() => _initTenantChangeHandler());

                $ctrl.ready = true;
            });
        }

        $ctrl.campusesHint = () => {
            const definedCampuses = $ctrl.allowedCampuses.length;

            if (definedCampuses) {
                return $translate.instant('THESE_SELECTED_CAMPUSES_OVERRIDE_THE_USER_GROUP_SETTINGS');
            }

            if ($ctrl.selectedUserGroups.length) {
                const userGroupsCampuses = $ctrl.selectedUserGroups
                    .map(item => item.allowedCampuses)
                    .flat()
                    .filter(_onlyUnique);

                if (userGroupsCampuses.length) {
                    return $translate.instant('USER_GROUP_SETTINGS') + ': ' + userGroupsCampuses.map(campusId => {
                        const campus = campusesAll.find(campus => campus.id === campusId);
                        return campus ? campus.name : campusId;
                    }).join(', ');
                } else {
                    return $translate.instant('USER_GROUP_SETTINGS') + ': ' + $translate.instant('NO_ALLOWED_CAMPUSES');
                }
            } else {
                return $translate.instant('ALL_CAMPUSES_ALLOWED');
            }
        }

        $ctrl.serviceProvidersHint = () => {
            if (
                [ROLES.SYS_ADMIN, ROLES.TENANT_ADMIN, ROLES.ADMIN].includes($ctrl.userRole)
            ) {
                return $translate.instant('ADMIN_HAS_ACCESS_TO_ALL_SERVICE_PROVIDERS');
            }

            const definedServiceProviders = $ctrl.allowedServiceProviders.length;

            if (definedServiceProviders) {
                return $translate.instant('THESE_SELECTED_SERVICE_PROVIDERS_OVERRIDE_THE_USER_GROUP_SETTINGS');
            }

            if ($ctrl.selectedUserGroups.length) {
                const userGroupsServiceProviders = $ctrl.selectedUserGroups
                    .map(item => item.allowedServiceProviders)
                    .flat()
                    .filter(_onlyUnique);

                if (userGroupsServiceProviders.length) {
                    return $translate.instant('USER_GROUP_SETTINGS') + ': ' + userGroupsServiceProviders.map(spId => {
                        const serviceProvider = serviceProvidersAll.find(sp => sp.id === spId);
                        return serviceProvider ? serviceProvider.name : spId;
                    }).join(', ');
                } else {
                    return $translate.instant('USER_GROUP_SETTINGS') + ': ' + $translate.instant('NO_ALLOWED_SERVICE_PROVIDERS');
                }
            } else {
                return $translate.instant('ALL_SERVICE_PROVIDERS_ALLOWED');
            }
        }

        $ctrl.profilesHint = () => {
            if (
                [ROLES.SYS_ADMIN, ROLES.TENANT_ADMIN, ROLES.ADMIN].includes($ctrl.userRole)
            ) {
                return $translate.instant('ADMIN_HAS_ACCESS_TO_ALL_PROFILES');
            }

            const definedProfiles = $ctrl.allowedProfiles.length;

            if (definedProfiles) {
                return $translate.instant('THESE_SELECTED_PROFILES_OVERRIDE_THE_USER_GROUP_SETTINGS');
            }

            if ($ctrl.selectedUserGroups.length) {
                const userGroupsProfiles = $ctrl.selectedUserGroups
                    .map(item => item.allowedProfiles)
                    .flat()
                    .filter(_onlyUnique);

                if (userGroupsProfiles.length) {
                    return $translate.instant('USER_GROUP_SETTINGS') + ': ' + userGroupsProfiles.map(pId => {
                        const profile = profilesAll.find(p => p.id === pId);
                        return profile ? TranslateService.getCurrentUiLanguageTranslation(profile.name) : pId;
                    }).join(', ');
                } else {
                    return $translate.instant('USER_GROUP_SETTINGS') + ': ' + $translate.instant('NO_ALLOWED_PROFILES');
                }
            } else {
                return $translate.instant('ALL_PROFILES_ALLOWED');
            }
        }

        function _setTenant() {
            $ctrl.tenant = $ctrl.tenants.find(tenant => tenant.id === $ctrl.tenantId);
        }

        function _setDropdownElements() {
            $ctrl.serviceProviders = serviceProvidersAll
                .filter(s => $ctrl.tenant && s.tenant === $ctrl.tenant.tenantRef)
                .sort(_orderByFullName);

            $ctrl.campuses = campusesAll
                .filter(s => $ctrl.tenant && s.tenant === $ctrl.tenant.tenantRef)
                .sort(_orderByName);

            $ctrl.profiles = profilesAll
                .filter(s => $ctrl.tenant && s.tenant === $ctrl.tenant.tenantRef)
                .sort(_orderByEncodedName);
        }

        function _initTenantChangeHandler() {
            $scope.$watch(
                () => $ctrl.tenantId,
                () => {
                    _setTenant();
                    _setDropdownElements();
                },
            );
        }

        /**
         * @param {{name: string}} a
         * @param {{name: string}} b
         * @private
         */
        function _orderByName(a, b) {
            return a.name.localeCompare(b.name);
        }

        /**
         * @param {{fullCompanyName: string}} a
         * @param {{fullCompanyName: string}} b
         * @private
         */
        function _orderByFullName(a, b) {
            return a.fullCompanyName.localeCompare(b.fullCompanyName);
        }

        /**
         * @param {{name: string}} a - JSON value
         * @param {{name: string}} b - JSON value
         * @private
         */
        function _orderByEncodedName(a, b) {
            const aName = TranslateService.getCurrentUiLanguageTranslation(a.name);
            const bName = TranslateService.getCurrentUiLanguageTranslation(b.name);

            return aName.localeCompare(bName);
        }

        function _onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('quizQuestionsTab', {
            templateUrl: '/assets/views/content/elements/types/quiz/tabs/questions/questions.tpl.html',
            controller: QuizQuestionsTab,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                allUserAttributeTypes: '='
            }
        });

    function QuizQuestionsTab($scope,
                              ContentHelper,
                              UtilitiesService,
                              PopupService,
                              StorageFactory,
                              ModelsFactory,
                              ContentDataService,
                              QUIZ_QUESTION_TYPES,
                              QUIZ_TYPE) {
        const vm = this;

        const {compareAsNumber, stripHtmlToText} = UtilitiesService;
        const { isFixedInfoType } = ContentHelper;

        vm.QUIZ_TYPE = QUIZ_TYPE;
        vm.QUIZ_QUESTION_TYPES = QUIZ_QUESTION_TYPES;

        vm.selectedQuestion = null;
        vm.quiz = null;
        vm.localUserAttributeTypes = [];
        vm.canModify = true;

        vm.langId = vm.contentData.language_id;

        vm.itemHeaderModel = new ModelsFactory.QuizQuestionHeader(
            questionClicked,
            copyQuestion,
            deleteQuestion
        );


        /**
         * Exports
         */
        vm.getCurrentLanguageLabel = getCurrentLanguageLabel;
        vm.addNewQuestion = addNewQuestion;
        vm.questionTypeOnChange = questionTypeOnChange;
        vm.questionClicked = questionClicked;
        vm.addNewAnswer = addNewAnswer;
        vm.removeAnswer = removeAnswer;
        vm.isLastItem = isLastItem;
        vm.isFixedInfoType = isFixedInfoType;
        vm.searchUserAttributeTypes = searchUserAttributeTypes;
        vm.getText = getText;
        vm.languageChangeHandler = languageChangeHandler;

        vm.$onInit = init;

        function init() {
            vm.STORAGE = StorageFactory.Storage('Content');
            vm.STORAGE.on('contentData', (value) => {
                vm.contentData = value;

                vm.currentLangId = vm.contentData.language_id;

                vm.quiz = vm.contentData.message.quiz;

                if (vm.contentData.reference_id) {
                    ContentDataService.getFeedbackEvents(vm.contentData.reference_id)
                        .then(statistics => {
                            vm.canModify = !statistics.length;
                        });
                }
            });
        }

        function setWatchers() {
            $scope.$watch(angular.bind(vm, function() {
                return vm.selectedQuestion;
            }), function() {
                if (vm.quiz) {
                    vm.localUserAttributeTypes = getAllUserAttributeTypes();
                } else {
                    vm.localUserAttributeTypes = [];
                }
            });
        }

        function getCurrentLanguageLabel() {
            const language = vm.langArray.filter((lang) => {
                return compareAsNumber(lang.id, vm.contentData.language_id);
            })[0];

            return language ? language.name : '';
        }

        function getAllUserAttributeTypes() {
            let userAttributeTypes = new Set();

            vm.quiz.questions.forEach(function(question) {
                userAttributeTypes.add(question.userAttributeType);
            });

            vm.allUserAttributeTypes.forEach(function(userAttributeType) {
                userAttributeTypes.add(userAttributeType);
            });
            userAttributeTypes.delete('');
            userAttributeTypes.delete(null);
            return Array.from(userAttributeTypes);
        }

        function searchUserAttributeTypes(text) {
            if (text) {
                return vm.localUserAttributeTypes.filter((element) => element && element.indexOf(text) > -1);
            } else {
                return vm.localUserAttributeTypes;
            }
        }

        // region question header

        function addNewQuestion() {
            if (!isFixedInfoType(vm.contentData) || !vm.quiz.questions.length) {
                const type = parseInt(vm.quiz.type, 10) === QUIZ_TYPE.QUIZ
                    ? QUIZ_QUESTION_TYPES.FEEDBACK
                    : QUIZ_QUESTION_TYPES.RATING;
                const langIds = vm.langArray.map(lang => lang.id);
                const model = new ModelsFactory.QuizQuestionBody(
                    type,
                    langIds
                );
                vm.quiz.questions.push(model);
            }
        }

        function copyQuestion(event, quiz) {
            event.preventDefault();
            event.stopPropagation();
            vm.quiz.questions.push(angular.copy(quiz));
        }

        function deleteQuestion(event, quiz) {
            event.preventDefault();
            event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_QUESTION',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }, function() {
                vm.quiz.questions.splice(vm.quiz.questions.indexOf(quiz), 1);
            });
        }

        function questionClicked(item) {
            vm.selectedQuestion = item;
        }

        function questionTypeOnChange(question) {
            question.isSingleAnswer = !(parseInt(question.type, 10) === QUIZ_QUESTION_TYPES.RATING)
        }

        // endregion

        // region answer buttons

        function addNewAnswer(item) {
            if (!item.votingOptions) {
                item.votingOptions = [];
            }

            item.votingOptions.push({answer: {}, isCorrectAnswer: false});
        }

        function removeAnswer(item, index) {
            item.votingOptions.splice(index, 1);
        }

        // endregion

        function isLastItem(item) {
            return vm.quiz.questions.length && item === vm.quiz.questions[vm.quiz.questions.length - 1];
        }

        /**
         * Returns text by language or default language text
         * @param {object} textObj Multilaguage text object
         * @returns {string} Text
         */
        function getText(textObj) {
            const defaultLangId = vm.langArray[0].id;
            const html = textObj[vm.langId] || textObj[defaultLangId] || "";
            return stripHtmlToText(html);
        }

        /**
         * Update scope variables on language change
         */
        function languageChangeHandler() {
            vm.langId = vm.contentData.language_id;
        }

        setWatchers();
    }
})();

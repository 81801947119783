(function() {
    'use strict';

    angular.module('beacon.app')
        .component('parkingEventListPreview', {
            templateUrl: '/assets/views/share-park/operations/parking-events/parking-event-list-preview/parking-event-list-preview.tpl.html',
            controller: ParkingEventListPreviewController,
            bindings: {
                parkingEvent: '<',
                tariffs: '<',
            }
        });

    function ParkingEventListPreviewController(
        $state,
        $translate,
        ProfilesHelper,
        ShareParkDataService,
        ShareParkHelperService,
        Restangular,
    ) {
        const vm = this;

        vm.$onInit = init;

        const currentLocale = $translate.use();

        const { decodeCarParkData } = ShareParkHelperService;
        const { decodeCustomerProfile } = ProfilesHelper;

        vm.editCarPark = editCarPark;

        function init() {
            if (vm.parkingEvent.guid) {
                ShareParkDataService.getCustomerData(vm.parkingEvent.guid).then(response => {
                    const customer = response.plain();

                    if (customer.profile) {
                        customer.profile = decodeCustomerProfile(customer.profile);
                    }

                    vm.customer = customer;
                });
            }

            if (vm.parkingEvent.permissionRef) {
                ShareParkDataService.getPermissionById(vm.parkingEvent.permissionRef)
                    .then(permission => {

                        const permissionData = permission.plain();
                        permissionData.permissionFrom = moment(permission.permissionFrom).format('YYYY-MM-DD HH:mm:ss');
                        permissionData.permissionTill = moment(permission.permissionTill).format('YYYY-MM-DD HH:mm:ss');

                        const tariff = vm.tariffs.find(tariff => tariff.id === permissionData.rateRef);

                        if (tariff) {
                            permissionData.tariffName = tariff.name[currentLocale] || Object.values(tariff.name)[0];
                        }

                        if (vm.parkingEvent.carPark && vm.parkingEvent.carPark.sub_areas) {
                            const relatedSubArea = vm.parkingEvent.carPark.sub_areas.find(subArea =>
                                subArea.type === permissionData.subArea
                            );

                            if (relatedSubArea) {
                                const subAreaLabel = relatedSubArea.label[currentLocale] || Object.values(relatedSubArea.label)[0];

                                permissionData.subArea = `${subAreaLabel} (${permissionData.subArea})`
                            }
                        }

                        vm.permission = permissionData;
                    });
            }
        }

        function editCarPark(carPark) {
            let carParkData = angular.copy(carPark);

            carParkData = decodeCarParkData(carParkData);

            Restangular.restangularizeElement(null, carParkData, '/carPark');

            $state.go('app.editCarPark', { data: carParkData });
        }
    }
})();
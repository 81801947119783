(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentBannersPopupController', ContentBannersPopupController);

    function ContentBannersPopupController(
        $state,
        $uibModalInstance,
        data,
        ContentHelper,
        ContentDataService,
        CONTENT_TYPES,
        BANNER_REFERENCE_TYPE,
    ) {
        const vm = this;

        const { decodeContent } = ContentHelper;
        const { getRelatedBanners } = ContentDataService;

        vm.relatedBanners = [];
        vm.selectedBanner = null;
        vm.content = decodeContent(data.content);

        vm.cancelHandler = cancelHandler;
        vm.addBannerHandler = addBannerHandler;
        vm.editBannerHandler = editBannerHandler;

        function init() {
            getRelatedBanners(vm.content.id).then(response => {
                vm.relatedBanners = response.map(decodeContent);
            })
        }

        function addBannerHandler() {
            $state.go('app.addContent', {
                initialData: {
                    contentType: CONTENT_TYPES.BANNER,
                    infoId: vm.content.id,
                    referenceType: BANNER_REFERENCE_TYPE.INFOTAINMENT,
                },
            });
            $uibModalInstance.close();
        }

        function editBannerHandler() {
            $state.go('app.editContent', {
                data: vm.selectedBanner,
            });
            $uibModalInstance.close();
        }

        function cancelHandler() {
            $uibModalInstance.dismiss();
        }

        init();
    }
})();
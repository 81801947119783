(function() {
    'use strict';

    angular.module('beacon.app')
        .component('digitalDisplayPageDetailsTab', {
            templateUrl: '/assets/views/content/elements/types/digital-display/tabs/page-details/page-details.tpl.html',
            controller: DigitalDisplayPageDetailsTab,
            bindings: {
                langArray: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<'
            }
        });

    function DigitalDisplayPageDetailsTab($rootScope,
                                          $timeout,
                                          UtilitiesService,
                                          StorageFactory,
                                          StatesFactory,
                                          PAGE_TYPES,
                                          VIEW_TYPES) {
        const vm = this;
        const { compareAsNumber, oneOfPageTypes, checkPageType } = UtilitiesService;

        /**
         * Exports
         */
        vm.state = StatesFactory.DigitalDisplayStates;
        vm.state.onSavePage = onSavePage;

        vm.VIEW_TYPES = VIEW_TYPES;
        vm.checkPageType = checkPageType;
        vm.oneOfPageTypes = oneOfPageTypes;

        vm.$onInit = onInit;

        function onInit() {
            vm.STORAGE = StorageFactory.Storage('Content-DigitalDisplay');
            vm.pageData = vm.STORAGE.get('pageData');
            vm.model = vm.contentData.message[vm.contentData.language_id];

            updateGlobalPageId();
        }

        /**
         * Set page id increment
         */
        function updateGlobalPageId(increment) {
            let allPages = [];

            for (let lang of vm.langArray) {
                allPages = allPages.concat(vm.contentData.message[lang.id].pages);
            }

            if (!increment) {
                increment = Math.max(...allPages.map((page) => page.id), 0);
            }

            vm.pageIdIncrement = vm.STORAGE.set('pageIdIncrement', increment + 1);
        }

        function onSavePage() {
            $timeout(function() {
                $rootScope.$broadcast('digital-display-savepage', {
                    callback: (obj) => {
                        // todo: move to some method
                        if (!obj.hasOwnProperty('pageData') || obj.pageData === undefined) {
                            throw new ReferenceError('The \'pageData\' property is not found!');
                        } else if (typeof obj.pageData !== 'object' && obj.pageData !== null) {
                            throw new TypeError('The \'pageData\' should be an object, not be a Null!');
                        }

                        savePage(obj.pageData);
                    }
                });
            }, 0);
        }

        function savePage() {
            if (vm.state.page.type === 'new') {
                let page = angular.copy(vm.pageData);
                page.id = vm.pageIdIncrement;
                updateGlobalPageId(page.id);

                // save new page for each language
                for (let lang of vm.langArray) {
                    let pageCopy = angular.copy(page);
                    vm.contentData.message[lang.id].pages.push(pageCopy);

                    if (!compareAsNumber(vm.contentData.language_id, lang.id)) {
                        pageCopy.header.title += ' ' + lang.code;
                    }
                }
            } else {
                let page = angular.copy(vm.pageData);
                page.id = vm.model.pages[vm.state.page.index].id;

                // Update page
                vm.model.pages[vm.state.page.index] = page;

                if (page.page_type_id !== PAGE_TYPES.TICKER) {
                    for (let lang of vm.langArray) {
                        if (compareAsNumber(vm.contentData.language_id, lang.id)) {
                            continue;
                        }

                        const pages = vm.contentData.message[lang.id].pages;
                        pages.forEach(_page => {
                            if (compareAsNumber(page.id, _page.id)) {
                                _page.page = page.page;
                                if (page.selectedContentId || page.selectedExternalLink) {
                                    if (angular.isDefined(page.selectedContentId)) {
                                        _page.selectedContentId = page.selectedContentId;
                                    }
                                    if (angular.isDefined(page.selectedExternalLink)) {
                                        _page.selectedExternalLink = page.selectedExternalLink;
                                    }
                                }
                            }
                        });
                    }
                }


                vm.state.pageEditCallback && vm.state.pageEditCallback(page)
            }

            vm.state.go('PAGES_LIST', true);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('defaultContentPreview', {
            templateUrl: '/assets/views/content/elements/preview/default/default.tpl.html',
            controller: DefaultContentPreview,
            bindings: {
                contentData: '=',
            }
        });

    function DefaultContentPreview(UtilitiesService, ContentHelper) {
        const vm = this;
        const { checkContentType } = UtilitiesService;
        const { isFixedInfoType } = ContentHelper;


        vm.getMessageText = getMessageText;
        vm.infotainmentType = infotainmentType;
        vm.getImageSrc = getImageSrc;

        /**
         * Exports
         */
        vm.checkContentType = checkContentType;

        function infotainmentType() {
            return ContentHelper.getInfotainmentType(vm.contentData);
        }

        /**
         * Returns image src
         * @return {string}
         */
        function getImageSrc() {
            return isFixedInfoType(vm.contentData) ? vm.contentData.data.useCaseMedia : vm.contentData.image_src;
        }

        /**
         * Returns message text
         * @returns {string}
         */
        function getMessageText() {
            const contentTypeId = vm.contentData.content_type_id;
            const langId = vm.contentData.language_id;
            const message = vm.contentData.message;
            const text = (checkContentType('QUIZ', contentTypeId) ||
                checkContentType('FEEDBACK', contentTypeId))
                    ? message.message[langId] : _.isString(message)
                        ? message : message[langId];

            return (typeof text === 'undefined') ? '' : text;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ImageUploadService', ImageUploadService);

    function ImageUploadService(
        PopupService,
    ) {
        return {
            upload,
            crop,
            preview,
        };

        /**
         * Upload image popup
         *
         * @param config
         * @param {array} config.accept
         * @param {string} config.placeholder
         * @param {bool} config.alwaysCrop
         * @param {callback} config.onImage
         * @param {string} config.externalImageUrl
         * @param {string} config.externalImageLabel
         * @param {bool} config.multiple
         */
        function upload(config) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/upload-image/upload-image.tpl.html',
                controller: 'UploadImagePopupController',
                controllerAs: 'UploadImagePopupController',
                windowClass: 'loadImagePopup',
                keyboard: false,
                resolve: {
                    data: config,
                }
            })
        }

        /**
         * Crop image popup
         *
         * @param config
         * @param {File} config.file
         * @param {int} config.minWidth
         * @param {int} config.minHeight
         * @param {bool} config.center
         * @param {bool} config.fullInfo
         * @param {bool} config.aspectRatio
         * @param {object} config.data - "cropped" object
         */
        function crop(config) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/crop/crop.tpl.html',
                controller: 'CropPopupController',
                controllerAs: 'cropPopupController',
                windowClass: 'cropPopup',
                keyboard: false,
                resolve: {
                    data: config,
                }
            });
        }

        /**
         * Image preview popup
         *
         * @param config
         * @param {string} config.okButtonText
         * @param {string} config.imageUrl
         */
        function preview(config) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/image-preview/image-preview.tpl.html',
                controller: 'ImagePreviewPopupController',
                controllerAs: 'imagePreviewPopupController',
                windowClass: 'imagePreviewPopup',
                keyboard: false,
                resolve: {
                    data: config
                }
            });
        }
    }
})();

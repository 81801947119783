(function() {
    'use strict';

    angular.module('beacon.app')
        .component('manageScreens', {
            controller: ManageScreensController,
            templateUrl: '/assets/views/location/manage-screens/manage-screens.tpl.html',
        });

    function ManageScreensController(
        $q,
        $state,
        ListPaginationService,
        LocationDataService,
        ScreenDataService,
        PermissionsService,
        locationTabState,
        ACTIVATION_STATUS,
        ContentDataService,
        CONTENT_TYPES,
        PopupService,
        TenantDataService,
        ManageScreensApiService,
        StorageFactory,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.STORAGE = StorageFactory.Storage('Main');
        vm.isEditBtnVisible = PermissionsService.isPermissionAvailable('can_modify_location_screen');
        vm.isDeleteBtnVisible = PermissionsService.isPermissionAvailable('can_delete_location_screen');
        vm.STATUS_FILTERS = Object.values(ACTIVATION_STATUS);
        vm.filters = {
            [ACTIVATION_STATUS.ACTIVE.value]: true,
        };
        vm.tenants = [];
        vm.tenantId = 0;
        vm.searchQuery = '';

        vm.filterScreenControllers = filterScreenControllers;
        vm.setDefaultContent = setDefaultContent;
        vm.onChangeTenant = onChangeTenant;
        vm.onSearchChange = onSearchChange;

        /**
         * List data for accordion
         */
        vm.listData = {
            columns: [
                {
                    name: 'Controller Name',
                    width: '25',
                    class: 'first-column',
                    title: item => item.name || '',
                },
                {
                    name: 'Screen Name',
                    width: '25',
                    title: item => item.screen ? item.screen.name : "",
                },
                {
                    name: 'Stop Name',
                    width: '25',
                    title: item => item.station ? item.station.stopName : ''
                },
            ],
            buttons: {
                width: '15',
                minWidth: '50px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: ($event, item) =>
                            deleteScreen($event, item),
                        isVisible: (btn, item) => vm.isDeleteBtnVisible &&
                            parseInt(item.status) !== ACTIVATION_STATUS.DELETED.value,
                    },
                    {
                        class: item => {
                            switch (parseInt(item.status)) {
                                case ACTIVATION_STATUS.ACTIVE.value:
                                    return 'block-btn';
                                default:
                                    return 'check-btn';
                            }
                        },
                        callback: changeControllerStatus,
                    },
                    {
                        class: 'editBtn',
                        callback: editScreen,
                        isVisible: () => vm.isEditBtnVisible
                    },
                ]
            },
            updateCallback,
            initOnOpen: true,
        };

        function init() {
            LocationDataService.getAllControllerDetails().then(response => {
                vm.screenControllers = response.list;
                filterScreenControllers();

                vm.screenControllers.forEach(screenController => {
                    if (!screenController.lat || !screenController.lng) {
                        return;
                    }

                    screenController.markers = [
                        {
                            label: screenController.name,
                            position: {
                                lat: Number(screenController.lat),
                                lng: Number(screenController.lng),
                            }
                        }
                    ]
                });
                loadTenants();
            });

            $q.all([
                ContentDataService.contents({
                    contentTypeId: [
                        CONTENT_TYPES.DIGITAL_DISPLAY,
                    ].join(',')
                }),
                TenantDataService.getDefaultScreenContent(),
            ])
            .then(response => {
                const contents = response[0].plain();
                const defaultContent = response[1].plain();

                vm.contents = contents.map(item => {
                    const title = JSON.parse(item.title);
                    const defaultLangId = Object.keys(title)[0];
                    item.name = title[defaultLangId];
                    return item;
                });
                vm.defaultContentId = defaultContent.id;
            }).catch(console.error.bind(console));
        }

        function onChangeTenant() {
            filterScreenControllers();
        }

        function onSearchChange() {
            filterScreenControllers();
        }

        /**
         * Set a default content
         *
         * @param {int} contentId
         */
        function setDefaultContent(contentId) {
            TenantDataService.setDefaultScreenContent(contentId)
                .catch(console.error.bind(console));
        }

        function updateCallback(page, itemsPerPage) {
            const result = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredScreenControllers);
            vm.visibleControllers = result.visibleItems;
        }

        function editScreen($event, screen) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            $state.go('app.editScreen', {
                data: angular.copy(screen),
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                },
                previousState: $state.current.name,
            });
        }

        function filterScreenControllers() {
            vm.filteredScreenControllers = Object.values(vm.filters).some(value => value)
                ? vm.screenControllers.filter(controller => vm.filters[controller.status])
                : vm.screenControllers;

            if (vm.searchQuery) {
                const queryLowerCase = vm.searchQuery.toLowerCase();
                vm.filteredScreenControllers = vm.filteredScreenControllers.filter(controller => {
                    return controller.screen && controller.screen.name.toLowerCase().includes(queryLowerCase)
                        || controller.name.toLowerCase().includes(queryLowerCase)
                        || controller.station && controller.station.stopName && controller.station.stopName.toLowerCase().includes(queryLowerCase)
                        || controller.hash === queryLowerCase;
                });
            }

            vm.controllersCount = vm.filteredScreenControllers.length;

            if (vm.tenantId) {
                ManageScreensApiService.getScreensList(vm.tenantId)
                    .then(screensList => {
                        vm.filteredScreenControllers = vm.filteredScreenControllers.filter(controller => {
                            return screensList.find(availableScreen => controller.id === availableScreen.controllerRef);
                        });
                        vm.controllersCount = vm.filteredScreenControllers.length;
                    })
            }
        }

        function deleteScreen($event, item) {
            PopupService.showConfirmationPopup({
                text: 'DO_YOU_REALLY_WANT_TO_DELETE_SCREEN',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                updateScreenController(item, ACTIVATION_STATUS.DELETED.value);
            });
        }

        function changeControllerStatus($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            const newStatus = item.status === ACTIVATION_STATUS.ACTIVE.value
                ? ACTIVATION_STATUS.INACTIVE.value
                : ACTIVATION_STATUS.ACTIVE.value

            updateScreenController(item, newStatus);
        }

        function updateScreenController(screenController, newStatus) {
            const controller = angular.copy(screenController);
            delete screenController.markers;
            controller.status = newStatus;

            const formData = new FormData();
            formData.append('screenController', angular.toJson(controller));

            const success = () => {
                screenController.status = newStatus;
                filterScreenControllers();
            };
            const error = console.error.bind(console);

            ScreenDataService.setScreen(formData)
                .then(success)
                .catch(error)
        }

        function loadTenants() {
            return ManageScreensApiService.getTenantList().then(tenants => {
                vm.tenants = tenants;
                const totalScreens = vm.tenants.reduce((total, tenant) => {
                    total += tenant.screens_count;
                    return total;
                }, 0);
                vm.tenants.unshift({
                    id: 0,
                    name: 'ALL',
                    screens_count: totalScreens,
                });

                const currentTenantId = vm.STORAGE.currentUserInfo.subdomain.tenant.id;
                vm.tenantId = vm.tenants.find(tenant => tenant.id === currentTenantId) ? currentTenantId : 0;
                onChangeTenant();
            });
        }
    }
})();
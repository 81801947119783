(function() {
    'use strict';

    angular.module('beacon.app')
        .service('MgateRestproxyService', mgateRestproxyService);

    function mgateRestproxyService(
        $http,
    ) {
        return {
            getTransportTypes,
        };

        /**
         * Returns available transportation types
         * @returns {Promise<{name: string, cls: string}[]>}
         */
        function getTransportTypes() {
            return $http.get('/api/mgate-restproxy/products')
                .then(response => response.data && response.data.ProductCategory);
        }
    }
}());

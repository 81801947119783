(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedbackCreate', {
            templateUrl: '/assets/views/feedback/feedback-create/feedback-create.tpl.html',
            controller: FeedbackCreateController,
        });

    function FeedbackCreateController(
        $state,
        FeedbackConfigService,
        FeedbackHelper,
    ) {
        const vm = this;
        const PRIORITY = FeedbackHelper.PRIORITY;

        vm.$onInit = onInit;
        vm.onCreate = onCreate;

        vm.feedbackForms = [];
        vm.feedbackAlias = null;
        vm.priorities = FeedbackHelper.getPrioritiesList();
        vm.priority = PRIORITY.LOW;

        function onInit() {
            FeedbackConfigService.getList().then(response => {
                vm.feedbackForms = response.data;
                vm.feedbackAlias = response.data[0] && response.data[0].alias;
            });
        }

        function onCreate() {
            $state.go('app.feedbackForm', {
                type: vm.feedbackAlias,
                priority: vm.priority,
            })
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('quizQuestionsHeader', {
            templateUrl: '/assets/views/content/elements/headers/quiz-questions-header/header.tpl.html',
            controller: QuizQuestionsHeader,
            bindings: {
                langArray: '<',
                contentForm: '=',
                contentData: '=',
                languageChangeCallback: '<',
            }
        });

    function QuizQuestionsHeader($timeout, $rootScope, GoogleTranslateService) {
        const vm = this;

        vm.translateHandler = translateHandler;
        vm.changeLanguage = changeLanguage;

        /**
         * On language change handler
         */
        function changeLanguage() {
            vm.languageChangeCallback();
            $timeout(() =>  {
                $rootScope.$broadcast('language-change', {
                    lang: vm.contentData.language_id
                });
            });
        }

        /**
         * Translate handler
         *
         * @param tgtLang - target language
         */
        function translateHandler(tgtLang) {
            const content = vm.contentData;
            const srcLang = vm.langArray.find(lang => lang.id === content.language_id);
            const translate = text => GoogleTranslateService.translate(text, srcLang.code, tgtLang.code);

            content.language_id = tgtLang.id;

            content.message.quiz.questions.forEach(question => {
                translate(question.title[srcLang.id]).then(text => question.title[tgtLang.id] = text);

                question.votingOptions.forEach(option => {
                    translate(option.answer[srcLang.id]).then(text => option.answer[tgtLang.id] = text);
                });
            });

            vm.languageChangeCallback();
        }
    }
})();

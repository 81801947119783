(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pushCampaignScreensTab', {
            templateUrl: '/assets/views/campaign/push/tabs/screens/screens.tpl.html',
            controller: PushCampaignScreensController,
            bindings: {
                campaignModel: '='
            }
        });

    function PushCampaignScreensController(LocationDataService) {
        const vm = this;

        vm.$onInit = init;

        vm.selectAllChanged = selectAllChanged;
        vm.updateScreensList = getScreensList;

        vm.searchName = '';
        vm.screensGroupingListData = {
            itemsList: {
                columns: [
                    {
                        name: 'Name',
                        class: 'checkbox-list-item-title',
                        width: '100',
                        title: 'name',
                    }
                ],
                trackBy: 'id',
                orderBy: 'name',
            },
            groupsList: {
                columns: [
                    {
                        name: 'Title',
                        class: 'checkbox-list-item-title',
                        width: '100',
                        translate: true,
                        getTranslationParams: ({name}) => ({name}),
                        title: () => 'GROUP_NAME',
                    },
                ],
                trackBy: 'id',
            },
            updateSelectedItemsListCallback: updateSelectedScreens
        };

        function init() {
            if (!vm.campaignModel.locations) {
                vm.campaignModel.locations = [];
            }
            getScreensList();
        }

        /**
         * On selectAll value change handler
         */
        function selectAllChanged() {
            vm.campaignModel.locations = vm.selectAll ? angular.copy(vm.screens) : [];
        }

        /**
         * Updates selected screens list
         * @param selectedScreens
         */
        function updateSelectedScreens(selectedScreens) {
            vm.campaignModel.locations = selectedScreens;
            vm.selectAll = selectedScreens.length === vm.screens.length;
        }

        /**
         * Get list of locations (screens)
         * @return {*}
         */
        function getScreensList() {
            const requestData = {
                type: 'screen'
            };

            if (vm.searchName) {
                requestData.name = vm.searchName;
            }

            return LocationDataService.getSections(requestData)
                .then(response => {
                    vm.screens = response.plain().list;
                    vm.selectAll = !vm.searchName && (vm.screens.length === vm.campaignModel.locations.length);
                })
        }
    }
})();
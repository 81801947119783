(function() {
    'use strict';

    angular.module('pushNotification')
        .component('pushNotificationItem', {
            templateUrl: '/assets/views/push-notification/push-notification-item/push-notification-item.tpl.html',
            bindings: {
                title: '<',
                message: '<',
                class: '@',
                flag: '<',
                imageLoadedCallback: '<',
            }
        });
})();
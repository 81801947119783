(function() {
    'use strict';

    angular.module('lineRoute')
        .service('RouteBase', (RouteStation, RouteLine, RouteHelper) => {

            class RouteBase {
                constructor(config) {
                    this.SINGLE_LINE_TEXT_WIDTH_COEFFICIENT = 1.5;

                    this.config = config;

                    if (!this.config.stations) {
                        return;
                    }

                    this.paddingX = RouteHelper.getPercent(config.width, config.paddingX);
                    this.paddingY = RouteHelper.getPercent(config.height, config.paddingY);

                    this.workspaceWidth = config.width - this.paddingX * 2;
                    this.workspaceHeight = config.height - this.paddingY * 2;

                    this.isMultiLine = config.stations.length > config.limitPerLine;
                    this.stationsPerLine = this.isMultiLine
                        ? Math.ceil(config.stations.length / 2)
                        : config.stations.length;

                    this.arrowSize = 25;
                    this.fontSize = config.fontSize;
                }
                /**
                 * Draw element on canvas
                 *
                 * @param context
                 */
                draw(context) {
                    this.config.stations.forEach((station, index) => {
                        const nextIndex = index + 1;

                        const currentCoords = {
                            x: this.getX(index),
                            y: this.getY(index),
                        };

                        const nextCoords = {
                            x: this.getX(nextIndex),
                            y: this.getY(nextIndex),
                        };

                        if (this.config.stations[nextIndex]) {
                            const line = new RouteLine({
                                from: currentCoords,
                                to: nextCoords,
                            });
                            line.draw(context);
                        }

                        const angle = this.getTextAngle(index);
                        const st = new RouteStation({
                            coords: currentCoords,
                            name: station.name,
                            products: this.config.stationProducts && station.products || [],
                            labelAngle: angle,
                            labelMaxWidth: this.getWithLimit(index, angle, currentCoords),
                            labelPadding: this.config.stationLabelPadding,
                            time: index !== 0 && this.config.stationDeparture && station.time,
                            fontSize: this.fontSize,
                            pointColor: index === 0 ? '#a5037c' : '#1b9748',
                        });
                        st.draw(context);
                    });
                }

                /**
                 * Get line number (index) by array index
                 *
                 * @param index
                 * @returns {number}
                 */
                getLineIndex(index) {
                    return Math.trunc(index / this.stationsPerLine);
                }

                /**
                 * Get the number of lines
                 *
                 * @returns {number}
                 */
                getLinesCount() {
                    return Math.ceil(this.config.stations.length / this.stationsPerLine);
                }

                /**
                 * Check if current line is reversed (2nd line)
                 *
                 * @param line
                 * @returns {boolean}
                 */
                static isLineReversed(line) {
                    return line % 2 === 1;
                }

                /**
                 * Convert degrees to radians
                 *
                 * @param deg
                 * @returns {number}
                 */
                static toRadians(deg) {
                    return deg * Math.PI / 180;
                }
            }
            
            return RouteBase;
        });
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('beaconItemsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/beacons-tab/beacon-items-tab/beacon-items-tab.tpl.html',
            controller: BeaconItemsTab,
            bindings: {
                beacons: '<',
                searchName: '<',
                updateBeacons: '=',
                onSelect: '=',
            }
        });

    function BeaconItemsTab(
        $scope,
        $state,
        ListPaginationService,
    ) {
        const vm = this;

        vm.filteredBeacons = vm.beacons;
        vm.updateCounter = 0;
        vm.beaconsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'locationTitle',
                    width: '40',
                    title: item => item.name

                }
            ],
            itemClickCallback: selectBeacon,
            updateCallback: updateList,
            needRadiobutton: true,
        };

        /**
         * Handle screen item click
         *
         * @param {object} beacon
         */
        function selectBeacon(beacon) {
            vm.onSelect(beacon.name, [beacon.locationId]);
            vm.selectedBeacon = beacon;
        }

        /**
         * Updates beacons list
         * @param page
         * @param itemsPerPage
         */
        function updateList(page, itemsPerPage) {
            vm.visibleBeacons = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredBeacons).visibleItems;
        }

        /**
         *
         * @param name
         * @return {T[]}
         */
        function filterBeaconsByName(name) {
            return vm.beacons.filter(beacon => beacon.name.toUpperCase().includes(name.toUpperCase()));
        }

        $scope.$watch(
            () => vm.searchName,
            searchName => {
                vm.filteredBeacons = vm.searchName
                    ? filterBeaconsByName(searchName)
                    : vm.beacons;
                vm.updateCounter++;
            }
        );

        $scope.$watch(
            () => vm.beacons,
            () => {
                if (vm.beacons) {
                    vm.filteredBeacons = vm.searchName ? filterBeaconsByName(vm.searchName) : vm.beacons;
                    vm.updateCounter++;
                }
            },
            true
        );
    }
})();
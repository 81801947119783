(function () {
    'use strict';

    angular.module('beacon.app')
        .component('trmPoiCategories', {
            templateUrl: '/assets/views/trm-poi/categories/trm-poi-categories.tpl.html',
            controller: TrmPoiCategoriesController
        });

    function TrmPoiCategoriesController(
        $state,
        PermissionsService,
        TrmPoiContentDataService,
        PopupService,
        UtilitiesService,
    ) {
        const vm = this;

        vm.searchQuery = '';
        vm.addCategory = addCategory;
        vm.updateCounter = 0;
        vm.filteredCategories = [];
        vm.filterCategoriesList = filterCategoriesList;
        vm.categoryCount = 0;
        vm.$onInit = function () {
            getCategories();
        };

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '35',
                    title: listItem => listItem.name,
                },
                {
                    name: 'MAIN_CATEGORY',
                    class: 'poi-content-title',
                    width: '35',
                    title: listItem => listItem.mainCategoryName,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deletePoiContent,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'editBtn',
                        callback: editPoiContent,
                        permissionAction: 'modify'
                    }
                ]
            },
            updateCallback: updateList,
            generatePermissionName: generatePermissionName,
        };

        /**
         * Create new element
         */
        function addCategory() {
            $state.go('app.addTrmPoiCategory');
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param itemsPerPage
         */
        function updateList(page = 1, itemsPerPage = 25) {
            vm.visibleCategories = UtilitiesService.getArrayPage(vm.filteredCategories, page, itemsPerPage);
        }

        /**
         * Updates list of categories
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function getCategories() {
            TrmPoiContentDataService.getTrmPoiCategories()
                .then(response => {
                    vm.poiCategories = response.data.sort((a, b) => a.mainCategoryName.localeCompare(b.mainCategoryName));
                    filterCategoriesList();
                    vm.categoryCount++;
                });
        }

        /**
         * Filters invitation list
         */
        function filterCategoriesList() {
            vm.filteredCategories = vm.searchQuery
                ? vm.poiCategories.filter(category => (
                    category.name.toUpperCase().includes(vm.searchQuery.toUpperCase()) 
                        || category.mainCategoryName.toUpperCase().includes(vm.searchQuery.toUpperCase())
                ))
                : vm.poiCategories;

            vm.categoryCount++;
        }

        /**
         * Removes poi category by id
         *
         * @param {MouseEvent} $event
         * @param {object} poiContent
         */
        function deletePoiContent($event, poiCategory) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_POI_CATEGORY',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {v
                TrmPoiContentDataService.deleteTrmPoiCategory(poiCategory.id)
                    .then(() => {
                        vm.updateCounter++;
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Edit poi content
         *
         * @param {MouseEvent} $event
         * @param {Object} poiContent
         */
        function editPoiContent($event, poiCategory) {
            $state.go('app.editTrmPoiCategory', { data: poiCategory });
        }

        /**
         * Generates permission name for each button on POI elements accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return PermissionsService.generateTrmPoiCategoriesPermission(button.permissionAction);
        }
    }
})();

(function () {
    'use strict';

    angular.module('beacon.app')
        .component('shopListPreview', {
            templateUrl: '/assets/views/loyalty/sponsor/new/shop-list-preview/shop-list-preview.tpl.html',
            controller: ShopListPreviewController,
            bindings: {
                shopData: '<',
            }
        });
    
    /**
     * @param UtilitiesService
     * @param GoogleMapsUtilitiesService
     * @param UserUtilitiesService
     *
     * @property {ShopConfig} shopData
     * @property {boolean} useHtmlEditor
     */
    function ShopListPreviewController(
        UtilitiesService,
        GoogleMapsUtilitiesService,
        UserUtilitiesService,
    ) {
        const vm = this;
        const { getCoordinates, toPrecision } = UtilitiesService;
        const { showMap } = GoogleMapsUtilitiesService;
        const { userLocationOptions } = UserUtilitiesService;

        vm.onMapClick = onMapClick;
        vm.$onInit = init;
        vm.onEditorModeChange = onEditorModeChange;

        function init() {
            vm.mapDefaults = userLocationOptions();
        }

        function onMapClick() {
            const coords = getCoordinates(vm.shopData.shop.latlng);

            const mapPopup = showMap({
                mapInfo: coords ? [coords] : null,
                mapDefaults: vm.mapDefaults,
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(mapInfo => {
                const coords = mapInfo.coordinates;
                const precision = 6;

                const lat = toPrecision(coords.latitude, precision);
                const lng = toPrecision(coords.longitude, precision);
                vm.shopData.shop.latlng = `${lat},${lng}`;
            });
        }

        function onEditorModeChange() {
            if (vm.useHtmlEditor) {
                vm.shopData.shop.description = window.html_beautify(vm.shopData.shop.description);
            }
        }
    }
})();

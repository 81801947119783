(function() {
    'use strict';

    angular.module('scMeetings').service(
        'InvitationsApiService',
        function(
            $http,
            $window,
        ) {
            return {
                getInvitations,
            }

            /**
             * @return {Promise<SCInvitation[]>}
             */
            function getInvitations()
            {
                return $http
                    .get(`/api/sc-meetings${$window.location.search}`)
                    .then(response => response.data)
            }
        }
    );
})();

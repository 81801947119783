(function() {
    'use strict';

    angular.module('beacon.app')
        .component('digitalDisplayContentType', {
            templateUrl: '/assets/views/content/elements/types/digital-display/digital-display.tpl.html',
            controller: DigitalDisplayContentType,
            bindings: {
                langArray: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<'
            }
        });

    function DigitalDisplayContentType($scope, $window, $stateParams,
                                       ContentDataService, UtilitiesService,
                                       ModelsFactory, StatesFactory, StorageFactory, PageDataService,
                                       DigitalDisplayHelper) {
        const vm = this;
        const { plainResponse } = UtilitiesService;

        // public properties
        vm.state = StatesFactory.DigitalDisplayStates.refresh();

        // public methods
        vm.generatePreview = generatePreview;

        init();

        // private methods

        /**
         * Initialization method
         */
        function init() {
            vm.STORAGE = new StorageFactory.Storage('Content-DigitalDisplay', true);
            vm.model = vm.contentData.message[vm.contentData.language_id];

            // Update state
            vm.state.type = $stateParams.type;

            // Load DigitalDisplay pages
            if (vm.contentData.id) {
                loadPages();
            }
        }

        /**
         * Loads digital display pages
         *
         * @return {Promise}
         */
        function loadPages() {
            PageDataService.contentPages({ id: vm.contentData.id })
                .then((pages) => {
                    pages = plainResponse(pages);

                    for (let page of pages) {
                        let pageData = angular.fromJson(page.json_content);

                        for (let langId in pageData) {
                            if (pageData.hasOwnProperty(langId)) {
                                let model = vm.contentData.message[langId];

                                if (!model.pages) {
                                    model.pages = [];
                                }
                                pageData[langId].dbId = page.id;
                                pageData[langId].staticId = page.static_id;

                                model.pages.push(new ModelsFactory.DigitalDisplayPage(pageData[langId]));
                            }
                        }
                    }
                })
                .catch(console.error.bind(console));
        }

        /**
         * NOTE: Modify data structure for backward compatibility.
         * When BasicApp will update -> update this fn
         */
        function generatePreview() {
            DigitalDisplayHelper.generatePreview(vm.contentData);
        }

        function onFinish(event, option) {
            if (typeof option.callback === 'function') {
                for (let languageIndex in vm.contentData['message']) {
                    if (!vm.contentData['message'].hasOwnProperty(languageIndex)) {
                        continue;
                    }

                    const languageData = vm.contentData['message'][languageIndex];
                    const backgroundImageProperty = `background_image${languageIndex}`;
                    const backgroundImageSrcProperty = `background_image_src${languageIndex}`;

                    if (languageData.globalPagesSettings && languageData.globalPagesSettings.backgroundImageSource) {
                        vm.contentData[backgroundImageProperty] = languageData.globalPagesSettings.backgroundImage;
                        vm.contentData[backgroundImageSrcProperty] = languageData.globalPagesSettings.backgroundImageSource;
                    } else {
                        vm.contentData[backgroundImageProperty] = null;
                        vm.contentData[backgroundImageSrcProperty] = null;
                    }

                    languageData.pages.forEach(page => {
                        delete page.dbId;
                    });
                }

                option.callback({
                    type: vm.state.type,
                    contentData: angular.copy(vm.contentData)
                });
            }
        }

        function onPagesLengthChange() {
            vm.state.canFinish = !!vm.model.pages.length;
        }

        function onStateChange() {
            // NOTE: canFinish -> add validation
            // NOTE: canSavePage -> add validation
            switch (Number(vm.state.view)) {
                case vm.state.views.PAGES_LIST[0]:
                    Object.assign(vm.state, {
                        canFinish: !!vm.model.pages.length
                    });
                    break;
                case vm.state.views.EDIT_PAGE[0]:
                    Object.assign(vm.state, {
                        canBack: true,
                        canSavePage: true
                    });
                    break;
            }
        }

        function onLanguageChange(event, option) {
            vm.model = vm.contentData.message[option.newValue];

            // Update state
            vm.state.canFinish = !!vm.model.pages.length;
        }

        // Watchers
        $scope.$watch(() => vm.state.view, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                onStateChange();
            }
        });
        $scope.$watch(() => vm.contentData.title, (newValue) => {
            if (!newValue || typeof newValue !== 'object') {
                return;
            }

            vm.langArray.forEach(function(lang) {
                if (!vm.contentData.title[lang.id]) {
                    vm.contentData.title[lang.id] = vm.contentData.title[vm.contentData.language_id];
                }
            });
        });
        $scope.$watch(() => vm.model.pages && vm.model.pages.length, (newValue, oldValue) => {
            if (newValue !== undefined && newValue !== oldValue) {
                onPagesLengthChange();
            }
        });

        // Listeners
        $scope.$on('content-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

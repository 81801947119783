(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TagService', TagService);

    function TagService(Restangular) {

        /**
         * Get all tags
         *
         * @returns {Promise}
         */
        function get() {
            return Restangular.all('tags').getList();
        }

        /**
         * Create tags
         *
         * @param {Array} data - array of tag objects
         * @returns {Promise}
         */
        function create(data) {
            return Restangular.all('tags').post(data);
        }

        return {
            get,
            create,
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poiImport', {
            templateUrl: '/assets/views/poi/import/poi-import.tpl.html',
            controller: PoiImportController
        });

    function PoiImportController(
        $state,
        $stateParams,
        $translate,
        PoiImportDataService,
        LanguageService,
        ListPaginationService,
        PoiContentDataProcessingService,
        PopupService,
        StorageFactory,
        HTTP_STATUSES,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.types = [];
        vm.importedItems = [];
        vm.filteredImportedItems = [];
        vm.markers = [];
        vm.selectedItems = [];
        vm.FILTER_ALL = -1;
        vm.selectedTypeFilter = vm.FILTER_ALL;
        vm.updateCounter = 0;
        vm.searchValue = '';

        vm.importPOIs = importPOIs;
        vm.createPOIElements = createPOIElements;
        vm.applyFilters = applyFilters;

        let STORAGE = StorageFactory.Storage('POIImport');

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '45',
                    title: 'name',
                },
                {
                    name: 'TYPE',
                    class: 'imported-poi-type',
                    width: '25',
                    title: 'type',
                },
                {
                    name: 'PROVIDER',
                    class: 'imported-poi-provider',
                    width: '25',
                    title: 'providerId',
                },
            ],
            updateCallback: updateVisibleItems,
            needCheckbox: true,
        };

        /**
         * Initialization method
         */
        function init() {
            if (!STORAGE) {
                STORAGE = new StorageFactory.Storage('POIImport', true);
            }
            vm.paginationParams = $stateParams.paginationData;
            PoiImportDataService.getImportedPOIs().then(result => {
                result.importedPOIs.forEach(poi => {
                    poi.isOpen = false;
                });
                vm.importedItems = result.importedPOIs;
                vm.filteredImportedItems = vm.importedItems;
                vm.types = result.importedPOIsTypes;
                vm.elementsCount = vm.importedItems.length;
                updateVisibleItems(1);
                setMarkers();
            });
            LanguageService.getLanguages().then((response) => {
                const langsArray = response.plain();
                vm.defaultLanguageId = langsArray[0].id;
            })
        }

        /**
         * Imports POIs from server
         */
        function importPOIs() {
            PoiImportDataService.importPOIs()
                .then(response => {
                    const result = response.plain();
                    result.forEach(poi => {
                        poi.lat = Number(poi.lat);
                        poi.lng = Number(poi.lng);
                        if (!vm.types.includes(poi.type)) {
                            vm.types.push(poi.type);
                        }
                    });
                    vm.importedItems = result;
                    vm.elementsCount = result.length;
                    STORAGE.set('importedPOIs', angular.copy(vm.importedItems));
                    STORAGE.set('importedPOIsTypes', vm.types);
                    applyFilters();
                });
        }

        /**
         * Updates visible items list
         * @param page
         * @param itemsPerPage
         */
        function updateVisibleItems(page = vm.paginationParams.page,
                                    itemsPerPage = vm.paginationParams.itemsPerPage) {
            const result = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredImportedItems);
            vm.visibleItems = result.visibleItems;
            vm.paginationParams = result.paginationParams;
        }

        /**
         * Filters elements list by type filter and by search value
         */
        function applyFilters() {
            if (vm.selectedTypeFilter === vm.FILTER_ALL) {
                if (!vm.searchValue) {
                    vm.filteredImportedItems = vm.importedItems;
                } else {
                    vm.filteredImportedItems = vm.importedItems.filter(importedPOI => {
                        return importedPOI.name.toUpperCase().includes(vm.searchValue.toUpperCase());
                    });
                }
            } else {
                vm.filteredImportedItems = vm.importedItems.filter(importedPOI => {
                    if (!vm.searchValue) {
                        return importedPOI.type === vm.selectedTypeFilter;
                    } else {
                        return importedPOI.type === vm.selectedTypeFilter &&
                            importedPOI.name.toUpperCase().includes(vm.searchValue.toUpperCase());
                    }
                });
            }
            vm.elementsCount = vm.filteredImportedItems.length;
            vm.selectedItems = [];
            vm.updateCounter++;
            setMarkers();
        }

        /**
         * Updates markers array
         */
        function setMarkers() {
            vm.markers = [];
            vm.filteredImportedItems.forEach(poiElement => {
                vm.markers.push({
                    label: poiElement.name,
                    position: {
                        lat: poiElement.lat,
                        lng: poiElement.lng,
                    }
                })
            })
        }

        /**
         * Creates POI elements from imported ones
         */
        function createPOIElements() {
            const newPoiElementsData = vm.selectedItems.map(importedPOI => {
                const newPoiData = PoiContentDataProcessingService.processImportedContent(importedPOI, vm.defaultLanguageId);
                PoiContentDataProcessingService.encodePoiData(newPoiData);
                return newPoiData;
            });
            const formData = new FormData();
            formData.append('poiElements', angular.toJson(newPoiElementsData));
            PoiImportDataService.createPoisFromImported(formData)
                .then(response => {
                    $state.go('app.poiElements');
                })
                .catch((response) => {
                    if (response.status === HTTP_STATUSES.LOCKED) {
                        _showMessageForUsedOrigins(response.data);
                    }
                });
        }

        /**
         * Generates and shows error message if some of imported POIs were used when creating POI elements
         * @param usedIds
         * @return {string}
         * @private
         */
        function _showMessageForUsedOrigins(usedIds) {
            const usedImportedPoisNames = usedIds.map(id => {
                return vm.importedItems.find(item => {
                    return item.id === id;
                }).name;
            });

            Promise.all([
                $translate('THIS_IMPORTED_POIS_WERE_ALREADY_USED'),
                $translate('OK')
            ]).then(([message, okButtonText]) => {
                PopupService.showAlertPopup({
                    text: `${message}: ${usedImportedPoisNames.join(', ')}.`,
                    okButtonText
                });
            })
        }
    }
})();
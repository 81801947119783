(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .directive('timetableText', () => ({
            restrict: 'A',
            transclude: true,
            templateUrl: '/screen/views/directives/timetable-text/timetable-text.tpl.html',
            scope: {
                scale: '<',
                maxHeight: '<',
            },
        }));
}());

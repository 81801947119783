(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ImageHelper', ImageHelperService);

    function ImageHelperService(
        FileService,
        UtilitiesService,
        ContentDataService,
    ) {
        return {
            remoteImageToBlob,
            uploadImage,
            blobToUrl,
        }

        /**
         * @deprecated - I'd recommend not to use it in the future. Using File objects is the best
         * @param {string} imageUrl
         * @returns {Promise<Blob>}
         */
        function remoteImageToBlob(imageUrl) {
            return FileService.getRemoteImage(imageUrl)
                .then(response => UtilitiesService.base64ImageToBlob(response.image, response.type));
        }

        /**
         * @deprecated - use ImageApiService instead
         * @param {Blob} blob
         * @returns {Promise<string>} - image URL
         */
        function uploadImage(blob) {
            const formData = new FormData();
            formData.append('image', blob);
            return ContentDataService.upload(formData);
        }

        /**
         * Creates an url from a blob file
         *
         * @param {Blob} blob
         * @returns {string}
         */
        function blobToUrl(blob) {
            const urlCreator = window.URL || window.webkitURL;
            return urlCreator.createObjectURL(blob)
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
           .component('screenHealth', {
                templateUrl: '/assets/views/location/screen-health/screen-health.tpl.html',
                controller: ScreenHealthController
            });

    function ScreenHealthController(
        LocationDataService,
        UtilitiesService,
        DateHelper,
        POLLING_STATUS,
        SCREEN_CONNECTION_STATUS,
        ScreenHelper,
        ScreenDataService,
        PermissionsService,
    ) {

        const vm = this;
        const PER_PAGE = 25;
        const { getScreenHealth } = LocationDataService;

        let screensAll = [];
        let screensFiltered = [];

        vm.currentPage = null;
        vm.screensLoaded = false;
        vm.updateCounter = 0;
        vm.searchName = '';
        vm.statusFilter = {
            red: false,
            yellow: false,
            green: false,
        };

        vm.SORTING_PARAMS = {
            MINUTE: {
                label: 'MINUTE',
                field: 'minute'
            },
            HOUR: {
                label: 'HOUR',
                field: 'hour'
            },
            DAY: {
                label: 'DAY',
                field: 'day'
            },
            WEEK: {
                label: 'WEEK',
                field: 'week'
            },
            MONTH: {
                label: 'MONTH',
                field: 'month'
            },
            YEAR: {
                label: 'YEAR',
                field: 'year'
            },
        };
        vm.sortBy = vm.SORTING_PARAMS.DAY.field;
        vm.SORTING_OPTIONS = Object.values(vm.SORTING_PARAMS);

        vm.ORDER_TYPES = {
            NAME: {
                id: 1,
                title: 'NAME',
            },
            LAST_INIT_TIME: {
                id: 2,
                title: 'LAST_INIT_TIME',
            },
            HEALTH_CHECK: {
                id: 7,
                title: 'HEALTH_CHECK',
            },
            IFRAME_CHECK: {
                id: 8,
                title: 'IFRAME_HEALTH_CHECK',
            },
            AMOUNT_OF_INITS: {
                id: 3,
                title: 'AMOUNT_OF_INITS',
            },
            LAST_STATUS_CHANGED: {
                id: 4,
                title: 'LAST_STATUS_CHANGED',
            },
            AMOUNT_OF_STATUS_CHANGES: {
                id: 5,
                title: 'AMOUNT_OF_STATUS_CHANGES',
            },
            STATUS: {
                id: 6,
                title: 'STATUS',
            },
        };
        vm.orderBy = vm.ORDER_TYPES.AMOUNT_OF_STATUS_CHANGES.id;
        vm.visibleScreens = [];

        vm.setPeriod = setPeriod;
        vm.onRefreshClick = onRefreshClick;

        vm.onOrderChange = onOrderChange;
        vm.onScreensFilterChange = onScreensFilterChange;

        vm.listData = {
            columns: [
                {
                    name: 'SCREEN_NAME',
                    class: 'column-title',
                    width: '25',
                    title: screen => screen.name,
                },
                {
                    name: 'LAST_INIT_TIME',
                    class: 'text-center',
                    headerClass: 'text-center',
                    width: '10',
                    title: screen => duration(screen, 'LAST_INIT_TIME')
                },
                {
                    name: 'HEALTH_CHECK',
                    class: 'text-center',
                    headerClass: 'text-center',
                    width: '10',
                    title: screen => duration(screen, 'LAST_HEALTH_CHECK')
                },
                {
                    name: 'IFRAME_HEALTH_CHECK',
                    class: 'text-center',
                    headerClass: 'text-center',
                    width: '10',
                    title: screen => duration(screen, 'IFRAME_HEALTH_CHECK')
                },
                {
                    name: 'AMOUNT_OF_INITS',
                    class: 'text-center',
                    headerClass: 'text-center',
                    width: '10',
                    title: screen => screen.inits.length,
                },
                {
                    name: 'LAST_STATUS_CHANGED',
                    class: 'text-center',
                    headerClass: 'text-center',
                    width: '10',
                    title: screen => duration(screen, 'LAST_STATUS_CHANGED'),
                },
                {
                    name: 'AMOUNT_OF_STATUS_CHANGES',
                    class: 'text-center',
                    headerClass: 'text-center',
                    width: '10',
                    title: screen => screen.status_changes.length,
                },
                {
                    name: 'STATUS',
                    headerClass: 'text-center',
                    class: 'status-cell',
                    width: '5',
                    title: status,
                },
            ],
            buttons: {
                items: [
                    {
                        class: 'quickUpBtn',
                        callback: ($event, item) => {
                            $event.preventDefault();
                            $event.stopPropagation();

                            ScreenDataService.reload(item.controllerRef);
                        },
                        tooltip: 'RELOAD_SCREEN',
                        isVisible: () => PermissionsService.isPermissionAvailable('can_reload_screen'),
                    },
                ]
            },
            updateCallback: onUpdate,
            displayMoreItems: _addNextPage,
        };


        init();

        function init() {
            _fetchScreens(vm.sortBy);
        }

        function onRefreshClick() {
            _fetchScreens(vm.sortBy);
        }

        function onUpdate() {

        }

        function onOrderChange() {
            _orderScreens();
            _filterScreens();
            _showFirstPage();
        }

        function onScreensFilterChange() {
            _orderScreens();
            _filterScreens();
            _showFirstPage();
        }

        function _showFirstPage() {
            vm.visibleScreens = [];
            _addPage(1);
            vm.currentPage = 1;
        }

        function _addNextPage() {
            _addPage(++vm.currentPage);
        }

        function _addPage(page) {
            if (vm.visibleScreens.length < screensFiltered.length) {
                vm.visibleScreens.push(
                    ... UtilitiesService.getArrayPage(screensFiltered, page, PER_PAGE),
                );
            }
        }

        function _filterScreens() {
            screensFiltered = screensAll
                .filter(screen => {
                    const visibleBySearch = !vm.searchName
                        || screen.name.toUpperCase().includes(vm.searchName.toUpperCase())
                        || screen.hash.toUpperCase().includes(vm.searchName.toUpperCase());

                    return visibleBySearch && _isVisibleByStatus(screen);
                });
        }

        function _isVisibleByStatus(screen) {
            if (!vm.statusFilter.red && !vm.statusFilter.yellow && !vm.statusFilter.green) {
                return true;
            }

            return vm.statusFilter[screen.connectionStatus];
        }

        function setPeriod() {
            switch (vm.sortBy) {
                case vm.SORTING_PARAMS.MINUTE.field:
                    _fetchScreens(vm.sortBy);
                    break;
                case vm.SORTING_PARAMS.HOUR.field:
                    _fetchScreens(vm.sortBy);
                    break;
                case vm.SORTING_PARAMS.DAY.field:
                    _fetchScreens(vm.sortBy);
                    break;
                case vm.SORTING_PARAMS.WEEK.field:
                    _fetchScreens(vm.sortBy);
                    break;
                case vm.SORTING_PARAMS.MONTH.field:
                    _fetchScreens(vm.sortBy);
                    break;
                case vm.SORTING_PARAMS.YEAR.field:
                    _fetchScreens(vm.sortBy);
                    break;
                default: break;
            }
        }

        /**
         * Set status light
         * @param item
         * @returns {string}
         */
        function status(item) {
            return `<div class="traffic-light ${item.connectionStatus ? item.connectionStatus : ''}"></div>`;
        }

        /**
         * Returns date in "1d 2h 3m" format
         * @param screen
         * @param columnName
         * @returns {string}
         */
        function duration({ inits, status_changes, lastCall, lastIframeCall }, columnName) {
            switch (columnName) {
                case 'LAST_INIT_TIME':
                    if (!inits.length) return '';
                    const initDate = inits[0].created_at;
                    return  DateHelper.duration(moment.utc(initDate), moment(), true, 2);

                case 'LAST_STATUS_CHANGED':
                    if (!status_changes.length) return '';
                    const statusDate = status_changes[0].created_at;
                    return  DateHelper.duration(moment.utc(statusDate), moment(), true, 2);

                case 'LAST_HEALTH_CHECK':
                    if (!lastCall) return '';

                    return  DateHelper.duration(moment.utc(lastCall), moment(), true, 2);

                case 'IFRAME_HEALTH_CHECK':
                    if (!lastIframeCall) return '';

                    return DateHelper.duration(moment.utc(lastIframeCall), moment(), true, 2);

                default:
                    return '';
            }
        }

        /**
         * Set screen status
         * @param lastCall
         * @returns {string}
         */
        function setScreenConnectionStatus(lastCall) {
            const { RED, YELLOW, GREEN } = POLLING_STATUS;
            const { RED_TIME, YELLOW_TIME } = SCREEN_CONNECTION_STATUS;
            let status = RED;

            if(lastCall) {
                const lastCallMoment = moment.utc(lastCall).local();
                const nowMoment = moment();
                const diff = moment.utc(nowMoment.diff(lastCallMoment, 'minutes'));

                switch (true) {
                    case diff > RED_TIME:
                        status = RED;
                        break;
                    case diff > YELLOW_TIME:
                        status = YELLOW;
                        break;
                    default:
                        status = GREEN;
                }
            }
            return status;
        }

        function _orderScreens() {
            const orderTypeKey = Object.keys(vm.ORDER_TYPES).find(key => vm.ORDER_TYPES[key].id === vm.orderBy);
            const orderType = vm.ORDER_TYPES[orderTypeKey];

            screensAll.sort((a, b) => {
                switch (orderType) {
                    case vm.ORDER_TYPES.NAME:
                        return checkOrder(a.name, b.name);

                    case vm.ORDER_TYPES.LAST_INIT_TIME:
                        const initA = a.inits[0] && a.inits[0].created_at
                            ? a.inits[0].created_at
                            : '0'; // to move it to the bottom
                        const initB = b.inits[0] && b.inits[0].created_at
                            ? b.inits[0].created_at
                            : '0'; // to move it to the bottom
                        return checkOrder(initB, initA);

                    case vm.ORDER_TYPES.AMOUNT_OF_INITS:
                        return checkOrder(b.inits.length, a.inits.length);

                    case vm.ORDER_TYPES.HEALTH_CHECK:
                        if (b.lastCall === null) {
                            return -1;
                        }

                        return checkOrder(b.lastCall, a.lastCall);

                    case vm.ORDER_TYPES.IFRAME_CHECK:
                        if (b.lastIframeCall === null) {
                            return -1;
                        }

                        return checkOrder(b.lastIframeCall, a.lastIframeCall);

                    case vm.ORDER_TYPES.LAST_STATUS_CHANGED:
                        const statusA = a.status_changes[0] && a.status_changes[0].created_at
                            ? a.status_changes[0].created_at
                            : '0'; // to move it to the bottom
                        const statusB = b.status_changes[0] && b.status_changes[0].created_at
                            ? b.status_changes[0].created_at
                            : '0'; // to move it to the bottom
                        return checkOrder(statusB, statusA);

                    case vm.ORDER_TYPES.AMOUNT_OF_STATUS_CHANGES:
                        return checkOrder(b.status_changes.length, a.status_changes.length);

                    case vm.ORDER_TYPES.STATUS:
                        const statA = a.lastCall || -1;
                        const statB = b.lastCall || -1;
                        return checkOrder(statA, statB);

                    default:
                        return checkOrder(a.name, b.name);
                }
            });

            function checkOrder(a, b) {
                switch(true) {
                    case a > b:
                        return 1;
                    case a < b:
                        return -1;
                    default:
                        return 0;
                }
            }
        }

        function _fetchScreens(period) {
            getScreenHealth(period)
                .then(response => {
                    screensFiltered = screensAll = response.plain()
                        .map(screen => {
                            if (screen.lastCall) {
                                screen.connectionStatus = setScreenConnectionStatus(screen.lastCall);
                            }

                            screen.url = ScreenHelper.screenUrl(screen);

                            return screen;
                        });

                    vm.screenItemsCount = screensAll.length;
                    vm.screensLoaded = true;
                    vm.updateCounter++;
                    _orderScreens();
                    _filterScreens();
                    _showFirstPage();
                });
        }

    }

}());

(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.factory('campaignScreenState', function() {
        return {
            views: {
                CONTENT: 0,
                CAMPAIGN_DETAILS: 1,
                SECTIONS: 2
            }
        };
    });

    app.controller('ScreenCampaignAddToolbarController', ScreenCampaignAddToolbarController);

    function ScreenCampaignAddToolbarController(campaignScreenState, PermissionsService) {
        const vm = this;

        const {
            generateScreenCampaignPermission,
            actions
        } = PermissionsService;

        // public properties
        vm.state = campaignScreenState;
        vm.state.canProcessNext = false;
        vm.state.view = 0;
        vm.state.finish = 0;

        // public methods
        vm.next = next;
        vm.back = back;
        vm.finish = finish;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;
        vm.modifyScreenCampaignPermission = generateScreenCampaignPermission(actions.modify);

        /**
         * Switches to selected tab
         *
         * @param {number} tab
         */
        function goToTab(tab) {
            if (tab < vm.state.view) {
                vm.state.view = tab;
            }
        }

        /**
         * Returns tab class
         * @param {number} tab
         * @returns {string}
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view++;
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.canProcessNext = false;
            vm.state.view--;
        }

        /**
         * Saves content
         */
        function finish() {
            vm.state.finish++;
        }
    }
}());

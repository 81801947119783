(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('minTime', minTime);

    /**
     * Directive validates clockpicker minimum time
     *
     * @returns {object}
     */
    function minTime() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel) {
                    return;
                }

                var validate = function() {
                    var minValue = scope.$eval(attrs.minTime),
                        modelValue = scope.$eval(attrs.ngModel);

                    if (modelValue && minValue && modelValue < minValue) {
                        ngModel.$setValidity('minTime', false);
                    } else {
                        ngModel.$setValidity('minTime', true);
                    }
                };

                scope.$watch(attrs.ngModel, function() {
                    validate();
                });

                scope.$watch(attrs.minTime, function() {
                    validate();
                });
            }
        };
    }
})();

(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('timetableHafas', {
            templateUrl: '/screen/views/components/timetable-hafas/timetable-hafas.tpl.html',
            controller: TimetableHafasController,
            bindings: {
                content: '<',
                mctConfig: '<',
                header: '<',
                footer: '<',
                subdomainId: '<',
                screenController: '<',
                onUpdate: '&', // usage: $ctrl.onUpdates($event)
                simulateNoJourneys: '<',
                stationsFilter: '<',
                hideError: '<', // use it if you don't want to show errors at all (except "no journeys")
            }
        });

    /**
     * @param {$q} $q
     * @param {$rootScope.Scope} $scope
     * @param {JourneyFactory} JourneyFactory
     * @param {JourneysProviderService} JourneysProviderService
     * @param {MgateService} MgateService
     * @param {$sce} $sce
     * @param {TimetableHafasService} TimetableHafasService
     * @param {DayNightService} DayNightService
     * @param {LogService} LogService
     * @param {JourneysBuffer} JourneysBuffer
     * @param {typeof TimetableHafasOptionsModel} TimetableHafasOptionsModel
     * @param {MultiStationsHelper} MultiStationsHelper
     *
     * @property {TimetableHafasContent} content
     * @property {MctConfig} mctConfig
     * @property {HeaderFooterContent} header - for the preview in CMS (for the screen app header is here: $ctrl.content.data.header)
     * @property {HeaderFooterContent} footer - for the preview in CMS (for the screen app header is here: $ctrl.content.data.footer)
     * @property {number} subdomainId         - for the preview in CMS (for the screen app subdomainId is here: $ctrl.content.subdomain_id)
     * @property {ScreenController} screenController
     * @property {function({$event: {totalTransition: number}})} onUpdate
     * @property {boolean} simulateNoJourneys
     * @property {number[]} stationsFilter
     * @property {boolean} hideError
     *
     * @constructor
     */
    function TimetableHafasController(
        $q,
        $scope,
        JourneyFactory,
        JourneysProviderService,
        MgateService,
        $sce,
        TimetableHafasService,
        DayNightService,
        LogService,
        JourneysBuffer,
        TimetableHafasOptionsModel,
        MultiStationsHelper,
    ) {
        const $ctrl = this;
        const log = (...args) => LogService.colored('#ffffff', '#000', 'Timetable (HAFAS) |', ...args);

        /** @type {JourneysBuffer} */
        let _buffer;

        /** @type {TimetableApi} */
        $ctrl.timetableApi = {};

        /** @type {Journey[]} - Filtered journeys (only future) */
        $ctrl.journeys = [];

        /** @type {MgateHim[]} - Loaded journeys */
        $ctrl.hims = [];

        $ctrl.isMgateOnline = false;
        $ctrl.errorMessage = '';
        $ctrl.loaded = false;

        $ctrl.$onInit = () => {
            $ctrl.stationName = _getStationName();
            $ctrl.options = _getOptions();

            _initColorScheme();
            _watchForUpdates();
        };

        $ctrl.$onDestroy = () => {
            _destroyBuffer();
        };

        /**
         * @param {number} maxJourneysPerView
         */
        $ctrl.onViewChanged = (maxJourneysPerView) => {
            _destroyBuffer();
            _initBuffer(maxJourneysPerView);
        }

        function _destroyBuffer() {
            _buffer && _buffer.destroy();
        }

        /**
         * @param {number} maxJourneysPerView
         * @private
         */
        function _initBuffer(maxJourneysPerView) {
            _buffer = new JourneysBuffer.JourneysBuffer({
                subdomainId: $ctrl.content.subdomain_id || $ctrl.subdomainId,
                limit: maxJourneysPerView * $ctrl.options.bufferSizeMultiplier,
                timetableOptions: $ctrl.options,
                mctConfig: $ctrl.mctConfig,
                screenController: $ctrl.screenController,
                stationsFilter: $ctrl.stationsFilter,
                callbacks: {
                    onChange: _onBufferChange,
                    onError: _onBufferError,
                }
            });
        }

        /**
         * @param {Journey[]} journeys
         * @param {MgateHim[]} hims
         * @param {boolean} isMgateOnline
         * @private
         */
        function _onBufferChange(journeys, hims, isMgateOnline) {
            $ctrl.errorMessage = null;
            $ctrl.journeys = journeys;
            $ctrl.hims = hims;
            $ctrl.isMgateOnline = isMgateOnline;
            $ctrl.loaded = true;

            _sendUpdatesEvent();

            void $ctrl.timetableApi.refreshView();
        }

        /**
         * @param {string} msg
         * @private
         */
        function _onBufferError(msg) {
            $ctrl.errorMessage = msg;

            _sendUpdatesEvent();
        }

        function _sendUpdatesEvent() {
            if ($ctrl.onUpdate) {
                $ctrl.onUpdate({
                    $event: {
                        totalTransition: $ctrl.hims.length * $ctrl.options.him.interval,
                        error: Boolean($ctrl.errorMessage),
                    }
                });
            }
        }

        function _initColorScheme() {
            $ctrl.colorsScheme = _getColorScheme();
            log('Initial color scheme:', $ctrl.colorsScheme);

            if ($ctrl.options.colors.mode !== 'auto') {
                return;
            }

            log('Auto color scheme: Enabled');

            $scope.$on(
                'DayNightService@change',
                /**
                 * @param event
                 * @param {'default'|'night'} colorsScheme
                 */
                (event, colorsScheme) => {
                    log('Changing color scheme to', colorsScheme);
                    $ctrl.colorsScheme = colorsScheme;
                });
        }

        /**
         * @return {'default'|'night'}
         * @private
         */
        function _getColorScheme() {
            if ($ctrl.options.colors.mode !== 'auto') {
                return $ctrl.options.colors.mode;
            }

            return DayNightService.currentColorScheme();
        }

        /**
         * @returns {TimetableHafasOptionsModel}
         * @private
         */
        function _getOptions() {
            let options = $ctrl.content.data;

            if (typeof options === 'string') {
                try {
                    options = JSON.parse(options);
                } catch(err) {
                    console.error(err);
                }
            }

            return new TimetableHafasOptionsModel(options);
        }

        function _getStationName() {
            const multiStationsAvailable = MultiStationsHelper.canSupportMultiStations($ctrl.screenController);

            return multiStationsAvailable
                ? MultiStationsHelper.getMultiStationsName($ctrl.screenController, $ctrl.stationsFilter)
                : $ctrl.screenController.controllerLabel || $ctrl.screenController.station.stopName;
        }

        function _watchForUpdates() {
            $scope.$watch(
                () => $ctrl.mctConfig,
                () => {
                    if ($ctrl.timetableApi.refreshView) {
                        void $ctrl.timetableApi.refreshView();
                    }
                },
                true,
            )
        }
    }
})();

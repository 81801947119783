(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capacityItem', {
            templateUrl: '/assets/views/share-park/car-park-capacity/capacity-item/capacity-item.tpl.html',
            controller: CapacityItemController,
            bindings: {
                capacity: '<',
                title: '<',
            }
        });

    function CapacityItemController(

    ) {
        const vm = this;

        vm.isStatusAvailable = isStatusAvailable;
        vm.isStatusFull = isStatusFull;
        vm.isStatusHighLoad = isStatusHighLoad;
        vm.occupation = occupation;
        vm.capacityReservations = capacityReservations;
        vm.capacityMeasured = capacityMeasured;

        /**
         * @returns {boolean}
         */
        function isStatusAvailable() {
            return !! vm.capacity.capacityVirtual;
        }

        /**
         * @returns {boolean}
         */
        function isStatusFull() {
            return capacityReservations() <= vm.capacity.alertThreshold;
        }

        /**
         * @returns {boolean}
         */
        function isStatusHighLoad() {
            return !isStatusFull() && capacityReservations() <= vm.capacity.warningThreshold;
        }

        /**
         * @returns {number}
         */
        function occupation() {
            return 100 - Math.round(capacityReservations() / vm.capacity.capacityVirtual * 100);
        }

        /**
         * @returns {number}
         */
        function capacityReservations() {
            const value = [
                vm.capacity.capacityLookahead,
                vm.capacity.capacityReservation,
                vm.capacity.capacityVirtual,
                vm.capacity.capacityPhysical
            ].find(value => value !== null);

            return value !== undefined
              ? value
              : null;
        }

        /**
         * @returns {number}
         */
        function capacityMeasured() {
            const value = [
                vm.capacity.capacityMeasured2,
                vm.capacity.capacityMeasured1,
            ].find(value => value !== null);

            return value !== undefined
              ? value
              : null;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('RevenuesDataService', RevenuesDataService);

    function RevenuesDataService(
        Restangular,
    ) {
        return {
            exportRevenuesByProfile,
        };

        function exportRevenuesByProfile(rangeStart, rangeEnd) {
            return Restangular.one('sharePark/revenues/csv-export').get({ rangeStart, rangeEnd});
        }
    }
})();
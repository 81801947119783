(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ScreenDataService', ScreenDataService);

    function ScreenDataService(
        Restangular,
        $http,
    ) {

        return {
            getControllerTypes,
            getScreenTypes,
            getStationsByName,
            getStationsByLocation,
            setScreen,
            getStationById,
            getTotalScreens,
            reload,
        };

        /**
         * Gets controller types
         */
        function getControllerTypes() {
            return Restangular.all('screen/controller-types').getList();
        }

        /**
         * Gets controller types
         */
        function getScreenTypes() {
            return Restangular.all('screen/screen-types').getList();
        }

        /**
         * Searches stations by name
         * @param name
         */
        function getStationsByName(name) {
            return Restangular.all('stations/by-name').getList({name});
        }

        /**
         * Searches stations by name
         * @param stationId
         */
        function getStationById(stationId) {
            return Restangular.one('stations/by-id').get({stationId});
        }

        /**
         * Searches stations by location
         * @param coords
         */
        function getStationsByLocation(coords) {
            return Restangular.all('stations/by-location').getList(coords);
        }

        /**
         * Creates and updates screen
         * @param data
         */
        function setScreen(data) {
            return Restangular.all('screens')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * @return {Promise<{total: number}>}
         */
        function getTotalScreens() {
            return $http.get('/api/screens/total').then(response => response.data);
        }

        /**
         * @param {number} controllerRef
         * @return {Promise<{result: string}>}
         */
        function reload(controllerRef) {
            return $http.get(`/api/screens/reload/${controllerRef}`)
                .then(response => response.data);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenDetailsTab', {
            controller: ScreenDetailsTabController,
            templateUrl: '/assets/views/location/new-screen/tabs/screen-details/screen-details.tpl.html',
            bindings: {
                controllerData: '<',
                distribute: '<',
                onFindStationCallback: '<',
                fillLocationData: '<',
                onStationIdChange: '<',
                stationDepartures: '<',
                unity3dModelId: '='
            }
        });

    /**
     * @property {ScreenController} controllerData
     *
     * @param $stateParams
     * @param $scope
     * @param UserUtilitiesService
     * @param GoogleMapsUtilitiesService
     * @param StatesFactory
     * @param ScreenHelper
     * @param ScreenDataService
     * @param StorageFactory
     * @param ACTIVATION_STATUS
     * @param Unity3dModelsDataService
     * @param PermissionsService
     * @param {MultiStationsHelper} MultiStationsHelper
     * @constructor
     */
    function ScreenDetailsTabController(
        $stateParams,
        $scope,
        UserUtilitiesService,
        GoogleMapsUtilitiesService,
        StatesFactory,
        ScreenHelper,
        ScreenDataService,
        StorageFactory,
        ACTIVATION_STATUS,
        Unity3dModelsDataService,
        PermissionsService,
        MultiStationsHelper,
    ) {
        const vm = this;
        const STORAGE = StorageFactory.Storage('Main');

        vm.state = StatesFactory.ScreenStates;

        const {
            userLocationOptions,
            isShopTenant,
        } = UserUtilitiesService;

        const { clearLocationData, showMap } = GoogleMapsUtilitiesService;
        const { getScreenCoordinates } = ScreenHelper;

        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };
        vm.coordinatesPattern = '^(\\-?\\d+(\\.\\d+)?),\\s*(\\-?\\d+(\\.\\d+)?)$';
        vm.statuses = ACTIVATION_STATUS;

        vm.unity3dModels = [];

        vm.$onInit = init;

        vm.showFieldError = showFieldError;
        vm.onControllerImage = onControllerImage;
        vm.onControllerImageDelete = onControllerImageDelete;
        vm.onScreenImage = onScreenImage;
        vm.onScreenImageDelete = onScreenImageDelete;
        vm.onMap = onMap;
        vm.onCoordinatesChange = onCoordinatesChange;
        vm.canDeleteSubStation = canDeleteSubStation;
        vm.onDeleteSubStation = onDeleteSubStation;

        /**
         * Initialization method
         */
        function init() {
            vm.tenantId = STORAGE.currentUserInfo.subdomain.tenant_id;
            vm.state.type = $stateParams.type;
            vm.isEditMode = vm.state.type === "edit";

            getControllerTypes();
            getScreenTypes();
            getUserData();
            getUnity3dModels();

            const canDeleteScreen = PermissionsService.isPermissionAvailable('can_delete_location_screen');
            if (vm.controllerData.status !== ACTIVATION_STATUS.DELETED.value && !canDeleteScreen) {
                delete vm.statuses.DELETED;
            }
        }

        /**
         * Gets controller types
         */
        function getControllerTypes() {
            ScreenDataService.getControllerTypes().then(response => {
                vm.controllerTypes = response.plain();

                const controllerType = vm.controllerData.controllerType;
                if (!controllerType || !controllerType.id) {
                    vm.controllerData.controllerType = vm.controllerTypes[0];
                }
            });
        }

        /**
         * Gets screen types
         */
        function getScreenTypes() {
            ScreenDataService.getScreenTypes().then(response => {
                vm.screenTypes = response.plain();

                if (!vm.controllerData.screen.screenType) {
                    vm.controllerData.screen.screenType = vm.screenTypes[0];
                }
            });
        }

        function getUserData() {
            vm.mapDefaults = userLocationOptions();
            vm.isShopTenant = isShopTenant();
        }

        function getUnity3dModels() {
            Unity3dModelsDataService.getAll()
                .then(response => {
                    vm.unity3dModels = response.plain();
                });
        }

        /**
         * Checks if field error should be shown
         * @param name
         * @return {*|boolean}
         */
        function showFieldError(name) {
            const field = vm.newScreenForm && vm.newScreenForm[name];
            return field && field.$dirty && field.$invalid;
        }

        /**
         * Map icon click handler
         */
        function onMap() {
            const coords = getScreenCoordinates(vm.controllerData, vm.newScreenForm);

            const mapPopup = showMap({
                mapInfo: !!coords ? [coords] : null,
                mapDefaults: vm.mapDefaults,
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(mapInfo => {
                const coords = mapInfo.coordinates;
                const round = value => {
                    const NUMBERS_AFTER_COMA = 6;
                    const precision = Math.pow(10, NUMBERS_AFTER_COMA);
                    return Math.round(value * precision) / precision;
                };
                vm.controllerData.latlng = `${round(coords.latitude)},${round(coords.longitude)}`;
                vm.controllerData.screen.latlng = vm.controllerData.latlng;

                vm.fillLocationData(coords);
            });
        }

        /**
         * On coordinates string change handler
         */
        function onCoordinatesChange() {
            const coords = getScreenCoordinates(vm.controllerData, vm.newScreenForm);
            if (!coords) {
                clearLocationData(vm.controllerData);
                return;
            }
            vm.fillLocationData(coords);
        }

        function onControllerImage(imageFile, uploadToS3) {
            const urlCreator = window.URL || window.webkitURL;
            vm.controllerData.controllerImage = uploadToS3 ? imageFile : null;
            vm.controllerData.imageRef = uploadToS3
                ? urlCreator.createObjectURL(imageFile)
                : imageFile.name;
        }

        function onControllerImageDelete() {
            if (vm.isEditMode && !vm.oldControllerImage && $stateParams.data.imageRef) {
                vm.oldControllerImage = $stateParams.data.imageRef;
            }

            delete vm.controllerData.controllerImage;
            vm.controllerData.imageRef = '';
        }

        function onScreenImage(imageFile, uploadToS3) {
            const urlCreator = window.URL || window.webkitURL;
            vm.controllerData.screenImage = uploadToS3 ? imageFile : null;
            vm.controllerData.screen.imageRef = uploadToS3
                ? urlCreator.createObjectURL(imageFile)
                : imageFile.name;
        }

        function onScreenImageDelete() {
            if (vm.isEditMode && !vm.oldScreenImage && $stateParams.data.screen.imageRef) {
                vm.oldScreenImage = $stateParams.data.screen.imageRef;
            }

            delete vm.controllerData.screenImage;
            vm.controllerData.screen.imageRef = '';
        }

        /**
         * Specific logic for the substations deleting
         *
         * @param {number} index
         * @return {boolean}
         */
        function canDeleteSubStation(index) {
            return MultiStationsHelper.canDeleteSubStation(vm.controllerData, index);
        }

        function onDeleteSubStation(index) {
            MultiStationsHelper.deleteSubStation(vm.controllerData, index);
        }

        $scope.$watch(
            () => vm.newScreenForm && vm.newScreenForm.$valid,
            () => {
                vm.state.canNext = vm.newScreenForm && vm.newScreenForm.$valid;
            });
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('statisticsStatisticsTab', {
            templateUrl: '/assets/views/statistic/tabs/statistics-tab/statistics-tab.tpl.html',
            controller: StatisticStatisticsTabController,
            bindings: {
                content: '<',
                selectedContents: '<',
                contentGroupsArray: '<'
            }
        });

    function StatisticStatisticsTabController(StorageFactory, $scope, $interval, $timeout,
                                              ShareDataService, UtilitiesService, CONTENT_TYPES, DateHelper,
                                              statisticsTabState, QUIZ_QUESTION_TYPES, PLAYLIST_TRANSITION_TYPES) {
        const vm = this;

        vm.state = statisticsTabState;
        vm.updateDataIntervalID = null;
        vm.currentDate = new Date();

        const { getDefaultDateFormat } = DateHelper;

        vm.dateFormat = getDefaultDateFormat();

        vm.CHART_TYPES = {
            BAR: 'bar',
            PIE: 'pie',
        }
        vm.QUIZ_QUESTION_TYPES = QUIZ_QUESTION_TYPES;
        const MainStorage = StorageFactory.Storage('Main')
        const PRIMARY_COLOR_INDEX = 0;
        const FONT_COLOR_INDEX = 3;
        const DEFAULT_CAROUSEL_INTERVAL = 3
        vm.tenantBackgroundColor = MainStorage.corporateColours[PRIMARY_COLOR_INDEX];
        vm.tenantFontColor = MainStorage.corporateColours[FONT_COLOR_INDEX];

        // public properties
        vm.contentStatistics = {};
        vm.contentStatisticsKeys = [];

        vm.PLAYLIST_TRANSITION_TYPES = PLAYLIST_TRANSITION_TYPES;
        vm.sliderInterval = null;
        vm.sliderTransition = null;
        vm.isAnimated = null;

        vm.expiresNever = true;
        vm.expiresOn = null;
        vm.previewURL = null;
        vm.justCopied = false;
        vm.btnText = 'COPY_LINK';
        vm.saveVisible = false;

        vm.COLORS = ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'];

        vm.checkContentType = UtilitiesService.checkContentType;

        vm.changeViewModeCallback = changeViewModeCallback;
        vm.changeOverallChartTypeCallback = changeOverallChartTypeCallback;
        vm.stackedCheckboxCallback = stackedCheckboxCallback;
        vm.aggregatedCheckboxCallback = aggregatedCheckboxCallback;
        vm.periodSelectCallback = periodSelectCallback;

        vm.copyShareLink = copyShareLink;
        vm.filterStatisticData = displayStatisticData;
        vm.sharePreview = sharePreview;

        vm.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                line: {
                    tension: 0 // disables bezier curves
                }
            },
            legend: {
                display: true,
                labels: {
                    fontColor: 'rgb(255, 99, 132)',
                    fontSize: 20
                }
            },
            tooltips: {
                mode: 'point'
            },
            scales: {
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'value'
                    },
                    ticks: {
                        suggestedMin: 0,
                        beginAtZero: true,
                        stepSize: 1,
                    },
                    stacked: false
                }]
            }
        };

        // region share link

        function generateHashOfSelectedContent() {
            var hash = [];
            vm.selectedContents.forEach(function(content) {
                hash.push(content.id);
                hash.push(content.subdomain_id);
                hash.push(content.created_at);
            });
            hash = hash.join(',');
            return hash;
        }

        function sharePreview() {
            const jdata = JSON.stringify(vm.contentStatistics);
            const data = new FormData();

            vm.expiresOn && vm.expiresOn.setHours(0,0,0,0);
            data.append('json', jdata);
            data.append('expires_on', moment(vm.expiresOn).format('YYYY-MM-DD HH:mm:ss'));
            data.append('expires_never', vm.expiresNever);

            data.append('hash', generateHashOfSelectedContent());
            data.append('additional_data', JSON.stringify({
                items: vm.selectedStatisticsItems,
                colors: {
                    backgroundColor: vm.tenantBackgroundColor,
                    fontColor: vm.tenantFontColor,
                },
                transition: vm.sliderTransition,
                interval: vm.sliderInterval,
                isAnimated: vm.isAnimated
            }));

            ShareDataService.preview(data).then(function(response) {
                vm.previewURL = response.preview_url;
                vm.saveVisible = false;
            });
        }

        function copyShareLink() {
            var $temp = document.createElement('input');
            document.body.appendChild($temp);
            $temp.value = vm.previewURL;
            $temp.select();
            document.execCommand('copy');
            $temp.remove();
            vm.justCopied = true;
            vm.btnText = 'LINK_COPIED';
        }

        // endregion

        /**
         * Change view mode callback
         * @param {object} content Content object
         * @return {undefined}
         */
        function changeViewModeCallback(content) {
            if (content.viewMode === 'overall') {
                content.stacked = false;
                content.aggregated = false;
                content.period = 'all';
                content.chartMode = 'bar';
            } else if (content.viewMode === 'bydays') {
                content.stacked = true;
                content.aggregated = true;
                content.period = 'all';
            } else if (content.viewMode === 'byhours') {
                content.stacked = true;
                content.aggregated = true;
                content.period = '24hours';
            }

            displayStatisticData(content);
        }

        /**
         * Stacked checkbox callback
         * @param {object} content Content object
         * @return {undefined}
         */
        function stackedCheckboxCallback(content) {
            displayStatisticData(content);
        }

        /**
         * Aggregated checkbox callback
         * @param {object} content Content object
         * @return {undefined}
         */
        function aggregatedCheckboxCallback(content) {
            displayStatisticData(content);
        }

        /**
         * Period select callback
         * @param {object} content Content object
         * @returns {undefined}
         */
        function periodSelectCallback(content) {
            displayStatisticData(content)
        }

        /**
         * Change overall chart type callback
         * @param {object} content Content object
         * @return {undefined}
         */
        function changeOverallChartTypeCallback(content) {
            content.stacked = false;

            delete content.chartOptions.scales;

            displayStatisticData(content);
        }

        /**
         * Display statistic data
         * @param {object} content Content object
         * @return {undefined}
         */
        function displayStatisticData(content) {
            if (content.chartOptions.scales) {
                delete content.chartOptions.scales;
            }

            if (content.viewMode === 'overall') {
                if (content.chartMode === 'bar') {
                    content.chartOptions = angular.copy(vm.chartOptions);
                }
                prepareOverall(content);
            } else if (content.viewMode === 'bydays') {
                content.chartMode = 'line';
                prepareData(content);
            } else if (content.viewMode === 'byhours') {
                content.chartMode = 'line';
                prepareData(content);
            }

            changeChartSizes(content);

        }

        /**
         * Count Y Axes params
         * @param {number} n Max votes value
         * @return {{step: number, max: number}}
         */
        function countYAxesParams(n) {
            let step = 10;

            while (true) {
                if ((n / step) < 10) {
                    break;
                } else {
                    step = step * 10;
                }
            }

            const max = Math.ceil(n / step) * step;

            if (n <= 10) {
                step = 1;
            }

            return {
                step,
                max
            };
        }

        /**
         * Prepare overall statistic data
         * @param {object} content Content object
         * @return {undefined}
         */
        function prepareOverall(content) {

            setOverrideColors(content);

            content.data = getOverall(content.statisticsData, content.chartMode, content.isAvgRatingItem);

            if (content.chartMode === 'bar') {
                if (!content.isAvgRatingItem) {
                    const params = countYAxesParams(Math.max(...(content.data.map(data => data[0]))));
                    content.chartOptions.scales.yAxes[0].ticks.max = params.max;
                    content.chartOptions.scales.yAxes[0].ticks.stepSize = params.step;
                }
                content.labels = ['overall'];
            } else if (content.chartMode === 'pie') {
                content.labels = content.series.map((label, i) => {
                    const value = Array.isArray(content.data[i]) ? content.data[i].pop() : content.data[i];
                    return label + ' (' +  value + ')';
                });
            }
        }

        /**
         * Get overall statistic data depends on chart type
         * @param {array} statisticsData Statistic data
         * @param {string} chartMode Chart mode
         * @param {boolean} isAvgRatingItem Is Avg Rating Item
         * @return {array} Overall statistic data depends on chart type
         */
        function getOverall(statisticsData, chartMode, isAvgRatingItem) {
            const overall = isAvgRatingItem
                ? statisticsData.map(series => {
                    return (series.reduce((sum, el) => {
                        return sum += el.feedback;
                    }, 0) / series.length).toFixed(2);
                })
                : statisticsData.map(series => {
                    return series.filter(el => el.feedback).length;
                });

            return (chartMode === vm.CHART_TYPES.BAR)
                ? overall.map(el => [el])
                : overall;
        }

        /**
         * Prepare statistic data
         * @param {object} content Content object
         * @return {undefined}
         */
        function prepareData(content) {

            const [minDate, maxDate] = getMinMaxDate(content);

            const unit = isNaN(parseInt(content.period, 10)) ? 'day' : 'hour';

            const sortedByTime = sortByTimeUnits(content.statisticsData, unit);

            setOverrideColors(content);

            if (content.aggregated) {
                content.data = content.isAvgRatingItem ? getAggregatedAvgData(sortedByTime) : getAggregatedData(sortedByTime);
            } else {
                content.data = content.isAvgRatingItem ? getAvgData(sortedByTime) : getData(sortedByTime);
            }

            const max = countMax(content.data, content.aggregated, content.stacked);

            const yAxesParams = countYAxesParams(max);

            content.chartOptions.scales = {
                xAxes: [{
                    type: 'time',
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Date'
                    },
                    time: {
                        unit: unit,
                        min: minDate,
                        max: maxDate
                    }
                }],
                yAxes: [{
                    stacked: content.stacked,
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'value'
                    },
                    ticks: {
                        suggestedMin: 0,
                        beginAtZero: true,
                        stepSize: yAxesParams.step,
                        max: yAxesParams.max
                    }
                }]
            };
        }

        /**
         * Count max value
         * @param {array} data Statistics data
         * @param {boolean} aggregated Is aggregated
         * @param {boolean} stacked Is Stacked
         * @return {number} Max value
         */
        function countMax(data, aggregated, stacked){
            const values = data.map(series => series.map(item => item.y));
            let result = [];
            if (stacked && !aggregated) {
                for (let i = 0; i < values[0].length; i++) {
                    let sum = 0;
                    for (let j = 0; j < values.length; j++) {
                        sum += values[j][i];
                    }
                    result.push(sum);
                }

                return Math.max(...result);
            } else {
                result = values.map(series => Math.max(...series));
                return stacked
                    ? result.reduce((sum, value) => (sum + value), 0)
                    : Math.max(...result);
            }
        }

        /**
         * Get aggregated avg data
         * @param {array} statisticsData Statistics data
         */
        function getAggregatedAvgData(statisticsData) {
            return statisticsData.map(series => {
                const days = Object.keys(series);

                return days.map((day, i, days) => {
                    let feedbacksValue = 0;
                    let feedbackCount = 0;
                    let k = i;

                    for (k; k >= 0; k--) {
                        feedbackCount += series[days[k]].feedback.length;
                        feedbacksValue += series[days[k]].feedback.reduce((sum, value) => {
                            return sum += value;
                        }, 0);
                    }

                    return {
                        x: moment.min(series[day].timestamps.map(timestamp => moment(timestamp))).format(),
                        y: (feedbacksValue / feedbackCount).toFixed(2)
                    }
                })
            });
        }

        /**
         * Get avg data
         * @param {array} statisticsData Statistics data
         */
        function getAvgData(statisticsData) {
            return statisticsData.map(series => {
                const days = Object.keys(series);

                return days.map(day => {
                    return {
                        x: moment.min(series[day].timestamps.map(timestamp => moment(timestamp))).format(),
                        y: (series[day].feedback.reduce((sum, value) => {
                            return sum += value;
                        }, 0) / series[day].feedback.length).toFixed(2)
                    }
                });
            });
        }

        /**
         * Change chart sizes
         * @param {object} content Content object
         * @return {undefined}
         */
        function changeChartSizes(content) {
            if (content.chartMode === 'pie') {
                content.width = '400px';
                content.height = '400px';
            } else {
                content.width = '100%';
                content.height = '100%';
            }
        }

        /**
         * Set override colors
         * @param {object} content Content object
         * @return {undefined}
         */
        function setOverrideColors(content) {
            content.datasetOverride = [];

            content.series.forEach(function(serie, i) {
                const props = {};
                if(content.chartMode === vm.CHART_TYPES.BAR){
                    props.backgroundColor = vm.COLORS[i];
                    props.borderColor = vm.COLORS[i];
                }
                content.datasetOverride.push(props);
            });
        }

        /**
         * Sort statistic by time units (days or hours)
         * @param {array} statisticsData Statistic data
         * @param {string} timeUnits Time units
         * @return {undefined}
         */
        function sortByTimeUnits(statisticsData, timeUnits) {
            const keyFormat = (timeUnits === 'day') ? 'YYYY-MM-DD' : 'YYYY-MM-DD-H';

            return statisticsData.map(series => {
                return series.reduce((groupedByDate, feedback) => {
                    const key = moment(feedback.timestamp).format(keyFormat);

                    if(!groupedByDate[key]) {
                        groupedByDate[key] = {
                            timestamps: [],
                            feedback: [],
                        };
                    }

                    groupedByDate[key].timestamps.push(feedback.timestamp);
                    groupedByDate[key].feedback.push(feedback.feedback);

                    return groupedByDate;
                }, {});
            });
        }

        /**
         * Get statistic data
         * @param {array} statisticsData Statistic data
         * @return {array} Statistic data
         */
        function getData(statisticsData) {
            return statisticsData.map(series => {
                const days = Object.keys(series);

                return days.map(day => {
                    return {
                        x: moment.min(series[day].timestamps.map(timestamp => moment(timestamp))).format(),
                        y: series[day].feedback.filter(value => value).length
                    }
                });
            });
        }

        /**
         * Get aggregated statistic data
         * @param {array} statisticsData Statistic data
         * @return {array} Statistic data
         */
        function getAggregatedData(statisticsData) {
            return statisticsData.map(series => {
                const days = Object.keys(series);

                return days.map((day, i, days) => {
                    let feedbacksValue = 0;
                    let k = i;

                    for(k; k>=0; k--){
                        feedbacksValue += series[days[k]].feedback.filter(value => value).length;
                    }

                    return {
                        x: moment.min(series[day].timestamps.map(timestamp => moment(timestamp))).format(),
                        y: feedbacksValue
                    }
                })
            });
        }

        /**
         * Get Min and Max Date (chart range)
         * @param {object} content Content object
         * @return {array} Min and max date array
         */
        function getMinMaxDate(content) {
            //Get first series statistics, all series has same timestamps
            const statisticsData = content.statisticsData[0];
            const dates = statisticsData.map(item => {
                return new Date(item.timestamp);
            });

            const minDate = new Date(Math.min.apply(null, dates));

            const to = new Date();
            let from = new Date();

            if (content.period === 'all') {
                from = minDate;
            } else {
                if (content.period === 'lastweek') {
                    from.setDate(from.getDate() - 7);
                } else if (content.period === 'last2weeks') {
                    from.setDate(from.getDate() - 14);
                } else if (content.period === 'lastmonth') {
                    from.setMonth(from.getMonth() - 1);
                } else if (content.period === 'last2months') {
                    from.setMonth(from.getMonth() - 2);
                } else {
                    const nHours = parseInt(content.period, 10);
                    from = from.getTime() - nHours * 60 * 60 * 1000;
                    from = new Date(from);
                }
            }

            return [from, to];
        }

        /**
         * Is statistic data
         * @param {array} statistic data
         * @return {boolean} if item has statistic
         */
        function isStatisticData(statistic) {
            return !!statistic.length;
        }

        /**
         * Filter statistic data by question id
         * @param {array} statisticsData Statistic data
         * @param {number} questionId Question id
         * @return {array} Statistic data filtered by question id
         */
        function filterByQuestionId(statisticsData, questionId) {
            return statisticsData.filter(data =>
                Object.values(questionId).some(id =>
                    Number(id) === Number(data.feedbackElementRef)
                )
            );
        }

        /**
         * Get rating series
         * @param {number} starsCount Rating units
         * @return {Array} Rating series array
         */
        function getRatingSeries(starsCount) {
            const series = [];

            for(let i = 1; i<=starsCount; i++){
                series.push(i + ' star(s)');
            }

            return series;
        }

        /**
         * Get quiz series
         * @param {array} votingOptions Options array
         * @param {number} langId Language ID
         * @return {array} Quiz series array
         */
        function getQuizSeries(votingOptions, langId) {
            return votingOptions.map(option => option.answer[langId]);
        }

        /**
         * Create default statistic item
         * @param {object} statisticItem Statistic item
         * @return {object} Statistic itme default object
         */
        function createStatisticItem(statisticItem) {
            const itemDefaultProps = getItemDefaultProps();
            const item = angular.copy(statisticItem);

            Object.keys(itemDefaultProps).forEach(key => {
                item[key] = itemDefaultProps[key];
            });

            return item;
        }

        /**
         * Create Quiz Statistic Item
         * @param {object} statisticItem Statistic item object
         * @param {object} question Question object
         * @return {object} Quiz statistic item
         */
        function createQuizStatisticItem(statisticItem, question) {
            const item = createStatisticItem(statisticItem);
            const langId = statisticItem.content.language_id;

            item.title = question.title[langId];
            item.statisticsData = getStatisticDataForQuiz(statisticItem.statisticsData, question.id);
            item.isStatisticData = isStatisticData(item.statisticsData);
            item.series = getQuizSeries(question.votingOptions, langId);

            item.previewData = {
                type: Number(question.type),
                title: question.title[langId],
                votingOptions: prepareVotingOptions(question.votingOptions, langId),
                isLongAnswer: question.isLongAnswer,
                isSingleAnswer: question.isSingleAnswer,
                message: statisticItem.content.message.message[langId],
            }

            return item;
        }

        /**
         * Create Rating Statistic Item
         * @param {object} statisticItem Statistic item object
         * @param {object} question Question object
         * @param {object} rating Rating object
         * @param {number} i Option index
         * @return {object} Rating statistic item
         */
        function createRatingStatisticItem(statisticItem, question, rating, i) {
            const item = createStatisticItem(statisticItem);
            const langId = statisticItem.content.language_id;

            item.title = rating.answer[langId];
            item.statisticsData = getStatisticDataForRating(statisticItem.statisticsData, question.id, i, question.ratingUnits);
            item.isStatisticData = isStatisticData(item.statisticsData);
            item.series = getRatingSeries(question.ratingUnits);
            item.ratingUnits = question.ratingUnits;
            item.avg = countAvgRating(statisticItem.statisticsData, question.id, i);
            const votingOptions = question.votingOptions.filter((option, idx) => (i === idx));

            item.previewData = {
                type: Number(question.type),
                title: question.title[langId],
                ratingUnits: question.ratingUnits,
                votingOptions: prepareVotingOptions(votingOptions, langId),
                message: statisticItem.content.message.message[langId],
            }

            return item;
        }

        /**
         * Create Avg Rating Statistic Item
         * @param statisticItem
         * @param question
         * @return {Object}
         */
        function createAvgRatingStatisticItem(statisticItem, question) {
            const item = createStatisticItem(statisticItem);
            const langId = statisticItem.content.language_id;
            item.title = question.title[langId];
            item.statisticsData = getStatisticDataForAvgRating(statisticItem.statisticsData, question.id);
            item.isStatisticData = isStatisticData(item.statisticsData);
            item.series = getQuizSeries(question.votingOptions, langId);
            item.isAvgRatingItem = true;

            item.previewData = {
                type: Number(question.type),
                title: question.title[langId],
                ratingUnits: question.ratingUnits,
                votingOptions: prepareVotingOptions(question.votingOptions, langId),
                message: statisticItem.content.message.message[langId],
            }

            return item;
        }

        /**
         * Prepare voting options
         * @param {array} votingOptions - Voting options
         * @param {number} langId - Language ID
         */
        function prepareVotingOptions(votingOptions, langId) {
            return votingOptions.map(option => Object.assign({}, option, {answer: option.answer[langId]}));
        }

        /**
         * Count average rating (Only for rating items)
         * @param {array} statisticsData Statistics data
         * @param {number} questionId Question id
         * @param {number} i Option index
         * @return {string} Average rating
         */
        function countAvgRating(statisticsData, questionId, i) {
            const filteredData = filterByQuestionId(statisticsData, questionId).map(data => parseInt(data.multiFeedback.split(',')[i]));
            return filteredData.length && (filteredData.reduce((sum, value) => {
                return sum + value;
            }, 0) / filteredData.length).toFixed(2);
        }

        /**
         * Get statistic data for rating
         * @param {array} statisticsData Statistics data
         * @param {number} questionId Question id
         * @param {number} i Option index
         * @param {number} ratingUnits Rating units
         * @return {array} Statistic data for rating
         */
        function getStatisticDataForRating(statisticsData, questionId, i, ratingUnits) {
            const filteredData = filterByQuestionId(statisticsData, questionId).map(data => {
                const feedbackValue = parseInt(data.multiFeedback.split(',')[i]);
                const feedback = [];
                const emptyValue = 0
                for(let i=1; i<=ratingUnits; i++){
                    feedback.push((i !== feedbackValue) ? emptyValue : feedbackValue);
                }
                return {
                    feedback,
                    timestamp: data.eventTimestamp,
                }
            });

            return sortBySeries(filteredData);
        }

        /**
         * Get statistic data for avg rating
         * @param statisticsData
         * @param questionId
         * @return {Array}
         */
        function getStatisticDataForAvgRating(statisticsData, questionId) {
            const filteredData = filterByQuestionId(statisticsData, questionId).map(data => {
                const feedback = data.multiFeedback.split(',').map(feedback => parseInt(feedback, 10));
                return {
                    feedback,
                    timestamp: data.eventTimestamp,
                }
            });
            return sortBySeries(filteredData);
        }

        /**
         * Get statistic data for quiz
         * @param {array} statisticsData Statistics data
         * @param {number} questionId Question id
         * @return {array} Statistic data for rating
         */
        function getStatisticDataForQuiz(statisticsData, questionId) {
            const filteredData = filterByQuestionId(statisticsData, questionId).map(data => {
                return {
                    feedback: data.multiFeedback.split(',').map(feedback => JSON.parse(feedback)),
                    timestamp: data.eventTimestamp,
                }
            });

            return sortBySeries(filteredData);
        }

        /**
         * Sort statistic data by series
         * @param {array} statisticsData Statistics data
         * @return {Array} Statistic data sorted by series
         */
        function sortBySeries(statisticsData) {
            const preparedData = [];

            for(let i=0; i < statisticsData.length; i++) {
                const timestamp = statisticsData[i].timestamp;
                const feedback = statisticsData[i].feedback;

                for(let j=0; j < feedback.length; j++) {
                    if(!preparedData[j]){
                        preparedData[j] = [];
                    }

                    preparedData[j].push({
                        feedback: feedback[j],
                        timestamp
                    });
                }
            }

            return preparedData;
        }

        /**
         * Get item default props
         * @return {object} Item default props
         */
        function getItemDefaultProps(){
            return {
                viewMode: 'overall',
                period: 'all',
                chartMode: 'bar',
                chartOptions: angular.copy(vm.chartOptions),
                width: '100%',
                height: '100%',
                datasetOverride: []
            }
        }

        /**
         * Load  statistic data from server and create statistic objects
         * @return {undefined}
         */
        function loadStatisticData() {
            const contentsList = [];
            vm.selectedContents.forEach(function(selectedItem) {
                vm.content.forEach(function(contentItem) {
                    if (contentItem.id === selectedItem.id) {
                        contentsList.push(contentItem);
                    }
                });
            });
            const data = new FormData();
            data.append('content', JSON.stringify(contentsList));
            data.append('hash', generateHashOfSelectedContent());

            ShareDataService.getStatistics(data).then(response => {
                const statData = response.stat_data;
                let selectedStatistics = false;
                let additionalData = {};

                if (statData.additional_data) {
                    additionalData = angular.isString(statData.additional_data)
                        ? JSON.parse(statData.additional_data)
                        : statData.additional_data;
                    selectedStatistics = additionalData.items;
                }

                if (vm.sliderTransition === null) {
                    vm.sliderTransition = typeof additionalData.transition !== 'undefined' ? additionalData.transition : PLAYLIST_TRANSITION_TYPES[0].value;
                }

                if (vm.sliderInterval === null) {
                    vm.sliderInterval = typeof additionalData.interval !== 'undefined' ? additionalData.interval : DEFAULT_CAROUSEL_INTERVAL;
                }

                if (vm.isAnimated === null) {
                    vm.isAnimated = typeof additionalData.isAnimated !== 'undefined' ? additionalData.isAnimated : false;
                }


                if (statData.preview_url) {
                    vm.previewURL = statData.preview_url;
                }

                if (!vm.selectedStatisticsItems) {
                    vm.selectedStatisticsItems = selectedStatistics ? selectedStatistics : [];
                }

                for (let index in response.list) {
                    if (!response.list.hasOwnProperty(index)) {
                        continue;
                    }
                    const statisticItem = response.list[index];
                    const language_id = statisticItem.content.language_id;

                    const contentTypeId = Number(statisticItem.content.content_type_id);

                    if (contentTypeId === CONTENT_TYPES.QUIZ || contentTypeId === CONTENT_TYPES.FEEDBACK) {
                        statisticItem.content.message.quiz.questions.forEach(question => {
                            const questionType = Number(question.type);
                            const key = Object.values(question.id).join('=');
                            if(questionType === QUIZ_QUESTION_TYPES.RATING){

                                if (!vm.contentStatistics[key]) {
                                    vm.contentStatistics[key] = createAvgRatingStatisticItem(statisticItem, question);
                                    vm.contentStatisticsKeys.push(key);
                                    selectedStatistics || vm.selectedStatisticsItems.push(key);
                                    prepareOverall(vm.contentStatistics[key]);
                                } else {
                                    statisticsItemUpdated(statisticItem.statisticsData, key, question);
                                }

                                question.votingOptions.forEach((rating, i) => {
                                    const ratingItemKey = [Object.values(question.id).join('='), i].join('=');

                                    if (!vm.contentStatistics[ratingItemKey]) {
                                        vm.contentStatistics[ratingItemKey] = createRatingStatisticItem(statisticItem, question, rating, i);
                                        vm.contentStatisticsKeys.push(ratingItemKey);
                                        selectedStatistics || vm.selectedStatisticsItems.push(ratingItemKey);
                                        prepareOverall(vm.contentStatistics[ratingItemKey]);
                                    } else {
                                        statisticsItemUpdated(statisticItem.statisticsData, ratingItemKey, question, i);
                                    }
                                })
                            } else {
                                if (!vm.contentStatistics[key]) {
                                    vm.contentStatistics[key] = createQuizStatisticItem(statisticItem, question);
                                    vm.contentStatisticsKeys.push(key)
                                    selectedStatistics || vm.selectedStatisticsItems.push(key);
                                    prepareOverall(vm.contentStatistics[key]);
                                } else {
                                    statisticsItemUpdated(statisticItem.statisticsData, key, question);
                                }
                            }
                        });
                    }
                }
            });
        }

        /**
         * Update statistic item
         * @param {object} statisticsData Statistic data object
         * @param {number} index Statistic index
         * @param {object} question Question object
         * @param {number|undefined} i question index if rating or undefined if quiz
         * @return {undefined}
         */
        function statisticsItemUpdated(statisticsData, index, question, i) {
            const item = vm.contentStatistics[index];
            if (typeof i !== 'undefined') {
                item.statisticsData = getStatisticDataForRating(statisticsData, question.id, i, question.ratingUnits);
                item.avg = countAvgRating(statisticsData, question.id, i);
            } else if (item.isAvgRatingItem){
                item.statisticsData = getStatisticDataForAvgRating(statisticsData, question.id);
            } else {
                item.statisticsData = getStatisticDataForQuiz(statisticsData, question.id);
            }

            displayStatisticData(item)
        }

        loadStatisticData();

        $scope.$on('$destroy', function() {
            if (vm.updateDataIntervalID) {
                $interval.cancel(vm.updateDataIntervalID);
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.justCopied;
        }), function(newValue, oldValue) {
            if (Number(newValue) === 1) {
                $timeout(function() {
                    vm.justCopied = false;
                    vm.btnText = 'COPY_LINK';
                }, 2000);
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.expiresNever;
        }), function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.saveVisible = true;
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.expiresOn;
        }), function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.saveVisible = true;
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.isAnimated;
        }), function(newValue, oldValue) {
            if (newValue !== oldValue && oldValue !== null) {
                vm.saveVisible = true;
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.sliderInterval;
        }), function(newValue, oldValue) {
            if (newValue !== oldValue && oldValue !== null) {
                vm.saveVisible = true;
            }
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.sliderTransition;
        }), function(newValue, oldValue) {
            if (newValue !== oldValue && oldValue !== null) {
                vm.saveVisible = true;
            }
        });


        $scope.$watchCollection(angular.bind(vm, function() {
            return this.selectedStatisticsItems;
        }), function(newValue, oldValue) {
            if (typeof oldValue === 'undefined') {
                return;
            }
            if(newValue !== oldValue) {
                vm.saveVisible = true;
            }
        });

        vm.updateDataIntervalID = $interval(loadStatisticData, 30 * 1000);
    }
}());

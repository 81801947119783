(function () {
    'use strict';

    angular.module('beacon.app')
        .directive('onImageLoad', ['$parse', function ($parse) {
            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {
                    const fn = $parse(attrs.sbLoad);
                    elem.on('load', function (event) {
                        scope.$apply(function() {
                            fn(scope, { $event: event });
                        });
                    });
                }
            };
        }]);
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('TiersFormController', TiersFormController);

    function TiersFormController($scope, $state, $stateParams, StatesFactory, TierService, TierGroupService,
                                 LoyaltyDataService) {
        const vm = this;
        vm.state = StatesFactory.TierStates.refresh();
        vm.state.canNext = true;

        vm.loaderConfig = {
            minWidth: 200,
            minHeight: 200,
            center: true,
            aspectRatio: 1
        };

        const CONFIG_TYPE_BENEFIT = 8;

        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.getBenefitType = getBenefitType;

        _init();

        /**
         * Image field change handler
         *
         * @param imageFile
         * @param uploadToS3
         */
        function onImage(imageFile, uploadToS3) {
            const urlCreator = window.URL || window.webkitURL;
            vm.data.image = uploadToS3 ? imageFile : null;
            vm.data.image_ref = uploadToS3
                ? urlCreator.createObjectURL(imageFile)
                : imageFile.name;

        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            vm.data.image = null;
            vm.data.image_ref = '';
        }

        /**
         * Getting benefit type by value
         *
         * @param benefitTypeValue
         * @returns {*}
         */
        function getBenefitType(benefitTypeValue) {
            if (vm.benefitTypes) {
                return vm.benefitTypes.find(benefitType => {
                    if (parseInt(benefitType.parameters.value) === parseInt(benefitTypeValue)) {
                        return benefitTypeValue;
                    }
                });
            }
        }

        /**
         * Finish handler
         *
         * @private
         */
        function _onFinish() {
            const data = angular.copy(vm.data);
            const formData = new FormData();
            formData.append('json', JSON.stringify(data));
            formData.append('image', data.image);

            const serviceAction = $stateParams.type === 'edit' ? 'save' : 'create';
            TierService[serviceAction](formData)
                .then(function() {
                    $state.go('app.loyaltyTiers', {
                        paginationData: $stateParams.paginationData
                    });
                }).catch((err) => {
                    vm.state.finish = false;
                    throw err;
                });
        }

        /**
         * Controller initialization
         *
         * @private
         */
        function _init() {
            if ($stateParams.type === 'edit') {
                vm.data = angular.copy($stateParams.data);
                vm.data.description = JSON.parse(vm.data.description);
                vm.data.benefit_value = parseFloat(vm.data.benefit_value);
            } else {
                vm.data = {};
            }
            _loadDropdownLists();
        }

        /**
         * Loading dropdown lists from server
         * and saving them to storage for using in other tabs
         */
        function _loadDropdownLists() {
            LoyaltyDataService.getLoyaltyConfigs().then(response => {
                const paramsData = _decodeDropdownParams(response.plain());
                vm.benefitTypes = paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_BENEFIT);
            }).catch(console.error.bind(console));
        }

        /**
         * Decoding dropdown parameters
         * eg. params = "foo=bar" is becoming
         *     params = {foo: bar}
         *
         * @param {array} dropdownParams
         * @returns {*}
         */
        function _decodeDropdownParams(dropdownParams) {
            return dropdownParams.map(dropdownParam => {
                const paramsEncodedArray = dropdownParam.parameters.split(';');
                const paramsDecoded = {};
                paramsEncodedArray.map(paramEncoded => {
                    const paramArray = paramEncoded.split('==');
                    if (paramArray[0] && paramArray[1]) {
                        paramsDecoded[paramArray[0]] = paramArray[1];
                    }
                });
                dropdownParam.parameters = paramsDecoded;
                return dropdownParam;
            });
        }

        /**
         * Finish button click watcher
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.dataForm.$valid,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
}());

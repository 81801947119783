(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('timetableHafasTable', {
            templateUrl: '/screen/views/components/timetable-hafas/timetable-hafas-table/timetable-hafas-table.tpl.html',
            controller: TimetableHafasTableController,
            bindings: {
                journeys: '<',
                options: '<',
                api: '<',
                colorsScheme: '<',
                isConnectionStable: '<',
            }
        });

    /**
     * @typedef {'normal'|'narrow'|'slim'} TimetableHafasViewMode
     */

    /**
     * @typedef {object} TimetableApi
     * @property {function(initial?: boolean): Promise<void>} refreshView
     */

    /**
     * @typedef {Journey|JourneyProduct|JourneyPlaceholder} TimetableItem
     */

    /**
     * @typedef {{type: 'placeholder'}} JourneyPlaceholder
     */

    /**
     * @param $q
     * @param {$sce} $sce
     * @param {$rootScope.Scope} $scope
     * @param $timeout
     * @param $element
     * @param {TimetableHafasService} TimetableHafasService
     * @param {LogService} LogService
     * @param {TIMETABLE_HAFAS_DEFAULTS} TIMETABLE_HAFAS_DEFAULTS
     *
     * @property {Journey[]} journeys
     * @property {TimetableHafasOptions} options
     * @property {'default'|'night'} colorsScheme
     * @property {boolean} isConnectionStable
     *
     * @constructor
     */
    function TimetableHafasTableController(
        $q,
        $sce,
        $scope,
        $timeout,
        $element,
        TimetableHafasService,
        LogService,
        TIMETABLE_HAFAS_DEFAULTS,
    ) {
        const $ctrl = this;

        const green = (...args) => {
            LogService.colored('green', '#FFF', 'Timetable (HAFAS) Table |', ...args);
        };

        const log = (...args) => {
            LogService.colored('#DDD', '#FFF', 'Timetable (HAFAS) Table |', ...args);
        };

        const waitViewUpdate = TimetableHafasService.waitViewUpdate;
        const isElementAvailable = TimetableHafasService.isElementAvailable;

        $ctrl.loading = true;

        /** @type {TimetableHafasElement | null} */
        $ctrl.destinationElement = null;
        $ctrl.destinationScale = 1;
        $ctrl.destinationScaleAlrorithm = null;

        $ctrl.componentElement = $element[0];

        /** @type {TimetableHafasElement[]} */
        $ctrl.elements = [];

        // this placeholder is needed to calculate the available area
        $ctrl.productColumns = [
            {
                category: 'PLACEHOLDER',
            }
        ];

        /** @type {TimetableHafasViewMode} */
        $ctrl.viewMode = 'normal';

        /** @type {Array<TimetableItem>[]} - Same journeys, but split by chunks (columns) */
        $ctrl.journeyColumns = [[]]; // initial value to render the table header and calculate its height

        $ctrl.$onInit = () => {
            _initAutoRefresh();
            _setViewVars();
            _initApi();
            _setStyles($ctrl.colorsScheme);
        }

        $ctrl.$onChanges = ({colorsScheme}) => {
            if (colorsScheme) {
                _setStyles(colorsScheme.currentValue);
            }
        }

        /**
         * Just for debugging
         *
         * @param {Journey} journey
         */
        $ctrl.onJourneyClick = journey => {
            green(journey);
        }

        /**
         * @param {object} object
         * @return {boolean}
         */
        $ctrl.isJourney = (object) => {
            return object.constructor.name === 'Journey';
        }

        /**
         * @param {TimetableItem} object
         * @return {boolean}
         */
        $ctrl.isPlaceholder = (object) => {
            return object.type === 'placeholder';
        }

        /**
         * @param {TimetableItem} object
         * @return {boolean}
         */
        $ctrl.isProduct = (object) => {
            return Boolean(object.cls);
        }

        /**
         * @param {string} html
         */
        $ctrl.trustHtml = html => {
            return $sce.trustAsHtml(html);
        }

        /**
         * @param {TimetableHafasElement} element
         * @param {number} rowIndex
         * @return {object}
         */
        $ctrl.generateElementStyles = (element, rowIndex) => {
            const styles = angular.copy(
                rowIndex % 2
                    ? $ctrl.cellStyleEven
                    : $ctrl.cellStyle
            );

            _addStartEndPaddingStyles(styles, element);

            return styles;
        }

        /**
         * @param {number} rowIndex
         * @return {object}
         */
        $ctrl.generatePlaceholderStyles = (rowIndex) => {
            return angular.copy(
                rowIndex % 2
                    ? $ctrl.placeholderStyleEven
                    : $ctrl.placeholderStyle
            );
        }

        /**
         * @param {TimetableHafasElement} element
         * @return {object}
         */
        $ctrl.generateHeaderCellStyles = (element) => {
            const styles = angular.copy($ctrl.headerCellStyle);

            _addStartEndPaddingStyles(styles, element);

            return styles;
        }

        /**
         * @param {TimetableHafasElement} element
         * @return {string[]}
         */
        $ctrl.generateElementClassList = (element) => {
            return [
                'line-' + element.params.line,
                'col-' + element.params.col,
                'col-' + element.params.colIndex,
                'col-' + element.type
            ];
        }

        /**
         * @param {TimetableHafasElement} element
         * @return {string[]}
         */
        $ctrl.generateHeaderClassList = (element) => {
            return [
                'line-single',
                'col-' + element.params.col,
                'col-' + element.params.colIndex,
            ];
        }

        function _setViewVars() {
            $ctrl.paddingLeft = angular.isNumber($ctrl.options.view.paddingLeft)
                ? $ctrl.options.view.paddingLeft + 'px'
                : TIMETABLE_HAFAS_DEFAULTS.FIRST_COLUMN_PADDING_LEFT;
            $ctrl.paddingRight = angular.isNumber($ctrl.options.view.paddingRight)
                ? $ctrl.options.view.paddingRight + 'px'
                : TIMETABLE_HAFAS_DEFAULTS.LAST_COLUMN_PADDING_RIGHT;

            $ctrl.destinationElement = $ctrl.options.elements.find(item => item.type === 'direction');
            $ctrl.viaStops = $ctrl.destinationElement ? $ctrl.destinationElement.viaStops : 0;
            $ctrl.viaStopsSeparator = $ctrl.destinationElement && $ctrl.destinationElement.viaStopsSeparator;
            $ctrl.destinationScaleAlrorithm = $ctrl.destinationElement && $ctrl.destinationElement.scaleAlgorithm;

            $ctrl.groupMode = $ctrl.options.grouping
                && $ctrl.options.grouping.enabled
                && $ctrl.options.grouping.mode;
        }

        // --- PRIVATE --- //

        function _initApi() {
            $ctrl.api.refreshView = _refreshView;
        }

        /**
         * @return {Promise<void>}
         * @private
         */
        function _refreshView() {
            return _refreshResponsiveVariables()
                .then(() => {
                    _setJourneys();

                    if ($ctrl.journeys.length) {
                        $ctrl.loading = false;
                    }
                });
        }

        /**
         * @param {'default'|'night'} colorScheme
         * @private
         */
        function _setStyles(colorScheme) {
            const colors = $ctrl.options.colors[colorScheme];
            const thColors = $ctrl.options.tableHeader.colors[colorScheme];
            const prodColors = $ctrl.options.grouping.colors[colorScheme];
            const mVert = $ctrl.options.view.innerMarginVertical;
            const mHoriz = $ctrl.options.view.innerMarginHorizontal;
            const border = $ctrl.options.view.borderWidth;

            $ctrl.componentElement.style.overflow = $ctrl.options.view.scroll ? 'auto' : 'hidden';
            $ctrl.componentElement.style.padding = $ctrl.options.view.outerMargin + 'px';

            $ctrl.columnsWrapperStyle = {
                'gap': `${mVert}px`,
            };

            $ctrl.columnStyle = {
                // collapse borders between columns (+ CSS in component styles for 1st and last column)
                'margin': mVert ? 0 : `0 -${border / 2}px`,
            };

            $ctrl.headerProductsStyle = {
                'gap': mVert + 'px',
                'margin-bottom': mHoriz
                    ? `${mHoriz}px` // inner margin horizontal
                    : `-${border}px`, // collapse borders between journeys (+ CSS)
            }

            $ctrl.headerProductStyle = {
                // collapse borders between columns (+ CSS in component styles for 1st and last column)
                'margin': `0 -${border / 2}px`,
                'border': `${border}px ${colors.border} solid`,
                'background': prodColors.background,
                'color': prodColors.text,
                'font-weight': $ctrl.options.grouping.styles.bold ? 'bold' : 'normal',
                'padding-left': $ctrl.paddingLeft,
                'padding-right': $ctrl.paddingRight,
            }

            $ctrl.headerCellStyle = {
                'border': `${border}px ${colors.border} solid`,
                'background': thColors.background,
                'color': thColors.text,
                'margin-bottom': mHoriz ? 0 : `-${border}px`, // collapse borders between journeys (+ CSS)
            }

            $ctrl.cellStyle = {
                'background': colors.foreground,
                'border-width': `${border}px`,
                'border-color': `${colors.border}`,
                'border-style': `solid`,
                'margin-top': `-${mHoriz}px`, // #gapInsideJourney remove gap between rows INSIDE the journey row (+ CSS)
                'margin-bottom': mHoriz ? 0 : `-${border}px`, // collapse borders between journeys (+ CSS)
            }

            $ctrl.cellStyleEven = {
                ...$ctrl.cellStyle,
                'background': colors.foregroundEven,
            }

            $ctrl.placeholderStyle = angular.copy($ctrl.cellStyle);
            $ctrl.placeholderStyleEven = angular.copy($ctrl.cellStyleEven)

            $ctrl.textColor = colors.text;

            $ctrl.productCellStyle = {
                'border': `${border}px ${colors.border} solid`,
                'background': prodColors.background,
                'color': prodColors.text,
                'margin-bottom': mHoriz ? 0 : `-${border}px`, // collapse borders between journeys (+ CSS)
                'padding-left': $ctrl.paddingLeft,
                'padding-right': $ctrl.paddingRight,
            }
        }

        /**
         * @private
         */
        function _setJourneys() {
            log('Setting journeys. Grid', $ctrl.rows, 'x', $ctrl.cols, `(total ${$ctrl.journeys.length} journeys)`);

            let journeyColumns;

            switch ($ctrl.groupMode) {
                case 'column':
                    const { products, columns } = TimetableHafasService.makeJourneyGroupedColumns(
                        $ctrl.journeys,
                        $ctrl.rows,
                        $ctrl.cols,
                        $ctrl.options.view.scroll,
                    );

                    $ctrl.productColumns = products;

                    void _refreshResponsiveVariables();
                    journeyColumns = columns;

                    break;

                case 'inline':
                    journeyColumns = TimetableHafasService.makeJourneyInlineGroupedColumns(
                        $ctrl.journeys,
                        $ctrl.rows,
                        $ctrl.cols,
                        $ctrl.options.view.scroll,
                    );
                    break;

                default:
                    journeyColumns = TimetableHafasService.makeJourneyColumns(
                        $ctrl.journeys,
                        $ctrl.rows,
                        $ctrl.cols,
                        $ctrl.options,
                    );
            }

            $ctrl.journeyColumns = _addPlaceholders(journeyColumns, $ctrl.rows, $ctrl.cols);
        }

        /**
         * @param {Array<TimetableItem>[]} journeyColumns
         * @param {number} rows
         * @param {number} cols
         * @return {Array<TimetableItem>[]}
         * @private
         */
        function _addPlaceholders(journeyColumns, rows, cols) {
            for (let col = 0; col < cols; col++) {
                for (let row = 0; row < rows; row++) {
                    if (journeyColumns[col][row]) { continue; }

                    journeyColumns[col][row] = { type: 'placeholder' };
                }
            }

            return journeyColumns;
        }

        /**
         * Update view styles
         *
         * @return {Promise<void>}
         * @private
         */
        function _refreshResponsiveVariables() {
            return waitViewUpdate()
                .then(() => {
                    const { width, height } = _getAvailableArea();

                    const { rows, cols, rowHeight, rowWidth } = TimetableHafasService.calculateGridSizePerArea(
                        width,
                        height,
                        $ctrl.options,
                    );

                    $ctrl.rows = rows;
                    $ctrl.cols = cols;

                    log('Setting sizes. Available area', width, 'x', height, 'Grid',
                        $ctrl.rows, 'x', $ctrl.cols, 'Row height', rowHeight.toPrecision(2), 'Row width', rowWidth);

                    const aspectRatio = rowWidth / rowHeight;
                    const { viewMode, rowHeightDivider } = __getViewMode(aspectRatio, $ctrl.options);
                    const rowTotalHeightStr = rowHeight + 'px';
                    const rowHeightStr = rowHeight / rowHeightDivider + 'px';

                    log(
                        'Aspect ratio', aspectRatio.toPrecision(2),
                        'View mode', viewMode,
                        'Row height divider', rowHeightDivider
                    );

                    $ctrl.viewMode = viewMode;
                    $ctrl.cellStyle['height'] = $ctrl.cellStyleEven['height'] = rowHeightStr;
                    $ctrl.placeholderStyle['height'] = $ctrl.placeholderStyleEven['height'] = rowTotalHeightStr;
                    $ctrl.componentElement.style.fontSize = rowHeightStr; // set global font size
                    $ctrl.destinationScale = _getDestinationScale(rowHeight, rowWidth, viewMode);

                    $ctrl.elements = TimetableHafasService.generateElementsList($ctrl.options.elements, viewMode);

                    $ctrl.tableStyle = {
                        'grid-template-columns': TimetableHafasService.generateGridTemplate($ctrl.elements),
                        'grid-row-gap': `${$ctrl.options.view.innerMarginHorizontal}px`,
                    };
                })
                .then(() => waitViewUpdate());
        }

        /**
         * @param {number} aspectRatio
         * @param {TimetableHafasOptions} options
         * @return {{viewMode: TimetableHafasViewMode, rowHeightDivider: number}}
         * @private
         */
        function __getViewMode(aspectRatio, options) {
            const availableElements = options.elements.filter(el => isElementAvailable(el));

            const narrowEnabled = !options.responsive || options.responsive.enabled.narrow;
            const slimEnabled = !options.responsive || options.responsive.enabled.slim;

            const destinationIndex = availableElements.findIndex(item => item.type === 'direction');
            const noDestination = destinationIndex === -1;
            const destinationFirstOrLast = destinationIndex === 0 || (destinationIndex + 1) === availableElements.length;

            const normal = { viewMode: 'normal', rowHeightDivider: 1 };
            const narrow = { viewMode: 'narrow', rowHeightDivider: 2 };
            const slim   = { viewMode: 'slim', rowHeightDivider: 3 };

            const slimThreshold = options.responsive ? options.responsive.thresholds.slim : 1.5;
            const narrowThreshold = options.responsive ? options.responsive.thresholds.narrow : 6;

            switch (true) {
                case noDestination:
                    return normal;
                case narrowEnabled && destinationFirstOrLast:
                    return narrow;
                case slimEnabled && aspectRatio < slimThreshold:
                    return slim;
                case narrowEnabled && aspectRatio < narrowThreshold:
                    return narrow;
                default:
                    return normal;
            }
        }

        /**
         * Area, available for the timetable rows
         *
         * @return {{width: number, height: number}}
         * @private
         */
        function _getAvailableArea() {
            const marginHorizontal = $ctrl.options.view.innerMarginHorizontal;
            const borderWidth = $ctrl.options.view.borderWidth;

            /** @type {HTMLElement} */
            const areaJourneys = $ctrl.componentElement;

            let headersHeight = 0;

            /** @type {HTMLElement} */
            const tableHeaderCell = $ctrl.componentElement.querySelector('.head');
            if (tableHeaderCell) {
                headersHeight += tableHeaderCell.offsetHeight;

                headersHeight += marginHorizontal
                    ? marginHorizontal
                    : -borderWidth; // collapse borders
            }

            const productHeader = $ctrl.componentElement.querySelector('.product-header');
            if (productHeader) {
                headersHeight += productHeader.offsetHeight;

                headersHeight += marginHorizontal
                    ? marginHorizontal
                    : -borderWidth; // collapse borders
            }

            return {
                width: areaJourneys.offsetWidth,
                height: areaJourneys.offsetHeight - headersHeight,
            }
        }

        /**
         * @param {number} rowHeight
         * @param {number} rowWidth
         * @param {TimetableHafasViewMode} viewMode
         * @return {number}
         * @private
         */
        function _getDestinationScale(rowHeight, rowWidth, viewMode) {
            if (viewMode !== 'normal') {
                return 1;
            }

            switch ($ctrl.destinationScaleAlrorithm) {
                case 'row-aspect-ratio-threshold':
                    const aspectRatio = rowWidth / rowHeight;
                    log('Row aspect ratio', aspectRatio.toFixed(2));

                    switch (true) {
                        case aspectRatio > 14:
                            return 1;
                        case aspectRatio > 8:
                            return 0.8
                        default:
                            return 0.59;
                    }

                default:
                    return 1;
            }
        }

        /**
         * @param {object} styles
         * @param {TimetableHafasElement} element
         * @return {object}
         * @private
         */
        function _addStartEndPaddingStyles(styles, element) {
            switch (element.params.col) {
                case 'first':
                    styles['padding-left'] = $ctrl.paddingLeft;
                    break;
                case 'last':
                    styles['padding-right'] = $ctrl.paddingRight;
                    break;
                case 'single':
                    styles['padding-left'] = $ctrl.paddingLeft;
                    styles['padding-right'] = $ctrl.paddingRight;
                    break;
            }
        }

        /**
         * For the live preview in the CMS
         *
         * @private
         */
        function _initAutoRefresh() {
            let timeout;
            $scope.$watch(
                () => $ctrl.options,
                () => {
                    $timeout.cancel(timeout);
                    timeout = $timeout(() => {
                        _setViewVars();
                        _setStyles($ctrl.colorsScheme);
                        void _refreshView();
                    }, 250);
                },
                true,
            );
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('lineLayoutDetails', {
            templateUrl: '/assets/views/content/elements/types/line-route/layout-details-picker/layout-details-picker.tpl.html',
            controller: LineLayoutDetailsController,
            bindings: {
                layoutDetails: '<',
                layoutType: '@',
            }
        });

    function LineLayoutDetailsController() {
        const vm = this;

        vm.textOptions = {
            vertical: [
                {
                    name: 'ALTERNATING',
                    angle: {
                        even: -180,
                        odd: 0,
                    },
                },
                {
                    name: 'LEFT',
                    angle: -180,
                },
                {
                    name: 'RIGHT',
                    angle: 0,
                },
            ],
            horizontal: [
                {
                    name: 'ALTERNATING',
                    angle: {
                        even: 90,
                        odd: -90,
                    },
                },
                {
                    name: 'TOP',
                    angle: -90,
                },
                {
                    name: 'BOTTOM',
                    angle: 90,
                },
            ],
        };

        vm.minTextAngle = -60;
        vm.maxTextAngle = 60;

        vm.$onInit = init;

        function init() {
            if (!vm.layoutDetails.textAngleBase) {
                vm.layoutDetails.textAngleBase = vm.textOptions[vm.layoutType][0];
            }

            if (!vm.layoutDetails.textAngle) {
                vm.layoutDetails.textAngle = 0;
            }
        }
    }
})();
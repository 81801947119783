(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('answersValidation', answersValidation);

    /**
     * Directive validates content answers
     *
     * @returns {object}
     */
    function answersValidation() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel) {
                    return;
                }

                var validate = function() {
                    var value = scope.$eval(attrs.ngModel),
                        maxOptionsCount = 4,
                        maxCharCount = 4,
                        answers = value ? value.split(',') : [],
                        length = answers.length,
                        charCount = 0,
                        valid = true,
                        i;


                    if (length > maxOptionsCount) {
                        valid = false;
                    } else {
                        for (i = 0; i < length; i++) {
                            charCount = answers[i].length;

                            if (charCount > maxCharCount || !charCount) {
                                valid = false;
                                break;
                            }
                        }
                    }

                    if (!valid) {
                        ngModel.$setValidity('answersValidation', false);
                    } else {
                        ngModel.$setValidity('answersValidation', true);
                    }
                };

                scope.$watch(attrs.ngModel, function() {
                    validate();
                });
            }
        };
    }
})();

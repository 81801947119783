(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewTariffModelToolbarController', NewTariffModelToolbarController);

    function NewTariffModelToolbarController(
        $state,
        StatesFactory
    ) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.TariffModelStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.shareParkTariffModels');
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('rewardSelector', {
            templateUrl: '/assets/views/content/elements/types/reward-select/reward.tpl.html',
            controller: RewardSelector,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
                previewmode: '<?'
            }
        });

    function RewardSelector($scope,
                            $timeout,
                            $http,
                            notify,
                            StatesFactory,
                            ContentDataService,
                            UtilitiesService) {
        const vm = this;
        vm.compareAsNumber = UtilitiesService.compareAsNumber;
        vm.savedReward = vm.contentData.message.quiz.reward;
        ContentDataService.getRewardTypes().then(rewardTypes => {
            vm.rewardTypes = [{
                description: "",
                id: "0",
                rewardCode: "0",
                rewardLabel: "None",
                rewardUnitLabel: "",
                rewardValueFormat: ""
            }].concat(rewardTypes.plain());
        })
        .catch(
            console.error.bind(console)
        );
    }
})();

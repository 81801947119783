(function() {
    'use strict';

    angular.module('beacon.app')
        .component('profile', {
            templateUrl: '/assets/views/account/profile/profile.tpl.html',
            controller: ProfileController
        });

    function ProfileController(
        UserDataService,
        PopupService,
        LanguageService,
        $translate,
        $q,
        StorageFactory,
        $stateParams,
        mdcDefaultParams,
    ) {
        const vm = this;
        const STORAGE = StorageFactory.Storage('Main');

        // public properties
        vm.userInfo = {};

        vm.showChangePasswordPopup = showChangePasswordPopup;
        vm.updateUiZoomProp = updateUiZoomProp;
        vm.changeLanguage = changeLanguage;

        vm.languages = [];

        vm.language = null;


        // private methods
        vm.$onInit = onInit;

        function changeLanguage() {
            const langId = vm.languages.find(language => language.code === vm.language).id;
            UserDataService.setUILanguage(langId)
                .then(() => {
                    $translate.use(vm.language);
                    moment.locale(vm.language);
                    mdcDefaultParams({lang: vm.language});
                    window.localStorage.uiLang = vm.language;
                    UserDataService.currentUserInfo().then(data => STORAGE.set('currentUserInfo', data.plain()));
                })
                .catch(err => console.log(err));
        }

        function updateUiZoomProp() {
            UserDataService.setZoomProperty(vm.userInfo.zoom)
                .then(() => {
                    UserDataService.currentUserInfo().then(data => STORAGE.set('currentUserInfo', data.plain()));
                })
                .catch(err => console.log(err));
        }

        /**
         * Shows popup for changing user's password
         *
         */
        function showChangePasswordPopup() {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/account/change-password.tpl.html',
                controller: 'ChangePasswordPopupController',
                controllerAs: 'changePasswordPopupController',
                windowClass: 'change-password-popup',
                keyboard: true,
            });
        }

        function onInit() {
            if ($stateParams.passwordExpiredAlert) {
                PopupService.showAlertPopup({
                    text: 'YOUR_PASSWORD_IS_EXPIRED',
                    okButtonText: 'OK',
                });
            }

            $q.all([
                UserDataService.currentUserInfo(),
                LanguageService.getUiLanguages(),
            ]).then(res => {
                const [userInfo, languages] = res;
                vm.languages = languages.plain();
                vm.userInfo = userInfo.plain();
                vm.language = vm.userInfo.language.code;
            }).catch(err => console.log(err));
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newProfileConfig', {
            templateUrl: '/assets/views/share-park/customers/profile-configs/new/new-profile-config.tpl.html',
            controller: NewProfileConfigController
        });

    function NewProfileConfigController(
        $scope,
        $state,
        $stateParams,
        LanguageService,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
        ProfilesHelper,
        IDENTITY_PROVIDERS,
    ) {
        const vm = this;

        const { getUserProfiles } = ShareParkDataService;
        const { decodeCustomerProfile } = ProfilesHelper;

        vm.$onInit = init;

        vm.state = StatesFactory.ShareParkProfileConfigStates.refresh();
        vm.state.type = $stateParams.type;
        vm.profiles = [];
        vm.IDENTITY_PROVIDERS = IDENTITY_PROVIDERS;

        vm.isActive = isActive;
        vm.changeProfileConfigStatus = changeProfileConfigStatus;
        vm.showError = showError;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.profileConfigData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.ProfileConfig();

            if (!vm.profileConfigData.externalIdentityProvider) {
                vm.profileConfigData.externalIdentityProvider = null;
            }

            getUserProfiles()
                .then(response => {
                    vm.profiles = response.plain()
                        .map(profile => decodeCustomerProfile(profile))
                        .sort((a, b) => a.nameLabel.localeCompare(b.nameLabel));
                })
        }

        function onFinish() {
            const formData = new FormData();

            formData.append('profileConfig', angular.toJson(vm.profileConfigData));

            ShareParkDataService.setProfileConfig(formData).then(() => {
                $state.go('app.shareParkProfileConfigs');
            });

        }

        function isActive() {
            const ACTIVE_STATUS = 1;
            return vm.profileConfigData.status === ACTIVE_STATUS;
        }

        function changeProfileConfigStatus() {
            const ACTIVE = 1;
            const INACTIVE = 2;

            vm.profileConfigData.status = isActive() ? INACTIVE : ACTIVE;
        }

        /**
         * Checks if field error should be shown
         * @param name
         * @return {*|boolean}
         */
        function showError(name) {
            const field = vm.newProfileConfigForm && vm.newProfileConfigForm[name];
            return field && field.$dirty && field.$invalid;
        }

        $scope.$watch(
            () => vm.newProfileConfigForm && vm.newProfileConfigForm.$valid,
            formValid => {
                vm.state.canFinish = formValid;
            }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
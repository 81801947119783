(() => {
    angular.module('beacon.app')
        .controller('ContentGroupController', ContentGroupController);

    function ContentGroupController(
        $state,
        ContentDataService,
        LanguageService,
        PopupService,
        PermissionsService,
        DEFAULT_CONTENT_GROUP,
    ) {
        const vm = this;

        vm.addContentGroup = addContentGroup;

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'contentGroupTitle',
                    width: '40',
                    title: item => vm.langArray.length && item.title[vm.langArray[0].id]
                        ? item.title[vm.langArray[0].id]
                        : item.title[Object.keys(item.title)[0]],
                },
                {
                    name: 'ICON',
                    class: 'contentGroupIcon no-padding',
                    width: '40',
                    translate: item => !item.icon,
                    title: item => item.icon ? `<img src="${item.icon.iconUrl}">` : 'ICON_NOT_AVAILABLE',
                    html: true,
                },
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteContentGroup,
                        isVisible: PermissionsService.isContentGroupDeleteBtnVisible,
                    },
                    {
                        class: 'editBtn',
                        callback: editContentGroup,
                    },
                ],
            },
            updateCallback: updateContentGroups,
            needRadiobutton: false,
        };

        vm.contentGroups = [];
        vm.langArray = [];
        vm.icons = [];
        vm.totalItems = 0;
        vm.updateCounter = 0;

        /**
         * Redirects to add/edit content group screen
         */
        function addContentGroup() {
            $state.go('app.addContentGroup', {});
        }

        /**
         * Redirects to add/edit content group screen
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function editContentGroup($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            if (item.id !== DEFAULT_CONTENT_GROUP.id) {
                $state.go('app.editContentGroup', { data: item });
            } else {
                PopupService.showAlertPopup({
                    text: 'CANNOT_EDIT_DEFAULT_CONTENT_GROUP',
                    okButtonText: 'OK',
                });
            }
        }

        /**
         * Removes content group
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function deleteContentGroup($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            if (item.id === DEFAULT_CONTENT_GROUP.id) {
                PopupService.showAlertPopup({
                    text: 'CANNOT_REMOVE_DEFAULT_CONTENT_GROUP',
                    okButtonText: 'OK',
                });
                return;
            }

            ContentDataService.getContentsCount(item.id)
                .then((contentsCount) => {
                    if (parseInt(contentsCount)) {
                        PopupService.showAlertPopup({
                            text: 'CONTENT_GROUP_CANT_BE_DELETED',
                            okButtonText: 'OK',
                        });
                    } else {
                        PopupService.showConfirmationPopup({
                            text: 'YOU_ARE_ABOUT_TO_DELETE_CONTENT_GROUP',
                            okButtonText: 'YES',
                            cancelButtonText: 'NO',
                        }, () => {
                            ContentDataService.deleteContentGroup(item.id)
                                .then(() => {
                                    vm.totalItems -= 1;
                                })
                                .catch((response) => {
                                    if (response && response.status && response.status === 400) {
                                        PopupService.showAlertPopup({
                                            text: 'CONTENT_GROUP_CANT_BE_DELETED',
                                            okButtonText: 'OK',
                                        });
                                    }
                                });
                        });
                    }
                }).catch(console.error.bind(console));
        }

        /**
         * Updates content groups list
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateContentGroups(page, itemsPerPage) {
            ContentDataService.contentGroups({
                page,
                itemsPerPage,
            }).then((response) => {
                vm.contentGroups = response;
                vm.totalItems = response.count;

                for (let i = 0; i < vm.contentGroups.length; i++) {
                    const contentGroup = vm.contentGroups[i];

                    contentGroup.title = angular.fromJson(contentGroup.title);
                    contentGroup.allLanguages = vm.langArray;
                }
            });
        }

        /**
         * Initialization method
         */
        function init() {
            LanguageService.getLanguages()
                .then((response) => {
                    vm.langArray = response;
                    vm.updateCounter++;
                });
        }

        init();
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('lineRouteContentType', {
            templateUrl: '/assets/views/content/elements/types/line-route/line-route.tpl.html',
            controller: LineRouteContentType,
            bindings: {
                langArray: '<?',
                contentForm: '=?',
                contentData: '=?',
                contentGroupsArray: '<?',
            }
        });

    function LineRouteContentType(
        $scope,
        $stateParams,
        $translate,
        StatesFactory,
        PermissionsService,
        LineRouteDataService,
        LineRoutePopup,
        TRANSPORT_TYPE,
        LINE_ROUTE_FIT_OPTIONS,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.state = StatesFactory.ContentStates;
        vm.isContentGroupSelectBoxVisible = PermissionsService.isContentGroupMenuItemVisible;
        vm.TRANSPORTS = Object.values(TRANSPORT_TYPE).filter(type => type.catCodes);
        vm.selectedTransportMeansText = '';

        vm.headerSettings = [
            {
                label: 'SHOW_TIME_TILL_DEPARTURE',
                model: 'showTillDepartureTime',
            },
            {
                label: 'SHOW_LOGO_OF_TRANSPORTATION_MODE',
                model: 'showModeLogo',
            },
            {
                label: 'SHOW_TRACK_NAME',
                model: 'showTrack',
            },
        ];
        vm.footerSettings = [
            {
                label: 'SHOW_MESSAGES',
                model: 'showMessages'
            },
        ];
        vm.lineSettings = [
            {
                label: 'SHOW_ALSO_NEXT_DEPARTURE',
                model: 'showNextDeparture',
            },
            {
                label: 'SHOW_PRODUCTS_AT_STATION',
                model: 'showStationProducts',
            },
            {
                label: 'SHOW_DEPARTURE_TIME_AT_EVERY_STATION',
                model: 'everyStationDeparture',
            },
        ];

        vm.lineRouteLayouts = [
            {
                id: 1,
                name: 'GRAPHIC_LINE_ROUTE',
            },
            {
                id: 2,
                name: 'HIGH_CONTRAST_LINE_ROUTE_DARK',
            },
            {
                id: 3,
                name: 'HIGH_CONTRAST_LINE_ROUTE_LIGHT',
            },
        ];

        vm.fitOptions = LINE_ROUTE_FIT_OPTIONS;

        const LAYOUT_TYPE_HTML_DARK = 2;
        const LAYOUT_TYPE_HTML_LIGHT = 3;

        vm.showFieldError = showFieldError;
        vm.getContentGroupTitle = getContentGroupTitle;
        vm.getSelectedTransportTypesText = getSelectedTransportTypesText;
        vm.openPreview = openPreview;
        vm.canEditLayout = canEditLayout;

        /**
         * Checks if field error should be shown
         * @param name
         * @return {*|boolean}
         */
        function showFieldError(name) {
            const field = vm.lineRouteForm && vm.lineRouteForm[name];
            return field && field.$dirty && field.$invalid;
        }

        /**
         * Returns content group title
         * @param group
         * @return {*}
         */
        function getContentGroupTitle(group) {
            const defaultLang = Object.keys(group.title)[0];
            return group.title[vm.contentData.language_id] ||
                group.title[defaultLang];
        }

        /**
         * Returns text for transport types select
         * @return {*}
         */
        function getSelectedTransportTypesText() {
            const selectedTransTypes = vm.contentData.message.transTypes;
            if(!selectedTransTypes.length) {
                $translate('SELECT_TRANSPORT_TYPES_FROM_LIST')
                    .then(translation => vm.selectedTransportMeansText = translation);
                return;
            }

            if (selectedTransTypes.length === vm.TRANSPORTS.length) {
                $translate('ALL_TRANSPORT_TYPES_ARE_AVAILABLE')
                    .then(translation => vm.selectedTransportMeansText = translation);
                return;
            }

            Promise.all(selectedTransTypes.map(item => $translate(TRANSPORT_TYPE[item].translationLabel)))
                .then(translations => {
                    vm.selectedTransportMeansText = translations.join(', ')
                });
        }

        /**
         * Opens popup with preview
         * @param previewType
         */
        function openPreview(previewType) {
            LineRoutePopup.showPreview(previewType, vm.contentData.message, vm.lineRouteDeparturesDetails);
        }

        /**
         * Initialization method
         */
        function init() {
            if ($stateParams.type === 'new') {
                vm.contentData.message.transTypes = vm.TRANSPORTS.map(type => type.code);
                vm.contentData.message.layout = vm.lineRouteLayouts[0].id;
            }

            getSelectedTransportTypesText();
        }

        /**
         * On finish handler
         */
        function onFinish(event, option) {
            let contentFormData = angular.copy(vm.contentData);
            contentFormData = Object.assign(contentFormData, {
                title: JSON.stringify(vm.contentData.title),
                message: JSON.stringify(vm.contentData.message)
            });

            if (angular.isFunction(option.callback)) {
                option.callback({
                    type: vm.state.type,
                    contentData: contentFormData
                });
            }
        }

        /**
         * Can edit layout
         *
         * @returns {boolean}
         */
        function canEditLayout() {
            const layouts = [LAYOUT_TYPE_HTML_DARK, LAYOUT_TYPE_HTML_LIGHT];
            return !layouts.includes(vm.contentData.message.layout);
        }

        /**
         * Enable/disable finish button
         */
        function canFinish() {
            vm.state.canFinish = vm.lineRouteForm.$valid;
        }

        /**
         * Watchers
         */
        $scope.$watch(() => vm.lineRouteForm.$valid, canFinish);

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
    }
})();
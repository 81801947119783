(function () {
    'use strict';

    angular.module('beacon.app').component('customerCommunication', {
        templateUrl: '/assets/views/share-park/customers/customer-communication/customer-communication.tpl.html',
        controller: CustomerCommunicationController,
    });

    function CustomerCommunicationController(
        $q,
        $state,
        $translate,
        UtilitiesService,
        CustomerCommunicationService,
        CustomerCommunicationHelper,
        PopupService,
        CustomersCacheFactory,
        ProfilesHelper,
        LanguageService
    ) {
        const vm = this;

        vm.addcustomerCommunicationConfig = addcustomerCommunicationConfig;

        vm.$onInit = init;

        vm.visibleCustomerCommunicationConfigs = [];
        vm.updateCounter = 0;
        vm.customerCommunicationList = [];
        const { decodeCustomerProfile } = ProfilesHelper;

        vm.listConfig = {
            columns: [
                {
                    name: 'SENDER',
                    class: 'column',
                    width: '15',
                    translate: true,
                    title: listItem => listItem.sender,
                },
                {
                    name: 'SUBJECT',
                    class: 'column',
                    width: '15',
                    translate: true,
                    title: listItem => listItem.email_subject[listItem.language] || '',
                },
                {
                    name: 'RECIPIENTS',
                    width: '10',
                    class: 'column',
                    translate: true,
                    title: listItem => listItem.target_users.emails.length
                        + listItem.target_users.guids.length + listItem.params.countCustomerByProfile.length,
                },
                {
                    name: 'STATUS',
                    class: 'status-column column',
                    width: '10',
                    translate: true,
                    title: listItem => {
                        switch (listItem.sent) {
                            case 1:
                                return 'SENT';
                            case 0:
                                return '';
                            default:
                                return '';
                        }
                    },
                },
                {
                    name: 'SCHEDULE_SEND',
                    width: '15',
                    translate: true,
                    title: listItem => {
                        return (listItem.start_date ? listItem.start_date.slice(0, -3) : '')
                    },
                },
                {
                    name: 'DATE_OF_LAST_SEND',
                    width: '15',
                    translate: true,
                    title: listItem => (listItem.sent_date ? listItem.sent_date.slice(0, -3) : ''),
                },
            ],
            buttons: {
                width: '20',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteBulkEmail,
                        permissionAction: 'can_delete_mailing',
                    },
                    {
                        class: 'editBtn',
                        callback: editCustomerCommunicationConfig,
                        permissionAction: 'can_save_mailing',
                    },
                    {
                        class: 'sendBtn',
                        callback: sendBulkEmails,
                        permissionAction: 'can_send_mailing',
                    },
                    {
                        class: 'copyBtn',
                        callback: copyCustomerCommunicationConfig,
                        permissionAction: 'can_save_mailing',
                    },
                ],
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        function init() {
            $q.all([
                CustomerCommunicationService.getBulkEmailsList(),
                CustomersCacheFactory.getAll(),
            ])
            .then(([emailsResponse, customersResponse]) => {
                vm.customersAll = customersResponse.plain().filter(customer => customer.profile).map(customer => {
                    customer.profile = decodeCustomerProfile(customer.profile);
                    return customer;
                });

                vm.customerCommunicationList = emailsResponse.data;
                vm.customerCommunicationList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                changeFormatLang();
                vm.updateCounter++;
                vm.customerCommunicationList.map(item => {
                    item.params = {};
                    item.params.countCustomerByProfile = [];
                    item.target_users.profileRefs.map(id => {
                        item.params.countCustomerByProfile.push(...vm.customersAll.filter(customer => customer.profile.id == id));
                    })
                })

            });
        }

        function changeFormatLang() {
            LanguageService.getLanguages().then(response => {
                vm.langsArray = response.plain();
                vm.langsArray.forEach(lang => {
                    vm.customerCommunicationList.map(item => {
                        if (item.language === lang.code) item.language = lang.id;
                    })
                })
            });
        }

        /**
         * Edit customer communication
         * @param $event
         * @param item
         */
        function editCustomerCommunicationConfig($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editCustomerCommunication', { data: item });
        }

        function copyCustomerCommunicationConfig($event, item) {
            $event.preventDefault();
            $event.stopPropagation();
            item.id = null;
            $state.go('app.addCustomerCommunication', { data: item });
        }

        function updateList(page = 1, count = 25) {
            vm.visibleCustomerCommunicationConfigs = UtilitiesService.getArrayPage(
                vm.customerCommunicationList,
                page,
                count
            );
        }

        function deleteBulkEmail($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup(
                {
                    text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_BULK_EMAILS',
                    okButtonText: 'DELETE',
                    cancelButtonText: 'CANCEL',
                },
                function () {
                    CustomerCommunicationService.deleteBulkEmail(item.id)
                        .then(() => {
                            vm.invitationsCount -= 1;
                            init();
                        })
                        .catch(console.error.bind(console));
                }
            );
        }

        function sendBulkEmails($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            vm.isFutureSending = new Date() < new Date(listItem.start_date);
            let message = vm.isFutureSending ? 'YOUR_EMAIL_WIIL_BE_SENT' : 'DO_YOU_REALLY_WANT_TO_SEND_EMAIL';

            $translate(message, {
                count: listItem.target_users.emails.length
                    + listItem.target_users.guids.length + listItem.params.countCustomerByProfile.length,
                sentDate: moment(listItem.start_date).format('YYYY-MM-DD'),
                time: moment(listItem.start_date).format('H:mm'),
            })
                .then(text =>
                    PopupService.showConfirmationPopup({
                        text: text,
                        okButtonText: 'OK',
                        cancelButtonText: 'CANCEL',
                    })
                )
                .then(_ =>
                    CustomerCommunicationService.sendBulkEmails(listItem.id)
                        .catch(err => CustomerCommunicationHelper.handleServerError(err))
                )
                .then(response => CustomerCommunicationHelper.handleServerResponse(response))
                .then(() => init());
        }

        function addcustomerCommunicationConfig() {
            $state.go('app.addCustomerCommunication');
        }

        function generatePermissionName(button) {
            return button.permissionAction;
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltyCampaignTypeTab', {
            templateUrl: '/assets/views/loyalty/campaigns/form/tabs/type/type.tpl.html',
            controller: loyaltyCampaignTypeTab
        });

    function loyaltyCampaignTypeTab(StatesFactory, StorageFactory) {
        const vm = this;

        vm.state = StatesFactory.LoyaltyCampaignStates;

        vm.setCampaignType = setCampaignType;

        init();

        vm.campaignTypes = [
            {
                id: 1,
                name: 'EVENT_BASED_PROGRAM',
            },
            {
                id: 2,
                name: 'REVENUE_BASED_PROGRAM'
            },
            {
                id: 3,
                name: 'TIER_BASED_PROGRAM'
            }
        ];

        /**
         * Setting campaign type to object
         *
         * @param {number} campaignType
         */
        function setCampaignType(campaignType) {
            const alreadySet = vm.data.campaignType === campaignType;
            vm.data.campaignType = alreadySet ? null : campaignType;
            vm.state.canNext = !!vm.data.campaignType;
        }

        function init() {
            vm.STORAGE = StorageFactory.Storage('LoyaltyCampaign');
            vm.STORAGE.on('data', (value) => (vm.data = value));
            vm.state.canNext = !!vm.data.campaignType;
        }
    }
})();

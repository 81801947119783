(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ServiceProviderBillsService', ServiceProviderBillsService);

    function ServiceProviderBillsService(
        Restangular,
        $http,
    ) {
        return {
            getBills,
            getBill,
        };

        /**
         * @param {int} serviceProviderExtId
         * @return Promise<ServiceProviderBill[]>
         */
        function getBills(serviceProviderExtId = null) {
            let url = `/api/carPark/getCapServiceProviderBills`;
            if (serviceProviderExtId) {
                url += '/' + serviceProviderExtId;
            }
            return $http.get(url).then(response => response.data);
        }

        /**
         *
         * @param {int} id
         * @return Promise<ArrayBuffer>
         */
        function getBill(id) {
            let url = `carPark/getCapServiceProviderBill/${id}`;
            return Restangular.one(url)
                .withHttpConfig({ responseType: 'arraybuffer' })
                .get();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('textProperties', textProperties);

    function textProperties() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/elements/text.tpl.html',
            replace: true,
            controller: textPropertiesController,
            controllerAs: 'textPropertiesCtrl',
            bindToController: true,
            scope: {
                item: '='
            }
        };
    }

    function textPropertiesController($scope) {}
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('unity3dModels', {
            templateUrl: '/assets/views/content/unity-3d-models/unity-3d-models.tpl.html',
            controller: Unity3dModelsController
        });

    function Unity3dModelsController(
        $state,
        Unity3dModelsDataService,
        UtilitiesService,
        PopupService,
        HTTP_STATUSES,
        $translate,
    ) {
        const vm = this;
        let unity3dModels = [];

        vm.dataLoaded = false;
        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'column-title',
                    width: '30',
                    title: unity3dModel => unity3dModel.name,
                },
                {
                    name: 'S3_DIRECTORY',
                    class: 'column-dir',
                    width: '30',
                    title: unity3dModel => unity3dModel.dir || '',
                    translate: true,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: onDelete,
                        permissionAction: 'delete',
                    },
                    {
                        class: 'editBtn',
                        callback: onEdit,
                        permissionAction: 'modify',
                    },
                ]
            },
            updateCallback: onUpdate,
            generatePermissionName,
        };

        vm.$onInit = init;
        vm.onCreate = onCreate;

        function init() {
            loadModels();
        }

        function loadModels() {
            Unity3dModelsDataService.getAll()
                .then(response => {
                    unity3dModels = response.plain();
                    vm.unity3dModelsCount = unity3dModels.length;
                    vm.dataLoaded = true;
                });
        }

        /**
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function onUpdate(page, itemsPerPage) {
            vm.unity3dModelsCurrentPage = UtilitiesService.getArrayPage(unity3dModels, page, itemsPerPage);
        }

        /**
         * @param {jQuery.Event} $event
         * @param {ModelsFactory.Unity3dModel} unity3dModel
         * @param unity3dModel
         */
        function onDelete($event, unity3dModel) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_3D_MODEL',
                okButtonText: 'Delete',
                cancelButtonText: 'Cancel'
            }, _ => {
                Unity3dModelsDataService.remove(unity3dModel)
                    .then(loadModels)
                    .catch(response => {
                        if (response.status === HTTP_STATUSES.LOCKED) {
                            Promise.all([
                                generateRelatedContentMessage(response.data),
                                $translate('OK')
                            ]).then(([text, okButtonText]) => {
                                PopupService.showAlertPopup({
                                    text,
                                    okButtonText
                                });
                            });
                        } else {
                            throw new Error(response);
                        }
                    });
            });
        }

        /**
         * @param {jQuery.Event} $event
         * @param {ModelsFactory.Unity3dModel} unity3dModel
         * @param unity3dModel
         */
        function onEdit($event, unity3dModel) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editUnity3dModel', {
                data: unity3dModel,
            });
        }

        function onCreate() {
            $state.go('app.addUnity3dModel');
        }

        /**
         * @param {object} button
         * @param {callback} button.callback
         * @param {string} button.class
         * @param {string} button.permissionAction
         * @returns {string}
         */
        function generatePermissionName(button) {
            return 'can_' + button.permissionAction + '_3d_models';
        }

        /**
         * @param {string[]} locationNames
         * @returns {Promise<string>}
         */
        function generateRelatedContentMessage(locationNames) {
            return $translate('THIS_3D_MODEL_IS_RELATED_TO_NEXT_LOCATIONS')
                .then(message => {
                    return `${message}: ${locationNames.join(', ')}`;
                });
        }
    }
})();
/**
 * @var SunCalc https://github.com/mourner/suncalc
 */
(function() {
    'use strict';

    angular.module('trmServices').service('ScreenControllerHelper', function (

    ) {
        /**
         * Replace all target strings in name by provided replacement map
         *
         * @param {string} source initial string to base formatted value of
         * @param {Object<string, string>[]} replacementMap
         * @returns {string} filtered string
         */
        function generateControllerLabel(source = '', replacementMap = {}) {
            let result = source;

            if (!angular.isArray(replacementMap)) {
                return result;
            }

            replacementMap.forEach((pairs) => {
                Object.keys(pairs).forEach(pattern => {
                    const replacement = pairs[pattern];
                    result = result.replaceAll(pattern, replacement);
                });
            });

            return result.trim().replace(/\s+/g, ' ');
        }

        return {
            generateControllerLabel,
        }
    });
})();

(function() {
    'use strict';

    angular.module('beacon.app')
      .service('DialogCustomerPayoutService', function ($mdDialog, ShareParkDataService, PopupService) {
        const vm = this;

        return { show };

        function show({ event, admin, bonusList }) {
            vm.admin = admin;
            vm.bonusList = bonusList;
            vm.response;

            return $mdDialog.show({
                controller: DialogCustomerPayoutController,
                templateUrl: '/assets/views/share-park/customers/customers/new/dialog-customer-payout/dialog-customer-payout.tpl.html',
                targetEvent: event,
                clickOutsideToClose: true,
            });
        }

        function DialogCustomerPayoutController($scope) {
            $scope.email = vm.admin.emailAddress;
            $scope.bonusList = vm.bonusList;
            $scope.comment = '';

            $scope.cancel = () => {
                $mdDialog.cancel();
            };

            $scope.submit = () => {
                const { guid, nameFirst, nameLast } = vm.admin;

                ShareParkDataService.payoutBonuses({
                    guid,
                    redeemAccounts: $scope.bonusList.map(({ id }) => id).join(','),
                    adminName: `${nameFirst || ''} ${nameLast || ''}`.trim(),
                    comment: $scope.comment,
                }).then(response => {
                    if (response.data) {
                        vm.response = response.data;
                        PopupService.showAlertPopup({
                            text: 'BONUS_PAYOUT_SUCCESSFUL',
                            okButtonText: 'OK',
                        });
                    }
                    $mdDialog.cancel(response.data);
                });
            }
        }
    });
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('campaignContentTab', {
            templateUrl: '/assets/views/campaign/mobile/tabs/content/content.tpl.html',
            controller: CampaignMobileContentTab,
            bindings: {
                campaignModel: '='
            }
        });

    function CampaignMobileContentTab($state, $scope, $stateParams,
                                      campaignMobileState, ContentDataService,
                                      ContentHelper, UtilitiesService, StorageFactory,
                                      CONTENT_TYPES) {
        const vm = this;
        const { sortByStringProperty, sortByDateProperty } = UtilitiesService;

        // public properties
        vm.state = campaignMobileState;
        vm.contentOrder = true;
        vm.checkboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'contentTitle',
                    width: '65',
                    title: ContentHelper.getContentNameForList
                },
                {
                    name: 'TYPE',
                    class: 'contentType',
                    width: '30',
                    translate: true,
                    title: (item) => item.hidden_content
                        ? 'BANNER_STANDALONE'
                        : item['content_type'].name
                }
            ],
            header: false,
            itemClickCallback: toggleContent
        };
        vm.content = [];

        // public methods
        vm.addNewContent = addNewContent;
        vm.toggleContent = toggleContent;

        init();

        // private methods

        /**
         * Sorts array of contents by string|number properties
         */
        function sortContent() {
            if (Number(vm.contentOrder) === 1) {
                vm.content = sortByStringProperty(vm.content, 'atitle');
            } else {
                vm.content = sortByDateProperty(vm.content, 'created_at');
            }
        }

        /**
         * Handler on content select/deselect
         */
        function toggleContent() {
            vm.state.canProcessNext = !!vm.campaignModel.contents.length;
        }

        /**
         * Go to content add page
         */
        function addNewContent() {
            $state.go('app.addContent', {});
        }

        /**
         * Get list of content
         */
        function getContents() {
            const requestData = {
                contentTypeId: `${CONTENT_TYPES.INFOTAINMENT},${CONTENT_TYPES.QUIZ},${CONTENT_TYPES.FEEDBACK}`,
                withHidden: true
            };

            ContentDataService.contents(requestData)
                .then((response) => {
                    let data = response;
                    if (data.hasOwnProperty('plain') && typeof data.plain === 'function') {
                        data = data.plain();
                    }

                    vm.content = vm.STORAGE.set('contentData', data || []);
                    vm.content.forEach((item) => {
                        item.title = JSON.parse(item.title);
                        item.message = JSON.parse(item.message);
                        item.atitle = item.title[item.language_id];
                    });
                    vm.content = sortByStringProperty(vm.content, 'atitle');

                    // Set content elements as checked which got from state
                    const selectedContent = $stateParams.selectedContent;
                    if (selectedContent) {
                        vm.campaignModel.contents = vm.content.filter(
                            content => selectedContent.includes(content.id)
                        );
                        vm.state.canProcessNext = true;
                    }
                })
                .catch(console.error.bind(console));
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.STORAGE = StorageFactory.Storage('MobileCampaign');
            vm.content = vm.STORAGE.get('contentData') || [];

            if (!vm.content.length) {
                getContents();
            }
        }

        /**
         * Switch type of sorting content watcher
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.contentOrder;
        }), sortContent);
    }
}());

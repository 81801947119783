(function() {
    'use strict';

    angular.module('beacon.app')
        .component('habitDetailsTab', {
            templateUrl: '/assets/views/campaign/habit/tabs/details/details.tpl.html',
            controller: HabitDetailsTab,
            bindings: {
                model: '='
            }
        });

    function HabitDetailsTab(
        $scope,
        $stateParams,
        habitEditState,
        BANNER_EVERY_STATIC_TIME_SEGMENTS,
        WEEK_DAYS,
        HABIT_TYPES
    ) {
        const vm = this;

        vm.TIME_OPTIONS = BANNER_EVERY_STATIC_TIME_SEGMENTS;
        vm.HABIT_TYPES = HABIT_TYPES;

        vm.state = habitEditState;

        vm.timeParams = vm.model.timeParams;

        vm.toggleDay = toggleDay;

        vm.today = new Date(); // need for form validation
        vm.today.setHours(0, 0, 0, 0);
        vm.maxDate = new Date();
        vm.maxDate.setFullYear(vm.maxDate.getFullYear() + 10);
        vm.WEEK_DAYS = WEEK_DAYS;

        vm.dayChecked = dayChecked;

        vm.$onInit = init;


        /**
         * Toggles day selection
         * @param {number} id
         */
        function toggleDay(id) {
            const { selectedDays } = vm.timeParams;
            const index = selectedDays.indexOf(id);

            (index === -1)
                ? selectedDays.push(id)
                : selectedDays.splice(index, 1);
        }

        /**
         * Check day
         * @param id
         * @returns {*|boolean}
         */
        function dayChecked(id) {
            const { selectedDays } = vm.timeParams;
            return selectedDays && (selectedDays.indexOf(id) !== -1);
        }



        /**
         * Initialization method
         */
        function init() {

            vm.state.type = $stateParams.type;

            const ALL_DAYS_FLAG = 9;

            const timeParams = vm.timeParams;

            if (timeParams.hourOfDayStart) {
                const startTime = timeParams.hourOfDayStart.split(':');
                timeParams.startTime = new Date();
                timeParams.startTime.setHours(...startTime);
            }

            if (timeParams.hourOfDayEnd) {
                const endTime = timeParams.hourOfDayEnd.split(':');
                timeParams.endTime = new Date();
                timeParams.endTime.setHours(...endTime);
            }

            const days = timeParams.daysOfWeek;
            timeParams.onlyCertainDays = !((days.length === 1) && (days[0] === ALL_DAYS_FLAG));
            timeParams.selectedDays = timeParams.onlyCertainDays ? days : [];
        }
    }

}());

'use strict';
(function () {
    const app = angular.module('jQuery', []);

    app.factory('jQuery', function($window) {
        if (!$window.jQuery) {
            throw new Error('jQuery is missing');
        }
        return $window.jQuery;
    });

})();
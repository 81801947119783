(function () {
    'use strict';

    angular.module('beacon.app')
        .component('enforcement', {
            templateUrl: '/assets/views/share-park/enforcement/enforcement.tpl.html',
            controller: EnforcementController
        });

    function EnforcementController(
        ShareParkDataService,
        ShareParkHelperService,
        DateHelper,
        $translate,
        PermissionsService
    ) {
        const vm = this;
        vm.$onInit = init;
        const { processParkingStatusesResponse } = ShareParkHelperService;
        const { decodeCarParkData } = ShareParkHelperService;
        const { isPermissionAvailable } = PermissionsService;

        vm.plateNumber = '';
        vm.onNumberPlateType = onNumberPlateType;
        vm.getAllPlates = getAllPlates;
        vm.onNumberPlateChange = onNumberPlateChange;
        vm.onEmailType = onEmailType;
        vm.onEmailChange = onEmailChange;
        vm.getCustomersByEmail = getCustomersByEmail;
        vm.carparksName = [];
        vm.getCarparksName = getCarparksName;
        vm.getCountryCode = getCountryCode;
        vm.isCanceled = isCanceled;
        vm.getFormatinPlateNumber = getFormatinPlateNumber;
        vm.downloadCSV = downloadCSV;
        const columnNames = {
            plateNumber: { en: 'Number plate', de: 'Kennzeichen' },
            start: { en: 'Start', de: 'Beginn' },
            end: { en: 'End', de: 'Ende' },
        }
        vm.timeFrame = {
            selectedDay: moment(),
            min_date: moment().subtract(1, 'd'),
            isNightlyParking: !isPermissionAvailable('can_view_download_all_permissions'),
            getStartDate: function () {
                if (!this.isNightlyParking) {
                    return moment(this.selectedDay).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                } else {
                    return moment(this.selectedDay).set({ h: 22, m: 30 }).format('YYYY-MM-DD HH:mm:ss')
                }
            },
            getEndDate: function () {
                if (!this.isNightlyParking) {
                    return moment(this.selectedDay).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                } else {
                    return moment(this.selectedDay).add(1, 'd').set({ h: 1, m: 30 }).format('YYYY-MM-DD HH:mm:ss')
                }
            }
        }

        vm.searchModes = {
            BY_NUMBER_PLATE: {
                id: 1,
                label: 'BY_NUMBER_PLATE',
            },
            BY_EMAIL: {
                id: 2,
                label: 'BY_EMAIL',
            }
        };
        vm.searchModesArr = Object.values(vm.searchModes);
        vm.searchMode = vm.searchModes.BY_NUMBER_PLATE.id;
        vm.customer = null;
        vm.customerEmail = '';
        vm.permissions = [];
        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
        vm.currentLocale = $translate.use();
        vm.getTranslation = getTranslation;
        vm.isAvailableAll = isPermissionAvailable('can_view_download_all_permissions');
        vm.isAvailableNightly= isPermissionAvailable('can_view_download_nightly_parking');

        function init() {
            ShareParkDataService.getCarParks({ page: 1, itemsPerPage: null })
                .then(response => {
                    vm.carparksName = response.plain();
                })
        }

        /**
         * @param {string} numberPlate
         */
        function onNumberPlateType(numberPlate) {
            vm.plateNumber = numberPlate
                ? numberPlate
                    .toUpperCase()
                    .replace(/[^0-9^A-ZÄÖÜß]/g, '')
                : '';
            vm.customerEmail = '';
            unsetUserData();
        }

        function downloadCSV() {
            ShareParkDataService.getPermissions({
                startDate: vm.timeFrame.getStartDate(),
                endDate: vm.timeFrame.getEndDate(),
                carParks: vm.carparksName.map(carpark => carpark.external_id).join(',')
            })
                .then(response => {
                    const permissions = response.plain();
                    let csvContent = columnNames.plateNumber[$translate.use()] + ';'
                        + columnNames.start[$translate.use()] + ';'
                        + columnNames.end[$translate.use()] + "\n"
                        + permissions
                            .filter(permission => permission.numberPlate && !permission.numberPlate.startsWith('#'))
                            .map(permission => {
                            if (permission.numberPlate) {
                                return permission.numberPlate + ';'
                                    + toCarParkLocalTime(permission.permissionFrom, permission.carparkRef) + ';'
                                    + toCarParkLocalTime(permission.permissionTill, permission.carparkRef)
                            }
                        }).join("\n");

                    const fileName = moment(vm.timeFrame.selectedDay).format('YYYY-MM-DD') + ".csv";
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    const file = new Blob(["\ufeff" + csvContent], { type: 'text/csv' });
                    a.href = window.URL.createObjectURL(file);
                    a.download = fileName;
                    a.click();
                })
        }

        /**
        *
        * @param {string} dateUTC
        * @param {number} carParkId
        * @return {string}
        */
        function toCarParkLocalTime(dateUTC, carParkId) {
            const timeZone = getTimeZoneId(carParkId);
            if (!timeZone) {
                return 'MISSING_CAR_PARK_TIMEZONE';
            }
            return moment(dateUTC).tz(timeZone).format('YYYY-MM-DD HH:mm')
        }

        /**
        *
        * @param {number} carParkId
        * @return {string}
        */
        function getTimeZoneId(carParkId) {
            const carPark = vm.carparksName.find(carPark => carPark.external_id === carParkId);
            return carPark
                ? carPark.time_zone_id
                : null;
        }

        function getTranslation(field) {
            return JSON.parse(field)[vm.currentLocale] || '';
        }

        /**
        * @return {Promise<{customer: object, numberPlate: string}[]>}
        */
        function getAllPlates() {
            if (!vm.plateNumber) {
                return Promise.resolve([]);
            }

            return ShareParkDataService.findCustomers(vm.plateNumber)
                .then(response => response.plain()
                    .map(customer => customer.registeredVehicles
                        .map(registeredVehicle => {
                            customer.vehicles = customer.registeredVehicles.map(vehicle => vehicle.capVehicleRegistration);
                            let numberPlateObject = {
                                customer,
                                numberPlate: registeredVehicle.capVehicleRegistration.numberPlate,
                            }
                            return numberPlateObject;
                        })
                    )
                    .flat()
                    .filter(item => item.numberPlate.includes(vm.plateNumber))
                );
        }

        /**
         * @return {Promise<{object}[]>} - customers
         */
        function getCustomersByEmail() {
            if (!vm.customerEmail) {
                return Promise.resolve([]);
            }

            return ShareParkDataService.findCustomers(vm.customerEmail).then(customers => {
                return customers.map(customer => {
                    customer.vehicles = customer.registeredVehicles.map(vehicle => vehicle.capVehicleRegistration);
                    return customer;
                });
            });
        }

        /**
         * @param {{customer: object, numberPlate: string}} data
         */
        function onNumberPlateChange(data) {
            vm.customerEmail = '';
            if (!data) {
                return;
            }
            const customer = data.customer;
            vm.customer = customer;
            vm.customerEmail = customer.emailAddress;
            vm.customer && loadCustomerAdditionalData(vm.customer);
        }

        /**
        *
        * @param {object} customer
        */
        function onEmailChange(customer) {
            vm.plateNumber = '';

            if (!customer) {
                return;
            }
            vm.customer = customer;
            vm.customer && loadCustomerAdditionalData(vm.customer);
        }

        function loadCustomerAdditionalData(customer) {
            ShareParkDataService.getParkingStatuses(customer.guid)
                .then(response => {
                    vm.parkingStatuses = processParkingStatusesResponse(response.plain()).filter(item => !item.endedParking);
                    vm.parkingStatuses = vm.parkingStatuses.map(status => {
                        status.startedParking = moment(status.startedParking).format(DATE_TIME_FORMAT);
                        return status;
                    });
                });

            ShareParkDataService.getUserReservationsByCap({ guid: customer.guid }).then(response => {
                let yesterdayDate = moment(new Date()).subtract(1, 'days').toISOString();
                let tomorrowDate = moment(new Date()).add(1, 'days').toISOString();

                vm.permissions = response.plain().filter(permission => {
                    if (permission.permissionTill > yesterdayDate && permission.permissionFrom < tomorrowDate) {
                        return permission;
                    }
                })
                vm.permissions = vm.permissions.map(permission => {
                    permission.permissionFrom = moment(permission.permissionFrom).format(DATE_TIME_FORMAT);
                    permission.permissionTill = moment(permission.permissionTill).format(DATE_TIME_FORMAT);
                    permission.canceledAt = moment(permission.canceledAt).format(DATE_TIME_FORMAT);

                    return permission;
                });
            })
        }

        function isCanceled(status) {
            return status === 3;
        }

        function getCountryCode(numberPlate) {
            let countryCode = null;
            vm.customer.registeredVehicles.map(vehicle => {
                if (vehicle.capVehicleRegistration.numberPlate === numberPlate) {
                    countryCode = vehicle.capVehicleRegistration.countryCode;
                }
            })
            return countryCode;
        }

        function getFormatinPlateNumber(numberPlate) {
            let plateNumber = numberPlate.replace('#', ' ');
            const array = plateNumber.match(/[^0-9]+|[0-9]+/g);
            return array ? array.join(' ') : '';
        }

        function onEmailType() {
            vm.plateNumber = '';
            unsetUserData();
        }

        function getCarparksName(id) {
            let name = null;
            vm.carparksName.forEach(item => item.external_id === id ? name = item.name : null)
            return name;
        }

        function unsetUserData() {
            vm.customer = null;
            vm.parkingStatuses = [];
            vm.permissions = [];
        }

    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tickerPreview', {
            templateUrl: '/assets/views/content/elements/types/ticker/ticker-preview/ticker-preview.tpl.html',
            controller: TickerPreviewController,
            bindings: {
                model: '<',
            }
        });

    function TickerPreviewController($sce, $scope, $window, ContentDataService, MARQUEE_TYPE) {
        const vm = this;

        const DEFAULT_CAROUSEL_INTERVAL = 5000;

        // public properties
        vm.activeTickerEl = 0;
        vm.contentLength = 0;
        vm.animationSpeed = '60s';

        // public methods
        vm.showPreview = showPreview;
        vm.getCarouselInterval = getCarouselInterval;
        vm.setAnimationSpeed = setAnimationSpeed;


        function showPreview() {
            vm.feeds = [];
            vm.activeTickerEl = 0;
            vm.sourceType = vm.model.type;

            switch (vm.model.type) {
                case MARQUEE_TYPE.RSS:
                    parseRss();
                    break;
                case MARQUEE_TYPE.STATIC:
                    parseStaticHTML();
                    break;
                case MARQUEE_TYPE.EXTERNAL:
                    parseHTMFfromURL();
            }
        }

        function getCarouselInterval() {
            const MILISECONDS_IN_SECOND = 1000;
            return vm.model.fixDuration ?
                (vm.model.fixDuration * MILISECONDS_IN_SECOND) : DEFAULT_CAROUSEL_INTERVAL;
        }

        function setAnimationSpeed(speed) {
            const el = document.getElementById('tickerelement');
            const wrapperWidth = el ? el.clientWidth : window.innerWidth || 200;
            const contentLen = Math.max(vm.contentLength, 25);

            vm.animationSpeed = Math.ceil(0.5 * (wrapperWidth + contentLen * 5) / speed) + 's';
        }

        // private methods
        function parseHTMFfromURL() {
            const xhr = new XMLHttpRequest();
            xhr.open('get', vm.model.source, true);
            xhr.onload = function() {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(xhr.response, 'text/xml');

                    recurseDomChildren(doc, true);
                    setAnimationSpeed(vm.model.speed);
                    $scope.$apply();
                }
            };

            xhr.send(null);
        }

        function recurseDomChildren(start, output) {
            if (start.childNodes) {
                const nodes = start.childNodes;
                loopNodeChildren(nodes, output);
            }
        }

        function loopNodeChildren(nodes, output) {
            let node;
            for (let i = 0; i < nodes.length; i++) {
                node = nodes[i];
                if (output && (['head', 'style', 'script'].indexOf(node.parentNode.nodeName) === -1)) {
                    if (node.nodeType === 1) {
                        processElementNode(node)
                    } else if (node.nodeType === 3) {
                        processTextNode(node);
                    }
                }
                if (node.parentNode.nodeName !== 'head' && node.childNodes && node.tagName !== 'STRONG' && node.tagName !== 'I' && node.tagName !== 'U') {
                    recurseDomChildren(node, output);
                }
            }
        }

        /**
         * Process element node
         * @param node
         */
        function processElementNode(node) {
            if (node.tagName.toLowerCase() === 'img') {
                processImageNode(node);

            } else if ((node.tagName.toLowerCase() === 'strong' || node.tagName.toLowerCase() === 'i' || node.tagName.toLowerCase() === 'u') && node.innerHTML.length) {
                vm.feeds.push({type: 'text', src: $sce.trustAsHtml(node.innerHTML)});
                vm.contentLength += node.innerHTML.length;
            }
        }

        /**
         * Process image node
         * @param node
         */
        function processImageNode(node) {
            if (node.src) {
                vm.feeds.push({type: 'img', src: node.src.replace(/http:\/\//, 'https://')});
            } else {
                let src = '';
                Array.from(node.attributes).forEach(function(attr) {
                    if (attr.name.toLowerCase() === 'src') {
                        src = attr.nodeValue || attr.value;
                    }
                });
                vm.feeds.push({type: 'img', src: src.replace(/http:\/\//, 'https://')});
            }
        }

        /**
         * Process text node
         * @param node
         */
        function processTextNode(node) {
            const whitespace = /^\s+$/g;
            // clear whitespace text nodes
            node.data = node.data.replace(whitespace, '');
            if (node.data) {
                vm.feeds.push({type: 'text', src: node.data});
                vm.contentLength += node.data.length;
            }
        }

        function parseStaticHTML() {
            vm.contentLength = 0;
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(vm.model.source, 'text/html');
            let counter = 0;
            let hasContent = false;

            Array.from(htmlDoc.getElementsByTagName('p')).forEach(function(item) {
                if (!Array.from(item.childNodes).length) {
                    return;
                }
                if (!vm.feeds[counter]) {
                    vm.feeds[counter] = [];
                }

                hasContent = false;

                Array.from(item.childNodes).forEach(function(node) {
                    if (node.nodeName && node.nodeName === 'IMG') {
                        vm.feeds[counter].push({type: 'img', src: node.src.replace(/http:\/\//, 'https://')});
                        hasContent = true;
                    } else {
                        if (node.nodeName === '#text' && node.nodeValue.length) {
                            vm.feeds[counter].push({type: 'text', src: $sce.trustAsHtml(node.nodeValue)});
                            vm.contentLength += node.nodeValue.length;
                            hasContent = true;
                        } else if (node.innerHTML && node.innerHTML.length) {
                            vm.feeds[counter].push({type: 'text', src: $sce.trustAsHtml(node.innerHTML)});
                            vm.contentLength += node.innerHTML.length;
                            hasContent = true;
                        }
                    }
                });
                if (hasContent) {
                    counter++;
                } else {
                    vm.feeds.splice(counter, 1);
                }
            });
            setAnimationSpeed(vm.model.speed);
        }

        function parseRss() {
            vm.contentLength = 0;
            ContentDataService.getRSS({'url': vm.model.source}).then(response => {
                vm.feeds = response;
                vm.feeds.forEach(function(item) {
                    vm.contentLength += item.title.length;
                });
                setAnimationSpeed(vm.model.speed);
            });
        }

        /**
         * Event handlers
         */
        angular.element($window).bind('resize', () => {
            setAnimationSpeed(vm.model.speed);
            $scope.$digest();
        });

        /**
         * Watchers
         */
        $scope.$watch(() => vm.model.speed, setAnimationSpeed);

        $scope.$watch(() => vm.model.fixed, newValue => {
            if(newValue) {
                vm.activeTickerEl = 0;
            }
        });
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('digitalDisplayHeader', {
            templateUrl: '/assets/views/content/elements/headers/digital-display/header.tpl.html',
            controller: DigitalDisplayHeader,
            bindings: {
                langArray: '<',
                contentForm: '<',
                contentData: '<',
                contentGroupsArray: '<',
                generatePreviewCallback: '&'
            }
        });

    function DigitalDisplayHeader(
        $scope,
        $rootScope,
        $timeout,
        PermissionsService,
        DIGITAL_DISPLAY_HEADERS,
        ContentDataService,
        CONTENT_TYPES,
    ) {
        const vm = this;

        /**
         * Exports
         */
        vm.headerTypes = DIGITAL_DISPLAY_HEADERS;
        vm.onLanguageChange = onLanguageChange;
        vm.isContentGroupSelectBoxVisible = PermissionsService.isContentGroupMenuItemVisible;

        vm.headerFooters = [];

        init();
        function init() {
            setContents();
            updateModel();

            if (!vm.contentData.data) {
                vm.contentData.data = {};
            }

            /**
             * @type {DigitalDisplayOptions}
             */
            vm.data = vm.contentData.data;

            if (angular.isUndefined(vm.data.departureFreeContentId)) {
                vm.data.departureFreeContentId = null;
            }
            setWatchers();
        }

        function setContents() {
            ContentDataService.contents({
                contentTypeId: [
                    CONTENT_TYPES.DIGITAL_DISPLAY,
                    CONTENT_TYPES.HEADER_FOOTER,
                ].join(',')
            }).then(response => {
                vm.contents = response.plain()
                    .filter(item => item.content_type_id === CONTENT_TYPES.DIGITAL_DISPLAY);
                vm.headerFooters = response.plain()
                    .filter(item => item.content_type_id === CONTENT_TYPES.HEADER_FOOTER);
            }).catch(console.error.bind(console));
        }

        function updateModel() {
            //Saves link to the old header object (if exist).
            var header = vm.model ? vm.model.header : null;

            vm.model = vm.contentData.message[vm.contentData.language_id];

            //Sets old header (if exist) object to the new model.
            if (header) {
                vm.model.header = header;
            }
        }

        /**
         * Language change handler
         * @param {string} oldValue
         */
        function onLanguageChange(oldValue) {
            vm.contentData.title = vm.contentData.title || {};

            if (!vm.contentData.title[oldValue]) {
                delete vm.contentData.title[oldValue];
            }

            updateModel();

            $timeout(function() {
                $rootScope.$broadcast('language-change', {
                    oldValue: oldValue,
                    newValue: vm.contentData.language_id
                });
            }, 0);
        }

        function setWatchers() {
            $scope.$watch(
                () => vm.data.departureFreeContentId,
                value => {
                    if (!value) {
                        vm.data.departureFreeThreshold = null;
                    }
                }
            )
        }

    }
})();

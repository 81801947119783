(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('BillingLayoutPreviewPopupController', BillingLayoutPreviewPopupController);

    function BillingLayoutPreviewPopupController(data) {
        const vm = this;

        vm.url = `${location.origin}/sharePark/billing-configuration/templates/preview/${data.layoutFilename}`;
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('datetimeRangePicker', {
            controller: DatetimeRangePickerController,
            templateUrl: '/assets/views/common/components/datetime-range-picker/datetime-range-picker.tpl.html',
            bindings: {
                form: '<',
                dateFormat: '@',
                startDate: '=',
                endDate: '=',
                startName: '@',
                endName: '@',
                startOnly: '<',
            }
    });

    function DatetimeRangePickerController(
        $scope,
    ) {
        const $this = this;

        $this.isError = false;

        if (!$this.endDate || moment.isMoment($this.endDate) && !$this.endDate.isValid()) {
            $this.endDate = $this.startDate;
        }

        function validate() {
            if (!$this.form[$this.startName]) { return; }

            if ($this.startOnly) {
                $this.isError = false;
                $this.form[$this.startName].$setValidity('range', true);
                return;
            }

            if (!moment.isMoment($this.startDate)) {
                $this.startDate = moment($this.startDate);
            }

            if (!moment.isMoment($this.endDate)) {
                $this.endDate = moment($this.endDate);
            }

            $this.isError = !($this.endDate.isSame($this.startDate) || $this.endDate.isAfter($this.startDate));
            $this.form[$this.startName].$setValidity('range', !$this.isError);
        }

        $scope.$watchGroup([
            () => $this.startDate,
            () => $this.endDate,
            () => $this.startOnly,
        ], validate);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentManagementController', ContentManagementController);

    function ContentManagementController(
        $state,
        $q,
        $stateParams,
        LocationDataService,
        LanguageService,
        ContentDataService,
        PermissionsService,
        CONTENT_TYPES,
        ACTIVATION_STATUS,
    ) {
        const vm = this;

        // public methods
        vm.getDefaultTitle = getDefaultTitle;
        vm.filterContentCallback = filterContentCallback;
        vm.isContentGroupSelectBoxVisible = PermissionsService.isContentGroupMenuItemVisible;

        // public properties
        vm.FILTER_ALL = -1;
        vm.content = [];
        vm.totalItems = $stateParams.paginationData ? $stateParams.paginationData.count : 0;
        vm.langArray = [];
        vm.contentGroupsArray = [];
        vm.contentGroupId = vm.FILTER_ALL;
        vm.contentTypeId = CONTENT_TYPES.DIGITAL_DISPLAY;
        vm.updateCounter = 0;

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'contentTitle',
                    width: '35',
                    title: (item) => {
                        return item.title[item.currentLanguage];
                    }
                },
                {
                    name: 'ICON',
                    class: 'contentIcon no-padding',
                    width: '20',
                    translate: item => {
                        let group = vm.contentGroupsArray.filter(group =>
                            group.icon_id.toString() === item.content_group.icon_id.toString())[0];
                        return !group || !group.icon;
                    },
                    title: item => {
                        let group = vm.contentGroupsArray.filter(group =>
                            group.icon_id.toString() === item.content_group.icon_id.toString())[0];

                        return group && group.icon ? '<img src="' + group.icon.iconUrl + '">' : 'ICON_NOT_AVAILABLE';
                    }
                },
                {
                    name: 'TYPE',
                    class: 'contentType',
                    width: '20',
                    translate: true,
                    title: (item) => {
                        return item.content_type.name;
                    }
                },
                {
                    name: 'PUBLISHED',
                    class: 'contentPublishedCount',
                    width: '20',
                    title: (item) => {
                        if (!item.sections || !item.sections.length) {
                            return '';
                        }

                        if (item.sections[0].id === 0) {
                            return 'D';
                        }

                        return String(item.sections.length);
                    }
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'linkBtn',
                        callback: editContent,
                        isVisible: PermissionsService.isContentManagementLinkBtnVisible
                    }
                ]
            },
            updateCallback: updateContent
        };

        /**
         * Updates list of contents
         */
        function updateContent(page, itemsPerPage) {
            $q.all([
                LanguageService.getLanguages(),
                ContentDataService.contents({
                    page: page,
                    itemsPerPage: itemsPerPage,
                    contentGroupId: vm.contentGroupId,
                    contentTypeId: vm.contentTypeId,
                    assignedSections: true
                }),
                LocationDataService.getScreens(),
                LocationDataService.getControllerDetails(),
            ]).then(data => {
                vm.langArray = data[0];
                vm.content = data[1] || [];
                vm.totalItems = data[1].count;
                const sections = data[2].list;
                const controllers = data[3].list;

                vm.content.forEach(item => {
                    const allLanguageIDs = vm.langArray.map(lang => parseInt(lang.id));
                    let prop;

                    item.title = JSON.parse(item.title);
                    item.message = JSON.parse(item.message);
                    item.content_group.title = JSON.parse(item.content_group.title);

                    if (item.language_sensitive_audio && parseInt(item.language_sensitive_audio)) {
                        item.audio_src = JSON.parse(item.audio_src);
                    }

                    if (item.language_sensitive_link && parseInt(item.language_sensitive_link)) {
                        item.web_link = JSON.parse(item.web_link);
                    }

                    for (prop in item.title) {
                        if (item.title.hasOwnProperty(prop) && allLanguageIDs.indexOf(parseInt(prop)) === -1) {
                            delete item.title[prop];
                            delete item.message[prop];
                        }
                    }

                    const languageIDs = Object.keys(item.title);
                    if (allLanguageIDs.indexOf(item.currentLanguage) === -1) {
                        item.language_id = languageIDs[0];
                    }
                    item.currentLanguage = parseInt(item.language_id);

                    item.allLanguages = vm.langArray;
                    item.languages = vm.langArray.filter(function(language) {
                        return languageIDs.indexOf(language.id.toString()) !== -1;
                    });

                    item.map_info = item.map_info && JSON.parse(item.map_info);
                });

                vm.sections = sections.map(screen => {
                    screen.details = controllers.find(controller => screen.controllerRef === controller.id);
                    return screen;
                }).filter(screen => {
                    const validStatuses = [ACTIVATION_STATUS.ACTIVE.value, ACTIVATION_STATUS.INACTIVE.value];
                    const status = screen.details ? screen.details.status : null;
                    return validStatuses.includes(status);
                }).sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });

                vm.sectionGroups =
                    _.chain(vm.sections)
                        .map('groups')
                        .flatten()
                        .uniqBy('id')
                        .sortBy('name')
                        .value();

                vm.content.forEach(item => {
                    item.sections = (item.locations || [])
                        .map(id => {
                            if (id === '0') {
                                return { id: 0, name: 'Default' };
                            }

                            return vm.sections.find(section => section.controllerRef === Number(id));
                        })
                        .filter(section => section);
                });
            });
        }

        /**
         * Edit content
         *
         * @param {MouseEvent} $event
         * @param {Object} contentItem
         */
        function editContent($event, contentItem) {
            $event.preventDefault();
            $event.stopPropagation();

            let page = parseInt(angular.element('.pagination-page.active a')[0].innerHTML);
            let itemsPerPage = parseInt(angular.element('.paginationControls select').val().replace('number:', ''));

            $state.go('app.editContentManagement', {
                data: contentItem,
                sections: vm.sections,
                sectionGroups: vm.sectionGroups,
                paginationData: {
                    page: page,
                    itemsPerPage: itemsPerPage,
                    count: vm.totalItems
                }
            });
        }

        /**
         * Filter content callback
         */
        function filterContentCallback() {
            vm.updateCounter++;
        }

        /**
         * Returns default translation
         *
         * @param {object} item
         * @returns {string}
         */
        function getDefaultTitle(item) {
            var keys = Object.keys(item),
                title = '',
                language;

            if (keys.length) {
                language = vm.langArray.filter(function(language) {
                    return language.id.toString() === keys[0];
                })[0];

                title = language ? (item[keys[0]] + ' [' + language.code + ']') : '';
            }

            return title;
        }

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;

            if (PermissionsService.isContentGroupMenuItemVisible()) {
                ContentDataService.contentGroups()
                    .then((response) => {
                        vm.contentGroupsArray = response.sort((a, b) => a.title.localeCompare(b.title));

                        vm.contentGroupsArray.forEach((item) => {
                            item.title = angular.fromJson(item.title);
                        });
                    });
            }
        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('lineRoute')
        .constant('TRANSPORT_NAME', {
            1: 'LONG_DISTANCE_RAIL',
            2: 'SUBURBAN',
            4: 'UNDERGROUND',
            16: 'TRAM',
            32: 'EXPRESS_BUS',
            64: 'REGIONAL_BUS',
            128: 'CITY_BUS',
            256: 'CABLE_CAR_RACK_RAILWAY',
            512: 'FERRY',
            1024: 'DRT_CALL_BUS',
            2048: 'OTHER',
        })
        .constant('TRANSPORT_TYPE', {
            SUBURBAN: {
                label: 'Suburban',
                translationLabel: 'SUBURBAN',
                code: 'SUBURBAN',
                catCodes: [0],
                mctId: 1,
            },
            UNDERGROUND: {
                label: 'Underground',
                translationLabel: 'UNDERGROUND',
                code: 'UNDERGROUND',
                catCodes: [1, 6],
                mctId: 2,
            },
            TRAM: {
                label: 'Tram',
                translationLabel: 'TRAM',
                code: 'TRAM',
                catCodes: [2],
                mctId: 4,
            },
            BUS: {
                label: 'Bus',
                translationLabel: 'BUS',
                code: 'BUS',
                catCodes: [3, 7, 8, 11],
                mctId: 8,
            },
            FERRY: {
                label: 'Ferry',
                translationLabel: 'FERRY',
                code: 'FERRY',
                catCodes: [],
                mctId: 16,
            },
            LIGHT_RAIL: {
                label: 'Light Rail',
                translationLabel: 'LIGHT_RAIL',
                code: 'LIGHT_RAIL',
                catCodes: [4, 5],
            },
            LONG_DISTANCE_RAIL: {
                label: 'Long Distance Rail',
                translationLabel: 'LONG_DISTANCE_RAIL',
                code: 'LONG_DISTANCE_RAIL',
                mctId: 32,
            },
            REGIONAL_RAIL: {
                label: 'Regional Rail',
                translationLabel: 'REGIONAL_RAIL',
                code: 'REGIONAL_RAIL',
                mctId: 64,
            },
        })
        .constant('LINE_ROUTE_ORIENTATIONS', {
            VERTICAL: 'vertical',
            HORIZONTAL: 'horizontal'
        })
        .constant('TRANSPORT_ICONS', {
            DEFAULT: '',
            CONTRAST_DARK: '_contrast_dark',
        })
        .constant('LAYOUTS', {
            CANVAS: 1,
            HTML_DARK: 2,
            HTML_LIGHT: 3,
        })
        .constant('LINE_ROUTE_FIT_OPTIONS', [
            {
                id: 1,
                name: 'ANIMATION',
            },
            {
                id: 2,
                name: 'SCALING'
            },
        ])
        .constant('TRACK_FILTERS_SPECIAL_VALUES', {
            ALL: {
                label: 'ALL',
                value: -2,
            },
            ALL_WITHOUT_TRACK: {
                label: 'ALL_WITHOUT_TRACK',
                value: -1,
            }
        })
})();

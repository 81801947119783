(function() {
    'use strict';

    angular.module('beacon.app')
        .service('CampaignHelper', CampaignHelperService);

    function CampaignHelperService() {

        return {
            isCampaignRelatedToContent,
            isCampaignDefault,
        };

        /**
         * Checks if campaign is related with content
         * @param campaign
         * @param contentId
         * @return {*}
         */
        function isCampaignRelatedToContent(campaign, contentId) {
            return campaign.contents.some(content => content.id === contentId);
        }

        /**
         * Check if campaign is default by finish date
         *
         * @param {object} finishDate
         */
        function isCampaignDefault(finishDate) {
            const defaultFinishYear = 1980;
            return finishDate.year() === defaultFinishYear;
        }
    }
})();
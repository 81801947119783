(function() {
    'use strict';

    angular.module('beacon.app')
        .component('translationToolTable', {
            templateUrl: '/assets/views/translation-tool/translation-tool-table/translation-tool-table.tpl.html',
            controller: TranslationToolTableController,
            bindings: {
                fields: '<',
                changedCallback: '<',
            }
        });

    function TranslationToolTableController(

    ) {
        const vm = this;

        vm.$onInit = init;
        vm.onInputChange = onInputChange;
        vm.onChildrenChange = onChildrenChange;

        function init() {

        }

        function onInputChange(field) {
            if (vm.changedCallback) {
                vm.changedCallback(field);
            }
        }

        function onChildrenChange(field) {
            console.log('child changed', field);
        }
    }
}());

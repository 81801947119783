(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userGroupSmartCampusTab', {
            templateUrl: '/assets/views/user-groups/new/tabs/user-group-smart-campus/user-group-smart-campus.tpl.html',
            controller: UserGroupSmartCampusController,
            bindings: {
                userGroupData: '<',
            }
        });

    function UserGroupSmartCampusController(
        StatesFactory,
        SmartCampusApiService,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.campuses = [];

        vm.state = StatesFactory.UserGroupStates;
        vm.state.canNext = true;
        vm.state.canBack = true;

        vm.campusesListConfig = {
            header: true,
            columns: [
                {
                    name: 'NAME',
                    class: 'name',
                    width: '25',
                    title:  item => item.name
                },
                {
                    name: 'LOCATION',
                    width: '25',
                    title: item => item.location
                },
                {
                    name: 'LOCATIONS',
                    width: '25',
                    title: item => item.locations.length
                },
                {
                    name: 'LOBBIES',
                    width: '25',
                    title: item => item.lobbies.length
                },
            ],
        }

        function init() {
            vm.userGroupData.selectedCampuses = [];

            SmartCampusApiService.getCampuses()
                .then(response => {
                    vm.campuses = response.sort((a, b) => a.name.localeCompare(b.name));
                    vm.userGroupData.selectedCampuses = vm.campuses
                        .filter(
                            campus => vm.userGroupData.allowedCampuses
                                .find(allowedCampusId => allowedCampusId === campus.id)
                        );
                });
        }
    }
})();

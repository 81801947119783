angular.module('beacon.app')
    .component('tiersPicker', {
        templateUrl: '/assets/views/loyalty/tiers/tier-groups/form/tiers-picker/tiers-picker.tpl.html',
        controller: tiersPickerController,
        bindings: {
            data: '='
        }
    });

    function tiersPickerController($scope, TierService) {
        const vm = this;

        vm.select = select;
        vm.isChecked = isChecked;

        _init();

        /**
         * Toggle tier checkbox
         *
         * @param tier
         */
        function select(tier) {
            vm.data.default_tier_id = null;
            const tierKey = vm.data.tiers.findIndex(item => {
                return tier.id === item.id;
            });

            if (typeof vm.data.tiers[tierKey] !== 'undefined') {
                vm.data.tiers.splice(tierKey, 1);
            } else {
                vm.data.tiers.push(tier);
            }
        }

        /**
         * Checking if tier is in current group
         *
         * @param {number} tierId
         * @returns {*}
         */
        function isChecked(tierId) {
            return !!vm.data.tiers.length && vm.data.tiers.find(item => {
                return item.id === tierId;
            });
        }

        /**
         * Checking if tier is in other group
         *
         * @param {object} tier
         * @returns {boolean}
         */
        function _isInOtherGroup(tier) {
            return tier.tier_groups.find(tierGroup => {
                return tierGroup.id !== vm.data.id;
            });
        }

        /**
         * Controller initialization
         *
         * @private
         */
        function _init() {
            TierService.getList()
                .then(response => {
                    const tiers = angular.copy(response.plain());
                    vm.tiers = tiers.filter(item => {
                        return !_isInOtherGroup(item);
                    })
                })
                .catch(console.error.bind(console));
        }
    }
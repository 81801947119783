(function() {
    'use strict';

    angular.module('beacon.app')
        .component('digitalDisplayContentDetailsTab', {
            templateUrl: '/assets/views/content/elements/types/digital-display/tabs/content-details/content-details.tpl.html',
            controller: DigitalDisplayContentDetailsTab,
            bindings: {
                langArray: '<',
                contentForm: '<',
                contentData: '<',
                contentGroupsArray: '<',
                generatePreviewCallback: '&'
            }
        });

    function DigitalDisplayContentDetailsTab($scope,
                                             $rootScope,
                                             $timeout,
                                             $window,
                                             ContentDataService,
                                             UtilitiesService,
                                             PopupService,
                                             StatesFactory,
                                             StorageFactory,
                                             ModelsFactory,
                                             VIEW_TYPES,
                                             PAGE_TYPES) {
        const vm = this;
        const { checkContentType } = UtilitiesService;

        vm.state = StatesFactory.DigitalDisplayStates;

        // Exports
        vm.addPage = addPage;
        vm.checkContentType = checkContentType;
        vm.toggleSpeedDialBuilder = toggleSpeedDialBuilder;
        vm.toggleGlobalPageSettings = toggleGlobalPageSettings;

        vm.$onInit = onInit;

        /**
         * Init
         */
        function onInit() {
            vm.STORAGE = StorageFactory.Storage('Content-DigitalDisplay');
            vm.model = vm.contentData.message[vm.contentData.language_id];

            // Update state
            vm.state.canNext = false;
        }

        // Pages
        /**
         * Add page
         */
        function addPage() {
            // Remove previus pageData model before create new
            vm.STORAGE.remove('pageData');

            // Update state
            vm.state.canBack = true;
            vm.state.page = {
                type: 'new'
            };
            vm.state.go('SELECT_PAGE_TYPE', true);
        }

        /**
         * Edit Page
         * @param event
         * @param option
         */
        function editPage(event, option) {
            const pageData = new ModelsFactory.DigitalDisplayPage(vm.model.pages[option.index]);
            vm.pageData = vm.STORAGE.set('pageData', angular.copy(pageData));

            vm.state.page = {
                type: 'edit',
                index: option.index
            };
            vm.state.pageEditCallback = updateSplitScreensItemTitle;
            vm.state.go('EDIT_PAGE', true);
        }

        /**
         * Update split screen items title
         * @param page
         */
        function updateSplitScreensItemTitle(page) {
            if (page.page_type_id === PAGE_TYPES.SPLIT_SCREEN) {
                return;
            }

            const model = vm.contentData.message[vm.contentData.language_id];
            const used = pageUsedInSplitScreens(page.id, model);

            used.forEach(item => {
                model.pages.forEach(_page => {
                    if (_page.id === item.pageId) {
                        _page.page[item.layoutType].items[item.key].title = page.header.title;
                    }
                })
            });

        }

        /**
         * Is page used in split screens
         * @param id
         * @param model
         * @returns {Array}
         */
        function pageUsedInSplitScreens(id, model) {
            const splitScreenPages = model.pages.filter(page => page.page_type_id === 10);
            const used = [];
            const layoutTypes = Object.keys(VIEW_TYPES).map(key => VIEW_TYPES[key]);

            splitScreenPages.forEach(page => {
                layoutTypes.forEach(layoutType => {
                    const items = page.page[layoutType].items;

                    Object.keys(items).forEach(key => {
                        if (items[key] && items[key].type === 'page' && items[key].id === id) {
                           used.push({pageId: page.id, layoutType, key});
                        }
                    });
                });
            });

            return used;
        }

        /**
         * Delete page
         * @param event
         * @param option
         */
        function deletePage(event, option) {
            const page = vm.model.pages[option.index];

            for (let lang of vm.langArray) {
                const model = vm.contentData.message[lang.id];
                const pageIndex = model.pages.findIndex((_page) => page.id === _page.id);
                const speedDialPageIndex = model.speedDial.selectedPages.indexOf(page.id);

                if (page.page_type_id !== 10) {
                    const used = pageUsedInSplitScreens(page.id, model);

                    used.forEach(item => {
                        model.pages.forEach(page => {
                            if (page.id === item.pageId) {
                                delete page.page[item.layoutType].items[item.key];
                            }
                        })
                    });
                }

                if (pageIndex !== -1) {
                    model.pages.splice(pageIndex, 1);

                    if (speedDialPageIndex !== -1) {
                        model.speedDial.selectedPages.splice(speedDialPageIndex, 1);
                    }
                }
            }
        }



        function toggleSpeedDialBuilder() {
            PopupService.show({
                templateUrl: '/assets/views/content/elements/types/digital-display/builders/speed-dial/speed-dial.tpl.html',
                controller: 'SpeedDailBuilderController',
                controllerAs: '$ctrl',
                windowClass: 'speedDailPopup',
                keyboard: false,
                resolve: {
                    data: () => ({
                        pages: vm.model.pages,
                        speedDial: vm.model.speedDial
                    })
                }
            }).then((result) => {
                // set same 'access' and 'language' properties for different languages
                vm.langArray.forEach((item) => {
                    vm.contentData.message[item.id].speedDial = result.speedDial;
                });
            }).catch(() => { /* None */ });
        }

        function toggleGlobalPageSettings() {
            PopupService.show({
                templateUrl: '/assets/views/content/elements/types/digital-display/builders/global-page-settings/global-page-settings.tpl.html',
                controller: 'GlobalPageSettingsController',
                controllerAs: '$ctrl',
                windowClass: 'speedDailPopup',
                keyboard: false,
                resolve: {
                    data: () => ({
                        globalPagesSettings: vm.model.globalPagesSettings
                    })
                }
            }).then((result) => {
                vm.model.globalPagesSettings = result.data.globalPagesSettings;
            }).catch(() => { /* None */ });
        }

        // Handlers
        function onLanguageChange(event, option) {
            let { oldValue, newValue } = option;

            vm.model = vm.contentData.message[newValue];
        }

        //  Listeners
        $scope.$on('language-change', onLanguageChange);
        $scope.$on('page-edit', editPage);
        $scope.$on('page-delete', deletePage);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkDataPreview', {
            templateUrl: '/assets/views/share-park/car-parks/new/car-park-data-preview/car-park-data-preview.tpl.html',
            controller: CarParkDataPreviewController,
            bindings: {
                carParkData: '<',
                layoutType: '<',
                smallPreview: '<',
            }
        });

    function CarParkDataPreviewController(
        $scope,
        PopupService,
        UtilitiesService,
        LocationHelper,
    ) {
        const vm = this;
        vm.$onInit = init;

        const { getCoordinates } = UtilitiesService;

        vm.markers = [];

        vm.showImagePopup = showImagePopup;
        vm.style = style;
        vm.defaultName = defaultName;

        function init() {
            updateShapes();
            updateMarkers();
        }

        function style(backgroundImage) {
            return backgroundImage
                ? {'background-image': `url("${backgroundImage}")`}
                : null;
        }

        function defaultName(name) {
            const defaultLangId = Object.keys(name)[0];
            return name[defaultLangId];
        }

        function updateShapes() {
            const shapes = [vm.carParkData.area_polygon];
            vm.carParkData.sub_areas.forEach(subArea => {
                const coloredArea = LocationHelper.coloredArea(subArea.location, subArea.color);
                shapes.push(coloredArea);
            });
            vm.shapes = shapes.filter(shape => shape).join('|');
        }

        function updateMarkers() {
            vm.markers = [];
            const carParkCoords = getCoordinates(vm.carParkData.latlng);

            if (carParkCoords) {
                vm.markers.push({
                    position: {
                        lat: carParkCoords.latitude,
                        lng: carParkCoords.longitude,
                    },
                    selected: true,
                    label: vm.carParkData.name
                });
            }

            vm.carParkData.car_park_gateways.forEach(gateway => {
                const coords = getCoordinates(gateway.latlng);

                if (!coords) {
                    return;
                }

                vm.markers.push({
                    position: {
                        lat: coords.latitude,
                        lng: coords.longitude,
                    },
                    label: gateway.gateway_name
                });
            })
        }

        /**
         * Shows image preview popup
         */
        function showImagePopup(imageSrc) {
            PopupService.show({
                templateUrl: '/assets/views/common/popups/image-preview/image-preview.tpl.html',
                controller: 'ImagePreviewPopupController',
                controllerAs: 'imagePreviewPopupController',
                windowClass: 'imagePreviewPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return {
                            okButtonText: 'OK',
                            imageUrl: imageSrc
                        };
                    }
                }
            });
        }

        $scope.$watch(
            () => vm.carParkData.car_park_gateways.map(gateway => ({
                name: gateway.gateway_name,
                latlng: gateway.latlng
            })),
            updateMarkers,
            true
        )
    }
})();
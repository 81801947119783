(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ChooseTenantController', ChooseTenantController);

    function ChooseTenantController(LoginService, $state, $auth) {
        const vm = this;

        let userId = $state.params.data.userId;

        //  public properties
        vm.tenants = $state.params.data.tenants;
        vm.onSubdomainClick = onSubdomainClick;

        /**
         * Subdomain click handler. Tries to login in a subdomain
         * @param subdomainId
         */
        function onSubdomainClick(subdomainId) {
            LoginService.authorize(userId, subdomainId, function() {
                LoginService.refreshToken().then(response => {
                    if (response.token) {
                        $auth.setToken(response.token);
                        $state.go('app.content', {});
                    } else {
                        alert('Internal server error');
                    }
                });

            });
        }
    }
}());

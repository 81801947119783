(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .constant('STATION_TYPES', [
            {
                name: 'PRIMARY',
                value: 1,
            },
            {
                name: 'SECONDARY',
                value: 2,
            },
            {
                name: 'TERTIARY',
                value: 3,
            },
            {
                name: 'QUATERNARY',
                value: 4,
            },
        ]);
}());

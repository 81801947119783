(function() {
    'use strict';

    angular.module('beacon.app')
        .component('segmentationGroupSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/segmentation-group/segmentation-group.tpl.html',
            controller: SegmentationGroupController,
            bindings: {
                segmentData: '=',
            }
        });

    function SegmentationGroupController(
        $scope,
        segmentAddState,
        StorageFactory,
        SegmentHelper,
        SEGMENT_STATUS,
    ) {
        const vm = this;

        // public methods
        vm.changeSegmentStatus = SegmentHelper.changeSegmentStatus;

        // Definition
        vm.state = segmentAddState;

        init();

        // private methods

        /**
         * Initialization method
         */
        function init() {
            vm.STORAGE = new StorageFactory.Storage('SegmentationGroup', true);
            if (!vm.segmentData.message.segmentationData) {
                vm.segmentData.message.segmentationData = {};
            }
            vm.activateSegment = vm.segmentData.status === SEGMENT_STATUS.ACTIVE;
        }

        function onFinish(event, option) {
            if (vm.segmentData.status === SEGMENT_STATUS.ACTIVE) {
                vm.segmentData.message.wasActivated = true;
            }
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: angular.copy(vm.segmentData)
                });
            }
        }

        function canFinish() {
            return !!vm.segmentData.title
                && !!vm.segmentData.description
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.title;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.description;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
    }
})();
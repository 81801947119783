(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('VoucherFormToolbarController', VoucherFormToolbarController);

    function VoucherFormToolbarController($scope, StatesFactory, $state, $stateParams) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.VoucherStates);
        vm.state = vm.stateAss.state;

        vm.state.finish = false;
        vm.state.save = false;

        vm.cancel = cancel;
        vm.finish = finish;
        vm.save = save;

        vm.showSave = $stateParams.type === 'new';

        /**
         * Cancel button handler
         */
        function cancel() {
            $state.go('app.loyaltyVouchersList');
        }

        /**
         * Finish button handler
         */
        function finish() {
            vm.state.finish = true;
        }

        /**
         * Finish button handler
         */
        function save() {
            vm.state.save = true;
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('locationRelatedCampaignsTab', {
            templateUrl: '/assets/views/location/tabs/related-campaigns-tab/related-campaigns-tab.tpl.html',
            controller: LocationRelatedCampaignsTab,
            bindings: {
                selectedLocations: '<',
                selectedSegment: '<',
                selectedGeofences: '<',
                selectedLocationName: '<'
            }
        });

    function LocationRelatedCampaignsTab(
        CampaignDataService,
        SegmentDataService,
        PopupService,
        SEGMENT_STATUS,
        SCREEN_CAMP_SORTING_PARAMS,
    ) {
        const vm = this;

        vm.updateRelatedCampaigns = updateRelatedCampaigns;
        vm.deleteCampaign = deleteCampaign;
        vm.onOrderChange = onOrderChange;


        vm.campaigns = [];
        vm.totalCampaignItems = 0;
        vm.totalSegmentItems = 0;
        vm.SORTING_PARAMS = Object.values(SCREEN_CAMP_SORTING_PARAMS);
        vm.orderBy = SCREEN_CAMP_SORTING_PARAMS.PRIORITY.field;
        vm.reverseOrder = false;
        vm.updateCounter = 0;

        vm.campaignListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '45',
                    title: 'name'
                },
                {
                    name: 'START_DATE',
                    class: 'startDate',
                    width: '20',
                    title: function(item) {
                        return moment(item.started_at).format('LL');
                    }
                },
                {
                    name: 'END_DATE',
                    class: 'endDate',
                    width: '20',
                    title: function(item) {
                        return moment(item.started_at)
                            .isAfter(item.finished_at) ? '-' : moment(item.finished_at).format('LL');
                    }
                }
            ],
            buttons: {
                width: '15',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: vm.deleteCampaign
                    }
                ]
            },
            updateCallback: updateRelatedCampaigns
        };

        vm.segmentListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '85',
                    title: 'title'
                }
            ],
            buttons: {
                width: '15',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteSegment
                    }
                ]
            },
            updateCallback: updateSegment,
        };


        /**
         * Updates list of releted campaigns
         */
        function updateRelatedCampaigns(page, itemsPerPage) {
            let requestParams = {
                page,
                itemsPerPage,
            };

            if (vm.selectedSegment && vm.selectedGeofences) {
                requestParams.segmentId = vm.selectedSegment.id;
                requestParams["geofence[]"] = vm.selectedGeofences;
            }
            if (vm.reverseOrder) {
                requestParams.reverseOrder = vm.reverseOrder
            }
            if (vm.orderBy === SCREEN_CAMP_SORTING_PARAMS.PRIORITY.field) {
                requestParams.sortByPriority = true;
            } else {
                requestParams.orderBy = vm.orderBy;
            }

            if (vm.selectedLocations.length) {
                requestParams.locationId = JSON.stringify(vm.selectedLocations);

                CampaignDataService.getCampaigns(requestParams).then(function (response) {
                    vm.campaigns = response;
                    vm.totalCampaignItems = response.count;
                    vm.campaigns.forEach(function (item) {
                        item.contents.forEach(function (content) {
                            content.title = JSON.parse(content.title);
                            content.message = JSON.parse(content.message);
                            content.data = JSON.parse(content.data);
                        });
                    });
                });
            }
        }

        /**
         * Removes campaign
         *
         * @param {MouseEvent} $event
         * @param {object} campaignItem
         */
        function deleteCampaign($event, campaignItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CAMPAIGN',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                CampaignDataService.deleteCampaign(campaignItem.id).then(function(response) {
                    vm.totalCampaignItems -= 1;
                });
            });
        }

        /**
         * Updates list of segments
         */
        function updateSegment(page, itemsPerPage) {
            let data = {
                page,
                itemsPerPage,
                "geofence[]": vm.selectedGeofences,
            };

            SegmentDataService.getSegments(data)
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    vm.segments = response;
                    vm.totalSegmentItems = response.count;
                })
                .catch(console.error.bind(console));
        }

        /**
         * Removes segment
         *
         * @param {MouseEvent} $event
         * @param {object} segmentItem
         */
        function deleteSegment($event, segmentItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_SEGMENT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                const data = segmentItem.plain();
                data.status = SEGMENT_STATUS.DELETED;
                const formData = new FormData();
                for (let prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        formData.append(prop, data[prop]);
                    }
                }
                formData.append('_method', 'PUT');
                SegmentDataService.updateSegment(segmentItem, formData).then(() => {
                    vm.totalSegmentItems -= 1;
                });
            });
        }

        function onOrderChange() {
            vm.updateCounter++;
        }
    }
}());

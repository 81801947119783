(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltyCampaignRewardTab', {
            templateUrl: '/assets/views/loyalty/campaigns/form/tabs/reward/reward.tpl.html',
            controller: loyaltyCampaignRewardTab
        });

    function loyaltyCampaignRewardTab(
        $scope,
        StatesFactory,
        StorageFactory,
        UtilitiesService,
        TierGroupService,
        TierService,
        VoucherService
    ) {
        const vm = this;

        const CONFIG_TYPE_REDEEM_INTERVALS  = 4;
        const CONFIG_TYPE_REDEEM_LIST       = 5;
        const CONFIG_TYPE_REDEEM_TYPES      = 6;
        const CONFIG_TYPE_TIER_EXPIRY_TYPE  = 9;

        vm.REWARD_TYPE_DISCOUNT = 1;
        vm.REWARD_TYPE_VOUCHER = 2;
        vm.REWARD_TYPE_TIER = 3;

        vm.state = StatesFactory.LoyaltyCampaignStates;
        vm.STORAGE = StorageFactory.Storage('LoyaltyCampaign');

        vm.getRedeemIntervalLabel = getRedeemIntervalLabel;
        vm.setRewardType = setRewardType;
        vm.setTierGroup = setTierGroup;
        vm.isValidField = isValidField;
        vm.updateTiersList = updateTiersList;

        init();

        // Public methods

        /**
         * Sets reward type info to data object
         *
         * @param rewardTypeValue
         */
        function setRewardType(rewardTypeValue) {
            const rewardType = _getRewardType(rewardTypeValue);
            vm.data.loyaltyRewardType.name             = rewardType.configTitle || '';
            vm.data.loyaltyRewardType.description      = rewardType.configText || '';
            vm.rewardUseCase                           = rewardType.useCase || null;

            vm.data.loyaltyRewardType.rewardTierGroup = null;
            vm.data.loyaltyRewardType.rewardCurrentTierType = null;
            vm.data.loyaltyRewardType.rewardTargetRef = null;
        }

        /**
         * Updates tiers list
         *
         * @param {number} tierGroupExternalId
         */
        function setTierGroup(tierGroupExternalId) {
            vm.data.loyaltyRewardType.rewardCurrentTierType = null;
            vm.data.loyaltyRewardType.rewardTargetRef = null;
            updateTiersList(tierGroupExternalId)
        }

        /**
         * Updates tiers list
         *
         * @param {number} tierGroupExternalId
         */
        function updateTiersList(tierGroupExternalId) {
            vm.tiers = vm.tiersAll.filter(item => {
                return item.tier_groups.find(item => {
                    return item.external_id === tierGroupExternalId;
                });
            });
        }

        /**
         * Returning redeem interval label for input
         * eg. "hour(s)" or "year(s)" etc.
         *
         * @param {number} redeemIntervalId
         * @returns {string}
         */
        function getRedeemIntervalLabel(redeemIntervalId) {
            if (!redeemIntervalId) {
                return false;
            }

            const param = vm.redeemIntervals.find(interval => {
                return +interval.parameters.value === redeemIntervalId;
            });

            return param && param.configText;
        }

        // Private methods

        /**
         * Controller initialization
         */
        function init() {
            vm.STORAGE.on('data', value => vm.data = value);
            vm.STORAGE.on('Languages', value => vm.langArray = value);

            loadDropdownLists();
            loadRedeemTypes();

            TierGroupService.getList()
                .then(response => {
                    vm.tierGroups = angular.copy(response.plain());
                })
                .catch(console.error.bind(console));

            TierService.getList()
                .then(response => {
                    vm.tiersAll = angular.copy(response.plain());

                    if (vm.data.loyaltyRewardType.rewardType === vm.REWARD_TYPE_TIER
                        && vm.data.loyaltyRewardType.rewardTargetRef) {
                        updateTiersList(vm.data.loyaltyRewardType.rewardTierGroup);
                    }
                })
                .catch(console.error.bind(console));

            VoucherService.getList()
                .then(response => {
                    vm.vouchers = angular.copy(response.plain());
                })
                .catch(console.error.bind(console));

            vm.state.canBack = true;
        }

        /**
         * Loads dropdown lists from storage to scope
         */
        function loadDropdownLists() {
            vm.STORAGE.on('LoyaltyCampaignParams', value => vm.paramsData = value);

            vm.redeemIntervals  = vm.paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_REDEEM_INTERVALS);
            vm.redeemList       = vm.paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_REDEEM_LIST);
            vm.redeemTypes      = vm.paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_REDEEM_TYPES);
            vm.tierExpiryTypes  = vm.paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_TIER_EXPIRY_TYPE);

            _setDefaultValue(vm.data.loyaltyAccountType, 'redeemPeriodType', vm.redeemIntervals);
        }

        /**
         * Setting the first config value if model property is empty
         *
         * @param {object} object
         * @param {string} property
         * @param {array} configList
         * @private
         */
        function _setDefaultValue(object, property, configList) {
            if (!object[property] && configList.length) {
                object[property] = configList[0].parameters.value;
            }
        }

        /**
         * Filter redeem types by useCase
         */
        function loadRedeemTypes() {
            const rewardTypeValue = vm.data.loyaltyRewardType.rewardType;
            if (rewardTypeValue) {
                const rewardType = _getRewardType(rewardTypeValue);
                vm.rewardUseCase = rewardType.useCase;
            }
        }

        /**
         * Field validation
         *
         * @param {string} fieldName
         * @returns {boolean}
         */
        function isValidField(fieldName) {
            return UtilitiesService.isValidField(vm.dataForm, fieldName);
        }

        /**
         * Getting rewardType from list by value
         *
         * @param {number} rewardTypeValue
         * @returns {object}
         * @private
         */
        function _getRewardType(rewardTypeValue) {
            return vm.redeemList.find(rewardType => {
                if (+rewardType.parameters.value === +rewardTypeValue) {
                    return rewardType;
                }
            });
        }

        /**
         * Validation watcher
         */
        $scope.$watch(
            () => !!vm.dataForm.$valid && !!vm.data.loyaltyRewardType.rewardType,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
})();

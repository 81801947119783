(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newCarPark', {
            templateUrl: '/assets/views/share-park/car-parks/new/new-car-park.tpl.html',
            controller: NewCarParkController
        });

    function NewCarParkController(
        $scope,
        $state,
        $stateParams,
        LanguageService,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
        UtilitiesService,
        DATETIME_FORMAT,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.state = StatesFactory.CarParkStates.refresh();
        vm.state.type = $stateParams.type;

        const { createCarPark, updateCarPark } = ShareParkDataService;
        const { generateGUID } = UtilitiesService;

        /**
         * Initialization method
         */
        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.carParkData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.CarPark();
        }

        function onFinish() {
            const formData = prepareFormData(angular.copy(vm.carParkData));

            if (vm.state.type === 'new') {
                createCarPark(formData)
                    .then(onSuccess);
            } else {
                updateCarPark(vm.carParkData, formData)
                    .then(onSuccess)
            }
        }

        function onSuccess() {
            $state.go('app.carParks', {
                paginationData: $stateParams.paginationData
            });
        }

        function prepareFormData(carParkData) {
            const formData = new FormData();
            const json_fields = [
                'description',
                'public_transport',
                'womens_parking',
                'barrier_free',
                'opening_hours',
            ];

            formData.append('carParkImage', carParkData.imageFile);

            json_fields.forEach(key => {
                carParkData[key] = JSON.stringify(carParkData[key]);
            });

            if (carParkData.opening_hour && carParkData.opening_hour.description) {
                carParkData.opening_hour.description = angular.toJson(carParkData.opening_hour.description);
            }

            carParkData.gateways = carParkData.car_park_gateways.length;
            carParkData.car_park_gateways.forEach(gateway => {
                delete gateway.isOpen;
                gateway.GUID = generateGUID();

                formData.append(`image[${gateway.GUID}]`, gateway.imageFile);

                gateway.entrances.forEach(entrance => {
                    delete entrance.isOpen;
                    entrance.GUID = generateGUID();

                    formData.append(`image[${entrance.GUID}]`, entrance.imageFile);

                    if (entrance.iotEntitiesMode) {
                        entrance.entrance_number = null;
                        entrance.remote_device_ref = null;
                    } else {
                        entrance.iot_entity_assignments = [];
                    }

                    const relatedDigitalIoExists = !!(entrance.relatedDigitalIoAssignment
                        && entrance.relatedDigitalIoAssignment.iot_entity_id);

                    entrance.iot_entity_assignments.forEach(assignment => {
                        delete assignment.isOpen;

                        if (relatedDigitalIoExists) {
                            assignment.channel = entrance.relatedDigitalIoAssignment.channel;
                        }
                    });

                    if (relatedDigitalIoExists) {
                        entrance.iot_entity_assignments.push(entrance.relatedDigitalIoAssignment);
                    }
                });
            });

            carParkData.rate_model_assignments = carParkData.rateModelAssignments.map(assignment => {
                const assignmentData = Object.assign(angular.copy(assignment), {
                    cap_rate_model_ref: assignment.rateModel.id,
                    start_date: assignment.start_date
                        ? moment(assignment.start_date).utc().format(DATETIME_FORMAT)
                        : null,
                    end_date: assignment.end_date
                        ? moment(assignment.end_date).utc().format(DATETIME_FORMAT)
                        : null,
                });

                delete assignmentData.rateModel;
                return assignmentData;
            });

            delete carParkData.rateModelAssignments;

            formData.append('carPark', angular.toJson(carParkData));
            return formData;
        }

        $scope.$watch(
            () => vm.state.finish,
            () => vm.state.canFinish && onFinish()
        );
    }
})();
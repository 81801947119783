(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofenceGroupsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/geofence-tab/geofence-groups-tab/geofence-groups-tab.tpl.html',
            controller: GeofenceGroupsTab,
            bindings: {
                onSelect: '=',
            }
        });

    function GeofenceGroupsTab(
        $state,
        ListPaginationService,
        GeofenceDataService,
        PopupService,
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.updateCounter = 0;
        vm.geofenceGroups = [];
        vm.geofenceGroupsListData = {
            columns: [
                {
                    name: 'Name',
                    class: 'locationTitle',
                    width: '100',
                    title: item => item.name
                }
            ],
            buttons: {
                width: '10',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteGeofenceGroup,
                    },
                    {
                        class: 'editBtn',
                        callback: updateGeofenceGroup,
                    },
                ]
            },
            itemClickCallback: selectGeofenceGroup,
            updateCallback: updateGeofenceGroups,
            additionalData: {
                getPolyline: item => (item.geofences.map(geofence => geofence.polyline)).join('|'),
            },
            initOnOpen: true,
            needRadiobutton: true,
        };
        vm.paginationData = {
            itemsPerPage: 10,
        };

        vm.createGeofenceGroup = createGeofenceGroup;

        function init() {
            loadGeofenceGroups();
        }

        /**
         * Updates geofence groups list
         * @param page
         * @param itemsPerPage
         */
        function updateGeofenceGroups(page, itemsPerPage) {
            vm.visibleGeofenceGroups = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.geofenceGroups).visibleItems;
        }

        /**
         * Creates geofence group
         *
         */
        function createGeofenceGroup() {
            $state.go('app.addGeofenceGroup', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Updates geofence group
         *
         * @param {MouseEvent} $event
         * @param {Object} geofenceGroup
         */
        function updateGeofenceGroup($event, geofenceGroup) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editGeofenceGroup', {
                data: geofenceGroup,
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Deletes geofence group
         *
         * @param {MouseEvent} $event
         * @param {Object} geofenceGroup
         */
        function deleteGeofenceGroup($event, geofenceGroup) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_GEOFENCE_GROUP',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                GeofenceDataService.deleteGeofenceGroup(geofenceGroup.id)
                    .then(() => {
                        loadGeofenceGroups();
                    });
            });
        }

        /**
         * On geofence group select handler
         * @param {object} geofenceGroup
         */
        function selectGeofenceGroup(geofenceGroup) {
            const name = `group - ${geofenceGroup.name}`;
            const selectedGeofenceIds = geofenceGroup && geofenceGroup.geofences
                ? geofenceGroup.geofences.filter(geofence => geofence.externalId)
                    .map(geofence => geofence.externalId)
                : null;

            vm.onSelect(name, selectedGeofenceIds);
        }

        /**
         * Loads geofence groups from API
         * @return {undefined}
         */
        function loadGeofenceGroups () {
            GeofenceDataService.getGeofenceGroups().then(response => {
                vm.geofenceGroups = response;
                vm.geofenceGroupsCount = vm.geofenceGroups.length;
                vm.updateCounter++;
            });
        }
    }
})();
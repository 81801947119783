(function() {
    'use strict';

    angular.module('lineRoute')
        .service('RouteHelper', RouteHelper);

    function RouteHelper(
        TRANSPORT_TYPE,
        TRANSPORT_ICONS,
        TRANSPORT_NAME,
        LineRouteDateTimeService
    ) {
        return {
            getPercent,
            getTransportIcon,
            getVerbalTime,
            getTransportIconByMCTId
        };

        function getPercent(value, percent) {
            return value / 100 * percent;
        }

        function getTransportIcon(catCode, type = TRANSPORT_ICONS.DEFAULT) {
            const typesWidthIcon = [
                ...TRANSPORT_TYPE.TRAM.catCodes,
                ...TRANSPORT_TYPE.SUBURBAN.catCodes,
                ...TRANSPORT_TYPE.BUS.catCodes,
                ...TRANSPORT_TYPE.UNDERGROUND.catCodes,
                ...TRANSPORT_TYPE.FERRY.catCodes,
                ...TRANSPORT_TYPE.LIGHT_RAIL.catCodes,
            ];

            catCode = parseInt(catCode);
            const transportType = Object.keys(TRANSPORT_TYPE).find(key =>
                TRANSPORT_TYPE[key].catCodes
                && TRANSPORT_TYPE[key].catCodes.includes(catCode)
            );
            return transportType &&
                typesWidthIcon.includes(catCode) &&
                `/assets/images/transport_icon_${transportType.toLowerCase()}${type}.png`;
        }

        function getTransportIconByMCTId(mctId) {
            return `/assets/images/transport_icon_${TRANSPORT_NAME[mctId].toLowerCase()}.png`;
        }

        /**
         * Get time left to departure
         * @return {string}
         */
        function getVerbalTime(time) {
            const minsToDeparture = LineRouteDateTimeService.getMinutesToDeparture(time);
            switch (true) {
                case minsToDeparture <= 0:
                    return 'Now';
                case minsToDeparture === 1:
                    return `${minsToDeparture} min`;
                default:
                    return `${minsToDeparture} mins`;
            }
        }
    }
})();
(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('elementNow', {
            templateUrl: '/screen/views/components/timetable-hafas/partials/element-now/element-now.tpl.html',
            controller: ElementNowController,
            bindings: {
                odd: '<',
                textScaleParams: '<',
            }
        });

    /**
     * @property {Journey} journey
     * @property {{ scale:number, nowScale: number, maxHeight:string }} textScaleParams
     *
     * @constructor
     */
    function ElementNowController() {
        const $ctrl = this;

        $ctrl.nowScaleDefault = 0.75;
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .config(addMissingMethods)
        .config(restangularConfig)
        .config(ngMaterialConfig)
        .config(whiteList)
        .config(textAngularConfig)
        .config(uiGmapConfig)
        .config(colorPickerConfig)
        .config(disableAriaLabelWarnings)
        .config(translateProviderConfig)
        .config(translateProviderConfig)
        .config(materialDateConfig);

    function addMissingMethods() {
        if (!angular.lowercase) {
            angular.lowercase = angular.$$lowercase;
        }
    }

    function whiteList($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist(['**']);
    }

    function restangularConfig(RestangularProvider) {
        RestangularProvider.setBaseUrl('/api');

        RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response, deferred) {
            var extractedData = data;
            if (operation === 'getList') {
                extractedData = data.list;
                extractedData.count = data.count;
            }
            return extractedData;
        });
    }

    function ngMaterialConfig($mdThemingProvider) {
        $mdThemingProvider.definePalette('beaconGreen', {
            '50': '879baa',
            '100': '879baa',
            '200': '879baa',
            '300': '879baa',
            '400': '879baa',
            '500': '879baa',
            '600': '879baa',
            '700': '879baa',
            '800': '879baa',
            '900': '879baa',
            'A100': '879baa',
            'A200': '879baa',
            'A400': '879baa',
            'A700': '879baa',
            'contrastDefaultColor': 'light',
        });

        $mdThemingProvider
            .theme('default')
            .primaryPalette('beaconGreen')
            .backgroundPalette('grey', { default: '50' });
    }

    function textAngularConfig($provide) {
        $provide.decorator('taOptions', ['$delegate', 'taRegisterTool', 'taSelection', function(taOptions, taRegisterTool, taSelection) {
            taOptions.forceTextAngularSanitize = true;
            taOptions.keyMappings = [];

            taRegisterTool('standard', {
                buttontext: 'Standard',
                tooltiptext: 'Standard',
                action: function(deferred, restoreSelection) {
                    this.$editor().wrapSelection('formatBlock', '<p>');
                },
                activeState: function(commonElement) {
                    var el = taSelection.getSelectionElement();
                    if (!el || !el.tagName) {
                        return;
                    }

                    return taSelection.getSelectionElement().tagName.toLowerCase() === 'p';
                }
            });

            taOptions.toolbar = [
                ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'standard', 'quote'],
                ['bold', 'italics', 'underline'],
                ['charcount']
            ];
            taOptions.classes = {
                focussed: 'focussed',
                toolbar: 'btn-toolbar',
                toolbarGroup: 'btn-group',
                toolbarButton: 'btn btn-default',
                toolbarButtonActive: 'active',
                disabled: 'disabled',
                textEditor: 'form-control',
                htmlEditor: 'form-control'
            };

            taRegisterTool('fontName', {
                display: "<md-select ng-model='ngModel' " +
                        "style='margin-top: 0;\n" +
                            "padding-top: 0;\n" +
                            "padding-bottom: 3px;'" +
                        "md-selected-text=\"'A'\">" +
                        "<md-option ng-repeat='o in options' " +
                            "ng-value='o.css' " +
                            "style='font-family: {{o.css}}' " +
                            "ng-click='action($event, o.css)'>" +
                            "{{o.name}}" +
                        "</md-option>" +
                    "</md-select>",
                action: function (event, font) {
                    //Ask if event is really an event.
                    if (!!event.stopPropagation) {
                        //With this, you stop the event of textAngular.
                        event.stopPropagation();
                        //Then click in the body to close the dropdown.
                        $("body").trigger("click");
                    }
                    return this.$editor().wrapSelection('fontName', font);
                },
                options: [
                    { name: 'Sans-Serif', css: 'Arial, Helvetica, sans-serif' },
                    { name: 'Serif', css: "'times new roman', serif" },
                    { name: 'Wide', css: "'arial black', sans-serif" },
                    { name: 'Narrow', css: "'arial narrow', sans-serif" },
                    { name: 'Comic Sans MS', css: "'comic sans ms', sans-serif" },
                    { name: 'Courier New', css: "'courier new', monospace" },
                    { name: 'Garamond', css: 'garamond, serif' },
                    { name: 'Georgia', css: 'georgia, serif' },
                    { name: 'Tahoma', css: 'tahoma, sans-serif' },
                    { name: 'Trebuchet MS', css: "'trebuchet ms', sans-serif" },
                    { name: "Helvetica", css: "'Helvetica Neue', Helvetica, Arial, sans-serif" },
                    { name: 'Verdana', css: 'verdana, sans-serif' },
                    { name: 'Proxima Nova', css: 'proxima_nova_rgregular' }
                ]
            });

            return taOptions;
        }]);
    }

    function uiGmapConfig(uiGmapGoogleMapApiProvider) {
        uiGmapGoogleMapApiProvider.configure({
            key: 'AIzaSyDwsiCR6eRCLNYPHFFwY-n53xxJvCFT0iw'
        });
    }

    function colorPickerConfig($provide) {
        $provide.decorator('ColorPickerOptions', function($delegate) {
            const options = angular.copy($delegate);
            options.format = 'hexString';
            options.case = 'lower'
            return options;
        });
    }

    /**
     * Disable all warnings, associated with "aria-label" attribute
     *
     * @param $mdAriaProvider
     */
    function disableAriaLabelWarnings($mdAriaProvider) {
        $mdAriaProvider.disableWarnings();
    }

    function translateProviderConfig($translateProvider) {
        $translateProvider.useStaticFilesLoader({
            prefix: '/locales/locale-',
            suffix: '.json'
        });
        $translateProvider.registerAvailableLanguageKeys(['en', 'de']);
        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('escape');
    }

    function materialDateConfig($mdDateLocaleProvider) {
        $mdDateLocaleProvider.formatDate = function(date) {
            return moment(date).format('DD/MM/YYYY');
        };
    }
}());

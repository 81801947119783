(function() {
    'use strict';

    angular.module('beacon.app')
        .component('chargingStationListPreview', {
            templateUrl: '/assets/views/share-park/charging-stations/charging-station-list-preview/charging-station-list-preview.tpl.html',
            controller: ChargingStationListPreviewController,
            bindings: {
                chargingStation: '<',
            }
        });

    function ChargingStationListPreviewController(
        UtilitiesService,
        ChargingStationHelper,
        ChargingStationDataService,
    ) {
        const vm = this;

        const CHARGING_TYPE = {
            CCS: 3,
            CHA_DEMO: 4,
            TYPE_1: 1,
            TYPE_2: 2,
        };

        vm.markers = [];
        vm.plugTypes = [];
        vm.dataLoaded = false;

        vm.$onInit = init;
        vm.plugLabel = plugLabel;
        vm.plugPowerLabel = plugPowerLabel;
        vm.plugIcon = plugIcon;
        vm.chargingStationDescription = chargingStationDescription;

        function init() {
            vm.markers = [generateMarker(vm.chargingStation)];
            ChargingStationDataService.plugTypes()
                .then(response => {
                    vm.plugTypes = response.plain();
                    vm.dataLoaded = true;
                });
        }

        function plugLabel(plugId) {
            const plugType = getPlugType(plugId);
            return plugType.plugLabel;
        }

        function plugPowerLabel(plugId) {
            const plugType = getPlugType(plugId);
            return plugType.chargingPowerLabel;
        }

        function plugIcon(plugId) {
            const plugType = getPlugType(plugId);
            switch (plugType.type) {
                case CHARGING_TYPE.CCS:
                    return '/assets/images/ic_chrg_ccs.svg';
                case CHARGING_TYPE.CHA_DEMO:
                    return '/assets/images/ic_chrg_chademo.svg';
                case CHARGING_TYPE.TYPE_1:
                    return '/assets/images/ic_chrg_type1.svg';
                case CHARGING_TYPE.TYPE_2:
                    return '/assets/images/ic_chrg_type2.svg';
            }
        }

        function chargingStationDescription() {
            const defaultLangId = Object.keys(vm.chargingStation.description)[0];
            return vm.chargingStation.description[defaultLangId];
        }

        function getPlugType(plugId) {
            return vm.plugTypes.find(item => item.id === plugId);
        }

        function generateMarker(chargingStation) {
            const coordinates = UtilitiesService.getCoordinates(chargingStation.location);
            return {
                position: {
                    lat: coordinates.latitude,
                    lng: coordinates.longitude,
                },
            };
        }
    }
})();
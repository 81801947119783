(function() {
    'use strict';

    angular.module('beacon.app')
        .component('rssContentType', {
            templateUrl: '/assets/views/content/elements/types/rss/rss.tpl.html',
            controller: RssContentType,
            bindings: {
                langArray: '<?',
                contentForm: '=?',
                contentData: '=?',
                contentGroupsArray: '<?'
            }
        });

    function RssContentType(
        $scope,
        $stateParams,
        StatesFactory,
        ContentDataService,
        PopupService,
        CampaignDataService,
        ContentImageProcessingService,
        PermissionsService,
        UserDataService,
    ) {
        const vm = this;

        // public properties
        vm.state = StatesFactory.ContentStates;
        vm.rssWebSources = [];
        vm.campaigns = [];
        vm.activeItem = null;
        vm.imageUploadMessages = [{
            error: 'required',
            text: 'Uploading a picture is required'
        }];

        vm.preventAccordionOpening = preventAccordionOpening;
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.channelCheckboxOnChange = channelCheckboxOnChange;
        vm.campaignCheckboxOnChange = campaignCheckboxOnChange;
        vm.isAllCampaignsCheckboxVisible = isAllCampaignsCheckboxVisible;
        vm.isCampaignsListVisible = isCampaignsListVisible;
        vm.isWebSourcesListVisible = isWebSourcesListVisible;
        vm.getDefaultTitle = getDefaultTitle;
        vm.setFinalLink = setFinalLink;

        bindWatchers();
        init();

        /**
         * initialization method
         */
        function init() {

            ContentDataService.getRssWebSources().then(data => {
                vm.rssWebSources = data;
                vm.rssWebSources.forEach(source => {
                    vm.contentData.message.selectedChannels.forEach(selectedChannelId => {
                        if(source.id === selectedChannelId) {
                            source.enabled = true;
                        }
                    })
                })
            });

            CampaignDataService.getCampaigns({type: 0}).then((data) => {
                    vm.campaigns = data;
                    vm.campaigns.forEach(campaign => {
                        vm.contentData.message.selectedCampaigns.forEach(selectedCampaignId => {
                            if(campaign.externalId === selectedCampaignId) {
                                campaign.enabled = true;
                            }
                        })
                    })
                }).catch(console.error.bind(console));

            UserDataService.loadUserData().then(function(data){
                vm.userData = data;
                const message = vm.contentData.message;
                const tenant = data.subdomain.tenant;

                if (!message.copyright && tenant.copyrightLabel) {
                    message.copyright = tenant.copyrightLabel;
                }

                if (!message.companyUrl && tenant.companyUrl) {
                    message.companyUrl = tenant.companyUrl;
                }
                
                if (!message.groupName && tenant.groupName) {
                    message.groupName = tenant.groupName;
                }

                // Channel link should always be actual on edit rss
                if (message.channelLink !== tenant.defaultRssRoot) {
                    message.channelLink = tenant.defaultRssRoot;
                }

                setFinalLink();
            });

            _fixDescription();
        }

        /**
         * Channel checkbox on change callback
         */
        function channelCheckboxOnChange() {
            const selectedChannels = vm.contentData.message.selectedChannels = [];
            vm.rssWebSources.forEach(source => {
                source.enabled && selectedChannels.push(source.id);
            });
        }

        /**
         * Campaign checkbox on change callback
         */
        function campaignCheckboxOnChange() {
            const selectedCampaigns = vm.contentData.message.selectedCampaigns = [];
            vm.campaigns.forEach(campaign => {
                campaign.enabled && selectedCampaigns.push(campaign.externalId);
            });
        }

        /**
         * Generates final RSS link
         * <tenant.defaultRssRoot>+<tenant.groupName>+<language>+channelName (force groupName to lower case)
         * e.g.: "https://bvg.cdn.trm.digital/rss/bvg/en/wichtige_mitteilungen.rss"
         *
         */
        function setFinalLink() {
            if (!_.isObject(vm.contentData.message.finalLink)) {
                vm.contentData.message.finalLink = {};
            }

            const langCode = _.isString(vm.currentLangCode) && vm.currentLangCode.toLowerCase();
            const groupName = vm.contentData.message.groupName && vm.contentData.message.groupName.toLowerCase();
            vm.contentData.message.finalLink[vm.langId] =
                `${vm.contentData.message.channelLink}${groupName}/${langCode}/${vm.contentData.message.channelName}`;
        }

        /**
         * On finish
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            let contentFormData = angular.copy(vm.contentData);
            contentFormData = Object.assign(contentFormData, {
                title: JSON.stringify(contentFormData.title),
                message: JSON.stringify(contentFormData.message)
            });

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    contentData: contentFormData
                });
            }
        }

        /**
         * Prevent accordion opening
         * @param $event
         */
        function preventAccordionOpening($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        /**
         * On image
         * @param imageFile
         * @param uploadToS3
         */
        function onImage(imageFile, uploadToS3) {
            ContentImageProcessingService.onImage(vm.contentData, imageFile, uploadToS3);
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            ContentImageProcessingService.onImageDelete(vm.contentData);
        }

        /**
         * Bind watchers
         */
        function bindWatchers() {
            $scope.$watch(
                () => vm.contentData,
                () => vm.state.canFinish = vm.contentForm.$valid,
                true
            );

            $scope.$watch(
                () => vm.contentData.language_id,
                langId => {
                    const lang = vm.langArray.find(item => item.id === langId);
                    vm.langId = langId;
                    vm.currentLangCode = lang ? lang.code.toUpperCase() : 'N/A';
                    setFinalLink();
                }
            );
        }

        /**
         * Is All Campaigns Checkbox Visible
         * @return {boolean}
         */
        function isAllCampaignsCheckboxVisible() {
            return PermissionsService.isRssAllCampaignsCheckboxVisible();
        }

        /**
         * Is Campaigns List Visible
         * @return {boolean}
         */
        function isCampaignsListVisible() {
            return PermissionsService.isRssCampaignsListVisible();
        }

        /**
         * Is Web Sources List Visible
         * @return {boolean}
         */
        function isWebSourcesListVisible() {
            return PermissionsService.isRssWebSourcesListVisible();
        }

        /**
         * Returns default translation
         *
         * @param {object} item
         * @returns {string} Title
         */
        function getDefaultTitle(item) {
            const firstLangId = Object.keys(item)[0];
            const language = firstLangId && vm.langArray.find(
                language => language.id === Number(firstLangId)
            );
            return language && `${item[firstLangId]} [${language.code}]` || 'N/A';
        }

        /**
         * Fix for old RSS items, where description was not language sensitive
         *
         * @private
         */
        function _fixDescription() {
            const message = vm.contentData.message;
            if (_.isString(message.message)) {
                message.message = {
                    [vm.contentData.language_id]: message.message
                };
            }
        }

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
    }
})();

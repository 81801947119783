(function() {
    'use strict';

    angular.module('beacon.app').service('ToastService', function ($mdToast) {
        const TOAST_TYPE = {
            INFO: 'info',
            SUCCESS: 'success',
            ERROR: 'error',
        };

        return {
            info,
            success,
            error,
        };

        /**
         * @typedef {object} ToastOptions
         * 
         * @property {string} [position] toast position, supported values: 'top', 'right', 'bottom', 'left' and all of their combinations
         * @property {number} [hideDelay] how much time to show the toast. Defaults to 3000ms
         */

        /**
         * Show default toast
         * 
         * @param {string} text message to display
         * @param {ToastOptions} options
         */
        function info(text, options = {}) {
            _show({
                ...options,
                text,
                type: TOAST_TYPE.INFO
            });
        }

        /**
         * Show "success" themed toast
         * 
         * @param {string} text message to display
         * @param {ToastOptions} options
         */
        function success(text, options = {}) {
            _show({
                ...options,
                text,
                type: TOAST_TYPE.SUCCESS
            });
        }

        /**
         * Show "error" themed toast
         * 
         * @param {string} text message to display
         * @param {ToastOptions} options
         */
        function error(text, options = {}) {
            _show({
                ...options,
                text,
                type: TOAST_TYPE.ERROR
            });
        }

        /**
         * Show toast
         * 
         * @param {object} params toast display options
         */
        function _show({
            hideDelay,
            position,
            text,
            type,
        }) {
            $mdToast.show({
                hideDelay: hideDelay || 3000,
                position: position || 'top right',
                controller: () => {},
                controllerAs: '$toast',
                bindToController: true,
                locals: {
                    text: text || '',
                    icon: _getIconNameByType(type),
                },
                templateUrl: '/assets/views/common/popups/toast/toast.tpl.html',
                toastClass: type || TOAST_TYPE.INFO,
            });
        }

        /**
         * Get Material icon name for toast by provided type
         * 
         * @param {string} type toast type
         * @returns {string} material icon name
         */
        function _getIconNameByType(type) {
            switch (type) {
                case TOAST_TYPE.ERROR:
                    return 'error';
                case TOAST_TYPE.SUCCESS:
                    return 'check_circle';
                default:
                    return 'info';
            }
        }
    });
}());

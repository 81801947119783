(function() {
    'use strict';

    angular.module('beacon.app')
        .component('preferencesSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/preferences/preferences.tpl.html',
            controller: PreferencesSegmentType,
            bindings: {
                langArray: '<',
                segmentData: '=',
            }
        });

    function PreferencesSegmentType(
        $scope,
        $window,
        segmentAddState,
        TenantDataService,
        SegmentDataService,
        ModelsFactory,
        PopupService,
        UtilitiesService,
        SegmentHelper,
        SEGMENT_STATUS,
    ) {
        const vm = this;
        const { generateGUID } = UtilitiesService;

        // Definition
        vm.state = segmentAddState;

        vm.preferenceHeader = {
            columns: [
                {
                    name: 'TITLE',
                    title: 'title',
                    width: '70',
                    class: 'preference-title'
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deletePreference
                    },
                    {
                        class: 'editBtn',
                        callback: editPreference
                    }
                ]
            }
        };

        vm.preferencesTypes = [
            {name: 'TITLE', value: 'title'},
            {name: 'CHECKBOX', value: 'checkbox'},
            {name: 'SWITCH', value: 'switch'},
            {name: 'SPINNER', value: 'spinner'},
            {name: 'MULTIBUTTON', value: 'multibutton'},
            {name: 'SEEKBAR', value: 'seekbar'},
        ];

        vm.itemHasDescription = itemHasDescription;
        vm.description1Label = description1Label;
        vm.description2Label = description2Label;
        vm.newPreference = newPreference;
        vm.setApplication = setApplication;
        vm.preferenceMoved = preferenceMoved;
        vm.itemTypeOnChange = itemTypeOnChange;
        vm.getPreferenceName = SegmentHelper.getPreferenceName;
        vm.getPropertyDescription = SegmentHelper.getPropertyDescription;
        vm.changeSegmentStatus = SegmentHelper.changeSegmentStatus;
        vm.isItemSaved = isItemSaved;
        vm.isItemFreezed = isItemFreezed;

        function deletePreference($event, idx) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            const minPreferenceLength = 1;
            if(vm.segmentData.message[vm.segmentData.language_id].preferences.length <= minPreferenceLength){
                PopupService.showAlertPopup({
                    text: 'PREFERENCE_SEGMENT_SHOULD_HAVE_AT_LEAST_ONE_PROP',
                    okButtonText: 'OK'
                }, function() {
                });
            } else {
                PopupService.showConfirmationPopup({
                    text: 'YOU_ARE_ABOUT_TO_DELETE_YOU_PREFERENCE',
                    okButtonText: 'DELETE',
                    cancelButtonText: 'CANCEL'
                }).then(() => {
                    vm.langArray.forEach(lang => {
                        vm.segmentData.message[lang.id].preferences.splice(idx, 1);
                    });
                    updatePositions();
                });
            }
        }

        function editPreference() {

        }

        function itemHasDescription(type) {
               return ['checkbox','spinner','multibutton','seekbar'].indexOf(type) !== -1;
        }

        function description1Label(type) {
            switch (type){
                case 'checkbox':
                    return 'ON_TEXT';
                case 'spinner':
                    return 'LIST_ELEMENTS_LABEL';
                case 'multibutton':
                    return 'BUTTON_ELEMENTS_LABEL';
                case 'seekbar':
                    return 'FROM_TO_LABEL';
                default:
                    return "";
            }
        }

        function description2Label(type) {
            switch (type){
                case 'checkbox':
                    return 'OFF_TEXT';
                case 'spinner':
                    return 'LIST_TITLE';
                case 'seekbar':
                    return 'UNIT_LABEL';
                default:
                    return "";
            }
        }

        function preferenceMoved(index, item) {
            const languageId = vm.segmentData.language_id;
            vm.segmentData.message[languageId].preferences.splice(index, 1);

            const newIdx = vm.segmentData.message[languageId].preferences.reduce((newIdx, el, idx) => {
                return el.code === item.code ? idx : newIdx;
            }, null)

            vm.langArray.forEach(lang => {
                if(lang.id !== languageId){
                    const i = vm.segmentData.message[lang.id].preferences.findIndex(el => el.code === item.code);
                    const el = vm.segmentData.message[lang.id].preferences.splice(i, 1).pop();
                    vm.segmentData.message[lang.id].preferences.splice(newIdx, 0, el);
                }
            });

            updatePositions();
        }

        function updatePositions() {
            vm.langArray.forEach(lang => {
                vm.segmentData.message[lang.id].preferences.forEach((option, idx) => {
                    const position = idx + 1;
                    option.position = position <= 9 ? "0" + position : position;
                })
            });
        }

        function itemTypeOnChange(item) {
            const languageId = vm.segmentData.language_id;
            item.description1 = "";
            item.description2 = "";
            vm.langArray.forEach(lang => {
                if(lang.id !== languageId){
                    vm.segmentData.message[lang.id].preferences.forEach(pref => {
                        if(pref.code === item.code){
                            pref.type = item.type;
                            pref.description1 = "";
                            pref.description2 = "";
                        }
                    })
                }
            });
        }

        function newPreference() {
            const guid = generateGUID();
            const languageId = vm.segmentData.language_id;
            vm.langArray.forEach(lang => {
                const props = {code: guid};
                const model = new ModelsFactory.SegmentPreferencesItem(props);
                vm.segmentData.message[lang.id].preferences.push(model);
            });

            updatePositions();
        }

        vm.errorMsg = null;

        init();

        function init() {
            setModel();
            vm.activateSegment = vm.segmentData.status === SEGMENT_STATUS.ACTIVE;

            TenantDataService.getAvailableApps().then(apps => {
                vm.availableApps = apps;
            });
        }

        function setModel() {
            vm.model = vm.segmentData;
        }

        function onFinish(event, option) {
            if (vm.segmentData.status === SEGMENT_STATUS.ACTIVE) {

                // wasActivated value is same for all existing languages
                vm.langArray.forEach(lang => {
                    vm.segmentData.message[lang.id].wasActivated = !!SEGMENT_STATUS.ACTIVE;
                });
            }
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: angular.copy(vm.segmentData)
                });
            }
        }

        function onLanguageChange() {
            setModel();
        }

        function setApplication() {
            const languageId = vm.segmentData.language_id;
            const appId = vm.segmentData.message[languageId].appId;
            vm.segmentData.definitionRef = appId;
            vm.langArray.forEach(lang => {
                if(lang.id !== languageId){
                    vm.segmentData.message[lang.id].appId = appId;
                }
            });
        }

        function isItemSaved(item) {
            return vm.langArray && vm.langArray.map(lang => {
                return vm.segmentData.message[lang.id].preferences
                    .filter(preference => preference.code === item.code)
                    .pop();
            }).some(el => el.id);
        }

        /**
         * Checks if the item can't be edited
         * @param item
         * @return boolean
         */
        function isItemFreezed(item) {
            return item.id && vm.segmentData.message[vm.segmentData.language_id].wasActivated;
        }

        function canFinish() {
            const messageLang = vm.segmentData.message[vm.segmentData.language_id];
            return !!vm.segmentData.title && !!messageLang && !!messageLang.appId && !!messageLang.preferences.length;
        }

        function bindWatchers() {
            $scope.$watch(angular.bind(vm, () => {
                return vm.segmentData.title;
            }), () => {
                vm.state.canFinish = canFinish();
            });

            $scope.$watch(angular.bind(vm, () => {
                const messageLang = vm.segmentData.message[vm.segmentData.language_id];
                return messageLang && messageLang.appId;
            }), () => {
                vm.state.canFinish = canFinish();
            });

            $scope.$watch(angular.bind(vm, () => {
                const messageLang = vm.segmentData.message[vm.segmentData.language_id];
                return messageLang && messageLang.preferences && messageLang.preferences.length;
            }), () => {
                vm.state.canFinish = canFinish();
            });
        }

        bindWatchers();

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('DateHelper', DateHelperService);

    function DateHelperService(
        TIME_ZONES,
    ) {

        const MILISECONDS_IN_SECOND = 1000;
        const SECONDS_IN_MINUTE = 60;
        const MINUTES_IN_HOUR = 60;
        const HOURS_IN_DAY = 24;

        let defaultDateFormat = "YYYY-MM-DD";

        return {
            incrementCurrentDate,
            getTimeDiff,
            setDefaultDateFormat,
            getDefaultDateFormat,
            duration,
            generateTimeZones,
        };

        /**
         * Incrementing current date by minutes
         * @param {number} increment Increment value
         * @return {Date} Date object
         */
        function incrementCurrentDate(increment) {
            const date = new Date();
            date.setMinutes(date.getMinutes() + increment);
            return date;
        }

        /**
         * Get differences between current date and date
         * @param checkDate
         * @return {object}
         */
        function getTimeDiff(checkDate) {
            const currentDate = new Date();
            const difference = {};

            const dateTime = angular.isDate(checkDate) ? checkDate : moment(checkDate).toDate();
            const diff = dateTime.getTime() - currentDate.getTime();
            const minutesDiff = Math.ceil(diff / MILISECONDS_IN_SECOND / SECONDS_IN_MINUTE);
            const hoursDiff = Math.floor(minutesDiff / MINUTES_IN_HOUR);
            difference.days = Math.floor(hoursDiff / HOURS_IN_DAY);
            difference.hours = hoursDiff % HOURS_IN_DAY;
            difference.minutes = minutesDiff % MINUTES_IN_HOUR;

            return difference;
        }

        function setDefaultDateFormat(dateFormat) {
            if (dateFormat) {
                defaultDateFormat = dateFormat;
            }
        }

        function getDefaultDateFormat() {
            return defaultDateFormat;
        }

        /**
         * Diff in "1d 2h 3m 4s" format
         *
         * @param start
         * @param end
         * @param seconds
         * @param {number|null} items
         * @returns {string}
         */
        function duration(start, end, seconds = false, items = null) {
            const MONTHS_IN_YEAR = 12;
            const HOURS_IN_DAY = 24;
            const MINUTES_IN_HOUR = 60;
            const SECONDS_IN_MINUTE = 60;

            const diff = moment.duration(moment(end) - moment(start));
            const int = Math.trunc;
            const result = {
                Y: int(diff.asYears()),
                M: int(diff.asMonths()) % MONTHS_IN_YEAR,
                d: int(diff.asDays()),
                h: int(diff.asHours() % HOURS_IN_DAY),
                m: int(diff.asMinutes() % MINUTES_IN_HOUR) + (seconds ? 0 : 1)
            };

            if (seconds) {
                result.s = int(diff.asSeconds() % SECONDS_IN_MINUTE) + 1;
            }

            const parts = Object.keys(result)
                .filter(label => result[label])
                .map(label => result[label] + label);

            if (items) {
                parts.splice(result.M || result.Y ? 1 : items);
            }

            return parts.join(' ');
        }

        /**
         * Generates time zones array
         * @return { Array }
         */
        function generateTimeZones() {
            const INDEX_OFFSET = 0;
            const INDEX_VALUE = 1;
            return TIME_ZONES.map(
                item => ({
                    offset: item[INDEX_OFFSET],
                    value: item[INDEX_VALUE],
                }));
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LoginService', ['Restangular', '$state', '$auth', '$translate', '$window', 'mdcDefaultParams', 'APP_MODES', loginService]);

    function loginService(
        Restangular,
        $state,
        $auth,
        $translate,
        $window,
        mdcDefaultParams,
        APP_MODES,
    ) {
        return {
            isLoggedIn,
            authorize,
            logout,
            signup,
            refreshToken,
            resetPassword,
            setPassword,
            validatePasswordResetToken,
            getUserRelations,
            oauthAndRedirectToSubdomain,
        };

        // Public methods

        /**
         * Checks if user logged in
         *
         * @returns {boolean}
         */
        function isLoggedIn() {
            return $auth.isAuthenticated();
        }

        /**
         * Authorizes user in the selected subdomain
         *
         * @param {number} userId
         * @param {string} subdomainId
         * @param {function} callback
         */
        function authorize(userId, subdomainId, callback) {
            Restangular.all('login/subdomain')
                .post({subdomainId})
                .then(response => callback(response));
        }

        /**
         * @return {Promise<{errorCode: number, langCode: string, tenants: [], userId: number}>}
         */
        function getUserRelations() {
            return Restangular.one('login/relations').get();
        }

        /**
         * Logs user out
         */
        function logout() {
            Restangular.all('logout')
                .post()
                .then(function() {
                    $auth.logout();
                    $state.go('login', { message: "YOU_HAVE_BEEN_LOGGED_OUT_SUCCESSFULLY" });
                    setTimeout(function() {
                        less.modifyVars();
                    }, 0);
                });
        }

        /**
         * Creates new user
         *
         * @param {object} data
         * @param {function} callback
         * @param {function} errorCallback
         */
        function signup(data, callback, errorCallback) {
            Restangular.all('signup')
                .post(data)
                .then(function(response) {
                    callback(response);
                })
                .catch(function(response) {
                    errorCallback(response);
                });
        }

        /**
         * Reset user password
         *
         * @param {object} email
         */
        function resetPassword(email) {
            return Restangular.one('resetPassword').post(null, {email});
        }

        /**
         *
         * @param {object} data
         * @param {string} data.userId
         * @param {string} data.token
         * @param {string} data.password
         * @returns {Promise}
         */
        function setPassword(data) {
            return Restangular.one('setPassword').post(null, data);
        }

        function validatePasswordResetToken(token) {
            return Restangular.one('validatePasswordResetToken').post(null, {token});
        }

        function refreshToken() {
            return Restangular.one('refreshToken').get();
        }

        /**
         * Authorize user locally and redirect subdomain
         * @param {string} token access token
         * @param {number} subdomainId tenant's subdomain id
         * @param {string} [appMode] application mode
         */
        function oauthAndRedirectToSubdomain(token, subdomainId, appMode = APP_MODES.TRM) {
            $auth.setToken(token);

            getUserRelations()
                .then(({ langCode }) => {
                    $translate.use(langCode);
                    moment.locale(langCode);
                    mdcDefaultParams({ lang: langCode });
                    $window.localStorage.uiLang = langCode;

                    authorize(null, subdomainId, function() {
                        refreshToken().then((response) => {
                            if (response.token) {
                                $auth.setToken(response.token);
                                $state.go('app.content', { appMode });
                            }
                        });
                    });
                })
                .catch((err) => {
                    $state.go('login', { errorMessage: 'LOGIN_FAILED' })
                });
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentManagementEditController', ContentManagementEditController);

    function ContentManagementEditController(
        $scope,
        $state,
        $stateParams,
        StatesFactory,
        ShareDataService,
        PopupService,
        ScreenHelper,
    ) {
        const vm = this;

        let toolbarState = StatesFactory.ManagementStates.refresh();
        let stateParamsContent = $stateParams.data;

        /**
         * Public properties and methods
         */
        vm.defaultPage = false;
        vm.searchName = '';
        vm.sections = $stateParams.sections;
        vm.visibleSections = vm.sections;
        vm.selectedSections = [];
        vm.sectionsCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'sectionTitle',
                    width: '',
                    title: 'name'
                },
                {
                    name: 'DESCRIPTION',
                    class: 'sectionDescription',
                    width: '20',
                    title: 'description'
                },
                {
                    name: 'Status',
                    class: 'sectionStatus',
                    width: '20',
                    translate: true,
                    title: item => item.details ? ScreenHelper.getStatusName(item.details.status) : 'N/A'
                },
            ],
            header: false,
            itemClickCallback: toggleSection
        };
        vm.sectionGroups = $stateParams.sectionGroups;
        vm.selectedSectionGroups = [];
        vm.sectionGroupsCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'sectionGroupTitle',
                    width: '100',
                    translate: true,
                    getTranslationParams: ({name}) => ({name}),
                    title: () => 'GROUP_NAME',
                },
            ],
            header: false,
            itemClickCallback: toggleSectionGroup
        };
        vm.onFinish = onFinish;
        vm.updateSectionsList = updateSectionsList;

        init();

        /**
         * Initialization method
         */
        function init() {
            vm.selectedSections = stateParamsContent.sections;

            toolbarState.canBack = true;
            toolbarState.canFinish = isSaveBtnEnabled();
            toggleSection();
        }

        /**
         * Determines if Save button is enabled or disabled.
         *
         * @return {boolean}
         */
        function isSaveBtnEnabled() {
            return vm.defaultPage || vm.selectedSections.length;
        }

        /**
         * Handler on section select/deselect
         */
        function toggleSection() {

            // creates new selectedSectionsGroupsIds array according to selected sections list changes
            let selectedSectionsGroupsIds =
                _.chain(vm.sections)
                    .map(section => _.map(section.groups, 'id'))
                    .flatten()
                    .countBy()
                    .pickBy((count, key) => {
                        return count === _.filter(vm.selectedSections, section => {
                            return !!_.find(section.groups, ['id', parseInt(key)]);
                        }).length
                    })
                    .keys()
                    .map(_.parseInt)
                    .value();

            vm.selectedSectionGroups = _.filter(vm.sectionGroups,
                group => selectedSectionsGroupsIds.indexOf(group.id) !== -1);
            toolbarState.canFinish = isSaveBtnEnabled();
        }

        /**
         * Handler on sectionGroup select/deselect
         */

        function toggleSectionGroup(groupItem) {
            let groupSections = _.filter(vm.sections,
                section => !!_.find(section.groups, ["id", groupItem.id]));
            if (_.find(vm.selectedSectionGroups, ["id", groupItem.id])){
                vm.selectedSections = _.unionWith(vm.selectedSections, groupSections, _.isEqual);
            } else {
                vm.selectedSections = _.pullAllBy(vm.selectedSections, groupSections, "id");
            }
            toolbarState.canFinish = isSaveBtnEnabled();
        }

        function updateSectionsList() {
            vm.visibleSections = vm.searchName
                ? vm.sections.filter(section =>
                    section.name.toUpperCase().includes(vm.searchName.toUpperCase())
                )
                : vm.sections;
        }

        /**
         * Save/finish action callback
         *
         * @param {number} newValue Can be 1 or 0. If 1 then process saving, otherwise return.
         */
        function onFinish(newValue) {
            if (!newValue) {
                return;
            }

            let controllers = [];

            if (vm.defaultPage || !vm.selectedSections.length) {
                controllers = [0];
            } else {
                controllers = vm.selectedSections.map(item => item.controllerRef);
            }

            let data = new FormData();
            data.append('content', stateParamsContent.id);
            data.append('controllers', controllers.join(','));

            ShareDataService.assignContent(data)
                .then(response => {
                    vm.selectedSections.forEach(item => {
                        item.url = response.urls[item.controllerRef];
                    });

                    PopupService.show({
                        templateUrl: '/assets/views/common/popups/assign-content/assign-content.tpl.html',
                        controller: 'AssignContentPopupController',
                        controllerAs: 'assignContentPopupController',
                        windowClass: 'assignContentPopup',
                        keyboard: false,
                        resolve: {
                            data: function() {
                                return {
                                    sections: vm.selectedSections,
                                    defaultPage: response.urls[0]
                                };
                            }
                        }
                    }).then(() => {
                        $state.go('app.contentManagement', {
                            paginationData: $stateParams.paginationData
                        });
                    });
                });
        }

        $scope.$watch(() => toolbarState.finish, onFinish);

        $scope.$watch(() => vm.defaultPage, () => {
            toolbarState.canFinish = isSaveBtnEnabled();
        });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('layoutItem', layoutItem);

    function layoutItem(
        $compile,
        $translate,
    ) {
        return {
            restrict: 'E',
            scope: {
                item: '=',
                model: '=',
                callback: '=',
                actions: '=',
                layoutType: '=',
                langId: '=',
                getLabel: '=',
            },
            link: function(scope, element) {
                scope.getTitle = function(currentModel) {
                    let title = angular.isString(currentModel.title) ? currentModel.title :
                        (currentModel.title[this.langId] || currentModel.title[currentModel.default_lang]);

                    title = _appendStationsLabel(title, currentModel);

                    return title;
                };
                const renderElement = (item, model, langId) => {

                    let str = '';
                    const itemType = item.type ? `layout="${item.type}"` : "";
                    const itemFlex = item.flex !== -1 ? `flex="${item.flex || ""}"` : "";
                    const height = item.height ? `height: ${item.height}%` : null;
                    const width = item.width ? `width: ${item.width}%` : null;
                    const style = `style="${[width, height].filter(e => e).join('; ')}"`

                    str += `<div  ${itemType} ${itemFlex} ${style}>`;

                    if (item.id) {
                        str += `<div class="layout-item">
                                    <span 
                                        ng-if="!model[${item.id}].id" 
                                        class="newBtn" 
                                        ng-click="callback($event, ${item.id}, actions.ADD, layoutType)"></span>
                                    <span 
                                        ng-if="model[${item.id}].id" 
                                        class="editBtn" 
                                        ng-click="callback($event, ${item.id}, actions.EDIT, layoutType)"></span>
                                    <span 
                                        ng-if="model[${item.id}].id" 
                                        class="deleteBtn" 
                                        ng-click="callback($event, ${item.id}, actions.DELETE, layoutType)"></span>
                                    <span class="item-title" ng-bind-html="getTitle(model[${item.id}])"></span>
                                    <span 
                                        ng-if="model[${item.id}].id"
                                        class="item-icon">
                                        <i class="material-icons">{{model[${item.id}].type === "page" ? "description" : "web"}}</i> 
                                    </span>
                                    <span class="layout-item-label">${item.label}</span>
                                    <span class="layout-item-id">${item.id}</span>
                                </div>`;
                    }
                    if (item.items) {
                        for (let i = 0; i < item.items.length; i++) {
                            str += renderElement(item.items[i], model, langId);
                        }
                    }

                    str += `</div>`;

                    return str;
                }

                scope.getLabel(scope.item.label, scope.layoutType);

                const html = renderElement(scope.item, scope.model, scope.langId);
                const compiledElement = $compile(html)(scope);

                element.append(compiledElement);

                /**
                 * @param {string} title
                 * @param {SplitScreenItem} item
                 * @return {string}
                 * @private
                 */
                function _appendStationsLabel(title, item) {
                    if (angular.isArray(item.stations)) {
                        const labels = {
                            title: $translate.instant('STATION_S'),
                            all: $translate.instant('ALL'),
                        };

                        title += `<br>${labels.title}: `
                        title += item.stations.length
                            ? item.stations.map(index => '#' + index).join(', ')
                            : labels.all;
                    }

                    return title;
                }
            }
        };
    }
}());

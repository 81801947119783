(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('copyFromWebSource', copyFromWebSource);

    function copyFromWebSource() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/copy-from-web-source/copy-from-web-source.tpl.html',
            replace: true,
            controller: CopyFromWebSourceController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                callback: '='
            }
        };
    }

    function CopyFromWebSourceController($scope, PopupService) {
        const vm = this;

        vm.openPopup = openPopup;

        /**
         * Open "copy from web source" popup
         * @returns {undefined}
         */
        function openPopup() {
            PopupService.show({
                templateUrl: '/assets/views/common/popups/copy-from-web-source/copy-from-web-source.tpl.html',
                controller: 'CopyFromWebSourcePopupController',
                controllerAs: '$ctrl',
                windowClass: 'copy-from-web-source-popup',
                keyboard: false
            }).then(data => {
                vm.callback(data);
            });
        }
    }
}());

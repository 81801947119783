(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TierGroupService', TierGroupService);

    function TierGroupService(Restangular) {

        /**
         * Getting the tier groups list
         *
         * @returns {array}
         */
        function getList() {
            return Restangular.all('tier-groups').getList();
        }

        /**
         * Creating a tier group
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function create(data) {
            return Restangular.all('tier-groups')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updating the tier group
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function save(data) {
            data.append('_method', 'PUT');
            return Restangular.all('tier-groups')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Delete tier group (changing status to 3)
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function remove(data) {
            data.append('_method', 'DELETE');
            return Restangular.all('tier-groups')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        return {
            getList,
            remove,
            save,
            create
        }
    }
})();
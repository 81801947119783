(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentCampaigns', {
            templateUrl: '/assets/views/content/campaigns/campaigns.tpl.html',
            controller: ContentCampaignsController
        });

    function ContentCampaignsController(
        $scope,
        $state,
        $stateParams,
        CampaignDataService,
        CampaignHelper,
        PopupService,
        CAMPAIGN_TYPES,
        PermissionsService,
    ) {
        const vm = this;

        vm.$onInit = _init;

        vm.contentElement = $stateParams.contentElement.plain();
        vm.contentElement.title = typeof vm.contentElement.title === 'string'
            ? JSON.parse(vm.contentElement.title)
            : vm.contentElement.title;
        vm.contentTitle = vm.contentElement.title[vm.contentElement.language_id];
        vm.selectedCampaigns  = [];

        vm.checkboxListConfig = {
            columns: [
                {
                    name: 'Campaign name',
                    class: 'contentTitle',
                    width: '65',
                    title: item => item.name
                },
                {
                    name: 'Content elements',
                    class: 'contentElements',
                    width: '10',
                    title: item => item.contents.length
                },
            ],
            header: true,
            itemClickCallback: toggleCampaignHandler
        };

        vm.createCampaign = createCampaign;
        vm.isPermissionAvailable = PermissionsService.isPermissionAvailable;

        vm.canCreateMobileOrPushCampaign = vm.isPermissionAvailable('can_create_campaign_mobile')
            || vm.isPermissionAvailable('can_create_campaign_push');
        vm.canViewAndModifyMobileCampaign = vm.isPermissionAvailable('can_view_campaign_mobile')
            && vm.isPermissionAvailable('can_modify_campaign_mobile');

        /**
         * Init method
         *
         * @private
         */
        function _init() {
            CampaignDataService.getCampaigns()
                .then(response => {
                    const campaigns = response.plain();
                    vm.campaigns = campaigns.filter(campaign => campaign.campaign_type === CAMPAIGN_TYPES.MOBILE);
                    vm.selectedCampaigns = vm.campaigns.filter(_isRelatedToCurrentContent);
                })
                .catch(console.error.bind(console));
        }

        /**
         * Toggle campaign handler
         */
        function toggleCampaignHandler(campaign) {
            const lastRelationDeleted = campaign.contents.length === 1 && _wasUnchecked(campaign);

            if (lastRelationDeleted) {
                PopupService.showAlertPopup({
                    text: `YOU_HAVE_DELETED_LAST_CONTENT_RELATION`,
                    okButtonText: 'OK'
                });
            }
        }

        /**
         * Save current data and redirect to elements page
         */
        function save() {
            // Get rid of untouched campaigns
            const campaigns = angular.copy(vm.campaigns).filter(
                campaign => (_wasChecked(campaign) || _wasUnchecked(campaign))
            );

            if (!campaigns.length) {
                $state.go('app.content');
            }

            campaigns.forEach(campaign => {
                if (_wasChecked(campaign)) {
                    campaign.contents.push(vm.contentElement);
                } else if (_wasUnchecked(campaign)) {
                    const contentKey = campaign.contents.findIndex(content => content.id === vm.contentElement.id);
                    campaign.contents.splice(contentKey, 1);
                }

                const contentList = campaign.contents.map(content => content.id);

                const formData = new FormData();
                formData.append('contentList', JSON.stringify(contentList));

                CampaignDataService.syncContent(campaign.id, formData)
                    .then(() => {
                        $state.go('app.content');
                    }).catch(console.error.bind(console));
            });
        }

        /**
         * Create campaign handler
         */
        function createCampaign() {
            PopupService.show({
                templateUrl: '/assets/views/content/campaigns/popup-create-campaign/popup-create-campaign.tpl.html',
                controller: 'PopupCreateCampaignController',
                controllerAs: '$ctrl',
                windowClass: 'popup-create-campaign',
                backdrop: true,
                resolve: {
                    data: () => {
                        return {
                            contentId: vm.contentElement.id
                        }
                    }
                }
            });
        }

        /**
         * Check if campaign was checked by user
         *
         * @param {condition} campaign
         * @return {boolean}
         * @private
         */
        function _wasChecked(campaign) {
            return _isSelected(campaign) && !_isRelatedToCurrentContent(campaign);
        }

        /**
         * Check if campaign was unchecked by user
         *
         * @param {object} campaign
         * @return {boolean}
         * @private
         */
        function _wasUnchecked(campaign) {
            return !_isSelected(campaign) && _isRelatedToCurrentContent(campaign);
        }

        /**
         * Check if campaign was related to current content
         *
         * @param {object} campaign
         * @return {boolean}
         * @private
         */
        function _isRelatedToCurrentContent(campaign) {
            return CampaignHelper.isCampaignRelatedToContent(campaign, vm.contentElement.id);
        }

        /**
         * Check if campaign is selected
         *
         * @param {object} campaign
         * @return {boolean}
         * @private
         */
        function _isSelected(campaign) {
            return vm.selectedCampaigns.some(item => item.id === campaign.id);
        }

        /**
         * Save button click broadcast from toolbar/toolbar.ctrl.js
         */
        $scope.$on('saveClick', save);
    }

}());

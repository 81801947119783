(function() {
    'use strict';

    angular.module('lineRoute')
        .service('RouteVertical', (RouteStation, RouteLine, RouteBase, RouteHelper) => {

            class RouteVertical extends RouteBase {
                constructor(config) {
                    super(config);

                    this.stepX = this.isMultiLine
                        ? RouteHelper.getPercent(this.workspaceWidth, config.distance)
                        : 0;
                    this.stepY = this.workspaceHeight / (this.stationsPerLine - 1);

                    this.angle = this.config.textAngle;
                    this.isSingleLeft = !this.isMultiLine && this.angle === -180;
                    this.isSingleRight = !this.isMultiLine && this.angle === 0;

                    this.startX = this.getStartX();
                    this.endX = config.width - this.startX;

                    this.startY = this.paddingY;
                    this.endY = config.height - this.paddingY;

                    const freeSpace = (this.workspaceWidth - this.stepX) / 2;
                    this.textWidthLimit = freeSpace / Math.cos(RouteBase.toRadians(this.config.textAngleOffset));

                    // Increase max label width for one-line and one-label-sided routes
                    if (this.isSingleLeft || this.isSingleRight) {
                        this.textWidthLimit *= this.SINGLE_LINE_TEXT_WIDTH_COEFFICIENT;
                    }
                }

                /**
                 * Calculate width limit for labels.
                 * Edge station labels should be shorter to fit the screen
                 *
                 * @param index
                 * @param angle
                 * @param coords
                 * @returns {any}
                 */
                getWithLimit(index, angle, coords) {
                    const isTop = coords.y === this.startY;
                    const isBottom = coords.y === this.endY;
                    const isAngleTop = angle < 0 && angle > -180;
                    const isAngleBottom = angle > 0 && angle < 180;
                    const edgeLimit = Math.abs(this.paddingY / Math.sin(RouteBase.toRadians(angle))) - this.fontSize;
                    return (isTop && isAngleTop || isBottom && isAngleBottom) && edgeLimit < this.textWidthLimit
                        ? edgeLimit
                        : this.textWidthLimit;
                };

                /**
                 * Calculate text angle for current station
                 *
                 * @param index
                 * @returns {*}
                 */
                getTextAngle(index) {
                    let angle = 0;
                    switch (true) {
                        case this.isMultiLine:
                            angle = this.getLineIndex(index) % 2 * 180;
                            angle += this.config.flipHorizontal ? 0 : -180;
                            break;
                        case angular.isObject(this.config.textAngle):
                            angle = index % 2 === 0
                                ? this.config.textAngle.even
                                : this.config.textAngle.odd;
                            break;
                        default:
                            angle = this.config.textAngle;
                    }
                    return angle + this.config.textAngleOffset;
                }

                /**
                 * Calculate X coordinate for first point
                 *
                 * @returns {number}
                 */
                getStartX() {
                    const linesCount = this.getLinesCount();
                    const graphWidth = this.stepX * (linesCount - 1);
                    const leftDistance = (this.workspaceWidth - graphWidth) / 2;

                    let startX = this.paddingX + leftDistance;
                    const offset = this.workspaceWidth / 4;

                    if (this.isSingleLeft) {
                        startX += offset;
                    }

                    if (this.isSingleRight) {
                        startX -= offset;
                    }

                    return startX;
                }

                /**
                 * Calculating X coordinate of a station by array index
                 *
                 * @param index
                 * @returns {number}
                 */
                getX(index) {
                    const line = this.getLineIndex(index);
                    return this.config.flipHorizontal
                        ? this.endX - line * this.stepX
                        : this.startX + line * this.stepX;
                }

                /**
                 * Calculating X coordinate of a station by array index
                 *
                 * @param index
                 * @returns {number}
                 */
                getY(index) {
                    const line = this.getLineIndex(index);
                    const internalIndex = index % this.stationsPerLine;    // station index inside a single line
                    const fromTop = this.startY + internalIndex * this.stepY;
                    const fromBottom = this.endY - internalIndex * this.stepY;
                    const flipVertical = this.config.flipVertical;
                    return !RouteBase.isLineReversed(line)
                        ? flipVertical ? fromBottom : fromTop
                        : flipVertical ? fromTop : fromBottom

                }
            }

            return RouteVertical;
        });
})();
(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasOrderFilters', {
            templateUrl:
                '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-order-filters/timetable-hafas-order-filters.tpl.html',
            controller: TimetableHafasOrderFilters,
            bindings: {
                contentData: '=',
            }
        });

    /**
     * @param {TIMETABLE_ORDER} TIMETABLE_ORDER 
     * 
     * @property {TimetableHafasContent} contentData
     * 
     * @constructor
     */
    function TimetableHafasOrderFilters(
        TIMETABLE_ORDER,
    ) {
        const $ctrl = this;

        $ctrl.orderFilters = [
            {
                value: TIMETABLE_ORDER.PRODUCT,
                label: 'PRODUCT'
            },
            {
                value: TIMETABLE_ORDER.REAL_TIME,
                label: 'REAL_TIME',
            },
            {
                value: TIMETABLE_ORDER.SCHEDULED_TIME,
                label: 'SCHEDULED_TIME',
            }
        ];

        $ctrl.addFilter = () => {
            $ctrl.contentData.data.order.push({
                by: null,
                direction: 'asc',
            });
        };

        $ctrl.removeFilter = (index) => {
            $ctrl.contentData.data.order.splice(index, 1);
        };
    }

})();

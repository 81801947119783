(function() {
    'use strict';

    angular.module('beacon.app')
        .component('profileFeaturePermissions', {
            templateUrl: '/assets/views/share-park/customers/profiles/new/feature-permissions/feature-permissions.tpl.html',
            controller: ProfileFeaturePermissionsController,
            bindings: {
                featurePermissions: '<'
            }
        });

    function ProfileFeaturePermissionsController() {
        const vm = this;
    }
})();
(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.factory('locationGroupAddState', function() {
        return {
            finish: 0
        };
    });

    app.controller('LocationGroupAddToolbarController', LocationGroupAddToolbarController);

    function LocationGroupAddToolbarController(
        $translate,
        locationGroupAddState,
    ) {
        const vm = this;

        // public properties
        vm.state = locationGroupAddState;
        vm.state.finish = 0;

        // public methods
        vm.finish = finish;

        /**
         * Saves content group
         */
        function finish() {
            vm.state.finish++;
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('preferenceFilters', {
            templateUrl: '/assets/views/common/directives/audience-picker/preference-filters/preference-filters.tpl.html',
            controller: PreferenceFiltersController,
            bindings: {
                filterValues: '=',
                preferenceAudience: '=',
                disabled: '<',
                updateRecipientsCount: '=',
            }
        });

    function PreferenceFiltersController(
        AudiencesService,
        PREFERENCE_TYPE
    ) {
        const vm = this;

        vm.PREFERENCE_TYPE = PREFERENCE_TYPE;
        const MULTISELECT_PREFERENCE_TYPES = [PREFERENCE_TYPE.SPINNER, PREFERENCE_TYPE.MULTIBUTTON];

        // public methods
        vm.isFilterChecked = isFilterChecked;
        vm.isCustomCheckboxChecked = isCustomCheckboxChecked;
        vm.initPreferenceData = initPreferenceData;
        vm.initSliderData = initSliderData;
        vm.getCheckboxLabel = getCheckboxLabel;
        vm.onCustomCheckboxClick = onCustomCheckboxClick;

        /**
         * Checks if filter value is checked
         * @param {int} filterValueId
         * @return {boolean}
         */
        function isFilterChecked(filterValueId) {
            return vm.preferenceAudience.audienceValues.includes(filterValueId);
        }

        /**
         * Checks if custom checkbox is checked
         * @param filterValue
         * @param valueIndex
         * @return {boolean}
         */
        function isCustomCheckboxChecked(filterValue, valueIndex) {
            const selectedValuesIndexes = vm.preferenceAudience.preferenceData[filterValue.id].values;
            return selectedValuesIndexes && selectedValuesIndexes.includes(valueIndex);
        }

        /**
         * Inits preference data
         * @param filterValue
         */
        function initPreferenceData(filterValue) {
            if (angular.isUndefined(vm.preferenceAudience.preferenceData[filterValue.id])) {
                const data = {
                    type: filterValue.filterValueParams.type,
                };
                if (MULTISELECT_PREFERENCE_TYPES.includes(data.type)) {
                    data.values = [];
                } else {
                    data.value = false;
                }
                vm.preferenceAudience.preferenceData[filterValue.id] = data;
            }
        }

        /**
         * Init slider data
         * @param filterValue
         */
        function initSliderData(filterValue) {
            if (angular.isUndefined(vm.preferenceAudience.preferenceData[filterValue.id])) {
                vm.preferenceAudience.preferenceData[filterValue.id] = {
                    type: filterValue.filterValueParams.type,
                    rangeData: {
                        minValue: filterValue.filterValueParams.min,
                        maxValue: filterValue.filterValueParams.max,
                        outOfRange: false,
                    },
                };
            }

            filterValue.sliderOptions = {
                floor: filterValue.filterValueParams.min,
                ceil: filterValue.filterValueParams.max,
                step: 1,
                disabled: vm.disabled,
                onChange: vm.updateRecipientsCount,
            };
            AudiencesService.updateSlider();
            vm.updateRecipientsCount();
        }

        /**
         * Returns label for a checkbox
         * @param filterValue
         * @return {string}
         */
        function getCheckboxLabel(filterValue) {
            return `${filterValue.filterCategoryValue} (${filterValue.filterValueParams.labelOn}/
            ${filterValue.filterValueParams.labelOff})`;
        }

        /**
         * Handles custom checkbox click (for spinner and multibutton types)
         * @param filterValue
         * @param valueIndex
         */
        function onCustomCheckboxClick(filterValue, valueIndex) {
            const valuesIndexes = vm.preferenceAudience.preferenceData[filterValue.id].values;
            const index = valuesIndexes.indexOf(valueIndex);
            if (index !== -1) {
                valuesIndexes.splice(index, 1);
            } else {
                valuesIndexes.push(valueIndex);
            }
            vm.updateRecipientsCount();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app').directive('imageLoader', imageLoader);

    function imageLoader() {
        return {
            restrict: 'EA',
            templateUrl: '/assets/views/common/directives/image-loader/image-loader.tpl.html',
            replace: true,
            controller: ImageLoaderController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                alwaysCrop: '<?',
                onImage: '=',
                onImageDelete: '=',
                imageSrc: '<?',
                loadBtnText: '<?',
                imageName: '<?',
                getImageCropPopup: '=',
                label: '<?',
                loaderConfig: '<?',
                externalImageUrl: '<?',
                externalImageLabel: '@?',
            }
        };
    }

    function ImageLoaderController(
        $scope,
        PopupService,
        UtilitiesService,
        $translate,
        ImageUploadService,
    ) {
        const vm = this;

        // public properties
        vm.acceptTypes = [
            'image/jpeg',
            'image/png',
            'image/gif',
        ];

        init();

        // public methods
        vm.cropImage = cropImage;
        vm.showImagePopup = showImagePopup;
        vm.showImageLoadPopup = showImageLoadPopup;




        /**
         * Initialization method
         */
        function init() {
            vm.loaderConfig = _.defaults(vm.loaderConfig || {}, {
                minWidth: 400,
                minHeight: 300,
                center: false,
                fullInfo: false,
                aspectRatio: null
            });

            $translate('LOAD_IMAGE_PLACEHOLDER')
                .then(translation => {
                    vm.placeholder = translation;
                }, translationId => {
                    vm.placeholder = translationId;
                });

            vm.getImageCropPopup && vm.getImageCropPopup(cropImage);
        }

        /**
         * Image load callback
         * @param {File} file
         * @param {boolean} uploadToS3
         * @returns {*}
         */
        function cropImage(file, uploadToS3) {
            const urlCreator = window.URL || window.webkitURL;
            const {minWidth, minHeight, center, fullInfo, aspectRatio} = vm.loaderConfig;

            if (!file || !vm.acceptTypes.includes(file.type)) {
                angular.element('input[type="file"].image').val(null);
                $translate('ALLOWED_FILE_TYPES').then(translation => {
                    PopupService.showAlertPopup({
                        text: `${translation}: ${vm.acceptTypes.join(', ')}`,
                        okButtonText: 'OK',
                    });
                });
                return;
            }

            if (uploadToS3) {
                if (isGif(file.type)) {
                    validateImageSize(file).then(() => {
                        convertFileToBlob(file).then(blob => {
                            if (!fullInfo) {
                                vm.onImage(blob, uploadToS3);
                                return;
                            }

                            blob.name = file.name;
                            vm.onImage({blob, cropData: null}, uploadToS3);                            
                        });
                    });
                } else {
                    ImageUploadService.crop({
                        file,
                        minWidth,
                        minHeight,
                        center,
                        fullInfo,
                        aspectRatio
                    }).then(result => {
                        if (fullInfo) {
                            result.blob.name = file.name
                        }
                        vm.onImage(result, uploadToS3);
                    }).catch(() => {
                        angular.element('input[type="file"].image').val(null);
                    });
                }
            } else {
                validateImageSize(file)
                    .then(() => {
                        if (fullInfo) {
                            vm.onImage({blob: file, cropData: null}, uploadToS3);
                        } else {
                            vm.onImage(file, uploadToS3);
                        } 
                    });
            }
        }

        /**
         * @param {File} file
         * @returns {Promise<void>}
         */
        function validateImageSize(file) {
            const { minWidth, minHeight } = vm.loaderConfig;
            const urlCreator = window.URL || window.webkitURL;
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = () => {
                    if (image.width >= minWidth && image.height >= minHeight) {
                        resolve();
                    } else {
                        $translate('IMAGE_CANT_BE_ADDED_MINIMAL_SIZE_IS').then(translation => {
                            PopupService.showAlertPopup({
                                text: `${translation} ${minWidth}*${minHeight}`,
                                okButtonText: 'OK'
                            });
                        });
                        reject();
                    }
                };
                image.src = urlCreator.createObjectURL(file);
            })
        }

        /**
         * @param {File} file
         * @returns {Promise<Blob>}
         */
        function convertFileToBlob(file) {
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(file);
            return fetch(imageUrl)
                .then(response => response.blob())
        }

        /**
         * @param {string} type
         * @returns {boolean}
         */
        function isGif(type) {
            return type === 'image/gif';
        }

        /**
         * Shows image preview popup
         */
        function showImagePopup() {
            ImageUploadService.preview({
                okButtonText: 'Ok',
                imageUrl: vm.imageSrc
            });
        }

        /**
         * Show popup for loading image
         */
        function showImageLoadPopup() {
            ImageUploadService.upload({
                accept: vm.acceptTypes.join(','),
                placeholder: vm.placeholder,
                alwaysCrop: vm.alwaysCrop || false,
                onImage: vm.cropImage,
                externalImageUrl: vm.externalImageUrl,
                externalImageLabel: vm.externalImageLabel,
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('PlaylistAppPopupController', PlaylistAppPopupController);

    /**
     * @callback PlaylistAppPopupController~SelectPlaylistItemCallback
     * @param {PlaylistItem|string} item
     */

    /**
     *
     * @param {{
     *     addField: PlaylistAppPopupController~SelectPlaylistItemCallback,
     *     contentData: DigitalDisplayContent,
     *     index: number|undefined,
     *     interval: number,
     *     orientation: string|undefined,
     *     pageData: PlaylistPage,
     *     type: string,
     *     splitScreens: SplitScreenPage[],
     * }} data
     * @param $q
     * @param $uibModalInstance
     * @param ContentDataService
     * @param PermissionsService
     * @param UtilitiesService
     * @param CONTENT_TYPES
     * @param PAGE_TYPES
     * @param {PLAYLIST_ITEM_TYPE} PLAYLIST_ITEM_TYPE
     * @param TIMETABLE_TYPE
     * @param ContentHelper
     *
     * @constructor
     */
    function PlaylistAppPopupController(
        data,
        $q,
        $uibModalInstance,
        ContentDataService,
        PermissionsService,
        UtilitiesService,
        CONTENT_TYPES,
        PAGE_TYPES,
        PLAYLIST_ITEM_TYPE,
        TIMETABLE_TYPE,
        ContentHelper,
    ) {
        const vm = this;

        let internalAppTemplatesPath = '/assets/views/common/popups/app-playlist/internal-apps-templates/';
        //  defines if a user try to add a new internal/external app or click on existing
        let isAddNewApp = data.orientation === undefined;
        let defaultInternalAppList = [
            {
                type: PLAYLIST_ITEM_TYPE.INFOTAINMENT,
                label: 'Info Element',
                isVisible: PermissionsService.isPlaylistInfoElementTabVisible(),
                template: internalAppTemplatesPath + 'internal-info-element-tab.tpl.html',
            }, {
                type: PLAYLIST_ITEM_TYPE.TIMETABLE,
                label: 'Timetable',
                isVisible: PermissionsService.isPlaylistTimetableTabVisible(),
                template: internalAppTemplatesPath + 'internal-timetable-tab.tpl.html',
            }, {
                type: PLAYLIST_ITEM_TYPE.WEATHER,
                label: 'Weather',
                isVisible: PermissionsService.isPlaylistWeatherTabVisible(),
                template: internalAppTemplatesPath + 'internal-weather-tab.tpl.html',
            },
            {
                type: PLAYLIST_ITEM_TYPE.LINE_ROUTE,
                label: 'Line Route',
                isVisible: PermissionsService.isPermissionAvailable('can_view_lineroute'),
                template: internalAppTemplatesPath + 'internal-line-route-tab.tpl.html',
            },
            {
                type: PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT,
                label: 'Dynamic Content',
                isVisible: PermissionsService.isPermissionAvailable('can_view_dynamic_content'),
                template: internalAppTemplatesPath + 'internal-dynamic-content-tab.tpl.html',
            },
            {
                type: PLAYLIST_ITEM_TYPE.TIMETABLE_HAFAS,
                label: 'Timetable (HAFAS)',
                isVisible: PermissionsService.isPermissionAvailable('can_view_timetable_hafas'),
                template: internalAppTemplatesPath + 'internal-timetable-hafas-tab.tpl.html',
            },
            {
                type: PLAYLIST_ITEM_TYPE.HIM,
                label: 'HIM Content',
                isVisible: PermissionsService.isPermissionAvailable('can_view_him'),
                template: internalAppTemplatesPath + 'internal-him-tab.tpl.html',
            },
        ];
        vm.splitScreenTemplatePath = internalAppTemplatesPath + 'internal-split-screen-popup.tpl.html';

        const TIMETABLE_TAB_INDEX = 1;

        vm.data = data;
        vm.availableInternalAppList = defaultInternalAppList.filter((tab) => tab.isVisible === true);

        vm.isInternalAppPopupShown = data.type.indexOf('internal') !== -1
            || data.type === PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE;
        vm.isExternalAppPopupShown = data.type === PLAYLIST_ITEM_TYPE.EXTERNAL;
        vm.isSplitScreenPopupShown = data.type === PLAYLIST_ITEM_TYPE.SPLIT_SCREEN;

        //  set default value for selectedTabIndex if it undefined
        vm.data.selectedTabIndex = vm.data.selectedTabIndex !== undefined ? vm.data.selectedTabIndex : 0;

        vm.data.internalTimetables = [];
        vm.data.externalTimetables = [];

        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.getTitle = ContentHelper.getDefaultTitle;
        vm.getContentTitle = ContentHelper.getContentNameForList;

        init();

        function init() {
            if (!isAddNewApp) {
                /** @type {PlaylistItem} */
                vm.item = vm.data.pageData.page.items[vm.data.orientation][vm.data.index];

                let appType = vm.item.type;

                if (appType === PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE) {
                    vm.data.selectedTabIndex = TIMETABLE_TAB_INDEX;
                } else {
                    for (let idx in vm.availableInternalAppList) {
                        if (appType === vm.availableInternalAppList[idx].type) {
                            vm.data.selectedTabIndex = Number(idx);
                        }
                    }
                }
            }

            if (vm.isInternalAppPopupShown) {
                if (PermissionsService.isPlaylistTimetableTabVisible()) {
                    loadTimetableTabData();
                }

                if (PermissionsService.isPlaylistWeatherTabVisible()) {
                    loadWeatherTabData();
                }

                if (PermissionsService.isPermissionAvailable('can_view_lineroute')) {
                    loadLineRouteTabData();
                }

                if (PermissionsService.isPermissionAvailable('can_view_dynamic_content')) {
                    loadDynamicContentTabData();
                }

                if (PermissionsService.isPermissionAvailable('can_view_timetable_hafas')) {
                    loadTimetableHafasTabData();
                }

                if (PermissionsService.isPermissionAvailable('can_view_timetable_hafas')) {
                    loadHimTabData();
                }
            }

            if (vm.isSplitScreenPopupShown && vm.item && vm.item.pageStaticId) {
                vm.data.splitScreenItem = vm.data.splitScreens.find(item => vm.item.pageStaticId === item.staticId);
            }
        }

        function cleanPage() {
            const page = vm.data.pageData.page;

            delete page.item;
            delete page.type;
            delete page.basicAuth;
            delete page.authLogin;
            delete page.authPass;
            delete page.externalLink;
            delete page.selectedContent;
            delete page.selectedCampaign;
            delete page.internalCategories;
        }

        function loadTimetableTabData() {
            const promises = [];

            if (PermissionsService.isPermissionAvailable('can_view_timetable')) {
                promises.push(
                    ContentDataService.contents({
                        contentTypeId: CONTENT_TYPES.TIMETABLE
                    }).then(internal =>
                        vm.data.internalTimetables = internal.plain().map(content =>
                            ContentHelper.decodeContent(content)
                        ))
                );
            }

            if (PermissionsService.isPermissionAvailable('can_use_external_timetable')) {
                promises.push(
                    ContentDataService.getExternalTimetables()
                        .then(external => vm.data.externalTimetables = external.plain())
                );
            }

            $q.all(promises).then(() => {
                if (vm.item) {
                    switch (vm.item.type) {
                        case PLAYLIST_ITEM_TYPE.TIMETABLE:
                            vm.data.timetableItem = vm.data.internalTimetables.find(item => vm.item.contentId === item.id);
                            break;
                        case PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE:
                            vm.data.timetableItem = vm.data.externalTimetables.find(item => vm.item.externalLink === item.url);
                            break;
                    }
                }
            });
        }

        function loadWeatherTabData() {
            ContentDataService.contents({'contentTypeId': CONTENT_TYPES.WEATHER}).then(function(response) {
                vm.data.weathers = response.plain().map(content => ContentHelper.decodeContent(content));
                if (vm.item && vm.item.type === PLAYLIST_ITEM_TYPE.WEATHER) {
                    vm.data.weatherItem = vm.data.weathers.find((item) => vm.item.contentId === item.id);
                }
            });
        }

        /**
         * Loads data for line route tab
         */
        function loadLineRouteTabData() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.LINE_ROUTE})
                .then(response => {
                    vm.data.lineRoutes = response.plain().map(content => ContentHelper.decodeContent(content));
                    if (vm.item && vm.item.type === PLAYLIST_ITEM_TYPE.LINE_ROUTE) {
                        vm.data.lineRouteItem = vm.data.lineRoutes.find(item => vm.item.contentId === item.id);
                    }
                });
        }

        /**
         * Loads data for dynamic content tab
         */
        function loadDynamicContentTabData() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.DYNAMIC_CONTENT})
                .then(response => {
                    vm.data.dynamicContents = response.plain().map(content => ContentHelper.decodeContent(content));
                    if (vm.item && vm.item.type === PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT) {
                        vm.data.dynamicContentItem = vm.data.dynamicContents.find(item => vm.item.contentId === item.id);
                    }
                });
        }

        function loadTimetableHafasTabData() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.TIMETABLE_HAFAS})
                .then(response => {
                    vm.data.timetableHafasItems = response.plain().map(content => ContentHelper.decodeContent(content));
                    if (vm.item && vm.item.type === PLAYLIST_ITEM_TYPE.TIMETABLE_HAFAS) {
                        vm.data.timetableHafasItem = vm.data.timetableHafasItems.find(item => vm.item.contentId === item.id);
                    }
                });
        }

        function loadHimTabData() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.HIM})
                .then(response => {
                    vm.data.himItems = response.plain().map(content => ContentHelper.decodeContent(content));
                    if (vm.item && vm.item.type === PLAYLIST_ITEM_TYPE.HIM) {
                        vm.data.himItem = vm.data.himItems.find(item => vm.item.contentId === item.id);
                    }
                });
        }

        function okHandler() {
            if (!angular.isFunction(data.addField)) {
                cancelHandler();
                return;
            }

            let page = vm.data.pageData.page;

            /** @type {PlaylistItem} */
            const item = {
                title: null,
                type: vm.data.type.indexOf('internal') !== -1
                    ? vm.availableInternalAppList[vm.data.selectedTabIndex].type
                    : vm.data.type,
            };
            const title = ContentHelper.getDefaultTitle;

            switch (item.type) {
                case PLAYLIST_ITEM_TYPE.INFOTAINMENT:
                case PLAYLIST_ITEM_TYPE.EXTERNAL:
                    if (!page.item) {
                        return cancelHandler();
                    }
                    item.contentId = page.item.id;
                    item.title = title(page.item);
                    item.content = page.item;
                    break;
                case PLAYLIST_ITEM_TYPE.TIMETABLE:
                    const timetable = vm.data.timetableItem;
                    // Internal timetable
                    if (timetable.id) {
                        item.contentId = timetable.id;
                        item.title = title(timetable);

                    // External timetable for created (replace "internal timetable" type with "external timetable")
                    } else {
                        item.title = timetable.name;
                        item.externalLink = timetable.url;
                        item.type = PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE;
                    }
                    break;
                // External timetable for modified elements
                case PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE:
                    item.title = vm.data.timetableItem.name;
                    item.externalLink = vm.data.timetableItem.url;
                    break;
                case PLAYLIST_ITEM_TYPE.WEATHER:
                    item.contentId = vm.data.weatherItem.id;
                    item.title = title(vm.data.weatherItem);
                    break;
                case PLAYLIST_ITEM_TYPE.LINE_ROUTE:
                    item.contentId = vm.data.lineRouteItem.id;
                    item.title = title(vm.data.lineRouteItem);
                    item.content = vm.data.lineRouteItem;
                    break;
                case PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT:
                    item.contentId = vm.data.dynamicContentItem.id;
                    item.title = vm.getContentTitle(vm.data.dynamicContentItem);
                    item.content = vm.data.dynamicContentItem;
                    break;
                case PLAYLIST_ITEM_TYPE.TIMETABLE_HAFAS:
                    item.contentId = vm.data.timetableHafasItem.id;
                    item.title = vm.getContentTitle(vm.data.timetableHafasItem);
                    item.content = vm.data.timetableHafasItem;
                    break;
                case PLAYLIST_ITEM_TYPE.HIM:
                    item.contentId = vm.data.himItem.id;
                    item.title = vm.getContentTitle(vm.data.himItem);
                    item.content = vm.data.himItem;
                    break;
                case PLAYLIST_ITEM_TYPE.SPLIT_SCREEN:
                    item.pageStaticId = vm.data.splitScreenItem.staticId;
                    item.title = vm.data.splitScreenItem.header.title;
                    break;
                default:
                    return cancelHandler();
            }

            if (vm.data.index !== undefined) {
                let items = vm.data.pageData.page.items[vm.data.orientation];
                items[vm.data.index] = item;
            } else {
                vm.data.addField(item);
            }

            cancelHandler();
        }

        function cancelHandler() {
            cleanPage();
            $uibModalInstance.dismiss('cancel');
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LoginController', LoginController);

    function LoginController(
        LoginService,
        $state,
        $translate,
        $window,
        mdcDefaultParams,
        $auth,
        $timeout,
    ) {
        const vm = this;

        vm.dismissibleAlertConfig = {
            type: 'success',        //  types: error, success
            isVisible: false,
            message: '',
            hide: function() {
                this.isVisible = false;
            },
            showError: function(msg, translationValues = {}) {
                this.type = 'error';
                this.message = msg;
                this.translationValues = translationValues;
                this.isVisible = false;

                $timeout(() => {
                    this.isVisible = true;
                });
            },
            showSuccess: function(msg) {
                this.type = 'success';
                this.isVisible = true;
                this.message = msg;
            },
        };
        vm.credentials = {
            name: '',
            password: ''
        };

        vm.validateUser = validateUser;
        vm.signup = signup;
        vm.resetPassword = resetPassword;
        vm.checkEnterKey = checkEnterKey;
        vm.onOAuthError = onOAuthError;

        function onOAuthError({ data }) {
            if (!(data && data.errorMessage)) {
                return;
            }

            vm.dismissibleAlertConfig.showError(data.errorMessage);
        }

        /**
         * Function to check pressed key
         *
         * @param {jQuery.Event} $event
         */
        function checkEnterKey($event) {
            if ($event.keyCode === 13) {
                validateUser();
            }
        }

        /**
         * Validates user and redirects to 'choose tenant' page or authorizes him into application (when only one tenant is available)
         */
        function validateUser() {
            if (!vm.credentials.name || !vm.credentials.password) {
                vm.dismissibleAlertConfig.showError('PLEASE_FILL_OUT_USERNAME_AND_PASSWORD_FIELDS');
                return;
            }

            if (vm.credentials.name.includes('@')) {
                vm.credentials.name = vm.credentials.name.toLowerCase();
            }

            $auth.login(vm.credentials)
                .then(_ => afterLogin())
                .catch(response => validateUserErrorHandler(response));
        }

        function afterLogin() {
            LoginService.getUserRelations().then(({tenants, langCode, userId}) => {
                let tenantsCount = tenants.length;

                $translate.use(langCode);
                moment.locale(langCode);
                mdcDefaultParams({lang: langCode});
                $window.localStorage.uiLang = langCode;


                if (!tenantsCount) {
                    vm.dismissibleAlertConfig.showError('YOU_DO_NOT_HAVE_ANY_AVAILABLE_SUBDOMAINS_FOR_LOGIN');
                    return;
                }

                if (tenantsCount > 1 || tenants[0] && tenants[0].subdomains && tenants[0].subdomains.length > 1) {
                    $state.go('chooseTenant', {
                        data: {
                            tenants,
                            userId,
                        }
                    });
                    return;
                }

                LoginService.authorize(userId, tenants[0].subdomains[0].id, () => {
                    LoginService.refreshToken().then(response => {
                        if (response.token) {
                            $auth.setToken(response.token);
                            $state.go('app.content', {});
                        } else {
                            alert('Internal server error');
                        }
                    });
                });
            });
        }

        /**
         * Validate user error response handler
         *
         * @param response
         */
        function validateUserErrorHandler(response) {
            let msg = '';
            let translationValues = {};

            switch (response.status) {
                case 401:
                    const error = parseError(response.data.errorMessage)
                    msg = error.message || 'ACCESS_IS_DENIED_DUE_TO_INVALID_CREDENTIALS';
                    translationValues = error.translationValues;
                    break;
                default:
                    msg = 'SERVER_ERROR_PLEASE_CONTACT_YOUR_SYSTEM_ADMIN';
                    break;
            }

            vm.dismissibleAlertConfig.showError(msg, translationValues);
        }

        /**
         *
         * @param errorMessage
         * @returns {{translationValues: {}, message: string}}
         */
        function parseError(errorMessage) {
            const [message, translationValuesEncoded] = errorMessage.split(';');
            const translationValues = {};
            translationValuesEncoded && translationValuesEncoded.split(',').forEach(item => {
                const [prop, value] = item.split(':');
                translationValues[prop] = value;
            })
            return {
                message,
                translationValues,
            }
        }

        /**
         * Renders "sign up" page view
         */
        function signup() {
            $state.go('signup', {});
        }

        /**
         * Renders "reset-password" page view
         */
        function resetPassword() {
            $state.go('resetPassword', {});
        }

        /**
         * Initialization method
         */
        function init() {
            if ($state.params.message) {
                vm.dismissibleAlertConfig.showSuccess($state.params.message);
            }

            if ($state.params.errorMessage) {
                vm.dismissibleAlertConfig.showError($state.params.errorMessage);
            }
        }

        init();
    }
}());

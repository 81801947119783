(function () {
    'use strict';

    angular
        .module('beacon.app')
        .service('ReportsService', ReportsService);

    function ReportsService($http, $mdDialog) {
        return {
            getReportList,
            runReport,
            downloadReport
        };

        function getReportList() {
            return $http.get('/api/sharePark/reports');
        }

        function runReport(id, isPreview = false, data) {
            return $http.post(`/api/sharePark/run-report?id=${id}&isPreview=${isPreview}`, data);
        }

        function downloadReport(id, isDownload = false) {
            return $http.get(`/api/sharePark/download-report-csv?id=${id}&isDownload=${isDownload}`);
        }

    }
})();
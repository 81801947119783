(function() {
    'use strict';

    angular.module('beacon.app')
        .component('datetimePickerWrapper', {
            controller: DatetimePickerWrapperController,
            templateUrl: '/assets/views/common/components/datetime-picker-wrapper/datetime-picker-wrapper.tpl.html',
            bindings: {
                value: '=',
                label: '@',
                form: '<',
                name: '@',
                time: '<',
                date: '<',
                minuteSteps: '<',
                minDate: '<',
                maxDate: '<',
                required: '<',
                isDisabled: '<',
                minErrMessage: '@',
                maxErrMessage: '@',
                onChange: '<',
                clearButton: '<',
                hideIcon: '<',
            },
        });

    function DatetimePickerWrapperController(
        $scope,
        $timeout,
        $translate,
        mdcDateTimeDialog,
        DateHelper,
    ) {
        const vm = this;

        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

        vm.$onInit = init;

        vm.inputValue = '';
        vm.okBtnText = 'OK';
        vm.cancelBtnText = 'CANCEL';
        vm.todayBtnText = 'TODAY';

        vm.showFieldError = showFieldError;
        vm.showDialog = showDialog;
        vm.onInputValueChange = onInputValueChange;
        vm.clear = clear;

        function init() {
            vm.format = vm.date
                ? vm.time
                    ? DATE_TIME_FORMAT
                    : DATE_FORMAT
                : TIME_FORMAT;
            const passedValue = moment(vm.value);
            vm.inputValue = (vm.value && passedValue.isValid()) ? passedValue.format(vm.format) : '';

            $translate('OK').then(translation => {
                vm.okBtnText = translation;
            });

            $translate('CANCEL').then(translation => {
                vm.cancelBtnText = translation;
            });

            $translate('TODAY').then(translation => {
                vm.todayBtnText = translation;
            });
        }

        function showFieldError() {
            if (!vm.form || !vm.name) {
                return;
            }

            const field = vm.form[vm.name];
            return field.$dirty && field.$invalid;
        }

        function showDialog() {
            mdcDateTimeDialog.show({
                date: vm.date,
                time: vm.time,
                minuteSteps: vm.minuteSteps,
                minDate: vm.minDate,
                maxDate: vm.maxDate,
                currentDate: vm.value,
                todayBtn: vm.date,
                okText: vm.okBtnText,
                cancelText: vm.cancelBtnText,
                todayText: vm.todayBtnText,
            }).then(date => {
                vm.value = moment(date);
                vm.inputValue = vm.value.format(vm.format);

                if (angular.isFunction(vm.onChange)) {
                    $timeout(vm.onChange);
                }
            });
        }

        function clear(date) {
            vm.value = null;
            vm.inputValue = null;

            _triggerChange();
        }

        function onInputValueChange() {
            if (!vm.form || validateInputValue()) {
                if (!vm.inputValue && !vm.required) {
                    vm.value = null;
                    return;
                }

                const parsedValue = moment(vm.inputValue, vm.format, true);
                const previousValue = vm.value ? moment(vm.value) : moment();
                vm.value = (vm.time && !vm.date)
                    ? previousValue.set({
                        hour: parsedValue.get('hour'),
                        minute: parsedValue.get('minute'),
                    })
                    : parsedValue;
            }

            _triggerChange();
        }


        function validateInputValue() {
            if (!vm.inputValue && !vm.required) {
                setValid();
                return true;
            }
            const parsedByFormat = moment(vm.inputValue, vm.format, true);
            vm.form[vm.name].$setValidity('format', parsedByFormat.isValid());

            if (!parsedByFormat.isValid()) {
                return false;
            }

            const previousValue = vm.value ? moment(vm.value) : moment();
            const newValue = (vm.time && !vm.date)
                ? previousValue.set({
                    hour: parsedByFormat.get('hour'),
                    minute: parsedByFormat.get('minute'),
                })
                : parsedByFormat;

            if (vm.minDate) {
                vm.form[vm.name].$setValidity('min', !newValue.isBefore(vm.minDate));
            }

            if (vm.maxDate) {
                vm.form[vm.name].$setValidity('max', !newValue.isAfter(vm.maxDate));
            }

            return vm.form[vm.name].$valid;
        }

        function setValid() {
            [
                'format',
                'min',
                'max'
            ].forEach(key =>
                vm.form[vm.name].$setValidity(key, true)
            );
        }

        function _triggerChange() {
            if (angular.isFunction(vm.onChange)) {
                $timeout(vm.onChange);
            }
        }

        $scope.$watchGroup([
            () => vm.minDate,
            () => vm.maxDate,
            () => vm.required,
        ], () => {
            if (!vm.form || !vm.name) {
                return;
            }
            validateInputValue();
        });

        $scope.$watch(
            () => vm.value,
            (newValue, oldValue) => {
                if (!vm.form
                    || !vm.name
                    || newValue === oldValue
                    || moment(newValue).isSame(oldValue)
                ) {
                    return;
                }

                vm.inputValue = moment(newValue).isValid()
                    ? moment(vm.value).format(vm.format)
                    : '';
                validateInputValue();
            }
        )
    }
})();

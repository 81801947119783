(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkTariffTab', {
            templateUrl: '/assets/views/share-park/car-parks/new/tabs/car-park-tariff/car-park-tariff-tab.tpl.html',
            controller: CarParkTariffTabController,
            bindings: {
                carParkData: '<',
            }
        });

    function CarParkTariffTabController(
        $q,
        ModelsFactory,
        StatesFactory,
        PricingDataService,
        LanguageService,
    ) {
        const vm = this;

        vm.state = StatesFactory.CarParkStates;
        vm.state.canFinish = true;
        const DATE_FORMAT = 'MMMM D, YYYY (HH:mm)';

        vm.$onInit = init;

        vm.rateModelsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'rate-model-name',
                    width: '25',
                    title: item => item.rateModel.name || ''
                },
                {
                    name: 'DESCRIPTION',
                    width: '25',
                    title: item => item.rateModel.description || ''
                },
                {
                    name: 'START_DATE',
                    width: '25',
                    title: item =>
                        item.start_date
                        ? moment(item.start_date).format(DATE_FORMAT)
                        : '',
                },
                {
                    name: 'END_DATE',
                    width: '25',
                    title: item =>
                        item.end_date
                        ? moment(item.end_date).format(DATE_FORMAT)
                        : '',
                },
            ],
            updateCallback: () => {},
            needCheckbox: true,
            additionalData: {
                langId: vm.carParkData.langId
            }
        };
        vm.rateModels = [];

        function init() {
            vm.carParkData.rateModelAssignments = [];
            const langsPromise = LanguageService.getLanguages()
                .then(response =>
                    vm.langsArray = response.plain()
                );
            const rateModelsPromise = PricingDataService.getAllTariffModels()
                .then(response => {
                    vm.rateModelsEncoded = response.plain();
                });

            $q.all([langsPromise, rateModelsPromise])
                .then(onDataLoaded);
            vm.state.canBack = true;
        }

        function onDataLoaded() {
            vm.rateModels = vm.rateModelsEncoded.map(rateModel => {
                rateModel.capRateAssignment.forEach(assignment => {
                    assignment.capRate = decodeCapRateData(assignment.capRate);
                });
                const result = {
                    rateModel
                };

                const assignment = vm.carParkData.rate_model_assignments.find(assignment =>
                    assignment.cap_rate_model_ref === rateModel.id
                );

                if (assignment) {
                    Object.assign(result, assignment);
                    vm.carParkData.rateModelAssignments.push(result);
                }

                return result;
            });
        }

        /**
         * Decodes cap rate data
         * @param capRate
         * @return {*}
         */
        function decodeCapRateData(capRate) {
            const decoded = angular.copy(capRate);
            const multiLangFields = ['name', 'description', 'nameShort', 'priceLabel', 'rateDescription'];
            decoded.validityDuration = angular.fromJson(capRate.validityDuration);

            multiLangFields.forEach(field => {
                const fieldValue = angular.fromJson(capRate[field]);
                const decodedFieldValue = {};

                Object.keys(fieldValue).forEach(langCode => {
                    const lang = vm.langsArray.find(lang =>
                        lang.code === langCode
                    );

                    if (!lang) {
                        return;
                    }

                    decodedFieldValue[lang.id] = fieldValue[langCode];
                });

                decoded[field] = decodedFieldValue;
            });

            return decoded;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkSubAreaForm', {
            templateUrl: '/assets/views/share-park/car-parks/new/tabs/car-park-sub-areas/form/car-park-sub-area-form.tpl.html',
            controller: CarParkSubAreaFormController,
            bindings: {
                subArea: '<',
                typesInUse: '<',
                mapDefaultCenter: '<',
                onSave: '&',
                onCancel: '&',
            }
        });

    function CarParkSubAreaFormController(
        $translate,
        $scope,
        $timeout,
        LanguageService,
        UtilitiesService,
        TenantHelper,
        LocationHelper,
    ) {
        const vm = this;

        vm.oldTemplate = vm.subArea.template;
        vm.oldType = vm.subArea.type;

        vm.$onInit = init;
        vm.onMapChange = onMapChange;
        vm.onTemplateChange = onTemplateChange;
        vm.isFormValid = isFormValid;

        function init() {
            $timeout(setErrorChecker);

            LanguageService.getLanguages()
                .then(response => {
                    vm.langArray = response.plain();
                    vm.lang = vm.langArray[0];
                });

            const tenant = TenantHelper.getCurrentTenant();
            vm.templates = JSON.parse(tenant.carParkSubAreaTemplates);
            vm.shape = shape(vm.subArea);
            setWatchers();
        }

        /**
         *
         * @param {CarParkSubArea} subArea
         * @returns {string}
         */
        function shape(subArea) {
            return LocationHelper.coloredArea(subArea.location, subArea.color);
        }

        /**
         *
         * @param {string} data
         */
        function onMapChange(data) {
            vm.subArea.location = data;
            $scope.$apply();
        }

        function onTemplateChange() {
            setTemplate(vm.subArea.template);
        }

        function setTemplate(templateName) {
            const template = vm.templates.find(item => item.name === templateName);
            vm.langArray.forEach(lang => {
                $translate(template.label, {}, null, null, lang.code).then(translation => {
                    if (translation) {
                        vm.subArea.label[lang.id] = translation;
                    }
                });
            });

            // vm.subArea.filter = template.filter;
            vm.subArea.public = template.public;
            vm.subArea.color = template.color || vm.subArea.color;
            const type = templateName + '_' + getNextTypeNumber(templateName);
            vm.subArea.type = templateName === vm.oldTemplate ? vm.oldType : type;
        }

        function getNextTypeNumber(template) {
            const sameTypeNumbers = vm.typesInUse
                .filter(type => type.includes(template))
                .map(type => Number(type.replace(template + '_', '')))
                .filter(number => angular.isNumber(number) && !Number.isNaN(number));
            return sameTypeNumbers.length
                ? Math.max(...sameTypeNumbers) + 1
                : 1;
        }

        function setErrorChecker() {
            vm.showError = UtilitiesService.inputValidator(vm.form);
        }

        function isFormValid() {
            return vm.form.$valid && vm.subArea.color;
        }

        function setWatchers() {
            $scope.$watch(
                () => vm.subArea.color,
                () => {
                    $scope.$broadcast('redraw-polyline', {polyline: shape(vm.subArea)})
                }
            );
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ChangePasswordPopupController', ChangePasswordPopupController);

    function ChangePasswordPopupController(
        $uibModalInstance,
        UserDataService,
        PopupService,
        UserUtilitiesService,
        StorageFactory,
    ) {
        const vm = this;

        // public methods
        vm.ok = okHandler;
        vm.cancel = closePopup;

        function okHandler() {
            UserDataService.changePassword(vm.password, vm.passwordOld)
                    .then(() => {
                        UserDataService.currentUserInfo()
                            .then(data => {
                                StorageFactory.Storage('Main').set('currentUserInfo', data.plain());
                            })
                            .then(_ => closePopup())
                            .then(
                                _ => PopupService.showAlertPopup({
                                    text: "PASSWORD_CHANGED_MESSAGE",
                                    okButtonText: 'OK'
                                })
                            );
                    })
                    .catch(response => {
                        console.log(response.data);
                        closePopup();
                        PopupService.showAlertPopup({
                            text: response.data.message || 'SERVER_ERROR_PLEASE_CONTACT_YOUR_SYSTEM_ADMIN',
                            okButtonText: 'OK'
                        });
                    });
        }

        function closePopup() {
            $uibModalInstance.dismiss('cancel');
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capReservationsParkingsTab', {
            templateUrl: '/assets/views/share-park/operations/reservations/tabs/car-parks-tab/car-parks-tab.tpl.html',
            controller: CapReservationsParkingsTabController,
            bindings: {
                selectedCarParks: '<',
                statisticsMode: '<',
                onCarParkSelect: '<',
                updateSelectedCarParks: '<',
            }
        });

    function CapReservationsParkingsTabController(
        ShareParkDataService,
        CAP_STATISTICS_MODES,
        UtilitiesService,
    ) {
        const vm = this;

        vm.$onInit = init;
        const { sortByStringProperty } = UtilitiesService;
        vm.filterCarParks = filterCarParks;
        vm.filteredCarParks = [];
        vm.searchValue = '';
        vm.carParks = [];
        vm.carParksCount = 0;
        vm.CAP_STATISTICS_MODES = CAP_STATISTICS_MODES;
        vm.carParksListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'car-park-title',
                    width: '30',
                    title: 'name',
                },
                {
                    name: 'ADDRESS',
                    width: '30',
                    title: item => `${item.address_city}, ${item.address_street}`,
                },
            ],
            itemClickCallback: onCarParkClick,
        };

        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;

        function init() {
            ShareParkDataService.getCarParks()
                .then(response => {
                    vm.carParks = sortByStringProperty(response, 'name');
                    filterCarParks();
                    vm.carParksCount = response.count;
                })
        }

         /**
         * Filters Car Park list
         */
         function filterCarParks() {
            vm.filteredCarParks = vm.searchValue
                ? vm.carParks.filter(carPark => {
                    return carPark.name.toUpperCase().includes(vm.searchValue.toUpperCase())
                })
                : vm.carParks;
        }

        function onCarParkClick() {
            vm.onCarParkSelect();
        }

        function selectAll() {
            vm.updateSelectedCarParks(angular.copy(vm.carParks))
        }
        
        function  deselectAll() {
            vm.updateSelectedCarParks([]);
        }
    }
})();

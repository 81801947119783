(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('timetableHafasTemplate', {
            templateUrl: '/screen/views/components/timetable-hafas/timetable-hafas-template/timetable-hafas-template.tpl.html',
            controller: TimetableHafasTemplateController,
            bindings: {
                api: '<',
                journeys: '<',
                hims: '<',
                disableHim: '<',
                isConnectionStable: '<',
                options: '<',
                colorsScheme: '<',
                onViewChanged: '&',
            }
        });

    /**
     * @property {TimetableApi} api
     * @property {Journey[]} journeys
     * @property {MgateHim[]} hims
     * @property {boolean} disableHim
     * @property {boolean} isConnectionStable
     * @property {TimetableHafasOptions} options
     * @property {'default'|'night'} colorsScheme
     * @property {function({maxJourneysPerView: number}): void} onViewChanged
     *
     * @param $timeout
     * @param {HTMLElement[]} $element
     * @param {TimetableHafasService} TimetableHafasService
     * @param {LogService} LogService
     *
     * @constructor
     */
    function TimetableHafasTemplateController(
        $timeout,
        $element,
        TimetableHafasService,
        LogService,
    ) {
        const $ctrl = this;
        const _componentEl = $element[0];
        const waitViewUpdate = TimetableHafasService.waitViewUpdate;
        const log = (...args) => LogService.colored('#999', '#FFF', 'Timetable (HAFAS) Template |', ...args);

        let _onResizeDebounceTimer;

        $ctrl.$onInit = () => {
            _initResizeHandler();

            waitViewUpdate()
                .then(() => {
                    _setMaxGridSize();
                    _callViewChangedEvent();
                });
        }

        $ctrl.$onDestroy = () => {
            removeEventListener('resize', _onResize);
        };

        function _onResize() {
            $timeout.cancel(_onResizeDebounceTimer);
            _onResizeDebounceTimer = $timeout(() => {
                log('Timetable (HAFAS) resize');
                _setMaxGridSize();
                _updateTableView();
                _callViewChangedEvent();
            }, 1000);
        }

        $ctrl.$onChanges = ({colorsScheme}) => {
            if (colorsScheme) {
                _setColors(colorsScheme.currentValue);
            }
        }

        $ctrl.onHimChanged = () => _updateTableView();

        function _callViewChangedEvent() {
            $ctrl.onViewChanged({
                maxJourneysPerView: $ctrl.maxCols * $ctrl.maxRows
            });
        }

        function _initResizeHandler() {
            addEventListener('resize', _onResize);
        }

        function _setMaxGridSize() {
            const maxPossibleWidth = _componentEl.offsetWidth;
            const maxPossibleHeight = _componentEl.offsetHeight;

            const maxGrid = TimetableHafasService.calculateGridSizePerArea(
                maxPossibleWidth,
                maxPossibleHeight,
                $ctrl.options,
            );

            $ctrl.maxRows = maxGrid.rows;
            $ctrl.maxCols = maxGrid.cols;

            log(
                'Max grid size', $ctrl.maxRows, 'x',$ctrl.maxCols,
                'Component area', _componentEl.offsetWidth, 'x', _componentEl.offsetHeight
            );
        }

        /**
         * @param {'default'|'night'} colorScheme
         * @private
         */
        function _setColors(colorScheme) {
            const colors = $ctrl.options.colors[colorScheme];

            _componentEl.style.background = colors.background;
            _componentEl.style.color = colors.text;
        }

        function _updateTableView() {
            void $ctrl.api.refreshView();
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('tenantAddState', function() {
            return {
                finish: 0
            };
        })
        .controller('TenantsController', TenantsController);

    function TenantsController($scope, tenantsTabState) {

        const vm = this;
        vm.state = tenantsTabState;

        // bindings for sub controllers
        vm.selectedTenant = false;
        vm.selectedSubdomain = false;

        // public methods
        vm.clearSelectedValues = clearSelectedValues;


        /**
         * Clears selected values
         *
         * @param {number} currentTab
         */
        function clearSelectedValues(currentTab) {
            switch (currentTab) {
                case vm.state.views.TENANTS:
                    vm.selectedTenant = false; // fall throught to clear all next values
                case vm.state.views.SUBDOMAINS:
                    vm.selectedSubdomain = false;
            }
        }

        $scope.$watch(angular.bind(vm, function() {
            return this.state.view;
        }), function(newValue, oldValue) {
            vm.clearSelectedValues(newValue);
        });
    }
}());

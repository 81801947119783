(function() {
    'use strict';

    angular.module('timetableOptions')
        .service('TimetableOptionsHelper', TimetableOptionsHelper);

    function TimetableOptionsHelper(TIMETABLE_OPTIONS) {
        return {
            options,
            makeRequestObject,
            setDefaults,
            paramsStringToArray,
        };

        /**
         * Array of timetable options
         *
         * @returns array
         */
        function options() {
            const options = [];
            Object.keys(TIMETABLE_OPTIONS).forEach(key => {
                const option = TIMETABLE_OPTIONS[key];
                option.localProp = key;
                options.push(option);
            });
            return options;
        }

        function setDefaults(currentOptions) {
            const timetableOptions = currentOptions || {};

            const allOptions = options();
            allOptions.forEach(item => {
                timetableOptions[item.localProp] = angular.isDefined(timetableOptions[item.localProp])
                    ? timetableOptions[item.localProp]
                    : angular.copy(item.defaultValue);

                if (item.mode) {
                    timetableOptions[item.mode.prop] = angular.isDefined(timetableOptions[item.mode.prop])
                        ? timetableOptions[item.mode.prop]
                        : angular.copy(item.mode.defaultValue);
                }
            });

            // TEMPORARY WORKAROUND FOR OLD VALUES
            if (!angular.isObject(timetableOptions[TIMETABLE_OPTIONS.poleFilter.prop])) {
                timetableOptions[TIMETABLE_OPTIONS.poleFilter.prop] = {};
            }

            return timetableOptions;
        }

        /**
         *
         * @param data
         * @param tenant
         */
        function makeRequestObject(data, tenant) {
            const object = {};
            const jsonParams = [
                TIMETABLE_OPTIONS.journeyNameFilter.prop,
                TIMETABLE_OPTIONS.platformFilter.prop,
                TIMETABLE_OPTIONS.productOrder.prop,
            ];
            Object.keys(data).forEach(localKey => {
                if (TIMETABLE_OPTIONS[localKey]
                    && tenant.allowedTimetableOptions.includes(TIMETABLE_OPTIONS[localKey].id)
                    && angular.isDefined(data[localKey])) {
                    const timetableOption = TIMETABLE_OPTIONS[localKey];
                    const prop = TIMETABLE_OPTIONS[localKey].prop;

                    switch (true) {
                        case jsonParams.includes(prop):
                            if (data[localKey] === '') {
                                return;
                            }
                            const value = TIMETABLE_OPTIONS[localKey].type === 'text'
                                ? paramsStringToArray(data[localKey], true)
                                : data[localKey];

                            if (value.length) {
                                object[prop] = angular.toJson(value);
                            }
                            break;
                        case prop === TIMETABLE_OPTIONS.poleFilter.prop:
                            if (!angular.isObject(data[localKey]) || !Object.keys(data[localKey]).length) {
                                return;
                            }
                            const poleFilterIds = Object.keys(data[localKey])
                                .filter(poleFilterId => angular.isObject(data[localKey][poleFilterId]));

                            const poleFilters = poleFilterIds.map(poleFilterId => data[localKey][poleFilterId]);

                            if (poleFilters.length) {
                                object[prop] = angular.toJson(poleFilterIds);

                                object.poleFilterDirectionArrow = angular.toJson(
                                  poleFilterIds.map(poleId => {
                                      return data.poleFilter && data.poleFilter[poleId] && data.poleFilter[poleId].arrow
                                        ? data.poleFilter[poleId].arrow.toString()
                                        : '0';
                                  })
                                );

                                if (data.showFilterNames) {
                                    object[TIMETABLE_OPTIONS.poleFilter.namesProp] = angular.toJson(
                                        poleFilters.map(({filterName}) => filterName)
                                    );
                                }
                            }
                            break;
                        case prop === TIMETABLE_OPTIONS.direction.prop:
                            if (!angular.isDefined(data[localKey].extId)) {
                                return;
                            }
                            object[prop] = data[localKey].extId;
                            break;
                        default:
                            if (data[localKey] === '') {
                                return;
                            }
                            object[prop] = data[localKey];
                    }

                    if (timetableOption.mode && angular.isDefined(data[timetableOption.mode.prop])) {
                        object[timetableOption.mode.prop] = data[timetableOption.mode.prop];
                    }
                }
            });
            return object;
        }

        /**
         *
         * @param {string} str
         * @param {boolean} filterArray
         * @return {*}
         */
        function paramsStringToArray(str, filterArray) {
            return str.split(',')
                .map(value => value.trim())
                .filter(value => !filterArray || !!value);
        }
    }
})();
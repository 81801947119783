(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LoyaltyDashboardController', LoyaltyDashboardController);

    function LoyaltyDashboardController(
        $state,
        $stateParams,
        StorageFactory,
        LoyaltyCampaignService,
        LoyaltyDashboardService,
        UtilitiesService,
    ) {
        const vm = this;

        _init();
        vm.STORAGE = new StorageFactory.Storage('Dashboard', true);

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    width: '25',
                    class: 'dashboard-list__column-title',
                    title: item => item.campaignName,
                },
                {
                    name: 'SUBSCRIBERS_PARTICIPANTS',
                    width: '15',
                    class: 'dashboard-list__column-number',
                    title: item => item.participants,
                },
                {
                    name: 'COUNTER_TOTAL',
                    width: '15',
                    class: 'dashboard-list__column-number',
                    title: item => {
                        return `${item.counterSum} ${_getUnit(item.counterUnits)}`
                    },
                },
                {
                    name: 'ACCOUNT_TOTAL',
                    width: '15',
                    class: 'dashboard-list__column-number',
                    title: item => {
                        return `${item.accountSum} ${_getUnit(item.accountUnits)}`
                    }
                },
                {
                    name: 'REWARDS_TOTAL',
                    width: '15',
                    class: 'dashboard-list__column-number',
                    title: item => {
                        return `${item.rewardSum} ${_getUnit(item.rewardUnits)}`
                    }
                },
                {
                    name: 'COST_TOTAL',
                    width: '15',
                    class: 'dashboard-list__column-number',
                    title: item => {
                        return `${item.costSum} ${_getUnit(item.costUnits)}`
                    }
                },
            ],
            buttons: {
                minWidth: '0',
                items: [
                    {
                        class: 'editBtn',
                        callback: _editCampaign,
                        permissionAction: 'can_modify_loyalty_campaign'
                    },
                ]
            },
            updateCallback: _updateList,
            generatePermissionName: _generatePermissionName,
        };

        /**
         * Controller initialization
         *
         * @private
         */
        function _init() {
            vm.paginationParams = $stateParams.paginationData;
        }

        /**
         * Update list callback
         *
         * @param {int} page
         * @param {int} itemsPerPage
         * @private
         */
        function _updateList(page, itemsPerPage) {
            const campaigns = vm.STORAGE.get('campaigns');
            if (campaigns) {
                _applyCampaignsToScope(campaigns, page, itemsPerPage);
            } else {
                LoyaltyDashboardService.get()
                    .then(response => {
                        const campaigns = response.plain();
                        vm.STORAGE.set('campaigns', campaigns);
                        _applyCampaignsToScope(campaigns, page, itemsPerPage);
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Applying campaigns to scope
         *
         * @param {array} campaigns
         * @param {int} page
         * @param {int} itemsPerPage
         * @private
         */
        function _applyCampaignsToScope(campaigns, page, itemsPerPage) {
            vm.totalItems = campaigns.length;
            vm.paginationParams = {page, itemsPerPage};
            vm.campaigns = UtilitiesService.getArrayPage(campaigns, page, itemsPerPage);
        }

        /**
         * Getting units for values
         *
         * @param {string|bool|null} value
         * @returns {string}
         * @private
         */
        function _getUnit(value) {
            return typeof value === 'string' ? value : ''
        }

        /**
         * Generating permission name
         *
         * @param {object} button
         * @returns {string}
         * @private
         */
        function _generatePermissionName(button) {
            return button.permissionAction;
        }

        /**
         * Edit campaign callback
         *
         * @param {object} $event
         * @param {object} item
         * @private
         */
        function _editCampaign($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            LoyaltyCampaignService.getSingleExternal(item.campaignRef).then(response => {
                const campaign = response.plain();
                $state.go('app.loyaltyCampaignsEdit', {
                    data: campaign,
                    paginationData: null,
                    type: 'edit'
                });
            }).catch(console.error.bind(console));
        }
    }
}());
(function() {
    'use strict';

    angular.module('lineRoute')
        .component('lineRouteCanvas', {
            templateUrl: '/assets/views/line-route/line-route-canvas/line-route-canvas.tpl.html',
            controller: LineRouteInfoController,
            bindings: {
                orientation: '<',
                lineRouteSettings: '<',
                departureDetails: '<',
                isSecondColumn: '<?',
                isDouble: '<',
                isVertical: '<',
            }
        });

    function LineRouteInfoController($scope, $timeout, $interval, LineRouteDateTimeService, RouteHelper) {
        const vm = this;

        const { getMinutesToDeparture } = LineRouteDateTimeService;

        vm.$onInit = init;

        vm.getFirstDepTime = getFirstDepTime;
        vm.showTrack = showTrack;
        vm.getTrack = getTrack;
        vm.getMinutesToDeparture = getMinutesToDeparture;
        vm.showNextDepartures = showNextDepartures;
        vm.showDepartureDetailsInHeader = showDepartureDetailsInHeader;

        /**
         * Initialization method
         */
        function init() {
            setWatchers();
        }

        /**
         * Get icon src by catCode
         * @return {boolean | string}
         */
        function getIconSrc() {
            if (!vm.lineRouteSettings.header.showModeLogo) {
                return false;
            }
            return RouteHelper.getTransportIcon(vm.departureDetails.Product.catCode);
        }

        /**
         * Get time left to departure
         * @return {string}
         */
        function getFirstDepTime() {
            return RouteHelper.getVerbalTime(vm.departureDetails.times[0]);
        }

        /**
         * Checks if track should be shown
         */
        function showTrack() {
            return vm.lineRouteSettings.header.showTrack && (vm.departureDetails.rtTrack || vm.departureDetails.track);
        }

        /**
         * Gets track from departure details
         * @return {*}
         */
        function getTrack() {
            return vm.departureDetails.rtTrack || vm.departureDetails.track;
        }

        /**
         * Checks if next departures should be shown
         */
        function showNextDepartures() {
            return vm.lineRouteSettings.header.showTillDepartureTime &&
                vm.lineRouteSettings.line.showNextDeparture &&
                (vm.departureDetails.times.length - 1);
        }

        /**
         * Check if departure details should be shown in header
         * @return {*}
         */
        function showDepartureDetailsInHeader() {
            const showTimeTillDep = vm.lineRouteSettings.header.showTillDepartureTime;

            switch(true) {
                case vm.isDouble:
                    return vm.isVertical && showTimeTillDep;
                case vm.isVertical:
                    return showTimeTillDep || showTrack();
                default:
                    return showTimeTillDep;
            }
        }

        /**
         * Updates route config for canvas
         * @param settings
         * @param departureDetails
         */
        function updateRouteConfig(settings, departureDetails) {
            vm.icon = getIconSrc();
            vm.config = {
                stations: departureDetails.stations || [],
                stationProducts: settings.line.showStationProducts,
                stationsPerLine: settings.line.stationsPerLine,
                stationDeparture: settings.line.everyStationDeparture,
                flipHorizontal: settings[vm.orientation].flipHorizontal,
                flipVertical: settings[vm.orientation].flipVertical,
                isDouble: settings[vm.orientation].doubleLayout,
                textAngle: settings[vm.orientation].textAngleBase.angle,
                textAngleOffset: settings[vm.orientation].textAngle || 0,
            };
        }

        /**
         * Sets watchers
         */
        function setWatchers() {
            $scope.$watch(
                () => vm.lineRouteSettings,
                () => updateRouteConfig(vm.lineRouteSettings, vm.departureDetails),
                true,
            );

            $scope.$watch(
                () => vm.departureDetails,
                () => updateRouteConfig(vm.lineRouteSettings, vm.departureDetails),
                true,
            );

            $scope.$watch(
                () => vm.orientation,
                () => updateRouteConfig(vm.lineRouteSettings, vm.departureDetails),
                true,
            );
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ShareDataService', shareDataService);

    function shareDataService(Restangular) {
        return {
            getStatistics,
            preview,
            assignContent,
            shareCampaign,
            updateSharedCampaign,
            updateAllSharedCampaigns,
        };

        function getStatistics(data) {
            return Restangular.all('share/statistics')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function preview(data) {
            return Restangular.all('share/statistics/preview')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function assignContent(data) {
            return Restangular.all('share/content/assign')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function shareCampaign(data) {
            const formData = new FormData();
            formData.append('campaignId', data.campaignId);
            formData.append('controllerRefs', data.controllerRefs.join(','));
            return Restangular.all('share/campaign')
                .withHttpConfig({transformRequest: angular.identity})
                .post(formData, {}, {'Content-Type': undefined});
        }

        function updateSharedCampaign(campaignId) {
            const formData = new FormData();
            formData.append('campaignId', campaignId);
            return Restangular.all('share/campaign/update')
                .withHttpConfig({transformRequest: angular.identity})
                .post(formData, {}, {'Content-Type': undefined});
        }
        
        function updateAllSharedCampaigns() {
            return Restangular.one('share/campaign/updateAll').get();
        }

    }
})();

(function () {
    'use strict';

    angular.module('beacon.app')
        .controller('NewShareParkProfileRulesToolbarController', NewShareParkProfileRulesToolbarController);

    function NewShareParkProfileRulesToolbarController(
        $state,
        StatesFactory
    ) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ShareParkProfileRulesStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.shareParkProfileRules');
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
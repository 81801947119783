(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('StatisticsDashboardController', StatisticsDashboardController);

    function StatisticsDashboardController($stateParams, $q, ContentDataService, LoyaltyCampaignService,
                                           StatisticsService, UtilitiesService, CONTENT_TYPES) {
        const vm = this;

        /**
         * Content type ids on mobile server are different from local
         */
        const CONTENT_TYPES_REMOTE = {
            INFOTAINMENT: 1,
            FEEDBACK: 4,
            QUIZ: 5,
            BANNER: 6,
            LOYALTY_CAMPAIGN: 8
        };

        /**
         * Setting content type id for loyalty campaigns for mapping between local and remote data
         */
        vm.CONTENT_TYPE_LOYALTY_CAMPAIGN = -1;

        /**
         * Mapping content types id between remote and local data
         */
        const CONTENT_TYPES_MAP = {
            [CONTENT_TYPES_REMOTE.INFOTAINMENT]: [CONTENT_TYPES.INFOTAINMENT],
            [CONTENT_TYPES_REMOTE.QUIZ]: [CONTENT_TYPES.QUIZ],
            [CONTENT_TYPES_REMOTE.BANNER]: [CONTENT_TYPES.BANNER],
            // Some old Feedback elements have the same content type id as a Quiz on mobile API side
            [CONTENT_TYPES_REMOTE.FEEDBACK]: [CONTENT_TYPES.FEEDBACK, CONTENT_TYPES.QUIZ],
            [CONTENT_TYPES_REMOTE.LOYALTY_CAMPAIGN]: [vm.CONTENT_TYPE_LOYALTY_CAMPAIGN],
        };

        /**
         * Define content types to show from table "content" (loyalty campaigns are taking separately from other table)
         */
        const CONTENT_TYPES_TO_SHOW = [CONTENT_TYPES.INFOTAINMENT, CONTENT_TYPES.BANNER, CONTENT_TYPES.QUIZ, CONTENT_TYPES.FEEDBACK];

        /**
         * Predefined order types
         */
        vm.ORDER_TYPES = {
            CREATED: {
                id: 1,
                title: 'CREATED',
            },
            NAME: {
                id: 2,
                title: 'NAME',
            },
            VIEWS: {
                id: 3,
                title: 'VIEWS',
            },
            OPENED: {
                id: 4,
                title: 'OPENED',
            },
            CONVERSION: {
                id: 5,
                title: 'CONVERSION',
            },
            INTERACTION: {
                id: 6,
                title: 'INTERACTION',
            },
        };

        /**
         * List data for accordion
         */
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    width: '40',
                    class: 'column-title',
                    title: item => item.name,
                },
                {
                    name: 'TYPE',
                    width: '20',
                    title: item => item.content_type.name,
                    translate: true,
                },
                {
                    name: 'VIEWS',
                    width: '10',
                    class: 'text-center',
                    title: item => item.Stats.listCount !== undefined ? item.Stats.listCount : '-',
                },
                {
                    name: 'OPENED',
                    width: '10',
                    class: 'text-center',
                    title: item => item.Stats.viewCount !== undefined ? item.Stats.viewCount : '-',
                },
                {
                    name: 'CONVERSION',
                    width: '10',
                    class: 'text-center',
                    title: item => (item.Stats.viewCount && item.Stats.listCount)
                        ? Math.round(item.Stats.viewCount / item.Stats.listCount * 100) + '%'
                        : '-',
                },
                {
                    name: 'INTERACTION',
                    width: '10',
                    class: 'text-center',
                    title: _interaction
                },
            ],
            additionalData: {
                CONTENT_TYPES
            },
            updateCallback: _updateList,
        };

        /**
         * Predefined filter data
         */
        vm.filter = {
            showEmptyStats: false,
            contentTypes: {},
        };
        vm.orderBy = vm.ORDER_TYPES.CREATED.id;

        /**
         * Public methods
         */
        vm.setContents = setContents;

        /**
         * Update list callback
         *
         * @param {int} page
         * @param {int} itemsPerPage
         * @private
         */
        function _updateList(page, itemsPerPage) {
            vm.paginationData = {page, itemsPerPage};
            if (vm.contentsAll === undefined) {
                _getContents()
                    .then(contents => {
                        _setContentTypes(contents);
                        return _addStats(contents);
                    })
                    .then(contents => {
                        vm.contentsAll = contents;
                        setContents();
                    });
            } else {
                setContents();
            }
        }

        /**
         * Filtering, sorting and setting contents to view
         */
        function setContents() {
            // Get an array of content types enabled by filter
            const contentTypesEnabled = Object.keys(vm.filter.contentTypes)
                .map(key => Number(key))
                .filter(key => vm.filter.contentTypes[key] === true);

            // Filter contents
            const contents = vm.contentsAll.filter(content => {
                const statsPresent = Object.keys(content.Stats).length !== 0;
                const statsCheck = statsPresent ? true : vm.filter.showEmptyStats;

                const contentTypesCheck = !contentTypesEnabled.length || contentTypesEnabled.includes(content.content_type_id);

                return statsCheck && contentTypesCheck;
            });

            // Order contents
            const orderTypeKey = Object.keys(vm.ORDER_TYPES).find(key => vm.ORDER_TYPES[key].id === vm.orderBy);
            if (orderTypeKey) {
                const orderType = vm.ORDER_TYPES[orderTypeKey];

                contents.sort((a, b) => {
                    switch (orderType) {
                        case vm.ORDER_TYPES.NAME:
                            return checkOrder(a.name, b.name);
                        case vm.ORDER_TYPES.VIEWS:
                            return checkOrder(b.Stats.listCount, a.Stats.listCount);
                        case vm.ORDER_TYPES.OPENED:
                            return checkOrder(b.Stats.viewCount, a.Stats.viewCount);
                        case vm.ORDER_TYPES.CONVERSION:
                            const conversionA = (a.Stats.viewCount && a.Stats.listCount) && a.Stats.viewCount / a.Stats.listCount;
                            const conversionB = (b.Stats.viewCount && b.Stats.listCount) && b.Stats.viewCount / b.Stats.listCount;
                            return checkOrder(conversionB, conversionA);
                        case vm.ORDER_TYPES.INTERACTION:
                            return checkOrder(b.Stats.interactCount, a.Stats.interactCount);
                        case vm.ORDER_TYPES.CREATED:
                        default:
                            return checkOrder(b.created_at, a.created_at);
                    }
                });
            }

            vm.totalItems = contents.length;
            vm.contents = UtilitiesService.getArrayPage(contents, vm.paginationData.page, vm.paginationData.itemsPerPage);

            function checkOrder(a, b) {
                return b === undefined || a > b ? 1 : -1
            }
        }

        /**
         * Get contents from backend
         *
         * @return {*}
         * @private
         */
        function _getContents() {
            return $q.all([
                ContentDataService
                    .contents({contentTypeId: CONTENT_TYPES_TO_SHOW.join(',')})
                    .catch(console.error.bind(console)),
                LoyaltyCampaignService.getList()
                    .catch(console.error.bind(console))
            ])
            .then(([contentElementsResponse, loyaltyCampaignsResponse]) => {
                const contentElements = contentElementsResponse ? contentElementsResponse.plain() : [];
                contentElements.forEach(contentElement => {
                    contentElement.title = JSON.parse(contentElement.title);
                    contentElement.name = contentElement.title[contentElement.language_id];
                });

                const loyaltyCampaigns = loyaltyCampaignsResponse ? loyaltyCampaignsResponse.plain() : [];
                loyaltyCampaigns.forEach(loyaltyCampaign => {
                    loyaltyCampaign.reference_id = loyaltyCampaign.externalId;
                    loyaltyCampaign.content_type_id = vm.CONTENT_TYPE_LOYALTY_CAMPAIGN;
                    loyaltyCampaign.content_type = {
                        name: 'LOYALTY_CAMPAIGN',
                    };
                });

                return [].concat(contentElements, loyaltyCampaigns);
            });
        }

        /**
         * Get content types from backend and set it to the view
         *
         * @param contents
         * @private
         */
        function _setContentTypes(contents) {
            ContentDataService.contentTypes()
                .then(response => {
                    vm.contentTypes = response.plain().filter(contentType => {
                        return contents.find(content => content.content_type_id === contentType.id);
                    });
                });
        }

        /**
         * Adding stats to content elements
         *
         * @param {array} contents
         * @return {array}
         * @private
         */
        function _addStats(contents) {
            const contentIds = contents
                .map(content => _getContentMessage(content).infoExternalId || content.reference_id)
                .filter(_onlyUniqueAndNotEmpty);

            return StatisticsService.getUsageStats(contentIds)
                .then(stats => {
                    return contents.map(content => {
                        content.Stats = _findRelatedStat(content, stats) || {};
                        return content;
                    });
                })
                .catch(console.error.bind(console));
        }

        /**
         * @param content
         * @return {object}
         * @private
         */
        function _getContentMessage(content) {
            try {
                return JSON.parse(content.message);
            } catch (error) { }

            return {};
        }

        /**
         * @param value
         * @param index
         * @param array
         * @return {boolean}
         * @private
         */
        function _onlyUniqueAndNotEmpty(value, index, array) {
            return value && array.indexOf(value) === index;
        }

        /**
         * Returns related stat to content
         *
         * @param {object} content
         * @param {array} stats
         * @return {object}
         * @private
         */
        function _findRelatedStat(content, stats) {
            return stats.find(stat => {
                const contentTypeMap = CONTENT_TYPES_MAP[stat.contentType];
                const contentTypeId = content.content_type_id;
                // Check if content type matches
                const contentTypeMatch = contentTypeMap && contentTypeMap.includes(contentTypeId);

                // Check if reference id matches
                let referenceIdMatch = false;

                if (contentTypeId === CONTENT_TYPES.BANNER) {
                    const bannerMessage = JSON.parse(content.message);
                    referenceIdMatch = stat.contentRef === Number(bannerMessage.infoExternalId);
                } else {
                    referenceIdMatch = stat.contentRef === content.reference_id
                }

                return referenceIdMatch && contentTypeMatch;
            });
        }

        /**
         * Returns interaction column value for list items
         * @param item
         * @return {*}
         * @private
         */
        function _interaction(item) {
            if (item.content_type_id === CONTENT_TYPES.BANNER) {
                return angular.isDefined(item.Stats.cancelCount) ? item.Stats.cancelCount : '-'
            }

            return angular.isDefined(item.Stats.interactCount) ? item.Stats.interactCount : '-';
        }
    }
}());

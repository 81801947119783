(function() {
    'use strict';

    angular.module('beacon.app')
        .component('stationDeparturesPreview', {
            templateUrl: '/assets/views/common/components/station-departures-preview/station-departures-preview.tpl.html',
            controller: StationDeparturesPreviewController,
            bindings: {
                stationDepartures: '<'
            }
        });

    function StationDeparturesPreviewController() {}
})();
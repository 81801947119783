(function() {
    'use strict';

    angular.module('beacon.app')
        .component('beaconGroupsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/beacons-tab/beacon-groups-tab/beacon-groups-tab.tpl.html',
            controller: BeaconGroupsTab,
            bindings: {
                beaconGroups: '<',
                beaconGroupsCount: '<',
                updateBeaconGroups: '=',
                getGroupMarkers: '=',
                onSelect: '=',
            }
        });

    function BeaconGroupsTab(
        $state,
        ListPaginationService,
        LocationDataService,
        PermissionsService,
        PopupService,
        LOCATION_GROUPS_TYPES,
    ) {
        const vm = this;

        vm.updateCounter = 0;
        vm.beaconGroupsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'locationTitle',
                    width: '100',
                    title: item => item.name
                }
            ],
            buttons: {
                width: '10',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteBeaconGroup,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'editBtn',
                        callback: updateBeaconGroup,
                        permissionAction: 'modify'
                    },
                ]
            },
            itemClickCallback: selectBeaconGroup,
            generatePermissionName: generateBeaconGroupPermission,
            updateCallback: updateList,
            initOnOpen: true,
            needRadiobutton: true,
        };

        vm.createBeaconGroup = createBeaconGroup;

        /**
         * Creates beacon group
         *
         */
        function createBeaconGroup() {
            $state.go('app.addBeaconGroup', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                },
                group_type: LOCATION_GROUPS_TYPES.BEACON
            });
        }

        /**
         * Updates beacon group
         *
         * @param {MouseEvent} $event
         * @param {Object} beaconGroupItem
         */
        function updateBeaconGroup($event, beaconGroupItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editBeaconGroup', {
                data: beaconGroupItem,
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                },
                group_type: LOCATION_GROUPS_TYPES.BEACON
            });
        }

        /**
         * Deletes location group
         *
         * @param {MouseEvent} $event
         * @param {Object} beaconGroup
         */
        function deleteBeaconGroup($event, beaconGroup) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DELETE_LOCATION_GROUP_CONFIRMATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                LocationDataService.deleteLocationGroup(beaconGroup.id)
                    .then(() => {
                        angular.isFunction(vm.updateBeaconGroups) && vm.updateBeaconGroups();
                    });
            });
        }

        /**
         * Handle location group item click
         *
         * @param {object} beaconGroup
         */
        function selectBeaconGroup(beaconGroup) {
            const selectedGroupName = `group - ${beaconGroup.name}`;

            const groupBeaconIds = beaconGroup && beaconGroup.locations
                ? [...new Set(beaconGroup.locations.map(location => location.locationId))]
                : null;

            vm.onSelect(selectedGroupName, groupBeaconIds);

            vm.selectedGroup = beaconGroup;
            if (!vm.selectedGroup.markers) {
                vm.selectedGroup.markers = vm.getGroupMarkers(beaconGroup);
            }
        }

        /**
         * Generates permission name for each button on beaconGroup list
         * @param {object} button Button from list
         * @return {string} Permission name
         */
        function generateBeaconGroupPermission(button) {
            return PermissionsService.generateBeaconGroupPermission(button.permissionAction);
        }

        /**
         * Updates beacon groups list
         * @param page
         * @param itemsPerPage
         */
        function updateList(page, itemsPerPage) {
            vm.visibleBeaconGroups = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.beaconGroups).visibleItems;
        }
    }
})();
(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.factory('contentGroupState', function() {
        return {
            finish: 0
        };
    });

    app.controller('ContentGroupAddToolbarController', ContentGroupAddToolbarController);

    function ContentGroupAddToolbarController($scope, contentGroupState) {
        const vm = this;

        // public properties
        vm.state = contentGroupState;
        vm.state.finish = 0;

        // public methods
        vm.finish = finish;

        /**
         * Saves content group
         */
        function finish() {
            vm.state.finish++;
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LookupDataService', LookupDataService);

    function LookupDataService(Restangular, StorageFactory) {
        return {
            getRoles: getRoles,
            getAccessData: getAccessData,
            removeAccessData: removeAccessData,
        };

        /**
         * Returns list of roles
         */
        function getRoles(forceReload) {
            return new Promise((resolve, reject) => {
                let STORAGE = StorageFactory.Storage('Main');
                let roles = STORAGE.get('roles');
                if (forceReload || !roles) {
                    Restangular.all('roles').getList().then((data) => {
                        roles = STORAGE.set('roles', data.plain());
                        STORAGE.remove('accessData');
                        resolve(roles);
                    });
                } else {
                    resolve(roles);
                }
            });
        }

        /**
         * Returns access data
         */
        function getAccessData(forceReload) {
            return new Promise((resolve, reject) => {
                let STORAGE = StorageFactory.Storage('Main');
                let accessData = STORAGE.get('accessData');
                if (forceReload || !accessData) {
                    Restangular.one('access-data').get().then((data) => {
                        accessData = {}
                        _.forEach(data.plain(), (value, key) => {accessData[key + "Map"] = _.keyBy(value, 'id')});
                        resolve(STORAGE.set('accessData', accessData));
                    });
                } else {
                    resolve(accessData);
                }
            });
        }

        /**
         * Removes cached access data
         */
        function removeAccessData() {
            let STORAGE = StorageFactory.Storage('Main');
            STORAGE.remove('accessData');
        }
    }
})();

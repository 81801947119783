(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.factory('tenantsTabState', function() {
        return {
            views: {
                TENANTS: 0,
                SUBDOMAINS: 1,
                USERS: 2
            }
        };
    });

    app.controller('TenantsToolbarController', TenantsToolbarController);

    function TenantsToolbarController(tenantsTabState) {
        const vm = this;

        // public methods
        vm.next = next;
        vm.back = back;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;

        // public properties
        vm.state = tenantsTabState;
        vm.state.view = vm.state.views.TENANTS;
        vm.state.canProcessNext = false;
        vm.tabsText = ['TENANTS', 'SUBDOMAINS', 'USERS'];

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view++;
                vm.state.canProcessNext = false;
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.view--;
            vm.state.canProcessNext = false;
        }

        /**
         * Sets selected tab
         *
         * @param {number} tab - tab number
         */
        function goToTab(tab) {
            if (vm.state.view > tab) {
                vm.state.view = tab;
                vm.state.canProcessNext = false;
            }
        }

        /**
         * Return css class depending of tab state
         *
         * @param {number} tab - tab number
         * @returns {string} - css class for tab
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkCustomers', {
            templateUrl: '/assets/views/share-park/customers/customers/customers.tpl.html',
            controller: CustomersController
        });

    function CustomersController(
        $state,
        $stateParams,
        $window,
        ShareParkDataService,
        CustomersCacheFactory,
        ProfilesHelper,
        UtilitiesService,
        PermissionsService,
        PopupService,
        PdfPopupService,
        SHARE_PARK_CUSTOMER_STATUS,
        $q,
    ) {
        const vm = this;

        const { actions, generateCapCustomerPermission } = PermissionsService;
        const { decodeCustomerProfile } = ProfilesHelper;

        vm.$onInit = init;

        vm.listItems = [];
        vm.filteredCustomers = [];
        vm.profiles = [];
        vm.updateCounter = 0;
        vm.allCustomersCount = 0;
        vm.count = 0;

        vm.filter = {
            visibleProfiles: [],
            showVisitors: false,
            searchValue: '',
        }

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'customers-list__title',
                    width: '20',
                    title: listItem => _customerName(listItem.customer),
                },
                {
                    name: 'EMAIL',
                    width: '20',
                    title: listItem => _customerEmail(listItem.customer),
                },
                {
                    name: 'VEHICLES',
                    headerClass: 'text-center',
                    class: 'text-center',
                    width: '15',
                    title: listItem => _customerVehicles(listItem.customer),
                },
                {
                    name: 'PROFILE',
                    headerClass: 'text-center',
                    class: 'text-center',
                    width: '20',
                    title: listItem => _customerProfile(listItem.customer),
                },

            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteCustomer,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editCustomer,
                        permissionAction: actions.modify
                    },
                    {
                        class: 'receiptBtn',
                        callback: createBill,
                        permissionAction: 'bill'
                    },
                    {
                        class: item => {
                            switch (item.customer.status) {
                                case SHARE_PARK_CUSTOMER_STATUS.ACTIVE:
                                    return 'block-btn';
                                case SHARE_PARK_CUSTOMER_STATUS.INACTIVE:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeCustomerStatus,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        vm.addCustomer = addCustomer;
        vm.editCustomer = editCustomer;
        vm.onFilterChange = onFilterChange;

        /**
         * Controller initialization
         */
        function init() {
            $q.all([
                loadUserProfiles(),
                loadCustomers(),
            ]);
        }

        function onFilterChange() {
            loadCustomers();
        }

        function loadCustomers() {
            const query = generateFiltersQuery();
            return ShareParkDataService.findCustomersFlexible(query)
                .then(response => {
                    vm.listItems = response.plain().map(customer => {
                        if (customer.profile) {
                            customer.profile = decodeCustomerProfile(customer.profile);
                        }
                        return {customer};
                    });
                    vm.filteredCustomers = vm.listItems;
                    vm.count = vm.filteredCustomers.length;
                    updateList();
                });
        }

        function loadUserProfiles() {
            return $q.all([
                ShareParkDataService.getUserProfiles(),
                ShareParkDataService.getUserProfilesCount(),
            ])
            .then(([profiles, profilesCount]) => {
                vm.profiles = profiles.plain()
                    .map(profile => {
                        const countItem = profilesCount.find(item => item.profileId === profile.id);
                        profile.usersCount = countItem ? countItem.amount : 0;
                        return decodeCustomerProfile(profile);
                    })
                    .sort((a, b) => a.nameLabel.localeCompare(b.nameLabel));
                vm.allCustomersCount = vm.profiles.reduce((acc, item) => item.usersCount + acc, 0);
            });
        }

        function generateFiltersQuery() {
            const VISITOR_PROFILE_CATEGORY = 1;
            const query = {};

            if (vm.filter.searchValue) {
                query.searchValue = vm.filter.searchValue;
            }

            if (vm.filter.visibleProfiles.length) {
                query.profiles = vm.filter.visibleProfiles.join(',');
            }

            if (vm.filter.showVisitors) {
                query.profiles = vm.profiles
                    .filter(profile => profile.capPermissions.profileCategory === VISITOR_PROFILE_CATEGORY)
                    .map(profile => profile.id)
                    .join(',');
            }

            return query;
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.customers = UtilitiesService.getArrayPage(vm.filteredCustomers, page, count);
        }

        /**
         * Customer's name
         *
         * @param customer
         * @returns {string}
         * @private
         */
        function _customerName(customer) {
            return `${customer.nameFirst} ${customer.nameLast}`;
        }

        /**
         * Vehicles number
         *
         * @param customer
         * @returns {*}
         * @private
         */
        function _customerVehicles(customer) {
            return customer.registeredVehicles.length;
        }

        /**
         * Customer's email address
         *
         * @param customer
         * @returns {string}
         * @private
         */
        function _customerEmail(customer) {
            return customer.emailAddress;
        }

        /**
         * Customer's profile
         *
         * @param customer
         * @returns {string}
         * @private
         */
        function _customerProfile(customer) {
            return (customer.profile && customer.profile.nameLabel)
                ? customer.profile.nameLabel
                : '';
        }

        /**
         * Create new customer
         */
        function addCustomer() {
            $state.go('app.addShareParkCustomer');
        }

        /**
         * Edit customer
         * @param $event
         * @param item
         */
        function editCustomer($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkCustomer', { data: item.customer });
        }

        /**
         * Delete customer
         * @param $event
         * @param item
         */
        function deleteCustomer($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CUSTOMER',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                ShareParkDataService.deleteCustomer(item.customer.guid)
                    .then(() => {
                        const index = vm.filteredCustomers.findIndex(({customer}) => customer.guid === item.customer.guid);
                        vm.filteredCustomers.splice(index, 1);
                        CustomersCacheFactory.removeSingleRecord(item.customer);
                        vm.updateCounter++;
                    })
            });
        }

        function createBill($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.show({
                templateUrl: '/assets/views/common/popups/select-month/select-month.tpl.html',
                controller: 'SelectMonthPopupController',
                controllerAs: '$ctrl',
            }).then(({selectedMonth, selectedYear}) => {
                ShareParkDataService.createCustomerBill(item.customer, selectedMonth + 1, selectedYear)
                    .then(response => {
                        const file = new Blob([response], {type: 'application/pdf'});

                        PdfPopupService.showBlob(file);
                    })
            });
        }

        function changeCustomerStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const formData = new FormData();
            const customerData = angular.copy(item.customer);

            customerData.status = customerData.status === SHARE_PARK_CUSTOMER_STATUS.ACTIVE
                ? SHARE_PARK_CUSTOMER_STATUS.INACTIVE
                : SHARE_PARK_CUSTOMER_STATUS.ACTIVE;

            formData.append('customer', angular.toJson(customerData));

            ShareParkDataService.createCustomer(formData)
                .then(response => {
                    item.customer.status = customerData.status;
                    CustomersCacheFactory.updateSingleRecord(response.plain());
                });
        }

        function generatePermissionName(button) {
            return generateCapCustomerPermission(button.permissionAction);
        }
    }
})();
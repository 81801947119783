(function() {
    'use strict';

    angular.module('beacon.app')
        .service('CustomerCommunicationHelper', function(
            PopupService,
        ) {
            return {
                handleServerResponse,
                handleServerError,
            }

            /**
             * @param {{data: {result: 'MAIL_SENT'|'GATEWAY_TIMEOUT'}}} response
             */
            function handleServerResponse(response) {
                return PopupService.showAlertPopup({
                    text: _getResponseMessage(response.data.result),
                    okButtonText: 'OK',
                })
            }

            /**
             * @param {{status: number}} error
             */
            function handleServerError(error) {
                if (error.status === 504) {
                    return { data: { result: 'GATEWAY_TIMEOUT' } };
                }

                PopupService.showAlertPopup({
                    text: 'ERROR_WHILE_SENDING_EMAILS',
                    okButtonText: 'OK',
                });

                throw error;
            }

            /**
             * @param {'MAIL_SENT'|'GATEWAY_TIMEOUT'} serverResult
             * @private
             */
            function _getResponseMessage(serverResult) {
                switch (serverResult) {
                    case 'GATEWAY_TIMEOUT':
                        return 'EMAILS_ARE_SENT_BUT_NEED_TO_WAIT';
                    case 'MAIL_SENT':
                    default:
                        return 'EMAILS_SENT_SUCCESSFULLY';
                }
            }
        });


})();

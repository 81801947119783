(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('realTimeIndicatorIcon', {
            templateUrl: '/screen/views/components/timetable-hafas/partials/real-time-indicator-icon/real-time-indicator-icon.tpl.html',
            controller: RealTimeIndicatorController,
            bindings: {
                color: '<',
                element: '<',
            }
        });

    /**
     * @property {TimetableHafasElement} element
     * @constructor
     */
    function RealTimeIndicatorController() { }
})();

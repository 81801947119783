(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poiDoubletPreview', {
            templateUrl: '/assets/views/common/components/poi-doublet-preview/poi-doublet-preview.tpl.html',
            controller: PoiDoubletPreviewController,
            bindings: {
                poiContent: '<',
                onDismissCallback: '&',
            }
        });

    function PoiDoubletPreviewController(
            $scope,
            PoiImportDataService,
        ) {
        const vm = this;

        const OMIT_FIELDS = ['id', 'lat', 'lng', 'name', '$$hashKey'];

        vm.$onInit = init;
        vm.dismissPoi = dismissPoi;
        vm.addToWhitelist = addToWhitelist;

        /**
         * Initialization method
         */
        function init() {
            if (vm.poiContent) {
                processPoiContent();
            }
        }

        /**
         * Prepare poi content data for displaying
         */
        function processPoiContent() {
            const neededData = _.omit(vm.poiContent, OMIT_FIELDS);
            vm.visibleData = Object.keys(neededData).map(key => {
                return {
                    key,
                    value: neededData[key]
                }
            });
        }

        function dismissPoi() {
            PoiImportDataService.addToBlacklist(vm.poiContent.id)
                .then(response => {
                    vm.onDismissCallback({id: vm.poiContent.id});
                })
                .catch(console.error.bind(console));
        }

        function addToWhitelist() {
            PoiImportDataService.addToWhitelist(vm.poiContent.id)
                .then(response => {
                    vm.onDismissCallback({id: vm.poiContent.id});
                })
                .catch(console.error.bind(console));
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.poiContent;
        }), processPoiContent);
    }
})();
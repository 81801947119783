(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkProfiles', {
            templateUrl: '/assets/views/share-park/customers/profiles/profiles.tpl.html',
            controller: ProfilesController
        });

    function ProfilesController(
        $state,
        PermissionsService,
        PopupService,
        ProfilesHelper,
        ShareParkDataService,
        UtilitiesService,
    ) {
        const vm = this;
        const { sortByStringProperty } = UtilitiesService;
        const ACTIVE_STATUSES = [0, 1];     // customer requirement
        const INACTIVE_STATUS = 2;
        const DELETED_STATUS = 3;

        const { actions, generateProfilePermission } = PermissionsService;
        const { getUserProfiles } = ShareParkDataService;
        const { decodeCustomerProfile, encodeCustomerProfile } = ProfilesHelper;

        vm.$onInit = init;

        vm.filterProfiles = filterProfiles;
        vm.filteredProfiles = [];
        vm.searchValue = '';
        vm.profiles = [];
        vm.visibleProfiles = [];
        vm.updateCounter = 0;
        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'profile-title',
                    width: '30',
                    title: listItem => listItem.profile.nameLabel,
                },
                {
                    name: 'DESCRIPTION',
                    width: '50',
                    title: listItem => listItem.profile.descriptionLabel || '',
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteProfile,
                        permissionAction: actions.delete,
                        isVisible,
                    },
                    {
                        class: 'editBtn',
                        callback: editProfile,
                        permissionAction: actions.modify,
                        isVisible,
                    },
                    {
                        class: item => {
                            const status = item.profile.status;
                            switch (true) {
                                case ACTIVE_STATUSES.includes(status):
                                    return 'block-btn';
                                case INACTIVE_STATUS === status:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeProfileStatus,
                        isVisible,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        }

        vm.addProfile = addProfile;

        function init() {
            getUserProfiles()
                .then(response => {
                    vm.profiles = response.plain()
                        .filter(profile => profile.status !== DELETED_STATUS)
                        .map(profile => {
                            return {
                                profile: decodeCustomerProfile(profile, true)
                            }
                        });
                    
                    vm.profiles = sortProfilesByName(vm.profiles);
                    filterProfiles();
                    vm.updateCounter++;
                })
        }

        function sortProfilesByName(profiles) {
            return profiles.sort((a, b) => {
                if (a.profile.nameLabel.toLowerCase() < b.profile.nameLabel.toLowerCase()) {
                    return -1;
                } else if (a.profile.nameLabel.toLowerCase() > b.profile.nameLabel.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        /**
         * Filters profiles list
         */
        function filterProfiles() {
            vm.filteredProfiles = vm.searchValue
                ? vm.profiles.filter(listItem => {
                    return listItem.profile.nameLabel.toUpperCase().includes(vm.searchValue.toUpperCase())
                        || listItem.profile.descriptionLabel.toUpperCase().includes(vm.searchValue.toUpperCase())
                })
                : vm.profiles;
            
            vm.updateCounter++;
        }

        function isVisible(btn, item) {
            return item.profile.status !== DELETED_STATUS;
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.visibleProfiles = UtilitiesService.getArrayPage(vm.filteredProfiles, page, count);
        }

        function addProfile() {
            $state.go('app.addShareParkProfile');
        }

        /**
         * Edit profile
         * @param $event
         * @param item
         */
        function editProfile($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkProfile', { data: item.profile });
        }

        /**
         * Delete profile
         * @param $event
         * @param item
         */
        function deleteProfile($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_PROFILE',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                const formData = new FormData();
                const profile = angular.copy(item.profile);

                profile.status = DELETED_STATUS;

                formData.append('profile', angular.toJson(encodeCustomerProfile(profile)));
                ShareParkDataService.setProfile(formData).then(() => {
                    init();
                })
            });
        }

        function changeProfileStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const ACTIVE_STATUS = 1;

            const formData = new FormData();
            const profileData = angular.copy(item.profile);

            profileData.status = ACTIVE_STATUSES.includes(profileData.status)
                ? INACTIVE_STATUS
                : ACTIVE_STATUS;

            formData.append('profile', angular.toJson(encodeCustomerProfile(profileData)));

            ShareParkDataService.setProfile(formData).then(() => {
                init();
            });
        }

        function generatePermissionName(button) {
            return generateProfilePermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capStatisticsTab', {
            templateUrl: '/assets/views/share-park/car-parks/statistics/tabs/statistics-tab/cap-statistics.tpl.html',
            controller: CapStatisticsTabController,
            bindings: {
                selectedCarParks: '<',
            }
        });

    function CapStatisticsTabController(
        $scope,
        $interval,
        $timeout,
        StorageFactory,
        ShareParkDataService,
        STATISTICS_TIME_RANGES,
        CAP_STATISTICS_MODES,
    ) {
        const vm = this;
        vm.$onInit = init;

        const MainStorage = StorageFactory.Storage('Main');
        const PRIMARY_COLOR_INDEX = 0;
        const FONT_COLOR_INDEX = 3;
        const UPDATE_INTERVAL = 60 * 1000;

        vm.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }],
            },
            legend: {
                display: vm.statisticsMode === CAP_STATISTICS_MODES.TIME_STATISTICS.id,
            }
        };

        vm.separateStatisticsChartOptions = angular.copy(vm.chartOptions);
        delete vm.separateStatisticsChartOptions.legend;

        vm.series = [];
        vm.timeRange = null;
        vm.uniqueCustomers = false;
        vm.statisticsByCaps = [];

        vm.statisticsMode = CAP_STATISTICS_MODES.TOTAL.id;
        vm.CAP_STATISTICS_MODES = CAP_STATISTICS_MODES;
        vm.tenantBackgroundColor = MainStorage.corporateColours[PRIMARY_COLOR_INDEX];
        vm.tenantFontColor = MainStorage.corporateColours[FONT_COLOR_INDEX];

        vm.onStatisticsModeChange = onStatisticsModeChange;
        vm.fillTotalStatisticsData = fillTotalStatisticsData;
        vm.fillStatisticsByDays = fillStatisticsByDays;
        vm.updateSingleParkingStatistics = updateSingleParkingStatistics;

        function init() {
            getCarParksStatistics();
        }

        function getCarParksStatistics() {
            const carParkRefs = vm.selectedCarParks.map(carPark => carPark.external_id).join(',');
            ShareParkDataService.getAllUserStatuses({carParkRefs})
                .then(response => {
                    vm.userStatuses = response.plain();
                    vm.series = vm.selectedCarParks.map(cap => cap.name);
                    fillStatisticsData();
                });
        }

        function fillStatisticsData() {
            vm.STATISTICS_TIME_RANGES = vm.statisticsMode === CAP_STATISTICS_MODES.TOTAL.id
                ? STATISTICS_TIME_RANGES
                : STATISTICS_TIME_RANGES.filter(range => !range.hoursRange || range.hoursRange >= 2);

            if (vm.statisticsMode === CAP_STATISTICS_MODES.TIME_STATISTICS.id) {
                fillStatisticsByDays();
                fillStatisticsByCarParks();
            } else {
                fillTotalStatisticsData();
                vm.statisticsByCaps = [];
            }
        }

        function fillTotalStatisticsData() {
            const minStatisticsDate = getMinStatisticsDate(vm.timeRange);

            vm.statistics = new Array(vm.selectedCarParks.length).fill(0);
            vm.chartLabels = [];
            const statisticsData = [];
            vm.selectedCarParks.forEach((carPark, index) => {
                const countedCarPlates = [];
                statisticsData[index] = {
                    name: carPark.name,
                    visits: 0
                };

                vm.userStatuses.forEach(userStatus => {
                    if (carPark.external_id === userStatus.carParkRef
                    && (!vm.uniqueCustomers || !countedCarPlates.includes(userStatus.carNumberPlate))) {

                        if (!userStatus.endedParking
                            || moment(userStatus.endedParking).isAfter(minStatisticsDate)) {
                            statisticsData[index].visits++;
                            countedCarPlates.push(userStatus.carNumberPlate);
                        }
                    }
                })
            });

            statisticsData.sort((a, b) => {
                return a.visits > b.visits ? -1 : 1;
            });

            vm.chartLabels = statisticsData.map(cap => cap.name);
            vm.statistics = statisticsData.map(cap => cap.visits);
        }

        function fillStatisticsByDays() {
            const minStatisticsDate = getMinStatisticsDate(vm.timeRange);

            vm.userStatuses.forEach(status => status.startedParking = moment(status.startedParking));
            const userStatuses = vm.userStatuses.filter(status => status.startedParking.isAfter(minStatisticsDate));
            userStatuses.sort((a, b) => {
                return a.startedParking.isBefore(b.startedParking) ? -1 : 1;
            });

            let { showHours, dateFormat } = getChartDetailsByUserStatuses(userStatuses, vm.timeRange);

            vm.statistics = [];
            vm.chartLabels = generateDaysLabels(vm.timeRange, userStatuses);
            vm.selectedCarParks.forEach((carPark, capIndex) => {
                vm.statistics[capIndex] = new Array(vm.chartLabels.length).fill(0);

                userStatuses.forEach(userStatus => {
                    if (carPark.external_id === userStatus.carParkRef) {
                        const graphTimeValue = showHours
                            ? moment(userStatus.startedParking).minute(0)
                            : userStatus.startedParking;
                        const dayIndex = vm.chartLabels.indexOf(graphTimeValue.format(dateFormat));
                        vm.statistics[capIndex][dayIndex]++;
                    }
                })
            });
        }

        /**
         * Returns labels for chart
         * @param { Number } timeRange number of hours before current date
         * @param { Array } userStatuses
         * @return { Array }
         */
        function generateDaysLabels(timeRange, userStatuses) {
            const labels = [];

            let { rangeStart , showHours, dateFormat } = getChartDetailsByUserStatuses(userStatuses, timeRange);
            rangeStart = moment(rangeStart.minute(0));
            const lastDate = moment();
            const stepValue = showHours ? 'hour' : 'day';

            while (!rangeStart.isAfter(lastDate, stepValue)) {
                labels.push(rangeStart.format(dateFormat));
                rangeStart.add(1, stepValue);
            }

            return labels;
        }

        /**
         * @param { Array } userStatuses
         * @param { Number } timeRange number of hours before current date
         * @return {{rangeStart: moment, showHours: boolean, dateFormat: string}}
         */
        function getChartDetailsByUserStatuses(userStatuses, timeRange) {
            const DAYS_IN_WEEK = 7;
            const rangeStart = timeRange
                ? moment().subtract(timeRange, 'hours')
                : (userStatuses.length ? moment(userStatuses[0].startedParking) : moment());
            const showHours = moment().diff(rangeStart, 'days') <= DAYS_IN_WEEK;
            const dateFormat = showHours ? 'lll' : 'LL';

            return {
                rangeStart,
                showHours,
                dateFormat
            }
        }

        /**
         * Fills statistics by car parks (when statistics by days selected)
         */
        function fillStatisticsByCarParks() {
            vm.selectedCarParks.forEach((carPark, index) => {
                if (!vm.statisticsByCaps[index]) {
                    vm.statisticsByCaps[index] = {
                        capId: carPark.external_id,
                        capName: carPark.name,
                        timeRange: null,
                        statistics: [],
                        labels: [],
                    }
                }

                updateSingleParkingStatistics(vm.statisticsByCaps[index]);
            })
        }

        function updateSingleParkingStatistics(statisticsObj) {
            const minStatisticsDate = getMinStatisticsDate(statisticsObj.timeRange);

            const userStatuses = vm.userStatuses.filter(status =>
                statisticsObj.capId === status.carParkRef && status.startedParking.isAfter(minStatisticsDate)
            );

            userStatuses.sort((a, b) => {
                return a.startedParking.isBefore(b.startedParking) ? -1 : 1;
            });

            statisticsObj.labels = generateDaysLabels(statisticsObj.timeRange, userStatuses);

            let { showHours, dateFormat } = getChartDetailsByUserStatuses(userStatuses, statisticsObj.timeRange);

            statisticsObj.statistics = new Array(statisticsObj.labels.length).fill(0);

            userStatuses.forEach(userStatus => {
                const graphTimeValue = showHours
                    ? moment(userStatus.startedParking).minute(0)
                    : userStatus.startedParking;
                const dayIndex = statisticsObj.labels.indexOf(graphTimeValue.format(dateFormat));
                statisticsObj.statistics[dayIndex]++;
            });
        }

        /**
         * Returns time range start
         * @param { Number } timeRange
         * @return { moment }
         */
        function getMinStatisticsDate(timeRange) {
            return timeRange
                ? moment().subtract(timeRange, 'hours')
                : moment(0);
        }

        function onStatisticsModeChange() {
            fillStatisticsData();
        }

        vm.updateInterval = $interval(getCarParksStatistics, UPDATE_INTERVAL);

        $scope.$on('$destroy', function() {
            if (vm.updateInterval) {
                $interval.cancel(vm.updateInterval);
            }
        });
    }
})();

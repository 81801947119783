(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poiTours', {
            templateUrl: '/assets/views/poi/tours/tours.tpl.html',
            controller: ToursController
        });

    function ToursController(
        $state,
        $stateParams,
        PoiContentDataService,
        PopupService,
        PermissionsService,
    ) {
        const vm = this;

        vm.$onInit = init;

        // public properties
        vm.addTour = addTour;

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '35',
                    title: function (item) {
                        return item.title[item.language_id];
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deletePoiTour,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: copyPoiTour,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: editPoiTour,
                        permissionAction: 'modify'
                    }
                ]
            },
            updateCallback: updateToursList,
            generatePermissionName: generatePermissionName,
        };

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Create new tour
         */
        function addTour() {
            $state.go('app.addPoiTour', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                    count: vm.toursCount || 0,
                }
            });
        }

        /**
         * Updates list of tours
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateToursList(page, itemsPerPage = null) {
            let data = {
                page,
                itemsPerPage
            };
            PoiContentDataService.getPoiTours(data)
                .then(response => {
                    vm.tours = _.isArray(response) ? response : [];
                    vm.tours.forEach(tour => {
                        tour.title = angular.fromJson(tour.title);
                        tour.description = angular.fromJson(tour.description);
                        tour.poi_contents.forEach(processTourInnerContent);
                        tour.contents.forEach(processTourInnerContent);
                        tour.tourContents = _.orderBy(tour.poi_contents.concat(tour.contents), 'index');
                        tour.tourPoints = getTourPoints(tour);
                    });
                    vm.toursCount = response.count;
                })
                .catch(console.error.bind(console));
        }

        function processTourInnerContent(content) {
            content.title = angular.fromJson(content.title);
            content.message = angular.fromJson(content.message);
            content.map_info = angular.fromJson(content.map_info);
            content.index = content.pivot.index;
            content.description = angular.fromJson(content.pivot.description);
        }
        /**
         * Removes poi tour by id
         *
         * @param {MouseEvent} $event
         * @param {object} poitour
         */
        function deletePoiTour($event, poiTour) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_POI_TOUR',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                PoiContentDataService.deletePoiTour(poiTour.id)
                    .then(() => {
                        vm.toursCount -= 1;
                    })
                    .catch(console.error.bind(console));
            })
        }

        /**
         * Edit poi tour
         *
         * @param {MouseEvent} $event
         * @param {Object} poiTour
         */
        function editPoiTour($event, poiTour) {
            const page = angular.element('.pagination-page.active a')[0].innerHTML;
            const itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            const data = poiTour;

            $state.go('app.editPoiTour', {
                data,
                paginationData: {
                    page: Number(page),
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Copy poi tour
         *
         * @param {MouseEvent} $event
         * @param {Object} poiTour
         */
        function copyPoiTour($event, poiTour) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_POI_TOUR',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then(() => {
                $state.go('app.addPoiTour', {
                    data: poiTour,
                    paginationData: {
                        page: 1,
                        itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                        count: vm.elementsCount
                    }
                });
            });
        }

        function getTourPoints(item) {
            let tourPoints = [];
            item.tourContents.forEach(element => {
                tourPoints = tourPoints.concat(element.map_info.map(point => {
                    return {
                        coords: {
                            lat: point.latitude,
                            lng: point.longitude
                        },
                        label: element.title[item.language_id] || element.title[element.language_id],
                    }
                }));
            });
            return tourPoints;
        }

        /**
         * Generates permission name for each button on POI tours accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return PermissionsService.generatePoiTourPermission(button.permissionAction);
        }
    }
})();
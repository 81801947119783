(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('playlistCarousel', playlistCarousel);

    function playlistCarousel() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/playlist/playlist-carousel/playlist-carousel.tpl.html',
            replace: true,
            controller: PlaylistCarouselController,
            controllerAs: 'playlistCarousel',
            bindToController: true,
            scope: {
                item: '=item',
                selected: '=?selected',
                view: '=?',
            }
        };
    }

    function PlaylistCarouselController($scope, $timeout,
                                        ModelsFactory,
                                        VIEW_TYPES,
                                        DigitalDisplayHelper,
                                        PlaylistHelper,
                                        PLAYLIST_ITEM_TYPE,
                                        ) {
        const vm = this;

        const { getAppItemIcon, getTooltip } = PlaylistHelper;

        vm.interval = vm.item.interval;
        vm.activeSlide = 0;
        vm.playedVideo = null;
        vm.itemTypes = PLAYLIST_ITEM_TYPE;
        vm.itemTypesWithIconPreview = [
            PLAYLIST_ITEM_TYPE.INFOTAINMENT,
            PLAYLIST_ITEM_TYPE.TIMETABLE,
            PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT_PLACEHOLDER,
            PLAYLIST_ITEM_TYPE.WEATHER,
            PLAYLIST_ITEM_TYPE.TIMETABLE_HAFAS,
            PLAYLIST_ITEM_TYPE.HIM,
            PLAYLIST_ITEM_TYPE.SPLIT_SCREEN,
        ];

        vm.registerVideoTag = registerVideoTag;
        vm.getScaleClass = DigitalDisplayHelper.getScaleClass;
        vm.getAppItemIcon = getAppItemIcon;
        vm.showIcon = showIcon;
        vm.getTooltip = getTooltip;
        vm.getExternalLink = getExternalLink;

        function registerVideoTag(event, index) {
            vm.item.items[vm.view][index].tag = event.target;

            if (vm.activeSlide === index) {
                update();
            }
        }

        function updateSize() {
            var height = vm.item.containerHeight || vm.item.height;
            var width = vm.item.containerWidth || vm.item.width;

            vm.height = vm.view === VIEW_TYPES.PORTRAIT ? height : width * 9 / 16;
            vm.width = vm.view === VIEW_TYPES.PORTRAIT ? height * 9 / 16 : width;
        }

        /**
         * Checks if icon should be shown
         * @param itemType
         * @return {boolean}
         */
        function showIcon(itemType) {
            return vm.itemTypesWithIconPreview.includes(itemType);
        }

        /**
         * Gets external link
         * @param item
         * @return {string|undefined}
         */
        function getExternalLink(item) {
            if (item.type === PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE) {
                return item.externalLink;
            }

            if (!item.content || !item.content.message) {
                return;
            }
            const messageWithLink = Object.values(item.content.message)
                .find(translation => translation.externalLink);

            return messageWithLink && messageWithLink.externalLink;
        }

        function update() {
            var item = vm.item.items[vm.view][vm.activeSlide];
            vm.interval = (item && item.transitionDelay && item.transitionDelay > 0)
                ? item.transitionDelay * 1000
                : vm.item.interval;

            if (vm.playedVideo) {
                if (vm.playedVideo.type === PLAYLIST_ITEM_TYPE.VIDEO) {
                    vm.playedVideo.tag.pauseVideo();
                } else {
                    vm.playedVideo.tag.pause();
                }

                vm.playedVideo = null;
            }

            if (!item || !item.src) {
                return;
            }

            vm.interval = 0; // pause
            if (item.type === PLAYLIST_ITEM_TYPE.VIDEO && (!item.tag || !item.tag.playVideo)) {
                var tagId = 'youtube-iframe-' + item.id + '_' + item.random;

                if (item.tag) {
                    var div = document.createElement('div');
                    var deprecatedIframe = document.getElementById(tagId);

                    div.id = tagId;
                    deprecatedIframe.parentNode.replaceChild(div, deprecatedIframe);
                }
                $timeout(() => {
                    new ModelsFactory.YouTubePlayer(tagId, {
                        width: vm.width,
                        height: vm.height,
                        videoId: item.id,
                        events: {
                            onReady: function(event) {
                                item.tag = event.target;
                                item.tag.mute();
                                item.tag.playVideo();
                            },
                            onStateChange: function(event) {
                                if (event.data === YT.PlayerState.ENDED) {
                                    vm.interval = 1;
                                }
                            }
                        }
                    });
                });
            } else {
                // start video
                vm.playedVideo = item;
                if (vm.playedVideo.type === PLAYLIST_ITEM_TYPE.VIDEO) {
                    vm.playedVideo.tag.playVideo();
                } else {
                    vm.playedVideo.tag.play();
                    vm.playedVideo.tag.onended = function(e) {
                        vm.interval = 1;
                    };
                }
            }
        }

        updateSize();

        $scope.$on('angular-resizable.resizing', function(event, args) {
            $timeout(function() {
                vm.item.carouselHeight = args.height;
            }, 0);
            vm.item.height = args.height;
            vm.item.width = args.width;
        });

        $scope.$on('viewType-change', function(event, args) {
            vm.view = args.view;
        });


        if (vm.item.items) {
            $scope.$watch(function() {
                return vm.activeSlide;
            }, function() {
                if (vm.activeSlide !== undefined) {
                    update();
                }
            });
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkLegal', {
            templateUrl: '/assets/views/share-park/operations/legal/legal.tpl.html',
            controller: ShareParkLegalController
        });

    function ShareParkLegalController(
        $state,
        PermissionsService,
        LegalTextDataService,
        PopupService,
        DateHelper,
    ) {
        const vm = this;

        const { actions, generateLegalTextPermission } = PermissionsService;
        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

        vm.updateCounter = 0;
        vm.legalListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'legal-text-name',
                    width: '30',
                    title: 'name',
                },
                {
                    name: 'PUBLISHING_DATE',
                    width: '30',
                    title: item => item.publishing_date.format(DATE_TIME_FORMAT),
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteLegalText,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editLegalText,
                        permissionAction: actions.modify
                    },
                    {
                        class: 'historyBtn',
                        callback: showLegalTextHistory,
                        permissionAction: actions.view,
                        isVisible: (btn, item) => item.versions.length
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName,
        };

        vm.createLegalText = createLegalText;

        /**
         * Create new legal text
         */
        function createLegalText() {
            $state.go('app.addLegalText');
        }

        function deleteLegalText($event, legalText) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_LEGAL_TEXT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                LegalTextDataService.deleteLegalText(legalText.id)
                    .then(() => {
                        vm.updateCounter++;
                    })
            });
        }

        function editLegalText($event, legalText) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editLegalText', { data: legalText });
        }

        function showLegalTextHistory($event, legalText) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.legalTextVersions', { legalText });
        }

        function updateElementsList(page, itemsPerPage) {
            let data = { page, itemsPerPage };

            LegalTextDataService.getLegalTexts(data)
                .then(response => {
                    vm.legalTexts = response;
                    vm.legalTexts.forEach(legalText => {
                        legalText.publishing_date = moment.utc(legalText.publishing_date).local()
                    });
                    vm.legalTextsCount = response.count;
                })

        }

        function generatePermissionName(button) {
            return generateLegalTextPermission(button.permissionAction);
        }
    }
})();
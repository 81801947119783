(function() {
    'use strict';

    var app = angular.module('beacon.app');

    app.controller('TenantAddToolbarController', TenantAddToolbarController);

    function TenantAddToolbarController($scope, $state, $stateParams, tenantAddState) {
        const vm = this;

        // public properties
        vm.state = tenantAddState;
        vm.state.finish = 0;

        // public methods
        vm.finish = finish;
        vm.back = back;

        /**
         * Sets previous view
         */
        function back() {
            $state.go('app.tenants', {
                paginationData: $stateParams.paginationData
            });
        }

        /**
         * Saves content group
         */
        function finish() {
            vm.state.finish++;
        }
    }
}());

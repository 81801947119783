(function() {
    'use strict';

    angular.module('beacon.app')
        .run(authConfiguration)
        .run(restangularErrorInterceptor)
        .run(setUserUiLanguage)
        .run(browserUpdateChecker);

    function authConfiguration($auth) {
        $auth.setStorageType('sessionStorage');
    }

    function restangularErrorInterceptor(Restangular, $state) {
        Restangular.setErrorInterceptor(function(response) {
            if (response && response.data && response.data.message && response.data.message === 'Token not provided') {
                $state.go('login', {});
                setTimeout(function() {
                    less.modifyVars();
                }, 0);
                return false;
            } else {
                return true;
            }
        });
    }

    function browserUpdateChecker(PopupService, appBrowser) {
        const INTERNET_EXPLORER = 'msie';
        if (appBrowser.getName() === INTERNET_EXPLORER) {
            PopupService.show({
                templateUrl: 'assets/views/common/popups/browser-update-checker/browser-update-checker.tpl.html',
                windowClass: 'browser-update-checker',
                resolve: {
                    data: () => null
                }
            });
        }
    }

    function setUserUiLanguage(UserDataService, LoginService, $translate, $window, mdcDefaultParams) {
        const useLanguage = code => {
            $translate.use(code);
            moment.locale(code);
            mdcDefaultParams({lang: code});
        }

        if (LoginService.isLoggedIn()) {
            UserDataService.currentUserInfo().then(res => {
                const code = res.language.code;
                useLanguage(code);
                $window.localStorage.uiLang = code;
            });
        } else if ($window.localStorage.uiLang) {
            useLanguage($window.localStorage.uiLang);
        } else {
            const browserLang = $translate.resolveClientLocale().substring(0, 2);
            const availableLanguages = $translate.getAvailableLanguageKeys();
            if (availableLanguages.includes(browserLang)) {
                useLanguage(browserLang);
            }
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('$exceptionHandler', exceptionHandler);

    function exceptionHandler($log) {
        return (exception, cause) => {
            $log.error(exception, cause);

            if (window.Sentry && window.Sentry.captureException) {
                window.Sentry.captureException(exception, {extra: {cause: cause}});
            }
        };
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ConciergeDataService', ConciergeDataService);

    function ConciergeDataService(
        $http,
    ) {
        return {
            carParks,
            events,
            openGate,
        };

        /**
         * Get list of car parks
         * @return {Promise<any>}
         */
        function carParks() {
            return $http.get('/api/sharePark/concierge/car-parks').then(response => response.data);
        }

        /**
         * Get list of car parks
         * @return {Promise<any>}
         */
        function events() {
            return $http.get('/api/sharePark/concierge/events').then(response => response.data);
        }

        /**
         * Opens the gate
         * @param {object} data
         * @param {number} data.entranceId
         * @param {string} data.plateNumber
         * @param {number} data.eventType (entrance = 1; exit = 2)
         * @param {string} data.conciergeEmail
         * @param {string} data.countryCode
         * @param {number} data.duration
         * @param {string} data.subArea
         * @returns {Promise<any>}
         */
        function openGate(data) {
            return $http.post('/api/sharePark/concierge/open-gate', data).then(response => response.data);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poiElements', {
            templateUrl: '/assets/views/poi/elements/poi-elements.tpl.html',
            controller: PoiElementsController
        });

    function PoiElementsController(
        $state,
        $stateParams,
        ContentDataService,
        PermissionsService,
        PoiContentDataService,
        PopupService,
        ) {
        const vm = this;

        vm.$onInit = init;

        // public properties
        vm.addElement = addElement;

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'poi-content-title',
                    width: '35',
                    title: function(item) {
                        return item.title[item.language_id];
                    }
                },
                {
                    name: 'ICON',
                    class: 'poi-content-icon no-padding',
                    width: '20',
                    title: function(item) {
                        let group = vm.contentGroupsArray.find((group) => {
                            return parseInt(group.icon_id) === parseInt(item.content_group.icon_id);
                        });

                        return group && group.icon ? '<img src="' + group.icon.iconUrl + '">' : 'Icon not available';
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deletePoiContent,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: copyPoiContent,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: editPoiContent,
                        permissionAction: 'modify'
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName: generatePermissionName,
        };

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Create new element
         */
        function addElement() {
            $state.go('app.addPoiElement', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                    count: vm.elementsCount
                }
            });
        }

        /**
         * Updates list of elements
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateElementsList(page, itemsPerPage = null) {
            let data = {
                page,
                itemsPerPage
            };
            loadContentGroups(() => {
                PoiContentDataService.getPoiContents(data)
                    .then(response => {
                        vm.elements = _.isArray(response) ? response : [];
                        vm.elements.forEach(element => {
                            element.title = angular.fromJson(element.title);
                            element.message = angular.fromJson(element.message);
                            element.delivery_times = angular.fromJson(element.delivery_times);
                            element.opening_days = angular.fromJson(element.opening_days);
                            element.opening_times = angular.fromJson(element.opening_times);
                            element.map_info = angular.fromJson(element.map_info);
                            if (!!element.language_sensitive_audio) {
                                element.audio_src = angular.fromJson(element.audio_src);
                            }
                            if (!!element.content_group) {
                                element.content_group.title = angular.fromJson(element.content_group.title);
                            }
                        });
                        vm.elementsCount = response.count;
                    })
                    .catch(console.error.bind(console));
            })
        }

        /**
         * Loads content groups from the server
         * @param {function} callback
         */
        function loadContentGroups(callback) {
            ContentDataService.contentGroups({noSync: true})
                .then(response => {
                    vm.contentGroupsArray = response.map((item) => {
                        item.title = angular.fromJson(item.title);
                        return item;
                    });
                    callback();
                })
                .catch(console.error.bind(console));
        }

        /**
         * Removes poi content by id
         *
         * @param {MouseEvent} $event
         * @param {object} poiContent
         */
        function deletePoiContent($event, poiContent) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_POI_CONTENT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                PoiContentDataService.deletePoiContent(poiContent.id)
                    .then(() => {
                        vm.elementsCount -= 1;
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Edit poi content
         *
         * @param {MouseEvent} $event
         * @param {Object} poiContent
         */
        function editPoiContent($event, poiContent) {
            const page = angular.element('.pagination-page.active a')[0].innerHTML;
            const itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            const data = poiContent;

            $state.go('app.editPoiElement', {
                data,
                paginationData: {
                    page: Number(page),
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Copy poi content
         *
         * @param {MouseEvent} $event
         * @param {Object} poiContent
         */
        function copyPoiContent($event, poiContent) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_CONTENT',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then(() => {
                poiContent.copy = true;
                $state.go('app.addPoiElement', {
                    data: poiContent,
                    paginationData: {
                        page: 1,
                        itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                        count: vm.elementsCount
                    }
                });
            });
        }

        /**
         * Generates permission name for each button on POI elements accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return PermissionsService.generatePoiElementPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tickerItem', {
            templateUrl: '/assets/views/content/elements/types/ticker/ticker-preview/ticker-item/ticker-item.tpl.html',
            controller: TickerItemController,
            bindings: {
                sourceType: '<',
                feed: '<',
                showSeparator: '<',
                fontColor: '<',
            }
        });

    function TickerItemController(MARQUEE_TYPE) {
        const vm = this;

        vm.CONTENT_TYPES = {
            IMG: 'img',
            TEXT: 'text'
        };

        vm.MARQUEE_TYPE = MARQUEE_TYPE;
    }
})();
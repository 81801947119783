(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('CropPopupController', CropPopupController);

    function CropPopupController(
        $scope,
        Cropper,
        $timeout,
        $uibModalInstance,
        $translate,
        data
    ) {
        const vm = this;

        // public properties
        vm.imageUrl = '';
        vm.scale = false;
        vm.data = data;
        vm.showEvent = 'show';
        vm.cropper = {};
        vm.cropperProxy = 'cropPopupController.cropper.first';
        vm.cropData = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rotate: 0
        };

        const initialAspectRatio = vm.data.aspectRatio || false;

        vm.options = {
            maximize: false,
            autoCrop: true,
            aspectRatio: initialAspectRatio,
            crop: newData => {
                $scope.$apply(() => {
                    vm.cropData.x = newData.x;
                    vm.cropData.y = newData.y;
                    vm.cropData.width = Math.round(newData.width);
                    vm.cropData.height = Math.round(newData.height);
                    vm.cropData.rotate = newData.rotate;
                });
            },
            data: vm.data.data || {}
        };

        // placeholders
        vm.aspectRatioHorizontal = 1;
        vm.aspectRatioVertical = 1;
        vm.customAspectRatio = false;

        // public methods
        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.updateAspectRatio = updateAspectRatio;

        vm.$onInit = init;

        /**
         * Updates cropper aspect ratio
         */
        function updateAspectRatio() {
            vm.options.aspectRatio = vm.customAspectRatio
                ? vm.aspectRatioHorizontal / vm.aspectRatioVertical
                : initialAspectRatio;
            jQuery('#ngCropper').cropper('destroy').cropper(vm.options);
        }

        /**
         * OK button handler
         */
        function okHandler() {
            const { minWidth, minHeight } = vm.data;
            const isValidMinSize = vm.cropData.width >= minWidth && vm.cropData.height >= minHeight;
            const closeModal = blob => {
                $uibModalInstance.close(vm.data.fullInfo ? {blob: blob, cropData: vm.cropData} : blob);
            };

            if (isValidMinSize || vm.scale) {
                Cropper.crop(vm.data.file, vm.cropData).then(blob => {
                    if (vm.scale) {
                        const scaledSize = _getScaledSize(vm.cropData.width, vm.cropData.height);
                        Cropper.scale(blob, scaledSize).then(blob => closeModal(blob));
                    } else {
                        closeModal(blob);
                    }
                });
            }
        }

        /**
         * Calculate scaled image size
         *
         * @param {int} imageWidth
         * @param {int} imageHeight
         * @return {{width: number, height: number}}
         * @private
         */
        function _getScaledSize(imageWidth, imageHeight) {
            const { minWidth, minHeight } = vm.data;
            let scalingFactor;

            switch (true) {
                case !minWidth || !minHeight:
                    scalingFactor = 1;
                    break;
                case imageWidth <= imageHeight:
                    scalingFactor = minWidth / imageWidth;
                    break;
                case imageWidth > imageHeight:
                    scalingFactor = minHeight / imageHeight;
                    break;
                default:
                    scalingFactor = 1;
            }

            return {
                width: Math.round(imageWidth * scalingFactor),
                height: Math.round(imageHeight * scalingFactor),
            }
        }

        /**
         * Cancel button handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {
            Cropper.encode(vm.data.file).then(url => {
                vm.imageUrl = url;
                if (vm.data.croppedData) {
                    vm.cropData = vm.data;
                }
                $timeout(showCropper);
            });
        }

        /**
         * Shows image cropper rectangle
         */
        function showCropper() {
            $scope.$broadcast(vm.showEvent);
        }
    }
}());

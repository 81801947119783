(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timetableOptions', {
            controller: TimetableOptionsController,
            templateUrl: '/assets/views/location/new-screen/timetable-options/timetable-options.tpl.html',
            bindings: {
                data: '=',
                onTimetableOptionsChange: '<',
                lines: '<',
                tracks: '<',
            }
        });

    /**
     * @param FindStationPopupService
     * @param ScreenHelper
     * @param TenantHelper
     * @param TimetableOptionsHelper
     * @param TIMETABLE_OPTIONS
     * @param TRACK_FILTERS_SPECIAL_VALUES
     * @param MgateRestproxyService
     * @param TRANSPORT_NAME
     * @param {MgateService} MgateService
     * @param StorageFactory
     *
     * @constructor
     */
    function TimetableOptionsController(
        FindStationPopupService,
        ScreenHelper,
        TenantHelper,
        TimetableOptionsHelper,
        TIMETABLE_OPTIONS,
        TRACK_FILTERS_SPECIAL_VALUES,
        MgateRestproxyService,
        TRANSPORT_NAME,
        MgateService,
        StorageFactory,
    ) {
        const vm = this;
        const STORAGE = StorageFactory.Storage('Main');

        vm.$onInit = init;

        const SPECIAL_TRACK_FILTERS = Object.values(TRACK_FILTERS_SPECIAL_VALUES);

        const { paramsStringToArray } = TimetableOptionsHelper;
        const { getScreenCoordinates } = ScreenHelper;

        vm.TIMETABLE_OPTIONS = TIMETABLE_OPTIONS;
        vm.products = [];
        vm.notRepeatedOptions = [
            TIMETABLE_OPTIONS.additionalTime.id,
            TIMETABLE_OPTIONS.poleFilter.id,
            TIMETABLE_OPTIONS.productOrder.id,
        ];
        vm.transportLabel = TRANSPORT_NAME;

        vm.isTimetableFilterVisible = TenantHelper.isTimetableFilterVisible;
        vm.findDirectionStation = findDirectionStation;
        vm.clearDirectionStation = clearDirectionStation;
        vm.getFilterListItems = getFilterListItems;
        vm.isFilterCheckboxChecked = isFilterCheckboxChecked;
        vm.onFilterCheckboxClick = onFilterCheckboxClick;
        vm.getCheckboxLabel = getCheckboxLabel;

        vm.isTransportTypeAllowed = isTransportTypeAllowed;
        vm.toggleTransportType = toggleTransportType;
        vm.getUnexpectedProducts = getUnexpectedProducts;
        vm.removeUnexpectedProducts = removeUnexpectedProducts;

        function init() {
            vm.options = TimetableOptionsHelper.options();
            void setProductParams();
        }

        function findDirectionStation() {
            FindStationPopupService.show(getScreenCoordinates(vm.data))
                .then(({extId, name}) => {
                    vm.data.timetableOptions[TIMETABLE_OPTIONS.direction.prop] = {
                        name,
                        extId,
                    }
                });
        }

        function getCheckboxLabel(value, optionName) {
            if (optionName === TIMETABLE_OPTIONS.platformFilter.prop) {
                const defaultFilterValue = SPECIAL_TRACK_FILTERS.find(filterValue =>
                    filterValue.value.toString() === value
                );

                return defaultFilterValue ? defaultFilterValue.label : value;
            }

            return value;
        }

        function getFilterListItems(optionName) {
            switch (optionName) {
                case TIMETABLE_OPTIONS.journeyNameFilter.prop:
                    return vm.lines;
                case TIMETABLE_OPTIONS.platformFilter.prop:
                    return vm.tracks;
                default:
                    return [];
            }
        }

        function getInputValue(optionName) {
            const value = optionName === TIMETABLE_OPTIONS.platformFilter.prop
                ? vm.data.locationTrack
                : vm.data.timetableOptions[optionName];

            return value || '';
        }

        function isFilterCheckboxChecked(value, optionName) {
            const inputValue = getInputValue(optionName);

            const selectedOptions = paramsStringToArray(inputValue, true);

            return selectedOptions.includes(value);
        }

        function onFilterCheckboxClick(value, optionName) {
            const inputValue = getInputValue(optionName);

            const selectedOptions = paramsStringToArray(inputValue, true);

            const valueIndex = selectedOptions.indexOf(value);
            valueIndex === -1
                ? selectedOptions.push(value)
                : selectedOptions.splice(valueIndex, 1);

            if (optionName === TIMETABLE_OPTIONS.platformFilter.prop) {
                vm.data.locationTrack = selectedOptions.join(',');
                vm.data.timetableOptions[optionName] = selectedOptions.filter(option =>
                    SPECIAL_TRACK_FILTERS.every(filter => filter.value.toString() !== option)
                ).join(',');
                return;
            }

            vm.data.timetableOptions[optionName] = selectedOptions.join(',');
        }

        function clearDirectionStation() {
            delete vm.data.timetableOptions[TIMETABLE_OPTIONS.direction.prop];
        }

        function isTransportTypeAllowed(mctId) {
            const allowedTypes = vm.data.timetableOptions.productOrder;
            return allowedTypes && allowedTypes.includes(mctId);
        }

        function toggleTransportType(mctId) {
            const allowedTypes = vm.data.timetableOptions.productOrder;
            const index = allowedTypes.indexOf(mctId);
            if (index !== -1) {
                allowedTypes.splice(index, 1)
            } else {
                allowedTypes.push(mctId);
            }
        }

        /**
         * Set transportation types (i.e. "Bus", "Train", etc.)
         * @returns {Promise<void>}
         */
        function setProductParams() {
            return MgateService.productParams(STORAGE.currentUserInfo.subdomain.id)
                .then(response => {
                    const products = response.productConfig;

                    if (Array.isArray(products)) {
                        vm.products = products;
                    } else {
                        console.error('Error: Unexpected products list response');
                    }
                });
        }

        /**
         * @return {Number[]}
         */
        function getUnexpectedProducts() {
            return vm.data.timetableOptions.productOrder.filter(
                cls => !vm.products.some(product => product.cls === cls)
            );
        }

        function removeUnexpectedProducts() {
            const unexpected = getUnexpectedProducts();
            const clsList = vm.data.timetableOptions.productOrder;

            vm.data.timetableOptions.productOrder = clsList.filter(
                cls => !unexpected.includes(cls)
            );
        }
    }
})();

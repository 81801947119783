(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userGroups', {
            templateUrl: '/assets/views/user-groups/user-groups.tpl.html',
            controller: UserGroupsController
        });

    function UserGroupsController(
        $state,
        $stateParams,
        UserGroupsDataService,
        PopupService,
        UtilitiesService,
    ) {
        const vm = this;

        vm.$onInit = init;

        // public properties
        vm.count = 0;
        vm.updateCounter = 0;
        vm.userGroupsAll = [];
        vm.userGroups = [];
        vm.updateUserGroupsList = updateUserGroupsList;
        vm.searchValue = '';
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    title: 'name',
                    width: 35
                },
                {
                    name: 'TENANT',
                    width: 20,
                    title: item => item.tenant ? item.tenant.name : '-',
                },
                {
                    name: 'APPS',

                    title: item => item.app_modes.join(', '),
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'editBtn',
                        callback: editUserGroup,
                        permissionAction: 'can_modify_user_groups',
                    },
                    {
                        class: 'copyBtn',
                        callback: onCopy,
                        permissionAction: 'can_create_user_groups',
                    }
                ]
            },
            updateCallback: updateUserGroupsList,
            generatePermissionName
        };

        vm.addUserGroup = addUserGroup;

        /**
         * Initialization method
         */
        function init() {
            UserGroupsDataService.getUserGroups()
                .then(response => {
                    vm.userGroupsAll = response.filter(item => item.deleted !== 1);
                    updateUserGroupsList();
                })
        }

        /**
         * Create new element
         */
        function addUserGroup() {
            $state.go('app.addUsersGroup');
        }

        /**
         * Updates list of user groups
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateUserGroupsList(page = 1, itemsPerPage = 25) {
            vm.filteredUserGroups  = vm.searchValue
                ? vm.userGroupsAll.filter(group => {
                    return group.name.toUpperCase().includes(vm.searchValue.toUpperCase());
                })
                : vm.userGroupsAll;

            vm.userGroups = UtilitiesService.getArrayPage(vm.filteredUserGroups, page, itemsPerPage);
        }

        /**
         * Removes users group by id
         *
         * @param {MouseEvent} $event
         * @param {object} userGroup
         */
        function deleteUsersGroup($event, userGroup) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'ARE_YOU_SURE',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                UserGroupsDataService.deleteUserGroup(userGroup.id)
                    .then(() => {
                        vm.userGroupsAll = vm.userGroupsAll.filter(group => group.id !== userGroup.id);
                    })
            });
        }

        /**
         * Edit userGroup
         *
         * @param {MouseEvent} $event
         * @param {Object} userGroup
         */
        function editUserGroup($event, userGroup) {
            $state.go('app.editUsersGroup', {
                data: userGroup,
            });
        }

        function onCopy($event, userGroup) {
            $event.preventDefault();
            $event.stopPropagation();

            const copy = angular.copy(userGroup);
            delete copy.id;

            $state.go('app.addUsersGroup', {
                data: copy,
                copy: true,
            });
        }

        function generatePermissionName(button) {
            return button.permissionAction;
        }
    }
})();

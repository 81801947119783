(function() {
    'use strict';

    angular.module('beacon.app')
        .component('habitTypeTab', {
            templateUrl: '/assets/views/campaign/habit/tabs/type/type.tpl.html',
            controller: HabitTypeTab,
            bindings: {
                model: '='
            }
        });

    function HabitTypeTab(
        habitEditState,
        HABIT_TYPES_TITLES
    ) {

        const vm = this;
        vm.state = habitEditState;
        vm.setHabitType = setHabitType;

        vm.habitTypes = HABIT_TYPES_TITLES;

        vm.$onInit = init;

        /**
         * Init
         */
        function init() {
            vm.state.canProcessNext = !!vm.model.type;
        }

        /**
         * Sets segment type
         * @param {number} type
         */
        function setHabitType(type) {
            vm.model.type = type;
            vm.state.canProcessNext = !!vm.model.type;
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('multiGuideLot', {
            templateUrl: '/assets/views/share-park/operations/multi-guide/multi-guide-lot/multi-guide-lot.tpl.html',
            controller: ShareParkMultiGuideLotController,
            bindings: {
                lot: '<'
            }
        });

    function ShareParkMultiGuideLotController(

    ) {
        const vm = this;

        vm.$onInit = init;
        vm.classes = classes;
        vm.icon = icon;

        function init() {

        }

        /**
         * @return {string}
         */
        function classes() {
            const classes = [];

            if (vm.lot.occupied) {
                classes.push('occupied');
            }

            if (vm.lot.alarming) {
                classes.push('alarming');
            }

            if (vm.lot.reserved) {
                classes.push('reserved');
            }

            if (vm.lot.available) {
                classes.push('available');
            }

            if (vm.lot.lotType.name === 'Electro') {
                classes.push('electro');
            }

            if (vm.lot.lotType.name === 'Handicapped') {
                classes.push('handicapped');
            }

            return classes.join(' ');
        }

        function icon() {
            switch (vm.lot.lotType.name) {
                // case 'Normal':
                //     return 'local_parking';
                case 'Electro':
                    return 'flash_on';
                case 'Handicapped':
                    return 'accessible';
            }
        }
    }
})();
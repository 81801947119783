(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('DeleteSubdomainPopupController', DeleteSubdomainPopupController);

    function DeleteSubdomainPopupController(
        $uibModalInstance,
        PopupService,
        TenantDataService,
        UtilitiesService,
        data,
    ) {
        const vm = this;
        const DELETE_CONTENT_RADIO_BTN_ID = -1;
        const SUBDOMAIN_ID_TO_REMOVE = data && data.subdomainIdToRemove;

        vm.onCancelBtnClick = onCancelBtnClick;
        vm.onDeleteBtnClick = onDeleteBtnClick;
        vm.onRadioGroupChanged = onRadioGroupChanged;

        vm.subdomains = [{ id: DELETE_CONTENT_RADIO_BTN_ID, label: 'Delete Content' }];
        vm.selectedSubdomainId = null;
        vm.isDeleteBtnDisabled = true;

        /**
         * Initialization method
         */
        function init() {
            TenantDataService.getTenantsWhereUserIsTenantAdmin()
                .then((res) => {
                    let tenants = UtilitiesService.plainResponse(res);

                    for (let tenant of tenants) {
                        for (let subdomain of tenant.subdomains) {
                            if (subdomain.id === SUBDOMAIN_ID_TO_REMOVE) {
                                continue;
                            }
                            subdomain.label = tenant.tenant_name + ' - ' + subdomain.name;
                            vm.subdomains.push(subdomain);
                        }
                    }
                });
        }

        /**
         * Cancel button click handler
         */
        function onCancelBtnClick() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Delete button click handler
         */
        function onDeleteBtnClick() {
            PopupService.show({
                templateUrl: '/assets/views/common/popups/subdomain/delete-subdomain/delete-confirmation.tpl.html',
                controller: 'ConfirmationPopupController',
                controllerAs: 'confirmationPopupController',
                keyboard: false,
                resolve: {
                    data: {}
                }
            }).then(() => {
                let subdomainIdForReassigning;

                let subdomainToReassign = vm.subdomains.find((subdomain) => {
                    if (subdomain.id === DELETE_CONTENT_RADIO_BTN_ID) {
                        return false;
                    }

                    return subdomain.id === vm.selectedSubdomainId;
                });

                if (subdomainToReassign && subdomainToReassign.id) {
                    subdomainIdForReassigning = subdomainToReassign.id;
                }

                $uibModalInstance.close(subdomainIdForReassigning);
            });
        }

        /**
         * Radio item click handler
         */
        function onRadioGroupChanged() {
            vm.isDeleteBtnDisabled = false;
        }
        
        init();
    }
}());
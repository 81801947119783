(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('TierGroupsFormController', TierGroupsFormController);

    function TierGroupsFormController($scope, $state, $stateParams, StatesFactory, TierGroupService) {
        const vm = this;
        vm.state = StatesFactory.TierGroupStates.refresh();
        vm.state.canNext = true;

        _init();

        /**
         * Finish button handler
         *
         * @private
         */
        function _onFinish() {
            const data = angular.copy(vm.data);
            const formData = new FormData();
            formData.append('json', JSON.stringify(data));

            const serviceAction = $stateParams.type === 'edit' ? 'save' : 'create';
            TierGroupService[serviceAction](formData)
                .then(function() {
                    $state.go('app.loyaltyTierGroups', {
                        paginationData: $stateParams.paginationData
                    });
                }).catch((err) => {
                    vm.state.finish = false;
                    throw err;
                });
        }

        /**
         * Controller initialization
         *
         * @private
         */
        function _init() {
            if ($stateParams.type === 'edit') {
                vm.data = angular.copy($stateParams.data);
                vm.data.label = JSON.parse(vm.data.label);
                vm.data.description = JSON.parse(vm.data.description);
            } else {
                vm.data = {};
                vm.data.tiers = [];
            }
        }

        /**
         * Watcher for finish button click event
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.dataForm.$valid,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('routeMap', {
            templateUrl: '/assets/views/common/components/route-map/route-map.tpl.html',
            controller: RouteMapController,
            bindings: {
                markers: '<',
                markerClickCallback: '<',
            }
        });

    /**
     * @typedef {object} RouteMapMarker
     *
     * @property {{lat: number, lng: number}} position
     * @property {string} label
     * @property {boolean} checked
     */

    /**
     * @property {RouteMapMarker[]} markers
     *
     * @param $scope
     * @param $timeout
     * @param ModelsFactory
     * @param UserUtilitiesService
     * @param MARKER_ICON_SIZE
     * @constructor
     */
    function RouteMapController(
        $scope,
        $timeout,
        ModelsFactory,
        UserUtilitiesService,
        MARKER_ICON_SIZE,
    ) {
        const vm = this;

        const { MapOptions } = ModelsFactory;

        const MAX_ZOOM = 20;
        const MAPS = google.maps;
        let map = null;
        let allMarkers = [];

        vm.$onInit = onInit;

        vm.mapId = 'route_map';

        function onInit() {
            const positionOptions = UserUtilitiesService.userLocationOptions();
            if (!positionOptions.maxZoom) {
                positionOptions.maxZoom = MAX_ZOOM;
            }
            const mapOptions = new MapOptions(positionOptions);

            $timeout(function () {
                let mapId = vm.mapId;
                const mapElement = document.getElementById(mapId);
                map = new MAPS.Map(mapElement, mapOptions);
                updateMarkers();
            }, 0);
        }

        function updateMarkers() {
            if (!map) {
                return;
            }
            clearMarkers();
            drawMarkers();
        }

        /**
         * Puts markers on the map from the markers array
         */
        function drawMarkers() {
            if (!vm.markers || !vm.markers.length) {
                return;
            }

            const viewBounds = new MAPS.LatLngBounds();

            vm.markers.forEach(marker => {
                const newMarker = new MAPS.Marker({
                    position: marker.position,
                    label: marker.label,
                    map
                });

                if (marker.checked) {
                    newMarker.setIcon({
                        url: '/assets/images/bluemapicon.png',
                        scaledSize: new google.maps.Size(MARKER_ICON_SIZE.width, MARKER_ICON_SIZE.height),
                        anchor: new google.maps.Point(MARKER_ICON_SIZE.width / 2, MARKER_ICON_SIZE.height)
                    });
                }

                newMarker.addListener('click', () => {
                    if (vm.markerClickCallback) {
                        vm.markerClickCallback(marker.label);
                    }

                    if (marker.onClick) {
                        marker.onClick();
                    }
                });

                allMarkers.push(newMarker);
                const coordinates = new MAPS.LatLng(marker.position.lat, marker.position.lng);
                viewBounds.extend(coordinates);
            });

            map.fitBounds(viewBounds);
        }

        /**
         * Removes all markers
         */
        function clearMarkers() {
            allMarkers.forEach(marker => {
                marker.setMap(null);
            });
            allMarkers = [];
        }

        $scope.$watch(
            () => vm.markers,
            updateMarkers,
            true
        )
    }
})();

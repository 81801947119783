(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofenceItemsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/geofence-tab/geofence-items-tab/geofence-items-tab.tpl.html',
            controller: GeofenceItemsTab,
            bindings: {
                onSelect: '=',
                searchName: '<',
            }
        });

    function GeofenceItemsTab(
        $element,
        $scope,
        $state,
        ListPaginationService,
        GeofenceDataService,
        PopupService,
    ){
        const vm = this;
        const element = $element[0];

        vm.geofenceFile = null;

        vm.$onInit = init;

        vm.geofences = [];
        vm.filteredGeofences = [];
        vm.geofencesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'locationTitle',
                    width: '100',
                    title: item => item.name,
                }
            ],
            buttons: {
                width: '10',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteGeofence,
                    },
                    {
                        class: 'editBtn',
                        callback: updateGeofence,
                    },
                ]
            },
            itemClickCallback: selectGeofence,
            updateCallback: updateGeofences,
            initOnOpen: true,
            needRadiobutton: true,
        };
        vm.paginationData = {
            itemsPerPage: 10,
        };
        vm.updateCounter = 0;

        vm.createGeofence = createGeofence;
        vm.setGeofenceFile = setGeofenceFile;
        vm.uploadGeofenceFile = uploadGeofenceFile;

        function init() {
            loadGeofences();
        }

        /**
         *
         * @return {undefined}
         */
        function loadGeofences () {
            GeofenceDataService.getGeofences().then(response => {
                vm.geofences = response;
                vm.filteredGeofences = vm.searchName ? filterGeofencesByName(vm.searchName) : vm.geofences;
                vm.filteredGeofencesCount = vm.filteredGeofences.length;
                vm.updateCounter++;
            });
        }

        /**
         * Creates geofence
         *
         */
        function createGeofence() {
            $state.go('app.addGeofence', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Updates geofence
         *
         */
        function updateGeofence($event, geofenceItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editGeofence', {
                data: geofenceItem,
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Removes geofence
         *
         */
        function deleteGeofence($event, geofence) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DELETE_GEOFENCE_CONFIRMATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                GeofenceDataService.deleteGeofence(geofence.id)
                    .then(() => {
                        loadGeofences();
                    });
            });
        }

        /**
         * Handler on geofence item click
         *
         * @param {object} geofence
         */
        function selectGeofence(geofence) {
            vm.onSelect(geofence.name, [geofence.externalId]);
        }

        /**
         * Updates geofences list
         * @param page
         * @param itemsPerPage
         */
        function updateGeofences(page, itemsPerPage) {
            vm.visibleGeofences = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredGeofences).visibleItems;
        }

        /**
         * Open file manager
         */
        function setGeofenceFile() {
            element.querySelector('#geofenceFile').click();
        }

        /**
         * Upload geofence
         */
        function uploadGeofenceFile(file) {
            const formData = new FormData();
            formData.append('file', file);

            GeofenceDataService.upload(formData)
                .then(() => loadGeofences())
                .catch(error => {
                    PopupService.showAlertPopup({
                        text: error.data.message,
                        okButtonText: 'OK',
                    });
                });
        }

        /**
         *
         * @param name
         * @return {T[]}
         */
        function filterGeofencesByName(name) {
            return vm.geofences.filter(geofence => geofence.name.toUpperCase().includes(name.toUpperCase()));
        }

        $scope.$watch(
            () => vm.searchName,
            searchName => {
                vm.filteredGeofences = vm.searchName
                    ? filterGeofencesByName(searchName)
                    : vm.geofences;

                vm.filteredGeofencesCount = vm.filteredGeofences.length;
            }
        )
    }
})();

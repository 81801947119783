(function() {
    'use strict';

    angular.module('beacon.app')
        .service('HabitService', HabitService);

    function HabitService(Restangular) {

        const path = 'habits';
        /**
         * Getting the habits list
         *
         * @returns {array}
         */
        function getList(data) {
            return Restangular.all(path).getList(data);
        }

        /**
         * Creating habit
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function create(data) {
            return Restangular.all(path)
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updates habit
         *
         * @param {object} habit
         * @param {object} data
         */
        function update(habit, data) {
            data.append('_method', 'PUT');
            return habit.customPOST(data, '', {}, {'Content-Type': undefined});
        }

        /**
         * Delete habit (changing status to 3)
         *
         * @param {object} habit
         * @param {object} data
         */
        function remove(habit, data) {
            data.append('_method', 'DELETE');
            return habit.customPOST(data, '', {}, {'Content-Type': undefined});
        }

        return {
            getList,
            create,
            update,
            remove,
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newUserGroup', {
            templateUrl: '/assets/views/user-groups/new/new-user-group.tpl.html',
            controller: NewUserGroupController
        });

    function NewUserGroupController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        Restangular,
        ShareParkDataService,
        StatesFactory,
        UserDataService,
        UserGroupsDataService,
    ) {
        const vm = this;

        vm.state = StatesFactory.UserGroupStates.refresh();
        vm.state.type = $stateParams.type;
        vm.isEditMode = vm.state.type === 'edit';
        vm.userGroupData = new ModelsFactory.UserGroup();
        
        vm.$onInit = init;

        function init() {
           if ($stateParams.data) {
                vm.userGroupData = $stateParams.data;
            }

            vm.userGroupData.selectedServiceProviders = [];

            if (vm.isEditMode || $stateParams.data) {
                vm.userGroupData.group_permissions = vm.userGroupData.permissions.map(({id}) => id);
            }
        }

        /**
         * On finish handler
         */
        function onFinish() {
            const groupData = Restangular.copy(vm.userGroupData);

            groupData.allowedServiceProviders = vm.userGroupData.selectedServiceProviders.map(({id}) => id);
            groupData.allowedProfiles = vm.userGroupData.selectedProfiles.map(({id}) => id);
            groupData.allowedCampuses = vm.userGroupData.selectedCampuses.map(({id}) => id);

            delete groupData.selectedServiceProviders;
            delete groupData.selectedProfiles;
            delete groupData.selectedCampuses;
            delete groupData.permissions;

            if (vm.isEditMode) {
                groupData.put()
                    .then(() => {
                        $state.go('app.userGroups');
                    });
            } else {
                UserGroupsDataService.createUserGroup(groupData)
                    .then(() => {
                        $state.go('app.userGroups');
                    })
            }
        }

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
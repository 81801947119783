(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ManageScreensApiService', ManageScreensApiService);

    function ManageScreensApiService($http) {
        return {
            getTenantList,
            getScreensList,
        }

        /**
         * @returns Array<{id: number, name: string, screens_count: number}>
         */
        function getTenantList() {
            return $http.get('/api/manage-screens/tenants-with-screens')
                .then(response => response.data);
        }

        /**
         * @returns Array<{id: number, screenRef: number, controllerRef: number}>
         */
        function getScreensList(tenantId) {
            return $http.get('/api/manage-screens/screens-list/' + tenantId)
                .then(response => response.data);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('DigitalDisplayHelper', DigitalDisplayHelperService);

    function DigitalDisplayHelperService($window, ContentDataService, DIGITAL_DISPLAY_SCALE_TYPES) {

        return {
            getScaleClass,
            generatePreview,
        };

        /**
         *
         * @param {int} scaleType
         * @return {string}
         */
        function getScaleClass(scaleType) {
            const scaleTypeKey = Object.keys(DIGITAL_DISPLAY_SCALE_TYPES).find(
                key => DIGITAL_DISPLAY_SCALE_TYPES[key].id === scaleType
            );
            return DIGITAL_DISPLAY_SCALE_TYPES[scaleTypeKey]
                ? DIGITAL_DISPLAY_SCALE_TYPES[scaleTypeKey].class
                : '';
        }

        /**
         * Generates digital display preview
         * @param {object} contentData
         * @return {*}
         */
        function generatePreview(contentData) {
            let formData = new FormData();
            let data = {
                name: contentData.title[contentData.language_id],
                contentData: JSON.stringify(contentData)
            };

            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }

            return ContentDataService.preparePreview(formData)
                .then((response) => $window.open(response.preview_url + '/', '_blank'))
                .catch(console.error.bind(console));
        }
    }
}());

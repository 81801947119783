(function() {
    'use strict';

    angular.module('trmTimetableHafas').service('TimetableHafasService',
        function(
            $q,
            $timeout,
            $moment,
            TIMETABLE_ORDER,
        ) {
            const shadow = '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)';

            return {
                order,
                makeJourneyColumns,
                makeJourneyGroupedColumns,
                makeJourneyInlineGroupedColumns,
                calculateGridSizePerArea,
                generateElementsList,
                generateGridTemplate,
                waitViewUpdate,
                isElementAvailable,
                log,
                warn,
                error,
                shadow,
                overrideOptions,
            }

            /**
             * Order method for journeys
             *
             * @param {Journey} journeyA
             * @param {Journey} journeyB
             * @param {TimetableHafasOrderCondition[]} orderConditions
             * @private
             */
            function order(journeyA, journeyB, orderConditions) {
                for (const order of orderConditions) {
                    const a = order.direction === 'desc' ? journeyB : journeyA;
                    const b = order.direction === 'desc' ? journeyA : journeyB;

                    let result;

                    switch (order.by) {
                        case TIMETABLE_ORDER.PRODUCT:
                            const indexA = a.product.productConfig.index;
                            const indexB = b.product.productConfig.index;

                            result = indexA - indexB;
                            break;
                        case TIMETABLE_ORDER.REAL_TIME:
                            // realtime value could be missing
                            result = (a.getRealTime() || a.getScheduledTime()).unix() - (b.getRealTime() || b.getScheduledTime()).unix();
                            break;
                        case TIMETABLE_ORDER.SCHEDULED_TIME:
                        default:
                            result = a.getScheduledTime().unix() - b.getScheduledTime().unix();
                    }

                    if (result !== 0) {
                        return result;
                    }
                }

                return 0;
            }

            /**
             * Convert array of journeys to matrix of columns and rows
             *
             * @param {Journey[]} journeysBuffer
             * @param {Number} rows
             * @param {Number} cols
             * @param {TimetableHafasOptions} options
             *
             * @return {Journey[][]}
             * @private
             */
            function makeJourneyColumns(journeysBuffer, rows, cols, options) {
                const scroll = options.view.scroll;
                const gridSize = rows * cols;
                const total = journeysBuffer.length;

                const journeysPerRow = total > gridSize && scroll
                    ? Math.ceil(total / cols)
                    : rows;

                const journeys = (scroll ? journeysBuffer : journeysBuffer.slice(0, gridSize))
                    .sort((a, b) => order(a, b, options.order));

                const result = [];
                let totalPushed = 0;

                for (let i = 0; i < cols; i++) {
                    result.push(journeys.slice(totalPushed, totalPushed + journeysPerRow));
                    totalPushed += journeysPerRow;
                }

                return result;
            }

            /**
             * Convert array of journeys to matrix of columns and rows
             *
             * @param {Journey[]} journeys
             * @param {Number} rows
             * @param {Number} cols
             * @param {boolean} scroll
             *
             * @return {Array<Journey|JourneyProduct>[]}
             * @private
             */
            function makeJourneyInlineGroupedColumns(journeys, rows, cols, scroll) {
                const gridSize = rows * cols;
                const grouped = _groupJourneysByProduct(journeys);
                const products = _extractUniqueOrderedProducts(journeys);
                const journeysPerProductAdvance = (gridSize - products.length) / products.length;
                const productPlacement = journeysPerProductAdvance >= 1;
                const journeysPerProduct = journeysPerProductAdvance >= 1
                    ? Math.trunc(journeysPerProductAdvance)
                    : 1;

                if (scroll) {
                    rows = Math.ceil((journeys.length + products.length) / cols);
                }

                // Make flat array of product headers and journeys first
                const flatGrid = [];
                const getNextJourneys = (product, amount) => {
                    return scroll
                        ? grouped[product.clsOverride]
                        : grouped[product.clsOverride].splice(0, amount);
                }

                products.forEach((product, index) => {
                    const isLastProduct = products.length === index + 1;

                    if (productPlacement) {
                        flatGrid.push(product);
                    }

                    flatGrid.push(
                       ...getNextJourneys(product, journeysPerProduct)
                    );

                    const emptyCellsToBottom = rows - flatGrid.length % rows;

                    switch (true) {
                        // fill the empty space in the end with last product journeys
                        case isLastProduct:
                            flatGrid.push(
                                ...getNextJourneys(product, emptyCellsToBottom)
                            );
                            break;
                        // do not place product headers in the end of the column
                        case emptyCellsToBottom === 1 && cols > 1:
                            flatGrid.push(
                                ...getNextJourneys(product, 1)
                            );
                            break;
                    }
                });

                // Convert flat array to grid
                const result = [];

                for (let i = 0; i < cols; i++) {
                    result.push(flatGrid.splice(0, rows));
                }

                return result;
            }

            /**
             * Convert array of journeys to matrix of columns and rows
             *
             * @param {Journey[]} journeys
             * @param {Number} rows
             * @param {Number} cols
             * @param {boolean} scroll
             *
             * @return {products: JourneyProduct[], columns: Journey[][]}
             * @private
             */
            function makeJourneyGroupedColumns(journeys, rows, cols, scroll) {
                const grouped = _groupJourneysByProduct(journeys);
                const products = _extractUniqueOrderedProducts(journeys);

                /**
                 * @type {Journey[][]}
                 */
                let result = products.map(product => grouped[product.clsOverride])

                if (!scroll) {
                    result = result.map(col => col.splice(0, rows));
                }

                return {
                    products: result.map(col => col[0].product).splice(0, cols),
                    columns: result.splice(0, cols)
                };
            }

            /**
             * Calculates the journeys grid size, based on the area size and timetable options
             *
             * @param {number} areaWidth
             * @param {number} areaHeight
             * @param {TimetableHafasOptions} options
             * @return {{rows: number, cols: number, rowHeight: number, rowWidth: number}}}
             * @private
             */
            function calculateGridSizePerArea(areaWidth, areaHeight, options) {
                const borderWidth = options.view.borderWidth;
                const innerMarginHorizontal = options.view.innerMarginHorizontal;
                const innerMarginVertical = options.view.innerMarginVertical;
                const outerMargin = options.view.outerMargin;

                let availableHeight = areaHeight + innerMarginHorizontal - outerMargin * 2;
                let availableWidth = areaWidth + innerMarginVertical - outerMargin * 2;

                // if no margin, borders are 1 less than amount of rows or cols
                if (!innerMarginHorizontal) {
                    availableHeight = availableHeight - borderWidth;
                }

                // if no margin, borders are 1 less than amount of rows or cols
                if (!innerMarginVertical) {
                    availableWidth = availableWidth - borderWidth;
                }

                let rows, cols;

                switch (options.view.type) {
                    case 'strict':
                        rows = options.view.rows;
                        cols = options.view.columns;
                        break;

                    /**
                     * Calculation of row size (borders and inner margins take part in calculation as well)
                     * be careful: amount of borders is different with spacing (CSS "border-collapse: separate|collapse")
                     *
                     * 1) "collapsed" row:
                     *  --------------------
                     * | CONTENT | CONTENT |
                     * ---------------------
                     * | CONTENT | CONTENT |
                     * --------------------
                     *
                     * 2) "separate" row:
                     *  ----------  ----------
                     * | CONTENT | | CONTENT |
                     * ----------  -----------
                     *  ----------  ----------
                     * | CONTENT | | CONTENT |
                     * ----------  ----------
                     */
                    case 'dynamic':
                        const colTotalWidth = innerMarginVertical
                            ? options.view.minColWidth + borderWidth * 2 + innerMarginVertical
                            : options.view.minColWidth + borderWidth + innerMarginVertical;

                        const colTotalBorderHeight = innerMarginHorizontal
                            ? borderWidth * 2
                            : borderWidth;
                        const rowTotalHeight = options.view.minRowHeight + colTotalBorderHeight + innerMarginHorizontal;

                        rows = Math.floor(availableHeight / rowTotalHeight) || 1;
                        cols = Math.floor(availableWidth / colTotalWidth) || 1;
                        break;

                    default:
                        throw new Error('Missing "options.view.type" parameter');
                }

                const rowHeight = innerMarginHorizontal
                    ? availableHeight / rows - options.view.innerMarginHorizontal - borderWidth * 2
                    : availableHeight / rows - borderWidth;

                const rowWidth = innerMarginVertical
                    ? availableWidth / cols - options.view.innerMarginVertical - borderWidth * 2
                    : availableWidth / cols - borderWidth;

                return {
                    rows,
                    cols,
                    rowHeight,
                    rowWidth,
                }
            }

            /**
             * @param {TimetableHafasElement[]} elementsOriginal
             * @param {TimetableHafasViewMode} viewMode
             * @return {TimetableHafasElement[]}
             * @private
             */
            function generateElementsList(elementsOriginal, viewMode) {
                /** @type {TimetableHafasElement[]} */
                const elements = angular.copy(elementsOriginal)
                    .filter(el => isElementAvailable(el));

                const spacer = {
                    type: 'spacer',
                    params: {
                        width: 'auto',
                    }
                };

                const destinationIndex = elements.findIndex(el => el.type === 'direction');
                const isDestinationFirst = destinationIndex === 0;

                switch (viewMode)
                {
                    case 'normal':
                        return elements.map((el, index, els) => {
                            const getCol = (elements, index) => {
                                switch (true) {
                                    case !index:
                                        return 'first';
                                    case elements.length === (index + 1):
                                        return 'last';
                                    default:
                                        return 'middle';
                                }
                            }

                            const getWidth = (/**TimetableHafasElement=*/el) => {
                                switch (true) {
                                    case el.type === 'direction':
                                        return 'auto';
                                    case !!el.width:
                                        return el.width;
                                    default:
                                        return 'min-content';
                                }
                            }

                            return {
                                ...el,
                                params: {
                                    line: 'single',
                                    col: getCol(els, index),
                                    colIndex: index + 1,
                                    width: getWidth(el),
                                    header: true,
                                }}
                        });

                    case 'narrow':
                        let colIndex = 0;

                        if (!isDestinationFirst) {
                            // move destination to the end of the array
                            elements.push(elements.splice(destinationIndex, 1)[0]);
                        }

                        return elements.map((el, index, els) => {
                            if (el.type === 'direction') {
                                return {
                                    ...el,
                                    params: {
                                        line: isDestinationFirst ? 'first' : 'last',
                                        col: 'single',
                                        colIndex: 1,
                                        width: 'full',
                                        header: false,
                                    }
                                }
                            } else {
                                const getCol = (elements, colIndex) => {
                                    switch (true) {
                                        case (elements.length - 1) === 1:
                                            return 'single';
                                        case !colIndex:
                                            return 'first';
                                        case (elements.length - 1) === (colIndex + 1): // elements except destination
                                            return 'last';
                                        default:
                                            return 'middle';
                                    }
                                }

                                return {
                                    ...el,
                                    params: {
                                        line: isDestinationFirst ? 'last' : 'first',
                                        col: getCol(els, colIndex),
                                        width: el.type === 'product' ? 'auto' : 'min-content',
                                        colIndex: colIndex++ + 1,
                                        header: true,
                                    }
                                }
                            }
                        });

                    case 'slim':
                        const chunks = _makeSlimViewChunks(elements);
                        const chunkSizes = chunks.map(chunk => chunk.length);
                        const maxChunkSize = Math.max(...chunkSizes);
                        const maxChunkIndex = chunkSizes.indexOf(maxChunkSize);

                        /** @type {TimetableHafasElement[]} */
                        const result = [];

                        chunks.forEach((line, lineIndex, lines) => {
                            const lineFirst = !lineIndex;
                            const lineLast = lineIndex + 1 === lines.length;

                            const getCol = (colIndex, cols) => {
                                const colFirst = !colIndex;
                                const colLast = colIndex + 1 === cols.length;

                                switch (true) {
                                    case cols.length === 1:
                                        return 'single';
                                    case colFirst:
                                        return 'first';
                                    case colLast:
                                        return 'last';
                                    default:
                                        return 'middle';
                                }
                            }

                            // Fill up 1st line with extra spacers
                            if (lineFirst) {
                                line.push({
                                    ...spacer,
                                    behavior: 'show',
                                });
                                for (let i = 0; i < chunkSizes[2] ; i++) {
                                    line.push(spacer);
                                }
                            }

                            // Fill up the last line with extra spacers
                            if (lineLast) {
                                line.unshift({
                                    ...spacer,
                                });
                                for (let i = 0; i < chunkSizes[0]; i++) {
                                    line.unshift(spacer);
                                }
                            }

                            line.forEach(
                                /**
                                 * @param {TimetableHafasElement} el
                                 * @param colIndex
                                 * @param cols
                                 */
                                (el, colIndex, cols) => {
                                    if (el.type === 'direction') {
                                        result.push({
                                            ...el,
                                            params: {
                                                line: 'middle',
                                                col: 'single',
                                                width: 'full',
                                                colIndex: 1,
                                                header: false,
                                            }
                                        });
                                    } else {
                                        result.push({
                                            ...el,
                                            params: {
                                                line: lineFirst ? 'first' : 'last',
                                                col: getCol(colIndex, cols),
                                                // width: el.type === 'product' ? 'auto' : 'min-content',
                                                width: el.type === 'spacer' ? el.params.width : 'min-content',
                                                colIndex: colIndex + 1,
                                                header: lineIndex === maxChunkIndex,
                                            }
                                        });
                                    }
                                }
                            );
                        });

                        return result;
                }

                /**
                 * Split elements into 3 chunks: [[before destination], [destination], [after destination]]
                 *
                 * @param {TimetableHafasElement[]} elements
                 * @return {TimetableHafasElement[][]}
                 * @private
                 */
                function _makeSlimViewChunks(elements) {
                    const els = angular.copy(elements);
                    const destinationIndex = els.findIndex(el => el.type === 'direction');

                    const result = [];

                    result.push(els.splice(0, destinationIndex)); // before destination
                    result.push(els.splice(0, 1)); // destination
                    result.push(els); // after destination

                    return result;
                }
            }

            /**
             * @param {TimetableHafasElement[]} elements
             * @return {string}
             * @private
             */
            function generateGridTemplate(elements) {
                const columnSizes = elements.map(element => {
                    switch (true) {
                        case !isElementAvailable(element):
                            return null;
                        case element.params.width === 'full':
                            return null;
                        default:
                            return element.params.width;
                    }
                })
                .filter(el => el);

                return columnSizes.join(' ');
            }

            /**
             * @param {TimetableHafasElement} element
             * @return {boolean}
             */
            function isElementAvailable(element) {
                return ['show', 'auto'].includes(element.behavior);
            }

            /**
             * @param {number} extraDelay
             * @return {Promise<void>}
             * @private
             */
            function waitViewUpdate(extraDelay = 0) {
                return $q(resolve => {
                    $timeout(() => {
                        resolve();
                    }, extraDelay);
                });
            }

            function log() {
                const time = $moment().format('HH:mm:ss.SSS');
                console.log(time, ...arguments);
            }

            function warn() {
                const time = $moment().format('HH:mm:ss.SSS');
                console.log(`%c${time}`, 'background: orange; color: #FFF', ...arguments);
            }

            function error() {
                const time = $moment().format('HH:mm:ss.SSS');
                console.log(`%c${time}`, 'background: #FF0000; color: #FFFFFF', ...arguments);
            }

            /**
             * @param {Journey[]} journeys
             * @return {Object<number, Journey[]>} - where the Object key is a MgateProduct.cls value
             * @private
             */
            function _groupJourneysByProduct(journeys) {
                const grouped = {};

                journeys.forEach(journey => {
                    const cls = journey.product.clsOverride;
                    if (!grouped[cls]) {
                        grouped[cls] = [journey];
                    } else {
                        grouped[cls].push(journey);
                    }
                });

                return grouped;
            }

            /**
             * @param {Journey[]} journeys
             * @return {JourneyProduct[]}
             * @private
             */
            function _extractUniqueOrderedProducts(journeys) {
                return journeys
                    .map(journey => journey.product)
                    .filter((value, index, self) => {
                        return self.findIndex(
                            product => product.clsOverride === value.clsOverride
                        ) === index;
                    })
                    .sort((productA, productB) => {
                        const indexA = productA.productConfig && productA.productConfig.index || null;
                        const indexB = productB.productConfig && productB.productConfig.index || null;

                        return indexA - indexB;
                    });
            }

            /**
             * Override options with different preset (keeps the compatibility if the preset is outdated)
             *
             * @param {TimetableHafasOptions} target
             * @param {TimetableHafasOptions} source
             * @param {boolean} rootLoop
             */
            function overrideOptions(target, source, rootLoop = true) {
                const keys = Object.keys(source);

                keys.forEach(key => {
                    const value = source[key];

                    switch (true) {
                        case key === 'elements' && rootLoop:
                            source.elements.forEach(sEl => {
                                const type = sEl.type;
                                const tEl = target.elements.find(tEl => tEl.type === type);

                                if (tEl) {
                                    overrideOptions(tEl, sEl, false);
                                }
                            });
                            break;
                        case angular.isObject(value):
                            overrideOptions(target[key], source[key]);
                            break;
                        default:
                            target[key] = value;
                    }
                })
            }
        });
})();

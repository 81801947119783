(function() {
    'use strict';

    angular.module('capCapacity')
        .component('capCapacityApp', {
            templateUrl: '/assets/views/components/cap-capacity-app/cap-capacity-app.tpl.html',
            controller: CapCapacityAppController,
        });

    function CapCapacityAppController(
        $scope,
        $location,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            _setVars();
            _validateVars();
        }

        function _validateVars() {
            switch (true) {
                case !$scope.carParkId:
                    $scope.error = 'Missing GET param carParkId';
                    break;
                case !$scope.tenantId:
                    $scope.error = 'Missing GET param tenantId';
                    break;
            }
        }

        function _setVars() {
            const {
                carParkId,
                tenantId
            } = $location.search();

            $scope.carParkId = carParkId;
            $scope.tenantId = tenantId;
        }
    }
})();

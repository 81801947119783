(function() {
    'use strict';

    angular.module('beacon.app')
        .service('GoogleTranslateService', GoogleTranslateService);

    function GoogleTranslateService($http) {
        return {
            translate,
            translateMultiple,
        };

        /**
         * Translate text with Google Translate
         *
         * @param text
         * @param srcLangCode
         * @param tgtLangCode
         * @param html
         * @returns {Promise}
         */
        function translate(text, srcLangCode, tgtLangCode, html = false) {
            if (!text) {
                return Promise.resolve('');
            }

            return $http({
                method: 'POST',
                url: '/api/google-translate',
                data: {
                    text,
                    target: tgtLangCode,
                    source: srcLangCode,
                }
            })
            .then(response => {
                if (html) {
                    return response.data.text;
                }
                // unescape special chars
                const doc = new DOMParser().parseFromString(response.data.text, "text/html");
                return doc.documentElement.textContent;
            });
        }

        function translateMultiple(texts, srcLangCode, tgtLangCode) {
            return $http({
                method: 'POST',
                url: '/api/google-translate-multiple',
                data: {
                    texts,
                    target: tgtLangCode,
                    source: srcLangCode,
                }
            }).then(response => {
                const parser = new DOMParser();
                return response.data.map(translation => {
                    return parser.parseFromString(translation.text, "text/html").documentElement.textContent;
                });
            });
        }
    }
})();
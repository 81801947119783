(function() {
    'use strict';

    angular.module('beacon.app')
        .component('oauthOpenidCallback', {
            templateUrl: '/assets/views/login/oauth-openid-callback/oauth-openid-callback.tpl.html',
            controller: OAuthOpenidCallbackController
        });
    
    function OAuthOpenidCallbackController(
        $state,
        $location,
        OpenIdOAuthService,
        LoginService,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            const { code, state: appMode } = _getQueryParams();

            if (!code) {
                $state.go('login');

                return;
            }

            _login(code)
                .then(response => {
                    if (!response) { return; }

                    const { token, subdomains } = response;

                    LoginService.oauthAndRedirectToSubdomain(token, subdomains[0].id, appMode);
                });
        };

        function _getQueryParams() {
            return $location.search();
        }

        /**
         * Authorize openid user
         * @param {string} code authorization code
         */
        function _login(code) {
            return OpenIdOAuthService.login(code)
                .catch((err) => {
                    $state.go('login', { errorMessage: err.data });
                });
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('statisticsContentTab', {
            templateUrl: '/assets/views/statistic/tabs/content-tab/content-tab.tpl.html',
            controller: StatisticsContentTabController,
            bindings: {
                selectedContents: '=',
                content: '<',
                contentGroupsArray: '<'
            }
        });

    function StatisticsContentTabController(
            $scope,
            statisticsTabState,
            ContentHelper,
        ) {
        const vm = this;

        vm.state = statisticsTabState;

        vm.contentCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'contentTitle',
                    width: '45',
                    title: ContentHelper.getContentNameForList
                },
                {
                    name: 'ICON',
                    class: 'contentIcon',
                    width: '20',
                    title: function(item) {
                        var group = vm.contentGroupsArray.filter(function(group) {
                            return group.icon_id.toString() === item.content_group.icon_id.toString();
                        })[0];

                        return group && group.icon ? '<img src="' + group.icon.iconUrl + '">' : 'N/A';
                    }
                },
                {
                    name: 'TYPE',
                    class: 'contentType',
                    width: '30',
                    translate: true,
                    title: function(item) {
                        return item.content_type.name;
                    }
                }
            ],
            header: false,
            itemClickCallback: toggleContent
        };

        /**
         * Handler on content select/deselect
         *
         * @param {object} item
         */
        function toggleContent(item) {
            vm.state.canProcessNext = !!vm.selectedContents.length;
        }

        function onViewChange(newValue, oldValue) {
            if (Number(newValue) === vm.state.views.CONTENT) {
                vm.state.canProcessNext = !!vm.selectedContents.length;
            }
        }

        $scope.$watch(angular.bind(vm, function() {
            return this.state.view;
        }), onViewChange);
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ValuesService', ValuesService);

    function ValuesService(Restangular) {
        return {
            downloadCsv: downloadCsv,
            uploadCsv: uploadCsv
        };

        /**
         * Downloads values as CSV file
         */
        function downloadCsv() {
            return new Restangular.one('values/csv').get();
        }

        /**
         * Uploads values as CSV file
         */
        function uploadCsv(data) {
            return new Restangular.one('values/csv').withHttpConfig({transformRequest: angular.identity})
                    .customPOST(data, '', undefined, {'Content-Type': undefined});
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkGatewaysTab', {
            templateUrl: '/assets/views/share-park/car-parks/new/tabs/car-park-gateways/car-park-gateways-tab.tpl.html',
            controller: CarParkGatewaysTabController,
            bindings: {
                carParkData: '<',
            }
        });

    function CarParkGatewaysTabController(
        $scope,
        ModelsFactory,
        StatesFactory,
        UtilitiesService,
        UserUtilitiesService,
        GoogleMapsUtilitiesService,
        ShareParkDataService,
        IotEntityDataService,
        CAR_PARK_ENTRANCE_DIRECTION,
        ENTITY_TYPE_CATEGORIES,
        $mdConstant
    ) {
        const vm = this;

        vm.state = StatesFactory.CarParkStates;

        const { userLocationOptions } = UserUtilitiesService;
        const { getCoordinates, toPrecision } = UtilitiesService;
        const { showMap } = GoogleMapsUtilitiesService;
        const { getIotEntities, getIotEntityTypes } = IotEntityDataService;
        const { getAllDevices } = ShareParkDataService;
        const imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };
        const devices = [];

        vm.digitalIOs = [];
        vm.iotEntities = [];
        vm.iotEntityTypes = [];
        vm.visibleEntityAssignments = [];

        vm.$onInit = init;
        vm.addGateway = addGateway;
        vm.addEntrance = addEntrance;
        vm.addIotEntityAssignment = addIotEntityAssignment;
        vm.isChannelValid = isChannelValid;
        vm.getDigitalIoById = getDigitalIoById;
        vm.chipsSeparators = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.SEMICOLON, $mdConstant.KEY_CODE.COMMA]

        vm.gatewaysListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'gateway-name',
                    width: '80',
                    title: item =>  {
                        return item.gateway_name || '';
                    }
                }
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteGateway,
                    },
                ],
            },
            itemClickCallback: selectGateway,
            updateCallback: updateGatewaysList,
            needRadiobutton: true,
            additionalData: {
                imagePickerConfig,
                onMap,
            }
        };

        vm.entrancesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'entrance-label',
                    width: '80',
                    title: (item, index) =>  {
                        return `Entrance ${index + 1}`;
                    }
                }
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteEntrance,
                    },
                ],
            },
            updateCallback: () => {},
            itemClickCallback: selectEntrance,
            needRadiobutton: true,
            additionalData: {
                directions: Object.values(CAR_PARK_ENTRANCE_DIRECTION),
                imagePickerConfig,
                devices,
                getMaxEntranceNumber,
            },
        };

        vm.entityAssignmentsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'entrance-label',
                    width: '80',
                    title: (item, index) =>
                        item.iot_entity_id
                            ? vm.iotEntities.find(entity => entity.id === item.iot_entity_id).name
                            : ''
                }
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteIotEntityAssignment,
                    },
                ],
            },
            updateCallback: () => {},
            additionalData: {
                iotEntities: [],
                iotEntityTypes: [],
                isLaneVisible,
                getEntityById,
            }
        };

        function init() {
            getUserData();
            getAllDevicesFromAPI();

            vm.state.canBack = true;
            vm.carParkData.car_park_gateways.forEach(gateway => {
                gateway.onImage = onImage.bind(gateway);
                gateway.onImageDelete = onImageDelete.bind(gateway);

                gateway.entrances.forEach(entrance => {
                    entrance.onDirectionChange = onEntranceDirectionChange.bind(entrance);
                    entrance.onImage = onImage.bind(entrance);
                    entrance.onImageDelete = onImageDelete.bind(entrance);
                    entrance.iotEntitiesMode = !!entrance.iot_entity_assignments.length;
                });
            });
        }

        /**
         * Adds new gateway
         */
        function addGateway() {
            const newGateway = new ModelsFactory.CarParkGateway();
            newGateway.onImage = onImage.bind(newGateway);
            newGateway.onImageDelete = onImageDelete.bind(newGateway);

            vm.carParkData.car_park_gateways.push(newGateway);
        }

        /**
         * Deletes gateway
         * @param $event
         * @param {object} gateway
         * @param {number} index
         */
        function deleteGateway($event, gateway, index) {
            $event.stopPropagation();
            $event.preventDefault();
            vm.carParkData.car_park_gateways.splice(index, 1);
        }

        function updateGatewaysList() {
        }

        function selectGateway(gateway) {
            vm.selectedGateway = gateway;
            vm.selectedEntrance = null;

            vm.selectedDigitalIoAssignment = null;
            vm.visibleEntityAssignments = [];
        }

        function selectEntrance(entrance) {
            vm.selectedEntrance = entrance;
        }

        function getUserData() {
            vm.mapDefaults = userLocationOptions();
        }

        /**
         * Gets all devices list
         */
        function getAllDevicesFromAPI() {

            Promise.all([
                getIotEntities(),
                getIotEntityTypes(),
                getAllDevices(),
            ]).then(([iotEntities, iotEntityTypes, devices]) => {

                vm.digitalIOs = iotEntities.plain().filter(iotEntity => iotEntity.type === ENTITY_TYPE_CATEGORIES.DIGITAL_IO);
                vm.iotEntities = iotEntities.plain().filter(iotEntity => iotEntity.type !== ENTITY_TYPE_CATEGORIES.DIGITAL_IO);
                vm.entityAssignmentsListData.additionalData.iotEntities = vm.iotEntities;
                prepareEntrancesData();

                vm.iotEntityTypes = iotEntityTypes.plain();
                vm.iotEntityTypes.forEach(entityType => {
                        entityType.technicalProperties = angular.fromJson(entityType.technicalProperties);
                    });
                vm.entityAssignmentsListData.additionalData.iotEntityTypes = vm.iotEntityTypes;

                vm.devices = devices.plain();
                vm.entrancesListData.additionalData.devices = vm.devices;

                canNext();
            })
        }


        function prepareEntrancesData() {
            vm.carParkData.car_park_gateways.forEach(gateway =>
                gateway.entrances.forEach(entrance => {
                    if (!entrance.relatedDigitalIoAssignment) {
                        entrance.relatedDigitalIoAssignment = getRelatedDigitalIoAssignment(entrance)
                            || new ModelsFactory.IotEntityAssignment();

                        if (entrance.relatedDigitalIoAssignment.iot_entity_id) {
                            const index = entrance.iot_entity_assignments.findIndex(assignment =>
                                assignment.iot_entity_id === entrance.relatedDigitalIoAssignment.iot_entity_id
                            );

                            entrance.iot_entity_assignments.splice(index, 1);
                        }
                    }
                })
            )
        }

        /**
         * Map icon click handler
         */
        function onMap(gateway) {
            const coords = getCoordinates(gateway.latlng);
            const carParkCoords = getCoordinates(vm.carParkData.latlng);

            const mapPopup = showMap({
                mapInfo: !!coords ? [coords] : null,
                mapDefaults: Object.assign(vm.mapDefaults, {
                    maxZoom: 20,
                    latitude: !coords ? carParkCoords.latitude : null,
                    longitude: !coords ? carParkCoords.longitude : null,
                }),
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(function(mapInfo) {
                const coords = mapInfo.coordinates;
                const precision = 6;

                const lat = toPrecision(coords.latitude, precision);
                const lng = toPrecision(coords.longitude, precision);
                gateway.latlng = `${lat},${lng}`;
            });
        }

        function onImage(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            this.imageFile = imageFile;
            this.image_ref = urlCreator.createObjectURL(imageFile);
        }

        function onImageDelete() {

            delete this.imageFile;
            this.image_ref = '';
        }

        function addEntrance() {
            const newEntrance = new ModelsFactory.CarParkEntrance;
            newEntrance.onDirectionChange = onEntranceDirectionChange.bind(newEntrance);
            newEntrance.onImage = onImage.bind(newEntrance);
            newEntrance.onImageDelete = onImageDelete.bind(newEntrance);

            vm.selectedGateway.entrances.push(newEntrance);
        }

        function deleteEntrance($event, entrance, index) {
            vm.selectedGateway.entrances.splice(index, 1);
        }

        function addIotEntityAssignment() {
            if (!vm.selectedEntrance.iot_entity_assignments) {
                vm.selectedEntrance.iot_entity_assignments = [];
            }

            vm.selectedEntrance.iot_entity_assignments.push(new ModelsFactory.IotEntityAssignment());
        }

        function deleteIotEntityAssignment($event, assignment, index) {
            vm.selectedEntrance.iot_entity_assignments.splice(index, 1);
        }

        function onEntranceDirectionChange() {
            const selectedDirection = Object.values(CAR_PARK_ENTRANCE_DIRECTION)
                .find(direction =>
                    direction.direction === this.entrance_direction
                );
            this.entrance_label = selectedDirection ? selectedDirection.label : '';
        }

        /**
         * Gets max entrance number for selected device
         * @param deviceId
         * @return {null}
         */
        function getMaxEntranceNumber(deviceId) {
            if (!deviceId || !vm.devices.length) {
                return;
            }

            const selectedDevice = vm.devices.find(device => device.id === deviceId);

            return selectedDevice ?
                parseInt(selectedDevice.relays) : null
        }

        /**
         * Checks if lane is visible
         * @param {Number} iotEntityId
         * @return {*|boolean}
         */
        function isLaneVisible(iotEntityId) {
            const selectedEntity = vm.iotEntities.find(iotEntity => iotEntity.id === iotEntityId);
            return selectedEntity && selectedEntity.type !== ENTITY_TYPE_CATEGORIES.QR_CODE_READER
        }

        /**
         * Checks if channel is valid
         * @param { String } channel
         * @param { Number } digitalIoId
         * @return {boolean}
         */
        function isChannelValid(channel, digitalIoId) {
            const channelNumber = channel ? Number(channel) : NaN;
            if (Number.isNaN(channelNumber)) {
                return false;
            }

            const relatedDiditalIo = vm.digitalIOs.find(digitalIo => digitalIo.id === digitalIoId);

            if (relatedDiditalIo) {
                const digitalIoType = vm.iotEntityTypes.find(type => type.id === relatedDiditalIo.entityTypeRef);
                return digitalIoType.technicalProperties.channelCount >= channel && channel >= 0;
            }

            return true;
        }

        /**
         * Returns iot entity by id
         * @param {Number} entityId
         * @return {*}
         */
        function getEntityById(entityId) {
            return vm.iotEntities.find(entity => entity.id === entityId);
        }

        /**
         * Returns digitalIo by id
         * @param {Number} id
         * @return {*}
         */
        function getDigitalIoById(id) {
            return vm.digitalIOs.find(digitalIo => digitalIo.id === id);
        }

        /**
         * Returns related digital io for specific entrance
         * @param { Object } entrance
         * @return {Object|null}
         */
        function getRelatedDigitalIoAssignment(entrance) {
            return entrance.iot_entity_assignments.find(assignment =>
                vm.digitalIOs.some(digitalIo => digitalIo.id === assignment.iot_entity_id)
            ) || null;
        }

        function canNext() {
            return vm.carParkData.car_park_gateways.every(gateway =>
                gateway.entrances.every(entrance => {
                    if (!entrance.relatedDigitalIoAssignment) {
                        return true;
                    }

                    const { channel, iot_entity_id } = entrance.relatedDigitalIoAssignment;

                    return iot_entity_id
                        ? isChannelValid(channel, iot_entity_id)
                        : true;
                })
            )
        }

        /**
         * Form validation watcher
         */
        $scope.$watch(() =>
            vm.newCarParkForm && vm.newCarParkForm.$valid
            , formValid => {
                vm.state.canNext = !!formValid && canNext();
            }, true);


        $scope.$watch(() =>
            vm.carParkData
            , () => {
                vm.state.canNext = vm.newCarParkForm && vm.newCarParkForm.$valid && canNext();
            }, true);
    }
})();
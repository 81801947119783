(function () {
    'use strict';

    angular
        .module('beacon.app')
        .service('CustomerCommunicationService', CustomerCommunicationService);

    function CustomerCommunicationService($http) {
        return {
            seveBulkEmails,
            getBulkEmailsList,
            sendBulkEmails,
            deleteBulkEmail,
            sendTestEmail
        };

        function seveBulkEmails(data) {
            return $http.post('/api/sharePark/customers/mailing', data);
        }

        function sendBulkEmails(id) {
            return $http.post(`/api/sharePark/customers/mailing/send/${id}`, null);
        }

        function deleteBulkEmail(id) {
            return $http.post(`/api/sharePark/customers/mailing/delete/${id}`, null);
        }

        function getBulkEmailsList(data) {
            return $http.get('/api/sharePark/customers/mailing');
        }

        function sendTestEmail(data) {
            return $http.post('/api/sharePark/customers/mailing/send-test', data);
        }
    }
})();

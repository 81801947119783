(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkCustomerInfo', {
            templateUrl: '/assets/views/share-park/customers/customers/customer-info/customer-info.tpl.html',
            controller: CustomerInfoController,
            bindings: {
                listItem: '<',
            }
        });

    function CustomerInfoController(
        $scope,
        ShareParkDataService,
    ) {
        const vm = this;
        const DATE_FORMAT = 'DD.MM.YYYY HH:mm';

        vm.expireDate = expireDate;
        vm.amount = amount;
        vm.formatDate = formatDate;
        vm.statusIcon = statusIcon;
        vm.statusColor = statusColor;

        vm.isVisible = null;
        vm.shopDetails = null;
        vm.customer = null;
        vm.paymentMeans = [];

        vm.$onInit = init();

        /**
         * Controller initialization
         */
        function init() {
            vm.customer = vm.listItem.customer;
            setWatchers();
        }

        /**
         * Fill shop details
         */
        function updateShopDetails() {
            ShareParkDataService.getCustomerShopInfo(vm.customer.guid)
                .then(response => {
                    vm.shopDetails = response.plain();
                    vm.paymentMeans = vm.shopDetails.user.payment.meansList.filter(item => item.status !== 3); // deleted
                    vm.transactions = vm.shopDetails.user.payment.transactions.filter(item => item.transType !== 'REFUND_REDEEM');
                    vm.transactions.sort((a, b) => moment(b.transDate) - moment(a.transDate));
                });
        }

        /**
         * Date format for card expire date
         *
         * @param month
         * @param year
         * @returns {string}
         */
        function expireDate(month, year) {
            const monthFormatted = month <= 9
                ? '0' + month
                : month;
            return `${monthFormatted}/${year}`;
        }

        /**
         * Format the amount
         *
         * @param cents
         * @returns {number}
         */
        function amount(cents) {
            return cents / 100;
        }

        /**
         * Format date
         *
         * @param string
         * @returns {string}
         */
        function formatDate(string) {
            return moment(string).format(DATE_FORMAT);
        }

        /**
         * Make payment status icon
         *
         * @param name
         * @returns {string}
         */
        function statusIcon(name) {
            switch (name) {
                case 'AUTHORIZED':
                    return 'done';
                case 'CAPTURED':
                    return 'attach_money';
                case 'CANCELED':
                    return 'highlight_off';
                default:
                    return 'error_outline';
            }
        }

        /**
         * Get status color
         *
         * @param name
         * @returns {string}
         */
        function statusColor(name) {
            switch (name) {
                case 'AUTHORIZED':
                    return 'green';
                case 'CAPTURED':
                    return 'orange';
                default:
                    return 'red';
            }
        }

        /**
         * Set watchers
         */
        function setWatchers() {
            $scope.$watch(
                () => vm.listItem.isOpen,
                isOpen => isOpen && updateShopDetails()
            );
        }
    }
})();
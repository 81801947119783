(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofenceDetailsTab', {
            templateUrl: '/assets/views/location/new-geofence/tabs/geofence-details/geofence-details.tpl.html',
            controller: GeofenceDetailsTab,
            bindings: {
                data: '='
            },
        });

    function GeofenceDetailsTab(
        $scope,
        $translate,
        geofenceAddState,
    ) {
        const vm = this;

        vm.state = geofenceAddState;

        vm.updatePolyline = updatePolyline;
        vm.canFinish = canFinish;

        vm.maxlenght = 60;

        function updatePolyline(polylineNew) {
            canFinish(polylineNew);
            $scope.$apply();
        }

        /**
         * Check if page can be saved
         */
        function canFinish(polylineNew = null) {
            const polyline = polylineNew !== null ? polylineNew : vm.data.polyline;
            vm.state.canProcessNext = !!polyline && !!vm.data.name;
        }

        init();

        function init() {
            canFinish();
        }
    }
})();

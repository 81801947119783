(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capReservations', {
            templateUrl: '/assets/views/share-park/operations/reservations/reservations.tpl.html',
            controller: CarParksReservationsController
        });

    function CarParksReservationsController(
        StatesFactory,
    ) {
        const vm = this;

        vm.state = StatesFactory.CapReservationsStates.refresh();
        vm.selectedCarParks = [];

        vm.onCarParkSelect = onCarParkSelect;
        vm.updateSelectedCarParks = updateSelectedCarParks;

        function onCarParkSelect() {
            vm.state.canNext = !!vm.selectedCarParks.length;
        }

        function updateSelectedCarParks(selectedCarParks) {
            vm.selectedCarParks = selectedCarParks;
            onCarParkSelect();
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('passwordValidator', function(PASSWORD_VALIDITY_PATTERN) {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.password = (modelValue, viewValue) => {
                        const value = viewValue || '';
                        // lower case, upper case, number, and special character, 8-20 characters
                        return !!value.match(PASSWORD_VALIDITY_PATTERN);
                    };
                }
            };
        });
})();

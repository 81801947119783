(function() {
    'use strict';

    angular.module('beacon.app')
        .service('GoogleAPIUtilitiesService', GoogleAPIUtilitiesService);

    function GoogleAPIUtilitiesService(GOOGLE_API_KEY) {

        const YOUTUBE_API_V3 = 'https://www.googleapis.com/youtube/v3/';

        /**
         * Gets YouTube video duration
         *
         * @param {string} videoId
         * @return {Promise}
         */
        function getYouTubeVideoDuration(videoId) {
            let url = YOUTUBE_API_V3 + 'videos?id=' + videoId + '&part=contentDetails&key=' + GOOGLE_API_KEY;

            return fetch(url, {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json; charset=UTF-8'
                    })
                })
                .then(res => res.json())
                .catch(error => {
                        return {
                            status: error.status,
                            statusText: error.statusText
                        };
                    })
                .then(res => {
                    let videoInfo = res.items.find(item => item.id === videoId);
                    let seconds = 0;

                    if (videoInfo) {
                        seconds = convertISO8601ToSeconds(videoInfo.contentDetails.duration);
                    }

                    return seconds;
                });
        }

        /**
         * Converts ISO 8601 string to seconds
         *
         * @param {string} str
         * @return {number}
         */
        function convertISO8601ToSeconds(str) {
            let totalSeconds = 0;

            if (str) {
                totalSeconds = moment.duration(str).asSeconds();
            }

            return totalSeconds;
        }

        return {
            getYouTubeVideoDuration,
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('topCustomers', {
            templateUrl: '/assets/views/share-park/dashboard/widgets/top-customers/top-customers.tpl.html',
            controller: TopCustomersController,
            bindings: {
                customers: '<',
                currencies: '<',
                timeRange: '<',
            }
        });

    function TopCustomersController(
        $scope,
    ) {
        const vm = this;

        const TOP_LIST_LENGTH = 5;

        vm.$onInit = init;

        vm.topCustomers = [];

        vm.updateTopCustomers = updateTopCustomers;
        vm.setCurrency = setCurrency;

        function init() {
            vm.currentCurrency = vm.currencies[0] || 'EUR';
            updateTopCustomers();
        }

        function updateTopCustomers() {
            vm.topCustomers = getCustomersWithPurchasesInRange();
        }

        /**
         * Returns filtered customers array
         * @return { Array }
         */
        function getCustomersWithPurchasesInRange() {
            const minDate = vm.timeRange.diffValue ?
                moment().subtract(vm.timeRange.diffValue, vm.timeRange.diffUnit)
                : null;

            const customersList = vm.customers.map(({nameFirst, nameLast, emailAddress, purchases}) => {
                const filteredPurchases = purchases.filter(purchase => {
                        const dateMatches = !minDate || purchase.purchaseDate.isAfter(minDate);

                        return dateMatches && purchase.currencyCode === vm.currentCurrency
                    }
                );

                return {
                    nameFirst,
                    nameLast,
                    email: emailAddress,
                    purchasesCount: filteredPurchases.length,
                    purchasesSum: filteredPurchases.reduce((accum, currentValue) => {
                        const price = angular.isNumber(currentValue.discountedPrice)
                            ? currentValue.discountedPrice
                            : currentValue.originalPrice;

                        return accum + price;
                        }, 0) / 100
                }
            });

            return customersList.filter(customer => customer.purchasesSum)
                .sort((a, b) => b.purchasesSum - a.purchasesSum)
                .slice(0, TOP_LIST_LENGTH);
        }

        /**
         * Sets current currency
         * @param {String} currency
         */
        function setCurrency(currency) {
            vm.currentCurrency = currency;
            updateTopCustomers();
        }

        $scope.$watch(
            () => vm.timeRange,
            () => updateTopCustomers()
        )
    }
})();
(function() {
    'use strict';

    angular.module('scMeetings').service(
        'ScMeetingsService',
        function(
            $moment,
        ) {
            /**
             * @param {SCInvitation} invitation
             *
             * @return {string}
             */
            function relativeTime(invitation)
            {
                const date = $moment(invitation.invitationStart);
                const now = $moment();

                const minutes = date.diff(now, 'minutes') + 1;


                if (isPast(date)) {
                    return 'started';
                }

                if (minutes < 60) {
                    return `in ${minutes} min` + (minutes > 1 ? 's' : '');
                }

                const hours = date.diff(now, 'hours');

                if (hours < 24) {
                    return `in ${hours} hour` + (hours > 1 ? 's' : '');
                }

                const days = date.diff(now, 'days');

                return `in ${days} day` + (days > 1 ? 's' : '');
            }

            /**
             * @param {SCInvitation} invitation
             * @return {string}
             */
            function meetingClass(invitation) {
                const MINUTES_TO_SOON = 15;
                const MINUTES_TO_CHECK_IN = 60;
                const minutesTo = _getMinutesTo(invitation.invitationStart) + 1;

                if (isPast(invitation.invitationStart)) {
                    return 'ongoing';
                }

                if (minutesTo > 0 && minutesTo <= MINUTES_TO_SOON) {
                    return 'soon';
                }

                if (minutesTo > MINUTES_TO_SOON && minutesTo <= MINUTES_TO_CHECK_IN) {
                    return 'not-soon';
                }
            }

            /**
             * @param {string|moment.Moment} date
             * @return {*}
             */
            function isToday(date) {
                const now = $moment();
                const moment = $moment(date);

                return moment.isSame(now, 'day');
            }

            /**
             * @param {string|moment.Moment} date
             * @return {*}
             */
            function isPast(date) {
                const now = $moment();
                const moment = $moment(date);

                return moment.isBefore(now);
            }

            /**
             * @param {string} date
             * @return {number}
             */
            function _getMinutesTo(date) {
                const now = $moment();
                const moment = $moment(date);

                return moment.diff(now, 'minutes');
            }

            return {
                relativeTime,
                meetingClass,
                isToday,
                isPast,
            }
        }
    );
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newTrmPoiCategory', {
            templateUrl: '/assets/views/trm-poi/categories/new/new-trm-poi-category/new-trm-poi-category.tpl.html',
            controller: NewTrmPoiCategoryController
        });

    function NewTrmPoiCategoryController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        TrmPoiContentDataService,
    ) {
        const vm = this;

        vm.$onInit = init;
        vm.isNewMainCategoryName = false;

        // public properties
        vm.state = StatesFactory.PoiCategoryStates.refresh();
        vm.state.canNext = true;
        vm.setNewMainName = setNewMainName;
        vm.onSetNameInputKeyPress = onSetNameInputKeyPress;
        vm.selectMainCategoryName = selectMainCategoryName;

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            if (vm.state.type === 'new') {
                vm.contentData = new ModelsFactory.PoiContent();
            } else {
                vm.contentData = $stateParams.data;
            }

            TrmPoiContentDataService.getTrmPoiCategories().then(response => {
                vm.mainCategoryNames = [];
                vm.internalCategories = [];

                response.data.forEach(category => {
                    if(!vm.mainCategoryNames.includes(category.mainCategoryName)) {
                        vm.mainCategoryNames.push(category.mainCategoryName)
                        vm.internalCategories.push({mainCategoryName: category.mainCategoryName, internalCategory: category.internalCategory});
                    }
                });
                vm.mainCategoryNames = vm.mainCategoryNames.sort((a, b) => a.localeCompare(b));
            });
            
        }

        function selectMainCategoryName() {
            vm.internalCategories.forEach(category => {
                if (category.mainCategoryName === vm.contentData.mainCategoryName) {
                    vm.contentData.internalCategory = category.internalCategory;
                }
            })
        }

        function setNewMainName() {
            if (!vm.mainCategoryNames.includes(vm.contentData.mainCategoryName)) {
                vm.mainCategoryNames.push(vm.contentData.mainCategoryName)
            }
            vm.isNewMainCategoryName = false;
        }

        function onSetNameInputKeyPress(event) {
            const enterKey = 13
            if (event.which !== enterKey) { return; }

            setNewMainName();
        }


        function _onFinish() {
            TrmPoiContentDataService.saveTrmPoiCategory(vm.contentData).then(() => {
                $state.go('app.trmPoiCategories');
            });
        }

        /**
         * Watcher for finish button click event
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.contentForm.$valid,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newTrmPoiElement', {
            templateUrl: '/assets/views/trm-poi/elements/new/new-trm-poi-element/new-trm-poi-element.tpl.html',
            controller: NewTrmPoiElementsController
        });

    function NewTrmPoiElementsController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        TrmPoiContentDataService,
        ImageUploadFactory,
        UtilitiesService,
        GoogleMapsUtilitiesService,
        UserUtilitiesService,
    ) {
        const vm = this;

        vm.$onInit = init;
        const { getCoordinates, toPrecision } = UtilitiesService;
        const { showMap, getLocationDataByCoordinates, getCoordinatesByLocation } = GoogleMapsUtilitiesService;
        let imageUploader = null;

        // public properties
        vm.state = StatesFactory.PoiContentStates.refresh();
        vm.state.canNext = true;

        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
            fullInfo: true,
        };
        vm.poiScheduleConfig = {
            columns: [
                {
                    width: '90',
                    title: (listItem) => {
                        _onDatesChange();

                        return getDate(listItem.scheduleFrom) + ' - ' + getTillDate(listItem.scheduleFrom, listItem.scheduleTill)
                    },
                },
            ],
            buttons: {
                width: '10',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteTimeSlot,
                    },
                ],
            },
            updateCallback: () => { },
        };
        vm.eventDateRange = '';

        // public methods
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.onMap = onMap;
        vm.getDate = getDate;
        vm.getTillDate = getTillDate;
        vm.addNewTimeSlot = addNewTimeSlot;
        vm.setLocationAddress = setLocationAddress;
        vm.setCoordinates = setCoordinates;

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            if (vm.state.type === 'new') {
                vm.contentData = $stateParams.data || new ModelsFactory.PoiContent();

                if ($stateParams.data) {
                    delete vm.contentData.id;
                }
            } else {
                vm.contentData = $stateParams.data;
            }

            if (vm.contentData.poiScheduleElements) {
                _setFilteredPoiScheduleElements();
            }

            TrmPoiContentDataService.getTrmPoiTypes().then(poiTypes => {
                vm.poiTypes = poiTypes.data.sort((a, b) => a.name.localeCompare(b.name));
            });

            TrmPoiContentDataService.getTrmPoiCategories().then(poiCategories => {
                vm.poiCategories = poiCategories.data.sort((a, b) => a.mainCategoryName && a.mainCategoryName.localeCompare(b.mainCategoryName));
            });

            imageUploader = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.contentData.imagesRef,
                onImageUploaded: (imageUrl) => {
                    vm.contentData.imagesRef = imageUrl;
                }
            });
        }

        function addNewTimeSlot() {
            if (!vm.contentData.poiScheduleElements) {
                vm.contentData.poiScheduleElements = [];
            }

            vm.contentData.poiScheduleElements.push(new ModelsFactory.TimeSlotModel());
            _setFilteredPoiScheduleElements();
        }

        function deleteTimeSlot($event, timeSlot) {
            $event.stopPropagation();
            $event.preventDefault();

            vm.contentData.poiScheduleElements = timeSlot.id
                ? vm.contentData.poiScheduleElements.filter(el => el.id !== timeSlot.id)
                : vm.contentData.poiScheduleElements
                    .filter(el => el.scheduleFrom !== timeSlot.scheduleFrom && el.scheduleTill !== timeSlot.scheduleTill);

            _setFilteredPoiScheduleElements();
        }

        function getDate(date) {
            const dayAndMonth = moment(date).format('DD.MM');
            const year = moment(date).format('YYYY');
            const time = moment(date).format('HH:mm');

            return `${dayAndMonth}.${year} ${time}`;
        }

        function getTillDate(from, till) {
            const dateFrom = moment(from).format('DD.MM');
            const yearFrom = moment(from).format('YYYY');
            
            const dateTill = moment(till).format('DD.MM');
            const yearTill = moment(till).format('YYYY');
            const timeTill = moment(till).format('HH:mm')

            return `${dateFrom !== dateTill ? dateTill : ''}${yearFrom !== yearTill ? '.' + yearTill : ''} ${timeTill}`;
        }

        /**
         * Image file input handler
         * @param {{blob: File, cropData: Object}} imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         */
        function onImage(imageFile, uploadToS3) {
            imageUploader.setImage(uploadToS3 ? imageFile.blob : imageFile.blob.name);
            vm.contentData.imagesRef = imageUploader.getImagePreview();
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            imageUploader.setImage(null);
            vm.contentData.imagesRef = '';
        }

        /**
         * Map icon click handler
         */
        function onMap() {
            vm.mapDefaults = UserUtilitiesService.userLocationOptions();
            const coords = getCoordinates(vm.contentData.latlng);

            const mapPopup = showMap({
                mapInfo: coords ? [coords] : null,
                mapDefaults: vm.mapDefaults,
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(mapInfo => {
                const coords = mapInfo.coordinates;
                const precision = 6;

                const lat = toPrecision(coords.latitude, precision);
                const lng = toPrecision(coords.longitude, precision);
                vm.contentData.latlng = `${lat},${lng}`;
                setLocationAddress(lat, lng);
            });
        }

        function setCoordinates() {
            getCoordinatesByLocation(vm.contentData.address)
                .then((latlng) => {
                    vm.contentData.latlng = latlng;
                })
        }

        function setLocationAddress(latitude, longitude) {
            getLocationDataByCoordinates({ latitude, longitude })
                .then(({locationAddressLine, locationLocality}) => {
                    vm.contentData.address = locationAddressLine;
                    vm.contentData.locationLabel = locationLocality;
                })
        }

        /**
         * Is called whenever date range is changed for POI Elements.
         * Misc.: technically is called on each re-render in "accordion-list"
         * 
         * @private
         */
        function _onDatesChange() {
            _setEventDateRange();
        }

        /**
         * Get min and max date from the list of filtered POI Schedule Elements and assign "eventDateRange" a formatted value
         * 
         * @private
         */
        function _setEventDateRange() {
            const fromDates = vm.filteredPoiScheduleElements.map(element => moment(element.scheduleFrom));
            const tillDates = vm.filteredPoiScheduleElements.map(element => moment(element.scheduleTill));

            vm.eventDateRange = getDate(moment.min(fromDates)) + ' - ' + getDate(moment.max(tillDates));
        }

        /**
         * Filters out elements that have been started in the past and assigns the result to "filteredPoiScheduleElements"
         * 
         * @private
         */
        function _setFilteredPoiScheduleElements() {
            vm.filteredPoiScheduleElements = vm.contentData.poiScheduleElements
                .filter(date => moment(date.scheduleFrom) >= moment());
        }

        function _onFinish() {
            imageUploader.run().then(imageUrl => {
                vm.contentData.imagesRef = imageUrl;
            }).then(() => {
                TrmPoiContentDataService.save(vm.contentData).then(() => {
                    $state.go('app.trmPoiElements');
                });
            })
        }

        /**
         * Watcher for finish button click event
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.contentForm.$valid,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('serviceProvidersChart', {
            templateUrl: '/assets/views/share-park/dashboard/widgets/service-providers-chart/service-providers-chart.tpl.html',
            controller: ServiceProvidersChartController,
            bindings: {
                serviceProviders: '<',
                currencies: '<',
                timeRange: '<',
                purchasedItems: '<',
            }
        });

    function ServiceProvidersChartController(
        $scope,
        DateHelper,
        CAP_DASHBOARD_TIME_RANGES,
    ) {
        const vm = this;

        vm.$onInit = init;

        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();

        vm.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'value'
                    },
                }],
            },
        };
        vm.WIDGET_TYPES = {
            BAR_CHART: {
                id: 1,
                icon: 'insert_chart_outlined'
            },
            TIMELINE_CHART: {
                id: 2,
                icon: 'show_chart'
            },
        };
        vm.widgetTypesArr = Object.values(vm.WIDGET_TYPES);
        vm.selectedWidgetType = vm.WIDGET_TYPES.BAR_CHART.id;
        vm.TIME_RANGES = CAP_DASHBOARD_TIME_RANGES;
        vm.selectedTimeRange = CAP_DASHBOARD_TIME_RANGES[3];
        vm.chartLabels = [];
        vm.statistics = [];

        vm.updateData = updateData;
        vm.setCurrency = setCurrency;
        vm.selectWidgetType = selectWidgetType;

        function init() {
            vm.currentCurrency = vm.currencies[0] || 'EUR';
            updateData();
        }

        function updateServiceProvidersChart() {
            const serviceProviders = getServiceProvidersWithPurchasesInRange();

            vm.chartLabels = serviceProviders.map(({fullCompanyName}) => fullCompanyName);
            vm.statistics = serviceProviders.map(({purchasesSum}) => purchasesSum);
        }

        /**
         *
         * @return { Array }
         */
        function getServiceProvidersWithPurchasesInRange() {
            const minDate = vm.timeRange.diffValue ?
                moment().subtract(vm.timeRange.diffValue, vm.timeRange.diffUnit)
                : null;

            const serviceProvidersList = vm.serviceProviders.map(({fullCompanyName, purchases}) => {
                const filteredPurchases = purchases.filter(purchase => {
                        const dateMatches = !minDate || purchase.purchaseDate.isAfter(minDate);

                        return dateMatches && purchase.currencyCode === vm.currentCurrency
                    }
                );
                return {
                    fullCompanyName,
                    purchasesSum: filteredPurchases.reduce((accum, currentValue) => {
                        const price = angular.isNumber(currentValue.discountedPrice)
                            ? currentValue.discountedPrice
                            : currentValue.originalPrice;

                        return accum + price;
                    }, 0) / 100
                }
            });

            return serviceProvidersList.sort((a, b) => b.purchasesSum - a.purchasesSum);
        }

        /**
         * Sets current currency
         * @param {String} currency
         */
        function setCurrency(currency) {
            vm.currentCurrency = currency;
            updateData();
        }

        function updateData() {
            switch (vm.selectedWidgetType) {
                case vm.WIDGET_TYPES.BAR_CHART.id:
                    updateServiceProvidersChart();
                    break;
                case vm.WIDGET_TYPES.TIMELINE_CHART.id:
                    updateTimelineChart();
                    break;
            }
        }

        function updateTimelineChart() {
            const minDate = vm.timeRange.diffValue
                ? moment().subtract(vm.timeRange.diffValue, vm.timeRange.diffUnit)
                : vm.purchasedItems[0].purchaseDate;

            vm.timelineChartLabels = getTimelineLabels(minDate);
            const daysDiff = moment().diff(minDate, 'days') + 1;

            vm.timelineChartData = vm.serviceProviders.map(serviceProvider => {
                const lineData = new Array(daysDiff).fill(0);
                serviceProvider.purchases.forEach(purchase => {
                    if (purchase.currencyCode !== vm.currentCurrency) {
                        return;
                    }

                    const currentDateString = moment(purchase.purchaseDate).format(DATE_FORMAT);
                    const index = vm.timelineChartLabels.indexOf(currentDateString);
                    if (index !== -1) {
                        const purchaseSum = angular.isNumber(purchase.discountedPrice)
                            ? purchase.discountedPrice
                            : purchase.originalPrice;
                        lineData[index] += purchaseSum / 100;
                    }
                });

                return lineData;
            });

            vm.timelineChartSeries = vm.serviceProviders.map(serviceProvider =>
                serviceProvider.fullCompanyName
            )
        }

        /**
         *
         * @param { moment } rangeStart
         * @return {[]}
         */
        function getTimelineLabels(rangeStart) {
            const labels = [];
            const currentDate = moment();
            const currentRangeDate = moment(rangeStart);
            while (!currentRangeDate.isAfter(currentDate, 'days')) {
                labels.push(currentRangeDate.format(DATE_FORMAT));
                currentRangeDate.add(1, 'days');
            }

            return labels;
        }

        /**
         * Sets current widget type
         * @param { Number } id
         */
        function selectWidgetType(id) {
            vm.selectedWidgetType = id;
            updateData();
        }

        $scope.$watch(
            () => vm.timeRange,
            () => updateData()
        )
    }
})();
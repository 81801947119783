(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capParkingEvents', {
            templateUrl: '/assets/views/share-park/operations/parking-events/parking-events.tpl.html',
            controller: OperationsParkingEventsController
        });

    /**
     * @typedef {{
     *   caps: string,
     *   limit?: number,
     *   from: string,
     *   till: string
     * }} IotEventsRequestParams
     */

    function OperationsParkingEventsController(
        $q,
        $state,
        $timeout,
        DateHelper,
        ListPaginationService,
        PricingDataService,
        ProfilesHelper,
        ShareParkDataService,
        ShareParkHelperService,
        UtilitiesService,
    ) {
        const vm = this;

        const {
            getIotEvents,
            getCarParks,
            getCustomerData,
        } = ShareParkDataService;

        const { decodeTariff } = ShareParkHelperService;
        const { decodeCustomerProfile } = ProfilesHelper;

        const { getAllTariffs } = PricingDataService;

        const { getDefaultDateFormat } = DateHelper;
        const { isJson } = UtilitiesService;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm:ss';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

        vm.carParks = [];
        vm.customers = [];
        vm.iotEvents = [];
        vm.filteredEvents = [];
        vm.entrances = [];
        vm.listData = {
            columns: [
                {
                    name: 'CAR_PARK',
                    class: 'car-park-name',
                    width: '20',
                    title: item => {
                        return item.iotEvent.carPark ? item.iotEvent.carPark.name : item.iotEvent.carparkRef;
                    }
                },
                {
                    name: 'ENTRANCE',
                    width: '15',
                    title: ({iotEvent}) => {
                        return (iotEvent.entrance && iotEvent.entrance.name)
                            ? iotEvent.entrance.name
                            : iotEvent.entranceRef;
                    }
                },
                {
                    name: 'EVENT_TYPE',
                    width: '10',
                    title: item => item.iotEvent.eventType
                },
                {
                    name: 'DEVICE_TYPE',
                    width: '10',
                    title: item => item.iotEvent.deviceType || ''
                },
                {
                    name: 'NUMBER_PLATE',
                    width: '5',
                    title: item => item.iotEvent.numberPlate && item.iotEvent.numberPlate.startsWith('#')
                            ? undefined
                            : item.iotEvent.numberPlate
                },
                {
                    name: 'DATE_TIME',
                    width: '10',
                    title: item => moment.utc(item.iotEvent.createdAt).local().format(DATE_TIME_FORMAT)
                },
                {
                    name: 'PERMISSION_CODE',
                    width: '15',
                    title: item => item.iotEvent.permissionCode || '',
                },
                {
                    name: 'ADDITIONAL_PARAM',
                    width: '10',
                    title: item => item.iotEvent.params.alert || '',
                },
            ],
            buttons: {
                width: '5',
                items: [
                    {
                        class: 'accountBtn',
                        callback: editCustomerByGuid,
                        isVisible: (btn, item) => !!item.iotEvent.guid,
                    },
                ]
            },
            additionalData: {
                tariffs: [],
            },
            updateCallback: updateList,
            initOnOpen: true,
        };
        vm.isAccordionListReady = true;
        vm.showFilter = false;
        vm.showQuickSearch = !vm.showFilter;
        vm.quickSearchQuery = '';
        vm.dateFormat = DATE_TIME_FORMAT;

        const defaultRemoteFilters = {
            carParks: [],
            from: '',
            to: '',
        };
        const defaultLocalFilters = {
            onlyValid: true,
            entrances: [],
            email: '',
            numberPlate: '',
            additionalParam: '',
            permission: '',
        };
        vm.filter = {
            ...defaultRemoteFilters,
            ...defaultLocalFilters
        };

        vm.$onInit = init;
        vm.applyFilter = applyFilter;
        vm.applyQuickSearch = applyQuickSearch;
        vm.applyRemoteFilter = applyRemoteFilter;
        vm.clearFilter = clearFilter;
        vm.clearRemoteFilter = clearRemoteFilter;
        vm.triggerFilter = triggerFilter;
        vm.applyOnlyActive = applyOnlyActive;
        vm.onRefreshClick = onRefreshClick;

        function init() {
            return $q.all([
                getCarParks(),
                getAllTariffs(),
                _loadEvents(),
            ]).then(([carParks, tariffs]) => {
                vm.carParks = carParks.plain();
                vm.entrances = generateEntrancesList(vm.carParks);
                vm.listData.additionalData.tariffs = tariffs.plain().map(decodeTariff);
            });
        }

        function _loadEvents() {
            vm.filteredEvents = [];

            const { carParks, to: till, from } = vm.filter;

            const params = _removeEmptyProps({
                caps: carParks.join(','),
                till,
                from
            });

            return getIotEvents(params).then(iotEvents => {
                vm.iotEvents = iotEvents.plain()
                    .map(iotEvent => {
                        iotEvent.params = isJson(iotEvent.params) ? JSON.parse(iotEvent.params) : {};
                        return {
                            iotEvent,
                            carPark: vm.carParks.find(carPark => carPark.external_id === iotEvent.carparkRef),
                        };
                    });

                _setInitialFilteredEvents();
            });
        }

        /**
         * Refreshes data in the table by fetching it
         *
         * @returns {Promise<void>}
         */
        function onRefreshClick() {
            void applyRemoteFilter();
        }

        /**
         * Updates iotEvent list
         * @param { Number } [page]
         * @param { Number } [itemsPerPage]
         */
        function updateList(page = 0, itemsPerPage = 25) {
            vm.visibleIotEvents = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredEvents).visibleItems;

            vm.visibleIotEvents.forEach(({iotEvent}) => {
                const carPark = vm.carParks.find(carPark =>
                    carPark.external_id === iotEvent.carparkRef
                );

                if (carPark) {
                    iotEvent.carPark = carPark;

                    const entrances = carPark.car_park_gateways.reduce((accum, currentValue) => {
                        return accum.concat(currentValue.entrances);
                    }, []);

                    const relatedEntrance = entrances.find(entrance =>
                        entrance.external_id === iotEvent.entranceRef
                    );
                    if (relatedEntrance) {
                        iotEvent.entrance = relatedEntrance;
                    }
                }
            })
        }

        function applyOnlyActive() {
            if (!vm.showFilter) {
                void applyQuickSearch();
            }
        }

        /**
         * @return {Promise<void>}
         */
        function applyRemoteFilter() {
            return applyFilter({
                isRemoteFiltering: true,
            });
        }

        /**
         * Apply filter from multiple inputs.
         * Called on "Apply" filter buttons press
         * 
         * @param {{isRemoteFiltering: boolean}} [filteringOptions] if true - apply backend based filtering as well as frontend based
         * @returns {Promise<void>}
         */
        function applyFilter({ isRemoteFiltering } = { isRemoteFiltering: false }) {
            const filter = (customerGuids = []) => {
                const { filter: f } = vm;

                vm.filteredEvents = vm.iotEvents.filter(
                    item => {
                        if (!f) {
                            return true;
                        }

                        const { iotEvent } = item;
    
                        return (f.onlyValid
                                ? iotEvent.permissionCode
                                : true)
                            && (f.entrances.length
                                ? f.entrances.includes(iotEvent.entranceRef)
                                : true)
                            && (f.numberPlate
                                ? includes(f.numberPlate, iotEvent.numberPlate)
                                : true)
                            && (f.permission
                                ? includes(f.permission, iotEvent.permissionCode)
                                : true)
                            && (f.additionalParam
                                ? iotEvent.params && iotEvent.params.alert && includes(f.additionalParam, iotEvent.params.alert)
                                : true)
                            && (f.email
                                ? customerGuids.includes(iotEvent.guid)
                                : true);
                    }
                );
            };

            _clearQuickSearchQuery();

            const { email } = vm.filter;

            $q.all([
                email && _getCustomerGuidsByEmail(email),
                isRemoteFiltering && _loadEvents(),
            ]).then(([customerGuids]) => {
                filter(customerGuids);
            });
        }

        /**
         * Apply filter by single query string.
         * Called on "quick search" input event with debounce
         * 
         * @returns {Promise<void>}
         */
        function applyQuickSearch() {
            const filter = (q, customerGuids = []) => {
                vm.filteredEvents = vm.iotEvents.filter(
                    item => {
                        /**
                         * @type IotEvent
                         */
                        const { iotEvent } = item;

                        if (vm.filter.onlyValid && !iotEvent.permissionCode) {
                            return false;
                        }

                        if (!q) {
                            return true;
                        }

                        return item.carPark && includes(q, item.carPark.name)
                            || Number(q) === item.iotEvent.entranceRef
                            || iotEvent.entrance && includes(q, iotEvent.entrance.name)
                            || includes(q, iotEvent.eventType)
                            || includes(q, iotEvent.numberPlate)
                            || includes(q, iotEvent.createdAt)
                            || includes(q, iotEvent.permissionCode)
                            || iotEvent.params && iotEvent.params.alert && includes(q, iotEvent.params.alert)
                            || customerGuids.includes(iotEvent.guid);
                    }
                );
            };

            _getCustomerGuidsBySearchQuery(vm.quickSearchQuery).then(customerGuids => {
                filter(vm.quickSearchQuery, customerGuids);
            });
        }

        function clearRemoteFilter() {
            vm.filter = {
                ...vm.filter,
                ...defaultRemoteFilters
            };

            void applyRemoteFilter();
        }

        function clearFilter() {
            vm.filter = {
                ...vm.filter,
                ...defaultLocalFilters
            };

            void applyFilter();
        }

        function triggerFilter() {
            vm.showFilter = !vm.showFilter;
            vm.showQuickSearch = !vm.showQuickSearch;

            if (!vm.showFilter) {
                clearFilter();
                clearRemoteFilter();
            }

            if (!vm.showQuickSearch) {
                _clearQuickSearchQuery();
                void applyQuickSearch();
            }
        }

        /**
         * @param {string} needle
         * @param {string} source
         * @return {boolean}
         */
        function includes(needle, source) {
            needle = needle ? needle.toUpperCase() : '';
            source = source ? source.toUpperCase() : '';
            return source.includes(needle);
        }

        function editCustomerByGuid($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            getCustomerData(item.iotEvent.guid).then(response => {
                const customer = response.plain();

                if (customer.profile) {
                    customer.profile = decodeCustomerProfile(customer.profile);
                }

                $state.go('app.editShareParkCustomer', { data: customer });
            })
        }

        function generateEntrancesList(carParks) {
            const result = [];
            carParks.forEach(carPark => {
                carPark.car_park_gateways.forEach(gateway => {
                    gateway.entrances.forEach(entrance => {
                        result.push({
                            carPark,
                            entrance,
                        });
                    })
                })
            });
            return result;
        }

        /**
         * Returns customer GUIDs by search query
         * 
         * @param {string} query search query
         * @returns {Promise<object[] | []>}
         */
        function _getCustomerGuidsBySearchQuery(query) {
            return $q(resolve => {
                if (!query) {
                    resolve([]);
                    return;
                }

                ShareParkDataService.findCustomers(query).then(response => {
                    const customers = response.plain();

                    if (!customers.length) {
                        resolve([]);
                        return;
                    }

                    const customerGuids = customers.map(({ guid }) => guid);

                    resolve(customerGuids);
                }).catch(_ => {
                    resolve([]);
                });
            });
        }

        /**
         * Returns customer GUIDs by provided email
         * 
         * @param {string} query search query
         * @returns {Promise<object[] | []>}
         */
        function _getCustomerGuidsByEmail(query) {
            return $q(resolve => {
                ShareParkDataService.findCustomersByParameter('email', query).then(customers => {
                    if (!customers.length) {
                        resolve([]);
                        return;
                    }

                    const customerGuids = customers.map(({ guid }) => guid);

                    resolve(customerGuids);
                }).catch(_ => {
                    resolve([]);
                });
            });
        }

        function _clearQuickSearchQuery() {
            vm.quickSearchQuery = '';
        }

        function _setInitialFilteredEvents() {
            vm.filteredEvents = vm.iotEvents.filter(({ iotEvent }) => {
                if (vm.filter.onlyValid) {
                    return iotEvent.permissionCode;
                }

                return true;
            });
        }

        function _removeEmptyProps(object) {
            const result = angular.copy(object);

            Object.keys(result).forEach(key => {
                if (!result[key]) { delete result[key] }
            });

            return result;
        }
    }
})();

/**
 * @global window.logs
 */
(function() {
    'use strict';

    angular.module('trmServices').service('LogService',
        function(
            $moment,
        ) {
            return {
                colored,
                log,
                warn,
                error,
            }

            /**
             * Example of usage:
             * const log = (...args) => {
             *    LogService.colored('#f5f542', '#FFFFFF', ...args);
             * };
             *
             * @param {string} colorBg
             * @param {string} colorText
             * @param args
             */
            function colored(colorBg, colorText, ...args) {
                if (!window.logs) { return; }

                const time = _time();
                console.log(`%c${time}`, `background: ${colorBg}; color: ${colorText}`, ...args);
            }

            function log() {
                if (!window.logs) { return; }

                const time = _time();
                console.log(time, ...arguments);
            }

            function warn() {
                if (!window.logs) { return; }

                const time = _time();
                console.log(`%c${time}`, 'background: orange; color: #FFF', ...arguments);
            }

            function error() {
                if (!window.logs) { return; }

                const time = _time();
                console.log(`%c${time}`, 'background: #FF0000; color: #FFFFFF', ...arguments);
            }

            function _time() {
                return $moment().format('HH:mm:ss.SSS');
            }
        });
})();

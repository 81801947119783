(function () {
    'use strict';

    angular.module('beacon.app')
        .component('himType', {
            templateUrl: '/assets/views/content/elements/types/him/him-type.tpl.html',
            controller: HimTypeController,
            bindings: {
                langArray: '<',
                contentForm: '<',
                contentData: '<',
                contentGroupsArray: '<',
            }
        });

    /**
     * @param {$rootScope.Scope} $scope
     * @param $filter
     * @param StatesFactory
     * @param ModelsFactory
     *
     * @property {Language[]} langArray
     * @property {form.FormController} contentForm
     * @property {HimContent} contentData
     * @property {ContentGroup[]} contentGroupsArray
     *
     * @property {string} messageJson
     *
     * @constructor
     */
    function HimTypeController(
        $scope,
        $filter,
        StatesFactory,
        ModelsFactory,
    ) {
        const $ctrl = this;

        /**
         * @type {ContentStates}
         */
        $ctrl.state = StatesFactory.ContentStates;
        $ctrl.isEdit = $ctrl.state.type === 'edit';

        $ctrl.$onInit = () => {
            _initModel();
            _initJsonEditor();
            _initFinishHandler();
            _initFormValidation();
        };

        function _initModel() {
            $ctrl.contentData.data = new ModelsFactory.HimOptions($ctrl.contentData.data);
        }

        /**
         * @private
         */
        function _initFinishHandler() {
            $scope.$on(
                'content-finish',
                /**
                 * @param event
                 * @param {{ callback: FinishCallback }} data
                 */
                (event, data) => {
                    data.callback({
                        type: $ctrl.state.type,
                        contentData: angular.copy($ctrl.contentData),
                    });
                }
            );

            /**
             * @callback FinishCallback
             * @param  {{ type: string, contentData: HimContent }} data
             * @return void
             */
        }

        /**
         * @private
         */
        function _initFormValidation() {
            $scope.$watch(
                () => $ctrl.contentForm && $ctrl.contentForm.$valid,
                isFormValid => {
                    $ctrl.state.canFinish = Boolean(isFormValid)
                });
        }

        /**
         * @private
         */
        function _initJsonEditor() {
            $ctrl.messageJson = $filter('json')($ctrl.contentData.data);

            $scope.$watch(
                () => $ctrl.messageJson,
                /**
                 * @param {string} message
                 */
                function (message) {
                    $ctrl.contentData.data = JSON.parse(message);
                }
            );
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('SelectMonthPopupController', SelectMonthPopupController);

    function SelectMonthPopupController(
        $uibModalInstance,
        MONTHS,
    ) {
        const vm = this;

        const LAST_YEARS = 5;
        vm.MONTHS = MONTHS;
        vm.selectedMonth = new Date().getMonth();
        vm.selectedYear = new Date().getFullYear();

        vm.years = [];

        for (let i=0; i < LAST_YEARS; i++) {
            vm.years.push(vm.selectedYear - i);
        }

        vm.okHandler = okHandler;
        vm.cancelHandler = cancelHandler;

        function okHandler() {
            $uibModalInstance.close({
                selectedMonth: vm.selectedMonth,
                selectedYear: vm.selectedYear,
            });
        }

        function cancelHandler() {
            $uibModalInstance.dismiss();
        }
    }
}());


(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkDiscounts', {
            templateUrl: '/assets/views/share-park/pricing/discounts/discounts.tpl.html',
            controller: DiscountsController
        });

    function DiscountsController(
        $state,
        $translate,
        PermissionsService,
        PricingDataService,
        UtilitiesService,
        FileService,
        PopupService,
) {
        const vm = this;

        const { actions, generateDiscountsPermission } = PermissionsService;
        const currentLocale = $translate.use();

        vm.$onInit = init;

        vm.addDiscount = addDiscount;

        vm.discountsAll = [];
        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'discount-name',
                    width: '20',
                    title: listItem => listItem.discount.name[currentLocale] || Object.values(listItem.discount.name)[0],
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteDiscount,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editDiscount,
                        permissionAction: actions.modify
                    },

                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        /**
         * Controller initialization
         */
        function init() {
            PricingDataService.getDiscounts()
                .then(response => {
                    vm.discountsAll = response.plain().map(discount => {
                        discount.name = angular.fromJson(discount.name);
                        discount.description = angular.fromJson(discount.description);
                        return {discount};
                    });
                    vm.count = vm.discountsAll.length;
                    updateList();
                });
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.discounts = UtilitiesService.getArrayPage(vm.discountsAll, page, count);
        }

        /**
         * Create new Discount
         */
        function addDiscount() {
            $state.go('app.newDiscount');
        }

        /**
         * Edit Discount
         * @param $event
         * @param item
         */
        function editDiscount($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editDiscount', { data: item.discount });
        }

        function deleteDiscount($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_DISCOUNT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                PricingDataService.deleteDiscount(item.discount.id)
                    .then(() => {
                        if (item.discount.imageRef) {
                            FileService.deleteFromS3(item.discount.imageRef);
                        }

                        init();
                    })
            });
        }

        /**
         * Generates permission name for each button on discounts accordion list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateDiscountsPermission(button.permissionAction);
        }
    }
})();
(function () {
    'use strict';

    angular.module('beacon.app')
            .directive('geofenceMapView', geofenceMapView);

    function geofenceMapView() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/geofence-map-view/geofence-map-view.tpl.html',
            replace: true,
            controller: GeofenceMapViewController,
            controllerAs: 'geofenceMapView',
            bindToController: true,
            scope: {
                data: '=geofenceMapView', // Use "LocationHelper.coloredArea" to make colored shapes
                createdShapeColor: '<',
                updatePolyline: '=',
                editable: '<',
                mapClickCallback: '<',
                markers: '<',
                markerClickCallback: '=',
                disableSelect: '<',
                disableClustering: '<',
                mapDefaultCenter: '<'
            }
        };
    }

    function GeofenceMapViewController(
        $scope,
        $timeout,
        ModelsFactory,
        UserUtilitiesService,
        UtilitiesService,
    ) {
        const vm = this;

        const { MapOptions } = ModelsFactory;

        const DEFAULT_ZOOM_VALUE = 16;
        const POINTS_IN_CIRCLE = 12;
        const POINTS_IN_RECTANGLE = 4;
        const MIN_POINTS_IN_POLYGON = 3;
        const MAPS = google.maps;
        const MAX_ZOOM = 20;
        const round = (number, precision = 5) => UtilitiesService.toPrecision(number, precision);

        let map = null;
        let infoWindow = null;

        let shapes = {};
        let selectedShape;
        const allMarkers = [];
        let markerCluster;
        let oms;
        let shapeCoords = [];

        let drawingManager;

        vm.$onInit = init;

        function ShapeOptions(color) {
            this.clickable = vm.editable;
            this.draggable = vm.editable;
            this.editable = vm.editable;
            this.fillColor = color || '#BBB';
            this.strokeColor = color || '#000';
            this.strokeWeight = 0.5;
            this.fillOpacity = 0.2;
        }

        function CircleOptions(color) {
            Object.assign(this, new ShapeOptions(color));
        }

        function PolygonOptions(color){
            Object.assign(this, new ShapeOptions(color));
        }

        function RectangleOptions(color) {
            Object.assign(this, new ShapeOptions(color));
        }

        function CenterControl(controlDiv) {

            // Set CSS for the control border.
            let controlUI = document.createElement('div');
            controlUI.style.backgroundColor = '#fff';
            controlUI.style.border = '2px solid #fff';
            controlUI.style.borderRadius = '3px';
            controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
            controlUI.style.cursor = 'pointer';
            controlUI.style.marginBottom = '22px';
            controlUI.style.textAlign = 'center';
            controlUI.title = 'Select to delete the shape';
            controlDiv.appendChild(controlUI);

            // Set CSS for the control interior.
            let controlText = document.createElement('div');
            controlText.style.color = 'rgb(25,25,25)';
            controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
            controlText.style.fontSize = '16px';
            controlText.style.lineHeight = '38px';
            controlText.style.paddingLeft = '5px';
            controlText.style.paddingRight = '5px';
            controlText.innerHTML = 'Delete Selected shape';
            controlUI.appendChild(controlText);

            controlUI.addEventListener('click', function () {
                deleteSelectedShape();
            });

        }

        /**
         * Creates and returns circle coords
         *
         * @return {object} google.maps.Circle
         * @param {string} shape
         */
        function getCircleCoords(shape) {
            let coords = [];
            let degreeStep = 360 / POINTS_IN_CIRCLE;
            for (let i = 0; i < POINTS_IN_CIRCLE; i++) {
                var latLng = MAPS.geometry.spherical.computeOffset(shape.getCenter(), Math.round(shape.getRadius()), degreeStep * i);
                coords.push([
                    round(latLng.lat(), 6),
                    round(latLng.lng(), 6),
                ].join());
            }
            return coords.join(";");
        }

        /**
         * Creates and returns rectangle coords
         *
         * @return {object} google.maps.Rectangle
         * @param {string} shape
         */
        function getRectangleCoords(shape) {
            let coords = [];
            let bounds = shape.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();
            coords.push([round(northEast.lat()), round(southWest.lng())].join());
            coords.push([round(northEast.lat()), round(northEast.lng())].join());
            coords.push([round(southWest.lat()), round(northEast.lng())].join());
            coords.push([round(southWest.lat()), round(southWest.lng())].join());
            return coords.join(";");
        }

        /**
         * Creates and returns polygon coords
         *
         * @return {object} google.maps.Polygon
         * @param {string} shape
         */
        function getPolygonCoords(shape) {
            let paths = shape.getPaths();
            paths = (paths.getArray) ? paths.getArray() : paths;
            let coords = paths.map(path => {
                path = (path.getArray) ? path.getArray() : path;
                return path.map(latLng => [round(latLng.lat()), round(latLng.lng())].join()).join(";");
            });
            return coords.join();
        }

        /**
         * Updates polyline
         *
         * @return {void}
         */
        function updatePolyline() {
            let shapesCoords = [];
            _.forEach(shapes, shape => {
                switch (shape.type) {
                    case "circle":
                        shapesCoords.push(getCircleCoords(shape));
                        break;
                    case "rectangle":
                        shapesCoords.push(getRectangleCoords(shape));
                        break;
                    case "polygon":
                        shapesCoords.push(getPolygonCoords(shape));
                }
            });
            vm.data = shapesCoords.join("|");
            if (_.isFunction(vm.updatePolyline)) {
                vm.updatePolyline(vm.data);
            }
        }

        /**
         * Creates and returns circle object
         *
         * @param {array} coords
         * @param {string} color
         * @return {object} google.maps.Circle
         */
        function getCircle(coords, color) {
            let shape = null;
            if (coords.length < POINTS_IN_CIRCLE || coords.length % 2) {
                return shape;
            }
            let center = MAPS.geometry.spherical.interpolate(coords[0], coords[coords.length / 2], 0.5);
            let radius = Math.round(MAPS.geometry.spherical.computeDistanceBetween(coords[0], center));
            for (let i=1; i < coords.length; i++) {
                if (i === coords.length / 2) {
                    continue;
                }
                if (Math.round(MAPS.geometry.spherical.computeDistanceBetween(coords[i], center)) !== radius) {
                    return shape;
                }
            }
            let options = new CircleOptions(color);
            options.radius = radius;
            options.center = center;
            shape = new MAPS.Circle(options);
            shape.type = "circle";
            return shape;
        }

        /**
         * Creates and returns rectangle object
         *
         * @param {array} coords
         * @param {string} color
         * @return {object} google.maps.Rectangle
         */
        function getRectangle(coords, color) {
            let shape = null;
            let isRectangle = coords.length === POINTS_IN_RECTANGLE
                    && coords[0].lat() === coords[1].lat()
                    && coords[1].lng() === coords[2].lng()
                    && coords[2].lat() === coords[3].lat()
                    && coords[3].lng() === coords[0].lng();
            if (!isRectangle) {
                return shape;
            }
            let options = new RectangleOptions(color);
            options.bounds = new MAPS.LatLngBounds(coords[3], coords[1]);
            shape = new MAPS.Rectangle(options);
            shape.type = "rectangle";
            return shape;
        }

        /**
         * Creates and returns polygon object
         *
         * @param {array} coords
         * @param {string} color
         * @return {object} google.maps.Polygon
         */
        function getPolygon(coords, color) {
            let shape = null;
            if (coords.length < MIN_POINTS_IN_POLYGON) {
                return shape;
            }
            let options = new PolygonOptions(color);
            options.paths = coords;
            shape = new MAPS.Polygon(options);
            shape.type = "polygon";
            return shape;
        }

        /**
         * Draws shapes from the polyline string
         *
         * @param {string} polyline
         * @param {object} map
         * @return {void}
         */
        function drawShapes(polyline, map) {
            if (!_.isString(polyline) || !_.isObject(map)) {
                return;
            }
            deleteAllShapes();
            if (!polyline.length) {
                return;
            }
            let viewBounds = new MAPS.LatLngBounds();
            _.forEach(polyline.split("|"),
                    coordsItems => {
                        const [coordsString, color] = coordsItems.split(':');
                        shapeCoords = _.map(
                                coordsString.split(";"),
                                latLongString => {
                                    let latLong = latLongString.split(",")
                                            .map(coord => parseFloat(coord));
                                    let coordinates = new MAPS.LatLng(latLong[0], latLong[1]);
                                    viewBounds.extend(coordinates);
                                    return coordinates;
                                }
                        );
                        let shape = getCircle(shapeCoords, color) || getRectangle(shapeCoords, color) || getPolygon(shapeCoords, color);

                        if (shape) {
                            shape.setMap(map);
                            addShape(shape);
                            if (vm.editable) {
                                shape.setEditable(false);
                            }
                        }
                    }
            );
            map.fitBounds(viewBounds);
        }

        /**
         * Puts markers on the map from the markers array
         *
         * @param {array} markers
         * @param {object} map
         * @returns {undefined}
         */
        function putMarkers(markers, map) {
            if (!vm.disableClustering && !oms) {
                oms = new OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,   // we promise not to move any markers, allowing optimizations
                    keepSpiderfied: true,
                    spiralFootSeparation: 75,
                    circleFootSeparation: 100
                })
            }

            const viewBounds = new MAPS.LatLngBounds();
            const markerIconSize = {
                width: 26,
                height: 42
            };
            const markerAnchor = [markerIconSize.width / 2, markerIconSize.height];
            markers.forEach(marker => {
                const newMarker = new MAPS.Marker({
                    position: marker.position,
                    label: marker.label,
                    map
                });
                const image = {
                    url: '/assets/images/bluemapicon.png',
                    scaledSize: new google.maps.Size(markerIconSize.width, markerIconSize.height),
                    anchor: new google.maps.Point(markerAnchor[0], markerAnchor[1])
                };
                if (marker.selected) {
                    newMarker.setIcon(image);
                }
                newMarker.locationId = marker.locationId;
                newMarker.selected = marker.selected;

                if (!!oms) {
                    oms.addMarker(newMarker);
                }

                newMarker.addListener('click', () => {
                    if (vm.markerClickCallback && angular.isFunction(vm.markerClickCallback)) {
                        if (!vm.disableSelect) {
                            !newMarker.selected ? newMarker.setIcon(image) : newMarker.setIcon();
                            marker.selected = newMarker.selected = !newMarker.selected;
                        }
                        vm.markerClickCallback(marker);
                    }
                });
                allMarkers.push(newMarker);
                const coordinates = new MAPS.LatLng(marker.position.lat, marker.position.lng);
                viewBounds.extend(coordinates);
            });

            if (!vm.disableClustering) {
                markerCluster = new MarkerClusterer(map, allMarkers,
                    {imagePath: '/assets/images/m'});
                
                markerCluster.setMaxZoom(positionOptions.maxZoom - 3);
            }

            // TODO Remove this temporary workaround
            // find better solution
            if (!angular.isFunction(vm.mapClickCallback)) {
                shapeCoords && shapeCoords.forEach(coords => {
                    viewBounds.extend(coords)
                });
                map.fitBounds(viewBounds);
            }
        }

        /**
         * Removes all markers
         */
        function clearMarkers() {
            allMarkers.forEach(marker => {
                marker.setMap(null);
            });
            allMarkers.splice(0, allMarkers.length);
            if (!!markerCluster) {
                markerCluster.clearMarkers();
            }

            if (!!oms) {
                oms.forgetAllMarkers();
            }
        }

        /**
         * Updates icon for selected or deselected markers property
         * @param {array} changedMarkers
         */
        function updateChangedMarkers(changedMarkers) {
            const markerIconSize = {
                width: 26,
                height: 42
            };
            const markerAnchor = [markerIconSize.width / 2, markerIconSize.height];
            const image = {
                url: '/assets/images/bluemapicon.png',
                scaledSize: new google.maps.Size(markerIconSize.width, markerIconSize.height),
                anchor: new google.maps.Point(markerAnchor[0], markerAnchor[1])
            };
            changedMarkers.forEach(changedMarker => {
                const currentMarker = allMarkers.find(marker => {
                    return marker.locationId === changedMarker.locationId;
                });
                if (!_.isEmpty(currentMarker)) {
                    if (changedMarker.selected) {
                        currentMarker.setIcon(image);
                        currentMarker.selected = true;
                    } else {
                        currentMarker.selected = false;
                        currentMarker.setIcon();
                    }
                }
            })
        }

        function clearSelection() {
            if (selectedShape) {
                infoWindow.close();
                selectedShape.setEditable(false);
                selectedShape = null;
            }
        }

        function showInfoWindow (position, content){
            infoWindow.setPosition(position);
            infoWindow.setContent(content);
            infoWindow.open(map);
        }

        function showCircleInfo(shape) {
            let position = shape.getCenter();
            let content = "Radius: " + Math.round(shape.getRadius()) + "m";
            showInfoWindow(position, content);
        }

        function showRectangleInfo(shape) {
            let bounds = shape.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();
            let northWest = new MAPS.LatLng(northEast.lat(), southWest.lng());
            let height = Math.round(MAPS.geometry.spherical.computeDistanceBetween(southWest, northWest));
            let width = Math.round(MAPS.geometry.spherical.computeDistanceBetween(northEast, northWest));
            let position = MAPS.geometry.spherical.interpolate(northEast, northWest, 0.5);
            let content = "Height: " + height + "m<br/>Width: " + width + "m";
            showInfoWindow(position, content);
        }

        let timeoutPromise = null;

        function addShape(shape) {
            shape.id = _.uniqueId();
            shapes[shape.id] = shape;

            if (angular.isFunction(vm.mapClickCallback)) {
                MAPS.event.addListener(shape, 'click', function (event) {
                    vm.mapClickCallback(event);
                });
            }

            MAPS.event.addListener(shape, 'mouseup', function () {
                setSelection(shape);
            });
            switch (shape.type) {
                case "circle":
                    MAPS.event.addListener(shape, "center_changed", () => {
                        infoWindow.close();
                        $timeout.cancel(timeoutPromise);
                        timeoutPromise = $timeout(_.partialRight(showCircleInfo, shape), 200);
                        updatePolyline();
                    });
                    MAPS.event.addListener(shape, "radius_changed", () => {
                        showCircleInfo(shape);
                        updatePolyline();
                    });
                    break;
                case "rectangle":
                   MAPS.event.addListener(shape, "bounds_changed", () => {
                        infoWindow.close();
                        $timeout.cancel(timeoutPromise);
                        timeoutPromise = $timeout(_.partialRight(showRectangleInfo, shape), 200);
                        updatePolyline();
                    });
                    break;
                case "polygon":
                    MAPS.event.addListener(shape, 'dragend', updatePolyline);
                    MAPS.event.addListener(shape.getPath(), 'insert_at', updatePolyline);
                    MAPS.event.addListener(shape.getPath(), 'remove_at', updatePolyline);
                    MAPS.event.addListener(shape.getPath(), 'set_at', updatePolyline);
            }
        }

        function setSelection(shape) {
            clearSelection();
            selectedShape = shape;
            selectedShape.setEditable(true);
            switch (shape.type) {
                case "circle":
                    $timeout.cancel(timeoutPromise);
                    showCircleInfo(shape);
                    break;
                case "rectangle":
                    $timeout.cancel(timeoutPromise);
                    showRectangleInfo(shape);
            }
        }

        function deleteSelectedShape() {
            if (infoWindow && _.isFunction(infoWindow.close)) {
                infoWindow.close();
            }
            if (selectedShape) {
                selectedShape.setMap(null);
                MAPS.event.clearInstanceListeners(selectedShape);
                delete shapes[selectedShape.id];
                updatePolyline();
            }
        }

        function deleteAllShapes() {
            if (infoWindow && _.isFunction(infoWindow.close)) {
                infoWindow.close();
            }
            _.forEach(shapes, (shape) => {
                shape.setMap(null);
                MAPS.event.clearInstanceListeners(shape);
                delete shapes[shape.id];
            });
        }

        const positionOptions = UserUtilitiesService.userLocationOptions();
        if (!positionOptions.maxZoom) {
            positionOptions.maxZoom = MAX_ZOOM;
        }
        if (vm.mapDefaultCenter) {
            const coordinates = UtilitiesService.getCoordinates(vm.mapDefaultCenter);
            Object.assign(positionOptions, coordinates);
        }
        const mapOptions = new MapOptions(positionOptions);

        $timeout(function () {

            const mapElement = document.getElementById('map_' + $scope.$id);
            map = new MAPS.Map(mapElement, mapOptions);
            drawShapes(vm.data, map);

            if (!!vm.markers && !!vm.markers.length) {
                putMarkers(vm.markers, map);
            }
            if (!vm.editable) {
                $scope.$watch(angular.bind(vm, function () {
                    return this.data;
                }), (newValue, oldValue) => {
                    drawShapes(newValue, map);
                });
                $scope.$watch(angular.bind(vm, function () {
                    return this.markers;
                }), (newValue, oldValue) => {
                    clearMarkers();
                    if (newValue && !!newValue.length) {
                        putMarkers(newValue, map);
                    }
                })
            } else {
                MAPS.event.addListener(map, 'click', function (event) {
                    if (angular.isFunction(vm.mapClickCallback)) {
                        vm.mapClickCallback(event);
                    }
                    clearSelection();
                });
                $scope.$watch(angular.bind(vm, function () {
                    return this.markers;
                }), (newValue, oldValue) => {
                    if (newValue && oldValue &&
                        (newValue.length !== oldValue.length ||
                        oldValue.every(marker => !newValue.find(
                            newMarker =>
                                marker.locationId === newMarker.locationId
                        )))) {
                        clearMarkers();
                        newValue.length && putMarkers(newValue, map);
                        return;
                    }
                    const changedMarkers = _.differenceWith(newValue, oldValue, _.isEqual);
                    if (changedMarkers.length && allMarkers.length) {
                        updateChangedMarkers(changedMarkers);
                    }
                }, true);

                infoWindow = new MAPS.InfoWindow();

                drawingManager = new MAPS.drawing.DrawingManager({
                    drawingControl: vm.editable,
                    drawingControlOptions: {
                        position: MAPS.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            MAPS.drawing.OverlayType.CIRCLE,
                            MAPS.drawing.OverlayType.POLYGON,
                            MAPS.drawing.OverlayType.RECTANGLE,
                        ]
                    },
                    circleOptions: new CircleOptions(vm.createdShapeColor),
                    polygonOptions: new PolygonOptions(vm.createdShapeColor),
                    rectangleOptions: new RectangleOptions(vm.createdShapeColor),
                });

                drawingManager.setMap(map);

                MAPS.event.addListener(drawingManager, 'overlaycomplete', function (event) {
                    drawingManager.setDrawingMode(null);
                    let newShape = event.overlay;
                    newShape.type = event.type;

                    addShape(newShape);
                    setSelection(newShape);
                    updatePolyline();
                });

                let centerControlDiv = document.createElement('div');
                let centerControl = new CenterControl(centerControlDiv);

                centerControlDiv.index = 1;
                map.controls[MAPS.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
            }

            MAPS.event.addListener(map, 'zoom_changed', function() {
                const zoomChangeBoundsListener =
                    MAPS.event.addListener(map, 'bounds_changed', function() {
                        if (this.getZoom() > DEFAULT_ZOOM_VALUE && this.initialZoom === true) {
                            // Change max/min zoom here
                            this.setZoom(DEFAULT_ZOOM_VALUE);
                            this.initialZoom = false;
                        }
                        MAPS.event.removeListener(zoomChangeBoundsListener);
                    });
            });
            map.initialZoom = true;
        }, 0);

        /**
         * Bind watchers
         */
        function bindWatchers() {
            $scope.$watch(
                () => vm.markers,
                val => val && (val.length === 1) && map && (map.initialZoom = true)
            );

            $scope.$watch(
                () => vm.createdShapeColor,
                color => {
                    drawingManager && drawingManager.setOptions({
                        circleOptions: new CircleOptions(color),
                        polygonOptions: new PolygonOptions(color),
                        rectangleOptions: new RectangleOptions(color),
                    });
                }
            );
        }

        $scope.$on('redraw-polyline', (event, data) => {
            map && angular.isDefined(data.polyline) && drawShapes(data.polyline, map);
        });

        /**
         * Initialization method
         */
        function init() {
            bindWatchers();
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltyCampaignTargetingTab', {
            templateUrl: '/assets/views/loyalty/campaigns/form/tabs/targeting/targeting.tpl.html',
            controller: loyaltyCampaignTargetingTab
        });

    function loyaltyCampaignTargetingTab(
            StorageFactory,
        ) {
        const vm = this;

        vm.STORAGE = StorageFactory.Storage('LoyaltyCampaign');
        vm.STORAGE.on('data', value => {
            vm.data = value;
        });
    }
}());
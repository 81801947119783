(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('panelTab', panelTab);

    function panelTab() {
        return {
            restrict: 'A',
            scope: {
                tabData: '=panelTab',
                template: '='
            },
            link: function(scope, element, attrs) {
                scope.templateUrl = '/assets/views/common/partials/' + scope.panelTab.template + '.tpl.html';
            },
            template: '<div ng-include="templateUrl"></div>',
            replace: true,
            controller: PanelTabController,
            controllerAs: 'panelTab',
            bindToController: true
        };
    }

    function PanelTabController() {}
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newTariff', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/new/new-tariff.tpl.html',
            controller: NewTariffController
        });

    function NewTariffController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        PricingDataService,
        StatesFactory,
        ShareParkHelperService,
    ) {
        const vm = this;

        const { encodeTariff, encodeQuota, encodeTimeWindow } = ShareParkHelperService;

        vm.state = StatesFactory.TariffStates.refresh();
        vm.state.type = $stateParams.type;
        vm.selectedProducts = [];
        vm.selectedDiscounts = [];

        vm.$onInit = init;

        vm.onAssignmentsUpdateCallback = onAssignmentsUpdateCallback;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.tariffData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.Tariff();
        }

        function onAssignmentsUpdateCallback(products, discounts) {
            vm.selectedProducts = products;
            vm.selectedDiscounts = discounts;
        }

        /**
         * On finish handler
         */
        function onFinish() {
            const formData = new FormData();
            const tariffData = encodeTariff(vm.tariffData);
            const quotaData = encodeQuota(vm.tariffData.quota);
            let timeWindow = null;

            if (!vm.tariffData.timeWindowRef && vm.tariffData.timeWindow) {
                timeWindow = encodeTimeWindow(vm.tariffData.timeWindow);
            }

            const productAssignments = [];
            const discountAssignments = [];

            vm.selectedProducts.forEach(product => {
                const assignmentData = {
                    capProductRef: product.id,
                };
                const relatedAssignment = vm.tariffData.capProductAssignment.find(assignment =>
                    assignment.capProductRef.id === product.id
                );

                if (!!relatedAssignment) {
                    assignmentData.id = relatedAssignment.id
                }

                productAssignments.push(assignmentData);
            });

            vm.selectedDiscounts.forEach(discount => {
                const assignmentData = {
                    capRateDiscountTypeRef: discount.id,
                };
                const relatedAssignment = vm.tariffData.capRateDiscountAssignment.find(assignment =>
                    assignment.capRateDiscountType.id === discount.id
                );

                if (!!relatedAssignment) {
                    assignmentData.id = relatedAssignment.id
                }

                discountAssignments.push(assignmentData);
            });

            const removedProductAssignments = vm.tariffData.capProductAssignment.filter(assignment =>
                !productAssignments.some(({id}) => assignment.id === id)
            ).map(({id}) => id);

            const removedDiscountAssignments = vm.tariffData.capRateDiscountAssignment.filter(assignment =>
                !discountAssignments.some(({id}) => assignment.id === id)
            ).map(({id}) => id);

            formData.append('tariffData', angular.toJson({
                tariff: tariffData,
                quota: quotaData,
                productAssignments: productAssignments,
                discountAssignments: discountAssignments,
                removedProductAssignments: removedProductAssignments,
                removedDiscountAssignments: removedDiscountAssignments,
                timeWindow: timeWindow,
            }));

            PricingDataService.setTariff(formData)
                .then(onSuccess);
        }

        function onSuccess() {
            $state.go('app.shareParkTariffs', {
                paginationData: $stateParams.paginationData
            });
        }

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tenantsSubdomainsTab', {
            templateUrl: '/assets/views/tenants/tabs/subdomains-tab/subdomains-tab.tpl.html',
            controller: TenantsSubdomainsTab,
            bindings: {
                selectedTenant: '<',
                selectedSubdomain: '='
            }
        });

    function TenantsSubdomainsTab(
        $q,
        tenantsTabState,
        StorageFactory,
        TenantDataService,
        PopupService,
        UserDataService,
        LoginService,
        LookupDataService,
        LocationDataService,
        UserUtilitiesService,
        ACTIVATION_STATUS,
    ) {
        const vm = this;

        let STORAGE = StorageFactory.Storage('Main');
        let currentUserInfo = STORAGE ? STORAGE.get('currentUserInfo') : null;

        vm.state = tenantsTabState;

        vm.updateSubdomainsList = updateSubdomainsList;
        vm.selectSubdomain = selectSubdomain;
        vm.createSubdomain = createSubdomain;

        vm.selectedSubdomain = false;

        vm.totalSubdomainsItems = 0;
        vm.subdomainsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'subdomain-title',
                    width: '100',
                    title: 'name'
                }
            ],
            buttons: {
                width: '10',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: showDeleteSubdomainPopup,
                        isVisible: isDeleteBtnVisible,
                    },
                    {
                        class: 'editBtn',
                        callback: updateSubdomain,
                        isVisible: () => {
                            return UserUtilitiesService.hasRoleSysAdmin() || UserUtilitiesService.hasRoleTenantAdmin();
                        },
                    },
                    {
                        class: 'signInBtn',
                        callback: signIn,
                        isVisible: (btn, subdomainItem) => {
                            return subdomainItem.id !== currentUserInfo.subdomain_id;
                        },
                    }
                ]
            },
            itemClickCallback: vm.selectSubdomain,
            updateCallback: vm.updateSubdomainsList,
            needRadiobutton: true
        };

        vm.subdomains = [];
        vm.currentSubdomains = [];
        vm.subdomainsLength = 0;

        vm.paginationData =  {
            itemsPerPage: 10
        };

        /**
         * Updates list of subdomains
         */
        function updateSubdomainsList(page, itemsPerPage) {
            vm.currentSubdomains = vm.subdomains.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        // private methods

        /**
         * Determines whether to display a delete button
         *
         * @param {object} btn
         * @param {object} subdomainItem
         * @return {boolean} True if btn should be visible, otherwise false
         */
        function isDeleteBtnVisible(btn, subdomainItem) {
            if (UserUtilitiesService.hasRoleSysAdmin() || UserUtilitiesService.hasRoleTenantAdmin()) {
                return subdomainItem.id !== currentUserInfo.subdomain_id;
            }

            return false;
        }

        /**
         * Initialization method
         */
        function init() {
            reloadSubdomainsList();
        }

        /**
         * Reloads subdomains list
         */
        function reloadSubdomainsList() {
            let tenantId = vm.selectedTenant && vm.selectedTenant.id;

            if (!tenantId) {
                console.error('Tenant does not selected or has not got Id.');
                return;
            }

            $q.all([
                TenantDataService.getSubdomains(tenantId, {
                    "include[]": ["permissions", "locations"]
                }),
                TenantDataService.getTenantSubdomainsAdmins(tenantId)
            ]).then((responses) => {
                let subdomainsList = responses[0];
                let subdomainsAdmins = responses[1].plain();

                for (let subdomain of subdomainsList) {
                    subdomain["admins"] = subdomainsAdmins.filter((admin) => admin.subdomain_id === subdomain.id);
                }

                vm.subdomains = subdomainsList;
                vm.subdomainsLength = vm.subdomains.length;
                vm.subdomainsListData.updateCallback(1, vm.paginationData.itemsPerPage);
            });
        }

        init();

        /**
         * Handle subdomain item click
         *
         * @param {object} subdomainItem
         */
        function selectSubdomain(subdomainItem) {
            vm.selectedSubdomain = vm.selectedSubdomain !== subdomainItem ? subdomainItem : false;
            vm.state.canProcessNext = !!vm.selectedSubdomain;
        }

        /**
         * Signs user into another subdomain
         *
         * @param {MouseEvent} event
         * @param {object} item
         */
        function signIn($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            UserDataService.loadUserData().then((userData) => {
                if (item.id === userData.subdomain_id) {
                    PopupService.showAlertPopup({
                        text: 'YOU_ARE_ALREADY_LOGGED_INTO_THIS_SUBDOMAIN',
                        okButtonText: 'OK'
                    });
                } else {
                    LoginService.authorize(userData.id, item.id, function () {
                        location.reload();
                    });
                }
            });
        }

        /**
         * Shows popup for editing og creating sudbdomain
         *
         * @param {object} subdomainObj
         */
        function showSubdomainPopup(subdomainObj) {
            $q.all([
                LookupDataService.getAccessData(),
                getAllTenantLocations()
            ]).then(([accessData, locationsAll]) => {
                    const subdomainPopupData = {
                        reloadSubdomainsList: reloadSubdomainsList,
                        tenant: vm.selectedTenant,
                        subdomainObj: subdomainObj
                    };

                    if (accessData) {
                        subdomainPopupData.permissions = _.values(accessData.permissionsMap);
                        subdomainPopupData.permissionsGroup = _.values(accessData.permissions_groupMap);
                    }

                    if (locationsAll) {
                        subdomainPopupData.locationsAll = locationsAll;
                    }

                    return PopupService.show({
                        templateUrl: '/assets/views/common/popups/subdomain/subdomain.tpl.html',
                        controller: 'SubdomainPopupController',
                        controllerAs: '$ctrl',
                        windowClass: 'subdomain-popup',
                        keyboard: false,
                        resolve: {
                            data: function () {
                                return subdomainPopupData;
                            }
                        }
                    });
            }).catch(console.error.bind(console));
        }

        function getAllTenantLocations() {
            return $q.all([
                LocationDataService.getLocationsAll(),
                LocationDataService.getControllerDetails(),
            ]).then(([locations, details]) => {
                return Object.values(locations)
                    .map(location => {
                        const isScreen = location.controllerRef > 0;
                        location.details = isScreen
                            ? details.list.find(controller => location.controllerRef === controller.id)
                            : null;
                        return location;
                    }).filter(location => {
                        if (location.controllerRef > 0) {
                            return location.details
                                && [
                                    ACTIVATION_STATUS.ACTIVE.value,
                                    ACTIVATION_STATUS.INACTIVE.value
                                ].includes(location.details.status);
                        }

                        return location.beaconRef > 0;
                    });
            });
        }

        function createSubdomain() {
            showSubdomainPopup({
                'tenant_id': vm.selectedTenant.id,
                'permission_tenant': _.cloneDeep(vm.selectedTenant.permission_tenant),
                'location_tenant': _.cloneDeep(vm.selectedTenant.location_tenant)
            });
        }

        /**
         * Updates subdomain
         *
         * @param {MouseEvent} $event
         * @param {Object} subdomainItem
         */
        function updateSubdomain($event, subdomainItem) {
            $event.preventDefault(); // to prevent selecting subdomain
            $event.stopPropagation();
            showSubdomainPopup(subdomainItem);
        }

        function deleteSubdomain(tenantId, subdomainId, subdomainIdForReassigning) {
            TenantDataService.deleteSubdomain(tenantId, subdomainId, subdomainIdForReassigning)
                    .then((response) => {
                        reloadSubdomainsList();
                        vm.subdomainsListData.updateCallback(1, 10);
                    })
                    .catch((response) => {
                        if (response.status === 500) {
                            PopupService.showAlertPopup({
                                text: 'ERROR.INTERNAL_SERVER_ERROR',
                                okButtonText: 'OK'
                            });
                        }
                    });
        }

        /**
         * Delete button click handler
         *
         * @param {jQuery.Event} $event
         * @param {Restangular.Object} subdomainItem
         */
        function showDeleteSubdomainPopup($event, subdomainItem) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.show({
                templateUrl: '/assets/views/common/popups/subdomain/delete-subdomain/delete-subdomain.tpl.html',
                controller: 'DeleteSubdomainPopupController',
                controllerAs: 'deleteSubdomain',
                keyboard: false,
                resolve: {
                    data: () => ({
                        subdomainIdToRemove: subdomainItem.id,
                    })
                }
            }).then((subdomainIdForReassigning) => {
                deleteSubdomain(subdomainItem.tenant_id, subdomainItem.id, subdomainIdForReassigning);
            });
        }
    }
}());

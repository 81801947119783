(function() {
    'use strict';

    angular.module('beacon.app')
        .service('DynamicContentPopup', DynamicContentPopup);

    function DynamicContentPopup(PopupService) {

        return {
            show
        };

        function show(data) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/dynamic-content-popup/dynamic-content-popup.tpl.html',
                controller: 'DynamicContentPopupController',
                controllerAs: '$ctrl',
                windowClass: 'dynamic-content-popup',
                backdrop: true,
                resolve: {
                    data
                }
            });
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewUserToolbarController', NewUserToolbarController);

    function NewUserToolbarController(newUserState, $state) {
        const vm = this;

        // public properties
        vm.state = newUserState;
        vm.state.canProcessNext = false;
        vm.state.canFinish = false;
        vm.state.view = 0;
        vm.state.finish = 0;

        // public methods
        vm.next = next;
        vm.back = back;
        vm.finish = finish;
        vm.cancel = cancel;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;

        /**
         * Switches to selected tab
         *
         * @param {number} tab
         */
        function goToTab(tab) {
            if (tab < vm.state.view) {
                vm.state.view = tab;
            }
        }

        /**
         * Returns tab class
         * @param {number} tab
         * @returns {string}
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view++;
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.canProcessNext = false;
            vm.state.view--;
        }

        /**
         * Saves content
         */
        function finish() {
            vm.state.finish++;
        }

        function cancel() {
            $state.go('app.users');
        }
    }
}());

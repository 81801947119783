(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('CustomersCacheFactory', CustomersCacheFactory);

    function CustomersCacheFactory(
        ShareParkDataService
    ) {
        this.customers = null;

        const getAll = () => {
            if (this.customers) {
                return Promise.resolve(this.customers);
            }
            return ShareParkDataService.getCustomers().then(response => this.customers = response);
        }

        const clean = () => {
            this.customers = null;
        }

        const updateSingleRecord = customer => {
            if (!this.customers) {
                return;
            }
            const index = this.customers.findIndex(item => item.id === customer.id);

            if (index >= 0) {
                this.customers[index] = customer;
            } else {
                this.customers.push(customer);
            }
        }

        const removeSingleRecord = customer => {
            if (!this.customers) {
                return;
            }
            const index = this.customers.findIndex(item => item.id === customer.id);

            if (index >= 0) {
                this.customers.splice(index, 1);
            }
        }

        return {
            getAll,
            updateSingleRecord,
            removeSingleRecord,
            clean,
        }
    }
})();

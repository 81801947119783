(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('playlistPage', playlistPage);

    function playlistPage() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/playlist/playlist.tpl.html',
            replace: true,
            controller: PlaylistPageController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                pageData: '=',
                contentData: '<'
            }
        };
    }

    /**
     * @property {PlaylistPage} pageData
     * @property {DigitalDisplayContent} contentData
     *
     * @param $scope
     * @param $element
     * @param $translate
     * @param PopupService
     * @param ContentDataService
     * @param PermissionsService
     * @param Cropper
     * @param DigitalDisplayHelper
     * @param ContentHelper
     * @param PlaylistHelper
     * @param ImageUploadService
     * @param PageDataService
     * @param LanguageService
     * @param VIEW_TYPES
     * @param PLAYLIST_TRANSITION_TYPES
     * @param DIGITAL_DISPLAY_SCALE_TYPES
     * @param {PLAYLIST_ITEM_TYPE} PLAYLIST_ITEM_TYPE
     * @param CONTENT_TYPES
     * @param {PAGE_TYPES} PAGE_TYPES
     * @constructor
     */
    function PlaylistPageController(
        $scope,
        $element,
        $translate,
        PopupService,
        ContentDataService,
        PermissionsService,
        Cropper,
        DigitalDisplayHelper,
        ContentHelper,
        PlaylistHelper,
        ImageUploadService,
        PageDataService,
        LanguageService,
        VIEW_TYPES,
        PLAYLIST_TRANSITION_TYPES,
        DIGITAL_DISPLAY_SCALE_TYPES,
        PLAYLIST_ITEM_TYPE,
        CONTENT_TYPES,
        PAGE_TYPES,
    ) {
        const vm = this;

        const { getAppItemIcon, getTooltip } = PlaylistHelper;

        vm.dragover = true;
        vm.orientations = [
            { id: 1, title: 'PORTRAIT', type: VIEW_TYPES.PORTRAIT, ratio: 9 / 16 },
            { id: 2, title: 'LANDSCAPE', type: VIEW_TYPES.LANDSCAPE, ratio: 16 / 9 }
        ];
        vm.itemTypes = PLAYLIST_ITEM_TYPE;
        vm.MIN_INTERVAL = 2;
        vm.languages = [];
        vm.acceptTypes = [
            'image/jpeg',
            'image/png'
        ];

        vm.playlistButtonsTemplate =
            '/assets/views/content/elements/types/digital-display/page-types/playlist/playlist-buttons.tpl.html';

        vm.updateCrop = updateCrop;
        vm.uploadFiles = uploadFiles;
        vm.deleteField = deleteField;
        vm.showVideoPopup = showVideoPopup;
        vm.dragStart = dragStart;
        vm.dragEnd = dragEnd;
        vm.showImagePopup = showImagePopup;
        vm.showPagePopup = showPagePopup;
        vm.generatePermissionNameForExternalApp = generatePermissionNameForExternalApp;
        vm.isPlaylistInternalAppIconVisible = PermissionsService.isPlaylistInternalAppIconVisible;
        vm.getScaleClass = DigitalDisplayHelper.getScaleClass;
        vm.getTooltip = getTooltip;
        vm.addDynamicContentPlaceholder = addDynamicContentPlaceholder;
        vm.changeContent = changeContent;
        vm.isVideo = isVideo;
        vm.isImage = isImage;

        vm.items = vm.pageData.page.items;
        vm.pageData.page.view = VIEW_TYPES.PORTRAIT;

        vm.interval = (vm.pageData.page.interval / 1000) || 1;

        vm.transitionTypes = PLAYLIST_TRANSITION_TYPES;
        vm.transition =  vm.pageData.page.transition;
        vm.scaleTypes =  DIGITAL_DISPLAY_SCALE_TYPES;

        if (!vm.items.portrait) {
            vm.items.portrait = [];
        }
        if (!vm.items.landscape) {
            vm.items.landscape = [];
        }

        vm.dragTemp = null;

        function dragStart(orientation, index) {
            vm.dragover = false;
        }

        vm.getAppItemIcon = getAppItemIcon;

        /**
         * @param {{
         *   title: string,
         *   type: string,
         *   contentId: number,
         *   stations: number[],
         * }} item
         * @return {boolean}
         */
        vm.isTimetableHafas = item => {
            return item.type === PLAYLIST_ITEM_TYPE.TIMETABLE_HAFAS;
        }

        function dragEnd(orientation, index, targetOrientation, targetIndex) {
            vm.dragover = true;
            var list = vm.items[orientation];
            var targetList = vm.items[targetOrientation];

            // Copy the item from source to target.
            targetList.splice(targetIndex, 0, list[index]);

            // Remove the item from the source, possibly correcting the index first.
            // We must do this immediately, otherwise ng-repeat complains about duplicates.
            if (list === targetList && targetIndex <= index) {
                index++;
            }
            list.splice(index, 1);

            // By returning true from dnd-drop we signalize we already inserted the item.
            [orientation, targetOrientation].forEach(function(type) {
                vm.items[type].forEach(function(item) {
                    if (item.type === PLAYLIST_ITEM_TYPE.VIDEO) {
                        delete item.tag;
                    }
                });
            });

            return true;
        }

        /**
         * @param {PlaylistItem|string} item
         */
        function addField(item) {
            if (typeof item === 'string') {
                item = { type: item };
            }

            switch (true) {
                case isImage(item.type):
                    item.scaleType = DIGITAL_DISPLAY_SCALE_TYPES.FILL.id;
                    // Fallthrough
                case isVideo(item.type):
                    item.img = '/assets/images/noImage.png';
                    item.cropped = { img: '/assets/images/noImage.png' };
                    break;
            }

            vm.items[vm.orientation.type || VIEW_TYPES.PORTRAIT].push(item);
        }

        function deleteField(orientation, index) {
            vm.items[orientation].splice(index, 1);
        }

        function showCrop(imageFile, orientation, index, data) {
            if (vm.items[orientation][index]) {
                data = vm.items[orientation][index].cropped;
            }

            return ImageUploadService.crop({
                file: imageFile,
                minWidth: 400,
                minHeight: 400,
                center: true,
                fullInfo: true,
                aspectRatio: false,
                data: data || {}
            });
        }

        function showImagePopup() {
            $translate('PLEASE_SELECT_IMAGE')
                .then(translation => {
                    ImageUploadService.upload({
                        accept: vm.acceptTypes.join(','),
                        placeholder: translation,
                        alwaysCrop: true,
                        multiple: true,
                        onImage: onImageCallback,
                    });
                });
        }

        function blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        }

        function toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        function encodeImageFileAsURL(imageFile, onSuccess, onCancel) {
            var index = vm.items[vm.orientation.type].length - 1;
            var item = vm.items[vm.orientation.type][index];

            Cropper.encode(imageFile).then(function(url) {
                var img = new Image();
                img.onload = function() {
                    Cropper.crop(imageFile, {
                        x: 0,
                        y: 0,
                        width: this.width,
                        height: this.height,
                        rotate: 0
                    }).then(function(blob) {
                        var data = new FormData();
                        data.append('image', imageFile);

                        ContentDataService.upload(data).then(function(res) {
                            if (item) {
                                item.img = res;
                                item.newImage = !!res;
                                if (isGif(imageFile.type)) {
                                    item.cropped = { img: res }
                                }
                            }
                        });
                    });
                };
                img.src = url;
            });

            if (isGif(imageFile.type)) {
                return;
            }

            showCrop(imageFile, vm.orientation.type, index)
                .then(function(result) {
                    item.cropped = angular.copy(result.cropData);

                    var imagePath = item.img.split('.');
                    var lastIndex = imagePath.length - 1;
                    var ext = imagePath[lastIndex];
                    var data = new FormData();

                    data.append('image', blobToFile(result.blob, 'temp.' + ext), 'temp.' + ext);
                    ContentDataService.upload(data).then(function(res) {
                        item.cropped.img = res;

                        if (typeof onSuccess === 'function') {
                            onSuccess();
                        }
                    });
                })
                .catch(function() {
                    if (typeof onCancel === 'function') {
                        onCancel();
                    }
                });
        }

        function updateCrop(orientation, index) {
            var item = vm.items[orientation][index];

            if (isGif(item.type)) {
                return;
            }
            switch (true) {
                case isImage(item.type):
                    toDataUrl(item.img, function(myBase64) {
                        var imageFile = Cropper.decode(myBase64);

                        showCrop(imageFile, orientation, index).then(function(result) {
                            Cropper.encode(result.blob).then(function(url) {
                                item.cropped = angular.copy(result.cropData);

                                var data = new FormData();
                                data.append('image', blobToFile(result.blob));
                                ContentDataService.upload(data).then(function(res) {
                                    item.cropped.img = res;
                                });
                            });
                        }, function() {});
                    });
                    break;

                case isVideo(item.type):
                    videoCrop({
                        img: item.img,
                        cropped: item.cropped.img,
                        index: index,
                        orientation: orientation
                    }, function(result) {
                        item.cropped = result.cropped;
                        uploadImage(result.cropped.img).then(function(res) {
                            item.cropped.img = res;
                        });
                    });
                    break;
            }
        }

        function videoCrop(options, callback) {
            if (!options.orientation) {
                options.orientation = vm.orientation.type;
            }

            if (!options.img && options.cropped) {
                return toDataUrl(options.cropped, function(base64) {
                    options.img = base64;
                    delete options.cropped;

                    videoCrop(options, callback);
                });
            }

            var image = Cropper.decode(options.img);
            showCrop(image, options.orientation, options.index, options.cropped)
                .then(function(result) {
                    Cropper.encode(result.blob).then(function(url) {
                        var cropped = angular.copy(result.cropData);
                        cropped.img = url;

                        if (typeof callback === 'function') {
                            callback({ cropped: cropped });
                        }
                    });
                })
                .catch(console.error.bind(console));
        }

        function uploadImage(image) {
            var temp = 'temp.jpeg';
            var data = new FormData();
            var imageBlob = Cropper.decode(image);

            data.append('image', blobToFile(imageBlob, temp), temp);
            return ContentDataService.upload(data);
        }

        /**
         * Click on playlist element
         *
         * @param orientation
         * @param index
         * @param item
         */
        function changeContent(orientation, index, item) {
            vm.orientation = orientation;

            switch (true) {
                case isImage(item.type):
                    vm.updateCrop(orientation.type, index);
                    break;
                case isVideo(item.type):
                    vm.showVideoPopup(orientation.type, index);
                    break;
                case item.type === PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT_PLACEHOLDER:
                    break;
                default:
                    vm.showPagePopup(item.type, orientation.type, index);
            }
        }

        /**
         *
         * @param {object} imageFiles BLOB image files
         * @param {boolean} uploadToS3
         */
        function onImageCallback(imageFiles, uploadToS3) {
            angular.isArray(imageFiles) ? uploadFiles(imageFiles) : uploadFiles([imageFiles]);
        }

        function uploadFiles(files, orientation, index) {
            function step(i) {
                if (i === files.length) {
                    return;
                }

                if (index === undefined) {
                    addField(files[i].type);
                }

                var ind = index || vm.items[orientation].length - 1;

                var fileType = files[i].type.slice(0, 5);
                switch (true) {
                    case isImage(fileType):
                        encodeImageFileAsURL(files[i], function() {
                            step(i + 1);
                        }, function() {
                            deleteField(orientation, ind);
                            step(i + 1);
                        });
                        break;

                    case isVideo(fileType):
                        if (files[i].cropped.img.slice(0, 4) === 'data') {
                            uploadImage(files[i].cropped.img).then(function(res) {
                                vm.items[orientation][ind] = {
                                    id: files[i].id,
                                    img: files[i].img,
                                    src: files[i].src,
                                    type: files[i].type,
                                    random: files[i].random,
                                    cropped: vm.items[orientation][ind].cropped,
                                };

                                vm.items[orientation][ind].cropped.img = res;

                                step(i + 1);
                            });
                        }
                        break;

                    default: break;
                }
            }

            // create iterable object
            if (files.length === undefined) {
                files = [files];
            }

            if (!orientation) {
                orientation = vm.orientation.type;
            } else if (!vm.orientation) {
                vm.orientation = vm.orientations.filter(function(obj) {
                    return obj.type === orientation;
                })[0];
            }

            step(0);
        }

        function showVideoPopup(orientation, index) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/upload-video/upload-video.tpl.html',
                controller: 'UploadVideoPopupController',
                controllerAs: 'uploadVideoPopupController',
                windowClass: 'uploadVideoPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return {
                            videoCrop: videoCrop,
                            uploadFiles: vm.uploadFiles,
                            item: orientation ? vm.items[orientation][index] : undefined,
                            index: index
                        };
                    }
                }
            });
        }

        /**
         * @param {'internal'|'external'|'split-screen'} type
         * @param [orientation]
         * @param [index]
         * @return {*}
         */
        function showPagePopup(type, orientation, index) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/app-playlist/app-playlist.tpl.html',
                controller: 'PlaylistAppPopupController',
                controllerAs: 'playlistAppPopupCtrl',
                windowClass: 'playlist-app-popup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return {
                            type: type,
                            pageData: vm.pageData,
                            contentData: vm.contentData,
                            addField: addField,
                            orientation: orientation,
                            index: index,
                            interval: vm.interval,
                            splitScreens: _getSplitScreenPages(),
                        };
                    }
                }
            });
        }

        function dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }

        function createVideosUploader(callback) {
            var queue = [];
            var uploading = false;

            function __upload(file, fn) {
                uploading = true;

                var data = new FormData();
                data.append('video', file);

                ContentDataService.uploadVideo(data)
                    .then(function(res) {
                        fn(res);

                        if (!queue.length) {
                            uploading = false;
                            return typeof callback === 'function' ? callback() : null;
                        }

                        __upload.apply(null, queue.shift());
                    });
            }

            return function(file, fn) {
                if (uploading) {
                    queue.push([file, fn]);
                } else {
                    __upload(file, fn);
                }
            };
        }

        function uploadAllVideos(callback) {
            let videoUploader = createVideosUploader(callback);

            let hasVideo = false;
            [vm.pageData.page.items.portrait, vm.pageData.page.items.landscape].forEach((array) => {
                array.forEach((item) => {
                    if (isVideo(item.type)) {
                        let ext = item.type.replace('video/', '');
                        if (ext !== 'youtube' && item.src.slice(0, 4) === 'data') {
                            hasVideo = true;
                            let videoFile = dataURLtoFile(item.src, 'temp.' + ext);

                            videoUploader(videoFile, (res) => {
                                item.src = res;
                            });
                        }

                        delete item.img;
                        delete item.tag;
                    }
                });
            });

            if (!hasVideo && typeof callback === 'function') {
                return callback();
            }
        }

        function addDynamicContentPlaceholder() {
            addField({
                type: PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT_PLACEHOLDER,
                title: 'Dynamic Content Placeholder',
            });
        }

        /**
         * @return {DigitalDisplayPage[]}
         * @private
         */
        function _getSplitScreenPages() {
            const defaultLngId = Object.keys(vm.contentData.message)[0];
            const pages = vm.contentData.message[defaultLngId].pages;
            return pages.filter(page => page.page_type_id === PAGE_TYPES.SPLIT_SCREEN);
        }

        function onFinish(event, option) {
            vm.pageData.page.transition = vm.transition;
            Object.values(VIEW_TYPES).forEach(viewType => {
                vm.pageData.page.items[viewType].forEach(item => {
                    delete item.content;
                })
            });
            uploadAllVideos(() => {
                if (typeof option.callback === 'function') {
                    option.callback({
                        pageData: vm.pageData
                    });
                }
            });
        }

        /**
         * Is video
         *
         * @param type
         */
        function isVideo(type) {
            return type.slice(0, 5) === 'video';
        }

        /**
         * Is video
         *
         * @param type
         */
        function isImage(type) {
            return type.slice(0, 5) === 'image';
        }

        /**
         * Generates permission name for playlist external app button
         * @return {string} Permission name
         */
        function generatePermissionNameForExternalApp() {
            return PermissionsService.generatePlaylistExternalAppPermission();
        }

        function loadPageContents() {
            if (!vm.pageData.dbId) {
                return;
            }

            const contentTypeIds = [
                CONTENT_TYPES.EXTERNAL_APP,
                CONTENT_TYPES.LINE_ROUTE,
                CONTENT_TYPES.DYNAMIC_CONTENT,
            ].join(',');
            PageDataService.getPlaylistContents(vm.pageData.dbId, contentTypeIds)
                .then(response => {
                    const contents = response.plain().map(item => ContentHelper.decodeContent(item));

                    Object.values(VIEW_TYPES).forEach(viewType => {
                        vm.pageData.page.items[viewType].forEach(item => {
                            if (item.contentId) {
                                item.content = contents.find(content => content.id === item.contentId);
                            }
                        })
                    });
                })
        }

        /**
         * Checks if image type is gif
         * @param imageType
         * @return {boolean}
         */
        function isGif(imageType) {
            return imageType === 'image/gif'
        }

        function init() {
            loadPageContents();
            LanguageService.getLanguages()
                .then(response => vm.languages = response.plain());
        }

        init();

        $scope.$watch(() => vm.interval, (newValue) => {
            if (newValue) {
                vm.pageData.page.interval = vm.interval * 1000;
            }
        });

        $scope.$on('digital-display-savepage', onFinish);
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('TrmPoiCategoryToolbarController', TrmPoiCategoryToolbarController);

    function TrmPoiCategoryToolbarController($state, $stateParams, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.PoiCategoryStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = false;

        vm.cancel = cancel;
        vm.finish = finish;

        /**
         * Cancel button handler
         */
        function cancel() {
            $state.go('app.trmPoiCategories', {
                paginationData: $stateParams.paginationData
            });
        }

        /**
         * Finish button handler
         */
        function finish() {
            vm.state.finish = true;
        }
    }
})();

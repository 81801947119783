(function() {
    'use strict';

    angular.module('beacon.app')
        .component('topRevenues', {
            templateUrl: '/assets/views/share-park/dashboard/widgets/top-revenues/top-revenues.tpl.html',
            controller: TopRevenuesController,
            bindings: {
                purchases: '<',
                customers: '<',
                currencies: '<',
                timeRange: '<',
            }
        });

    function TopRevenuesController(
        $scope,
        $translate,
        DateHelper,
    ) {
        const vm = this;

        const { getDefaultDateFormat } = DateHelper;

        const TOP_LIST_LENGTH = 10;
        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
        const currentLocale = $translate.use();


        vm.$onInit = init;

        vm.topRevenues = [];
        vm.userProfiles = [];
        vm.WIDGET_TYPES = {
            TOP_LIST: {
                id: 1,
                icon: 'list'
            },
            PIE_CHART: {
                id: 2,
                icon: 'pie_chart'
            },
        };

        vm.GROUPING_MODES = {
            BY_REVENUE: {
                label: 'BY_REVENUE',
                id: 1,
            },
            BY_PROFILE: {
                label: 'BY_PROFILE',
                id: 2,
            }
        };
        
        vm.widgetTypesArr = Object.values(vm.WIDGET_TYPES);
        vm.selectedWidgetType = vm.WIDGET_TYPES.TOP_LIST.id;

        vm.groupingModesArr = Object.values(vm.GROUPING_MODES);
        vm.selectedGroupingMode = vm.GROUPING_MODES.BY_REVENUE.id;

        vm.updateData = updateData;
        vm.formatDateTime = formatDateTime;
        vm.selectWidgetType = selectWidgetType;
        vm.setCurrency = setCurrency;
        vm.onGroupingModeChange = onGroupingModeChange;

        function init() {
            vm.currentCurrency = vm.currencies[0] || 'EUR';
            updateData();
        }

        function updateData() {
            if (vm.selectedWidgetType === vm.WIDGET_TYPES.TOP_LIST.id) {
                updateTopRevenues();
            } else {
                updatePieChart();
            }
        }

        function updatePieChart() {
            const filteredRevenues = getFilteredRevenues();
            const groupedRevenues = _.groupBy(filteredRevenues,
                vm.selectedGroupingMode === vm.GROUPING_MODES.BY_REVENUE.id
                    ? 'name'
                    : 'userProfileRef'
            );

            vm.pieChartLabels = vm.selectedGroupingMode === vm.GROUPING_MODES.BY_REVENUE.id
                ? Object.keys(groupedRevenues)
                : Object.values(groupedRevenues).map(sameProfileRevenues => {
                    const firstRevenue = sameProfileRevenues[0];
                    return firstRevenue.userProfileLabel[currentLocale] || Object.values(firstRevenue.userProfileLabel)[0]
                });
            vm.pieChartData = Object.values(groupedRevenues).map(revenues => revenues.reduce((accum, revenue) => {
                const price = angular.isNumber(revenue.discountedPrice)
                    ? revenue.discountedPrice
                    : revenue.originalPrice;

                return accum + price;
            }, 0) / 100);
        }

        /**
         * Updates top revenues list
         */
        function updateTopRevenues() {
            const filteredRevenues = getFilteredRevenues();
            const groupedRevenues = _.groupBy(filteredRevenues,
                vm.selectedGroupingMode === vm.GROUPING_MODES.BY_REVENUE.id
                    ? 'name'
                    : 'userProfileRef'
            );

            vm.topRevenues = Object.keys(groupedRevenues).map(key => {
                const revenues = groupedRevenues[key];
                const firstRevenue = revenues[0];
                const sum = revenues.reduce((accum, revenue) => {
                    const price = angular.isNumber(revenue.discountedPrice)
                        ? revenue.discountedPrice
                        : revenue.originalPrice;

                    return accum + price;
                }, 0) / 100;

                return {
                    name: vm.selectedGroupingMode === vm.GROUPING_MODES.BY_REVENUE.id
                        ? key
                        : firstRevenue.userProfileLabel[currentLocale] || Object.values(firstRevenue.userProfileLabel)[0],
                    sum,
                }
            });
            vm.topRevenues = vm.topRevenues.sort((a, b) => {
                return b.sum - a.sum;
            }).slice(0, TOP_LIST_LENGTH);
        }

        /**
         * Returns filtered revenues array
         * @return {Array}
         */
        function getFilteredRevenues() {
            const minDate = vm.timeRange.diffValue ?
                moment().subtract(vm.timeRange.diffValue, vm.timeRange.diffUnit)
                : null;

            return vm.purchases.filter(purchase => {
                const dateMatches = !minDate || purchase.purchaseDate.isAfter(minDate);
                return dateMatches && purchase.currencyCode === vm.currentCurrency
            });
        }

        /**
         * @param { moment } dateTime
         * @return { string }
         */
        function formatDateTime(dateTime) {
            return dateTime.format(DATE_TIME_FORMAT);
        }

        /**
         * Sets current widget type
         * @param { Number } id
         */
        function selectWidgetType(id) {
            vm.selectedWidgetType = id;

            switch (vm.selectedWidgetType) {
                case vm.WIDGET_TYPES.PIE_CHART.id:
                    updatePieChart();
                    break;
                case vm.WIDGET_TYPES.TOP_LIST.id:
                    updateTopRevenues();
                    break;
            }
        }

        function onGroupingModeChange() {
            updateData();
        }

        /**
         * Sets current currency
         * @param {String} currency
         */
        function setCurrency(currency) {
            vm.currentCurrency = currency;
            updateData();
        }

        $scope.$watch(
            () => vm.timeRange,
            () => updateData()
        )
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('fileRead', fileRead);

    function fileRead() {
        return {
            scope: {
                fileRead: '=',
            },
            link,
        };

        function link(scope, element) {
            element.bind('change',
                event => scope.fileRead(event.target.files[0])
            );
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedbackAttributesSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/feedback-attributes/feedback-attributes.tpl.html',
            controller: FeedbackAttributesSegmentType,
            bindings: {
                langArray: '<',
                segmentData: '=',
            }
        });

    function FeedbackAttributesSegmentType(
        $scope,
        $window,
        segmentAddState,
    ) {
        const vm = this;

        // Definition
        vm.state = segmentAddState;

        vm.errorMsg = null;

        init();
        function init() {
            setModel();
        }

        function setModel() {
            vm.model = vm.segmentData;
        }

        function onFinish(event, option) {
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: angular.copy(vm.segmentData)
                });
            }
        }

        function onLanguageChange() {
            setModel();
        }

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

(function() {
    'use strict';

    angular.module('scMeetings')
        .filter('datetime', function (
            $moment
        ) {
            return function (value) {
                const date = $moment(value);

                if (!date.isValid()) {
                    return '';
                }

                const isToday = date.isSame(new Date(), 'day');

                if (isToday) {
                    return date.format('HH:mm');
                }

                return date.format('DD MMM YYYY, HH:mm');
            };
        });
}());

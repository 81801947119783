(function() {
    'use strict';

    angular.module('beacon.app')
        .component('quizQuestionPreview', {
            templateUrl: '/assets/views/content/elements/preview/quiz-question/quiz-question.tpl.html',
            controller: QuizQuestionPreview,
            bindings: {
                question: '<',
                contentData: '<',
                isLastItem: '<?'
            }
        });

    function QuizQuestionPreview($scope, UtilitiesService, QUIZ_QUESTION_TYPES, ContentHelper) {
        const vm = this;

        vm.compareAsNumber = UtilitiesService.compareAsNumber;
        vm.QUIZ_QUESTION_TYPES = QUIZ_QUESTION_TYPES;

        vm.isFixedInfoType = ContentHelper.isFixedInfoType;

        $scope.$watch(
            () => vm.contentData.language_id,
            langId => vm.langId = langId
        );
    }
}());

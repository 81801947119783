(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poiMapView', {
            templateUrl: '/assets/views/common/components/poi-map-view/poi-map-view.tpl.html',
            controller: PoiMapViewController,
            bindings: {
                tourPoints: '<',
                itemId: '<?',
            }
        });

    function PoiMapViewController(
        $scope,
        $timeout,
        ModelsFactory,
        UserUtilitiesService,
    ) {
        const vm = this;

        const { MapOptions } = ModelsFactory;
        const MAPS = google.maps;
        const MAX_ZOOM = 20;

        const allMarkers = [];
        let map = null;
        let infoWindow = null;
        let tourPath = null;

        vm.defaultMapId = 'tour_map_';

        vm.$onInit = onInit;

        function drawTour() {
            clearMap();
            const viewBounds = new MAPS.LatLngBounds();
            vm.tourPoints.forEach(point => {
                const newMarker = new MAPS.Marker({
                    position: point.coords,
                    label: point.label,
                    map
                });
                allMarkers.push(newMarker);
                const coordinates = new MAPS.LatLng(point.coords.lat, point.coords.lng);
                viewBounds.extend(coordinates);
            });
            if (vm.tourPoints.length) {
                map.fitBounds(viewBounds);
            }
            const lineSymbol = {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
            };
            tourPath = new MAPS.Polyline({
                path: vm.tourPoints.map(point => point.coords),
                icons: [{
                    icon: lineSymbol,
                    offset: '100%'
                }],
                map,
                strokeColor: '#FF0000',
            });
        }

        /**
         * Removes all markers & polylines
         */
        function clearMap() {
            allMarkers.forEach(marker => {
                marker.setMap(null);
            });
            allMarkers.splice(0, allMarkers.length);
            if (tourPath) {
                tourPath.setMap(null);
            }
        }

        function onInit() {
            const positionOptions = UserUtilitiesService.userLocationOptions();

            if (!positionOptions.maxZoom) {
                positionOptions.maxZoom = MAX_ZOOM;
            }
            const mapOptions = new MapOptions(positionOptions);

            $timeout(function () {
                let mapId = vm.defaultMapId;
                if (vm.itemId) {
                    mapId = mapId + vm.itemId;
                }
                const mapElement = document.getElementById(mapId);
                map = new MAPS.Map(mapElement, mapOptions);
                if (vm.tourPoints) {
                    drawTour();
                }
            }, 0);
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.tourPoints;
        }), () => {
            map && drawTour();
        });
    }
})();
(function() {
    'use strict';

    angular.module('lineRoute')
        .service('LineRouteDataService', LineRouteDataService);

    function LineRouteDataService($http, LineRouteDateTimeService, TRANSPORT_TYPE) {

        const DEPARTURE_DATE_OFFSET = 25;
        const { incrementCurrentDate, toLocalTime, compareDates } = LineRouteDateTimeService;

        return {
            getDepartureTimes,
            getJourneyDetails,
            getPreviewData,
            filterDepartures,
            orderDepartures,
            checkDeparturesChanged,
            filterPreviousStations,
            processStationTime,
            filterByTrack,
            getDeparturesTracks,
        };


        function getDepartureTimes(stopId, tenantId) {
            return $http.get(`/share/station/departures?stopId=${stopId}&tenantId=${tenantId}`);
        }

        function getJourneyDetails(journeyId, tenantId) {
            return $http.post('/share/journey/details', {
                tenantId,
                journeyId,
            });
        }

        function getPreviewData(stationsCount) {
            // dummy data for preview
            const previewData = {
                Product: {
                    name: "      S3",
                    line: "S3",
                    catCode: "0",
                },
                name: "      S3",
                direction: "S+U Alexanderplatz Bhf (Berlin)",
                stations: getDummyStations(stationsCount),
                times: [
                    incrementCurrentDate(DEPARTURE_DATE_OFFSET),
                    incrementCurrentDate(DEPARTURE_DATE_OFFSET + 2),
                    incrementCurrentDate(DEPARTURE_DATE_OFFSET + 8)
                ],
                track: '1',
            };
            return [previewData, previewData];
        }

        /**
         * Filters departures
         * @param { array } departures
         * @param { number } timezoneId
         * @param { array } screenTracks
         * @param { array } transportTypes
         * @return { array }
         */
        function filterDepartures(departures, timezoneId, screenTracks, transportTypes) {
            let filteredDepartures = filterByTrack(departures, screenTracks);
            filteredDepartures = filterByTransportTypes(filteredDepartures, transportTypes);
            return filterByDepartureTime(filteredDepartures, timezoneId);
        }

        /**
         * Filters stations before current one
         * @param stations
         * @param departure
         * @return {*}
         */
        function filterPreviousStations(stations, departure) {
            const currentStopIndex = stations.findIndex(station => station.id === departure.stopid);
            const visibleStations = stations.slice(currentStopIndex);

            visibleStations.forEach(station => {
                const { name, line } = departure.Product;
                station.products = station.products &&
                    station.products.filter(product =>
                        ![name, line].includes(product.name)
                );
            });

            return visibleStations;
        }

        /**
         * Orders departures array by firs departure time
         * @param departures
         * @return {Array}
         */
        function orderDepartures(departures) {
            return departures.sort((dep1, dep2) =>
                compareDates(dep1.times[0], dep2.times[0])
            );
        }

        /**
         * Checks if departures has changed
         * @param oldDepartures
         * @param newDepartures
         * @return {boolean|*}
         */
        function checkDeparturesChanged(oldDepartures, newDepartures) {
            return !angular.isArray(oldDepartures) ||
                newDepartures.some((departure, index) => {
                    const oldDep = oldDepartures[index];

                    if (!oldDep) {
                        return true;
                    }
                    const oldTrack = oldDep.rtTrack || oldDep.track;
                    const track = departure.rtTrack || departure.track;

                    const sameDepartures = departure.Product.line === oldDep.Product.line &&
                        departure.direction === oldDep.direction;
                    const trackChanged = oldTrack !== track;
                    return !sameDepartures || trackChanged || !departure.times.every((time, index) => {
                        return time.isSame(oldDep.times[index]);
                    });
                })
        }

        /**
         * Add property "time" to station object with correct arrival time
         *
         * @param {object} station
         * @param {string} timeZone
         * @returns {object}
         */
        function processStationTime(station, timeZone) {
            const arrivalDateString = station.rtArrDate && station.rtArrTime
                ? `${station.rtArrDate} ${station.rtArrTime}`
                : `${station.arrDate} ${station.arrTime}`;

            station.time = LineRouteDateTimeService.toLocalTime(arrivalDateString, timeZone);
            return station;
        }

        // private methods

        /**
         * Filters departures by departure time
         * @param departures
         * @param timezoneId
         * @return {Array}
         */
        function filterByDepartureTime(departures, timezoneId) {
            const filteredDepartures = [];
            departures.forEach(departure => {
                const depTime = departure.rtDate && departure.rtTime
                    ? `${departure.rtDate} ${departure.rtTime}`
                    : `${departure.date} ${departure.time}`;

                // convert departure time to browser timezone
                const depTimeMoment = toLocalTime(depTime, timezoneId);
                const sameDeparture = filteredDepartures.find(filteredDeparture => {
                    return filteredDeparture.Product.line === departure.Product.line &&
                        filteredDeparture.direction === departure.direction;
                });

                if (sameDeparture) {
                    sameDeparture.times.push(depTimeMoment);
                    return;
                }

                departure.times = [depTimeMoment];
                filteredDepartures.push(departure);
            });

            return orderDeparturesTimes(filteredDepartures);
        }

        /**
         * Filters departures by track
         * @param departures
         * @param screenTracks
         * @return {*}
         */
        function filterByTrack(departures, screenTracks) {
            const NO_TRACK = -1;           // if screenTracks has -1 should show only departures without track
            const ALL_TRACKS = -2;         // if screenTracks has -2 should show all departures
            const noTracksSelected = Array.isArray(screenTracks) && !screenTracks.length;

            if (screenTracks.includes(ALL_TRACKS) || noTracksSelected) {
                return departures;
            }

            return departures.filter(departure => {
                const depTrack = (departure.rtTrack || departure.track) ?
                    Number(departure.rtTrack || departure.track) : NO_TRACK;

                return screenTracks.includes(depTrack);
            });
        }

        /**
         * Filters departures by transport types
         * @param departures
         * @param transportTypes
         */
        function filterByTransportTypes(departures, transportTypes) {
            let allowedCatCodes = [];
            transportTypes.forEach(type => {
                allowedCatCodes = [
                    ...allowedCatCodes,
                    ...(TRANSPORT_TYPE[type].catCodes || [])
                ];
            });

            return departures.filter(departure => {
                // Handle different API versions
                const product = Array.isArray(departure.Product)
                    ? departure.Product[0]
                    : departure.Product;

                return allowedCatCodes.includes(Number(product.catCode));
            })
        }

        /**
         * Order times of every departure
         * @param { array } departures
         * @return { array }
         */
        function orderDeparturesTimes(departures) {
            return departures.map(departure => {
                departure.times = departure.times.sort(compareDates);
                return departure;
            });
        }

        /**
         * Returns dummy stations list for preview
         * @param { number } stationsCount
         * @return {Array}
         */
        function getDummyStations(stationsCount) {
            const DEFAULT_PRODUCTS_COUNT = 5;
            const stations = [];
            for (let i = 1; i <= stationsCount; i++) {
                stations.push({
                    name: `Station ${i}`,
                    products: getDummyProducts(DEFAULT_PRODUCTS_COUNT),
                    time: moment(incrementCurrentDate(DEPARTURE_DATE_OFFSET)),
                });
            }
            return stations;
        }

        /**
         * Returns dummy products data
         * @param { number } count
         * @return { Array }
         */
        function getDummyProducts(count) {
            const products = [];
            for (let i = 1; i <= count; i++) {
                products.push({
                    name: `PR${i}`,
                });
            }
            return products;
        }

        /**
         * Gets tracks list from departures
         * @param { Array } departures
         * @return { Array }
         */
        function getDeparturesTracks(departures) {
            const tracks = [];

            departures.forEach(departure => {
                if (!(departure.rtTrack || departure.track)) {
                    return;
                }
                const depTrack = Number(departure.rtTrack || departure.track);

                if (!!depTrack && !tracks.includes(depTrack)) {
                    tracks.push(depTrack);
                }
            });

            return tracks;
        }
    }
})();
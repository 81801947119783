(function() {
    'use strict';

    angular.module('trmTimetableHafas').service(
        'MgateService',
        function($http) {
            return {
                serverInfo,
                journeys,
                locations,
                productParams,
            }

            /**
             * @param {Object} options
             * @param {number} options.subdomainId
             *
             * @return {Promise<{sD: string, sT: string}>}
             */
            function serverInfo(options) {
                return $http.post(
                    '/mgate/' + options.subdomainId,
                    [
                        {
                            meth: 'ServerInfo',
                            req: {
                                getServerDateTime: true,
                                getTimeTablePeriod: true
                            },
                        }
                    ]
                ).then(response => response.data.svcResL[0].res);
            }

            /**
             * @typedef MgateRequestOptions
             *
             * @property {number} subdomainId
             * @property {TimetableHafasBoardType} [boardType]
             * @property {moment.Moment} [from]
             * @property {number} [maxJourneys]
             * @property {{id: string, poles: MgateLocation[]}[]} stations
             * @property {number[]} [transportTypes]
             * @property {boolean} [onlyRealTime]
             * @property {string[]} [journeyNames]
             * @property {'INC'|'EXC'} [journeyNamesFilterMode]
             * @property {number} [viaStopsNumber]
             * @property {string[]} [tracks]
             * @property {'INC'|'EXC'} [tracksFilterMode]
             * @property {number} [minDur]
             */

            /**
             * @param {MgateRequestOptions} options
             *
             * @return {Promise<MgateStationBoardResponseCore>}
             */
            function journeys(options) {
                return $http.post(
                    '/mgate/' + options.subdomainId,
                    options.stations.map(station => {
                        return {
                            meth: 'StationBoard',
                            req: _generateStationBoardRequest(station, options),
                        }
                    }),
                ).then(response => response.data);
            }

            /**
             * @param {{id: string, poles: MgateLocation[]}} station
             * @param {MgateRequestOptions} options
             *
             * @return {Object}
             */
            function _generateStationBoardRequest(station, options) {
                const request = {
                    stbLoc: {
                        extId: station.id,
                        type: 'S' // What is this? (probably station)
                    },
                    maxJny: options.maxJourneys,
                    date: options.from.format('YYYYMMDD'),
                    time: options.from.format('HHmmss'),
                    sort: 'RT',
                    type: options.boardType.toUpperCase(),
                };

                if (options.viaStopsNumber) {
                    request.pslMode = 'IMP';
                    request.pslMaxStops = options.viaStopsNumber;
                }

                if (options.onlyRealTime) {
                    request.getWithRtOnly = true;
                }

                if (options.minDur) {
                    request.minDur = options.minDur;
                }

                request.jnyFltrL = [];
                request.locFltrL = [];

                if (options.journeyNames && options.journeyNames.length) {
                    request.jnyFltrL.push(
                        ...options.journeyNames.map(journeyName => {
                            return {
                                type: 'NAME',
                                mode: options.journeyNamesFilterMode,
                                value: journeyName,
                            }
                        })
                    );
                }

                if (options.transportTypes && options.transportTypes.length) {
                    request.jnyFltrL.push(
                        ...options.transportTypes.map(typeId => {
                            return {
                                type: 'PROD',
                                mode: 'INC',
                                value: typeId,
                            }
                        })
                    );
                }

                if (options.tracks && options.tracks.length) {
                    request.locFltrL.push(
                        ...options.tracks.map(track => {
                            return {
                                type: 'PLATF',
                                mode: options.tracksFilterMode,
                                value: Number(track),
                            }
                        })
                    );
                }

                const poles = station.poles;

                if (poles && poles.length) {
                    request.locFltrL.push(
                        ...poles.map(pole => {
                            return {
                                type: 'LID',
                                mode: 'INC',
                                value: pole.lid,
                            }
                        })
                    );
                }

                if (!request.jnyFltrL.length) {
                    delete request.jnyFltrL;
                }

                if (!request.locFltrL.length) {
                    delete request.locFltrL;
                }

                return request;
            }

            /**
             * @param {object} options
             * @param {number} options.subdomainId
             * @param {string[][]} options.poleIds
             *
             * @return {Promise<MgateLocationResponseCore>}
             */
            function locations(options) {
                return $http.post(
                    '/mgate/' + options.subdomainId,
                    options.poleIds.map(poleIds => {
                        return {
                            meth: "LocDetails",
                            req: {
                                locL: poleIds.map(poleId => ({extId: poleId.toString()})),
                            }
                        };
                    })
                ).then(response => response.data);
            }

            /**
             * @param {number} subdomainId
             * @return {Promise<ProductParams>}
             */
            function productParams(subdomainId) {
                return $http.get('/tenant/product-params/' + subdomainId)
                    .then(response => response.data);
            }
        });
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('PushCampaignToolbarController', PushCampaignToolbarController);

    function PushCampaignToolbarController(
        $scope,
        campaignPushState,
        PermissionsService
    ) {
        const vm = this;

        const { actions, isPermissionAvailable, generateSegmentPermission } = PermissionsService;

        // public properties
        vm.state = campaignPushState;

        vm.state.canProcessNext = false;
        vm.state.view = 0;
        vm.state.finish = 0;
        vm.availableTabs = angular.copy(vm.state.views);

        // public methods
        vm.next = next;
        vm.back = back;
        vm.finish = finish;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;
        vm.isLastTab = isLastTab;

        /**
         * Initialization method
         */
        function init() {
            vm.showAudiences = isPermissionAvailable(generateSegmentPermission(actions.view));
        }

        /**
         * Switches to selected tab
         *
         * @param {number} tab
         */
        function goToTab(tab) {
            if (tab < vm.state.view) {
                vm.state.view = tab;
            }
        }

        /**
         * Returns tab class
         * @param {number} tab
         * @returns {string}
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view = getNextTab();
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.canProcessNext = false;
            vm.state.view = getPreviousTab();
        }

        /**
         * Saves content
         */
        function finish() {
            vm.state.finish++;
        }

        /**
         * Check if current tab is last one
         */
        function isLastTab() {
            let lastTabIndex = 0;
            Object.keys(vm.availableTabs).forEach(key => {
                if (vm.availableTabs[key] > lastTabIndex) {
                    lastTabIndex = vm.availableTabs[key];
                }
            });
            return vm.state.view === lastTabIndex;
        }

        /**
         * Returns available tabs
         * @param tabsSettings
         * @return {*}
         */
        function getAvailableTabs(tabsSettings) {
            const availableTabs = angular.copy(vm.state.views);

            if (!vm.showAudiences || !tabsSettings.allowTargeting) {
                delete availableTabs.AUDIENCE;
            }

            if (!tabsSettings.allowScreens) {
                delete availableTabs.SCREENS;
            }

            return availableTabs;
        }

        /**
         * Returns next tab code
         * @return {number}
         */
        function getNextTab() {
            const availableTabCodes = Object.values(vm.availableTabs);
            const currentValueIndex = availableTabCodes.indexOf(vm.state.view);
            return availableTabCodes[currentValueIndex + 1];
        }

        /**
         * Returns previous tab code
         * @return {number}
         */
        function getPreviousTab() {
            const availableTabCodes = Object.values(vm.availableTabs);
            const currentValueIndex = availableTabCodes.indexOf(vm.state.view);
            return availableTabCodes[currentValueIndex - 1];
        }

        $scope.$on('push-settings-changed', (event, options) => {
            vm.availableTabs = getAvailableTabs(options);
        });

        init();
    }
}());

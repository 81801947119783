(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('FindStationPopupController', FindStationPopupController);

    function FindStationPopupController(
        $uibModalInstance,
        data,
        ScreenDataService,
        ) {
        const vm = this;

        const {
            getStationsByName,
            getStationsByLocation,
        } = ScreenDataService;

        vm.data = data;

        vm.okHandler = okHandler;
        vm.cancelHandler = cancelHandler;
        vm.onStationNameChange = onStationNameChange;
        vm.onSearchTypeChange = onSearchTypeChange;
        vm.getStationProducts = getStationProducts;

        /**
         * On search type change handler
         */
        function onSearchTypeChange() {
            vm.selectedStation = null;
            if (!vm.findByLocation) {
                return;
            }

            if (vm.stationsNearby ) {
                vm.foundStations = vm.stationsNearby;
                vm.showError = !vm.foundStations.length;
                return;
            }

            getStationsByLocation(data.location).then(response => {
                vm.foundStations = response.plain();
                vm.stationsNearby = vm.foundStations;
                vm.showError = !vm.foundStations.length;
            }).catch(() => {
                vm.foundStations = [];
                vm.showError = true;
            });
        }

        /**
         * On station name change handler
         */
        function onStationNameChange() {
            const MIN_NAME_LENGTH = 3;
            if (vm.stationName.length < MIN_NAME_LENGTH) {
                return;
            }

            getStationsByName(vm.stationName).then(response => {
                vm.foundStations = response.plain();
                vm.showError = !vm.foundStations.length;
            }).catch(() => {
                vm.foundStations = [];
                vm.showError = true;
            });
        }

        function getStationProducts(station) {
            if (!station.productAtStop) {
                return '';
            }
            
            return station.productAtStop.map(product => product.name).join(',');
        }

        function okHandler() {
            $uibModalInstance.close(vm.selectedStation);
        }

        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

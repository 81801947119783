/**
 * @typedef BonusAccount
 *
 * @property {string} name
 * @property {string} description
 * @property {string} accountCurrency
 * @property {number} accountPrecision
 * @property {number} accountTypeRef
 * @property {number} accountValue
 * @property {number} accountValueAuthorized
 * @property {number} accountValueReserved
 * @property {string} createdAt
 * @property {string} guid
 * @property {number} id
 * @property {string} modifiedAt
 * @property {number} moneyToValueRatio
 * @property {number} status
 */

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ShareParkDataService', ShareParkDataService);

    function ShareParkDataService(
        $http,
        Restangular,
    ) {
        return {
            getCarParks,
            getCarParksList,
            getCarParkByExternalId,
            createCarPark,
            updateCarPark,
            deleteCarPark,
            getAllDevices,
            getDevices,
            getDeviceTypes,
            createDevice,
            updateDevice,
            deleteDevice,
            getOpeningHours,
            getCustomers,
            getCustomerData,
            getCustomerShopInfo,
            getParkingStatuses,
            getAllUserStatuses,
            createCustomer,
            deleteCustomer,
            getInvitations,
            createInvitation,
            updateInvitation,
            deleteInvitation,
            sendInvitations,
            getCapServiceProviders,
            getCapServiceProvidersAll,
            createCapServiceProvider,
            deleteCapServiceProvider,
            createCustomerBill,
            createServiceProviderBill,
            getCarParkCapacities,
            getCapSublets,
            createCapSublet,
            deleteCapSublet,
            getUserReservations,
            getUserReservationsByCap,
            getPermissions,
            refundTransaction,
            cancelTransaction,
            redeemAccountTypes,
            getUserProfiles,
            getUserProfilesCount,
            setUserProfile,
            getCronQueue,
            updateProfilesRemote,
            deleteCronQueueElement,
            getPurchasedItems,
            stopParkingProcedure,
            getIotEvents,
            setProfile,
            getProfileConfigs,
            setProfileConfig,
            getPermissionById,
            getPsp,
            findCustomers,
            findCustomersByParameter,
            findCustomersFlexible,
            payoutBonuses,
            getAccessSystemConfigs,
        };

        /**
         * Get list of car parks
         * @param {object} params
         * @param {number} params.page
         * @param {number} params.itemsPerPage
         * @return {*}
         */
        function getCarParks(params = {}) {
            return Restangular.all('carPark').getList(params);
        }

        /**
         * Get list of car parks
         * @return {*}
         */
        function getCarParksList() {
            return Restangular.all('carPark/list').getList();
        }

        function getAccessSystemConfigs() {
            return $http.get('/api/smart-campus/guest/access-system-configs').then(response => response.data);
        }

        function getCarParkByExternalId(externalId) {
            return Restangular.one('carPark/byExternalId/' + externalId).get();
        }

        function createCarPark(data) {
            return Restangular.all('carPark')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function updateCarPark(carPark, data) {
            data.append('_method', 'PUT');
            return carPark.customPOST(data, '', {}, { 'Content-Type': undefined });
        }

        function deleteCarPark(id, force) {
            return Restangular.one('carPark', id).remove({force});
        }

        function getAllDevices() {
            return Restangular.all('sharePark/allDevices').getList();
        }

        function getDevices(data) {
            return Restangular.all('sharePark/device').getList(data);
        }

        function getDeviceTypes() {
            return Restangular.all('sharePark/deviceTypes').getList();
        }

        function createDevice(data) {
            return Restangular.all('sharePark/device')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteDevice(id) {
            return Restangular.one('sharePark/device', id).remove();
        }

        function updateDevice(device, data) {
            data.append('_method', 'PUT');
            return device.customPOST(data, '', {}, { 'Content-Type': undefined });
        }

        function getOpeningHours() {
            return Restangular.all('carPark/getOpeningHours').getList();
        }

        function getCustomers() {
            return Restangular.all('sharePark/customers').getList();
        }

        function findCustomers(query, limit = null) {
            let url = 'sharePark/customers/find';

            if (query) {
                url += '/' + query;
            }

            if (limit) {
                url += '/' + limit;
            }

            return Restangular.all(url).getList();
        }

        /**
         * 
         * @param {string} parameterName
         * @param {string} query
         * @param {number} [limit]
         * @returns 
         */
        function findCustomersByParameter(parameterName, query, limit = '') {
            let url = `/api/sharePark/customers/findByParameter?searchParameter=${parameterName}&searchValue=${query}`;

            if (limit) {
                url += `&limit=${limit}`;
            }

            return $http.get(url).then(response => response.data.list);
        }

        /**
         *
         * @param params
         * @param {string} params.searchValue - first, last name, email, number plate
         * @param {number[]} params.profiles
         * @param {number} params.limit
         * @return {*}
         */
        function findCustomersFlexible(params = {}) {
            let url = 'sharePark/customers/find-flexible';

            let getQuery = '';
            for (let key in params) {
                if (getQuery !== '') {
                    getQuery += '&';
                } else {
                    getQuery += '?';
                }
                getQuery += key + "=" + encodeURIComponent(params[key]);
            }

            return Restangular.all(url + getQuery).getList();
        }

        function getCustomerShopInfo(customerId) {
            return Restangular.one('sharePark/customers/shop/' + customerId).get();
        }

        /**
         * Payout all available bonuses for customer
         * @param {{
         *    guid: string,
         *    redeemAccounts: string,
         *    adminName: string,
         *    comment: string
         * }} data
         * @returns {Promise<BonusAccount[]>}
         */
        function payoutBonuses(data) {
            return $http.post('/api/sharePark/customers/bonus-payout', data);
        }

        function getCustomerData(guid) {
            return Restangular.one('sharePark/customer/' + guid).get();
        }

        function getParkingStatuses(customerId) {
            return Restangular.all('sharePark/customers/parking-statuses/' + customerId).getList();
        }

        function getAllUserStatuses(data) {
            return Restangular.all('sharePark/statistics/allUserStatus').getList(data);
        }

        function createCustomer(data) {
            return Restangular.all('sharePark/customers')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteCustomer(guid) {
            return Restangular.one('sharePark/customers', guid).remove();
        }

        function getInvitations(data) {
            return Restangular.all('sharePark/invitation').getList(data);
        }

        function createInvitation(data) {
            return Restangular.all('sharePark/invitation')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function updateInvitation(invitation, data) {
            data.append('_method', 'PUT');
            return invitation.customPOST(data, '', {}, { 'Content-Type': undefined });
        }

        function deleteInvitation(id) {
            return Restangular.one('sharePark/invitation', id).remove();
        }

        function sendInvitations(id) {
            return Restangular.one('sharePark/sendInvitations/' + id).get();
        }

        function getCapServiceProviders() {
            return Restangular.all('carPark/getCapServiceProviders').getList();
        }

        function getCapServiceProvidersAll() {
            return Restangular.all('carPark/getCapServiceProvidersAll').getList();
        }

        function getPsp() {
            return Restangular.all('carPark/psp').getList();
        }

        function createCapServiceProvider(data) {
            return Restangular.all('carPark/serviceProvider')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteCapServiceProvider(serviceProviderId) {
            return Restangular.one(`carPark/serviceProvider/${serviceProviderId}`).remove();
        }

        function createCustomerBill(customer, selectedMonth, selectedYear) {
            return Restangular.all(`sharePark/customers/createBill`)
                .withHttpConfig({ responseType: 'arraybuffer' })
                .post({
                    customer,
                    selectedMonth,
                    selectedYear
                });
        }

        function createServiceProviderBill(data) {
            return Restangular.all(`sharePark/service-provider/createBill`)
                .withHttpConfig({ responseType: 'arraybuffer' })
                .post(data);
        }

        function getCarParkCapacities() {
            return Restangular.all('carPark/getCarParkCapacities').getList();
        }

        function getCapSublets() {
            return Restangular.all('carPark/getCapSublets').getList();
        }

        function createCapSublet(sublet) {
            return Restangular.all('carPark/sublet').post({ sublet });
        }

        function deleteCapSublet(subletId) {
            return Restangular.one(`carPark/sublet/${subletId}`).remove();
        }

        function getUserReservations(data) {
            return Restangular.all('sharePark/customers/reservations').post(data);
        }

        function getPermissions(data) {
            return Restangular.all('sharePark/customers/permissions').post(data);
        }

        function getUserReservationsByCap(data) {
            return Restangular.one('sharePark/customers/reservationsByCap').get(data);
        }

        function refundTransaction(transactionId, amount, comment = '', redeemAccountId = null) {
            const data = {
                transactionId,
                amount,
                comment,
            }

            if (redeemAccountId) {
                data.redeemAccountId = redeemAccountId;
            }

            return Restangular.all('sharePark/customers/refundTransaction')
                .withHttpConfig({transformRequest: angular.identity})
                .post(JSON.stringify(data), {}, {'Content-Type': 'application/json'});
        }

        function cancelTransaction(transactionId, comment = '') {
            const data = {
                transactionId,
                comment,
            }
            return Restangular.all('sharePark/customers/cancelTransaction').post(data);
        }

        function redeemAccountTypes() {
            return Restangular.one(`share-park/redeem/account-types`).get();
        }

        function getUserProfiles(data = {}) {
            return Restangular.all('sharePark/customers/profiles').getList(data);
        }

        function getUserProfilesCount() {
            return Restangular.all('sharePark/customers/profiles/count').getList();
        }

        function setProfile(data) {
            return Restangular.all('sharePark/customers/profiles')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function setUserProfile(guid, profileId) {
            return Restangular.one(`sharePark/customers/setProfile/${guid}/${profileId}`).get();
        }

        function getCronQueue(guid) {
            return Restangular.all(`sharePark/customers/cronQueue/${guid}`).getList();
        }

        function updateProfilesRemote(email) {
            return $http.get(`/api/sharePark/customers/updateProfilesRemote/${email}`);
        }

        function deleteCronQueueElement(id) {
            return Restangular.one(`sharePark/customers/cronQueue/${id}`).remove();
        }

        function getPurchasedItems() {
            return Restangular.one(`sharePark/purchased-items`).get();
        }

        function stopParkingProcedure(data) {
            return Restangular.all('sharePark/customers/stop-parking-procedure').post(data);
        }

        /**
         * 
         * @param {{
         *   limit: number,
         *   from: string,
         *   till: string,
         *   carParks: string,
         * }} params
         */
        function getIotEvents(params = {}) {
            return Restangular.one(`sharePark/iot/events`).get(params);
        }

        function getProfileConfigs() {
            return Restangular.all('sharePark/customers/profile-configs').getList();
        }

        function setProfileConfig(data) {
            return Restangular.all('sharePark/customers/profile-configs')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function getPermissionById(permissionId) {
            return Restangular.one(`sharePark/permission/${permissionId}`).get();
        }
    }
})();
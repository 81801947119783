(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newShareParkServiceProvider', {
            templateUrl: '/assets/views/share-park/customers/service-providers/new/new-service-provider.tpl.html',
            controller: NewShareParkServiceProviderController
        });

    function NewShareParkServiceProviderController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
    ) {
        const vm = this;

        vm.state = StatesFactory.ShareParkServiceProvidersStates.refresh();
        vm.state.type = $stateParams.type;
        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };

        vm.serviceProviders = [];

        vm.$onInit = init;

        vm.showInputError = showInputError;
        vm.onServiceProviderImage = onServiceProviderImage;
        vm.onServiceProviderImageDelete = onServiceProviderImageDelete;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.serviceProviderData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.CarParkServiceProvider();

            if (vm.isEditMode) {
                vm.oldImage = vm.serviceProviderData.imageRef || null;
            }

            ShareParkDataService.getCapServiceProviders()
                .then(response => {
                    vm.serviceProviders = response.plain().sort((a, b) => a.name.localeCompare(b.name));
                });

            ShareParkDataService.getPsp()
                .then(response => {
                    vm.pspList = response.plain();
                });
        }

        function onServiceProviderImage(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            vm.serviceProviderData.imageFile = imageFile;
            vm.serviceProviderData.imageRef = urlCreator.createObjectURL(imageFile);
        }

        function onServiceProviderImageDelete() {
            delete vm.serviceProviderData.imageFile;
            vm.serviceProviderData.imageRef = '';
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.newServiceProviderForm
                && vm.newServiceProviderForm[name].$dirty
                && vm.newServiceProviderForm[name].$invalid;
        }

        /**
         * On finish handler
         */
        function onFinish() {
            const formData = new FormData();
            const serviceProviderData = angular.copy(vm.serviceProviderData);

            if (vm.oldImage && (serviceProviderData.imageRef !== vm.oldImage)) {
                serviceProviderData.oldImage = vm.oldImage;
            }

            formData.append('serviceProviderImage', serviceProviderData.imageFile);
            formData.append('serviceProvider', angular.toJson(serviceProviderData));
            ShareParkDataService.createCapServiceProvider(formData)
                .then(onSuccess);
        }

        function onSuccess() {
            $state.go('app.shareParkServiceProviders', {
                paginationData: $stateParams.paginationData
            });
        }

        function canFinish() {
            vm.state.canFinish = vm.newServiceProviderForm
                && vm.newServiceProviderForm.$valid;
        }

        $scope.$watch(function() {
            return vm.newServiceProviderForm && vm.newServiceProviderForm.$valid;
        }, canFinish);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
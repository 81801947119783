(function() {
    'use strict';

    angular.module('beacon.app')
        .component('segmentDetailsTab', {
            templateUrl: '/assets/views/campaign/segment/new/tabs/segment-details/segment-details.tpl.html',
            controller: SegmentDetailsTab
        });

    function SegmentDetailsTab(
        $q,
        $stateParams,
        StorageFactory,
        ModelsFactory,
        UtilitiesService,
        LanguageService,
        SEGMENT_TYPES,
    ) {
        const vm = this;
        const { checkSegmentType } = UtilitiesService;

        const ONE_LANGUAGE_TYPES = [
            SEGMENT_TYPES.GEOFENCES,
            SEGMENT_TYPES.LOYALTIES,
            SEGMENT_TYPES.PREDEFINED,
            SEGMENT_TYPES.SALES,
            SEGMENT_TYPES.SEGMENTATION_GROUP,
            SEGMENT_TYPES.RANDOM,
        ];

        /**
         * Exports
         */
        vm.checkSegmentType = checkSegmentType;
        vm.isMultiLanguages = isMultiLanguages;

        init();

        function init() {
            vm.dataLoaded = false;
            vm.STORAGE = StorageFactory.Storage('Segment');
            vm.STORAGE.on('segmentData', (value) => {
                vm.segmentData = value;
            });
            let DataPromises = [];

            vm.langArray = vm.STORAGE.get('langArray');

            if (!vm.langArray) {
                DataPromises.push(loadLanguageData());
            }

            $q.all(DataPromises)
                .then(() => onDataLoaded())
                .catch((error) => console.error(error));
        }

        function isMultiLanguages () {
            return ONE_LANGUAGE_TYPES.indexOf(Number(vm.segmentData.segment_type_id)) === -1;
        }

        function loadLanguageData() {
            return LanguageService.getLanguages().then((response) => {
                vm.langArray = vm.STORAGE.set('langArray', response.plain());
            });
        }

        /**
         * When all data load
         * 1. Process loaded data
         * 2. Set model
         * 3. Update view
         */
        function onDataLoaded() {
            processLanguageData();

            let segment = null;
            let segmentTypeId = Number(vm.segmentData.segment_type_id);
            switch (segmentTypeId) {
                case SEGMENT_TYPES.FEEDBACK_ATTRIBUTES:
                    segment = new ModelsFactory.SegmentFeedbackAttributes();
                    break;

                case SEGMENT_TYPES.GEOFENCES:
                    segment = new ModelsFactory.SegmentGeofences();
                    break;

                case SEGMENT_TYPES.NUMBER_RANGES:
                    segment = new ModelsFactory.SegmentNumberRanges();
                    break;

                case SEGMENT_TYPES.CALCULATED:
                    segment = new ModelsFactory.SegmentCalculated();
                    break;

                case SEGMENT_TYPES.PREFERENCES:
                    segment = new ModelsFactory.SegmentPreferences();
                    break;

                case SEGMENT_TYPES.LOYALTIES:
                    segment = new ModelsFactory.SegmentLoyalties();
                    break;

                default:
                    break;
            }

            if (segment) {
                setSegmentMessage(segment, segmentTypeId);
            }

            vm.dataLoaded = true;
        }

        function processLanguageData() {
            if (!vm.segmentData.language_id) {
                vm.segmentData.language_id = vm.langArray[0].id;
            }
        }

        function setSegmentMessage(segment, segmentTypeId) {
            if (!vm.segmentData.message) {
                vm.segmentData.message = {};
            } else {
                vm.segmentData.message = angular.fromJson(vm.segmentData.message);
            }

            if (!isMultiLanguages()) {
                vm.segmentData.message = Object.assign(
                    angular.copy(segment),
                    vm.segmentData.message || {}
                );
                return;
            }

            for (let lang of vm.langArray) {
                vm.segmentData.message[lang.id] = Object.assign(
                    angular.copy(segment),
                    vm.segmentData.message[lang.id] || {}
                );
            }
        }
    }
})();

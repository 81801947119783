(function() {
    'use strict';

    angular.module('beacon.app')
        .component('billingConfigurations', {
            templateUrl: '/assets/views/share-park/operations/billing/billing-configurations.tpl.html',
            controller: BillingConfigurationsController
        });

    function BillingConfigurationsController(
        $state,
        PermissionsService,
        BillingConfigurationDataService,
        PopupService,
        DateHelper
    ) {
        const vm = this;

        const { actions, generateBillingConfigurationPermission } = PermissionsService;

        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

        vm.updateCounter = 0;
        vm.billingConfigurationsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'billing-configuration-name',
                    width: '30',
                    title: 'name',
                },

                {
                    name: 'STARTED_AT',
                    width: '25',
                    title: item => item.start_at.format(DATE_TIME_FORMAT),
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteBillingConfiguration,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'copyBtn',
                        callback: copyBillingConfiguration,
                        permissionAction: actions.create
                    },
                    {
                        class: 'editBtn',
                        callback: editBillingConfiguration,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName,
        };

        vm.createBillingConfiguration = createBillingConfiguration;
        vm.exportCsv = exportCsv;

        /**
         * Create new billing configuration
         */
        function createBillingConfiguration() {
            $state.go('app.addBillingConfiguration');
        }

        /**
         * Delete billing configuration
         * @param $event
         * @param { Object } billingConfiguration
         */
        function deleteBillingConfiguration($event, billingConfiguration) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_DELETE_THIS_BILLING_CONFIGURATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                BillingConfigurationDataService.deleteBillingConfiguration(billingConfiguration.id)
                    .then(() => {
                        vm.updateCounter++;
                    })
            });
        }

        /**
         * Edit billing configuration
         * @param $event
         * @param { Object } billingConfiguration
         */
        function editBillingConfiguration($event, billingConfiguration) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editBillingConfiguration', { data: billingConfiguration });
        }

        /**
         * Copy billing configuration
         * @param $event
         * @param { Object } billingConfiguration
         */
        function copyBillingConfiguration($event, billingConfiguration) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = angular.copy(billingConfiguration);
            data.copy = true;
            delete data.id;

            $state.go('app.addBillingConfiguration', { data });
        }

        /**
         * Updates billing configurations list
         * @param { number } page
         * @param { number } itemsPerPage
         */
        function updateElementsList(page, itemsPerPage) {
            let data = { page, itemsPerPage };

            BillingConfigurationDataService.getBillingConfigurations(data)
                .then(response => {
                    vm.billingConfigurations = response.map(billingConfiguration => {
                        billingConfiguration.start_at = moment.utc(billingConfiguration.start_at).local();
                        return billingConfiguration;
                    });
                    vm.billingConfigurationsCount = response.count;
                })
        }

        /**
         * Generates permission name
         * @param button
         * @return { String }
         */
        function generatePermissionName(button) {
            return generateBillingConfigurationPermission(button.permissionAction);
        }


        function exportCsv() {
            const fileName = 'billingConfigurations.csv';
            const a = document.createElement('a');
            document.body.appendChild(a);
            BillingConfigurationDataService.exportBillnigConfigurations().then(configurations => {
                const file = new Blob([configurations], {type: 'text/csv'});
                a.href = window.URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            });
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltiesSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/loyalties/loyalties.tpl.html',
            controller: LoyaltiesSegmentType,
            bindings: {
                langArray: '<',
                segmentData: '=',
            }
        });

    function LoyaltiesSegmentType(
        $scope,
        segmentAddState,
        UtilitiesService,
        LoyaltyCampaignService,
        SegmentHelper,
        SEGMENT_STATUS,
    ) {
        const vm = this;

        const DEFAULT_RANGE_LIMITS = {
            minValue: 0,
            maxValue: 100,
            editable: true
        };

        vm.DYNAMIC_RANGE_TYPE = 0;
        vm.PREDEFINED_RANGE_TYPE = 1;
        vm.SEGMENT_STATUS_ACTIVE = SEGMENT_STATUS.ACTIVE;
        vm.SEGMENT_STATUS_INACTIVE = SEGMENT_STATUS.INACTIVE;
        vm.counterRangesValid = true;
        vm.accountRangesValid = true;

        // public methods
        vm.selectLoyaltyCampaignCallback = selectLoyaltyCampaignCallback;
        vm.showTierStatusCheckbox = showTierStatusCheckbox;
        vm.counterRangeChanged = counterRangeChanged;
        vm.accountRangeChanged = accountRangeChanged;
        vm.changeSegmentStatus = SegmentHelper.changeSegmentStatus;

        // Definition
        vm.state = segmentAddState;

        vm.errorMsg = null;

        init();

        // public methods

        /**
         * Handles counter ranges changes
         * @param rangeType
         * @param {boolean} valid
         */
        function counterRangeChanged(rangeType, valid) {
            if (parseInt(rangeType) === vm.PREDEFINED_RANGE_TYPE) {
                vm.segmentData.message.params.counterRanges = vm.counterRanges;
                delete vm.segmentData.message.params.counterLimits;
            } else {
                if (vm.segmentData.message.params.counterRanges) {
                    delete vm.segmentData.message.params.counterRanges;
                }
                vm.segmentData.message.params.counterLimits = vm.counterLimits;
            }
            if (angular.isDefined(valid)) {
                vm.counterRangesValid = valid;
                vm.state.canFinish = canFinish();
            }
        }

        /**
         * Handles account ranges changes
         * @param rangeType
         * @param {boolean} valid
         */
        function accountRangeChanged(rangeType, valid) {
            if (parseInt(rangeType) === vm.PREDEFINED_RANGE_TYPE) {
                vm.segmentData.message.params.accountRanges = vm.accountRanges;
                delete vm.segmentData.message.params.accountLimits;
            } else {
                if (vm.segmentData.message.params.accountRanges) {
                    delete vm.segmentData.message.params.accountRanges;
                }
                vm.segmentData.message.params.accountLimits = vm.accountLimits;
            }
            if (angular.isDefined(valid)) {
                vm.accountRangesValid = valid;
                vm.state.canFinish = canFinish();
            }
        }

        /**
         * Handles loyalty campaign select
         */
        function selectLoyaltyCampaignCallback () {
            vm.segmentData.definitionRef = vm.selectedLoyaltyCampaign.externalId;
            if (!vm.segmentData.title) {
                vm.segmentData.title = vm.selectedLoyaltyCampaign.name;
            }
            if (!vm.segmentData.description && !!vm.selectedLoyaltyCampaign.description) {
                let description = vm.selectedLoyaltyCampaign.description;
                description = UtilitiesService.stripHtmlToText(description[Object.keys(description)[0]]);
                vm.segmentData.description = description;
            }
            vm.state.canFinish = canFinish();
        }

        /**
         * Checks if tier status checkbox must be shown
         * @return {boolean}
         */
        function showTierStatusCheckbox() {
            if (angular.isDefined(vm.selectedLoyaltyCampaign)) {
                return !!vm.selectedLoyaltyCampaign.loyaltyRewardType.rewardCurrentTierType;
            }
            return false;
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {
            LoyaltyCampaignService.getList().then(response => {
                vm.loyaltyCampaigns = response.plain();
                if(angular.isDefined(vm.segmentData.definitionRef)) {
                    vm.selectedLoyaltyCampaign = vm.loyaltyCampaigns.find(loyaltyCampaign => loyaltyCampaign.externalId === parseInt(vm.segmentData.definitionRef));
                }
            })
                .catch(console.error.bind(console));

            vm.counterRanges = (vm.segmentData.message.params && vm.segmentData.message.params.counterRanges) ? vm.segmentData.message.params.counterRanges : [];
            vm.accountRanges = (vm.segmentData.message.params && vm.segmentData.message.params.accountRanges) ? vm.segmentData.message.params.accountRanges : [];
            vm.counterLimits = (vm.segmentData.message.params && vm.segmentData.message.params.counterLimits) ?
                vm.segmentData.message.params.counterLimits : angular.copy(DEFAULT_RANGE_LIMITS);
            vm.accountLimits = (vm.segmentData.message.params && vm.segmentData.message.params.accountLimits) ?
                vm.segmentData.message.params.accountLimits : angular.copy(DEFAULT_RANGE_LIMITS);

            vm.activateSegment = vm.segmentData.status === vm.SEGMENT_STATUS_ACTIVE;
        }

        function onFinish(event, option) {
            if (vm.segmentData.status === vm.SEGMENT_STATUS_ACTIVE) {
                vm.segmentData.message.wasActivated = true;
            }
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: angular.copy(vm.segmentData)
                });
            }
        }

        function canFinish() {
            return !!vm.segmentData.title
                && !!vm.segmentData.definitionRef
                && vm.counterRangesValid
                && vm.accountRangesValid;
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.title;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.description;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
    }
})();

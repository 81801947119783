(function() {
    'use strict';

    angular.module('lineRoute')
        .component('lineRouteCanvasDrawer', {
            controller,
            templateUrl: '/assets/views/line-route/line-route-canvas/line-route-canvas-drawer/line-route-canvas-drawer.tpl.html',
            bindings: {
                config: '<'
            },
        });

    function controller($scope,
                        $element,
                        $timeout,
                        $interval,
                        Canvas,
                        RouteHorizontal,
                        RouteVertical
    ) {
        const vm = this;

        vm.defaultConfig = {
            stations: [],
        };

        vm.element = $element[0];
        vm.canvasElement = null;
        vm.isHorizontal = null;

        $timeout(init);

        /**
         * Controller initialization
         */
        function init() {
            draw();
            setWatchers();
        }

        /**
         * Draw canvas
         */
        function draw() {
            vm.canvasElement = vm.element.querySelector('canvas');
            const canvas = new Canvas(vm.canvasElement);

            const size = {
                width: vm.element.clientWidth,
                height: vm.element.clientHeight,
            };

            if (!size.width || !size.height) {
                return;
            }

            vm.aspectRatio = size.width / size.height;
            vm.isHorizontal = vm.aspectRatio >= 1;

            vm.canvasElement.width = size.width;
            vm.canvasElement.height = size.height;

            const BIGGER_SIDE = 1920;
            vm.canvasElement.width = vm.isHorizontal ? BIGGER_SIDE : BIGGER_SIDE * vm.aspectRatio;
            vm.canvasElement.height = vm.isHorizontal ? BIGGER_SIDE / vm.aspectRatio : BIGGER_SIDE;

            const {
                flipHorizontal,
                flipVertical,
                stations,
                stationProducts,
                stationDeparture,
                stationsPerLine,
                textAngle,
                textAngleOffset,
            } = vm.config;

            const PADDING_SHORT_SIDE = 10;
            const PADDING_LONG_SIDE = 2;

            const config = {
                flipHorizontal,
                flipVertical,
                width: vm.canvasElement.width,
                height: vm.canvasElement.height,
                stations,
                stationProducts,
                stationDeparture,
                limitPerLine: stationsPerLine,
                distance: 10, // distance between lines
                textAngle,
                textAngleOffset,
                fontSize: 18,
                stationLabelPadding: 25, // distance between station point and label
                paddingX: vm.isHorizontal ? PADDING_SHORT_SIDE: PADDING_LONG_SIDE,
                paddingY: vm.isHorizontal ? PADDING_LONG_SIDE : PADDING_SHORT_SIDE,
            };

            const route = vm.isHorizontal
                ? new RouteHorizontal(config)
                : new RouteVertical(config);

            canvas.add(route);
        }

        /**
         * Watchers setter
         */
        function setWatchers() {
            /**
             * Redraw the route on config change
             */
            $scope.$watch(
                () => vm.config,
                () => draw(),
                true
            );

            /**
             * Redraw the route every minute (at 0 second)
             */
            $scope.$watch(
                () => new Date().getMinutes(),
                () => draw(),
            );

            /**
             * Redraw the route on change size of parent element
             */
            $scope.$watchGroup([
                () => vm.element.clientWidth,
                () => vm.element.clientHeight
            ], () => $timeout(draw));
        }
    }
})();
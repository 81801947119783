(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PageDataService', pageDataService);

    function pageDataService(Restangular, $state, $auth) {
        return {
            getPages,
            page,
            create,
            update,
            deletePage,
            pageTypes,
            updateOrder,
            contentPages,
            getLayouts,
            downloadLayouts,
            uploadLayouts,
            getPlaylistContents,
        };

        // Public methods
        /**
         * Returns pages list
         *
         * @param {object} data
         */
        function getPages(data) {
            return Restangular.all('pages').getList(data);
        }

        /**
         * Returns simple page by it`s id
         *
         * @param {object} id
         */
        function page(id) {
            return Restangular.one('page', id).get();
        }

        /**
         * Creates page
         *
         * @param {object} data
         */
        function create(data) {
            return Restangular.all('page')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updates page
         *
         * @param {object} page
         * @param {object} data
         */
        function update(page, data) {
            return page.customPOST(data, '', {}, { 'Content-Type': undefined, '_method': 'PUT' });
        }

        // /**
        //  * Deletes content by it`s id
        //  *
        //  * @param {object} id
        //  */
        function deletePage(id) {
            return Restangular.one('page', id).remove();
        }

        /**
         * Returns page types list
         */
        function pageTypes(data) {
            return Restangular.all('pages/types').getList(data);
        }

        /**
         * Updates pages order
         *
         * @param {object} page
         */
        function updateOrder(data) {
            console.info('order data:', data);
            return Restangular.all('pages/order')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Returns pages list
         *
         * @param {object} data
         */
        function contentPages(data) {
            return Restangular.all('content/pages').getList(data);
        }

        /**
         * Returns layouts list
         *
         * @returns {Array} Layouts
         */
        function getLayouts() {
            return Restangular.all('layouts').getList();
        }

        /**
         * Dowload layouts JSON
         */
        function downloadLayouts() {
            return Restangular.one('layouts/download').get();
        }

        /**
         * Upload layouts JSON
         * @param data
         * @returns {*}
         */
        function uploadLayouts(data) {
            return new Restangular.one('layouts/upload').withHttpConfig({transformRequest: angular.identity})
                .customPOST(data, '', undefined, {'Content-Type': undefined});
        }

        function getPlaylistContents(pageId, contentTypeIds) {
            return Restangular.one('playlist/contents').get({pageId, contentTypeIds});
        }
    }
})();

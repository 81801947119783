(function () {
    'use strict';

    angular.module('beacon.app')
        .service('ShareParkReservationsService', ShareParkReservationsService);

    function ShareParkReservationsService(
        $http,
    ) {
        return {
            getAllReservationStatuses,
        };

        function getAllReservationStatuses(data) {
            return $http.post('/api/sharePark/reservations', data);
        }


    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('StorageFactory', StorageFactory);

    function StorageFactory() {
        const StorageStack = {};

        /**
         * Storage model
         * @param  {String} name
         * @param  {Boolean} force
         * @return {StorageStack}
         */
        const StorageModel = function(name, force = false) {
            if (this instanceof StorageModel) {
                if ((name in StorageStack) && !force) {
                    throw new Error('Storage with name (' + name + ') already exists');
                }

                StorageStack[name] = this;
                this.__WatchersStack = {};
            } else {
                if (typeof name !== 'string') {
                    throw new TypeError('The \'name\' argument should be the string');
                }

                return StorageStack[name];
            }
        };

        StorageModel.prototype.set = function(property, value) {
            this[property] = value;

            if (property in this.__WatchersStack) {
                this.__WatchersStack[property] = this.__WatchersStack[property]
                    .filter((object) => {
                        object.fn(value);

                        return object.type === 'on';
                    });

                if (this.__WatchersStack[property].length === 0) {
                    delete this.__WatchersStack[property];
                }
            }

            return value;
        };

        StorageModel.prototype.get = function(property) {
            return this[property];
        };

        StorageModel.prototype.on = function(property, fn) {
            if (property in this) {
                fn(this[property]);
            }

            if (property in this.__WatchersStack) {
                this.__WatchersStack[property].push({ type: 'on', fn });
            } else {
                this.__WatchersStack[property] = [{ type: 'on', fn }];
            }
        };

        StorageModel.prototype.once = function(property, fn) {
            if (property in this) {
                return fn(this[property]);
            }

            if (property in this.__WatchersStack) {
                this.__WatchersStack[property].push({ type: 'once', fn });
            } else {
                this.__WatchersStack[property] = [{ type: 'once', fn }];
            }
        };

        StorageModel.prototype.dropWatchers = function() {
            this.__WatchersStack = {};
        };

        StorageModel.prototype.remove = function(property) {
            delete this[property];
            delete this.__WatchersStack[property];
        };

        return {
            Storage: StorageModel
        };
    }

})();

(function () {
    'use strict';

    /**
     * @deprecated - Please, use ImageUploadFactory
     */
    angular.module('beacon.app')
        .service('ImageProcessorService', ImageProcessorService);

    function ImageProcessorService(
        ImageApiService,
        $q,
    ) {
        let initialImageUrl = null;
        let initialUploadMode = null
        let initialImageDeleted = false;
        let imageToUpload = null;

        /**
         * @param {string} imageUrl 
         * @param {string} uploadMode
         */
        function initImage(imageUrl, uploadMode) {
            initialImageUrl = imageUrl;
            initialUploadMode = uploadMode;
            imageToUpload = null;
            initialImageDeleted = false;
        }

        /**
         * @param {Blob} image
         * @param {string} image.name
         */
        function setImage(image) {
            imageToUpload = image;

            if (!image) {
                initialImageDeleted = true;
            }
        }

        function run() {
            return $q.all([
                imageToUpload
                    ? ImageApiService.uploadImage(imageToUpload, initialUploadMode).then(response => response.data)
                    : $q.resolve(initialImageDeleted ? null : initialImageUrl),
                initialImageUrl && initialImageDeleted
                    ? ImageApiService.deleteImage(initialImageUrl)
                    : $q.resolve(null),
            ]).then(response => {
                let imageUrl = response.filter((itemResponse) => itemResponse)[0];

                return $q.resolve(typeof imageUrl === 'string' ? imageUrl : null)
            });
        }

        return {
            initImage,
            setImage,
            run,
        }
    }
})();

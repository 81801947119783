(function() {
    'use strict';

    angular.module('beacon.app')
        .service('Unity3dModelsDataService', Unity3dModelsDataService);

    function Unity3dModelsDataService(
        $http,
        Restangular,
    ) {
        return {
            getAll,
            upload,
            create,
            update,
            remove,
        };

        /**
         * Get list of car parks
         * @return {Promise<any>}
         */
        function getAll() {
            return Restangular.all('content/unity-3d-models').getList();
        }

        /**
         * @param {Blob} file
         * @param {ModelsFactory.Unity3dModel} unity3dModel
         * @returns {Promise<any>}
         */
        function upload(file, unity3dModel) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', 'name');
            return Restangular.all('content/unity-3d-models/upload/' + unity3dModel.id)
                .post(formData, null, {'Content-Type': undefined});
        }

        /**
         * @param unity3dModel
         * @returns {Promise<any>}
         */
        function create(unity3dModel) {
            return Restangular.all('content/unity-3d-models').post(unity3dModel);
        }

        /**
         * @param unity3dModel
         * @returns {Promise<any>}
         */
        function update(unity3dModel) {
            return Restangular.all('content/unity-3d-models/' + unity3dModel.id).customPUT(unity3dModel);
        }

        /**
         * @param unity3dModel
         * @returns {Promise<any>}
         */
        function remove(unity3dModel) {
            return Restangular.all('content/unity-3d-models/' + unity3dModel.id).customDELETE();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('StatisticsController', StatisticsController);

    function StatisticsController($stateParams, statisticsTabState, ContentDataService, CONTENT_TYPES, PermissionsService) {
        const vm = this;

        vm.state = statisticsTabState;

        // public properties
        vm.content = [];
        vm.selectedContents = [];
        vm.contentGroupsArray = [];
        vm.contentTypes = CONTENT_TYPES;


        /**
         * Initialization method
         */
        function init() {
            ContentDataService.contents({'contentTypeId': `${vm.contentTypes.QUIZ},${vm.contentTypes.FEEDBACK}`})
                .then(function(response) {
                    vm.content = response || [];
                    vm.content.forEach(function(item) {
                        item.title = JSON.parse(item.title);
                        item.message = JSON.parse(item.message);

                        if (item.language_sensitive_link) {
                            item.web_link = JSON.parse(item.web_link);
                        }
                        item.atitle = item.title[item.language_id];
                    });

                    function compare(a, b) {
                        if (a.atitle < b.atitle) {
                            return -1;
                        }
                        if (a.atitle > b.atitle) {
                            return 1;
                        }
                        return 0;
                    }

                    vm.content = vm.content.sort(compare);
                    var selectedData = angular.copy($stateParams.data);
                    if (selectedData) {
                        vm.selectedContents = [];
                        vm.selectedContents.push(selectedData);
                        vm.state.view = 1;
                    }
                });

            if (PermissionsService.isContentGroupMenuItemVisible()) {
                ContentDataService.contentGroups()
                    .then((response) => {
                        vm.contentGroupsArray = response;

                        vm.contentGroupsArray.forEach((item) => {
                            item.title = angular.fromJson(item.title);
                        });
                    });
            }
        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('elementDestination', {
            templateUrl: '/screen/views/components/timetable-hafas/elements/element-destination/element-destination.tpl.html',
            controller: ElementDestinationController,
            bindings: {
                // TODO: get rid of unnecessary bindings like viaStops and viaStopsSeparator
                // these props should be present in the "element" input. I have no time for investigations
                element: '<',
                journey: '<',
                viaStops: '<',
                viaStopsSeparator: '<',
                textScale: '<',
            }
        });

    /**
     * @property {TimetableHafasElement} element
     * @property {Journey} journey
     * @property {number} viaStops - the amount of via-stops
     * @property {string} viaStopsSeparator
     * @property {number} textScale - additional scale (IMO, based on the additional scale algorithm)
     *
     * @constructor
     */
    function ElementDestinationController() {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            _setVariables();
        }

        /**
         * <wbr> tag is used to control the place, where we would like to have a line break
         * @private
         */
        function _setVariables() {
            $ctrl.destination = $ctrl.journey.direction
                .replace(/\//g, '/\u200B');

            const viaStopsAvailable = $ctrl.journey.viaStops && $ctrl.journey.viaStops.length;

            if (viaStopsAvailable) {
                $ctrl.viaStopsLine = $ctrl.journey
                    .getViaStops($ctrl.viaStopsSeparator)
                    .replace(/\//g, '/\u200B');
            }
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('campaignMobileState', function() {
            return {
                views: {
                    CONTENT: 0,
                    CAMPAIGN_DETAILS: 1,
                    SECTIONS: 2,
                    AUDIENCE: 3
                }
            };
        })
        .controller('MobileCampaignController', MobileCampaignController);

    function MobileCampaignController($state, $stateParams,
                                      PopupService, CampaignDataService, PermissionsService, MOBILE_CAMPAIGN_INFINITE_DATE) {
        const vm = this;
        const DATE_FORMAT = 'MMMM D, YYYY (HH:mm)';
        const { actions, generateCampaignMobilePermission } = PermissionsService;

        // public methods
        vm.deleteCampaign = deleteCampaign;
        vm.updateCampaigns = updateCampaigns;
        vm.newCampaign = newCampaign;
        vm.updateCampaign = updateCampaign;
        vm.copyCampaign = copyCampaign;
        vm.getCampaignsAudience = getCampaignsAudience;
        vm.getCampaign = getCampaign;
        vm.searchQuery = '';

        init();

        // public properties
        vm.campaigns = [];
        vm.createCampaignMobilePermission = generateCampaignMobilePermission(actions.create);
        vm.totalItems = $stateParams.paginationData ? $stateParams.paginationData.count : 0;
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '35',
                    title: 'name'
                },
                {
                    name: 'CONTENTS',
                    class: 'contents',
                    width: '10',
                    title: item => item.contents.length,
                },
                {
                    name: 'START_DATE',
                    class: 'startDate',
                    width: '15',
                    title: item => moment.utc(item.started_at).local().format(DATE_FORMAT)
                },
                {
                    name: 'END_DATE',
                    class: 'endDate',
                    width: '15',
                    title: item => MOBILE_CAMPAIGN_INFINITE_DATE.getTime() === new Date(item.finished_at).getTime()
                        ? '-' : moment.utc(item.finished_at).local().format(DATE_FORMAT)
                },
                {
                    name: 'LIVE',
                    class: 'live',
                    width: '10',
                    title: item => item.additional_data && item.additional_data.testCampaign === 0 ? 'LIVE' : '',
                    translate: true,
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: vm.deleteCampaign,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'copyBtn',
                        callback: vm.copyCampaign,
                        permissionAction: actions.create
                    },
                    {
                        class: 'editBtn',
                        callback: vm.updateCampaign,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: vm.updateCampaigns,
            generatePermissionName: generatePermissionName
        };

        // private methods

        /**
         * Updates list of campaigns
         *
         * @param {Number} page
         * @param {Number} itemsPerPage
         */
        function updateCampaigns(page, itemsPerPage) {
            let data = {
                page,
                itemsPerPage,
                type: 0,
                searchQuery: vm.searchQuery
            };

            CampaignDataService.getCampaigns(data)
                .then((response) => {
                    vm.campaigns = response;
                    vm.totalItems = response.count;
                    vm.campaigns.forEach((item) => {
                        item.additional_data = item.additional_data
                            ? angular.fromJson(item.additional_data)
                            : {};

                        item.contents.forEach((content) => {
                            content.title = JSON.parse(content.title);
                            content.message = JSON.parse(content.message);
                            content.data = JSON.parse(content.data);
                        });
                    });
                })
                .catch(console.error.bind(console));
        }

        /**
         * Removes campaign
         *
         * @param {MouseEvent} $event
         * @param {object} campaignItem
         */
        function deleteCampaign($event, campaignItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CAMPAIGN',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                CampaignDataService.deleteCampaign(campaignItem.id)
                    .then(() => {
                        vm.totalItems -= 1;
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Get list of campaign audience
         */
        function getCampaignsAudience() {
            CampaignDataService.getCampaignsAudience('MobileCampaign')
                .then((response) => {})
                .catch(console.error.bind(console));
        }

        /**
         * Get campaign info by it`s id
         */
        function getCampaign(id) {
            CampaignDataService.getCampaign(id)
                .then((response) => {})
                .catch(console.error.bind(console));
        }

        /**
         * Create new campaign button handler
         */
        function newCampaign() {
            let itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            $state.go('app.addCampaign', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Edit campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function updateCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            let page = angular.element('.pagination-page.active a')[0].innerHTML;
            let itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
            let data = item.plain();

            $state.go('app.editCampaign', {
                data,
                paginationData: {
                    page: Number(page),
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Copy campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function copyCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_CAMPAIGN',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }, function() {
                let itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
                CampaignDataService.getLocations({'campaignID': item.id})
                    .then((response) => {
                        let data = item.plain();
                        data.locationsIDs = response.plain();

                        $state.go('app.addCampaign', {
                            data,
                            paginationData: {
                                page: 1,
                                itemsPerPage: Number(itemsPerPage),
                                count: vm.totalItems
                            }
                        });
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Generates permission name for each button on campaign mobile accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateCampaignMobilePermission(button.permissionAction);
        }
    }
}());

(function () {
    'use strict';

    angular.module('beacon.app')
        .component('newProfileRules', {
            templateUrl: '/assets/views/share-park/customers/profile-rules/new/new-profile-rules.tpl.html',
            controller: NewProfileRulesController
        });

    function NewProfileRulesController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
        ProfileRulesService,
        StorageFactory,
        PopupService
    ) {
        const vm = this;

        vm.STORAGE = StorageFactory.Storage('Main');
        vm.$onInit = init;
        vm.state = StatesFactory.ShareParkProfileRulesStates.refresh();
        vm.state.type = $stateParams.type;
        vm.profileRulesData = [];
        vm.version = null;
        const { getUserProfiles } = ShareParkDataService;
        const DELETED_STATUS = 3;
        vm.externalIdsSearch = externalIdsSearch;
        vm.numberOfColumn = 3;
        vm.incrementColumn = incrementColumn;
        vm.decrementColumn = decrementColumn;
        vm.searchValues = [];
        vm.addNewRole = addNewRole;
        vm.changeSearchLabel = changeSearchLabel;
        vm.getSimulate = getSimulate;
        vm.description = '';
        vm.downloadCSV = downloadCSV;
        vm.profileRulesCSV = null;
        vm.isDisabledSimulate = false;


        function init() {
            vm.isEditMode = vm.state.type === 'edit';
            if (vm.isEditMode) {
                vm.profileRulesData = $stateParams.data;
            } else {
                vm.profileRulesData = {
                    version: 1,
                    status: 1,
                    rules: [new ModelsFactory.ProfileRules()],
                };
                vm.profileRulesData.rules[0].tenant = vm.STORAGE.currentUserInfo.subdomain.tenant.id;
                vm.profileRulesData.rules[0].configs.isNew = true;
            }
            vm.numberOfColumn = vm.profileRulesData.rules[0].amountOfWildcards;
            vm.description = vm.profileRulesData.rules[0].description || '';
            getUserProfiles().then(response => {
                vm.profiles = response.plain().filter(profile => profile.status !== DELETED_STATUS);
                vm.externalIds = vm.profiles.map(profile => profile.externalId);
            })
            for (let i = 1; i <= 10; i++) {
                vm.searchValues.push(`searchValue${i}`);
            }

            vm.profileRulesData.rules.map(rule => {
                if (!rule.name) {
                    rule.configs = {};
                    rule.configs.isNew = true;
                }
            })
        }

        function externalIdsSearch(query, rule) {
            var results = query
                ? vm.externalIds.filter(id => id.toLowerCase().includes(query.toLowerCase()))
                : vm.externalIds;

            setTimeout(() => {
                vm.profiles.map(profile => {
                    if (profile.externalId === rule.profileExtId) rule.profileId = profile.id;
                })
            }, 100)

            return results;
        }

        function incrementColumn() {
            if (vm.numberOfColumn < 10) vm.numberOfColumn++;
            vm.profileRulesData.rules.map(rule => {
                rule.amountOfWildcards = vm.numberOfColumn;
            })
        }

        function decrementColumn() {
            if (vm.numberOfColumn != 0) vm.numberOfColumn--;
            vm.profileRulesData.rules.map(rule => {
                rule.amountOfWildcards = vm.numberOfColumn;
            })
        }

        function changeSearchLabel(index) {
            index ? vm.selectedSearchLabel = index : vm.selectedSearchLabel = null;
            setSearchValueLable();
        }

        function getSimulate() {
            let simulationTimestamp = null;

            PopupService.showConfirmationPopup({
                text: 'THIS_PROCESS_TAKES_LONG_PLEASE_WAIT_UNTIL_IT_IS_FINISHED',
                okButtonText: 'OK',
                cancelButtonText: 'CANCEL'
            }).then(_ => {
                ProfileRulesService.getSimulateAutoConfig(vm.profileRulesData.version).then(response => {
                    simulationTimestamp = new Date(response.data[0].simulationTimestamp);
                    ProfileRulesService.runSimulateAutoConfig(vm.profileRulesData.version);
                }).then(result => {
                    vm.isDisabledSimulate = true;
                    let waiting = setInterval(() => {
                        ProfileRulesService.getSimulateAutoConfig(vm.profileRulesData.version).then(response => {
                            if (simulationTimestamp < new Date(response.data[0].simulationTimestamp)) {
                                vm.profileRulesCSV = new Blob(['sep=,\n' + response.data[0].resultCsv], { type: 'text/csv' })
                                clearTimeout(waiting);
                                vm.isDisabledSimulate = false;
                            }
                        })
                    }, 5000)
                });
            })
        }

        function downloadCSV() {
            if (vm.profileRulesCSV) {
                const a = document.createElement('a');
                a.download = 'Version' + vm.profileRulesData.version + '.csv';
                a.href = URL.createObjectURL(vm.profileRulesCSV);
                a.click();
                URL.revokeObjectURL(a.href);
            }
        }

        function setSearchValueLable() {
            vm.profileRulesData.rules.forEach(rule => {
                for (let i = 1; i <= 10; i++) {
                    rule[`searchValue${i}Label`] = vm.profileRulesData.rules[0][`searchValue${i}Label`];
                }
            })
        }

        function addNewRole() {
            const newRules = new ModelsFactory.ProfileRules();
            newRules.configs.isNew = true;
            newRules.version = vm.profileRulesData.rules[0].version;
            newRules.status = vm.profileRulesData.rules[0].status;
            newRules.tenant = vm.profileRulesData.rules[0].tenant;

            vm.profileRulesData.rules.push(newRules);
            setSearchValueLable();

            setTimeout(() => {
                var block = document.getElementById('fixTableHead');
                block.scrollTop = 99999;
            }, 0)

        }

        function onFinish() {
            vm.profileRulesData.rules.map(rule => {
                rule.description = vm.description;
            })

            ProfileRulesService.saveProfileRulesList(vm.profileRulesData.rules).then(response => {
                $state.go('app.shareParkProfileRules');
            })
        }

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );

    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('checkboxView', checkboxView);

    function checkboxView() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/checkbox-view/checkbox-view.tpl.html',
            replace: true,
            controller: CheckboxViewController,
            controllerAs: 'checkboxView',
            bindToController: true,
            scope: {
                data: '=checkboxView',
                userRoleItem: '='
            }
        };
    }

    function CheckboxViewController($scope) {
        const vm = this;

        // public properties
        vm.filteredItems = [];

        // public methods

        vm.getValue = getValue;
        vm.updateFilteredItems = updateFilteredItems;
        vm.toggleAll = toggleAll;
        vm.isSelectedGroupItem = isSelectedGroupItem;
        vm.toggleGroupItem = toggleGroupItem;
        vm.toggleGroupItemAll = toggleGroupItemAll;
        vm.getGroupItemTitle = getGroupItemTitle;

        // private methods
        function isSelectedGroupItem (group, groupItem) {
            if (_.isObject(group) && group.selectedItems && group.idProp && _.isObject(groupItem) && groupItem[group.idProp]) {
                return _.indexOf(group.selectedItems, groupItem[group.idProp]) !== -1;
            } else {
                return false;
            }
        }

        function toggleGroupItem(group, groupItem) {
            if (_.isObject(group) && group.selectedItems && group.idProp && _.isObject(groupItem) && groupItem[group.idProp]) {
                var itemIndex = _.indexOf(group.selectedItems, groupItem[group.idProp]);
                if (itemIndex > -1) {
                    group.selectedItems.splice(itemIndex, 1);
                } else {
                    group.selectedItems.push(groupItem[group.idProp]);
                }
                updateFilteredItems();
            }
        }

        function toggleGroupItemAll(group, checked) {
            if (group) {
                if (!checked) {
                    group.selectedItems = [];
                } else if (group.items && _.isArray(group.items) && group.idProp) {
                    group.selectedItems = _.map(group.items, group.idProp);
                }
            }
            updateFilteredItems();
        }

        function toggleAll (itemsGroup, checked) {
            if (itemsGroup && _.isArray(itemsGroup) && _.isFunction(vm.data.toggleCheckbox)) {
                _.forEach(itemsGroup,(item)=>{
                    if (vm.data.isChecked(item, vm.userRoleItem) === !checked) {
                        vm.data.toggleCheckbox(item, vm.userRoleItem);
                    }
                });
            }
        }

        function getGroupItemTitle(key) {
            if (key && vm && vm.data && vm.data.getGroupItemTitle
                    && _.isFunction(vm.data.getGroupItemTitle)) {
                return vm.data.getGroupItemTitle(key);
            } else {
                return key;
            }
        }

        function updateFilteredItems () {
            vm.filteredItems = [];
            if (vm && vm.data && vm.data.items && _.isArray(vm.data.items) && vm.data.groups) {
                vm.filteredItems = _.filter(vm.data.items, (item)=>{
                    var isVisible = true;
                    _.forEach(vm.data.groups,(group)=>{
                        if (group && group.selectedItems && group.selectedItems.length
                                && _.isFunction(group.filterFunction)
                                && !group.filterFunction(item, group.selectedItems)) {
                            isVisible = false;
                            return false;
                        }
                    });
                    return isVisible;
                });
            }
        }

        /**
         * Initialization method
         */
        function init() {
            if (vm && vm.data && vm.data.groups) {
                _.forEach(vm.data.groups,(group)=>group.selectedItems = []);
            }

            updateFilteredItems();
        }

        init();

        /**
         * Extracts value from item by key
         *
         * @param {object} item
         * @param {string} || {function} key
         * @returns {string}
         */
        function getValue(item, key) {
            if (!item || !_.isObject(item) || !key) {
                return "";
            }
            return String(_.isString(key) && item[key] ? item[key] :
                        _.isFunction(key) ? key(item) : "");
        }

        $scope.$watch(
            () => vm.data && vm.data.items,
            () => init(),
            true
        )
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('AssignContentPopupController', AssignContentPopupController);

    function AssignContentPopupController($scope, Cropper, $timeout, $uibModalInstance, data) {
        const vm = this;

        // public properties
        vm.sections = data.sections;
        // vm.defaultPage = data.defaultPage;

        // public methods
        vm.close = closeHandler;
        vm.copyUrl = copyUrl;

        init();

        /**
         * Cancel button handler
         */
        function closeHandler() {
            $uibModalInstance.close();
        }

        function copyUrl(url) {
            var $temp = document.createElement('input');
            document.body.appendChild($temp);
            $temp.value = url;
            $temp.select();
            document.execCommand('copy');
            $temp.remove();
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {
            if (data.defaultPage) {
                vm.sections = [{
                    description: 'Default page',
                    url: data.defaultPage
                }];
            }
        }
    }
}());

(function() {
    'use strict';

    angular.module('trmDigitalDisplayStaticHeader')
        .component('digitalDisplayStaticHeader', {
            templateUrl: '/screen/views/components/digital-display-static-header/digital-display-static-header.tpl.html',
            controller: DigitalDisplayStaticHeaderController,
            bindings: {
                stopName: '<',
                content: '<',
            }
        });

    /**
     * @constructor
     *
     * @param {$rootScope.Scope} $scope
     * @param {DayNightService} DayNightService
     * @param {HEADER_FOOTER_DEFAULTS} HEADER_FOOTER_DEFAULTS
     *
     * @property {string} stopName
     * @property {HeaderFooterContent} content
     */
    function DigitalDisplayStaticHeaderController(
        $scope,
        DayNightService,
        HEADER_FOOTER_DEFAULTS,
    ) {
        const $ctrl = this;

        $ctrl.columns = [];
        $ctrl.colorScheme;

        $ctrl.$onInit = () => {
            $ctrl.config = angular.isString($ctrl.content.data)
                ? JSON.parse($ctrl.content.data)
                : $ctrl.content.data;

            _setVariables();
            _setColorScheme();
            _setColors();
            _initDayNightChange();
            _initWorkaroundToFixALongSingleLineText();

            $ctrl.height = $ctrl.config.height;

            $ctrl.columns = [
                {
                    position: 'left',
                    elements: $ctrl.config.elements.left,
                },
                {
                    position: 'center',
                    elements: $ctrl.config.elements.center,
                },
                {
                    position: 'right',
                    elements: $ctrl.config.elements.right,
                }
            ];
        }

        /**
         * @param {TimetableHafasHeaderElement} element
         */
        $ctrl.styles = (element) => {
            const styles = {};

            if (element.size) {
                styles['font-size'] = element.size * 100 + '%';
            }

            if (element.bold) {
                styles['font-weight'] = 'bold';
            }

            return styles;
        }

        $ctrl.generateWrapperStyle = () => {
            return {
                'color': $ctrl.color,
                'background': $ctrl.background,
                'height': $ctrl.height + 'vh',
                'font-size': $ctrl.height / 3 + 'vh',
                'padding-left': $ctrl.paddingLeft,
                'padding-right': $ctrl.paddingRight,
            };
        }

        function _setVariables() {
            $ctrl.paddingLeft = $ctrl.config.view && angular.isNumber($ctrl.config.view.paddingLeft)
                ? $ctrl.config.view.paddingLeft + 'px'
                : HEADER_FOOTER_DEFAULTS.PADDING_LEFT;
            $ctrl.paddingRight = $ctrl.config.view && angular.isNumber($ctrl.config.view.paddingRight)
                ? $ctrl.config.view.paddingRight + 'px'
                : HEADER_FOOTER_DEFAULTS.PADDING_RIGHT;

            $ctrl.stopName = $ctrl.stopName
                .replace(/\//g, '/\u200B');
        }

        /**
         * @private
         */
        function _setColors() {
            $ctrl.background = $ctrl.config.colors[$ctrl.colorScheme].background;
            $ctrl.color = $ctrl.config.colors[$ctrl.colorScheme].text;
        }

        function _initDayNightChange() {
            if ($ctrl.config.colors.mode !== 'auto') {
                return;
            }

            $scope.$on(
                'DayNightService@change',
                /**
                 * @param event
                 * @param {'default'|'night'} colorsScheme
                 */
                (event, colorsScheme) => {
                    $ctrl.colorScheme = colorsScheme;
                    _setColors();
                });
        }

        function _initWorkaroundToFixALongSingleLineText() {
            const containsLongText = (elements) => elements.find(item => ['text', 'stop_name'].includes(item.type));

            $ctrl.containsLongText = {
                left: containsLongText($ctrl.config.elements.left),
                right: containsLongText($ctrl.config.elements.right),
            };
        }

        function _setColorScheme() {
            if ($ctrl.config.colors.mode !== 'auto') {
                $ctrl.colorScheme = $ctrl.config.colors.mode;
                return;
            }

            $ctrl.colorScheme = DayNightService.currentColorScheme();
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofenceGroupsListTab', {
            templateUrl: '/assets/views/location/new-geofence/tabs/geofence-groups-list/geofence-groups-list.tpl.html',
            controller: GeofenceGroupsListTab,
            bindings: {
                data: '='
            },
        });

    function GeofenceGroupsListTab(
        $translate,
        GeofenceDataService,
    ) {
        const vm = this;

        vm.checkboxListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'group-title',
                    width: '65',
                    title: 'name',
                },
            ],
            header: false,
        };

        function init() {
            GeofenceDataService.getGeofenceGroups().then(response => {
                vm.geofenceGroups = response;
            });
        }

        init();
    }
})();

(function () {
    'use strict';

    angular.module('beacon.app')
        .component('iotDashboard', {
            templateUrl: '/assets/views/share-park/iot/dashboard/iot-dashboard.tpl.html',
            controller: IotDashboardController
        });

    function IotDashboardController(
        $interval,
        $state,
        $stateParams,
        PopupService,
        ShareParkDataService,
        IotEntityDataService,
        UtilitiesService,
    ) {
        const vm = this;

        vm.$onInit = init;

        const DATE_FORMAT = 'MMMM D, YYYY (HH:mm)';
        const UPDATE_INTERVAL = 60 * 1000; // 60 seconds
        let interval;

        vm.showIotEntities = true;
        vm.devices = [];
        vm.filteredDevices = [];
        vm.visibleDevices = [];
        vm.devicesCount = 0;
        vm.updateCounter = 0;
        vm.searchValue;
        vm.additionalParams = {
            deviceTypes: []
        }
        vm.selectedLocation = [];
        const { getDeviceTypes } = ShareParkDataService;
        vm.devicesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'device-title',
                    width: '20',
                    title: 'name',
                },
                {
                    name: 'DEVICE_TYPE',
                    class: 'device-title',
                    width: '15',
                    title: item => item.additionalParams && item.additionalParams.typeName || ''
                },
                {
                    name: 'LAST_ACTIVITY',
                    width: '20',
                    title: item => item.last_call
                        ? moment.utc(item.last_call).local().format(DATE_FORMAT)
                        : ''
                },
                {
                    name: 'LOCATION',
                    class: 'device-title',
                    width: '15',
                    title: item => item.location,
                },
                {
                    name: 'IP_ADDRESS',
                    class: 'device-title',
                    width: '15',
                    title: item => item.ipAddress1 ? item.ipAddress1 : '',
                },
                {
                    name: 'STATUS',
                    width: '5',
                    class: 'status-cell',
                    title: item => {
                        return `<div class="traffic-light ${item.deviceStatus}"></div>`;
                    }
                }
            ],
            updateCallback: updateElementsList,
        };

        vm.DEVICE_STATUS = {
            RED: 'RED',
            YELLOW: 'YELLOW',
            GREEN: 'GREEN'
        };
        vm.filter = {};

        vm.onDevicesModeChange = onDevicesModeChange;
        vm.filterDevices = filterDevices;

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
            getDevicesList();
            setUpdateInterval();
        }

        /**
         * Update elements list handler
         * @param page
         * @param itemsPerPage
         */
        function updateElementsList(page, itemsPerPage) {
            vm.visibleDevices = UtilitiesService.getArrayPage(vm.filteredDevices, page, itemsPerPage)
        }

        /**
         * Gets devices from API
         */
        function getDevicesList() {
            let data = {
                activity: true,
            };

            const getFunction = vm.showIotEntities
                ? IotEntityDataService.getIotEntities
                : ShareParkDataService.getDevices;

            getFunction(data)
                .then(response => {
                    vm.devices = response.plain();

                    vm.devices.forEach(device => {
                        device.deviceStatus = getDeviceStatus(device);
                    });

                    vm.devices.sort(compare);

                    let locations = [...new Set(vm.devices.map(device => device.location))];
                    vm.locations = locations.filter(location => location).sort();
                })
                .then(_ => {
                    getDeviceTypes().then(response => {
                        vm.additionalParams.deviceTypes = response.plain();
                        vm.additionalParams.deviceTypes.forEach(deviceType =>
                            deviceType.technicalProperties = angular.fromJson(deviceType.technicalProperties)
                        );
                        vm.devices.map(device => {
                            device.additionalParams = { typeName: '' };
                            let findedDevice = device.type
                                ? vm.additionalParams.deviceTypes.filter(item => item.id == device.type)[0]
                                : vm.additionalParams.deviceTypes.filter(item => item.id == device.device_type)[0]

                            device.additionalParams.typeName = findedDevice && findedDevice.name;
                        })
                        filterDevices();
                    });
                })
        }

        function onDevicesModeChange() {
            getDevicesList();
        }


        /**
         * Sort devices callback
         * @param a
         * @param b
         * @returns {number}
         */
        function compare(a, b) {
            const { RED, YELLOW, GREEN } = vm.DEVICE_STATUS;

            if ((a.deviceStatus === RED && b.deviceStatus !== RED)
                || (a.deviceStatus === YELLOW && b.deviceStatus === GREEN)) {
                return -1;
            }
            if ((a.deviceStatus !== RED && b.deviceStatus === RED)
                || (a.deviceStatus === GREEN && b.deviceStatus === YELLOW)) {
                return 1;
            }
            if (a.deviceStatus === b.deviceStatus) {
                if (a.last_call && !b.last_call) {
                    return -1;
                }
                return moment(a.last_call).isAfter(b.last_call) ? -1 : 1;
            }
        }

        /**
         * Gets device status by last activity
         * @param device
         * @return {string}
         */
        function getDeviceStatus(device) {
            const { RED, YELLOW, GREEN } = vm.DEVICE_STATUS;
            let status = RED;

            if (device.last_call) {
                const RED_TIME = 5;
                const YELLOW_TIME = 4;
                const lastCallMoment = moment.utc(device.last_call).local();
                const nowMoment = moment();
                const diff = moment.utc(nowMoment.diff(lastCallMoment, 'minutes'));

                switch (true) {
                    case diff > RED_TIME:
                        status = RED;
                        break;
                    case diff > YELLOW_TIME:
                        status = YELLOW;
                        break;
                    default:
                        status = GREEN;
                }
            }

            return status;
        }

        /**
         * Filters devices list
         */
        function filterDevices() {
            const allowedStatuses = Object.keys(vm.filter).filter(key => vm.filter[key]);
            vm.filteredDevices = allowedStatuses.length
                ? vm.devices.filter(device => {
                    return allowedStatuses.includes(device.deviceStatus);
                })
                : vm.devices;

            vm.filteredDevices = vm.searchValue
                ? vm.filteredDevices.filter(device => {
                    return device.name.toUpperCase().includes(vm.searchValue.toUpperCase())
                        || device.location.toUpperCase().includes(vm.searchValue.toUpperCase())
                })
                : vm.filteredDevices;

            if (vm.selectedLocation.length) {
                vm.filteredDevices = vm.filteredDevices.filter(device => vm.selectedLocation.includes(device.location));
            }

            vm.devicesCount = vm.filteredDevices.length;
            vm.updateCounter++;
        }

        /**
         * Set update interval
         */
        function setUpdateInterval() {
            interval = $interval(() => {
                getDevicesList();
            }, UPDATE_INTERVAL);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewFaqConfigurationToolbarController', NewFaqConfigurationToolbarController);

    function NewFaqConfigurationToolbarController($state, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ShareParkFAQStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.shareParkFAQ');
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
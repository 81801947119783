(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newDiscount', {
            templateUrl: '/assets/views/share-park/pricing/discounts/new/new-discount.tpl.html',
            controller: NewDiscountController
        });

    function NewDiscountController(
        $scope,
        $state,
        $stateParams,
        $translate,
        LanguageService,
        ModelsFactory,
        PricingDataService,
        StatesFactory,
    ) {
        const vm = this;

        const currentLocale = $translate.use();
        vm.language_code = currentLocale;

        vm.DISCOUNT_TYPES = [
            {
                label: '%',
                value: 1,
            },
            {
                label: '€',
                value: 2,
            }
        ];

        vm.state = StatesFactory.DiscountStates.refresh();
        vm.state.type = $stateParams.type;
        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };
        vm.rates = [];
        vm.selectedRates = [];
        vm.ratesListData = {
            columns: [
                {
                    name: 'NAME',
                    width: '55',
                    title: item => item.name[currentLocale] || Object.values(item.name)[0],
                },
                {
                    name: 'PRICE',
                    width: '45',
                    title: item => item.priceLabel[currentLocale] || Object.values(item.priceLabel)[0],
                },
            ],
            header: false,
        };

        vm.$onInit = init;

        vm.showInputError = showInputError;
        vm.isActive = isActive;
        vm.changeDiscountStatus = changeDiscountStatus;
        vm.onDiscountImage = onDiscountImage;
        vm.onDiscountImageDelete = onDiscountImageDelete;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.discountData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.Discount();

            if (vm.isEditMode) {
                vm.oldImage = vm.discountData.imageRef || null;
            }

            LanguageService.getLanguages().then((response) => {
                const filledLanguages = Object.keys(vm.discountData.name);
                vm.langArray = response.plain();

                if (filledLanguages.length && !filledLanguages.includes(vm.language_code)) {
                    vm.language_code = filledLanguages[0];
                }
            });

            PricingDataService.getAllTariffs()
                .then(response => {
                    vm.rates = response.plain().map(rate => {
                        rate.name = angular.fromJson(rate.name);
                        rate.priceLabel = angular.fromJson(rate.priceLabel);

                        return rate;
                    });

                    if (vm.isEditMode) {
                        vm.selectedRates = vm.rates.filter(rate =>
                            rate.capRateDiscountAssignment.some(assignment =>
                                assignment.capRateDiscountType.id === vm.discountData.id
                            )
                        )
                    }
                });
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.newDiscountForm
                && vm.newDiscountForm[name].$dirty
                && vm.newDiscountForm[name].$invalid;
        }

        function isActive() {
            const ACTIVE = 1;
            return vm.discountData.status === ACTIVE;
        }

        function changeDiscountStatus() {
            const ACTIVE = 1;
            const INACTIVE = 2;

            vm.discountData.status = isActive() ? INACTIVE : ACTIVE;
        }

        function onDiscountImage(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            vm.discountData.imageFile = imageFile;
            vm.discountData.imageRef = urlCreator.createObjectURL(imageFile);
        }

        function onDiscountImageDelete() {
            delete vm.discountData.imageFile;
            vm.discountData.imageRef = '';
        }

        /**
         * On finish handler
         */
        function onFinish() {
            const formData = new FormData();

            const removedAssignments = [];
            const discountData = angular.copy(vm.discountData);

            discountData.name = angular.toJson(discountData.name);
            discountData.description = angular.toJson(discountData.description);
            discountData.validFrom = discountData.validFrom
                ? moment(discountData.validFrom).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
                : null;
            discountData.validTill = discountData.validTill
                ? moment(discountData.validTill).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
                : null;

            if (vm.oldImage && (discountData.imageRef !== vm.oldImage)) {
                discountData.oldImage = vm.oldImage;
            }

            const rateAssignments = vm.selectedRates.map(rate => {
                const assignmentData = {
                    capRateRef: rate.id
                };

                if (vm.isEditMode) {
                    const relatedAssignment = rate.capRateDiscountAssignment.find(assignment =>
                        assignment.capRateDiscountType.id === vm.discountData.id
                    );

                    if (relatedAssignment) {
                        assignmentData.id = relatedAssignment.id;
                    }
                }

                return assignmentData;
            });

            if (vm.isEditMode) {
                vm.rates.forEach(rate => {
                    rate.capRateDiscountAssignment.forEach(assignment => {
                        if (assignment.capRateDiscountType.id === vm.discountData.id
                            && !rateAssignments.some(({id}) => id === assignment.id)) {
                            removedAssignments.push({
                                id: assignment.id
                            })
                        }
                    })
                })
            }

            formData.append('discountImage', discountData.imageFile);
            formData.append('discountData', angular.toJson(discountData));
            formData.append('rateAssignments', angular.toJson(rateAssignments));
            formData.append('removedAssignments', angular.toJson(removedAssignments));

            PricingDataService.createDiscount(formData)
                .then(onSuccess)
        }

        function onSuccess() {
            $state.go('app.shareParkDiscounts', {
                paginationData: $stateParams.paginationData
            });
        }

        $scope.$watch(function() {
            return vm.newDiscountForm && vm.newDiscountForm.$valid;
        }, formValid => vm.state.canFinish = formValid);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
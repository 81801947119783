(function() {
    'use strict';

    angular.module('beacon.app')
        .component('beaconsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/beacons-tab/beacons-tab.tpl.html',
            controller: BeaconsTab,
            bindings: {
                subtab: '<',
                searchName: '<',
                onSelect: '=',
                onSubTabChange: '<',
            }
        });

    function BeaconsTab(
        locationTabState,
        LocationDataService,
        LOCATION_GROUPS_TYPES,
    ) {
        const vm = this;

        vm.$onInit = init;

        const subtabs = locationTabState.views.LOCATIONS.tabs.BEACONS.subtabs;

        vm.BEACONS_TAB = subtabs.BEACON_ITEMS.index;
        vm.BEACON_GROUPS_TAB = subtabs.BEACON_GROUPS.index;

        vm.beacons = [];
        vm.beaconGroups = [];
        vm.beaconsCount = 0;
        vm.groupsCount = 0;

        vm.onTabChange = onTabChange;
        vm.loadBeacons = loadBeacons;
        vm.loadBeaconGroups = loadBeaconGroups;
        vm.getGroupMarkers = getGroupMarkers;

        /**
         * Initialization method
         */
        function init() {
            vm.currentTab = angular.isNumber(vm.subtab) ? vm.subtab : vm.BEACONS_TAB;

            loadBeacons();
            loadBeaconGroups();
        }

        function onTabChange(newTab) {
            vm.currentTab = newTab;
            vm.onSubTabChange(vm.currentTab);
        }

        /**
         * Loads beacons
         * @return {*}
         */
        function loadBeacons() {
            return LocationDataService.getBeacons()
                .then(response => {
                    vm.beacons = response.plain().list;
                    vm.beaconsCount = vm.beacons.length;

                    loadBeaconDetails();
                });
        }

        /**
         * Loads beacon details
         */
        function loadBeaconDetails() {
            LocationDataService.getBeaconDetails().then(response => {
                vm.beaconsDetails = response.list;
                vm.beacons.forEach(item => {
                    const currentLocationDetails = vm.beaconsDetails.find(detailsItem =>
                        item.beaconRef === parseInt(detailsItem.beaconReferenceId)
                    );
                    if (!!currentLocationDetails) {
                        const geoLocation = currentLocationDetails.geoLocation.split(',');
                        item.markers = [{
                            label: item.name,
                            position: {
                                lat: parseFloat(geoLocation[0]),
                                lng: parseFloat(geoLocation[1])
                            }
                        }]
                    }
                });
            })
        }

        function getGroupMarkers(group) {
            let markers = [];
            group.locations.forEach(location => {
                const currentLocation = vm.beacons.find(beacon => beacon.locationId === location.locationId);
                if (!currentLocation) {
                    return;
                }

                markers = markers.concat(currentLocation.markers);
            });

            return markers;
        }

        /**
         * Loads beacon groups
         */
        function loadBeaconGroups () {
            LocationDataService.getLocationGroups({type: LOCATION_GROUPS_TYPES.BEACON}).then(response => {
                vm.beaconGroups = response;
                vm.groupsCount = vm.beaconGroups.length;
            });
        }
    }
})();
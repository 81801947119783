(function() {
    'use strict';

    angular.module('beacon.app')
        .service('StopParkingPopupService', StopParkingPopupService);

    function StopParkingPopupService(
        PopupService) {

        return {
            show
        };

        function show(carPark) {
            return PopupService.show({
                templateUrl: '/assets/views/common/popups/stop-parking-popup/stop-parking-popup.tpl.html',
                controller: 'StopParkingPopupController',
                controllerAs: '$ctrl',
                windowClass: 'stop-parking-popup',
                backdrop: true,
                resolve: {
                    // send data to the component
                    data: {carPark},
                }
            });
        }
    }
}());
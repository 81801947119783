(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentTypeTab', {
            templateUrl: '/assets/views/content/elements/new/tabs/content-type/content-type.tpl.html',
            controller: ContentTypeTab
        });

    function ContentTypeTab(
        $stateParams,
        StatesFactory,
        StorageFactory,
        ContentDataService,
        PermissionsService
    ) {

        const vm = this;
        vm.state = StatesFactory.ContentStates;
        vm.setContentType = setContentType;

        init();

        function init() {
            vm.STORAGE = StorageFactory.Storage('Content');
            vm.STORAGE.on('contentData', (value) => (vm.contentData = value));
            vm.contentTypes = vm.STORAGE.get('contentTypes');

            /**
             * TODO: 03.12.17 serhii.kokovskyi
             *       STORAGE object after initializing current angular module again does not contain 'contentTypes' value
             *       that is why it each times reload content types. Need to fix.
             */
            if (!vm.contentTypes) {
                loadContentTypes();
            }
        }

        function loadContentTypes() {
            return ContentDataService.contentTypes().then(function(response) {
                let contentTypes = response.plain();

                contentTypes = PermissionsService.filterContentTypesWhenCreateNewContent(contentTypes);

                vm.STORAGE.set('contentTypes', contentTypes);
                vm.contentTypes = contentTypes;
            });
        }

        /**
         * Sets content type
         * @param {number} type
         * @param {boolean} force
         */
        function setContentType(type, force = false) {
            type = Number(type);
            vm.contentData.content_type_id = (type && vm.contentData.content_type_id !== type) ? type : false;
            vm.state.canNext = !!vm.contentData.content_type_id;
        }
    }
})();

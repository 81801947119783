(function() {
    'use strict';

    angular.module('beacon.app')
        .component('editScreenCampaign', {
                templateUrl: '/assets/views/campaign/screen/new/new-campaign.tpl.html',
                controller: ScreenCampaignAddController,
            });

    function ScreenCampaignAddController(
        $q,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $translate,
        campaignScreenState,
        ContentDataService,
        CampaignDataService,
        LocationDataService,
        ShareDataService,
        UtilitiesService,
        ScreenCampaignHelper,
        WEEK_DAYS,
        CONTENT_TYPES,
        ScreenHelper,
        ContentHelper,
        DateHelper,
        ACTIVATION_STATUS,
        lodash,
        ScreensFilterPopup,
    ) {
        const vm = this;

        const {
            sortByStringProperty,
            sortByDateProperty,
            distinctByProp
        } = UtilitiesService;

        const {
            ALL_DAYS_FLAG,
            ENDLESS_DATE_STRING,
            DATE_TIME_FORMAT,
            createCampaignFormData,
        } = ScreenCampaignHelper;

        const { getDefaultDateFormat } = DateHelper;
        const { getContentNameForList } = ContentHelper;

        let sectionGroupsAll = [];

        vm.SORT_BY = {
            DATE: '0',
            TITLE: '1',
        };

        // public properties
        vm.state = campaignScreenState;

        vm.campaignData = {
            contents: 0,
            sections: [],
            audiences: [],
            name: '',
            description: '',
            started_at: null,
            finished_at: null,
            start_time: null,
            end_time: null,
            selectedDays: []
        };

        vm.searchName = '';
        vm.wholeDay = true;
        vm.onlyCertainDays = false;
        vm.content = [];
        vm.sections = [];
        vm.selectedSectionGroups = [];

        vm.sectionsCheckboxListData = {
            columns: [
                {
                    name: 'Title',
                    class: 'sectionTitle',
                    width: '50',
                    title: 'name'
                },
                {
                    name: 'Region',
                    class: 'sectionRegion',
                    width: '30',
                    title: 'region'
                },
                {
                    name: 'Status',
                    class: 'sectionStatus',
                    width: '20',
                    translate: true,
                    title: item => item.details ? ScreenHelper.getStatusName(item.details.status) : 'N/A'
                }
            ],
            header: false,
            itemClickCallback: toggleSection
        };
        vm.sectionGroupsCheckboxListData = {
            columns: [
                {
                    name: 'Title',
                    class: 'sectionGroupTitle',
                    width: '100',
                    title: item => `Group - ${item.name}`
                },
            ],
            header: false,
            itemClickCallback: toggleSectionGroup
        };

        vm.overnightWarning = '';
        vm.days = WEEK_DAYS;
        vm.today = new Date(); // need today date for form validations
        vm.today.setHours(0, 0, 0, 0);
        vm.dateFormat = getDefaultDateFormat();

        vm.minStarted_at = angular.copy(vm.today);

        vm.isTitleEntered = false;
        vm.isDescriptionEntered = false;
        vm.contentOrder = '1';

        vm.status = {
            active: true,
            inactive: false,
        };

        vm.onlySelectedScreens = false;

        // public methods
        vm.addContent = addContent;
        vm.onDaysOptionSwitch = onDaysOptionSwitch;
        vm.onDefaultCampaign = onDefaultCampaign;
        vm.toggleDay = toggleDay;
        vm.toggleContent = toggleContent;
        vm.onTitleBlur = onTitleBlur;
        vm.onDescriptionBlur = onDescriptionBlur;
        vm.sortContent = sortContent;
        vm.getContentNameForList = getContentNameForList;
        vm.selectDeselectAll = selectDeselectAll;
        vm.applyFilter = applyFilter;
        vm.onOnlySelectedFilterChange = onOnlySelectedFilterChange;

        vm.$onInit = onInit;

        vm.showScreensFilter = () => {
            ScreensFilterPopup.show(vm.filteredSections).then(filteredScreens => {
                if (!filteredScreens) { return; }

                vm.isClearFiltersBtnVisible = true;
                vm.filteredSections = filteredScreens;

                // filter out hidden screens from "checked" list
                vm.campaignData.sections = vm.campaignData.sections.filter(section => {
                    return vm.filteredSections.find(filteredSection => section.id === filteredSection.id);
                });

                filterOutUnrelatedGroups();
                toggleSection();
                toggleSectionGroup();
            });
        }

        vm.clearAdvancedFilters = () => {
            applyFilter();
        }

        // Invert/toggle all checkboxes for visible screens (checked -> unchecked, unchecked -> checked)
        vm.invertCheckedAll = () => {
            vm.campaignData.sections = vm.filteredSections.filter(filteredSection => {
                return !vm.campaignData.sections.find(selectedSection => filteredSection.id === selectedSection.id);
            });

            toggleSection();
            toggleSectionGroup();
        }

        /**
         * Filter out group sections that have no visible children screens
         */
        function filterOutUnrelatedGroups() {
            vm.sectionGroups = vm.sectionGroups.map(screenGroup => {
                const groupRelatedScreens = vm.filteredSections.map(({ groups }) => {
                    return groups.find(group => group.id === screenGroup.id);
                }).filter(screens => screens);

                return groupRelatedScreens.length ? screenGroup : null;
            }).filter(group => group);
        }

        /**
         * Sorts array of contents by string|number properties
         */
        function sortContent() {
            vm.content = Number(vm.contentOrder)
                ? sortByStringProperty(vm.content, 'atitle')
                : sortByDateProperty(vm.content, 'created_at');
        }

        /**
         * Go to content add page
         */
        function addContent() {
            $state.go('app.addContent', {});
        }

        function canProcess() {
            return vm.campaignDetailsForm
                ? (vm.campaignDetailsForm.name.$valid &&
                    vm.campaignDetailsForm.description.$valid &&
                    (vm.campaignData.default
                        ? true // since there's no way for start date to be invalid, we return true all the time
                        : vm.campaignDetailsForm.$valid && (!vm.onlyCertainDays || vm.campaignData.selectedDays.length)
                    ))
                : true;
        }

        /**
         * Days switch handler
         */
        function onDaysOptionSwitch() {
            vm.state.canProcessNext = canProcess();
        }

        function onDefaultCampaign() {
            vm.state.canProcessNext = canProcess();
            if (!vm.campaignData.default) {
                vm.wholeDay = true;
                vm.onlyCertainDays = false;
            }
        }

        /**
         * Toggles day selection
         * @param {number} id
         */
        function toggleDay(id) {
            const { selectedDays } = vm.campaignData;
            const index = selectedDays.indexOf(id);
            (index === -1)
                ? selectedDays.push(id)
                : selectedDays.splice(index, 1);

            vm.state.canProcessNext = canProcess();
        }

        /**
         * Campaign title blur handler
         */
        function onTitleBlur() {
            if (vm.campaignData.name) {
                vm.isTitleEntered = true;
            }
            vm.state.canProcessNext = canProcess();
        }

        /**
         * Campaign description blur handler
         */
        function onDescriptionBlur() {
            if (vm.campaignData.description) {
                vm.isDescriptionEntered = true;
            }
            vm.state.canProcessNext = canProcess();
        }

        /**
         * Toggle all handler
         */
        function selectDeselectAll() {
            const filtered = vm.filteredSections.map(item => item.id);
            const checked = vm.campaignData.sections.map(item => item.id);
            vm.campaignData.sections = lodash.isEqual(filtered, checked) ? [] : vm.filteredSections;
            toggleSection();
        }

        /**
         * Handler on content select/deselect
         */
        function toggleContent() {
            vm.state.canProcessNext = vm.campaignData.contents && canProcess();
        }

        /**
         * Handler on section select/deselect
         */
        function toggleSection() {
            // creates new selectedSectionsGroupsIds array according to selected sections list changes
            let selectedSectionsGroupsIds =
                _.chain(vm.filteredSections)
                    .map(section => section.groups.map(item => item.id))
                    .flatten()
                    .countBy() // amount of selected screens in each group
                    .pickBy((totalAmount, groupId) => totalAmount === amountSelected(groupId))
                    .keys()
                    .map(_.parseInt)
                    .value();
            vm.selectedSectionGroups = vm.sectionGroups.filter(group => selectedSectionsGroupsIds.includes(group.id));
            vm.state.canProcessNext = vm.campaignData.sections.length && canProcess();

            /**
             * @param {string} groupId
             * @return {number}
             */
            function amountSelected(groupId) {
                return vm.campaignData.sections.filter(section => {
                    return section.groups.find(group => group.id === parseInt(groupId));
                }).length;
            }
        }

        /**
         * Handler on sectionGroup select/deselect
         */
        function toggleSectionGroup(groupItem) {
            const groupSections = groupItem
                ? _.filter(vm.filteredSections, section => _.find(section.groups, ['id', groupItem.id]))
                : undefined;

            if (groupItem && _.find(vm.selectedSectionGroups, ['id', groupItem.id])) {
                vm.campaignData.sections = _.uniqBy(
                    _.unionWith(vm.campaignData.sections, groupSections, _.isEqual),
                    'id'
                );
            } else {
                vm.campaignData.sections = _.uniqBy(
                    _.pullAllBy(vm.campaignData.sections, groupSections, 'id'),
                    'id'
                );
            }
            vm.state.canProcessNext = vm.campaignData.sections.length && canProcess();
        }

        /**
         * Campaign details form validity change callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onFormValidityChange(newValue, oldValue) {
            vm.state.canProcessNext = canProcess();
        }

        /**
         * View change callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onViewChange(newValue, oldValue) {
            switch (newValue) {
                case vm.state.views.CONTENT:
                    vm.state.canProcessNext = vm.campaignData.contents && canProcess();
                    break;
                case vm.state.views.CAMPAIGN_DETAILS:
                    vm.unregisterCampaignForm = $scope.$watch(angular.bind(vm, function() {
                        return this.campaignDetailsForm && this.campaignDetailsForm.$valid;
                    }), onFormValidityChange);
                    break;
                case vm.state.views.SECTIONS:
                    filterSectionsAccordingToContent();
                    vm.state.canProcessNext = vm.campaignData.sections.length && canProcess();
                    toggleSection();
                    break;
            }

            if (vm.unregisterCampaignForm && vm.state.view !== vm.state.views.CAMPAIGN_DETAILS) {
                vm.unregisterCampaignForm();
                delete vm.unregisterCampaignForm;
            }
        }

        /**
         * Finish action callback
         */
        function onFinish() {

            if (vm.campaignData.default) {
                vm.campaignData.finished_at = ENDLESS_DATE_STRING;
                vm.campaignData.show_days = [ ALL_DAYS_FLAG ];
                vm.campaignData.send_time = { start: '00:00', end: '24:00' };
            } else {
                vm.campaignData.finished_at = moment(vm.campaignData.finished_at).utc().format(DATE_TIME_FORMAT);
                vm.campaignData.show_days = vm.onlyCertainDays ? vm.campaignData.selectedDays : [ ALL_DAYS_FLAG ];
                vm.campaignData.send_time = {
                    start: !vm.wholeDay ? moment(vm.campaignData.start_time).utc().format('HH:mm') : '00:00',
                    end: !vm.wholeDay ? moment(vm.campaignData.end_time).utc().format('HH:mm') : '24:00'
                };
            }

            const formData = createCampaignFormData(vm.campaignData);

            const success = () => {
                $stateParams.redirect
                    ? $state.go($stateParams.redirect)
                    : $state.go('app.screenCampaign', {
                        paginationData: $stateParams.paginationData
                    });
            };

            const shareCampaign = campaignId => {
                const controllerRefs = vm.campaignData.sections.map(section => section.controllerRef);
                return ShareDataService.shareCampaign({
                    campaignId,
                    controllerRefs
                })
            };

            const error = console.error.bind(console);

            (vm.state.type === 'new'
                ? CampaignDataService.create(formData)
                : CampaignDataService.update(vm.campaignData, formData)
            ).then(shareCampaign).then(success).catch(error);
        }

        /**
         * Campaign title change handler
         */
        function onTitleChange() {
            if (vm.state.type === 'new' && !$stateParams.data && !vm.isTitleEntered && !vm.isDescriptionEntered) {
                vm.campaignData.description = vm.campaignData.name;
            }
        }

        /**
         * Initialization method
         */
        function onInit() {
            getOvernightWarning();
            vm.state.type = $stateParams.type;

            if ($stateParams.data) {
                vm.campaignData = angular.copy($stateParams.data);

                if (vm.campaignData.finished_at === ENDLESS_DATE_STRING) {
                    vm.campaignData.finished_at = null;
                    vm.campaignData.default = true;
                }

                if (typeof vm.campaignData.contents === 'object') {
                    vm.campaignData.contents = vm.campaignData.contents.length ? vm.campaignData.contents[0].id : '';
                    vm.state.canProcessNext = canProcess();
                }

                let days = JSON.parse(vm.campaignData.show_days);
                let time = JSON.parse(vm.campaignData.send_time);

                vm.onlyCertainDays = !((days.length === 1) && (days[0] === ALL_DAYS_FLAG));
                vm.campaignData.selectedDays = vm.onlyCertainDays ? days : [];
                vm.wholeDay = time.end === '24:00';

                if (!vm.campaignData.default && !vm.wholeDay) {
                    vm.campaignData.start_time = moment.utc(time.start, 'HH:mm').local();
                    vm.campaignData.end_time = moment.utc(time.end, 'HH:mm').local();
                } else {
                    vm.campaignData.start_time = null;
                    vm.campaignData.end_time = null;
                }

                if (vm.state.type === 'edit') {
                    vm.campaignData.sections = vm.campaignData.locations;
                    vm.campaignData.audiences = [];

                    vm.campaignData.started_at = moment.utc(vm.campaignData.started_at).local();
                    vm.campaignData.finished_at = moment.utc(vm.campaignData.finished_at).local();
                } else {
                    vm.campaignData.sections = [];
                    vm.campaignData.audiences = [];
                }

                delete vm.campaignData.locations;
            } else if (vm.state.type === 'new') {
                vm.campaignData.started_at = angular.copy(vm.today);
                // default finish date is + 1 day after start date
                vm.campaignData.finished_at = angular.copy(vm.campaignData.started_at);
                vm.campaignData.finished_at.setDate(vm.campaignData.started_at.getDate() + 1);
            }

            $scope.$watch(() => vm.state.view, onViewChange);
            $scope.$watch(
                () => vm.state.finish,
                newValue => newValue && onFinish()
            );
            $scope.$watch(() => vm.campaignData.name, onTitleChange);

            const { selectedContentId, tab } = $stateParams;
            if (selectedContentId) {
                vm.campaignData.contents = selectedContentId;
            }
            if (vm.campaignData.contents && tab) {
                vm.state.view = tab;
            }

            ContentDataService.contents({
                contentTypeId: [
                    CONTENT_TYPES.DIGITAL_DISPLAY,
                    CONTENT_TYPES.TICKER,
                    CONTENT_TYPES.DYNAMIC_CONTENT,
                ].join(',')
            }).then(response => {
                vm.content = response || [];
                vm.content = vm.content.map(item => {
                    const decoded = ContentHelper.decodeContent(item);
                    decoded.atitle = getContentNameForList(item);
                    return decoded;
                });
                vm.content = sortByStringProperty(vm.content, 'atitle');
            })
            .catch(console.error.bind(console));

            $q.all([
                LocationDataService.getScreens(),
                LocationDataService.getControllerDetails(),
            ]).then(response => {
                const screens = response[0];
                const details = response[1];

                vm.sections = screens.list
                    .map(screen => {
                        screen.details = details.list.find(controller => screen.controllerRef === controller.id);
                        return screen;
                    }).filter(screen => screen.details
                        && screen.details.status !== ACTIVATION_STATUS.DELETED.value
                    );
                vm.filteredSections = angular.copy(vm.sections);

                applyFilter();

                if (!vm.campaignData.sections.length) {
                    if (vm.campaignData.locationsIDs && vm.campaignData.locationsIDs.length) {
                        vm.campaignData.locationsIDs.forEach((ID) => {
                            vm.filteredSections.forEach((section, index) => {
                                if (section.locationId === ID) {
                                    vm.campaignData.sections.push(section);
                                }
                            });
                        });
                    }
                }
                sectionGroupsAll =
                    _.chain(vm.filteredSections)
                        .map('groups')
                        .flatten()
                        .uniqBy('id')
                        .sortBy('name')
                        .value();
                vm.sectionGroups = sectionGroupsAll;
            }).catch(console.error.bind(console));
        }

        function getOvernightWarning() {
            $translate('OVERNIGHT_WARNING', {}, undefined, undefined, false, 'escape')
                .then(translation => {
                    vm.overnightWarning = translation;
                });
        }

        function filterSectionsAccordingToContent() {
            vm.filteredSections = distinctByProp(vm.filteredSections, 'locationId');
            vm.filteredSections = sortByStringProperty(vm.filteredSections, 'name');
        }

        function onOnlySelectedFilterChange() {
            vm.sectionGroups = vm.onlySelectedScreens
                ? sectionGroupsAll.filter(
                    group => vm.selectedSectionGroups.find(selectedGroup => selectedGroup.id === group.id)
                ) : sectionGroupsAll;
            applyFilter();
        }

        /**
         * Apply screen filter according to filter params
         */
        function applyFilter() {
            if (vm.isClearFiltersBtnVisible) {
                vm.sectionGroups = sectionGroupsAll;
                vm.isClearFiltersBtnVisible = false;
                toggleSection();
            }

            vm.filteredSections = vm.sections.filter(screen => {
                const status = screen.details ? screen.details.status : null;
                const statusMatches =  vm.status.active && status === ACTIVATION_STATUS.ACTIVE.value
                    || vm.status.inactive && status === ACTIVATION_STATUS.INACTIVE.value;
                const nameMatches = !vm.searchName || screen.name.toUpperCase().includes(vm.searchName.toUpperCase());
                const onlySelectedCondition = !vm.onlySelectedScreens
                    || !!vm.campaignData.sections.find(selectedScreen => screen.id === selectedScreen.id);

                return statusMatches && nameMatches && onlySelectedCondition;
            });
            filterSectionsAccordingToContent();
        }

        /**
         * Apply filters on status change
         */
        $scope.$watch(
            () => vm.status,
            () => applyFilter(),
            true,
        );

        $scope.$watch(
            () => vm.campaignData,
            () => {
                vm.state.canProcessNext = canProcess()
            },
            true,
        );
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('MultiGuideApiService', MultiGuideApiService);

    function MultiGuideApiService($http) {
        return {
            getLotsInfo,
            setLotType,
            setReserved,
            getCapacityProviders,
        }

        /**
         * @return {Promise<MultiGuideLot[]>}
         */
        function getLotsInfo(data) {
            return $http.post('/api/share-park/multi-guide', data)
                .then(response => response.data);
        }

        /**
         * @return {Promise<CapacityProvider[]>}
         */
        function getCapacityProviders() {
            return $http.get('/api/share-park/multi-guide/capacity-providers')
                .then(response => response.data);
        }

        /**
         * @return {Promise<MultiGuideLot[]>}
         */
        function setLotType(lotId, lotTypeId) {
            return $http.post('/api/share-park/multi-guide/set-type', {
                elementId: lotId,
                typeId: lotTypeId,
            })
            .then(response => response.data);
        }

        /**
         * @return {Promise<MultiGuideLot[]>}
         */
        function setReserved(lotId, reserved = true) {
            return $http.post('/api/share-park/multi-guide/reservation', {
                elementId: lotId,
                reserved,
            })
            .then(response => response.data);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('dynamicContentPage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/dynamic-content/dynamic-content.tpl.html',
            controller: DynamicContentPageCtrl,
            bindings: {
                contentData: '=',
                pageData: '<',
                languages: '<',
            }
        });

    function DynamicContentPageCtrl(
        $scope,
        ContentDataService,
        LineRouteDataService,
        LineRoutePopup,
        CONTENT_TYPES
    ) {
        const vm = this;

        vm.$onInit = init;

        /**
         * Initialization method
         */
        function init() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.DYNAMIC_CONTENT})
                .then(response => {
                    vm.contents = response.plain().map(content => {
                        content.title = angular.fromJson(content.title);
                        content.message = angular.fromJson(content.message);
                        content.data = angular.fromJson(content.data);
                        return content;
                    });
                    vm.selected = vm.contents.find(content => content.id === vm.pageData.selectedContentId);
                });
        }

        /**
         * On finish click handler
         */
        function onFinish(event, option) {
            if (!vm.selected) {
                return;
            }

            vm.pageData.selectedContentId = vm.selected.id;

            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        $scope.$on('digital-display-savepage', onFinish);
    }
})();
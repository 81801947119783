(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltyCampaignCollectionTab', {
            templateUrl: '/assets/views/loyalty/campaigns/form/tabs/collection/collection.tpl.html',
            controller: loyaltyCampaignCollectionTab
        });

    function loyaltyCampaignCollectionTab($scope, StatesFactory, StorageFactory, LoyaltyDataService, Restangular,
                                          UtilitiesService, LanguageService) {
        const vm = this;

        const CONFIG_TYPE_EVENTS_LIST       = 1;
        const CONFIG_TYPE_EXP_PERIODS       = 2;
        const CONFIG_TYPE_REWARD_INTERVALS  = 3;
        const CONFIG_TYPE_RESET_TYPE        = 7;

        vm.state    = StatesFactory.LoyaltyCampaignStates;
        vm.STORAGE  = StorageFactory.Storage('LoyaltyCampaign');

        vm.getExpPeriodLabel = getExpPeriodLabel;
        vm.getRewardIntervalLabel = getRewardIntervalLabel;
        vm.setLoyaltyEvent = setLoyaltyEvent;
        vm.isValidField = isValidField;

        init();

        // Public methods

        /**
         * Sets loyalty event information to data object
         *
         * @param {number} loyaltyEventValue
         */
        function setLoyaltyEvent(loyaltyEventValue) {
            const loyaltyEvent = getLoyaltyEvent(loyaltyEventValue);
            vm.data.loyaltyEventType.name               = loyaltyEvent.configTitle || '';
            vm.data.loyaltyEventType.description        = loyaltyEvent.configText || '';
        }

        /**
         * Returning expiration period label for input
         * eg. "hour(s)" or "year(s)" etc.
         *
         * @param {number} expPeriodValueId
         * @returns {*}
         */
        function getExpPeriodLabel(expPeriodValueId) {
            // avoid errors while loading the data
            if (!expPeriodValueId || !vm.expPeriods) {
                return false;
            }

            const param = vm.expPeriods.find(period => {
                return +period.parameters.value === expPeriodValueId;
            });

            return param && param.configText;
        }

        /**
         * Returning reward interval label for input
         * eg. "hour(s)" or "year(s)" etc.
         *
         * @param {number} rewardIntervalId
         * @returns {*}
         */
        function getRewardIntervalLabel(rewardIntervalId) {
            if (!rewardIntervalId || !vm.rewardIntervals) {
                return false;
            }

            const param = vm.rewardIntervals.find(period => {
                return +period.parameters.value === rewardIntervalId;
            });

            return param && param.configText;
        }

        // Private methods

        /**
         * Controller initialization
         */
        function init() {
            loadCampaignData();
            loadDropdownLists();
            loadLanguagesData();

            vm.state.canBack = true;
        }

        /**
         * Getting loyalty event object from array
         *
         * @param loyaltyEventValue
         * @returns {*}
         */
        function getLoyaltyEvent(loyaltyEventValue) {
            return vm.eventsList.find(loyaltyEvent => {
                if (+loyaltyEvent.parameters.value === +loyaltyEventValue) {
                    return loyaltyEvent;
                }
            });
        }

        /**
         * Setting language data to scope and storage
         */
        function loadLanguagesData() {
            LanguageService.getLanguages().then((response) => {
                vm.langArray = vm.STORAGE.set('Languages', response.plain());
            }).catch(console.error.bind(console));
        }

        /**
         * Loads campaign data object to scope
         */
        function loadCampaignData() {
            vm.STORAGE.on('data', value => vm.data = value);
        }

        /**
         * Loading dropdown lists from server
         * and saving them to storage for using in other tabs.
         * Also this method sets default select values to model data
         */
        function loadDropdownLists() {
            LoyaltyDataService.getLoyaltyConfigs().then(response => {
                const paramsData = decodeDropdownParams(Restangular.stripRestangular(response));

                vm.STORAGE.set('LoyaltyCampaignParams', paramsData);

                vm.eventsList      = paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_EVENTS_LIST);
                vm.expPeriods      = paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_EXP_PERIODS);
                vm.rewardIntervals = paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_REWARD_INTERVALS);
                vm.resetTypes      = paramsData.filter(el => parseInt(el.configType) === CONFIG_TYPE_RESET_TYPE);

                _setDefaultValue(vm.data.loyaltyEventType, 'expirationPeriodType', vm.expPeriods);
                _setDefaultValue(vm.data.loyaltyCounterType, 'expirationPeriodType', vm.rewardIntervals);
                _setDefaultValue(vm.data.loyaltyCounterType, 'resetType', vm.resetTypes);
            }).catch(console.error.bind(console));
        }

        /**
         * Setting the first config value if model property is empty
         *
         * @param {object} object
         * @param {string} property
         * @param {array} configList
         * @private
         */
        function _setDefaultValue(object, property, configList) {
            if (!object[property] && configList.length) {
                object[property] = configList[0].parameters.value;
            }
        }

        /**
         * Decoding dropdown parameters
         * eg. params = "foo=bar" is becoming
         *     params = {foo: bar}
         *
         * @param {array} dropdownParams
         * @returns {*}
         */
        function decodeDropdownParams(dropdownParams) {
            return dropdownParams.map(dropdownParam => {
                const paramsEncodedArray = dropdownParam.parameters.split(';');
                const paramsDecoded = {};
                paramsEncodedArray.map(paramEncoded => {
                    const paramArray = paramEncoded.split('==');
                    if (paramArray[0] && paramArray[1]) {
                        paramsDecoded[paramArray[0]] = paramArray[1];
                    }
                });
                dropdownParam.parameters = paramsDecoded;
                return dropdownParam;
            });
        }

        /**
         * Validation checker for input fields
         *
         * @param {string} fieldName
         * @returns {boolean}
         */
        function isValidField(fieldName) {
            return UtilitiesService.isValidField(vm.dataForm, fieldName);
        }

        /**
         * Watching for Form validation to allow next step
         */
        $scope.$watch(
            () => !!vm.dataForm.$valid && !!vm.data.loyaltyEventType.eventTypeFilter,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
})();
(function() {
    'use strict';

    angular.module('capCapacity').service(
        'CapacityApiService',
        function($http) {
            return {
                getCapacity,
            }

            /**
             * @param carParkId
             * @param tenantId
             * @return {Promise<CapacityAlert[]>}
             */
            function getCapacity(carParkId, tenantId)
            {
                return $http
                    .get(`/api/cap-capacity?tenantId=${tenantId}&carParkId=${carParkId}`)
                    .then(response => response.data)
            }
        }
    );
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkEchargeTab', {
            templateUrl: '/assets/views/share-park/car-parks/new/tabs/car-park-echarge/car-park-echarge-tab.tpl.html',
            controller: CarParkEchargeTabController,
            bindings: {
                carParkData: '<',
            }
        });

    function CarParkEchargeTabController(
        $scope,
        StatesFactory,
        ChargingStationDataService,
        ChargingStationHelper,
        UtilitiesService,
    ) {
        const vm = this;

        let chargingStationsAll = [];

        vm.state = StatesFactory.CarParkStates;
        vm.state.canNext = true;
        vm.state.canBack = true;
        vm.chargingStations = [];
        vm.selected = [];

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'device-title',
                    width: '30',
                    title: chargingStation => ChargingStationHelper.defaultTitle(chargingStation),
                },
                {
                    name: 'STATUS',
                    width: '10',
                    title: chargingStation => ChargingStationHelper.status(chargingStation),
                    translate: true,
                },
            ],
            needCheckbox: true,
            updateCallback: () => {},
        };

        vm.$onInit = init;
        vm.onMarkerClick = onMarkerClick;

        function init() {
            ChargingStationDataService.getAll().then(response => {
                chargingStationsAll = response.plain();
                vm.chargingStations = filterOutAssignedStations(chargingStationsAll);
                vm.selected = getAssignedStations(vm.chargingStations);
                updateMarkers();
                setWatchers();
            });
        }

        function onMarkerClick(marker) {
            const station = marker.chargingStation;
            if (vm.selected.find(item => item.id === station.id)) {
                vm.selected = vm.selected.filter(item => item.id !== station.id);
            } else {
                vm.selected.push(station);
            }
            $scope.$apply();
        }

        function generateMarkers(chargingStations, selectedChargingStations) {
            return chargingStations.map(chargingStation => {
                const coordinates = UtilitiesService.getCoordinates(chargingStation.location);
                return {
                    position: {
                        lat: coordinates.latitude,
                        lng: coordinates.longitude,
                    },
                    selected: !!selectedChargingStations.find(item => item.id === chargingStation.id),
                    label: ChargingStationHelper.defaultTitle(chargingStation),
                    chargingStation,
                };
            })
        }

        function filterOutAssignedStations(chargingStations) {
            return chargingStations.filter(item => [null, vm.carParkData.id].includes(item.car_park_id));
        }

        function getAssignedStations(chargingStations) {
            return chargingStations.filter(
                chargingStation => vm.carParkData.charging_stations
                    .find(item => item.id === chargingStation.id)
            );
        }

        function updateMarkers() {
            vm.markers = generateMarkers(vm.chargingStations, vm.selected);
        }

        function setWatchers() {
            $scope.$watch(
                () => vm.selected,
                selected => {
                    vm.carParkData.charging_stations = selected;
                    updateMarkers();
                },
                true
            );
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PoiContentDataProcessingService', poiContentDataProcessingService);

    function poiContentDataProcessingService(
        ModelsFactory
    ) {
        return {
            processImportedContent,
            encodePoiData,
        };


        /**
         * Processes imported content data before saving
         * @param importedContent
         * @param defaultLanguageId
         * @return {object}
         */
        function processImportedContent(importedContent, defaultLanguageId) {
            const processedContentData = new ModelsFactory.PoiContent();
            processedContentData.map_info = [{
                latitude: importedContent.lat,
                longitude: importedContent.lng
            }];
            processedContentData.language_id = defaultLanguageId;
            processedContentData.origin_id = importedContent.id;
            processedContentData.title[defaultLanguageId] = importedContent.name;
            processedContentData.message[defaultLanguageId] = `<p>${importedContent.name}</p>`;

            return processedContentData;
        }

        /**
         * Encodes POI fields to JSON
         * @param poiContent
         */
        function encodePoiData(poiContent) {
            const jsonFields = ['title', 'message', 'map_info', 'delivery_times', 'opening_days', 'opening_times'];
            Object.keys(poiContent).forEach(key => {
               if (jsonFields.includes(key)) {
                   poiContent[key] = angular.toJson(poiContent[key]);
               }
            });

            if (angular.isObject(poiContent.audio_src)) {
                poiContent.audio_src = angular.toJson(poiContent.audio_src);
            }
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newPoiElement', {
            templateUrl: '/assets/views/poi/elements/new/new-poi-element/new-poi-element.tpl.html',
            controller: NewPoiElementsController
        });

    function NewPoiElementsController(
        $q,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $translate,
        ContentDataService,
        ContentDataProcessingService,
        ContentImageProcessingService,
        LanguageService,
        PopupService,
        PoiContentDataService,
        UserDataService,
        ModelsFactory,
        StatesFactory,
        DEFAULT_CONTENT_GROUP,
    ) {
        const vm = this;

        const { processDefaultContent, processContentData } = ContentDataProcessingService;

        vm.$onInit = init;

        // public properties
        vm.state = StatesFactory.PoiContentStates.refresh();
        vm.state.canNext = true;

        // public methods
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;
        vm.onMap = onMap;
        vm.onOpeningDataChange = onOpeningDataChange;
        vm.onAudioDataChange = onAudioDataChange;
        vm.onLanguageChange = onLanguageChange;

        /**
         * Initialization method
         */
        function init() {
            const promises = [getlanguages(), loadContentGroups()];
            vm.state.type = $stateParams.type;
            if (vm.state.type === "new" && !$stateParams.data) {
                vm.contentData = new ModelsFactory.PoiContent();
            } else {
                vm.contentData = $stateParams.data;
            }

            $q.all(promises).then(processPoiContent);

            UserDataService.loadUserData().then(function(data){
                vm.userData = data;
                processUserData();
            });
            vm.openingHours = vm.contentData.always_opened || (!_.isEmpty(vm.contentData.opening_days) &&
                !_.isEmpty(vm.contentData.opening_times));
        }

        /**
         *
         * @param { Object} response
         */
        function processPoiContent(response) {
            /**
             * Please note: by default content_group_id equal 0 (see ModelsFactory). If content_group_id is undefined or null
             *              then sets a default value.
             */
            if (vm.contentData.content_group_id === undefined || vm.contentData.content_group_id === null) {
                vm.contentData.content_group_id = DEFAULT_CONTENT_GROUP.id;
            }
        }

        /**
         * Image file input handler
         * @param {object} imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @returns {*}
         */
        function onImage(imageFile, uploadToS3) {
            ContentImageProcessingService.onImage(vm.contentData, imageFile, uploadToS3);
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            ContentImageProcessingService.onImageDelete(vm.contentData);
        }

        /**
         * Map icon click handler
         */
        function onMap() {
            var mapPopup;

            Promise.all([
                $translate('OK'),
                $translate('CANCEL'),
            ]).then(([okButtonText, cancelButtonText]) => {
                mapPopup = PopupService.show({
                    templateUrl: '/assets/views/common/popups/map/map.tpl.html',
                    controller: 'MapPopupController',
                    controllerAs: 'mapPopupController',
                    windowClass: 'mapPopup',
                    keyboard: false,
                    resolve: {
                        data: function() {
                            return {
                                okButtonText,
                                cancelButtonText,
                                mapInfo: vm.contentData.map_info,
                                mapDefaults: vm.mapDefaults,
                                canEdit: true
                            };
                        }
                    }
                });

                mapPopup.then(function(mapInfo) {
                    vm.contentData.map_info = mapInfo;
                });
            });
        }

        function getlanguages() {
            return LanguageService.getLanguages().then((response) => {
                vm.langArray = response.plain();
                if (!!vm.langArray.length) {
                    vm.contentData.language_id = vm.langArray[0].id;
                }
            })
        }

        function loadContentGroups() {
            return ContentDataService.contentGroups({noSync: true})
                .then((response) => {
                    vm.contentGroupsArray = response.plain();
                    vm.contentGroupsArray.forEach((item) => {
                        item.title = angular.fromJson(item.title);
                    });
                });
        }

        function processUserData() {
            vm.mapDefaults = {};

            if (vm.userData.subdomain.tenant.homeLocation) {
                let mapData = vm.userData.subdomain.tenant.homeLocation.split(',');
                if (mapData.length === 3) {
                    vm.mapDefaults.latitude = parseFloat(mapData[0]);
                    vm.mapDefaults.longitude = parseFloat(mapData[1]);
                    vm.mapDefaults.zoom = Number(mapData[2]);
                }
            }

            vm.isShopTenant = Number(vm.userData.subdomain.tenant.storeRef) > 0;
        }

        /**
         * Language change handler
         * @param {string} oldValue
         * @returns {undefined}
         */
        function onLanguageChange(oldValue) {
            let valid = vm.contentData.title[oldValue] && vm.contentData.message[oldValue] &&
                (!vm.contentData.delivery || vm.contentData.delivery_times[oldValue]) &&
                (!vm.openingHours || vm.contentData.always_opened || vm.contentData.opening_times[oldValue]);

            if (!valid) {
                delete vm.contentData.title[oldValue];
                delete vm.contentData.message[oldValue];
                delete vm.contentData.delivery_times[oldValue];
                delete vm.contentData.opening_times[oldValue];
                if (vm.contentData.language_sensitive_audio) {
                    if (vm.contentData.audio && vm.contentData.audio[oldValue]) {
                        delete vm.contentData.audio[oldValue];
                    }
                    if (vm.contentData.audio_src && vm.contentData.audio_src[oldValue]) {
                        delete vm.contentData.audio_src[oldValue];
                    }
                }
            }
        }

        function onOpeningDataChange(data) {
            if (!data.always_opened) {
                Object.assign(vm.contentData, data);
            } else {
                vm.contentData.always_opened = data.always_opened;
                vm.contentData.opening_times = {};
                vm.contentData.opening_days = [];
            }
        }

        function onAudioDataChange(data) {
            Object.assign(vm.contentData, data);
        }

        function _onFinish() {
            const formData = new FormData();
            processContentData(vm.contentData);
            if (!vm.openingHours || vm.contentData.always_opened) {
                vm.contentData.opening_times = {};
            }
            if (!vm.contentData.delivery) {
                vm.contentData.delivery_times = {};
            }
            const contentFormData = processDefaultContent(formData, vm.contentData, vm.contentForm);
            contentFormData.delivery_times = angular.toJson(contentFormData.delivery_times);
            contentFormData.opening_days = angular.toJson(contentFormData.opening_days);
            contentFormData.opening_times = angular.toJson(contentFormData.opening_times);
            if (angular.isObject(vm.contentData.audio_src)) {
                contentFormData.audio_src = angular.toJson(contentFormData.audio_src);
            }
            for (let prop in contentFormData) {
                let value = contentFormData[prop];

                // jump to next loop iteration, because we don't need to rewrite 'audio' property
                if (prop === 'audio' && formData.get('audio')) {
                    continue;
                }

                if (contentFormData.hasOwnProperty(prop) && value !== undefined) {
                    formData.append(prop, value);
                }
            }
            if (vm.state.type === 'new') {
                PoiContentDataService.create(formData)
                    .then(() => {
                        $state.go('app.poiElements', {
                            paginationData: $stateParams.paginationData
                        });
                    });
            } else {
                PoiContentDataService.poiContent(vm.contentData.id)
                    .then((poiContent) => {
                        formData.append('_method', 'PUT');

                        return PoiContentDataService.update(poiContent, formData)
                            .then(() => {
                                $state.go('app.poiElements', {
                                    paginationData: $stateParams.paginationData
                                });
                            });
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Watcher for finish button click event
         */
        $scope.$watch(
            () => vm.state.finish,
            allowFinish => {
                allowFinish && _onFinish();
            });

        /**
         * Form validation watcher
         */
        $scope.$watch(
            () => !!vm.contentForm.$valid,
            formValid => {
                vm.state.canNext = formValid;
            });
    }
})();
(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasThemeColorPicker', {
            templateUrl:
                '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-theme-color-picker/timetable-hafas-theme-color-picker.tpl.html',
            controller: TimetableHafasThemeColorPicker,
            bindings: {
                contentData: '=',
            }
        });

    /**
     * @property {TimetableHafasContent} contentData
     * 
     * @constructor
     */
    function TimetableHafasThemeColorPicker() {}
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkCompanies', {
            templateUrl: '/assets/views/share-park/customers/companies/companies.tpl.html',
            controller: CompaniesController
        });

    function CompaniesController(
        $state,
        $translate,
        ExternalCompaniesDataService,
        PopupService,
        DATETIME_FORMAT,
        PermissionsService,
        UserUtilitiesService,
    ) {
        const vm = this;

        const { actions, generateCapCompanyPermission } = PermissionsService;
        const timeZoneId = UserUtilitiesService.timeZoneId();

        vm.companies = [];
        vm.companiesCount = 0;

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'companies-list-name',
                    width: '25',
                    title: listItem => listItem.company.name,
                },
                {
                    name: 'VALID_FROM',
                    width: '20',
                    title: listItem =>
                        listItem.company.valid_from ?
                            listItem.company.valid_from.format(DATETIME_FORMAT)
                            : '',
                },
                {
                    name: 'VALID_TILL',
                    width: '20',
                    title: listItem =>
                        listItem.company.valid_till ?
                            listItem.company.valid_till.format(DATETIME_FORMAT)
                            : '',
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteCompany,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editCompany,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        vm.addCompany = addCompany;

        /**
         * Update data on pagination
         *
         * @param page
         * @param itemsPerPage
         */
        function updateList(page = 1, itemsPerPage = 25) {
            let data = { page, itemsPerPage };

            ExternalCompaniesDataService.getExternalCompanies(data)
                .then(response => {
                    vm.companies = response.map(company => {
                        company.valid_from = company.valid_from ? moment.utc(company.valid_from).tz(timeZoneId) : null;
                        company.valid_till = company.valid_till ? moment.utc(company.valid_till).tz(timeZoneId) : null;
                        return { company };
                    });
                    vm.companiesCount = response.count;
                })
        }

        function addCompany() {
            $state.go('app.addShareParkCompany');
        }

        function deleteCompany($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_INVITATION',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                ExternalCompaniesDataService.deleteExternalCompany(listItem.company.id)
                    .then(() => {
                        vm.companiesCount -= 1;
                    })
                    .catch(console.error.bind(console));
            });
        }

        function editCompany($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkCompany', { data: listItem.company });
        }

        function generatePermissionName(button) {
            return generateCapCompanyPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('languagePicker', languagePicker);

    function languagePicker() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/language-picker/language-picker.tpl.html',
            controller: languagePickerController,
            controllerAs: '$ctrl',
            scope: {
                ngModel: '='
            },
        };
    }

    function languagePickerController(LanguageService, StorageFactory) {
        const vm = this;

        vm.STORAGE = StorageFactory.Storage('LoyaltyLanguages');
        if (!vm.STORAGE) {
            vm.STORAGE = new StorageFactory.Storage('LoyaltyLanguages', true);
        }

        vm.langArray = vm.STORAGE.get('list');

        if (!vm.langArray) {
            LanguageService.getLanguages().then(response => {
                vm.STORAGE.set('list', vm.langArray = response.plain());
            }).catch(console.error.bind(console));
        }
    }
}());

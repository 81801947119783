(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkCapacity', {
            templateUrl: '/assets/views/share-park/car-park-capacity/car-park-capacity.tpl.html',
            controller: CarParkCapacity
        });

    function CarParkCapacity(
        ShareParkDataService
    ) {
        const vm = this;
        let capacities = [];

        vm.groups = [];
        vm.subLabel = subLabel;
        vm.$onInit = init;

        function init() {
            ShareParkDataService.getCarParkCapacities()
                .then(response => {
                    capacities = response.plain();
                    vm.groups = groupCapacities(capacities);
                });
        }

        function subLabel(capacity) {
            const subLabel = capacity.capSubLabel ? JSON.parse(capacity.capSubLabel) : {};
            return subLabel.en;
        }

        function groupCapacities(capacities) {
            const groups = [];

            capacities.forEach(capacity => {
                const existingGroup = groups.find(capacityGroup => capacityGroup.carParkId === capacity.capRef);
                if (existingGroup) {
                    existingGroup.capacities.push(capacity);
                } else {
                    groups.push({
                        carParkId: capacity.capRef,
                        carParkName: capacity.capName,
                        capacities: [capacity],
                    })
                }
            });
            return groups;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('dynamicContentDashboard', {
            controller: dynamicContentDashboard,
            templateUrl: '/assets/views/dynamic-content-dashboard/dynamic-content-dashboard.tpl.html',
        });

    function dynamicContentDashboard(
        $q,
        $state,
        $scope,
        $timeout,
        ContentDataService,
        CampaignDataService,
        LanguageService,
        ContentHelper,
        LocationDataService,
        CONTENT_TYPES,
        CampaignHelper,
        StorageFactory,
    ) {
        const vm = this;
        const STORAGE = StorageFactory.Storage('Main');

        let filterTimeout = null;
        let contentsAll = [];
        let screensAll = [];

        vm.events = [];
        vm.markers = [];
        vm.uiConfig = {
            calendar:{
                height: 'parent',
                editable: true,
                header:{
                    left: 'title',
                    center: null,
                    right: 'today prev,next'
                },
                timeFormat: 'H:mm',
                locale: STORAGE.currentUserInfo.language.code,
                eventClick,
            }
        };

        const STATUS = {
            ALL: 'ALL',
            ACTIVE: 'ACTIVE',
            FUTURE: 'FUTURE',
            EXPIRED: 'EXPIRED',
            UNASSIGNED: 'UNASSIGNED',
        };

        vm.$onInit = init;
        vm.setActiveContent = setActiveContent;
        vm.setCampaign = setCampaign;
        vm.markerClick = markerClick;
        vm.addContent = addContent;
        vm.deleteContent = deleteContent;
        vm.applyFilter = applyFilter;

        /**
         * Controller initialization
         */
        function init() {
            $q.all([
                LanguageService.getLanguages(),
                ContentDataService.contents({contentTypeId: CONTENT_TYPES.DYNAMIC_CONTENT}),
                LocationDataService.getScreensWithDetails(),
            ]).then(response => {
                vm.languages = response[0].plain();
                contentsAll = response[1].plain().map(item => ContentHelper.processContentItem(vm.languages, item));
                vm.items = contentsAll;
                vm.items.length && setActiveContent(vm.items[0]);
                screensAll = response[2];
                vm.events = generateEvents(vm.items);
                vm.statuses = generateStatusList();
                vm.status = [STATUS.ACTIVE, STATUS.FUTURE];
                vm.searchQuery = '';
            });
        }

        /**
         * Apply filtering
         */
        function applyFilter() {
            const SET_ACTIVE_TIMEOUT = 1000;

            filterTimeout && $timeout.cancel(filterTimeout);
            vm.items = contentsAll.filter(item => {
                const isCampaignStatusMatched = (campaign, status) => {
                    const startDate = moment.utc(campaign.started_at).local();
                    const finishDate = moment.utc(campaign.finished_at).local();
                    const currentDate = moment();

                    switch (status) {
                        case STATUS.ACTIVE:
                            return currentDate.isAfter(startDate)
                                && (currentDate.isBefore(finishDate) || CampaignHelper.isCampaignDefault(finishDate));
                        case STATUS.FUTURE:
                            return currentDate.isBefore(startDate);
                        case STATUS.EXPIRED:
                            return currentDate.isAfter(finishDate);
                        default:
                            return false;
                    }
                };

                const isSearchMatched = vm.searchQuery
                    ? (item.data.name && item.data.name.toLowerCase().includes(vm.searchQuery.toLowerCase()))
                    : true;
                let statusesMatched;

                switch (true) {
                    case vm.status.includes(STATUS.ALL):
                        statusesMatched = true;
                        break;
                    case vm.status.includes(STATUS.UNASSIGNED):
                        statusesMatched = !item.campaigns.length;
                        break;
                    default:
                        statusesMatched = item.campaigns.find(
                            campaign => vm.status.find(status => isCampaignStatusMatched(campaign, status))
                        );
                }

                return isSearchMatched && statusesMatched;
            });

            filterTimeout = $timeout(
                () => vm.items.length && setActiveContent(vm.items[0])
            , SET_ACTIVE_TIMEOUT);
        }

        /**
         * Set active content item
         *
         * @param {object} content
         */
        function setActiveContent(content) {
            vm.activeContent = content;
            vm.campaigns = content.campaigns;
            vm.campaigns[0] ? setCampaign(vm.campaigns[0]) : setCampaign(null);
        }

        /**
         * Set active campaign
         *
         * @param {object} campaign
         */
        function setCampaign(campaign) {
            const campaignId = campaign
                ? campaign.id
                : null;
            vm.activeCampaign = campaign || null;
            vm.activeContent = campaignId
                ? findCampaignContent(campaignId)
                : vm.activeContent;
            setMarkers(campaignId);
            vm.events = generateEvents(vm.items);
        }

        /**
         * Set campaign's markers to the map
         *
         * @param {int} campaignId
         */
        function setMarkers(campaignId) {
            if (!campaignId) {
                vm.markers = vm.markersAll
                    ? screensAll.map(screen => makeMarker(screen))
                    : null;
                return;
            }

            CampaignDataService.getLocations({
                campaignID: campaignId
            }).then(response => {
                const locationsList = response.plain();
                if (vm.markersAll) {
                    vm.markers = screensAll.map(screen => makeMarker(screen, locationsList));
                } else {
                    vm.markers = locationsList.map(locationExternalId => {
                        const screen = screensAll.find(screen => screen.locationId === locationExternalId);
                        return makeMarker(screen);
                    });
                }
            });
        }

        /**
         * Make single marker from screen
         *
         * @param {object} screen
         * @param {array} selectedList
         * @returns {{screen: *, label: *, position: {lng: *, lat: *}, selected: boolean}}
         */
        function makeMarker(screen, selectedList = []) {
            return {
                label: screen.name,
                position: {
                    lat: screen.lat,
                    lng: screen.lng,
                },
                selected: !selectedList.includes(screen.locationId),
                screen,
            };
        }

        /**
         * Generates events array for Full Calendar plugin
         *
         * @param {array} items
         * @returns {Array}
         */
        function generateEvents(items) {
            const events = [];
            items.forEach(item => {
                item.campaigns.forEach(campaign => {
                    const isActive = vm.activeCampaign && campaign.id === vm.activeCampaign.id;
                    events.push({
                        id: campaign.id,
                        title: campaign.name,
                        start: moment.utc(campaign.started_at).toDate(),
                        end: moment.utc(campaign.finished_at).toDate(),
                        allDay: false,
                        backgroundColor: isActive ? '#C00B1E' : '#3a87ad',
                        borderColor: 'transparent',
                        campaign,
                        click: eventClick,
                    })
                })
            });
            return events;
        }

        /**
         * Generate status list
         *
         * @returns {Array}
         */
        function generateStatusList() {
            return Object.keys(STATUS).map(key => STATUS[key]);
        }

        /**
         * Returns a content object related to the campaign id
         *
         * @param {int} campaignId
         * @returns {object}
         */
        function findCampaignContent(campaignId) {
            return vm.items.find(
                item => item.campaigns.find(campaign => campaign.id === campaignId)
                );
        }

        /**
         * Event click handler (in the calendar)
         *
         * @param {object} calEvent
         */
        function eventClick(calEvent) {
            const campaign = calEvent.campaign;
            setCampaign(campaign);
            vm.campaigns = [campaign];
            vm.activeContent = findCampaignContent(campaign.id);
        }

        /**
         * Marker click handler (on the map)
         *
         * @param {object} marker
         */
        function markerClick(marker) {
            const screen = marker.screen;
            LocationDataService.getLocationCampaigns(screen.locationId).then(response => {
                const campaignList = response.plain().list;
                const campaigns = [];
                vm.items.forEach(item => {
                    const campaign = item.campaigns.find(campaign => campaignList.includes(campaign.id));
                    campaign && campaigns.push(campaign);
                });

                vm.campaigns = campaigns;
                vm.activeCampaign = campaigns.length && campaigns[0];
                vm.activeContent = findCampaignContent(vm.activeCampaign.id);
            });
        }

        /**
         * Delete content handler
         *
         * @param {int} contentId
         */
        function deleteContent(contentId) {
            const index = vm.items.findIndex(item => item.id === contentId);
            index !== -1 && vm.items.splice(index, 1);
            vm.activeContent = null;
            vm.items.length && setActiveContent(vm.items[0]);
        }

        /**
         * Add content handler
         */
        function addContent() {
            $state.go('app.addContent', {
                initialData: {
                    contentType: CONTENT_TYPES.DYNAMIC_CONTENT
                },
                redirect: 'app.dynamicContentDashboard',
            });
        }

        /**
         * Watchers
         */
        $scope.$watch(
            () => vm.markersAll,
            () => vm.activeCampaign ? setMarkers(vm.activeCampaign.id) : setMarkers(null),
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('bannerFirstTab', {
            templateUrl: '/assets/views/content/elements/types/banner/tabs/banner-first-tab/banner-first-tab.tpl.html',
            controller: BannerFirstTab,
            bindings: {
                contentForm: '=',
                contentData: '=',
                infos: '='
            }
        });

    function BannerFirstTab(
        $scope,
        $stateParams,
        $timeout,
        StatesFactory,
        ContentDataService,
        BANNER_REFERENCE_TYPE,
        PermissionsService,
        ) {
        const vm = this;

        const TYPE_LOYALTY_CAMPAIGN_LABEL = 'LOYALTY_CAMPAIGN';

        vm.BANNER_REFERENCE_TYPE = BANNER_REFERENCE_TYPE;

        vm.$onInit = _init;

        vm.state = StatesFactory.BannerStates;
        vm.opened = {};

        vm.setInfotainment = setInfotainment;
        vm.isOpen = isOpen;
        vm.getType = getType;
        vm.isShownByFilter = isShownByFilter;
        vm.getTitle = getTitle;
        vm.getMessage = getMessage;
        vm.onBannerTypeChange = onBannerTypeChange;

        vm.isEditMode = vm.state.type === 'edit';
        vm.isStandaloneBannerAvailable = PermissionsService.isPermissionAvailable('can_use_standalone_banner');

        /**
         * Initialization method
         *
         * @private
         */
        function _init() {
            if (!vm.isEditMode && !vm.contentData.message.infoId && !$stateParams.initialData) {
                vm.contentData.data.standaloneBanner = true;
            }
            vm.isModeChangeDisabled = vm.isEditMode || !!$stateParams.initialData;
            bindWatchers();
            _initFilters();

            if (vm.contentData.data.standaloneBanner) {
                return;
            }

            if (vm.contentData.message.infoId !== null && vm.contentData.message.referenceType !== null) {
                return setInfotainment(vm.contentData.message.infoId, vm.contentData.message.referenceType);
            }

            if ($stateParams.initialData
                && $stateParams.initialData.infoId !== null && $stateParams.initialData.referenceType !== null) {
                return setInfotainment($stateParams.initialData.infoId, $stateParams.initialData.referenceType);
            }
        }

        /**
         * Checking if infotainment is passing filter settings
         *
         * @param {Banner} info
         * @returns {boolean}
         */
        function isShownByFilter(info) {
            if (!_isFilterEnabled()) {
                return true;
            }

            const referenceTypeMatch = Object.keys(vm.filter.referenceTypes).some(
                key => vm.filter.referenceTypes[key] && Number(key) === info.referenceType);

            const contentTypeMatch = Object.keys(vm.filter.contentTypes).some(
                key => vm.filter.contentTypes[key] && Number(key) === info.content_type_id);

            return referenceTypeMatch || contentTypeMatch;
        }

        function isOpen(info) {
            const infoId = vm.contentData.message.infoId;
            const referenceType = vm.contentData.message.referenceType;

            const infoIdMatch = infoId && Number(infoId) === Number(info.id);
            const referenceTypeMatch = referenceType !== null
                && Number(referenceType) === Number(info.referenceType);

            return infoIdMatch && referenceTypeMatch;
        }

        /**
         * Getting the type of infotainment
         *
         * @param {object} item
         * @returns {string}
         */
        function getType(item) {
            if (item.referenceType === BANNER_REFERENCE_TYPE.LOYALTY_CAMPAIGN) {
                return TYPE_LOYALTY_CAMPAIGN_LABEL;
            } else {
                return vm.contentTypes && vm.contentTypes.find(type => item.content_type_id === type.id).name;
            }
        }

        /**
         * Get content title
         *
         * @param {object} content
         * @return {string}
         */
        function getTitle(content) {
            return content.name || content.title[vm.contentData.language_id] || content.title[content.language_id];
        }

        /**
         * Get content message
         *
         * @param {object} content
         * @return {string}
         */
        function getMessage(content) {
            const msg = content.message;
            return (msg.message ? msg.message[vm.contentData.language_id] : msg[vm.contentData.language_id]) || '';
        }

        function setInfotainment(infoId, referenceType) {
            vm.contentData.data.standaloneBanner = false;
            vm.contentData.message.referenceType = referenceType;
            vm.contentData.message.infoId = infoId;

            vm.contentData.selectedInfotainment = vm.infos.find(item => {
                return item.id === infoId && item.referenceType === referenceType;
            });

            if (!vm.contentData.selectedInfotainment) {
                throw new Error('Infotainment is missing in CMS');
            }

            vm.contentData.message.infoExternalId = vm.contentData.selectedInfotainment.reference_id;

            $timeout(() => {
                vm.infos.forEach(info => {
                    vm.opened[info.id] = vm.isOpen(info);
                });
            });
        }

        function bindWatchers() {
            $scope.$watchGroup([
                () => vm.contentData.message.infoId,
                () => vm.contentData.data.standaloneBanner,
            ], function() {
                vm.state.canNext = vm.isStandaloneBannerAvailable && vm.contentData.data.standaloneBanner
                    || !!vm.contentData.message.infoId;
            });
        }

        /**
         * Filter initialization
         *
         * @private
         */
        function _initFilters() {
            vm.filter = {
                contentTypes: {},
                referenceTypes: {}
            };

            ContentDataService.contentTypes().then(response => {
                // Show only filters with infotainments
                vm.contentTypes = response.plain().filter(contentType => {
                    return vm.infos.find(info => info.content_type_id === contentType.id);
                });
            });
        }

        /**
         * Checking if any of filters is active
         *
         * @returns {boolean}
         * @private
         */
        function _isFilterEnabled() {
            const anyContentGroupChecked = Object.keys(vm.filter.contentTypes).some(
                key => vm.filter.contentTypes[key]);
            const anyReferenceTypeChecked = Object.keys(vm.filter.referenceTypes).some(
                key => vm.filter.referenceTypes[key]);
            return anyContentGroupChecked || anyReferenceTypeChecked;
        }

        function onBannerTypeChange() {
            if (vm.contentData.data.standaloneBanner) {
                uncheckAll();
                vm.contentData.selectedInfotainment = null;

                delete vm.contentData.message.referenceType;
                delete vm.contentData.message.infoId;
                delete vm.contentData.message.infoExternalId;
            }
        }

        function uncheckAll() {
            Object.keys(vm.opened).forEach(infoId => vm.opened[infoId] = false);
        }
    }
}());

(function() {
    'use strict';

    angular.module('lineRoute')
        .service('RouteHorizontal', (RouteStation, RouteLine, RouteBase, RouteHelper) => {

            class RouteHorizontal extends RouteBase {
                constructor(config) {
                    super(config);

                    this.stepX = this.workspaceWidth / (this.stationsPerLine - 1);
                    this.stepY = this.isMultiLine
                        ? RouteHelper.getPercent(this.workspaceHeight, config.distance)
                        : 0;

                    this.angle = this.config.textAngle;
                    this.isSingleTop = !this.isMultiLine && this.angle === -90;
                    this.isSingleBottom = !this.isMultiLine && this.angle === 90;

                    this.startX = this.paddingX;
                    this.endX = config.width - this.paddingX;

                    this.startY = this.getStartY();
                    this.endY = config.height - this.startY;

                    const freeSpace = (this.workspaceHeight - this.stepY) / 2;
                    this.textWidthLimit = freeSpace / Math.cos(RouteBase.toRadians(this.config.textAngleOffset));

                    // Increase max label width for one-line and one-label-sided routes
                    if (this.isSingleTop || this.isSingleBottom) {
                        this.textWidthLimit *= this.SINGLE_LINE_TEXT_WIDTH_COEFFICIENT;
                    }
                }

                /**
                 * Calculate width limit for labels.
                 * Edge station labels should be shorter to fit the screen
                 *
                 * @param index
                 * @param angle
                 * @param coords
                 * @returns {any}
                 */
                getWithLimit(index, angle, coords) {
                    const isLeft = coords.x === this.startX;
                    const isRight = coords.x === this.endX;
                    const isAngleLeft = angle > 90 && angle < 270;
                    const isAngleRight = angle > -90 && angle < 90;
                    const edgeLimit = Math.abs(this.paddingX / Math.cos(RouteBase.toRadians(angle))) - this.fontSize;
                    return (isLeft && isAngleLeft || isRight && isAngleRight) && edgeLimit < this.textWidthLimit
                        ? edgeLimit
                        : this.textWidthLimit;
                };

                /**
                 * Calculate text angle for current station
                 *
                 * @param index
                 * @returns {*}
                 */
                getTextAngle(index) {
                    let angle = null;
                    switch (true) {
                        case this.isMultiLine:
                            angle = this.getLineIndex(index) % 2 * 180;
                            angle += this.config.flipVertical ? 90 : -90;
                            break;
                        case angular.isObject(this.config.textAngle):
                            angle = index % 2 === 0
                                ? this.config.textAngle.even
                                : this.config.textAngle.odd;
                            break;
                        default:
                            angle = this.config.textAngle;
                    }
                    return angle + this.config.textAngleOffset;
                }

                /**
                 * Calculate Y coordinate for first point
                 *
                 * @returns {number}
                 */
                getStartY() {
                    const linesCount = this.getLinesCount();
                    const graphHeight = this.stepY * (linesCount - 1);
                    const topDistance = (this.workspaceHeight - graphHeight) / 2;

                    let startY = this.paddingY + topDistance;
                    const offset = this.workspaceHeight / 4;

                    if (this.isSingleTop) {
                        startY += offset;
                    }

                    if (this.isSingleBottom) {
                        startY -= offset;
                    }

                    return startY;
                }

                /**
                 * Calculating X coordinate of a station by array index
                 *
                 * @param index
                 * @returns {number}
                 */
                getX(index) {
                    const line = this.getLineIndex(index);
                    const internalIndex = index % this.stationsPerLine;    // station index inside a single line

                    const fromLeft = this.startX + internalIndex * this.stepX;
                    const fromRight = this.endX - internalIndex * this.stepX;
                    const flipHorizontal = this.config.flipHorizontal;

                    return RouteBase.isLineReversed(line)
                        ? flipHorizontal ? fromLeft : fromRight
                        : flipHorizontal ? fromRight : fromLeft;
                }

                /**
                 * Calculating X coordinate of a station by array index
                 *
                 * @param index
                 * @returns {number}
                 */
                getY(index) {
                    const line = this.getLineIndex(index);
                    return this.config.flipVertical
                        ? this.endY - line * this.stepY
                        : this.startY + line * this.stepY;
                }
            }

            return RouteHorizontal;
        });
})();
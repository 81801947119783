(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('UploadImagePopupController', UploadImagePopupController);

    function UploadImagePopupController(
        $http,
        $scope,
        $uibModalInstance,
        $translate,
        Restangular,
        UtilitiesService,
        FileService,
        data
    ) {
        const vm = this;
        const { base64ImageToBlob } = UtilitiesService;
        const { getRemoteImage } = FileService;
        vm.LOAD_FROM_FILE = '1';
        vm.LOAD_FROM_URL = '0';

        // publick properties
        vm.loadingType = vm.LOAD_FROM_FILE;
        vm.data = data;
        vm.useImageCopy = true;
        vm.imageURL = vm.data.externalImageUrl || "";
        vm.imageUrlLabel = vm.data.externalImageLabel;

        // public methods
        vm.cancel = cancelHandler;
        vm.onImageCallback = onImageCallback;
        vm.loadImageFromUrl = loadImageFromUrl;

        /**
         * Cancel button handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Handles image selection
         * @param file
         */
        function onImageCallback(file) {
            vm.data.onImage(file, true);
            $uibModalInstance.dismiss();
        }

        /**
         * Loads image from url
         */
        function loadImageFromUrl() {
            if (!_.isEmpty(vm.imageURL) && vm.uploadImage.urlInput.$valid) {
                getRemoteImage(vm.imageURL)
                    .then(response => {
                        if (response.image) {
                            const blob = base64ImageToBlob(response.image, response.type);
                            blob.name = vm.imageURL;
                            vm.data.onImage(blob, vm.useImageCopy);
                            $uibModalInstance.dismiss();
                        }
                    })
            }
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('permission', function (PermissionsService) {
            return {
                restrict: 'A',
                link: function($scope, element, attrs) {
                    $scope.$watch(attrs.permission, function(permissionName) {
                        if (!permissionName) {
                            return;
                        }

                        if (!PermissionsService.isPermissionNameValid(permissionName)) {
                            throw `Permission ${permissionName} is not valid`;
                        }

                        if (!PermissionsService.isPermissionAvailable(permissionName)) {
                            element.css('display', 'none');
                        }
                    });
                }
            };
        });
}());
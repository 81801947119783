(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedback', {
            templateUrl: '/assets/views/feedback/feedback/feedback.tpl.html',
            controller: FeedbackController,
            bindings: {
                feedbackFormConfig: '<',
                preview: '<',
            }
        });

    function FeedbackController(
        $state,
        $http,
        $stateParams,
        $window,
        FEEDBACK_FORM_TYPE,
        StorageFactory,
        FeedbackConfigService,
        FeedbackTicketService,
        PopupService,
        FeedbackHelper,
        $translate,
        $mdConstant,
    ) {
        const vm = this;
        const type = FEEDBACK_FORM_TYPE;
        const PRIORITY = FeedbackHelper.PRIORITY;

        vm.$onInit = onInit;
        vm.onSubmit = onSubmit;
        vm.onCheckboxClick = onCheckboxClick;
        vm.isRequiredError = isRequiredError;
        vm.isErrorInColumn = isErrorInColumn;

        vm.chipsSeparators = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.SEMICOLON, $mdConstant.KEY_CODE.COMMA];
        vm.additionalEmails = [];

        vm.type = FEEDBACK_FORM_TYPE;
        /**
         * @type {FeedbackFormConfig}
         */
        vm.rows = [];
        vm.subject = "";
        vm.showErrors = false;

        function onInit() {
            if (vm.feedbackFormConfig) {
                setInitialValues(vm.feedbackFormConfig);
            } else {
                FeedbackConfigService.getByAlias($stateParams.type)
                    .then(response => setInitialValues(response.data));
            }
        }

        function setInitialValues(feedbackFormConfig) {
            vm.feedbackFormConfig = feedbackFormConfig;
            vm.rows = JSON.parse(vm.feedbackFormConfig.config).elements;
            setDefaultValues(vm.rows);
            vm.subject = `${vm.feedbackFormConfig.name} (${moment().format('DD MMM HH:mm')})`;
        }

        function onSubmit() {
            vm.showErrors = true;

            if (isAnyError(vm.rows)) {
                $translate('FEEDBACK_FORM_ERROR').then(message => {
                    PopupService.showAlertPopup({
                        text: message,
                        okButtonText: 'OK',
                    });
                });
                return;
            }

            const feedbackTicket = {
                feedback_form_config_id: vm.feedbackFormConfig.id,
                subject: vm.subject,
                text: generateTicketMessage(vm.rows),
                priority: $stateParams.priority || PRIORITY.LOW,
                additional_emails: vm.additionalEmails,
            };

            $translate(FeedbackHelper.priorityName(feedbackTicket))
                .then(priorityName => {
                    feedbackTicket.priorityName = priorityName;

                    FeedbackTicketService.create(feedbackTicket).then(_ => {
                        $state.go('app.feedbackTickets');
                    });
                });
        }

        /**
         * @param {string|array} fieldValue
         * @param {string} checkboxValue
         */
        function onCheckboxClick(fieldValue, checkboxValue) {
            if (fieldValue.includes(checkboxValue)) {
                fieldValue = fieldValue.filter(item => item !== checkboxValue);
            } else {
                fieldValue.push(checkboxValue);
            }
        }

        /**
         * @param {FeedbackFormConfigItem} col
         * @returns {boolean}
         */
        function isRequiredError(col) {
            if (col.required) {
                return col.type === type.CHECKBOXES
                    ? !col.value.length
                    : !col.value;
            }
            return false;
        }

        /**
         * @param {FeedbackFormConfig} rows
         * @returns {FeedbackFormConfig}
         */
        function setDefaultValues(rows) {
            rows.forEach(row => {
                row.forEach(col => {
                    col.value = col.type === type.CHECKBOXES ? [] : '';
                });
            })
        }

        /**
         * @param {FeedbackFormConfig} rows
         * @returns {boolean}
         */
        function isAnyError(rows) {
            const errorCol = rows.find(row => {
                return row.find(col => {
                    return isErrorInColumn(col);
                });
            });
            return !!errorCol || !vm.subject;
        }

        function isErrorInColumn(col) {
            return isRequiredError(col)
        }

        /**
         * @param {FeedbackFormConfig} rows
         * @returns {string}
         */
        function generateTicketMessage(rows) {
            const result = [];
            rows.forEach(row => {
                row.forEach(col => {
                    let value = angular.isArray(col.value)
                        ? col.value.join(', ')
                        : col.value;

                    if (value) {
                        value = value.replace(/[<>]/g, ''); // Removes all '<' and '>'
                        result.push(`<b>${col.label}</b>:<br>${value || 'N/A'}`);
                    }
                });
            })
            return result.join('<br><br>');
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('GeofenceLocationPickerPopupController', GeofenceLocationPickerPopupController);

    function GeofenceLocationPickerPopupController(
        $scope,
        $uibModalInstance,
        $timeout,
        data,
        GeofenceDataService,
    ) {
        const vm = this;

        vm.locations = data.locations;
        vm.selectedLocations = data.selectedLocations && data.selectedLocations.length !== vm.locations.length
            ? data.selectedLocations : [];
        vm.showSelectedOnly = false;
        vm.geofences = [];
        vm.selectedGeofences = [];

        vm.polyline = '';

        vm.geofenceGroupingListData = {
            itemsList: {
                columns: [
                    {
                        name: 'NAME',
                        class: 'checkbox-list-item-title',
                        width: '',
                        title: 'name',
                    },
                ],
                trackBy: 'id',
                orderBy: 'name',
            },
            groupsList: {
                columns: [
                    {
                        name: 'TITLE',
                        class: 'checkbox-list-item-title',
                        width: '100',
                        translate: true,
                        getTranslationParams: ({name}) => ({name}),
                        title: () => 'GROUP_NAME',
                    },
                ],
                trackBy: 'id',
            },
            updateSelectedItemsListCallback: updateSelectedGeofences
        };

        vm.setLocations = setLocations;
        vm.onShowSelectedChange = onShowSelectedChange;
        vm.updatePolyline = updatePolyline;
        vm.unselectAll = unselectAll;
        vm.invertSelection = invertSelection;
        vm.cancel = cancelHandler;
        vm.ok = okHandler;

        function init() {
            loadGeofences();
        }

        function updateSelectedGeofences(selectedGeofences, updateSelectedLocations = true) {
            vm.selectedGeofences = selectedGeofences;
            vm.polyline = selectedGeofences.map(geofence => geofence.polyline)
                .join('|') || '';
            $scope.$broadcast('redraw-polyline', { polyline: vm.polyline, updateSelectedLocations });
        }

        function loadGeofences() {
            GeofenceDataService.getGeofences().then(response => {
                vm.geofences = response;
                const selectedGeofenceIds = data.selectedGeofenceIds || [];

                if (selectedGeofenceIds.length) {
                    const selectedGeofences = vm.geofences.filter(geofence => {
                        return selectedGeofenceIds.includes(geofence.id)
                    });

                    updateSelectedGeofences(selectedGeofences, false);
                }
            });
        }

        function updatePolyline(polyline) {
            vm.polyline = polyline;
            const selectedGeofencePolylines = polyline ? polyline.split('|') : [];
            vm.selectedGeofences = vm.geofences.filter(geofence => {
                return geofence.polyline.split('|')
                    .every(polyline => selectedGeofencePolylines.includes(polyline))
            });
        }

        /**
         * Save filtered screens
         *
         * @param locations
         */
        function setLocations(locations) {
            $timeout(() => {
                vm.selectedLocations = locations;
            });
        }

        /**
         * On show selected switch change
         */
        function onShowSelectedChange() {
            vm.locations = vm.showSelectedOnly
                ? angular.copy(vm.selectedLocations)
                : data.locations;
        }

        function unselectAll() {
            vm.selectedLocations = [];
        }

        function invertSelection() {
            vm.selectedLocations = data.locations.filter(location => {
                return vm.selectedLocations.every(selectedLocation => {
                    return selectedLocation.id !== location.id;
                })
            });
            onShowSelectedChange();
        }

        /**
         * "OK" click handler
         */
        function okHandler() {
            const selectedGeofenceIds = vm.selectedGeofences.map(({ id }) => id);
            $uibModalInstance.close({
                locations: vm.selectedLocations,
                selectedGeofenceIds
            });
        }

        /**
         * "Cancel" click handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss();
        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pushCampaignAudienceTab', {
            templateUrl: '/assets/views/campaign/push/tabs/audience/audience.tpl.html',
            controller: CampaignPushAudienceTab,
            bindings: {
                campaignModel: '='
            }
        });

    function CampaignPushAudienceTab(
    ) {
        const vm = this;

        init();

        /**
         * Initialization method
         */
        function init() {
            if (vm.campaignModel.audiencesData) {
                if (angular.isString(vm.campaignModel.audiencesData)) {
                    vm.campaignModel.audiencesData = JSON.parse(vm.campaignModel.audiencesData);
                }
            } else {
                vm.campaignModel.audiencesData = {};
            }
        }
    }
}());
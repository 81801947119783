(function() {
    'use strict';

    angular.module('trmTimetableHafas').service('JourneysProviderService',
        /**
         *
         * @param {MomentService} $moment
         * @param MgateService
         */
        function($moment, MgateService) {
            /**
             * @type {number} - Difference between server and local time in seconds
             */
            let serverTimeDiff;
            let subdomainId;

            return {
                journeys,
            }

            /**
             * @typedef JourneysProviderOptions
             *
             * @property {number} subdomainId
             * @property {number} [limit]
             * @property {{id: string, poles: MgateLocation[]}[]} stations
             * @property {number[]} [transportTypes]
             * @property {number} [viaStops]
             * @property {MctConfig} mctConfig
             * @property {TimetableHafasOptions|{walkingTime: number, boardType: "arr"|"dep"}} timetableOptions
             * @property {ScreenController|undefined} screenController
             * @property {ProductParams} productParams
             */

            /**
             * @param {JourneysProviderOptions} options
             *
             * @return {Promise<MgateStationBoardResponseCore>}
             */
            function journeys(options) {
                subdomainId = options.subdomainId;

                if (!serverTimeDiff) {
                    return _syncServerTime()
                        .then(
                            () => _getJourneys(options)
                        );
                }

                return _getJourneys(options);
            }

            /**
             * @return {Promise<void>}
             * @private
             */
            function _syncServerTime() {
                return MgateService.serverInfo({ subdomainId })
                    .then(response => {
                        const serverTime = $moment(response.sD + response.sT, 'YYYYMMDDHHmmss');
                        serverTimeDiff = serverTime.diff($moment(), 'seconds');
                    });
            }

            /**
             * @param {JourneysProviderOptions} options
             *
             * @return {Promise<MgateStationBoardResponseCore>}
             */
            function _getJourneys(options) {
                const walkingTime = angular.isNumber(options.screenController.locationWalkingTime)
                    ? options.screenController.locationWalkingTime
                    : options.timetableOptions.walkingTime;

                const expectedProductsList = options.productParams.productConfig.map(product => product.cls);
                const transportTypes = options.mctConfig.productOrder
                    .filter(cls => expectedProductsList.includes(cls)); // remove unexpected cls values

                return MgateService.journeys({
                    subdomainId: subdomainId,
                    from: _getServerTime().add(walkingTime, 'minutes'),
                    stations: options.stations,
                    maxJourneys: options.limit,
                    minDur: options.mctConfig.remainingTime,
                    boardType: options.mctConfig.boardType || options.timetableOptions.boardType,
                    transportTypes,
                    onlyRealTime: options.mctConfig.onlyRealtime,
                    journeyNames: options.mctConfig.journeyNameFilter
                        .split(',')
                        .map(item => item.trim())
                        .filter(item => item),
                    journeyNamesFilterMode: options.mctConfig.journeyNameFilterMode,
                    viaStopsNumber: options.viaStops,
                    tracks: options.mctConfig.platformFilter
                        .split(',')
                        .map(item => item.trim())
                        .filter(item => item),
                    tracksFilterMode: options.mctConfig.platformFilterMode,
                });
            }

            /**
             * @return {moment.Moment}
             * @private
             */
            function _getServerTime() {
                return $moment().add(serverTimeDiff, 'seconds');
            }
        });
})();

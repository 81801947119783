(function() {
    'use strict';

    angular.module('beacon.app')
        .service('UserUtilitiesService', UserUtilitiesService);

    function UserUtilitiesService(
        StorageFactory,
        ROLES,
        DEFAULT_LOCATION_OPTIONS,
        APP_MODES,
        ) {
        let currentUserInfo;
        let hasRoleSysAdmin;
        let hasRoleTenantAdmin;
        let hasRoleAdmin;
        let hasRoleUser;
        let isShopTenant;
        let allowedModes;
        let trmModeEnabled;
        let shareParkModeEnabled;
        let isSmartCampusModeEnabled;
        let loginTimeout;
        let timeZoneId;
        let userLocationOptions = {
            longitude: DEFAULT_LOCATION_OPTIONS.LONGITUDE,
            latitude: DEFAULT_LOCATION_OPTIONS.LATITUDE,
            zoom: DEFAULT_LOCATION_OPTIONS.ZOOM,
            maxZoom: DEFAULT_LOCATION_OPTIONS.DEFAULT_MAX_ZOOM,
        };
        let isPasswordExpired;
        let daysToPasswordExpiry;

        /**
         * Initialization method. Sets default values for private variables. Subscribe on change of Main storage.
         */
        function init() {
            allowedModes = [ APP_MODES.TRM.id ];
            trmModeEnabled = true;
            shareParkModeEnabled = false;
            isSmartCampusModeEnabled = false;

            hasRoleSysAdmin = false;
            hasRoleTenantAdmin = false;
            hasRoleAdmin = false;
            hasRoleUser = false;
            isShopTenant = false;
            loginTimeout = null;
            timeZoneId = null;
            isPasswordExpired = false;
            daysToPasswordExpiry = null;

            StorageFactory.Storage('Main').on('currentUserInfo', onChangeCurrentUserInfoHandler);
        }

        /**
         * On change current user info handler. Determines private variables
         * @param {object} userData
         */
        function onChangeCurrentUserInfoHandler(userData) {

            currentUserInfo = userData;

            if (!currentUserInfo) {
                throw new Error('UserUtilitiesService cannot be initialized because Current User Info is missing.');
            }

            let currentTenantId = currentUserInfo.subdomain.tenant.id;

            //  determines if User has role SysAdmin
            hasRoleSysAdmin = _.findIndex(currentUserInfo.roles, ['role_id', ROLES.SYS_ADMIN]) > -1;

            //  gets all user_access in current Tenant
            let userAccessListInCurrentTenant = currentUserInfo.roles
                    .filter((userAccess) => userAccess.tenant_id === currentTenantId);

            //  find the highest User role in current Tenant
            let roleId = hasRoleSysAdmin
                ? ROLES.SYS_ADMIN
                : userAccessListInCurrentTenant.reduce((acc, userAccess) => {
                    if (userAccess.role_id !== ROLES.TENANT_ADMIN
                        && currentUserInfo.subdomain_id !== userAccess.subdomain_id) {
                        return acc;
                    }
                    return userAccess.role_id > acc ? userAccess.role_id : acc;
                }, ROLES.USER);

            switch (roleId) {
                case ROLES.TENANT_ADMIN:
                    hasRoleTenantAdmin = true;
                    break;
                case ROLES.ADMIN:
                    hasRoleAdmin = true;
                    break;
                case ROLES.USER:
                    hasRoleUser = true;
                    break;
                default:
                    break;
            }

            setLoginTimeout(roleId, currentUserInfo.roles, currentUserInfo);

            const tenant = currentUserInfo.subdomain.tenant;
            timeZoneId = tenant.mainTimezoneId;
            const tenantAllowedModes = tenant.allowedModes;
            if (tenant.homeLocation) {
                let mapData = tenant.homeLocation.split(',');
                if (mapData.length === 3) {
                    userLocationOptions.latitude = parseFloat(mapData[0]);
                    userLocationOptions.longitude = parseFloat(mapData[1]);
                    userLocationOptions.zoom = Number(mapData[2]);
                }
            }

            switch (true) {
                case hasRoleSysAdmin:
                    allowedModes = [ APP_MODES.TRM.id, APP_MODES.SHARE_PARK.id, APP_MODES.SMART_CAMPUS.id, APP_MODES.MANAGEMENT.id ];
                    break;
                case hasRoleTenantAdmin:
                    allowedModes = tenantAllowedModes;
                    break;
                default:
                    const userAllowedModes = new Set();

                    userAccessListInCurrentTenant.forEach(userAccess => {
                        if (userAccess.subdomain_id === currentUserInfo.subdomain.id) {
                            userAccess.allowedModes.forEach(mode => userAllowedModes.add(mode))
                        }
                    });
                    allowedModes = [...userAllowedModes].filter(mode => tenantAllowedModes.includes(mode));
            }

            trmModeEnabled = allowedModes.includes(APP_MODES.TRM.id);
            shareParkModeEnabled = allowedModes.includes(APP_MODES.SHARE_PARK.id);
            isSmartCampusModeEnabled = allowedModes.includes(APP_MODES.SMART_CAMPUS.id);

            isShopTenant = Number(currentUserInfo.subdomain.tenant.storeRef) > 0;
            isPasswordExpired = currentUserInfo.password_expired;
            daysToPasswordExpiry = currentUserInfo.password_expire_days;
        }

        /**
         * Sets login timeout
         * @param currentRole
         * @param allUserRoles
         * @param currentUserInfo
         */
        function setLoginTimeout(currentRole, allUserRoles, currentUserInfo) {
            let currentRoles;
            const subdomainId =  currentUserInfo.subdomain.id;
            const tenantId =  currentUserInfo.subdomain.tenant.id;

            switch (currentRole) {
                case ROLES.SYS_ADMIN:
                    currentRoles = allUserRoles
                        .filter(role => role.role_id === ROLES.SYS_ADMIN);
                    break;
                case ROLES.TENANT_ADMIN:
                    currentRoles = allUserRoles.filter(role =>
                        role.role_id === currentRole
                        && role.tenant_id === tenantId
                    );
                    break;
                default:
                    currentRoles = allUserRoles.filter(role =>
                        role.role_id === currentRole
                        && role.tenant_id === tenantId
                        && role.subdomain_id === subdomainId
                    );
            }
            loginTimeout = currentRoles.reduce((acc, role) => {
                return (role.loginTimeout && role.loginTimeout > acc) ? role.loginTimeout : acc;
            }, 0)
        }

        return {
            init,
            hasRoleSysAdmin: () => hasRoleSysAdmin,
            hasRoleTenantAdmin: () => hasRoleTenantAdmin,
            hasRoleAdmin: () => hasRoleAdmin,
            hasRoleUser: () => hasRoleUser,
            userLocationOptions: () => userLocationOptions,
            isShopTenant: () => isShopTenant,
            trmModeEnabled: () => trmModeEnabled,
            shareParkModeEnabled: () => shareParkModeEnabled,
            isSmartCampusModeEnabled: () => isSmartCampusModeEnabled,
            allowedModes: () => allowedModes,
            loginTimeout: () => loginTimeout,
            timeZoneId: () => timeZoneId,
            isPasswordExpired: () => isPasswordExpired,
            daysToPasswordExpiry: () => daysToPasswordExpiry,
        }
    }
})();

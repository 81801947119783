(function() {
    'use strict';

    angular.module('beacon.app')
        .component('unity3dModelForm', {
            templateUrl: '/assets/views/content/unity-3d-models/form/unity-3d-model-form.tpl.html',
            controller: Unity3dModelFormController
        });

    function Unity3dModelFormController(
        $scope,
        $state,
        $stateParams,
        $timeout,
        UtilitiesService,
        ModelsFactory,
        StatesFactory,
        Unity3dModelsDataService,
    ) {
        const vm = this;
        const isEdit = $stateParams.type === 'edit';

        vm.state = StatesFactory.Unity3dModelStates.refresh();
        vm.file = null;
        vm.unity3dModel = null;
        vm.error = null;

        vm.$onInit = init;
        vm.onFileChange = onFileChange;

        function init() {
            $timeout(setErrorChecker);
            vm.unity3dModel = !isEdit
                ? new ModelsFactory.Unity3dModel()
                : $state.params.data;
        }

        function onFinish() {
            saveModel()
                .then(unity3dModel => vm.file && Unity3dModelsDataService.upload(vm.file, unity3dModel))
                .then(_ => $state.go('app.unity3dModels'))
                .catch(response => vm.error = response.data.error);
        }

        function saveModel() {
            return isEdit
                ? Unity3dModelsDataService.update(vm.unity3dModel)
                : Unity3dModelsDataService.create(vm.unity3dModel)
        }

        /**
         *
         * @param {File} file
         */
        function onFileChange(file) {
            vm.file = file;
            vm.uploadError = null;
            $scope.$apply();
        }

        function setErrorChecker() {
            vm.showError = UtilitiesService.inputValidator(vm.form);
        }

        $scope.$watch(
            () => vm.state.finish,
            finish => finish && onFinish(),
        );

        $scope.$watch(
            () => vm.form && vm.form.$valid,
            isFormValid => {
                vm.state.canFinish = isFormValid;
            },
        )
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('openingHoursPicker', {
            templateUrl: '/assets/views/common/components/opening-hours-picker/opening-hours-picker.tpl.html',
            controller: OpeningHoursPickerController,
            bindings: {
                contentForm: '<',
                alwaysOpened: '<',
                lang: '<',
                selectedDays: '<',
                selectedHours: '<',
                onChangeCallback: '&'
            }
        });

    function OpeningHoursPickerController(
        WEEK_DAYS
    ) {
        const vm = this;

        // public properties
        vm.possibleDays = WEEK_DAYS;
        vm.workingHours = [];
        vm.clockPickerOptions = {
            twelvehour: false,
            autoclose: true,
            afterShow: function() {
                // need this workaround to style clockpicker and close it on overlay click
                $('.clockpicker-popover').click(function(event) {
                    if (event.target === this) {
                        $('input[data-lng-clockpicker]').clockpicker('hide');
                    }
                });
                if (!$('.clockpicker:visible').length) {
                    $('.clockpicker-popover').wrapInner('<div class="clockpicker"></div>');
                }
            }
        };

        // public methods
        vm.toggleDay = toggleDay;
        vm.onDataChange = onDataChange;

        /**
         * Toggles day selection
         * @param {number} id
         */
        function toggleDay(id) {
            const index = vm.selectedDays.indexOf(id);

            if (index === -1) {
                vm.selectedDays.push(id);
            } else {
                vm.selectedDays.splice(index, 1);
            }
            onDataChange();
        }

        function onDataChange() {
            const data = {
                always_opened: vm.alwaysOpened,
            };
            if (!vm.alwaysOpened) {
                Object.assign(data, {
                    opening_times: vm.selectedHours,
                    opening_days: vm.selectedDays
                });
            }
            vm.onChangeCallback({data});
        }
    }
})();
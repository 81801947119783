(function() {
    'use strict';

    angular.module('beacon.app')
        .component('onboardingTour', {
            templateUrl: '/assets/views/onboarding-tour/onboarding-tour.tpl.html',
            controller: OnboardingTourController,
        });

    function OnboardingTourController(
        $rootScope,
        $timeout,
        $translate,
        $q,
    ) {
        // Docs: https://github.com/xbsoftware/enjoyhint

        const LS_KEY = 'onboardingToursShown';

        const steps = [
            {
                id: 1,
                selector: '.newBtn',
                description: 'ONBOARDING_NEW_BUTTON',
                onBeforeStart: _ => {
                    _markAsShown(1);
                },
            },
            {
                id: 2,
                selector: '.toolbarButtons',
                description: 'ONBOARDING_TOOLBAR_BUTTONS',
                onBeforeStart: _ => {
                    _markAsShown(2);
                },
            },
        ];

        $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            $timeout(_ => {
                const availableSteps = steps
                    .filter(step => !_wasTourShown(step.id))
                    .filter(step => document.querySelector(step.selector));

                if (availableSteps.length) {
                    translateSteps(availableSteps).then(steps => {
                        if (steps.length) {
                            steps = _configureStepButtons(steps);
                            const instance = new EnjoyHint({});
                            instance.set(steps);
                            instance.run();
                        }
                    });
                }
            });
        });

        function translateSteps(steps) {
            const promises = angular.copy(steps).map(step => {
                return $translate(step.description)
                    .then(translation => {
                        step.description = _htmlDecode(translation);
                        return step;
                    }).catch(_ => {
                        throw new Error(`Can not find a translation for ${step.description}`)
                    });
            });

            return $q.all(promises);
        }

        function _configureStepButtons(steps) {
            return steps.map((step, index) => {
                const isLast = steps.length === (index + 1);
                step.showNext = true;
                step.nextButton = {text: 'OK'};
                step.showPrev = false;
                step.showSkip = false;
                return step;
            })
        }

        function _htmlDecode(input) {
            const doc = new DOMParser().parseFromString(input, 'text/html');
            return doc.documentElement.textContent;
        }

        function _markAsShown(stepId) {
            const string = localStorage.getItem(LS_KEY);
            const toursShown = string ? JSON.parse(string) : [];
            toursShown.push(stepId);
            localStorage.setItem(LS_KEY, JSON.stringify(toursShown));
        }

        function _wasTourShown(stepId) {
            const string = localStorage.getItem(LS_KEY);
            const toursShown = string ? JSON.parse(string) : [];
            return toursShown.includes(stepId);
        }
    }
}());

(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasElements', {
            templateUrl: '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-elements/timetable-hafas-elements.tpl.html',
            controller: TimetableHafasElements,
            bindings: {
                options: '<',
            }
        });

    /**
     * @property {TimetableHafasOptions} options
     *
     * @constructor
     */
    function TimetableHafasElements() {
        const $ctrl = this;

        $ctrl.onlyActiveElements = true;
        $ctrl.TIME_FORMAT_OPTION = {
            COUNTDOWN: 'countdown',
            HHMM: 'hh:mm',
            RELATIVE: 'relative',
        };

        /**
         * @param {TimetableHafasElement} element
         * @return {boolean}
         */
        $ctrl.isElementAvailable = element => {
            if (!$ctrl.onlyActiveElements) {
                return true;
            }

            return ['show', 'auto'].includes(element.behavior);
        }
    }
})();

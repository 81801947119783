(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pushCampaignDetailsTab', {
            templateUrl: '/assets/views/campaign/push/tabs/details/details.tpl.html',
            controller: CampaignPushDetailsTab,
            bindings: {
                campaignModel: '='
            }
        });

    function CampaignPushDetailsTab(
        $q,
        $rootScope,
        $scope,
        $stateParams,
        $translate,
        campaignPushState,
        ContentDataService,
        LanguageService,
        PopupService,
        StorageFactory,
        UtilitiesService,
        FileService,
        CONTENT_TYPES,
        PUSH_CAMPAIGNS_CLICK_OPTIONS,
        WEEK_DAYS,
        ContentHelper,
        GoogleTranslateService,
    ) {
        const vm = this;
        vm.TITLE_MAX_LENGTH = 60;
        vm.DESCRIPTION_MAX_LENGTH = 200;

        const { sortByStringProperty, sortByDateProperty } = UtilitiesService;

        const CLICK_ACTION_NO_ACTION = 0;
        const CLICK_ACTION_START_INFOTAINMENT = 1;

        const SHOW_ACTIONS_ACTIONS_ID = 2;
        const SHOW_ACTIONS_SCREENS_ID = 3;
        const SHOW_ACTIONS_LINKS_ID = 4;

        const DEFAULT_SCREEN_NOTIFICATION_TIMEOUT = 20;

        const DEFAULT_CONFIG = {
            slidingLanguage: true,
            slidingTimeout: 5,
            showFlags: true,
            showTitle: true,
            multiLanguage: true,
            timeout: DEFAULT_SCREEN_NOTIFICATION_TIMEOUT,
        };

        const URL_CREATOR = window.URL || window.webkitURL;

        var allLanguageIDs = [];

        // public properties
        vm.PUSH_CAMPAIGNS_CLICK_OPTIONS = PUSH_CAMPAIGNS_CLICK_OPTIONS;
        vm.state = campaignPushState;
        vm.isTitleEntered = false;
        vm.isDescriptionEntered = false;
        vm.isEditState = $stateParams.type === 'edit';

        vm.iconPickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
            aspectRatio: 1
        };

        vm.imagePickerConfig = {
            minWidth: 600,
            minHeight: 300,
            center: true,
            aspectRatio: 2
        };

        vm.days = WEEK_DAYS;
        vm.ALL_DAYS_FLAG = 9;
        vm.STORAGE = StorageFactory.Storage('PushCampaign');
        vm.zeroDate = new Date(0);
        vm.currentDate = new Date().setSeconds(0, 0); // need for form validation

        vm.$onInit = init;

        // public methods
        vm.onIcon = onIcon;
        vm.onImage = onImage;
        vm.onIconDelete = onIconDelete;
        vm.onImageDelete = onImageDelete;
        vm.onTitleBlur = onTitleBlur;
        vm.onDescriptionBlur = onDescriptionBlur;
        vm.getDefaultTitle = getDefaultTitle;
        vm.pushImmediatelyChange = pushImmediatelyChange;
        vm.copyBtnCallback = copyBtnCallback;
        vm.updateToolbarSettings = updateToolbarSettings;
        vm.translateHandler = translateHandler;
        vm.generateMultiLanguagePush = generateMultiLanguagePush;
        vm.minTimeout = minTimeout;
        vm.getContentTitle = ContentHelper.getTitle;

        // private methods

        /**
         * Get list of content
         */
        function getContents() {
            const requestData = {
                contentTypeId: `${CONTENT_TYPES.INFOTAINMENT},${CONTENT_TYPES.QUIZ},${CONTENT_TYPES.FEEDBACK}`
            };

            ContentDataService.contents(requestData)
                .then((response) => {
                    let data = response;
                    if (data.hasOwnProperty('plain') && typeof data.plain === 'function') {
                        data = data.plain();
                    }

                    vm.contents = vm.STORAGE.set('contentData', data || []);
                    vm.contents.forEach((item) => {
                        item.title = JSON.parse(item.title);
                        item.message = JSON.parse(item.message);
                        item.atitle = item.title[item.language_id];
                    });
                    vm.contents = sortByStringProperty(vm.contents, 'atitle');

                    const selectedContentId = $stateParams.selectedContentId;
                    if (selectedContentId) {
                        vm.campaignModel.content = vm.contents.find(
                            content => selectedContentId === content.id
                        );
                    }
                })
                .catch(console.error.bind(console));
        }

        /**
         * Returns default translation
         *
         * @param {object} item
         * @returns {string}
         */
        function getDefaultTitle(item) {
            let keys = Object.keys(item),
                title = '',
                language;

            if (keys.length) {
                language = vm.langArray.filter(language => {
                    return language.id.toString() === keys[0];
                })[0];

                title = language ? (item[keys[0]] + ' [' + language.code + ']') : '';
            }

            return title;
        }

        /**
         *  Title blur handler
         */
        function onTitleBlur() {
            if (vm.campaignModel.name) {
                vm.isTitleEntered = true;
            }
        }

        /**
         *  Title change handler
         */
        function onTitleChange() {
            if ($stateParams.type === 'new' && !$stateParams.data && !vm.isTitleEntered && !vm.isDescriptionEntered) {
                vm.campaignModel.description = vm.campaignModel.name;
            }
        }

        /**
         *  Description blur handler
         */
        function onDescriptionBlur() {
            vm.isDescriptionEntered = !!vm.campaignModel.description;
        }

        /**
         * Initialization method
         */
        function init() {
            const clickActionActionsUseCase = '2';
            const clickActionScreensUseCase = '3';
            vm.state.type = $stateParams.type;
            getContents();

            if (!vm.campaignModel.push_date || moment(vm.currentDate).isAfter(vm.campaignModel.push_date)) {
                vm.campaignModel.push_date = vm.currentDate;
                vm.campaignModel.pushImmediately = true;
            }

            if (angular.isUndefined(vm.campaignModel.clickOptions.case)) {
                vm.campaignModel.clickOptions.case = CLICK_ACTION_NO_ACTION;
            }

            LanguageService.getLanguages()
                .then(response => {
                    if (_.isArray(response)) {
                        vm.langArray = response;
                        vm.lngId = vm.langArray[0].id;
                        allLanguageIDs = response.map(lang => Number(lang.id));
                    }
                });

            ContentDataService.getBannerConfigs().then(data => {
                vm.clickActionsScreens = data
                    .filter(el => el.useCase === clickActionScreensUseCase);
                vm.clickActionsActions = data
                    .filter(el => el.useCase === clickActionActionsUseCase);
            });

            vm.campaignModel.copy = !!$stateParams.copy;
            updateToolbarSettings();

            vm.config = vm.campaignModel.config || DEFAULT_CONFIG;
            vm.config.timeout = vm.config.timeout || DEFAULT_SCREEN_NOTIFICATION_TIMEOUT;
        }

        /**
         * Image load callback
         * @param imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @param {int|null} languageId
         * @returns {*}
         */
        function onIcon(imageFile, uploadToS3, languageId = null) {
            const lngId = languageId || vm.lngId;
            const campaign = vm.campaignModel;
            campaign.icon[lngId] = imageFile;
            campaign.icon_src[lngId] = uploadToS3
                ? URL_CREATOR.createObjectURL(imageFile)
                : imageFile.name;
        }

        /**
         * Image load callback
         * @param imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @param {int|null} languageId
         * @returns {*}
         */
        function onImage(imageFile, uploadToS3, languageId = null) {
            const lngId = languageId || vm.lngId;
            const campaign = vm.campaignModel;
            campaign.image[lngId] = uploadToS3 ? imageFile : null;
            campaign.image_src[lngId] = uploadToS3
                ? URL_CREATOR.createObjectURL(imageFile)
                : imageFile.name;
        }

        /**
         * Image delete click handler
         */
        function onIconDelete() {
            vm.campaignModel.icon_src[vm.lngId] = null;
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            vm.campaignModel.image_src[vm.lngId] = null;
        }

        /**
         * Handles push immediately checkbox state change
         */
        function pushImmediatelyChange() {
            if (vm.campaignModel.pushImmediately) {
                vm.campaignModel.push_date = new Date();
            }
        }

        /**
         * Handles copy button click
         */
        function copyBtnCallback() {
            const defaultLangId = vm.langArray[0].id;
            const content = vm.campaignModel.content;

            const getTitle = lngId => ContentHelper.getTitle(content, lngId);
            const getText = lngId => UtilitiesService.stripHtmlToText(
                ContentHelper.getMessageText(content, lngId)
            );

            const defaultTitle = getTitle(defaultLangId);
            if (defaultTitle) {
                vm.campaignModel.name = vm.campaignModel.description = defaultTitle;
            }

            const languagesFilled = [];
            vm.langArray.forEach(lang => {
                const title = getTitle(lang.id);
                const text = getText(lang.id);
                if (title && text) {
                    languagesFilled.push(lang.id);
                    vm.campaignModel.title[lang.id] = title.substring(0, vm.TITLE_MAX_LENGTH);
                    vm.campaignModel.text[lang.id] = text.substring(0, vm.DESCRIPTION_MAX_LENGTH);
                }
            });

            if (content.image_src) {
                $q.all([
                    _copyImage(content.image_src, vm.iconPickerConfig),
                    _copyImage(content.image_src, vm.imagePickerConfig),
                ]).then(result => {
                    const iconBlob = result[0];
                    const imageBlob = result[1];

                    languagesFilled.forEach(langId => {
                        vm.onIcon(iconBlob, true, langId);
                        vm.onImage(imageBlob, true, langId);
                    });
                });
            }
        }

        /**
         * Set a copy of image to campaign with cropper
         *
         * @param {string} imageUrl
         * @param {object} cropperConfig
         * @private
         */
        function _copyImage(imageUrl, cropperConfig) {
            return FileService.getRemoteImage(imageUrl)
                .then(response => {
                    if (!response.image) {
                        throw new Error('Error retrieving image data');
                    }

                    const blob = UtilitiesService.base64ImageToBlob(response.image);
                    blob.name = imageUrl;

                    return PopupService.show({
                        templateUrl: '/assets/views/common/popups/crop/crop.tpl.html',
                        controller: 'CropPopupController',
                        controllerAs: 'cropPopupController',
                        windowClass: 'cropPopup',
                        keyboard: false,
                        resolve: {
                            data: () => {
                                const config = angular.copy(cropperConfig);
                                config.file = blob;
                                return config;
                            }
                        }
                    }).catch(console.error.bind(console));
                });
        }

        /**
         * Send broadcast to toolbar
         */
        function updateToolbarSettings() {
            $rootScope.$broadcast('push-settings-changed', {
                allowTargeting: !!(vm.campaignModel.apps && vm.campaignModel.apps.length),
                allowScreens: vm.campaignModel.pushToScreens,
            });
        }

        /**
         * Language click handler
         *
         * @param {object} language
         */
        function translateHandler(language) {
            const currentLang = vm.langArray.find(lang => lang.id === vm.lngId);

            Promise.all([
                $translate(currentLang.name),
                $translate(language.name)
            ]).then(([sourceLangName, targetLangName]) => {
                $translate('TRANSLATE_FROM_TO', {
                    from: sourceLangName,
                    to: targetLangName,
                }).then(translation => {
                    PopupService.showConfirmationPopup({
                        text: translation,
                        okButtonText: 'OK',
                        cancelButtonText: 'CANCEL'
                    }).then(() => {
                        translateTo(language);
                    });
                });
            });
        }

        /**
         * Translate handler
         *
         * @param tgtLang - target language
         */
        function translateTo(tgtLang) {
            const campaign = vm.campaignModel;
            const srcLang = vm.langArray.find(lang => lang.id === vm.lngId);
            const translate = text => GoogleTranslateService.translate(text, srcLang.code, tgtLang.code);

            translate(campaign.title[srcLang.id]).then(text => campaign.title[tgtLang.id] = text);
            translate(campaign.text[srcLang.id]).then(text => campaign.text[tgtLang.id] = text);

            vm.lngId = tgtLang.id;
        }

        /**
         * Creates a multi language push content object
         *
         * @param {object} campaign
         * @returns {object}
         */
        function generateMultiLanguagePush(campaign) {
            if (!vm.langArray) {
                return null;
            }

            const languages = angular.copy(vm.langArray);
            const defaultLang = languages.shift();
            const result = generateSingleLanguagePush(campaign, defaultLang.id);

            result.translations = languages
                .map(lang => generateSingleLanguagePush(campaign, lang.id))
                .filter(item => item.title);
            result.config = campaign.config;
            return result;
        }

        /**
         * Creates a single language push content object
         *
         * @param {object} campaign
         * @param {int} langId
         */
        function generateSingleLanguagePush(campaign, langId) {
            const fieldsMap = {
                title: 'title',
                text: 'text',
                icon_src: 'iconUrl',
                image_src: 'imageUrl',
            };

            const result = {};
            Object.keys(fieldsMap).map(internalKey => {
                const externalKey = fieldsMap[internalKey];
                result[externalKey] = campaign[internalKey][langId] || null
            });

            const language = vm.langArray.find(lang => lang.id === langId);
            result.lang = language.code;
            result.countryFlag = language.countryflags_code;

            return result;
        }

        /**
         * Calculate minimal push timeout for screens
         *
         * @returns {number}
         */
        function minTimeout() {
            const slidingInterval = vm.campaignModel.pushToScreens
                && vm.config.slidingLanguage && vm.config.slidingTimeout;
            const translationsCount = Object.keys(vm.campaignModel.title).length - 1;
            return slidingInterval
                ? vm.config.slidingTimeout * translationsCount
                : DEFAULT_SCREEN_NOTIFICATION_TIMEOUT;
        }

        /**
         * Link config properties
         */
        $scope.$watch(
            () => vm.config,
            () => {
                vm.campaignModel.config = vm.config;
            }, true);

        /**
         * Update preview
         */
        $scope.$watch(
            () => [vm.campaignModel, vm.langArray],
            () => {
                vm.preview = generateMultiLanguagePush(vm.campaignModel);
            }, true);

        /**
         * Change name watcher
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.campaignModel.name;
        }), onTitleChange);

        /**
         * Switch days options watcher
         */
        $scope.$watch(angular.bind(vm, function() {
            const clickCase = Number(vm.campaignModel.clickOptions.case);
            // Content is required if click case is "Open infotainment"
            const isInfotainmentValid = clickCase !== CLICK_ACTION_START_INFOTAINMENT
                || clickCase === CLICK_ACTION_START_INFOTAINMENT && vm.campaignModel.content;
            vm.state.canProcessNext = vm.campaignDetailsForm && vm.campaignDetailsForm.$valid && isInfotainmentValid;
        }), function() {});

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function () {
            return vm.campaignModel.clickOptions.case;
        }), function () {
            vm.showActionsActionsSelect = (parseInt(vm.campaignModel.clickOptions.case, 10) === SHOW_ACTIONS_ACTIONS_ID);
            vm.showActionsScreensSelect = (parseInt(vm.campaignModel.clickOptions.case, 10) === SHOW_ACTIONS_SCREENS_ID);
            vm.showActionsExternalLinkInput = (parseInt(vm.campaignModel.clickOptions.case, 10) === SHOW_ACTIONS_LINKS_ID);
            if (vm.showActionsExternalLinkInput) {
                delete vm.campaignModel.clickOptions.param;
            }
        });
    }

}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PricingDataService', PricingDataService);

    function PricingDataService(
        Restangular,
    ) {
        return {
            getDiscounts,
            createDiscount,
            deleteDiscount,
            getProducts,
            createProduct,
            deleteProduct,
            getTimeWindows,
            getRateQuotas,
            getAllTariffs,
            setTariff,
            deleteTariff,
            getAllTariffModels,
            setTariffModel,
            deleteTariffModel,
        };


        function getDiscounts() {
            return Restangular.all('share-park/discounts').getList();
        }

        function createDiscount(data) {
            return Restangular.all('share-park/discounts')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteDiscount(discountId) {
            return Restangular.one(`share-park/discounts/${discountId}`).remove();
        }

        function getProducts() {
            return Restangular.all('share-park/products').getList();
        }

        function createProduct(data) {
            return Restangular.all('share-park/products')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteProduct(productId) {
            return Restangular.one(`share-park/products/${productId}`).remove();
        }

        function getTimeWindows() {
            return Restangular.all('share-park/getTimeWindows').getList();
        }

        function getRateQuotas() {
            return Restangular.all('share-park/rateQuotas').getList();
        }

        function getAllTariffs() {
            return Restangular.all('share-park/tariffs').getList();
        }

        function setTariff(data) {
            return Restangular.all('share-park/tariffs')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteTariff(tariffId) {
            return Restangular.one(`share-park/tariffs/${tariffId}`).remove();
        }

        function getAllTariffModels() {
            return Restangular.all('share-park/tariff-models').getList();
        }

        function setTariffModel(data) {
            return Restangular.all('share-park/tariff-models')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteTariffModel(tariffModelId) {
            return Restangular.one(`share-park/tariff-models/${tariffModelId}`).remove();
        }
    }
})();
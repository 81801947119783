(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('GeofenceAddController', GeofenceAddController);

    function GeofenceAddController(
        $scope,
        $stateParams,
        $state,
        GeofenceDataService,
        ModelsFactory,
        geofenceAddState,
        locationTabState,
    ) {
        const vm = this;

        // public properties
        vm.state = geofenceAddState;

        init();

        // private methods

        function init() {
            vm.state.type = $stateParams.type;
            vm.geofenceData = (vm.state.type === "new")
                ? new ModelsFactory.Geofence(): $stateParams.data;
        }

        function geofenceResponseHandler(response) {
            $state.go('app.location', {
                paginationData: $stateParams.paginationData,
                data: {
                    selectedTabIndex: locationTabState.views.LOCATIONS.tabs.GEOFENCES.index,
                    selectedSubtabIndex: locationTabState.views.LOCATIONS.tabs.GEOFENCES
                            .subtabs.GEOFENCE_ITEMS.index,
                },
            });
        }

        /**
         * Save/finish action callback
         */
        function onFinish() {
            let promise;
            if (vm.state.type === 'new') {
                promise = GeofenceDataService.createGeofence(vm.geofenceData);
            } else {
                promise = vm.geofenceData.put();
            }
            promise.then(geofenceResponseHandler).catch(console.error.bind(console));
        }

        $scope.$watch(() => vm.state.finish, (newValue) => {
            if (newValue) {
                onFinish();
            }
        });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newBillingConfiguration', {
            templateUrl: '/assets/views/share-park/operations/billing/new/new-billing-configuration.tpl.html',
            controller: NewBillingConfigurationController
        });

    function NewBillingConfigurationController(
        $scope,
        $state,
        $stateParams,
        LanguageService,
        ModelsFactory,
        PopupService,
        StatesFactory,
        BillingConfigurationDataService,
        BillingLayoutPreviewPopupService,
    ) {
        const vm = this;

        const {
            createBillingConfiguration,
            updateBillingConfiguration,
            getBillingTemplates,
        } = BillingConfigurationDataService;

        vm.$onInit = init;

        vm.state = StatesFactory.BillingConfigurationStates.refresh();
        vm.billingTemplates = [];
        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };

        vm.showInputError = showInputError;
        vm.onBillingConfigurationIcon = onBillingConfigurationIcon;
        vm.onBillingConfigurationIconDelete = onBillingConfigurationIconDelete;
        vm.getBillingTemplatePreviewUrl = getBillingTemplatePreviewUrl;
        vm.selectBillingLayout = selectBillingLayout;
        vm.showLayoutPreview = showLayoutPreview;

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.isEditMode = vm.state.type === 'edit';

            vm.billingConfigurationData = !!$stateParams.data
                ? $stateParams.data
                : new ModelsFactory.BillingConfiguration();

            getBillingTemplates().then(response => {
                vm.billingTemplates = response.plain();

                if (!vm.billingConfigurationData.bill_layout && vm.billingTemplates[0]) {
                    vm.billingConfigurationData.bill_layout = vm.billingTemplates[0].file;
                }
            })
        }

        /**
         * Ob finish handler
         */
        function onFinish() {
            const formData = new FormData();

            const billingConfigData = angular.copy(vm.billingConfigurationData);
            billingConfigData.start_at = moment(billingConfigData.start_at).utc().format('YYYY-MM-DD HH:mm:ss');

            formData.append('billingConfiguration', angular.toJson(billingConfigData));
            formData.append('billIcon', vm.billIcon);

            if (!vm.isEditMode) {
                createBillingConfiguration(formData)
                    .then(onSuccess)
                    .catch(errorHandler);
            } else {
                updateBillingConfiguration(vm.billingConfigurationData, formData)
                    .then(onSuccess)
                    .catch(errorHandler);
            }
        }

        function onSuccess() {
            $state.go('app.billingConfigurations', {
                paginationData: $stateParams.paginationData
            });
        }

        function errorHandler(err) {
            if (angular.isString(err.data.error)) {
                PopupService.showAlertPopup({
                    text: err.data.error,
                    okButtonText: 'OK'
                });
            }
            console.log(err)
        }

        /**
         * Checks is error must be shown
         * @param { String } name
         * @return {boolean}
         */
        function showInputError(name) {
            return vm.newBillingConfigurationForm
                && vm.newBillingConfigurationForm[name].$dirty
                && vm.newBillingConfigurationForm[name].$invalid;
        }

        /**
         * @param { blob } imageFile
         */
        function onBillingConfigurationIcon(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            vm.billIcon = imageFile;
            vm.billingConfigurationData.icon_src = urlCreator.createObjectURL(imageFile);
        }

        function onBillingConfigurationIconDelete() {
            vm.billIcon = null;
            vm.billingConfigurationData.icon_src = null;
        }

        /**
         * Returna billing config preview url
         * @param templateData
         * @returns {string}
         */
        function getBillingTemplatePreviewUrl(templateData) {
            return `${location.origin}/sharePark/billing-configuration/templates/preview/${templateData.file}`
        }

        /**
         * On billing layout select
         * @param layout
         */
        function selectBillingLayout(layout) {
            vm.billingConfigurationData.bill_layout = layout.file;
        }

        /**
         * Shows layout preview
         * @param $event
         * @param layout
         */
        function showLayoutPreview($event, layout) {
            $event.preventDefault();
            $event.stopPropagation();

            BillingLayoutPreviewPopupService.showPreview(layout.file);
        }

        $scope.$watch(() => {
            return vm.newBillingConfigurationForm && vm.newBillingConfigurationForm.$valid;
        }, formValid => {
            vm.state.canFinish = !!formValid;
        }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('HabitToolbarController', HabitToolbarController);

    function HabitToolbarController($state, habitEditState, PermissionsService) {
        const vm = this;

        // public properties
        vm.state = habitEditState;
        vm.state.canProcessNext = false;
        vm.state.view = 0;
        vm.state.finish = 0;

        // public methods
        vm.cancel = cancel;
        vm.next = next;
        vm.back = back;
        vm.showBack = showBack;
        vm.showCancel = showCancel;
        vm.finish = finish;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;
        vm.isLastTab = isLastTab;


        const { generateHabitPermission, actions } = PermissionsService;

        vm.modifyHabitPermission = generateHabitPermission.bind(null, actions.modify);

        /**
         * Cancel button callback
         */
        function cancel() {
            $state.go('app.habit');
        }

        /**
         * Show cancel button
         * @returns {boolean}
         */
        function showCancel() {
            return vm.state.view === 0 || (vm.state.view === 1 && vm.state.type === 'edit') || vm.state.copy === true;
        }

        /**
         * Show back button
         * @returns {boolean}
         */
        function showBack() {
            return vm.state.view !== 0 && !showCancel();
        }

        /**
         * Switches to selected tab
         * @param {number} tab
         */
        function goToTab(tab) {
            if (tab < vm.state.view) {
                vm.state.view = tab;
            }
        }

        /**
         * Returns tab class
         * @param {number} tab
         * @returns {string}
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view++;
                vm.state.canBack = true;
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.canProcessNext = false;
            vm.state.view--;
        }

        /**
         * Saves content
         */
        function finish() {
            vm.state.finish = true;
        }

        /**
         * Check if current tab is last one
         */
        function isLastTab() {
            let lastTabIndex = 0;
            Object.keys(vm.state.views).forEach(key => {
                if (vm.state.views[key] > lastTabIndex) {
                    lastTabIndex = vm.state.views[key];
                }
            });
            return vm.state.view === lastTabIndex;
        }
    }
}());

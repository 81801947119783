(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ChargingStationDataService', ChargingStationDataService);

    function ChargingStationDataService(
        Restangular,
    ) {
        return {
            getAll,
            create,
            edit,
            remove,
            plugTypes,
        };

        /**
         * Get list of car parks
         * @return {Promise<any>}
         */
        function getAll() {
            return Restangular.all('sharePark/charging-station').getList();
        }

        /**
         * @param chargingStation
         * @returns {Promise<any>}
         */
        function create(chargingStation) {
            return Restangular.all('sharePark/charging-station').post(chargingStation);
        }

        /**
         * @param chargingStation
         * @returns {Promise<any>}
         */
        function edit(chargingStation) {
            return Restangular.all('sharePark/charging-station/' + chargingStation.id).customPUT(chargingStation);
        }

        /**
         * @param chargingStation
         * @returns {Promise<any>}
         */
        function remove(chargingStation) {
            return Restangular.all('sharePark/charging-station/' + chargingStation.id).customDELETE();
        }

        /**
         * Get list of plug types
         * @return {Promise<any>}
         */
        function plugTypes() {
            return Restangular.one('sharePark/charging-station/plug-types').getList();
        }
    }
})();
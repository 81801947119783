(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ScenarioDataService', ScenarioDataService);

    function ScenarioDataService(Restangular) {

        return {
            createScenario,
            deleteScenario,
            getScenarios,
            getScenarioContents,
        };


        /**
         * Creates new scenario
         * @param {object} data
         * @return {*}
         */
        function createScenario(data) {
            return Restangular.all('scenario').post(data);
        }

        /**
         * Delete scenario by it`s id
         *
         * @param {object} id
         */
        function deleteScenario(id) {
            return Restangular.one('scenario', id).remove();
        }

        /**
         * Get list of scenarios
         * @param {object} data
         * @return {*}
         */
        function getScenarios(data) {
            return Restangular.all('scenario').getList(data);
        }

        /**
         * Get list of contents which can be attached to the scenario
         * @return {array}
         */
        function getScenarioContents() {
            return Restangular.all('scenario/getScenarioContents').getList();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ScreenHelper', ScreenHelperService);

    function ScreenHelperService(
        ACTIVATION_STATUS,
    ) {

        return {
            createFormData,
            mergeScreenWithControllerData,
            getStatusName,
            getScreenCoordinates,
            screenUrl,
        };

        function createFormData(screenController, distribute, selectedScreenGroups) {
            const controller = angular.copy(screenController);
            controller.locationStationIds = angular.toJson(controller.locationStationIds);

            const formData = new FormData();
            formData.append('screenController', getConrollerDataJSON(controller));
            distribute && formData.append('distribute', angular.toJson(distribute));

            if (selectedScreenGroups && selectedScreenGroups.length) {
                formData.append('screenGroupIds', angular.toJson(selectedScreenGroups.map(group => group.id)));
            }

            controller.controllerImage && formData.append('controllerImage', controller.controllerImage);
            controller.screenImage && formData.append('screenImage', controller.screenImage);

            return formData;
        }

        /**
         * Processes controller data and converts it to JSON
         * @param {Object} screenController
         * @return {string}
         */
        function getConrollerDataJSON(screenController) {
            const controllerData = angular.copy(screenController);
            if (controllerData.latlng) {
                const controllerCoords = controllerData.latlng.split(',');
                controllerData.lat = controllerCoords[0];
                controllerData.lng = controllerCoords[1];
            }

            if (controllerData.screen.latlng) {
                const screen = controllerData.screen.latlng.split(',');
                controllerData.screen.lat = screen[0];
                controllerData.screen.lng = screen[1];
            }

            if (controllerData.controllerImage instanceof Blob) {
                delete controllerData.controllerImage;
            }

            if (controllerData.screenImage instanceof Blob) {
                delete controllerData.screenImage;
            }

            controllerData.station.stopInternalId = controllerData.station.stopId;

            return angular.toJson(controllerData);
        }

        /**
         * Merges screen data with responding controller data
         * @param screen
         * @param screenControllers
         * @return {*}
         */
        function mergeScreenWithControllerData(screen, screenControllers) {
            const currentLocationDetails = screenControllers.find(controller =>
                screen.controllerRef === parseInt(controller.id)
            );
            if (!!currentLocationDetails && currentLocationDetails.screen.lat) {
                screen.name = currentLocationDetails.name;
                screen.lat = parseFloat(currentLocationDetails.screen.lat);
                screen.lng = parseFloat(currentLocationDetails.screen.lng);
                screen.markers = [{
                    label: currentLocationDetails.name,
                    position: {
                        lat: screen.lat,
                        lng: screen.lng
                    },
                }];
                screen.details = currentLocationDetails;
            }

            return screen;
        }

        function getStatusName(value) {
            const key = Object.keys(ACTIVATION_STATUS).find(key => ACTIVATION_STATUS[key].value === value);
            return key
                ? ACTIVATION_STATUS[key].label
                : `STATUS_${value}`;
        }

        function getScreenCoordinates(controllerData, form = null) {
            if ((form && form.coordinates.$invalid) || !controllerData.latlng) {
                return null;
            }

            const coords = controllerData.latlng.split(',');
            return {
                latitude: Number(coords[0]),
                longitude: Number(coords[1])
            };

        }

        /**
         * @param {{hash: string}} screen
         * @return {string}
         */
        function screenUrl(screen) {
            return '/screen/index.html?hash=' + screen.hash;
        }
    }
}());

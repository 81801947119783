(function() {
    'use strict';

    angular.module('beacon.app')
        .service('UserDataService', UserDataService);

    function UserDataService(
        Restangular,
        DateHelper,
        StorageFactory,
        ) {

        const { setDefaultDateFormat } = DateHelper;

        return {
            currentUserInfo,
            loadUserData,
            getUsers,
            createUser,
            deleteUser,
            getUserRoles,
            deleteUserFromSubdomain,
            changeUserStatus,
            changePassword,
            setUILanguage,
            // REMOVED DUE TO SECURITY REPORT
            // resetPassword,
            setZoomProperty,
        };

        /**
         * Returns current user info
         */
        function currentUserInfo() {
            return Restangular.one('profile').get();
        }

        function loadUserData() {
            return new Promise((resolve, reject) => {
                let STORAGE = StorageFactory.Storage('Main');
                let userDataModel = STORAGE ? STORAGE.get('currentUserInfo') : null;

                if (userDataModel) {
                    resolve(userDataModel);
                    return;
                }

                currentUserInfo().then((data) => {
                    userDataModel = STORAGE.set('currentUserInfo', data.plain());
                    setDefaultDateFormat(userDataModel.subdomain.tenant.dateFormat);
                    resolve(userDataModel);
                });
            });
        }

        /**
         * Returns return list of users
         */
        function getUsers(data) {
            return Restangular.all('users').getList(data);
        }

        /**
         * Creates new user
         *
         * @param {object} data
         */
        function createUser(data) {
            return Restangular.all('users').post(data);
        }

        /**
         * Delete user by it`s id
         *
         * @param {object} id
         */
        function deleteUser(id) {
            return Restangular.one('users', id).remove();
        }

        /**
         * Delete a User from Subdomain
         *
         * @param tenantId
         * @param subdomainId
         * @param userId
         */
        function deleteUserFromSubdomain(tenantId, subdomainId, userId) {
            let url = ['tenants', tenantId, 'subdomains', subdomainId, 'users', userId].join('/');
            return Restangular.one(url).remove();
        }

        /**
         * Get User's roles
         *
         * @param {string} userId
         */
        function getUserRoles(userId) {
            let url = ['users', userId, 'roles'].join('/');
            return Restangular.all(url).getList();
        }

        /**
         * Change user status
         *
         * @param userId
         * @param status
         */
        function changeUserStatus(userId, status) {
            let url = ['users', userId, 'status', status].join('/');
            return Restangular.one(url).customPUT();
        }

        /**
         * Reset & send user password to the email
         *
         * @param userId
         */
        // REMOVED DUE TO SECURITY REPORT
        // function resetPassword(userId) {
        //     let url = ['users', 'reset-password', userId].join('/');
        //     return Restangular.one(url).post();
        // }

        /**
         * Changes user's password
         *
         * @param {string} password
         * @param {string} passwordOld
         */
        function changePassword(password, passwordOld) {
            return Restangular.all('profile/change-password').post({password, passwordOld});
        }

        /**
         * Sets user's password
         *
         * @param langId
         */
        function setUILanguage(langId) {
            return Restangular.all('profile/set-ui-language').post({langId});
        }

        function setZoomProperty(zoom) {
            return Restangular.all('profile/set-zoom-property').post({zoom});
        }
    }
})();

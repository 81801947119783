(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ContentImageProcessingService', contentImageProcessingService);

    function contentImageProcessingService() {
        return {
            onImage,
            onImageDelete
        };
    }

    /**
     * Image file input handler
     * @param {object} contentData
     * @param {object} imageFile
     * @param {boolean} uploadToS3 Need upload to S3
     * @returns {*}
     */
    function onImage(contentData, imageFile, uploadToS3) {
        const urlCreator = window.URL || window.webkitURL;
        contentData.image = uploadToS3 ? imageFile : null;
        contentData.image_src = uploadToS3
            ? urlCreator.createObjectURL(imageFile)
            : imageFile.name;
    }

    /**
     * Image delete click handler
     * @param {object} contentData
     */
    function onImageDelete(contentData) {
        delete contentData.image;
        contentData.image_src = null;
    }
})();
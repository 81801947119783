(function () {
    'use strict';

    angular.module('beacon.app').component('sponsorList', {
        templateUrl: '/assets/views/loyalty/sponsor/sponsor.tpl.html',
        controller: SponsorListController,
    });

    function SponsorListController(
        $state,
        SponsorDataService,
    ) {
        const vm = this;

        vm.sponsors = [];
        vm.sponsorsCount = 0;

        vm.listConfig = {
            columns: [
                {
                    name: 'COMPANY_LOGO',
                    width: '20',
                    title: listItem => listItem.logoRef ? `<img class="sponsor-logo" src="${listItem.logoRef}">` : '',
                },
                {
                    name: 'NAME',
                    class: 'guests-list-name',
                    width: '25',
                    title: listItem => listItem.name,
                },
                {
                    name: 'CITY',
                    width: '25',
                    title: listItem => listItem.legalAddress.city ? listItem.legalAddress.city : '',
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'editBtn',
                        callback: editSponsor,
                        permissionAction: 'can_modify_sponsor'
                    },
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        vm.createSponsor = createSponsor;

        /**
         * Update data on pagination
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateList(page = 1, itemsPerPage = 25) {
            const data = { page, itemsPerPage };

            SponsorDataService.getSponsors(data)
                .then(response => {
                    vm.sponsors = response.map(sponsor => {
                        sponsor.branchInfo = sponsor.branchInfo ? JSON.parse(sponsor.branchInfo) : '';
                        sponsor.legalAddress = sponsor.legalAddress ? JSON.parse(sponsor.legalAddress) : '';
                        return sponsor;
                    });

                    vm.sponsorsCount = response.count;
                })
        }

        function createSponsor() {
            $state.go('app.loyaltySponsorAdd');
        }

        /**
         * Update data on pagination
         *
         * @param event
         * @param {SponsorConfig} listItem
         */
        function editSponsor($event, listItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.loyaltySponsorEdit', { data: listItem });
        }

        function generatePermissionName(button) {
            return button.permissionAction;
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ChargingStationToolbarController', ChargingStationToolbarController)

    function ChargingStationToolbarController($state, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ShareParkChargingStationStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;
        vm.state.canFinish = false;

        vm.cancel = cancel;
        vm.finish = finish;

        function cancel() {
            $state.go('app.chargingStations');
        }

        function finish() {
            vm.state.finish++;
        }
    }
})();
(function() {
    'use strict';

    angular.module('timetableOptions')
        .constant('TIMETABLE_OPTIONS', {
            additionalTime: {
                id: 1,
                label: 'WALKING_TIME_MINUTES',
                prop: 'additionalTime',
                type: 'int',
                defaultValue: 0,
            },
            boardType: {
                id: 2,
                label: 'BOARD_TYPE',
                prop: 'boardType',
                type: 'select',
                options: [
                    {
                        title: 'DEPARTURES',
                        value: 'dep',
                    },
                    {
                        title: 'ARRIVALS',
                        value: 'arr',
                    },
                ],
                defaultValue: 'dep',
            },
            showTimes: {
                id: 3,
                label: 'SHOW_TIMES',
                prop: 'showTimes',
                type: 'int',
                defaultValue: 0,
                disabled: true,
            },
            remainingTime: {
                id: 12,                                 // item with id 12 put here because we need to keep the order
                label: 'REMAINING_TIME',
                prop: 'remainingTime',
                type: 'int',
                defaultValue: 0,
            },
            showViaStops: {
                id: 4,
                label: 'SHOW_VIA_STOPS',
                prop: 'showViaStops',
                type: 'int',
                defaultValue: 0,
            },
            maxJourneys: {
                id: 13,
                label: 'MAX_JOURNEYS',
                prop: 'maxJourneys',
                type: 'int',
                defaultValue: 20,
            },
            him: {
                id: 5,
                label: 'HIM',
                prop: 'him',
                type: 'bool',
                defaultValue: true,
            },
            headline: {
                id: 6,
                label: 'HEADLINE',
                prop: 'headline',
                type: 'bool',
                defaultValue: true,
            },
            onlyRealtime: {
                id: 6,
                label: 'ONLY_REALTIME',
                prop: 'rtOnly',
                type: 'bool',
                defaultValue: false,
            },
            journeyNameFilter: {
                id: 7,
                label: 'LINES',
                prop: 'journeyNameFilter',
                type: 'text',
                defaultValue: '',
                mode: {
                    trueLabel: 'INCLUDING',
                    falseLabel: 'EXCLUDING',
                    prop: 'journeyNameFilterMode',
                    trueValue: 'INC',
                    falseValue: 'EXC',
                    defaultValue: 'INC',
                }
            },
            platformFilter: {
                id: 8,
                label: 'TRACKS',
                prop: 'platformFilter',
                type: 'text',
                defaultValue: '',
                mode: {
                    trueLabel: 'INCLUDING',
                    falseLabel: 'EXCLUDING',
                    prop: 'platformFilterMode',
                    trueValue: 'INC',
                    falseValue: 'EXC',
                    defaultValue: 'INC',
                }
            },
            direction: {
                id: 9,
                label: 'DIRECTION',
                prop: 'direction',
                type: 'direction',
                defaultValue: {},
                mode: {
                    isDisabled: true,
                    trueLabel: 'INCLUDING',
                    falseLabel: 'EXCLUDING',
                    prop: 'directionFilterMode',
                    trueValue: 'INC',
                    falseValue: 'EXC',
                    defaultValue: 'INC',
                }
            },
            poleFilter: {
                id: 10,
                label: 'POLE_FILTERS',
                prop: 'poleFilter',
                type: 'object',
                defaultValue: {},
                namesProp: 'poleFilterName'
            },
            productOrder: {
                id: 11,
                label: 'TRANSPORT_MEANS',
                prop: 'productOrder',
                type: 'transport-means-filter',
                defaultValue: [],
            },
            poleFilters: {
                id: 14,
                label: 'POLE_FILTERS_MULTIPLE',
                prop: 'poleFilters',
                type: 'object',
                defaultValue: {},
            },
        })
        .constant('TIMETABLE_ORDER', {
            PRODUCT: 'product',
            REAL_TIME: 'timeReal',
            SCHEDULED_TIME: 'time',
        })
        .constant('NO_JOURNEYS_VIEW_MODE', {
            DEFAULT: 'default',
            IMAGE: 'image',
            TEXT: 'text',
        })
    ;
}());

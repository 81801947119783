(function() {
    'use strict';

    angular.module('beacon.app')
        .service('GoogleMapsUtilitiesService', GoogleMapsUtilitiesService);

    function GoogleMapsUtilitiesService(
        PopupService,
    ) {
        const geocoder = new google.maps.Geocoder;

        const MAP_LOCATION_PROP_TO_ADDRESS_COMPONENT_TYPE = [
            {
                type: 'locality',
                source_prop: 'long_name',
                target_prop: 'locationLocality',
            },
            {
                type: 'administrative_area_level_2',
                source_prop: 'long_name',
                target_prop: 'locationSubAdminArea',
            },
            {
                type: 'administrative_area_level_1',
                source_prop: 'long_name',
                target_prop: 'locationAdminArea',
            },
            {
                type: 'country',
                source_prop: 'long_name',
                target_prop: 'locationCountryName',
            },
            {
                type: 'country',
                source_prop: 'short_name',
                target_prop: 'locationCountryCode',
            },
            {
                type: 'postal_code',
                source_prop: 'long_name',
                target_prop: 'locationPostalCode',
            },
            {
                type: 'route',
                source_prop: 'long_name',
                target_prop: 'locationStreetName',
            },
            {
                type: 'street_number',
                source_prop: 'long_name',
                target_prop: 'locationStreetNumber',
            },
        ];

        /**
         * Gets location data object using coordinates
         * @param coords
         * @return {Promise<any>}
         */
        function getLocationDataByCoordinates(coords) {
            return new Promise((resolve, reject) => {
                geocoder.geocode({'location': {lat: coords.latitude, lng: coords.longitude}}, function(results, status) {
                    if (status !== 'OK' || !results[0]) {
                        return reject('Location data not found');
                    }

                    resolve(parseLocationData(results[0]));
                });
            });
        }

        /**
         * Gets coordinates using location
         * @param location
         * @return {Promise<any>}
         */
        function getCoordinatesByLocation(location) {
            return new Promise((resolve, reject) => {
                geocoder.geocode({'address': location}, function(results, status) {
                    if (status !== 'OK' || !results[0]) {
                        return reject('Coordinates not found');
                    }

                    const latitude = results[0].geometry.location.lat();
                    const longitude = results[0].geometry.location.lng();
                    resolve(`${latitude},${longitude}`);
                })
            });
        }

        /**
         * Parses data from googlemaps api to the format needed
         * @param locationData
         * @return {{locationAddressLine: *}}
         */
        function parseLocationData(locationData) {
            const result = {
                locationAddressLine: locationData.formatted_address,
            };

            MAP_LOCATION_PROP_TO_ADDRESS_COMPONENT_TYPE.forEach(mapping => {
                const sourceObject = locationData.address_components.find(component =>
                    component.types.includes(mapping.type)
                );
                if (!!sourceObject) {
                    result[mapping.target_prop] = sourceObject[mapping.source_prop];
                }
            });

            return result;
        }

        /**
         * Removes location data from source obj
         * @param { Object } sourceObj
         */
        function clearLocationData(sourceObj) {
            MAP_LOCATION_PROP_TO_ADDRESS_COMPONENT_TYPE.forEach(mapping => {
                delete sourceObj[mapping.target_prop];
            });

            delete sourceObj.locationAddressLine;
            delete sourceObj.locationTimeZoneId;
        }

        /**
         * Show map
         *
         * @param data
         */
        function showMap(data) {
            const defaults = {
                okButtonText: 'OK',
                cancelButtonText: 'CANCEL',
                mapInfo: [],
                mapDefaults: {},
                canEdit: false
            };

            return PopupService.show({
                templateUrl: '/assets/views/common/popups/map/map.tpl.html',
                controller: 'MapPopupController',
                controllerAs: 'mapPopupController',
                windowClass: 'mapPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return Object.assign(defaults, data);
                    }
                }
            });
        }

        return {
            getLocationDataByCoordinates,
            getCoordinatesByLocation,
            clearLocationData,
            showMap,
        };
    }
})();

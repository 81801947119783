(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('LoyaltyCampaignsListToolbarController', LoyaltyCampaignsListToolbarController);

    function LoyaltyCampaignsListToolbarController($scope, StatesFactory) {
        const vm = this;
        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.LoyaltyCampaignListStates);
        vm.state = vm.stateAss.state;
        vm.back = back;

        /**
         * Back button handler
         */
        function back() {
            vm.state.onBack = true;
        }
    }
}());
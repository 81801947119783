(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ContentGroupAddController', ContentGroupAddController);

    function ContentGroupAddController(
        $scope, $state, $stateParams,
        contentGroupState, LanguageService, ContentDataService
    ) {
        const vm = this;

        // public properties
        vm.state = contentGroupState;
        vm.langArray = [];
        vm.icons = [];
        vm.contentGroupData = {};
        vm.imageChanged = false;
        vm.loaderConfig = {
            minWidth: 400,
            minHeight: 400,
            center: true,
            fullInfo: true,
            aspectRatio: 1
        };

        // public methods
        vm.onIconChoose = onIconChoose;
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;

        /**
         * Icon choose handler
         *
         * @param {object} iconData
         */
        function onIconChoose(iconData) {
            vm.contentGroupData.icon_id = iconData.id;
            vm.contentGroupData.type = iconData.infotainmentTypeRef;
            vm.contentGroupData.default_category_image = iconData.imageUrl
        }

        /**
         * Image field change handler
         *
         * @param imageFile
         * @param uploadToS3
         */
        function onImage(imageFile, uploadToS3) {
            if (uploadToS3) {
                const urlCreator = window.URL || window.webkitURL;
                vm.contentGroupData.image = imageFile.blob;
                vm.contentGroupData.image_url = urlCreator.createObjectURL(imageFile.blob);
            }
        }

        /**
         * Image delete handler
         */
        function onImageDelete() {
            vm.contentGroupData.image = null;
            vm.contentGroupData.image_url = '';
        }

        init();

        // private methods

        /**
         * Save action callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onFinish(newValue, oldValue) {
            if (!newValue) { return; }

            vm.contentGroupForm.title.$setValidity('uniqueness', true);
            vm.contentGroupForm.$submitted = true;

            if (vm.contentGroupForm.$valid) {
                const data = new FormData();
                data.append('title', JSON.stringify(vm.contentGroupData.title));
                data.append('icon_id', vm.contentGroupData.icon_id);
                data.append('type', vm.contentGroupData.type);
                data.append('image_url', vm.contentGroupData.image_url);
                data.append('default_category_image', vm.contentGroupData.default_category_image);
                if (vm.contentGroupData.image) {
                    data.append('image', vm.contentGroupData.image);
                }
                saveChanges(data);
            }
        }

        function saveChanges(data) {
            if (vm.state.type === 'new') {
                ContentDataService.createContentGroup(data)
                    .then(function() {
                        $state.go('app.contentGroup', {});
                    })
                    .catch(function() {
                        vm.contentGroupForm.title.$setValidity('uniqueness', false);
                    });

                return;
            }

            ContentDataService.contentGroup(vm.contentGroupData.id)
                .then(function(contentGroup) {
                    data.append('_method', 'PUT');
                    ContentDataService.updateContentGroup(contentGroup, data)
                        .then(function() {
                            $state.go('app.contentGroup', {});
                        })
                        .catch(function() {
                            vm.contentGroupForm.title.$setValidity('uniqueness', false);
                        });
                });
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;

            LanguageService.getLanguages().then(function(response) {
                vm.langArray = response;

                if (!vm.contentGroupData.language_id) {
                    vm.contentGroupData.language_id = vm.langArray[0].id;
                }
            });

            ContentDataService.getContentGroupIcons().then(function(response) {
                vm.icons = response;

                if (response.count && vm.state.type === 'new') {
                    vm.contentGroupData.icon_id = parseInt(vm.icons[0].id);
                    vm.contentGroupData.type = vm.icons[0].infotainmentTypeRef;
                    vm.contentGroupData.default_category_image = vm.icons[0].imageUrl;
                }

                if (vm.state.type === 'edit') {
                    const { title, icon_id, type, id, default_category_image, image_url } = $stateParams.data;

                    vm.contentGroupData.title = title;
                    vm.contentGroupData.icon_id = icon_id;
                    vm.contentGroupData.type = type;
                    vm.contentGroupData.id = id;
                    vm.contentGroupData.default_category_image = default_category_image;

                    // workaround: set bottom image only if it was uploaded manually (URLs will not be the same in that case)
                    if (default_category_image !== image_url) {
                        vm.contentGroupData.image_url = image_url;
                    }
                }

                vm.contentGroupData.cropped = {
                    x: 0,
                    y: 0,
                    width: 400,
                    height: 400,
                    rotate: 0,
                    img: vm.contentGroupData.imageUrl ? vm.contentGroupData.imageUrl : null
                };
            });

            $scope.$watch(angular.bind(vm, function() {
                return this.state.finish;
            }), onFinish);
        }
    }
}());

/**
 * @var SunCalc https://github.com/mourner/suncalc
 */
(function() {
    'use strict';

    angular.module('trmServices').service('ExpectedError', function (

    ) {
        class ExpectedError extends Error {
            constructor(message) {
                super(message);

                this.name = 'ExpectedError';
            }
        }

        return ExpectedError;
    });
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentDetailsTab', {
            templateUrl: '/assets/views/content/elements/new/tabs/content-details/content-details.tpl.html',
            controller: ContentDetailsTab
        });

    function ContentDetailsTab(
        $scope,
        StorageFactory,
        ModelsFactory,
        ContentDataService,
        UtilitiesService,
        LanguageService,
        UserDataService,
        PermissionsService,
        CONTENT_TYPES,
        DEFAULT_CONTENT_GROUP,
    ) {
        const vm = this;
        const { checkContentType, oneOfContentTypes } = UtilitiesService;
        const singleLanguageTypes = [
            'Rss',
        ];

        /**
         * These content types have a new database structure since October 2018
         */
        const refactoredStructureContentTypes = [
            CONTENT_TYPES.FEEDBACK,
            CONTENT_TYPES.QUIZ,
            CONTENT_TYPES.BANNER,
            CONTENT_TYPES.ONBOARDING,
            CONTENT_TYPES.LINE_ROUTE,
        ];

        /**
         * Exports
         */
        vm.checkContentType = checkContentType;
        vm.oneOfContentTypes = oneOfContentTypes;

        vm.$onInit = init;

        function init() {
            vm.dataLoaded = false;
            vm.STORAGE = StorageFactory.Storage('Content');
            vm.STORAGE.on('contentData', (value) => (vm.contentData = value));

            let DataPromises = [];

            vm.langArray = vm.STORAGE.get('langArray');
            vm.contentGroupsArray = vm.STORAGE.get('contentGroupsArray');

            DataPromises.push(UserDataService.loadUserData().then(function(data){
                vm.userData = data;
            }));

            if (!vm.contentGroupsArray) {
                if (!PermissionsService.isContentGroupMenuItemVisible()) {
                    /**
                     * TODO: 31.01.2018 serhii.kokovskyi If you do not have permission to see/load all content groups
                     *                  then will be using only default content group
                     */
                    vm.contentGroupsArray = vm.STORAGE.set('contentGroupsArray', [DEFAULT_CONTENT_GROUP]);
                    vm.contentGroupsArray.forEach((item) => {
                        item.title = angular.fromJson(item.title);
                    });
                } else {
                    DataPromises.push(loadContentGroups());
                }
            }

            if (!vm.langArray) {
                DataPromises.push(loadLanguageData());
            }

            Promise.all(DataPromises)
                .then(() => onDataLoaded())
                .catch((error) => console.error(error));
        }

        function loadContentGroups() {
            return ContentDataService.contentGroups()
                .then((response) => {
                    vm.contentGroupsArray = vm.STORAGE.set('contentGroupsArray', response.plain());
                    vm.contentGroupsArray.forEach((item) => {
                        item.title = angular.fromJson(item.title);
                    });
                });
        }

        function loadLanguageData() {
            return LanguageService.getLanguages().then((response) => {
                vm.langArray = vm.STORAGE.set('langArray', response.plain());
            });
        }

        /**
         * When all data load
         * 1. Process loaded data
         * 2. Set model
         * 3. Update view
         */
        function onDataLoaded() {
            processContentGroupsData();
            processLanguageData();

            let content = null;
            switch (Number(vm.contentData.content_type_id)) {
                case CONTENT_TYPES.TICKER:
                    content = new ModelsFactory.Ticker();
                    break;

                case CONTENT_TYPES.TIMETABLE:
                    content = new ModelsFactory.Timetable();
                    break;

                case CONTENT_TYPES.WEATHER:
                    content = new ModelsFactory.Weather();
                    break;

                case CONTENT_TYPES.QUIZ:
                case CONTENT_TYPES.FEEDBACK:
                    content = new ModelsFactory.Quiz();
                    break;

                case CONTENT_TYPES.DIGITAL_DISPLAY:
                    content = new ModelsFactory.DigitalDisplay();
                    break;

                case CONTENT_TYPES.EXTERNAL_APP:
                    content = new ModelsFactory.ExternalApp();
                    break;

                case CONTENT_TYPES.BANNER:
                    content = new ModelsFactory.Banner();
                    break;

                case CONTENT_TYPES.RSS:
                    content = new ModelsFactory.Rss();
                    break;

                case CONTENT_TYPES.ONBOARDING:
                    content = new ModelsFactory.Onboarding();
                    break;

                case CONTENT_TYPES.LINE_ROUTE:
                    content = new ModelsFactory.LineRoute();
                    break;

                default:
                    break;
            }

            if (content) {
                setContentMessage(content);
            }

            vm.isShopTenant = Number(vm.userData.subdomain.tenant.storeRef) > 0;
            vm.dataLoaded = true;
            $scope.$apply();
        }

        function processContentGroupsData() {
            /**
             * Please note: by default content_group_id equal 0 (see ModelsFactory). If content_group_id is undefined or null
             *              then sets a default value.
             */
            if (vm.contentData.content_group_id === undefined || vm.contentData.content_group_id === null) {
                vm.contentData.content_group_id = DEFAULT_CONTENT_GROUP.id;
            }
        }

        function processLanguageData() {
            if (!vm.contentData.language_id) {
                vm.contentData.language_id = vm.langArray[0].id;
            }
        }

        function setContentMessage(content) {
            if (!vm.contentData.message) {
                vm.contentData.message = {};
            }

            const isRefactoredContentType = refactoredStructureContentTypes
                .some(typeId => typeId === Number(vm.contentData.content_type_id));

            if(singleLanguageTypes.some(type => type === content.type)) {
                vm.contentData.message = Object.assign(
                    angular.copy(content),
                    vm.contentData.message || {}
                );
            } else if (isRefactoredContentType) {
                vm.contentData.message = Object.assign(
                    angular.copy(content),
                    vm.contentData.message
                );
            } else {
                for (let lang of vm.langArray) {
                    // Create new message model OR wrap exist message
                    vm.contentData.message[lang.id] = Object.assign(
                        angular.copy(content),
                        vm.contentData.message[lang.id] || {}
                    );
                }
            }
        }

        /**
         * Watchers
         */
        $scope.$watch(angular.bind(vm, function() {
            return typeof vm.contentForm === 'object';
        }), function(newValue) {
            if (newValue) {
                vm.STORAGE.set('contentForm', vm.contentForm);
            }
        });
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('GeofenceLocationPickerPopup', GeofenceLocationPickerPopup);

    function GeofenceLocationPickerPopup(PopupService) {

        return {
            show
        };

        function show(locations, selectedLocations, selectedGeofenceIds) {
            return PopupService.show({
                templateUrl: '/assets/views/common/components/geofence-locations-picker/popup/popup.tpl.html',
                controller: 'GeofenceLocationPickerPopupController',
                controllerAs: '$ctrl',
                windowClass: 'geofence-location-picker-popup',
                backdrop: true,
                resolve: {
                    data: {
                        locations,
                        selectedLocations,
                        selectedGeofenceIds,
                    },
                }
            });
        }
    }
}());
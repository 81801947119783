(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasType', {
            templateUrl: '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-type.tpl.html',
            controller: TimetableHafasType,
            bindings: {
                langArray: '<',
                contentForm: '<',
                contentData: '<',
                contentGroupsArray: '<',
            }
        });

    /**
     * @param {$q} $q
     * @param {$rootScope.Scope} $scope
     * @param StatesFactory
     * @param ModelsFactory
     * @param {CONTENT_TYPES} CONTENT_TYPES
     * @param {ContentDataService} ContentDataService
     * @param UserDataService
     * @param {ImageUploadFactory} ImageUploadFactory
     * @param TenantDataService
     * @param {TimetableHafasService} TimetableHafasService
     * @param ClipboardService
     * @param ToastService
     * @param NO_JOURNEYS_VIEW_MODE
     *
     * @property {Language[]} langArray
     * @property {form.FormController} contentForm
     * @property {TimetableHafasContent} contentData
     * @property {ContentGroup[]} contentGroupsArray
     *
     * @property {string} messageJson
     *
     * @constructor
     */
    function TimetableHafasType(
        $q,
        $scope,
        StatesFactory,
        ModelsFactory,
        CONTENT_TYPES,
        ContentDataService,
        UserDataService,
        ImageUploadFactory,
        TenantDataService,
        TimetableHafasService,
        ClipboardService,
        ToastService,
        NO_JOURNEYS_VIEW_MODE,
    ) {
        const $ctrl = this;
        const waitViewUpdate = TimetableHafasService.waitViewUpdate;

        /**
         * @type {ImageUploader}
         */
        let noJourneysImageUploader = null;

        /**
         * @type {ContentStates}
         */
        $ctrl.state = StatesFactory.ContentStates;
        $ctrl.isCreate = $ctrl.state.type === 'new';
        $ctrl.isEdit = $ctrl.state.type === 'edit';
        $ctrl.onlyActiveElements = true;
        $ctrl.showPreview = false;
        $ctrl.simulateNoJourneys = false;

        $ctrl.NO_JOURNEYS_VIEW_MODE = NO_JOURNEYS_VIEW_MODE;

        $ctrl.imageLoaderConfig = {
            minWidth: 200,
            minHeight: 200,
            center: true,
            fullInfo: true,
        };

        $ctrl.screenController = null;
        /**
         * @type {MctConfig}
         */
        $ctrl.mctConfig = {
            him: true,
            headline: true,
            journeyNameFilter: '',
            productOrder: [],
            platformFilter: '',
            // Not available for VAO
            poleFilter: {
                // // Bus poles
                // 300304002: { arrow: 2 },
                // 300304047: { arrow: 7 },
                // 300308005: { arrow: 1 },
                //
                // // Urban poles
                // 300003071: { arrow: 3 },
                // 300003110: { arrow: 1 },
                // 300003156: { arrow: 7 },
            },
        };

        $ctrl.headerFooters = [];

        /**
         * @type {HeaderFooterContent}
         */
        $ctrl.header = null;

        /**
         * @type {HeaderFooterContent}
         */
        $ctrl.footer = null;

        $ctrl.$onInit = () => {
            $q.all([
                UserDataService.currentUserInfo(),
                ContentDataService
                    .contents({contentTypeId: [CONTENT_TYPES.HEADER_FOOTER].join(',')})
                    .catch(console.error)
            ])
            .then(([userResponse, headerFooters]) => {
                $ctrl.headerFooters = headerFooters ? headerFooters.plain() : [];
                _initHeaderFooterWatcher();

                const user = userResponse.plain();
                $ctrl.subdomainId = user.subdomain_id;
                _setScreenController(user);
            })
            .then(() => {
                _initModel()
                    .then(waitViewUpdate)
                    .then(() => {
                        $ctrl.showPreview = true;
                    });

                _initFinishHandler();
                _initFormValidation();

                if (!$ctrl.contentData.data.noJourneys.viewMode) {
                    $ctrl.contentData.data.noJourneys.viewMode = NO_JOURNEYS_VIEW_MODE.DEFAULT;
                }

                noJourneysImageUploader = new ImageUploadFactory.ImageUploader({
                    initialImageUrl: $ctrl.contentData.data.noJourneys.image,
                    onImageUploaded: imageUrl => {
                        $ctrl.contentData.data.noJourneys.image = imageUrl;
                    }
                });
            });
        };

        /**
         * Timetable preview resolution change callback function
         *
         * @param {{ w: number, h: number }} previewSize screen resolution
         * @returns {void}
         */
        $ctrl.onPreviewResolutionChange = (previewSize) => {
            $ctrl.contentData.data.previewSize = previewSize;
        };

        /**
         * Image field change handler
         *
         * @param {{blob: File, cropData: Object}} imageFile
         * @param {boolean} uploadToS3
         */
        $ctrl.onNoJourneysImageChange = (imageFile, uploadToS3) => {
            const image = imageFile
                ? (uploadToS3 ? imageFile.blob : imageFile.blob.name)
                : '';

            noJourneysImageUploader.setImage(image);
            $ctrl.contentData.data.noJourneys.image = image
                ? noJourneysImageUploader.getImagePreview()
                : image;
        }

        $ctrl.toClipboard = () => {
            ClipboardService.copy(
                angular.toJson($ctrl.contentData.data)
            );

            ToastService.success('COPIED_TO_CLIPBOARD');
        }

        /**
         * @return {Promise<void>}
         * @private
         */
        function _initModel() {
            $ctrl.contentData.data = new ModelsFactory.TimetableHafasOptions($ctrl.contentData.data);

            if ($ctrl.isCreate) {
                return TenantDataService.getTimetableHafasPreset()
                    .then(preset => {
                        TimetableHafasService.overrideOptions($ctrl.contentData.data, preset);
                    });
            }

            return Promise.resolve();
        }

        function _setScreenController(user) {
            const tenantParams = JSON.parse(user.subdomain.tenant.params);

            $ctrl.screenController = {
                controllerLabel: 'Stop name example',
                station: {
                    stopId: tenantParams.previewStationId,
                    stopName: 'Stop name example',
                },
            };
        }

        /**
         * @private
         */
        function _initFinishHandler() {
            $scope.$on(
                'content-finish',
                /**
                 * @param event
                 * @param {{ callback: FinishCallback }} data
                 */
                (event, data) => {
                    noJourneysImageUploader.run().then(
                        () => {
                            data.callback({
                                type: $ctrl.state.type,
                                contentData: angular.copy($ctrl.contentData),
                            });
                        }
                    );
                }
            );

            /**
             * @callback FinishCallback
             * @param  {{ type: string, contentData: TimetableHafasContent }} data
             * @return void
             */
        }

        /**
         * @private
         */
        function _initFormValidation() {
            $scope.$watch(
                () => $ctrl.contentForm && $ctrl.contentForm.$valid,
                isFormValid => {
                    $ctrl.state.canFinish = Boolean(isFormValid)
                });
        }

        function _initHeaderFooterWatcher() {
            $scope.$watch(
                () => $ctrl.contentData.data.headerId,
                headerId => {
                    $ctrl.header = $ctrl.headerFooters.find(item => item.id === headerId);
                }
            );

            $scope.$watch(
                () => $ctrl.contentData.data.footerId,
                headerId => {
                    $ctrl.footer = $ctrl.headerFooters.find(item => item.id === headerId);
                }
            );
        }
    }
})();

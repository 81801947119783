(function() {
    'use strict';

    angular.module('beacon.app')
        .component('locationPicker', {
            templateUrl: '/assets/views/common/components/location-picker/location-picker.tpl.html',
            controller: LocationPickerController,
            bindings: {
                form: '<',
                inputName: '@',
                location: '=',
                label: '@?',
                required: '<?',
                readonly: '<?',
            }
        });

    function LocationPickerController(
        $scope,
        GoogleMapsUtilitiesService,
        UserUtilitiesService,
        UtilitiesService,
    ) {
        const vm = this;

        vm.onClick = onClick;
        vm.isError = UtilitiesService.inputValidator(vm.form);

        function onClick() {
            vm.mapDefaults = UserUtilitiesService.userLocationOptions();
            const coords = UtilitiesService.getCoordinates(vm.location);

            const mapPopup = GoogleMapsUtilitiesService.showMap({
                mapInfo: coords ? [coords] : null,
                mapDefaults: vm.mapDefaults,
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(mapInfo => {
                const coords = mapInfo.coordinates;
                const precision = 6;

                const lat = UtilitiesService.toPrecision(coords.latitude, precision);
                const lng = UtilitiesService.toPrecision(coords.longitude, precision);
                vm.location = `${lat},${lng}`;
            });
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FeedbackHelper', FeedbackHelper);

    function FeedbackHelper(
        FEEDBACK_STATUSES,
        FEEDBACK_PRIORITIES,
    ) {
        const STATUS = generateStatuses();
        const PRIORITY = generatePriorities();

        return {
            statusName,
            priorityName,
            priorityColor,
            getStatusesList,
            getPrioritiesList,
            STATUS,
            PRIORITY,
        };

        function statusName(feedbackTicket) {
            const found = FEEDBACK_STATUSES.find(status => status.id === feedbackTicket.status);
            return found ? found.name : 'N/A';
        }

        function priorityName(feedbackTicket) {
            const found =  FEEDBACK_PRIORITIES.find(priority => priority.id === feedbackTicket.priority);
            return found
                ? found.translate || found.name
                : 'N/A';
        }

        function priorityColor(feedbackTicket) {
            const found =  FEEDBACK_PRIORITIES.find(priority => priority.id === feedbackTicket.priority);
            return found ? found.color : 'white';
        }

        function getStatusesList() {
            return FEEDBACK_STATUSES;
        }

        function getPrioritiesList() {
            return FEEDBACK_PRIORITIES;
        }

        function generateStatuses() {
            const result = {};
            FEEDBACK_STATUSES.forEach(item => {
                result[item.name] = item.id;
            });
            return result;
        }

        function generatePriorities() {
            const result = {};
            FEEDBACK_PRIORITIES.forEach(item => {
                result[item.name] = item.id;
            });
            return result;
        }
    }
})();
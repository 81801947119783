
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capSublets', {
            templateUrl: '/assets/views/share-park/customers/sublet/sublets.tpl.html',
            controller: SubletsController
        });

    function SubletsController(
        $state,
        ShareParkDataService,
        PermissionsService,
        UtilitiesService,
    ) {
        const vm = this;

        const { actions, generateCapSubletPermission } = PermissionsService;

        vm.$onInit = init;

        vm.subletsAll = [];
        vm.count = 0;

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'sublets-list-name',
                    width: '20',
                    title: listItem => listItem.sublet.fullCompanyName,
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteSublet,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editSublet,
                        permissionAction: actions.modify
                    },
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        vm.addSublet = addSublet;
        vm.editSublet = editSublet;

        /**
         * Controller initialization
         */
        function init() {
            ShareParkDataService.getCapSublets()
                .then(response => {
                    vm.subletsAll = response.plain().map(sublet => ({sublet}));
                    vm.count = vm.subletsAll.length;
                    updateList();
                });
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.sublets = UtilitiesService.getArrayPage(vm.subletsAll, page, count);
        }

        /**
         * Create new Sublet
         */
        function addSublet() {
            $state.go('app.addShareParkSublet');
        }

        /**
         * Edit Sublet
         * @param $event
         * @param item
         */
        function editSublet($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editShareParkSublet', { data: item.sublet });
        }

        function deleteSublet($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            ShareParkDataService.deleteCapSublet(item.sublet.id)
                .then(init)
        }

        function generatePermissionName(button) {
            return generateCapSubletPermission(button.permissionAction);
        }
    }
})();
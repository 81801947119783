(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PoiContentDataService', poiContentDataService);

    function poiContentDataService(Restangular) {
        return {
            create,
            getPoiContents,
            update,
            deletePoiContent,
            poiContent,
            getPossibleTourPoints,
            createTour,
            getPoiTours,
            deletePoiTour,
            poiTour,
            updateTour,
        };

        /**
         * Return poi contents list
         */
        function getPoiContents(data) {
            return Restangular.all('poi/contents').getList(data);
        }

        /**
         * Creates content
         *
         * @param {object} data
         */
        function create(data) {
            return Restangular.all('poi/content')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updates content
         *
         * @param {object} content
         * @param {object} data
         */
        function update(content, data) {
            return content.customPOST(data, '', {}, { 'Content-Type': undefined, '_method': 'PUT' });
        }

        /**
         * Delete poi content by it`s id
         *
         * @param {object} id
         */
        function deletePoiContent(id) {
            return Restangular.one('poi/content', id).remove();
        }

        /**
         * Returns simple POI content by it`s id
         *
         * @param {object} id
         */
        function poiContent(id) {
            return Restangular.one('poi/content', id).get();
        }

        /**
         * Returns list of contents (POI, infotainment, quiz and feedback)
         * which have coordinates set
         */
        function getPossibleTourPoints() {
            return Restangular.all('poi/tour/contents').getList();
        }

        /**
         * Creates poi tour
         *
         * @param {object} data
         */
        function createTour(data) {
            return Restangular.all('poi/tour')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Return poi contents list
         */
        function getPoiTours(data) {
            return Restangular.all('poi/tours').getList(data);
        }

        /**
         * Delete poi tour by it`s id
         *
         * @param {object} id
         */
        function deletePoiTour(id) {
            return Restangular.one('poi/tour', id).remove();
        }

        /**
         * Returns simple POI tour by it`s id
         *
         * @param {object} id
         */
        function poiTour(id) {
            return Restangular.one('poi/tour', id).get();
        }

        /**
         * Updates tour
         *
         * @param {object} tour
         * @param {object} data
         */
        function updateTour(tour, data) {
            return tour.customPOST(data, '', {}, { 'Content-Type': undefined, '_method': 'PUT' });
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tenantEditSettings', {
            controller: TenantEditSettingsController,
            templateUrl: '/assets/views/tenants/tabs/tenants-tab/new/tenant-edit-settings/tenant-edit-settings.tpl.html',
            bindings: {
                tenant: '<'
            }
        });

    function TenantEditSettingsController(
        $scope,
        ContentDataService,
        PopupService,
        ScreenDataService,
        DYNAMIC_CONTENT_TEMPLATES,
        TIMETABLE_OPTIONS,
        SCREENS_REFRESH_DEFAULT_RATE,
        SCREENS_REFRESH_DEFAULT_RATE_CAMPAIGN_INITIATED,
        SCREENS_REFRESH_MIN_PERIOD_CAMPAIGN_INITIATED_SECONDS,
    ) {
        const vm = this;
        vm.changeBannerPositionConfig = changeBannerPositionConfig;
        vm.bannerPositionApps = [];
        vm.SCREENS_REFRESH_DEFAULT_RATE = SCREENS_REFRESH_DEFAULT_RATE;
        vm.SCREENS_REFRESH_DEFAULT_RATE_CAMPAIGN_INITIATED = SCREENS_REFRESH_DEFAULT_RATE_CAMPAIGN_INITIATED;
        vm.totalScreens = 0;

        vm.$onInit = () => {
            vm.templates = DYNAMIC_CONTENT_TEMPLATES;
            vm.timetableOptions = Object.values(TIMETABLE_OPTIONS);

            _loadTotalScreens();
            _setWatchers();
        }

        vm.refreshPeriodUserInitiated = (refreshRate) => {
            return Math.ceil(vm.totalScreens / refreshRate);
        }

        vm.refreshPeriodCampaignInitiated = (refreshRate) => {
            const period =  Math.ceil(vm.totalScreens / refreshRate);

            if (period < SCREENS_REFRESH_MIN_PERIOD_CAMPAIGN_INITIATED_SECONDS) {
                return SCREENS_REFRESH_MIN_PERIOD_CAMPAIGN_INITIATED_SECONDS;
            }

            return period;
        }

        function _loadTotalScreens() {
            ScreenDataService.getTotalScreens().then(
                ({ total }) => {
                    vm.totalScreens = total;
                }
            );
        }

        function changeBannerPositionConfig() {
            PopupService.show({
                template: `<banner-positions-config 
                            class="banner-positions" 
                            on-complete="$ctrl.onSubmit" 
                            tenant-id="$ctrl.tenantId">
                            </banner-positions-config>`,
                controller: function ($uibModalInstance, data) {
                    this.tenantId = data.tenantId;
                    this.onSubmit = () => {
                        $uibModalInstance.dismiss();
                    }
                },
                controllerAs: '$ctrl',
                windowClass: 'banner-positions-config-popup',
                backdrop: true,
                resolve: {
                    data: {
                        tenantId: vm.tenant.id,
                    },
                }
            });
        }

        function _setWatchers() {
            $scope.$watch(
                () => vm.tenant.carParkSubAreaTemplates,
                value => {
                    if (!value) {
                        vm.tenant.carParkSubAreaTemplates = '[]';
                    }
                }
            )
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tenantsTenantsTab', {
            templateUrl: '/assets/views/tenants/tabs/tenants-tab/tenants-tab.tpl.html',
            controller: TenantsTenantsTab,
            bindings: {
                item: '<',
                selectedTenant: '='
            }
        });

    function TenantsTenantsTab(
        $q,
        $state,
        $translate,
        tenantsTabState,
        LoginService,
        PopupService,
        ValuesService,
        LookupDataService,
        TenantDataService,
        LocationDataService,
        UserUtilitiesService,
        PageDataService,
        ERROR_CODES,
        HTTP_STATUSES,
        ModelsFactory,
        ACTIVATION_STATUS,
        ToastService,
    ) {

        const vm = this;

        vm.state = tenantsTabState;
        vm.isUserSysAdmin = UserUtilitiesService.hasRoleSysAdmin();

        vm.addTenant = addTenant;
        vm.reloadTenantsList = reloadTenantsList;
        vm.reloadLocationsList = reloadLocationsList;
        vm.downloadCsvFile = downloadCsvFile;
        vm.onCsvAttached = onCsvAttached;
        vm.openCsvInputForm = openCsvInputForm;
        vm.downloadLayouts = downloadLayouts;
        vm.onLayoutsJsonAttached = onLayoutsJsonAttached;
        vm.openLayoutsInputForm = openLayoutsInputForm;

        vm.tenantsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'tenant-title',
                    width: '100',
                    title: 'name'
                }
            ],
            buttons: {
                width: '10',
                minWidth: '150px',
                items: [
                    {
                        class: 'editBtn',
                        callback: updateTenant,
                        isVisible: () => {
                            return vm.isUserSysAdmin;
                        },
                    }
                ]
            },
            itemClickCallback: selectTenant,
            updateCallback: updateTenantsList
        };

        vm.errorCodesMap = {};
        vm.errorCodesMap[ERROR_CODES.ERR_INVALID_TENANT_ID] = 'INVALID_TENANT_ID';
        vm.errorCodesMap[ERROR_CODES.ERR_TENANT_ALREADY_LINKED] = 'TENANT_ALREADY_LINKED';

        vm.tenants = [];
        vm.currentTenants = [];
        vm.activeTenant = null;
        vm.newTenant = '';
        vm.tenantsLength = 0;

        vm.paginationData = {
            itemsPerPage: 100
        };

        /**
         * Downloads CSV file
         *
         * @param {object} result
         */
        function downloadCsvFile() {
            var fileName = "values.csv";
            var a = document.createElement("a");
            document.body.appendChild(a);
            ValuesService.downloadCsv().then(function (result) {
                var file = new Blob([result], {type: 'text/csv'});
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName;
                a.click();
            });
        }

        /**
         * Download layouts JSON
         */
        function downloadLayouts() {
            const fileName = "layouts.json";
            const a = document.createElement("a");
            document.body.appendChild(a);
            PageDataService.downloadLayouts().then(function (layouts) {
                const file = new Blob([JSON.stringify(layouts)], {type: 'application/json'});
                const fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName;
                a.click();
            });
        }

        /**
         * Opens JSON file input form
         */
        function openLayoutsInputForm() {
            setTimeout(function () {
                document.getElementById('layout-input-form').click();
            }, 0);
        }

        /**
         * Shows popup with result message
         * @param file
         */
        function onLayoutsJsonAttached(file) {
            let data = new FormData();
            data.append('layouts', file);
            document.getElementById('layout-input-form').value = "";
            PageDataService.uploadLayouts(data).then(uploadLayoutsSuccessHandler, uploadLayoutsErrorHandler);
        }

        /**
         * Opens CSV file input form
         *
         * @param {object} result
         */
        function openCsvInputForm() {
            setTimeout(function () {
                document.getElementById('csv-input-form').click();
            }, 0);
        }

        /**
         * Shows popup with error message
         *
         * @param {object} result
         */
        function uploadCsvErrorHandler(result) {
            PopupService.showAlertPopup({
                text: result.message || "VALUES_UPLOAD_FAILS",
                okButtonText: 'OK',
            });
        }

        /**
         * Shows popup with result message
         *
         * @param {object} result
         */
        function uploadCsvSuccessHandler(result) {
            LookupDataService.removeAccessData();
            PopupService.showAlertPopup({
                text: result.message || "VALUES_WERE_IMPORTED",
                okButtonText: 'OK',
            });
        }

        /**
         * Shows popup with error message
         *
         * @param {object} result
         */
        function uploadLayoutsErrorHandler(result) {
            let text = 'LAYOUT_UPLOAD_FAILS';

            if (result.status === HTTP_STATUSES.LOCKED) {
                const usages = result.data.map(el => (`${el.digitalDisplayTitle} -> ${el.pageTitle}`));

                $translate('LAYOUT_UPLOAD_FAILS_USED', { usages })
                    .then(translation => {
                        PopupService.showAlertPopup({
                            text: translation,
                            okButtonText: 'OK',
                        });
                    });
            } else {
                PopupService.showAlertPopup({
                    text,
                    okButtonText: 'OK',
                });
            }
        }

        /**
         * Shows popup with result message
         *
         * @param {object} result
         */
        function uploadLayoutsSuccessHandler(result) {
            PopupService.showAlertPopup({
                text: "LAYOUTS_WERE_IMPORTED",
                okButtonText: 'OK',
            });
        }

        /**
         * Uploads file when it attached
         *
         * @param {object} file
         */
        function onCsvAttached (file) {
            let data = new FormData();
            data.append('csv', file);
            document.getElementById('csv-input-form').value = "";
            ValuesService.uploadCsv(data).then(uploadCsvSuccessHandler, uploadCsvErrorHandler);
        }

        /**
         * TODO: 04.01.2018 serhii.kokovskyi Currently Jan's server doesn't support this feature, that's why this feature
         *                                   is dimmed in our UI.
         *
         * Unlinks tenant from user account
         *
         * @param {MouseEvent} event
         * @param {object} item
         */
        function removeTenant(event, item) {
            if (item.id === vm.activeTenant.id) {
                PopupService.showAlertPopup({
                    text: 'CANNOT_REMOVE_CURRENT_TENANT',
                    okButtonText: 'OK'
                });
            } else {
                PopupService.showConfirmationPopup({
                    text: 'DO_YOU_WANT_TO_SIGN_OUT_OF_THIS_TENANT',
                    okButtonText: 'YES',
                    cancelButtonText: 'NO'
                }, function() {
                    LoginService.removeTenant(item.tenantRef, function() {
                        reloadTenantsList();
                    });
                });
            }
        }

        /**
         * Updates pagination
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateTenantsList(page, itemsPerPage) {
            vm.currentTenants = vm.tenants.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        /**
         * Links new tenant to user account
         */
        function addTenant() {
            var errorCode;

            vm.addTenantForm.newTenant.$setTouched();

            for (errorCode in vm.errorCodesMap) {
                vm.addTenantForm.newTenant.$setValidity(vm.errorCodesMap[errorCode], true);
            }

            LoginService.addTenantByUser(vm.newTenant, function() {
                vm.newTenant = '';
                reloadTenantsList();
            }, function(response) {
                vm.addTenantForm.newTenant.$setValidity(vm.errorCodesMap[response.data.errorCode], false);
            });
        }

        /**
         * Initialization method
         */
        function init() {
            reloadTenantsList();
        }

        /**
         * Reloads tenants list
         */
        function reloadTenantsList(reload) {
            let paramsObj = reload ? { reload:reload } : {};
            paramsObj["include[]"] = ["permissions", "locations"];

            $q.all([
                TenantDataService.getFullTenants(paramsObj),
                TenantDataService.getTenantsAdmins()
            ]).then((responses) => {
                if (reload) {
                    ToastService.success('TENANTS_UPDATE_SUCCESSFUL');
                }

                let tenants = responses[0];
                let tenantsAdmins = responses[1].plain();

                for (let tenant of tenants) {
                    tenant['admins'] = tenantsAdmins.filter((admin) => admin.tenant_id === tenant.id);
                    tenant['params'] = JSON.parse(tenant.params);
                }

                vm.tenants = tenants;
                vm.tenantsLength = vm.tenants.length;
                vm.tenantsListData.updateCallback(1, vm.paginationData.itemsPerPage);
            }).catch(_err => {
                ToastService.error('TENANTS_UPDATE_FAILED');
            });
        }

        /**
         * Reloads locations list
         */
        function reloadLocationsList(reload) {
            const paramsObj = reload ? { reload } : {};
            LocationDataService.getLocationsAll(paramsObj).then(() => {
                vm.tenantsListData.updateCallback(1, 10);
            });
        }

        /**
         * Handle tenant item click
         *
         * @param {object} tenantItem
         */
        function selectTenant(tenantItem) {
            vm.selectedTenant = vm.selectedTenant !== tenantItem ? tenantItem : false;
            vm.state.canProcessNext = !!vm.selectedTenant;
        }

        function getLocations() {
            return $q.all([
                LocationDataService.getLocationsAllWithTenants(),
                LocationDataService.getControllerDetails(),
            ]).then(([locations, details]) => {
                return locations.plain().map(location => {
                    const isScreen = location.controllerRef > 0;
                    location.details = isScreen
                        ? details.list.find(controller => location.controllerRef === controller.id)
                        : null;
                    return location;
                }).filter(screen => {
                    const isScreen = screen.details;
                    const validStatuses = Object.keys(ACTIVATION_STATUS).map(
                        statusName => ACTIVATION_STATUS[statusName].value);
                    const isNotDeletedScreen = isScreen
                        && screen.details.status !== ACTIVATION_STATUS.DELETED.value
                        && validStatuses.includes(screen.details.status);
                    return !isScreen || isNotDeletedScreen;
                });
            });
        }

        /**
         * Updates user
         *
         * @param {MouseEvent} $event
         * @param {Object} tenantItem
         */
        function updateTenant($event, tenantItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            $q.all([
                LookupDataService.getAccessData(),
                getLocations(),
            ]).then((responses) => {
                var stateData = {tenant: tenantItem};
                if (responses && _.isArray(responses) && responses.length) {

                    if (responses[0]) {
                        stateData.permissions = _.values(responses[0].permissionsMap);
                        stateData.permissionsGroup = _.values(responses[0].permissions_groupMap);
                    }

                    if (responses[1]) {
                        stateData.locationsAll = _.values(responses[1]);
                    }

                    $state.go('app.editTenant', {
                        data: stateData,
                        paginationData: {
                            page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                            itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                        }
                    });
                }
            }).catch(console.error.bind(console));
        }

        vm.createTenant = createTenant;

        function createTenant() {
            $q.all([
                LookupDataService.getAccessData(),
                getLocations(),
            ]).then((responses) => {
                const data = {
                    tenant: new ModelsFactory.Tenant()

                };
                if (responses && _.isArray(responses) && responses.length) {

                    if (responses[0]) {
                        data.permissions = _.values(responses[0].permissionsMap);
                        data.permissionsGroup = _.values(responses[0].permissions_groupMap);
                    }

                    if (responses[1]) {
                        data.locationsAll = _.values(responses[1]);
                    }

                    $state.go('app.newTenant', {
                        data
                    });
                }
            }).catch(console.error.bind(console));

        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('oauthOpenidCallback', {
            templateUrl: '/assets/views/login/oauth-openid-callback/oauth-openid-callback.tpl.html',
            controller: OAuthOpenidCallbackController
        });
    
    function OAuthOpenidCallbackController(
        $state,
        $location,
        $window,
        OpenIdOAuthService,
        LoginService,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            const { code, state: appMode } = _getQueryParams();

            if (!code) {
                $state.go('login');

                return;
            }

            // We temporarily handle the "Share Park" web app (in Smart Campus mode) SSO redirect (webhook).
            // This is a temporary solution just for development.
            // #tempShareParkAppSSO
            if (['sc-external', 'sc-external-demo', 'sc-external-pentest', 'sp-external-pentest'].includes(appMode)) {
                OpenIdOAuthService.getScExternalRedirectUrl(code, appMode)
                    .then(({ redirectUrl }) => {
                        $window.location.href = redirectUrl;
                    })
                    .catch((_err) => {
                        $state.go('login');
                    });
            } else {
                _login(code)
                    .then(response => {
                        if (!response) { return; }

                        const { token, subdomains } = response;

                        LoginService.oauthAndRedirectToSubdomain(token, subdomains[0].id, appMode);
                    });
            }
        };

        function _getQueryParams() {
            return $location.search();
        }

        /**
         * Authorize openid user
         * @param {string} code authorization code
         */
        function _login(code) {
            return OpenIdOAuthService.login(code)
                .catch((err) => {
                    $state.go('login', { errorMessage: err.data });
                });
        }
    }
})();

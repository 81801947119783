(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newCustomer', {
            templateUrl: '/assets/views/share-park/customers/customers/new/new-customer.tpl.html',
            controller: NewCustomerController
        });

    function NewCustomerController(
        $scope,
        $state,
        $stateParams,
        $translate,
        DateHelper,
        ModelsFactory,
        PermissionsService,
        PopupService,
        ProfilesHelper,
        StatesFactory,
        ShareParkDataService,
        ShareParkHelperService,
        StopParkingPopupService,
        TenantHelper,
        UtilitiesService,
        SHARE_PARK_CUSTOMER_STATUS,
        SHARE_PARK_CUSTOMER_BOOKING_LOCK_STATUS,
        CUSTOMER_SCHEDULED_EVENT_TYPES,
        MOBILE_CAMPAIGN_INFINITE_DATE,
        VEHICLE_TYPES,
        CustomersCacheFactory,
        CsvService,
        ClipboardService,
        TranslateService,
        DialogCustomerPayoutService,
    ) {
        const vm = this;

        const { createCustomer } = ShareParkDataService;
        const { processParkingStatusesResponse } = ShareParkHelperService;
        const { getDefaultDateFormat } = DateHelper;
        const { sortByStringProperty } = UtilitiesService;
        const { isPermissionAvailable } = PermissionsService;
        const { decodeCustomerProfile } = ProfilesHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
        const currentDate = new Date();
        const EVENT_TYPES = Object.values(CUSTOMER_SCHEDULED_EVENT_TYPES).filter(eventType =>
            eventType.permission ? isPermissionAvailable(eventType.permission) : true
        );

        const reservationTabs = {
            ACTIVE: {
                id: 1,
                label: 'ACTIVE',
            },
            PASSED: {
                id: 2,
                label: 'PAST',
            }
        };


        vm.refundType = {
            REFUND_TO_MONETARY_PAYMENT: 1,
            REFUND_TO_BONUS_ACCOUNT: 2,
            OUTPAYMENT_TO_BONUS_ACCOUNT: 3,
        };

        vm.EVENT_TYPES_ARR = EVENT_TYPES;
        vm.state = StatesFactory.ShareParkCustomerStates.refresh();
        vm.state.type = $stateParams.type;
        vm.dateFormat = DATE_FORMAT;
        vm.currentDate = currentDate;
        vm.reservationsMaxDate = new Date(2030, 0, 1);
        vm.reservationsMinDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        vm.cancelledReservationStatus = 3;
        vm.paymentMeans = [];
        vm.showFailedTransactions = false;

        vm.savedProfile = null;
        vm.vehiclesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'vehicle-name',
                    width: '25',
                    title: item =>  {
                        return item.vehicleName || '';
                    }
                },
                {
                    name: 'NUMBER_PLATE',
                    width: '25',
                    title: item =>  {
                        return item.capVehicleRegistration.numberPlate || '';
                    }
                },
                {
                    name: 'BRAND',
                    width: '25',
                    title: item =>  {
                        return item.capVehicleRegistration.vehicleBrand || '';
                    }
                }
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteVehicle,
                    },
                ],
            },
            updateCallback: () => {},
            additionalData: {
                VEHICLE_TYPES
            },
            hideHeader: true,
        };
        vm.scheduledAssignmentsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'scheduled-assignment-name',
                    width: '45',
                    title: item =>  {
                        if (item.eventType === CUSTOMER_SCHEDULED_EVENT_TYPES.USER_PROFILE_CHANGE.id) {
                            const selectedProfile = vm.availableUserProfiles.find(profile =>
                                profile.externalId === item.prefs
                            );

                            return selectedProfile ? selectedProfile.nameLabel : '';
                        }

                        return EVENT_TYPES.find(({id}) => id === item.eventType).label;
                    },
                    translate: true
                },
                {
                    name: 'ACTIVE_FROM',
                    width: '35',
                    title: item =>
                        item.executionDate
                            ? moment(item.executionDate).format(DATE_TIME_FORMAT)
                            : ''
                },
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteScheduledAssignment,
                    },
                ],
            },
            updateCallback: () => {},
            additionalData: {
                availableUserProfiles: [],
                EVENT_TYPES_ARR: EVENT_TYPES,
                EVENT_TYPES: CUSTOMER_SCHEDULED_EVENT_TYPES
            },
        };
        vm.genders = [
            {
                name: 'MALE',
                id: "0"
            },
            {
                name: 'FEMALE',
                id: "1"
            }
        ];
        vm.reservationTabsArr = Object.values(reservationTabs);
        vm.selectedReservationTab = reservationTabs.ACTIVE.id;

        vm.activeReservations = [];
        vm.passedReservations = [];
        vm.visibleReservations = [];
        vm.availableUserProfiles = [];
        vm.scheduledAssignments = [];

        vm.redeemAccounts = [];

        vm.$onInit = init;

        vm.addVehicle = addVehicle;
        vm.addEventAssignment = addEventAssignment;
        vm.showInputError = showInputError;
        vm.isActive = isActive;
        vm.changeUserStatus = changeUserStatus;
        vm.isSameDate = isSameDate;
        vm.formatDate = formatDate;
        vm.formatDateTime = formatDateTime;
        vm.onSelectedReservationsTabChange = onSelectedReservationsTabChange;
        vm.onReservationsRangeChange = onReservationsRangeChange;
        vm.canRefundTransaction = canRefundTransaction;
        vm.refundTransaction = refundTransaction;
        vm.onCancelTransactionClick = onCancelTransactionClick;
        vm.setProfile = setProfile;
        vm.isProfileChanged = isProfileChanged;
        vm.parkingStatusColor = parkingStatusColor;
        vm.parkingStatusIcon = parkingStatusIcon;
        vm.isUserActivationTimeUnlimited = isUserActivationTimeUnlimited;
        vm.toggleUnlimited = toggleUnlimited;
        vm.isActiveReservationsTabActive = isActiveReservationsTabActive;
        vm.stopParking = stopParking;
        vm.paymentMeansIcon = paymentMeansIcon;
        vm.printVipTicket = printVipTicket;
        vm.formatParkingStatusDate = formatParkingStatusDate;
        vm.getSubAreaName = getSubAreaName;
        vm.onCsvDownloadParkingStatuses = onCsvDownloadParkingStatuses;
        vm.onCsvDownloadPayments = onCsvDownloadPayments;
        vm.onCsvDownloadTickets = onCsvDownloadTickets;
        vm.showBonusPayoutDialog = showBonusPayoutDialog;
        vm.isBookingLocked = isBookingLocked;
        vm.changeBookingLockedStatus = changeBookingLockedStatus;
        vm.updateProfilesRemote = updateProfilesRemote;

        vm._getBonusPayoutSum = _getBonusPayoutSum;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            getCustomer().then(customer => {
                vm.customerData = customer;
                vm.savedProfile = vm.customerData.profile ? vm.customerData.profile.externalId : null;

                if (vm.customerData.guid && vm.isEditMode) {
                    onReservationsRangeChange();
                    updatePurchasesList();
                    getCronQueue();
                    getParkingStatuses();
                }

                getUserProfiles();
            });
        }

        function getCustomer() {
            if (!vm.isEditMode) {
                return Promise.resolve(new ModelsFactory.ShareParkCustomer());
            }

            return ShareParkDataService.getCustomerData($stateParams.data.guid)
                .then(response => response.plain());
        }

        function getCronQueue() {
            ShareParkDataService.getCronQueue(vm.customerData.guid)
                .then(response => {
                    vm.scheduledAssignments = response.plain()
                        .filter(queueItem =>
                            EVENT_TYPES.some(eventType => eventType.id === queueItem.eventType)
                        ).map(queueItem => {
                            queueItem.executionDate = moment(moment.utc(queueItem.executionDate).toDate()).local();
                            return queueItem;
                        });
                });
        }

        function updateProfilesRemote() {
            ShareParkDataService.updateProfilesRemote(vm.customerData.emailAddress)
                .then(() => {
                    init();
                });
        }

        function getParkingStatuses() {
            ShareParkDataService.getParkingStatuses(vm.customerData.guid)
                .then(response => {
                    vm.parkingStatuses = processParkingStatusesResponse(response.plain());
                });
        }

        function updatePurchasesList() {
            ShareParkDataService.getCustomerShopInfo(vm.customerData.guid)
                .then(response => {
                    const shopDetails = response.plain();
                    const transactions = shopDetails.user.payment.transactions;

                    const paymentMeans = shopDetails.user.payment.meansList || [];
                    vm.paymentMeans = paymentMeans.filter(item => item.status !== 3); // not deleted

                    vm.purchases = transactions
                        .map(transaction => ({
                            transaction,
                            purchase: shopDetails.user.payment.purchases.find(purchase => purchase.paymentRef === transaction.id),
                            comments: _getTransactionCommentsByCommentString(transaction.adminComment),
                            refundAccountName: _getRefundAccountNameByTransaction(shopDetails, transaction),
                            payoutAmount: transaction.refundType == vm.refundType.OUTPAYMENT_TO_BONUS_ACCOUNT
                                ? _getPayoutAmountByTransaction(shopDetails, transaction)
                                : 0,
                        }))
                        .filter(item => !['REFUND', 'REFUND_REDEEM'].includes(item.transaction.transType));

                    vm.redeemAccounts = shopDetails.user.payment.redeemAccounts.filter(item => item.status === 1);
                });
        }

        function _getRefundAccountNameByTransaction(shopDetails, transaction) {
            switch (transaction.refundType) {
                case vm.refundType.REFUND_TO_MONETARY_PAYMENT:
                case undefined:
                    const means = shopDetails.user.payment.meansList.find(({ id }) => id === transaction.refundMeansRef);

                    return means ? means.meansLabel : '';
                case vm.refundType.REFUND_TO_BONUS_ACCOUNT:
                case vm.refundType.OUTPAYMENT_TO_BONUS_ACCOUNT:
                    const redeemAccount = shopDetails.user.payment.redeemAccounts.find(({ id }) => id === transaction.refundMeansRef);

                    return redeemAccount ? redeemAccount.name : '';
                default: return;
            }
        }

        function _getTransactionCommentsByCommentString(commentsString) {
            if (!commentsString) { return; }

            const comments = JSON.parse(commentsString);

            if (!Array.isArray(comments)) { return; }

            return comments.map(({ author, comment, timestamp }) => ({
                author,
                comment,
                date: moment(timestamp).format(DATE_TIME_FORMAT),
            }));
        }

        function _getPayoutAmountByTransaction(shopDetails, transaction) {
            const redeemAccount = shopDetails.user.payment.redeemAccounts.find(({ id }) => id === transaction.refundMeansRef);

            return transaction.refundAmount / 100 * redeemAccount.moneyToValueRatio;
        }

        function getUserProfiles() {
            const ACTIVE_STATUSES = [0, 1];
            ShareParkDataService.getUserProfiles()
                .then(response => {
                    const customerProfileId = vm.customerData.profile ? vm.customerData.profile.id : null;
                    vm.availableUserProfiles = response.plain()
                        .map(decodeCustomerProfile)
                        .filter(profile => {
                            return profile.externalId !== 'POOL'
                                && (ACTIVE_STATUSES.includes(profile.status)
                                    || customerProfileId === profile.id);
                        });

                    vm.availableUserProfiles = sortByStringProperty(vm.availableUserProfiles, 'nameLabel');
                    vm.scheduledAssignmentsListData.additionalData.availableUserProfiles = vm.availableUserProfiles;
                })
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.newCustomerForm
                && vm.newCustomerForm[name].$dirty
                && vm.newCustomerForm[name].$invalid;
        }

        /**
         * Adds new vehicle
         */
        function addVehicle() {
            vm.customerData.registeredVehicles.push(new ModelsFactory.CapVehicle());
        }

        /**
         * Get parking status color
         *
         * @param parkingStatus
         * @returns {string}
         */
        function parkingStatusColor(parkingStatus) {
            if (!parkingStatus.endedParking) {
                return '#0071bd';
            } else {
                return 'green';
            }
        }

        /**
         * Get parking status icon
         *
         * @param parkingStatus
         * @returns {string}
         */
        function parkingStatusIcon(parkingStatus) {
            if (!parkingStatus.endedParking) {
                return 'local_parking';
            } else {
                return 'done';
            }
        }

        function paymentMeansIcon(paymentMean) {
            const dir = '/assets/images/';
            switch (paymentMean.meansType) {
                case 'VISA':        return dir + 'cap_card_visa.png';
                case 'MASTERCARD':  return dir + 'cap_card_mastercard.png';
                case 'DIRECTDEBIT': return dir + 'cap_card_sepa.png';
                case 'AMEX':        return dir + 'cap_card_amex.png';
                case 'DINERS':      return dir + 'cap_card_diners.png';
                case 'JCB':         return dir + 'cap_card_jcb.png';
                case 'UNIONPAY':    return dir + 'cap_card_union.png';
                default:            return null;
            }
        }

        function getSubAreaName(item) {
            const subArea = item.carPark.sub_areas.find(subArea => subArea.type === item.subArea);
            if (!subArea) {
                return 'N/A';
            }

            const languages = Object.keys(subArea.label);

            if (languages.length) {
                const defaultLang = languages[0];
                return subArea.label[defaultLang];
            } else {
                return item.subArea;
            }
        }

        /**
         * Adds new event assignment
         */
        function addEventAssignment() {
            vm.scheduledAssignments.push({
                prefs: null,
                executionDate: null,
                eventType: EVENT_TYPES[0].id
            });
        }

        /**
         * Deletes vehicle
         * @param $event
         * @param {object} vehicle
         * @param {number} index
         */
        function deleteVehicle($event, vehicle, index) {
            $event.stopPropagation();
            $event.preventDefault();
            vm.customerData.registeredVehicles.splice(index, 1);
        }

        /**
         * Deletes event assignment
         * @param $event
         * @param {object} eventAssignment
         * @param {number} index
         */
        function deleteScheduledAssignment($event, eventAssignment, index) {
            $event.stopPropagation();
            $event.preventDefault();

            if (eventAssignment.id) {
                ShareParkDataService.deleteCronQueueElement(eventAssignment.id)
                    .then(() => {
                        vm.scheduledAssignments.splice(index, 1);
                    })
            } else {
                vm.scheduledAssignments.splice(index, 1);
            }
        }

        function isActive() {
            return vm.customerData.status === SHARE_PARK_CUSTOMER_STATUS.ACTIVE;
        }

        function changeUserStatus() {
            vm.customerData.status = isActive()
                ? SHARE_PARK_CUSTOMER_STATUS.INACTIVE
                : SHARE_PARK_CUSTOMER_STATUS.ACTIVE;
        }

        /**
         * Check booking locked status
         * @returns {boolean}
         */
        function isBookingLocked() {
            return vm.customerData.bookingLockStatus === SHARE_PARK_CUSTOMER_BOOKING_LOCK_STATUS.ACTIVE;
        }

        function changeBookingLockedStatus() {
            vm.customerData.bookingLockStatus = isBookingLocked()
                ? SHARE_PARK_CUSTOMER_BOOKING_LOCK_STATUS.INACTIVE
                : SHARE_PARK_CUSTOMER_BOOKING_LOCK_STATUS.ACTIVE;
        }

        /**
         * checks if the date is the same
         * @param start
         * @param end
         * @returns {boolean}
         */
        function isSameDate(start, end) {
            return end
                ? moment(start).isSame(end, 'day')
                : false;
        }

        /**
         * Format parking status dates
         *
         * @param start
         * @param end
         * @returns {string}
         */
        function formatParkingStatusDate(start, end) {
            switch (true) {
                case !start:
                    return '';
                case !end:
                    return formatDateTime(start);
                case isSameDate(start, end):
                    return `${moment(start).format(DATE_FORMAT)} <br>${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
                default:
                    return `${formatDateTime(start)}<br>${formatDateTime(end)}`;
            }
        }

        /**
         * Format date
         *
         * @param string
         * @returns {string}
         */
        function formatDate(string) {
            return moment(string).format(DATE_FORMAT);
        }

        /**
         * Format date time
         *
         * @param string
         * @returns {string}
         */
        function formatDateTime(string) {
            return moment(string).format(DATE_TIME_FORMAT);
        }

        /**
         * On finish handler
         */
        function onFinish() {
            const formData = new FormData();
            const customer = angular.copy(vm.customerData);

            customer.registeredVehicles.forEach(vehicle => {
               delete vehicle.isOpen;

               vehicle.capVehicleRegistration.vehicleName = vehicle.vehicleName;
            });

            customer.bookingFromDate = customer.bookingFromDate 
                ? moment(customer.bookingFromDate).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') 
                : null;
            customer.bookingTillDate = customer.bookingTillDate 
                ? moment(customer.bookingTillDate).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') 
                : null;

            if (customer.userExpirationDate) {
                customer.userExpirationDate = moment(customer.userExpirationDate).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            } else {
                delete customer.userExpirationDate;
            }

            formData.append('customer', angular.toJson(customer));
            formData.append('scheduledAssignments', angular.toJson(prepareScheduledEventsData()));

            createCustomer(formData).then(customer => {
                CustomersCacheFactory.updateSingleRecord(customer.plain());
                onSuccess();

                if (isProfileChanged()) {
                    ShareParkDataService.setUserProfile(vm.customerData.guid, vm.customerData.profile.externalId)
                        .then(onSuccess);
                } else {
                    onSuccess();
                }
            });
        }

        function onSuccess() {
            $state.go('app.shareParkCustomers', {
                paginationData: $stateParams.paginationData
            });
        }

        function prepareScheduledEventsData() {
            const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

            return vm.scheduledAssignments.map(scheduledEvent =>
                Object.assign({}, scheduledEvent, {
                    executionDate: moment(scheduledEvent.executionDate).utc().format(API_DATE_FORMAT)
                }))
        }

        /**
         * Changes visible reservations despite of selectedTab
         * @param { Number } tabId
         */
        function onSelectedReservationsTabChange(tabId) {
            vm.selectedReservationTab = tabId;
            vm.reservationsMaxDate = isActiveReservationsTabActive()
                ? moment(new Date(2030, 0, 1))
                : moment().add(1, 'day').startOf('day');

            onReservationsRangeChange();
        }

        function onCancelTransactionClick({purchase, transaction}) {
            const name = purchase.name;

            PopupService.show({
                templateUrl: '/assets/views/common/popups/cancel-popup/cancel-popup.tpl.html',
                controller: 'cancelPopupController',
                controllerAs: '$ctrl',
                windowClass: 'cancel-popup',
                resolve: {
                    data: () => ({
                        paymentName: name,
                        maxAmount: transaction.transAmount / 100,
                        currencyCode: transaction.transCurrencyCode,
                    })
                }
            }).then(comment => {
                cancelTransaction(transaction.id, comment);
            });
        }

        function cancelTransaction(id, comment = '') {
            ShareParkDataService.cancelTransaction(id, comment).then(() => {
                PopupService.showAlertPopup({
                    text: 'PAYMENT_WAS_CANCELED',
                    okButtonText: 'OK'
                });
                updatePurchasesList();
            }).catch(err => {
                PopupService.showAlertPopup({
                    text: 'FAILED_TO_CANCEL_THE_PAYMENT',
                    okButtonText: 'OK'
                });
            });
        }

        /**
         * Checks if transaction can be refund
         * @param { Object } item
         * @return {boolean}
         */
        function canRefundTransaction(item) {
            return !!(item.transaction && item.transaction.captureId);
        }

        /**
         * Makes refund transaction request
         * @param { Object } payment
         */
        function refundTransaction({purchase, transaction}) {
            const name = purchase.name;
            let refundAmount = 0;
            let refundRedeemAccount = null;
            let refundComment = '';
            PopupService.show({
                templateUrl: '/assets/views/common/popups/refund-popup/refund-popup.tpl.html',
                controller: 'RefundPopupController',
                controllerAs: '$ctrl',
                windowClass: 'refund-popup',
                resolve: {
                    data: () => ({
                        paymentName: name,
                        transaction: transaction,
                        maxAmount: transaction.transAmount / 100,
                        currencyCode: transaction.transCurrencyCode,
                    })
                }
            }).then(({
                amount,
                redeemAccount,
                comment,
            }) => {
                refundAmount = amount;
                refundRedeemAccount = redeemAccount;
                refundComment = comment;

                if (redeemAccount) {
                    refundAmount = refundAmount * redeemAccount.moneyToValueRatio;
                    const round = getRound(redeemAccount.moneyToValueRatio);
                    const lng = TranslateService.getCurrentUiLanguageTranslation;
                    const currency = lng(redeemAccount.accountCurrency);
                    return $translate('REFUND_FINAL_CONFIRMATION', {
                        amount: `${refundAmount.toFixed(round).replace('.', ',')} ${currency}`,
                        transName: name,
                        paymentMean: lng(redeemAccount.name),
                    }, undefined, undefined, false, 'escape')
                } else {
                    return $translate('REFUND_FINAL_CONFIRMATION', {
                        amount: `${amount} ${transaction.transCurrencyCode}`,
                        transName: name,
                        paymentMean: transaction.transAcqName,
                    }, undefined, undefined, false, 'escape')
                }
            }).then(message => {
                PopupService.showConfirmationPopup({
                    text: message,
                    translatedText: true,
                    okButtonText: 'OK',
                    cancelButtonText: 'CANCEL'
                }, function() {
                    if (refundRedeemAccount) {
                        sendRefundTransactionRequest(
                            transaction.id,
                            refundAmount * refundRedeemAccount.accountPrecision,
                            refundComment,
                            refundRedeemAccount.id
                        );
                    } else {
                        sendRefundTransactionRequest(
                            transaction.id,
                            refundAmount * 100,
                            refundComment
                        );
                    }
                });
            });
        }

        function getRound(moneyToValueRatio) {
            if (moneyToValueRatio >= 100) {
                return 0;
            }

            if (moneyToValueRatio >= 10) {
                return 1;
            }

            return 2;
        }

        function sendRefundTransactionRequest(id, amount, comment = '', redeemAccountId = null) {
            ShareParkDataService.refundTransaction(id, amount, comment, redeemAccountId)
                .then(response => {
                    PopupService.showAlertPopup({
                        text: response || 'REFUND_SUCCESSFUL',
                        okButtonText: 'OK'
                    });
                    updatePurchasesList();
                }).catch(err => {
                console.log(err);
                PopupService.showAlertPopup({
                    text: 'FAILED_TO_REFUND_TRANSACTION',
                    okButtonText: 'OK'
                });
            });
        }

        function isProfileChanged() {
            const currentProfileId = vm.customerData.profile ? vm.customerData.profile.externalId : null;
            return vm.savedProfile
                ? (vm.savedProfile !== currentProfileId)
                : currentProfileId;
        }

        function setProfile() {
            return ShareParkDataService.setUserProfile(vm.customerData.guid, vm.customerData.profile.externalId)
                .then(() => {
                    vm.savedProfile = vm.customerData.profile.externalId;

                    if (vm.customerData.guid && vm.isEditMode) {
                        onReservationsRangeChange();
                        updatePurchasesList();
                        getParkingStatuses();
                    }
                })
        }

        /**
         * Updates reservations list on reservations range change
         */
        function onReservationsRangeChange() {
            const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

            ShareParkDataService.getUserReservations({
                startDate: moment(vm.reservationsMinDate).utc().format(API_DATE_FORMAT),
                endDate: moment(vm.reservationsMaxDate).utc().format(API_DATE_FORMAT),
                guid: vm.customerData.guid,
            }).then(reservations => {
                reservations.sort((a, b) => {
                    return moment(a.permissionTill).isBefore(b.permissionTill) ? 1 : -1;
                });
                vm.passedReservations = reservations.filter(reservation =>
                    moment(reservation.permissionTill).isBefore(moment())
                );
                vm.activeReservations = reservations.filter(reservation =>
                    moment(reservation.permissionTill).isAfter(moment())
                );

                vm.visibleReservations = isActiveReservationsTabActive()
                    ? vm.activeReservations
                    : vm.passedReservations;
            }).catch(err => {
                console.log(err);
                vm.activeReservations = [];
                vm.passedReservations = [];
                vm.visibleReservations = [];
            });
        }

        function stopParking(parkingStatus) {
            ShareParkDataService.getCarParkByExternalId(parkingStatus.carParkRef)
                .then(({ carPark }) => {
                    if (!carPark) {
                        return PopupService.showAlertPopup({
                            text: 'RELATED_CAR_PARK_NOT_FOUND',
                            okButtonText: 'OK'
                        })
                    }
                    StopParkingPopupService.show(carPark)
                        .then((response) => {
                            stopParkingApi(Object.assign(response, {carPark, parkingStatus}))
                        });
                });
        }

        function stopParkingApi ({ gatewayRef, entranceRef, exitTime, carPark, parkingStatus }) {
            ShareParkDataService.stopParkingProcedure({
                carparkRef: carPark.external_id,
                gatewayRef,
                entranceRef,
                exitTime,
                permissionRef: parkingStatus.permissionRef
            }).then(getParkingStatuses)
                .catch(err => {
                    console.error(err);
                    PopupService.showAlertPopup({
                        text: 'FAILED_TO_STOP_PARKING_PROCEDURE',
                        okButtonText: 'OK'
                    })
                })
        }

        /**
         * @return {boolean}
         */
        function isActiveReservationsTabActive() {
            return vm.selectedReservationTab === reservationTabs.ACTIVE.id
        }

        /**
         * Checks if activation time is unlimited
         * @return {boolean}
         */
        function isUserActivationTimeUnlimited() {
            return moment(MOBILE_CAMPAIGN_INFINITE_DATE).isSame(vm.customerData.userExpirationDate, 'day');
        }

        function toggleUnlimited() {
            if (isUserActivationTimeUnlimited()) {
                vm.customerData.userExpirationDate = moment().add(1, 'day');
            } else {
                vm.customerData.userExpirationDate = MOBILE_CAMPAIGN_INFINITE_DATE;
            }
        }

        function printVipTicket() {
            const tenantId = TenantHelper.getCurrentTenant().id;
            const printPage = window.open(
                `${location.origin}/print-qr-code-app/index.html?customerGuid=${vm.customerData.guid}&tenant=${tenantId}`,
                '_blank'
            );

            printPage.onafterprint = () => printPage.close();
            printPage.focus();
        }

        function onCsvDownloadParkingStatuses() {
            if (!vm.parkingStatuses || !vm.parkingStatuses.length) {
                return alert('NO_PARKING_STATUSES');
            }

            const array = [
                [
                    'Number Plate',
                    'Price',
                    'Start',
                    'End',
                    'Duration',
                ]
            ];

            vm.parkingStatuses.forEach(item => {
                array.push([
                    item.carNumberPlate,
                    item.parkingCostsLabel,
                    item.startedParking
                        ? moment(item.startedParking)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : '',
                    item.endedParking
                        ? moment(item.endedParking)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : '',
                    item.duration
                ]);
            });

            const content = CsvService.generateCsvContent(array);
            ClipboardService.copy(content);

            PopupService.showAlertPopup({
                text: 'LIST_CONTENT_WAS_COPIED_TO_CLIPBOARD',
                okButtonText: 'OK'
            });

        }

        function onCsvDownloadPayments() {
            if (!vm.purchases || !vm.purchases.length) {
                return alert('NO_PURCHASES');
            }

            const array = [
                [
                    'Name',
                    'transAcqName',
                    'Price',
                    'Bonus',
                    'Refund',
                    'Date',
                ]
            ];

            vm.purchases.forEach(item => {
                array.push([
                    item.purchase && item.purchase.name,
                    item.transaction.transAcqName,
                    item.transaction.transAmount / 100 + ' ' + item.transaction.transCurrencyCode,
                    item.purchase && item.purchase.redeemAmount
                      ? item.purchase.redeemAmount / 100 + ' ' + item.purchase.currencyCode
                      : '',
                    item.transaction.refundAmount
                      ? item.transaction.refundAmount / 100 + ' ' + item.transaction.transCurrencyCode
                      : '',
                    item.purchase && item.purchase.purchaseDate
                        ? moment(item.purchase.purchaseDate)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : '',
                ]);
            });

            const content = CsvService.generateCsvContent(array);
            ClipboardService.copy(content);

            PopupService.showAlertPopup({
                text: 'LIST_CONTENT_WAS_COPIED_TO_CLIPBOARD',
                okButtonText: 'OK'
            });

        }

        function onCsvDownloadTickets() {
            if (!vm.visibleReservations || !vm.visibleReservations.length) {
                return alert('NO_TICKETS');
            }

            const array = [
                [
                    'Car Park',
                    'Address',
                    'Number Plate',
                    'Ticket',
                    'Start',
                    'End',
                    'Sub Area',
                    'Status',
                ]
            ];

            vm.visibleReservations.forEach(item => {
                array.push([
                    item.carPark.name,
                    item.carPark.address_city + ' ' + item.carPark.address_street,
                    item.numberPlate,
                    item.id,
                    item.permissionFrom
                        ? moment(item.permissionFrom)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : '',
                    item.permissionTill
                        ? moment(item.permissionTill)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : '',
                    vm.getSubAreaName(item),
                    item.status === vm.cancelledReservationStatus && item.canceledAt
                        ? 'CANCELLED ' + moment(item.canceledAt)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : '',
                ]);
            });

            const content = CsvService.generateCsvContent(array);
            ClipboardService.copy(content);

            PopupService.showAlertPopup({
                text: 'LIST_CONTENT_WAS_COPIED_TO_CLIPBOARD',
                okButtonText: 'OK'
            });

        }

        function showBonusPayoutDialog(event) {
            if (!vm.redeemAccounts.length || !vm._getBonusPayoutSum(vm.redeemAccounts)) {
                PopupService.showAlertPopup({
                    text: 'BONUS_PAYOUT_UNAVAILABLE',
                    okButtonText: 'OK',
                });
                return;
            }

            DialogCustomerPayoutService.show({
                event,
                admin: vm.customerData,
                bonusList: vm.redeemAccounts,
            }).then(() => {}, accounts => {
                if (accounts) {
                    vm.redeemAccounts = accounts;
                }
            });
        }

        function _getBonusPayoutSum(accounts) {
            let sum = 0;

            accounts.forEach(({ accountValue }) => sum += accountValue);

            return sum;
        }

        $scope.$watch(function() {
            return vm.newCustomerForm && vm.newCustomerForm.$valid;
        }, function(formValid) {
            vm.state.canFinish = formValid;
        }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();

(function () {
    'use strict';

    angular.module('beacon.app')
        .component('tenantEditOauth', {
            templateUrl: '/assets/views/tenants/tabs/tenants-tab/new/tenant-edit-oauth/tenant-edit-oauth.tpl.html',
            controller: TenantEditOauthController,
            bindings: {
                tenant: '<',
            }
        });

    function TenantEditOauthController(
        $scope,
    ) {
        const $ctrl = this;

        $ctrl.openidSecretDisplay = '';
        $ctrl.oauthConf = {
            domains: [],
            openId: {
                isEnabled: false,
                params: {
                    authorizationEndpoint: '',
                    tokenEndpoint: '',
                    clientId: '',
                    redirectURI: '',
                    clientSecret: '',
                    responseType: 'code',
                    scope: ['openid', 'profile', 'email'],
                },
            },
            microsoft: {
                isEnabled: false,
                params: {
                    clientId: '',
                },
            },
        };

        $ctrl.$onInit = () => {
            if (!$ctrl.tenant.oauth) { return; }

            _setOAuthConfig();
        };

        $ctrl.onOpenidClientSecretChange = (newValue) => {
            $ctrl.openidSecretDisplay = newValue;
        };

        $ctrl.onOpenidSecretInputBlur = () => {
            if ($ctrl.oauthConf.openId.params.clientSecret) {
                return;
            }

            $ctrl.setOpenidSecret();
        };

        $ctrl.setOpenidSecret = () => {
            $ctrl.oauthConf.openId.params.clientSecret = angular.copy($ctrl.openidSecretDisplay);

            _hideOpenidClientSecret($ctrl.openidSecretDisplay);
        };

        $ctrl.resetOpenidSecret = () => {
            $ctrl.openidSecretDisplay = '';
            $ctrl.oauthConf.openId.params.clientSecret = '';
        };

        function _setOAuthConfig() {
            $ctrl.oauthConf = {
                ...$ctrl.oauthConf,
                ...$ctrl.tenant.oauth
            };

            _hideOpenidClientSecret($ctrl.oauthConf.openId.params.clientSecret);
        }

        /**
         * Partially hide client secret string
         * @param {string|undefined} secret
         */
        function _hideOpenidClientSecret(secret) {
            if (!secret) {
                return;
            }

            const len = secret.length;
            const replaceIndx = 4;
            const visiblePart = secret.substring(0, replaceIndx);
            const hiddenPart = Array(len - replaceIndx).fill('*').join('');

            $ctrl.openidSecretDisplay = visiblePart + hiddenPart;
        }

        $scope.$watch(
            () => $ctrl.oauthConf,
            () => {
                $ctrl.tenant.oauth = $ctrl.oauthConf;
            },
            true
        );
    }
})();

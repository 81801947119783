(function() {
    'use strict';

    angular.module('beacon.app')
        .service('RevenuesExportPopupService', RevenuesExportPopupService);

    function RevenuesExportPopupService(PopupService) {

        return {
            show
        };

        function show() {
            return PopupService.show({
                templateUrl: '/assets/views/share-park/operations/revenues/revenues-export-popup/revenues-export-popup.tpl.html',
                controller: 'RevenuesExportPopupController',
                controllerAs: '$ctrl',
                windowClass: 'revenues-export-popup-wrapper',
                backdropClass: 'revenues-export-popup-backdrop',
                backdrop: true,
            });
        }
    }
}());
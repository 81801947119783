(function() {
    'use strict';

    angular.module('dynamicContent')
        .component('dynamicContentItem', {
            templateUrl: '/assets/views/dynamic-content/dynamic-content-item/dynamic-content-item.tpl.html',
            bindings: {
                title: '<',
                message: '<',
                class: '@',
                flag: '<',
                imageLoadedCallback: '<',
                fontColor: '<',
            }
        });
})();
(function () {
    'use strict';

    angular.module('beacon.app')
        .controller('SponsorFormToolbarController', SponsorFormToolbarController);

    function SponsorFormToolbarController(
        $state,
        StatesFactory,
        $stateParams,
    ) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.SponsorStates);
        vm.state = vm.stateAss.state;

        vm.state.finish = false;
        vm.state.save = false;

        // public methods
        vm.cancel = cancel;
        vm.finish = finish;
        vm.save = save;

        vm.showSave = $stateParams.type === 'new';

        function cancel() {
            $state.go('app.loyaltySponsorList');
        }

        /**
          * Finish button handler
          */
        function finish() {
            vm.state.finish = true;
        }

        /**
         * Finish button handler
         */
        function save() {
            vm.state.save = true;
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('RevenuesExportPopupController', RevenuesExportPopupController);

    function RevenuesExportPopupController(
        $uibModalInstance,
        RevenuesDataService,
        ) {
        const vm = this;

        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.isDisabled = isOkButtonDisabled;

        vm.rangeStart = moment().subtract(1, 'months');
        vm.rangeEnd = moment();

        /**
         * "OK" click handler
         */
        function okHandler() {
            const rangeStart = moment(vm.rangeStart).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            const rangeEnd = moment(vm.rangeEnd).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

            const fileName = 'revenuesByProfile.csv';
            const a = document.createElement('a');
            document.body.appendChild(a);
            RevenuesDataService.exportRevenuesByProfile(rangeStart, rangeEnd).then(revenues => {
                const file = new Blob([revenues], {type: 'text/csv'});
                a.href = window.URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            });
            $uibModalInstance.close();
        }

        /**
         * "Cancel" click handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss();
        }

        function isOkButtonDisabled() {
            return moment(vm.rangeStart).isBefore(vm.rangeEnd);
        }
    }
})();
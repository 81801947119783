(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pageDetailsDefaultHeader', {
            templateUrl: '/assets/views/content/elements/headers/page-default/header.tpl.html',
            controller: PageDetailsDefaultHeader,
            bindings: {
                contentForm: '<',
                contentData: '<',
                pageData: '<'
            }
        });

    function PageDetailsDefaultHeader(ContentDataService, PopupService, Cropper,
                                      CONTENT_TYPES, PAGE_TYPES) {
        const vm = this;

        // public properties
        vm.CONTENT_TYPES = CONTENT_TYPES;
        vm.clearImageFile = false;

        vm.icons = [
            'account_circle',
            'announcement',
            'accessibility',
            'card_giftcard',
            'home',
            'info_outline',
            'language'
        ];
        vm.loaderConfig = {
            minWidth: 400,
            minHeight: 400,
            center: true,
            fullInfo: true,
            aspectRatio: 1,
        };

        // public methods
        vm.onPageImage = onPageImage;
        vm.deletePageImage = deletePageImage;
        vm.isExternalType = isExternalType;

        // private methods

        /**
         * Delete page image
         */
        function deletePageImage() {
            if (vm.pageData.header.image.fromContent
                || (vm.contentData.copy && !vm.imageChanged)) {
                clearImageProps();
                return;
            }

            let imageUrls = [];
            let formData = new FormData();

            imageUrls.push(vm.pageData.header.image.cropped.img);
            imageUrls.push(vm.pageData.header.image.img);

            formData.append('images', imageUrls);
            ContentDataService.deleteImages(formData)
                .then(() => {
                    clearImageProps();
                })
                .catch(console.error.bind(console));
        }

        /**
         * Clears image properties in vm.models
         */
        function clearImageProps() {
            vm.pageData.header.image.cropped.img = null;
            vm.pageData.header.image.img = null;
            vm.pageData.header.image.name = null;
        }

        /**
         * Create file from Blob object
         * @param  {Blob}   theBlob
         * @param  {String} fileName
         * @return {File}
         */
        function blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        }

        /**
         *
         * @param imageData
         * @param uploadToS3
         */
        function onPageImage(imageData, uploadToS3) {
            vm.clearImageFile = false;

            if (uploadToS3) {
                const imagePath = imageData.blob.name.split('.');
                const ext = imagePath[imagePath.length - 1];
                const formData = new FormData();

                formData.append('image', blobToFile(imageData.blob, `temp.${ext}`), `temp.${ext}`);

                vm.pageData.header.image.cropped = angular.copy(imageData.cropData);
                vm.pageData.header.image.fromContent = false;

                ContentDataService.upload(formData)
                    .then((res) => {
                        vm.imageChanged = true;
                        vm.pageData.header.image.cropped.img = res;
                        vm.pageData.header.image.cropped.newImage = true;
                    })
                    .catch(console.error.bind(console));
            } else {
                vm.pageData.header.image.fromContent = true;
                vm.imageChanged = true;
                vm.pageData.header.image.cropped.img = imageData.name;
            }
        }

        function isExternalType() {
            return vm.pageData.page_type_id === PAGE_TYPES.EXTERNAL_APP;
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('locationDetails', {
            controller: LocatioDetailsController,
            templateUrl: '/assets/views/common/components/location-details/location-details.tpl.html',
            bindings: {
                locationData: '<',
                allowEditTags: '<',
                markerClickCallback: '=',
            },
        });

    function LocatioDetailsController(
        PopupService,
        LocationDataService,
        PermissionsService
    ) {
        const vm = this;

        const NO_REF = -1;

        vm.showImagePopup = showImagePopup;
        vm.saveTags = saveTags;
        vm.showScreenDetails = showScreenDetails;

        vm.isScreenUrlVisible = PermissionsService.isScreenUrlVisible();

        /**
         * Sync tags with locations
         *
         * @param {array} tags - array of tags
         */
        function saveTags(tags) {
            const tagsList = tags.map(tag => tag.id);
            LocationDataService.syncTags(vm.locationData.id, tagsList);
        }

        /**
         * Shows image preview popup
         */
        function showImagePopup() {
            const resolve = {
                data: () => ({
                    okButtonText: 'Ok',
                    imageUrl: vm.locationData.details.screen.imageRef
                })
            };

            PopupService.show({
                templateUrl: '/assets/views/common/popups/image-preview/image-preview.tpl.html',
                controller: 'ImagePreviewPopupController',
                controllerAs: 'imagePreviewPopupController',
                windowClass: 'imagePreviewPopup',
                keyboard: false,
                resolve
            });
        }

        /**
         * Checks if screen details should be shown
         * @return {boolean}
         */
        function showScreenDetails() {
            return !!(vm.locationData.screenRef > NO_REF && vm.locationData.details);
        }
    }
})();

(function () {
    'use strict';

    angular.module('beacon.app')
            .factory('newUserState', function () {
                return {
                    views: {
                        INFO: 0,
                        ROLES: 1,
                    }
                };
            })
            .controller('UsersController', UsersController);

    function UsersController(
        $state,
        $translate,
        $q,
        StorageFactory,
        UserDataService,
        LookupDataService,
        TenantDataService,
        PopupService,
        UserGroupsDataService,
        USER_STATUS_TYPES,
    ) {
        const vm = this;

        const STORAGE = StorageFactory.Storage('Main');
        const currentUserInfo = STORAGE ? STORAGE.get('currentUserInfo') : null;

        /**
         * @type {User[]}
         */
        let allUsers = [];
        let rolesMap = {};
        let permissionsMap = {};
        let permissionsGroupMap = {};
        let tenantsMap = {};
        let currentUserData = {};
        vm.userGroups = {};

        //  public methods
        vm.createUser = createUser;
        vm.filterUsers = filterUsers;
        vm.onStatusFilterChange = filterUserListByStatus;
        vm.selectedUserGroup = 'ALL';

        //  public properties
        vm.searchValue = '';
        vm.paginationData = {
            itemsPerPage: 10
        };
        vm.updateCounter = 0;
        vm.usersListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'user-name-column',
                    width: '20',
                    title: item => {
                        const initials = [];
                        item.first_name && initials.push(item.first_name);
                        item.last_name && initials.push(item.last_name);
                        return `${item.name} <span class="small test-muted">${initials.join(' ')}</span>`
                    }
                },
                {
                    name: 'EMAIL',
                    class: 'user-column',
                    width: '25',
                    title: 'email'
                },
                {
                    name: 'STATUS',
                    class: 'user-column',
                    translate: true,
                    width: '10',
                    title: (item) => {
                        let status = '';

                        switch(item.status) {
                            case USER_STATUS_TYPES.ACTIVE.value:
                                status = USER_STATUS_TYPES.ACTIVE.label;
                                break;
                            case USER_STATUS_TYPES.INACTIVE.value:
                                status = USER_STATUS_TYPES.INACTIVE.label;
                                break;
                            case USER_STATUS_TYPES.DELETED.value:
                                status = USER_STATUS_TYPES.DELETED.label;
                                break;
                            default:
                                break;
                        }

                        return status;
                    }
                },
                {
                    name: 'CREATED_AT',
                    class: 'user-column',
                    width: '15',
                    title: (item) => {
                        return moment(item.created_at).format('LL');
                    }
                },
                {
                    name: 'UPDATED_AT',
                    class: 'user-column',
                    width: '15',
                    title: (item) => {
                        return moment(item.updated_at).format('LL');
                    }
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteUser,
                        isVisible: isUserItemButtonVisible,
                    },
                    {
                        class: 'editBtn',
                        callback: updateUser,
                        isVisible: isUserItemButtonVisible,
                    },
                    {
                        class: (userItem) => {
                            let className = '';

                            switch (userItem.status) {
                                case USER_STATUS_TYPES.ACTIVE.value:
                                    className = 'block-btn';
                                    break;
                                case USER_STATUS_TYPES.INACTIVE.value:
                                case USER_STATUS_TYPES.DELETED.value:
                                    className = 'check-btn';
                                    break;
                                default:
                                    break;
                            }

                            return className;
                        },
                        callback: onChangeStatusBtnClick,
                        isVisible: (btn, userItem) => currentUserInfo.id !== userItem.id,
                    },
                ]
            },
            updateCallback: updateUsersList,
            additionalData: {
                userRolesListData: {
                    columns: [
                        {
                            name: 'TENANT',
                            class: 'user-column',
                            width: 15,
                            title: (item) => {
                                return getNameValue(item, 'tenant_id', tenantsMap);
                            }
                        },
                        {
                            name: 'SUBDOMAIN',
                            class: 'user-column',
                            width: 15,
                            title: (item) => {
                                let title = '';
                                if (item && item.tenant_id && tenantsMap && tenantsMap[item.tenant_id]) {
                                    title = getNameValue(item, 'subdomain_id', tenantsMap[item.tenant_id].subdomainsMap);
                                }
                                return title;
                            }
                        },
                        {
                            name: 'ROLE',
                            class: 'user-column',
                            width: 10,
                            title: (item) => {
                                return getNameValue(item, 'role_id', rolesMap);
                            }
                        },
                        {
                            name: 'USER_GROUP',
                            class: 'user-group-column',
                            width: 25,
                            title: (item) => getUserGroupName(item.user_groups_allowed), // #ListOfAllowedUserGroups
                        },
                        {
                            name: 'LOGIN_TIMEOUT',
                            class: 'timeout-column',
                            width: 15,
                            title: (item) => {
                                return item.loginTimeout + ' min' || '-';
                            }
                        },
                        {
                            name: 'APPS',
                            class: 'apps-column',
                            width: 20,
                            title: (item) => {
                                return item.allowedModes.join(', ');
                            }
                        }
                    ]
                }
            }
        };
        vm.users = [];
        vm.usersLength = 0;
        vm.currentUsers = [];
        vm.filteredUsers = [];
        vm.statusTypes = prepareUserStatusTypes();

        //#region User CRUD methods

        /**
         * Creates new user
         */
        function createUser() {
            $state.go('app.addUser', {
                data: getStateData(),
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Updates user
         *
         * @param {MouseEvent} $event
         * @param {Object} userItem
         */
        function updateUser($event, userItem) {
            $event.preventDefault();
            $event.stopPropagation();

            let stateData = getStateData();
            stateData.userData = userItem;

            // Filter out deleted groups but leave the deleted one if it is still in use
            stateData.userGroups = angular.copy(stateData.userGroups).filter(group => {
                return group.deleted !== 1 || userItem.roles.find(role => (role.user_groups.find(item => item.id === group.id)))
            });
            $state.go('app.editUser', {
                data: stateData,
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Creates objects with loaded lookup data
         *
         * return {object} stateData
         */
        function getStateData() {
            return {
                rolesMap: rolesMap,
                permissionsMap: permissionsMap,
                permissionsGroupMap: permissionsGroupMap,
                tenantsMap: tenantsMap,
                currentUserData: currentUserData,
                userGroups: vm.userGroups,
            };
        }

        /**
         * Deletes user by id
         */
        function deleteUser($event, userItem) {
            $event.preventDefault();
            $event.stopPropagation();

            $translate('YOU_ARE_ABOUT_TO_DELETE_USER', { userName: userItem.name})
                .then(text => {
                    PopupService.showConfirmationPopup({
                        text,
                        okButtonText: 'DELETE',
                        cancelButtonText: 'CANCEL'
                    }).then(() => {
                        UserDataService.deleteUser(userItem.id)
                            .then(() => {
                                userItem.status = USER_STATUS_TYPES.DELETED.value;
                                userItem.roles = [];

                                filterUserListByStatus();
                            })
                            .catch((response) => {
                                if (response.status === 500) {
                                    PopupService.showAlertPopup({
                                        text: 'ERROR.INTERNAL_SERVER_ERROR',
                                        okButtonText: 'OK'
                                    });
                                }
                            });
                    });
                });
        }

        /**
         * Change user status
         *
         * @param {object} userItem
         * @param {number} status User status. See USER_STATUS_TYPES
         */
        function changeUserStatus(userItem, status) {
            UserDataService.changeUserStatus(userItem.id, status)
                .then((res) => {
                    let updateUserItem = res.plain();
                    userItem.status = updateUserItem.status;

                    filterUserListByStatus();

                    PopupService.showAlertPopup({
                        text: 'USER_STATUS_HAS_BEEN_CHANGED',
                        okButtonText: 'OK'
                    });
                })
        }

        //#endregion

        //#region Public/exposed methods

        /**
         * Determines whether to display a button. If User status is DELETED then hide all buttons.
         *
         * @param {object} btn
         * @param {object} userItem
         * @return {boolean} True if btn should be visible, otherwise false
         */
        function isUserItemButtonVisible(btn, userItem) {
            return userItem.status !== USER_STATUS_TYPES.DELETED.value;
        }

        /**
         * Change user status button click handler
         *
         * @param {jQuery.Event} $event
         * @param {Restangular.Object} userItem
         */
        function onChangeStatusBtnClick($event, userItem) {
            $event.preventDefault();
            $event.stopPropagation();

            let text = '';
            let status;

            switch (userItem.status) {
                case USER_STATUS_TYPES.ACTIVE.value:
                    text = USER_STATUS_TYPES.INACTIVE.actionLabel;
                    status = USER_STATUS_TYPES.INACTIVE.value;
                    break;
                case USER_STATUS_TYPES.INACTIVE.value:
                case USER_STATUS_TYPES.DELETED.value:
                    text = USER_STATUS_TYPES.ACTIVE.actionLabel;
                    status = USER_STATUS_TYPES.ACTIVE.value;
                    break;
                default:
                    break;
            }

            PopupService.showConfirmationPopup({
                text: `ARE_YOU_SURE_YOU_WANT_TO_${text}_THIS_USER`,
                okButtonText: 'OK',
                cancelButtonText: 'CANCEL'
            }).then(() => changeUserStatus(userItem, status));
        }

        /**
         * Updates pagination
         *
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function updateUsersList(page, itemsPerPage) {
            vm.currentUsers = vm.filteredUsers.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        //#endregion

        //#region Private methods

        /**
         * Initialization method
         */
        function init() {
            $q.all([
                LookupDataService.getAccessData(),
                TenantDataService.getTenantsMap({
                        "include[]": ["subdomains", "permissions", "locations"]
                    }),
                UserDataService.loadUserData(),
                UserGroupsDataService.getUserGroups().catch(() => vm.userGroups = []),
            ]).then((responses) => {
                if (responses && _.isArray(responses) && responses.length) {
                    if (responses[0]) {
                        rolesMap = responses[0].rolesMap ? responses[0].rolesMap : rolesMap;
                        permissionsMap = responses[0].permissionsMap ? responses[0].permissionsMap : permissionsMap;
                        permissionsGroupMap = responses[0].permissions_groupMap ? responses[0].permissions_groupMap : permissionsGroupMap;
                    }
                    tenantsMap = responses[1] || tenantsMap;
                    currentUserData = responses[2] || currentUserData;
                    vm.userGroups = responses[3].sort((a, b) => a.name.localeCompare(b.name));
                }
                reloadUsersList();
            }).catch(console.error.bind(console));
        }

        /**
         * Reloads users list
         */
        function reloadUsersList() {
            UserDataService.getUsers().then(function (response) {
                allUsers = response;

                filterUserListByStatus();
            });
        }

        /**
         * Prepares user status types
         *
         * @return {object[]} List of prepared status types
         */
        function prepareUserStatusTypes() {
            return [
                {
                    id: USER_STATUS_TYPES.ACTIVE.value,
                    label: USER_STATUS_TYPES.ACTIVE.label,
                    isChecked: false
                },
                {
                    id: USER_STATUS_TYPES.INACTIVE.value,
                    label: USER_STATUS_TYPES.INACTIVE.label,
                    isChecked: false
                },
                {
                    id: USER_STATUS_TYPES.DELETED.value,
                    label: USER_STATUS_TYPES.DELETED.label,
                    isChecked: false
                }
            ];
        }

        /**
         * Extracts name value from role item
         *
         * @param {object} item
         * @param {string} value_id
         * @param {object} collection
         * @return {string}
         */
        function getNameValue(item, value_id, collection) {
            return _.isObject(item) && value_id && _.isObject(collection)
            && item[value_id] && collection[item[value_id]]
            && collection[item[value_id]].name ? collection[item[value_id]].name : "";
        }

        /**
         * @param {array} userGroups
         */
        function getUserGroupName(userGroups) {
            if (!userGroups || !userGroups.length) {
                return '-';
            }

            return userGroups.map(item => item.name).join(', ');
        }

        /**
         * Filters users by their status
         *
         * @return {array} List of filtered users by their status
         */
        function filterUserListByStatus() {
            let checkedCheckboxesKeys = vm.statusTypes.filter(v => v.isChecked).map(v => v.id);

            let users = allUsers;

            if (checkedCheckboxesKeys.length) {
                users = allUsers.filter(user => checkedCheckboxesKeys.includes(user.status));
            }

            vm.users = users;
            filterUsers();
            vm.usersListData.updateCallback(1, vm.paginationData.itemsPerPage);
        }

        function filterUsers() {
            const matchQuery = (value) => value ? value.toUpperCase().includes(vm.searchValue.toUpperCase()) : false;
            const matchGroupName = (userRoles) => {
                const groupName = vm.searchValue;
                const foundGroups = vm.userGroups.filter(({ name })=> name.toUpperCase().includes(groupName.toUpperCase()));
    
                return Boolean(userRoles.find(
                    role => foundGroups.find(
                        group => role.user_groups.find(item => item.id === group.id)
                    )
                ));
            }

            vm.filteredUsers = vm.searchValue
                ? vm.users.filter(({ name, first_name, last_name, email, roles }) => {
                    return matchQuery(name)
                        || matchQuery(first_name)
                        || matchQuery(last_name)
                        || matchQuery(email)
                        || matchGroupName(roles);
                })
                : vm.users;

            vm.filteredUsers = vm.selectedUserGroup !== 'ALL'
                ? vm.filteredUsers.filter(
                    ({ roles }) => !!roles.filter(
                        role => role.user_groups.find(
                            item => item.id === vm.selectedUserGroup
                        )
                    ).length
                )
                : vm.filteredUsers;

            vm.usersLength = vm.filteredUsers.length;
            vm.updateCounter++;
        }

        //#endregion

        init();
    }
}());

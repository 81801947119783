(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timePicker', {
            templateUrl: '/assets/views/common/components/time-picker/time-picker.tpl.html',
            controller: TimePickerController,
            bindings: {
                input: '=',
                label: '@',
                name: '@',
                form: '<',
                required: '<',
            }
        });

    /**
     * @property {moment.Moment} value
     * @param $moment
     * @constructor
     */
    function TimePickerController(
        $moment,
    ) {
        const $ctrl = this;
        const TIME_FORMAT = 'HH:mm';

        $ctrl.$onInit = () => {
            $ctrl.value = $moment($ctrl.input, TIME_FORMAT);
        }

        $ctrl.onChange = () => {
            $ctrl.input = $ctrl.value && $ctrl.value.isValid()
                ? $ctrl.value.format(TIME_FORMAT)
                : null;
        }
    }
})();

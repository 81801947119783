(function() {
    'use strict';

    angular.module('beacon.app')
        .component('onboardingContentType', {
            templateUrl: '/assets/views/content/elements/types/onboarding/onboarding.tpl.html',
            controller: OnboardingContentTypeController,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<'
            }
        });

    function OnboardingContentTypeController($q,
                                             $scope,
                                             PopupService,
                                             StatesFactory,
                                             ContentDataProcessingService,
                                             ContentDataService,
                                             LanguageService,
                                             ContentHelper,
                                             OnboardingHelper,
                                             SegmentDataService,
                                             UtilitiesService,
                                             GoogleMapsUtilitiesService,
    ) {
        const vm = this;

        vm.MODEL_TYPES = OnboardingHelper.MODEL_TYPES;
        vm.CONTENT_TYPE_SEGMENT = OnboardingHelper.CONTENT_TYPE_SEGMENT;

        vm.state = StatesFactory.ContentStates;

        vm.listConfig = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'onboarding-create__content-title',
                    width: '50',
                    title: item => OnboardingHelper.getTitle(item)
                },
                {
                    name: 'CONTENT_TYPE',
                    class: 'onboarding-create__content-type',
                    width: '35',
                    translate: true,
                    title: item => item.content_type.name
                }
            ],
            additionalData: {
                itemMapCallback: GoogleMapsUtilitiesService.showMap,
                getMessageText: ContentHelper.getMessageText,
            },
        };

        vm.items = [];
        vm.language_id = vm.langArray[0].id;
        vm.isEditMode = vm.state.type === 'edit';
        vm.isCreateMode = vm.state.type === 'new';

        vm.addContentElement = addContentElement;
        vm.selectContent = selectContent;
        vm.onDragEnd = onDragEnd;
        vm.onDrop = onDrop;
        vm.getAccordionTemplateName = OnboardingHelper.getAccordionTemplateName;

        vm.$onInit = init;

        /**
         * Controller initialization
         */
        function init() {
            _loadConfigs();

            if (vm.isEditMode || vm.contentData.copy) {
                _loadContents();
            }
        }

        /**
         * Cache item index on drop event
         *
         * @param {int} index - place where item was dropped
         * @param {object} item - item object (before dragging)
         * @return {object}
         */
        function onDrop(index, item) {
            vm.itemIndexWhereDropped = index;
            return item;
        }

        /**
         * Set a new object to the preview after dragging and remove the old one from list
         * (the drag component has already created a new one)
         *
         * @param index - place, where origin item was shifted after drop
         */
        function onDragEnd(index) {
            selectContent(vm.items[vm.itemIndexWhereDropped]);
            if (vm.itemIndexWhereDropped !== index) {
                vm.items.splice(index, 1);
            }
        }

        /**
         * Load and set content configs to scope
         *
         * @private
         */
        function _loadConfigs() {
            const CONTENT_CONFIG_APP_LOCATION = 1;
            ContentDataService.getContentConfigs()
                .then(response => {
                    vm.appLocations = response.plain()
                        .filter(item => item.configType === CONTENT_CONFIG_APP_LOCATION)
                        .map(item => {
                            item.parameters = UtilitiesService.decodeConfigString(item.parameters);
                            return item;
                        });
                    // set first item as default
                    if (vm.isCreateMode) {
                        vm.contentData.message.location = vm.appLocations[0] && vm.appLocations[0].parameters.location;
                    }
                });
        }

        /**
         * Load and set contents to scope
         *
         * @private
         */
        function _loadContents() {
            const contentElementsList = _getList(vm.contentData.message.elements, vm.MODEL_TYPES.CONTENT);
            const segmentsList = _getList(vm.contentData.message.elements, vm.MODEL_TYPES.SEGMENT);

            const PROMISE_INDEXES = {
                LANGUAGES: 0,
                CONTENTS: 1,
                SEGMENTS: 2
            };

            $q.all([
                LanguageService
                    .getLanguages()
                    .catch(console.error.bind(console)),
                ContentDataService
                    .contents({contentId: contentElementsList.join(',')})
                    .catch(console.error.bind(console)),
                SegmentDataService
                    .getInternalSegments({
                        id: segmentsList.join(',')
                    })
                    .catch(console.error.bind(console)),
            ]).then(response => {
                vm.langArrayAll = response[PROMISE_INDEXES.LANGUAGES].plain();

                const contents = _processContentsResponse(response[PROMISE_INDEXES.CONTENTS], vm.langArrayAll);
                const segments = _processSegmentsResponse(response[PROMISE_INDEXES.SEGMENTS], vm.langArrayAll);

                vm.items = _makeItems(contents, segments);
            });
        }

        /**
         * Returns prepared contents list
         *
         * @param {object} response
         * @param {array} langArray
         * @return {array}
         * @private
         */
        function _processContentsResponse(response, langArray) {
            if (!response) {
                return [];
            }

            return response.plain().map(
                item => OnboardingHelper.prepareContentElement(item, langArray)
            );
        }

        /**
         * Returns prepared segments list
         *
         * @param {object} response
         * @param {array} langArray
         * @return {array}
         * @private
         */
        function _processSegmentsResponse(response, langArray) {
            if (!response) {
                return [];
            }

            return response.plain().map(
                item => OnboardingHelper.prepareSegment(item, langArray)
            );
        }

        /**
         * Return items array
         *
         * @param {array} contents - array of contents data used in current onboarding
         * @param {array} segments - array of segments data used in current onboarding
         * @return {array} - merged array of contents and segments data
         * @private
         */
        function _makeItems(contents, segments) {
            const items = vm.contentData.message.elements.map(listItem => {
                switch(listItem.modelType) {
                    case vm.MODEL_TYPES.CONTENT:
                        const element = angular.copy(contents.find(item => listItem.id === item.id));
                        if (element) {
                            element.elementRefId = listItem.elementRefId || null;
                            return element;
                        }
                        break;
                    case vm.MODEL_TYPES.SEGMENT:
                        const segment = angular.copy(segments.find(item => listItem.id === item.id));
                        if (segment) {
                            // make active segment preferences checked
                            segment.message[segment.language_id].preferences.forEach(preference => {
                                preference.active = listItem.preferences.includes(preference.code);
                            });
                            segment.elementRefId = listItem.elementRefId || null;
                            return segment;
                        }
                        break;
                    default:
                        throw new Error('Wrong model type');
                }
            });

            return items.filter(item => item !== undefined);
        }

        /**
         * Explode the list of ids from onboarding message
         *
         * @param {array} elementsList
         * @param {int} modelType
         * @return {array}
         * @private
         */
        function _getList(elementsList, modelType) {
            return elementsList
            // filter by model type & only unique values
                .filter((item, index, self) => item.modelType === modelType && self.indexOf(item) === index)
                .map(item => item.id);
        }

        /**
         * Add button handler
         */
        function addContentElement() {
            PopupService.show({
                templateUrl: '/assets/views/content/elements/types/onboarding/popup-add/popup-add.tpl.html',
                windowClass: 'onboarding-add',
                controller: 'OnboardingContentAddController',
                controllerAs: '$ctrl',
                backdrop: true,
                resolve: {
                    data: {
                        addContentCallback: addContentCallback
                    }
                }
            });
        }

        /**
         * Add content to onboarding (handler)
         *
         * @param {object} contentElement
         */
        function addContentCallback(contentElement) {
            vm.items.push(contentElement);
            selectContent(contentElement);

            vm.items.forEach((item, index) => {
                item.opened = index === vm.items.length - 1; // make the last item opened
            });
        }

        /**
         * Make the content element active to show on content preview
         *
         * @param contentElement
         */
        function selectContent(contentElement) {
            vm.selectedContent = contentElement;
        }

        /**
         * Finish handler
         *
         * @param {object} event
         * @param {function} option
         */
        function onFinish(event, option) {
            vm.contentData.message.elements = vm.items.map((item, index) => {
                const isSegment = item.content_type_id === vm.CONTENT_TYPE_SEGMENT.id;

                const element = {
                    index: index,
                    /**
                     * Do not rename and reorder id and modelType properties
                     * dependency in model method Content::getRelatedOnboardings() on backend side
                     */
                    id: item.id,
                    elementRefId: item.elementRefId,
                    modelType: isSegment ? vm.MODEL_TYPES.SEGMENT : vm.MODEL_TYPES.CONTENT,
                };

                if (isSegment) {
                    element.preferences = item.message[item.language_id].preferences
                        .filter(item => item.code && item.active === true)
                        .map(item => item.code);
                }

                return element;
            });

            let formData = new FormData();
            let contentFormData = ContentDataProcessingService.processDefaultContent(
                formData,
                vm.contentData,
                vm.contentForm
            );

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    formData,
                    contentData: contentFormData
                });
            }
        }

        /**
         * Enables or disables finish button (with validation)
         *
         * @private
         */
        function _canFinish() {
            vm.state.canFinish =
                vm.onboardingForm && vm.onboardingForm.$valid && vm.items.length;
        }

        /**
         * Finish watcher
         */
        $scope.$on('content-finish', onFinish);

        /**
         * Validation watcher 1
         */
        $scope.$watch(
            () => vm.onboardingForm && vm.onboardingForm.$valid,
            () => _canFinish()
        );

        /**
         * Validation watcher 2
         */
        $scope.$watch(
            () => vm.items.length,
            () => _canFinish()
        );
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentHeaderTranslate', {
            controller,
            templateUrl: '/assets/views/content/elements/headers/content-header-translate/content-header-translate.tpl.html',
            bindings: {
                languages: '<',
                currentLanguageId: '<',
                callback: '<',
                disabled: '<',
            },
        });

    function controller(PopupService, $translate) {
        const vm = this;

        vm.openMenu = openMenu;
        vm.translateTo = translateTo;

        /**
         * Opens dropdown menu
         *
         * @param $mdMenu
         * @param ev
         */
        function openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }

        /**
         * Language click handler
         *
         * @param {object} language
         */
        function translateTo(language) {
            const currentLang = vm.languages.find(lang =>
                lang.id === vm.currentLanguageId
            );

            Promise.all([
                $translate(currentLang.name),
                $translate(language.name)
            ]).then(([sourceLangName, targetLangName]) => {
                $translate('TRANSLATE_FROM_TO', {
                    from: sourceLangName,
                    to: targetLangName,
                }).then(translation => {
                    PopupService.showConfirmationPopup({
                        text: translation,
                        okButtonText: 'OK',
                        cancelButtonText: 'CANCEL'
                    }).then(() => {
                        vm.callback(language);
                    });
                });
            });
        }
    }
})();
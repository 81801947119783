(function() {
    'use strict';

    angular.module('beacon.app')
        .component('datetimePicker', {
            controller: DatetimePickerController,
            templateUrl: '/assets/views/common/components/datetime-range-picker/datetime-picker/datetime-picker.tpl.html',
            bindings: {
                dateInputLabel: '@',
                timeInputLabel: '@',
                pickerOkText: '@',
                pickerCancelText: '@',
                dateFormat: '@',
                value: '=',
                isError: '<',
                name: '@',
            }
    });

    function DatetimePickerController(
        mdcDateTimeDialog,
    ) {
        const $this = this;
        const TIME_FORMAT = 'HH:mm';

        $this.dateInputValue = moment($this.value);
        $this.timeInputValue = moment($this.value).format(TIME_FORMAT).toString();

        $this.showTimePickerDialog = () => {
            mdcDateTimeDialog.show({
                date: false,
                time: true,
                todayBtn: false,
                minuteSteps: 1,
                currentDate: $this.dateInputValue,
                okText: $this.pickerOkText,
                cancelText: $this.pickerCancelText,
            }).then(date => {
                $this.dateInputValue = moment(date);
                $this.timeInputValue = moment(date).format(TIME_FORMAT);

                setValue();
            });
        }

        $this.onTimeInputBlur = () => {
            convertManualTimeToDate();
        }

        $this.onTimeInputKeyPress = (event) => {
            if (event.which !== 13) { return; }

            convertManualTimeToDate();
        }

        $this.onDateChange = () => {
            $this.dateInputValue = moment($this.dateInputValue);

            setValue();
        }

        function convertManualTimeToDate() {
            if (!moment($this.timeInputValue, TIME_FORMAT).isValid()) {
                $this.timeInputValue = '00:00';
            }

            const date = moment($this.timeInputValue, TIME_FORMAT);
            const hour = date.get('hour');
            const minute = date.get('minute');

            $this.dateInputValue.set({ hour, minute });
            $this.timeInputValue = date.format(TIME_FORMAT, { trim: false });

            setValue();
        }

        function setValue() {
            $this.value = moment($this.dateInputValue);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('CarParkSubAreaPopupService', CarParkSubAreaPopupService);

    function CarParkSubAreaPopupService(PopupService) {
        return {
            editForm,
        }

        /**
         * @param {object} options
         * @param {object} options.subArea
         * @param {array<string>} options.typesInUse
         * @param {string} options.mapDefaultCenter
         * @returns {Promise<CarParkSubArea>}
         */
        function editForm(options) {
            return PopupService.show({
                template:
                    `<car-park-sub-area-form
                        sub-area="$ctrl.subArea"
                        types-in-use="$ctrl.typesInUse"
                        map-default-center="$ctrl.mapDefaultCenter"
                        on-save="$ctrl.onSave()"
                        on-cancel="$ctrl.onCancel()">
                    </car-park-sub-area-form>
                `,
                controller: function ($uibModalInstance, data) {
                    this.subArea = data.subArea;
                    this.typesInUse = data.typesInUse;
                    this.mapDefaultCenter = data.mapDefaultCenter;

                    this.onCancel = () => {
                        $uibModalInstance.dismiss();
                    }

                    this.onSave = () => {
                        $uibModalInstance.close(this.subArea);
                    }
                },
                controllerAs: '$ctrl',
                windowClass: 'car-park-sub-area-popup',
                backdrop: true,
                resolve: {
                    data: {
                        subArea: options.subArea,
                        typesInUse: options.typesInUse,
                        mapDefaultCenter: options.mapDefaultCenter,
                    },
                }
            });
        }
    }
}());
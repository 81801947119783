(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasPreview', {
            templateUrl: '/assets/views/timetable-hafas-preview/timetable-hafas-preview.tpl.html',
            controller: TimetableHafasPreviewController,
            bindings: {
                content: '<',
                mctConfig: '<',
                screenController: '<',
                subdomainId: '<',
                screenW: '<',
                screenH: '<',
                footer: '<?',
                header: '<?',
                showControls: '<?',
                maxWidth: '<?',
                maxHeight: '<?',
                previewSizeDefault: '<?',
                onResolutionChange: '<?',
                simulateNoJourneys: '<',
            },
        });

    /**
     * @param $timeout
     * @param $scope
     * @param $window
     * @param $element
     *
     * @property {TimetableHafasContent} content
     * @property {MctConfig} mctConfig
     * @property {ScreenController} screenController
     * @property {number} subdomainId
     * @property {number} screenW
     * @property {number} screenH
     * @property {number} footer
     * @property {number} header
     * @property {boolean} showControls - if true -- show display resolution selector
     * @property {number} maxWidth - max width of the wrapper element in px
     * @property {number} maxHeight - max height of the wrapper element in px
     * @property {{w: number, h: number}} previewSizeDefault - default resolution value of a preview
     * @property {function} onResolutionChange - resolution change callback function
     * @property {boolean} simulateNoJourneys
     *
     * @constructor
     */
    function TimetableHafasPreviewController(
        $timeout,
        $window,
        $scope,
        $element,
    ) {
        const vm = this;
        const SCREEN_ORIENTATION = {
            LANDSCAPE: 'LANDSCAPE',
            PORTRAIT: 'PORTRAIT'
        };
        vm.inputDebounceTime = 500;

        vm.showControls = vm.showControls || false;

        vm.timetableStyles;
        vm.isTimetablePreviewReady = false;
        vm.isUpdateAvailable = false;
        vm.resolutionList = {
            [SCREEN_ORIENTATION.LANDSCAPE]: [
                { w: 1920, h: 1080 },
                { w: 1024, h: 768 },
            ],
            [SCREEN_ORIENTATION.PORTRAIT]: [
                { w: 1080, h: 1920 },
                { w: 768,  h: 1024 },
            ]
        };
        vm.resolutionSelected;
        vm.previewSize;
        vm.componentElement = $element[0];

        vm.refreshTimetable = refreshTimetable;
        vm.onResolutionSelect = onResolutionSelect;
        vm.onManualResolutionChange = onManualResolutionChange;

        vm.$onInit = () => {
            if (vm.showControls) {
                _setDefaultResolution();
            }

            _watchForContentChange();
            _generateTimetableStyles();
        };

        function refreshTimetable() {
            _generateTimetableStyles().then(() => {
                vm.isUpdateAvailable = false;
            });
        }

        /**
         * Called when predefined resolution is selected from a list
         * @returns {void}
         */
        function onResolutionSelect() {
            // update timetable options if resolution is not custom
            if (vm.resolutionSelected !== null) {
                vm.previewSize = { ...vm.resolutionSelected };
            }

            vm.onResolutionChange(vm.previewSize);
            _generateTimetableStyles();
        }

        /**
         * Called when resolution is being changed manually via number inputs
         * @returns {void}
         */
        function onManualResolutionChange() {
            vm.onResolutionChange(vm.previewSize);
            _generateTimetableStyles();
        }

        /**
         * Calculate timetable scaling and dimensions relative to screen size
         * @returns {Promise<void>}
         */
        function _generateTimetableStyles() {
            return new Promise((resolve) => {
                vm.isTimetablePreviewReady = false;

                $timeout(() => {
                    const screenW = vm.screenW || vm.previewSize.w;
                    const screenH = vm.screenH || vm.previewSize.h;
                    const wrapperWidth = vm.maxWidth ? vm.maxWidth : vm.componentElement.clientWidth;
                    const wrapperHeight = vm.maxHeight ? vm.maxHeight : vm.componentElement.clientHeight;

                    const scale = screenW > screenH
                        ? wrapperWidth / screenW
                        : wrapperHeight / screenH;

                    vm.timetableStyles = {
                        width: screenW,
                        height: screenH,
                        scale: scale,
                        wrapperWidth: screenW * scale,
                        wrapperHeight: screenH * scale,
                    };

                    vm.isTimetablePreviewReady = true;

                    resolve();
                });
            });
        }

        /**
         * Searches previewSize resolution in predefined list, if found - set from list, if not - set as a custom input (null)
         * @returns {void}
         */
        function _setDefaultResolution() {
            const [foundRes] = Object.keys(vm.resolutionList).map(screenOrientation => {
                return vm.resolutionList[screenOrientation].find(({ w, h }) => {
                    return vm.previewSizeDefault && vm.previewSizeDefault.w === w && vm.previewSizeDefault.h === h;
                });
            }).filter(res => res);

            vm.resolutionSelected = foundRes || null;
            vm.previewSize = vm.resolutionSelected
                ? { ...vm.resolutionSelected }
                : { ...vm.previewSizeDefault };
        }

        function _watchForContentChange() {
            $scope.$watch(() => vm.content, () => {
                if (vm.showControls) {
                    return vm.isUpdateAvailable = true;
                }

                return vm.refreshTimetable();
            }, vm.showControls);
        }

        $scope.$watch(
            () => [vm.stationId, vm.stationName, vm.mctConfig, vm.header, vm.footer, vm.screenController],
            () => vm.isUpdateAvailable = true,
            true
        );

        angular.element($window).on('resize', function() {
            vm.refreshTimetable();
            $scope.$apply();
        });
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('SelectElementPopupController', SelectElementPopupController);

    function SelectElementPopupController(
        $uibModalInstance,
        ContentDataService,
        PageDataService,
        UtilitiesService,
        PAGE_TYPES,
        PAGE_TYPE_TITLES,
        CONTENT_TYPES,
        RESIZE_TYPES,
        data,
    ) {
        const vm = this;

        vm.$onInit = init;

        const { getKeyByValue } = UtilitiesService;
        vm.RESIZE_TYPES = RESIZE_TYPES;
        vm.PAGE_TYPES = PAGE_TYPES;


        vm.contents = [];
        vm.pages = [];
        vm.contentId = null;
        vm.pageId = null;

        vm.selectedTabIndex = 0;


        vm.okHandler = okHandler;
        vm.cancelHandler = cancelHandler;

        const TYPES = {
            PAGE: 'page',
            INFO_ELEMENT: 'info-element',
        };

        const templatesPath = '/assets/views/common/popups/select-element/tabs-templates/';

        vm.tabs = [
            {
                type: TYPES.INFO_ELEMENT,
                label: 'INFO_ELEMENT',
                template: templatesPath + 'info-element-tab.tpl.html',
            },
            {
                type: TYPES.PAGE,
                label: 'PAGE',
                template: templatesPath + 'page-element-tab.tpl.html',
            },
        ];

        /**
         * Ok handler
         * @returns {undefined}
         */
        function okHandler() {
            const type = vm.tabs.filter((tab, idx) => idx === vm.selectedTabIndex).pop().type;
            const item = type === TYPES.PAGE
                ? vm.pages.filter(page => page.id === vm.pageId).pop()
                : vm.contents.filter(content => content.id === vm.contentId).pop();
            const result = {
                id: item.id,
                title: item.title,
                type,
                isExternalApp: item.isExternalApp || false,
                default_lang: item.default_lang || vm.langId,
            };

            if (result.isExternalApp) {
                result.resize = parseInt(item.resize);
            }

            if (item.stations) {
                result.stations = item.stations;
            }

            $uibModalInstance.close(result);
        }

        /**
         * Cancel handler
         * @returns {undefined}
         */
        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        function _getPageTypeTitle(typeId) {
            const key = getKeyByValue(PAGE_TYPES, typeId);
            return PAGE_TYPE_TITLES[key];
        }

        /**
         * Prepare pages data for displaying in popup
         * @return {*}
         */
        function preparePagesData() {
            return data.message[data.langId].pages.filter(page => {
                return page.page_type_id !== PAGE_TYPES.SPLIT_SCREEN && page.page_type_id !== PAGE_TYPES.TICKER;})
                .map(page => {
                    const result = {
                        id: page.id,
                        type: TYPES.PAGE,
                        page_type_id: page.page_type_id,
                        title: {},
                        description: _getPageTypeTitle(page.page_type_id),
                        isExternalApp: page.page_type_id === PAGE_TYPES.EXTERNAL_APP ||
                            (page.page_type_id === PAGE_TYPES.TIMETABLE && !!page.page.externalLink),
                    };


                    if (result.isExternalApp) {
                        result.resize = data.item.resize || RESIZE_TYPES.RESPONSIVE;
                    }

                    if (page.page_type_id === PAGE_TYPES.TIMETABLE_HAFAS) {
                        result.stations = data.item.stations;
                    }

                    for (let language in data.message) {
                        const selectedTranslatedPage = data.message[language].pages.find(translatedPage => {
                            return translatedPage.id === page.id;
                        });
                        if (selectedTranslatedPage) {
                            result.title[language] = selectedTranslatedPage.header.title;
                        }
                    }
                    return result;
                });
        }

        /**
         * Init
         * Loads data required for popup
         * @returns {undefined}
         */
        function init() {
            vm.langId = data.langId;
            ContentDataService.getContentForInternalApp()
                .then(data => {
                    vm.contents = angular.copy(data)
                        .map(content => {
                            return {
                                id: content.id,
                                title: JSON.parse(content.title),
                                type: TYPES.INFO_ELEMENT,
                                description: content.content_type.name,
                                default_lang: content.language_id
                            }
                        });
                    });

            vm.pages = preparePagesData();

            if (data.item.type) {
                vm.selectedTabIndex = vm.tabs.reduce((selectedTabIndex, tab, idx) => {
                    if (tab.type === data.item.type) {
                        return idx;
                    }
                }, 0)

                data.item.type === TYPES.PAGE
                    ? vm.pageId = data.item.id
                    : vm.contentId = data.item.id;

            }
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newIotEntity', {
            templateUrl: '/assets/views/share-park/iot/iot-devices/new-iot-entity/new-iot-entity.tpl.html',
            controller: NewIotEntityController
        });

    function NewIotEntityController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        IotEntityDataService,
        GoogleMapsUtilitiesService,
        UtilitiesService,
        UserUtilitiesService,
        IOT_ENTITY_STATUSES,
        ENTITY_TYPE_CATEGORIES,
    ) {
        const vm = this;

        vm.$onInit = init;

        const {
            getIotEntityTypes,
            createIotEntity,
            updateIotEntity,
            getIotEntities
        } = IotEntityDataService;
        const { showMap } = GoogleMapsUtilitiesService;
        const { userLocationOptions } = UserUtilitiesService;
        const { getCoordinates, toPrecision } = UtilitiesService;

        vm.state = StatesFactory.IotEntityStates.refresh();
        vm.state.type = $stateParams.type;

        vm.parentIotEntities = [];
        vm.iotEntityTypes = [];
        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };

        vm.isActive = isActive;
        vm.changeEntityStatus = changeEntityStatus;
        vm.onIotEntityTypeChange = onIotEntityTypeChange;
        vm.onMap = onMap;
        vm.onIotEntityImage = onIotEntityImage;
        vm.onIotEntityImageDelete = onIotEntityImageDelete;
        vm.onIpAddressChange = onIpAddressChange;

        /**
         * Initialization method
         */
        function init() {
            vm.isEditMode = vm.state.type === 'edit';
            vm.mapDefaults = userLocationOptions();

            vm.iotEntityData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.IotEntity();

            getIotEntityTypes()
                .then(response => {
                    vm.iotEntityTypes = response.plain().sort((a, b) => a.name.localeCompare(b.name));

                    if (vm.iotEntityTypes[0]
                        && !vm.iotEntityData.entityTypeRef) {
                        vm.iotEntityData.entityTypeRef = vm.iotEntityTypes[0].id;
                        vm.iotEntityData.type = vm.iotEntityTypes[0].entityTypeCategory;
                    }
                });

            getIotEntities()
                .then(response => {
                    const parentIotEntities = response.plain()
                    .filter(entity =>
                        entity.type === ENTITY_TYPE_CATEGORIES.LOCAL_CONTROLLER
                    ).sort((a, b) => a.name.localeCompare(b.name));

                    vm.parentIotEntities = vm.iotEntityData.id
                        ? parentIotEntities.filter(entity => entity.id !== vm.iotEntityData.id)
                        : parentIotEntities;
                })

        }

        function isActive() {
            return vm.iotEntityData.status === IOT_ENTITY_STATUSES.ACTIVE;
        }

        function changeEntityStatus() {
            vm.iotEntityData.status = isActive()
                ? IOT_ENTITY_STATUSES.INACTIVE
                : IOT_ENTITY_STATUSES.ACTIVE;
        }

        function onIotEntityTypeChange() {
            const selectedType = vm.iotEntityTypes.find(type =>
                type.id === vm.iotEntityData.entityTypeRef
            );

            vm.iotEntityData.type = selectedType.entityTypeCategory;
        }

        /**
         * Map icon click hendler
         */
        function onMap() {
            const coords = getCoordinates(vm.iotEntityData.latlng);

            const mapPopup = showMap({
                mapInfo: !!coords ? [coords] : null,
                mapDefaults: vm.mapDefaults,
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(mapInfo => {
                const coords = mapInfo.coordinates;
                const precision = 6;

                const lat = toPrecision(coords.latitude, precision);
                const lng = toPrecision(coords.longitude, precision);
                vm.iotEntityData.latlng = `${lat},${lng}`;
            });
        }

        function onIotEntityImage(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            vm.iotEntityData.imageFile = imageFile;
            vm.iotEntityData.imageRef = urlCreator.createObjectURL(imageFile);
        }

        function onIotEntityImageDelete() {
            delete vm.iotEntityData.imageFile;
            vm.iotEntityData.imageRef = '';
        }

        function onIpAddressChange(fieldName) {
            const ipV6Addresses = ['ip6Address1', 'ip6Address2'];
            const ipAddressRegex = ipV6Addresses.includes(fieldName)
                ? /^([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}$/i
                : /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(:[0-9]{1,4})?$/i;

            const hostnameRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;

            const modelValue = vm.iotEntityData[fieldName];

            if (!modelValue) {
                vm.newIotEntityForm[fieldName].$setValidity('pattern', true);
                return;
            }

            const isValidIpAddress = ipAddressRegex.test(modelValue),
                isValidHostname = hostnameRegex.test(modelValue);

            if (isValidIpAddress || isValidHostname) {
                vm.newIotEntityForm[fieldName].$setValidity('pattern', true);
            } else {
                vm.newIotEntityForm[fieldName].$setValidity('pattern', false);
            }
        }

        /**
         * Ob finish handler
         */
        function onFinish() {
            const formData = new FormData();

            formData.append('iotEntity', angular.toJson(vm.iotEntityData));
            formData.append('iotEntityImage', vm.iotEntityData.imageFile);

            if (!vm.isEditMode) {
                createIotEntity(formData)
                    .then(onSuccess);
            } else {
                updateIotEntity(vm.iotEntityData, formData)
                    .then(onSuccess)
            }
        }

        function onSuccess() {
            $state.go('app.iotDevices', {
                paginationData: $stateParams.paginationData
            });
        }

        $scope.$watch(function() {
            return vm.newIotEntityForm && vm.newIotEntityForm.$valid;
        }, function(formValid) {
            vm.state.canFinish = !!formValid;
        }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('resetPasswordForm', {
            templateUrl: '/assets/views/login/reset-password-form/reset-password-form.tpl.html',
            controller: resetPasswordFormController
        });

    function resetPasswordFormController(
        $state,
        $scope,
        $timeout,
        UtilitiesService,
        LoginService,
    ) {
        const vm = this;

        vm.onSubmit = onSubmit;
        vm.isFormValid = isFormValid;
        vm.$onInit = init;

        function init() {
            $timeout(() => {
                vm.isError = UtilitiesService.inputValidator(vm.resetPasswordForm);
            });

            LoginService.validatePasswordResetToken($state.params.token)
                .then(response => {
                    vm.isTokenValid = response.valid;
                });
        }

        function onSubmit() {
            vm.error = null;
            LoginService.setPassword({
                token: $state.params.token,
                password: vm.password,
            }).then(() => {
                $state.go('login', {message: 'USE_YOUR_NEW_PASSWORD_TO_LOGIN'});
            }).catch(error => {
                vm.error = error.data.message;
            })
        }

        function isFormValid() {
            return vm.resetPasswordForm.$valid && vm.password === vm.passwordRepeat;
        }
    }
})();
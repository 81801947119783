(function () {
    'use strict';

    angular.module('beacon.app')
        .component('headerFooterImageElement', {
            templateUrl: '/assets/views/content/elements/types/header-footer/header-footer-image-element/header-footer-image-element.tpl.html',
            controller: HeaderFooterImageElementController,
            bindings: {
                element: '<',
            }
        });

    /**
     * @param ImageApiService
     *
     * @property {TimetableHafasHeaderElement} element
     *
     * @constructor
     */
    function HeaderFooterImageElementController(
        ImageApiService,
    ) {
        const $ctrl = this;

        $ctrl.imageLoaderConfig = {
            minWidth: 0,
            minHeight: 0,
            center: false,
            fullInfo: true,
        };

        /**
         * @param {object} data
         * @param {Blob} data.blob
         * @param {object} data.cropData
         */
        $ctrl.onImageUpload = (data) => {
            ImageApiService.uploadImage(data.blob)
                .then(url => {
                    $ctrl.element.url = url.data;
                });
        }

        $ctrl.onImageDelete = () => {
            $ctrl.element.url = null;
        }
    }
})();

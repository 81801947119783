(function() {
    'use strict';

    angular.module('beacon.app')
        .component('scenariosList', {
            templateUrl: '/assets/views/campaign/scenario/scenario.tpl.html',
            controller: ScenariosListController
        });

    function ScenariosListController($state, $stateParams, $translate, PopupService, Restangular, ScenarioDataService, mdcDateTimeDialog,
                                     PermissionsService, ACTIVATION_STATUS, DATETIME_FORMAT) {
        const vm = this;

        const { generateScenarioPermission, actions } = PermissionsService;

        vm.$onInit = init;

        // public properties
        vm.scenarios = [];
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'scenario-name',
                    width: '40',
                    title: 'name'
                },
                {
                    name: 'STATUS',
                    class: 'scenario-status',
                    width: '10',
                    translate: true,
                    title: (item) => {
                        return getScenarioStatus(item).label;
                    }
                },
                {
                    name: 'ACTIVE_TILL',
                    class: 'scenario-status',
                    width: '15',
                    title: (item) => {
                        return getScenarioStatus(item).value === ACTIVATION_STATUS.ACTIVE.value ?
                            moment(item.active_till).format(DATETIME_FORMAT) : '-';
                    }
                },
            ],
            buttons: {
                width: '20',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteScenario,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'copyBtn',
                        callback: copyScenario,
                        permissionAction: actions.create
                    },
                    {
                        class: 'editBtn',
                        callback: editScenario,
                        permissionAction: actions.modify
                    },
                    {
                        class: item => {
                            switch (getScenarioStatus(item).value) {
                                case ACTIVATION_STATUS.ACTIVE.value:
                                    return 'block-btn';
                                case ACTIVATION_STATUS.INACTIVE.value:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeScenarioStatus,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateScenariosList,
            generatePermissionName
        };

        // public methods
        vm.addScenario = addScenario;
        vm.createScenarioPermission = generateScenarioPermission(actions.create);

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        function updateScenariosList(page, itemsPerPage) {
            let data = { page, itemsPerPage };

            ScenarioDataService.getScenarios(data)
                .then(response => {
                    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
                    vm.scenarios = response.map(scenario => {
                        if (scenario.active_till) {
                            const activeTillUTC = moment.utc(scenario.active_till).toDate();
                            scenario.active_till = moment(activeTillUTC).local().format(DATETIME_FORMAT);
                        }
                        return scenario;
                    });
                    vm.totalItems = response.count;
                })
        }

        function deleteScenario($event, scenario) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_SCENARIO',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                ScenarioDataService.deleteScenario(scenario.id)
                    .then(() => {
                        vm.totalItems -= 1;
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Change scenario status
         * @param {object} $event
         * @param {object} item
         */
        function changeScenarioStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();
            const scenarioData = Restangular.copy(item);
            if (getScenarioStatus(item).value === ACTIVATION_STATUS.ACTIVE.value) {
                scenarioData.active_till = null;
                setScenario(scenarioData, () => item.active_till = scenarioData.active_till);
            } else {
                const currentDate = new Date();
                Promise.all([
                    $translate('OK'),
                    $translate('CANCEL'),
                    $translate('TODAY'),
                ]).then(([okText, cancelText, todayText]) => {
                    mdcDateTimeDialog.show({
                        date: true,
                        time: true,
                        minuteSteps: 1,
                        minDate: currentDate,
                        okText,
                        cancelText,
                        todayText,
                        templateUrl: '/assets/views/common/partials/scenario-active-time-picker/scenario-active-time-picker.tpl.html'
                    }).then(date => {
                        scenarioData.active_till = moment(date).utc().format(DATETIME_FORMAT);
                        setScenario(scenarioData, () => item.active_till = moment(date).format(DATETIME_FORMAT))
                    });
                });
            }
        }

        function setScenario(scenarioData, itemSavedCallback) {
            scenarioData.assignments.forEach(assignment => {
                assignment.locationIds = assignment.locations.map(location => location.id);
                delete assignment.locations;
            });

            scenarioData.put()
                .then(itemSavedCallback)
                .catch(console.error.bind(console));
        }

        function getScenarioStatus(scenario) {
            return moment().isBefore(scenario.active_till) ? ACTIVATION_STATUS.ACTIVE : ACTIVATION_STATUS.INACTIVE;
        }

        function copyScenario($event, scenario) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = _.omit(scenario, ['id', 'externalId']);
            data.assignments = data.assignments.map(({name, content_id, locations}) => {
                return {
                    name,
                    content_id,
                    locations
                };
            });

            $state.go('app.newScenario', { data });
        }

        function editScenario($event, scenario) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editScenario', { data: scenario });
        }

        function addScenario() {
            $state.go('app.newScenario');
        }

        /**
         * Generates permission name for each button on scenarios accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateScenarioPermission(button.permissionAction);
        }
    }
})();
(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasHeaderControls', {
            templateUrl: '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-header-controls/timetable-hafas-header-controls.tpl.html',
            bindings: {
                options: '<',
            }
        });
})();

(function () {
    'use strict';

    angular.module('lineRoute')
        .directive('ngRepeatFinished', ngRepeatFinished);

    function ngRepeatFinished($timeout) {
        return function (scope, element, attr) {
            if (scope.$last) {
                $timeout(function () {
                    scope.$emit(attr.ngRepeatFinished, scope);
                });
            }
        };
    }
})();
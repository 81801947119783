(function() {
    'use strict';

    angular.module('beacon.app')
        .component('defaultContentHeader', {
            templateUrl: '/assets/views/content/elements/headers/default/header.tpl.html',
            controller: DefaultContentHeader,
            bindings: {
                langArray: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
                copyFromWebSourceCallback: '='
            }
        });

    function DefaultContentHeader(
        $rootScope,
        $timeout,
        UtilitiesService,
        PermissionsService,
        CONTENT_TYPES,
        GoogleTranslateService,
        ContentHelper,
    ) {
        const vm = this;

        const { oneOfContentTypes } = UtilitiesService;
        const { isLanguageFilled, translateContent } = ContentHelper;

        vm.CONTENT_TYPES = CONTENT_TYPES;
        vm.isContentGroupSelectBoxVisible = PermissionsService.isContentGroupMenuItemVisible;

        vm.titleMaxLength = 250;

        vm.$onInit = init;

        /**
         * Exports
         */
        vm.getDefaultTitle = getDefaultTitle;
        vm.onLanguageChange = onLanguageChange;
        vm.isCopyFromWebSourceVisible = isCopyFromWebSourceVisible;
        vm.isTranslateAvailable = isTranslateAvailable;
        vm.translateHandler = translateHandler;
        vm.oneOfContentTypes = oneOfContentTypes;
        vm.isLanguageFilled = isLanguageFilled;

        /**
         * Controller initialization
         */
        function init() {
            vm.showError = UtilitiesService.inputValidator(vm.contentForm);
        }

        /**
         * Returns default translation
         *
         * @param {object} item
         * @returns {string} Title
         */
        function getDefaultTitle(item) {
            var keys = Object.keys(item),
                title = '',
                language;

            if (keys.length) {
                language = vm.langArray.filter(function(language) {
                    return language.id.toString() === keys[0];
                })[0];

                title = language ? (item[keys[0]] + ' [' + language.code + ']') : '';
            }

            return title;
        }

        /**
         * Language change handler
         * @param {string} oldValue
         * @returns {undefined}
         */
        function onLanguageChange(oldValue) {
            vm.contentData.title = vm.contentData.title || {};
            vm.contentData.message = vm.contentData.message || {};

            if (!isLanguageFilled(vm.contentData, oldValue)) {
                delete vm.contentData.title[oldValue];
                delete vm.contentData.message[oldValue];
            }

            $timeout(function() {
                $rootScope.$broadcast('language-change', {
                    lang: vm.contentData.language_id
                });
            }, 0);
        }

        /**
         * Is Copy from web source button visible
         * @returns {boolean}
         */
        function isCopyFromWebSourceVisible() {
            return oneOfContentTypes([
                'INFOTAINMENT',
                'QUIZ',
                'FEEDBACK'
            ], vm.contentData.content_type_id);
        }

        /**
         * Checks if translate button is available
         *
         * @returns {bool}
         */
        function isTranslateAvailable() {
            return [
                CONTENT_TYPES.INFOTAINMENT,
                CONTENT_TYPES.QUIZ,
                CONTENT_TYPES.FEEDBACK,
                CONTENT_TYPES.DYNAMIC_CONTENT,
            ].some(type =>
                type === vm.contentData.content_type_id
            );
        }

        /**
         * Translate handler
         *
         * @param tgtLang - target language
         */
        function translateHandler(tgtLang) {
            translateContent(vm.contentData, vm.langArray, tgtLang);

            onLanguageChange(tgtLang.id);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('tabbedPanel', tabbedPanel);

    function tabbedPanel() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/tabbed-panel/tabbed-panel.tpl.html',
            replace: true,
            controller: TabbedPanelController,
            controllerAs: 'tabbedPanel',
            bindToController: true,
            scope: {
                data: '=tabbedPanel'
            }
        };
    }

    function TabbedPanelController($scope) {
        const vm = this;

        // public properties
        vm.selectedTab = null;

        // public methods
        vm.switchTab = switchTab;

        init();

        /**
         * Switches active tab
         */
        function switchTab(tab) {
            vm.selectedTab = tab;
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {
            let index = vm.data.selectedTabIndex || 0;
            vm.selectedTab = vm.data.tabs[index];
        }
    }
}());

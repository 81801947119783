(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PlaylistHelper', PlaylistHelperService);

    function PlaylistHelperService(
        ContentHelper,
        PLAYLIST_ITEM_TYPE,
    ) {

        return {
            getAppItemIcon,
            getTooltip,
        };

        /**
         * App item icon
         * @param {string} type
         * @returns {string|false} icon
         */
        function getAppItemIcon(type) {
            switch (type) {
                case PLAYLIST_ITEM_TYPE.INFOTAINMENT:
                    return 'web';
                case PLAYLIST_ITEM_TYPE.TIMETABLE:
                case PLAYLIST_ITEM_TYPE.EXTERNAL_TIMETABLE:
                case PLAYLIST_ITEM_TYPE.TIMETABLE_HAFAS:
                    return 'directions_bus';
                case PLAYLIST_ITEM_TYPE.WEATHER:
                    return 'wb_sunny';
                case PLAYLIST_ITEM_TYPE.EXTERNAL:
                    return 'http';
                case PLAYLIST_ITEM_TYPE.LINE_ROUTE:
                    return 'directions_bus';
                case PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT:
                case PLAYLIST_ITEM_TYPE.DYNAMIC_CONTENT_PLACEHOLDER:
                    return 'message';
                case PLAYLIST_ITEM_TYPE.HIM:
                    return 'notifications';
                case PLAYLIST_ITEM_TYPE.SPLIT_SCREEN:
                    return 'view_quilt';
                default :
                    return false;
            }
        }

        /**
         * Gets tooltip of the playlist item
         * @param item
         * @return {*}
         */
        function getTooltip(item) {
            if (angular.isString(item.title)) {
                return item.title;
            }

            return item.content && item.content.title
                ? ContentHelper.getDefaultTitle(item.content)
                : null;
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('trmPoiContentPreview', {
            templateUrl: '/assets/views/trm-poi/elements/preview/trm-poi-preview.tpl.html',
            controller: TrmPoiContentPreview,
            bindings: {
                contentData: '=',
                openingHours: '<',
            }
        });

    function TrmPoiContentPreview(UtilitiesService) {
        const vm = this;
        const { prearrangeText } = UtilitiesService;

        vm.getDescription = getDescription;
        vm.prearrangeText = prearrangeText;

        /**
         * Returns message text
         * @returns {string}
         */
        function getDescription() {
            return vm.contentData.description || '';
        }
    }
})();

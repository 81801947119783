(function() {
    'use strict';

    angular.module('beacon.app')
        .component('feedbackTicket', {
            templateUrl: '/assets/views/feedback/feedback-ticket/feedback-ticket.tpl.html',
            controller: FeedbackTicketController,
        });

    function FeedbackTicketController(
        $q,
        $http,
        $state,
        $scope,
        $stateParams,
        FeedbackHelper,
        FeedbackTicketService,
        FeedbackCommentService,
        $translate,
    ) {
        const vm = this;
        let initialFeedbackTicket = null;

        vm.$onInit = onInit;
        vm.onStatusChange = onStatusChange;
        vm.onPriorityChange = onPriorityChange;
        vm.onAddComment = onAddComment;
        vm.formatDate = formatDate;

        vm.statuses = FeedbackHelper.getStatusesList();
        vm.priorities = FeedbackHelper.getPrioritiesList();

        vm.feedbackTicket = null;
        vm.comment = '';

        function onInit() {
            FeedbackTicketService.get($stateParams.id)
                .then(response => {
                    vm.feedbackTicket = response.data;
                    initialFeedbackTicket = angular.copy(vm.feedbackTicket);
                });
        }

        function onPriorityChange() {
            saveModel(vm.feedbackTicket);
        }

        function onStatusChange() {
            saveModel(vm.feedbackTicket);

            const initialStatusName = FeedbackHelper.statusName(initialFeedbackTicket);
            const statusName = FeedbackHelper.statusName(vm.feedbackTicket);

            $q.all([
                $translate(initialStatusName),
                $translate(statusName),
            ])
            .then(([initialStatusName, statusName]) => {
                FeedbackCommentService.create({
                    feedback_ticket_id: vm.feedbackTicket.id,
                    text: initialStatusName + ' => ' + statusName,
                }).then(onInit);
            });
        }

        function saveModel(ticket) {
            FeedbackTicketService.save(ticket);
        }

        function formatDate(date) {
            return moment.utc(date).local().format('MMMM D, YYYY (HH:mm)');
        }

        function onAddComment() {
            if (vm.comment) {
                FeedbackCommentService.create({
                    feedback_ticket_id: vm.feedbackTicket.id,
                    text: vm.comment,
                }).then(_ => {
                    vm.comment = '';
                    onInit();
                });
            }
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FeedbackTicketService', FeedbackTicketService);

    function FeedbackTicketService(
        $http,
    ) {
        return {
            getAll,
            get,
            create,
            save,
        }

        /**
         * @param {string} statuses
         * @param {string} priorities
         * @param {string} searchQuery
         */
        function getAll(statuses, priorities, searchQuery = '') {
            return $http.get(`/api/feedback-tickets?status=${statuses}&priority=${priorities}&searchQuery=${searchQuery}`);
        }

        function get(ticketId) {
            return $http.get('/api/feedback-tickets/' + ticketId);
        }

        function create(data) {
            return $http.post('/api/feedback-tickets', data);
        }

        function save(data) {
            return $http.post('/api/feedback-tickets/' + data.id, data);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pageEditorExternalApp', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/external-app/external-app.tpl.html',
            controller: PageEditorExternalAppController,
            bindings: {
                contentData: '=',
                pageData: '<',
                popup: '=',
                item: '='
            }
        });

    function PageEditorExternalAppController(
        $scope,
        ContentDataService,
        StorageFactory,
        UtilitiesService,
        CONTENT_TYPES,
        ContentHelper
    ) {
        const vm = this;

        // public properties
        vm.selected = null;

        // public methods

        vm.$onInit = init;

        // private methods

        /**
         * Initialization method
         */
        function init() {
            vm.STORAGE = StorageFactory.Storage('Content-DigitalDisplay');
            vm.externalApps = vm.STORAGE.get('externalApps');

            if (!vm.externalApps) {
                ContentDataService.contents({contentTypeId: CONTENT_TYPES.EXTERNAL_APP})
                    .then(response => {
                        vm.externalApps = vm.STORAGE.set(
                            'externalApps',
                            response.plain().map(item => ContentHelper.decodeContent(item))
                        );
                        vm.selected = findSelectedItem();
                    })
                    .catch(console.error.bind(console));
            } else {
                vm.selected = findSelectedItem();
            }
        }

        function findSelectedItem() {
            let selectedItem = vm.externalApps.find(app => {
                return app.id === vm.pageData.selectedContentId
                    || vm.item && app.id === vm.item.id;
            });
            return selectedItem || vm.externalApps[0];
        }

        /**
         * @param {String} path
         * @return {String}
         */
        function getFileNameFromPath(path) {
            if (typeof path !== 'string') {
                return '';
            }

            let splitedPath = path.split('/');
            return splitedPath[splitedPath.length - 1];
        }

        /**
         * On change external app content handler
         */
        function onSelectedApp() {
            if (vm.selected && vm.pageData && !vm.popup) {
                vm.pageData.header.image.cropped.img = vm.selected.image_src;
                vm.pageData.header.image.name = getFileNameFromPath(vm.selected.image_src);
                vm.pageData.header.image.fromContent = true;
                vm.pageData.selectedContentId = vm.selected.id;
            } else if (vm.selected && vm.pageData && vm.popup) {
                vm.pageData.page.item = vm.selected;
            }
        }



        /**
         * onFinish handler
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            if (!vm.selected) {
                return;
            }

            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }



        /**
         * watch on change external app content
         */
        $scope.$watch(() => vm.selected, onSelectedApp);

        /**
         * Save page listener
         */
        $scope.$on('digital-display-savepage', onFinish);
    }
}());

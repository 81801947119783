(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('FeedbackTicketToolbarController', FeedbackTicketToolbarController);

    function FeedbackTicketToolbarController(
        $state,
    ) {
        const vm = this;

        // public methods
        vm.cancel = cancel;

        function cancel() {
            $state.go('app.feedbackTickets');
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tickerPage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/ticker/ticker.tpl.html',
            controller: TickerPageCtrl,
            bindings: {
                contentForm: '=',
                contentData: '=',
                pageData: '<'
            }
        });

    function TickerPageCtrl($scope) {
        const vm = this;

        // private methods

        function onFinish(event, option) {
            if (!vm.contentForm.$valid && !vm.pageData.page.source) {
                return;
            }

            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        $scope.$on('digital-display-savepage', onFinish);
    }
}());

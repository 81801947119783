(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenStationPickerV2', {
            controller: ScreenStationPickerV2Controller,
            templateUrl: '/assets/views/location/new-screen/tabs/screen-details/screen-station-picker-v2/screen-station-picker-v2.tpl.html',
            bindings: {
                inputTitle: '@',
                station: '=',
                canDelete: '<',
                onDelete: '&',
            }
        });

    /**
     * @property {{name: string, id: string}} station
     * @property {string} inputTitle
     * @property {boolean} canDelete
     * @property {function} onDelete
     *
     * @param {FindStationPopupService} FindStationPopupService
     * @param MgateService
     * @param StorageFactory
     * @param ScreenHelper
     * @param {MultiStationsHelper} MultiStationsHelper
     * @constructor
     */
    function ScreenStationPickerV2Controller(
        FindStationPopupService,
        MgateService,
        StorageFactory,
        ScreenHelper,
        MultiStationsHelper,
    ) {
        const $ctrl = this;

        const STORAGE = StorageFactory.Storage('Main');
        const subdomainId = STORAGE.currentUserInfo.subdomain.id;

        $ctrl.onSearch = onSearch;
        $ctrl.onRemove = onRemove;

        /**
         * On find station click handler
         */
        function onSearch() {
            FindStationPopupService.show()
                .then(station => MgateService.productParams(subdomainId)
                .then(
                    /**
                     * @param {ProductParams} params
                     */
                    (params = {}) => {
                        $ctrl.station = MultiStationsHelper.makeSubStation(station, params.stationNameReplacementMap);
                    })
                );
        }

        function onRemove() {
            delete $ctrl.station;

            if ($ctrl.onDelete) {
                $ctrl.onDelete();
            }
        }
    }
})();

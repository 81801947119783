(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('PopupCreateCampaignController', PopupCreateCampaignController);

    function PopupCreateCampaignController($state, $uibModalInstance, data) {
        const vm = this;
        const contentId = data.contentId;

        vm.createMobileCampaign = createMobileCampaign;
        vm.createPushCampaign = createPushCampaign;

        /**
         * Create mobile campaign handler
         */
        function createMobileCampaign() {
            $uibModalInstance.close();
            $state.go('app.addCampaign', {
                selectedContent: [contentId]
            });
        }

        /**
         * Create push campaign handler
         */
        function createPushCampaign() {
            $uibModalInstance.close();
            $state.go('app.addPushCampaign', {
                selectedContentId: contentId
            });
        }
    }

}());

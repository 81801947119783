(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('PdfPopupController', PdfPopupController);

    function PdfPopupController(
        $sce,
        $uibModalInstance,
        data
        ) {
        const vm = this;

        vm.ok = okHandler;

        vm.pdfUrl = data.url;

        /**
         * "OK" click handler
         */
        function okHandler() {
            $uibModalInstance.close();
        }
    }
}());

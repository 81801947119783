(function() {
    'use strict';

    angular.module('trmTimetableHafas').service('TimeFormatService',
        function (
            $moment,
        ) {
            return {
                getCountdownMinutes,
            };

            /**
             * Returns formatted countdown value based on provided threshold value
             * @param {moment.Moment} time time left
             * @param {number} threshold threshold in minutes
             *
             * @returns {string} number of minutes left or time in hh:mm format
             */
            function getCountdownMinutes(time, threshold = -1) {
                const minutes = time.diff($moment(), 'minutes');

                if (minutes <= threshold || threshold === -1) {
                    return String(minutes);
                }

                return String(time.format('HH:mm'));
            }
        });
})();

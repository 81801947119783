(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ChargingStationHelper', ChargingStationHelper);

    function ChargingStationHelper() {

        return {
            defaultTitle,
            status,
        };

        function defaultTitle(chargingStation) {
            const defaultLangId = Object.keys(chargingStation.name)[0];
            return chargingStation.name[defaultLangId];
        }

        function status(chargingStation) {
            switch (chargingStation.status) {
                case 0:
                    return 'INACTIVE';
                case 1:
                    return 'ACTIVE';
                default:
                    return 'N/a';
            }
        }
    }
})();
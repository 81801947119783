(function () {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkProfileRules', {
            templateUrl: '/assets/views/share-park/customers/profile-rules/profile-rules.tpl.html',
            controller: ProfileRulesController
        });

    function ProfileRulesController(
        $state,
        UtilitiesService,
        ProfileRulesService,
        ProfileRulesDialogService
    ) {
        const vm = this;

        const ACTIVE_STATUS = 1;
        const INACTIVE_STATUS = 2;
        const DELETED_STATUS = 3;

        vm.profileRulesList = [];
        vm.allProfileRules = [];
        vm.allVersionNumber = [];
        vm.visibleProfileRulesList = [];
        vm.updateCounter = 0;
        vm.addProfileVersion = addProfileVersion;
        vm.$onInit = init;

        vm.listProfileRules = {
            columns: [
                {
                    name: 'NAME',
                    class: 'rules-title',
                    width: '30',
                    translate: true,
                    title: listItem => 'Version' + ' ' + listItem.version,
                },
                {
                    name: 'STATUS',
                    class: '',
                    width: '10',
                    translate: true,
                    title: listItem => {
                        switch (listItem.status) {
                            case ACTIVE_STATUS:
                                return 'ACTIVE';
                            case INACTIVE_STATUS:
                                return 'INACTIVE';
                            case DELETED_STATUS:
                                return 'DELETED';
                            default:
                                return 'INACTIVE';
                        }
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'editBtn',
                        callback: editProfileRules,
                        permissionAction: 'can_modify_profile_rules',
                        isVisible,
                    },
                    {
                        class: item => {
                            switch (item.status) {
                                case ACTIVE_STATUS:
                                    return 'block-btn';
                                case INACTIVE_STATUS:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeProfileRulesStatus,
                        isVisible,
                    }
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        }


        function init() {
            ProfileRulesService.getProfileRulesList().then(response => {
                vm.allProfileRules = response.data;
                vm.profileRulesList = separatingByVersion(vm.allProfileRules);
                vm.updateCounter++;
            });
        }

        function separatingByVersion(allProfileRules) {
            const profileRulesList = [];
            const listVersions = [];
            allProfileRules.forEach(rule => {
                if (!listVersions.includes(rule.version)) {
                    profileRulesList.push({ version: rule.version, status: rule.status, rules: [] });
                    listVersions.push(rule.version);
                }

            })
            vm.allVersionNumber = [];
            profileRulesList.forEach(rule => {
                rule.rules = allProfileRules.filter(item => item.version === rule.version);
                vm.allVersionNumber.push(rule.version);
            })

            return profileRulesList.reverse();
        }

        function isVisible(btn, item) {
            return item.status !== DELETED_STATUS;
        }

        /**
         * @param $event 
         * @param item 
         */
        function changeProfileRulesStatus($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            let ruleIds = [];
            item.rules.forEach(rule => ruleIds.push(rule.id));

            vm.allProfileRules.map(rule => rule.status = ruleIds.includes(rule.id)
                ? ACTIVE_STATUS
                : INACTIVE_STATUS);

            vm.profileRulesList = separatingByVersion(vm.allProfileRules);

            ProfileRulesService.saveProfileRulesList(vm.allProfileRules).then(response => {
                vm.updateCounter++;
            })

        }

        /**
         * Edit profile
         * @param $event
         * @param item
         */
        function editProfileRules($event, item) {
            $event.preventDefault();
            $event.stopPropagation();
            $state.go('app.editShareParkProfileRules', { data: item });
        }

        /**
         * @param event 
         */
        function addProfileVersion(event) {
            if (vm.profileRulesList.length) {
                ProfileRulesDialogService.showServiceSelection(event, vm.profileRulesList).then(function (version) {
                    if (version) {
                        var selectedVersionData = vm.profileRulesList.filter(item => item.version === version)[0];
                        selectedVersionData.version = Math.max(...vm.allVersionNumber) + 1;
                        selectedVersionData.rules.map(rule => {
                            rule.version = selectedVersionData.version;
                            rule.id = null;
                            rule.status = INACTIVE_STATUS;
                        })
                        $state.go('app.editShareParkProfileRules', { data: selectedVersionData });
                    }
                });
            } else {
                $state.go('app.addShareParkProfileRules');
            }
        }

        /**
         * Update data on pagination
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.visibleProfileRulesList = UtilitiesService.getArrayPage(vm.profileRulesList, page, count);
        }

        function generatePermissionName(button) {
            return button.permissionAction;
        }

    }
})();
(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('timetableHafasHim', {
            templateUrl: '/screen/views/components/timetable-hafas/timetable-hafas-him/timetable-hafas-him.tpl.html',
            controller: TimetableHafasHimController,
            bindings: {
                hims: '<',
                options: '<',
                colorsScheme: '<',
                onChanged: '&',
            }
        });

    /**
     * @param {$sce} $sce
     * @param {$rootScope.Scope} $scope
     * @param $timeout
     * @param $interval
     * @param {LogService} LogService
     *
     * @property {MgateHim[]} hims
     * @property {TimetableHafasOptions} options
     * @property {function} onChanged
     *
     * @constructor
     */
    function TimetableHafasHimController(
        $sce,
        $scope,
        $interval,
        $timeout,
        LogService,
    ) {
        const $ctrl = this;
        const log = (...args) => {
            LogService.colored('#f0d722', '#FFF', 'Timetable (HAFAS) HIM |', ...args);
        };

        let currentHimId = null;
        let _himPlaylistInterval;

        $ctrl.$onInit = () => {
            _initHim();
            _setFontSize();
            _initWatchers();
        }

        /**
         * Remove timers
         */
        $ctrl.$onDestroy = () => {
            if (_himPlaylistInterval) {
                $interval.cancel(_himPlaylistInterval);
            }
        };

        /**
         * @param {string} html
         */
        $ctrl.trustHtml = html => {
            return $sce.trustAsHtml(html);
        }

        /**
         * @private
         */
        function _initHim() {
            $timeout(
                () => __showNextHim()
            );

            _himPlaylistInterval = $interval(
                () => __showNextHim(),
                $ctrl.options.him.interval * 1000
            );
        }

        function _setFontSize() {
            if (!$ctrl.options.him.fontSize) {
                return;
            }

            $ctrl.fontSize = $ctrl.options.him.fontSize * 100 + '%';
        }

        /**
         * @private
         */
        function __showNextHim() {
            if (!$ctrl.hims.length) {
                log('No HIM available');
                return;
            }

            $ctrl.activeHimIndex = $ctrl.activeHimIndex !== null && $ctrl.activeHimIndex + 1 < $ctrl.hims.length
                ? $ctrl.activeHimIndex + 1
                : 0;

            const nextHimId = $ctrl.hims[$ctrl.activeHimIndex].hid;
            log('Rotation. Prev', currentHimId, 'next', nextHimId, $ctrl.hims);

            const himChanged = currentHimId !== nextHimId;

            if (himChanged) {
                log('Changed. Update view');
                $ctrl.onChanged();
            } else {
                log('Content is the same. No view update');
            }

            currentHimId = nextHimId;
        }

        function _initWatchers() {
            $scope.$watch(
                () => $ctrl.options.him.fontSize,
                () => _setFontSize(),
            )
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('ModelsFactory', modelsFactory);

    // TODO: check 'type' field
    function modelsFactory(
        PAGE_HEADER_ICONS,
        VIEW_TYPES,
        DIGITAL_DISPLAY_HEADERS,
        QUIZ_QUESTION_TYPES,
        CAR_PARK_TYPE,
        CAR_PARK_ENTRANCE_DIRECTION,
        WEEK_DAYS,
        TIMETABLE_ORDER,
        NO_JOURNEYS_VIEW_MODE,
        StorageFactory
    ) {
        /**
         * Base model
         * @type {Class}
         */
        class BaseModel {}

        /**
         * @name BaseContentModel
         * @property {int} [id]
         */
        class BaseContentModel extends BaseModel {
            constructor(object = {}) {
                super();

                // DB content fields
                Object.assign(this, {
                    title: {},
                    message: {},
                    data: {},
                    web_link: null,
                    map_info: null,
                    image_src: null,
                    audio_src: null,
                    subdomain_id: null,
                    language_id: null,
                    show_on_map: 0,
                    youtube_link: null,
                    external_ids: null,
                    show_in_list: 1,
                    content_type_id: null,
                    content_group_id: 0,
                    language_sensitive_audio: 0,
                    language_sensitive_link: 0,
                }, object);
            }
        }

        class Quiz extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    message: {},
                    quiz: {
                        questions: [],

                        type: null,
                        secondsForAnswer: null,
                        reward: {
                            description: "0",
                            id: "0",
                            rewardCode: "0",
                            rewardLabel: "None",
                            rewardUnitLabel: "",
                            rewardValueFormat: "",
                        }
                    }

                });
            };
        }

        class QuizQuestionBody extends BaseModel {
            constructor(type, langIds) {
                super(...arguments);

                Object.assign(this, {
                    id: {},
                    type: type,
                    title: langIds.reduce((obj, key) => {obj[key] = ''; return obj;}, {}),
                    votingOptions: [],
                    ratingUnits: 5,
                    isSingleAnswer: !(QUIZ_QUESTION_TYPES.RATING === type),
                    isLongAnswer: false,
                    secondsForAnswer: null,

                    userAttributeType: null,
                    userAttributeValue: null
                });
            };
        }

        class QuizQuestionHeader extends BaseModel {
            constructor(editQuestion, copyQuestion, deleteQuestion) {
                super(...arguments);

                Object.assign(this, {
                    columns: [
                        {
                            name: 'Title',
                            title: 'title',
                            width: '70',
                            class: 'contentTitle'
                        }
                    ],
                    buttons: {
                        width: '15',
                        minWidth: '150px',
                        items: [
                            {
                                class: 'deleteBtn',
                                callback: deleteQuestion
                            },
                            {
                                class: 'copyBtn',
                                callback: copyQuestion
                            },
                            {
                                class: 'editBtn',
                                callback: editQuestion
                            }
                        ]
                    }
                });
            };
        }

        /**
         * @name DigitalDisplay
         *
         * @property {DigitalDisplayPage[]} pages
         */
        class DigitalDisplay extends BaseModel {
            constructor() {
                super(...arguments);

                const corporateColours = StorageFactory.Storage('Main').get('corporateColours') || [];

                Object.assign(this, {
                    pages: [],
                    header: {
                        type_id: DIGITAL_DISPLAY_HEADERS[0].id,
                        screenTimeout: 5,
                        screenTimeoutAppId: 0
                    },
                    globalPagesSettings: {
                        primaryColor: corporateColours[0],
                        darkColor: corporateColours[1],
                        accentColor: corporateColours[2],
                        textColor: corporateColours[3],
                        backgroundColor: '#e1e6ef',
                        backgroundImageSource: null,
                    },
                    speedDial: {
                        access: false,
                        language: false,
                        selectedPages: []
                    }
                });
            }
        }

        /**
         * @name DigitalDisplayPage
         *
         * @property {number} id - index of the page (ordering)
         * @property {{
         *     fullScreen: boolean,
         *     icon: {
         *         content: string,
         *         position: string,
         *         size: number,
         *     },
         *     image: {
         *         img: string,
         *         cropped: { img: unknown }
         *     },
         *     title: string,
         * }} header
         *
         * @property {{
         *     view: {
         *         landscape: boolean,
         *         portrait: boolean
         *     }
         * }} page
         *
         * @property {number} page_type_id
         * @property {number} [dbId]
         * @property {number} [staticId]
         */
        class DigitalDisplayPage extends BaseModel {
            constructor(object = {}, title = 'Page') {
                super();

                // Deep customizer
                let customizer = function customizer(objValue, srcValue) {
                    if (_.isObject(srcValue) && _.isObject(objValue)) {
                        return _.assignWith(objValue, srcValue, customizer);
                    }

                    return _.isUndefined(srcValue) ? objValue : srcValue;
                };

                _.assignWith(this, {
                    // page header settings
                    header: {
                        title,
                        icon: {
                            content: PAGE_HEADER_ICONS.HOME,
                            position: 'right',
                            size: 18
                        },
                        fullScreen: true,
                        image: {
                            img: null,
                            cropped: {
                                img: null
                            }
                        }
                    },
                    // page settings
                    page: {
                        view: {
                            landscape: true,
                            portrait: true
                        }
                    }
                }, object, customizer);
            };
        }

        class Ticker extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    type: 1,
                    size: 'small',
                    position: 'top',
                    overlay: false,
                    fixed: false,
                    speed: 15,
                    source: null,
                    background: '#ffffff',
                    fontColor: '#000000',
                    tickerType: 'tape',
                    transition: 'none',
                    textAlign: 'center',
                });
            };
        }

        class Banner extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    infoId: null,
                    infoExternalId: null,
                    referenceType: null,
                    message: {},
                    title: {},
                    textColor: '#000000',
                    backgroundColor: '#ffffff',
                    formatType: 1,
                    bannerType: 1,
                    screens: {},
                    presentationOptions: {
                        case: 0,
                        ntimes: null,
                        every: null

                    },
                    clickOptions: {
                        case: 0,
                        param: null
                    },
                    comebackOptions: {
                        case: 0,
                        ntimes: null,
                        after: null
                    },
                    titleOnly: false,
                    imageOnly: false,
                });
            };
        }

        class Timetable extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    type: 'Departure',
                    stationHeader: true,
                    staticImage: false,
                    contentSwap: false,
                    contentMockup: false, // false - Short, true - Long
                    header: {
                        enabled: true,
                        columns: []
                    },
                    map: {
                        zoom: 15
                    },
                    filters: {
                        transTypes: [],
                        timeTill: false
                    },
                    groups: {
                        by: null // enum: [null, 'transport', 'line']
                    },
                    appearanceSize: 'small' //  enum: ['small', 'medium', 'big']. See constant TIMETABLE_APPEARANCE_SIZE
                });
            };
        }

        class Weather extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    type: 'Weather'
                });
            };
        }

        class Rss extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    type: 'Rss',
                    message: "",
                    channelLink: "",
                    channelName: "",
                    copyright: "",
                    maxItems: 30,
                    selectedChannels: [],
                    selectedCampaigns: [],
                    includeAllCampaigns: false,
                });
            };
        }

        class NewCampaign extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    contents: [],
                    sections: [],
                    audiences: '',
                    audiencesObject: {},
                    locations: [],
                    selectedDays: [],
                    name: '',
                    description: '',
                    started_at: null,
                    finished_at: null,
                    start_time: null,
                    end_time: null,
                    wholeDay: true,
                    onlyCertainDays: false,
                    infinite: false,
                    additional_data: {},
                });
            }
        }

        class Playlist extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    type: 'Playlist',
                    items: { // TODO: rename to 'src', like ImageSlider
                        [VIEW_TYPES.PORTRAIT]: [],
                        [VIEW_TYPES.LANDSCAPE]: []
                    },
                    width: 400,
                    height: 400,
                    interval: 2000,
                    transition: 'slide'
                });
            }
        }

        class ExternalApp extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    forTouchScreen: true,
                    externalLink: '',
                    isPdf: false,
                });
            }
        }

        class VisitorInfo extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    sectionID: null,
                    internalCategories: [],
                    selectedContent: [],
                    selectedCampaign: []
                });
            }
        }

        class BaseSegmentModel extends BaseModel {
            constructor(object = {}) {
                super();

                Object.assign(this, {
                    title: "",
                    message: {},
                    tenant_id: null,
                    language_id: null,
                    segment_type_id: null,
                    description: '',
                    definitionRef: '',
                    categoryRef: '',
                    status: 0,
                }, object);
            }
        }

        class SegmentFeedbackAttributes extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {

                });
            };
        }

        class SegmentGeofences extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {

                });
            };
        }

        class SegmentNumberRanges extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {

                });
            };
        }

        class SegmentCalculated extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {

                });
            };
        }

        class SegmentPreferences extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    appId: null,
                    preferences: []
                });
            };
        }

        class SegmentPreferencesItem extends BaseModel {
            constructor(object) {
                super(...arguments);

                Object.assign(this, {
                    id: null,
                    position: null,
                    propertyName: "",
                    type: null,
                    description1: "",
                    description2: "",
                }, object);
            };
        }

        class SegmentLoyalties extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    params: {
                        subscription: false,
                        counterValue: false,
                        accountValue: false,
                        tierStatus: false
                    },
                    wasActivated: false,
                });
            };
        }

        class Geofence extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: "",
                    polyline: "",
                    groups: [],
                });
            };
        }

        class GeofenceGroup extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: "",
                    geofences: [],
                });
            };
        }

        class LocationGroup extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: "",
                    type: 0,
                    locations: [],
                });
            };
        }

        // Etc. models
        class YouTubePlayer {
            constructor(tagId, params) {
                let settings = Object.assign({
                    playerVars: {
                        rel: 0,
                        showinfo: 0,
                        controls: 0,
                        disablekb: 1,
                        enablejsapi: 1,
                        iv_load_policy: 3
                    }
                }, params);

                new YT.Player(tagId, settings);
            }
        }

        class LoyaltyCampaign extends BaseModel {
            constructor(object) {
                super(...arguments);

                Object.assign(this, {
                    name: "",
                    description: {},
                    campaignLabel: {},
                    preconditionsLabel: {},
                    campaignType: null,
                    campaignStart: null,
                    campaignEnd: null,
                    collectionStart: null,
                    collectionEnd: null,
                    subscriptionType: 0,
                    loyaltyEventType: {
                        name: "", // ""
                        description: "", // ""
                        label: "Test label hardcoded", // ""
                        expirationPeriodType: null,
                        expirationPeriodValue: null,
                        conversionRatio: 1.0,
                        countingMethod: 0,
                        status: 1,
                    },
                    loyaltyCounterType: {
                        name: "Test name hardcoded", // ""
                        description: "Test description hardcoded", // ""
                        label: {},
                        expirationPeriodType: null,
                        expirationPeriodValue: null,
                        conversionRatio: 1.0,
                        maximumAccumulated: null,
                        initialCounterValue: 0, // null
                        conversionThreshold: 0.0,
                        resetType: null,
                        rewardMethod: 0,
                        unitsLabel: {},
                        visibility: 0,
                        status: 1,
                    },
                    loyaltyAccountType: {
                        name: "Test name hardcoded",
                        description: "Test description hardcoded",
                        label: {},
                        redeemPeriodType: null,
                        redeemPeriodValue: null,
                        conversionRatio: 1.0,
                        initialAccountValue: 0, // null
                        maximumAccumulated: null,
                        conversionThreshold: 0.0,
                        accountType: 1, // null
                        rewardMethod: 0,
                        unitsLabel: {},
                        usageCondition: 0, // null
                        visibility: 0,
                        status: 1,
                    },
                    loyaltyRewardType: {
                        name: "",
                        description: "",
                        label: "Test label hardcoded",
                        expirationPeriodType: null,
                        expirationPeriodValue: null,
                        conversionRatio: 1.0,
                        countingMethod: null,
                        rewardType: null,
                        rewardTargetLabel: "",
                        rewardTargetRef: null,
                        status: 1,
                    },
                    loyaltyNotifications: [
                        {
                            title: "Sample title",
                            text: "",
                            notificationUseCase: 1,
                            language: 'en',
                            status: 0
                        },
                        {
                            title: "Sample title",
                            text: "",
                            notificationUseCase: 2,
                            language: 'en',
                            status: 0
                        },
                        {
                            title: "Sample title",
                            text: "",
                            notificationUseCase: 3,
                            language: 'en',
                            status: 0
                        }
                    ],
                    status: 0,
                    tenant: null,
                    audiencesData: {}
                }, object);
            };
        }

        class PoiContent extends BaseModel {
            constructor(object = {}) {
                super();

                // DB content fields
                Object.assign(this, {
                    title: {},
                    message: {},
                    web_link: null,
                    map_info: null,
                    image_src: null,
                    audio_src: null,
                    language_id: null,
                    youtube_link: null,
                    email: "",
                    address: "",
                    always_opened: 0,
                    audio: null,
                    delivery: 0,
                    delivery_times: {},
                    opening_times: {},
                    opening_days: [],
                    origin_id: null,
                    phone_number: "",
                    content_group_id: 0,
                    language_sensitive_audio: 0,
                }, object);
            }
        }

        class PoiTour extends BaseModel {
            constructor(object = {}) {
                super();

                // DB content fields
                Object.assign(this, {
                    title: {},
                    description: {},
                    tourContents: [],
                    language_id: null,
                }, object);
            }
        }

        class Onboarding extends BaseModel {
            constructor(object = {}) {
                super();

                Object.assign(this, {
                    message: {},
                    elements: [],
                    minVersionAndroid: null,
                    maxVersionAndroid: null,
                    minVersionIos: null,
                    maxVersionIos: null,
                    location: null,
                }, object);
            };
        }

        class Tenant extends BaseModel {
            constructor(object) {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    groupName: '',
                    company: '',
                    email: '',
                    companyUrl: '',
                    tenantKey: null,
                    tenantLogoUrl: '',
                    homeLocation: '',
                    location: null,
                    supportedLanguages: '',
                    supportedUiLanguages: '',
                    corporateColours: '',
                    colors: {
                        primary: '#D3D3D3',
                        dark: '#808080',
                        accent: '#FFFFFF'
                    },
                    storeRef: 0,
                    statsTypes: '',
                    statsDb: '',
                    currency: '',
                    mctApiKey: '',
                    availableApps: '',
                    defaultRssRoot: '',
                    copyrightLabel: '',
                    manualRef: '',
                    permission_tenant: [],
                    location_tenant: [],
                    allowedModes: ['trm'],
                    dynamicContentTemplates: [],
                    allowedTimetableOptions: [],
                    carParkSubAreaTemplates: '[]',
                    primeMode: 'trm'
                }, object);
            };
        }

        class Habit extends BaseModel {
            constructor(object) {
                super(...arguments);

                Object.assign(this, {
                    description: '',
                    name: '',
                    status: 1,
                    timeParams: {
                        habitPeriodType: null,
                        habitPeriodCount: null,
                        habitIsPeriodic: false,
                        daysOfWeek: [],
                        hourOfDayStart: '',
                        hourOfDayEnd: ''
                    },
                    type: null,
                    typeRef: '',
                    params: null
                }, object);
            };
        }

        class Scenario extends BaseModel {
            constructor(object = {}) {
                super();

                Object.assign(this, {
                    name: '',
                    description: '',
                    active_till: null,
                    assignments: [],
                }, object);
            };
        }

        class ScenarioAssignment extends BaseModel {
            constructor(object = {}) {
                super();

                Object.assign(this, {
                    name: '',
                    content_id: null,
                    locations: [],
                }, object);
            };
        }

        class LineRoute extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    horizontal: {
                        textAngleBase: null,
                    },
                    vertical: {
                        textAngleBase: null,
                    },
                    transTypes: [],
                    header: {},
                    footer: {},
                    line: {
                        textAngle: 0,
                        stationsNumber: 50,
                        stationsPerLine: 50,
                    },
                });
            };
        }

        /**
         * @typedef {{by: 'time'|'timeReal'|'product', direction: 'asc'|'desc'}} TimetableHafasOrderCondition
         */

        /**
         * @typedef {'dep'|'arr'} TimetableHafasBoardType
         */

        /**
         * @typedef {object} TimetableHafasElement
         *
         * @property {string} headerLabel
         * @property {string} headerSubLabel
         * @property {'arrow'|'product'|'direction'|'track'|'time'|'realTime'} type
         * @property {'countdown'|'hh:mm'|'relative'} [timeFormat]
         * @property {number} [timeNow] - seconds to departure/arrival when time will be replaced with a "NOW" sign
         * @property {'left'|'center'|'right'} [textAlign]
         * @property {'top'|'center'|'bottom'} [textPlacement]
         * @property {'show'|'hide'|'auto'} behavior
         * @property {number} viaStops
         * @property {string} viaStopsSeparator
         * @property {number} viewMode
         * @property {number} nowScale
         * @property {number} textScale
         * @property {'row-aspect-ratio-threshold'} scaleAlgorithm
         * @property {Number} countdownThreshold
         * @property {boolean} isDelayedTimeHighlight
         * @property {{default: string, night: string}} delayedHighlightColors
         * @property {boolean} realtimeIndicator
         * @property {'icon'|'clock'} realtimeIndicatorType
         * @property {string} [width] - available for the product element
         * @property {boolean} [showDate] - available for the time and realtime element
         *
         * @property {object} [params] - these params are set in the runtime
         * @property {'first'|'middle'|'last'|'single'} params.line
         * @property {'first'|'middle'|'last'|'single'} params.col
         * @property {number} params.colIndex - starts with 1
         * @property {'auto'|'min-content'} params.width - grid cell width
         * @property {'boolean'} params.header
         */

        /**
         * @typedef {object} TimetableHafasHeaderElement // TODO: rename
         *
         * @property {boolean} show
         * @property {'clock'|'date'|'image'|'stop_name'} type
         * @property {string|null} url - only for image type
         * @property {boolean} bold
         * @property {boolean} size
         * @property {string} content - only for text type
         * @property {boolean} singleLine - only for text type and the station name
         * @property {boolean} ticker - only for text type and the station name
         */

        /**
         * @typedef {object} TimetableHafasHeader
         *
         * @property {boolean} show
         * @property {object} colors
         * @property {object} colors.default
         * @property {string} colors.default.background
         * @property {string} colors.default.text
         * @property {object} colors.night
         * @property {string} colors.night.background
         * @property {string} colors.night.text
         * @property {object} elements
         * @property {TimetableHafasHeaderElement[]} elements.left
         * @property {TimetableHafasHeaderElement[]} elements.right
         */

        /**
         * @typedef {object} TimetableHafasResponsiveOptions
         *
         * @property {object} enabled
         * @property {boolean} enabled.narrow
         * @property {boolean} enabled.slim
         * @property {object} thresholds
         * @property {number} thresholds.narrow
         * @property {number} thresholds.slim
         */

        /**
         * @name TimetableHafasOptions
         *
         * @property {TimetableHafasBoardType} boardType
         * @property {Object} view
         * @property {number} bufferSizeMultiplier
         * @property {number} himInterval - DEPRECATED
         * @property {object} him
         * @property {boolean} him.show
         * @property {number} him.interval
         * @property {number} him.fontSize
         * @property {object} grouping
         * @property {boolean} grouping.enabled
         * @property {boolean} grouping.mode
         * @property {object} grouping.styles
         * @property {number} grouping.styles.textScale
         * @property {boolean} grouping.styles.bold
         * @property {object} grouping.colors
         * @property {object} grouping.colors.default
         * @property {string} grouping.colors.default.background
         * @property {string} grouping.colors.default.text
         * @property {object} grouping.colors.night
         * @property {string} grouping.colors.night.background
         * @property {string} grouping.colors.night.text
         * @property {"strict"|"dynamic"} view.type
         * @property {number} view.rows
         * @property {number} view.columns
         * @property {number} view.minRowHeight
         * @property {number} view.minColWidth
         * @property {boolean} view.scroll
         * @property {number} view.borderWidth
         * @property {number} view.innerMarginHorizontal
         * @property {number} view.innerMarginVertical
         * @property {number} view.outerMargin
         * @property {number} view.paddingLeft
         * @property {number} view.paddingRight
         * @property {boolean} view.shadows
         * @property {TimetableHafasResponsiveOptions} responsive
         * @property {TimetableHafasOrderCondition[]} order
         * @property {Object} colors
         * @property {Object} colors.default
         * @property {string} colors.default.background
         * @property {string} colors.default.foreground
         * @property {string} colors.default.foregroundEven - every 2nd row
         * @property {string} colors.default.text
         * @property {string} colors.default.border
         * @property {Object} colors.night
         * @property {string} colors.night.background
         * @property {string} colors.night.foreground
         * @property {string} colors.night.foregroundEven - every 2nd row
         * @property {string} colors.night.text
         * @property {string} colors.night.border
         * @property {'default'|'night'|'auto'} colors.mode
         * @property {number} walkingTime
         * @property {number} interval - update interval in seconds
         * @property {object} tableHeader
         * @property {boolean} tableHeader.show - show table headers
         * @property {number} tableHeader.size
         * @property {object} tableHeader.colors
         * @property {object} tableHeader.colors.default
         * @property {string} tableHeader.colors.default.background
         * @property {string} tableHeader.colors.default.text
         * @property {object} tableHeader.colors.night
         * @property {string} tableHeader.colors.night.background
         * @property {string} tableHeader.colors.night.text
         * @property {TimetableHafasElement[]} elements
         * @property {number} headerId
         * @property {number} footerId
         * @property {HeaderFooterContent} [header] - available only in the Digital Display app
         * @property {HeaderFooterContent} [footer] - available only in the Digital Display app
         * @property {object} noJourneys
         * @property {string | null} noJourneys.image
         * @property {string} noJourneys.text
         */
        class TimetableHafasOptions {
            constructor(options = {}) {
                const defaults = {
                    boardType: 'dep',
                    bufferSizeMultiplier: 3,
                    him: {
                        show: false,
                        interval: 10,
                        fontSize: 1,
                    },
                    headerId: null,
                    footerId: null,
                    grouping: {
                        enabled: false,
                        mode: 'inline',
                        styles: {
                            textScale: 1,
                            bold: false,
                        },
                        colors: {
                            default: {
                                background: '#FFFFFF',
                                text: '#232323',
                            },
                            night: {
                                background: '#000000',
                                text: '#FFFFFF',
                            },
                        },
                    },
                    view: {
                        type: 'strict',
                        rows: 6,
                        columns: 1,
                        minRowHeight: 100,
                        minColWidth: 800,
                        scroll: false,
                        borderWidth: 0,
                        innerMarginHorizontal: 0,
                        innerMarginVertical: 0,
                        outerMargin: 0,
                        paddingLeft: null,
                        paddingRight: null,
                        shadows: false,
                    },
                    responsive: {
                        enabled: {
                            narrow: true,
                            slim: true,
                        },
                        thresholds: {
                            narrow: 6,
                            slim: 1.5,
                        },
                    },
                    order: [
                        {
                            by: TIMETABLE_ORDER.REAL_TIME,
                            direction: 'asc',
                        }
                    ],
                    colors: {
                        default: {
                            background: '#eeeeee',
                            foreground: '#ffffff',
                            foregroundEven: '#f0f0f0',
                            text: '#232323',
                            border: '#232323',
                        },
                        night: {
                            background: '#666666',
                            foreground: '#222222',
                            foregroundEven: '#333333',
                            text: '#FFFFFF',
                            border: '#FFFFFF',
                        },
                        mode: 'default',
                    },
                    walkingTime: 0,
                    interval: 30,
                    tableHeader: {
                        show: false,
                        showSubLabels: false,
                        size: 1,
                        colors: {
                            default: {
                                background: '#eeeeee',
                                text: '#232323',
                            },
                            night: {
                                background: '#666666',
                                text: '#FFFFFF',
                            },
                        },
                    },
                    elements: [
                        {
                            headerLabel: 'Richtung',
                            headerSubLabel: 'Direction',
                            type: 'arrow',
                            behavior: 'show',
                        },
                        {
                            headerLabel: 'Linie',
                            headerSubLabel: 'Line',
                            type: 'product',
                            behavior: 'show',
                            viewMode: 1,
                            textScale: 0.75,
                            gap: 0.15,
                        },
                        {
                            headerLabel: 'Ziel',
                            headerSubLabel: 'Destination',
                            type: 'direction',
                            behavior: 'show',
                            textAlign: 'left',
                            textPlacement: 'center',
                            fontSize: 0.75,
                            fontSizeViaStops: 0.5,
                            viaStops: 0,
                            viaStopsSeparator: ' ➡ ',
                            scaleAlgorithm: null,
                        },
                        {
                            headerLabel: 'Bahnsteig',
                            headerSubLabel: 'Platform',
                            type: 'track',
                            behavior: 'hide',
                            textAlign: 'center',
                            textScale: 0.75,
                        },
                        {
                            headerLabel: 'Position',
                            headerSubLabel: 'Position',
                            type: 'position_numbers',
                            behavior: 'hide',
                            textAlign: 'center',
                            textScale: 0.75,
                        },
                        {
                            headerLabel: 'Aufzug',
                            headerSubLabel: 'Elevator',
                            type: 'elevator_numbering',
                            behavior: 'hide',
                            textAlign: 'center',
                        },
                        {
                            headerLabel: 'Soll',
                            headerSubLabel: 'Plan',
                            type: 'time',
                            behavior: 'hide',
                            timeFormat: 'hh:mm',
                            timeNow: 0,
                            textAlign: 'right',
                            nowScale: 0.75,
                            textScale: 0.75,
                            realtimeIndicator: false,
                            realtimeIndicatorType: 'icon',
                            isDelayedTimeHighlight: false,
                            delayedHighlightColors: {
                                default: '#FF0000',
                                night: '#FF0000',
                            }
                        },
                        {
                            headerLabel: 'Ist',
                            headerSubLabel: 'Realtime',
                            type: 'realTime',
                            behavior: 'show',
                            timeFormat: 'hh:mm',
                            timeNow: 60,
                            textAlign: 'right',
                            nowScale: 0.75,
                            textScale: 0.75,
                            realtimeIndicator: true,
                            realtimeIndicatorType: 'icon',
                            isDelayedTimeHighlight: false,
                            delayedHighlightColors: {
                                default: '#FF0000',
                                night: '#FF0000',
                            }
                        }
                    ],
                    noJourneys: {
                        image: '',
                        text: '',
                        textColor: '#333333',
                        bgColor: '#ffffff',
                        viewMode: NO_JOURNEYS_VIEW_MODE.DEFAULT,
                    },
                    previewSize: {
                        w: 1920,
                        h: 1080
                    }
                };

                // Set missing default properties to the provided object
                mergeNestedProperties(options, defaults);
                mergeNestedProperties(this, options);

                // Set default missing properties of array of elements
                this.elements.forEach(element => {
                    const defaultElement = defaults.elements.find(defaultElement => defaultElement.type === element.type);
                    mergeNestedProperties(element, defaultElement);
                });
            }
        }

        /**
         * @name TimetableHafasPage
         *
         * @property {{ landscape: boolean, portrait: boolean }} view
         */
        class TimetableHafasPage extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    options: {
                        pageParam1: 'value1',
                        pageParam2: 'value2',
                    },
                });
            };
        }

        /**
         * @name HimOptions
         *
         * @property {object} colors
         * @property {'default'|'night'|'auto'} colors.mode
         * @property {number} slidingTimeout - slide transition time in seconds
         */
        class HimOptions extends BaseModel {
            constructor(options = {}) {
                super(...arguments);

                const defaults = {
                    colors: {
                        mode: 'default',
                    },
                    slidingTimeout: 5,
                };

                // Set missing default properties to the provided object
                mergeNestedProperties(options, defaults);
                mergeNestedProperties(this, options);
            };
        }

        /**
         * @name HeaderFooterOptions
         *
         * @property {number} height
         * @property {object} view
         * @property {number} view.paddingLeft
         * @property {number} view.paddingRight
         * @property {object} colors
         * @property {object} colors.mode
         * @property {object} colors.default
         * @property {string} colors.default.background
         * @property {string} colors.default.text
         * @property {object} colors.night
         * @property {string} colors.night.background
         * @property {string} colors.night.text
         * @property {object} elements
         * @property {TimetableHafasHeaderElement[]} elements.left
         * @property {TimetableHafasHeaderElement[]} elements.center
         * @property {TimetableHafasHeaderElement[]} elements.right
         */
        class HeaderFooterOptions extends BaseModel {
            constructor(options = {}) {
                super(...arguments);

                const defaults = {
                    height: 15,
                    view: {
                        paddingLeft: null,
                        paddingRight: null,
                    },
                    colors: {
                        mode: 'default',
                        default: {
                            background: '#DDDDDD',
                            text: '#232323',
                        },
                        night: {
                            background: '#555555',
                            text: '#FFFFFF',
                        },
                    },
                    elements: {
                        left: [
                            {
                                type: 'image',
                                show: true,
                                url: '',
                            },
                            {
                                type: 'stop_name',
                                show: true,
                                bold: true,
                                size: 1,
                            }
                        ],
                        center: [
                            {
                                type: 'date',
                                show: true,
                                bold: true,
                                size: 1,
                            },
                        ],
                        right: [
                            {
                                type: 'clock',
                                show: true,
                                bold: true,
                                size: 1.5,
                            }
                        ],
                    }

                }

                // Set missing default properties to the provided object
                mergeNestedProperties(options, defaults);
                mergeNestedProperties(this, options);
            }
        }

        /**
         * @name HimPage
         *
         * @property {{ landscape: boolean, portrait: boolean }} view
         */
        class HimPage extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    options: {
                        pageParam1: 'value1',
                        pageParam2: 'value2',
                    },
                });
            };
        }

        class ScreenControllerDetails extends BaseModel {
            constructor() {
                super(...arguments);

                const date = new Date();
                const timestamp = date.getTime().toString();

                Object.assign(this, {
                    deviceIdExt: timestamp,
                    deviceId: timestamp,
                    name: `Controller #${timestamp}`,
                    controllerLabel: `Controller #${timestamp}`,
                    details: 'station',
                    imageRef: '',
                    locationLevel: '0',
                    locationWalkingTime: 0,
                    locationTrack: '-2',
                    screen: {
                        name: `Screen #${timestamp}`,
                        purpose: `Screen for controller #${timestamp}`,
                        location: 'station',
                        mountingOrientation: '0',
                        imageRef: '',
                    },
                    station: {},
                    controllerType: {},
                    status: 1,
                });
            };
        }

        class CarPark extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    description: {},
                    service_provider_id: null,
                    country_code: '',
                    country: '',
                    address_city: '',
                    address_street: '',
                    area_polygon: '',
                    operator: '',
                    owner: '',
                    quantity: 0,
                    gateways: 0,
                    public_transport: {},
                    womens_parking: {},
                    barrier_free: {},
                    latlng: '',
                    time_zone_id: '',
                    status: 0,
                    type: CAR_PARK_TYPE.OPEN_AIR_CARPARK.id,
                    image_ref: '',
                    opening_hour: {
                        name: '',
                        parameters: {}
                    },
                    opening_hours: {},
                    car_park_gateways: [],
                    rate_model_assignments: [],
                    charging_stations: [],
                    sub_areas: [],
                });
            };
        }

        class CarParkGateway extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    barrier_type: "1",
                    reader_type: "1",
                    gateway_name: "",
                    image_ref: "",
                    latlng: "",
                    entry_lanes: 0,
                    exit_lanes: 0,
                    entrances: [],
                    unique_id: Date.now(),
                });
            };
        }

        class CarParkEntrance extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    entrance_direction: CAR_PARK_ENTRANCE_DIRECTION.IN_OUT.direction,
                    entrance_label: CAR_PARK_ENTRANCE_DIRECTION.IN_OUT.label,
                    entrance_number: null,
                    remote_device_ref: null,
                    iot_entity_assignments: [],
                    relatedDigitalIoAssignment: new IotEntityAssignment(),
                    image_ref: "",
                    name: "",
                    unique_id: Date.now(),
                });
            };
        }

        class IotDevice extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    details: '',
                    location: '',
                    device_type: null,
                    device_ref: '',
                    latlng: '',
                    mounted_in: '',
                    image_ref: '',
                    relays: 1
                })
            }
        }

        class IotEntity extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    controllerRef: null,
                    description: '',
                    entityTypeRef: null,
                    hwAddress: '',
                    hwSerial: '',
                    imageRef: '',
                    inventoryNr: '',
                    ip6Address1: '',
                    ip6Address2: '',
                    ipAddress1: '',
                    ipAddress2: '',
                    latlng: '',
                    location: '',
                    macAddress: '',
                    name: '',
                    params: null,
                    status: 1,
                    type: null,
                })
            }
        }

        class ChargingStation extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    capacity: 0,
                    car_park_id: null,
                    description: {},
                    image: '',
                    location: '',
                    location_name: '',
                    name: {},
                    operator: '',
                    payment_types: [],
                    plug_types: [],
                    status: 0,
                    support_label: {},
                })
            }
        }

        class CarParkSubArea extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    id: null,
                    car_park_id: null,
                    type: '',
                    public: false,
                    capacity_physical: null,
                    capacity_virtual: null,
                    priority: null,
                    label: {},
                    index: 0,
                    location: '',
                    color: '',
                })
            }
        }

        class ShareParkCustomer extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    addressCity: '',
                    addressCountry: '',
                    addressHouseNumber: '',
                    addressPostalCode: '',
                    addressStreet: '',
                    emailAddress: '',
                    gender: '0',
                    nameFirst: '',
                    nameLast: '',
                    status: 2,
                    birthday: null,
                    registeredVehicles: []
                })
            }
        }

        class CapVehicle extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    vehicleName: '',
                    capVehicleRegistration: {
                        countryCode: '',
                        numberPlate: '',
                        vehicleBrand: '',
                        vehicleType: ''
                    }
                })
            }
        }

        class CarParkInvitation extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    guests: [],
                    email: '',
                    amount: 1,
                    startDate: moment().utc().seconds(0),
                    endDate: moment().utc().add(1, 'day').seconds(0),
                    carParkId: null,
                    subAreaId: null,
                    sender: '',
                    subject: 'Car Park Invitation',
                    linkLabel: 'Link',
                })
            }
        }

        class CarParkCustomerCommunication extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    id: null,
                    sender: '',
                    sent: 0,
                    text_code: 'FREE_EMAIL',
                    language: null,
                    start_date: null,
                    end_date: null,
                    target_users: {
                        profileRefs: [],
                        guids: [],
                        emails: [],
                        testEmail: [],
                    },
                    email_subject: {},
                    email_body: {},
                })
            }
        }

        class CarParkServiceProvider extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    companyId: '',
                    fullCompanyName: '',
                    legalForm: '',
                    name: '',
                    status: 1,
                    taxCountry: '',
                    taxId: '',
                    parentCompanyRef: null,
                    imageRef: ""
                })
            }
        }

        class CarParkSublet extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    companyId: '',
                    fullCompanyName: '',
                    legalForm: '',
                    name: '',
                    status: 1,
                    taxCountry: '',
                    taxId: '',
                    parentCompanyRef: null
                })
            }
        }

        class MapOptions extends BaseModel {
            constructor(options) {
                super();

                Object.assign(this, {
                    center: {
                        lat: options.latitude,
                        lng: options.longitude,
                    },
                    zoom: options.zoom,
                    maxZoom: options.maxZoom,
                    gestureHandling: 'cooperative'
                })
            }
        }

        class Unity3dModel extends BaseModel {
            constructor(options) {
                super();

                Object.assign(this, {
                    name: '',
                })
            }
        }

        class IotEntityAssignment extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    cap_entrance_id: null,
                    iot_entity_id: null,
                    channel: '',
                    lane: '',
                    params: null,
                    assign_access_rights: [],
                    unassign_access_rights: [],
                })
            }
        }

        class LegalText extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    filename: '',
                    text: {},
                    link: [],
                    notify_app_users: false,
                    mandatory: false,
                    language_id: null
                })
            }
        }

        class BillingConfiguration extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    operator_name: '',
                    operator_email: '',
                    operator_phone_number: '',
                    operator_url: '',
                    opening_statement: '',
                    icon_src: '',
                    closing_statement: '',
                    legal_disclaimer: '',
                    operator_address: '',
                    board_members: '',
                    company_information: '',
                    banking_information: '',
                    bill_layout: '',
                    start_at: null,
                })
            }
        }

        class ShareParkCompany extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    addressCity: '',
                    addressCountry: '',
                    addressHouseNumber: '',
                    addressPostalCode: '',
                    addressStreet: '',
                    emailAddress: '',
                    name: '',
                    status: 1,
                    valid_from: null,
                    valid_till: null,
                    vehicles: [],
                })
            }
        }

        class CapCompanyVehicle extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    country_code: '',
                    number_plate: '',
                    vehicle_brand: '',
                    vehicle_type: '',
                    permission: null,
                });
            }
        }

        class ShopModel extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    city: '',
                    houseNumber: '',
                    isOpen: false,
                    latlng: '',
                    postalCode: '',
                    street: '',
                });
            }
        }

        class TimeSlotModel extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    params: '',
                    scheduleFrom: moment().add(1, 'd').toISOString(),
                    scheduleTill: moment().add(1, 'd').toISOString(),
                    scheduleWeekday: String(moment().day()),
                    status: 1,
                });
            }
        }

        class FAQConfiguration extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    questionGroups: {},
                    faq_json: {},
                    language_id: null,
                    filename: '',
                    headerText: {},
                    footerText: {},
                })
            }
        }

        class Profile extends BaseModel {
            constructor(isEditMode) {
                super(...arguments);

                Object.assign(this, {
                    name: {},
                    description: {},
                    capPermissionLevel: 1,
                    capPermissions: new ProfileCapPermissions(isEditMode),
                    externalId: '',
                    ratePermissions: new ProfileRatePermissions(isEditMode),
                    profilePermissions: {
                        defaultProfile: {
                            featurePermissions: new ProfileFeaturePermissions(),
                        },
                        overrideProfiles: []
                    },
                    status: 1,
                    typeCategory: '',
                })
            }
        }

        class ProfileFeaturePermissions extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    isLoyaltyEnabled: false,
                    isOvernightEnabled: false,
                    isChargingDetailsEnabled: false,
                    maxBookaheadDays: 30,
                    bookingTimeslotMillis: 3600000,
                    isChangeNumberPlateEnabled: false,
                    isChangeParkingLotEnabled: false,
                    isMultiBookingEnabled: false,
                    isTokenIdEnabled: false,
                    isTokenBadgeEnabled: false
                })
            }
        }

        class ProfileRatePermissions extends BaseModel {
            constructor(editMode) {
                super(...arguments);

                Object.assign(this, {
                    adhocParking: false,
                    bookingPermission: false,
                    permissionMode: 1,
                    ratesArr: [],
                    adhocRates: '',
                    adhocRatesArr: [],
                }, editMode ? {} : { rates: '' })
            }
        }

        class ProfileCapPermissions extends BaseModel {
            constructor(editMode) {
                super(...arguments);

                Object.assign(this, {
                    permissionMode: 1,
                    profileCategory: 0,
                    adhocCaps: '',
                }, editMode ? {} : { caps: '' })        // Example caps: '82:DEFAULT_1;82:DEFAULT_3'
            }
        }

        class ProfileConfig extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    isDefault: false,
                    profileEmail: '',
                    profileRef: null,
                    refuseUser: false,
                    externalIdentityProvider: null,
                    status: 1
                })
            }
        }

        class SponsorModel extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    branchInfo: false,
                    description: '',
                    legalAddress: null,
                    legalName: '',
                    logoRef: null,
                    status: 1,
                    params: 1,
                    tenant: 1,
                })
            }
        }

        class ProfileRules extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    amountOfWildcards: 3,
                    name: "",
                    profileExceptionValues: "*",
                    profileExtId: "",
                    profileMatchValues: "*",
                    searchValue1: "*",
                    searchValue1Label: "*",
                    searchValue2: "*",
                    searchValue2Label: "*",
                    searchValue3: "*",
                    searchValue3Label: "",
                    searchValue4: "*",
                    searchValue5: "*",
                    searchValue6: "*",
                    searchValue7: "*",
                    searchValue8: "*",
                    searchValue9: "*",
                    searchValue10: "*",
                    status: 1,
                    tenant: 13,
                    version: 1,
                    description: null,
                    configs: {}
                })
            }
        }

        class UserGroup extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    app_modes: [],
                    allowedServiceProviders: [],
                    allowedProfiles: [],
                    allowedCampuses: [],
                    group_permissions: [],
                })
            }
        }

        class Discount extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: {},
                    description: {},
                    status: 1,
                })
            }
        }

        class Product extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: {},
                    description: {},
                    params: {
                        quotaType: 1,
                        quotaUnit: 1,
                        quotaQuantity: 1,
                        quotaAction: 1,
                        quotaRelation: 1,
                    },
                    status: 1,
                    amount: 1,
                    amountType: 1,
                    productType: 1,
                    fulfillmentType: 1
                })
            }
        }

        class Tariff extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: {},
                    description: {},
                    capProductAssignment: [],
                    capRateDiscountAssignment: [],
                    defaultRate: 0,
                    nameShort: {},
                    params: {},
                    priceLabel: {},
                    quotaPrice: 1,
                    quotaUnit: 'EUR',
                    rateDescription: {},
                    rateType: null,
                    validityDuration: {
                        quotaUnit: 1,
                        quotaUnitLabel: 'minute(s)',
                        quotaSize: 1
                    },
                    status: 1,
                    quota: new RateQuota()
                })
            }
        }

        class RateQuota extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    description: '',
                    gracePeriod: 0,
                    name: '',
                    nameShort: '',
                    params: {},
                    rateType: null,
                    status: 1
                })
            }
        }

        class BaseQuotaParamsModel extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    isRecurring: false,
                    quotaCurrency: '',
                    quotaPriceTax: 0,
                    quotaSize: 0,
                    quotaType: null,
                    quotaUnit: 2,
                    quotaUnitLabel: 'hour(s)',
                    unlimited: true,
                })
            }
        }

        class FixedProductOrSubscriptionQuotaParamsModel extends BaseQuotaParamsModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    dayBreak: moment('23:59', 'HH:mm'),
                    quotaPrice: 0,
                })
            }
        }

        class PayAsYouParkQuotaParamsModel extends BaseQuotaParamsModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    dayBreak: moment('23:59', 'HH:mm'),
                    maxPrice: 0,
                    maxPriceDuration: 0,
                    maxPriceDurationType: '',
                    tiers: [],
                })
            }
        }

        class TimeSlotTariffQuotaParamsModel extends BaseQuotaParamsModel {
            constructor() {
                super(...arguments);

                const tariffDays = {};
                Object.values(WEEK_DAYS).forEach(day => tariffDays[day.id] = []);

                Object.assign(this, {
                    dayBreak: moment('23:59', 'HH:mm'),
                    maxPrice: 0,
                    maxPriceDuration: 0,
                    maxPriceDurationType: '',
                    tiers: [],
                    rates: [],
                    tariffDays,
                })
            }
        }

        class ExtendedQuotaParamsModel extends BaseQuotaParamsModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    quotaPrice: 0,
                    recurringType: 1,
                })
            }
        }

        class TariffModel extends BaseModel {
            constructor() {
                super(...arguments);

                Object.assign(this, {
                    name: '',
                    nameShort: '',
                    description: '',
                    status: 1,
                })
            }
        }

        function mergeNestedProperties(target, source) {
            for (const key in source) {
                if (source[key] !== null && typeof source[key] === 'object' && !Array.isArray(source[key])) {
                    target[key] = angular.isObject(target[key]) ? target[key] : {};

                    mergeNestedProperties(target[key], source[key]);
                } else if (!target.hasOwnProperty(key)) {
                    target[key] = source[key];
                }
            }
        }

        return {
            BaseContentModel,
            Quiz,
            QuizQuestionHeader,
            QuizQuestionBody,
            DigitalDisplay,
            DigitalDisplayPage,
            Ticker,
            Timetable,
            NewCampaign,
            Weather,
            Playlist,
            ExternalApp,
            VisitorInfo,
            YouTubePlayer,
            Banner,
            BaseSegmentModel,
            SegmentFeedbackAttributes,
            SegmentGeofences,
            SegmentNumberRanges,
            SegmentCalculated,
            SegmentPreferences,
            SegmentLoyalties,
            Geofence,
            GeofenceGroup,
            LocationGroup,
            SegmentPreferencesItem,
            Rss,
            LoyaltyCampaign,
            PoiContent,
            PoiTour,
            Onboarding,
            Tenant,
            Habit,
            Scenario,
            ScenarioAssignment,
            LineRoute,
            TimetableHafasOptions,
            TimetableHafasPage,
            HimOptions,
            HeaderFooterOptions,
            HimPage,
            ScreenControllerDetails,
            CarPark,
            CarParkGateway,
            CarParkEntrance,
            IotDevice,
            IotEntity,
            ChargingStation,
            ShareParkCustomer,
            CapVehicle,
            CarParkInvitation,
            CarParkCustomerCommunication,
            MapOptions,
            CarParkSubArea,
            Unity3dModel,
            CarParkServiceProvider,
            CarParkSublet,
            IotEntityAssignment,
            LegalText,
            BillingConfiguration,
            ShareParkCompany,
            CapCompanyVehicle,
            ShopModel,
            TimeSlotModel,
            FAQConfiguration,
            Profile,
            ProfileConfig,
            ProfileRules,
            UserGroup,
            Discount,
            Product,
            Tariff,
            BaseQuotaParamsModel,
            FixedProductOrSubscriptionQuotaParamsModel,
            PayAsYouParkQuotaParamsModel,
            ExtendedQuotaParamsModel,
            TariffModel,
            ProfileFeaturePermissions,
            ProfileRatePermissions,
            ProfileCapPermissions,
            TimeSlotTariffQuotaParamsModel,
            SponsorModel,
        };
    }

})();

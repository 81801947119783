(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('elementTimeHeader', {
            templateUrl: '/screen/views/components/timetable-hafas/partials/element-time-header/element-time-header.tpl.html',
            controller: ElementTimeHeaderController,
            bindings: {
                value: '<',
            }
        });

    /**
     * @property {moment.Moment} value
     *
     * @constructor
     */
    function ElementTimeHeaderController(
        $moment,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            $ctrl.isTomorrow = _isTomorrow();
        }

        function _isTomorrow() {
            if (!$ctrl.value) {
                return false;
            }

            const todayEnd = $moment().endOf('day');
            const tomorrowEnd = $moment().add(1, 'day').endOf('day');

            return $ctrl.value.isAfter(todayEnd) && $ctrl.value.isBefore(tomorrowEnd);
        }
    }
})();

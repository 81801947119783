(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ListPaginationService', listPaginationService);

    function listPaginationService() {
        return {
            updateVisibleItems
        };

        function updateVisibleItems(page, itemsPerPage, allItems) {
            --page; // because pages logically start with 1, but technically with 0
            const firstElement = page * itemsPerPage;
            const visibleItems =  allItems.slice(firstElement, firstElement + itemsPerPage);
            const paginationParams = {page, itemsPerPage};
            return {
                visibleItems,
                paginationParams
            }
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('visitorInfoPage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/visitor-info/visitor-info.tpl.html',
            controller: VisitorInfoPageCtrl,
            bindings: {
                contentForm: '=',
                contentData: '=',
                pageData: '<',
                popup: '=?popup',
                item: '=?item'
            }
        });

    function VisitorInfoPageCtrl(
        $scope,
        StorageFactory,
        ContentDataService,
        ContentHelper,
        CampaignDataService,
        UtilitiesService,
        PermissionsService,
        CONTENT_TYPES,
        CAMPAIGN_TYPES,
    ) {
        const vm = this;
        const AVAILABLE_CONTENT_IDS = [
            CONTENT_TYPES.INFOTAINMENT,
            CONTENT_TYPES.QUIZ,
            CONTENT_TYPES.FEEDBACK,
        ];
        const { plainResponse } = UtilitiesService;
        const CONTENT_STORAGE = StorageFactory.Storage('Content');

        const { filterContentListByDefaultContentGroup, decodeContent, getTitle } = ContentHelper;
        const { filterPlaylistInteractiveContentList, isContentGroupMenuItemVisible} = PermissionsService;


        vm.isSelected = isSelected;
        vm.checkItem = checkItem;
        vm.getCategoryTitle = getCategoryTitle;
        vm.getContentTitle = getTitle;

        vm.$onInit = init;

        /**
         * Initialization method
         */
        function init() {
            let contentGroupsArray = CONTENT_STORAGE.get('contentGroupsArray') || [];
            vm.currentLang = vm.contentData.language_id;

            vm.categories = angular.copy(contentGroupsArray);

            if (!vm.pageData.page.internalCategories) {
                vm.pageData.page.internalCategories = [];
            }

            vm.pageData.page.selectedContent = vm.pageData.page.selectedContent || [];
            vm.pageData.page.selectedCampaign = vm.pageData.page.selectedCampaign || [];

            if (vm.pageData.page.selectedContent.length || vm.pageData.page.selectedCampaign.length) {
                buildPreview();
            }

            loadIntenralAppContent();
            loadCampaigns();
        }

        /**
         * Loads campaigns
         */
        function loadCampaigns() {
            CampaignDataService.getCampaigns({
                page: null,
                itemsPerPage: null,
                type: CAMPAIGN_TYPES.MOBILE
            }).then((response) => {
                    let campaignsList = plainResponse(response) || [];

                    /**
                     * TODO: 31.01.2018 serhii.kokovskyi If you do not have permission to see all content groups
                     *                  then filter content by default content group
                     */
                    if (!isContentGroupMenuItemVisible()) {
                        for (let i = 0; i < campaignsList.length; i++) {
                            campaignsList[i].contents = filterContentListByDefaultContentGroup(campaignsList[i].contents);
                        }
                    }

                    vm.campaignContent = campaignsList.filter((campaign) => {
                        if (!Array.isArray(campaign.contents)) {
                            return false;
                        }

                        return campaign.contents.some((content) => {
                            return isContentAvailableForPreview(content.content_type_id);
                        });
                    });
                    vm.campaignContent.forEach((item) => {
                        item.finished_at = Date.parse(item.finished_at);
                        item.contents.forEach(content => {
                                content.message = JSON.parse(content.message);
                                content.title = JSON.parse(content.title);
                                content.data = content.data ? JSON.parse(content.data) : {};
                        });
                    });
                })
                .catch((err) => { console.error(err); });
        }

        /**
         * Loads internal application content
         */
        function loadIntenralAppContent() {
            ContentDataService.getContentForInternalApp()
                .then((response) => {
                    let contentList = plainResponse(response) || [];
                    contentList = filterPlaylistInteractiveContentList(contentList);

                    /**
                     * TODO: 31.01.2018 serhii.kokovskyi If you do not have permission to see/load all content groups
                     *                  then filter content by default content group
                     */
                    if (!isContentGroupMenuItemVisible()) {
                        contentList = filterContentListByDefaultContentGroup(contentList);
                    }

                    vm.infotainmentContent = contentList.map(decodeContent);
                })
                .catch((err) => { console.error(err); });
        }

        /**
         * Builds preview of selected contents and campaigns
         */
        function buildPreview() {
            vm.categories.forEach((item) => {
                if (typeof item.title !== 'object') {
                    item.title = JSON.parse(item.title);
                }

                item.contents = [];

                vm.pageData.page.selectedContent.forEach((contentItem) => {
                    if (contentItem.content_group_id === item.id) {
                        if (typeof contentItem.title !== 'object') {
                            contentItem.title = JSON.parse(contentItem.title);
                        }
                        item.contents.push(contentItem);
                    }
                });

                vm.pageData.page.selectedCampaign.forEach((campaign) => {
                    if (!campaign.contents.length) {
                        return;
                    }

                    campaign.contents.forEach((campaignContent) => {
                        let contentTypeId = campaignContent.content_type_id;

                        if (campaignContent.content_group_id === item.id && isContentAvailableForPreview(contentTypeId)) {
                            if (typeof campaignContent.title !== 'object') {
                                campaignContent.title = JSON.parse(campaignContent.title);
                            }

                            campaignContent.send_time = JSON.parse(campaign.send_time);
                            campaignContent.show_days = campaign.show_days;
                            campaignContent.started_at = campaign.started_at;
                            campaignContent.finished_at = campaign.finished_at;

                            item.contents.push(campaignContent);
                        }
                    });
                });
            });

            vm.pageData.page.internalCategories = vm.categories;
        }

        /**
         * Checks if content is available for preview
         *
         * @param {number|string} contentId
         * @return {boolean} True if this content is available for preview, false otherwise
         */
        function isContentAvailableForPreview(contentId) {
            return AVAILABLE_CONTENT_IDS.includes(Number(contentId));
        }

        /**
         * @param {Object} item
         * @param {String} type
         */
        function checkItem(item, type) {
            const isContentType = type === 'content';
            const selectedArray = isContentType ? 'selectedContent' : 'selectedCampaign';
            const selectedItem = vm.pageData.page[selectedArray].filter((selectedItem) => selectedItem.id === item.id)[0];
            const itemIndex = selectedItem ? vm.pageData.page[selectedArray].indexOf(selectedItem) : -1;

            if (itemIndex === -1) {
                vm.pageData.page[selectedArray].push(item);
            } else {
                vm.pageData.page[selectedArray].splice(itemIndex, 1);
            }

            if (!vm.popup) {
                buildPreview();
            }
        }

        /**
         * @param {Object} item
         * @param {String }type
         * @return {Boolean}
         */
        function isSelected(item, type) {
            let selectedArray = (type === 'content') ? 'selectedContent' : 'selectedCampaign';
            return vm.pageData.page[selectedArray].some((selectedItem) => selectedItem.id === item.id);
        }

        /**
         * Returns category title
         * @param {Object} category
         * @returns {String}
         */
        function getCategoryTitle(category) {
            return category.title[vm.currentLang] || category.title[Object.keys(category.title)[0]];
        }

        /**
         * onFinish handler
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            buildPreview();

            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        /**
         * Save page listener
         */
        $scope.$on('digital-display-savepage', onFinish);

        /**
         * Change language listener
         */
        $scope.$on('language-change', (event, args) => { vm.currentLang = args.lang; });
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('FeedbackToolbarController', FeedbackToolbarController);

    function FeedbackToolbarController(

    ) {
        const vm = this;
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('lotManagement', {
            templateUrl: '/assets/views/share-park/lot-management/lot-management.tpl.html',
            controller: LotManagementController,
        });

    function LotManagementController() {
        const vm = this;

        vm.$onInit = init;

        function init() {
            vm.url = 'https://demo.multiguide.info';
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tenantsUsersTab', {
            templateUrl: '/assets/views/tenants/tabs/users-tab/users-tab.tpl.html',
            controller: TenantsUsersTab,
            bindings: {
                selectedSubdomain: '<'
            }
        });

    function TenantsUsersTab($q, $state, $translate, Restangular,
                             TenantDataService, LookupDataService, UserDataService, PopupService) {
        const vm = this;

        let usersPerPage = 25;
        let allUsers = [];
        let selectedTenantId = null;
        let selectedSubdomainId = null;

        vm.users = [];
        vm.usersCount = vm.users.length;
        vm.usersListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'user-name',
                    width: '60',
                    title: 'name'
                },
                {
                    name: 'EMAIL',
                    width: '30',
                    title: 'email'
                }
            ],
            buttons: {
                width: '10',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: onDeleteBtnClick
                    },
                    {
                        class: 'editBtn',
                        callback: onEditBtnClick
                    }
                ]
            },
            updateCallback: updateUsers
        };

        vm.updateUsers = updateUsers;

        /**
         * Delete button click handler
         *
         * @param {jQuery.Event} $event
         * @param {object} userItem
         */
        function onDeleteBtnClick($event, userItem) {
            $translate('YOU_ARE_ABOUT_TO_DELETE_USER_FROM_SUBDOMAIN', { userName: userItem.name })
                .then(text => {
                    PopupService.showConfirmationPopup({
                        text,
                        okButtonText: 'DELETE',
                        cancelButtonText: 'CANCEL'
                    }).then(() => {
                        UserDataService.deleteUserFromSubdomain(selectedTenantId, selectedSubdomainId, userItem.id)
                            .then((response) => {
                                reloadUsersList();
                            })
                            .catch((response) => {
                                PopupService.showAlertPopup({
                                    text: 'ERROR.SERVER_ERROR',
                                    okButtonText: 'OK'
                                });
                            });
                    });
                });
        }

        /**
         * Edit button click handler
         *
         * @param {jQuery.Event} $event
         * @param {object} userItem
         */
        function onEditBtnClick($event, userItem) {
            loadRequiredDataForEditUserState(userItem)
                .then((stateData) => {
                    if (Object.keys(stateData).length) {
                        $state.go('app.editUser', { data: stateData });
                    }
                });
        }

        /**
         * Loads all required data for 'editUser' state
         *
         * @param {object} userItem
         */
        function loadRequiredDataForEditUserState(userItem) {
            let userId = userItem.id;

            return $q.all([
                    LookupDataService.getAccessData(),
                    TenantDataService.getTenantsMap({
                        "include[]": ["subdomains", "permissions", "locations"]
                    }),
                    UserDataService.getUserRoles(userId)
                ])
                .then((responses) => {
                    if (!(_.isArray(responses) && responses.length)) {
                        return {};
                    }

                    let accessData = responses[0] || {};
                    let tenantsMap = responses[1] || {};
                    let rolesData = responses[2] || {};

                    let rolesMap = accessData.rolesMap ? accessData.rolesMap : {};
                    let permissionsMap = accessData.permissionsMap ? accessData.permissionsMap : {};
                    let permissionsGroupMap = accessData.permissions_groupMap ? accessData.permissions_groupMap : {};

                    userItem['roles'] = rolesData;

                    /**
                     * Note: We need to convert a User plain object to Restangular object because 'editUser' state
                     *       working with Restangular object
                     */
                    Restangular.restangularizeElement('', userItem, 'users');

                    return {
                        rolesMap,
                        permissionsMap,
                        permissionsGroupMap,
                        tenantsMap,
                        userData: userItem
                    };
                })
                .catch(console.error.bind(console));
        }

        /**
         * Updates list of users
         */
        function updateUsers(page, itemsPerPage) {
            vm.users = allUsers.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        /**
         * Reloads users list
         */
        function reloadUsersList() {
            TenantDataService.getTenantSubdomainUsers(selectedTenantId, selectedSubdomainId).then((response) => {
                allUsers = response.plain();

                vm.usersCount = allUsers.length;
                vm.usersListData.updateCallback(1, usersPerPage);
            });
        }

        /**
         * Initialization method
         */
        function init() {
            let subdomain = vm.selectedSubdomain && vm.selectedSubdomain.plain();

            if (Object.keys(subdomain).length) {
                selectedTenantId = subdomain.tenant_id;
                selectedSubdomainId = subdomain.id;
            }

            reloadUsersList();
        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('BodyController', BodyController);

    function BodyController(
        StorageFactory
    ) {
        const vm = this;

        vm.isZoomPropSet = isZoomPropSet;

        function getStorage() {
            if (!vm.STORAGE) {
                vm.STORAGE = StorageFactory.Storage('Main');
            }

            return vm.STORAGE;
        }

        function isZoomPropSet() {
            const STORAGE = getStorage();

            if (!STORAGE) {
                return false;
            }

            const currentUserInfo = STORAGE ? STORAGE.get('currentUserInfo') : null;

            if (!currentUserInfo) {
                return false;
            }

            return !!currentUserInfo.zoom;
        }
    }
}());

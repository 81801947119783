(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capOperationsRevenues', {
            templateUrl: '/assets/views/share-park/operations/revenues/revenues.tpl.html',
            controller: OperationsRevenuesController
        });

    function OperationsRevenuesController(
        $state,
        $scope,
        DateHelper,
        ListPaginationService,
        RevenuesExportPopupService,
        ShareParkDataService,
    ) {
        const vm = this;

        const {
            getPurchasedItems,
            getCustomers,
        } = ShareParkDataService;

        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
        vm.$onInit = init;

        vm.searchName = '';
        vm.revenues = [];
        vm.filteredRevenues = [];
        vm.customers = [];
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'revenue-name',
                    width: '40',
                    title: item => item.name
                },
                {
                    name: 'CUSTOMER_EMAIL',
                    width: '25',
                    class: 'revenue-customer-email',
                    title: item => item.customer ? item.customer.emailAddress : 'DELETED_USER',
                    translate: true,
                },
                {
                    name: 'PRICE',
                    width: '15',
                    title: item => {
                        const price = angular.isNumber(item.discountedPrice)
                            ? item.discountedPrice
                            : item.originalPrice;
                        return `${price / 100} ${item.currencyCode}`;
                    }
                },
                {
                    name: 'DATE',
                    width: '20',
                    title: item => formatDateTime(item.purchaseDate)
                }
            ],
            updateCallback: updateRevenuesList,
        };
        vm.updateCounter = 0;

        vm.filterRevenues = filterRevenues;
        vm.exportCsv = exportCsv;

        function init() {
            Promise.all([
                getPurchasedItems(),
                getCustomers(),
            ]).then(([revenues, customers]) => {
                vm.customers = customers.plain();

                const customersByGUID = _.keyBy(vm.customers, 'guid');
                vm.revenues = revenues.plain().map(purchase => {
                    purchase.purchaseDate = moment.utc(purchase.purchaseDate).local();
                    if (purchase.guid && customersByGUID[purchase.guid]) {
                        purchase.customer = customersByGUID[purchase.guid];
                    }
                    return purchase;
                }).sort((a, b) => {
                    return a.purchaseDate.isAfter(b.purchaseDate) ? 1 : -1;
                });

                vm.filteredRevenues = vm.revenues;
                vm.updateCounter++;
                $scope.$apply();
            });
        }

        /**
         * Updates revenues list
         * @param { Number | undefined } page
         * @param { Number | undefined } itemsPerPage
         */
        function updateRevenuesList(page = 0, itemsPerPage = 25) {
            vm.visibleRevenues = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredRevenues).visibleItems;
        }

        function filterRevenues() {
            vm.filteredRevenues = vm.searchName
                ? vm.revenues.filter(revenue =>
                    revenue.customer && revenue.customer.emailAddress.toUpperCase().includes(vm.searchName.toUpperCase())
                )
                : vm.revenues;
            vm.updateCounter++;
        }

        function exportCsv() {
            RevenuesExportPopupService.show();
        }

        /**
         * @param dateTime
         * @return { string }
         */
        function formatDateTime(dateTime) {
            return dateTime.format(DATE_TIME_FORMAT);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FAQConfigurationDataService', FAQConfigurationDataService);

    function FAQConfigurationDataService(
        Restangular,
    ) {
        return {
            getFAQConfigurations,
            createFAQConfiguration,
            updateFAQConfiguration,
            deleteFAQConfiguration,
        };

        function getFAQConfigurations(data) {
            return Restangular.all('sharePark/faq-configuration').getList(data);
        }

        function createFAQConfiguration(data) {
            return Restangular.all('sharePark/faq-configuration')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        function deleteFAQConfiguration(id) {
            return Restangular.one('sharePark/faq-configuration', id).remove();
        }

        function updateFAQConfiguration(legalText, data) {
            data.append('_method', 'PUT');
            return legalText.customPOST(data, '', {}, { 'Content-Type': undefined });
        }
    }
})();
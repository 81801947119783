(function() {
    'use strict';

    angular.module('beacon.app')
        .component('pageEditorSplitScreen', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/split-screen/split-screen.tpl.html',
            controller: PageEditorSplitScreenController,
            bindings: {
                contentData: '=',
                pageData: '<',
            }
        });

    /**
     * @property {DigitalDisplayContent} contentData
     * @property {SplitScreenPage} pageData
     *
     * @constructor
     */
    function PageEditorSplitScreenController(
        $scope,
        ContentDataService,
        StorageFactory,
        UtilitiesService,
        PopupService,
        PageDataService,
        PAGE_TYPES,
        RESIZE_TYPES,
    ) {
        const vm = this;

        const DEFAULT_MODEL_ITEMS_COUNT = 4;

        vm.LAYOUT_TYPES = {
            PORTRAIT: 'portrait',
            LANDSCAPE: 'landscape',
            NARROW_LANDSCAPE: 'narrowLandscape',
        };

        vm.SELECT_LAYOUT_ACTIONS = {
            PREV: 'prev',
            NEXT: 'next',
        };

        vm.portraitSelectedId = 1;
        vm.landscapeSelectedId = 1;
        vm.narrowLandscapeSelectedId = 1;

        vm.portraitModel = {};
        vm.landscapeModel = {};
        vm.narrowLandscapeModel = {};

        vm.PORTRAIT_LAYOUTS = [];
        vm.LANDSCAPE_LAYOUTS = [];
        vm.NARROW_LANDSCAPE_LAYOUTS = [];

        vm.ITEM_CALLBACK_OPTIONS = {
            ADD: 'add',
            EDIT: 'edit',
            DELETE: 'delete',
        }

        vm.label = {
            portrait: '',
            landscape: '',
            narrowLandscape: '',
        };


        vm.getLabel = getLabel;
        vm.changeLayout = changeLayout;
        vm.layoutItemCallback = layoutItemCallback;
        vm.copyFromOpposite = copyFromOpposite;

        vm.$onInit = () => {
            vm.pageData.page = pageModel(vm.pageData.page);

            PageDataService.getLayouts().then(response => {

                vm.PORTRAIT_LAYOUTS = response
                    .filter(layout => layout.type === vm.LAYOUT_TYPES.PORTRAIT)
                    .map(layout => Object.assign({}, layout.layout, {layoutId: layout.layoutId, label: layout.label}));

                vm.LANDSCAPE_LAYOUTS = response
                    .filter(layout => layout.type === vm.LAYOUT_TYPES.LANDSCAPE)
                    .map(layout => Object.assign({}, layout.layout, {layoutId: layout.layoutId, label: layout.label}));

                vm.NARROW_LANDSCAPE_LAYOUTS = response
                    .filter(layout => layout.type === vm.LAYOUT_TYPES.NARROW_LANDSCAPE)
                    .map(layout => Object.assign({}, layout.layout, {layoutId: layout.layoutId, label: layout.label}));
            });
        };

        function getLabel(label, layoutType) {
            vm.label[layoutType] = label;
        }

        /**
         * Creates page model
         * @param {SplitScreenPageInner} data
         * @returns {SplitScreenPageInner} Page model
         */

        function pageModel(data = {}) {
            const page = { };
            page.options = data.options;

            Object.keys(vm.LAYOUT_TYPES).forEach(key => {
                page[vm.LAYOUT_TYPES[key]] = {
                    layoutId: data[vm.LAYOUT_TYPES[key]] && data[vm.LAYOUT_TYPES[key]].layoutId || 1,
                    items: data[vm.LAYOUT_TYPES[key]] && itemsModel(data[vm.LAYOUT_TYPES[key]].items) || itemsModel(),
                }
            });

            return page;
        }

        /**
         * Creates items
         * @param data
         * @returns {object} Items
         */
        function itemsModel(data = {}) {
            const items = {};
            for (let i = 1; i <= DEFAULT_MODEL_ITEMS_COUNT; i++) {
                items[i] = modelItem(data[i]);
            }
            return items;
        }

        /**
         * Create item
         * @param item
         * @returns {{id: number|null, title: string, type: string|null}} Item
         */
        function modelItem(item) {
            const result = {
                id: item && item.id || null,
                title: item && item.title || '',
                type: item && item.type || null,
                isExternalApp: item && item.isExternalApp || null,
                default_lang: item && item.default_lang || vm.contentData.language_id,
            };
            if (item && item.isExternalApp) {
                result.resize = item.resize ? item.resize : RESIZE_TYPES.RESPONSIVE;
            }

            if (item && item.stations) {
                result.stations = item.stations;
            }

            return result;
        }

        /**
         * Determine is landscape layout
         * @param {string} layoutType Layout type
         * @returns {boolean} Is landscape layout
         */
        function isLandscapeLayout(layoutType) {
            return layoutType === vm.LAYOUT_TYPES.LANDSCAPE;
        }

        /**
         * Change layout
         * @param {string} layoutType Layout type
         * @param {string} action Action type
         */
        function changeLayout(layoutType, action) {
            let selectedId = vm.pageData.page[layoutType].layoutId;
            let layouts;

            switch (layoutType) {
                case vm.LAYOUT_TYPES.LANDSCAPE:
                    layouts = vm.LANDSCAPE_LAYOUTS;
                    break;
                case vm.LAYOUT_TYPES.PORTRAIT:
                    layouts = vm.PORTRAIT_LAYOUTS;
                    break;
                case vm.LAYOUT_TYPES.NARROW_LANDSCAPE:
                    layouts = vm.NARROW_LANDSCAPE_LAYOUTS;
                    break;
                default:
                    return;
            }

            if(action === vm.SELECT_LAYOUT_ACTIONS.NEXT) {
                selectedId = selectedId === layouts.length
                    ? layouts[0].layoutId
                    : selectedId + 1;
            } else {
                selectedId = selectedId === layouts[0].layoutId
                    ? layouts[layouts.length - 1].layoutId
                    : selectedId - 1;
            }

            vm.pageData.page[layoutType].layoutId = selectedId;
        }

        /**
         * Layout item callback
         * @param {object} $event Event
         * @param {number} id Item Id
         * @param {string} action Action type
         * @param {string} layoutType Layout type
         */
        function layoutItemCallback ($event, id, action, layoutType) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            const model = vm.pageData.page[layoutType].items;

            action === vm.ITEM_CALLBACK_OPTIONS.DELETE ? showDeletePopup(model, id) : showAddEditPopup(model, id);
        }

        /**
         * Copy items from oposite layout
         * @param {string} layoutType Layout type
         */
        function copyFromOpposite(layoutType) {
            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_COPY_CONTENT_FROM_OPPOSITE_LAYOUT',
                okButtonText: 'OK',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                const isLandscape = isLandscapeLayout(layoutType);
                const page = vm.pageData.page;
                const to = isLandscape ? page[vm.LAYOUT_TYPES.LANDSCAPE].items : page[vm.LAYOUT_TYPES.PORTRAIT].items;
                const from = !isLandscape ? page[vm.LAYOUT_TYPES.LANDSCAPE].items : page[vm.LAYOUT_TYPES.PORTRAIT].items;

                Object.assign(to, from);
            });
        }

        /**
         * Show delete confirmation popup
         * @param model
         * @param id
         */
        function showDeletePopup(model, id) {
            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CONTENT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }).then(() => {
                model[id] = modelItem();
            });
        }

        /**
         * Show add/edit popup
         * @param model
         * @param id
         */
        function showAddEditPopup(model, id) {
            PopupService.show({
                templateUrl: '/assets/views/common/popups/select-element/select-element.tpl.html',
                controller: 'SelectElementPopupController',
                controllerAs: '$ctrl',
                windowClass: 'select-element-popup',
                keyboard: false,
                resolve: {
                    data: () => ({
                        message: vm.contentData.message,
                        item: model[id],
                        langId: vm.contentData.language_id,
                    })
                }
            }).then(data => {
                model[id] = modelItem(data);
            });
        }

        /**
         * onFinish handler
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        /**
         * Save page listener
         */
        $scope.$on('digital-display-savepage', onFinish);
    }
}());

(function() {
    'use strict';

    angular.module('lineRoute')
        .component('lineRoute', {
            templateUrl: '/assets/views/line-route/line-route.tpl.html',
            controller: LineRouteController,
            bindings: {
                lineRouteSettings: '<',
                stopId: '<',
                isPreview: '<',
                timezoneId: '<',
                screenTracks: '<',
                isStopped: '<',
                tenantId: '<',
            }
        });

    function LineRouteController(
        $q,
        $scope,
        $element,
        $timeout,
        $interval,
        $window,
        LineRouteDateTimeService,
        LineRouteDataService,
        LINE_ROUTE_ORIENTATIONS,
        LAYOUTS,
    ) {
        const vm = this;

        const {
            toTimeString,
            getTimeDiff
        } = LineRouteDateTimeService;

        const {
            getDepartureTimes,
            getJourneyDetails,
            getPreviewData,
            filterDepartures,
            orderDepartures,
            checkDeparturesChanged,
            filterPreviousStations,
            processStationTime
        } = LineRouteDataService;

        const UPDATE_ROUTES_INTERVAL = 60000;

        vm.$onInit = init;

        // public methods
        vm.getCurrentTime = getCurrentTime;
        vm.isDouble = isDouble;
        vm.isVertical = isVertical;
        vm.messageVisible = messageVisible;
        vm.getClass = getClass;
        vm.isCanvasLayout = isCanvasLayout;
        vm.isHtmlLayout = isHtmlLayout;

        /**
         * Initialization method
         */
        function init() {
            vm.showEveryStationDeparture = vm.lineRouteSettings.line.everyStationDeparture;
            vm.parent = $element[0];
            vm.firstChild = $element[0].children[0];
            vm.orientation = getOrientation();
            vm.errorMessage = null;
            fillLineRouteData();
            setWatchers();
        }

        function getOrientation() {
            const width = vm.firstChild.offsetWidth;
            const height = vm.firstChild.offsetHeight;

            return width > height
                ? LINE_ROUTE_ORIENTATIONS.HORIZONTAL
                : LINE_ROUTE_ORIENTATIONS.VERTICAL;
        }

        function fillLineRouteData() {
            if (vm.isPreview) {
                vm.departuresDetails = getPreviewData(vm.lineRouteSettings.line.stationsNumber);
                vm.footerMessage = 'Test footer message';
                return;
            }

            if (!vm.stopId) {
                throw new Error('StopId is missing');
            }

            getDeparturesData(vm.stopId);
            vm.updateRoutesInterval = $interval(
                () => getDeparturesData(vm.stopId),
                UPDATE_ROUTES_INTERVAL
            );
        }

        function getDeparturesData(stopId) {
            getDepartureTimes(stopId, vm.tenantId)
                .then(response => {
                    vm.allDepartures = response.data.Departure;
                    vm.filteredDepartures = filterDepartures(vm.allDepartures, vm.timezoneId, vm.screenTracks,
                        vm.lineRouteSettings.transTypes);

                    if (!vm.filteredDepartures.length) {
                        vm.errorMessage = 'No departures in the near future';
                        return;
                    }

                    vm.filteredDepartures = orderDepartures(vm.filteredDepartures);
                    prepareDeparturesData();
                    vm.errorMessage = '';
                })
                .catch(error => {
                    console.error(error);
                    processError();
                });
        }

        /**
         * Process error if new data is not received
         */
        function processError() {
            if (!_.isEmpty(vm.departuresDetails)) {
                vm.departuresDetails = vm.departuresDetails.filter(detail => {
                    detail.times = detail.times.filter(time => getTimeDiff(time) > 0);
                    return detail.times.length;
                });
                // If some of requests is gone - disable "every station departure"
                vm.lineRouteSettings.line.everyStationDeparture = false;
            }

            if (_.isEmpty(vm.departuresDetails)) {
                vm.errorMessage = 'Sorry, we have trouble with the data connection';
            }
        }

        /**
         * Processing departures data
         */
        function prepareDeparturesData() {
            const SINGLE_LAYOUT_DEPARTURES_COUNT = 1;
            const DOUBLE_LAYOUT_DEPARTURES_COUNT = 2;
            const departuresCount = vm.lineRouteSettings[vm.orientation].doubleLayout ?
                DOUBLE_LAYOUT_DEPARTURES_COUNT :
                SINGLE_LAYOUT_DEPARTURES_COUNT;
            const departuresDetails = angular.copy(vm.filteredDepartures.slice(0, departuresCount));
            fillStationsData(departuresDetails);
        }

        /**
         * Get stations for nearest departures
         * @param departures
         */
        function fillStationsData(departures) {
            let promises = departures.map(departure => {
                if (!departure.stations) {
                    return getJourneyDetails(departure.JourneyDetailRef.ref, vm.tenantId)
                        .then(response => {
                            departure.stations = filterPreviousStations(response.data.Stops.Stop, departure);
                            departure.stations.map(station => {
                                station.name = station.name.replace('(Berlin)', '');
                                processStationTime(station, vm.timezoneId);
                            });
                        });
                }
            });
            $q.all(promises).then(() => {
                vm.departuresDetails = departures;
                // Return station time setting to it's default value (can be forced to false on data receive error)
                vm.lineRouteSettings.line.everyStationDeparture = vm.showEveryStationDeparture;
            }).catch(processError);
        }

        /**
         * Checks if current layout is double
         * @return {*}
         */
        function isDouble() {
            return !!(vm.lineRouteSettings[vm.orientation].doubleLayout &&
                vm.departuresDetails && vm.departuresDetails[1]);            // if there are details of second departure
        }

        /**
         * Checks if orientation is vertical
         * @return {boolean}
         */
        function isVertical() {
            return vm.orientation === LINE_ROUTE_ORIENTATIONS.VERTICAL;
        }

        /**
         * Checks if message in footer should be visible
         * @return {*}
         */
        function messageVisible() {
            return vm.lineRouteSettings.footer.showMessages && vm.footerMessage;
        }

        /**
         * Returns current time string
         * @return {string}
         */
        function getCurrentTime() {
            const currentDate = new Date;
            return toTimeString(currentDate);
        }

        /**
         * On resize handler
         */
        function onResize() {
            const newOrientation = getOrientation();
            if (newOrientation !== vm.orientation) {
                vm.orientation = newOrientation;
                prepareDeparturesData();
                $scope.$digest();
            }
        }

        /**
         * Scope destroy listener
         */
        function destroy() {
            angular.element($window).off('resize', onResize);
            $interval.cancel(vm.updateRoutesInterval);
        }

        /**
         * Get class for wrapper
         *
         * @returns {string|boolean}
         */
        function getClass() {
            const layout = vm.lineRouteSettings.layout;
            switch (layout) {
                case LAYOUTS.HTML_DARK:
                    return 'layout-html';
                case LAYOUTS.HTML_LIGHT:
                    return 'layout-html-light';
                case LAYOUTS.CANVAS:
                default:
                    return 'layout-canvas';
            }
        }

        /**
         * Checks if layout is canvas (also if layout is not set)
         *
         * @returns {boolean}
         */
        function isCanvasLayout() {
            const layouts = [undefined, LAYOUTS.CANVAS];
            return layouts.includes(vm.lineRouteSettings.layout);
        }

        /**
         * Checks if layout is HTML
         *
         * @returns {boolean}
         */
        function isHtmlLayout() {
            const layouts = [LAYOUTS.HTML_LIGHT, LAYOUTS.HTML_DARK];
            return layouts.includes(vm.lineRouteSettings.layout);
        }

        /**
         * Sets watchers
         */
        function setWatchers() {
            if (vm.isPreview) {
                $scope.$watch(
                    () => vm.lineRouteSettings.line.stationsNumber,
                    () => fillLineRouteData()
                );

                $scope.$watchGroup([
                    () => vm.parent.clientWidth,
                    () => vm.parent.clientHeight
                ], () => vm.orientation = getOrientation());
            }
        }

        /**
         * Event listeners
         */
        angular.element($window).bind('resize', onResize);
        $scope.$on('$destroy', destroy);
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('playlistInfoElementPicker', {
            templateUrl: '/assets/views/common/components/playlist-info-element-picker/playlist-info-element-picker.tpl.html',
            controller: PlaylistInfoElementPickerCtrl,
            bindings: {
                contentForm: '=',
                contentData: '=',
                pageData: '<',
                item: '=?item'
            }
        });

    function PlaylistInfoElementPickerCtrl(
        $scope,
        ContentHelper,
        ContentDataService,
        UtilitiesService,
        PermissionsService,
    ) {
        const vm = this;
        const { plainResponse } = UtilitiesService;
        const {
            filterContentListByDefaultContentGroup,
            decodeContent
        } = ContentHelper;
        const {
            filterPlaylistInteractiveContentList,
            isContentGroupMenuItemVisible
        } = PermissionsService;

        vm.getMessage = getMessage;
        vm.getTitle = ContentHelper.getDefaultTitle;

        vm.$onInit = init;

        /**
         * Initialization method
         */
        function init() {
            vm.currentLang = vm.contentData.language_id;
            loadIntenralAppContent();
        }

        /**
         * Loads internal application content
         */
        function loadIntenralAppContent() {
            ContentDataService.getContentForInternalApp()
                .then(response => {
                    let contentList = plainResponse(response) || [];
                    contentList = filterPlaylistInteractiveContentList(contentList);
                    if (!isContentGroupMenuItemVisible()) {
                        contentList = filterContentListByDefaultContentGroup(contentList);
                    }

                    vm.infotainmentContent = contentList.map(decodeContent);
                    const selectedContent = vm.item && vm.infotainmentContent.find(content =>
                        content.id === vm.item.contentId
                    );

                    if (!!selectedContent) {
                        vm.item_id = selectedContent.id;
                    }
                })
                .catch((err) => { console.error(err); });
        }

        /**
         * Returns message for current lang
         * @param {Object} item
         * @return {String}
         */
        function getMessage(item) {
            if (!item.message) {
                return '';
            }
            return item.message.message
                ? (item.message.message[vm.currentLang] || item.message.message[item.language_id])
                : (item.message[vm.currentLang] || item.message[item.language_id]);
        }

        $scope.$watch(() => vm.item_id, () => {
            if (vm.item_id && vm.infotainmentContent) {
                vm.item = vm.infotainmentContent.find(item => item.id === vm.item_id);
                vm.pageData.page.item = vm.item;
            }
        });
    }
})();
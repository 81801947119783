(function() {
    'use strict';

    angular.module('beacon.app')
        .service('UserGroupsDataService', UserGroupsDataService);

    function UserGroupsDataService(
        Restangular,
    ) {

        return {
            getUserGroups,
            createUserGroup,
            deleteUserGroup,
        };

        function getUserGroups() {
            return Restangular.all('user-groups').getList();
        }

        function createUserGroup(data) {
            return Restangular.all('user-groups').post(data);
        }

        function deleteUserGroup(id) {
            return Restangular.one('user-groups', id).remove();
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('SignupController', SignupController);

    function SignupController($scope, $state, LoginService, ERROR_CODES) {
        const vm = this;

        // public properties
        vm.data = {};

        // public methods
        vm.signup = signup;
        vm.resetUsernameValidity = resetUsernameValidity;
        vm.resetFirstnameValidity = resetFirstnameValidity;
        vm.resetLastnameValidity = resetLastnameValidity;
        vm.resetEmailValidity = resetEmailValidity;
        vm.resetTenantValidity = resetTenantValidity;

        /**
         * Resets username validation state
         */
        function resetUsernameValidity() {
            $scope.signupForm.username.$setValidity('serverValidation', true);
        }

        /**
         * Resets firstname validation state
         */
        function resetFirstnameValidity() {
            $scope.signupForm.firstname.$setValidity('serverValidation', true);
        }

        /**
         * Resets lastname validation state
         */
        function resetLastnameValidity() {
            $scope.signupForm.lastname.$setValidity('serverValidation', true);
        }

        /**
         * Resets email validation state
         */
        function resetEmailValidity() {
            $scope.signupForm.email.$setValidity('serverValidation', true);
        }

        /**
         * Resets tenant id validation state
         */
        function resetTenantValidity() {
            $scope.signupForm.tenant.$setValidity('serverValidation', true);
        }

        /**
         * Creates new user
         */
        function signup() {
            LoginService.signup(vm.data, function() {
                $state.go('login', { message: 'ACCOUNT_WAS_SUCCESSFULLY_CREATED' });
            }, function(response) {
                switch (response.data.errorCode) {
                    case ERROR_CODES.ERR_INVALID_EMAIL:
                        $scope.signupForm.email.$setValidity('serverValidation', false);
                        break;
                    case ERROR_CODES.ERR_INVALID_NAME:
                        $scope.signupForm.username.$setValidity('serverValidation', false);
                        break;
                    case ERROR_CODES.ERR_INVALID_TENANT_ID:
                        $scope.signupForm.tenant.$setValidity('serverValidation', false);
                        break;
                }
            });
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('geofenceAddState', function() {
            return {
                views: {
                    GEOFENCE_DETAILS: 0,
                    GEOFENCE_GROUPS: 1,
                }
            };
        })
        .controller('LocationController', LocationController);

    function LocationController(
        $scope,
        locationTabState,
    ) {

        const vm = this;
        vm.state = locationTabState;

        // bindings for sub controllers
        vm.selectedLocation = false;
        vm.selectedGeofences = false;
        vm.selectedSegment = false;
        vm.selectedLocationName = '';

        // public methods
        vm.clearSelectedValues = clearSelectedValues;


        /**
         * Clears selected values
         *
         * @param {number} currentTab
         */
        function clearSelectedValues(currentTab) {
            if (currentTab === vm.state.views.LOCATIONS.index) {
                    vm.selectedGeofences = false;
            }
        }

        $scope.$watch(angular.bind(vm, function() {
            return this.state.view;
        }), function(newValue, oldValue) {
            vm.clearSelectedValues(newValue);
        });
    }
}());

(function () {
    'use strict';

    angular.module('beacon.app')
            .directive('geofenceMapViewV2', geofenceMapViewV2);

    function geofenceMapViewV2() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/geofence-map-view-v2/geofence-map-view-v2.tpl.html',
            replace: true,
            controller: GeofenceMapViewControllerV2,
            controllerAs: 'geofenceMapView',
            bindToController: true,
            scope: {
                /**
                 * @type {string|MapShapeV2[]} - old and new format is supported
                 */
                data: '=geofenceMapViewV2', // Use "LocationHelper.coloredArea" to make colored shapes
                createdShapeColor: '<',
                /**
                 * @type {function<string, MapShapeV2[]>} - old and new format is supported
                 */
                updatePolyline: '=',
                editable: '<',
                mapClickCallback: '<',
                markers: '<',
                markerClickCallback: '=',
                disableSelect: '<',
                disableClustering: '<',
                mapDefaultCenter: '<'
            }
        };
    }

    function GeofenceMapViewControllerV2(
        $scope,
        $timeout,
        ModelsFactory,
        UserUtilitiesService,
        UtilitiesService,
        $translate,
    ) {
        const vm = this;

        const { MapOptions } = ModelsFactory;

        const DEFAULT_ZOOM_VALUE = 16;
        const POINTS_IN_CIRCLE = 12;
        const POINTS_IN_RECTANGLE = 4;
        const MIN_POINTS_IN_POLYGON = 3;
        const MAPS = google.maps;
        const MAX_ZOOM = 20;
        const round = (number, precision = 5) => UtilitiesService.toPrecision(number, precision);

        let map = null;
        let infoWindow = null;


        let shapes = {};

        let selectedShape;
        const allMarkers = [];
        let markerCluster;
        let shapeCoords = [];

        let drawingManager;
        let deleteButton;

        vm.$onInit = init;

        /**
         * Initialization method
         */
        function init() {
            const positionOptions = UserUtilitiesService.userLocationOptions();
            if (!positionOptions.maxZoom) {
                positionOptions.maxZoom = MAX_ZOOM;
            }
            if (vm.mapDefaultCenter) {
                const coordinates = UtilitiesService.getCoordinates(vm.mapDefaultCenter);
                Object.assign(positionOptions, coordinates);
            }
            const mapOptions = new MapOptions(positionOptions);

            $timeout(() => {
                const mapElement = document.getElementById('map_' + $scope.$id);
                map = new MAPS.Map(mapElement, mapOptions);
                drawShapes(vm.data, map);

                if (!!vm.markers && !!vm.markers.length) {
                    drawMarkers(vm.markers, map);
                }
                if (!vm.editable) {
                    $scope.$watch(
                        () => vm.data,
                        () => drawShapes(vm.data, map),
                    );

                    $scope.$watch(
                        () => vm.markers,
                        /**
                         * @param {MapEditorMarker[]} markers
                         */
                        markers => {
                            clearMarkers();
                            if (markers && !!markers.length) {
                                drawMarkers(markers, map);
                            }
                        }
                    );
                } else {
                    MAPS.event.addListener(map, 'click', function (event) {
                        if (angular.isFunction(vm.mapClickCallback)) {
                            vm.mapClickCallback(event);
                        }
                        clearSelection();
                    });
                    $scope.$watch(
                        () => vm.markers,
                        /**
                         * @param {MapEditorMarker[]} markersNew
                         * @param {MapEditorMarker[]} markersOld
                         */
                        (markersNew, markersOld) => {
                            if (markersNew && markersOld &&
                                (markersNew.length !== markersOld.length ||
                                    markersOld.every(marker => !markersNew.find(
                                        newMarker =>
                                            marker.locationId === newMarker.locationId
                                    )))) {
                                clearMarkers();
                                markersNew.length && drawMarkers(markersNew, map);
                                return;
                            }
                            const changedMarkers = _.differenceWith(markersNew, markersOld, _.isEqual);
                            if (changedMarkers.length && allMarkers.length) {
                                updateChangedMarkers(changedMarkers);
                            }
                        }, true);

                    infoWindow = new MAPS.InfoWindow();

                    drawingManager = new MAPS.drawing.DrawingManager({
                        drawingControl: vm.editable,
                        drawingControlOptions: {
                            position: MAPS.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                MAPS.drawing.OverlayType.CIRCLE,
                                MAPS.drawing.OverlayType.POLYGON,
                                MAPS.drawing.OverlayType.RECTANGLE,
                            ]
                        },
                        circleOptions: new CircleOptions(vm.createdShapeColor),
                        polygonOptions: new PolygonOptions(vm.createdShapeColor),
                        rectangleOptions: new RectangleOptions(vm.createdShapeColor),
                    });

                    drawingManager.setMap(map);

                    MAPS.event.addListener(drawingManager, 'overlaycomplete', event => onAddShape(event));

                    $translate('DELETE_SELECTED_SHAPE').then(btnText => {
                        deleteButton = generateButton(btnText, deleteSelectedShape);
                        map.controls[MAPS.ControlPosition.BOTTOM_CENTER].push(deleteButton);
                        hideActionButtons();
                    });

                    // for old car parks
                    // if only old polygon was filled - update also new format data
                    returnCallback();
                }

                MAPS.event.addListener(map, 'zoom_changed', function() {
                    const zoomChangeBoundsListener =
                        MAPS.event.addListener(map, 'bounds_changed', function() {
                            if (this.getZoom() > DEFAULT_ZOOM_VALUE && this.initialZoom === true) {
                                // Change max/min zoom here
                                this.setZoom(DEFAULT_ZOOM_VALUE);
                                this.initialZoom = false;
                            }
                            MAPS.event.removeListener(zoomChangeBoundsListener);
                        });
                });
                map.initialZoom = true;
            }, 0);

            bindWatchers();
        }

        /**
         * Prepare result and return it to the parent component
         *
         * @return {void}
         */
        function returnCallback() {
            /**
             * New format of data
             * @type {MapShapeV2[]}
             */
            const resultsV2 = [];
            _.forEach(shapes, shape => {
                const path = getPath(shape);
                const parent = resultsV2.find(possibleParent => isInsideShape(possibleParent.area, path));
                if (parent) {
                    parent.holes.push(path);
                } else {
                    resultsV2.push({
                        area: path,
                        holes: [],
                    });
                }
            });

            /**
             * Old format of data
             * @type {string[]}
             */
            const result = [];
            _.forEach(shapes, shape => {
                switch (shape.type) {
                    case "circle":
                        result.push(getCircleEncodedPath(shape));
                        break;
                    case "rectangle":
                        result.push(getRectangleEncodedPath(shape));
                        break;
                    case "polygon":
                        result.push(getPolygonEncodedPath(shape));
                }
            });
            vm.data = result.join("|");

            if (_.isFunction(vm.updatePolyline)) {
                vm.updatePolyline(vm.data, resultsV2);
            }
        }

        function ShapeOptions(color) {
            this.clickable = vm.editable;
            this.draggable = vm.editable;
            this.editable = vm.editable;
            this.fillColor = color || '#BBB';
            this.strokeColor = color || '#000';
            this.strokeWeight = 0.5;
            this.fillOpacity = 0.2;
        }

        function CircleOptions(color) {
            Object.assign(this, new ShapeOptions(color));
        }

        function PolygonOptions(color){
            Object.assign(this, new ShapeOptions(color));
        }

        function RectangleOptions(color) {
            Object.assign(this, new ShapeOptions(color));
        }

        /**
         *
         * @param {string} title
         * @param {function} callback - on click callback
         * @return {HTMLDivElement}
         */
        function generateButton(title, callback) {
            const controlDiv = document.createElement('div');
            controlDiv.style.padding = '0 5px';

            // Set CSS for the control border.
            let controlUI = document.createElement('div');
            controlUI.style.backgroundColor = '#fff';
            controlUI.style.border = '2px solid #fff';
            controlUI.style.borderRadius = '3px';
            controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
            controlUI.style.cursor = 'pointer';
            controlUI.style.marginBottom = '22px';
            controlUI.style.textAlign = 'center';
            controlDiv.appendChild(controlUI);

            // Set CSS for the control interior.
            let controlText = document.createElement('div');
            controlText.style.color = 'rgb(25,25,25)';
            controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
            controlText.style.fontSize = '16px';
            controlText.style.lineHeight = '38px';
            controlText.style.paddingLeft = '5px';
            controlText.style.paddingRight = '5px';
            controlText.innerHTML = title;
            controlUI.appendChild(controlText);

            controlUI.addEventListener('click', function () {
                callback();
            });

            return controlDiv;
        }

        /**
         *
         * @param {google.maps.Polygon|google.maps.Rectangle|google.maps.Circle} shape
         * @param {string} shape.type
         * @return {google.maps.LatLngLiteral[]}
         */
        function getPath(shape) {
            switch (shape.type) {
                case "circle":
                    return getCirclePath(shape);
                case "rectangle":
                    return getRectanglePath(shape);
                case "polygon":
                    return getPolygonPath(shape);
            }
        }

        /**
         * @param {google.maps.Circle} shape
         * @return {google.maps.LatLngLiteral[]}
         */
        function getCirclePath(shape) {
            let coords = [];
            let degreeStep = 360 / POINTS_IN_CIRCLE;
            for (let i = 0; i < POINTS_IN_CIRCLE; i++) {
                const latLng = MAPS.geometry.spherical.computeOffset(
                    shape.getCenter(),
                    Math.round(shape.getRadius()),
                    degreeStep * i
                );
                coords.push({
                    lat: round(latLng.lat(), 6),
                    lng: round(latLng.lng(), 6),
                });
            }
            return coords;
        }

        /**
         * Convert Circle object to string format
         *
         * @param {google.maps.Circle} shape
         * @return {string}
         */
        function getCircleEncodedPath(shape) {
            return getCirclePath(shape).map(point => {
                return [point.lat, point.lng].join();
            }).join(';');
        }

        /**
         * @param {google.maps.Rectangle} shape
         * @return {google.maps.LatLngLiteral[]}
         */
        function getRectanglePath(shape) {
            const path = [];
            const bounds = shape.getBounds();
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            path.push({
                lat: round(northEast.lat()),
                lng: round(southWest.lng())
            });
            path.push({
                lat: round(northEast.lat()),
                lng: round(northEast.lng())
            });
            path.push({
                lat: round(southWest.lat()),
                lng: round(northEast.lng())
            });
            path.push({
                lat: round(southWest.lat()),
                lng: round(southWest.lng())
            });
            return path;
        }

        /**
         * Convert Rectangle object to string format
         *
         * @param {google.maps.Rectangle} shape
         * @returns {string}
         */
        function getRectangleEncodedPath(shape) {
            return getRectanglePath(shape).map(point => {
                return [point.lat, point.lng].join();
            }).join(';');
        }

        /**
         * Convert Polygon object to string format
         *
         * @param {google.maps.Polygon} shape
         * @returns {google.maps.LatLngLiteral[]}
         */
        function getPolygonPath(shape) {
            const path = shape.getPath();
            const result = [];
            path.forEach(point => {
                result.push({
                    lat: round(point.lat()),
                    lng: round(point.lng()),
                });
            })
            return result;
        }

        /**
         * Convert Polygon object to string format
         *
         * @param {google.maps.Polygon} shape
         * @returns {string}
         */
        function getPolygonEncodedPath(shape) {
            return getPolygonPath(shape).map(point => {
                return [point.lat, point.lng].join();
            }).join(';');
        }

        /**
         * Creates and returns circle object
         *
         * @param {google.maps.LatLng[]} coords
         * @param {string} color
         * @return {google.maps.Circle}
         */
        function getCircle(coords, color = '#333') {
            let shape = null;
            if (coords.length < POINTS_IN_CIRCLE || coords.length % 2) {
                return shape;
            }
            let center = MAPS.geometry.spherical.interpolate(coords[0], coords[coords.length / 2], 0.5);
            let radius = Math.round(MAPS.geometry.spherical.computeDistanceBetween(coords[0], center));
            for (let i=1; i < coords.length; i++) {
                if (i === coords.length / 2) {
                    continue;
                }
                if (Math.round(MAPS.geometry.spherical.computeDistanceBetween(coords[i], center)) !== radius) {
                    return shape;
                }
            }
            let options = new CircleOptions(color);
            options.radius = radius;
            options.center = center;
            shape = new MAPS.Circle(options);
            shape.type = "circle";
            return shape;
        }

        /**
         * Creates and returns rectangle object
         *
         * @param {google.maps.LatLng[]} coords
         * @param {string} color
         * @return {google.maps.Rectangle}
         */
        function getRectangle(coords, color = '#333') {
            let shape = null;
            let isRectangle = coords.length === POINTS_IN_RECTANGLE
                    && coords[0].lat() === coords[1].lat()
                    && coords[1].lng() === coords[2].lng()
                    && coords[2].lat() === coords[3].lat()
                    && coords[3].lng() === coords[0].lng();
            if (!isRectangle) {
                return shape;
            }
            let options = new RectangleOptions(color);
            options.bounds = new MAPS.LatLngBounds(coords[3], coords[1]);
            shape = new MAPS.Rectangle(options);
            shape.type = "rectangle";
            return shape;
        }

        /**
         * Creates and returns polygon object
         *
         * @param {google.maps.LatLng[]} coords
         * @param {string} color
         * @return {google.maps.Polygon}
         */
        function getPolygon(coords, color = '#333') {
            let shape = null;
            if (coords.length < MIN_POINTS_IN_POLYGON) {
                return shape;
            }
            let options = new PolygonOptions(color);
            options.paths = coords;
            shape = new MAPS.Polygon(options);
            shape.type = "polygon";
            return shape;
        }

        /**
         * Draws shapes from the database string format
         *
         * @param {string|MapShapeV2[]} data
         * @param {google.maps.Map} map
         * @return {void}
         */
        function drawShapes(data, map) {
            if (!data.length || !_.isObject(map)) {
                return;
            }

            deleteAllShapes();

            _.isArray(data) && data.length
                ? drawNewFormat(data, map)
                : drawDeprecatedFormat(data, map);

            /**
             *
             * @param {MapShapeV2[]} data
             * @param {google.maps.Map} map
             */
            function drawNewFormat(data, map) {
                const bounds = new MAPS.LatLngBounds();
                const figuresAll = [];
                const toLatLng = points => points.map(point => new google.maps.LatLng(point));

                data.forEach(area => {
                    figuresAll.push(toLatLng(area.area));
                    area.holes.forEach(holePoints => {
                        figuresAll.push(toLatLng(holePoints));
                    })
                });

                figuresAll.forEach(points => {
                    points.forEach(point => {
                        bounds.extend(point);
                    })

                    const shape = getCircle(points)
                        || getRectangle(points)
                        || getPolygon(points);

                    shape.setMap(map);
                    saveShapeAndBindEventListeners(shape);
                    if (vm.editable) {
                        shape.setEditable(false);
                    }
                });
                map.fitBounds(bounds);
            }

            /**
             * @param {string} data
             * @param {google.maps.Map} map
             */
            function drawDeprecatedFormat(data, map) {
                let viewBounds = new MAPS.LatLngBounds();
                _.forEach(data.split("|"),
                    coordsItems => {
                        const [coordsString, color] = coordsItems.split(':');
                        shapeCoords = _.map(
                            coordsString.split(";"),
                            latLongString => {
                                let latLong = latLongString.split(",")
                                    .map(coord => parseFloat(coord));
                                let coordinates = new MAPS.LatLng(latLong[0], latLong[1]);
                                viewBounds.extend(coordinates);
                                return coordinates;
                            }
                        );
                        let shape = getCircle(shapeCoords, color) || getRectangle(shapeCoords, color) || getPolygon(shapeCoords, color);

                        if (shape) {
                            shape.setMap(map);
                            saveShapeAndBindEventListeners(shape);
                            if (vm.editable) {
                                shape.setEditable(false);
                            }
                        }
                    }
                );
                map.fitBounds(viewBounds);
            }
        }

        /**
         * Puts markers on the map from the markers array
         *
         * @param {MapEditorMarker[]} markers
         * @param {google.maps.Map} map
         * @returns {void}
         */
        function drawMarkers(markers, map) {
            const viewBounds = new MAPS.LatLngBounds();
            const markerIconSize = {
                width: 26,
                height: 42
            };
            const markerAnchor = [markerIconSize.width / 2, markerIconSize.height];
            markers.forEach(marker => {
                const newMarker = new MAPS.Marker({
                    position: marker.position,
                    label: marker.label,
                    map
                });
                const image = {
                    url: '/assets/images/bluemapicon.png',
                    scaledSize: new google.maps.Size(markerIconSize.width, markerIconSize.height),
                    anchor: new google.maps.Point(markerAnchor[0], markerAnchor[1])
                };
                if (marker.selected) {
                    newMarker.setIcon(image);
                }
                newMarker.locationId = marker.locationId;
                newMarker.selected = marker.selected;

                newMarker.addListener('click', () => {
                    if (vm.markerClickCallback && angular.isFunction(vm.markerClickCallback)) {
                        if (!vm.disableSelect) {
                            !newMarker.selected ? newMarker.setIcon(image) : newMarker.setIcon();
                            marker.selected = newMarker.selected = !newMarker.selected;
                        }
                        vm.markerClickCallback(marker);
                    }
                });
                allMarkers.push(newMarker);
                const coordinates = new MAPS.LatLng(marker.position.lat, marker.position.lng);
                viewBounds.extend(coordinates);
            });

            if (!vm.disableClustering) {
                markerCluster = new MarkerClusterer(map, allMarkers,
                    {imagePath: '/assets/images/m'});
            }

            // TODO Remove this temporary workaround
            // find better solution
            if (!angular.isFunction(vm.mapClickCallback)) {
                shapeCoords && shapeCoords.forEach(coords => {
                    viewBounds.extend(coords)
                });
                map.fitBounds(viewBounds);
            }
        }

        /**
         * Removes all markers
         */
        function clearMarkers() {
            allMarkers.forEach(marker => {
                marker.setMap(null);
            });
            allMarkers.splice(0, allMarkers.length);
            if (!!markerCluster) {
                markerCluster.clearMarkers();
            }
        }

        /**
         * Updates icon for selected or deselected markers property
         * @param {array} changedMarkers
         */
        function updateChangedMarkers(changedMarkers) {
            const markerIconSize = {
                width: 26,
                height: 42
            };
            const markerAnchor = [markerIconSize.width / 2, markerIconSize.height];
            const image = {
                url: '/assets/images/bluemapicon.png',
                scaledSize: new google.maps.Size(markerIconSize.width, markerIconSize.height),
                anchor: new google.maps.Point(markerAnchor[0], markerAnchor[1])
            };
            changedMarkers.forEach(changedMarker => {
                const currentMarker = allMarkers.find(marker => {
                    return marker.locationId === changedMarker.locationId;
                });
                if (!_.isEmpty(currentMarker)) {
                    if (changedMarker.selected) {
                        currentMarker.setIcon(image);
                        currentMarker.selected = true;
                    } else {
                        currentMarker.selected = false;
                        currentMarker.setIcon();
                    }
                }
            })
        }

        /**
         * @param {google.maps.LatLng} position
         * @param {string | Node} content
         */
        function showInfoWindow (position, content) {
            infoWindow.setPosition(position);
            infoWindow.setContent(content);
            infoWindow.open(map);
        }

        /**
         * @param {google.maps.Circle} shape
         */
        function showCircleInfo(shape) {
            let position = shape.getCenter();
            let content = "Radius: " + Math.round(shape.getRadius()) + "m";
            showInfoWindow(position, content);
        }

        /**
         * @param {google.maps.Rectangle} shape
         */
        function showRectangleInfo(shape) {
            let bounds = shape.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();
            let northWest = new MAPS.LatLng(northEast.lat(), southWest.lng());
            let height = Math.round(MAPS.geometry.spherical.computeDistanceBetween(southWest, northWest));
            let width = Math.round(MAPS.geometry.spherical.computeDistanceBetween(northEast, northWest));
            let position = MAPS.geometry.spherical.interpolate(northEast, northWest, 0.5);
            let content = "Height: " + height + "m<br/>Width: " + width + "m";
            showInfoWindow(position, content);
        }

        let timeoutPromise = null;

        /**
         * @param {google.maps.Polygon|google.maps.Rectangle|google.maps.Circle} shape
         */
        function saveShapeAndBindEventListeners(shape) {
            shape.id = _.uniqueId();
            shapes[shape.id] = shape;

            if (angular.isFunction(vm.mapClickCallback)) {
                MAPS.event.addListener(shape, 'click', function (event) {
                    vm.mapClickCallback(event);
                });
            }

            MAPS.event.addListener(shape, 'mouseup', function () {
                onShapeClick(shape);
            });
            switch (shape.type) {
                case "circle":
                    MAPS.event.addListener(shape, "center_changed", () => {
                        infoWindow.close();
                        $timeout.cancel(timeoutPromise);
                        timeoutPromise = $timeout(_.partialRight(showCircleInfo, shape), 200);
                        returnCallback();
                    });
                    MAPS.event.addListener(shape, "radius_changed", () => {
                        showCircleInfo(shape);
                        returnCallback();
                    });
                    break;
                case "rectangle":
                   MAPS.event.addListener(shape, "bounds_changed", () => {
                        infoWindow.close();
                        $timeout.cancel(timeoutPromise);
                        timeoutPromise = $timeout(_.partialRight(showRectangleInfo, shape), 200);
                        returnCallback();
                    });
                    break;
                case "polygon":
                    MAPS.event.addListener(shape, 'dragend', returnCallback);
                    MAPS.event.addListener(shape.getPath(), 'insert_at', returnCallback);
                    MAPS.event.addListener(shape.getPath(), 'remove_at', returnCallback);
                    MAPS.event.addListener(shape.getPath(), 'set_at', returnCallback);
            }
        }

        /**
         * @param {google.maps.OverlayCompleteEvent} event
         */
        function onAddShape(event) {
            drawingManager.setDrawingMode(null);
            let newShape = event.overlay;
            newShape.type = event.type;

            saveShapeAndBindEventListeners(newShape);
            onShapeClick(newShape);
            returnCallback();
        }

        /**
         *
         * @param {google.maps.LatLngLiteral[]} parent
         * @param {google.maps.LatLngLiteral[]} child
         */
        function isInsideShape(parent, child) {
            const polygon = new google.maps.Polygon({
                paths: parent,
            });

            const somePointOutside = child.find(
                point => !google.maps.geometry.poly.containsLocation(
                    new google.maps.LatLng(point),
                    polygon,
                ));

            return !somePointOutside;
        }

        /**
         * @param {google.maps.Polygon|google.maps.Rectangle|google.maps.Circle} shape
         */
        function onShapeClick(shape) {
            clearSelection();
            selectedShape = shape;
            selectedShape.setEditable(true);

            switch (shape.type) {
                case "circle":
                    $timeout.cancel(timeoutPromise);
                    showCircleInfo(shape);
                    break;
                case "rectangle":
                    $timeout.cancel(timeoutPromise);
                    showRectangleInfo(shape);
            }
            showActionButtons();
        }

        function clearSelection() {
            if (selectedShape) {
                infoWindow.close();
                selectedShape.setEditable(false);
                selectedShape = null;
            }
            hideActionButtons();
        }

        function deleteSelectedShape() {
            if (infoWindow && _.isFunction(infoWindow.close)) {
                infoWindow.close();
            }
            if (selectedShape) {
                selectedShape.setMap(null);
                MAPS.event.clearInstanceListeners(selectedShape);
                delete shapes[selectedShape.id];
                returnCallback();
            }
            hideActionButtons();
        }

        function deleteAllShapes() {
            if (infoWindow && _.isFunction(infoWindow.close)) {
                infoWindow.close();
            }
            _.forEach(shapes, (shape) => {
                shape.setMap(null);
                MAPS.event.clearInstanceListeners(shape);
                delete shapes[shape.id];
            });
            hideActionButtons();
        }

        function showActionButtons() {
            if (deleteButton) {
                deleteButton.style.opacity = '1';
            }
        }

        function hideActionButtons() {
            if (deleteButton) {
                deleteButton.style.opacity = '0';
            }
        }

        /**
         * Bind watchers
         */
        function bindWatchers() {
            $scope.$watch(
                () => vm.markers,
                val => val && (val.length === 1) && map && (map.initialZoom = true)
            );

            $scope.$watch(
                () => vm.createdShapeColor,
                color => {
                    drawingManager && drawingManager.setOptions({
                        circleOptions: new CircleOptions(color),
                        polygonOptions: new PolygonOptions(color),
                        rectangleOptions: new RectangleOptions(color),
                    });
                }
            );
        }
    }
}());

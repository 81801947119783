(function () {
    'use strict';

    angular.module('beacon.app')
        .component('timetableHafasResponsive', {
            templateUrl:
                '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-responsive/timetable-hafas-responsive.tpl.html',
            controller: TimetableHafasResponsive,
            bindings: {
                content: '=',
                form: '<',
            }
        });

    function TimetableHafasResponsive() { }

})();

(function () {
    'use strict';

    angular.module('beacon.app')
        .component('capReservationsTab', {
            templateUrl: '/assets/views/share-park/operations/reservations/tabs/reservation-tab/reservations.tpl.html',
            controller: CapReservationsTabTabController,
            bindings: {
                selectedCarParks: '<',
            }
        });

    function CapReservationsTabTabController(
        $timeout,
        ShareParkReservationsService,
        StorageFactory,
        RESERVATION_MODES,
        ShareParkDataService,
        ShareParkHelperService,
        $translate,
        LanguageService
    ) {
        const vm = this;
        const { decodeCarParkData } = ShareParkHelperService;
        const MainStorage = StorageFactory.Storage('Main');
        const PRIMARY_COLOR_INDEX = 0;
        const FONT_COLOR_INDEX = 3;

        vm.RESERVATION_MODES = RESERVATION_MODES;

        vm.date = {
            start: {
                value: moment().subtract(1, 'd'),
            },
            end: {
                isRendered: true,
                min: null,
                max: null,
                value: moment(),
                _maxDateRangeDays: 32,
                _restrictValueInRange: function () {
                    // If current "end" date is after the "max" allowed date, we update "end" date to be the new "max".
                    // New "max" date is updated before this condition in setMinMax and is relative to the "start" date
                    if (this.value.isAfter(this.max.clone().add(this._maxDateRangeDays, 'd'))) {
                        this.value = this.max.clone();

                        return;
                    }

                    const start = vm.date.start.value;

                    // As "start" date has no restrictions, there's a possibility we can set it after the "end" date.
                    // In such case we update "end" date to be relative to the new "start" with an addition of 1 day
                    if (start.isAfter(this.value)) {
                        this.value = start.clone().add(1, 'd');
                    }
                },
                setMinMax: function() {
                    const start = vm.date.start.value;

                    this.min = start.clone();
                    this.max = start.clone().add(this._maxDateRangeDays, 'd');

                    this._restrictValueInRange();
                },
                /**
                 * Manually re-render the date picker so the input value changes
                 * after we programmatically set new value in _restrictValueInRange
                 */
                updateInput: function() {
                    this.isRendered = false;
                    $timeout(() => this.isRendered = true);
                },
            },
            tick: function () {
                this.end.setMinMax();
                this.end.updateInput();
            },
        };
        vm.currentLangId;
        vm.reservationsData = [];
        vm.tenantBackgroundColor = MainStorage.corporateColours[PRIMARY_COLOR_INDEX];
        vm.tenantFontColor = MainStorage.corporateColours[FONT_COLOR_INDEX];
        vm.carparks = [];
        vm.carparksName = [];
        vm.filteredCarparks = [];
        vm.statisticsMode = RESERVATION_MODES.BAR.id;
        vm.barDatasetOverride = {
            backgroundColor: '#fdb45c',
            borderColor: '#fdb45c',
            categoryPercentage: 1
        };
        vm.lineDatasetOverride = {
            backgroundColor: 'rgba(225, 165, 0, 0.1)',
            borderColor: '#fdb45c',
            lineTension: 0
        };
        vm.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }],
            },
        };

        vm.$onInit = init;
        vm.filterSuberials = filterSuberials;
        vm.switchAvailableView = switchAvailableView;
        vm.getCarparksName = getCarparksName;
        vm.onDateChange = onDateChange;

        function init() {
            vm.date.tick();
            LanguageService.getLanguages()
                .then(response => {
                    vm.langsArray = response.plain();
                    vm.currentLangId = vm.langsArray.find(lang =>
                        lang.code === $translate.use()
                    ).id;
                });
            ShareParkDataService.getCarParks({ page: 1, itemsPerPage: null })
                .then(response => {
                    vm.carparksName = response.map(decodeCarParkData);
                })

            setReservationData();
        }

        function onDateChange() {
            vm.date.tick();
            setReservationData();
        }

        function setReservationData() {
            ShareParkReservationsService.getAllReservationStatuses({
                carParkRefs: vm.selectedCarParks.map(carPark => carPark.external_id).join(','),
                start_date: vm.date.start.value.clone().startOf('day').add(1, 'second').utc().format('YYYY-MM-DD HH:mm:ss'),
                end_date: vm.date.end.value.clone().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
            }).then(response => {
                vm.reservationsData = response.data;
                vm.carparks = generateViewData();
                vm.filteredCarparks = [ ...vm.carparks ];
            });
        }

        function getCarparksName(id) {
            let name = null;
            vm.carparksName.forEach(item => item.external_id === id ? name = item.name : null)
            return name;
        }

        /**
         * @return {{
         *  carParkRef: Number,
         *  subAreaType: {
         *    barDatasetOverride: object[],
         *    chartLabels: string[],
         *    code: string,
         *    isAvailable: boolean,
         *    lineDatasetOverride: object[],
         *    name: Object<Number, string>,
         *    statistics: Number[],
         *  }[],
         *  subAreaLength: Number,
         *  subAreaNames: object[],
         *  visibleSuberials: string[]
         * }[]}
         */
        function generateViewData() {
            const carParksIds = [];
            const carparks = [];

            vm.reservationsData.forEach(item => {
                if (!carParksIds.includes(item.carParkRef)) {
                    carParksIds.push(item.carParkRef);
                    carparks.push({
                        carParkRef: item.carParkRef,
                        subAreaType: [],
                        subAreaLength: null,
                        subAreaNames: [],
                        visibleSuberials: []
                    });
                }
            });

            vm.reservationsData.forEach(item => {
                carparks.forEach(carPark => {
                    if (carPark.carParkRef === item.carParkRef) {
                        carPark.subAreaType.push({
                            code: item.subAreaType,
                            name: null,
                            chartLabels: [],
                            statistics: [],
                            isAvailable: true,
                            barDatasetOverride: [],
                            lineDatasetOverride: []
                        });
                    }
                })
            });

            carparks.forEach(carPark => {
                const subAreaType = [];
                carPark.subAreaType = carPark.subAreaType.filter(item => {
                    if (!subAreaType.includes(item.code)) {
                        subAreaType.push(item.code);
                        return item;
                    }
                })
            })

            vm.reservationsData.forEach(item => {
                carparks.forEach(carPark => {
                    if (carPark.carParkRef === item.carParkRef) {
                        carPark.subAreaType.forEach(sub => {
                            if (sub.code === item.subAreaType) {
                                sub.chartLabels.push(
                                    `${moment(item.segmentStartDate).format('YYYY-MM-DD HH:mm')}-${moment(item.segmentEndDate).format('HH:mm')}`
                                );

                                sub.statistics.push(sub.isAvailable ? item.available : item.amount);
                                sub.barDatasetOverride.push(vm.barDatasetOverride);
                                sub.lineDatasetOverride.push(vm.lineDatasetOverride);
                            }
                        })
                    }
                })
            });

            carparks.forEach(carPark => {
                carPark.subAreaLength = carPark.subAreaType.length;
                carPark.subAreaType.forEach(sub => {

                    vm.selectedCarParks.forEach(selectedCarPark => {
                        if (selectedCarPark.external_id === carPark.carParkRef) {
                            selectedCarPark.sub_areas.forEach(subArial => {
                                if (subArial.type === sub.code) {
                                    sub.name = subArial.label;
                                    carPark.subAreaNames.push({ subCode: sub.code, name: subArial.label });
                                }
                            })
                        }
                    })
                })

            })

            return carparks;
        }

        function filterSuberials(carParkRef) {
            vm.filteredCarparks.forEach(carPark => {
                carPark.carParkRef === carParkRef ? carPark.subAreaType = [] : null;
            });

            vm.carparks.forEach(item => {
                vm.filteredCarparks.forEach(carPark => {
                    if (carPark.carParkRef === item.carParkRef && carPark.carParkRef === carParkRef) {
                        if (!carPark.visibleSuberials.length) {
                            carPark.subAreaType = item.subAreaType;
                        } else {
                            carPark.subAreaType = item.subAreaType.filter(sub => carPark.visibleSuberials.includes(sub.code))
                        }
                    }
                })
            });
        }

        function switchAvailableView() {
            vm.filteredCarparks.forEach(carPark => carPark.subAreaType.forEach(sub => sub.statistics = []));
            vm.reservationsData.forEach(item => {
                vm.filteredCarparks.forEach(carPark => {
                    if (carPark.carParkRef === item.carParkRef) {
                        carPark.subAreaType.forEach(sub => {
                            if (sub.code === item.subAreaType) {
                                sub.statistics.push(sub.isAvailable ? item.available : item.amount);
                            }
                        })
                    }
                })
            });
        }

    }


})();

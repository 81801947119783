(function() {
    'use strict';

    angular.module('beacon.app')
        .component('campaignContentListPreview', {
            controller: CampaignContentListPreviewController,
            templateUrl: '/assets/views/common/components/campaign-content-list-preview/campaign-content-list-preview.tpl.html',
            bindings: {
                contents: '<'
            },
        });

    function CampaignContentListPreviewController(
        $state,
        $translate,
        PermissionsService,
        PopupService,
    ) {
        const vm = this;
        
        vm.editContent = editContent;
        vm.getContentTypeName = getContentTypeName;
        
        function editContent(content) {
            if (content.hidden_content) {
                return;
            }

            const { generateContentPermission, actions, isPermissionAvailable } = PermissionsService;
            const permissionName = generateContentPermission(actions.modify, content.content_type_id);

            if (!isPermissionAvailable(permissionName)) {
                return Promise.all([
                    $translate('YOU_DONT_HAVE_PERMISSION_TO_EDIT'),
                    $translate('THIS_CONTENT'),
                    $translate('OK'),
                ]).then(([message, thisContent, okButtonText]) => {
                    PopupService.showAlertPopup({
                        text: `${message} ${thisContent}`,
                        okButtonText
                    });
                });
            }

            $state.go('app.editContent', {
                data: content,
            });
        }

        function getContentTypeName(item) {
            return item.hidden_content
                ? 'BANNER_STANDALONE'
                : item.content_type.name;
        }
    }
})();
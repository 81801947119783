(function() {
    'use strict';

    angular.module('beacon.app')
        .component('configureMct', {
            templateUrl: '/assets/views/content/configure-mct/configure-mct.tpl.html',
            controller: ConfigureMctController,
        });

    function ConfigureMctController($interval, $element, StorageFactory) {
        const vm = this;

        vm.$onInit = init;

        function init() {
            const STORAGE = StorageFactory.Storage('Main');
            const userInfo = STORAGE.get('currentUserInfo');
            vm.url = userInfo.subdomain.tenant.mctUrl + 'views/editor/';
        }
    }
}());
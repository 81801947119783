
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkProducts', {
            templateUrl: '/assets/views/share-park/pricing/products/products.tpl.html',
            controller: ProductsController
        });

    function ProductsController(
        $state,
        $translate,
        PermissionsService,
        PopupService,
        PricingDataService,
        UtilitiesService,
    ) {
        const vm = this;

        const { actions, generateProductsPermission } = PermissionsService;
        const currentLocale = $translate.use();

        vm.$onInit = init;

        vm.addProduct = addProduct;

        vm.productsAll = [];
        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'product-name',
                    width: '20',
                    title: listItem => listItem.product.name[currentLocale] || Object.values(listItem.product.name)[0],
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteProduct,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editProduct,
                        permissionAction: actions.modify
                    },

                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        /**
         * Controller initialization
         */
        function init() {
            PricingDataService.getProducts()
                .then(response => {
                    vm.productsAll = response.plain().map(product => {
                        product.name = angular.fromJson(product.name);
                        product.description = angular.fromJson(product.description);
                        product.params = angular.fromJson(product.params);
                        return {product};
                    });
                    vm.count = vm.productsAll.length;
                    updateList();
                });
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.products = UtilitiesService.getArrayPage(vm.productsAll, page, count);
        }

        /**
         * Create new product
         */
        function addProduct() {
            $state.go('app.newProduct');
        }

        /**
         * Edit product
         * @param $event
         * @param item
         */
        function editProduct($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editProduct', { data: item.product });
        }

        function deleteProduct($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_PRODUCT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                PricingDataService.deleteProduct(item.product.id)
                    .then(init)
            });
        }

        /**
         * Generates permission name for each button on products accordion list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateProductsPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('habitPlacesDetails', {
            templateUrl: '/assets/views/campaign/habit/tabs/details/places/places.tpl.html',
            controller: habitPlacesDetails,
            bindings: {
                model: '='
            }
        });

    function habitPlacesDetails(
        $scope,
        $stateParams,
        habitEditState
    ) {
        const vm = this;

        vm.state = habitEditState;

        vm.$onInit = init;

        const DEFAULT_LAT = 50;
        const DEFAULT_LNG = 10;
        const DEFAULT_ZOOM = 4;
        const DEFAULT_MAX_ZOOM = 16;

        vm.hoverId = null;
        vm.timeout = null;
        vm.control = {};
        vm.fit = true;
        vm.markerCount = 0;

        vm.mapEvents = {
            click: addMarker,
        };

        vm.markerEvents = {
            mouseover: markerOnMouseOver,
            mouseout: markerOnMouseOut
        };

        vm.singleMarker = false;

        vm.map = {
            center: {
                latitude: DEFAULT_LAT,
                longitude: DEFAULT_LNG
            },
            zoom: DEFAULT_ZOOM,
            options: {
                maxZoom: DEFAULT_MAX_ZOOM
            }
        };

        // public methods
        vm.removeMarker = removeMarker;
        vm.stringifyCoordinates = stringifyCoordinates;
        vm.listItemOnMouseOver = listItemOnMouseOver;
        vm.listItemOnMouseLeave = listItemOnMouseLeave;

        /**
         * Adds new maker
         *
         *@param mapModel {object} GoogleMap object
         *@param eventName {string} event name
         *@param eventArgs {object} arguments provided by Google Maps fot click
         */
        function addMarker(mapModel, eventName, eventArgs) {
            vm.fit = false; // to not fit map to new markers
            const markerId = vm.markerCount++;
            const latitude = eventArgs[0].latLng.lat();
            const longitude = eventArgs[0].latLng.lng();
            const marker = new Marker(markerId, latitude, longitude);
            $scope.$apply(() => {
                vm.model.markers.push(marker);
            });
        }

        /**
         * Removes maker
         *
         *@param {object} instance of the google.maps.Marker
         *@param {string} event name
         *@param {object} marker object
         */
        function removeMarker(marker, eventName, markerElem) {
            vm.fit = false; // to not fit map to markers that remains
            marker.setMap(null);
            const markers = vm.model.markers;
            markers.splice(markers.indexOf(markerElem), 1);
        }

        /**
         * Marker mouseover callback
         * @param marker
         */
        function markerOnMouseOver(marker) {
            vm.hoverId = marker.model.id;
        }

        /**
         * Marker mouseout callback
         */
        function markerOnMouseOut() {
            vm.hoverId = null;
        }

        /**
         * Get marker object by id
         * @param id
         */
        function getMarkerById(id) {
            const gMarkers = vm.control.getGMarkers();
            return gMarkers.find(marker => marker.model.id === id);
        }

        /**
         * List item mouseover callback
         * @param id
         */
        function listItemOnMouseOver(id) {
            const marker = getMarkerById(id);
            marker.setAnimation(google.maps.Animation.BOUNCE)
        }

        /**
         * List item mouseleave callback
         * @param id
         */
        function listItemOnMouseLeave(id) {
            const marker = getMarkerById(id);
            marker.setAnimation(null)
        }

        /**
         * Stringify coordinates to display like list item
         * @param coordinates
         * @returns {string}
         */
        function stringifyCoordinates(coordinates) {
            return Object.keys(coordinates)
                .map(key => coordinates[key])
                .join(', ');
        }

        /**
         * Marker constructor
         * @param id
         * @param latitude
         * @param longitude
         * @constructor
         */
        function Marker(id, latitude, longitude) {
            this.id = id;
            this.coordinates = {
                latitude,
                longitude
            };
            this.stringify = () => {
                return Object.keys(this.coordinates)
                    .map(key => this.coordinates[key])
                    .join(',');
            }
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;

            vm.model.markers = [];
            const typeRef = vm.model.typeRef;

            if(typeRef.length) {
                vm.model.markers = typeRef.split(';').map((e, id) => {
                        const coordinates = e.split(',');
                        return new Marker(id, coordinates[0], coordinates[1]);
                    }
                );
            }

            vm.markerCount = vm.model.markers.length;

            bindWatchers();
        }

        /**
         * Can finish
         */
        function canFinish() {
            vm.state.canFinish = vm.model.name
                && vm.model.description
                && !!vm.model.markers.length;
        }

        /**
         * Bind watchers
         */
        function bindWatchers() {

            $scope.$watch(
                () => vm.model.name,
                () => canFinish()
            );

            $scope.$watch(
                () => vm.model.description,
                () => canFinish()
            );

            $scope.$watch(
                () => vm.model.markers.length,
                () => canFinish()
            );
        }
    }

}());

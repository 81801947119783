(function() {
    'use strict';

    angular.module('lineRoute')
        .component('lineRouteHtml', {
            controller,
            templateUrl: '/assets/views/line-route/line-route-html/line-route-html.tpl.html',
            bindings: {
                lineRouteSettings: '<',
                departureDetails: '<',
                isStopped: '<',
            },
        });

    function controller(
        $element,
        $scope,
        $window,
        $timeout,
        $interval,
        RouteHelper,
        LineRouteDateTimeService,
        TRANSPORT_ICONS,
        LAYOUTS,
        jQuery,
    ) {
        const vm = this;
        const element = $element[0];

        const $stopsWrap = jQuery(element).find('.route-html__stops-wrap');
        const $stops = jQuery(element).find('.route-html__stops');

        const HORIZONTAL = 1;
        const VERTICAL = 2;

        const ANIMATION = 1;
        const SCALING = 2;

        const NEXT_DEPARTURES_LIMIT = 4;

        vm.stationClass = stationClass;
        vm.getZoom = getZoom;
        vm.$onInit = init;

        function init() {
            render();
            setWatchers();
        }

        /**
         * Set variables for view
         */
        function render() {
            const details = vm.departureDetails;
            const settings = vm.lineRouteSettings;

            vm.class = _getClass();
            vm.lineName = details.Product.line || details.Product.name;
            vm.direction = details.direction;
            vm.icon = getIconSrc();
            vm.departureTime = details.times[0].format('HH:mm');
            vm.departureTimeVerbal = settings.header.showTillDepartureTime
                && RouteHelper.getVerbalTime(details.times[0]);
            vm.stations = angular.copy(details.stations).map(station => {
                station.productIcons = station.products
                    ? makeProductIconsList(station.products)
                    : [];
                station.time = LineRouteDateTimeService.getMinutesToDeparture(station.time.toDate());
                return station;
            });

            vm.nextDepartures = settings.line.showNextDeparture && details.times
                .filter((item, index) => index !== 0)
                .map(item => item.format('HH:mm'))
                .slice(0, NEXT_DEPARTURES_LIMIT);
            vm.track = settings.header.showTrack && (details.rtTrack || details.track);
            vm.aspectRatio = element.clientWidth / element.clientHeight > 1 ? HORIZONTAL : VERTICAL;
            vm.fitOption = settings.fitOption || ANIMATION;
        }

        /**
         * Callback if stations are rendered
         */
        function startFitting() {
            imagesLoaded().then(() => {
                switch (vm.fitOption) {
                    case ANIMATION:
                        resetScaling();
                        stopAnimation();
                        startAnimation();
                        break;
                    case SCALING:
                        scaleStations();
                        break;
                }
            });
        }

        /**
         * Resets stations scaling
         */
        function resetScaling() {
            $stopsWrap.css({zoom: 1});
        }

        /**
         * Scale stations
         */
        function scaleStations() {
            const ZOOM_THRESHOLD = 0.05;
            const ZOOM_MIN = 0.1;

            const isScalingNeeded = () => {
                const $lastStop = $stops.find('.route-html__stop:last');
                return $stopsWrap.get(0).offsetLeft + $stopsWrap.width()
                    < $lastStop.get(0).offsetLeft + $lastStop.outerWidth();
            };

            let zoom = 1;

            resetScaling();
            while(isScalingNeeded() && zoom > ZOOM_MIN) {
                zoom -= ZOOM_THRESHOLD;
                $stopsWrap.css({zoom});
            }
        }

        /**
         * Start animation
         */
        function startAnimation() {
            if (vm.fitOption !== ANIMATION) {
                return;
            }

            const START_ANIMATION_SPEED = 15;
            const END_ANIMATION_SPEED = 2;
            const PAUSE_DELAY = 3000;

            const startMargin = 0;

            imagesLoaded().then(() => {
                let endMargin, diff;

                switch (vm.aspectRatio) {
                    case VERTICAL:
                        if ($stopsWrap.height() > $stops.height()) {
                            return;
                        }
                        endMargin = $stopsWrap.height() - $stops.height();
                        diff = Math.abs(endMargin);
                        $stops
                            .css({marginTop: startMargin})
                            .delay(PAUSE_DELAY)
                            .animate({marginTop: endMargin}, diff * START_ANIMATION_SPEED, 'linear')
                            .delay(PAUSE_DELAY)
                            .animate({marginTop: startMargin}, diff * END_ANIMATION_SPEED, 'linear')
                            .queue(() => {
                                stopAnimation();
                                startAnimation();
                            });
                        break;

                    case HORIZONTAL:
                        const scrollWidth = $stops.get(0).scrollWidth;
                        if ($stopsWrap.width() > scrollWidth) {
                            return;
                        }
                        endMargin = scrollWidth - $stopsWrap.width();
                        diff = Math.abs(endMargin);

                        $stops
                            .delay(PAUSE_DELAY)
                            .animate({scrollLeft: endMargin}, diff * START_ANIMATION_SPEED, 'linear')
                            .delay(PAUSE_DELAY)
                            .animate({scrollLeft: startMargin}, diff * END_ANIMATION_SPEED, 'linear')
                            .queue(() => {
                                stopAnimation();
                                startAnimation();
                            });
                        break;
                }
            });
        }

        /**
         * Stop animation
         */
        function stopAnimation() {
            $stops.css({marginTop: 0});
            $stops.finish();
        }

        /**
         * Return a promise if images were loaded
         */
        function imagesLoaded() {
            return new Promise(resolve => {
                const images = $stops.find('img');
                let loaded;

                const interval = $interval(() => {
                    loaded = 0;
                    images.each(function() {
                        this.complete && loaded++;
                    });

                    if (loaded === images.length) {
                        $interval.cancel(interval);
                        resolve();
                    }
                }, 100);
            });
        }

        /**
         * Generate list of products category codes
         *
         * @param products
         * @returns {*}
         */
        function makeProductIconsList(products) {
            const icons = [];
            products.forEach(item => {
                const icon = RouteHelper.getTransportIcon(item.catCode, _getIconsTheme());
                icon && !icons.includes(icon) && icons.push(icon);
            });
            return icons;
        }

        /**
         * Get station class
         *
         * @param index
         * @returns {string}
         */
        function stationClass(index) {
            switch (index) {
                case 0:
                    return '--first';
                case vm.stations.length - 1:
                    return '--last';
            }
        }

        /**
         * Get icon src by catCode
         * @return {boolean | string}
         */
        function getIconSrc() {
            if (!vm.lineRouteSettings.header.showModeLogo) {
                return false;
            }
            return RouteHelper.getTransportIcon(vm.departureDetails.Product.catCode, _getIconsTheme());
        }

        /**
         * Get scale factor
         */
        function getZoom() {
            const size = element.clientHeight + element.clientWidth;
            const originSize = 1920 + 1080;
            return size / originSize;
        }

        /**
         * Return the class for line route wrapper
         *
         * @private
         */
        function _getClass() {
            const classes = [];

            switch (vm.lineRouteSettings.layout) {
                case LAYOUTS.HTML_LIGHT:
                    classes.push('--light');
                    break;
                case LAYOUTS.HTML_DARK:
                    classes.push('--dark');
                    break;
            }

            const aspectRatioClass = vm.aspectRatio === HORIZONTAL ? '--horizontal' : '--vertical';
            classes.push(aspectRatioClass);

            if (vm.fitOption === ANIMATION) {
                classes.push('--animation');
            }

            if (vm.fitOption === SCALING) {
                classes.push('--scaling');
            }

            return classes.join(' ');
        }

        /**
         * Returns the icons theme
         *
         * @returns {string}
         * @private
         */
        function _getIconsTheme() {
            return vm.lineRouteSettings.layout === LAYOUTS.HTML_DARK
                ? TRANSPORT_ICONS.CONTRAST_DARK
                : TRANSPORT_ICONS.DEFAULT;
        }

        function setWatchers() {
            $scope.$watch(
                () => vm.departureDetails,
                () => render(),
                true
            );

            $scope.$watch(
                () => new Date().getMinutes(),
                () => render(),
            );

            $scope.$watch(
                () => vm.lineRouteSettings,
                () => render(),
                true
            );

            $scope.$watch(
                () => vm.fitOption,
                () => {
                    render();
                    switch (vm.fitOption) {
                        case ANIMATION:
                            resetScaling();
                            startAnimation();
                            break;
                        case SCALING:
                            stopAnimation();
                            break;
                    }
                }
            );

            $scope.$watch(
                () => vm.isStopped,
                isStopped => isStopped ? stopAnimation() : startAnimation(),
            );

            $scope.$on('stationsRendered', startFitting);
            angular.element($window).on('resize', startFitting);

            angular.element($window).bind('resize', () => {
                $scope.$digest();
            });
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ScreensFilterPopupController', ScreensFilterPopupController);

    function ScreensFilterPopupController($uibModalInstance, data) {
        const vm = this;

        vm.screens = data.screens;
        vm.screensFiltered = [];

        vm.setScreens = setScreens;
        vm.cancel = cancelHandler;
        vm.ok = okHandler;

        /**
         * Save filtered screens
         *
         * @param screens
         */
        function setScreens(screens) {
            vm.screensFiltered = screens;
        }

        /**
         * "OK" click handler
         */
        function okHandler() {
            $uibModalInstance.close(vm.screensFiltered);
        }

        /**
         * "Cancel" click handler
         */
        function cancelHandler() {
            $uibModalInstance.close(false);
        }
    }
}());

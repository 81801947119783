(function() {
    'use strict';

    angular.module('beacon.app')
        .component('oauthControls', {
            templateUrl: '/assets/views/login/oauth-controls/oauth-controls.tpl.html',
            controller: OAuthControlsController,
            bindings: {
                onErrorHandler: '&'
            }
        });

    function OAuthControlsController(
        $window,
        MsOAuthService,
        OpenIdOAuthService,
        LoginService,
    ) {
        const $ctrl = this;

        $ctrl.entraClientId = '';
        $ctrl.openidSignInURL = '';

        $ctrl.$onInit = () => {
            MsOAuthService.getEntraClientId()
                .then(({ clientId }) => {
                    $ctrl.entraClientId = clientId;
                });

            OpenIdOAuthService.getSignInLink()
                .then(({ url }) => {
                    $ctrl.openidSignInURL = url;
                });
        };

        /**
         * Redirect to OpenID authorization page
         */
        $ctrl.initOpenIdLogin = () => {
            $window.location.href = $ctrl.openidSignInURL;
        };

        $ctrl.initMSLogin = () => {
            const { PublicClientApplication } = msal;
            const pca = new PublicClientApplication({
                auth: {
                    clientId: $ctrl.entraClientId,
                }
            });
            const loginRequest = {
                scopes: ['user.read'],
                prompt: 'select_account',
            };

            pca.initialize()
                .then(() => pca.handleRedirectPromise())
                .then(() => pca.loginPopup(loginRequest))
                .then(({ accessToken }) => MsOAuthService.loginWithMicrosoft(accessToken))
                .then(({ token, subdomains }) => {
                    LoginService.oauthAndRedirectToSubdomain(token, subdomains[0].id)
                })
                .catch(handleError);
        };

        function handleError(error) {
            $ctrl.onErrorHandler && $ctrl.onErrorHandler({ error });
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('appModesPicker', {
            templateUrl: '/assets/views/common/components/app-modes-picker/app-modes-picker.tpl.html',
            controller: AppModesPickerController,
            bindings: {
                appModes: '<',
                model: '=',
            }
        });

    /**
     * @typedef {object} AppMode
     *
     * @property {string} name
     * @property {string} id
     */

    /**
     * @property {AppMode[]} appModes
     * @property {string[]} model
     *
     * @constructor
     */
    function AppModesPickerController() {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            if (!$ctrl.model) {
                $ctrl.model = [];
            }
        }

        /**
         * @param {AppMode} appMode
         */
        $ctrl.toggleMode = (appMode) => {
            const index = $ctrl.model.findIndex(item => item === appMode.id);

            if (index >= 0) {
                $ctrl.model.splice(index, 1);
            } else {
                $ctrl.model.push(appMode.id);
            }
        }

        /**
         *
         * @param {AppMode} appMode
         */
        $ctrl.isActive = (appMode) => {
            return $ctrl.model.includes(appMode.id);
        }
    }
})();

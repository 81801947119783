(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('realTimeIndicator', {
            templateUrl: '/screen/views/components/timetable-hafas/partials/real-time-indicator/real-time-indicator.tpl.html',
            controller: RealTimeController,
            bindings: {
                color: '<',
            }
        });

    function RealTimeController() { }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newShareParkSublet', {
            templateUrl: '/assets/views/share-park/customers/sublet/new/new-sublet.tpl.html',
            controller: NewShareParkSubletController
        });

    function NewShareParkSubletController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
    ) {
        const vm = this;

        vm.state = StatesFactory.ShareParkSubletsStates.refresh();
        vm.state.type = $stateParams.type;

        vm.sublets = [];

        vm.$onInit = init;

        vm.showInputError = showInputError;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            vm.subletData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.CarParkSublet();

            ShareParkDataService.getCapSublets()
                .then(response => {
                    vm.sublets = response.plain().sort((a, b) => a.name.localeCompare(b.name));
                });
        }

        /**
         * Checks is error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.newSubletForm
                && vm.newSubletForm[name].$dirty
                && vm.newSubletForm[name].$invalid;
        }


        /**
         * On finish handler
         */
        function onFinish() {
            ShareParkDataService.createCapSublet(vm.subletData)
                .then(onSuccess);
        }

        function onSuccess() {
            $state.go('app.shareParkSublets', {
                paginationData: $stateParams.paginationData
            });
        }

        function canFinish() {
            vm.state.canFinish = vm.newSubletForm
                && vm.newSubletForm.$valid;
        }

        $scope.$watch(function() {
            return vm.newSubletForm && vm.newSubletForm.$valid;
        }, canFinish);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
(function() {
    'use strict';

    angular.module('lineRoute')
        .service('RouteStation', (LineRouteDateTimeService) => {

            class Station {
                constructor(config) {
                    this.LABEL_LINE_HEIGHT = 1.4;
                    this.PRODUCTS_FONT_SIZE_RATIO = 0.5;
                    this.PRODUCTS_MAX_WIDTH_RATIO = 1.4;

                    this.PI = Math.PI;

                    this.name = config.name;
                    this.time = config.time;

                    this.padding = config.labelPadding;
                    this.coords = config.coords;
                    this.pointColor = config.pointColor;
                    this.fontSize = config.fontSize;
                    this.labelAngle = config.labelAngle;
                    this.labelMaxWidth = config.labelMaxWidth - this.padding;
                    this.lineHeight = this.fontSize * this.LABEL_LINE_HEIGHT;

                    this.products = config.products;
                    this.productsFontSize = this.fontSize * this.PRODUCTS_FONT_SIZE_RATIO;
                    this.productsMargin = 7;

                    this.offsetTop = (this.productsFontSize + this.productsMargin) / 2;

                    this.labelWidth = null;
                }

                /**
                 * Draw the station
                 *
                 * @param context
                 */
                draw(context) {
                    this.addDot(context);
                    const labelSize = this.addLabel(context);
                    this.addProducts(context, labelSize);
                }

                /**
                 * Add station point
                 *
                 * @param context
                 */
                addDot(context) {
                    context.save();

                    const circle = (radius, color) => {
                        context.beginPath();
                        context.arc(this.coords.x, this.coords.y, radius, 0, 2 * this.PI);
                        context.fillStyle = color;
                        context.fill();
                    };

                    const UNDERGROUND_OUTER_RADIUS = 16;
                    const UNDERGROUND_MIDDLE_RADIUS = 12;
                    const UNDERGROUND_INNER_RADIUS = 8;
                    const COMMON_POINT_RADIUS = 8;

                    if (this.hasUnderground()) {
                        circle(UNDERGROUND_OUTER_RADIUS, this.pointColor);
                        circle(UNDERGROUND_MIDDLE_RADIUS, '#FFF');
                        circle(UNDERGROUND_INNER_RADIUS, this.pointColor);
                    } else {
                        circle(COMMON_POINT_RADIUS, this.pointColor);
                    }

                    context.restore();
                }

                /**
                 * Add station label
                 *
                 * @param context
                 * @returns {{height: *}}
                 */
                addLabel(context) {
                    context.save();

                    context.beginPath();
                    context.fillStyle = '#000';
                    context.font = this.fontSize + 'pt Arial';

                    const makeName = (cutChars = 0, time) => {
                        let name = this.name
                            .substr(0, this.name.length - cutChars)
                            .trim();

                        if (cutChars) {
                            name += '...';
                        }

                        if (time) {
                            time = LineRouteDateTimeService.getMinutesToDeparture(time);
                            name += time > 0 ? ` (${time}')` : ' (now)';
                        }
                        return name;
                    };

                    let cutChars = 0;
                    let name;
                    do {
                        name = makeName(cutChars, this.time);
                        this.labelWidth = context.measureText(name).width;
                        cutChars++;
                    } while(this.labelWidth > this.labelMaxWidth);

                    context.translate(this.coords.x, this.coords.y);

                    let angle = this.labelAngle;
                    let x = this.padding;
                    let y = this.fontSize / 2 - this.offsetTop;

                    if (Station.isMirrored(angle)) {
                        angle -= 180;
                        x = - this.padding - this.labelWidth;
                    }

                    context.rotate(Station.toRadians(angle));
                    context.fillText(name, x, y);

                    context.restore();

                    return {height: this.fontSize};
                }

                /**
                 * Add products below the label (station name)
                 *
                 * @param context
                 * @param labelSize
                 */
                addProducts(context, labelSize) {
                    context.save();

                    const offsetX = 0;
                    const products = angular.copy(this.products);
                    let productsText = products.map(item => item.name).join(' ');

                    context.font = `${this.productsFontSize} pt Arial`;

                    const areProductsWider = value => {
                        const productsWidth = context.measureText(productsText).width + offsetX;
                        return productsWidth > value;
                    };

                    while (areProductsWider(this.labelMaxWidth)
                        || areProductsWider(this.labelWidth * this.PRODUCTS_MAX_WIDTH_RATIO)) {
                        products.pop();
                        productsText = products.map(item => item.name).join(' ') + '...';
                    }

                    let x = this.padding + offsetX;
                    let y = labelSize.height / 2 + this.productsFontSize + this.productsMargin - this.offsetTop;
                    let angle = this.labelAngle;

                    if (Station.isMirrored(this.labelAngle)) {
                        angle -= 180;
                        const size = context.measureText(productsText);
                        x = - this.padding - size.width;
                    }

                    context.translate(this.coords.x, this.coords.y);
                    context.rotate(Station.toRadians(angle));
                    context.fillText(productsText, x, y);
                    context.restore();
                }

                /**
                 * Add departure time above the label (station name)
                 *
                 * @param context
                 * @param labelSize
                 */
                addTime(context, labelSize) {
                    context.save();

                    const time = this.makeTime();
                    context.font = this.productsFontSize + 'pt Arial';

                    let x = this.padding;
                    let y = - labelSize.height / 2;
                    let angle = this.labelAngle;

                    if (Station.isMirrored(this.labelAngle)) {
                        angle -= 180;
                        const size = context.measureText(time);
                        x = - this.padding - size.width;
                    }

                    context.translate(this.coords.x, this.coords.y);
                    context.rotate(Station.toRadians(angle));
                    context.fillText(time, x, y);
                    context.restore();
                }

                makeTime() {
                    if (!this.time) {
                        return false;
                    }
                    const timeArray = this.time.split(':');
                    timeArray.pop();
                    return timeArray.join(':');
                }

                /**
                 * Checks if station has underground
                 */
                hasUnderground() {
                    return this.products.some(item => item.name.startsWith('U'));
                }

                /**
                 * Convert degrees to radians
                 *
                 * @param deg
                 * @returns {number}
                 */
                static toRadians(deg) {
                    return deg * Math.PI / 180;
                }

                /**
                 * Check if text needs to be mirrored
                 *
                 * @param angle
                 * @returns {boolean}
                 */
                static isMirrored(angle) {
                    angle = angle % 360;
                    if (angle < 0) {
                        angle += 360;
                    }
                    return angle >= 90 && angle < 270;
                }
            }

            return Station;
        });
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('UtilitiesService', utilitiesService);

    function utilitiesService(
        CONTENT_TYPES,
        SEGMENT_TYPES,
        PAGE_TYPES,
        VIEW_TYPES,
    ) {
        /**
         * Exports
         */
        return {
            compareAsNumber,
            checkContentType,
            checkSegmentType,
            checkPageType,
            distinctByProp,
            oneOfContentTypes,
            oneOfPageTypes,
            sortByStringProperty,
            sortByStringPropertyWithLang,
            sortByDateProperty,
            stripHtmlToText,
            findDifferences,
            findIntersection,
            getKeyByValue,
            selectImageSourceByViewType,
            plainResponse,
            isValidField,
            generateGUID,
            base64ImageToBlob,
            removeProperties,
            getArrayPage,
            stripHtml,
            prearrangeText,
            decodeConfigString,
            getTimeByDiff,
            inputValidator,
            generateTranstalionId,
            getCoordinates,
            toPrecision,
            isJson,
        };

        /**
         * Compare arguments types with types conversion to Number
         * @param  {Any} a
         * @param  {Any} b
         * @return {Boolean}
         */
        function compareAsNumber(a, b) {
            return Number(a) === Number(b);
        }

        /**
         * Check content type
         * @param  {String} string  :: content type
         * @param  {Any} type       :: value to check
         * @return {Boolean}
         */
        function checkContentType(string, type) {
            return compareAsNumber(CONTENT_TYPES[string], type);
        }

        /**
         * Check segment type
         * @param  {String} string  :: segment type
         * @param  {Any} type       :: value to check
         * @return {Boolean}
         */
        function checkSegmentType(string, type) {
            return compareAsNumber(SEGMENT_TYPES[string], type);
        }

        /**
         * Check page type
         * @param  {String} string  :: page type
         * @param  {Any} type       :: value to check
         * @return {Boolean}
         */
        function checkPageType(string, type) {
            return compareAsNumber(PAGE_TYPES[string], type);
        }

        /**
         * Gets text from html string
         * @param htmlString
         */
        function stripHtmlToText(htmlString) {
            let container = document.createElement("DIV");
            container.innerHTML = htmlString;
            return container.textContent || container.innerText || "";
        }

        /**
         * Filters array
         * If some array elements have same property values - only one of them is left
         * @param {array} originalArray
         * @param {string} prop Property to check for equality
         * @returns {Array} filtered array
         */
        function distinctByProp(originalArray, prop) {
            var newArray = [];
            var lookupObject = {};

            for (var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }

            for (i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        }

        /**
         * Check does the array contain content type
         * @param  {array} array Content types
         * @param  {number|string} type Value to check
         * @return {boolean}
         */
        function oneOfContentTypes(array, type) {
            return array
                .map((string) => CONTENT_TYPES[string])
                .some((value) => compareAsNumber(value, type));
        }

        /**
         * Check does the array contain page type
         * @param  {Array[String]} array  :: page types array
         * @param  {Any} type             :: value to check
         * @return {Boolean}
         */
        function oneOfPageTypes(array, type) {
            return array
                .map((string) => PAGE_TYPES[string])
                .some((value) => compareAsNumber(value, type));
        }

        /**
         * Get key by value
         * @param  {Object} object
         * @param  {Any} value
         * @return {String}
         */
        function getKeyByValue(object, value) {
            let pair = Object.entries(object).filter((pair) => pair[1] === value)[0];

            return pair ? pair[0] : null;
        }

        /**
         * Sorts array of objects by string property
         * @param  {Array} arrayData
         * @param  {String} property
         * @return {Array}
         */
        function sortByStringProperty(arrayData, property) {
            return arrayData.slice().sort((a, b) => {
                if (a[property].toLowerCase() < b[property].toLowerCase()) {
                    return -1;
                } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        /**
         * Sorts array of objects by string property with language
         * @param  {Array} arrayData
         * @param  {String} property
         * @param  {String} lang
         * @return {Array}
         */
        function sortByStringPropertyWithLang(arrayData, property, lang = null) {
            return arrayData.slice().sort((a, b) => {
                const propA = a[property][lang] || Object.values(a[property])[0];
                const propB = b[property][lang] || Object.values(a[property])[0];

                if (propA.toLowerCase() < propB.toLowerCase()) {
                    return -1;
                } 
                if (propA.toLowerCase() > propB.toLowerCase()) {
                    return 1;
                } 
                return 0;
            });
        }

        /**
         * Sorts array of objects by date/time property
         * @param  {Array} arrayData
         * @param  {String|Number} property
         * @return {Array}
         */
        function sortByDateProperty(arrayData, property) {
            return arrayData.slice().sort((a, b) => {
                if (moment(a[property]).isAfter(b[property])) {
                    return -1;
                } else if (moment(a[property]).isBefore(b[property])) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        /**
         * Finds differences of two arrays
         * @param  {Array} array1
         * @param  {Array} array2
         * @return {Array}
         */
        function findDifferences(array1, array2) {
            var ai = 0,
                bi = 0,
                array1Length = array1.length,
                array2Length = array2.length,
                result = [];

            while (ai < array1Length && bi < array2Length) {
                if (array1[ai] === array2[bi]) {
                    ai++;
                    bi++;
                } else if (array1[ai] < array2[bi]) {
                    result.push(array1[ai++]);
                } else {
                    bi++;
                }
            }

            if (ai < array1Length) {
                result = result.concat(array1.slice(ai));
            }
            return result;
        }

        /**
         * Finds intersections of two arrays
         * @param  {Array} array1
         * @param  {Array} array2
         * @return {Array}
         */
        function findIntersection(array1, array2) {
            var ai = 0,
                bi = 0,
                array1Length = array1.length,
                array2Length = array2.length,
                result = [];

            while (ai < array1Length && bi < array2Length) {
                if (array1[ai] === array2[bi]) {
                    result.push(array1[ai]);
                    ai++;
                    bi++;
                } else if (array1[ai] < array2[bi]) {
                    ai++;
                } else {
                    bi++;
                }
            }
            return result;
        }

        /**
         * Return image source for target viewType if it exists. In other case return image source for any viewType.
         * @param item
         * @param viewType {string} view type from VIEW_TYPES
         * @returns {string}
         */
        function selectImageSourceByViewType(item, viewType) {
            return (item.cropped[viewType] && item.cropped[viewType].img) ||
                (item.cropped[VIEW_TYPES.LANDSCAPE] && item.cropped[VIEW_TYPES.LANDSCAPE].img) ||
                (item.cropped[VIEW_TYPES.PORTRAIT] && item.cropped[VIEW_TYPES.PORTRAIT].img) ||
                '';
        }

        /**
         * Clears server response from REST Angular properties calling "plain" method
         *
         * @param {Object} response
         * @return {Object}
         */
        function plainResponse(response) {
            if (response.hasOwnProperty('plain') && typeof response.plain === 'function') {
                return response.plain();
            }
            return response;
        }

        /**
         * Field validation
         *
         * @param {object} formObject
         * @param {string} fieldName
         * @returns {boolean}
         */
        function isValidField(formObject, fieldName) {
            if (formObject.hasOwnProperty(fieldName)) {
                return formObject[fieldName].$dirty && !formObject[fieldName].$valid;
            }
        }

        /**
         * Generate GUID
         * @returns {string} GUID
         */
        function generateGUID() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }

        /**
         * Convert base64 image to Blob
         * @param {string} base64Image Image encoded to base64
         * @param {string} imageType MIME type
         * @returns {Blob} Image Blob
         */
        function base64ImageToBlob(base64Image, imageType = 'image/png') {
            const byteCharacters = atob(base64Image);
            const byteNumbers = new Array(byteCharacters.length); //TODO mb []

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            return new Blob([byteArray], {type: imageType});
        }

        /**
         * Removing specific keys from object
         *
         * @param {object} obj - target object
         * @param {array} keys - array of property names
         */
        function removeProperties(obj, keys) {
            let index;
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    index = keys.indexOf(prop);
                    if (index > -1) {
                        delete obj[prop];
                    }
                    if (typeof obj[prop] === 'object') {
                        removeProperties(obj[prop], keys);
                    }
                }
            }
        }

        /**
         * Getting part of array (page)
         *
         * @param {array} array
         * @param {int} page
         * @param {int} perPage
         * @returns {*}
         */
        function getArrayPage(array, page, perPage) {
            --page; // because pages logically start with 1, but technically with 0
            const firstElement = page * perPage;
            return array.slice(firstElement, firstElement + perPage);
        }

        /**
         * Replaces /n with <br>
         * @param {string} text
         * @returns {string}
         */
        function prearrangeText(text) {
            return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }

        /**
         * Strip html from string or object properties
         *
         * @param {string} data
         * @return {string|null}
         * @private
         */
        function stripHtml(data) {
            const pattern = /<[^>]+>/g;

            if (typeof data === 'string') {
                return data.replace(pattern, '');
            }

            throw new TypeError('Data type is not a string');
        }

        /**
         * Decodes 'param==value;param2==value2' into {param: 'value', param2: 'value2'}
         *
         * @param {string} configString
         * @return {object}
         */
        function decodeConfigString(configString) {
            const paramsEncodedArray = configString.split(';');
            const paramsDecoded = {};
            paramsEncodedArray.map(paramEncoded => {
                const paramArray = paramEncoded.split('==');
                if (paramArray[0] && paramArray[1]) {
                    paramsDecoded[paramArray[0]] = paramArray[1];
                }
            });
            return paramsDecoded;
        }

        /**
         * Get time by difference from now
         * @param difference
         * @return {Date}
         */
        function getTimeByDiff(difference) {
            const date = new Date();
            date.setDate(date.getDate() + difference.days);
            date.setHours(date.getHours() + difference.hours);
            date.setMinutes(date.getMinutes() + difference.minutes);
            return date;
        }

        /**
         * Returns a checker for md-input-container, that allows to hide error message if field was not touched
         *
         * Usage:
         * vm.showError = inputValidator(vm.formName);
         * md-is-error="$ctrl.showError('title')"
         *
         * @param form
         * @returns {function}
         */
        function inputValidator(form) {
            if (!form) {
                throw new Error('Form is missing!');
            }

            return inputName => {
                const input = form && form[inputName];
                return input && input.$dirty && input.$invalid;
            };
        }

        function generateTranstalionId(prefix, translationId) {
            return `${prefix}.${translationId}`
        }

        /**
         * Gets data from coordinates string
         * @param { string } latlng
         * @return {*}
         */
        function getCoordinates(latlng) {
            if (!latlng) {
                return null;
            }

            const coords = latlng.split(',');
            return {
                latitude: Number(coords[0]),
                longitude: Number(coords[1])
            };
        }

        /**
         * Returns number with fixed amount of numbers after decimal point
         * @param {number} number
         * @param {number} precision
         * @return {number}
         */
        function toPrecision(number, precision) {
            const multiplier = Math.pow(10, precision);

            return Math.round((number + Number.EPSILON) * multiplier) / multiplier;
        }

        /**
         * Checks if string is valid json
         * @param str
         * @return {boolean}
         */
        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }

            return true;
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('pagesEditorTemplates', pagesEditorTemplates);

    function pagesEditorTemplates() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/templates/templates.tpl.html',
            replace: true,
            controller: PagesEditorTemplatesController,
            controllerAs: 'pagesEditorTemplates',
            bindToController: true,
            scope: {
                templates: '=templates'
            }
        };
    }

    function PagesEditorTemplatesController($scope) {}
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ProfilesHelper', ProfilesHelper);

    function ProfilesHelper($translate) {

        this.decodeCustomerProfile = decodeCustomerProfile;
        this.encodeCustomerProfile = encodeCustomerProfile;
        this.getCapWithSubAreasMapping = getCapWithSubAreasMapping;
        this.encodeRatesData = encodeRatesData;
        this.encodeCarParksData = encodeCarParksData;
        this.encodeOverrideProfiles = encodeOverrideProfiles;
        this.decodeOverrideProfilesData = decodeOverrideProfilesData;

        /**
         *
         * @param {object} profile
         * @param {boolean} syncDataStructures
         * @returns {*}
         */
        function decodeCustomerProfile(profile, syncDataStructures) {
            const currentLocale = $translate.use();
            let profileData = angular.copy(profile);

            const nameDecoded = angular.fromJson(profileData.name);
            profileData.nameLabel = nameDecoded[currentLocale] || Object.values(nameDecoded)[0];
            profileData.name = nameDecoded;
            profileData.description = angular.fromJson(profileData.description);
            profileData.descriptionLabel = profileData.description[currentLocale]
                || Object.values(profileData.description)[0];
            profileData.capPermissions = angular.fromJson(profileData.capPermissions);
            profileData.ratePermissions = angular.fromJson(profileData.ratePermissions);

            profileData.profilePermissions = profileData.profilePermissions
                ? angular.fromJson(profileData.profilePermissions)
                : {};

            if (syncDataStructures) {
                profileData = syncProfileData(profileData);
            }

            return profileData;
        }

        /**
         * Keeps profile data up to date with old and new structure
         */
        function syncProfileData(profileData) {
            const defaultProfileData = profileData.profilePermissions.defaultProfile || {};
            if (_.isEmpty(profileData.capPermissions) && !_.isEmpty(defaultProfileData.capPermissions)) {
                profileData.capPermissions = angular.copy(defaultProfileData.capPermissions);
            }

            if (_.isEmpty(defaultProfileData.capPermissions) && !_.isEmpty(profileData.capPermissions)) {
                defaultProfileData.capPermissions = angular.copy(profileData.capPermissions);
            }

            if (_.isEmpty(profileData.ratePermissions) && !_.isEmpty(defaultProfileData.ratePermissions)) {
                profileData.ratePermissions = angular.copy(defaultProfileData.ratePermissions);
            }

            if (_.isEmpty(defaultProfileData.ratePermissions) && !_.isEmpty(profileData.ratePermissions)) {
                defaultProfileData.ratePermissions = angular.copy(profileData.ratePermissions);
            }

            profileData.profilePermissions.defaultProfile = defaultProfileData;
            return profileData;
        }

        function encodeCustomerProfile(profile) {
            const profileData = angular.copy(profile);

            delete profileData.nameLabel;
            delete profileData.descriptionLabel;
            profileData.name = angular.toJson(profileData.name);
            profileData.description = angular.toJson(profileData.description);
            profileData.capPermissions = angular.toJson(profileData.capPermissions);
            profileData.ratePermissions = angular.toJson(profileData.ratePermissions);
            profileData.profilePermissions = angular.toJson(profileData.profilePermissions);

            return profileData;
        }

        /**
         * Decodes caps string
         * @param {string} capsString       // example "15:DEFAULT_2;16;17"
         * @returns {*}                     // { 15: ["DEFAULT_2"], 16: [], 17: []}
         */
        function getCapWithSubAreasMapping(capsString) {
            return capsString.split(';').map(capEncodedText => {
                let [carParkId, subAreaType] = capEncodedText.split(':');
                return {
                    carParkId: Number(carParkId),
                    subAreaType
                }
            }).reduce((accum, current) => {
                if (!accum[current.carParkId]) {
                    accum[current.carParkId] = [];
                }

                if (current.subAreaType) {
                    accum[current.carParkId].push(current.subAreaType);
                }
                return accum;
            }, {});
        }


        /**
         * Encodes rates data for customer profile
         * @param {object} profileData
         * @param {array} capRates
         * @returns {*}
         */
        function encodeRatesData(profileData, capRates) {
            const selectedRateIds = profileData.ratePermissions.ratesArr.map(({id}) => id);
            profileData.typeCategory = profileData.externalId;

            if (profileData.ratePermissions.ratesArr.length === capRates.length) {
                delete profileData.ratePermissions.rates;
            } else {
                profileData.ratePermissions.rates = selectedRateIds.join(';');
            }

            profileData.ratePermissions.adhocRates = profileData.ratePermissions.adhocRatesArr
                .filter(({id}) => selectedRateIds.includes(id))
                .map(({id}) => id)
                .join(';');

            profileData.profilePermissions.defaultProfile.ratePermissions = profileData.ratePermissions;

            delete profileData.ratePermissions.ratesArr;
            delete profileData.ratePermissions.adhocRatesArr;

            return profileData;
        }

        /**
         * Encodes car parks data in profiles
         * @param {object} profileData
         * @param {array} selectedCarParks
         * @param {boolean} allCapsSelected
         * @returns {*}
         */
        function encodeCarParksData(profileData, selectedCarParks, allCapsSelected) {
            selectedCarParks.forEach(carPark => {
                const overrideSettings = profileData.profilePermissions.overrideProfiles.find(override =>
                    override.carParkRef === carPark.external_id
                )

                if (overrideSettings) {
                    overrideSettings.ratePermissions.adhocParking = !!carPark.adhocParking;
                    overrideSettings.ratePermissions.bookingPermission = !!carPark.bookingPermission;
                }
            });

            const customSubAreas = selectedCarParks.find(carPark => carPark.related_sub_areas.length);

            if (allCapsSelected && !customSubAreas) {
                delete profileData.capPermissions.caps;
            } else {
                profileData.capPermissions.caps = selectedCarParks.map(carPark => {
                    return carPark.related_sub_areas.length
                        ? carPark.related_sub_areas.map(subArea => {
                            return `${carPark.external_id}:${subArea}`;
                        }).join(';')
                        : carPark.external_id;
                }).join(';');
            }

            profileData.capPermissions.adhocCaps = selectedCarParks.filter(cap => cap.adhocParking)
                .map(cap => cap.external_id)
                .join(';');
            profileData.profilePermissions.defaultProfile.capPermissions = profileData.capPermissions;

            return profileData;
        }

        /**
         * Encodes override profiles data
         * @param {object} profileData
         * @param {array} capRatesAll
         * @returns {*}
         */
        function encodeOverrideProfiles(profileData, capRatesAll) {
            profileData.profilePermissions.overrideProfiles.forEach(override => {
                const rateIds = override.ratePermissions.ratesArr.map(({id}) => id);

                if (rateIds.length === capRatesAll.length) {
                    delete override.ratePermissions.rates;
                } else {
                    override.ratePermissions.rates = rateIds.join(';');
                }

                override.ratePermissions.adhocRates = override.ratePermissions.adhocRatesArr
                    .filter(({id}) => rateIds.includes(id))
                    .map(({id}) => id)
                    .join(';');

                delete override.ratePermissions.ratesArr;
                delete override.ratePermissions.adhocRatesArr;
            });

            return profileData;
        }

        /**
         *
         * @param {array} overrideProfiles
         * @param {array} capRates
         * @returns {*}
         */
        function decodeOverrideProfilesData(overrideProfiles, capRates) {
            overrideProfiles.forEach(override => {
                if (angular.isDefined(override.ratePermissions.rates)) {
                    const rateIds = override.ratePermissions.rates.split(';')
                        .map(rateId => Number(rateId));
                    override.ratePermissions.ratesArr = capRates.filter(rate => rateIds.includes(rate.id));
                } else {
                    override.ratePermissions.ratesArr = angular.copy(capRates);
                }

                if (angular.isDefined(override.ratePermissions.adhocRates)) {
                    const rateIds = override.ratePermissions.adhocRates.split(';')
                        .map(rateId => Number(rateId));
                    override.ratePermissions.adhocRatesArr = capRates.filter(rate => rateIds.includes(rate.id));
                } else {
                    override.ratePermissions.adhocRatesArr = [];
                }
            });

            return overrideProfiles;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tierTypes', {
            templateUrl: '/assets/views/loyalty/tiers/tier-types/tier-types.tpl.html',
            controller: TiersController
        });

    function TiersController($state, $stateParams, StorageFactory, PermissionsService, TierService, UtilitiesService) {
        const vm = this;

        vm.STORAGE = new StorageFactory.Storage('Tiers', true);

        vm.TIER_STATUS_INACTIVE = 0;
        vm.TIER_STATUS_ACTIVE = 1;
        vm.TIER_STATUS_ACTIVE_DEFAULT = 2;
        vm.TIER_STATUS_DELETED = 3;

        vm.filter = {
            status: {}
        };

        vm.newTier = newTier;
        vm.updateTiersList = updateTiersList;
        vm.isPermissionAvailable = PermissionsService.isPermissionAvailable;

        _init();

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '40',
                    title: 'name'
                },
                {
                    name: 'STATUS',
                    class: 'campaignStatus',
                    width: '15',
                    translate: true,
                    title: item => {
                        switch(item.status) {
                            case vm.TIER_STATUS_INACTIVE:
                                return 'INACTIVE';
                            case vm.TIER_STATUS_ACTIVE:
                                return 'ACTIVE';
                            case vm.TIER_STATUS_ACTIVE_DEFAULT:
                                return 'ACTIVE_DEFAULT';
                            case vm.TIER_STATUS_DELETED:
                                return 'DELETED';
                            default:
                                return 'UNDEFINED';
                        }
                    }
                },
                {
                    name: '',
                    class: 'text-center',
                    title: item => {
                        return item.image_ref ? `<img src="${item.image_ref}" height="41">` : '';
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: _deleteTier,
                        isVisible: (btn, item) => item.status !== vm.TIER_STATUS_DELETED,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'copyBtn',
                        callback: _copyTier,
                        permissionAction: 'create'
                    },
                    {
                        class: 'editBtn',
                        callback: _editTier,
                        isVisible: (btn, item) => item.status !== vm.TIER_STATUS_DELETED,
                        permissionAction: 'modify'
                    },
                ]
            },
            updateCallback: updateTiersList,
            generatePermissionName: _generatePermissionName
        };

        /**
         * Create tier button handler
         */
        function newTier() {
            $state.go('app.loyaltyTiersAdd');
        }

        /**
         * Updates tiers list
         *
         * @param {number} page
         * @param {number} itemsPerPage
         * @param {boolean} forceApi - force update from DB
         */
        function updateTiersList(page = vm.paginationParams.page,
                                        itemsPerPage = vm.paginationParams.itemsPerPage,
                                        forceApi = false) {
            const tiersAll = vm.STORAGE.get('tiers');
            if (!forceApi && tiersAll !== undefined) {
                _applyTiersToScope(tiersAll, page, itemsPerPage);
            } else {
                TierService.getList()
                    .then(response => {
                        vm.STORAGE.set('tiers', response);
                        _applyTiersToScope(response, page, itemsPerPage);
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Edit tier button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function _editTier($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            const data = item.plain();

            $state.go('app.loyaltyTiersEdit', {
                data,
                paginationData: {
                    page: vm.paginationParams.page,
                    itemsPerPage: vm.paginationParams.itemsPerPage,
                    count: vm.totalItems
                },
                type: 'edit'
            });
        }

        /**
         * Delete campaign button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function _deleteTier($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = item.plain();
            data.description = JSON.parse(data.description);
            data.status = vm.TIER_STATUS_DELETED;

            const formData = new FormData();
            formData.append('json', JSON.stringify(data));

            TierService.remove(formData)
                .then(() => {
                    item.status = data.status;
                }).catch(console.error.bind(console));
        }

        /**
         * Copy tier button handler
         *
         * @param {object} $event
         * @param {object} item
         */
        function _copyTier($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const objCopy = angular.copy(item.plain());
            UtilitiesService.removeProperties(objCopy, ['id', 'external_id']);

            objCopy.description = JSON.parse(objCopy.description);
            objCopy.status = vm.TIER_STATUS_INACTIVE;
            objCopy.copy = true;

            const formData = new FormData();
            formData.append('json', JSON.stringify(objCopy));

            TierService.create(formData)
                .then(() => {
                    updateTiersList(vm.paginationParams.page, vm.paginationParams.itemsPerPage, true);
                }).catch(console.error.bind(console));
        }

        /**
         * Applying filtering and pagination parameters to tiers, and adding tiers list to scope
         *
         * @param {array} tiers
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function _applyTiersToScope(tiers, page, itemsPerPage) {
            const tiersFiltered = _getFiltered(tiers);
            vm.totalItems = tiersFiltered.length;
            vm.tiers = _getPage(tiersFiltered, page, itemsPerPage);
            vm.paginationParams = {page, itemsPerPage};
        }

        /**
         * Getting filtered tiers from all tiers
         *
         * @param {array} tiers
         * @returns {array}
         */
        function _getFiltered(tiers) {
            const statuses = Object.keys(vm.filter.status).filter(statusId => vm.filter.status[statusId] === true);

            if (!statuses.length) {
                return tiers;
            }

            return tiers.filter(item => {
                return statuses.includes(item.status.toString());
            });
        }

        /**
         * Getting part (page) of array
         *
         * @param {array} array
         * @param {number} page
         * @param {number} perPage
         * @returns {array}
         */
        function _getPage(array, page, perPage) {
            --page; // because pages logically start with 1, but technically with 0
            const firstElement = page * perPage;
            return array.slice(firstElement, firstElement + perPage);
        }

        /**
         * From short name to permission full name
         *
         * @param {object} button
         * @returns {string}
         */
        function _generatePermissionName(button) {
            return 'can_' + button.permissionAction + '_tiers';
        }

        /**
         * Controller initialization
         */
        function _init() {
            vm.paginationData = $stateParams.paginationData;
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('pagesEditorIcons', pagesEditorIcons);

    function pagesEditorIcons() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/pages-editor-icons/pages-editor-icons.tpl.html',
            replace: true,
            controller: PagesEditorIconsController,
            controllerAs: 'pagesEditorIcons',
            bindToController: true,
            scope: {
                icon: '=icon',
                type: '=type'
            }
        };
    }

    function PagesEditorIconsController($scope) {
        const vm = this;

        vm.icons = [
            'account_circle',
            'announcement',
            'accessibility',
            'card_giftcard',
            'home',
            'info_outline',
            'language'
        ];

        vm.positions = [
            'top', 'right', 'bottom', 'left'
        ];

        if (vm.type === 'header') {
            vm.positions = [
                'right', 'left'
            ];
            vm.icon.position = 'right';
            vm.icon.size = 18;
        }

        vm.sizes = [
            '18', '24', '36', '48'
        ];

        vm.setIcon = setIcon;

        function setIcon(icon) {
            vm.icon.content = icon || '';
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tariffDetailsTab', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/new/tabs/tariff-details-tab/tariff-details-tab.tpl.html',
            controller: TariffDetailsTabController,
            bindings: {
                tariffData: '<',
                newTariffForm: '<',
            }
        });

    function TariffDetailsTabController(
        $scope,
        $translate,
        LanguageService,
        StatesFactory,
        UtilitiesService,
        QUOTA_UNIT_TYPES,
    ) {
        const vm = this;

        const currentLocale = $translate.use();
        vm.language_code = currentLocale;

        vm.state = StatesFactory.TariffStates;
        vm.QUOTA_UNIT_TYPES = QUOTA_UNIT_TYPES;

        vm.$onInit = init;

        vm.onTimeWindowChange = onTimeWindowChange;
        vm.setQuotaValue = setQuotaValue;

        function init() {
            vm.showError = UtilitiesService.inputValidator(vm.newTariffForm);

            LanguageService.getLanguages().then((response) => {
                const filledLanguages = Object.keys(vm.tariffData.name);
                vm.langArray = response.plain();

                if (filledLanguages.length && !filledLanguages.includes(vm.language_code)) {
                    vm.language_code = filledLanguages[0];
                }
            });
        }

        function setQuotaValue(name){
            vm.tariffData.quota.params[name] =
                name === 'quotaPrice'
                    ? vm.tariffData[name]
                    : vm.tariffData.validityDuration[name];
        }

        function onTimeWindowChange(timeWindow, timeWindowRef) {
            if (timeWindowRef) {
                vm.tariffData.timeWindowRef = timeWindowRef;
                vm.tariffData.timeWindow = timeWindow;

                return;
            }


            if (timeWindow && timeWindow.id) {
                delete timeWindow.id;
            }
            vm.tariffData.timeWindow = timeWindow;
            delete vm.tariffData.timeWindowRef;
        }

        function canNext() {
            vm.state.canNext = vm.newTariffForm
                && vm.newTariffForm.$valid;
        }

        $scope.$watch(function() {
            return vm.newTariffForm && vm.newTariffForm.$valid;
        }, canNext);
    }
})();
(function() {
    'use strict';

    angular.module('trmTimetableHafas').service('TimeElementService',
        function (
            $moment,
        ) {
            return {
                isDateAvailable,
            };

            /**
             * @param {moment.Moment} value
             * @param {TimetableHafasElement} element
             * @return {boolean}
             */
            function isDateAvailable(value, element) {
                if (!value) {
                    return false;
                }

                const todayEnd = $moment().endOf('day');
                const isToday = value.isBefore(todayEnd);
                const isClockFormat = element.timeFormat === 'hh:mm';

                return element.showDate && !isToday && isClockFormat;
            }
        });
})();

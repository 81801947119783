(function() {
    'use strict';

    angular.module('capCapacity')
        .component('capCapacity', {
            templateUrl: '/assets/views/components/cap-capacity/cap-capacity.tpl.html',
            controller: CapCapacityController,
            bindings: {
                tenantId: '<',
                carParkId: '<',
            }
        });

    /**
     * @property {string} tenantId
     * @property {string} carParkId
     *
     * @param $scope
     * @param $interval
     * @param CapacityApiService
     * @constructor
     */
    function CapCapacityController(
        $scope,
        $interval,
        CapacityApiService,
    ) {
        const $ctrl = this;
        const REFRESH_INTERVAL_MS = 15000;

        /**
         * @type {CapacityAlert[]}
         */
        $scope.capacities = [];
        $scope.carParkName = '';

        $ctrl.$onInit = () => {
            _initCapacity();
        }

        /**
         * @param {CapacityAlert} capacity
         */
        $scope.isWarning = capacity => {
            return capacity.capacityLookahead < capacity.alertThreshold;
        }

        function _initCapacity() {
            return _loadCapacity().then(
                () => {
                    $interval(() => _loadCapacity(), REFRESH_INTERVAL_MS);
                }
            );
        }

        function _loadCapacity() {
            return CapacityApiService
                .getCapacity($ctrl.carParkId, $ctrl.tenantId)
                .then(response => {
                    $scope.capacities = response;
                    $scope.carParkName = response.find(item => item.capName).capName;
                });
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('tariffAssignmentsTab', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/new/tabs/tariff-assignments-tab/tariff-assignments-tab.tpl.html',
            controller: TariffAssignmentsTabController,
            bindings: {
                tariffData: '<',
                updateCallback: '<',
            }
        });

    function TariffAssignmentsTabController(
        $scope,
        $translate,
        PricingDataService,
        StatesFactory,
    ) {
        const vm = this;

        const currentLocale = $translate.use();
        const { getDiscounts, getProducts } = PricingDataService;

        vm.state = StatesFactory.TariffStates;
        vm.discounts = []
        vm.products = []
        vm.selectedDiscounts = []
        vm.selectedProducts = [];
        vm.productsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'product-name',
                    width: '90',
                    title: product => product.name[currentLocale] || Object.values(product.name)[0],
                },
            ],
            header: false,
            itemClickCallback: updateAssignments
        };
        vm.discountsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'discount-name',
                    width: '90',
                    title: discount => discount.name[currentLocale] || Object.values(discount.name)[0],
                },
            ],
            header: false,
            itemClickCallback: updateAssignments
        };

        vm.$onInit = init;

        function init() {
            vm.state.canFinish = true;
            const selectedProductsIds = [
                ... new Set(vm.tariffData.capProductAssignment.map(({capProductRef}) =>
                    capProductRef.id
                ))
            ];

            const selectedDiscountsIds = [
                ... new Set(vm.tariffData.capRateDiscountAssignment.map(({capRateDiscountType}) =>
                    capRateDiscountType.id
                ))
            ];

            Promise.all([
                getDiscounts(),
                getProducts()
            ]).then(([discountsResponse, productsResponse]) => {
                vm.discounts = discountsResponse.plain()
                    .map(discount => {
                        discount.name = angular.fromJson(discount.name);
                        return discount;
                    });
                vm.products = productsResponse.plain()
                    .map(product => {
                        product.name = angular.fromJson(product.name);
                        return product;
                    });

                vm.selectedProducts = vm.products.filter(product => selectedProductsIds.includes(product.id));
                vm.selectedDiscounts = vm.discounts.filter(discount => selectedDiscountsIds.includes(discount.id));

                updateAssignments();
                $scope.$digest();
            })
        }

        function updateAssignments() {
            vm.updateCallback(vm.selectedProducts, vm.selectedDiscounts);
        }
    }
})();
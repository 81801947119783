(function() {
    'use strict';

    angular.module('beacon.app')
        .component('iotEntityDescription', {
            controller: IotEntityDescriptionController,
            templateUrl: '/assets/views/common/components/iot-entity-description/iot-entity-description.tpl.html',
            bindings: {
                iotEntity: '<',
                iotEntityTypes: '<',
            },
        });

    function IotEntityDescriptionController() {
        const vm = this;

        vm.getTypeName = getTypeName;

        function getTypeName() {
            return vm.iotEntityTypes && vm.iotEntityTypes.find(type => vm.iotEntity.entityTypeRef === type.id).name;
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app').directive('filterParamsPicker', filterParamsPicker);

    function filterParamsPicker() {
        return {
            restrict: 'EA',
            templateUrl: '/assets/views/common/directives/filter-params-picker/filter-params-picker.tpl.html',
            replace: true,
            controller: FilterParamsPicker,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                filterParams: '=',
                editable: '<'
            }
        };
    }

    function FilterParamsPicker() {
        const vm = this;

        // public methods
        vm.deleteFilterCallback = deleteFilterCallback;

        /**
         * Callback on filter delete
         * @param index
         */
        function deleteFilterCallback(index) {
            vm.filterParams.splice(index, 1);
        }
    }

})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('AudiencesService', audiencesService);

    function audiencesService(
        $timeout,
        $rootScope,
        SEGMENT_TYPES,
        PREFERENCE_TYPE,
    ) {

        const DYNAMIC_RANGES_TYPES = [
            SEGMENT_TYPES.LOYALTY_COUNTER_RANGE,
            SEGMENT_TYPES.LOYALTY_ACCOUNT_RANGE,
            SEGMENT_TYPES.SALES,
            SEGMENT_TYPES.PREDEFINED_RANGES,
        ];
        const MIN_RANGE_VALUE = 0;
        const MAX_RANGE_VALUE = 100;
        /**
         * Exports
         */
        return {
            decodeFilterValueParams,
            isDynamicRangeType,
            isGroupSegmentationDataSelected,
            isRandomAudienceTypeSelected,
            getLimits,
            mergeAudiences,
            removeTitlePreferenceValues,
            updateSlider,
        };

        function isDynamicRangeType(audience) {
            const DYNAMIC_RANGE_INDEX = -1;
            if (!DYNAMIC_RANGES_TYPES.includes(audience.categoryType)) {
                return false;
            }

            return [SEGMENT_TYPES.SALES, SEGMENT_TYPES.PREDEFINED_RANGES].includes(audience.categoryType) ||
                parseInt(audience.filterValues[0].elementIndex) === DYNAMIC_RANGE_INDEX;
        }

        /**
         * Returns dynamic range limits
         * @param {object} audience
         * @return {object}
         */
        function getLimits(audience) {
            if (audience.filterValues[0].filterValueParams.rangeLimits) {
                return JSON.parse(audience.filterValues[0].filterValueParams.rangeLimits);
            } else {
                return {
                    minValue: MIN_RANGE_VALUE,
                    maxValue: MAX_RANGE_VALUE,
                    editable: true
                };
            }
        }

        /**
         * Checks if one of groups predefined audiences is selected
         * @param groupAudienceOptions
         * @param selectedAudienceOptions
         * @return {boolean}
         */
        function isGroupSegmentationDataSelected(groupAudienceOptions, selectedAudienceOptions) {
            let isGroupSelected = true;
            if (!_.isEmpty(groupAudienceOptions.audienceValues)) {
                isGroupSelected = !_.difference(groupAudienceOptions.audienceValues, selectedAudienceOptions.audienceValues).length;
            }

            isGroupSelected = isGroupSelected &&
                groupAudienceOptions.audienceLocationWhen === selectedAudienceOptions.audienceLocationWhen &&
                Object.keys(groupAudienceOptions.audienceLocationTypes).every(key => {
                return !!groupAudienceOptions.audienceLocationTypes[key] === !!selectedAudienceOptions.audienceLocationTypes[key];
            });

            if (isGroupSelected && !_.isEmpty(groupAudienceOptions.audienceStars)) {
                isGroupSelected = Object.keys(groupAudienceOptions.audienceStars).every(key => {
                    return !_.difference(groupAudienceOptions.audienceStars[key], selectedAudienceOptions.audienceStars[key]).length;
                });
            }
            if (isGroupSelected && !_.isEmpty(groupAudienceOptions.preferenceData)) {
                isGroupSelected = isPreferencesDataSelected(groupAudienceOptions.preferenceData, selectedAudienceOptions.preferenceData);
            }
            if (isGroupSelected && groupAudienceOptions.rangesData) {
                isGroupSelected = isRangeDataSelected(groupAudienceOptions.rangesData, selectedAudienceOptions.rangesData);
            }
            return isGroupSelected;
        }

        /**
         * Checks if range data is selected
         * @param groupRangeData
         * @param selectedRangeData
         * @return {boolean}
         */
        function isRangeDataSelected(groupRangeData, selectedRangeData) {
            if (groupRangeData.outOfRange && selectedRangeData.outOfRange) {
                return groupRangeData.minValue <= selectedRangeData.minValue &&
                    groupRangeData.maxValue >= selectedRangeData.maxValue;
            } else if (groupRangeData.outOfRange === selectedRangeData.outOfRange) {
                return groupRangeData.minValue >= selectedRangeData.minValue &&
                    groupRangeData.maxValue <= selectedRangeData.maxValue;
            }
            return false;
        }

        /**
         * Checks if currently selected audience is random type
         * @return boolean
         */
        function isRandomAudienceTypeSelected(audiencesData) {
            const selectedAudienceIds = Object.keys(audiencesData);
            return selectedAudienceIds.length === 1 && !_.isEmpty(audiencesData[selectedAudienceIds[0]].preselectedAudiencesData);
        }

        /**
         * Check if group preference data is selected
         * @param groupPreferenceData
         * @param selectedPreferenceData
         */
        function isPreferencesDataSelected(groupPreferenceData, selectedPreferenceData) {
            return Object.keys(groupPreferenceData).every(key => {
                const groupPreference = groupPreferenceData[key];
                const selectedPreference = selectedPreferenceData[key];

                if (groupPreference && selectedPreference) {
                    if (angular.isDefined(groupPreference.value)) {
                        return groupPreference.value === selectedPreference.value;
                    } else if (angular.isDefined(groupPreference.values)) {
                        return !_.difference(groupPreference.values, selectedPreference.values).length;
                    } else {
                        return isRangeDataSelected(groupPreference.rangeData, selectedPreference.rangeData)
                    }
                } else {
                    return false;
                }
            })
        }

        /**
         * Customizer function for merging audiences data using _.mergeWith
         *
         * @param destinationObj
         * @param sourceObject
         * @return {*}
         */
        function mergeAudiences(destinationObj, sourceObject) {
            if (!_.isEmpty(destinationObj) && !_.isEmpty(sourceObject)) {
                if (_.isArray(destinationObj)) {
                    return _.union(destinationObj, sourceObject);
                }
                if (angular.isDefined(destinationObj.minValue) && angular.isDefined(destinationObj.maxValue)) {
                    const result = {outOfRange: destinationObj.outOfRange};
                    if (destinationObj.outOfRange && sourceObject.outOfRange) {
                        result.minValue = (destinationObj.minValue > sourceObject.minValue) ?
                            destinationObj.minValue : sourceObject.minValue;
                        result.maxValue = (destinationObj.maxValue < sourceObject.maxValue) ?
                            destinationObj.maxValue : sourceObject.maxValue;
                    } else if (destinationObj.outOfRange || sourceObject.outOfRange) {
                        return sourceObject;
                    } else {
                        result.minValue = (destinationObj.minValue < sourceObject.minValue) ?
                            destinationObj.minValue : sourceObject.minValue;
                        result.maxValue = (destinationObj.maxValue > sourceObject.maxValue) ?
                            destinationObj.maxValue : sourceObject.maxValue;
                    }
                    return result;
                }
            }
        }

        /**
         * Decoding audience filter value parameters,
         * eg. filterValueParams = "foo==bar" is becoming
         *     filterValueParams = {foo: bar}
         *
         * @param {array} audienceData
         */
        function decodeFilterValueParams(audienceData) {
            return audienceData.map(audienceOptionValue => {
                audienceOptionValue.filterValues.map(filterValue => {
                    if (filterValue.filterValueParams && angular.isString(filterValue.filterValueParams)) {
                        if (audienceOptionValue.categoryType === SEGMENT_TYPES.PREFERENCES) {
                                filterValue.filterValueParams = angular.fromJson(filterValue.filterValueParams);
                        } else {
                            const paramsEncodedArray = filterValue.filterValueParams.split('|');
                            const paramsDecoded = {};
                            paramsEncodedArray.map(paramEncoded => {
                                const paramArray = paramEncoded.split('==');
                                if (paramArray[0] && paramArray[1]) {
                                    paramsDecoded[paramArray[0]] = paramArray[1];
                                }
                            });
                            filterValue.filterValueParams = paramsDecoded;
                            return filterValue;
                        }
                    }
                });
                return audienceOptionValue;
            });
        }

        /**
         * Removes preference values with type TITLE from filter values list
         * @param audienceOptions
         */
        function removeTitlePreferenceValues(audienceOptions) {
            audienceOptions.forEach(option => {
                if (option.categoryType === SEGMENT_TYPES.PREFERENCES) {
                    option.filterValues = option.filterValues.filter(filterValue => {
                        return filterValue.filterValueParams.type !== PREFERENCE_TYPE.TITLE;
                    })
                }
            })
        }

        /**
         * Update slider broadcast
         */
        function updateSlider() {
            $timeout(function () {
                $rootScope.$broadcast('rzSliderForceRender');
            });
        }
    }
})();
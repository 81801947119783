(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewPushCampaignController', NewPushCampaignController);

    function NewPushCampaignController($scope, $state, $stateParams, campaignPushState, CampaignDataService, StorageFactory, CAMPAIGN_TYPES) {
        const vm = this;
        const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

        // public properties
        vm.state = campaignPushState;
        vm.ALL_DAYS_FLAG = 9;

        vm.campaignModel = {
            content: null,
            clickOptions: {},
            audiences: [],
            audiencesObject: {},
            selectedDays: [],
            name: '',
            description: '',
            title: {},
            text: {},
            icon_src: {},
            image_src: {},
            icon: {},
            image: {},
            push_date: null,
            started_at: null,
            finished_at: null,
            copy: null,
            apps: [],
            locations: [],
        };

        init();

        // private methods
        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.STORAGE = new StorageFactory.Storage('PushCampaign', true);

            if ($stateParams.data) {
                vm.campaignModel = Object.assign({}, vm.campaignModel, $stateParams.data);
            }

            const locations = vm.campaignModel.locations;
            vm.campaignModel.pushToScreens = !!(locations && locations.length);
        }

        /**
         * Finishing of creation/edition campaign callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onFinish(newValue, oldValue) {
            if (newValue) {
                const {
                    name,
                    description,
                    title,
                    text,
                    icon_src,
                    image_src,
                    icon,
                    image,
                    push_date,
                    copy,
                    apps,
                    pushImmediately,
                    locations,
                    pushToScreens,
                    config,
                } = vm.campaignModel;

                const clickOptions = Object.keys(vm.campaignModel.clickOptions).length !== 0 ? vm.campaignModel.clickOptions : null;

                clickOptions.case = parseInt(clickOptions.case);

                const filledLanguages = Object.keys(title);
                const setEmptyLanguages = data => {
                    filledLanguages.forEach(langId => {
                        data[langId] = data[langId] || null;
                    });
                    return data;
                };

                const campaign = {
                    name,
                    description,
                    audiences: angular.isObject(vm.campaignModel.audiencesData) ?
                        JSON.stringify(vm.campaignModel.audiencesData) : (vm.campaignModel.audiencesData || ''),
                    contents: !_.isNil(vm.campaignModel.content) ? [vm.campaignModel.content.id] : [],
                    locations: pushToScreens ? locations.map(screen => screen.locationId) : [],
                    campaign_type: CAMPAIGN_TYPES.PUSH,
                    show_days: [vm.ALL_DAYS_FLAG],
                    additional_data: {
                        campaignContent: {
                            title,
                            text: setEmptyLanguages(text),
                            icon_src: setEmptyLanguages(icon_src),
                            image_src: setEmptyLanguages(image_src),
                            config,
                        },
                        clickOptions,
                        apps: apps.join(','),
                    },
                    copy,
                    icon,
                    image,
                    started_at: !pushImmediately && push_date
                        ? moment(push_date).utc().format(DATE_FORMAT)
                        : moment().utc().format(DATE_FORMAT),
                    finished_at: !pushImmediately && push_date
                        ? moment(push_date).utc().format(DATE_FORMAT)
                        : moment().utc().format(DATE_FORMAT),
                };

                const formData = _makeFormData(campaign);

                if (vm.state.type === 'new') {
                    CampaignDataService.create(formData)
                        .then(() => {
                            $state.go('app.pushCampaign', {
                                paginationData: $stateParams.paginationData
                            });
                        })
                        .catch((err) => { throw err; });
                } else if (vm.state.type === 'edit') {
                    CampaignDataService.campaign(vm.campaignModel.id)
                        .then((campaign) => {
                            CampaignDataService.update(campaign, formData)
                                .then((campaign) => {
                                    $state.go('app.pushCampaign', {
                                        paginationData: $stateParams.paginationData
                                    });
                                })
                                .catch((err) => { throw err; });
                        });

                }
            }
        }

        /**
         * Generate form data object
         *
         * @param campaign
         * @private
         */
        function _makeFormData(campaign) {
            const nativeFormArrays = ['icon', 'image'];
            const comaSeparated = ['contents', 'locations'];
            const formData = new FormData();

            Object.keys(campaign).forEach(prop => {
                switch(true) {
                    case nativeFormArrays.includes(prop):
                        Object.keys(campaign[prop]).forEach(langId => {
                            formData.append(`${prop}[${langId}]`, campaign[prop][langId]);
                        });
                        break;
                    case comaSeparated.includes(prop):
                        formData.append(prop, campaign[prop].join(','));
                        break;
                    case angular.isArray(campaign[prop]) || angular.isObject(campaign[prop]):
                        formData.append(prop, JSON.stringify(campaign[prop]));
                        break;
                    default:
                        formData.append(prop, campaign[prop]);
                }
            });

            return formData;
        }

        /**
         * View change callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onViewChange(newValue, oldValue) {
            switch (newValue) {
                case vm.state.views.CONTENT:
                    vm.state.canProcessNext = !!vm.campaignModel.contents.length;
                    break;

                default:
                    break;
            }
        }

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.state.view;
        }), onViewChange);

        /**
         *
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.state.finish;
        }), onFinish);
    }

}());

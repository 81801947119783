(function () {
    'use strict';

    angular
        .module('beacon.app')
        .service('ImageApiService', ImageApiService);

    function ImageApiService($http, Restangular) {
        return {
            uploadImage,
            deleteImage,
        };

        /**
         * @param {File} image
         * @param {string|null} uploadDirMode - used for the separate directory placement on the S3. Details in ContentController->uploadImage(...)
         */
        function uploadImage(image, uploadDirMode = null) {
            const formData = new FormData();
            formData.append('image', image, image.name);

            if (uploadDirMode) {
                formData.append('mode', uploadDirMode);
            }
            return $http.post(`/api/upload`, formData, {
                headers: { 'Content-Type': undefined }
              });
        }

        /**
         * @param {string} s3Url
         */
        function deleteImage(s3Url) {
            return $http.post(`/api/deleteImages`, { images: s3Url });
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('translationTool', {
            templateUrl: '/assets/views/translation-tool/translation-tool.tpl.html',
            controller: TranslationToolController,
        });

    function TranslationToolController(
        $http,
        $window,
        $scope,
        PopupService,
        GoogleTranslateService,
        LanguageService,
    ) {
        const vm = this;

        const FORMAT = {
            WEB_APP_AND_CMS: 'json',
            IOS: 'strings',
            ANDROID: 'xml',
        };

        /**
         * A flag to stop translating fields
         * @type {boolean}
         */
        let translationInProgress = false;
        /**
         * @type {TranslationToolField[]}
         */
        vm.fields = [];
        vm.languages = [];
        vm.type = null;

        vm.translateOptionsActive = false;
        vm.lngSource = null;
        vm.lngTarget = null;

        vm.status = 'Ready';

        vm.$onInit = init;

        vm.saveProgress = _saveProgress;
        vm.onUploadClick = onUploadClick;
        vm.onDownloadClick = onDownloadClick;
        vm.onTranslateClick = onTranslateClick;
        vm.onFileUpload = onFileUpload;
        vm.onInputChange = onInputChange;
        vm.onCopyClick = onCopyClick;
        vm.onTranslateOpen = onTranslateOpen;

        function init() {
            LanguageService.getLanguagesAll()
                .then(response => {
                    vm.languages = response.plain();
                });
            _restoreProgress();
        }

        function onUploadClick() {
            const fileControl = $window.document.getElementById('file');
            fileControl.click();
        }

        /**
         * @param {FileList} files
         */
        function onFileUpload(files) {
            vm.fields = [];
            vm.status = 'Parsing file...';
            $scope.$apply();

            const file = files.item(0);
            const fileExtension = file.name.split('.').pop().toLowerCase();

            file.text().then(fileContents => {
                switch (fileExtension) {
                    case FORMAT.WEB_APP_AND_CMS:
                        vm.type = FORMAT.WEB_APP_AND_CMS;
                        const originItems = JSON.parse(fileContents);
                        Object.keys(originItems).forEach(originKey => {
                            vm.fields.push(_parseJsonItem(originKey, originItems[originKey]));
                        });
                        break;
                    case FORMAT.IOS:
                        vm.type = FORMAT.IOS;
                        const results = fileContents.match(/"(.+)".*=.*"(.+)"/gm);
                        results.forEach(line => {
                            vm.fields.push(_parseIosLine(line));
                        })
                        break;
                    case FORMAT.ANDROID:
                        vm.type = FORMAT.ANDROID;
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(fileContents,'text/xml');
                        const searchSelector = 'string, plurals, string-array';
                        const elements = xmlDoc.querySelectorAll(searchSelector);

                        elements.forEach(element => {
                            const item = _parseXmlElement(element);
                            vm.fields.push(item);
                        })
                        break;
                    default:
                        PopupService.showAlertPopup({
                            text: 'WRONG_FILE_FORMAT',
                            okButtonText: 'OK',
                        });
                }
                _saveProgress();
                _forceUpdateView();
                vm.status = 'Ready';
            })
        }

        function onDownloadClick() {
            switch (vm.type) {
                case FORMAT.WEB_APP_AND_CMS:
                    const resultWebCms = {};
                    vm.fields.forEach(field => {
                        const {key, value} = _makeJsonItem(field);
                        resultWebCms[key] = value;
                    });
                    _download('translation.json', JSON.stringify(resultWebCms, null, 4));
                    break;
                case FORMAT.IOS:
                    const resultIOS = [];
                    vm.fields.forEach(field => {
                        resultIOS.push(`"${field.id}" = "${field.newValue}";`);
                    });
                    _download('translation.strings', resultIOS.join("\n"));
                    break;
                case FORMAT.ANDROID:
                    const xmlDoc = document.implementation.createDocument(null, 'resources', null);
                    vm.fields.forEach(field => {
                        xmlDoc.documentElement.appendChild(_makeXmlElement(field, xmlDoc));
                    });
                    const string = new XMLSerializer().serializeToString(xmlDoc);
                    _download('translation.xml', _formatXml(string));
                    break;
                default:
                    window.alert('Not implemented');
            }
        }

        function onTranslateOpen() {
            vm.translateOptionsActive = true;
        }

        function onTranslateClick() {
            vm.translateOptionsActive = false;
            translationInProgress = true;

            let translated = 0;

            const increaseLoader = () => {
                translated += 1;
                vm.status = translated < vm.fields.length
                    ? `Translating: ${translated}/${vm.fields.length}. Press ESC to cancel`
                    : 'Ready';
                $scope.$apply();
            }

            const htmlDecode = str => {
                const e = document.createElement('textarea');
                e.innerHTML = str;
                return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
            }

            const translateField = field => {
                if (angular.isString(field.originValue)) {
                    return GoogleTranslateService.translate(field.originValue, vm.lngSource, vm.lngTarget, true)
                        .then(translation => {
                            field.newValue = htmlDecode(translation);
                            _replaceWrongValues(field);
                            _validateField(field);
                            _saveProgress();
                        });
                } else {
                    return Promise.resolve();
                }
            }

            const translateFields = (fields, incrementStatus = false) => {
                return _forEachAsync(fields, (field, index) => {
                    if (incrementStatus) {
                        increaseLoader();
                    }

                    if (field.disabled) {
                        return Promise.resolve();
                    }

                    if (translationInProgress) {
                        return translateField(field)
                            .then(() => field.children.length ? translateFields(field.children) : Promise.resolve());
                    }
                });
            }

            translateFields(vm.fields, true);
        }

        function onEscapeClick() {
            if (translationInProgress) {
                translationInProgress = false;
            }
        }

        function onCopyClick() {
            vm.fields.forEach(field => {
                field.newValue = field.originValue;
                _validateField(field);

                field.children.forEach(child => {
                    child.newValue = child.originValue;
                    _validateField(child);
                })
            });
            _saveProgress();
        }

        function onInputChange(field) {
            _saveProgress();
            _validateField(field);
        }

        /**
         * @param {TranslationToolField} field
         * @private
         */
        function _validateField(field) {
            if (!angular.isString(field.originValue)) {
                return;
            }
            const countPattern = (str, pattern) => (str.match(pattern) || []).length;

            // The amount of such patterns should be the same in the source and in the result values
            const patterns = [
                /\\n/g,
                /%s/g,
                /%d/g,
                /<!\[CDATA\[/g,
                /]]>/g
            ];
            field.error = !!patterns.find(
                pattern => countPattern(field.originValue, pattern) !== countPattern(field.newValue, pattern)
            );
        }

        function _replaceWrongValues(field) {
            field.newValue = field.newValue
                .replaceAll('% s', ' %s')
                .replaceAll('% d', ' %d')
                // stupid workaround
                .replaceAll('\ n', '\=======n')
                .replaceAll('\ N', '\=======n')
                .replaceAll('=======', '');
        }

        /**
         * @param {string} filename
         * @param {string} text
         * @private
         */
        function _download(filename, text) {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }

        /**
         *
         * @param {TranslationToolField} field
         * @param xmlDoc
         * @return {Element}
         * @private
         */
        function _makeXmlElement(field, xmlDoc) {
            const element = xmlDoc.createElement(field.tag);

            if (field.attributes) {
                field.attributes.forEach(attribute => {
                    element.setAttribute(attribute.name, attribute.value);
                });
            }

            if (field.children.length) {
                field.children.forEach(child => {
                    element.appendChild(_makeXmlElement(child, xmlDoc));
                })
            } else {
                element.innerHTML = field.newValue;
            }
            return element;
        }

        /**
         * {TranslationToolField} field
         * @return {{value: string|object, key: string}}
         * @private
         */
        function _makeJsonItem(field) {
            const result = {
                key: field.id,
                value: null,
            };
            if (field.children.length) {
                result.value = {};
                field.children.forEach(child => {
                    const {key, value} = _makeJsonItem(child);
                    result.value[key] = value;
                })
            } else {
                result.value = field.newValue;
            }
            return result;
        }

        /**
         *
         * @param {string} key
         * @param {string} value
         * @return {TranslationToolField}
         * @private
         */
        function _parseJsonItem(key, value) {
            if (angular.isObject(value)) {
                const result = {
                    id: key,
                    originValue: false,
                    newValue: false,
                    children: [],
                }
                Object.keys(value).forEach(key => {
                    result.children.push(_parseJsonItem(key, value[key]));
                })
                return result;
            } else {
                return {
                    id: key,
                    originValue: value,
                    newValue: '',
                    children: [],
                }
            }
        }

        /**
         *
         * @param {string} line
         * @return {TranslationToolField}
         * @private
         */
        function _parseIosLine(line) {
            const [key, value] = line.split('=')
                .map(item => item.trim().replace(/^"|"$/g, ''));

            return {
                id: key,
                originValue: value,
                newValue: '',
                children: [],
            }
        }

        /**
         * @param {Element} element
         * @private
         */
        function _parseXmlElement(element) {
            const key = element.getAttribute('name');
            const value = element.innerHTML;

            const item = {
                id: key,
                tag: element.tagName,
                attributes: _simplifyXmlAttributes(element.attributes),
                originValue: null,
                newValue: null,
                disabled: element.getAttribute('translatable') === 'false',
                children: [],
            }

            if (element.children.length) {
                item.originValue = false;
                item.newValue = false;

                for (let i = 0; i < element.children.length; i++) {
                    const child = element.children[i];
                    item.children.push({
                        id: child.getAttribute('name') || child.getAttribute('quantity') || null,
                        tag: child.tagName,
                        attributes: _simplifyXmlAttributes(child.attributes),
                        originValue: child.innerHTML,
                        newValue: '',
                        disabled: element.getAttribute('translatable') === 'false',
                        children: [],
                    })
                }
            } else {
                item.originValue = value;
                item.newValue = item.disabled ? value : '';
            }
            return item;
        }

        /**
         * @param {NamedNodeMap} attributes
         * @return {{name: string, value: string}[]}
         * @private
         */
        function _simplifyXmlAttributes(attributes) {
            const result = [];
            for (let i = 0; i < attributes.length; i++) {
                result.push({
                    name: attributes[i].name,
                    value: attributes[i].value,
                })
            }
            return result;
        }

        function _saveProgress() {
            $window.localStorage.translationToolProgress = angular.toJson(vm.fields);
            $window.localStorage.translationToolType = vm.type;
        }

        function _restoreProgress() {
            vm.fields = angular.fromJson($window.localStorage.translationToolProgress) || [];
            vm.type = $window.localStorage.translationToolType || null;
        }

        function _forceUpdateView() {
            setTimeout(() => {
                $scope.$apply();
            });
        }

        function _formatXml(xml) {
            let formatted = '';
            const reg = /(>)(<)(\/*)/g;
            xml = xml.replace(reg, '$1\r\n$2$3');
            let pad = 0;
            jQuery.each(xml.split('\r\n'), function(index, node) {
                let indent = 0;
                if (node.match( /.+<\/\w[^>]*>$/ )) {
                    indent = 0;
                } else if (node.match( /^<\/\w/ )) {
                    if (pad !== 0) {
                        pad -= 1;
                    }
                } else if (node.match( /^<\w([^>]*[^\/])?>.*$/ )) {
                    indent = 1;
                } else {
                    indent = 0;
                }

                let padding = '';
                for (let i = 0; i < pad; i++) {
                    padding += '  ';
                }

                formatted += padding + node + '\r\n';
                pad += indent;
            });

            return formatted;
        }

        function _forEachAsync(arr, fn) {
            return arr.reduce((prom, val, idx, arr) =>
                prom.finally(() => fn(val, idx, arr)),
                Promise.resolve()
            );
        }

        document.onkeydown = function(evt) {
            evt = evt || window.event;
            let isEscape = false;
            if ('key' in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape) {
                onEscapeClick();
            }
        };
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('lineRoutePage', {
            templateUrl: '/assets/views/content/elements/types/digital-display/page-types/internal-app/line-route/line-route.tpl.html',
            controller: LineRoutePageCtrl,
            bindings: {
                contentData: '=',
                pageData: '<'
            }
        });

    function LineRoutePageCtrl(
        $scope,
        ContentDataService,
        LineRouteDataService,
        LineRoutePopup,
        CONTENT_TYPES
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.selectedLineRoute = null;
        vm.lineRoutes = [];

        vm.openPreview = openPreview;

        /**
         * Initialization method
         */
        function init() {
            getLineRoutes();
        }

        /**
         * Gets line routes from the server
         */
        function getLineRoutes() {
            ContentDataService.contents({contentTypeId: CONTENT_TYPES.LINE_ROUTE})
                .then(response => {
                    vm.lineRoutes = processLineRoutes(response.plain());
                    vm.selectedLineRoute = findSelectedItem();
                });
        }

        /**
         * Decodes line routes data
         * @param lineRoutes
         * @return { array }
         */
        function processLineRoutes(lineRoutes) {
            return lineRoutes.map(lineRoute => {
                lineRoute.title = angular.fromJson(lineRoute.title);
                lineRoute.message = angular.fromJson(lineRoute.message);
                return lineRoute;
            })
        }

        /**
         * Finds selected line route
         * @return { null | object }
         */
        function findSelectedItem() {
            const selectedId = vm.pageData.selectedContentId;

            return selectedId
                ? vm.lineRoutes.find(lineRoute => lineRoute.id === selectedId)
                : null;
        }

        /**
         * Opens popup with preview
         * @param previewType
         */
        function openPreview(previewType) {
            LineRoutePopup.showPreview(previewType, vm.selectedLineRoute.message, vm.lineRouteDeparturesDetails);
        }

        /**
         * On finish click handler
         */
        function onFinish(event, option) {
            if (!vm.selectedLineRoute) {
                return;
            }

            vm.pageData.selectedContentId = vm.selectedLineRoute.id;
            vm.pageData.page = Object.assign(vm.pageData.page, vm.selectedLineRoute.message);

            if (typeof option.callback === 'function') {
                option.callback({
                    pageData: vm.pageData
                });
            }
        }

        $scope.$on('digital-display-savepage', onFinish);
    }
})();
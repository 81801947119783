(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('ServiceProviderBillsPopupController', ServiceProviderBillsPopupController);

    function ServiceProviderBillsPopupController(
        $uibModalInstance,
        serviceProviderExternalId,
        ServiceProviderBillsService,
        PdfPopupService,
        FileSizeHelper,
    ) {
        const vm = this;

        vm.$onInit = init;
        vm.onDownload = onDownload;

        /**
         * @type {ServiceProviderBill[]}
         */
        vm.bills = [];

        function init() {
            ServiceProviderBillsService.getBills(serviceProviderExternalId)
                .then(bills => {
                    vm.bills = bills.map(bill => {
                        bill.file_size_human = FileSizeHelper.humanFileSize(bill.file_size);
                        return bill;
                    })
                });
        }

        function onDownload(bill) {
            ServiceProviderBillsService.getBill(bill.id)
                .then(response => {
                    const file = new Blob([response], {type: 'application/pdf'});
                    PdfPopupService.showBlob(file);
                });
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newCompany', {
            templateUrl: '/assets/views/share-park/customers/companies/new/new-company.tpl.html',
            controller: NewCompanyController
        });

    function NewCompanyController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        Restangular,
        StatesFactory,
        ExternalCompaniesDataService,
        PopupService,
        ShareParkDataService,
        UtilitiesService,
        UserUtilitiesService,
        DATETIME_FORMAT,
        SHARE_PARK_CUSTOMER_STATUS,
        VEHICLE_TYPES,
    ) {
        const vm = this;

        const {
            createExternalCompany,
            updateExternalCompany,
            getExternalCompanyById,
        } = ExternalCompaniesDataService;

        const timeZoneId = UserUtilitiesService.timeZoneId();

        vm.state = StatesFactory.ShareParkCompaniesStates.refresh();
        vm.state.type = $stateParams.type;
        vm.carParks = [];
        vm.selectedCarParks = [];

        vm.vehiclesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'vehicle-name',
                    width: '30',
                    title: item =>  {
                        return item.name || '';
                    }
                },
                {
                    name: 'NUMBER_PLATE',
                    width: '25',
                    title: item =>  {
                        return item.number_plate || '';
                    }
                },
                {
                    name: 'BRAND',
                    width: '25',
                    title: item =>  {
                        return item.vehicle_brand || '';
                    }
                }
            ],
            buttons: {
                width: '20',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteVehicle,
                    },
                    {
                        class: 'printBtn',
                        callback: printVehiclePermission,
                        isVisible: (btn, item) => item.permission
                    },
                ],
            },
            updateCallback: () => {},
            additionalData: {
                VEHICLE_TYPES
            }
        };

        vm.carParksListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'car-park-title',
                    width: '55',
                    title: 'name',
                },
                {
                    name: 'SUB_AREA',
                    width: '45',
                    title: item => {
                        if (!item.related_sub_area) {
                            return ''
                        }

                        const relatedSubArea = item.sub_areas.find(subArea => subArea.id === item.related_sub_area);
                        return relatedSubArea
                            ? `${getDefaultTranslation(relatedSubArea.label)} (${relatedSubArea.type})`
                            : '';
                    },
                },
                {
                    width: '5',
                    title: item => `<md-icon class="material-icons" role="img">${item.isOpen ? 'expand_less' : 'expand_more'}</md-icon>`,
                }
            ],
            updateCallback: () => {},
            needCheckbox: true,
            checkboxClickCallback: selectCarParkCallback,
            additionalData: {
                previewLayout: 'column',
                getDefaultTranslation
            },
            initOnOpen: true
        };

        vm.$onInit = init;

        vm.addVehicle = addVehicle;
        vm.showInputError = showInputError;
        vm.isActive = isActive;
        vm.changeCompanyStatus = changeCompanyStatus;
        vm.canPrintPermissions = canPrintPermissions;
        vm.printAllPermissions = printAllPermissions;
        vm.onDatesChange = onDatesChange;

        function init() {
            vm.isEditMode = vm.state.type === 'edit';

            if (vm.isEditMode) {
                vm.companyData = $stateParams.data;
            } else {
                vm.companyData = new ModelsFactory.ShareParkCompany();
            }

            loadCarParks();
        }

        /**
         * Validation checker for input fields
         *
         * @param {string} fieldName
         * @returns {boolean}
         */
        function showInputError(fieldName) {
            return vm.newCompanyForm
                && UtilitiesService.isValidField(vm.newCompanyForm, fieldName);
        }

        function loadCarParks() {
            ShareParkDataService.getCarParks()
                .then(response => {
                    vm.carParks = response.plain();

                    if (vm.carParks.length && vm.companyData.sub_areas) {
                        vm.selectedCarParks = [];
                        vm.selectedCarParks = vm.carParks.filter(carPark =>
                            vm.companyData.sub_areas.some(({car_park_id}) => car_park_id === carPark.id)
                        );

                        vm.selectedCarParks.forEach(carPark => {
                            carPark.related_sub_area = vm.companyData.sub_areas.find(({car_park_id}) => {
                                return car_park_id === carPark.id;
                            }).id
                        })
                    }
                });
        }

        function getDefaultTranslation(field) {
            return Object.values(field)[0];
        }

        /**
         * Deletes vehicle
         * @param $event
         * @param {object} vehicle
         * @param {number} index
         */
        function deleteVehicle($event, vehicle, index) {
            $event.stopPropagation();
            $event.preventDefault();
            vm.companyData.vehicles.splice(index, 1);
        }

        /**
         * Adds new vehicle
         */
        function addVehicle() {
            vm.companyData.vehicles.push(new ModelsFactory.CapCompanyVehicle());
        }

        function isActive() {
            return vm.companyData.status === SHARE_PARK_CUSTOMER_STATUS.ACTIVE;
        }

        function changeCompanyStatus() {
            vm.companyData.status = isActive()
                ? SHARE_PARK_CUSTOMER_STATUS.INACTIVE
                : SHARE_PARK_CUSTOMER_STATUS.ACTIVE;
        }

        function selectCarParkCallback(item) {
            const isSelected = vm.selectedCarParks.some(carPark => carPark.id === item.id);
            if (isSelected && !item.related_sub_area) {
                item.related_sub_area = item.sub_areas[0].id;
            }
        }

        function printVehiclePermission($event, vehicle, index) {
            $event.stopPropagation();
            $event.preventDefault();

            if (vm.newCompanyForm && vm.newCompanyForm.$dirty) {
                PopupService.showAlertPopup({
                    text: 'YOU_NEED_TO_SAVE_BEFORE_PRINTING_PERMISSIONS',
                    okButtonText: 'OK'
                });

                return;
            }

            const printPage = window.open(
                `${location.origin}/print-qr-code-app/index.html?vehicleHash=${vehicle.permission}`,
                '_blank'
            );

            printPage.onafterprint = () => printPage.close();
            printPage.focus();
        }

        function printAllPermissions() {
            if (vm.newCompanyForm && vm.newCompanyForm.$dirty) {
                PopupService.showAlertPopup({
                    text: 'YOU_NEED_TO_SAVE_BEFORE_PRINTING_PERMISSIONS',
                    okButtonText: 'OK'
                });

                return;
            }

            const printPage = window.open(
                `${location.origin}/print-qr-code-app/index.html?companyHash=${vm.companyData.guid}`,
                '_blank'
            );

            printPage.onafterprint = () => printPage.close();
            printPage.focus();
        }

        function canPrintPermissions() {
            return vm.companyData.vehicles.some(vehicle => vehicle.permission);
        }

        function onDatesChange() {
            vm.newCompanyForm.$setDirty();
        }

        /**
         * On finish handler
         */
        function onFinish(closeEditor) {
            const companyData = angular.copy(vm.companyData);
            const formData = new FormData();

            companyData.valid_from = companyData.valid_from
                ? moment.tz(moment(companyData.valid_from).format(DATETIME_FORMAT), timeZoneId).utc().format(DATETIME_FORMAT)
                : null;

            companyData.valid_till = companyData.valid_till
                ? moment.tz(moment(companyData.valid_till).format(DATETIME_FORMAT), timeZoneId).utc().format(DATETIME_FORMAT)
                : null;
            formData.append('externalCompany', angular.toJson(companyData));
            formData.append('subAreaIds', angular.toJson(vm.selectedCarParks.map(carPark => carPark.related_sub_area)));

            if (!vm.isEditMode) {
                createExternalCompany(formData)
                    .then(response => onSuccess(response, closeEditor));
            } else {
                updateExternalCompany(vm.companyData, formData)
                    .then(response => onSuccess(response, closeEditor))
            }
        }

        function onSuccess(response, closeEditor) {
            if (closeEditor) {
                $state.go('app.shareParkCompanies', {
                    paginationData: $stateParams.paginationData
                });

                return;
            }

            PopupService.showAlertPopup({
                text: 'CAR_POOL_WAS_SAVED',
                okButtonText: 'OK'
            });
            vm.isEditMode = true;

            if (angular.isNumber(response)) {
                getExternalCompanyById(response).then(company => {
                    Restangular.restangularizeElement(null, company, 'sharePark/external-company');
                    vm.companyData = company;
                    vm.companyData.valid_from = company.valid_from ? moment.utc(company.valid_from).tz(timeZoneId) : null;
                    vm.companyData.valid_till = company.valid_till ? moment.utc(company.valid_till).tz(timeZoneId) : null;

                    vm.newCompanyForm.$setPristine();
                });
            }
        }

        function canFinish() {
            vm.state.canFinish = vm.newCompanyForm
                && vm.newCompanyForm.$valid
                && selectedCarParksValid();
        }

        function selectedCarParksValid() {
            return vm.selectedCarParks
                && vm.selectedCarParks.length
                && vm.selectedCarParks.every(cap => cap.related_sub_area);
        }

        $scope.$watch(function() {
            return vm.newCompanyForm && vm.newCompanyForm.$valid;
        }, canFinish);

        $scope.$watch(() => vm.selectedCarParks, canFinish, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );

        $scope.$watch(
            () => vm.state.finishAndClose,
            newValue => newValue && onFinish(true)
        );
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('campaignSectionsTab', {
            templateUrl: '/assets/views/campaign/mobile/tabs/sections/sections.tpl.html',
            controller: CampaignMobileSectionsTab,
            bindings: {
                campaignModel: '='
            }
        });

    function CampaignMobileSectionsTab(
        $stateParams,
        campaignMobileState,
        LocationDataService,
        UtilitiesService,
        StorageFactory
    ) {
        const vm = this;
        const {
            sortByStringProperty,
            distinctByProp
        } = UtilitiesService;

        // public properties
        vm.state = campaignMobileState;
        vm.checkboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'sectionTitle',
                    width: '50',
                    title: 'description'
                }
            ],
            header: false,
            itemClickCallback: toggleSection
        };
        vm.sectionGroupsCheckboxListData = {
            columns: [
                {
                    name: 'TITLE',
                    class: 'sectionGroupTitle',
                    width: '100',
                    translate: true,
                    getTranslationParams: ({name}) => ({name}),
                    title: () => 'GROUP_NAME',
                },
            ],
            header: false,
            itemClickCallback: toggleSectionGroup
        };
        vm.sections = [];
        vm.filteredSections = [];
        vm.selectedSectionGroups = [];

        vm.toggleSection = toggleSection;
        vm.updateSectionsList = updateSectionsList;

        init();

        // public methods

        /**
         * Handler on section select/deselect
         */
        function toggleSection() {

            // creates new selectedSectionsGroupsIds array according to selected sections list changes
            let selectedSectionsGroupsIds =
                _.chain(vm.sections)
                    .map(section => _.map(section.groups, 'id'))
                    .flatten()
                    .countBy()
                    .pickBy((count, key) => {
                        return count === _.filter(vm.campaignModel.sections, section => {
                            return !!_.find(section.groups, ['id', parseInt(key)])
                        }).length
                    })
                    .keys()
                    .map(_.parseInt)
                    .value();

            vm.selectedSectionGroups = _.filter(vm.sectionGroups,
                group => selectedSectionsGroupsIds.indexOf(group.id) !== -1);
            vm.state.canProcessNext = (!!vm.campaignModel.sections.length || !vm.campaignModel.useBeacons);
        }

        // private methods

        /**
         * Handler on sectionGroup select/deselect
         */

        function toggleSectionGroup(groupItem) {
            let groupSections = _.filter(vm.sections,
                section => !!_.find(section.groups, ["id", groupItem.id]));
            if (_.find(vm.selectedSectionGroups, ["id", groupItem.id])){
                vm.campaignModel.sections = _.unionWith(vm.campaignModel.sections, groupSections, _.isEqual);
            } else {
                vm.campaignModel.sections = _.pullAllBy(vm.campaignModel.sections, groupSections, "id");
            }
            toggleSection();
        }

        /**
         * Get list of sections
         */
        function getSections() {
            LocationDataService.getBeacons()
                .then(response => {
                    let sections = distinctByProp(response.list, 'locationId');

                    vm.sections = sortByStringProperty(sections, 'description');
                    vm.STORAGE.set('sectionsData', vm.sections);
                    let locationIDs = vm.campaignModel.sections.map(item => item.locationId);
                    if (!vm.campaignModel.sections.length) {
                        if (vm.campaignModel.locationsIDs && vm.campaignModel.locationsIDs.length) {
                            vm.campaignModel.locationsIDs.forEach((ID) => {
                                vm.sections.forEach((section) => {
                                    if (section.locationId === ID) {
                                        vm.campaignModel.sections.push(section);
                                    }
                                });
                            });
                        }
                    } else {
                        vm.campaignModel.sections = vm.sections.filter(section => {
                            return locationIDs.some(locationID => locationID === section.locationId);
                        })
                    }
                    vm.filteredSections = vm.sections;
                    vm.sectionGroups =
                        _.chain(vm.sections)
                            .map('groups')
                            .flatten()
                            .uniqBy('id')
                            .sortBy('name')
                            .value();

                    vm.STORAGE.set('sectionsGroups', vm.sectionGroups);
                    toggleSection();
                    vm.campaignModel.useBeacons = !!vm.campaignModel.sections.length;
                })
                .catch(console.error.bind(console));
        }

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.STORAGE = StorageFactory.Storage('MobileCampaign');
            vm.sections = vm.STORAGE.get('sectionsData') || [];
            vm.sectionGroups = vm.STORAGE.get('sectionsGroups') || [];

            if (!vm.sections.length || !vm.sectionGroups.length) {
                getSections();
            }
        }

        function updateSectionsList() {
            vm.filteredSections = vm.searchName
                ? vm.sections.filter(section => section.description.toUpperCase().includes(vm.searchName.toUpperCase()))
                : vm.sections;
        }
    }
}());

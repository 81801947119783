(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('containerProperties', containerProperties);

    function containerProperties() {
        return {
            restrict: 'E',
            templateUrl: '/assets/views/common/directives/pages-editor/elements/container.tpl.html',
            replace: true,
            controller: containerPropertiesController,
            controllerAs: 'containerPropertiesCtrl',
            bindToController: true,
            scope: {
                item: '='
            }
        };
    }

    function containerPropertiesController($scope) {
        const vm = this;

        vm.handleColumnChange = handleColumnChange;

        vm.equal = true;
        vm.widths = [];
        vm.item.columns.forEach(function(item, index) {
            vm.widths.push(vm.item.widths[index]);
        });

        function handleColumnChange() {
            if ((vm.columnsLength - vm.item.columns.length) > 0) {
                var newColsLength = vm.columnsLength - vm.item.columns.length;
                for (var i = 0; i < newColsLength; i++) {
                    vm.item.columns.push([]);
                }
            } else if ((vm.columnsLength - vm.item.columns.length) < 0) {
                var colsRemoved = 0;
                var colsToRemove = vm.item.columns.length - vm.columnsLength;
                vm.item.columns.forEach(function(item, index) {
                    if (!item.length && (colsRemoved < colsToRemove)) {
                        vm.item.columns.splice(index, 1);
                        colsRemoved++;
                    }
                });
            }

            setEqual();
        }

        function setEqual() {
            var defaultColumnWidth = 100 / vm.item.columns.length;
            vm.item.widths = [];
            vm.widths = [];

            // set widths for columns
            vm.item.columns.forEach(function(item, index) {
                vm.item.widths.push(defaultColumnWidth);
                vm.widths.push(defaultColumnWidth);
            });
        }

        $scope.$watch(function() {
            return vm.item.columns.length;
        }, function() {
            vm.columnsLength = vm.item.columns.length;
        });

        $scope.$watch(function() {
            return vm.equal;
        }, function(equal) {
            if (equal) {
                setEqual();
            }
        });

        $scope.$watch(function() {
            return vm.widths;
        }, function(newValue, oldValue) {
            if (newValue.length === oldValue.length) {
                var colsCount = newValue.length;
                var index = 0;
                newValue.forEach(function(item, i) {
                    if (newValue[i] !== oldValue[i]) {
                        index = i;
                    }
                });
                if (newValue[index]) {
                    if (colsCount === 2) {
                        vm.item.widths[index] = newValue[index];
                        var secondIndex = index ? 0 : 1;
                        vm.item.widths[secondIndex] = 100 - vm.item.widths[index];
                        vm.widths[secondIndex] = vm.item.widths[secondIndex];
                    } else {
                        var widthDifference = newValue[index] - vm.item.widths[index];
                        var countDifference = vm.item.widths.length - parseInt(index) - 1;
                        var difference = widthDifference / countDifference;

                        vm.item.widths[index] = vm.widths[index];
                        for (var i = (index + 1); i < vm.item.widths.length; i++) {
                            vm.item.widths[i] -= difference;
                            vm.widths[i] = vm.item.widths[i];
                        }
                    }
                }

            }
        }, true);
    }
}());

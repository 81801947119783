(function() {
    'use strict';

    angular.module('beacon.app')
        .service('AppVersionService', AppVersionService);

    function AppVersionService(
        $http
    ) {
        return {
            getCurrentVersion,
        };

        /**
         * @return {Promise<string>}
         */
        function getCurrentVersion() {
            return $http.get('/api/version')
                .then(response => response.data.version);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userGroupShareParkTab', {
            templateUrl: '/assets/views/user-groups/new/tabs/user-group-share-park/user-group-share-park.tpl.html',
            controller: UserGroupSmartCampusController,
            bindings: {
                userGroupData: '<',
                form: '<'
            }
        });

    function UserGroupSmartCampusController(
        $scope,
        ProfilesHelper,
        ShareParkDataService,
        UserDataService,
        StatesFactory,
        TenantDataService,
    ) {
        const vm = this;

        const ACTIVE_STATUS = 1;
        const INACTIVE_STATUS = 2;
        const DELETED_STATUS = 3;

        const { decodeCustomerProfile } = ProfilesHelper;

        vm.state = StatesFactory.UserGroupStates;

        vm.state.canBack = true;

        vm.serviceProviders = [];
        vm.filteredServiceProviders = [];
        vm.profiles = [];
        vm.filteredProfiles = [];
        vm.tenants = [];
        vm.filteredTenants = [];

        vm.serviceProvidersCheckboxListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'name',
                    width: '50',
                    title:  listItem => `<p title="${listItem.fullCompanyName}">${listItem.fullCompanyName}</p>`
                },
                {
                    name: 'TENANT',
                    width: '30',
                    title: listItem => listItem.tenantName
                },
            ],
        };

        vm.profilesCheckboxListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'name',
                    width: '35',
                    title: 'nameLabel'
                },
                {
                    name: 'TENANT',
                    width: '35',
                    title: 'tenantName'
                },
                {
                    name: 'STATUS',
                    width: '25',
                    translate: true,
                    title: listItem => {
                        switch (listItem.status) {
                            case ACTIVE_STATUS:
                                return 'ACTIVE';
                            case INACTIVE_STATUS:
                                return 'INACTIVE';
                            case DELETED_STATUS:
                                return 'DELETED';
                            default:
                                return 'INACTIVE';
                        }
                    }
                }
            ],
        };

        vm.$onInit = init;

        vm.showInputError = showInputError;
        vm.filterItems = filterItems;
        vm.toggleAllServiceProviders = toggleAllServiceProviders;
        vm.toggleAllProfiles = toggleAllProfiles;

        function init() {
            TenantDataService.getTenants({})
                .then(response => {
                    vm.tenants = response.plain().sort((a, b) => a.name.localeCompare(b.name));

                    loadServiceProviders();
                    loadProfiles();
                });
        }

        function loadServiceProviders() {
            const tenantRefs = vm.tenants.map(({tenantRef}) => tenantRef);
            const ALL_TENANTS = 0;

            ShareParkDataService.getCapServiceProvidersAll()
                .then(response => {
                    vm.serviceProviders = response.plain()
                        .filter(provider => (provider.tenant === ALL_TENANTS) || tenantRefs.includes(provider.tenant))
                        .map(provider => {
                            provider.tenantName = (provider.tenant === ALL_TENANTS)
                                ? ''
                                : vm.tenants.find(({tenantRef}) => tenantRef === provider.tenant).name;

                            return provider;
                        })
                        .sort((a, b) => a.name.localeCompare(b.name));

                    vm.userGroupData.selectedServiceProviders = vm.serviceProviders.filter(provider =>
                        vm.userGroupData.allowedServiceProviders.includes(provider.id)
                    );

                    filterItems();
                });
        }

        function loadProfiles() {
            const tenantRefs = vm.tenants.map(({tenantRef}) => tenantRef);

            ShareParkDataService.getUserProfiles({
                allProfiles: true
            }).then(response => {
                vm.profiles = response.plain()
                    .map(profile => decodeCustomerProfile(profile))
                    .filter(profile => profile.tenant && tenantRefs.includes(profile.tenant))
                    .map(profile => {
                        profile.tenantName = vm.tenants.find(({tenantRef}) => tenantRef === profile.tenant).name;
                        return profile;
                    })
                    .sort((a, b) => a.nameLabel.localeCompare(b.nameLabel));

                vm.userGroupData.selectedProfiles = vm.profiles.filter(profile =>
                    vm.userGroupData.allowedProfiles.includes(profile.id)
                );

                filterItems();
            });
        }

        function filterItems() {
            if (!vm.filteredTenants.length) {
                vm.filteredProfiles = vm.profiles;
                vm.filteredServiceProviders = vm.serviceProviders;

                return;
            }

            vm.filteredProfiles = vm.profiles.filter(profile => {
                return !profile.tenant || vm.filteredTenants.includes(profile.tenant);
            });

            vm.filteredServiceProviders = vm.serviceProviders.filter(provider => {
                return !provider.tenant || vm.filteredTenants.includes(provider.tenant);
            });
        }

        function toggleAllServiceProviders() {
            const filteredProviderIds = vm.filteredServiceProviders.map(({id}) => id);
            const selectedProviderIds = vm.userGroupData.selectedServiceProviders.map(({id}) => id);
            const allVisibleProvidersSelected = filteredProviderIds.every(id => selectedProviderIds.includes(id));

            if (allVisibleProvidersSelected) {
                vm.userGroupData.selectedServiceProviders = vm.userGroupData.selectedServiceProviders.filter(provider =>
                    !filteredProviderIds.includes(provider.id)
                );
            } else {
                vm.filteredServiceProviders.forEach(provider => {
                    if (!selectedProviderIds.includes(provider.id)) {
                        vm.userGroupData.selectedServiceProviders.push(provider);
                    }
                })
            }
        }

        function toggleAllProfiles() {
            const filteredProfileIds = vm.filteredProfiles.map(({id}) => id);
            const selectedProfileIds = vm.userGroupData.selectedProfiles.map(({id}) => id);
            const allVisibleProfilesSelected = filteredProfileIds.every(id => selectedProfileIds.includes(id));

            if (allVisibleProfilesSelected) {
                vm.userGroupData.selectedProfiles = vm.userGroupData.selectedProfiles.filter(profile =>
                    !filteredProfileIds.includes(profile.id)
                );
            } else {
                vm.filteredProfiles.forEach(profile => {
                    if (!selectedProfileIds.includes(profile.id)) {
                        vm.userGroupData.selectedProfiles.push(profile);
                    }
                })
            }
        }

        /**
         * Checks if error must be shown
         * @param name
         * @return {*|boolean}
         */
        function showInputError(name) {
            return vm.form
                && vm.form[name].$dirty
                && vm.form[name].$invalid;
        }

        function canNext() {
            vm.state.canNext = vm.form
                && vm.form.$valid;
        }

        $scope.$watch(
            () => vm.form && vm.form.$valid,
            () => canNext(),
        );
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ContentRelationsService', ContentRelationsService);

    function ContentRelationsService(
        $http,
    ) {
        return {
            getRelatedCampaigns,
            getRelatedDigitalDisplays,
        };

        function getRelatedCampaigns(content) {
            return $http.get('/api/contents-relations/campaigns/' + content.id)
                .then(response => response.data);
        }

        function getRelatedDigitalDisplays(content) {
            return $http.get('/api/contents-relations/digital-displays/' + content.id)
                .then(response => response.data);
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LoyaltyDataService', loyaltyDataService);

    function loyaltyDataService(Restangular) {
        return {
            getLoyaltyConfigs,
            getLoyaltyProducts,
        };

        function getLoyaltyConfigs() {
            return Restangular.all('loyalty/get-loyalty-configs').getList();
        }

        function getLoyaltyProducts() {
            return Restangular.all('loyalty/get-loyalty-products').getList();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('dynamicContentPreviews', {
            controller: dynamicContentDashboard,
            templateUrl: '/assets/views/dynamic-content-dashboard/previews/previews.tpl.html',
            bindings: {
                languages: '<',
                items: '<',
                active: '<',
                callbackActiveContent: '<',
            }
        });

    function dynamicContentDashboard() {
        const vm = this;

        vm.isActive = isActive;
        vm.setActiveContent = vm.callbackActiveContent;

        function isActive(item) {
            return vm.active && item.id === vm.active.id;
        }
    }
})();
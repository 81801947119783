(function() {
    'use strict';

    angular.module('beacon.app')
        .constant('TIME_ZONES', [
            ['(GMT+14:00)', 'Pacific/Kiritimati'],
            ['(GMT+13:00)', 'Pacific/Tongatapu'],
            ['(GMT+12:45)', 'Pacific/Chatham'],
            ['(GMT+12:00)', 'Pacific/Wake'],
            ['(GMT+12:00)', 'Pacific/Fiji'],
            ['(GMT+12:00)', 'Pacific/Auckland'],
            ['(GMT+12:00)', 'Asia/Kamchatka'],
            ['(GMT+12:00)', 'Antarctica/South_Pole'],
            ['(GMT+11:00)', 'Pacific/Guadalcanal'],
            ['(GMT+11:00)', 'Asia/Sakhalin'],
            ['(GMT+10:30)', 'Australia/Lord_Howe'],
            ['(GMT+10:00)', 'Pacific/Guam'],
            ['(GMT+10:00)', 'Australia/Sydney'],
            ['(GMT+10:00)', 'Australia/Melbourne'],
            ['(GMT+10:00)', 'Asia/Vladivostok'],
            ['(GMT+9:30)', 'Australia/Darwin'],
            ['(GMT+9:30)', 'Australia/Adelaide'],
            ['(GMT+9:00)', 'Asia/Tokyo'],
            ['(GMT+9:00)', 'Asia/Seoul'],
            ['(GMT+9:00)', 'Asia/Pyongyang'],
            ['(GMT+8:00)', 'Australia/Perth'],
            ['(GMT+8:00)', 'Asia/Singapore'],
            ['(GMT+8:00)', 'Asia/Shanghai'],
            ['(GMT+8:00)', 'Asia/Kuala_Lumpur'],
            ['(GMT+8:00)', 'Asia/Hong_Kong'],
            ['(GMT+7:00)', 'Asia/Jakarta'],
            ['(GMT+7:00)', 'Asia/Ho_Chi_Minh'],
            ['(GMT+7:00)', 'Asia/Bangkok'],
            ['(GMT+6:30)', 'Asia/Rangoon'],
            ['(GMT+6:00)', 'Asia/Dhaka'],
            ['(GMT+6:00)', 'Asia/Almaty'],
            ['(GMT+5:45)', 'Asia/Kathmandu'],
            ['(GMT+5:30)', 'Asia/Kolkata'],
            ['(GMT+5:30)', 'Asia/Colombo'],
            ['(GMT+5:00)', 'Asia/Yekaterinburg'],
            ['(GMT+5:00)', 'Asia/Karachi'],
            ['(GMT+4:00)', 'Asia/Dubai'],
            ['(GMT+4:00)', 'Asia/Baku'],
            ['(GMT+3:30)', 'Asia/Tehran'],
            ['(GMT+3:00)', 'Europe/Moscow'],
            ['(GMT+3:00)', 'Asia/Riyadh'],
            ['(GMT+3:00)', 'Asia/Istanbul'],
            ['(GMT+3:00)', 'Asia/Bahrain'],
            ['(GMT+3:00)', 'Africa/Nairobi'],
            ['(GMT+2:00)', 'Europe/Kiev'],
            ['(GMT+2:00)', 'Europe/Helsinki'],
            ['(GMT+2:00)', 'Europe/Athens'],
            ['(GMT+2:00)', 'Asia/Tel_Aviv'],
            ['(GMT+2:00)', 'Asia/Jerusalem'],
            ['(GMT+2:00)', 'Asia/Amman'],
            ['(GMT+2:00)', 'Africa/Johannesburg'],
            ['(GMT+2:00)', 'Africa/Cairo'],
            ['(GMT+1:00)', 'Europe/Zurich'],
            ['(GMT+1:00)', 'Europe/Vienna'],
            ['(GMT+1:00)', 'Europe/Stockholm'],
            ['(GMT+1:00)', 'Europe/Rome'],
            ['(GMT+1:00)', 'Europe/Prague'],
            ['(GMT+1:00)', 'Europe/Paris'],
            ['(GMT+1:00)', 'Europe/Madrid'],
            ['(GMT+1:00)', 'Europe/Luxembourg'],
            ['(GMT+1:00)', 'Europe/Copenhagen'],
            ['(GMT+1:00)', 'Europe/Brussels'],
            ['(GMT+1:00)', 'Europe/Berlin'],
            ['(GMT+1:00)', 'Europe/Andorra'],
            ['(GMT+1:00)', 'Europe/Amsterdam'],
            ['(GMT+1:00)', 'CET'],
            ['(GMT+1:00)', 'Africa/Tunis'],
            ['(GMT+1:00)', 'Africa/Algiers'],
            ['(GMT0:00)', 'Africa/Casablanca'],
            ['(GMT0:00)', 'Atlantic/Reykjavik'],
            ['(GMT0:00)', 'Europe/Dublin'],
            ['(GMT0:00)', 'Europe/Lisbon'],
            ['(GMT0:00)', 'Europe/London'],
            ['(GMT0:00)', 'GMT'],
            ['(GMT-1:00)', 'Atlantic/Azores'],
            ['(GMT-1:00)', 'Atlantic/Cape_Verde'],
            ['(GMT-2:00)', 'Atlantic/South_Georgia'],
            ['(GMT-3:00)', 'America/Argentina/Buenos_Aires'],
            ['(GMT-3:00)', 'America/Buenos_Aires'],
            ['(GMT-3:00)', 'America/Cordoba'],
            ['(GMT-3:00)', 'America/Montevideo'],
            ['(GMT-3:00)', 'America/Sao_Paulo'],
            ['(GMT-3:30)', 'Canada/Newfoundland'],
            ['(GMT-4:00)', 'America/Aruba'],
            ['(GMT-4:00)', 'America/Barbados'],
            ['(GMT-4:00)', 'America/Curacao'],
            ['(GMT-4:00)', 'America/Puerto_Rico'],
            ['(GMT-4:00)', 'America/Virgin'],
            ['(GMT-4:00)', 'Atlantic/Bermuda'],
            ['(GMT-5:00)', 'US/Eastern'],
            ['(GMT-5:00)', 'America/Havana'],
            ['(GMT-5:00)', 'America/Montreal'],
            ['(GMT-5:00)', 'America/New_York'],
            ['(GMT-5:00)', 'America/Toronto'],
            ['(GMT-6:00)', 'America/Chicago'],
            ['(GMT-6:00)', 'America/Mexico_City'],
            ['(GMT-6:00)', 'America/Winnipeg'],
            ['(GMT-6:00)', 'Canada/Saskatchewan'],
            ['(GMT-6:00)', 'US/Central'],
            ['(GMT-7:00)', 'America/Denver'],
            ['(GMT-7:00)', 'America/Phoenix'],
            ['(GMT-7:00)', 'Canada/Mountain'],
            ['(GMT-7:00)', 'US/Mountain'],
            ['(GMT-8:00)', 'America/Los_Angeles'],
            ['(GMT-8:00)', 'America/Vancouver'],
            ['(GMT-8:00)', 'Canada/Yukon'],
            ['(GMT-8:00)', 'US/Pacific'],
            ['(GMT-9:00)', 'America/Anchorage'],
            ['(GMT-9:00)', 'US/Alaska'],
            ['(GMT-9:30)', 'Pacific/Marquesas'],
            ['(GMT-10:00)', 'Pacific/Honolulu'],
            ['(GMT-10:00)', 'Pacific/Tahiti'],
            ['(GMT-10:00)', 'US/Hawaii'],
            ['(GMT-11:00)', 'Pacific/Midway'],
            ['(GMT-11:00)', 'US/Samoa'],
        ])
    ;
}());
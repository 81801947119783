(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('TierGroupsFormToolbarController', TierGroupsFormToolbarController);

    function TierGroupsFormToolbarController($scope, StatesFactory, $state, $stateParams) {
        const vm = this;

        vm.stateParams = $stateParams;
        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.TierGroupStates);
        vm.state = vm.stateAss.state;

        vm.state.finish = false;

        vm.cancel = cancel;
        vm.finish = finish;

        /**
         * Cancel button handler
         */
        function cancel() {
            $state.go('app.loyaltyTierGroups');
        }

        /**
         * Finish button handler
         */
        function finish() {
            vm.state.finish = true;
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('digitalDisplayPageTypeTab', {
            templateUrl: '/assets/views/content/elements/types/digital-display/tabs/page-type/page-type.tpl.html',
            controller: DigitalDisplayPageTypeTab
        });

    function DigitalDisplayPageTypeTab(
        $translate,
        UtilitiesService,
        PageDataService,
        StatesFactory,
        ModelsFactory,
        StorageFactory,
        PAGE_TYPES,
        PermissionsService,
        PAGE_TYPE_TITLES
    ) {
        const vm = this;
        const { compareAsNumber, oneOfPageTypes } = UtilitiesService;
        vm.state = StatesFactory.DigitalDisplayStates;

        /**
         * Exports
         */
        vm.setPageType = setPageType;
        vm.oneOfPageTypes = oneOfPageTypes;
        vm.compareAsNumber = compareAsNumber;
        vm.isAvailableInRootList = isAvailableInRootList;

        init();
        function init() {
            vm.STORAGE = StorageFactory.Storage('Content-DigitalDisplay');
            vm.pageTypes = vm.STORAGE.get('pageTypes');

            if (!vm.pageTypes) {
                PageDataService.pageTypes().then(function(response) {
                    let pageTypes = response.plain();

                    /**
                     * TODO: 20.12.2017 serhii.kokovsyi Fix it. Only in demo purposes
                     */
                    //  vm.pageTypes = vm.STORAGE.set('pageTypes', pageTypes);

                    fillWithChildren(pageTypes);

                    pageTypes = PermissionsService.filterPageTypesWhenAddNewPageToDigitalDisplay(pageTypes);

                    vm.STORAGE.set('pageTypes', pageTypes);
                    vm.pageTypes = pageTypes;
                });
            }

            // Reset model
            vm.pageData = vm.STORAGE.get('pageData');
            if (vm.pageData) {
                setPageModel(vm.pageData.page_type_id);
            }

            // Update state
            vm.state.canBack = true;
            vm.state.canNext = !!vm.pageData;
        }

        /**
         * @param {int} pageTypeId
         * @return {boolean}
         */
        function isAvailableInRootList(pageTypeId) {
            return !oneOfPageTypes([
                'TICKER',
                'TIMETABLE',
                'WEATHER',
                'QUIZ',
                'LINE_ROUTE',
                'DYNAMIC_CONTENT',
                'TIMETABLE_HAFAS',
                'HIM',
            ], pageTypeId);
        }

        function setPageModel(pageTypeId) {
            let page = null;
            switch (pageTypeId) {

                case PAGE_TYPES.EXTERNAL_APP:
                    page = new ModelsFactory.ExternalApp();
                    break;
                case PAGE_TYPES.VISITOR_INFO:
                    page = new ModelsFactory.VisitorInfo();
                    break;
                case PAGE_TYPES.TICKER:
                    page = new ModelsFactory.Ticker();
                    break;
                case PAGE_TYPES.PLAYLIST:
                    page = new ModelsFactory.Playlist();
                    break;
                case PAGE_TYPES.TIMETABLE:
                    page = new ModelsFactory.Timetable();
                    break;
                case PAGE_TYPES.WEATHER:
                    page = new ModelsFactory.Weather();
                    break;
                case PAGE_TYPES.LINE_ROUTE:
                    page = new ModelsFactory.LineRoute();
                    break;
                case PAGE_TYPES.TIMETABLE_HAFAS:
                    page = new ModelsFactory.TimetableHafasPage();
                    break;
                case PAGE_TYPES.HIM:
                    page = new ModelsFactory.HimPage();
                    break;
                default:
                    page = {};
            }

            $translate(getPageTitle(pageTypeId)).then(pageTitle => {
                vm.pageData = vm.STORAGE.set('pageData', new ModelsFactory.DigitalDisplayPage({ page }, pageTitle));
                vm.pageData.page_type_id = pageTypeId;
            })
        }

        /**
         * Get default page name
         *
         * @param pageTypeId
         * @returns {string}
         */
        function getPageTitle(pageTypeId) {
            const key = Object.keys(PAGE_TYPES).find(key => PAGE_TYPES[key] === pageTypeId);
            return PAGE_TYPE_TITLES[key] || 'PAGE';
        }

        /**
         * Sets page type
         * @param {Number} type
         */
        function setPageType(type) {
            type = Number(type);
            type = vm.pageData ? (vm.pageData.page_type_id !== type ? type : null) : type;

            if (type) {
                setPageModel(type);
            } else {
                vm.pageData.page_type_id = null;
            }

            // Update state
            vm.state.canNext = !!type;
        }

        function fillWithChildren(pageTypes) {
            for (let item of pageTypes) {
                switch (Number(item.id)) {
                    case PAGE_TYPES.VISITOR_INFO:
                        item.children = [
                            {
                                id: PAGE_TYPES.VISITOR_INFO,
                                name: 'Infotainment',
                                description: 'CMS content'
                            },
                            {
                                id: PAGE_TYPES.TICKER,
                                name: 'Ticker',
                                description: 'News, html snippets, rss feeds'
                            },
                            {
                                id: PAGE_TYPES.TIMETABLE,
                                name: 'Timetable',
                                description: 'Departure time table and live map'
                            },
                            {
                                id: PAGE_TYPES.LINE_ROUTE,
                                name: 'Line route',
                                description: 'Line route with departure times'
                            },
                            {
                                id: PAGE_TYPES.WEATHER,
                                name: 'Weather',
                                description: 'Weather forecast for the next few days'
                            },
                            {
                                id: PAGE_TYPES.DYNAMIC_CONTENT,
                                name: 'Dynamic Content',
                                description: 'Dynamic notifications'
                            },
                            {
                                id: PAGE_TYPES.TIMETABLE_HAFAS,
                                name: 'Timetable (HAFAS)',
                                description: 'Timetable (HAFAS)',
                            },
                            {
                                id: PAGE_TYPES.HIM,
                                name: 'HIM Content',
                                description: 'HIM Content',
                            }
                        ];
                        break;

                    default:
                        item.children = [];
                }
            }
        }
    }
})();

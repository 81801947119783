(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screensFilterItem', {
            controller: screensFilterItemController,
            templateUrl: '/assets/views/common/components/screens-filter/filter-item/filter-item.tpl.html',
            bindings: {
                filterTypes: '<',
                filterComparisons: '<',
                filterItem: '=',
                index: '<',
                onChange: '<?',
                onDelete: '<?',
            },
        });

    function screensFilterItemController(
        $scope,
        SCREEN_FILTER_TYPE,
    ) {
        const vm = this;

        vm.FILTER_TYPES = SCREEN_FILTER_TYPE;

        vm.removeFilter = vm.onDelete;
        vm.showFilterOption = showFilterOption;

        /**
         *
         * @param filterComparison
         * @return {*}
         */
        function showFilterOption(filterComparison) {
            return filterComparison.types.includes(vm.filterItem.filterType.dataType);
        }

        $scope.$watch(
            () => vm.filterItem,
            filterItem => vm.onChange(filterItem, vm.index),
            true
        );
    }
})();
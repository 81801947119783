(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LoyaltyDashboardService', LoyaltyDashboardService);

    function LoyaltyDashboardService(Restangular) {
        return {
            get,
        };

        function get() {
            return Restangular.all('loyalty/dashboard').getList();
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofenceLocationsPicker', {
            templateUrl: '/assets/views/common/components/geofence-locations-picker/geofence-locations-picker.tpl.html',
            controller: GeofenceLocationsPicker,
            bindings: {
                locations: '<',
                selectedLocations: '<',
                polyline: '<',
                updatePolyline: '=',
                changeSelectedLocations: '=',
            }
        });

    function GeofenceLocationsPicker(
        $scope,
    ) {
        const vm = this;
        
        vm.$onInit = init;
        
        vm.markers = [];

        vm.onPolylineUpdate = onPolylineUpdate;
        vm.onMarkerClickCallback = onMarkerClickCallback;
        
        function init() {
            updateAllMarkers();
        }

        /**
         * @param {string} newPolyline
         * @return {void}
         */
        function onPolylineUpdate(newPolyline) {
            angular.isFunction(vm.updatePolyline) && vm.updatePolyline(newPolyline);
            const parsedPolygons = polylineStringToArray(newPolyline);
            const locationsInGeofence = checkLocationsInGeofences(parsedPolygons);
            updateSelectedLocations(locationsInGeofence);

            updateAllMarkers();
            vm.changeSelectedLocations(vm.selectedLocations);
        }

        /**
         * Handles marker click on the map
         * @param {object} locationMarker
         */
        function onMarkerClickCallback(locationMarker) {
            const currentLocation = vm.locations.find(location => location.id === locationMarker.locationId);
            if (locationMarker.selected) {
                vm.selectedLocations.push(currentLocation);
            } else {
                let locationIndex = vm.selectedLocations.findIndex(location => location.id === currentLocation.id);
                if (locationIndex !== -1) {
                    vm.selectedLocations.splice(locationIndex, 1);
                }
            }

            updateAllMarkers();
            vm.changeSelectedLocations(vm.selectedLocations);
        }

        /**
         * Convets polyline to array of polygons
         * @param {string} polyline string of polygons and their points
         * @returns {array} parsedPolygons array of polygons with their points inside
         */
        function polylineStringToArray(polyline) {
            if (!polyline.length) {
                return [];
            }

            return polyline.split('|').map(polygon =>
                polygon.split(';').map(point =>
                    point.split(',').map(coordinates =>
                        parseFloat(coordinates)
                    )
                )
            );
        }

        /**
         * Updates markers selected property according to selected locations list
         */
        function updateAllMarkers() {
            vm.markers = [];
            vm.locations.forEach(location => {
                if (!!location.lat) {
                    vm.markers.push({
                        label: location.name,
                        position: {
                            lat: location.lat,
                            lng: location.lng
                        },
                        selected: !!vm.selectedLocations.find(locationItem => locationItem.id === location.id),
                        locationId: location.id
                    });
                }
            });
        }

        function updateSelectedLocations(locationsInGeofence) {
            const locationsSelectedByGeofence = vm.selectedLocations.filter(location =>
                location.pushedByGeofence
            );

            const diff = locationsInGeofence.filter(location =>
                !locationsSelectedByGeofence.map(loc => loc.id).includes(location.id)
            );

            if (locationsInGeofence.length !== locationsSelectedByGeofence.length || diff.length) {
                vm.selectedLocations = vm.selectedLocations.filter(location =>
                    !location.pushedByGeofence
                );

                locationsInGeofence.forEach(location => {
                    if (!vm.selectedLocations.find(locationItem => locationItem.id === location.id)) {
                        const locationCopy = angular.copy(location);
                        locationCopy.pushedByGeofence = true;
                        vm.selectedLocations.push(locationCopy);
                    }
                });
            }
        }

        /**
         * Checks if point is inside polygon
         * @param {array} point array with point coordinates
         * @param {array} polygon array of polyogn points
         * @return {boolean}
         */
        function isInsidePolygon(point, polygon) {
            const [y, x] = point;
            const count = polygon.length;
            let inside = false;

            for (let i = 0, j = count - 1; i < count; i++) {
                const [y1, x1] = polygon[i];
                const [y2, x2] = polygon[j];

                if ((y1 > y) !== (y2 > y)) {
                    const intersectPoint = (x2 - x1) * (y - y1) / (y2 - y1) + x1;
                    if (x < intersectPoint) {
                        inside = !inside;
                    }
                }
                j = i;
            }
            return inside;
        }

        /**
         * Check which locations (markers) are inside geofences
         * @param {array} polygons
         */
        function checkLocationsInGeofences(polygons) {
            return vm.locations.filter(location => {
                if (!location.lat) {
                    return false;
                }

                const point = [location.lat, location.lng];
                return polygons.some(polygon =>
                    isInsidePolygon(point, polygon)
                )
            });
        }

        $scope.$watch(
            () => vm.locations.length,
            () => {
                updateAllMarkers();
            },
        );

        $scope.$watch(
            () => vm.selectedLocations,
            () => {
                updateAllMarkers();
            },
            true
        );

        $scope.$on('redraw-polyline', (event, data) => {
            data.updateSelectedLocations
                ? (angular.isDefined(data.polyline) && onPolylineUpdate(data.polyline))
                : updateAllMarkers();
        });
    }
})();
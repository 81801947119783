(function() {
    'use strict';

    angular.module('scMeetings')
        .filter('lng', function (TranslateService) {
            return function (value) {
                return TranslateService.getCurrentUiLanguageTranslation(value);
            };
        });
}());

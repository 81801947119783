(function() {
    'use strict';

    angular.module('beacon.app')
        .component('topServiceProviders', {
            templateUrl: '/assets/views/share-park/dashboard/widgets/top-service-providers/top-service-providers.tpl.html',
            controller: TopServiceProvidersController,
            bindings: {
                serviceProviders: '<',
                currencies: '<',
                timeRange: '<',
            }
        });

    function TopServiceProvidersController(
        $scope,
    ) {
        const vm = this;

        const TOP_LIST_LENGTH = 5;

        vm.$onInit = init;

        vm.topServiceProviders = [];

        vm.updateTopServiceProviders = updateTopServiceProviders;
        vm.setCurrency = setCurrency;

        function init() {
            vm.currentCurrency = vm.currencies[0] || 'EUR';
            updateTopServiceProviders();
        }

        function updateTopServiceProviders() {
            vm.topServiceProviders = getServiceProvidersWithPurchasesInRange();
        }

        /**
         * Returns service providers with purchases in range
         * @return {Array}
         */
        function getServiceProvidersWithPurchasesInRange() {
            const minDate = vm.timeRange.diffValue ?
                moment().subtract(vm.timeRange.diffValue, vm.timeRange.diffUnit)
                : null;

            const serviceProvidersList = vm.serviceProviders.map(({fullCompanyName, purchases}) => {
                const filteredPurchases = purchases.filter(purchase => {
                        const dateMatches = !minDate || purchase.purchaseDate.isAfter(minDate);

                        return dateMatches && purchase.currencyCode === vm.currentCurrency
                    }
                );

                return {
                    fullCompanyName,
                    purchasesCount: filteredPurchases.length,
                    purchasesSum: filteredPurchases.reduce((accum, currentValue) => {
                        const price = angular.isNumber(currentValue.discountedPrice)
                            ? currentValue.discountedPrice
                            : currentValue.originalPrice;

                        return accum + price;
                    }, 0) / 100
                }
            });

            return serviceProvidersList.filter(customer => customer.purchasesSum)
                .sort((a, b) => b.purchasesSum - a.purchasesSum)
                .slice(0, TOP_LIST_LENGTH);
        }

        /**
         * Sets current currency
         * @param {String} currency
         */
        function setCurrency(currency) {
            vm.currentCurrency = currency;
            updateTopServiceProviders();
        }

        $scope.$watch(
            () => vm.timeRange,
            () => updateTopServiceProviders()
        )
    }
})();
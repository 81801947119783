(function() {
    'use strict';

    angular.module('beacon.app')
        .component('colorContrastRatioViewer', {
            templateUrl: '/assets/views/content/elements/types/banner/tabs/banner-second-tab/color-contrast-ratio-viewer/color-contrast-ratio-viewer.tpl.html',
            controller: ColorContrastRatioViewer,
            bindings: {
                foregroundColor: '<',
                backgroundColor: '<',
            }
        });

    function ColorContrastRatioViewer() {
        const vm = this;

        vm.contrastInfo;
        vm.setContrastInfo = setContrastInfo;
        vm.getsRGB = getsRGB;
        vm.getLightness = getLightness;

        vm.$onChanges = function(changes) {
            if (changes.foregroundColor) {
                vm.setContrastInfo(changes.foregroundColor.currentValue, vm.backgroundColor);
            }

            if (changes.backgroundColor) {
                vm.setContrastInfo(vm.foregroundColor, changes.backgroundColor.currentValue);
            }
        };

        /**
         * Sets color contrast ratio info for provided colors
         * @param {string} fgColor foreground HEX color value
         * @param {string} bgColor background HEX color value
         * @returns {{ratio: string, passedWCAGAA: boolean, passedWCAGAAA: boolean}}
         */
        function setContrastInfo(fgColor, bgColor) {
            const L1 = vm.getLightness(fgColor), L2 = vm.getLightness(bgColor), ratio = (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
            const ratioTruncated = Math.trunc(ratio * 100) / 100;

            vm.contrastInfo = {
                ratio: ratioTruncated,
                passedWCAGAA: ratioTruncated >= 4.5, // WCAG 2.0 level AA requires a contrast ratio of at least 4.5:1 for normal text
                passedWCAGAAA: ratioTruncated >= 7, // WCAG Level AAA requires a contrast ratio of at least 7:1 for normal text
            };
        }

        function getsRGB(c) {
            c = parseInt(c, 16) / 255;
            return (c <= 0.03928) ? c / 12.92 : Math.pow(((c + 0.055) / 1.055), 2.4);
        }

        function getLightness(hex) {
            return (0.2126 * vm.getsRGB(hex.substr(1, 2)) + 0.7152 * vm.getsRGB(hex.substr(3, 2)) + 0.0722 * vm.getsRGB(hex.substr(-2)));
        }
    }
})();

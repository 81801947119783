(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('habitEditState', function() {
            return {
                views: {
                    HABIT_TYPES: 0,
                    HABIT_DETAILS: 1,
                },
                finish: 0,
                canFinish: false,
            };
        })
        .component('habit', {
                templateUrl: '/assets/views/campaign/habit/habit.tpl.html',
                controller: HabitController,
            });

    function HabitController(
            $state,
            $stateParams,
            PopupService,
            PermissionsService,
            HabitService,
            HABIT_TYPES,
            HABIT_TYPES_TITLES,
            STATUS_FILTERS,
            SEGMENT_STATUS
    ) {
        const vm = this;

        const { generateHabitPermission, actions } = PermissionsService;

        // public methods
        vm.updateHabits = updateHabits;
        vm.newHabit = newHabit;

        vm.STATUS_FILTERS = STATUS_FILTERS;
        vm.HABIT_TYPES = HABIT_TYPES;
        vm.HABIT_TYPES_TITLES = HABIT_TYPES_TITLES;
        vm.createHabitPermission = generateHabitPermission(actions.create);

        const {INACTIVE, ACTIVE, DELETED} = SEGMENT_STATUS;

        vm.statusFilter = {};
        vm.typeFilter = {};
        vm.habits = [];
        vm.totalItems = $stateParams.paginationData
            ? $stateParams.paginationData.count
            : 0;

        vm.$onInit = init;

        const isVisible = (btn, item) => parseInt(item.status) !== DELETED;

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'habit-title',
                    width: '25',
                    title: item => item.name
                },
                {
                    name: 'TYPE',
                    class: 'segmentType',
                    width: '25',
                    translate: true,
                    title: item => (HABIT_TYPES_TITLES
                        .filter(type => type.value === item.type))[0].title
                },
                {
                    name: 'STATUS',
                    class: 'segmentStatus',
                    width: '25',
                    translate: true,
                    title: item => (STATUS_FILTERS
                        .filter(status => parseInt(item.status) === status.value))[0].title
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteHabit,
                        permissionAction: actions.delete,
                        isVisible
                    },
                    {
                        class: 'copyBtn',
                        callback: copyHabit,
                        permissionAction: actions.create,
                        isVisible
                    },
                    {
                        class: 'editBtn',
                        callback: editHabit,
                        permissionAction: actions.modify,
                        isVisible
                    },
                    {
                        class: item => {
                            switch (parseInt(item.status)) {
                                case ACTIVE:
                                    return 'block-btn';
                                case INACTIVE:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeHabitStatus,
                        isVisible,
                        permissionAction: actions.modify,
                    },
                ]
            },
            updateCallback: updateHabits,
            generatePermissionName,
        };

        // private methods

        /**
         * Updates list of habits
         *
         * @param {Number} page
         * @param {Number} itemsPerPage
         */
        function updateHabits(page, itemsPerPage) {
            const data = { page, itemsPerPage };
            const filter = {};
            const status = Object.keys(vm.statusFilter).filter(key => vm.statusFilter[key]);
            const type = Object.keys(vm.typeFilter).filter(key => vm.typeFilter[key]);

            if (!!type.length) {
                filter.type = type;
            }
            if (!!status.length) {
                filter.status = status;
            }
            if (!_.isEmpty(filter)) {
                data.filter = JSON.stringify(filter);
            }
            HabitService.getList(data).then(response => {
                vm.habits = response;
                vm.totalItems = response.count;
            })
        }

        /**
         * Handle habit category activate/deactivate button click
         * @param {object} $event
         * @param {object} item Clicked segmentation category item
         */
        function changeHabitStatus($event, habit) {
            $event.preventDefault();
            $event.stopPropagation();

            habit.status = parseInt(habit.status) === ACTIVE
                ? INACTIVE
                : ACTIVE;

            const formData = new FormData();
            formData.append('habit', JSON.stringify(habit));

            const error = console.error.bind(console);

            HabitService.update(habit, formData).catch(error);
        }

        /**
         * Delete habit
         * @param $event
         * @param habit
         */
        function deleteHabit($event, habit) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_HABIT',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, () => {
                habit.status = DELETED;

                const formData = new FormData();
                formData.append('habit', JSON.stringify(habit));

                HabitService.remove(habit, formData);
            });
        }

        /**
         * Create new habit button handler
         */
        function newHabit() {
            const itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));
            $state.go('app.addHabit', {
                paginationData: {
                    page: 1,
                    itemsPerPage,
                    count: vm.totalItems
                }
            });
        }

        /**
         * Edit habit
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function editHabit($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            const page = angular.element('.pagination-page.active a')[0].innerHTML;
            const itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));

            $state.go('app.editHabit', {
                data: item,
                paginationData: {
                    page: Number(page),
                    itemsPerPage,
                    count: vm.totalItems
                }
            });
        }

        /**
         * Copy habit
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function copyHabit($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_HABIT',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }, () => {
                const itemsPerPage = Number(angular.element('.paginationControls select').val().replace('number:', ''));
                const data = _.omit(item, ['id', 'externalId']);

                data.status = INACTIVE;

                $state.go('app.addHabit', {
                    data,
                    paginationData: {
                        page: 1,
                        itemsPerPage,
                        count: vm.totalItems
                    }
                });
            });
        }

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Generates permission name for each button on habit accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return PermissionsService.generateHabitPermission(button.permissionAction);
        }

    }
}());

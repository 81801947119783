(function () {
    'use strict';

    angular.module('beacon.app')
        .factory('ImageUploadFactory', function(ImageApiService, $q) {
            return {
                ImageUploader,
            };

            /**
             * Use it to reduce the amount of code in the controller/component for uploading images
             *
             * @param {object} options
             * @param {string} options.initialImageUrl
             * @param {function} options.onImageUploaded
             * @param {string} [options.uploadMode]
             *
             * @constructor
             */
            function ImageUploader(options) {
                let initialImageUrl = options.initialImageUrl;
                let initialUploadMode = options.uploadMode
                let onImageUploadedCallback = options.onImageUploaded;

                let imageToUpload = null;
                let isRemoteImage = null;
                let initialImageDeleted = false;

                /**
                 * Set new image
                 *
                 * @param {Blob|string} image - file or image url
                 * @param {string} image.name
                 */
                this.setImage = (image) => {
                    imageToUpload = image;
                    isRemoteImage = angular.isString(image) && image.length > 0;

                    if (!image) {
                        initialImageDeleted = true;
                    }
                }

                this.getImagePreview = () => {
                    const urlCreator = window.URL || window.webkitURL;
                    return isRemoteImage
                        ? imageToUpload // image url
                        : urlCreator.createObjectURL(imageToUpload) // blob url;
                }

                /**
                 * Process image replacement on S3
                 * Use it before saving your object with images
                 *
                 * Also, it calls a onImageUploaded(imageUrl) callback
                 *
                 * @return {Promise<string|null>} - returns image url or null
                 */
                this.run = () => {
                    return $q.all([
                        uploadPromise(),
                        deletePromise(),
                    ]).then(([imageUrl]) => {
                        if (onImageUploadedCallback) {
                            onImageUploadedCallback(imageUrl);
                        }

                        return angular.isString(imageUrl)
                            ? imageUrl
                            : null;
                    });
                }

                function uploadPromise() {
                    switch(true) {
                        case !imageToUpload && initialImageDeleted:   // image deleted
                            return $q.resolve(null);
                        case !imageToUpload && !initialImageDeleted:  // leave initial image
                            return $q.resolve(initialImageUrl);
                        case isRemoteImage:                           // image url (no need to upload to S3)
                            return $q.resolve(imageToUpload);
                        case Boolean(imageToUpload):                  // blob image (need upload to S3)
                            return ImageApiService.uploadImage(imageToUpload, initialUploadMode).then(response => response.data);
                        default:
                            throw new Error('Image uploader error. Missing upload promise');
                    }
                }

                function deletePromise() {
                    return initialImageUrl && initialImageDeleted
                        ? ImageApiService.deleteImage(initialImageUrl)
                        : $q.resolve(null);
                }
            }
        });
})();

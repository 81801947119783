(function() {
    'use strict';

    angular.module('beacon.app')
        .component('permissionsEditor', {
            templateUrl: '/assets/views/common/components/permissions-editor/permissions-editor.tpl.html',
            controller: PermissionsEditorController,
            bindings: {
                permissions: '<?',
                permissionGroups: '<',
                userRoles: '<?',
                isPermissionChecked: '<',
                togglePermission: '<',
                getFilteredPermissions: '<?',
                allowedModes: '<',
            }
        });

    function PermissionsEditorController(
        $scope,
        APP_MODES
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.TABS = {
            CMS: {
                id: 1,
                name: 'CMS',
                mode: APP_MODES.TRM.id,
            },
            SHARE_PARK: {
                id: 2,
                name: 'SHARE_PARK',
                mode: APP_MODES.SHARE_PARK.id,
            },
            SMART_CAMPUS: {
                id: 3,
                name: 'SMART_CAMPUS',
                mode: APP_MODES.SMART_CAMPUS.id,
            },
            MANAGEMENT: {
                id: 4,
                name: 'MANAGEMENT',
                mode: APP_MODES.MANAGEMENT.id,
            }
        };
        vm.APP_MODES = APP_MODES;

        vm.TABS_ARR = Object.values(vm.TABS);
        vm.selectedTabId = vm.TABS.CMS.id;

        vm.isTabVisible = isTabVisible;

        const groupsDefaultDataStructure = {
            name: "PERMISSION_GROUPS",
            items: [],
            idProp: "id",
            titleProp: "label",
            tooltipProp: 'description',
            filterFunction: (checkboxItem, selectedGroupItems) =>
                selectedGroupItems.indexOf(checkboxItem.permissions_group_id) !== -1
        };

        const permissionTabDataStructure = {
            items: [],
            titleProp: 'label',
            tooltipProp: 'description',
            groupByValue : 'permissions_group_id',
            orderByValue: 'name',
            groups: [],
            getGroupItemTitle: (key)=> {
                const permissionsGroup = vm.permissionGroups.find(
                    group => group.id === parseInt(key)
                );
                return permissionsGroup && permissionsGroup.label
                    ? permissionsGroup.label : key;
            },
            getGroupItemTooltip: (key)=> {
                const permissionsGroup = vm.permissionGroups.find(
                    group => group.id === parseInt(key)
                );
                return permissionsGroup && permissionsGroup.description
                    ? permissionsGroup.description : key;
            }
        };

        function init() {
            addWatchers();
            updatePermissionsList(vm.permissions);
        }

        function isTabVisible(modeId) {
            return !vm.allowedModes || vm.allowedModes.includes(modeId);
        }

        function updatePermissionsList(permissionsSource = []) {
            const permissions = vm.permissions || permissionsSource;
            const cmsPermissionGroups = vm.permissionGroups.filter(group => group.type === 'cms');
            const shareParkPermissionGroups = vm.permissionGroups.filter(group => group.type === 'share_park');
            const smartCampusPermissionGroups = vm.permissionGroups.filter(group => group.type === 'smart_campus');
            const managementPermissionGroups = vm.permissionGroups.filter(group => group.type === 'management');
            vm.permissionsTabConfig = shareParkPermissionGroups.length
                ? {
                    tabs: {
                        cms: Object.assign({}, permissionTabDataStructure, {
                            isChecked: permission => vm.isPermissionChecked(permission, vm.userRoles),
                            toggleCheckbox: permission => vm.togglePermission(permission, vm.userRoles),
                            items: permissions.filter(permission =>
                                cmsPermissionGroups.some(group => group.id === permission.permissions_group_id)
                            ),
                            groups: [Object.assign({}, groupsDefaultDataStructure, {
                                items: cmsPermissionGroups
                            })]
                        }),

                        sharePark: Object.assign({}, permissionTabDataStructure, {
                            isChecked: permission => vm.isPermissionChecked(permission, vm.userRoles),
                            toggleCheckbox: permission => vm.togglePermission(permission, vm.userRoles),
                            items: permissions.filter(permission =>
                                shareParkPermissionGroups.some(group => group.id === permission.permissions_group_id)
                            ),
                            groups: [Object.assign({}, groupsDefaultDataStructure, {
                                items: shareParkPermissionGroups
                            })]
                        }),

                        smartCampus: Object.assign({}, permissionTabDataStructure, {
                            isChecked: permission => vm.isPermissionChecked(permission, vm.userRoles),
                            toggleCheckbox: permission => vm.togglePermission(permission, vm.userRoles),
                            items: permissions.filter(permission =>
                                smartCampusPermissionGroups.some(group => group.id === permission.permissions_group_id)
                            ),
                            groups: [Object.assign({}, groupsDefaultDataStructure, {
                                items: smartCampusPermissionGroups
                            })]
                        }),

                        management: Object.assign({}, permissionTabDataStructure, {
                            isChecked: permission => vm.isPermissionChecked(permission, vm.userRoles),
                            toggleCheckbox: permission => vm.togglePermission(permission, vm.userRoles),
                            items: permissions.filter(permission =>
                                managementPermissionGroups.some(group => group.id === permission.permissions_group_id)
                            ),
                            groups: [Object.assign({}, groupsDefaultDataStructure, {
                                items: managementPermissionGroups
                            })]
                        }),
                    }
                } : Object.assign({}, permissionTabDataStructure, {
                    isChecked: permission => vm.isPermissionChecked(permission, vm.userRoles),
                    toggleCheckbox: permission => vm.togglePermission(permission, vm.userRoles),
                    items: vm.permissions,
                    groups: [Object.assign({}, groupsDefaultDataStructure, {
                        items: vm.permissionGroups
                    })]
                });
        }

        function addWatchers() {
            $scope.$watch(
                () => vm.allowedModes,
                (allowedModes) => {
                    if (allowedModes && allowedModes.length) {
                        const currentTabMode = vm.TABS_ARR.find(tab => tab.id === vm.selectedTabId).mode;
                        if (!allowedModes.includes(currentTabMode)) {
                            const newTab = vm.TABS_ARR.filter(tab =>
                                allowedModes.includes(tab.mode)
                            )[0];

                            if (newTab) {
                                vm.selectedTabId = newTab.id;
                            }
                        }
                    }
                },
                true
            );

            if (!vm.userRoles || !angular.isFunction(vm.getFilteredPermissions)) {
                return;
            }

            $scope.$watchGroup([
                angular.bind(vm.userRoles, function () {
                    return this.tenant_id;
                }),
                angular.bind(vm.userRoles, function () {
                    return this.subdomain_id;
                }),
                angular.bind(vm.userRoles, function () {
                    return this.role_id;
                })
            ], function (newValues, oldValues) {
                const availablePermissions = vm.getFilteredPermissions(newValues);
                updatePermissionsList(availablePermissions);
            });
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ShareParkHelperService', ShareParkHelperService);

    function ShareParkHelperService(
        $translate,
        DateHelper,
        UtilitiesService,
        DATETIME_FORMAT,
        RATE_TYPES,
    ) {

        return {
            decodeCarParkData,
            decodeQuota,
            encodeQuota,
            decodeTariff,
            encodeTariff,
            encodeTimeWindow,
            processParkingStatusesResponse
        };

        function decodeCarParkData(carPark) {
            carPark.description = angular.fromJson(carPark.description);
            carPark.public_transport = angular.fromJson(carPark.public_transport);
            carPark.womens_parking = angular.fromJson(carPark.womens_parking);
            carPark.barrier_free = angular.fromJson(carPark.barrier_free);
            carPark.opening_hours = angular.fromJson(carPark.opening_hours);
            carPark.rate_model_assignments.forEach(assignment => {
                if (assignment.start_date) {
                    const startDateUTC = moment.utc(assignment.start_date).toDate();
                    assignment.start_date = moment(startDateUTC).local().format(DATETIME_FORMAT);
                }

                if (assignment.end_date) {
                    const endDateUTC = moment.utc(assignment.end_date).toDate();
                    assignment.end_date = moment(endDateUTC).local().format(DATETIME_FORMAT);
                }
            });
            return carPark;
        }

        function decodeQuota(quota) {
            const quotaData = angular.copy(quota);
            quotaData.params = angular.fromJson(quotaData.params);

            if (quotaData.params.quotaType === RATE_TYPES.TIME_SLOT_TARIFF.id) {
                const params = quotaData.params;
                const tariffDays = {};

                params.rates = Object.values(params.tiers[0].rates);
                params.tiers[0].timeWindows.forEach(timeWindow => {
                    const dayId = +timeWindow.day;
                    if (!tariffDays[dayId]) {
                        tariffDays[dayId] = [];
                    }

                    const [start, end] = timeWindow.timeWindow.split('-');
                    const startTime = moment(start, "HH:mm");
                    const endTime = moment(end, "HH:mm");

                    tariffDays[dayId].push({
                        startTime,
                        endTime,
                        tariff: timeWindow.tariff
                    });
                });

                params.tariffDays = tariffDays;
            }

            if (angular.isString(quotaData.params.dayBreak)) {
                quotaData.params.dayBreak = moment(quotaData.params.dayBreak, 'HH:mm');
            }

            quotaData.params.unlimited = !angular.isNumber(quotaData.params.availableOperations);

            return quotaData;
        }

        function encodeQuota(quota) {
            const quotaData = angular.copy(quota);
            if (quotaData.params.dayBreak) {
                quotaData.params.dayBreak = quotaData.params.dayBreak.format('HH:mm');
            }

            if (quotaData.params.unlimited) {
                delete quotaData.params.availableOperations;
            }

            delete quotaData.params.unlimited;

            quotaData.params = encodeQuotaParams(quotaData.params);

            return quotaData;
        }

        function encodeQuotaParams(params) {
            if (params.quotaType === RATE_TYPES.TIME_SLOT_TARIFF.id) {
                const rates = {};
                const timeWindowsAll = [];

                params.rates.forEach((rate, index) => {
                    rates[index.toString()] = rate;
                });

                Object.keys(params.tariffDays).forEach(key => {
                    const timeWindows = params.tariffDays[key];
                    const dayStart = moment('00:00', 'HH:mm');
                    const dayEnd = moment('23:59', 'HH:mm');
                    timeWindows.sort((a, b) => {
                        return a.startTime.isBefore(b.startTime);
                    });

                    if (!timeWindows.length) {
                        timeWindows.push({
                            startTime: dayStart.clone(),
                            endTime: dayEnd.clone(),
                            tariff: 0
                        });
                    } else {
                        if (!timeWindows[0].startTime.isSame(dayStart)) {
                            const endTime = timeWindows[0].startTime.clone().subtract(1, 'minutes');
                            timeWindows.unshift({
                                startTime: moment('00:00', 'HH:mm'),
                                endTime,
                                tariff: 0
                            })
                        }

                        const lastTimeWindow = timeWindows[timeWindows.length - 1];
                        if (!lastTimeWindow.endTime.isSame(dayEnd)) {
                            const startTime = lastTimeWindow.endTime.clone().add(1, 'minutes');
                            timeWindows.push({
                                startTime,
                                endTime: moment('23:59', 'HH:mm'),
                                tariff: 0
                            })
                        }
                    }

                    for (let i = 0; i < timeWindows.length - 1; i++) {
                        const currentEndTime = timeWindows[i].endTime.clone();
                        const nextStartTime = timeWindows[i+1].startTime.clone();

                        if (!currentEndTime.isSame(nextStartTime.clone().subtract(1, 'minutes'))) {
                            timeWindows.splice(i+1, 0, {
                                startTime: currentEndTime.add(1, 'minutes'),
                                endTime: nextStartTime.subtract(1, 'minutes'),
                                tariff: 0
                            })

                        }
                    }

                    timeWindows.forEach(timeWindow => {
                        timeWindowsAll.push({
                            day: +key,
                            timeWindow: `${timeWindow.startTime.format('HH:mm')}-${timeWindow.endTime.format('HH:mm')}`,
                            tariff: timeWindow.tariff
                        });
                    });
                })

                params.tiers = [{
                    timeWindows: timeWindowsAll,
                    rates: rates,
                    defaultRateId: 0,
                }]
            }

            delete params.rates;
            delete params.tariffDays;

            return angular.toJson(params);
        }

        function decodeTariff(tariffData) {
            const tariff = angular.copy(tariffData);

            tariff.name = angular.fromJson(tariff.name);
            tariff.priceLabel = angular.fromJson(tariff.priceLabel);
            tariff.nameShort = angular.fromJson(tariff.nameShort);
            tariff.description = angular.fromJson(tariff.description);
            tariff.rateDescription = angular.fromJson(tariff.rateDescription);
            tariff.validityDuration = angular.fromJson(tariff.validityDuration);

            return tariff;
        }

        function encodeTariff(tariffData) {
            const tariff = angular.copy(tariffData);

            tariff.name = angular.toJson(tariff.name);
            tariff.priceLabel = angular.toJson(tariff.priceLabel);
            tariff.nameShort = angular.toJson(tariff.nameShort);
            tariff.description = angular.toJson(tariff.description);
            tariff.rateDescription = angular.toJson(tariff.rateDescription);
            tariff.validityDuration = angular.toJson(tariff.validityDuration);

            if (angular.isObject(tariff.params)) {
                tariff.params = angular.toJson(tariff.params);
            }

            delete tariff.capProductAssignment;
            delete tariff.capRateDiscountAssignment;
            delete tariff.quota;
            delete tariff.timeWindow;

            return tariff;
        }

        function encodeTimeWindow(timeWindow) {
            const timeWindowData = angular.copy(timeWindow);

            timeWindowData.parameters = angular.toJson(timeWindowData.parameters);
            timeWindowData.description = angular.toJson(timeWindowData.description);

            return timeWindowData;
        }

        function processParkingStatusesResponse(parkingStatuses) {
            const currentLocale = $translate.use();

            parkingStatuses.sort((a, b) => moment(b.startedParking) - moment(a.startedParking));
            parkingStatuses.forEach(item => {
                if (UtilitiesService.isJson(item.parkingCostsLabel)) {
                    const costsLabel = angular.fromJson(item.parkingCostsLabel);
                    item.parkingCostsLabel = costsLabel[currentLocale] || Object.values(costsLabel)[0];
                }
                item.duration = DateHelper.duration(item.startedParking, item.endedParking || moment() );

                if (UtilitiesService.isJson(item.assignedParkingPlace)) {
                    item.assignedParkingPlace = angular.fromJson(item.assignedParkingPlace);
                }
            });

            return parkingStatuses;
        }
    }
})();
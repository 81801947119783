(function() {
    'use strict';

    angular.module('beacon.app').directive('beaconUploadFile', beaconUploadFile);

    function beaconUploadFile() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/file-upload/file-upload.tpl.html',
            replace: true,
            controller: BeaconUploadFileController,
            controllerAs: 'beaconUploadFileController',
            bindToController: true,
            scope: {
                buttonText: '@',
                placeholderText: '@',
                accept: '@',
                required: '@',
                callback: '=',
                errorTrigger: '=',
                inputName: '@',
                messages: '=',
                fileName: '=',
                label: '<?',
                multiple: '<?'
            },
            link: function(scope, element, attrs) {
                var input = element.find('input[type="file"]'),
                    button = element.find('.uploadButton');

                if (input.length && button.length) {
                    button.click(function() {
                        input.click();
                    });
                }

                input.on('click', function() {
                    this.value = null;
                });
            }
        };
    }

    function BeaconUploadFileController($scope) {
        const vm = this;

        // public methods
        vm.onChange = onChange;
        vm.onPaste = onPaste;

        function onPaste(e) {
            const items = (e.clipboardData || e.originalEvent.clipboardData).items;
            const files = Object.keys(items).map(key => {
                const item = items[key];
                if (item.kind === 'file' && item.type.includes('image')) {
                    return item.getAsFile();
                }
            }).filter(item => item);

            files.length && onChange(files);
        }
        /**
         * File change handler
         *
         * @param {array|object|null} files
         */
        function onChange(files) {
            vm.callback((!files || files.length > 1) ? files : files[0]);
        }
    }
})();

(function() {
    'use strict';

    angular.module('trmTimetableHafas').factory('JourneyModel',
        /**
         * @param {MomentService} $moment
         */
        function($moment) {
            /**
             * @name Journey
             *
             * @property {MgateJourney} origin
             * @property {string} direction
             * @property {moment.Moment} time
             * @property {moment.Moment} timeReal
             * @property {moment.Moment} serverTime
             * @property {JourneyProduct} product
             * @property {JourneyPole} pole
             * @property {object} track
             * @property {string} track.name
             * @property {string} track.prefix
             * @property {string[]} viaStops
             * @property {boolean} isCancelled
             * @property {boolean} isPartlyCancelled
             */
            class Journey {
                static NOW_THRESHOLD_FROM_MS = 0;

                getScheduledTime() {
                    return this.time;
                }

                getRealTime() {
                    return this.timeReal;
                }

                /**
                 * @return {boolean}
                 */
                isRealTime() {
                    return !!this.timeReal;
                }

                /**
                 * @return {boolean}
                 */
                isPast() {
                    const now = $moment();
                    const msToDeparture = (this.timeReal || this.time).diff(now, 'ms');
                    return msToDeparture < Journey.NOW_THRESHOLD_FROM_MS;
                }

                /**
                 * @return {boolean}
                 */
                isFuture() {
                    return !this.isPast();
                }

                /**
                 * Time in milliseconds to past state (journey arrived or departed)
                 *
                 * @return {number}
                 */
                getMsToRealtimePastState() {
                    const now = $moment();
                    const pastStateTime = (this.timeReal || this.time).clone().add(-Journey.NOW_THRESHOLD_FROM_MS, 'ms');
                    return pastStateTime.diff(now, 'ms') + 1; // not to be equal
                }

                /**
                 * @param {string} separator
                 * @return {string}
                 */
                getViaStops(separator) {
                    return this.viaStops.join(separator);
                }
            }

            return {
                Journey,
            };
        });
})();

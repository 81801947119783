(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('MobileCampaignToolbarController', MobileCampaignToolbarController);

    function MobileCampaignToolbarController(campaignMobileState, PermissionsService, UserDataService) {
        const vm = this;

        const {
            actions,
            generateCampaignMobilePermission,
            isPermissionAvailable,
            generateSegmentPermission
        } = PermissionsService;

        // public properties
        vm.state = campaignMobileState;
        vm.state.canProcessNext = false;
        vm.state.view = 0;
        vm.state.finish = 0;
        vm.modifyCampaignMobilePermission = generateCampaignMobilePermission(actions.modify);

        // public methods
        vm.next = next;
        vm.back = back;
        vm.finish = finish;
        vm.getTabClass = getTabClass;
        vm.goToTab = goToTab;
        vm.isLastTab = isLastTab;

        /**
         * Initialization method
         */
        function init() {
            vm.showSections = isPermissionAvailable('can_view_location_beacon');
            if (!vm.showSections) {
                delete vm.state.views.SECTIONS;
                vm.state.views.AUDIENCE = 2;
            }
            vm.showAudiences = isPermissionAvailable(generateSegmentPermission(actions.view));
            if (!vm.showAudiences) {
                delete vm.state.views.AUDIENCE;
            }

        }

        /**
         * Switches to selected tab
         *
         * @param {number} tab
         */
        function goToTab(tab) {
            if (tab < vm.state.view) {
                vm.state.view = tab;
            }
        }

        /**
         * Returns tab class
         * @param {number} tab
         * @returns {string}
         */
        function getTabClass(tab) {
            return vm.state.view === tab ? 'active' : (vm.state.view < tab ? 'disabled' : 'done');
        }

        /**
         * Sets next view
         */
        function next() {
            if (vm.state.canProcessNext) {
                vm.state.view++;
            }
        }

        /**
         * Sets previous view
         */
        function back() {
            vm.state.canProcessNext = false;
            vm.state.view--;
        }

        /**
         * Saves content
         */
        function finish() {
            vm.state.finish = true;
        }


        /**
         * Check if current tab is last one
         */
        function isLastTab() {
            let lastTabIndex = 0;
            Object.keys(vm.state.views).forEach(key => {
                if (vm.state.views[key] > lastTabIndex) {
                    lastTabIndex = vm.state.views[key];
                }
            });
            return vm.state.view === lastTabIndex;
        }

        init();
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app').directive('equals', equals);

    /**
     * Directive checks whether two values are equal
     *
     * @returns {object}
     */
    function equals() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel) {
                    return;
                }

                var validate = function() {
                    var value1 = ngModel.$viewValue,
                        value2 = attrs.equals;

                    ngModel.$setValidity('equals', !value1 || !value2 || value1 === value2);
                };

                scope.$watch(attrs.ngModel, function() {
                    validate();
                });

                attrs.$observe('equals', function() {
                    validate();
                });
            }
        };
    }
})();

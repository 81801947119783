(function() {
    'use strict';

    angular.module('beacon.app').service('LanguageProvider', ($q, $interval, LanguageService) => {
        const timeout = 10; // seconds
        const intervalMs = 200; // ms

        let languages = [];

        return {
            /**
             * @return {Promise<void>}
             */
            init: () => {
                return LanguageService.getLanguages().then(response => {
                    languages = response.plain();
                })
            },
            /**
             * @return {Promise<Language[]>}
             */
            getTenantLanguages: () => $q(
                (resolve, reject) => {
                    if (languages.length) {
                        resolve(languages);
                        return;
                    }

                    let waitingTime = 0;

                    const interval = $interval(() => {
                        waitingTime += intervalMs;

                        if (waitingTime > timeout * 1000) {
                            $interval.cancel(interval);
                            reject('Can not load languages');
                        }

                        if (languages.length) {
                            $interval.cancel(interval);
                            resolve(languages);
                        }
                    }, intervalMs);
                }
            ),
        }
    });
})();

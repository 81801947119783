(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('GeofenceGroupAddController', GeofenceGroupAddController);

    function GeofenceGroupAddController(
        $scope,
        $stateParams,
        $state,
        $translate,
        GeofenceDataService,
        ModelsFactory,
        geofenceGroupAddState,
        locationTabState,
    ) {
        const vm = this;

        // public methods
        vm.onNameChangeCallback = onNameChangeCallback;
        vm.onSearchFilterChange = onSearchFilterChange;

        // public properties
        vm.state = geofenceGroupAddState;
        vm.searchName = '';

        init();

        function updatePolyline() {
            vm.polyline = vm.geofenceGroupData.geofences
                    .map(geofence => geofence.polyline).join("|");
        }

        vm.checkboxListData = {
            columns: [
                {
                    name: 'Name',
                    class: 'checkbox-list-item-title',
                    width: '65',
                    title: 'name',
                },
            ],
            header: false,
            itemClickCallback: updatePolyline,
        };

        function init() {
            GeofenceDataService.getGeofences().then(response => {
                vm.geofenceItems = response;
                vm.visibleGeofences = vm.geofenceItems;
            });
            vm.state.type = $stateParams.type;
            vm.geofenceGroupData = (vm.state.type === "new")
                ? new ModelsFactory.GeofenceGroup(): $stateParams.data;
            vm.polyline = vm.geofenceGroupData.geofences.map(geofence => geofence.polyline).join("|");
            vm.state.canCreateGroup = !!vm.geofenceGroupData.name;
        }

       function geofenceGroupResponseHandler(response) {
            $state.go('app.location', {
                paginationData: $stateParams.paginationData,
                data: {
                    selectedTabIndex: locationTabState.views.LOCATIONS.tabs.GEOFENCES.index,
                    selectedSubtabIndex: locationTabState.views.LOCATIONS.tabs.GEOFENCES
                            .subtabs.GEOFENCE_GROUPS.index,
                },
            });
        }

        /**
         * Save/finish action callback
         */
        function onFinish() {
            let promise;
            if (vm.state.type === 'new') {
                promise = GeofenceDataService.createGeofenceGroup(vm.geofenceGroupData);
            } else {
                promise = vm.geofenceGroupData.put();
            }
            promise.then(geofenceGroupResponseHandler).catch(console.error.bind(console));
        }

        /**
         * Handles group name change
         */
        function onNameChangeCallback() {
            vm.state.canCreateGroup = !!vm.geofenceGroupData.name;
        }

        function onSearchFilterChange() {
            vm.visibleGeofences = vm.searchName
                ? vm.geofenceItems.filter(geofence =>
                    geofence.name.toUpperCase().includes(vm.searchName.toUpperCase())
                )
                : vm.geofenceItems;
        }

        $scope.$watch(() => vm.state.finish, (newValue) => {
            if (newValue) {
                onFinish();
            }
        });
    }
}());

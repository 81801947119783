(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LocationHelper', LocationHelper);

    function LocationHelper() {

        return {
            coloredArea,
        }

        /**
         *
         * @param {string} area - 'lat,lng;lat,lng;lat,lng|lat,lng;lat,lng;lat,lng'
         * @param {string} color
         * @returns {string} - 'lat,lng;lat,lng;lat,lng:#color|lat,lng;lat,lng;lat,lng:#color'
         */
        function coloredArea(area, color) {
            if (!area) {
                return '';
            }
            if (!color) {
                return area;
            }
            return area.split('|')
                .map(item => item + ':' + color)
                .join('|');
        }
    }
})();
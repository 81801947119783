(function() {
    'use strict';

    angular.module('beacon.app')
        .component('devices', {
            templateUrl: '/assets/views/share-park/iot/iot-devices/iot-devices.tpl.html',
            controller: IotDevicesController
        });

    function IotDevicesController(
        $state,
        $stateParams,
        IotEntityDataService,
        PopupService,
        PermissionsService,
        ShareParkDataService,
        IOT_ENTITY_STATUSES,
        UtilitiesService,
    ) {
        const vm = this;

        const { actions, generateIotDevicePermission } = PermissionsService;
        const { sortByStringProperty } = UtilitiesService;

        vm.$onInit = init;

        vm.filterDevices = filterDevices;
        vm.searchValue = '';
        vm.selectedLocation = [];
        vm.filteredDevices = [];
        vm.visibleDevices = [];
        vm.filter = {};
        vm.showIotEntities = $stateParams.iotEntitiesMode;
        vm.devices = [];
        vm.devicesCount = 0;
        vm.updateCounter = 0;
        vm.devicesListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'device-title',
                    width: '50',
                    title: 'name',
                },
                {
                    name: 'LOCATION',
                    class: 'device-title',
                    width: '30',
                    title: 'location'
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteDevice,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editDevice,
                        permissionAction: actions.modify
                    },
                    {
                        class: item => {
                            switch (item.status) {
                                case IOT_ENTITY_STATUSES.ACTIVE:
                                    return 'block-btn';
                                case IOT_ENTITY_STATUSES.INACTIVE:
                                    return 'check-btn';
                                default: break;
                            }
                        },
                        callback: changeIotEntityStatus,
                        isVisible: () => vm.showIotEntities,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName,
        };

        vm.createDevice = createDevice;
        vm.onDevicesModeChange = onDevicesModeChange;

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
            getDevicesList();
        }

        /**
         * Create new device
         */
        function createDevice() {
            const targetState = vm.showIotEntities ? 'app.addIotEntity' : 'app.addIotDevice';
            $state.go(targetState);
        }

        function deleteDevice($event, device) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_THIS_DEVICE',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                const deleteFunction = vm.showIotEntities
                    ? IotEntityDataService.deleteIotEntity
                    : ShareParkDataService.deleteDevice;
                deleteFunction(device.id)
                    .then(() => {
                        vm.devicesCount -= 1;
                    })
                    .catch(console.error.bind(console));
            });
        }

        function editDevice($event, device) {
            $event.preventDefault();
            $event.stopPropagation();

            const targetState = vm.showIotEntities ? 'app.editIotEntity' : 'app.editIotDevice';
            $state.go(targetState, { data: device });
        }

        function getDevicesList() {
            let fetchParams  = {
                activity: true,
            };
            const fetchFunction = vm.showIotEntities
                ? IotEntityDataService.getIotEntities
                : ShareParkDataService.getDevices;
            fetchFunction(fetchParams)
                .then(response => {
                    vm.devices = response;
                    vm.devices = sortByStringProperty(response, 'name');
                    const locations = [...new Set(vm.devices.map(device => device.location))];
                    vm.locations = locations.filter(location => location).sort();

                    filterDevices();
                })
        }

        /**
         * Filters devices list
         */
        function filterDevices() {
            vm.filteredDevices = vm.searchValue
                ? vm.devices.filter(device => {
                    return device.name.toUpperCase().includes(vm.searchValue.toUpperCase())
                        || device.location.toUpperCase().includes(vm.searchValue.toUpperCase())
                })
                : vm.devices;

            if (vm.selectedLocation.length) {
                vm.filteredDevices = vm.filteredDevices.filter(device => vm.selectedLocation.includes(device.location));
            }
            vm.devicesCount = vm.filteredDevices.length;
            vm.updateCounter++;
        }

        /**
         * Update elements list handler
         * @param page
         * @param itemsPerPage
         */
        function updateElementsList(page, itemsPerPage) {
            vm.visibleDevices = UtilitiesService.getArrayPage(vm.filteredDevices, page, itemsPerPage)
        }

        function changeIotEntityStatus($event, device) {
            $event.preventDefault();
            $event.stopPropagation();

            const deviceData = angular.copy(device);
            const formData = new FormData();

            deviceData.status = device.status === IOT_ENTITY_STATUSES.ACTIVE
                ? IOT_ENTITY_STATUSES.INACTIVE
                : IOT_ENTITY_STATUSES.ACTIVE;

            formData.append('iotEntity', angular.toJson(deviceData));
            IotEntityDataService.updateIotEntity(device, formData)
                .then(() => {
                    vm.updateCounter++;
                })
        }
        
        function onDevicesModeChange() {
            getDevicesList();
            vm.updateCounter++;
        }

        function generatePermissionName(button) {
            return generateIotDevicePermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('FileService', fileService);

    function fileService(Restangular) {
        return {
            getRemoteImage,
            parseCSV,
            isFromS3,
            deleteFromS3,
        };

        function getRemoteImage(url) {
            return Restangular.all('get-remote-image').post({url}, {responseType: 'blob'})
        }

        function parseCSV(data) {
            return Restangular.all('/files/parse_csv')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Checks if file is on the S3 host
         *
         * @param {string} url
         * @returns {Promise<boolean>}
         */
        function isFromS3(url) {
            return Restangular.all('is-from-s3').post({url});
        }

        /**
         * Checks if file is on the S3 host
         *
         * @param {string} url
         * @returns {Promise<boolean>}
         */
        function deleteFromS3(url) {
            return Restangular.all('delete-from-s3').post({url});
        }
    }
})();

(function() {
    'use strict';

    angular.module('trmServices').service('MgateLocationValidator', function(
        ExpectedError
    ) {
        return {
            validate,
        }

        /**
         * @param {MgateLocationResponseCore} response
         * @param {string[][]} poleIds
         * @private
         */
        function validate(response, poleIds) {
            for (let part of response.svcResL) {
                if (part.err === 'LOCATION') {
                    console.log('Poles:', angular.toJson(poleIds));

                    throw new ExpectedError('Invalid pole');
                }
            }
        }
    });
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('NewUserGroupToolbarController', NewUserGroupToolbarController);

    function NewUserGroupToolbarController(
        $state,
        StatesFactory
    ) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.UserGroupStates);
        vm.state = vm.stateAss.state;
        vm.state.finish = 0;

        // public methods
        vm.getTabClass = getTabClass;
        vm.cancel = cancel;
        vm.finish = finish;
        vm.next = next;
        vm.back = back;
        vm.isLastTab = isLastTab;

        /**
         * Initialization method
         */
        function init() {
            vm.tabs = Object.keys(vm.stateAss.state.views);
        }

        function getTabClass(tab) {
            if (!(tab in vm.stateAss.state.views)) {
                return "";
            }

            return vm.stateAss.state.is(tab)
                ? 'active'
                : vm.stateAss.state.view < vm.stateAss.state.views[tab][0]
                    ? 'disabled'
                    : 'done';
        }

        function cancel() {
            $state.go('app.userGroups');
        }

        /**
         * "Next" button handler
         */
        function next() {
            vm.stateAss.state.next();
        }

        /**
         * "Back" button handler
         */
        function back() {
            vm.stateAss.state.back();
        }

        /**
         * Check if current tab is last one
         */
        function isLastTab() {
            let lastTabIndex = 0;
            Object.keys(vm.state.views).forEach(key => {
                if (vm.state.views[key][0] > lastTabIndex) {
                    lastTabIndex = vm.state.views[key][0];
                }
            });
            return vm.state.view === lastTabIndex;
        }

        function finish() {
            vm.state.finish++;
        }

        init();
    }
})();
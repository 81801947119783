(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('errorView', {
            template: `
                <div 
                    class="error-message" 
                    ng-style="{ 
                        color: $ctrl.colors.text, 
                        background: $ctrl.colors.background,
                    }"
                >{{ 'Derzeit keine Abfahrtsdaten' | translate }}</div>
                <div 
                    class="error-details" 
                    ng-style="{ 
                        color: $ctrl.colors.text, 
                    }"
                >{{ $ctrl.message | translate }}</div>
            `,
            /**
             * @property {{text: string, background: string}} colors
             */
            controller: function() {},
            bindings: {
                message: '<',
                colors: '<',
            }
        });
})();

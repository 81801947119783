(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ContentHelper', ContentHelperService);

    function ContentHelperService($translate,
                                  PopupService,
                                  PermissionsService,
                                  UtilitiesService,
                                  GoogleTranslateService,
                                  INFOTAINMENT_TYPES,
                                  CONTENT_TYPES_WHICH_CAN_RELATE_TO_MOBILE_CAMPAIGN,
                                  SCREEN_CAMPAIGN_CONTENT_TYPES,
                                  DEFAULT_CONTENT_GROUP,
                                  CONTENT_TYPES,
    ) {

        const { actions, generateCampaignPermission, isPermissionAvailable } = PermissionsService;
        const { oneOfContentTypes } = UtilitiesService;

        let NO_CONTENT_TITLE = 'NO_TITLE';

        $translate('NO_TITLE').then(translation => {
            NO_CONTENT_TITLE = translation;
        })

        return {
            getTitle,
            getDefaultTitle,
            getMessageText,
            getInfotainmentType,
            isFixedInfoType,
            canRelateToCampaign,
            filterContentListByDefaultContentGroup,
            decodeContent,
            processContentItem,
            isLanguageFilled,
            translateContent,
            getContentNameForList,
            isStandaloneBanner,
        };

        /**
         * Returns infotainment type object
         *
         * @param {object} content
         * @return {object}
         */
        function getInfotainmentType(content) {
            if (!content.data || !angular.isNumber(content.data.infotainmentType)) {
                return INFOTAINMENT_TYPES.DEFAULT;
            }
            const typeName = Object.keys(INFOTAINMENT_TYPES).find(
                typeName => INFOTAINMENT_TYPES[typeName].id === content.data.infotainmentType);
            return INFOTAINMENT_TYPES[typeName];
        }

        /**
         * Checks if selected infotainment type is fixed
         * @param {object} contentData
         * @return {boolean}
         */
        function isFixedInfoType(contentData) {
            return INFOTAINMENT_TYPES.FIXED.id === getInfotainmentType(contentData).id;
        }

        /**
         * Return content element's title
         *
         * @param {object} item
         * @param {int|null} languageId
         * @return {string}
         */
        function getTitle(item, languageId = null) {
            const lngId = languageId || item.currentLanguage || item.language_id;
            return item.title[lngId] || NO_CONTENT_TITLE;
        }

        function getDefaultTitle(item) {
            const defaultLanguageId = Object.keys(item.title)[0];
            return item.title[defaultLanguageId] || NO_CONTENT_TITLE;
        }

        /**
         * Return content element's message (description)
         *
         * @param {object} item
         * @param {int|null} languageId
         * @return {string}
         */
        function getMessageText(item, languageId = null) {
            const msg = item.message;
            const lngId = languageId || item.currentLanguage || item.language_id;
            return _.isString(msg[lngId]) && msg[lngId]
                || msg.message && _.isString(msg.message[lngId]) && msg.message[lngId]
                || msg[lngId] && _.isString(msg[lngId].message) && msg[lngId].message
                || '';
        }

        /**
         * Check if content of this type can relate to campaign
         * @param contentType
         * @return boolean
         */
        function canRelateToCampaign(contentType) {
            const permissionActions = [actions.create, actions.modify];
            let campaignTypes = [];
            switch (true) {
                case CONTENT_TYPES_WHICH_CAN_RELATE_TO_MOBILE_CAMPAIGN.includes(contentType):
                    campaignTypes = ['mobile', 'push'];
                    break;
                case SCREEN_CAMPAIGN_CONTENT_TYPES.includes(contentType):
                    campaignTypes = ['screen'];
            }

            return campaignTypes.some(campaignType => {
                return permissionActions.some(action =>
                    isPermissionAvailable(generateCampaignPermission(action, campaignType))
                );
            });
        }

        /**
         * Filter list of contents by default content group
         *
         * @param {Array|undefined} contentList
         * @return {Array}
         */
        function filterContentListByDefaultContentGroup(contentList) {
            if (!Array.isArray(contentList)) {
                return [];
            }

            return contentList.filter(content => content.content_group_id === DEFAULT_CONTENT_GROUP.id);
        }

        /**
         * Decodes content
         * @param content
         * @return {*}
         */
        function decodeContent(content) {
            content.title = angular.fromJson(content.title);
            content.message = angular.fromJson(content.message);
            content.data = content.data ? angular.fromJson(content.data) : {};

            return content;
        }
        /**
         * Process content item
         *
         * @param {object} item
         */
        function processContentItem (langArray, item) {
            const allLanguageIDs = langArray.map(lang => Number(lang.id));
            let languageIDs = [];

            item.title = angular.fromJson(item.title);
            item.message = angular.fromJson(item.message);
            item.data = item.data && angular.fromJson(item.data);

            switch (item.content_type_id) {
                case CONTENT_TYPES.INFOTAINMENT:
                    languageIDs = Object.keys(item.message);
                    break;
                case CONTENT_TYPES.QUIZ:
                case CONTENT_TYPES.FEEDBACK:
                    languageIDs = Object.keys(item.message.message);
                    break;
                default:
                    languageIDs = Object.keys(item.title);
            }

            if (item.content_group) {
                item.content_group.title = angular.fromJson(item.content_group.title);
            }

            if (item.language_sensitive_audio && Number(item.language_sensitive_audio)) {
                item.audio_src = angular.fromJson(item.audio_src);
            }

            if (item.language_sensitive_link && Number(item.language_sensitive_link)) {
                item.web_link = angular.fromJson(item.web_link);
            }

            for (let prop in item.title) {
                if (item.title.hasOwnProperty(prop) && allLanguageIDs.indexOf(parseInt(prop)) === -1) {
                    delete item.title[prop];
                    delete item.message[prop];
                }
            }

            item.currentLanguage = Number(item.language_id);
            item.allLanguages = langArray;
            item.map_info = item.map_info && angular.fromJson(item.map_info);
            item.languages = langArray.filter(lang => languageIDs.includes(lang.id.toString()));

            return item;
        }

        /**
         * Checks if current language is filled
         * @return {boolean}
         */
        function isLanguageFilled(contentData, langId) {
            const message = contentData.message;

            switch (true) {
                case oneOfContentTypes(['DIGITAL_DISPLAY', 'TICKER'], contentData.content_type_id):
                    return contentData.title[langId];
                case oneOfContentTypes(['INFOTAINMENT', 'QUIZ', 'FEEDBACK'], contentData.content_type_id):
                    return !!(message[langId] || (message.message && message.message[langId]));
                default:
                    return !!(contentData.title[langId] && (message[langId] ||
                        (message.message && message.message[langId])));
            }
        }

        /**
         *
         * @param { Object } content
         * @param { Array } langArray
         * @param { Object } targetLang
         */
        function translateContent(content, langArray, targetLang) {
            const srcLang = langArray.find(lang => lang.id === content.language_id);
            const translate = text => GoogleTranslateService.translate(text, srcLang.code, targetLang.code);
            const translateHTML = text => GoogleTranslateService.translate(text, srcLang.code, targetLang.code, true);

            content.language_id = targetLang.id;

            translate(content.title[srcLang.id]).then(text => content.title[targetLang.id] = text);

            switch (content.content_type_id) {
                case CONTENT_TYPES.INFOTAINMENT:
                case CONTENT_TYPES.DYNAMIC_CONTENT:
                    translateHTML(content.message[srcLang.id]).then(text => content.message[targetLang.id] = text);
                    break;
                case CONTENT_TYPES.QUIZ:
                case CONTENT_TYPES.FEEDBACK:
                    translate(content.message.message[srcLang.id]).then(text => content.message.message[targetLang.id] = text);
                    break;
            }
        }

        /**
         * Returns content title for current language
         * or name for dynamic contents
         */
        function getContentNameForList(content) {
            if (content.content_type_id === CONTENT_TYPES.DYNAMIC_CONTENT
                && content.data.name) {
                return content.data.name;
            }

            return content.title[content.currentLanguage] || content.title[content.language_id] || NO_CONTENT_TITLE;
        }

        function isStandaloneBanner(contentItem) {
            return contentItem.content_type_id === CONTENT_TYPES.BANNER
            && contentItem.data.standaloneBanner
        }
    }
}());

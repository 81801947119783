(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParks', {
            templateUrl: '/assets/views/share-park/car-parks/car-parks.tpl.html',
            controller: CarParksController
        });

    function CarParksController(
        $state,
        $stateParams,
        ShareParkDataService,
        ShareParkHelperService,
        PopupService,
        PermissionsService,
        DATETIME_FORMAT,
        HTTP_STATUSES,
    ) {
        const vm = this;

        const { actions, generateCarParkPermission } = PermissionsService;
        const { decodeCarParkData } = ShareParkHelperService;

        vm.$onInit = init;

        vm.carParks = [];
        vm.carParksCount = 0;
        vm.carParksListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'car-park-title',
                    width: '30',
                    title: 'name',
                },
                {
                    name: 'ADDRESS',
                    width: '40',
                    title: item => `${item.address_city}, ${item.address_street}`,
                },
                {
                    name: 'STATUS',
                    width: '10',
                    translate: true,
                    title: item => {
                        switch (item.status) {
                            case 0:
                                return 'INACTIVE';
                            case 1:
                                return 'ACTIVE';
                            default:
                                return 'N/A';
                        }
                    },
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteCarPark,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editCarPark,
                        permissionAction: actions.modify
                    }
                ]
            },
            updateCallback: updateElementsList,
            generatePermissionName,
            initOnOpen: true,
            additionalData: {
                previewLayout: 'row'
            }
        };

        vm.createCarPark = createCarPark;

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }

        /**
         * Create new car park
         */
        function createCarPark() {
            $state.go('app.addCarPark');
        }

        function deleteCarPark($event, carPark) {
            $event.preventDefault();
            $event.stopPropagation();

            const okHandler = () => {
                vm.carParksCount -= 1;
            };

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_CAR_PARK',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                deleteCapRequest(carPark,
                    okHandler,
                    err => {
                        if (err.status !== HTTP_STATUSES.LOCKED) {
                            console.error(err);
                            return;
                        }

                        const invitationsText = err.data.map(name => `"${name}"`).join(', ');
                        PopupService.showConfirmationPopup({
                            text: `This car park is used in ${invitationsText} ${
                                err.data.length > 1 
                                    ? 'invitations' 
                                    : 'invitation'
                            } are you sure you want to delete it?`,
                            okButtonText: 'Force delete',
                            cancelButtonText: 'Cancel'
                        }, function () {
                            deleteCapRequest(carPark, okHandler, console.error.bind(console), true);
                        });
                    });
            });
        }

        function deleteCapRequest(carPark, okHandler, errorHandler, force) {
            ShareParkDataService.deleteCarPark(carPark.id, force)
                .then(okHandler)
                .catch(errorHandler)
        }

        function editCarPark($event, carPark) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editCarPark', { data: carPark });
        }

        function updateElementsList(page, itemsPerPage) {
            let data = { page, itemsPerPage };

            ShareParkDataService.getCarParks(data)
                .then(response => {
                    vm.carParks = response.map(decodeCarParkData);
                    vm.carParksCount = response.count;
                })
        }

        /**
         * Generates permission name for each button on list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateCarParkPermission(button.permissionAction);
        }
    }
})();
(function() {
  'use strict';

  angular.module('beacon.app')
      .controller('cancelPopupController', cancelPopupController);

  function cancelPopupController(
      $uibModalInstance,
      data,
      PermissionsService,
  ) {
      const vm = this;

      vm.isPermissionAvailable = PermissionsService.isPermissionAvailable;
      vm.ok = okHandler;
      vm.cancel = cancelHandler;
      vm.isFormInvalid = isFormInvalid;

      vm.maxAmount = data.maxAmount;
      vm.cancelAmount = vm.maxAmount;
      vm.currencyCode = data.currencyCode;

      vm.paymentName = data.paymentName;
      vm.comment = '';

      /**
       * "OK" click handler
       */
      function okHandler() {
          $uibModalInstance.close(vm.comment);
      }

      function isFormInvalid() {
          return vm.cancelPopupForm && vm.cancelPopupForm.$invalid;
      }

      /**
       * "Cancel" click handler
       */
      function cancelHandler() {
          $uibModalInstance.dismiss();
      }
  }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
           .service('PermissionsService', PermissionsService);

    function PermissionsService(
        $auth,
        $state,
        Restangular,
        MAP_CONTENT_TYPE_ID_TO_PERMISSION,
        CONTENT_TYPES_WHICH_HAVE_STATISTICS,
        PAGE_TYPES,
        CONTENT_TYPES,
    ) {
        let allPermissions = [];
        let availablePermissions = [];

        const actions = {
            view: 'view',
            create: 'create',
            modify: 'modify',
            delete: 'delete',
        };

        /**
         * Loads available permissions for current User and all list of permissions
         * @return {Promise}
         */
        function loadPermissions() {
            return Restangular
                .one('permissions')
                .get()
                .then(function(response) {
                    let data = response.plain();

                    allPermissions = data.allPermissions;
                    availablePermissions = data.availablePermissions;
                })
                .catch(() => {
                    $auth.logout();
                    $state.go('login', { message: "SESSION_EXPIRED_PLEASE_RELOGIN" });
                });
        }

        function isPermissionNameValid(permissionName) {
            return allPermissions.some(p => p.name === permissionName);
        }

        /**
         * Checks if permission is available for current user
         *
         * @param {string} permissionName
         * @return {boolean} True if permission is available for current user, false - otherwise
         */
        function isPermissionAvailable(permissionName) {
            return availablePermissions.some(p => p.name === permissionName);
        }

        function filterContentTypesWhenCreateNewContent(contentTypes) {
            return contentTypes.filter((contentType) => {
                let permissionName = 'can_create_' + MAP_CONTENT_TYPE_ID_TO_PERMISSION[contentType.id];
                return isPermissionAvailable(permissionName);
            });
        }

        function generateContentPermission(action, contentTypeId) {
            if (!action) {
                return false;
            }

            let permissionName = 'can_' + action + '_' + MAP_CONTENT_TYPE_ID_TO_PERMISSION[contentTypeId];

            if (action === 'view' && CONTENT_TYPES_WHICH_HAVE_STATISTICS.some(v => v === contentTypeId)) {
                permissionName += '_statistics';
            }

            return permissionName;
        }

        function generateContentToolbarPermission(contentTypeId) {
            return 'can_modify_' + MAP_CONTENT_TYPE_ID_TO_PERMISSION[contentTypeId];
        }

        function filterPageTypesWhenAddNewPageToDigitalDisplay(pageTypesData) {
            const pageTypes = angular.copy(pageTypesData);

            return pageTypes.filter(pageType => {
                switch (pageType.id) {
                    case PAGE_TYPES.EXTERNAL_APP:
                        return isPermissionAvailable('can_view_externalapp');
                    case PAGE_TYPES.VISITOR_INFO:
                        pageType.children = _filterPageTypesChildrenWhenAddNewPageToDigitalDisplay(pageType.children);
                        return pageType.children.length;
                    default:
                        return true;
                }
            });
        }

        function _filterPageTypesChildrenWhenAddNewPageToDigitalDisplay(childrenData) {
            const children = angular.copy(childrenData);

            return children.filter(pageType => {
                switch (pageType.id) {
                    case PAGE_TYPES.VISITOR_INFO:
                        return isPermissionAvailable('can_view_infotainment');
                    case PAGE_TYPES.TICKER:
                        return isPermissionAvailable('can_view_ticker');
                    case PAGE_TYPES.TIMETABLE:
                        return [
                            'can_view_timetable',
                            'can_use_external_timetable'
                        ].some(permission => isPermissionAvailable(permission));
                    case PAGE_TYPES.WEATHER:
                        return isPermissionAvailable('can_view_weather');
                    case PAGE_TYPES.LINE_ROUTE:
                        return isPermissionAvailable('can_view_lineroute');
                    case PAGE_TYPES.DYNAMIC_CONTENT:
                        return isPermissionAvailable('can_view_dynamic_content');
                    case PAGE_TYPES.TIMETABLE_HAFAS:
                        return isPermissionAvailable('can_view_timetable_hafas');
                    case PAGE_TYPES.HIM:
                        return isPermissionAvailable('can_view_him');
                    default:
                        return true;
                }
            });
        }

        /**
         * Generates permission name for playlist external app button
         * @return {string} Permission name
         */
        function generatePlaylistExternalAppPermission() {
            return 'can_view_externalapp';
        }

        /**
         * Checks if the playlist internal app icon visible
         * @return {boolean} True if the playlist internal app icon visible, false otherwise
         */
        function isPlaylistInternalAppIconVisible() {
            let oneOfRequiredPermissions = [
                'can_view_feedback',
                'can_view_quiz',
                'can_view_infotainment',
                'can_view_timetable',
                'can_view_weather',
                'can_view_lineroute',
                'can_view_dynamic_content',
                'can_view_timetable_hafas',
                'can_view_him',
            ];
            return oneOfRequiredPermissions.some(isPermissionAvailable);
        }

        function isPlaylistTimetableTabVisible() {
            return [
                'can_view_timetable',
                'can_use_external_timetable'
            ].some(permission => isPermissionAvailable(permission))
        }

        function isPlaylistInfoElementTabVisible() {
            let oneOfRequiredPermissions = [
                'can_view_feedback',
                'can_view_quiz',
                'can_view_infotainment',
            ];

            return oneOfRequiredPermissions.some(isPermissionAvailable);
        }

        function isPlaylistWeatherTabVisible() {
            return isPermissionAvailable('can_view_weather');
        }

        function filterPlaylistInteractiveContentList(contentList) {
            const list = angular.copy(contentList);

            return list.filter(content => {
                const permissionName = `can_view_${MAP_CONTENT_TYPE_ID_TO_PERMISSION[content.content_type_id]}`;
                return isPermissionAvailable(permissionName);
            });
        }

        /**
         * Checks if the campaign menu item is visible
         * @return {boolean} True if the campaign menu item is visible, false otherwise
         */
        function isCampaignMenuItemVisible() {
            return [
                'can_view_campaign_mobile',
                'can_view_campaign_screen',
                'can_view_campaign_segment',
                'can_view_campaign_push',
                'can_view_habit',
                'can_view_scenario'
            ].some(isPermissionAvailable);
        }

        /**
         * Generates permission name for each button on campaign screen accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateScreenCampaignPermission(action) {
            return `can_${action}_campaign_screen`;
        }

        /**
         * Generates permission name for each button on campaign mobile accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCampaignMobilePermission(action) {
            return `can_${action}_campaign_mobile`;
        }

        /**
         * Generates permission name for each button on campaign push accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCampaignPushPermission(action) {
            return `can_${action}_campaign_push`;
        }

        /**
         * Generates permission name for campaign
         * @param action
         * @param campaignType
         * @return {string}
         */
        function generateCampaignPermission(action, campaignType) {
            return `can_${action}_campaign_${campaignType}`;
        }

        /**
         * Generates permission name for each button on campaign segment accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateSegmentPermission(action) {
            return `can_${action}_segment`;
        }

        /**
         * Generates permission name for view campaign habit
         */
        function generateHabitPermission(action) {
            return `can_${action}_habit`;
        }

        /**
         * Generates permission name for view scenario
         * @param action
         * @return {string}
         */
        function generateScenarioPermission(action) {
            return `can_${action}_scenario`;
        }

        /**
         * Generates permission name for geofence
         * @param {string} action
         * @return {string} Permission name
         */
        function generateGeofencePermission(action) {
            return `can_${action}_location_geofence`;
        }

        /**
         * Generates permission name geofence segments types on audience-picker
         * @param {string} type
         * @return {string} Permission name
         */
        function generateGeofenceSegmentTypesPermission(type) {
            return `can_set_geofence_${type}_location`;
        }

        /**
         * Generates permission name for segment filters checkboxes visibility
         * @param {string} type
         * @return {string} Permission name
         */
        function generateSegmentTypeFilterPermission(type) {
            return `can_view_${type}_segments`;
        }

        /**
         * Generates permission name for each button on beacon groups list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateBeaconGroupPermission(action) {
            return `can_${action}_beacon_group`;
        }

        /**
         * Generates permission name for each button on screen groups list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateScreenGroupPermission(action) {
            return `can_${action}_screen_group`;
        }

        /**
         * Checks if the content group menu item is visible
         *
         * @return {boolean} True if the content group menu item is visible, false - otherwise
         */
        function isContentGroupMenuItemVisible() {
            return isPermissionAvailable('can_view_content_group');
        }

        /**
         * Checks if the content group delete btn is visible
         *
         * @return {boolean} True if the content group delete btn is visible, false - otherwise
         */
        function isContentGroupDeleteBtnVisible() {
            return isPermissionAvailable('can_delete_content_group');
        }

        /**
         * Checks if the content management menu item is visible
         *
         * @return {boolean} True if the content management menu item is visible, false - otherwise
         */
        function isContentManagementMenuItemVisible() {
            return isPermissionAvailable('can_view_content_management');
        }
        /**
         * Determines if the content management link btn is visible
         *
         * @return {boolean} True if visible, false otherwise
         */
        function isContentManagementLinkBtnVisible() {
            return isPermissionAvailable('can_assign_screen_content_management');
        }

        /**
         * Determines if the location menu item is visible
         *
         * @return {boolean} True if visible, false otherwise
         */
        function isLocationMenuItemVisible() {
            return [
                'can_view_location_beacon',
                'can_view_location_screen',
                'can_view_location_geofence'
            ].some(isPermissionAvailable);
        }

        /**
         * Determines if the screen url is visible
         * @returns {boolean}
         */
        function isScreenUrlVisible() {
            return isPermissionAvailable('can_view_screen_url');
        }

        /**
         * Determines if the screen url is visible
         * @returns {boolean}
         */
        function isScreensDashboardVisible() {
            return isPermissionAvailable('can_view_screens_dashboard');
        }
        /**
         * Determines if the Statistics is visible
         *
         * @return {boolean} True if visible, false otherwise
         */
        function isStatisticsVisible() {
            return isPermissionAvailable('can_view_statistics');
        }

        /**
         * Determines if the Feedback Statistics is visible
         * @returns {boolean}
         */
        function isFeedbackStatisticsVisible() {
            return isPermissionAvailable('can_view_feedback_statistics');
        }

        /**
         * Determines if the Rss All Campaigns Checkbox is visible
         *
         * @return {boolean} True if visible, false otherwise
         */
        function isRssAllCampaignsCheckboxVisible() {
           return isPermissionAvailable('can_select_all_campaigns');
        }

        /**
         * Determines if the Rss Campaigns List is visible
         *
         * @return {boolean} True if visible, false otherwise
         */
        function isRssCampaignsListVisible() {
           return isPermissionAvailable('can_select_campaigns');
        }

        /**
         * Determines if the Rss Web Sources List is visible
         *
         * @return {boolean} True if visible, false otherwise
         */
        function isRssWebSourcesListVisible() {
           return isPermissionAvailable('can_view_rss_from_web');
        }

        /**
         * Checks if the POI menu item is visible
         * @return {boolean} True if the POI menu item is visible, false otherwise
         */
        function isPoiMenuItemVisible() {
            return [
                'can_view_poi_elements',
                'can_view_poi_tours',
            ].some(isPermissionAvailable);
        }

        /**
         * Checks if the POI menu item is visible
         * @return {boolean} True if the POI menu item is visible, false otherwise
         */
        function isTrmPoiMenuItemVisible() {
            return [
                'can_view_trm_poi_elements',
            ].some(isPermissionAvailable);
        }

        /**
         * Checks if Loyalty Campaign menu item is visible
         *
         * @return {boolean}
         */
        function isLoyaltyMenuItemVisible() {
            return [
                'can_view_loyalty_campaign',
                'can_view_tiers',
                'can_view_vouchers'
            ].some(isPermissionAvailable);
        }

        /**
         * Generates permission name for each button on POI elements accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generatePoiElementPermission(action) {
            return `can_${action}_poi_elements`;
        }

        /**
         * Generates permission name for each button on POI elements accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateTrmPoiElementPermission(action) {
            return `can_${action}_trm_poi_elements`;
        }

        /**
         * Generates permission name for each button on POI elements accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateTrmPoiCategoriesPermission(action) {
            return `can_${action}_trm_poi_categories`;
        }

        /**
         * Generates permission name for each button on POI tours accordion-list
         * @param {string} action
         * @return {string} Permission name
         */
        function generatePoiTourPermission(action) {
            return `can_${action}_poi_tours`;
        }

        /**
         * Generates permission name for each button on car parks list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCarParkPermission(action) {
            return `can_${action}_car_park`;
        }

        /**
         * Generates permission name for each button on IOT devices list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateIotDevicePermission(action) {
            return `can_${action}_iot_device`;
        }

        /**
         * Generates permission name for each button on cap customers list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCapCustomerPermission(action) {
            return `can_${action}_cap_customer`;
        }

        /**
         * Generates permission name for each button on cap profiles list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateProfilePermission(action) {
            return `can_${action}_share_park_profile`;
        }

        /**
         * Generates permission name for each button on cap profile configs list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateProfileConfigsPermission(action) {
            return `can_${action}_profile_configs`;
        }

        /**
         * Generates permission name for each button on cap invitations list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCapInvitationPermission(action) {
            return `can_${action}_cap_invitation`;
        }

        /**
         * Generates permission name for each button on cap service providers list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCapServiceProviderPermission(action) {
            return `can_${action}_cap_service_provider`;
        }

        /**
         * Generates permission name for each button on cap sublets list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCapSubletPermission(action) {
            return `can_${action}_cap_sublet`;
        }

        /**
         * Generates permission name for each button on legal text list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateLegalTextPermission(action) {
            return `can_${action}_legal_text`;
        }

        /**
         * Generates permission name for each button on faq configurations list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateFAQConfigurationPermission(action) {
            return `can_${action}_share_park_faq`;
        }

        /**
         * Generates permission name for each button on billing configurations list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateBillingConfigurationPermission(action) {
            return `can_${action}_billing_configuration`;
        }

        /**
         * Generates permission name for each button on external companies list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateCapCompanyPermission(action) {
            return `can_${action}_external_companies`;
        }

        /**
         * Checks if the Customers menu item is visible
         * @return {boolean} True if the Customers menu item is visible, false otherwise
         */
        function isCustomersMenuItemVisible() {
            return [
                'can_view_cap_customer',
                'can_view_cap_invitation',
                'can_view_mailing',
                'can_view_cap_service_provider',
                'can_view_cap_sublet',
                'can_view_external_companies',
                'can_view_share_park_profile',
                'can_view_profile_configs',
                'can_view_profile_rules'
            ].some(isPermissionAvailable);
        }

        /**
         * Checks if the Customers menu item is visible
         * @return {boolean} True if the Customers menu item is visible, false otherwise
         */
        function isCarParksMenuItemVisible() {
            return [
                'can_view_car_park',
                'can_view_car_park_capacity',
                'can_view_car_park_lot_management',
                'can_view_car_park_statistics',
                'can_view_concierge',
            ].some(isPermissionAvailable);
        }

        /**
         * Checks if the IOT menu item is visible
         * @return {boolean} True if the IOT menu item is visible, false otherwise
         */
        function isIotMenuItemVisible() {
            return [
                'can_view_iot_device',
                'can_view_iot_device_dashboard',
            ].some(isPermissionAvailable);
        }

        function generateShowMapPermissionName(contentTypeId) {
            let contentTypeName;
            switch (contentTypeId) {
                case CONTENT_TYPES.INFOTAINMENT:
                    contentTypeName = 'infotainment';
                    break;
                case CONTENT_TYPES.QUIZ:
                    contentTypeName = 'quiz';
                    break;
                case CONTENT_TYPES.FEEDBACK:
                    contentTypeName = 'feedback';
                    break;
            }

            return `show_map_on_${contentTypeName}_content`
        }

        /**
         * Checks if the IOT menu item is visible
         * @return {boolean} True if the IOT menu item is visible, false otherwise
         */
        function isShareParkOperationsMenuItemVisible() {
            return [
                'can_view_share_park_parking_events',
                'can_view_share_park_operations_revenues',
                'can_view_legal_text',
                'can_view_billing_configuration',
                'can_view_share_park_faq',
                'can_access_feedback_forms',
                'can_view_share_park_parking_reservations'
            ].some(isPermissionAvailable);
        }

        /**
         * Generates permission name for each button on discounts list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateDiscountsPermission(action) {
            return `can_${action}_discounts`;
        }

        /**
         * Generates permission name for each button on products list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateProductsPermission(action) {
            return `can_${action}_products`;
        }

        /**
         * Generates permission name for each button on tariffa list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateTariffsPermission(action) {
            return `can_${action}_tariffs`;
        }

        /**
         * Generates permission name for each button on tariff models list
         * @param {string} action
         * @return {string} Permission name
         */
        function generateTariffModelsPermission(action) {
            return `can_${action}_tariff_models`;
        }

        /**
         * Checks if pricing menu item is visible
         * @return {boolean} True if the IOT menu item is visible, false otherwise
         */
        function isPricingMenuItemVisible() {
            return [
                'can_view_discounts',
                'can_view_products',
                'can_view_tariffs',
                'can_view_tariff_models',
            ].some(isPermissionAvailable);
        }

        return {
            actions,
            loadPermissions,
            isPermissionNameValid,
            isPermissionAvailable,
            filterContentTypesWhenCreateNewContent,
            generateContentToolbarPermission,
            generateContentPermission,
            filterPageTypesWhenAddNewPageToDigitalDisplay,
            generatePlaylistExternalAppPermission,
            isPlaylistInternalAppIconVisible,
            isPlaylistWeatherTabVisible,
            isPlaylistTimetableTabVisible,
            isPlaylistInfoElementTabVisible,
            filterPlaylistInteractiveContentList,

            /**
             * Campaigns related permissions
             */
            isCampaignMenuItemVisible,

            generateScreenCampaignPermission,

            generateCampaignMobilePermission,

            generateCampaignPushPermission,
            generateCampaignPermission,

            generateSegmentPermission,
            generateGeofenceSegmentTypesPermission,
            generateSegmentTypeFilterPermission,

            generateBeaconGroupPermission,
            generateScreenGroupPermission,

            generateHabitPermission,

            generateScenarioPermission,

            /*
             * Geofences permissions
             */
            generateGeofencePermission,

            /**
             * Content group permissions
             */
            isContentGroupMenuItemVisible,
            isLocationMenuItemVisible,
            isContentGroupDeleteBtnVisible,

            isContentManagementMenuItemVisible,
            isContentManagementLinkBtnVisible,

            isStatisticsVisible,
            isFeedbackStatisticsVisible,

            /**
             * RSS element additional permissions
             */
            isRssAllCampaignsCheckboxVisible,
            isRssCampaignsListVisible,
            isRssWebSourcesListVisible,

            /**
             * Loyalty Campaigns permissions
             */
            isLoyaltyMenuItemVisible,

            /**
             * POI contents
             */
            isPoiMenuItemVisible,
            isTrmPoiMenuItemVisible,
            generatePoiElementPermission,
            generateTrmPoiElementPermission,
            generateTrmPoiCategoriesPermission,
            generatePoiTourPermission,

            isScreenUrlVisible,
            isScreensDashboardVisible,

            /**
             * Share park
             */
            generateCarParkPermission,
            generateIotDevicePermission,
            generateCapCustomerPermission,
            generateCapInvitationPermission,
            generateCapServiceProviderPermission,
            generateCapSubletPermission,
            isCustomersMenuItemVisible,
            isCarParksMenuItemVisible,
            isIotMenuItemVisible,

            generateShowMapPermissionName,

            isShareParkOperationsMenuItemVisible,

            generateLegalTextPermission,
            generateBillingConfigurationPermission,
            generateCapCompanyPermission,
            generateProfilePermission,
            generateFAQConfigurationPermission,
            generateProfileConfigsPermission,
            generateDiscountsPermission,

            isPricingMenuItemVisible,
            generateProductsPermission,

            generateTariffsPermission,
            generateTariffModelsPermission,
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screensDashboard', {
            controller: ScreensDashboardController,
            templateUrl: '/assets/views/location/dashboard/dashboard.tpl.html',
        });

    function ScreensDashboardController(
        LocationDataService,
        $interval,
        $q,
        $scope,
        $state,
        $translate,
        ContentHelper,
        LanguageService,
        Restangular,
        PopupService,
        PermissionsService,
        POLLING_STATUS,
        SCREEN_CONNECTION_STATUS,
        UserUtilitiesService,
        ScreenDataService,
    ) {
        const vm = this;
        const UPDATE_INTERVAL = 60 * 1000; // 60 seconds
        let interval;
        const DATE_FORMAT = 'MMMM D, YYYY (HH:mm)';

        const { isPermissionAvailable } = PermissionsService;

        vm.searchName = '';
        vm.visibleScreens = [];
        vm.updateCounter = 0;
        vm.visibleScreens = [];
        vm.connectionStatusCounts = {};
        vm.allScreensCount = 0;
        let screens = [], filteredScreens = [];

        vm.langArray = [];

        vm.POLLING_STATUS = POLLING_STATUS;

        vm.SCREEN_STATUS = {
            ACTIVE: 1,
            INACTIVE: 2,
            DELETED: 3,
        };

        vm.filter = {
            connectionStatus: {},
            screenStatus: {
                ACTIVE: true
            }
        };

        vm.onScreensFilterChange = onScreensFilterChange;
        vm.onScreensOrderChange = onScreensOrderChange;

        /**
         * List data for accordion
         */
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    width: '20',
                    class: 'col column-title',
                    title: item => item.details ? item.details.name : "",
                },
                {
                    name: 'STOP_NAME',
                    width: '15',
                    class: 'col',
                    title: item => item.details && item.details.station ? item.details.station.stopName : ''
                },
                {
                    name: 'CAMPAIGNS',
                    width: '5',
                    headerClass: 'col campaigns-amount',
                    class: 'col campaigns-amount',
                    title: item => item.campaignsCount || 0
                },
                {
                    name: 'LAST_CALL',
                    width: '15',
                    class: 'col',
                    title: lastCall,
                },
                {
                    name: 'STATUS',
                    width: '5',
                    headerClass: 'col status-cell',
                    class: 'col status-cell',
                    title: status
                },
                {
                    name: 'RECENT_STATUS_CHANGES',
                    width: '10',
                    headerClass: 'col recent-status-change',
                    class: 'col recent-status-change',
                    title: item => item.status_changes_count,
                },
                {
                    name: 'IP',
                    width: '10',
                    class: 'col ip-address',
                    title: item => item.details.ipAddress2 || '',
                },
                {
                    name: 'DESCRIPTION',
                    width: '10',
                    class: 'col',
                    title: item => item.details 
                        ? item.details.details.slice(0, 15) + '...' 
                        : ''
                }
            ],
            additionalData: {
                langsArray: [],
            },
            buttons: {
                width: '10',
                items: [
                    {
                        class: 'screenshotBtn',
                        callback: takeScreenshot,
                        isVisible: (btn, item) =>
                            isPermissionAvailable('can_make_screenshot')
                            && item.connectionStatus === vm.POLLING_STATUS.GREEN
                    },
                    {
                        class: 'quickUpBtn',
                        callback: ($event, item) => {
                            $event.preventDefault();
                            $event.stopPropagation();

                            ScreenDataService.reload(item.controllerRef);
                        },
                        tooltip: 'RELOAD_SCREEN',
                        isVisible: () => isPermissionAvailable('can_reload_screen'),
                    },
                ]
            },
            updateCallback,
            displayMoreItems,
            initOnOpen: true,
        };

        /**
         * Take screenshot button handler
         * @param $event
         * @param item
         */
        function takeScreenshot($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const hash = item.details.hash;

            PopupService.show({
                templateUrl: '/assets/views/common/popups/show-screenshot/show-screenshot.tpl.html',
                controller: 'ShowScreenshotPopupController',
                controllerAs: '$ctrl',
                windowClass: 'show-screenshot-popup',
                keyboard: false,
                resolve: {
                    data: () => ({hash})
                }
            });
        }

        /**
         * Set status light
         * @param item
         * @returns {string}
         */
        function status(item) {
            return `<div class="traffic-light ${item.connectionStatus}"></div>`;
        }

        /**
         * Set screen status
         * @param lastCall
         * @returns {string}
         */
        function setScreenConnectionStatus(lastCall) {
            const {RED, YELLOW, GREEN} = vm.POLLING_STATUS;
            const { RED_TIME, YELLOW_TIME } = SCREEN_CONNECTION_STATUS;
            let status = RED;

            if(lastCall) {
                const lastCallMoment = moment.utc(lastCall).local();
                const nowMoment = moment();
                const diff = moment.utc(nowMoment.diff(lastCallMoment, 'minutes'));

                switch (true) {
                    case diff > RED_TIME:
                        status = RED;
                        break;
                    case diff > YELLOW_TIME:
                        status = YELLOW;
                        break;
                    default:
                        status = GREEN;
                }
            }

            return status;
        }

        /**
         * Generate last call string
         * @param item
         * @returns {string}
         */
        function lastCall(item) {
            return item.lastCall
                ? moment.utc(item.lastCall).local().format(DATE_FORMAT)
                : '';
        }

        /**
         * Update callback
         * @param page
         * @param itemsPerPage
         */
        function updateCallback(page, itemsPerPage) {
            vm.paginationData = {page, itemsPerPage};
            const {
                getControllerDetails,
                getScreens,
            } = LocationDataService;

            $q.all([
                getControllerDetails(),
                getScreens('dashboard'),
                LanguageService.getLanguages()
            ]).then(response => {
                const screensFromAPI = response[0].list;
                const screensFromDB = response[1].list;

                vm.listData.additionalData.langsArray = response[2];
                screens = screensFromDB
                    .map(screen => {
                        const controllerId = screen.controllerRef;
                        const screenFound = screensFromAPI.find(controller => controller.id === controllerId);

                        if(screenFound) {
                            screen.details = screenFound;
                            screen.connectionStatus = setScreenConnectionStatus(screen.lastCall);
                            return screen;
                        }
                    })
                    .filter(s => s);

                orderScreens(screens);
                filterScreens();
                updateStatusCounts();

                if (!vm.visibleScreens.length) {
                    displayMoreItems();
                } else {
                    updateVisibleScreens();
                }
            }).catch(console.error.bind(console));
        }

        /**
         * Sort screens callback
         * @param a
         * @param b
         * @returns {number}
         */
        function compare(a, b) {
            const {RED, YELLOW, GREEN} = vm.POLLING_STATUS;

            if ((a.connectionStatus === RED && b.connectionStatus !== RED)
                || (a.connectionStatus === YELLOW && b.connectionStatus === GREEN)) {
                return -1;
            }
            if ((a.connectionStatus !== RED && b.connectionStatus === RED)
                || (a.connectionStatus === GREEN && b.connectionStatus === YELLOW)) {
                return 1;
            }
            if (a.connectionStatus === b.connectionStatus) {
                if (a.lastCall && !b.lastCall) {
                    return -1;
                }
                return moment(a.lastCall).isAfter(b.lastCall) ? -1 : 1;
            }
        }

        function onScreensFilterChange() {
            filterScreens();
            vm.visibleScreens = filteredScreens.slice(0, 24);
        }

        /**
         * Filter screens by status
         */
        function filterScreens() {
            const connectionStatus = vm.filter.connectionStatus;
            const connectionStatusFilter = Object.keys(connectionStatus).filter(key =>
                connectionStatus[key]
            );

            const screenStatus = vm.filter.screenStatus;
            const screenStatusFilter = Object.keys(screenStatus).filter(key =>
                screenStatus[key]
            );

            filteredScreens = [];

            filteredScreens = screenStatusFilter.length
                ? screens.filter(screen => {
                    const screenStatus = Object.keys(vm.SCREEN_STATUS).find(key =>
                        vm.SCREEN_STATUS[key] === screen.details.status
                    );
                    return screenStatusFilter.includes(screenStatus);
                })
                : screens;

            filteredScreens = connectionStatusFilter.length
                ? filteredScreens.filter(screen =>
                    connectionStatusFilter.includes(screen.connectionStatus)
                )
                : filteredScreens;

            filteredScreens = filterScreensByNamAndDescription(filteredScreens);
            $scope.$emit('list:filtered')
        }

        function filterScreensByNamAndDescription(screensList) {
            return vm.searchName
                ? screensList.filter(screen =>
                    screen.details.name.toUpperCase().includes(vm.searchName.toUpperCase())
                    || screen.details.details.toUpperCase().includes(vm.searchName.toUpperCase())
                    || screen.details.hash.toUpperCase().includes(vm.searchName.toUpperCase())
                )
                : screensList;
        }

        /**
         * If scrolled to page bottom - adds more items to visible screens list
         */
        function displayMoreItems() {
            if (vm.visibleScreens.length < filteredScreens.length) {
                let currentCount = vm.visibleScreens.length;
                for (let i = currentCount; i <= currentCount + 25; i++) {
                    if (!filteredScreens[i]) {
                        break;
                    }

                    vm.visibleScreens.push(filteredScreens[i]);
                }
            }
        }

        /**
         * Mutates visible screens array
         * Updates screens data
         * Inserts new screens
         * Removes removed screens
         */
        function updateVisibleScreens() {
            const removedScreenIds = [];
            const newVisibleScreens = filteredScreens.slice(0, vm.visibleScreens.length);
            vm.visibleScreens.forEach(screen => {
                const screenData = filteredScreens.find(updatedScreenData => {
                    return screen.id === updatedScreenData.id;
                });

                if (!screenData) {
                    removedScreenIds.push(screen.id);
                    return;
                }

                Object.assign(screen, screenData);
            });

            orderScreens(vm.visibleScreens);

            removedScreenIds.forEach(id => {
                const index = vm.visibleScreens.findIndex(screen => screen.id === id);
                vm.visibleScreens.splice(index, 1);
            });

            // if new screens were added - insert them into visible screens array
            newVisibleScreens.forEach((screen, index) => {
                if (vm.visibleScreens[index].id !== screen.id) {
                    vm.visibleScreens.splice(index, 0, screen);
                }
            });
        }

        function updateStatusCounts() {
            vm.connectionStatusCounts = {};
            screens.forEach(screen => {
                if (angular.isUndefined(vm.connectionStatusCounts[screen.connectionStatus])) {
                    vm.connectionStatusCounts[screen.connectionStatus] = 0;
                }
                vm.connectionStatusCounts[screen.connectionStatus]++;
            });

            vm.allScreensCount = screens.length;
        }

        function orderScreens(screens) {
            if (vm.unstableFirst) {
                screens.sort((a, b) => {
                    if (b.status_changes_count !== a.status_changes_count) {
                        return b.status_changes_count - a.status_changes_count;
                    }

                    return compare(a, b);
                })
            } else {
                screens.sort(compare);
            }
        }

        function onScreensOrderChange() {
            orderScreens(screens);
            filterScreens();
            vm.visibleScreens = filteredScreens.slice(0, 24);
        }

        /**
         * Set update interval
         */
        function setUpdateInterval() {
            interval = $interval(() => {
                vm.updateCounter++;
            }, UPDATE_INTERVAL);
        }

        vm.$onInit = function () {
            setUpdateInterval();

            vm.isSysAdmin = UserUtilitiesService.hasRoleSysAdmin();
        }

        vm.$onDestroy = function () {
            interval && $interval.cancel(interval);
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TrmPoiContentDataService', trmPoiContentDataService);

    function trmPoiContentDataService(
        $http,
    ) {
        return {
            getTrmPoiContents,
            save,
            findTrmPoiContents,
            deleteTrmPoiContent,
            getTrmPoiTypes,
            getTrmPoiCategories,
            getTrmPoiById,
            saveTrmPoiCategory,
            deleteTrmPoiCategory,
        };

        /**
         * Return poi contents list
         * 
         * @param {object} data
         */
        function getTrmPoiContents() {
            return $http.get('/api/trm-poi/contents');
        }

        
        /**
         * Get trm poi by id
         *
         * @param {number} id
         */
        function getTrmPoiById(id) {
            return $http.get('/api/trm-poi/' + id);
        }

        /**
         * Return poi contents list
         * 
         * @param {object} data
         */
        function findTrmPoiContents(data) {
            return $http.post('/api/trm-poi/contents/find', data);
        }

         /**
         * Updates content
         *
         * @param {object} data
         */
         function save(data) {
            return $http.post('/api/trm-poi/contents', data);
        }

        /**
         * Delete trm poi content by it`s id
         *
         * @param {object} id
         */
        function deleteTrmPoiContent(id) {
            return $http.delete('/api/trm-poi/content/' + id);
        }

        /**
         * Return poi types list
         * 
         * @param {object} data
         */
        function getTrmPoiTypes() {
            return $http.get('/api/trm-poi-types');
        }

        /**
         * Return poi categories list
         * 
         * @param {object} data
         */
        function getTrmPoiCategories() {
            return $http.get('/api/trm-poi-categories');
        }

         /**
         * Updates category
         *
         * @param {object} data
         */
         function saveTrmPoiCategory(data) {
            return $http.post('/api/trm-poi-category', data);
        }

        /**
         * Delete trm poi content by it`s id
         *
         * @param {object} id
         */
        function deleteTrmPoiCategory(id) {
            return $http.delete('/api/trm-poi-category/' + id);
        }
       
    }
})();

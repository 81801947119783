(function () {
    'use strict';

    angular
        .module('beacon.app')
        .controller('CustomerCommunicationToolbarController', CustomerCommunicationToolbarController);

    function CustomerCommunicationToolbarController($state, StatesFactory) {
        const vm = this;

        vm.stateAss = new StatesFactory.StateAssistant(StatesFactory.ShareParkCustomerComunicationStates);
        vm.state = vm.stateAss.state;
        vm.state.save = 0;
        vm.state.send = 0;

        // public methods
        vm.cancel = cancel;
        vm.save = save;
        vm.send = send;

        function cancel() {
            $state.go('app.shareParkCustomerCommunication');
        }

        function save() {
            vm.state.save++;
        }

        function send() {
            vm.state.send++;
        }
    }
})();

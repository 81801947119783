(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenMctSettingsTab', {
            controller: ScreenMctSettingsTabController,
            templateUrl: '/assets/views/location/new-screen/tabs/screen-mct-settings/screen-mct-settings.tpl.html',
            bindings: {
                controllerData: '<',
                onFindStationCallback: '<',
                onStationIdChange: '<',
                lines: '<',
                tracks: '<',
            }
        });

    /**
     * @property controllerData
     * @property {MctConfig} controllerData.timetableOptions
     *
     * @param $q
     * @param $scope
     * @param $timeout
     * @param ContentDataService
     * @param UserDataService
     * @param StatesFactory
     * @param TimetableOptionsHelper
     * @param TenantHelper
     * @param TIMETABLE_OPTIONS
     * @param TRACK_FILTERS_SPECIAL_VALUES
     * @param {CONTENT_TYPES} CONTENT_TYPES
     * @param PopupService
     * @constructor
     */
    function ScreenMctSettingsTabController(
        $q,
        $scope,
        $timeout,
        ContentDataService,
        UserDataService,
        StatesFactory,
        TimetableOptionsHelper,
        TenantHelper,
        TIMETABLE_OPTIONS,
        TRACK_FILTERS_SPECIAL_VALUES,
        CONTENT_TYPES,
        PopupService,
    ) {
        const vm = this;

        vm.$onInit = onInit;

        const { paramsStringToArray } = TimetableOptionsHelper;
        const SPECIAL_TRACK_FILTERS = Object.values(TRACK_FILTERS_SPECIAL_VALUES);

        let polesErrorShown = false;

        vm.state = StatesFactory.ScreenStates;
        vm.state.canBack = true;

        vm.TIMETABLE_OPTIONS = TIMETABLE_OPTIONS;
        vm.polesAmount = {1: '-', 2: '-', 3: '-', 4: '-'};
        vm.markers = [];
        vm.markersStorage = {1: [], 2: [], 3: [], 4: []};
        vm.showAllMarkers = false;
        vm.showPreview = true;
        vm.subdomainId;

        /** @type {TimetableHafasContent} */
        vm.timetableContentSelected;
        vm.timetableStyles;

        vm.isTimetableFilterVisible = TenantHelper.isTimetableFilterVisible;
        vm.onTimetableOptionChange = onTimetableOptionChange;
        vm.onPreviewChange = onPreviewChange;

        function onInit() {
            _initBackwardCompatibility();
            _initMap();
            setDefaultTimetableOptions();
            void setTimetableContents();
            void setSubdomainId();
        }

        vm.onPolesError = () => {
            if (polesErrorShown) {
                return;
            }

            PopupService.showConfirmationPopup({
                text: 'INVALID_POLES_POPUP_MESSAGE',
                okButtonText: 'OK',
            }).then(
                () => _reloadTimetablePreview()
            );

            polesErrorShown = true;
        }

        function setDefaultTimetableOptions() {
            vm.controllerData.timetableOptions = TimetableOptionsHelper.setDefaults(vm.controllerData.timetableOptions);

            vm.controllerData.timetableOptions[TIMETABLE_OPTIONS.platformFilter.prop] =
                paramsStringToArray(vm.controllerData.locationTrack, true)
                .filter(track => !SPECIAL_TRACK_FILTERS.some(filter => filter.value === Number(track)))
                .join(',');
        }

        function onTimetableOptionChange(option) {
            if (option === TIMETABLE_OPTIONS.platformFilter.prop) {
                const mctTracks = paramsStringToArray(vm.controllerData.timetableOptions[option], true);
                const stationTracks = paramsStringToArray(vm.controllerData.locationTrack, true);
                const checkedDefaultOptions = stationTracks.filter(trackStr => {
                    return Object.values(TRACK_FILTERS_SPECIAL_VALUES).some(filter => filter.value.toString() === trackStr);
                });

                vm.controllerData.locationTrack = checkedDefaultOptions.concat(mctTracks).join(',');
            }
        }

        /**
         * Set available timetable HAFAS templates
         * @returns {Promise<void>}
         */
        function setTimetableContents() {
            const contentId = vm.controllerData.timetableOptions.contentId;

            $q.all([
                ContentDataService.contents({ contentTypeId: CONTENT_TYPES.TIMETABLE_HAFAS }),
                ContentDataService.contents({ contentTypeId: CONTENT_TYPES.HEADER_FOOTER }),
            ])
            .then((response) => {
                /** @type TimetableHafasContent[] */
                const timetables = response[0].plain() || [];
                const headersMap = _generateHeadersMap(response[1].plain() || []);

                /** @type TimetableHafasContent[] */
                vm.timetableContents = timetables.map(item => {
                    /** @type TimetableHafasOptions */
                    const data = JSON.parse(item.data);

                    if (data.headerId && headersMap[data.headerId]) {
                        data.header = headersMap[data.headerId];
                    }

                    if (data.footerId && headersMap[data.footerId]) {
                        data.footer = headersMap[data.footerId];
                    }

                    item.data = JSON.stringify(data);

                    return item;
                });

                vm.timetableContentSelected = vm.timetableContents.find(item => item.id === contentId);

                if (!vm.timetableContentSelected) {
                    vm.timetableContentSelected = vm.timetableContents[0];
                }
            });
        }

        function onPreviewChange() {
            vm.controllerData.timetableOptions.contentId = vm.timetableContentSelected.id;
        }

        /**
         * @param {HeaderFooterContent[]} headers
         * @return {Object<number, HeaderFooterContent>}
         * @private
         */
        function _generateHeadersMap(headers) {
            const result = {};

            headers.forEach(item => {
                result[item.id] = item;
            })

            return result;
        }

        /**
         * Set subdomain id
         * @returns {Promise<void>}
         */
        function setSubdomainId() {
            return UserDataService.currentUserInfo().then(response => {
                const user = response.plain();

                vm.subdomainId = user.subdomain_id;
            });
        }

        $scope.$watch(
            () => vm.mctSettingsForm && vm.mctSettingsForm.$valid,
            isValid => vm.state.canNext = isValid
        );

        function _initBackwardCompatibility() {
            $scope.$watch(
                () => vm.controllerData.timetableOptions.poleFilter,
                primaryPoleFilter => {
                    vm.controllerData.timetableOptions.poleFilters[1] = primaryPoleFilter;
                },
                true,
            );
        }

        function _initMap() {
            $scope.$watch(
                () => [vm.markersStorage, vm.showAllMarkers],
                () => {
                    vm.markers = Object
                        .values(vm.markersStorage)
                        .flat()
                        .filter(item => vm.showAllMarkers || item.checked);
                },
                true,
            );
        }

        function _reloadTimetablePreview() {
            vm.showPreview = false;

            $timeout(() => vm.showPreview = true);
        }
    }
})();

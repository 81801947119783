(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newFaqConfiguration', {
            templateUrl: '/assets/views/share-park/operations/faq/new/new-faq-configuration.tpl.html',
            controller: NewFaqConfigurationController
        });

    function NewFaqConfigurationController(
        $scope,
        $state,
        $stateParams,
        $translate,
        FAQConfigurationDataService,
        GoogleTranslateService,
        LanguageService,
        ModelsFactory,
        PopupService,
        StatesFactory,
        UtilitiesService,
    ) {
        const vm = this;

        vm.$onInit = init;

        const {
            createFAQConfiguration,
            updateFAQConfiguration,
        } = FAQConfigurationDataService;

        vm.state = StatesFactory.ShareParkFAQStates.refresh();
        vm.questionsListData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'question-title',
                    width: '95',
                    title: 'title',
                },
                {
                    width: '5',
                    title: item => `<md-icon class="material-icons" role="img">${item.isOpen ? 'expand_less' : 'expand_more'}</md-icon>`,
                }
            ],
            updateCallback: () => {},
        };

        vm.langsArray = [];
        vm.additionalData = {
            updateIndexes,
            onGroupDetailsEdit: updateJson
        };

        vm.showInputError = showInputError;
        vm.onLanguageChange = onLanguageChange;
        vm.onJsonFileUpload = onJsonFileUpload;
        vm.onJsonUpdate = onJsonUpdate;
        vm.addQuestionsGroup = addQuestionsGroup;
        vm.removeQuestionsGroup = removeQuestionsGroup;
        vm.onQuestionGroupsOrderChange = onQuestionGroupsOrderChange;
        vm.translateHandler = translateHandler;
        vm.updateJson = updateJson;

        /**
         * Initialization method
         */
        function init() {
            vm.state.type = $stateParams.type;
            vm.isEditMode = vm.state.type === 'edit';

            vm.faqConfigurationData = vm.isEditMode
                ? $stateParams.data
                : new ModelsFactory.FAQConfiguration();

            if (vm.isEditMode) {
                vm.faqConfigurationData.questionGroups = {};
                vm.faqConfigurationData.headerText = {};
                vm.faqConfigurationData.footerText = {};
                Object.keys(vm.faqConfigurationData.faq_json).forEach(key => {
                    const jsonDecoded = angular.fromJson(vm.faqConfigurationData.faq_json[key]);
                    vm.faqConfigurationData.questionGroups[key] = jsonDecoded.questionGroups;
                    vm.faqConfigurationData.headerText[key] = jsonDecoded.headerText;
                    vm.faqConfigurationData.footerText[key] = jsonDecoded.footerText;
                });
                bindCallbacks();
            }

            getLanguages();
        }

        function getLanguages() {
            LanguageService.getLanguages().then((response) => {
                vm.langsArray = response.plain();
                if (!vm.faqConfigurationData.language_id) {
                    vm.faqConfigurationData.language_id = vm.langsArray[0].id;
                }
            })
        }

        function onLanguageChange(oldValue) {
            if (!UtilitiesService.isJson(vm.faqConfigurationData.faq_json[oldValue])) {
                delete vm.faqConfigurationData.faq_json[oldValue];
                delete vm.faqConfigurationData.questionGroups[oldValue];
                delete vm.faqConfigurationData.headerText[oldValue];
                delete vm.faqConfigurationData.footerText[oldValue];
            }

            canFinish();
        }

        /**
         *
         * @param jsonFile
         */
        function onJsonFileUpload(jsonFile) {
            const reader = new FileReader();
            reader.onload = function(event) {
                const result = event.target.result;
                if (UtilitiesService.isJson(result)) {
                    const langId = vm.faqConfigurationData.language_id;
                    vm.faqConfigurationData.faq_json[langId] = result;
                    onJsonUpdate();
                    $scope.$digest();
                }
            };
            reader.readAsText(jsonFile);
        }

        function addQuestionsGroup() {
            const langId = vm.faqConfigurationData.language_id;
            const questionGroups = vm.faqConfigurationData.questionGroups;
            if (!questionGroups[langId]) {
                questionGroups[langId] = [];
            }

            const newQuestionsGroup = {
                title: '',
                elements: [],
                index: questionGroups[langId].length + 1
            };

            newQuestionsGroup.addQuestion = addQuestion.bind(newQuestionsGroup);
            newQuestionsGroup.removeQuestion = removeQuestion.bind(newQuestionsGroup);
            questionGroups[langId].push(newQuestionsGroup);
            updateJson();
        }

        function onQuestionGroupsOrderChange() {
            updateIndexes();
        }

        function bindCallbacks() {
            Object.values(vm.faqConfigurationData.questionGroups).forEach(questionsGroups => {
                questionsGroups.forEach(questionsGroup => {
                    questionsGroup.addQuestion = addQuestion.bind(questionsGroup);
                    questionsGroup.removeQuestion = removeQuestion.bind(questionsGroup);
                });
            });
        }

        /**
         * Removes questions group from the list
         * @param $event
         * @param index
         */
        function removeQuestionsGroup($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.faqConfigurationData.questionGroups[vm.faqConfigurationData.language_id].splice(index, 1);
            updateIndexes();
        }

        function addQuestion() {
            this.elements.push({
                title: '',
                text: '',
                index: this.elements.length + 1
            });
            updateJson();
        }

        function removeQuestion($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            this.elements.splice(index, 1);
            updateIndexes();
        }

        function updateIndexes() {
            // update indexes
            vm.faqConfigurationData.questionGroups[vm.faqConfigurationData.language_id].forEach((questionsGroup, index) => {
                questionsGroup.index = index + 1;

                questionsGroup.elements.forEach((question, index) => {
                    question.index = index + 1;
                })
            });
            updateJson();
        }

        /**
         * Updates JSON
         */
        function updateJson() {
            const langId = vm.faqConfigurationData.language_id;
            const questionGroups = vm.faqConfigurationData.questionGroups;
            const questionGroupsCopy = angular.copy(questionGroups[langId]);
            questionGroupsCopy.forEach(group => {
                delete group.isOpen
            });

            const data = {
                headerText: vm.faqConfigurationData.headerText[langId] || '',
                footerText: vm.faqConfigurationData.footerText[langId] || '',
                questionGroups: questionGroupsCopy
            };
            vm.faqConfigurationData.faq_json[langId] = angular.toJson(data, true);
            canFinish();
        }

        function onJsonUpdate() {
            const langId = vm.faqConfigurationData.language_id;
            const jsonData = vm.faqConfigurationData.faq_json[langId];
            const isValidJson = UtilitiesService.isJson(jsonData);
            if (isValidJson) {
                if (validateJsonFormat(jsonData)) {
                    vm.faqConfigurationData.questionGroups[langId] = angular.fromJson(jsonData).questionGroups;
                    vm.faqConfigurationData.headerText[langId] = angular.fromJson(jsonData).headerText;
                    vm.faqConfigurationData.footerText[langId] = angular.fromJson(jsonData).footerText;
                    vm.newFAQConfigurationForm.jsonEditor.$setValidity('missingFields', true);
                    vm.newFAQConfigurationForm.jsonEditor.$setValidity('notJson', true);
                    bindCallbacks();
                } else {
                    vm.newFAQConfigurationForm.jsonEditor.$setValidity('missingFields', false);
                    vm.newFAQConfigurationForm.jsonEditor.$setValidity('notJson', true);
                }
            } else {
                vm.newFAQConfigurationForm.jsonEditor.$setValidity('missingFields', true);
                vm.newFAQConfigurationForm.jsonEditor.$setValidity('notJson', false);
            }

            canFinish();
        }


        function validateJsonFormat(jsonString) {
            const data = angular.fromJson(jsonString);

            return angular.isDefined(data.headerText)
                && angular.isDefined(data.footerText)
                && angular.isArray(data.questionGroups)
                && data.questionGroups.every(group => {
                    return angular.isDefined(group.index)
                        && angular.isDefined(group.title)
                        && angular.isArray(group.elements)
                        && group.elements.every(question => {
                            return angular.isDefined(question.index)
                                && angular.isDefined(question.title)
                                && angular.isDefined(question.text);
                        });
                })
        }

        /**
         * Language click handler
         *
         * @param {object} language
         */
        function translateHandler(language) {
            const currentLang = vm.langsArray.find(lang => lang.id === vm.faqConfigurationData.language_id);

            Promise.all([
                $translate(currentLang.name),
                $translate(language.name)
            ]).then(([sourceLangName, targetLangName]) => {
                $translate('TRANSLATE_FROM_TO', {
                    from: sourceLangName,
                    to: targetLangName,
                }).then(translation => {
                    PopupService.showConfirmationPopup({
                        text: translation,
                        okButtonText: 'OK',
                        cancelButtonText: 'CANCEL'
                    }).then(() => {
                        translateTo(language);
                    });
                });
            });
        }

        /**
         * Translate handler
         *
         * @param tgtLang - target language
         */
        function translateTo(tgtLang) {
            const langId = vm.faqConfigurationData.language_id;
            const srcLang = vm.langsArray.find(lang => lang.id === langId);
            const strings = [];
            vm.faqConfigurationData.questionGroups[tgtLang.id] = angular.copy(vm.faqConfigurationData.questionGroups[langId]);

            strings.push(vm.faqConfigurationData.headerText[langId] || '');
            strings.push(vm.faqConfigurationData.footerText[langId] || '');
            vm.faqConfigurationData.questionGroups[langId].forEach(group => {
                strings.push(group.title);

                group.elements.forEach(element => {
                    strings.push(element.title);
                    strings.push(element.text);
                })
            });

            GoogleTranslateService.translateMultiple(strings, srcLang.code, tgtLang.code).then(translations => {
                vm.faqConfigurationData.headerText[tgtLang.id] = translations.shift();
                vm.faqConfigurationData.footerText[tgtLang.id] = translations.shift();
                vm.faqConfigurationData.questionGroups[tgtLang.id].forEach(group => {
                    group.title = translations.shift();

                    group.elements.forEach(element => {
                        element.title = translations.shift();
                        element.text = translations.shift();
                    })
                });
                vm.faqConfigurationData.language_id = tgtLang.id;
                updateJson();
                onLanguageChange(srcLang);
                bindCallbacks();
            });
        }

        /**
         * Checks is error must be shown
         * @param { String } name
         * @return {boolean}
         */
        function showInputError(name) {
            return vm.newFAQConfigurationForm
                && vm.newFAQConfigurationForm[name].$dirty
                && vm.newFAQConfigurationForm[name].$invalid;
        }

        /**
         * Ob finish handler
         */
        function onFinish() {
            const formData = new FormData();

            formData.append('faqConfiguration', angular.toJson(vm.faqConfigurationData));

            if (!vm.isEditMode) {
                createFAQConfiguration(formData)
                    .then(onSuccess)
                    .catch(errorHandler);
            } else {
                updateFAQConfiguration(vm.faqConfigurationData, formData)
                    .then(onSuccess)
                    .catch(errorHandler);
            }
        }

        function onSuccess() {
            $state.go('app.shareParkFAQ', {
                paginationData: $stateParams.paginationData
            });
        }

        function errorHandler(err) {
            if (angular.isString(err.data.error)) {
                PopupService.showAlertPopup({
                    text: err.data.error,
                    okButtonText: 'OK'
                });
            }
            console.log(err)
        }

        function canFinish() {
            const langId = vm.faqConfigurationData.language_id;
            const isValidJson = UtilitiesService.isJson(vm.faqConfigurationData.faq_json[langId]);

            vm.state.canFinish = vm.newFAQConfigurationForm
                && vm.newFAQConfigurationForm.$valid
                && isValidJson;
        }

        $scope.$watch(() => {
            return vm.newFAQConfigurationForm && vm.newFAQConfigurationForm.$valid;
        }, canFinish, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
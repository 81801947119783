(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screensFilter', {
            controller: screensFilterController,
            templateUrl: '/assets/views/common/components/screens-filter/screens-filter.tpl.html',
            bindings: {
                screens: '=',
                callback: '<',
            },
        });

    function screensFilterController(
        $scope,
        SCREEN_FILTER_TYPE,
        SCREEN_FILTER_GROUP_TYPE,
        LocationDataService,
    ) {
        const vm = this;

        vm.showErrors = true;
        vm.filter = null;
        vm.filteredScreens = [];
        vm.filterTypes = [];

        const TYPE = SCREEN_FILTER_TYPE;

        const COMPARISON = {
            MORE_THAN: 'More than',
            MORE_THAN_OR_EQUAL: 'More than or equal',
            LESS_THAN: 'Less than',
            LESS_THAN_OR_EQUAL: 'Less than or equal',
            EQUAL: 'Equal',
            NOT_EQUAL: 'Not equal',
            CONTAIN: 'Contain',
            NOT_CONTAIN: 'Not contain',
            STARTS_WITH: 'Starts with',
            ENDS_WITH: 'Ends with',
        };

        vm.filterComparisons = [
            {name: COMPARISON.MORE_THAN,            types: [TYPE.NUMBER]},
            {name: COMPARISON.MORE_THAN_OR_EQUAL,   types: [TYPE.NUMBER]},
            {name: COMPARISON.LESS_THAN,            types: [TYPE.NUMBER]},
            {name: COMPARISON.LESS_THAN_OR_EQUAL,   types: [TYPE.NUMBER]},
            {name: COMPARISON.EQUAL,                types: [TYPE.TEXT, TYPE.NUMBER, TYPE.TEXT_LABEL, TYPE.TEXT_ARRAY]},
            {name: COMPARISON.NOT_EQUAL,            types: [TYPE.TEXT, TYPE.NUMBER, TYPE.TEXT_LABEL]},
            {name: COMPARISON.CONTAIN,              types: [TYPE.TEXT, TYPE.TEXT_ARRAY]},
            {name: COMPARISON.NOT_CONTAIN,          types: [TYPE.TEXT, TYPE.TEXT_ARRAY]},
            {name: COMPARISON.STARTS_WITH,          types: [TYPE.TEXT]},
            {name: COMPARISON.ENDS_WITH,            types: [TYPE.TEXT]},
        ];

        vm.generateResult = generateResult;
        vm.$onInit = init;

        function init() {
            LocationDataService.getScreenFilterTypes()
                .then(response => {
                    vm.filterTypes = processFilterTypes(response.plain());
                });
        }

        /**
         * Processes filter types from API
         * @param filterTypes
         * @return {*}
         */
        function processFilterTypes(filterTypes) {
            return filterTypes.map(filterType => {
                if (filterType.dataType === TYPE.TEXT_LABEL) {
                    filterType.dataLabelMap = filterType.dataLabelMap.split('|')
                        .map(encodedFilter => {
                            const decodedFilter = encodedFilter.split(';');
                            return {
                                label: decodedFilter[0],
                                value: decodedFilter[1],
                            }
                        });
                }

                return filterType;
            });
        }

        /**
         * Generates component result
         *
         * @param filter
         */
        function generateResult(filter) {
            vm.filter = filter;
            vm.filteredScreens = vm.screens.filter(
                screen => checkCondition(screen, filter)
            );
            vm.callback(vm.filteredScreens);
        }

        /**
         * Checks group
         *
         * @param screen
         * @param filterGroup
         * @return {*}
         */
        function checkCondition(screen, filterGroup) {
            const compareMethod = getCompareMethod(filterGroup.group);

            return filterGroup.items[compareMethod](
                item => item.group
                    ? checkCondition(screen, item)
                    : checkFilterElementCondition(screen, item)
            );
        }

        /**
         * Returns array method for checking filter group
         *
         * @param groupType
         * @return {string}
         */
        function getCompareMethod(groupType) {
            switch (groupType) {
                case SCREEN_FILTER_GROUP_TYPE.AND:
                    return 'every';
                case SCREEN_FILTER_GROUP_TYPE.OR:
                    return 'some';
            }
        }

        /**
         * Check if screen is passing single filter condition
         *
         * @param screen
         * @param filterItem
         * @return {boolean}
         */
        function checkFilterElementCondition(screen, filterItem) {

            // consider unfilled filter as not passed
            if (isEmptyFilterItem(filterItem)) {
                return false;
            }

            const dataType = filterItem.filterType.dataType;
            const screenProp = objectProperty(screen, filterItem.filterType.dataSourceMap);
            const screenValue = dataType === TYPE.TEXT_ARRAY
                ? screenProp && screenProp.split(',') || []
                : screenProp;
            const filterValue = dataType === TYPE.NUMBER
                ? Number(filterItem.filterValue)
                : filterItem.filterValue;

            switch (filterItem.filterComparison.name) {
                case COMPARISON.MORE_THAN:
                    return screenValue > filterValue;
                case COMPARISON.MORE_THAN_OR_EQUAL:
                    return screenValue >= filterValue;
                case COMPARISON.LESS_THAN:
                    return screenValue < filterValue;
                case COMPARISON.LESS_THAN_OR_EQUAL:
                    return screenValue <= filterValue;
                case COMPARISON.EQUAL:
                    return dataType === TYPE.TEXT_ARRAY
                        ? screenValue.includes(filterValue)
                        : screenValue === filterValue;
                case COMPARISON.NOT_EQUAL:
                    return dataType === TYPE.TEXT_ARRAY
                        ? !screenValue.includes(filterValue)
                        : screenValue !== filterValue;
                case COMPARISON.CONTAIN:
                    return compareVals(screenValue, filterValue, 'contains');
                case COMPARISON.NOT_CONTAIN:
                    return compareVals(screenValue, filterValue, 'notContains');
                case COMPARISON.STARTS_WITH:
                    return compareVals(screenValue, filterValue, 'startsWith');
                case COMPARISON.ENDS_WITH:
                    return compareVals(screenValue, filterValue, 'endsWith');
            }
        }

        /**
         * Check if screen value matches filter value
         * @param screenValue
         * @param filterValue
         * @param compareMethod
         * @return {boolean}
         */
        function compareVals(screenValue, filterValue, compareMethod) {
            switch(true) {
                case angular.isArray(screenValue):
                    const arrayCompareMethod = compareMethod === 'notContains' ? 'every' : 'some';
                    return screenValue[arrayCompareMethod](value => {
                       return compareStrings(value, filterValue, compareMethod);
                    });
                case angular.isString(screenValue):
                    return compareStrings(screenValue, filterValue, compareMethod);
                default:
                    return false;
            }
        }

        /**
         * Compares 2 strings by compareMethod
         * @param screenValue
         * @param filterValue
         * @param compareMethod
         * @return boolean
         */
        function compareStrings(screenValue, filterValue, compareMethod) {
            if (compareMethod === 'notContains') {
                return !screenValue.toLowerCase().contains(filterValue.toLowerCase());
            }
            return screenValue.toLowerCase()[compareMethod](filterValue.toLowerCase());
        }

        /**
         * Checks if filter item is not filled completely
         *
         * @param filterItem
         * @return {boolean}
         */
        function isEmptyFilterItem(filterItem) {
            return !filterItem.filterType || !filterItem.filterComparison || !filterItem.filterValue;
        }

        /**
         * Returns object property by map string
         *
         * @param obj
         * @param path
         * @return {*}
         */
        function objectProperty(obj, path) {
            const pathArray = path.split('.');
            const len = pathArray.length;

            for (let i = 0; i < len; i++) {
                if (obj === undefined) {
                    return null;
                }

                if (angular.isArray(obj)) {
                    obj = obj.map(arrEl => arrEl[pathArray[i]]);
                } else {
                    obj = obj[pathArray[i]];
                }
            }
            return obj;
        }
    }
})();
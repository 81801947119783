(function() {
    'use strict';

    angular.module('beacon.app')
        .component('polesPicker', {
            controller: PolesPickerController,
            templateUrl: '/assets/views/location/new-screen/tabs/screen-mct-settings/poles-picker/poles-picker.tpl.html',
            bindings: {
                stationId: '<',
                enableTrackPrefix: '<',
                model: '=',
                amount: '=',
                markers: '=',
                onError: '&',
            }
        });

    /**
     * @typedef {Object} PolesPickerCoordinates
     *
     * @property {number} x
     * @property {number} y
     * @property {number} lng
     * @property {number} lat
     */

    /**
     * @typedef {Object} PolesPickerPole
     *
     * @property {string} lid
     * @property {string} type
     * @property {string} name
     * @property {number} icoX
     * @property {string} extId
     * @property {string} state
     * @property {PolesPickerCoordinates} crd
     * @property {boolean} meta
     * @property {number} pCls
     * @property {boolean} isMainMast
     * @property {string[]} gidL
     */

    /**
     * @property {string} stationId
     * @property {boolean} enableTrackPrefix
     * @property {MctConfigPoleFilter} model
     * @property {RouteMapMarker[]} markers
     * @property {function} onError
     *
     * @param ScreenDataService
     *
     * @constructor
     */
    function PolesPickerController(
        ScreenDataService
    ) {
        const $ctrl = this;

        $ctrl.ready = false;

        /**
         * @type {PolesPickerPole[]}
         */
        $ctrl.poles = [];

        $ctrl.$onInit = () => {
            _getPoles().then(poles => {
                $ctrl.poles = poles;
                $ctrl.ready = true;

                _updateView();
                _handleInvalidPoles();
            })
        }

        /**
         * @param {string} extId
         * @return {boolean}
         */
        $ctrl.isPoleActive = (extId) => {
            return Boolean($ctrl.model) && angular.isObject($ctrl.model[extId]);
        }

        /**
         * @param {PolesPickerPole} pole
         */
        $ctrl.togglePole = (pole) => {
            const { extId } = pole;

            if ($ctrl.isPoleActive(extId)) {
                delete $ctrl.model[extId];
            } else {
                if (!$ctrl.model) {
                    $ctrl.model = {};
                }

                $ctrl.model[extId] = {
                    filterName: '',
                    arrow: 0,
                };
            }

            _updateView();
        }

        function _updateView() {
            _updateAmount();
            _updateMarkers();
        }

        function _updateAmount() {
            if (angular.isUndefined($ctrl.amount)) {
                return;
            }

            const keys = angular.isObject($ctrl.model) ? Object.keys($ctrl.model) : [];
            const amount = !keys.length ? $ctrl.poles.length : keys.length;

            $ctrl.amount = amount + '/' + $ctrl.poles.length;
        }

        function _updateMarkers() {
            const allChecked = !$ctrl.model || !Object.keys($ctrl.model).length;

            $ctrl.markers = $ctrl.poles.map(pole => {
                return {
                    position : {
                        lat: pole.crd.lat,
                        lng: pole.crd.lng,
                    },
                    label: pole.extId,
                    checked: allChecked || Object.keys($ctrl.model).includes(pole.extId),
                    onClick: () => $ctrl.togglePole(pole),
                }
            });
        }

        /**
         * @return {Promise<PolesPickerPole[]>}
         * @private
         */
        function _getPoles() {
            return ScreenDataService.getStationById($ctrl.stationId)
                .then(({polesData}) => {
                    const poleFilters = polesData.svcResL[0].res.common.locL;

                    polesData.svcResL[0].res.locL.forEach(poleFilter => {
                        if (poleFilters.every(filter => filter.extId !== poleFilter.extId)) {
                            poleFilters.push(poleFilter);
                        }
                    });

                    return poleFilters.filter(pole => pole.extId.length > 4)
                        .map(pole => {
                            const coords = pole.crd;

                            if (coords) {
                                coords.lng = coords.x / 1000000;
                                coords.lat = coords.y / 1000000;
                            }

                            return pole;
                        })
                        .sort((a, b) => a.extId.localeCompare(b.extId));
                });
        }

        function _handleInvalidPoles() {
            if (!$ctrl.model) {
                return;
            }

            const selectedPoleIds = Object.keys($ctrl.model);

            const invalidPoleIds = selectedPoleIds.filter(poleId => {
                return !$ctrl.poles.some(pole => pole.extId === poleId); 
            });

            _fixInvalidPoles();

            if (invalidPoleIds.length) {
                $ctrl.onError && $ctrl.onError();
            }
        }

        function _fixInvalidPoles() {
            const poleIds = Object.keys($ctrl.model);

            poleIds.forEach(poleId => {
                const poleValid = $ctrl.poles.some(pole => pole.extId === poleId);

                if (!poleValid) {
                    delete $ctrl.model[poleId];
                }
            });

            _updateView();
        }
    }
})();

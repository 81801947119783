(function() {
    'use strict';

    angular.module('beacon.app')
        .component('weatherContentType', {
            templateUrl: '/assets/views/content/elements/types/weather/weather.tpl.html',
            controller: WeatherContentType,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
                previewmode: '<?'
            }
        });

    function WeatherContentType(
        $scope,
        $timeout,
        $http,
        $translate,
        notify,
        StatesFactory
    ) {
        const vm = this;

        // OpenWeatherMap
        const WEATHER_HOURLY_FORECAST_URL = '/share/weather-hourly-forecast/proxy';
        const WEATHER_DAILY_FORECAST_URL = '/share/weather-daily-forecast/proxy';

        const weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const RETRY_TIME_MILLIS = 100000; // in 100s
        const AUTO_RELOAD_INTERVAL_MS = 600000; // every 10min
        const FORECAST_DAYS = 3;

        let language = 'en';
        let lat = '48.10';
        let lng = '11.64';
        let units = 'metric';

        // region original app
        moment.locale(language);

        // Weather relevant variables
        let hourlyForecast; // Normally 1 forecast every 3 hours
        let dailyForecast; // One forecast per day
        let cachedHourlyForecast; // We keep a backup that is used when new forecast data is empty
        let cachedDailyForecast;
        let reloadTimer;
        let retryTimer;
        // let initCnt = 0;

        // The number of forecast reports per day that are displayed on the panel (for the hourly report).
        const NUMBER_OF_DISPLAYED_FORECASTS_PER_DAY = 4;

        // from Bootstrap, cannot change - used for determining the column size used
        const MAX_COLUMN_SIZE = 12;

        // The number of forecast reports received for the same day, contained in the hourlyForecast.
        // Normally it's 8 forecasts per day, 1 every three hours, except for the current day, for which it can be 1..8
        const NUMBER_OF_RECEIVED_FORECASTS_PER_DAY = 8;

        // The number of forecast reports per day that should be represented by each element in the displayed data
        // It should be equal to 2, normally
        let ELEMENTS_PER_GROUP = NUMBER_OF_RECEIVED_FORECASTS_PER_DAY / NUMBER_OF_DISPLAYED_FORECASTS_PER_DAY;

        // public properties
        vm.state = StatesFactory.ContentStates;
        vm.dailyForecast = [];

        // keeps only some modified fields of the hourly forecast data, necessary for weather display
        vm.hourlyForecast = [];

        // The size of the columns used to display the weather - used for css class 'col'
        vm.columnSize = MAX_COLUMN_SIZE / NUMBER_OF_DISPLAYED_FORECASTS_PER_DAY;

        // public methods

        init();

        // private methods

        /**
         * Initialization method
         */
        function init() {
            destroy();

            // initCnt += 1;
            vm.model = vm.contentData.message[vm.contentData.language_id];

            notify.closeAll();
            notify.config({ duration: 5000 }); // 0 = do not close automatically

            loadWeather((error, hourlyData, dailyData) => {
                if (error) {
                    // let str = `Error loading weather data (${error}), retrying...`;
                    retryTimer = $timeout(init, RETRY_TIME_MILLIS); // retry in 10s
                    notify('Error loading weather data from provider, retrying...');

                    if (cachedDailyForecast && cachedHourlyForecast) {
                        processWeatherData(cachedHourlyForecast, cachedDailyForecast);
                    }
                } else {
                    hourlyForecast = hourlyData;
                    dailyForecast = dailyData;
                    setViewUnits(units);
                    processWeatherData(hourlyForecast, dailyForecast);
                    reloadTimer = $timeout(init, AUTO_RELOAD_INTERVAL_MS);
                }
            });
        }

        /**
         * Get date object.
         *
         * @param {number} timeInMs
         * @returns {Date}
         */
        function getDate(timeInMs) {
            let date = new Date(0);
            if (timeInMs) {
                date.setUTCSeconds(timeInMs);
            }
            return date;
        }

        /**
         * Cancel running timers.
         */
        function destroy() {
            if (reloadTimer) {
                $timeout.cancel(reloadTimer);
                reloadTimer = undefined;
            }

            if (retryTimer) {
                $timeout.cancel(retryTimer);
                retryTimer = undefined;
            }
        }

        /**
         * Returns the hour in the day of the date parameter, as a double digit string
         * There is a systematic difference of two hours between the timestamp of each forecast received in ms
         * and the human readable date of each forecast. We keep the time of the date.
         *
         * @param {Date} date
         * @returns {*}
         */
        function getHour(date) {
            let hours;
            if (date.getHours() < 10) {
                hours = '0' + (date.getHours());
            } else {
                hours = (date.getHours());
            }
            return hours;
        }

        /**
         * Sets the units of measurement according to the chosen system.
         *
         * @param {string} units 'metric'/'imperial'
         */
        function setViewUnits(units) {
            if (units === 'metric') {
                vm.rainUnit = 'mm';
                vm.tempUnit = 'celsius';
                vm.windUnit = 'km/h';
            } else {
                vm.rainUnit = 'in';
                vm.tempUnit = 'fahrenheit';
                vm.windUnit = 'mph';
            }
        }

        /**
         * Aggregates the amount of rain for the time period defined by the given indices.
         *
         * @param {object} hourlyForecast
         * @param {number} startIndex
         * @param {number} endIndex
         * @returns {number}
         */
        function findRainInPartOfDay(hourlyForecast, startIndex, endIndex) {
            let rain = 0;
            if (startIndex === endIndex) {
                if (hourlyForecast.list[startIndex].rain) {
                    rain = isNaN(hourlyForecast.list[startIndex].rain['3h']) ? 0 : hourlyForecast.list[startIndex].rain['3h'];
                }
            }
            for (let i = startIndex; i < endIndex; i++) {
                if (hourlyForecast.list[i].rain) {
                    rain += isNaN(hourlyForecast.list[i].rain['3h']) ? 0 : hourlyForecast.list[i].rain['3h'];
                }
            }
            return rain;
        }

        /**
         * Finds the min and max temperatures in the given time period.
         *
         * @param {object} hourlyForecast
         * @param {number} startId
         * @param {number} endId
         * @returns {{min: number, max: number}}
         */
        function minMaxTemp(hourlyForecast, startId, endId) {
            /* jshint -W106 */
            // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
            let min = hourlyForecast.list[startId].temp.min;
            let max = hourlyForecast.list[startId].temp.max;
            for (let i = startId; i <= endId; i++) {
                if (hourlyForecast.list[i].temp.min < min) {
                    min = hourlyForecast.list[i].temp.min;
                }
                if (hourlyForecast.list[i].temp.max > max) {
                    max = hourlyForecast.list[i].temp.max;
                }
            }
            /* jshint +W106 */
            // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
            return { min, max };
        }

        /**
         * Traverses the hourlyForecast list for the given indices and finds the most common
         * weather type occurrence, which is set as representative for this time period.
         *
         * @param {object} hourlyForecast
         * @param {number} startId
         * @param {number} endId
         * @returns {*}
         */
        function findAverageOfHourlyGroupedWeather(hourlyForecast, startId, endId) {
            let weatherTypeInstances = [];

            for (let i = startId; i <= endId; i++) {
                if (i === startId) {
                    weatherTypeInstances.push({});
                    weatherTypeInstances[0].id = hourlyForecast.list[startId].weather[0].id;
                    weatherTypeInstances[0].cnt = 1;
                    weatherTypeInstances[0].description = hourlyForecast.list[startId].weather[0].description;
                } else {
                    for (let j = 0, l = weatherTypeInstances.length; j < l; j++) {
                        if (weatherTypeInstances[j].id === hourlyForecast.list[i].weather[0].id) {
                            weatherTypeInstances[j].cnt++;
                            break;
                        } else if (j === weatherTypeInstances.length - 1) {
                            weatherTypeInstances.push({});
                            weatherTypeInstances[j + 1].id = hourlyForecast.list[i].weather[0].id;
                            weatherTypeInstances[j + 1].cnt = 1;
                            weatherTypeInstances[j + 1].description = hourlyForecast.list[i].weather[0].description;
                            break;
                        }
                    }
                }
            }

            // Sort the array of weather types and get the type with the most instances.
            weatherTypeInstances.sort((instance1, instance2) => instance1.cnt - instance2.cnt);
            return weatherTypeInstances[weatherTypeInstances.length - 1];
        }

        /**
         * Sets the values of the hourly forecast data to be displayed, for the time period defined by
         * the given indices.
         *
         * @param {object} hourlyForecast
         * @param {number} startIndex
         * @param {number} endIndex
         * @returns {*}
         */
        function setHourlyForecastDisplayedData(hourlyForecast, startIndex, endIndex) {
            let averageWeatherType = findAverageOfHourlyGroupedWeather(hourlyForecast, startIndex, endIndex);
            let displayedWeather = {};
            let minMaxTemperatures = minMaxTemp(hourlyForecast, startIndex, endIndex);
            let startDate = getDate(hourlyForecast.list[startIndex].dt);
            let endDate = getDate(hourlyForecast.list[endIndex].dt);
            let startHours = getHour(startDate);
            let endHours = getHour(endDate);
            let rainInPartOfDay = findRainInPartOfDay(hourlyForecast, startIndex, endIndex);
            let snowInPartOfDay = findRainInPartOfDay(hourlyForecast, startIndex, endIndex);

            displayedWeather.iconId = averageWeatherType.id;
            displayedWeather.description = averageWeatherType.description;
            displayedWeather.temperatureMin = Math.round(minMaxTemperatures.min);
            displayedWeather.temperatureMax = Math.round(minMaxTemperatures.max);
            displayedWeather.rain = Math.round((1000 * rainInPartOfDay)) / 1000;
            displayedWeather.snow = Math.round((1000 * snowInPartOfDay)) / 1000;
            displayedWeather.time = `${startHours} - ${endHours + $translate.instant('h')}`;

            if (displayedWeather.iconId === 800 || displayedWeather.iconId === 801 || displayedWeather.iconId === 802) {
                if (endDate.getHours() > 21 || startDate.getHours() > 17) {
                    displayedWeather.day = '-n';
                } else {
                    displayedWeather.day = '-d';
                }
            } else {
                displayedWeather.day = '';
            }
            return displayedWeather;
        }

        /**
         * Sets vm.dailyForecast, vm.weekday
         * vm.dailyForecast: the values for the daily weather for each day
         * vm.weekday: the corresponding string for the day, e.g. Today, Tomorrow, Friday, ..
         *
         * @param {object} dailyForecast
         * @return {Boolean}
         */
        function setDailyForecast(dailyForecast) {
            vm.weekday = [];
            vm.dailyForecast = [];

            if (dailyForecast && dailyForecast.list && dailyForecast.list.length >= FORECAST_DAYS) {
                for (let i = 0; i < FORECAST_DAYS; i++) {
                    vm.dailyForecast.push({});

                    if (dailyForecast.list[i].weather && dailyForecast.list[i].weather.length > 0) {
                        // Daily weather, in summary, taken from open weather daily forecast
                        vm.dailyForecast[i].iconId = dailyForecast.list[i].weather[0].id ? dailyForecast.list[i].weather[0].id : '';
                        vm.dailyForecast[i].description = dailyForecast.list[i].weather[0].description ? dailyForecast.list[i].weather[0].description : '';
                    } else {
                        return true;
                    }

                    vm.dailyForecast[i].humidity = dailyForecast.list[i].humidity ? dailyForecast.list[i].humidity : 0;
                    vm.dailyForecast[i].rain = dailyForecast.list[i].rain ? dailyForecast.list[i].rain : 0.0;
                    vm.dailyForecast[i].snow = dailyForecast.list[i].snow ? dailyForecast.list[i].snow : 0.0;
                    vm.dailyForecast[i].wind = dailyForecast.list[i].speed
                        ? Math.round(dailyForecast.list[i].speed * 36) / 10
                        : 0;
                    vm.dailyForecast[i].deg = dailyForecast.list[i].deg ? dailyForecast.list[i].deg : 0;
                    vm.degrees = dailyForecast.list;
                    vm.dailyForecast[i].temperatureMin = dailyForecast.list[i].temp ? Math.round(dailyForecast.list[i].temp.min) : '-';
                    vm.dailyForecast[i].temperatureMax = dailyForecast.list[i].temp ? Math.round(dailyForecast.list[i].temp.max) : '-';

                    if (i === 0) {
                        vm.weekday.push('TODAY');
                    } else if (i === 1) {
                        vm.weekday.push('TOMORROW');
                    } else {
                        vm.weekday.push(weekday[getDate(dailyForecast.list[i].dt).getDay()]);
                    }
                }
                return false;
            } else {
                return true;
            }
        }

        /**
         * Traverses the hourlyForecast array and process its data in order to fill in the array
         * vm.hourlyForecast, which stores the hourly forecast data to display,
         * grouped by the day to which they refer. Data for different days are stored in
         * different lines. $scope.hourlyForecast data are displayed when the accordion is open.
         *
         * @param {object} hourlyForecast
         */
        function setHourlyForecast(hourlyForecast) {
            let dayIndex = 0;

            vm.hourlyForecast.push([]);

            // The date of the current hourly forecast element - all times received are in UTC
            if (hourlyForecast.list && hourlyForecast.list[0] && hourlyForecast.list[0].dt) {
                let dataDate = getDate(hourlyForecast.list[0].dt);
                let dayId = dataDate.getDay();

                // The date of the following hourly forecast element
                let nextDataDate;

                // Indices used to define the current group of data under examination
                let startOfGroup = 0;
                let endOfGroup = 0;

                // Traverse all weather data to group them into days
                for (let i = 1, j = hourlyForecast.list.length; i < j; i++) {
                    // Get date of next piece of data - UTC
                    nextDataDate = getDate(hourlyForecast.list[i].dt);
                    if (dayId !== (nextDataDate.getDay())) { // New day
                        // If the first forecast for the next day is early enough, keep the data to end current day
                        if (nextDataDate.getHours() < 3) {
                            endOfGroup = i;
                        } else {
                            // If not, don't take next day's data into account for current group
                            endOfGroup = i - 1;
                        }

                        // Push current forecast group to array and start a new group for the new day
                        let data = setHourlyForecastDisplayedData(hourlyForecast, startOfGroup, endOfGroup);
                        vm.hourlyForecast[dayIndex].push(data);
                        if (dayIndex + 1 < FORECAST_DAYS) {
                            dayIndex = dayIndex + 1;
                            dayId = nextDataDate.getDay();
                            // Start of next day, add a new row.
                            vm.hourlyForecast.push([]);
                            startOfGroup = i;
                        } else {
                            // We have enough elements, stop
                            break;
                        }
                    } else if (endOfGroup - startOfGroup === ELEMENTS_PER_GROUP) {
                        // Enough elements for this group, push to array
                        let data = setHourlyForecastDisplayedData(hourlyForecast, startOfGroup, endOfGroup);
                        vm.hourlyForecast[dayIndex].push(data);
                        startOfGroup = i;
                    } else {
                        endOfGroup = i + 1;
                    }
                }
                return false;
            } else {
                vm.hourlyForecast = [];
                return true;
            }
        }

        /**
         * Load weather data from openweather
         *
         * @param {function} callback(err, hourlyData, dailyData)
         */
        function loadWeather(callback) {
            let canCallCallback = callback && typeof callback === 'function';
            let hourlyWeatherUrl = `${WEATHER_HOURLY_FORECAST_URL}?lat=${lat}&lon=${lng}&units=${units}&lang=${language}`;

            // Get hourly forecast
            $http({
                method: 'GET',
                url: hourlyWeatherUrl,
                timeout: 30000
            }).then(function(response) {
                let hourlyData = response.data;
                let dailyUrl = `${WEATHER_DAILY_FORECAST_URL}?cnt=3&lat=${lat}&lon=${lng}&units=${units}&lang=${language}`;

                // Check if we have received enough data to display
                // if (hourlyData.list && hourlyData.list.length >= FORECAST_DAYS) {
                // Get daily forecast
                $http({
                    method: 'GET',
                    url: dailyUrl,
                    timeout: 30000
                }).then(function(response) {
                    let dailyData = response.data;
                    if (canCallCallback) {
                        if (dailyData.list && dailyData.list.length >= FORECAST_DAYS) {
                            callback(undefined, hourlyData, dailyData);
                        } else {
                            callback('Incorrect daily weather data', hourlyData);
                        }
                    }
                }, function(data, status /* , headers, config */) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    let error = 'Error loading ' + dailyUrl + '. status=' + status + ' data="' + data + '"';
                    if (canCallCallback) {
                        callback(error);
                    }
                });
            }, function(data, status /*, headers, config */) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                let error = 'Error loading ' + hourlyWeatherUrl + '. status=' + status + ' data="' + data + '"';
                if (canCallCallback) {
                    callback(error);
                }
            });
        }

        /**
         * Process data for the hourly and daily weather tables
         *
         * @param {object} hourlyForecast
         * @param {object} dailyForecast
         */
        function processWeatherData(hourlyForecast, dailyForecast) {
            let error;

            // Location variables
            let city = hourlyForecast && hourlyForecast.city ? hourlyForecast.city.name : '-';
            let country = hourlyForecast && hourlyForecast.city ? hourlyForecast.city.country : '-';

            vm.location = `${city}, ${$translate.instant(country)}`;

            error = setDailyForecast(dailyForecast);
            if (error) {
                console.warn('Error setting daily forecast');
            }

            error = setHourlyForecast(dailyForecast);
            if (error) {
                console.warn('Error setting hourly forecast');
            }

            // The offset that should be added to the position of the first displayed element
            // for each day of the hourly forecast, so that the elements are centered.
            vm.offset = [];
            for (let i = 0; i < FORECAST_DAYS; i++) {
                vm.offset.push();

                if (vm.hourlyForecast.length >= FORECAST_DAYS) {
                    vm.offset[i] = Math.floor((12 - vm.hourlyForecast[i].length * vm.columnSize) / 2);
                } else {
                    error = true;
                }
            }

            if (error && !dailyForecast && cachedHourlyForecast && cachedDailyForecast) {
                processWeatherData(cachedHourlyForecast, cachedDailyForecast);
            } else if (!error) {
                cachedHourlyForecast = hourlyForecast;
                cachedDailyForecast = dailyForecast;
            }
        }

        /**
         * @param {Event} event
         * @param {Function} option
         */
        function onFinish(event, option) {
            if (typeof option.callback === 'function') {
                let contentData = angular.copy(vm.contentData);

                contentData = Object.assign(contentData, {
                    title: JSON.stringify(vm.contentData.title),
                    message: JSON.stringify(vm.contentData.message)
                });

                option.callback({
                    type: vm.state.type,
                    contentData
                });
            }
        }

        function onLanguageChange() {
            vm.model = vm.contentData.message[vm.contentData.language_id];
        }

        /**
         *
         */
        $scope.$on('$destroy', destroy);

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

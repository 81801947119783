(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParkSubAreasTab', {
            templateUrl: '/assets/views/share-park/car-parks/new/tabs/car-park-sub-areas/car-park-sub-areas-tab.tpl.html',
            controller: CarParkSubAreasTabController,
            bindings: {
                carParkData: '<',
            }
        });

    function CarParkSubAreasTabController(
        $scope,
        StatesFactory,
        PopupService,
        CarParkSubAreaPopupService,
        ModelsFactory,
    ) {
        const vm = this;

        vm.state = StatesFactory.CarParkStates;
        vm.state.canBack = true;

        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'area-title',
                    width: '20',
                    title: subArea => defaultTitle(subArea),
                },
                {
                    name: 'TYPE',
                    class: '',
                    width: '10',
                    title: subArea => subArea.type,
                },
                {
                    name: 'INDEX',
                    class: '',
                    width: '10',
                    title: subArea => subArea.index,
                },
                {
                    name: 'CAPACITY_PHYSICAL',
                    class: '',
                    width: '10',
                    title: subArea => subArea.capacity_physical,
                },
                {
                    name: 'CAPACITY_VIRTUAL',
                    class: '',
                    width: '10',
                    title: subArea => subArea.capacity_virtual,
                },
                {
                    name: 'PUBLIC',
                    class: '',
                    width: '10',
                    title: subArea => subArea.public ? 'YES' : 'NO',
                    translate: true,
                },
                {
                    name: 'PRIORITY',
                    class: '',
                    width: '10',
                    title: subArea => angular.isNumber(subArea.priority) ? subArea.priority : '',
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: onDelete,
                    },
                    {
                        class: 'editBtn',
                        callback: onEdit,
                    },
                ]
            },
            updateCallback: () => {},
        };

        vm.$onInit = init;
        vm.onCreate = onCreate;
        vm.onMoved = onMoved;

        function init() {
            vm.subAreas = vm.carParkData.sub_areas.sort((a, b) => a.index - b.index);
            setWatchers();
        }

        function onCreate() {
            const subArea = new ModelsFactory.CarParkSubArea();
            const typesInUse = getUsedTypes();
            CarParkSubAreaPopupService.editForm({
                subArea,
                typesInUse,
                mapDefaultCenter: vm.carParkData.latlng
            }).then(subArea => {
                subArea.index = vm.subAreas.length;
                vm.subAreas.push(subArea);
            });
        }

        /**
         *
         * @param {Event} event
         * @param {CarParkSubArea} subArea
         */
        function onEdit(event, subArea) {
            event.preventDefault();
            event.stopPropagation();
            const typesInUse = getUsedTypes();
            CarParkSubAreaPopupService.editForm({
                subArea: angular.copy(subArea),
                typesInUse,
                mapDefaultCenter: vm.carParkData.latlng
            }).then(newSubArea => {
                const index = vm.subAreas.findIndex(subArea => subArea.index === newSubArea.index);
                vm.subAreas[index] = newSubArea;
            });
        }

        function getUsedTypes() {
            return vm.carParkData.sub_areas.map(item => item.type);
        }

        /**
         *
         * @param {Event} event
         * @param {CarParkSubArea} subArea
         */
        function onDelete(event, subArea) {
            event.preventDefault();
            event.stopPropagation();
            const index = vm.subAreas.findIndex(item => item.id === subArea.id);
            vm.subAreas.splice(index, 1);
            resetIndexes();
        }

        /**
         *
         * @param {number} index
         */
        function onMoved(index) {
            vm.subAreas.splice(index, 1);
            resetIndexes();
        }

        function resetIndexes() {
            let index = 0;
            vm.subAreas.forEach(item => item.index = index++);
        }

        /**
         *
         * @param {CarParkSubArea} subArea
         * @returns {string}
         */
        function defaultTitle(subArea) {
            const defaultLangId = Object.keys(subArea.label)[0];
            return subArea.label[defaultLangId];
        }

        function setWatchers() {
            $scope.$watch(
                () => vm.subAreas,
                () => vm.state.canNext = vm.subAreas.length,
                true,
            );
        }
    }
})();
(function() {
    'use strict';

    angular.module('pushNotification')
        .component('pushNotification', {
            templateUrl: '/assets/views/push-notification/push-notification.tpl.html',
            controller: PushNotification,
            bindings: {
                content: '<',
                isStopped: '<',
                preview: '<',
            }
        });

    function PushNotification(
        $scope,
        $element,
        $timeout,
    ) {
        const vm = this;
        const element = $element[0].children[0];
        const zoom = getZoom();

        const STOPPED_INTERVAL = -1;
        const MAX_FONT_SIZE = 70;

        vm.class = templateClass;
        vm.style = style;
        vm.flag = flag;
        vm.updateFontSize = updateFontSize;
        vm.$onInit = init;

        function init() {
            setValues();
            setWatchers();
            updateFontSize();
        }

        function setValues() {
            vm.interval = vm.isStopped ? STOPPED_INTERVAL : slideInterval();
        }

        /**
         * Get scale factor
         */
        function getZoom() {
            const size = element.clientHeight + element.clientWidth;
            const originSize = 1920 + 1080;
            return size / originSize;
        }

        /**
         * Get sliding interval
         *
         * @returns {number}
         */
        function slideInterval() {
            const SECOND = 1000;
            return vm.content.config.slidingTimeout * SECOND;
        }

        /**
         * Returns a style object for element
         *
         * @returns {object}
         */
        function style() {
            return {
                'zoom': zoom,
            };
        }

        function flag(flagCode) {
            return `https://www.flagsapi.com/${flagCode.toUpperCase()}/shiny/64.png`;
        }

        /**
         * Update font size (scaling to fit the screen)
         */
        function updateFontSize() {
            if (vm.preview) {
                return;
            }

            $timeout(() => {
                if (vm.isStopped) {
                    return;
                }

                const wrapper = element.querySelector('.push-notification__wrap');
                if (!wrapper) {
                    return;
                }
                const needsScaleDown = () => {
                    const elementHeight = getInnerHeight(element);
                    const wrapperHeight = parseInt(getComputedStyle(wrapper).height);
                    return wrapperHeight > elementHeight;
                };

                let currentFontSize = MAX_FONT_SIZE;
                do {
                    wrapper.style.fontSize = currentFontSize-- + 'px';
                } while (needsScaleDown());
            }, 50);
        }

        /**
         * Get inner height of an element
         *
         * @param element
         * @returns {number}
         */
        function getInnerHeight(element) {
            const style = getComputedStyle(element);
            return parseInt(style.height) - parseInt(style.paddingTop) - parseInt(style.paddingBottom);
        }

        /**
         * Returns template class
         *
         * @param id
         * @returns {*|string}
         */
        function templateClass(id) {
            const classes = [];
            vm.content.config.slidingLanguage && classes.push('--carousel');
            return classes.join(' ');
        }

        /**
         * Set watchers
         */
        function setWatchers() {
            $scope.$watch(
                () => vm.isStopped,
                isStopped => {
                    if (isStopped) {
                        vm.activeSlide = 0;
                        vm.interval = STOPPED_INTERVAL;
                    } else {
                        vm.interval = slideInterval();
                        updateFontSize();
                    }
                }
            );

            $scope.$watch(
                () => vm.activeSlide,
                () => updateFontSize(),
            );

            $scope.$watch(
                () => vm.content,
                () => {
                    setValues();
                    updateFontSize();
                },
                true
            );
        }
    }
})();
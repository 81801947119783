(function() {
    'use strict';

    angular.module('beacon.app')
        .service('ConciergePopupService', ConciergePopupService);

    function ConciergePopupService(
        PopupService
    ) {
        return {
            open,
        }

        function open(entrance, plateNumber, subAreas, langs, carParks, capExternalId) {
            return PopupService.show({
                animation: true,
                backdrop: true,
                windowClass: 'concierge-popup-window',
                template: '<concierge-popup ' +
                    'entrance="entrance" ' +
                    'plate-number="plateNumber" ' +
                    'sub-areas="subAreas" ' +
                    'langs="langs" ' +
                    'car-parks="carParks" ' +
                    'cap-external-id="capExternalId" ' +
                    'callback="onSubmit">' +
                    '</concierge-popup>',
                controller: ($scope, $uibModalInstance, data) => {
                    $scope.entrance = data.entrance;
                    $scope.plateNumber = data.plateNumber;
                    $scope.subAreas = data.subAreas;
                    $scope.langs = data.langs;
                    $scope.carParks = data.carParks;
                    $scope.capExternalId = data.capExternalId;
                    $scope.onSubmit = result => {
                        $uibModalInstance.close(result);
                    }
                },
                controllerAs: '$ctrl',
                resolve: {
                    data: {
                        entrance,
                        plateNumber,
                        subAreas,
                        langs,
                        carParks,
                        capExternalId,
                    },
                }
            });
        }
    }
})();
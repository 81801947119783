(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newIotDevice', {
            templateUrl: '/assets/views/share-park/iot/iot-devices/new/new-iot-device.tpl.html',
            controller: NewIotDeviceController
        });

    function NewIotDeviceController(
        $scope,
        $state,
        $stateParams,
        ModelsFactory,
        StatesFactory,
        ShareParkDataService,
        GoogleMapsUtilitiesService,
        UtilitiesService,
        UserUtilitiesService,
    ) {
        const vm = this;

        vm.$onInit = init;

        const { getDeviceTypes, createDevice, updateDevice } = ShareParkDataService;
        const { showMap } = GoogleMapsUtilitiesService;
        const { userLocationOptions } = UserUtilitiesService;
        const { getCoordinates, toPrecision } = UtilitiesService;

        vm.state = StatesFactory.IotDeviceStates.refresh();
        vm.state.type = $stateParams.type;

        vm.deviceTypes = [];
        vm.imagePickerConfig = {
            minWidth: 300,
            minHeight: 300,
            center: true,
        };

        vm.onDeviceTypeChange = onDeviceTypeChange;
        vm.onMap = onMap;
        vm.onDeviceImage = onDeviceImage;
        vm.onDeviceImageDelete = onDeviceImageDelete;

        /**
         * Initialization method
         */
        function init() {
            vm.isEditMode = vm.state.type === 'edit';
            vm.mapDefaults = userLocationOptions();

            vm.deviceData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.IotDevice();

            getDeviceTypes()
                .then(response => {
                    vm.deviceTypes = response.plain();
                    vm.deviceTypes.forEach(deviceType =>
                        deviceType.technicalProperties = angular.fromJson(deviceType.technicalProperties)
                    );
                });
        }

        function onDeviceTypeChange() {
            const selectedType = vm.deviceTypes.find(type => type.id === vm.deviceData.device_type);
            vm.deviceData.relays = selectedType.technicalProperties.relayCount ?
                selectedType.technicalProperties.relayCount :
                0;
        }

        /**
         * Map icon click hendler
         */
        function onMap() {
            const coords = getCoordinates(vm.deviceData.latlng);

            const mapPopup = showMap({
                mapInfo: !!coords ? [coords] : null,
                mapDefaults: vm.mapDefaults,
                canEdit: true,
                singleMarker: true,
            });

            mapPopup.then(mapInfo => {
                const coords = mapInfo.coordinates;
                const precision = 6;

                const lat = toPrecision(coords.latitude, precision);
                const lng = toPrecision(coords.longitude, precision);
                vm.deviceData.latlng = `${lat},${lng}`;
            });
        }

        function onDeviceImage(imageFile) {
            const urlCreator = window.URL || window.webkitURL;
            vm.deviceData.imageFile = imageFile;
            vm.deviceData.image_ref = urlCreator.createObjectURL(imageFile);
        }

        function onDeviceImageDelete() {
            delete vm.deviceData.imageFile;
            vm.deviceData.image_ref = '';
        }

        /**
         * Ob finish handler
         */
        function onFinish() {
            const formData = new FormData();

            formData.append('device', angular.toJson(vm.deviceData));
            formData.append('deviceImage', vm.deviceData.imageFile);

            if (!vm.isEditMode) {
                createDevice(formData)
                    .then(onSuccess);
            } else {
                updateDevice(vm.deviceData, formData)
                    .then(onSuccess)
            }
        }

        function onSuccess() {
            $state.go('app.iotDevices', {
                paginationData: $stateParams.paginationData,
                iotEntitiesMode: false,
            });
        }

        $scope.$watch(function() {
            return vm.newIotDeviceForm && vm.newIotDeviceForm.$valid;
        }, function(formValid) {
            vm.state.canFinish = !!formValid;
        }, true);

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish()
        );
    }
})();
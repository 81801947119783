(function() {
    'use strict';

    angular.module('beacon.app')
        .component('conciergePopup', {
            templateUrl: '/assets/views/share-park/concierge/concierge-popup/concierge-popup.tpl.html',
            controller: ConciergePopupController,
            bindings: {
                entrance: '<',
                plateNumber: '<',
                subAreas: '<',
                langs: '<',
                carParks: '<',
                capExternalId: '<',
                callback: '<',
            }
        });

    function ConciergePopupController(
        $scope,
        $translate,
        DateHelper,
        ShareParkDataService,
        ShareParkHelperService,
        StopParkingPopupService,
        PopupService,
    ) {
        const vm = this;

        const currentLocale = $translate.use();
        let currentLangId;

        const { processParkingStatusesResponse } = ShareParkHelperService;
        const { getDefaultDateFormat } = DateHelper;

        const DATE_FORMAT = getDefaultDateFormat();
        const TIME_FORMAT = 'HH:mm';
        const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
        vm.isValid = false;
        vm.eventTypes = {
            ENTRANCE: 1,
            EXIT: 2,
        };
        vm.cancelledPermissionStatus = 3;

        vm.durationUnits = [
            {
                minutesValue: 10,
                unit: 10,
                unitLabel: 'MINUTES',
            },
            {
                minutesValue: 30,
                unit: 30,
                unitLabel: 'MINUTES',
            },
            {
                minutesValue: 60,
                unit: 1,
                unitLabel: 'HOUR',
            },
            {
                minutesValue: 120,
                unit: 2,
                unitLabel: 'HOURS',
            },
            {
                minutesValue: 180,
                unit: 3,
                unitLabel: 'HOURS',
            },
            {
                minutesValue: 240,
                unit: 4,
                unitLabel: 'HOURS',
            },
            {
                minutesValue: 480,
                unit: 8,
                unitLabel: 'HOURS',
            },
            {
                minutesValue: 780,
                unit: 12,
                unitLabel: 'HOURS',
            },
        ];
        vm.searchModes = {
            BY_NUMBER_PLATE: {
                id: 1,
                label: 'BY_NUMBER_PLATE',
            },
            BY_EMAIL: {
                id: 2,
                label: 'BY_EMAIL',
            }
        };
        vm.searchModesArr = Object.values(vm.searchModes);
        vm.searchMode = vm.searchModes.BY_NUMBER_PLATE.id;
        vm.customersData = [];
        vm.parkingStatuses = [];
        vm.permissions = [];
        vm.permissionCode = null;

        vm.eventTypesList = generateEventTypesList(vm.eventTypes);
        vm.countryCode = 'D';
        vm.subArea = vm.subAreas[0] ? vm.subAreas[0].type : null;
        vm.duration = vm.durationUnits[0].minutesValue;
        vm.customer = null;
        vm.customerEmail = '';

        vm.onSubmit = onSubmit;
        vm.onCancel = onCancel;
        vm.onChangeCountryCode = onChangeCountryCode;
        vm.getTranslation = getTranslation;
        vm.onNumberPlateChange = onNumberPlateChange;
        vm.onNumberPlateType = onNumberPlateType;
        vm.onEmailChange = onEmailChange;
        vm.onEmailType = onEmailType;
        vm.stopParkingProcedure = stopParkingProcedure;
        vm.usePermission = usePermission;
        vm.getAllPlates = getAllPlates;
        vm.getCustomersByEmail = getCustomersByEmail;
        vm.$onInit = init;

        function init() {
            setDefaultEventType(vm.entrance.entrance_label);

            if (vm.langs) {
                const currentLang = vm.langs.find(lang => lang.code === currentLocale);
                currentLangId = currentLang ? currentLang.id : null;
            }

            if (typeof vm.plateNumber === 'string') {
                getAllPlates().then(items => {
                    if (items.length) {
                        const item = items.find(item => item.numberPlate === vm.plateNumber);
                        if (item) {
                            onNumberPlateChange(item);
                        }
                    }
                })
            }

        }

        /**
         * @param {string} entranceLabel
         */
        function setDefaultEventType(entranceLabel) {
            switch (entranceLabel) {
                case 'IN':
                    vm.eventType = vm.eventTypes.ENTRANCE;
                    break;
                case 'OUT':
                    vm.eventType = vm.eventTypes.EXIT;
                    break;
                default:
                    vm.eventType = vm.eventTypes.ENTRANCE;
            }
        }

        function onSubmit() {
            const openingData = {
                eventType: vm.eventType,
            }

            openingData.countryCode = vm.countryCode;
            openingData.plateNumber = vm.plateNumber;

            if (vm.eventType === vm.eventTypes.ENTRANCE) {
                openingData.subArea = vm.subArea;
                openingData.duration = vm.duration;
            }

            if (vm.permissionCode) {
                openingData.permissionCode = vm.permissionCode;
            }

            vm.callback(openingData);
        }

        function onCancel() {
            vm.callback(null);
        }

        /**
         * @param {object} eventTypes - eg. {ENTRANCE: 1, EXIT: 2}
         * @returns {Array<{id: number, name: string}>}
         */
        function generateEventTypesList(eventTypes) {
            const list = [];
            Object.keys(eventTypes).forEach(key => {
                list.push({
                    id: eventTypes[key],
                    name: key,
                })
            });
            return list;
        }

        /**
         * Transform to uppercase and leave only letters
         */
        function onChangeCountryCode() {
            vm.countryCode = vm.countryCode
                ? vm.countryCode
                    .toUpperCase()
                    .replace(/[^A-Z]/g, '')
                : '';
        }

        function getTranslation(field) {
            return field[currentLangId] || Object.values(field)[0] || '';
        }

        /**
         * @return {Promise<{customer: object, numberPlate: string}[]>}
         */
        function getAllPlates() {
            if (!vm.plateNumber) {
                return Promise.resolve([]);
            }

            return ShareParkDataService.findCustomers(vm.plateNumber)
                .then(response => {
                    return response.plain()
                        .map(customer => {
                            return customer.registeredVehicles
                                .map(registeredVehicle => {
                                    customer.vehicles = customer.registeredVehicles.map(vehicle => vehicle.capVehicleRegistration);
                                    return {
                                        customer,
                                        numberPlate: registeredVehicle.capVehicleRegistration.numberPlate,
                                    }
                                })
                        })
                        .flat()
                        .filter(item => item.numberPlate.includes(vm.plateNumber));
                });
        }

        /**
         * @return {Promise<{object}[]>} - customers
         */
        function getCustomersByEmail() {
            if (!vm.customerEmail) {
                return Promise.resolve([]);
            }

            return ShareParkDataService.findCustomers(vm.customerEmail).then(customers => {
                return customers.map(customer => {
                    customer.vehicles = customer.registeredVehicles.map(vehicle => vehicle.capVehicleRegistration);
                    return customer;
                });
            });
        }

        function loadCustomerAdditionalData(customer) {
            ShareParkDataService.getParkingStatuses(customer.guid)
                .then(response => {
                    vm.parkingStatuses = processParkingStatusesResponse(response.plain());
                    checkNotFinishedParkingProcedures();
                });

            ShareParkDataService.getUserReservationsByCap({
                guid: customer.guid,
                capExternalId: vm.capExternalId,
            }).then(response => {
                vm.permissions = response.plain().map(permission => {
                    permission.permissionFrom = moment(permission.permissionFrom).format(DATE_TIME_FORMAT);
                    permission.permissionTill = moment(permission.permissionTill).format(DATE_TIME_FORMAT);

                    return permission;
                });
            })
        }

        /**
         * @param {{customer: object, numberPlate: string}} data
         */
        function onNumberPlateChange(data) {
            vm.customerEmail = '';
            if (!data) {
                return;
            }
            const customer = data.customer;
            vm.customer = customer;
            vm.customerEmail = customer.emailAddress;
            vm.customer && loadCustomerAdditionalData(vm.customer);
        }

        /**
         * @param {string} numberPlate
         */
        function onNumberPlateType(numberPlate) {
            vm.plateNumber = numberPlate
                ? numberPlate
                    .toUpperCase()
                    .replace(/[^0-9^A-ZÄÖÜß]/g, '')
                : '';
            vm.customerEmail = '';
            unsetUserData();
        }

        /**
         * @param {string} value
         */
        function onEmailType(value) {
            vm.plateNumber = '';
            unsetUserData();
        }

        /**
         *
         * @param {object} customer
         */
        function onEmailChange(customer) {
            vm.plateNumber = '';

            if (!customer) {
                return;
            }
            vm.customer = customer;
            vm.customer && loadCustomerAdditionalData(vm.customer);
        }

        function unsetUserData() {
            vm.customer = null;
            vm.parkingStatuses = [];
            vm.permissions = [];
            vm.permissionCode = null;
            checkNotFinishedParkingProcedures();
        }

        function checkNotFinishedParkingProcedures() {
            vm.notFinishedParking = vm.parkingStatuses.find(parkingStatus => !parkingStatus.endedParking);

            if (vm.notFinishedParking) {
                const relatedCap = vm.carParks.find(carPark => carPark.external_id === vm.notFinishedParking.carParkRef);
                vm.notFinishedParking.capName = relatedCap && relatedCap.name;
            }
        }

        function stopParkingProcedure(parkingStatus) {
            const carPark = vm.carParks.find(carPark => carPark.external_id === vm.notFinishedParking.carParkRef);

            StopParkingPopupService.show(carPark)
                .then((response) => {
                    stopParkingApi(Object.assign(response, { carPark, parkingStatus }))
                });
        }

        function stopParkingApi ({ gatewayRef, entranceRef, exitTime, carPark, parkingStatus }) {
            ShareParkDataService.stopParkingProcedure({
                carparkRef: carPark.external_id,
                gatewayRef,
                entranceRef,
                exitTime,
                permissionRef: parkingStatus.permissionRef
            }).then(() => {
                vm.parkingStatuses = vm.parkingStatuses.filter(({id}) => id !== parkingStatus.id);
                checkNotFinishedParkingProcedures();
            })
                .catch(err => {
                    console.error(err);
                    PopupService.showAlertPopup({
                        text: 'FAILED_TO_STOP_PARKING_PROCEDURE',
                        okButtonText: 'OK'
                    })
                })
        }

        function usePermission(permission) {
            vm.permissionCode = permission.permissionCode;
        }

        function isValidOpeningData() {
            const notFinishedParking = (vm.eventType === vm.eventTypes.ENTRANCE) && vm.notFinishedParking;
            const validPlateNumber = vm.plateNumber || !vm.plateNumber && vm.searchMode === vm.searchModes.BY_EMAIL.id
            return vm.eventType && validPlateNumber && vm.countryCode && vm.duration && !notFinishedParking;
        }

        $scope.$watch(
            () => isValidOpeningData(),
            isValid => {
                vm.isValid = isValid;
            }
        );
    }
})();
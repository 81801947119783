(function() {
    'use strict';

    angular.module('beacon.app')
        .component('geofenceTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/geofence-tab/geofence-tab.tpl.html',
            controller: GeofenceTab,
            bindings: {
                subtab: '<',
                searchName: '<',
                onSelect: '=',
                onSubTabChange: '<',
            }
        });

    function GeofenceTab(
        $translate,
        locationTabState,
    ) {
        const vm = this;

        const subtabs = locationTabState.views.LOCATIONS.tabs.GEOFENCES.subtabs;

        vm.$onInit = init;

        vm.GEOFENCE_TAB = subtabs.GEOFENCE_ITEMS.index;
        vm.GEOFENCE_GROUPS_TAB = subtabs.GEOFENCE_GROUPS.index;

        vm.onTabChange = onTabChange;

        function init() {
            vm.currentTab = angular.isNumber(vm.subtab) ? vm.subtab : vm.GEOFENCE_TAB;
        }

        function onTabChange(newTab) {
            vm.currentTab = newTab;
            vm.onSubTabChange(vm.currentTab);
        }
    }
})();

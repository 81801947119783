(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentItemRelations', {
            templateUrl: '/assets/views/content/elements/content-item-relations/content-item-relations.tpl.html',
            controller: ContentItemRelationsController,
            bindings: {
                content: '<',
            }
        });

    function ContentItemRelationsController(
        $q,
        $state,
        ContentRelationsService,
        LanguageService,
        ContentHelper,
        CAMPAIGN_TYPES,
        CampaignDataService,
    ) {
        const vm = this;

        vm.languages = [];
        vm.campaigns = [];
        vm.digitalDisplays = [];

        vm.$onInit = init;
        vm.onDigitalDisplayClick = onDigitalDisplayClick;
        vm.onCampaignClick = onCampaignClick;

        function init() {
            $q.all([
                LanguageService.getLanguages(),
                ContentRelationsService.getRelatedCampaigns(vm.content),
                ContentRelationsService.getRelatedDigitalDisplays(vm.content),
            ])
            .then(([languages, campaigns, displays]) => {
                vm.languages = languages.plain();
                vm.campaigns = campaigns;
                vm.digitalDisplays = displays;
            });
        }

        function onDigitalDisplayClick(content) {
            $state.go('app.editContent', {
                data: ContentHelper.processContentItem(vm.languages, content),
            });
        }

        function onCampaignClick(campaign) {
            CampaignDataService.campaign(campaign.id).then(campaign => {
                let targetState;
                switch (campaign.campaign_type) {
                    case CAMPAIGN_TYPES.MOBILE:
                        targetState = 'app.editCampaign';
                        break;
                    case CAMPAIGN_TYPES.PUSH:
                        targetState = 'app.editPushCampaign';
                        break;
                    case CAMPAIGN_TYPES.SCREEN:
                        targetState = 'app.editScreenCampaign';
                }
                campaign.additional_data = campaign.additional_data
                    ? angular.fromJson(campaign.additional_data)
                    : {};
                $state.go(targetState, { data: campaign });
            });

        }
    }

}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('resetPassword', {
            templateUrl: '/assets/views/login/reset-password/reset-password.tpl.html',
            controller: resetPasswordController
        });

    function resetPasswordController(
        $state,
        LoginService,
        ERROR_CODES
    ) {
        const vm = this;

        vm.sendEmail = sendEmail;
        vm.resetEmailValidity = resetEmailValidity;

        vm.email = '';

        function sendEmail() {
            LoginService.resetPassword(vm.email.toLowerCase())
                .then(() => {
                    $state.go('login', { message: 'PASSWORD_RESET_CONFIRMATION_SENT' });
                })
                .catch(err => {

                    if (err.data.errorCode === ERROR_CODES.ERR_INVALID_EMAIL) {
                        vm.resetPasswordForm.email.$setValidity('serverValidation', false);
                    }
                });
        }

        /**
         * Resets email validation state
         */
        function resetEmailValidity() {
            vm.resetPasswordForm.email.$setValidity('serverValidation', true);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('SpeedDailBuilderController', SpeedDailBuilderController);

    function SpeedDailBuilderController($uibModalInstance, data, PAGE_TYPES) {
        const vm = this;

        // public properties
        vm.data = angular.copy(data);

        // public methods
        vm.ok = okHandler;
        vm.cancel = cancel;

        init();

        // private methods

        /**
         * Initialization method
         */
        function init() {
            // Remove Ticker pages
            vm.data.pages = vm.data.pages
                .filter((item) => item.page_type_id !== PAGE_TYPES.TICKER)
                .map((item) => {
                    item.selected = vm.data.speedDial.selectedPages.indexOf(item.id) !== -1;

                    return item;
                });
        }

        /**
         * OK button handler
         */
        function okHandler() {
            vm.data.speedDial.selectedPages = vm.data.pages
                .filter((item) => item.selected)
                .map((item) => item.id);

            $uibModalInstance.close({
                speedDial: vm.data.speedDial
            });
        }

        /**
         * CANCEL button handler
         */
        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenItemsTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/screens-tab/screen-items-tab/screen-items-tab.tpl.html',
            controller: ScreenItemsTab,
            bindings: {
                screens: '<',
                markers: '<',
                onSelect: '=',
                searchName: '<',
            }
        });

    function ScreenItemsTab(
        $scope,
        $state,
        $translate,
        ListPaginationService,
        ScreenHelper,
    ) {
        const vm = this;

        vm.filteredScreens = vm.screens || [];
        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'locationTitle',
                    headerColumnClass: 'header-location-title',
                    width: '60',
                    title: item => item.name
                },
                {
                    name: 'STATUS',
                    class: 'locationStatus',
                    width: '20',
                    translate: true,
                    title: item => item.details
                        ? ScreenHelper.getStatusName(item.details.status)
                        : ''
                },
                {
                    name: 'CAMPAIGNS',
                    width: '10',
                    title: item => item.campaignsCount || 0
                }
            ],
            itemClickCallback: selectScreen,
            updateCallback: updateScreens,
            needRadiobutton: true,
        };
        vm.updateCounter = 0;

        vm.createScreen = createScreen;
        vm.editScreen = editScreen;

        /**
         * Handle screen item click
         *
         * @param {object} screen
         */
        function selectScreen(screen) {
            vm.onSelect(screen.name, [screen.locationId]);
            vm.selectedScreen = screen;
        }

        /**
         * Updates screens list
         * @param page
         * @param itemsPerPage
         */
        function updateScreens(page, itemsPerPage) {
            vm.visibleScreens = ListPaginationService.updateVisibleItems(page, itemsPerPage, vm.filteredScreens).visibleItems;
        }

        /**
         * Creates screen (with controller and station)
         *
         */
        function createScreen() {
            $state.go('app.addScreen', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }

        /**
         * Modify screen
         *
         */
        function editScreen(screenData) {
            $state.go('app.editScreen', {
                data: angular.copy(screenData.details),
                paginationData: {
                    page: Number(angular.element('.pagination-page.active a')[0].innerHTML),
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', ''))
                }
            });
        }


        /**
         *
         * @param name
         * @return {array}
         */
        function filterScreensByName(name) {
            return vm.screens.filter(
                beacon => beacon.name.toUpperCase().includes(name.toUpperCase())
                    || beacon.id === Number(name)
                    || beacon.details && beacon.details.id === Number(name)
            );
        }

        $scope.$watch(
            () => vm.searchName,
            searchName => {
                vm.filteredScreens = vm.searchName
                    ? filterScreensByName(searchName)
                    : vm.screens;
                vm.updateCounter++;
            }
        );

        $scope.$watch(
            () => vm.screens,
            () => {
                if (vm.screens) {
                    vm.filteredScreens = vm.searchName ? filterScreensByName(vm.searchName) : vm.screens;
                    vm.updateCounter++;
                }
            },
            true
        );
    }
})();

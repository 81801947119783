(function() {
    'use strict';

    angular.module('beacon.app')
        .component('campaignLocationListPreview', {
            controller: CampaignLocationListPreviewController,
            templateUrl: '/assets/views/common/components/campaign-location-list-preview/campaign-location-list-preview.tpl.html',
            bindings: {
                locations: '<'
            },
        });
    
    function CampaignLocationListPreviewController(
        $state,
        $translate,
        PermissionsService,
        PopupService,
        LocationDataService,
        ScreenHelper,
    ) {
        const vm = this;

        vm.editScreen = editScreen;
        vm.screenUrl = ScreenHelper.screenUrl;

        function editScreen(screen) {
            const { isPermissionAvailable } = PermissionsService;

            if (!isPermissionAvailable('can_modify_location_screen')) {
                return Promise.all([
                    $translate('YOU_DONT_HAVE_PERMISSION_TO_EDIT'),
                    $translate('SCREENS'),
                    $translate('OK'),
                ]).then(([message, screensTranslation, okButtonText]) => {
                    PopupService.showAlertPopup({
                        text: `${message} ${screensTranslation}`,
                        okButtonText
                    });
                });
            }

            LocationDataService.getControllerDetails()
                .then(controllersData => {
                    const currentScreenController = controllersData.list.find(controller => {
                        return screen.controllerRef === controller.id;
                    });

                    if (!currentScreenController) {
                        return PopupService.showAlertPopup({
                            text: `CONTROLLER_NOT_FOUND`,
                            okButtonText: 'OK'
                        });
                    }

                    $state.go('app.editScreen', {
                        data: angular.copy(currentScreenController),
                    });
                });
        }
    }
})();

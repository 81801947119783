(function() {
    'use strict';

    angular.module('beacon.app')
        .service('SegmentDataService', SegmentDataService);

    function SegmentDataService(
        Restangular,
    ) {
        return {
            getSegments,
            createSegment,
            segmentTypes,
            updateSegment,
            getPreferencesSegments,
            getSegmentationGroups,
            updateFilterCategoryStatus,
            getPredefinedSegments,
            getInternalSegments,
            randomizeSegmentByCategoryRef,
        };


        function getPreferencesSegments() {
            return Restangular.all('preferences-segment').getList();
        }

        // Public methods

        /**
         * Returns segments list
         */
        function getSegments(data) {
            return Restangular.all('segment').getList(data);
        }

        /**
         * Returns segments list
         */
        function getInternalSegments(data) {
            return Restangular.all('segment/internal').getList(data);
        }

        /**
         * Returns segmentation groups
         *
         * @return {array}
         */
        function getSegmentationGroups() {
            return Restangular.all('segment/segmentationGroup').getList();
        }

        /**
         * Creates new segment
         *
         * @param {object} data
         */
        function createSegment(data) {
            return Restangular.all('segment').post(data);
        }

        function updateSegment(segment, data) {
            return segment.customPOST(data, '', {}, {'Content-Type': undefined, '_method': 'PUT'});
        }
        /**
         * Returns segment types list
         */
        function segmentTypes(data) {
            return Restangular.all('segment/types').getList(data);
        }

        function getPredefinedSegments(data) {
            return Restangular.all('predefined-segment').getList(data);
        }

        /**
         *
         * @param {object} data
         */
        function updateFilterCategoryStatus(id, newStatus) {
            return Restangular.one('filter/category').get({filterCategoryId: id, newStatus: newStatus});
        }

        /**
         * Gets segment by category ref
         */
        function randomizeSegmentByCategoryRef(categoryRef) {
            return Restangular.one('randomizeSegment').get({categoryRef});
        }
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('SegmentAddController', SegmentAddController);

    function SegmentAddController(
        $scope,
        $rootScope,
        $stateParams,
        $timeout,
        $state,
        Restangular,
        SegmentDataService,
        StorageFactory,
        ModelsFactory,
        segmentAddState,
    ) {
        const vm = this;

        // public properties
        vm.state = segmentAddState;

        init();

        // private methods

        function init() {
            vm.state.type = $stateParams.type;
            vm.STORAGE = new StorageFactory.Storage('Segment', true);

            switch (vm.state.type) {
                case 'new':
                    // NOTE: when content copy
                    if ($stateParams.data) {
                        refreshSegmentData($stateParams.data);

                        vm.state.view++;
                        vm.state.canBack = true;
                    } else {
                        refreshSegmentData();
                    }
                    break;

                case 'edit':
                    refreshSegmentData($stateParams.data);

                    vm.state.view++;
                    vm.state.canBack = true;
                    break;
            }
        }

        function refreshSegmentData(data = {}) {
            // TODO: Test it! Maybe we can remove this!!!
            let segmentData = new ModelsFactory.BaseSegmentModel(data);

            if (!segmentData.language_id) {
                let langArray = vm.STORAGE.get('langArray');

                if (langArray && langArray[0]) {
                    segmentData.language_id = langArray[0].id;
                }
            }

            vm.segmentData = vm.STORAGE.set('segmentData', segmentData);
        }

        /**
         * View change callback
         */
        function onStateChange() {
            switch (Number(vm.state.view)) {
                case vm.state.views.SEGMENT_TYPE[0]:
                    refreshSegmentData({
                        segment_type_id: vm.segmentData.segment_type_id
                    });
                    break;
                case vm.state.views.SEGMENT_DETAILS[0]:
                    Object.assign(vm.state, {
                        canFinish: true
                    });
                    break;
            }
        }

        function segmentResponseHandler(response) {
            $state.go('app.segment', {
                paginationData: $stateParams.paginationData
            });
        }

        /**
         * Save/finish action callback
         */
        function onFinish() {

            $timeout(function() {
                $rootScope.$broadcast('segment-finish', {
                    callback: (obj) => {
                        let promise;

                        for (let prop in obj.segmentData) {
                            let value = obj.segmentData[prop];

                            if (prop === 'title' && typeof value === 'object') {
                                obj.segmentData[prop] = angular.toJson(value);
                            } else if (prop === 'message' && typeof value === 'object') {
                                obj.segmentData[prop] = angular.toJson(value);
                            }
                        }

                        if (obj.type === 'new') {
                            promise = SegmentDataService.createSegment(obj.segmentData);
                        } else {
                            obj.segmentData = Restangular.copy(obj.segmentData);
                            promise = obj.segmentData.put();
                        }
                        promise.then(segmentResponseHandler).catch(console.error.bind(console));
                    }
                });
            }, 0);
        }

        // Watchers
        $scope.$watch(() => vm.state.view, onStateChange);
        $scope.$watch(() => vm.state.finish, (newValue) => {
            if (newValue) {
                onFinish();
            }
        });
    }
}());

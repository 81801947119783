(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LoyaltyCampaignService', LoyaltyCampaignService);

    function LoyaltyCampaignService(Restangular) {

        /**
         * Getting the campaign list
         *
         * @returns {array}
         */
        function getList() {
            return Restangular.all('loyalty/campaign').getList();
        }

        /**
         * Getting loyalty campaign by id
         * @param {Number} id
         * @return {*}
         */
        function campaign(id) {
            return Restangular.one('loyalty/campaign', id).get();
        }

        /**
         * Creating campaign
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function create(data) {
            return Restangular.all('loyalty/campaign')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updating campaign
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function save(data) {
            data.append('_method', 'PUT');
            return Restangular.all('loyalty/campaign')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Delete campaign (changing status to 3)
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function remove(data) {
            data.append('_method', 'DELETE');
            return Restangular.all('loyalty/campaign')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Getting single campaign by external id
         *
         * @returns {object}
         */
        function getSingleExternal(externalId) {
            return Restangular.one('loyalty/campaign/get_external/' + externalId).get();
        }

        return {
            getList,
            save,
            create,
            remove,
            getSingleExternal,
            campaign,
        }
    }
})();
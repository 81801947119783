(function() {
    'use strict';

    angular.module('beacon.app')
        .component('carParksStatistics', {
            templateUrl: '/assets/views/share-park/car-parks/statistics/cap-statistics.tpl.html',
            controller: CarParksDashboardController
        });

    function CarParksDashboardController(
        StatesFactory,
        CAP_STATISTICS_MODES,
    ) {
        const vm = this;

        vm.state = StatesFactory.CapStatisticsStates.refresh();
        vm.selectedCarParks = [];

        vm.onCarParkSelect = onCarParkSelect;
        vm.updateSelectedCarParks = updateSelectedCarParks;

        function onCarParkSelect() {
            vm.state.canNext = !!vm.selectedCarParks.length;
        }

        function updateSelectedCarParks(selectedCarParks) {
            vm.selectedCarParks = selectedCarParks;
            onCarParkSelect();
        }
    }
})();

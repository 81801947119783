(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('list', list);

    function list() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/list/list.tpl.html',
            replace: true,
            controller: ListController,
            controllerAs: 'list',
            bindToController: true,
            scope: {
                data: '=list',
                items: '=',
                counter: '=',
                updateCounter: '=',
                draggable: '=draggable',
                scrollable: '=scrollable',
                noPagination: '=',
            }
        };
    }

    function ListController($scope) {
        const vm = this;

        // public properties
        vm.itemsPerPageOptions = [10, 25, 50, 100];
        vm.itemsPerPage = vm.itemsPerPageOptions[1];
        vm.currentPage = 1;
        vm.needRadiobutton = vm.data && vm.data.needRadiobutton;
        vm.selectedItem = false;
        vm.NO_REF = -1;

        // public methods
        vm.changeItemsCountHandler = changeItemsCountHandler;
        vm.itemClickHandler = itemClickHandler;
        vm.isSelected = isSelected;

        vm.saveOrder = saveOrder;
        vm.updateOrder = updateOrder;
        vm.oldOrder = angular.copy(vm.items);

        vm.getValue = getValue;

        init();

        /**
         * Handler on items count change
         */
        function changeItemsCountHandler() {
            if (vm.counter < (vm.currentPage - 1) * vm.itemsPerPage + 1) {
                vm.currentPage = Math.ceil(vm.counter / vm.itemsPerPage) || 1;
            }
            vm.data.updateCallback(vm.currentPage, vm.itemsPerPage);
        }

        /**
         * Handles click on item
         */
        function itemClickHandler(item) {
            if (vm.data.needRadiobutton) {
                vm.selectedItem = vm.selectedItem !== item ? item : false;
            }
            if (typeof vm.data.itemClickCallback === 'function') {
                vm.data.itemClickCallback(item);
            }
        }

        // *
        //  * Checks if item is selected
        //  *
        //  * @param item
        //  * @returns {boolean}

        function isSelected(item) {
            return angular.equals(vm.selectedItem, item);
        }

        // private methods

        /**
         * Items count chage callback
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        function onItemsCountChange(newValue, oldValue) {
            if (_.isNumber(oldValue) && newValue !== oldValue) {
                vm.changeItemsCountHandler();
            }
        }

        /**
         * Refreshes list data
         *
         * @param {*} newValue
         * @param {*} newValue
         */
        function refresh(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                vm.data.updateCallback(vm.currentPage, vm.itemsPerPage);
            }
        }

        /**
         * Initialization method
         */
        function init() {
            vm.data.updateCallback(vm.currentPage, vm.itemsPerPage);

            $scope.$watch(angular.bind(vm, function(counter) {
                return this.counter;
            }), onItemsCountChange);

            $scope.$watch(angular.bind(vm, function(updateCounter) {
                return this.updateCounter;
            }), refresh);
        }

        function saveOrder() {
            console.info('drag started!');
            vm.oldOrder = angular.copy(vm.items);
        }

        function updateOrder() {
            console.info('drag ended!');
            var elementsToUpdate = [];
            // find elements to update
            vm.items.forEach(function(item, index) {
                if (item.id !== vm.oldOrder[index].id) {
                    elementsToUpdate.push({id: item.id, order: index});
                }
            });
            console.info('elementsToUpdate', elementsToUpdate);
            if (vm.data.updateOrderCallback && elementsToUpdate.length) {
                vm.data.updateOrderCallback(elementsToUpdate);
            }
        }

        /**
         * Extracts value from item by key
         *
         * @param {object} item
         * @param {string} key
         * @returns {string}
         */
        function getValue(item, key) {
            if (!item || !_.isObject(item) || !key) {
                return "";
            }
            return String(_.isString(key) && item[key] ? item[key] :
                        _.isFunction(key) ? key(item) : "");
        }

    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('UploadVideoPopupController', UploadVideoPopupController);

    function UploadVideoPopupController(
        $scope,
        $uibModalInstance,
        ContentDataService,
        GoogleAPIUtilitiesService,
        data,
        $timeout,
    ) {
        const vm = this;

        // public methods
        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.uploadFiles = uploadFiles;
        vm.getVideoThumbnail = getVideoThumbnail;

        vm.ready = false;
        vm.data = data;

        if (vm.data.item) {
            vm.video = vm.data.item;
        }

        function okHandler() {
            if (vm.video) {
                vm.data.uploadFiles(vm.video, null, vm.data.index);
            }

            cancelHandler();
        }

        function cancelHandler() {
            $uibModalInstance.dismiss('cancel');
        }

        function uploadFiles(file) {
            let fileReader = new FileReader();
            vm.video = file;

            fileReader.onload = function(event) {
                vm.video.src = event.target.result;

                $scope.$apply();
            };

            fileReader.readAsDataURL(file);
        }

        function getVideoThumbnail(video, showCropper) {
            const TIMEOUT = showCropper ? null : 1000;

            $timeout(() => {
                if (video) {
                    // Init (save video tag)
                    vm.videoTag = video;
                } else {
                    // Or use saved
                    video = vm.videoTag;
                }

                if (video && (!vm.video.img || showCropper)) {
                    var canvas = document.createElement('canvas');
                    var width = canvas.width = video.videoWidth;
                    var height = canvas.height = video.videoHeight;

                    var ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, width, height);

                    vm.video.img = canvas.toDataURL('image/jpeg');
                    if (!vm.video.cropped) {
                        vm.video.cropped = { img: vm.video.img };
                    }
                }

                if (!showCropper) {
                    return;
                }

                vm.data.videoCrop(vm.video, function(result) {
                    vm.video.cropped = result.cropped;
                });
            }, TIMEOUT);
        }

        function getYouTubeThumbnail(id, callback, i = 0) {
            if (i === 3) {
                return callback(new Error('Thumbnail not found!'));
            }

            let img = new Image();
            img.onload = function(evt) {
                if (this.width === 120) {
                    return getYouTubeThumbnail(id, callback, i + 1);
                } else {
                    // Be careful: getBase64 has a hardcoded mimetype "image/jpeg"
                    ContentDataService.getBase64(img.src).then(function(response) {
                        return callback(null, response.base64);
                    });
                }
            };

            let src = 'https://img.youtube.com/vi/' + id + '/' + ['maxresdefault', 'sddefault', '0'][i] + '.jpg';

            img.src = src;
        }

        $scope.$watch(function() {
            return vm.youtubesrc;
        }, function(newValue) {
            if (!newValue) {
                return;
            }

            vm.video = undefined;

            try {
                let id = vm.youtubesrc.trim()
                    .match(/(?:(?:youtu\.be\/|youtube\.com\/.+?v=)(.+?)(?=$|&))/)[1];

                let videoSrc = 'https://www.youtube.com/embed/' + id + '?' + [
                    ['rel', 0],
                    ['showinfo', 0],
                    ['disablekb', 1],
                    ['iv_load_policy', 3]
                ].map(pair =>  pair.join('=')).join('&');

                vm.video = {
                    id: id,
                    random: Math.random().toString(32).slice(2),
                    type: 'video/youtube',
                    src: videoSrc,
                };

                getYouTubeThumbnail(id, (err, src) => {
                    if (err) {
                        return console.error(err);
                    }
                    if (src) {
                        vm.video.img = src;
                        vm.video.cropped = { img: src };
                    }
                });

            } catch (e) {
                console.error('ID not found:', vm.youtubesrc);
            }
        });
    }
}());

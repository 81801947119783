(function() {
    'use strict';

    angular.module('beacon.app')
        .component('bannerContentType', {
            templateUrl: '/assets/views/content/elements/types/banner/banner.tpl.html',
            controller: BannerContentType,
            bindings: {
                langArray: '<',
                mapDefaults: '<',
                contentForm: '=',
                contentData: '=',
                contentGroupsArray: '<',
            }
        });

    function BannerContentType(
            $scope,
            $stateParams,
            $q,
            StatesFactory,
            StorageFactory,
            ContentDataProcessingService,
            ContentDataService,
            LoyaltyCampaignService,
            ModelsFactory,
            CONTENT_TYPES,
            BANNER_REFERENCE_TYPE,
            INFOTAINMENT_TYPES,
            ) {
        const vm = this;

        vm.ready = false;
        vm.infos = [];
        vm.positionConfig = [];
        vm.clickActionsScreens = [];
        vm.clickActionsActions = [];
        vm.clickActionsExternalApp = [];
        vm.configs = [];
        const bannerInfotainmentTypes = [
            CONTENT_TYPES.INFOTAINMENT,
            CONTENT_TYPES.QUIZ,
            CONTENT_TYPES.FEEDBACK
        ];

        const {processDefaultContent} = ContentDataProcessingService;
        vm.state = StatesFactory.BannerStates.refresh();

        function init() {
            vm.state.type = StatesFactory.ContentStates.type;

            vm.state.haveNext = true;
            vm.STORAGE = StorageFactory.Storage('Content');

            $q.all([
                ContentDataService.contents().catch(console.error.bind(console)),
                LoyaltyCampaignService.getList().catch(console.error.bind(console))
            ])
            .then(data => _onContentLoaded(data));

            ContentDataService.getBannerConfigs().then(data => {
                vm.positionConfig = data
                    .filter(el => el.useCase === 'position');
                vm.clickActionsScreens = data
                    .filter(el => el.useCase === '3');
                vm.clickActionsActions = data
                    .filter(el => el.useCase === '2');
                vm.clickActionsExternalApp = data
                    .filter(el => el.useCase === '4');
            });
        }

        /**
         * Content loading handler
         *
         * @param {array} data
         * @private
         */
        function _onContentLoaded(data) {
            const INFOTAINMENTS_INDEX = 0;
            const LOYALTY_CAMPAIGNS_INDEX = 1;

            const infos = !_.isArray(data[INFOTAINMENTS_INDEX])
                ? []
                : data[INFOTAINMENTS_INDEX]
                    .filter(el => bannerInfotainmentTypes.indexOf(el.content_type_id) !== -1)
                    .map(el => {
                        el.message = JSON.parse(el.message);
                        el.title = JSON.parse(el.title);
                        el.referenceType = BANNER_REFERENCE_TYPE.INFOTAINMENT;
                        return el;
                    });

            const loyaltyCampaigns = !_.isArray(data[LOYALTY_CAMPAIGNS_INDEX])
                ? []
                : data[LOYALTY_CAMPAIGNS_INDEX].plain()
                    .map(el => {
                        el.message = el.description;
                        el.title = el.campaignLabel;
                        el.referenceType = BANNER_REFERENCE_TYPE.LOYALTY_CAMPAIGN;
                        el.reference_id = el.externalId;
                        return el;
                    });

            vm.infos = infos.concat(loyaltyCampaigns);
            vm.ready = true;
        }

        function setListeners() {
            $scope.$on('content-finish', onFinish);
        }

        function setWatchers() {
            $scope.$watch(angular.bind(vm, function() {
                return vm.state.finish;
            }), function(newValue) {
                if (newValue) {
                    StatesFactory.ContentStates.finish++;
                }
            });

            $scope.$watch(angular.bind(vm, function() {
                return vm.state.view;
            }), onStateChange);
        }

        /**
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            if (vm.contentData.data.standaloneBanner) {
                prepareRelatedContent()
                    .then(({ formData, content }) => {
                        if (content) {
                            formData.append('_method', 'PUT');

                            ContentDataService.update(content, formData)
                                .then(onRelatedInfotainmentSaveCallback)
                                .then(() => saveBanner(option));
                        } else {
                            ContentDataService.create(formData)
                                .then(onRelatedInfotainmentSaveCallback)
                                .then(() => saveBanner(option));
                        }
                    });
            } else {
                saveBanner(option);
            }
        }

        function saveBanner(option) {
            let formData = new FormData();
            let contentFormData = processDefaultContent(
                formData,
                vm.contentData,
            );

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    formData,
                    contentData: contentFormData
                });
            }
        }

        /**
         * Prepares related content data for request
         * @return {Promise}
         */
        function prepareRelatedContent() {
            if ($stateParams.type === 'edit') {
                return ContentDataService.content(vm.contentData.message.infoId)
                  .then(content => ({
                      content,
                      formData: generateRelatedContentFormData(content),
                  }));
            }

            return Promise.resolve({
                formData: generateRelatedContentFormData(
                  new ModelsFactory.BaseContentModel()
                )
            });
        }

        /**
         * Updates banner data when related infotainment updated
         * @param {Object} relatedInfotainment
         */
        function onRelatedInfotainmentSaveCallback(relatedInfotainment) {
            vm.contentData.message.referenceType = BANNER_REFERENCE_TYPE.INFOTAINMENT;
            vm.contentData.message.infoId = relatedInfotainment.id;
            vm.contentData.message.infoExternalId = relatedInfotainment.reference_id;
        }

        /**
         * Makes form data for standalone banner related content
         * @param {Object} relatedInfotainment
         * @return {FormData}
         */
        function generateRelatedContentFormData(relatedInfotainment) {
            const fillEmptyLanguages = (multiLangField, value) => {
                multiLangField = angular.copy(multiLangField);
                Object.keys(multiLangField).forEach(langId => {
                    if (!multiLangField[langId]) {
                        multiLangField[langId] = value;
                    }
                });
                return multiLangField;
            }

            if ($stateParams.type === 'new') {
                relatedInfotainment.content_type_id = CONTENT_TYPES.INFOTAINMENT;
                relatedInfotainment.language_id = vm.contentData.language_id;
                relatedInfotainment.data.infotainmentType = INFOTAINMENT_TYPES.DEFAULT.id;
            }

            relatedInfotainment.content_group_id = vm.contentData.content_group_id;
            relatedInfotainment.title = vm.contentData.title;
            relatedInfotainment.message = fillEmptyLanguages(vm.contentData.message.message, 'n/a');
            relatedInfotainment.hidden_content = 1;

            if (vm.contentData.data.standaloneBanner) {
                relatedInfotainment.show_in_list = 0;
            }

            let infotainmentFormData = new FormData();
            let infotainmentData = processDefaultContent(
                infotainmentFormData,
                relatedInfotainment,
            );
            infotainmentData.data = angular.isObject(infotainmentData.data)
                ? angular.toJson(infotainmentData.data)
                : infotainmentData.data;

            Object.keys(infotainmentData).forEach(prop => {
                if (angular.isDefined(infotainmentData[prop]) && infotainmentData[prop] !== null) {
                    infotainmentFormData.append(prop, infotainmentData[prop]);
                }
            });

            return infotainmentFormData;
        }



        function onStateChange() {
            // NOTE: canFinish -> add validation
            switch (Number(vm.state.view)) {
                case vm.state.views.ACTIONS[0]:
                    Object.assign(vm.state, {
                        canFinish: true
                    });
                    break;
            }
        }

        init();
        setListeners();
        setWatchers();
    }
})();

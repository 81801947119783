(function() {
    'use strict';

    angular.module('beacon.app')
        .component('timetableContentType', {
            templateUrl: '/assets/views/content/elements/types/timetable/timetable.tpl.html',
            controller: TimetableContentType,
            bindings: {
                langArray: '<?',
                contentForm: '=?',
                contentData: '=?',
                contentGroupsArray: '<?',
                previewmode: '=?',
                languageId: '<?'
            }
        });

    function TimetableContentType(
        $scope,
        $stateParams,
        StatesFactory,
        DateHelper,
        TIMETABLE_COLUMNS,
        TIMETABLE_TRANSPORTS,
        TIMETABLE_APPEARANCE_SIZE
    ) {
        const vm = this;
        const APPEARANCE_SIZE_BIG = 'big';
        const { incrementCurrentDate } = DateHelper;

        // public methods
        vm.initMap = initMap;
        vm.processColor = processColor;
        vm.getSelectedText = getSelectedText;
        vm.onAppearanceSizeChange = onAppearanceSizeChange;

        // public properties
        vm.state = StatesFactory.ContentStates;
        vm.showPreview = false;
        vm.GROUPS = {};
        vm.GROUPSLEN = 1;
        vm.COLUMNS = angular.copy(TIMETABLE_COLUMNS);
        vm.TRANSPORTS = angular.copy(TIMETABLE_TRANSPORTS);
        vm.APPEARANCE_SIZE = angular.copy(TIMETABLE_APPEARANCE_SIZE);
        vm.PREVIEWDATA = [
            {
                'destinationId': '3500128',
                'destinationLat': '0',
                'destinationLng': '0',
                'destinationName': 'Rashidiya Metro Station',
                'destinationType': 'STATION',
                'id': 0,
                'lineId': 'dub:11MRe:d:R:y08',
                'lineLabel': 'MRed',
                'lineNetwork': 'dub',
                'lineProductCode': 'SUBWAY',
                'lineProductIcon': 'https://content.flow-siemens.com/image/line/ic_line_icon_underground_dub.png',
                'lineStyleBackgroundColor': '-16764784',
                'lineStyleBorderColor': '0',
                'lineStyleForegroundColor': '-1',
                'lineStyleShape': 'RECT',
                'plannedTime': new Date(),
                'positionName': '2'
            },
            {
                'destinationId': '3500159',
                'destinationLat': '0',
                'destinationLng': '0',
                'destinationName': 'UAE Exchange Metro Station',
                'destinationType': 'STATION',
                'id': 0,
                'lineId': 'dub:11MRe:d:H:y08',
                'lineLabel': 'MRed',
                'lineNetwork': 'dub',
                'lineProductCode': 'SUBWAY',
                'lineProductIcon': 'https://content.flow-siemens.com/image/line/ic_line_icon_underground_dub.png',
                'lineStyleBackgroundColor': '-16764784',
                'lineStyleBorderColor': '0',
                'lineStyleForegroundColor': '-1',
                'lineStyleShape': 'RECT',
                'plannedTime': incrementCurrentDate(45),
                'positionName': '1'
            },
            {
                'destinationId': '3503000',
                'destinationLat': '0',
                'destinationLng': '0',
                'destinationName': 'Dubai Outsourcing, Royal Bank of Scotland',
                'destinationType': 'STATION',
                'id': 0,
                'lineId': 'dub:02X25: :H:y08',
                'lineLabel': 'X25',
                'lineNetwork': 'dub',
                'lineProductCode': 'BUS',
                'lineProductIcon': 'https://content.flow-siemens.com/image/line/ic_line_icon_bus.png',
                'lineStyleBackgroundColor': '-6736999',
                'lineStyleBorderColor': '0',
                'lineStyleForegroundColor': '-1',
                'lineStyleShape': 'ROUNDED',
                'plannedTime': incrementCurrentDate(150)
            },
            {
                'destinationId': '3509211',
                'destinationLat': '0',
                'destinationLng': '0',
                'destinationName': 'Rashidiya Metro Bus Parking',
                'destinationType': 'STATION',
                'id': 0,
                'lineId': 'dub:01044: :H:y08',
                'lineLabel': '44',
                'lineNetwork': 'dub',
                'lineProductCode': 'BUS',
                'lineProductIcon': 'https://content.flow-siemens.com/image/line/ic_line_icon_bus.png',
                'lineStyleBackgroundColor': '-6736999',
                'lineStyleBorderColor': '0',
                'lineStyleForegroundColor': '-1',
                'lineStyleShape': 'ROUNDED',
                'plannedTime': incrementCurrentDate(30),
                'positionName': '1'
            }
        ];

        init();

        // private methods

        /**
         * initialization method
         */
        function init() {
            updateComponentModel();

            if (vm.model.header.columns.length) {
                vm.COLUMNS.forEach(function(item, i) {
                    let index = vm.model.header.columns.indexOf(item.id);
                    if (index !== -1) {
                        vm.COLUMNS.splice(i, 1);
                        vm.COLUMNS.splice(index, 0, item);
                    }
                    item.enabled = index !== -1;
                });
            }

            if (!vm.model.groups.by) {
                vm.model.groups.by = null;
            }

            vm.model.filters.transTypes = vm.model.filters.transTypes
                .map((item) => {
                    return vm.TRANSPORTS.find((obj) => item.code === obj.code);
                });

            if ($stateParams.type === 'new') {
                vm.model.filters.transTypes = vm.TRANSPORTS;
            }

            if (vm.previewmode) {
                $scope.$watch(() => vm.contentData.id, updateComponentModel);
            }
        }

        /**
         *
         * @param event
         * @param option
         */
        function onFinish(event, option) {
            let contentFormData = angular.copy(vm.contentData);
            contentFormData = Object.assign(contentFormData, {
                title: JSON.stringify(vm.contentData.title),
                message: JSON.stringify(vm.contentData.message)
            });

            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    contentData: contentFormData
                });
            }
        }

        /**
         *
         * @return {*}
         */
        function getSelectedText() {
            if (vm.model.filters.transTypes.length) {
                if (vm.model.filters.transTypes.length === vm.TRANSPORTS.length) {
                    return 'All transport types are available!';
                } else {
                    return vm.model.filters.transTypes
                        .map(function(item) { return item.title; })
                        .join(', ');
                }
            }

            return 'Select transport types from list';
        }

        /**
         *
         * @param string
         * @return {string}
         */
        function processColor(string) {
            let num = parseInt(string);
            let hex = (num < 0 ? (0xffffffff + num + 1) : num).toString(16);
            return '#' + ('000000' + hex).slice(-6);
        }

        /**
         *
         * @param data
         * @return {*}
         */
        function groupData(data) {
            let GROUPS = {};
            let transTypes = vm.model.filters.transTypes;

            if (transTypes.length !== 0) {
                let transCodes = transTypes.map((item) => {
                    return item.code;
                });
                data = data.filter((item) => transCodes.indexOf(item.lineProductCode) !== -1);
            }

            if (!vm.model.groups.by) { // XXX: is (null || undefined)
                return { all: { items: data } };
            }

            let groupParam;
            switch (vm.model.groups.by) {
                case 'transport':
                    groupParam = 'lineProductCode'; break;
                case 'line':
                    groupParam = 'lineLabel'; break;
                default:
                    console.warn('INCORRECT groupBy type!!!');
                    return { all: { items: data } };
            }

            data.forEach((item) => {
                let __item = item[groupParam];
                if (__item in GROUPS) {
                    GROUPS[__item].items.push(item);
                } else {
                    GROUPS[__item] = {
                        items: [item],
                        title: __item, // __item[0].toUpperCase() + __item.slice(1).toLowerCase(),
                        icon: groupParam === 'lineProductCode' ? item.lineProductIcon : null,
                        background: groupParam === 'lineLabel' ? processColor(item.lineStyleBackgroundColor) : null,
                        color: groupParam === 'lineLabel' ? processColor(item.lineStyleForegroundColor) : null,
                        rounded: groupParam === 'lineLabel' && item.lineStyleShape === 'ROUNDED'
                    };
                }
            });

            return GROUPS;
        }

        /**
         * Initialize map
         */
        function initMap() {
            vm.gMapStation = {
                lat: 25.24948,
                lng: 55.34012
            };

            vm.gMap = new google.maps.Map(document.getElementById('gmap'), {
                center: vm.gMapStation,
                zoom: vm.model.map.zoom || 15,
                disableDefaultUI: true,
                styles: [{
                    'featureType': 'transit',
                    'elementType': 'geometry',
                    'stylers': [
                        { 'visibility': 'on' },
                        { 'weight': 3 }
                    ]
                }]
            });

            vm.gMap.addListener('idle', updateMap);

            vm.gMapMarker = new google.maps.Marker({
                position: vm.gMapStation,
                title: 'Location',
                icon: '/assets/images/you-are-here-1.png',
                map: vm.gMap
            });

            vm.gMapTransitLayer = new google.maps.TransitLayer();
            vm.gMapTransitLayer.setMap(vm.gMap);
        }

        /**
         * Update map
         */
        function updateMap() {
            if (!vm.gMap) {
                return;
            }

            // google.maps.event.trigger(vm.gMap, 'resize');
            vm.gMap.setZoom(vm.model.map.zoom);
            vm.gMap.panTo(new google.maps.LatLng(vm.gMapStation.lat, vm.gMapStation.lng));
        }

        /**
         * On language change handler
         */
        function onLanguageChange() {
            updateComponentModel();
        }

        /**
         * Updates component model according to selected language
         */
        function updateComponentModel() {
            if (vm.previewmode && vm.languageId) {
                vm.model = vm.contentData.message[vm.languageId];
            } else {
                vm.model = vm.contentData.message[vm.contentData.language_id];
            }
        }

        /**
         * On appearance size change handler
         */
        function onAppearanceSizeChange(size) {
            if (size === APPEARANCE_SIZE_BIG) {
                vm.model.contentMockup = true;
            }
        }

        /**
         * Watchers
         */
        $scope.$watch(angular.bind(vm, function() {
            return this.COLUMNS
                .filter(function(item) { return item.enabled; })
                .length;
        }), function(newValue) {
            vm.showPreview = newValue > 0;

            vm.model.header.columns = vm.COLUMNS
                .filter(function(item) { return item.enabled; })
                .map(function(item) { return item.id; });
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.model.filters.transTypes.length;
        }), function(newValue) {
            vm.GROUPS = groupData(vm.PREVIEWDATA);
            vm.GROUPSLEN = Object.keys(vm.GROUPS).length;
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.model.groups.by;
        }), function(newValue) {
            vm.GROUPS = groupData(vm.PREVIEWDATA);
            vm.GROUPSLEN = Object.keys(vm.GROUPS).length;
        });

        $scope.$watch(angular.bind(vm, function() {
            return this.model.map && this.model.map.zoom;
        }), function(newValue, oldValue) {
            if (!vm.model.map) {
                vm.model.map = { zoom: 15 };
                return updateMap();
            }

            if (newValue !== oldValue) {
                updateMap();
            }

        });

        $scope.$watch(angular.bind(vm, function() {
            return this.model.filters.timeTill;
        }), function(newValue) {
            let now = new Date();
            vm.PREVIEWDATA = vm.PREVIEWDATA.map((item) => {
                let pred = new Date(item.predictedTime).getTime();
                let plan = new Date(item.plannedTime).getTime();
                let show = new Date(pred || plan);

                if (vm.model.filters.timeTill) {
                    let diff = parseInt((show - now) / 60000);
                    let diffH = parseInt(diff / 60);
                    let diffM = parseInt(diff % 60);
                    if (diffM > 0 && diffH > 0) {
                        diffH -= 1;
                    }

                    let strH = diffH ? (diffH + 'h') : '';
                    let strM = diffM ? (diffM + 'm') : '';
                    item.showTime = diff === 0 ? 'now' : strH + ' ' + strM;
                    return item;
                }

                item.showTime = [
                    ('00' + show.getHours()).slice(-2),
                    ('00' + show.getMinutes()).slice(-2)
                ].join(':');
                return item;
            });

            vm.GROUPS = groupData(vm.PREVIEWDATA);
            vm.GROUPSLEN = Object.keys(vm.GROUPS).length;
        });

        /**
         * Listeners
         */
        $scope.$on('content-finish', onFinish);
        $scope.$on('language-change', onLanguageChange);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('loyaltyCampaignDetailsTab', {
            templateUrl: '/assets/views/loyalty/campaigns/form/tabs/details/details.tpl.html',
            controller: loyaltyCampaignDetailsTab
        });

    function loyaltyCampaignDetailsTab(
        $scope, 
        StatesFactory, 
        StorageFactory, 
        UtilitiesService,
        ImageProcessorService,
    ) {
        const vm = this;

        vm.NOTIFICATION_COLLECTION_TYPE = 0;
        vm.NOTIFICATION_REWARD_TYPE = 1;
        vm.NOTIFICATION_REDEEM_TYPE = 2;

        vm.state = StatesFactory.LoyaltyCampaignStates;
        vm.STORAGE = StorageFactory.Storage('LoyaltyCampaign');
        vm.notifications = [];

        vm.isValidField = isValidField;
        vm.changeNotification = changeNotification;
        vm.setNotificationTitle = setNotificationTitle;
        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;

        vm.loaderConfig = {
            fullInfo: true,
            center: true,
        };

        init();

        /**
         * Controller initialization
         */
        function init() {
            vm.STORAGE.on('data', value => vm.data = value);
            vm.STORAGE.on('Languages', value => vm.langArray = value);

            vm.data.collectionStart = vm.data.collectionStart ? moment(vm.data.collectionStart).format('YYYY-MM-DD HH:mm') : null;
            vm.data.collectionEnd   = vm.data.collectionEnd ? moment(vm.data.collectionEnd).format('YYYY-MM-DD HH:mm') : null;
            ImageProcessorService.initImage(vm.data.imageRef);
            vm.state.canBack = true;
        }

        /**
         * Set mandatory fields for notification title
         *
         * @param notificationType
         */
        function setNotificationTitle(notificationType) {
            const defaultValue = 'Sample title';
            const status = vm.data.loyaltyNotifications[notificationType].status;
            let notification = vm.data.loyaltyNotifications[notificationType];

            if (status) {
                notification.title = notification.title !== defaultValue ? notification.title : "";
            } else {
                notification.title = notification.title || defaultValue;
            }
        }

        /**
         * Image field change handler
         *
         * @param {{blob: File, cropData: Object}} imageFile
         * @param {boolean} uploadToS3
         */
        function onImage(imageFile, uploadToS3) {
            ImageProcessorService.setImage(imageFile.blob);
            const urlCreator = window.URL || window.webkitURL;
            vm.data.imageRef = uploadToS3
                ? urlCreator.createObjectURL(imageFile.blob)
                : imageFile.blob.name;
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            ImageProcessorService.setImage(null);
            vm.data.imageRef = '';
        }

        /**
         * Checking field validation
         *
         * @param {string} fieldName
         * @returns {*|boolean}
         */
        function isValidField(fieldName) {
            return UtilitiesService.isValidField(vm.dataForm, fieldName);
        }

        /**
         * Removing notification object on checkbox uncheck
         *
         * @param {number} index
         */
        function changeNotification(index) {
            if (!vm.notifications[index]) {
                vm.data.loyaltyNotifications[index] = null;
            }
        }

        /**
         * Form validation watcher
         */
        $scope.$watch(function() {
            return vm.dataForm.$valid;
        }, function(formValid) {
            vm.state.canNext = !!formValid;
        }, true);
    }
})();
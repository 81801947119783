(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('tenantEditTab', tenantEditTab);

    function tenantEditTab() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/tenant-edit-tab/tenant-edit-tab.tpl.html',
            replace: true,
            controller: TenantEditTabController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                tenant: '=tenantEditTab',
            }
        };
    }

    function TenantEditTabController(
        $scope,
        PopupService,
        DateHelper,
        SUPPORTED_DATE_FORMATS,
    ) {
        const vm = this;

        vm.onMap = onMap;

        vm.onImage = onImage;
        vm.onImageDelete = onImageDelete;

        const { generateTimeZones } = DateHelper;

        const DEFAULT_LAT = 50;
        const DEFAULT_LNG = 10;
        const DEFAULT_ZOOM = 4;
        const DEFAULT_MAX_ZOOM = 16;

        vm.map = {};
        vm.marker = [];

        vm.SUPPORTED_DATE_FORMATS = SUPPORTED_DATE_FORMATS;
        vm.loaderConfig = {
            minWidth: 200,
            minHeight: 80,
            center: true,
            aspectRatio: 2.5
        };

        init();

        function init() {
            updateLocation();
            vm.timeZones = generateTimeZones();
        }

        function updateLocation(){
            const location = vm.tenant.location;
            const coordinates = location && location.coordinates;
            vm.map = {
                center: {
                    latitude: coordinates && coordinates.latitude || DEFAULT_LAT,
                    longitude: coordinates && coordinates.longitude || DEFAULT_LNG
                },
                zoom: location && location.zoom || DEFAULT_ZOOM,
                options: {
                    maxZoom: DEFAULT_MAX_ZOOM
                }
            };

            vm.marker = coordinates ? [{id: 0, coordinates}] : [];
        }

        /**
         * Map icon click handler
         */
        function onMap() {
            const location = vm.tenant.location;
            const mapInfo = (location && location.coordinates) ? [location.coordinates] : [];

            const mapPopup = PopupService.show({
                templateUrl: '/assets/views/common/popups/map/map.tpl.html',
                controller: 'MapPopupController',
                controllerAs: 'mapPopupController',
                windowClass: 'mapPopup',
                keyboard: false,
                resolve: {
                    data: () => (
                        {
                            okButtonText: 'OK',
                            cancelButtonText: 'CANCEL',
                            mapInfo,
                            mapDefaults: {zoom: location && location.zoom},
                            canEdit: true,
                            singleMarker: true,
                        }
                    )
                }
            });

            mapPopup.then(location => {
                vm.tenant.location = location;
                updateLocation();
            });
        }

        /**
         * Image field change handler
         *
         * @param imageFile
         * @param uploadToS3
         */
        function onImage(imageFile, uploadToS3) {
            const urlCreator = window.URL || window.webkitURL;
            vm.tenant.image = uploadToS3 ? imageFile : null;
            vm.tenant.tenantLogoUrl = uploadToS3
                ? urlCreator.createObjectURL(imageFile)
                : imageFile.name;
        }

        /**
         * Image delete click handler
         */
        function onImageDelete() {
            vm.tenant.image = null;
            vm.tenant.tenantLogoUrl = '';
        }
    }
}());

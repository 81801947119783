(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('StopParkingPopupController', StopParkingPopupController);

    function StopParkingPopupController(
        $sce,
        $uibModalInstance,
        data,
        DATETIME_FORMAT,
    ) {
        const vm = this;

        vm.ok = okHandler;
        vm.cancel = cancelHandler;
        vm.isFormInvalid = isFormInvalid;
        vm.onGatewayChange = onGatewayChange;

        vm.carPark = data.carPark;
        vm.exitTime = moment();

        onGatewayChange(vm.carPark.car_park_gateways[0]);

        /**
         * "OK" click handler
         */
        function okHandler() {
            $uibModalInstance.close({
                gatewayRef: vm.selectedGateway.external_id,
                entranceRef: vm.selectedEntranceId,
                exitTime: vm.exitTime.utc().format(DATETIME_FORMAT)
            });
        }

        function isFormInvalid() {
            return vm.stopParkingForm
                && vm.stopParkingForm.$invalid
                && !vm.selectedGateway
                && !vm.selectedEntranceId;
        }

        function onGatewayChange(gateway) {
            vm.selectedGateway = gateway;
            vm.selectedEntranceId = vm.selectedGateway.entrances[0]
                ? vm.selectedGateway.entrances[0].external_id
                : null;
        }

        /**
         * "Cancel" click handler
         */
        function cancelHandler() {
            $uibModalInstance.dismiss();
        }
    }
}());

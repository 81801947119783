(function() {
    'use strict';

    angular.module('beacon.app')
        .service('OnboardingHelper', OnboardingHelper);

    function OnboardingHelper(
        $translate
    ) {
        let NO_CONTENT_TITLE = 'NO_TITLE';

        $translate('NO_TITLE').then(translation => {
            NO_CONTENT_TITLE = translation;
        })

        /**
         * Setting content type id for segments to make them look like content elements in views
         */
        const CONTENT_TYPE_SEGMENT = {
            id: -1,
            name: 'Preference Segment',
        };

        /**
         * Used in DB to distinguish contents and segments
         */
        const MODEL_TYPES = {
            CONTENT: 1,
            SEGMENT: 2,
        };

        return {
            getTitle,
            getAccordionTemplateName,
            prepareContentElement,
            prepareSegment,
            CONTENT_TYPE_SEGMENT,
            MODEL_TYPES,
        };

        /**
         * Prepare content element after getting from DB
         *
         * @param {object} element
         * @param {array} langArray
         * @return {object}
         */
        function prepareContentElement(element, langArray) {
            const item = angular.copy(element);

            item.title = JSON.parse(item.title);
            item.message = JSON.parse(item.message);

            if (item.map_info) {
                item.map_info = JSON.parse(item.map_info);
            }

            item.currentLanguage = Number(item.language_id);
            item.allLanguages = langArray;

            const languageIDs = Object.keys(item.title);
            item.languages = langArray.filter(language => languageIDs.indexOf(language.id.toString()) !== -1);

            item.content_group.title = JSON.parse(item.content_group.title);
            return item;
        }

        /**
         * Prepare preferences segment element after getting from DB
         *
         * @param {object} item
         * @param {array} langArray
         * @return {object}
         */
        function prepareSegment(item, langArray) {
            item.content_type = CONTENT_TYPE_SEGMENT;
            item.content_type_id = CONTENT_TYPE_SEGMENT.id;
            item.message = JSON.parse(item.message);

            const languageIDs = Object.keys(item.message);
            item.languages = langArray.filter(language => languageIDs.indexOf(language.id.toString()) !== -1);
            return item;
        }

        /**
         * Return the title of content element or segment
         *
         * @return {string}
         */
        function getTitle(item) {
            const title = item.content_type_id === CONTENT_TYPE_SEGMENT.id ? item.title : item.title[item.language_id];

            return title || NO_CONTENT_TITLE;
        }

        /**
         * Returns an accordion template name
         *
         * @return {string}
         */
        function getAccordionTemplateName(item, editable = false) {
            const segmentTemplate = editable ? 'segment-item-editable' : 'segment-item';
            return item.content_type_id === CONTENT_TYPE_SEGMENT.id ? segmentTemplate : 'content-item';
        }
    }
}());
(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('accordionListItem', accordionListItem);

    function accordionListItem($timeout) {
        return {
            restrict: 'A',
            scope: {
                item: '=',
                itemTemplate: '=',
                additional: '='
            },
            link: function(scope, element, attrs) {
                scope.templateUrl = '/assets/views/common/partials/' + scope.accordionListItem.itemTemplate + '.tpl.html';
            },
            template: '<div data-ng-include="templateUrl"></div>',
            replace: true,
            controller: AccordionListItemController,
            controllerAs: 'accordionListItem',
            bindToController: true
        };
    }

    function AccordionListItemController(UtilitiesService) {
        const vm = this;

        const { prearrangeText } = UtilitiesService;


        // public methods
        vm.getArray = getArray;
        vm.switchLanguage = switchLanguage;
        vm.getLanguageName = getLanguageName;
        vm.getItemTranslations = getItemTranslations;
        vm.getValue = getValue;
        vm.prearrangeText = prearrangeText;

        /**
         * Forms dummy array with given number of items
         *
         * @param {string} str
         * @returns {array}
         */
        function getArray(str) {
            return Array(parseInt(str));
        }

        /**
         * Switches item language (for lists where language change is supported)
         * @param {number} languageID
         */
        function switchLanguage(languageID) {
            var audioElement;

            vm.item.currentLanguage = languageID;

            if (vm.item.language_sensitive_audio) {
                audioElement = $('#audio_' + vm.item.id);
                audioElement.find('source').attr('src', vm.item.audio_src[vm.item.currentLanguage]);
                if (angular.isDefined(audioElement[0])) {
                    audioElement[0].load();
                }
            }
        }

        /**
         * Returns language name by id
         *
         * @param {number} languageID
         * @returns {string}
         */
        function getLanguageName(languageID) {
            var filtered = vm.item.languages.filter(function(language) {
                return language.id === languageID;
            });

            return filtered.length ? filtered[0].name : '';
        }

        /**
         * Returns array with translation info
         *
         * @param {object} translationsObject
         * @returns {array}
         */
        function getItemTranslations(translationsObject) {
            var languageIds = Object.keys(translationsObject),
                translations = [];

            languageIds.forEach(function(languageId) {
                var language = vm.item.allLanguages.filter(function(language) {
                    return language.id.toString() === languageId;
                })[0];

                if (language) {
                    translations.push({
                        id: language.id,
                        code: language.code,
                        name: language.name,
                        translation: translationsObject[language.id]
                    });
                }
            });

            return translations;
        }

        /**
         * Extracts value from item by key
         *
         * @param {object} item
         * @param {string} key
         * @returns {string}
         */
        function getValue(item, key) {
            if (!item || !_.isObject(item) || !key) {
                return "";
            }
            return String(_.isString(key) && item[key] ? item[key] :
                        _.isFunction(key) ? key(item) : "");
        }
    }
}());

(function() {
    'use strict';

    angular.module('beacon.app')
        .service('StatisticsService', StatisticsService);

    function StatisticsService(Restangular) {
        return {
            getUsageStats,
        };

        /**
         * @param {Array} contentIds
         * @return {Array}
         */
        function getUsageStats(contentIds) {
            const contentIdsString = contentIds.join(',');
            return Restangular.all('statistics/get_usage_stats/' + contentIdsString).getList();
        }

    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LanguageService', ['Restangular', '$state', '$auth', languageService]);

    function languageService(Restangular, $state, $auth) {
        return {
            getLanguagesAll,
            getLanguages,
            getUiLanguages,
        };

        // Public methods

        /**
         * Returns languages list
         * @returns {object} - promise object
         */
        function getLanguagesAll() {
            return Restangular.all('languages-all').getList();
        }

        /**
         * Returns languages list
         * @returns {object} - promise object
         */
        function getLanguages() {
            return Restangular.all('languages').getList();
        }

        /**
         * Returns languages list
         * @returns {object} - promise object
         */
        function getUiLanguages() {
            return Restangular.all('languages-ui').getList();
        }
    }
})();

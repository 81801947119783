(function() {
    'use strict';

    angular.module('beacon.app')
        .component('countdownThresholdInput', {
            templateUrl:
                '/assets/views/content/elements/types/timetable-hafas/timetable-hafas-elements/countdown-threshold-input/countdown-threshold-input.tpl.html',
            controller: CountdownThresholdInput,
            bindings: {
                element: '=',
            }
        });

        /**
         * @property {TimetableHafasElement} element
         *
         * @constructor
         */
        function CountdownThresholdInput() {
            const $ctrl = this;
            const DISABLE_THRESHOLD_VALUE = -1;
            const DEFAULT_THRESHOLD_VALUE = 3;

            $ctrl.isThresholdEnabled = false;
            $ctrl.cachedValue;

            $ctrl.$onInit = () => {
                if (!$ctrl.element) { return; }

                // Enable threshold if "element" has "countdownThreshold" defined as "enabled" (value >= 0)
                if ($ctrl.element.countdownThreshold >= 0) {
                    $ctrl.isThresholdEnabled = true;
                }
            };

            $ctrl.onThresholdToggle = () => {
                if ($ctrl.isThresholdEnabled) {
                    $ctrl.element.countdownThreshold = $ctrl.cachedValue !== undefined
                        ? $ctrl.cachedValue
                        : DEFAULT_THRESHOLD_VALUE;
                    return;
                }

                // Store last value in the component on uncheck, but set it as "-1" in the form data.
                // That way when we send the form data after the uncheck we accidentally would not send last entered value.
                $ctrl.cachedValue = $ctrl.element.countdownThreshold;
                $ctrl.element.countdownThreshold = DISABLE_THRESHOLD_VALUE;
            };
        }
})();

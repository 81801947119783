(function() {
    'use strict';

    angular.module('trmComponents')
        .component('trmClock', {
            template: `<div class="clock">{{ $ctrl.currentTime | date:'HH:mm' }}</div>`,
            controller: function($interval) {
                const $ctrl = this;
                const UPDATE_INTERVAL_MS = 1000;

                $ctrl.$onInit = function() {
                    $ctrl.currentTime = new Date();

                    $ctrl.timer = $interval(() => {
                        $ctrl.currentTime = new Date();
                    }, UPDATE_INTERVAL_MS);
                };

                $ctrl.$onDestroy = function() {
                    $ctrl.timer && $interval.cancel($ctrl.timer);
                };
            }
        });
})();

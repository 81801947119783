(function() {
    'use strict';

    angular.module('beacon.app')
        .component('stationsSelector', {
            templateUrl: '/assets/views/common/popups/select-element/stations-selector/stations-selector.tpl.html',
            controller: StationsSelectorController,
            bindings: {
                model: '=',
                vertical: '<',
            }
        });

    /**
     * @typedef {{name: string, value: number}} StationType
     */

    /**
     * @param {$rootScope.Scope} $scope
     * @param {StationType[]} STATION_TYPES
     * @constructor
     */
    function StationsSelectorController(
        $scope,
        STATION_TYPES,
    ) {
        const $ctrl = this;

        $ctrl.options = STATION_TYPES;

        $ctrl.$onInit = () => {
            if (!angular.isArray($ctrl.model)) {
                $ctrl.model = [];
            }

            _initValidation();
        }

        /**
         * @param {StationType} option
         */
        $ctrl.toggle = option => {
            const index = $ctrl.model.indexOf(option.value);

            if (index !== -1) {
                $ctrl.model.splice(index, 1);
            } else {
                $ctrl.model.push(option.value);
            }

            $ctrl.model.sort();
        }

        /**
         * @param {StationType} option
         */
        $ctrl.isActive = option => {
            return $ctrl.model.includes(option.value);
        }

        function _handleEmpty() {
            if (angular.isArray($ctrl.model) && !$ctrl.model.length) {
                $ctrl.model = $ctrl.options.map(item => item.value);
            }
        }

        function _initValidation() {
            _handleEmpty();

            $scope.$watch(
                () => $ctrl.model,
                () => _handleEmpty(),
                true,
            )
        }
    }
})();

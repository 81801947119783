(function () {
    'use strict';

    angular.module('beacon.app')
        .component('headerFooterType', {
            templateUrl: '/assets/views/content/elements/types/header-footer/header-footer-type.tpl.html',
            controller: HeaderFooterTypeController,
            bindings: {
                langArray: '<',
                contentForm: '<',
                contentData: '<',
                contentGroupsArray: '<',
            }
        });

    /**
     * @param {$rootScope.Scope} $scope
     * @param {$rootScope.Timeout} $timeout
     * @param StatesFactory
     * @param ModelsFactory
     *
     * @property {Language[]} langArray
     * @property {form.FormController} contentForm
     * @property {HeaderFooterContent} contentData
     * @property {ContentGroup[]} contentGroupsArray
     *
     * @property {string} messageJson
     *
     * @constructor
     */
    function HeaderFooterTypeController(
        $scope,
        $timeout,
        StatesFactory,
        ModelsFactory,
    ) {
        const $ctrl = this;

        /**
         * @type {ContentStates}
         */
        $ctrl.state = StatesFactory.ContentStates;
        $ctrl.isEdit = $ctrl.state.type === 'edit';

        $ctrl.addElementType = 'clock';
        $ctrl.addElementPosition = 'left';

        $ctrl.isPreviewReady = true;
        $ctrl.updateDebounceTime = 500;

        $ctrl.elementTypes = [
            'clock',
            'date',
            'image',
            'stop_name',
            'text',
        ];

        $ctrl.$onInit = () => {
            _initModel();
            _initFinishHandler();
            _initFormValidation();
        };

        $ctrl.addElement = () => {
            switch ($ctrl.addElementType) {
                case 'clock':
                    $ctrl.options.elements[$ctrl.addElementPosition].push({
                        type: 'clock',
                        show: true,
                        bold: true,
                        size: 1.5,
                    });
                    break;
                case 'date':
                    $ctrl.options.elements[$ctrl.addElementPosition].push({
                        type: 'date',
                        show: true,
                        bold: true,
                        size: 1,
                    });
                    break;
                case 'stop_name':
                    $ctrl.options.elements[$ctrl.addElementPosition].push({
                        type: 'stop_name',
                        show: true,
                        bold: true,
                        size: 1,
                        singleLine: false,
                        ticker: false,
                    });
                    break;
                case 'image':
                    $ctrl.options.elements[$ctrl.addElementPosition].push({
                        type: 'image',
                        show: true,
                        url: '',
                    });
                    break;
                case 'text':
                    const colors = angular.copy($ctrl.options.colors);

                    $ctrl.options.elements[$ctrl.addElementPosition].push({
                        type: 'text',
                        show: true,
                        content: 'Custom text',
                        size: 1,
                        singleLine: false,
                        ticker: false,
                        colors: {
                            default: {
                                ...colors.default,
                                disableBackground: false,
                            },
                            night: {
                                ...colors.night,
                                disableBackground: false,
                            },
                        },
                    });
                    break;
                default:
                    alert('Not implemented');
            }
        }

        /**
         * Refresh header/footer preview
         * @returns {Promise<void>}
         */
        $ctrl.updatePreview = () => {
            $ctrl.contentData.data = $ctrl.options;
            $ctrl.isPreviewReady = false;

            return $timeout(() => {
                $ctrl.isPreviewReady = true;
            });
        }

        /**
         * @private
         */
        function _initModel() {
            $ctrl.contentData.data = new ModelsFactory.HeaderFooterOptions($ctrl.contentData.data);
            $ctrl.options = $ctrl.contentData.data;
        }

        /**
         * @private
         */
        function _initFinishHandler() {
            $scope.$on(
                'content-finish',
                /**
                 * @param event
                 * @param {{ callback: FinishCallback }} data
                 */
                (event, data) => {
                    data.callback({
                        type: $ctrl.state.type,
                        contentData: angular.copy($ctrl.contentData),
                    });
                }
            );

            /**
             * @callback FinishCallback
             * @param  {{ type: string, contentData: HeaderFooterContent }} data
             * @return void
             */
        }

        /**
         * @private
         */
        function _initFormValidation() {
            $scope.$watch(
                () => $ctrl.contentForm && $ctrl.contentForm.$valid,
                isFormValid => {
                    $ctrl.state.canFinish = Boolean(isFormValid)
                });
        }
    }
})();


(function() {
    'use strict';

    angular.module('beacon.app')
        .component('shareParkTariffs', {
            templateUrl: '/assets/views/share-park/pricing/tariffs/tariffs.tpl.html',
            controller: TariffsController
        });

    function TariffsController(
        $scope,
        $state,
        $translate,
        PermissionsService,
        PopupService,
        PricingDataService,
        UtilitiesService,
        ShareParkHelperService,
    ) {
        const vm = this;

        const { actions, generateTariffsPermission } = PermissionsService;
        const { getRateQuotas } = PricingDataService;
        const { decodeQuota, decodeTariff } = ShareParkHelperService;
        const currentLocale = $translate.use();
        const { sortByStringPropertyWithLang } = UtilitiesService;

        vm.$onInit = init;

        vm.addTariff = addTariff;

        vm.filterTariff = filterTariff;
        vm.filteredTariff = [];
        vm.searchValue = '';

        vm.tariffsAll = [];
        vm.listConfig = {
            columns: [
                {
                    name: 'NAME',
                    class: 'tariff-name',
                    width: '20',
                    title: listItem => listItem.tariff.name[currentLocale] || Object.values(listItem.tariff.name)[0],
                },
                {
                    name: 'PRICE',
                    width: '20',
                    title: listItem => listItem.tariff.priceLabel[currentLocale] || Object.values(listItem.tariff.priceLabel)[0],
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: deleteTariff,
                        permissionAction: actions.delete
                    },
                    {
                        class: 'editBtn',
                        callback: editTariff,
                        permissionAction: actions.modify
                    },
                    {
                        class: 'copyBtn',
                        callback: copyTariff,
                        permissionAction: actions.create
                    },
                ]
            },
            updateCallback: updateList,
            generatePermissionName,
        };

        /**
         * Controller initialization
         */
        function init() {
            Promise.all([
                PricingDataService.getAllTariffs(),
                getRateQuotas(),
            ]).then(([tariffsResponse, quotasResponse]) => {
                const quotas = quotasResponse.plain().map(decodeQuota);
                const tariffsDecoded = tariffsResponse.plain().map(decodeTariff);
                vm.tariffsAll = sortByStringPropertyWithLang(tariffsDecoded, 'name', currentLocale);
                vm.tariffsAll = vm.tariffsAll.map(tariff => {
                    tariff.quota = quotas.find(({id}) => id === tariff.id);

                    return {tariff};
                });
                filterTariff();
                vm.count = vm.tariffsAll.length;
                updateList();
                $scope.$digest();
            })
        }

        /**
         * Filters Tariff list
         */
        function filterTariff() {
            const searchValue = vm.searchValue.toUpperCase();

            vm.filteredTariff = vm.searchValue
                ? vm.tariffsAll.filter(listItem => {
                    const tariffName = listItem.tariff.name[currentLocale] || Object.values(listItem.tariff.name)[0];
                    const tariffPriceLabel = listItem.tariff.priceLabel[currentLocale] || Object.values(listItem.tariff.priceLabel)[0];

                    return tariffName.toUpperCase().includes(searchValue) || tariffPriceLabel.toUpperCase().includes(searchValue)
                })
                : vm.tariffsAll;
            
            vm.count = vm.tariffsAll.length;
            updateList();
        }

        /**
         * Update data on pagination
         *
         * @param page
         * @param count
         */
        function updateList(page = 1, count = 25) {
            vm.tariffs = UtilitiesService.getArrayPage(vm.filteredTariff, page, count);
        }

        /**
         * Create new tariff
         */
        function addTariff() {
            $state.go('app.newTariff');
        }

        /**
         * Edit tariff
         * @param $event
         * @param item
         */
        function editTariff($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            $state.go('app.editTariff', { data: item.tariff });
        }

        function copyTariff($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const copy = angular.copy(item.tariff);
            delete copy.id;
            delete copy.quota.id;
            copy.capProductAssignment.forEach(item => delete item.id);
            copy.capRateDiscountAssignment.forEach(item => delete item.id);

            $state.go('app.editTariff', { data: copy });
        }

        function deleteTariff($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_TARIFF',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                PricingDataService.deleteTariff(item.tariff.id)
                    .then(init)
            });
        }

        /**
         * Generates permission name for each button on tariffs accordion list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            return generateTariffsPermission(button.permissionAction);
        }
    }
})();
(function() {
    'use strict';

    angular.module('beacon.app')
        .component('capDashboard', {
            templateUrl: '/assets/views/share-park/dashboard/cap-dashboard.tpl.html',
            controller: CapDashboardController
        });

    function CapDashboardController(
        $scope,
        ShareParkDataService,
        CAP_DASHBOARD_TIME_RANGES,
    ) {
        const vm = this;

        const {
            getPurchasedItems,
            getCapServiceProviders,
            getCustomers,
        } = ShareParkDataService;

        vm.TIME_RANGES = CAP_DASHBOARD_TIME_RANGES;
        vm.selectedTimeRange = CAP_DASHBOARD_TIME_RANGES[2];
        vm.carParks = [];
        vm.purchasedItems = [];
        vm.customers = [];
        vm.serviceProviders = [];
        vm.userStatuses = [];

        vm.$onInit = init;

        vm.isTimeRangeSelected = isTimeRangeSelected;
        vm.selectTimeRange = selectTimeRange;

        function init() {

            Promise.all([
                getPurchasedItems(),
                getCustomers(),
                getCapServiceProviders(),
            ]).then(([purchasedItems, customers, serviceProviders]) => {
                vm.dataLoaded = true;
                vm.purchasedItems = purchasedItems.plain().map(purchase => {
                    purchase.purchaseDate = moment.utc(purchase.purchaseDate).local();
                    purchase.userProfileLabel = purchase.userProfileLabel
                        ? angular.fromJson(purchase.userProfileLabel)
                        : {};
                    return purchase;
                }).sort((a, b) => {
                    return a.purchaseDate.isAfter(b.purchaseDate) ? 1 : -1;
                });
                vm.customers = customers.plain();
                vm.serviceProviders = serviceProviders.plain();

                vm.customers.forEach(customer => {
                    customer.purchases = vm.purchasedItems.filter(purchase => customer.guid === purchase.guid);
                });

                vm.serviceProviders.forEach(serviceProvider => {
                    serviceProvider.purchases = vm.purchasedItems.filter(purchase =>
                        serviceProvider.id === purchase.serviceProviderRef
                    );
                });

                vm.currencies = [... new Set(vm.purchasedItems.map(({currencyCode}) => currencyCode))]
                    .filter(currencyCode => currencyCode);

                $scope.$digest();
            });
        }

        /**
         * Checks if time range is selected
         * @param {Object} timeRange
         * @return {boolean}
         */
        function isTimeRangeSelected(timeRange) {
            return timeRange.diffValue === vm.selectedTimeRange.diffValue
                && timeRange.diffUnit === vm.selectedTimeRange.diffUnit;
        }

        /**
         * @param {Object} timeRange
         */
        function selectTimeRange(timeRange) {
            vm.selectedTimeRange = timeRange;
        }
    }
})();
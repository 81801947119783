(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentScreenCampaigns', {
            templateUrl: '/assets/views/content/screen-campaigns/screen-campaigns.tpl.html',
            controller: ContentScreenCampaignsController
        });

    function ContentScreenCampaignsController(
        $q,
        $scope,
        $state,
        $stateParams,
        CampaignDataService,
        CampaignHelper,
        CAMPAIGN_TYPES,
        PermissionsService,
        ShareDataService,
    ) {
        const vm = this;

        const { actions, isPermissionAvailable, generateScreenCampaignPermission } = PermissionsService;
        const CAMPAIGN_DETAILS_TAB = 1;

        vm.$onInit = init;

        // public properties
        vm.contentElement = $stateParams.contentElement.plain();
        vm.contentElement.title = angular.isString(vm.contentElement.title)
            ? JSON.parse(vm.contentElement.title)
            : vm.contentElement.title;
        vm.contentTitle = vm.contentElement.title[vm.contentElement.language_id];
        vm.selectedCampaignId = null;

        //public methods
        vm.updateScreenCampaign = updateScreenCampaign;
        vm.updateAllScreenCampaigns = updateAllScreenCampaigns;
        vm.createCampaign = createCampaign;
        vm.showUpdateCampaignsBtn = showUpdateCampaignsBtn;

        /**
         * Initialization method
         */
        function init() {
            checkPermissions();
            $q.all([
                CampaignDataService.getCampaignsList({type: CAMPAIGN_TYPES.SCREEN}),
                CampaignDataService.getRelatedScreenCampaigns(vm.contentElement),
            ])
            .then(([campaignsAll, campaignsRelated]) => {
                vm.campaigns = campaignsAll;
                vm.relatedCampaigns = campaignsRelated;
                vm.notRelatedCampaigns = vm.campaigns.filter(campaign => !vm.relatedCampaigns.find(relatedCampaign => relatedCampaign.id === campaign.id));
            })
            .catch(console.error.bind(console));
        }

        /**
         * Checks user permissions for screen campaigns
         */
        function checkPermissions() {
            const permissionActions = [actions.view, actions.modify];
            vm.createScreenCampPermission = generateScreenCampaignPermission(actions.create);
            vm.canViewAndModifyScreenCampaigns = permissionActions.every(action =>
                isPermissionAvailable(generateScreenCampaignPermission(action))
            )
        }

        /**
         * Create campaign click handler
         */
        function createCampaign() {
            $state.go('app.addScreenCampaign', {
                selectedContentId: vm.contentElement.id,
                tab: CAMPAIGN_DETAILS_TAB,
                redirect: $stateParams.redirect,
            });
        }

        /**
         * Update screen campaigns and redirect to elements page
         */
        function updateAllScreenCampaigns() {
            ShareDataService.updateAllSharedCampaigns()
                .then(() => {
                    $stateParams.redirect
                        ? $state.go($stateParams.redirect)
                        : $state.go('app.content');
                }).catch(console.error.bind(console));
        }

        /**
         * Updates screen campaign
         * @param campaign
         * @return {*}
         */
        function updateScreenCampaign(campaign) {
            return ShareDataService.updateSharedCampaign(campaign.id).then(() => {
                campaign.new_content = 0;
            }).catch(console.error.bind(console));
        }

        /**
         * Checks if update all campaigns button should be shown
         * @return {boolean|*}
         */
        function showUpdateCampaignsBtn() {
            const NEW_CONTENT = 1;
            return vm.relatedCampaigns &&
                vm.relatedCampaigns.some(campaign => campaign.new_content === NEW_CONTENT);
        }


        /**
         * Next btn handler
         */
        function next() {
            if (!vm.selectedCampaignId) {
                $stateParams.redirect
                    ? $state.go($stateParams.redirect)
                    : $state.go('app.content');
                return;
            }

            CampaignDataService.getCampaigns({ id: vm.selectedCampaignId }).then(response => {
                const campaign = response[0];

                $state.go('app.editScreenCampaign', {
                    data: campaign,
                    selectedContentId: vm.contentElement.id,
                    tab: CAMPAIGN_DETAILS_TAB,
                    redirect: $stateParams.redirect,
                });
            });
        }

        /**
         * Next button click broadcast from toolbar/toolbar.ctrl.js
         */
        $scope.$on('nextClick', next);
    }
})();

(function() {
    'use strict';

    angular.module('beacon.app')
        .component('poleArrowSelector', {
            controller: PoleArrowSelectorController,
            templateUrl: '/assets/views/location/new-screen/tabs/screen-mct-settings/pole-arrow-selector/pole-arrow-selector.tpl.html',
            bindings: {
                poleArrow: '=',
            }
        });

    function PoleArrowSelectorController(

    ) {
        const vm = this;

        vm.arrows = [
            {
                id: 0,
                opacity: 0,
            },
            {
                id: 1,
                img: "/assets/images/pole_arrow_Geradeaus.svg",
            },
            {
                id: 2,
                img: "/assets/images/pole_arrow_rechts oben.svg",
            },
            {
                id: 3,
                img: "/assets/images/pole_arrow_rechts.svg",
            },
            {
                id: 4,
                img: "/assets/images/pole_arrow_rechts unten.svg",
            },
            {
                id: 5,
                img: "/assets/images/pole_arrow_zurueck.svg",
            },
            {
                id: 6,
                img: "/assets/images/pole_arrow_links unten.svg",
            },
            {
                id: 7,
                img: "/assets/images/pole_arrow_links.svg",
            },
            {
                id: 8,
                img: "/assets/images/pole_arrow_links oben.svg",
            },
            {
                id: 9,
                img: "/assets/images/pole_arrow_geradeaus links um Eck.svg",
            },
            {
                id: 10,
                img: "/assets/images/pole_arrow_links um Eck.svg",
            },
            {
                id: 11,
                img: "/assets/images/pole_arrow_geradeaus rechts um Eck.svg",
            },
            {
                id: 12,
                img: "/assets/images/pole_arrow_Geschlossen.svg",
            },
            {
                id: 13,
                img: "/assets/images/pole_arrow_rechts um Eck.svg",
            },
            {
                id: 14,
                img: "/assets/images/pole_arrow_umdrehen.svg",
            },
        ];
        vm.opened = false;

        vm.$onInit = init;
        vm.generateStyle = generateStyle;
        vm.toggle = toggle;
        vm.select = select;
        vm.image = image;

        function init() {

        }

        function toggle() {
            vm.opened = !vm.opened;
        }

        function select(id) {
            vm.poleArrow = id;
            vm.opened = false;
        }

        function image(id) {
            const arrow = vm.arrows.find(arrow => arrow.id === id);
            return arrow ? arrow.img : null;
        }

        function generateStyle(id) {
            if (id === undefined) {
                id = 0;
            }
            const arrow = vm.arrows.find(item => item.id === id);
            return {
                opacity: arrow.opacity !== undefined ? arrow.opacity: 1,
            };
        }
    }
})();
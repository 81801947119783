(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('StatesFactory', StatesFactory);

    function StatesFactory() {

        class StateAssistant {
            constructor(state) {
                this.parent = state;
            }

            get state() {
                if (!this.child || !this.parent.isLast()) {
                    return this.parent;
                }

                return this.child;
            }

            isParent() {
                return this.state === this.parent;
            }

            isChild() {
                return this.state === this.child;
            }

            back() {
                if (this.isChild() && this.child.isFirst()) {
                    delete this.child;
                    return this.parent.back();
                }

                this.state.back();
            }
        }

        /**
         * @name BaseStatesModel
         *
         * @property {string} type
         * @property {object} views
         * @property {boolean} canBack
         * @property {boolean} canNext
         * @property {boolean} canFinish
         */
        class BaseStatesModel {
            /**
             * @constructor
             * @param  {Object} params :: base settings
             */
            constructor(params) {
                if (typeof params !== 'object') {
                    throw new TypeError('First argument should be the object');
                }

                if (!params.hasOwnProperty('views')) {
                    throw new ReferenceError('Object does not contain the \'views\' own property');
                }

                if (typeof params.views !== 'object') {
                    throw new TypeError('The \'views\' property should be the Object');
                }

                this.__params = angular.copy(params);
                this.first = Math.min(...Object.values(this.__params.views).map((v) => v[0]));
                this.last = Math.max(...Object.values(this.__params.views).map((v) => v[0]));

                this.refresh();
            }

            /**
             * Refresh state
             * @return {Object} :: this
             */
            refresh() {
                return Object.assign(this, this.__params, {
                    view: this.first,
                    haveBack: false,
                    haveNext: this.first < this.last,
                    canBack: false,
                    canNext: false,
                    canFinish: false
                });
            }

            /**
             * Going to the view state
             * @param  {String}  view          :: string view state name
             * @param  {Boolean} [force=false] :: skip avaliable verification
             * @return {Object}                :: this
             */
            go(view, force = false) {
                if (!(view in this.views)) {
                    throw new Error('View (' + view + ') not found in \'views\' property');
                }

                if (force || (this.canBack && this.haveBack) || (this.canNext && this.haveNext)) {
                    this.view = this.views[view][0];
                    this.haveBack = this.view > this.first;
                    this.haveNext = this.view < this.last;

                    if (!this.haveBack) {
                        this.canBack = false;
                    }
                    if (!this.haveNext) {
                        this.canNext = false;
                    }
                }

                return this;
            }

            /**
             * Easyway for going to the next view state
             * @return {Object} :: this
             */
            back() {
                let view = Object.keys(this.views).filter((key) => this.views[key][0] === this.view - 1)[0];

                if (!view || !this.canBack) {
                    return;
                }

                this.go(view);
                this.canBack = false;
                this.canNext = true;

                return this;
            }

            /**
             * Easyway for going to the next view state
             * @return {Object} :: this
             */
            next() {
                let view = Object.keys(this.views).filter((key) => this.views[key][0] === this.view + 1)[0];

                if (!view || !this.canNext) {
                    return;
                }

                this.go(view);
                this.canNext = false;
                this.canBack = true;

                return this;
            }

            /**
             * Is current view active
             * @param  {String}  view :: string view state name
             * @return {Boolean}
             */
            is(view) {
                if (!(view in this.views)) {
                    return false;
                }

                return this.view === this.views[view][0];
            }

            /**
             * Easyway first view checking
             * @return {Boolean} :: is the first state
             */
            isFirst() {
                return this.view === this.first;
            }

            /**
             * Easyway last view checking
             * @return {Boolean} :: is the last state
             */
            isLast() {
                return this.view === this.last;
            }

            // Show buttons default rules
            showCancel() {
                return this.isFirst();
            }
            showBack() {
                return this.haveBack;
            }
            showNext() {
                return this.haveNext;
            }
            showFinish() {
                return !this.haveNext;
            }
        }

        /**
         * @name ContentStates
         */
        class ContentStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        CONTENT_TYPE: [0, 'CONTENT_TYPE'],
                        CONTENT_DETAILS: [1, 'CONTENT_DETAILS']
                    },
                    type: 'new'
                });
            }

            showCancel() {
                return this.is('CONTENT_DETAILS') && this.type === 'edit' || this.is('CONTENT_TYPE');
            }

            showBack(){
                return this.is('CONTENT_DETAILS') && this.type !== 'edit';
            }
        }

        class DigitalDisplayStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        PAGES_LIST: [0, 'CONTENT_DETAILS'],
                        SELECT_PAGE_TYPE: [1, 'PAGE_TYPE'],
                        EDIT_PAGE: [2, 'PAGE_DETAILS']
                    },
                    type: 'new'
                });
            }

            showNext() {
                return this.is('SELECT_PAGE_TYPE');
            }
            showFinish() {
                return this.is('PAGES_LIST');
            }
            showSavePage() {
                return this.is('EDIT_PAGE');
            }
        }

        class QuizStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        QUIZ_DESCRIPTION: [0, 'DESCRIPTION'],
                        QUIZ_QUESTIONS: [1, 'QUESTIONS']
                    },
                    type: 'new'
                });

            }
        };

        class BannerStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        CONTENT: [0, 'CONTENT'],
                        LAYOUT: [1, 'LAYOUT'],
                        ACTIONS: [2, 'ACTIONS']
                    },
                    type: 'new'
                });

            }
        };

        class ManagementStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {}
                });
            }

            showBack() {
                return true;
            }
        }

        class LoyaltyCampaignStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        CAMPAIGN_TYPE: [0, 'TYPE'],
                        COLLECTION: [1, 'COLLECTION'],
                        REWARD: [2, 'REWARD'],
                        DETAILS: [3, 'DETAILS'],
                        TARGETING: [4, 'TARGETING'],
                    }
                });
            }

            // TODO: Update needed when implementing edit feature
            showCancel() {
                return true;
            }

            showBack() {
                return !this.is('CAMPAIGN_TYPE');
            }
        }

        class TierGroupStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    }
                });
            }
        }

        class TierStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    }
                });
            }
        }

        class LoyaltyCampaignListStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        LIST: [0, 'LIST']
                    }
                });
            }
        }

        class VoucherStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    }
                });
            }
        }

        class SponsorStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    }
                });
            }
        }

        class PoiContentStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    },
                });
            }
        }

        class PoiCategoryStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    },
                });
            }
        }

        class PoiTourStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    },
                });
            }
        }

        class ScenarioStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'EDIT']
                    },
                });
            }
        }

        class ScreenStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        SCREEN_DETAILS: [0, 'SCREEN_DETAILS'],
                        SCREEN_MCT_SETTINGS: [1, 'SCREEN_DATA_FILTERING'],
                        SCREEN_GROUPS: [2, 'SCREEN_GROUPS'],
                    },
                });
            }
        }

        class CarParkStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        CAR_PARK_DATA: [0, 'CAR_PARK_DATA'],
                        SUB_AREAS: [1, 'SUB_AREAS'],
                        GATEWAYS_DATA: [2, 'GATEWAYS'],
                        ECHARGE_DATA: [3, 'CHARGING_STATIONS'],
                        TARIFF_DATA: [4, 'TARIFFS'],
                    }
                });
            }

            showBack() {
                return !this.is('CAR_PARK_DATA');
            }
        }

        class IotDeviceStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class IotEntityStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkCustomerStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkGuestsStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkCustomerComunicationStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkChargingStationStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class Unity3dModelStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkServiceProvidersStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkSubletsStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class CapStatisticsStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        CAR_PARKS_TAB: [0, 'CAR_PARKS'],
                        STATISTICS_TAB: [1, 'STATISTICS'],
                    }
                });
            }

            showBack() {
                return !this.is('CAR_PARKS_TAB');
            }
        }

        class CapReservationsStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        CAR_PARKS_TAB: [0, 'CAR_PARKS'],
                        CAPACITY_FORECAST_TAB: [1, 'CAPACITY_FORECAST'],
                    }
                });
            }

            showBack() {
                return !this.is('CAR_PARKS_TAB');
            }
        }

        class LegalTextStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class BillingConfigurationStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkCompaniesStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkFAQStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class FeedbackFormEditStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class FeedbackTicketStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkProfileStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkProfileConfigStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ShareParkProfileRulesStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class UserGroupStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        GROUP_DETAILS: [0, 'GROUP_DETAILS'],
                        GROUP_SHARE_PARK: [1, 'GROUP_SHARE_PARK'],
                        GROUP_SMART_CAMPUS: [2, 'GROUP_SMART_CAMPUS'],
                        GROUP_PERMISSIONS: [3, 'GROUP_PERMISSIONS'],
                    },
                });
            }
        }

        class DiscountStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class ProductStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        class TariffStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        TARIFF_TYPE: [0, 'TARIFF_TYPE'],
                        TARIFF_DETAILS: [1, 'TARIFF_DETAILS'],
                        TARIFF_QUOTA: [2, 'TARIFF_QUOTA'],
                        TARIFF_ASSIGNMENTS: [3, 'TARIFF_ASSIGNMENTS'],
                    },
                });
            }
        }

        class TariffModelStates extends BaseStatesModel {
            constructor() {
                super({
                    views: {
                        EDIT: [0, 'Edit']
                    },
                });
            }
        }

        return {
            StateAssistant,
            ContentStates: new ContentStates(),
            DigitalDisplayStates: new DigitalDisplayStates(),
            QuizStates: new QuizStates(),
            ManagementStates: new ManagementStates(),
            BannerStates: new BannerStates(),
            LoyaltyCampaignStates: new LoyaltyCampaignStates(),
            TierGroupStates: new TierGroupStates(),
            TierStates: new TierStates(),
            LoyaltyCampaignListStates: new LoyaltyCampaignListStates(),
            VoucherStates: new VoucherStates(),
            SponsorStates: new SponsorStates(),
            PoiContentStates: new PoiContentStates(),
            PoiCategoryStates: new PoiCategoryStates(),
            PoiTourStates: new PoiTourStates(),
            ScenarioStates: new ScenarioStates(),
            ScreenStates: new ScreenStates(),
            CarParkStates: new CarParkStates(),
            IotDeviceStates: new IotDeviceStates(),
            ShareParkCustomerStates: new ShareParkCustomerStates(),
            ShareParkGuestsStates: new ShareParkGuestsStates(),
            ShareParkCustomerComunicationStates: new ShareParkCustomerComunicationStates(),
            ShareParkChargingStationStates: new ShareParkChargingStationStates(),
            Unity3dModelStates: new Unity3dModelStates(),
            ShareParkServiceProvidersStates: new ShareParkServiceProvidersStates(),
            ShareParkSubletsStates: new ShareParkSubletsStates(),
            IotEntityStates: new IotEntityStates(),
            CapStatisticsStates: new CapStatisticsStates(),
            CapReservationsStates: new CapReservationsStates(),
            LegalTextStates: new LegalTextStates(),
            BillingConfigurationStates: new BillingConfigurationStates(),
            ShareParkCompaniesStates: new ShareParkCompaniesStates(),
            ShareParkFAQStates: new ShareParkFAQStates(),
            FeedbackFormEditStates: new FeedbackFormEditStates(),
            FeedbackTicketStates: new FeedbackTicketStates(),
            ShareParkProfileStates: new ShareParkProfileStates(),
            ShareParkProfileConfigStates: new ShareParkProfileConfigStates(),
            ShareParkProfileRulesStates: new ShareParkProfileRulesStates(),
            UserGroupStates: new UserGroupStates(),
            DiscountStates: new DiscountStates(),
            ProductStates: new ProductStates(),
            TariffStates: new TariffStates(),
            TariffModelStates: new TariffModelStates(),
        };
    }

})();
